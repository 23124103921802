import styled from 'styled-components';

import {offsetLg, mdBp} from 'constants/styleVars';

export const Wrapper = styled.div`
  overflow: auto;
  user-select: none;
`;

export const StyledSection = styled.section<{
  showLessVenuesThanByDefault?: boolean;
  isTablet?: boolean;
}>`
  display: grid;
  grid-gap: ${offsetLg};
  margin-bottom: ${offsetLg};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: ${({showLessVenuesThanByDefault, isTablet}) =>
      showLessVenuesThanByDefault || isTablet
        ? '1fr 1fr'
        : '1fr 1fr 1fr'}; // isTablet 1fr 1fr
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

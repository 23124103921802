import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';

import {
  borderColor,
  contentMaxWidth,
  whiteColor,
  pagePadding,
  offsetXLg,
} from '../../../constants/styleVars';

export const Container = styled.section<{
  hasSider?: boolean;
  autoHeight?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  border: 1px solid ${borderColor};
  ${({hasSider}) =>
    !hasSider
      ? `
    background-color: ${whiteColor};
  `
      : ''}
  ${({autoHeight}) => (autoHeight ? 'align-items: flex-start;' : '')}
`;

export const Content = styled.section<{hasSider?: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({hasSider}) => (hasSider ? offsetXLg : '0 auto')};
  padding: ${pagePadding};

  & .ant-spin-nested-loading {
    height: 100%;
  }

  ${({hasSider}) =>
    hasSider
      ? `
    background-color: ${whiteColor};
  `
      : `
    max-width: ${contentMaxWidth};
  `}
`;

const PageContent: FC<{
  hasSider?: boolean;
  autoHeight?: boolean;
  children: ReactNode;
}> = ({hasSider, autoHeight, children}) => {
  return (
    <Container hasSider={hasSider} autoHeight={autoHeight}>
      <Content hasSider={hasSider}>{children}</Content>
    </Container>
  );
};

export default PageContent;

import React from 'react';

import Icon from 'view/components/Icon';
import BookingChangeSidebarBodyPackageCard from './BookingChangeSidebarBodyPackageCard';
import {IBookedChanges} from '../types';

import {StyledLine} from '../BookingChangeOverviewSidebar.styles';

type TProps = {
  packageId?: IBookedChanges<number>;
  packagePrice?: IBookedChanges<number>;
  participants?: IBookedChanges<number>;
  currentParticipants?: number | null;
  currency?: string;
  prevTotalPriceForPackageSet?: number | null;
  newTotalPriceForPackageSet?: number | null;
};

const BookingChangeSidebarBodyInfoChanges = ({
  packageId,
  packagePrice,
  participants,
  currentParticipants,
  currency,
  prevTotalPriceForPackageSet,
  newTotalPriceForPackageSet,
}: TProps) => (
  <StyledLine>
    <BookingChangeSidebarBodyPackageCard
      packageId={packageId?.prev}
      packagePrice={packagePrice?.prev}
      participants={participants?.prev || currentParticipants}
      isPrev={true}
      isPackageChanged={Boolean(packageId)}
      isParticipantsChanged={Boolean(participants)}
      currency={currency}
      totalPriceForPackageSet={prevTotalPriceForPackageSet}
    />
    <Icon justify='center' disabledColor icon='NW2_ARROW_RIGHT_THIN' />
    <BookingChangeSidebarBodyPackageCard
      packageId={packageId?.new}
      packagePrice={packagePrice?.new}
      participants={participants?.new || currentParticipants}
      currency={currency}
      totalPriceForPackageSet={newTotalPriceForPackageSet}
    />
  </StyledLine>
);

export default BookingChangeSidebarBodyInfoChanges;

import {TContainer} from 'app';
import LocalStorageService from 'infra/common/localStorage.service';
import {AppDispatch, RootState} from 'store/types';
import {setVenue} from 'store/venue/actions';
import {IVenue} from 'types/venue';
import {getSortedOffice} from 'utils/corporateOfficeUtils';
import {
  setOfficesError,
  setOfficesLoading,
  setOfficesPayload,
  setSelectedOfficeId,
} from './corporateOfficeSlice';

export const getCorporateOffices =
  (corporateAccountId: number, isSetVenue: boolean) =>
  (dispatch: AppDispatch, _: () => RootState, {venueContainer}: TContainer) => {
    dispatch(setOfficesLoading(true));
    venueContainer.getCorporateVenues({
      payload: corporateAccountId,
      onSuccess: (venues: IVenue[]) => {
        if (venues.length) {
          const selectedOfficeId =
            LocalStorageService.getByKey('selectedOfficeId');
          const selectedOffice =
            venues.find(({id}) => id === Number(selectedOfficeId)) ??
            getSortedOffice(venues);

          dispatch(setOfficesPayload(venues));
          if (isSetVenue) {
            dispatch(setVenue(selectedOffice));
            dispatch(
              setSelectedOfficeId(
                selectedOfficeId ? Number(selectedOfficeId) : selectedOffice.id,
              ),
            );
          }
        }
        dispatch(setOfficesLoading(false));
      },
      onError: (error: any) => {
        dispatch(setOfficesError(error));
      },
    });
  };

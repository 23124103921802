import styled, {css} from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {offsetDef, offsetSm} from 'constants/styleVars';

export const StyledButtonConfirm = styled(NW2Button)<{hasCart?: boolean}>`
  white-space: nowrap;

  ${({hasCart}) =>
    hasCart &&
    css`
      padding: ${offsetSm} ${offsetDef};
    `}
`;

import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import NW2VenueDetailsExpandedMapGoogleMap from './components/NW2VenueDetailsExpandedMapGoogleMap';
import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';

import {getAddressStringFromLocation} from 'utils/stringUtils';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

type TProps = {
  closeMap: () => void;
};

export const MapBlock = styled.div`
  flex-grow: 1;
`;

export function NW2VenueDetailsExpandedMap({closeMap}: TProps) {
  const mapContainerRef = useRef(null);
  const [mapHeight, setMapHeight] = useState(0);

  const {venueDetails} = useVenueDetailsData();

  const setMapHeightHandler = () => {
    setMapHeight(0);
    return setTimeout(() => {
      if (mapContainerRef) {
        setMapHeight((mapContainerRef as any)?.current?.clientHeight || 0);
      }
    }, 0);
  };

  useEffect(() => {
    window.addEventListener('orientationchange', setMapHeightHandler);
    return () => {
      window.removeEventListener('orientationchange', setMapHeightHandler);
    };
  }, []);

  useLayoutEffect(() => {
    setMapHeightHandler();
  }, [mapContainerRef]);

  const address = getAddressStringFromLocation(venueDetails.location);

  const getMapElement = () => (
    <MapBlock ref={mapContainerRef}>
      <NW2VenueDetailsExpandedMapGoogleMap mapHeight={mapHeight} />
    </MapBlock>
  );

  return (
    <NW2ModalTwoColumns
      title="What's around"
      subTitle={address}
      isShowed={true}
      onToggleShow={closeMap}
      colLeft={null}
      colRight={getMapElement()}
      isRightColumnFullHeight
    />
  );
}

export default NW2VenueDetailsExpandedMap;

import React from 'react';
import {useAppSelector} from 'store/hooks';

import ModalOverlay from 'view/components/ModalOverlay';
import Slider from 'view/components/Slider/Slider';
import Icon from 'view/components/Icon';

import {borderRadiusDef} from 'constants/styleVars';
import {TImage} from 'types/app';

import {
  StyledButtonLeft,
  StyledButtonRight,
  StyledCloseButton,
  StyledContainer,
  StyledHeader,
  StyledMain,
  StyledTitle,
  StyledWrapper,
} from './NW2ModalGallery.styles';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

interface IProps {
  images: TImage[];
  isShowed: boolean;
  onClose: () => void;
  startIndex?: number;
  isNoBlockScroll?: boolean;
}

export function NW2ModalGallery({
  images,
  isShowed,
  onClose,
  startIndex,
  isNoBlockScroll,
}: IProps) {
  const {venueDetails} = useVenueDetailsData();

  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  if (!isShowed) {
    return null;
  }

  return (
    <ModalOverlay isNoBlockScroll={isNoBlockScroll}>
      <StyledWrapper>
        <StyledHeader>
          <StyledTitle>{venueDetails.name}</StyledTitle>

          <StyledCloseButton onClick={onClose}>
            <Icon icon='CLOSE' size={20} />
          </StyledCloseButton>
        </StyledHeader>

        <StyledMain>
          <StyledContainer>
            <Slider
              imageGalleryItems={images.map(({name, url}) => ({
                original: url,
                originalAlt: name,
              }))}
              imageBorderRadius={borderRadiusDef}
              renderLeftNav={(onClick, disabled) =>
                isDesktop && (
                  <StyledButtonLeft onClick={onClick} disabled={disabled} />
                )
              }
              renderRightNav={(onClick, disabled) =>
                isDesktop && (
                  <StyledButtonRight onClick={onClick} disabled={disabled} />
                )
              }
              startIndex={startIndex ?? 0}
            />
          </StyledContainer>
        </StyledMain>
      </StyledWrapper>
    </ModalOverlay>
  );
}

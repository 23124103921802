import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  ChangeEvent,
  ForwardedRef,
} from 'react';
import styled from 'styled-components';

import Predictions from './components/Predictions';
import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import Icon from 'view/components/Icon';

import {useAppSelector} from 'store/hooks';
import {useClickOutside} from 'hooks/useClickOutside';
import {EMPTY_ARRAY} from 'constants/app';
import {offsetXSm} from 'constants/styleVars';
import {TNW2GoogleMapSearchInputData} from 'types/googleMap';
import {
  Label,
  NW2SearchFormInput,
  InputComponentContainer,
} from '../InputBlock/InputBlock.styles';

type TInputProps = {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
  onFocus: () => void;
  onBlur: () => void;
  autoFocus?: boolean;
  ref?: any;
};

type TProps = {
  onSearch: (data: TNW2GoogleMapSearchInputData) => void;
  id: string;
  inputValue: string;
  searchInputFocused?: boolean;
  setSearchInputFocused?: (data: boolean) => void;
  placeholder?: string;
  onClick: () => void;
  onFocus: () => void;
  onBlur: () => void;
  isMobile: boolean;
  onChangeInput?: () => void;
};

const Container = styled.div`
  position: relative;
`;

const InputComponentIcon = styled(Icon)`
  margin-left: ${offsetXSm};
`;

const PlaceholderInput = () => {
  return (
    <input
      type='text'
      id='placeholder-input'
      name='placeholder-input'
      placeholder='Where do you want to work?'
      autoComplete='off'
    />
  );
};

const InputComponent = forwardRef(
  (
    {
      id,
      placeholder,
      value,
      onChange,
      onFocus,
      onBlur,
      onClick,
      autoFocus = false,
    }: TInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <NW2SearchFormInput
        type='text'
        id={id}
        name={id}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        autoComplete='off'
        autoFocus={autoFocus}
        ref={ref}
      />
    );
  },
);

const NW2GoogleMapSearchInput = ({
  inputValue,
  onSearch,
  searchInputFocused,
  setSearchInputFocused,
  id,
  placeholder,
  onClick,
  onFocus,
  onBlur,
  isMobile,
  onChangeInput,
}: TProps) => {
  const [search, setSearch] = useState(inputValue);
  const [predictions, setPredictions] = useState(EMPTY_ARRAY);
  const [visible, setVisible] = useState(false);
  const drawerInput = useRef(null);

  const isGoogleMapLoaded = useAppSelector(({app}) => app.isGoogleMapLoaded);

  const google = (window as any).google;

  const isModalVisible = visible && isMobile;

  const closePredictions = useCallback(() => {
    if (typeof setSearchInputFocused === 'function') {
      setSearchInputFocused(false);
    }
    setVisible(false);
    onBlur();
  }, [onBlur, setSearchInputFocused]);

  const [searchComponent] = useClickOutside(
    visible && !isMobile,
    closePredictions,
  );

  const displaySuggestions = useCallback(
    (predictions: React.SetStateAction<never[]>, status: any) => {
      if (
        !isGoogleMapLoaded ||
        status != google.maps.places.PlacesServiceStatus.OK ||
        !predictions?.length
      ) {
        return;
      }
      setPredictions(predictions);
    },
    [isGoogleMapLoaded, google],
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event?.target?.value || '');
  };

  const onInputFocus = () => {
    !isMobile && onFocus();
    setVisible(true);
  };

  const onSelect = useCallback(
    (searchData: TNW2GoogleMapSearchInputData) => {
      onSearch(searchData);
      closePredictions();

      if (typeof onChangeInput === 'function') {
        onChangeInput();
      }
    },
    [closePredictions, onChangeInput, onSearch],
  );

  useEffect(() => {
    if (search && isGoogleMapLoaded) {
      const service = new google.maps.places.AutocompleteService();

      service.getQueryPredictions({input: search}, displaySuggestions);
    } else {
      setPredictions(EMPTY_ARRAY);
    }
  }, [isGoogleMapLoaded, google, search, displaySuggestions]);

  useEffect(() => {
    if (!visible) {
      setSearch(inputValue);
    }
  }, [visible, inputValue]);

  const PredictionsComponent = () => (
    <Predictions
      predictions={predictions}
      onSearch={onSelect}
      isInModal={isModalVisible}
    />
  );

  const inputProps = {
    onBlur,
    onChange,
    value: search,
    placeholder,
    onFocus: onInputFocus,
    onClick,
  };

  const setFocus = (isOpen: boolean) => {
    isOpen && (drawerInput as any)?.current?.focus();
  };

  const clearInput = () => {
    setSearch('');
  };

  if (!isGoogleMapLoaded) return null;

  return (
    <Container ref={searchComponent}>
      {isModalVisible ? (
        <PlaceholderInput />
      ) : (
        <InputComponent
          id={id}
          autoFocus={!!searchInputFocused}
          {...inputProps}
        />
      )}

      {isMobile ? (
        <StyledAntdDrawer
          title={null}
          height='60%'
          onClose={closePredictions}
          open={visible}
          afterOpenChange={setFocus}
        >
          <Label htmlFor={`mobile${id}`} isFocused>
            <span>Search</span>
          </Label>
          <InputComponentContainer>
            <InputComponent
              id={`mobile${id}`}
              {...inputProps}
              ref={drawerInput}
              autoFocus={!!searchInputFocused}
            />
            <InputComponentIcon icon='CLOSE' onClick={clearInput} />
          </InputComponentContainer>
          <PredictionsComponent />
        </StyledAntdDrawer>
      ) : visible ? (
        <PredictionsComponent />
      ) : null}
    </Container>
  );
};

export default NW2GoogleMapSearchInput;

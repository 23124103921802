import React from 'react';
import {Form} from 'react-final-form';
import {DateTime} from 'luxon';

import DesktopDatePicker from 'view/components/NW2Datepicker/DesktopDatePicker';
import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';
import NW2Button from 'view/components/NW2Button';
import useSearchData from 'view/venue/hooks/useSearchData';

import {
  NW2FormItemInput,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  emailFieldRules,
  firstNameFieldRules,
  lastNameFieldRules,
  legalPhoneFieldRules,
  requiredFieldRules,
} from 'utils/finalFormFieldRules';
import {useOpenTermsAndConditionsModal} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';
import {DateFormats} from 'utils/dateUtils';
import {TRequestToHRSSupportPayload} from 'types/app';
import {useAppDispatch} from 'store/hooks';
import {postRequestToHRSSupport} from 'store/app/apiActions';

import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {Group} from 'styles/basicStyledComponents/group';

function RequestModalForm({
  isMeetingRoom,
  isWorkSpace,
  toggleRequestModal,
}: {
  isMeetingRoom: boolean;
  isWorkSpace: boolean;
  toggleRequestModal: () => void;
}) {
  const dispatch = useAppDispatch();
  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();
  const {querySearchData} = useSearchData();

  const submitRequestHandler = async (
    formState: TRequestToHRSSupportPayload,
  ) => {
    const data = {
      ...formState,
      roomType: querySearchData.roomType,
      subjectPlaceholder: isMeetingRoom ? 'meeting room' : 'work space',
    };

    await dispatch(postRequestToHRSSupport(data, toggleRequestModal));
  };

  const checkboxLabel = (
    <>
      By proceeding you accept the HRS
      <a onClick={openTermsAndConditionsModal}> Terms & Conditions</a>
    </>
  );

  const initialValues = {
    location: querySearchData?.searchString || '',
    ...(isMeetingRoom
      ? {
          date: querySearchData?.startDate
            ? DateTime.fromISO(querySearchData?.startDate).toFormat(
                DateFormats['31.12.2000'],
              )
            : '',
          participants: querySearchData?.meetingRoomCapacity || '',
        }
      : {}),
  };

  return (
    <Form onSubmit={submitRequestHandler} initialValues={initialValues}>
      {({handleSubmit}) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormGroup columnNumber={1} gap={16}>
              {isMeetingRoom && (
                <FormGroup columnNumber={2} gap={16}>
                  <NW2FormItemInput
                    name='firstName'
                    type='text'
                    label='First name*'
                    placeholder='First name*'
                    showAllValidationErrors
                    rules={firstNameFieldRules}
                  />
                  <NW2FormItemInput
                    name='lastName'
                    type='text'
                    label='Last name*'
                    placeholder='Last name*'
                    showAllValidationErrors
                    rules={lastNameFieldRules}
                  />
                </FormGroup>
              )}

              <NW2FormItemInput
                name='email'
                type='email'
                label='Your email address*'
                placeholder='Your email address*'
                rules={emailFieldRules}
                showAllValidationErrors
              />

              {isWorkSpace && (
                <NW2FormItemInput
                  name='accommodationName'
                  type='text'
                  label='Your favorite venue*'
                  placeholder='Your favorite venue*'
                  showAllValidationErrors
                  rules={requiredFieldRules('Your favorite venue')}
                />
              )}

              {isMeetingRoom && (
                <NW2FormItemPhoneNumber
                  type='text'
                  name='phoneNumber'
                  placeholder='Telephone number*'
                  label='Telephone number*'
                  rules={legalPhoneFieldRules}
                />
              )}

              {isMeetingRoom && (
                <DesktopDatePicker
                  inputName='date'
                  inputLabel='Date*'
                  minDate={new Date()}
                  maxDate={DateTime.now().plus({year: 1}).toJSDate()}
                  isRequired
                />
              )}

              <NW2FormItemInput
                name='location'
                type='text'
                label='Location*'
                placeholder='Location*'
                showAllValidationErrors
                rules={requiredFieldRules('Location')}
              />

              {isMeetingRoom && (
                <NW2FormItemInput
                  name='participants'
                  type='text'
                  label='Number of participants*'
                  placeholder='Number of participants*'
                  showAllValidationErrors
                  rules={requiredFieldRules('Number of participants')}
                />
              )}

              <NW2FormTextarea
                name='additionalComment'
                label='Additional requirements'
                placeholder='Additional requirements'
                showAllValidationErrors
              />

              <span>{checkboxLabel}</span>
              <span />

              <Group justify='flex-end'>
                <NW2Button
                  inline
                  buttonType='secondary'
                  onClick={toggleRequestModal}
                >
                  Cancel
                </NW2Button>
                <NW2Button inline buttonType='primary' type='submit'>
                  Submit
                </NW2Button>
              </Group>
            </FormGroup>
          </form>
        );
      }}
    </Form>
  );
}

export default RequestModalForm;

import React from 'react';
import styled from 'styled-components';
import {Button, Input} from 'antd';
import {FilterDropdownProps} from 'antd/es/table/interface';

import {offsetSm} from 'constants/styleVars';
import {StyledFilterButton, ButtonWrapper} from './ColumnRenderContentStyles';

const InputWrapper = styled.div`
  padding: ${offsetSm};
`;

export function FilterSearchDropdown({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: FilterDropdownProps) {
  const handleSearch = () => {
    confirm();
  };

  const handleReset = () => {
    if (clearFilters) clearFilters();
    confirm();
  };

  const handleSelectedKeys = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedKeys(e.target.value ? [e.target.value] : []);
  };

  return (
    <div>
      <InputWrapper>
        <Input
          placeholder='Search'
          value={selectedKeys[0] as string}
          onChange={handleSelectedKeys}
          onPressEnter={handleSearch}
          autoFocus
        />
      </InputWrapper>
      <ButtonWrapper>
        <Button onClick={handleReset} size='small' type='link'>
          Reset
        </Button>
        <StyledFilterButton type='primary' onClick={handleSearch} size='small'>
          Search
        </StyledFilterButton>
      </ButtonWrapper>
    </div>
  );
}

import {ERoomType} from 'types/dto/ERoomType.type';
import {EDeskSpaceName} from 'types/venue';

export const getNameByType = (type: ERoomType) => {
  switch (type) {
    case ERoomType.SHARED_SPACE:
      return EDeskSpaceName.SHARED_SPACE;
    case ERoomType.OPEN_SPACE:
      return EDeskSpaceName.OPEN_SPACE;
    default:
      return '';
  }
};

export const getTypeByName = (name: EDeskSpaceName) => {
  switch (name) {
    case EDeskSpaceName.SHARED_SPACE:
      return ERoomType.SHARED_SPACE;
    case EDeskSpaceName.OPEN_SPACE:
      return ERoomType.OPEN_SPACE;
    default:
      return '';
  }
};

export const getPopoverTypeConfig = (type: ERoomType) => {
  switch (type) {
    case ERoomType.SHARED_SPACE:
      return {
        header: EDeskSpaceName.SHARED_SPACE,
        body: `This space type is a typical co-working area, with different people sharing one office. People can work 
        here comfortably, and collaborate with friends, colleagues, and people they just met for extended periods of time.`,
      };
    case ERoomType.OPEN_SPACE:
      return {
        header: EDeskSpaceName.OPEN_SPACE,
        body: `This space has a lounge character, similar to our open-shared spaces, but with
         a greater variety of seating types. This allows you to work and collaborate comfortably.`,
      };
    default:
      return {};
  }
};

import React from 'react';

import NW2Button from 'view/components/NW2Button';
import {useAppSelector} from 'store/hooks';

import {FormActions} from './Space.styles';

interface IProps {
  noTopOffset?: boolean;
  alignRight?: boolean;
  saveButtonLabel?: string;
  isSaveButtonDisabled?: boolean;
  onSaveHandler: () => void;
  onCancelHandler: () => void;
}

const ActionButtons = ({
  noTopOffset,
  alignRight,
  onSaveHandler,
  onCancelHandler,
  saveButtonLabel,
  isSaveButtonDisabled,
}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const buttonSize = isMobile ? 'medium' : 'small';

  return (
    <FormActions alignRight={alignRight} noTopOffset={noTopOffset}>
      <NW2Button
        size={buttonSize}
        buttonType='secondary'
        onClick={onCancelHandler}
      >
        Cancel
      </NW2Button>
      <NW2Button
        size={buttonSize}
        buttonType='primary'
        onClick={onSaveHandler}
        disabled={isSaveButtonDisabled}
      >
        {saveButtonLabel ? saveButtonLabel : 'Save changes'}
      </NW2Button>
    </FormActions>
  );
};

export default ActionButtons;

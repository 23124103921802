import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {VENUE_LIST_LIMIT} from 'constants/app';
import {Routes} from 'constants/routes';
import {fontWeightExtraBold} from 'constants/styleVars';
import {checkInTime, checkOutTime} from 'constants/defaultOperationalTimes';
import {ERoomType} from 'types/dto/ERoomType.type';
import {objectToQueryString} from 'utils/queryUtils';
import DateUtils from 'utils/dateUtils';
import {StyledNavLink} from 'view/common/NW2Footer/NW2Footer.styles';
import {TVenuesQuerySearchData} from 'types/search';

enum EPopularLocations {
  BERLIN = 'Berlin',
  FRANKFURT_AM_MAIN = 'Frankfurt am Main',
  COLOGNE = 'Cologne',
  HAMBURG = 'Hamburg',
  DUSSELDORF = 'Düsseldorf',
  MUNCHEN = 'München',
}

type IPopularListData = {
  [key in EPopularLocations]: TVenuesQuerySearchData & {
    radius: number;
  };
};

const commonPopularListData = {
  startDate: encodeURIComponent(
    DateUtils.normalizeDateToBackendFormat(checkInTime),
  ),
  endDate: encodeURIComponent(
    DateUtils.normalizeDateToBackendFormat(checkOutTime),
  ),
  limit: VENUE_LIST_LIMIT,
  meetingRoomCapacity: 1,
  page: 0,
  offerPage: 0,
  roomType: ERoomType.MEETING_ROOM,
  radius: 0,
  multiRooms: false,
};

const popularListData: IPopularListData = {
  [EPopularLocations.BERLIN]: {
    searchString: encodeURIComponent('Berlin, Germany'),
    latitude: '52.52000659999999',
    longitude: '13.404954',
    ...commonPopularListData,
  },
  [EPopularLocations.FRANKFURT_AM_MAIN]: {
    searchString: encodeURIComponent('Frankfurt am Main, Germany'),
    latitude: '50.1109221',
    longitude: '8.6821267',
    ...commonPopularListData,
  },
  [EPopularLocations.COLOGNE]: {
    searchString: encodeURIComponent('Cologne, Germany'),
    latitude: '50.937531',
    longitude: '6.9602786',
    ...commonPopularListData,
  },
  [EPopularLocations.HAMBURG]: {
    searchString: encodeURIComponent('Hamburg, Germany'),
    latitude: '53.5510846',
    longitude: '9.9936818',
    ...commonPopularListData,
  },
  [EPopularLocations.DUSSELDORF]: {
    searchString: encodeURIComponent('Düsseldorf, Germany'),
    latitude: '51.2277411',
    longitude: '6.7734556',
    ...commonPopularListData,
  },
  [EPopularLocations.MUNCHEN]: {
    searchString: encodeURIComponent('München, Germany'),
    latitude: '48.1351253',
    longitude: '11.5819806',
    ...commonPopularListData,
  },
};

export function usePopularLocations() {
  const navigate = useNavigate();

  const popularLocations = useMemo(() => {
    const onGoPopularLocation = (name: EPopularLocations) => () => {
      const queryParams = objectToQueryString(popularListData[name]);

      navigate(`${Routes.venuesList}?${queryParams}`);
    };

    return Object.values(EPopularLocations).map((name) => ({
      value: (
        <StyledNavLink
          as='a'
          fontWeight={fontWeightExtraBold}
          onClick={onGoPopularLocation(name)}
        >
          {name}
        </StyledNavLink>
      ),
    }));
  }, [navigate]);

  return {popularLocations};
}

import {IDay} from 'types/dto/IDay.type';
import {DEFAULT_OPEN_FROM, DEFAULT_OPEN_TO} from 'constants/NW2OpenHours';
import {getInitially24HoursAccess} from '../components/helpers';

const useInitialState = (operationalTimes: IDay[]) => {
  const activeWorkDays = operationalTimes.filter((day) => day.active);
  const firstActiveWorkDay = activeWorkDays[0];
  const {timeStartHour, timeStartMinutes, timeEndHour, timeEndMinutes} =
    firstActiveWorkDay || {};

  const initiallySameEveryday = activeWorkDays.every(
    (day) =>
      day.timeEndHour === timeEndHour &&
      day.timeEndMinutes === timeEndMinutes &&
      day.timeStartHour === timeStartHour &&
      day.timeStartMinutes === timeStartMinutes,
  );

  const initial24HoursAccess =
    initiallySameEveryday &&
    getInitially24HoursAccess({
      timeStartHour,
      timeStartMinutes,
      timeEndHour,
      timeEndMinutes,
    });

  const hasInitialTime = !!(initiallySameEveryday && firstActiveWorkDay);

  const initialOpenFrom = hasInitialTime
    ? `${firstActiveWorkDay.timeStartHour}:${
        firstActiveWorkDay.timeStartMinutes == '0'
          ? '00'
          : firstActiveWorkDay.timeStartMinutes
      }`
    : DEFAULT_OPEN_FROM;
  const initialOpenTo = hasInitialTime
    ? `${firstActiveWorkDay.timeEndHour}:${
        firstActiveWorkDay.timeEndMinutes == '0'
          ? '00'
          : firstActiveWorkDay.timeEndMinutes
      }`
    : DEFAULT_OPEN_TO;

  return {
    initiallySameEveryday,
    initial24HoursAccess,
    initialOpenFrom,
    initialOpenTo,
  };
};

export default useInitialState;

import React from 'react';

import NW2FormItemRadio from 'view/components/NW2FormItem/components/NW2FormItemRadio';

import {EBookAbility} from 'types/venue';
import {bookabilityLabels} from '../../../../types';
import {ESpaceDetailsContentSections} from '../../types';
import {ERadioSizes} from 'view/components/NW2FormItem/components/NW2FormItemRadio/types';
import {RadioWrapper} from '../../NMMSpacesDetails.styles';

export const BookabilityTypeFormItems = () => (
  <RadioWrapper>
    {Object.values(EBookAbility).map((type) => {
      const label = bookabilityLabels[type];

      return (
        <NW2FormItemRadio
          value={type}
          key={type}
          id={type}
          label={label}
          name={ESpaceDetailsContentSections.BOOKABILITY_TYPE}
          size={ERadioSizes.MEDIUM}
        />
      );
    })}
  </RadioWrapper>
);

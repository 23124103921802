import React, {useState} from 'react';
import {DateTime} from 'luxon';
import {ReactDatePickerProps} from 'react-datepicker';
import {useField} from 'react-final-form';

import {DatePickerCustomInput, DesktopDatePickerHeader} from './components';
import {TDateType} from 'types/dto/ISearch.types';

import {StyledNW2DatePicker} from './DesktopDatePicker.styles';
import {DateFormats} from 'utils/dateUtils';

type TProps = Omit<ReactDatePickerProps, 'onChange'> & {
  inputName?: string;
  inputLabel?: string;
  dateFormat?: string;
  date?: TDateType;
  disabled?: boolean;
  isRequired?: boolean;
};

function DesktopDatePicker({
  inputName = 'date_picker',
  inputLabel = 'Date of birth',
  date,
  dateFormat = DateFormats['31.12.2000'],
  disabled,
  minDate,
  maxDate,
  isRequired = false,
  ...props
}: TProps) {
  const [selected, setSelected] = useState<TDateType>(null);
  const minDateValue = '1900-01-01';
  const maxDateValue = '2099-12-31';
  const defaultOnChange = (date: TDateType) => {
    setSelected(date);
  };

  const initialInputValue = useField(inputName);
  const formatDateInputValue = DateTime.fromFormat(
    initialInputValue.input.value,
    dateFormat,
  );
  const initialDateFromInput = formatDateInputValue.isValid
    ? formatDateInputValue.toJSDate()
    : new Date();

  const config = {
    date: date || selected || initialDateFromInput,
    dateFormat,
    minDate: minDate || new Date(minDateValue),
    maxDate: maxDate || new Date(maxDateValue),
    customInput: (
      <DatePickerCustomInput
        inputName={inputName}
        label={inputLabel}
        type='text'
        dateFormat={dateFormat}
        inputDisabled={disabled}
        isRequired={isRequired}
      />
    ),
    inline: false,
    onChange: defaultOnChange,
    ...props,
  };

  return (
    <StyledNW2DatePicker
      {...config}
      renderCustomHeader={(props) => (
        <DesktopDatePickerHeader
          minDate={config.minDate}
          maxDate={config.maxDate}
          {...props}
        />
      )}
    />
  );
}

export default DesktopDatePicker;

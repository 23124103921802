import React from 'react';

import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import {IBooking} from 'types/dto/IBooking.types';

export function TotalColumn(totalPrice: number, row: IBooking, index: number) {
  return (
    <div key={index}>
      <b>
        <PriceDisplay price={totalPrice} currency={row.currency} />
      </b>
    </div>
  );
}

import styled, {css} from 'styled-components';

import {
  borderRadiusLg,
  cardBoxShadow,
  mdBp,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  whiteColor,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  position: relative;
  padding: ${offsetDef} 0 0;
  width: 100%;

  @media (min-width: ${mdBp}px) {
    width: 72px;
    padding: 0 0 0 ${offsetXLg};
  }
`;

export const DropdownBox = styled.div<{isHidden?: boolean}>`
  position: absolute;
  top: calc(100% + ${offsetXLg});
  left: 0;
  width: 400px;
  background-color: ${whiteColor};
  padding: ${offsetXLg};
  box-shadow: ${cardBoxShadow};
  border-radius: ${borderRadiusLg};
  z-index: 2;

  ${({isHidden}) =>
    isHidden &&
    css`
      display: none;
      pointer-events: none;
    `}

  div > label {
    display: flex;
  }
`;

export const DrawerHeader = styled.div`
  border-bottom: 1px solid ${NW2Gray200Color};
  margin: 0 -${offsetXLg} ${offsetXLg};
  padding: 0 ${offsetXLg} ${offsetDef};
`;

export const DrawerContent = styled.div``;

export const DrawerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${offsetDef};
  border-top: 1px solid ${NW2Gray200Color};
  margin: ${offsetDef} -${offsetXLg} 0;
  padding: 0 ${offsetXLg};

  div,
  button {
    width: 100%;
  }
`;

import React, {ChangeEvent, useState} from 'react';
import styled from 'styled-components';

import {fontSizeXXSm, lineHeightXSm} from 'constants/styleVars';
import NW2FormTextarea from '../NW2FormItem/components/Textarea';
import {IProps} from '../NW2FormItem/components/Textarea/Textarea.types';

export const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
`;

export const Counter = styled.span`
  justify-self: end;
`;

interface ITextAreaCounted extends IProps {
  description: string;
  maxCounter: number;
}

export const TextAreaCounted = ({
  description,
  maxCounter,
  ...props
}: ITextAreaCounted) => {
  const [counter, setCounter] = useState(0);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCounter(event.target.value.length);
  };

  return (
    <div>
      <NW2FormTextarea onChange={onChange} {...props} />
      <DescriptionWrapper>
        {description && <span>{description}</span>}
        {<Counter>{`${counter}/${maxCounter}`}</Counter>}
      </DescriptionWrapper>
    </div>
  );
};

import React from 'react';
import styled from 'styled-components';

import {borderRadiusDef, mdBp, lgBp, offsetXXXLg} from 'constants/styleVars';

type TProps = {
  src: string;
  position?: string;
};

const StyledImg = styled.img<{position?: string}>`
  width: 100%;
  object-fit: cover;
  border-radius: ${borderRadiusDef};
  height: 200px;
  margin-bottom: ${offsetXXXLg};

  object-position: ${({position}) => position || 'unset'};

  @media (min-width: ${mdBp}px) {
    height: 250px;
  }

  @media (min-width: ${lgBp}px) {
    height: 200px;
  }
`;

const InfoPageImage = ({src, position}: TProps) => (
  <StyledImg src={src} position={position} />
);

export default InfoPageImage;

import isFQDN from 'validator/lib/isFQDN'; // check if the string is a fully qualified domain name (e.g. domain.com).

import {isWebUrlValid} from '../infra/common/venueValidation';
import {EMAIL_DOMAINS_FIELD_NAME} from 'constants/admin';

export const validateWebUrl = (rule: any, value: string) => {
  if (!!value && !isWebUrlValid(value)) {
    return Promise.reject('Not valid web URL!');
  } else {
    return Promise.resolve();
  }
};

const validateEmailDomains = (emailDomains: string[], value: string) => {
  const isNewItem = emailDomains[emailDomains.length - 1] === value;
  const trimmedEmailDomains = emailDomains.map((domain) =>
    domain.trim().toLowerCase(),
  );
  const trimmedValue = value.trim().toLowerCase();
  return isNewItem
    ? trimmedEmailDomains.slice(0, -1).includes(trimmedValue)
    : trimmedEmailDomains
        .filter((item) => item !== trimmedValue)
        .includes(trimmedValue);
};

export const validateDomain = (form: any) => (rule: any, value: string) => {
  const emailDomains = form.getFieldValue(EMAIL_DOMAINS_FIELD_NAME);
  if (!!value && !isFQDN(value)) return Promise.reject('Not valid domain!');
  if (validateEmailDomains(emailDomains, value))
    return Promise.reject('Domain is not unique!');

  return Promise.resolve();
};

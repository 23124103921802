import {SyntheticEvent} from 'react';

import {
  fontSizeMd,
  fontSizeSm,
  fontSizeXSm,
  lineHeightLg,
  lineHeightMd,
  lineHeightSm,
} from 'constants/styleVars';

export interface IControlProps {
  isActive: boolean;
  disabled?: boolean;
  size: ESwitchSizes;
}

export enum ESwitchSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

type TSize = keyof typeof ESwitchSizes;

export type TSwitchSize = {
  [key in ESwitchSizes]: {
    width: string;
    height: string;
    controlSize: string;
    labelFontSize: string;
    labelLineHeight: string;
  };
};

export type TSchemaType = TSwitchSize[TSize];

export const switchSizeSchema: TSwitchSize = Object.freeze({
  [ESwitchSizes.SMALL]: {
    width: '40px',
    height: '20px',
    controlSize: '16px',
    labelFontSize: fontSizeXSm,
    labelLineHeight: lineHeightSm,
  },
  [ESwitchSizes.MEDIUM]: {
    width: '42px',
    height: '22px',
    controlSize: '18px',
    labelFontSize: fontSizeSm,
    labelLineHeight: lineHeightMd,
  },
  [ESwitchSizes.LARGE]: {
    width: '46px',
    height: '26px',
    controlSize: '22px',
    labelFontSize: fontSizeMd,
    labelLineHeight: lineHeightLg,
  },
});

export type TLabelPosition = 'right' | 'left';
export interface IProps {
  isActive: boolean;
  size?: ESwitchSizes;
  label?: string;
  disabled?: boolean;
  onClick: (e?: SyntheticEvent) => void;
  labelPosition?: TLabelPosition;
  className?: string;
}

import React, {ChangeEvent, FC, useEffect, useMemo} from 'react';
import {Form, FormInstance, Input, Select} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';

import GoogleMap from '../../../../components/GoogleMap/GoogleMap';
import TextValue from 'view/components/TextValue';
import PointsOfInterest from './PointsOfInterest';
import venueTexts from 'texts/venueTexts';
import useCountryList from 'hooks/useCountriesList';
import {getFilteredOption} from 'view/venue/CreateVenue/helpers';

import {SubPageTitle} from 'view/components/Typography';

import {defaultAntdFormLayout} from 'constants/app';
import {
  buildingFieldRules,
  cityFieldRules,
  countryFieldRules,
  floorsFieldRules,
  postalCodeFieldRules,
  streetFieldRules,
} from 'utils/antdFormFieldRules';
import {offsetDef} from 'constants/styleVars';
import {ILocation} from 'types/dto/ILocation.types';
import {setVenueFields} from 'store/venue/actions';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {
  floorLevels,
  DEFAULT_FLOOR_STOREY_COUNTRIES,
} from 'constants/floorStorey';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IPointOfInterest} from 'types/venue';

const {Item} = Form;

type TProps = {
  location: ILocation;
  form: FormInstance;
  accommodationType: EAccommodationType;
};

export const FormIntroText = styled(TextValue)`
  margin-bottom: ${offsetDef};
`;

const PATH_PREFIX = 'location.';

const AddressForm: FC<TProps> = ({location, form, accommodationType}) => {
  const dispatch = useDispatch();
  const {countriesList} = useCountryList();
  const isExternalVenue = accommodationType === EAccommodationType.VENUE;
  const pointsOfInterest: IPointOfInterest[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.pointsOfInterest`),
  );

  useEffect(() => {
    const sortedFloors = location.floors?.sort((a, b) => a - b);

    // predefined poi fields for hmd registration flow
    const poiFields =
      pointsOfInterest?.map(({type, name}) => ({
        [type]: name,
      })) || [];

    form.setFieldsValue({
      ...location,
      floors: sortedFloors,
      ...Object.assign({}, ...poiFields),
    });
  }, [form, location, pointsOfInterest]);

  const storeyFloors = useMemo(() => {
    const floorList = Object.keys(floorLevels);

    return DEFAULT_FLOOR_STOREY_COUNTRIES.includes(location.country) ||
      !location.country
      ? floorList
      : floorList.filter((level) => level !== 'Ground Floor');
  }, [location.country]);

  const handleFormFields =
    (path: string) => (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setVenueFields({
          path: `${PATH_PREFIX}${path}`,
          value: event.target.value,
        }),
      );
    };

  const handleValueFormFields =
    (path: string) => (value: string | number | null) => {
      if (path === 'country' && value !== location.country) {
        dispatch(setVenueFields({path: `${PATH_PREFIX}floors`, value: []}));
      }

      if (value !== null)
        dispatch(setVenueFields({path: `${PATH_PREFIX}${path}`, value}));
    };

  // this function is meant to keep leading zeros
  const handleCustomNumericFormFields =
    (path: string) => (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setVenueFields({
          path: `${PATH_PREFIX}${path}`,
          value: event.target.value,
        }),
      );
    };

  const isLetter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/^[a-zA-Z ]+$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onGoogleMapLocationChange = (newLocation: ILocation) => {
    const updatedLocation = {
      ...location,
      ...newLocation,
    };
    dispatch(
      setVenueFields({
        path: 'location',
        value: updatedLocation,
      }),
    );

    if (location.country !== newLocation.country) {
      dispatch(setVenueFields({path: `${PATH_PREFIX}floors`, value: []}));
    }
  };

  const Option = Select.Option;

  return (
    <>
      <SubPageTitle>{`${venueTexts[accommodationType].venueTypeCapitalized} address`}</SubPageTitle>

      <GoogleMap
        onLocationChange={onGoogleMapLocationChange}
        location={location}
        showMapHint={true}
        searchHintText="If your venue doesn't appear in suggestions, please type your street address in instead."
        searchPlaceholder='Type in your full address'
      />

      <FormIntroText>
        Now confirm and complete any missing details in the location information
        below:
      </FormIntroText>

      <Form {...defaultAntdFormLayout} form={form}>
        <Item name='country' label='Country' rules={countryFieldRules}>
          <Select
            showSearch
            onChange={handleValueFormFields('country')}
            placeholder='Country'
            filterOption={getFilteredOption}
          >
            {countriesList.map(({countryCode, country}) => {
              return (
                <Select.Option key={countryCode} value={country}>
                  {country}
                </Select.Option>
              );
            })}
          </Select>
        </Item>

        <Item name='city' label='City' rules={cityFieldRules}>
          <Input
            name='city'
            allowClear
            placeholder='City'
            onChange={handleFormFields('city')}
            onKeyPress={isLetter}
          />
        </Item>

        <Item name='streetName' label='Street name' rules={streetFieldRules}>
          <Input
            name='streetName'
            allowClear
            placeholder='Street name'
            onChange={handleFormFields('streetName')}
          />
        </Item>

        <Item
          name='buildingNumber'
          label='Building no.'
          rules={buildingFieldRules}
        >
          <Input
            name='buildingNumber'
            allowClear
            placeholder='Building no.'
            onChange={handleFormFields('buildingNumber')}
          />
        </Item>

        <Item
          name='postalCode'
          label='Postal code'
          rules={postalCodeFieldRules}
        >
          <Input
            name='postalCode'
            placeholder='Postal code'
            onChange={handleCustomNumericFormFields('postalCode')}
          />
        </Item>

        <FormIntroText>
          Specify floors of the building dedicated to workspace offerings:
        </FormIntroText>

        <Item
          name='floors'
          label='Specify floor(s)'
          rules={floorsFieldRules}
          required
        >
          <Select mode='multiple' onChange={handleValueFormFields('floors')}>
            {storeyFloors.map((key) => (
              <Option key={key} value={floorLevels[key]}>
                <span>{key}</span>
              </Option>
            ))}
          </Select>
        </Item>

        {isExternalVenue && <PointsOfInterest />}
      </Form>
    </>
  );
};

export default AddressForm;

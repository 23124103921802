import {useEffect} from 'react';

import {ICustomerBillingAddress, IUser} from 'types/dto/IUser.types';
import {IOrderDay} from 'types/dto/IBooking.types';
// import {FormApi} from 'final-form';
// import {IEditFormState} from 'view/venue/NW2BookingEdit/helpers';
import {useForm} from 'react-final-form';

const defaultBillingAddressValues = {
  companyName: '',
  costCenter: '',
  country: '',
  city: '',
  postCode: '',
  streetHouseNumber: '',
  customerId: 0,
  additionalReference: undefined,
  uuid: '',
};

type TProps = {
  orderDays: IOrderDay[];
  billingAddress?: Partial<ICustomerBillingAddress>;
  user: Partial<IUser & {aboutMeeting: string | null}>;
};

// should be used only in react final form
export default function useFormBatchChangeBookingState({
  orderDays,
  billingAddress = defaultBillingAddressValues,
  user,
}: TProps) {
  const form = useForm();

  useEffect(() => {
    form.initialize({orderDays, ...user, ...billingAddress});
  }, [form, user, billingAddress, orderDays]);
}

import {SupplierHeaderItems, TMenuReturnType} from '../types';
import {Routes} from '../../../../constants/routes';
import {TdropdownItem} from '../../../components/NW2MenuItem/types';
import {TNmmNavMenuList} from '../../../../types/header';
import {EResourcesHashes} from '../../../venue/NW2VenueProfile/components/NMMResources/types';

const getAgentMenuDropdownItems = (): TdropdownItem[] => {
  return [
    {
      id: SupplierHeaderItems.AgentView,
      title: 'Agent View',
      link: Routes.agentView,
      isExternal: false,
    },
    {
      id: SupplierHeaderItems.LogOut,
      title: 'Log out',
      link: Routes.login,
      isExternal: false,
    },
  ];
};

const userMenuList = (): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.Profile,
    text: 'Profile',
    isVisible: true,
    dropDownItems: getAgentMenuDropdownItems(),
    icon: 'USER',
  },
];

const supplierMasterDataMenu = ({
  venueId,
  venueName,
}: {
  venueId: string;
  venueName?: string;
}): TMenuReturnType => {
  const userMenuDropDownItems = [
    {
      id: SupplierHeaderItems.MeetingRooms,
      title: 'Meeting rooms',
      link: `/agent/venue/${venueId}/spaces?id=${venueId}#room`,
      isExternal: false,
    },
    {
      id: SupplierHeaderItems.WorkDesks,
      title: 'Workdesks',
      link: `/agent/venue/${venueId}/spaces?id=${venueId}#desk`,
      isExternal: false,
    },
  ];

  const userMenuList = [
    {
      id: SupplierHeaderItems.BookingMeetingRooms,
      url: `/agent/venue/${venueId}/bookings`,
      text: 'Bookings',
      isVisible: true,
      icon: 'SEARCH_MEETING_ROOM',
    },
    {
      id: SupplierHeaderItems.Spaces,
      text: 'Spaces',
      isVisible: true,
      dropDownItems: userMenuDropDownItems,
      icon: 'NW2_SPACES',
    },
    {
      id: SupplierHeaderItems.Availability,
      url: `/agent/venue/${venueId}/availability`,
      text: 'Availability',
      isVisible: true,
      icon: 'NW2_CALENDAR',
    },
    {
      id: SupplierHeaderItems.Services,
      url: `/agent/venue/${venueId}/services?id=${venueId}#${EResourcesHashes.EQUIPMENT}`,
      text: 'Services',
      isVisible: true,
      icon: 'NW2_SERVICES',
    },
  ];

  const selectLocationButtonList = [
    {
      id: SupplierHeaderItems.LocationButton,
      text: `${venueName}`,
      isVisible: true,
      dropDownItems: [
        {
          id: SupplierHeaderItems.VenueDetails,
          title: 'Venue details',
          link: `/agent/venue/${venueId}/profile`,
          isExternal: false,
        },
      ],
      icon: 'NW2_LOCATION_OUTLINE',
    },
  ];

  return {
    layout: {
      defaultLayoutType: {
        type: 'CustomerLayout',
        components: [
          {component: 'isLogo'},
          {component: 'isSelectLocationButton'},
          {component: 'isSignOutButton'},
        ],
      },
      customRoutesLayout: {},
    },
    selectLocationButton: selectLocationButtonList,
    userMenuList,
  };
};

const basicAgentMenu: TMenuReturnType = {
  layout: {
    defaultLayoutType: {
      type: 'CustomerLayout',
      components: [{component: 'isLogo'}, {component: 'isSignOutButton'}],
    },
    customRoutesLayout: {},
  },
  userMenuList: userMenuList(),
};

export const agentMenu = ({
  venueId,
  venueName,
}: {
  venueId: string;
  venueName?: string;
}): TMenuReturnType => {
  return location.pathname.startsWith('/agent/venue/')
    ? supplierMasterDataMenu({venueId, venueName})
    : basicAgentMenu;
};

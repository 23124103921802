import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button';
import ExtraItem from './ExtraItem';

import {IExtrasResponse} from 'types/dto/IExtras.type';
import {getFoodAndBeverageQuantity} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/helpers';
import {offsetDef} from 'styles/configs/offset';
import {StyledExtrasList} from '../Space.styles';
import {ISummaryExtra} from 'types/dto/IBooking.types';
import {IOfferUnitExtra} from 'types/offer';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import useSortedExtras from 'view/venue//hooks/useSortedExtras';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import useToggle from 'hooks/useToggle';

type TProps = {
  extras: IExtrasResponse[];
  chosenExtras?: (ISummaryExtra | IOfferUnitExtra)[];
  initMaxToShow: number;
  dayId: number;
  unitId?: number;
  isFoodAndBeverage?: boolean;
  participants?: number;
  duration?: number;
  isPreview?: boolean;
  isPriceHidden?: boolean;
  checkInDate?: string;
  checkOutDate?: string;
  isMeetingRoom?: boolean;
};

export const StyledButton = styled(NW2Button)`
  margin-top: ${offsetDef};
`;

const ExtrasList = ({
  extras,
  chosenExtras = [],
  initMaxToShow,
  dayId,
  unitId = 0,
  isFoodAndBeverage = false,
  participants = 1,
  duration = 0,
  isPreview,
  isPriceHidden,
  checkInDate,
  checkOutDate,
  isMeetingRoom = true,
}: TProps) => {
  const [isShowMore, toggleShowMore] = useToggle(false);
  const [allSortedExtras, setAllSortedExtras] = useState<IExtrasResponse[]>([]);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const venueCurrency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const offerVenueCurrency: string = useSelector((state) =>
    _get(state, 'offers.offerDetails.venue.currency'),
  );

  const {venueDetails} = useVenueDetailsData();

  const {sortedExtras, chosenExtrasQuantity} = useSortedExtras(
    extras,
    chosenExtras,
  );

  const offerExtras = chosenExtras.filter((extra) =>
    extras?.some(({code}) => code === extra.code),
  );

  //we set initial checked extras only once on first render
  useEffect(() => {
    setAllSortedExtras(sortedExtras);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxToShow =
    initMaxToShow >= chosenExtrasQuantity
      ? initMaxToShow
      : chosenExtrasQuantity;

  const isMoreMax = allSortedExtras.length > maxToShow;

  const extrasToShow =
    isMoreMax && !isShowMore
      ? allSortedExtras.slice(0, maxToShow)
      : allSortedExtras;

  return (
    <StyledExtrasList isPreview={isPreview}>
      {(isPreview ? offerExtras : extrasToShow)?.map((extra) => {
        const chosenExtra = chosenExtras?.find(
          ({code}) => extra.code === code,
        ) as ISummaryExtra;

        const previewExtra = {...extra, name: extra.code};

        const foodAndBeverageQuantity = getFoodAndBeverageQuantity({
          extra: (isPreview ? previewExtra : extra) as IExtrasResponse,
          duration,
          participants,
        });

        const chosenQuantity = chosenExtra
          ? chosenExtra?.chosenQuantity || chosenExtra?.quantity
          : isFoodAndBeverage
          ? foodAndBeverageQuantity
          : 1;

        return (
          <ExtraItem
            key={extra.code}
            extra={
              'quantity' in extra
                ? (extra as IExtrasResponse | IOfferUnitExtra)
                : (extra as IExtrasResponse)
            }
            operationalTimes={venueDetails.operationalTimes}
            currency={
              venueDetails.currency || venueCurrency || offerVenueCurrency
            }
            chosenQuantity={chosenQuantity}
            dayId={dayId}
            unitId={unitId}
            isPriceHidden={isPriceHidden}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            isPreview={isPreview ?? false}
            isExtraChosen={
              chosenExtra?.code === (extra as IExtrasResponse)?.code
            }
            isMeetingRoom={isMeetingRoom}
          />
        );
      })}
      {isMoreMax && !isShowMore && !isPreview && (
        <StyledButton
          size='responsiveAdaptive'
          buttonType='tertiary'
          onClick={toggleShowMore}
          fullWidth={isMobile}
        >
          Show more options
        </StyledButton>
      )}
    </StyledExtrasList>
  );
};

export default ExtrasList;

import React, {useEffect} from 'react';
import {DateTime} from 'luxon';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import NW2Button from 'view/components/NW2Button';

import {NW2DateSection} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker';

import {NW2Gray50Color} from 'constants/styleVars';
import {
  StyledActions,
  StyledDrawerContent,
  StyledDrawerContentFooter,
  StyledDrawerContentHeader,
  StyledInputContainer,
  DateInput,
} from './NW2MultiDatePickerMobile.styles';
import {TDateChangeFn} from '../NW2DatePicker.types';

interface IProps {
  startDate: string;
  isOpened: boolean;
  dirty: boolean;
  onChange: TDateChangeFn;
  onToggleDatePicker: () => void;
  onNextClick: () => void;
  onClearClick: () => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  inputValue?: string;
}

const bodyStyle = {
  padding: 0,
  backgroundColor: NW2Gray50Color,
};

export const SingleDayDatePickerMobile = ({
  startDate,
  isOpened,
  dirty,
  onChange,
  onToggleDatePicker,
  onClearClick,
  onNextClick,
  minDate,
  maxDate,
  inputValue,
}: IProps) => {
  const startAsDate = startDate ? DateTime.fromISO(startDate).toJSDate() : null;

  useEffect(() => {
    if (isOpened) {
      document.body.style.width = '100%'; // remove the padding added by antd drawer to body
    }
  }, [isOpened]);

  const closeHandler = () => {
    onToggleDatePicker();
  };

  const lastStepNextHandler = () => {
    onNextClick();
  };

  return (
    <StyledAntdDrawer
      title={null}
      onClose={closeHandler}
      open={isOpened}
      height='auto'
      bodyStyle={bodyStyle}
      padding='0'
    >
      <StyledDrawerContentHeader showDivider={false}>
        <StyledInputContainer highlighted>
          <DateInput
            type='text'
            value={inputValue}
            readOnly
            data-testid='datepicker-mobile open picker'
          />
          <label>
            <span>Date</span>
          </label>
        </StyledInputContainer>
      </StyledDrawerContentHeader>

      <StyledDrawerContent>
        <NW2DateSection
          startDate={startAsDate}
          onChange={onChange}
          isSingleDayDatePicker
          minDate={minDate}
          maxDate={maxDate}
        />
      </StyledDrawerContent>

      <StyledDrawerContentFooter showDivider={true}>
        <StyledActions>
          <NW2Button
            size='large'
            fullWidth
            onClick={onClearClick}
            data-testid='datepicker-mobile clear'
          >
            Clear
          </NW2Button>

          <NW2Button
            buttonType='primary'
            size='large'
            fullWidth
            onClick={lastStepNextHandler}
            disabled={!dirty}
            data-testid='datepicker-mobile next'
          >
            Next
          </NW2Button>
        </StyledActions>
      </StyledDrawerContentFooter>
    </StyledAntdDrawer>
  );
};

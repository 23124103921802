import {Row, Col, Select} from 'antd';
import styled from 'styled-components';

import {offsetDef, offsetXSm, offsetXXSm} from 'constants/styleVars';

export const StyledRow = styled(Row)`
  margin: ${offsetXXSm} 0 ${offsetXSm};
`;

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  margin-right: ${offsetDef};
  margin-bottom: ${offsetDef};
`;

export const StyledSelect = styled(Select)`
  width: 130px;
`;

import React, {ChangeEvent, memo, useCallback} from 'react';
import {Field, useField, useForm} from 'react-final-form';

import NW2FormItemCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox';

import {ErrorMessage, Input} from 'view/components/NW2FormItem/components';

import {ERoomSchema, ERoomSchemaNames} from 'types/venue';
import {imageObject} from './types';
import {
  Container,
  InputBlock,
  SchemaImage,
  StyledItemInputWrapper,
} from './AddRoomSchema.styles';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {roomSchemaCheckboxWithInputRules} from 'utils/finalFormFieldRules';
import {NW2Gray600Color} from 'constants/styleVars';
import {inputTextToNumber} from 'utils/helpers';

interface ISchemaItemProps {
  hasError?: boolean;
  disabled: boolean;
  name: ERoomSchemaNames;
  fieldName: string;
  rules?: (value: number, allValues: any) => string;
  isNoCheckbox?: boolean;
  width?: string;
}

export const RoomSchemaItem = memo(
  ({
    name,
    hasError,
    disabled,
    fieldName,
    rules,
    isNoCheckbox,
    width,
  }: ISchemaItemProps) => {
    const {mutators} = useForm();

    const itemName = `${fieldName}.${name}`;

    const itemCapacity = `${itemName}.capacity`;

    const {meta} = useField(itemCapacity);

    const label = ERoomSchema[name];
    const image = imageObject[name];

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        inputTextToNumber(
          value,
          () => mutators.setValue(itemCapacity, Number(value)),
          true,
        );
      },
      [itemCapacity, mutators],
    );

    return (
      <div>
        <Container width={width}>
          <InputBlock>
            {isNoCheckbox ? (
              label
            ) : (
              <NW2FormItemCheckbox
                name={`${itemName}.checkbox`}
                label={label}
                id={name}
                size={ECheckboxSizes.MEDIUM}
                data-testid={`checkbox-room-schema-${name}`}
                highlightAsError={hasError}
              />
            )}

            <Field
              name={itemCapacity}
              validate={
                rules || roomSchemaCheckboxWithInputRules(name, fieldName)
              }
            >
              {({input, meta}) => (
                <StyledItemInputWrapper>
                  <Input
                    name={itemCapacity}
                    type='text'
                    label='Participants'
                    labelColor={NW2Gray600Color}
                    placeholder='Participants'
                    hasError={meta.error && meta.touched}
                    value={input.value}
                    onChange={handleChange}
                    onBlur={input.onBlur}
                    disabled={disabled}
                    variant='primary'
                    inputSize='small'
                    inputProps={{...input, disabled}}
                    data-testid={`text-input-room-schema-${name}`}
                  />
                </StyledItemInputWrapper>
              )}
            </Field>
          </InputBlock>
          <SchemaImage src={image} />
        </Container>

        <ErrorMessage
          hasError={meta.error && meta.touched}
          errorData={meta.error}
          showAllValidationErrors={false}
          variant='primary'
        />
      </div>
    );
  },
);

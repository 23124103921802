import styled, {css} from 'styled-components';

import Input from 'view/components/NW2FormItem/components/Input/Input';
import Icon from 'view/components/Icon';

import {
  blackColor,
  borderRadiusDef,
  cardBoxShadow,
  fontSizeSm,
  fontSizeXSm,
  lineHeightMd,
  lineHeightSm,
  mdBp,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Primary,
  offsetDef,
  offsetSm,
  offsetXXXLg,
  whiteColor,
} from 'constants/styleVars';

export const Dropdown = styled.div`
  cursor: default;
  box-shadow: ${cardBoxShadow};
  border-radius: ${borderRadiusDef};

  @media (min-width: ${mdBp}px) {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 6px);
    z-index: 31;
  }
`;

export const SearchBox = styled.div`
  position: relative;
  color: ${blackColor};

  input {
    border: 0;

    &:focus {
      outline: 0;
    }
  }
`;

export const Options = styled.ul`
  background-color: ${whiteColor};
  margin: 0;
  padding: 0;
  border-radius: 0 0 ${borderRadiusDef} ${borderRadiusDef};
  max-height: 462px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
`;

export const Option = styled.li<{isSelected?: boolean}>`
  display: flex;
  align-items: center;
  gap: ${offsetSm};
  padding: ${offsetDef} ${offsetSm};
  transition: background-color 0.15s;
  cursor: ${({isSelected}) => (isSelected ? 'default' : 'pointer')};
  border-top: 1px solid ${NW2Gray200Color};

  &:hover {
    background-color: ${NW2Primary};
  }

  ${({isSelected}) =>
    isSelected &&
    css`
      background-color: ${NW2Primary};
      padding-left: ${offsetDef};
    `}
`;

export const OptionText = styled.span`
  display: block;
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${blackColor};
`;

export const OptionSubText = styled.span`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
`;

export const StyledInput = styled(Input)`
  padding-right: ${offsetXXXLg};
`;

export const ResetIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${offsetDef};
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;

  svg {
    pointer-events: none;
  }
`;

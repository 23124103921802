import {useMemo} from 'react';
import _uniqBy from 'lodash/uniqBy';
import {IExtrasResponse} from 'types/dto/IExtras.type';
import {ISummaryExtra} from 'types/dto/IBooking.types';
import {IOfferUnitExtra} from 'types/offer';

const useSortedExtras = (
  extras: IExtrasResponse[],
  chosenExtras: (ISummaryExtra | IOfferUnitExtra)[],
) => {
  return useMemo(() => {
    const findChosenExtras = (extrasList: IExtrasResponse[]) =>
      extrasList.filter((extra) =>
        chosenExtras.some(
          (chosen) =>
            chosen.accommodationExtraId === extra.accommodationExtraId,
        ),
      );
    const freeExtras = extras.filter((extra) => extra.price === 0);
    const paidExtras = extras
      .filter((extra) => extra.price !== 0)
      .sort((a, b) => {
        // If prices are equal, sort by name (in alphabetical order)
        if (a.price === b.price) {
          return a.name.localeCompare(b.name);
        }
        return b.price - a.price;
      });

    const chosenFreeExtras = findChosenExtras(freeExtras);
    const chosenPaidExtras = findChosenExtras(paidExtras);

    const freeExtrasList = _uniqBy(
      [...chosenFreeExtras, ...freeExtras],
      'accommodationExtraId',
    );

    const paidExtrasList = _uniqBy(
      [...chosenPaidExtras, ...paidExtras],
      'accommodationExtraId',
    );

    const sortedExtras = [...freeExtrasList, ...paidExtrasList];
    const chosenExtrasQuantity = [...freeExtrasList, ...chosenPaidExtras]
      .length;

    return {
      sortedExtras,
      chosenExtrasQuantity,
    };
  }, [extras, chosenExtras]);
};

export default useSortedExtras;

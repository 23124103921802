import {TContainer} from 'app';
import {AppDispatch, RootState} from 'store/types';
import {IMultiDayPublicVenue} from 'types/dto/IPublicVenue';
import {IMultiDayVenueDetailsRequestData} from '../venues/types';
import {IDay} from 'types/dto/IDay.type';
import {
  setVenueDetails,
  setVenueDetailsError,
  setVenueDetailsLoading,
  setOperationalTimesError,
  setOperationalTimesRequest,
  setOperationalTimesSuccess,
} from './venueDetailsSlice';

export const getVenueDetails =
  ({
    payload,
    isNoLoading,
    onSuccessCallback,
    onErrorCallback,
  }: {
    payload: IMultiDayVenueDetailsRequestData;
    isNoLoading?: boolean;
    onSuccessCallback?: () => void;
    onErrorCallback?: (error: Error) => void;
  }) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    if (!isNoLoading) {
      /**
       * uses for case when we are setting selected unitId for recalculate totalPrice reasons
       */
      dispatch(setVenueDetailsLoading(true));
    }

    venuesContainer.getMultiDayVenueDetails({
      payload,
      onSuccess: (response: IMultiDayPublicVenue) => {
        dispatch(setVenueDetails(response));
        onSuccessCallback?.();
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode ||
          error?.message ||
          'Failed to fetch venue data!';
        dispatch(setVenueDetailsError(errorMessage));
        onErrorCallback?.(error);
      },
    });
  };

export const getOperationalTimes =
  (payload: string | number) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(setOperationalTimesRequest());

    venuesContainer.getOperationalTimes({
      payload,
      onSuccess: (response: IDay[]) => {
        dispatch(setOperationalTimesSuccess(response));
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode || error?.message || 'Failed to fetch data!';
        dispatch(setOperationalTimesError(errorMessage));
      },
    });
  };

export const setOperationalTimes =
  (
    accommodationId: string | number,
    updatedOperationalTimes: IDay[],
    callBack: () => void,
  ) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    const payload = {accommodationId, updatedOperationalTimes};
    dispatch(setOperationalTimesRequest());

    venuesContainer.setOperationalTimes({
      payload,
      onSuccess: (response: IDay[]) => {
        callBack();
        dispatch(setOperationalTimesSuccess(response));
      },
      onError: (error: any) => {
        callBack();
        const errorMessage =
          error?.data?.reasonCode || error?.message || 'Failed to fetch data!';
        dispatch(setOperationalTimesError(errorMessage));
      },
    });
  };

import React from 'react';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from 'store/hooks';
import {ELocale} from 'types/locale';
import {distanceConverter} from 'utils/venueUtils';

type TProps = {
  distanceInKm: number | string | undefined;
  toFixedCount?: number;
  isLong?: boolean;
};

const DistanceComponent = ({
  distanceInKm,
  toFixedCount = 1,
  isLong,
}: TProps) => {
  const locale = useAppSelector(({app}) => app.locale);
  const {t} = useTranslation();

  const distanceInKmAsNumber = Number(distanceInKm);

  if (!distanceInKmAsNumber && distanceInKmAsNumber !== 0) return <>-</>;

  const distance =
    locale === ELocale.EN_US
      ? distanceConverter('mi', distanceInKmAsNumber, toFixedCount)
      : distanceInKmAsNumber.toFixed(toFixedCount);

  const key = isLong ? 'long_distance' : 'short_distance';

  return <>{t(key, {value: distance})}</>;
};

export default DistanceComponent;

import React from 'react';

import InputBlock from 'view/components/NW2SearchSection/components/SearchForm/components/InputBlock/InputBlock';

import {NW2Gray200Color} from 'styles/configs/colors';
import {MultiRoomRequirementsWrapper} from '../NW2DatepickerDetails.styles';
import {useAppSelector} from 'store/hooks';
import {ESearchInputsId} from 'types/search';

function MultiRoomRequirementsSection() {
  const {multiRooms, days} = useAppSelector(
    ({search}) => search.searchCriteria,
  );

  if (!multiRooms) return null;
  return (
    <MultiRoomRequirementsWrapper>
      <InputBlock
        htmlFor={ESearchInputsId.REQUIREMENTS}
        color={NW2Gray200Color}
        labelText='Requirements'
        isNoMargin
        isNoBorder
        isWidthAuto
        isFocused={false}
      >
        {days?.length} day event
      </InputBlock>
    </MultiRoomRequirementsWrapper>
  );
}

export default MultiRoomRequirementsSection;

import styled, {css} from 'styled-components';

import {
  blackColor,
  fontSizeLg,
  fontSizeSm,
  fontSizeXLg,
  fontSizeXXSm,
  fontWeightBold,
  lineHeightXLg,
  lineHeightXSm,
  NW2Blue500Color,
  NW2Gray200Color,
  NW2Gray300Color,
  nw2TotalDrawerZIndex,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXSm,
  offsetXXXSm,
  whiteColor,
} from 'constants/styleVars';
import DRAWER_CONFIG from 'view/components/NW2SummaryBlock/NW2SummaryBlock.config';

const COUNT_CIRCLE_SIZE = '18px';

export const ExtrasCount = styled.div<{longExtrasCount: boolean}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  top: -2px;
  left: 7px;
  width: ${COUNT_CIRCLE_SIZE};
  height: ${COUNT_CIRCLE_SIZE};
  background-color: ${NW2Blue500Color};
  color: ${blackColor};
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXXSm};
  line-height: ${fontSizeLg};
  ${({longExtrasCount}) =>
    longExtrasCount &&
    css`
      padding: 0 ${offsetSm};
    `}
  border-radius: 100px;
`;

export const FooterMobile = styled.div<{
  hasCart: boolean;
  hasCustomActions: boolean;
  isOfferPreview?: boolean;
  isEditMode?: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  ${({isEditMode}) => !isEditMode && ' display: grid;'}

  grid-template-columns: ${({hasCustomActions, hasCart}) =>
    hasCustomActions ? '1fr' : hasCart ? `1fr 4fr 3fr` : `1fr 154px`};
  grid-column-gap: ${({hasCart}) =>
    hasCart ? `${offsetXSm}` : `${offsetDef}`};
  align-items: center;

  background-color: ${blackColor};
  height: ${DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT}px;
  padding: ${offsetXSm} ${offsetDef};
  z-index: ${Number(nw2TotalDrawerZIndex) + 1};

  ${({isOfferPreview, hasCustomActions}) =>
    isOfferPreview &&
    !hasCustomActions &&
    css`
      grid-template-columns: 1.2fr 1fr;
      grid-column-gap: ${offsetDef};
    `}
`;

export const FooterMobileLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterMobileRight = styled.div<{isEditMode?: boolean}>`
  ${({isEditMode}) => !isEditMode && 'display: flex;'}
  height: 100%;
`;

export const FooterMobileTitle = styled.div`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  color: ${NW2Gray300Color};
`;

export const FooterMobilePrice = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
`;

export const FooterMobilePriceCost = styled.div<{longSum: boolean}>`
  font-size: ${({longSum}) => (longSum ? `${fontSizeSm}` : `${fontSizeXLg}`)};
  line-height: ${lineHeightXLg};
  color: ${whiteColor};
  font-weight: ${fontWeightBold};
`;

export const IconContainer = styled.div`
  display: flex;
  position: relative;
  padding: ${offsetXSm} ${offsetSm} ${offsetXSm} ${offsetXXXSm};
  border-right: solid ${NW2Gray200Color} 1px;
`;

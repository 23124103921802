import {DateTime} from 'luxon';

import DateUtils from 'utils/dateUtils';
import {formatDate} from 'utils/stringUtils';
import {getISOString} from 'utils/dateUtils';
import {
  SEARCH_DEFAULT_END_TIME,
  ITimeData,
  SEARCH_DEFAULT_START_TIME,
} from 'types/dto/ISearch.types';
import {EEventType} from 'types/venue';
import {getDefaultTimeData} from 'constants/defaultOperationalTimes';

export const dpConfig = {
  minDate: DateTime.now().toJSDate(),
  timeIntervals: 60,
  timeFormat: 'HH:mm',
  timeCaption: 'time',
  dateFormat: 'd MMM, yyyy',
  calendarStartDay: 1,
  inline: true,
};

export const formatDateForPicker = (start: string, end?: string) => {
  if (
    !end ||
    DateTime.fromISO(start)
      .startOf('day')
      .equals(DateTime.fromISO(end).startOf('day'))
  ) {
    return formatDate(start, 'd MMM, YYYY', 'en-US');
  }

  return `${formatDate(start, 'd MMM, YYYY', 'en-US')} - ${formatDate(
    end,
    'd MMM, YYYY',
    'en-US',
  )}`;
};

export const setInitialTimeRange = ({
  startTime,
  endTime,
  timeData = [],
  isMultiSearchRoom,
}: {
  startTime: string;
  endTime: string;
  timeData: ITimeData[];
  isMultiSearchRoom: boolean;
}) => {
  const getAmountOfDays = () => {
    const days = DateUtils.getDifferenceInDaysAndHours(startTime, endTime).days;
    if (!days) return 1;
    return days + 1;
  };

  const amountOfDays = getAmountOfDays();

  if (timeData.length && isMultiSearchRoom) {
    return timeData.map(({timeStart, timeEnd, eventType}) => ({
      timeStart,
      timeEnd,
      eventType,
    }));
  }
  return new Array(amountOfDays).fill('').map((item, index) => {
    if (index === amountOfDays - 1) return getDefaultTimeData(endTime);
    const day = getISOString(DateTime.fromISO(startTime).plus({days: index}));

    return getDefaultTimeData(day);
  });
};

export const loopOverDates = (start: string, end: string) => {
  const now = DateTime.fromISO(start);

  if (!end) {
    return [
      {
        timeStart: getISOString(now.set(SEARCH_DEFAULT_START_TIME)),
        timeEnd: getISOString(now.set(SEARCH_DEFAULT_END_TIME)),
        eventType: EEventType.DAY,
      },
    ];
  }

  const dates: ITimeData[] = [];
  const endDate = DateTime.fromISO(end);

  for (let date = now; date <= endDate; date = date.plus({days: 1})) {
    dates.push({
      timeStart: getISOString(date.set({...SEARCH_DEFAULT_START_TIME})),
      timeEnd: getISOString(date.set({...SEARCH_DEFAULT_END_TIME})),
      eventType: EEventType.DAY,
    });
  }

  return dates;
};

import styled from 'styled-components';

import {
  fontSizeXSm,
  fontWeightExtraBold,
  lgBp,
  lineHeightSm,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXXXXLg,
} from 'constants/styleVars';

export const Container = styled.div`
  &:nth-child(2) {
    border-left: 1px solid ${NW2Gray200Color};
    padding-left: ${offsetXXXXXLg};

    @media (max-width: ${lgBp}px) {
      padding-left: ${offsetXXLg};
    }
  }
`;

export const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${offsetXLg};
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetDef};
`;

export const SpaceInfoItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${offsetXLg};
  color: ${NW2Gray900Color};

  &:not(&:last-of-type) {
    margin-bottom: ${offsetXSm};
  }
`;

import React, {useMemo} from 'react';

import AccommodationTitle from 'view/venue/components/AccommodationTitle';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {IOfferRequestDay} from 'types/offer';
import {
  SubTotalPrice,
  UnitCardContainer,
  UnitCardDay,
  UnitCardDayName,
  UnitCardFooter,
  UnitCardTitle,
  UnitCardWrapper,
} from './UnitCard.styles';
import {UnitCardExtrasList} from './UnitCardExtrasList';
import {UnitCardRow} from './UnitCardRow';
import {formatDate} from 'utils/stringUtils';
import {getFilteredExtrasByType} from 'utils/venueUtils';
import {TExtraPriceField, TGetBedroomSubtotalPrice} from '../../../types';
import {IOfferRequestReviewUnitExtra} from '../../../../OfferRequestReviewUnitCard/types';

interface IProps {
  unit: IOfferRequestDay;
  currency: string;
  extrasOption: IExtrasOption[];
  title: string;
  getBedroomSubtotalPrice: (props: TGetBedroomSubtotalPrice) => number;
  prices?: TExtraPriceField;
  isInitialised: boolean;
  cateringExtras?: IOfferRequestReviewUnitExtra[];
  cateringPrices?: TExtraPriceField;
  isCateringInitialised?: boolean;
}

export function BedroomUnits({
  unit,
  title,
  extrasOption,
  getBedroomSubtotalPrice,
  currency,
  prices,
  isInitialised,
  cateringExtras,
  cateringPrices,
  isCateringInitialised,
}: IProps) {
  const subTotalPrice = useMemo(
    () =>
      getBedroomSubtotalPrice({
        unit,
        prices,
      }),
    [getBedroomSubtotalPrice, prices, unit],
  );

  const date = formatDate(unit.checkIn, 'd MMM, YYYY', 'en-US');

  const accommodationExtras = getFilteredExtrasByType(
    unit.bedrooms,
    EResourcesType.BEDROOM,
    extrasOption,
  );

  return (
    <UnitCardWrapper id={unit.id}>
      <UnitCardDayName>{title}</UnitCardDayName>
      <UnitCardDay>{date}</UnitCardDay>

      <UnitCardContainer>
        {cateringExtras && cateringExtras.length > 0 && (
          <>
            <UnitCardTitle mb={16}>Food & beverage</UnitCardTitle>
            <UnitCardExtrasList
              extrasList={cateringExtras}
              extrasPrices={cateringPrices}
              isInitialised={isCateringInitialised}
              currency={currency}
            />
          </>
        )}

        <AccommodationTitle checkIn={unit.checkIn} />

        <UnitCardExtrasList
          key={`${title}-bedrooms-${date}`}
          extrasList={accommodationExtras}
          currency={currency}
          prices={prices}
          isInitialised={isInitialised}
          isBedrooms
        />

        <UnitCardFooter>
          <UnitCardTitle>Day sub-total:</UnitCardTitle>
          <UnitCardRow
            data={
              subTotalPrice && (
                <SubTotalPrice>
                  <PriceDisplay price={subTotalPrice} currency={currency} />
                </SubTotalPrice>
              )
            }
            minWidth='120px'
          />
        </UnitCardFooter>
      </UnitCardContainer>
    </UnitCardWrapper>
  );
}

export enum EFrontendErrors {
  'ACCESS_TO_VENUE_DENIED_ERROR' = 'ACCESS_TO_VENUE_DENIED_ERROR',
}

export interface ICognitoError {
  code: string;
  name: string;
  message: string;
}

export enum ICognitoExceptionCode {
  'NotAuthorizedException' = 'NotAuthorizedException',
}

import React, {FC} from 'react';
import styled from 'styled-components';

import Icon from '../../../components/Icon';

import {INavbarItemProps} from '../Navbar';
import {
  secondaryColor,
  fontSizeXSm,
  lineHeightXSm,
  fontWeightExtraBold,
  fontSizeXXSm,
} from 'constants/styleVars';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${secondaryColor};
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  color: ${({primary, disabled, theme}) =>
    primary
      ? theme.primaryColor
      : disabled
      ? secondaryColor
      : theme.navigation.color};
`;

const Title = styled.div<{
  primary?: boolean;
  disabled?: boolean;
}>`
  color: ${({primary, disabled, theme}) =>
    primary
      ? theme.primaryColor
      : disabled
      ? secondaryColor
      : theme.navigation.color};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightXSm};
  margin-top: 2px;
  text-align: center;
  ${({theme}) => {
    return theme.name === 'NW2'
      ? `text-transform: uppercase; font-weight: ${fontWeightExtraBold}; font-size: ${fontSizeXXSm}`
      : '';
  }}
`;

const NavbarItem: FC<INavbarItemProps> = ({
  icon,
  title,
  onClick,
  primary,
  disabled,
}) => {
  return (
    <Container onClick={onClick}>
      <StyledIcon icon={icon} primary={primary} disabled={disabled} />
      <Title primary={primary} disabled={disabled}>
        {title}
      </Title>
    </Container>
  );
};

export default NavbarItem;

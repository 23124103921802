import LocalStorageService from 'infra/common/localStorage.service';

export const useIsFastSearchEnabled = (): [boolean] => {
  const storedIsFastSearchEnabled = LocalStorageService.getByKey(
    'isFastSearchEnabled',
  );

  const isFastSearchEnabled = storedIsFastSearchEnabled
    ? JSON.parse(storedIsFastSearchEnabled)
    : false;

  return [isFastSearchEnabled];
};

import {useEffect, useState} from 'react';

import LocalStorageService from 'infra/common/localStorage.service';
import {useActions} from 'hooks/useActions';
import {useAppSelector} from 'store/hooks';

export function useShortList() {
  const shortList = useAppSelector(({offers}) => offers.shortList);

  const {addShortList} = useActions();
  const shortListLen = shortList.items.length;
  const isMultiRequest = shortListLen > 1;

  // init shortList
  const [isShortListInitialised, setShortListInitialised] = useState(false);
  useEffect(() => {
    if (isShortListInitialised) return;

    const storedShortList = JSON.parse(
      LocalStorageService.getByKey('shortList') || '{}',
    );

    if (!shortListLen && storedShortList.items?.length) {
      addShortList(storedShortList);
    }

    setShortListInitialised(true);
  }, [addShortList, isShortListInitialised, shortListLen]);

  return {
    shortList,
    isShortListInitialised,
    isMultiRequest,
  };
}

import React, {FormEventHandler} from 'react';
import {Field} from 'react-final-form';
import styled from 'styled-components';

import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';
import NW2Button from 'view/components/NW2Button';
import TextPopover from 'view/venue/CreateVenue/pages/ContactDetails/components/ContactDetailsForm/TextPopover';
import NW2Switch from 'view/components/NW2Switch';
import AnnouncementWysiwygEditor from './AnnouncementWysiwygEditor';
import ErrorMessage from 'view/components/NW2FormItem/components/ErrorMessage';
import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {EAnnouncementsVisibilityAreas} from 'store/admin/types';
import {requiredFieldRules} from 'utils/finalFormFieldRules';

import {offsetSm, offsetXSm, pagePadding} from 'styles/configs';
import {Group} from 'styles/basicStyledComponents/group';
import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';

const StyledForm = styled.form`
  padding: ${offsetSm} 0 ${pagePadding};
`;

type TProps = {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  initialHtmlString: string;
  isEditMode: boolean;
  onCancel: () => void;
  errors?: Record<string, string>;
  touched?: {[key: string]: boolean};
};

export function AnnouncementForm({
  handleSubmit,
  initialHtmlString,
  isEditMode,
  onCancel,
  errors,
  touched,
}: TProps) {
  const hasVisibilityAreasErrors = !!(
    errors?.visibilityAreasErrors && touched?.visibilityAreas
  );

  return (
    <StyledForm onSubmit={handleSubmit} noValidate>
      <FormGroup columnNumber={1} gap={pagePadding}>
        <NW2FormItemInput
          name='title'
          type='text'
          label='Title'
          placeholder='Title'
          rules={requiredFieldRules('Title', {maxLength: 256, minLength: 3})}
        />
        <NW2FormTextarea
          name='subtitle'
          label='Subtitle (optional)'
          placeholder='Subtitle (optional)'
          minLength={2}
          minHeight={70}
        />

        <AnnouncementWysiwygEditor
          initialHtmlString={initialHtmlString}
          isTouched={touched?.htmlString}
          htmlStringErrors={errors?.htmlString}
        />

        <Group gap={offsetXSm} align='center'>
          <Field name='isActiveSwitch'>
            {({input}) => (
              <NW2Switch
                isActive={input.value}
                label='Show announcement'
                onClick={() => input.onChange(!input.value)}
                labelPosition='right'
              />
            )}
          </Field>
          <TextPopover textIndex={7} placement='leftTop' iconWidth='auto' />
        </Group>
        <Group>
          <span>Show to: </span>
          <NW2FormItemCheckbox
            name='visibilityAreas'
            label='Public venues'
            id='publicVenues'
            value={EAnnouncementsVisibilityAreas.PUBLIC}
            highlightAsError={hasVisibilityAreasErrors}
          />
          <NW2FormItemCheckbox
            name='visibilityAreas'
            label='Corporate venues'
            id='corporateVenues'
            value={EAnnouncementsVisibilityAreas.CORPORATE}
            highlightAsError={hasVisibilityAreasErrors}
          />

          <ErrorMessage
            hasError={hasVisibilityAreasErrors}
            errorData={errors?.visibilityAreasErrors || ''}
            showAllValidationErrors
          />
        </Group>
        <Group justify='end'>
          <NW2Button buttonType='secondary' white onClick={onCancel}>
            Cancel
          </NW2Button>
          <NW2Button buttonType='primary' type='submit'>
            {isEditMode ? 'Update' : 'Create'}
          </NW2Button>
        </Group>
      </FormGroup>
    </StyledForm>
  );
}

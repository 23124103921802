import React from 'react';

import ModalOverlay from 'view/components/ModalOverlay';

import {NW2ModalTwoColumnsContent} from './NW2ModalTwoColumnsContent';
import {INW2ModalTwoColumnsProps} from './types';

function NW2ModalTwoColumns(props: INW2ModalTwoColumnsProps) {
  if (!props.isShowed) {
    return null;
  }

  return (
    <ModalOverlay>
      <NW2ModalTwoColumnsContent {...props} />
    </ModalOverlay>
  );
}

export default NW2ModalTwoColumns;

import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  blackColor,
  fontWeightBold,
  lineHeightXXXXLg,
  NW2FontSizeH2Xs,
  NW2Gray100Color,
  NW2Gray600Color,
  offset64,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXLg,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${whiteColor};
  height: 100%;
`;

export const Container = styled.div`
  padding: ${offsetXLg} ${offsetXXLg};
`;

export const Header = styled(Container)`
  padding-top: ${offset64};
  background-color: ${NW2Gray100Color};
`;

export const HeaderTitle = styled.h1`
  font-size: ${NW2FontSizeH2Xs};
  line-height: ${lineHeightXXXXLg};
  font-weight: ${fontWeightBold};
  color: ${blackColor};
`;

export const Actions = styled.div`
  display: flex;
  gap: ${offsetXLg};
  margin-bottom: ${offsetXLg};
`;

export const EmptyData = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetSm};
  color: ${NW2Gray600Color};
`;

export const FlexContainer = styled.div`
  display: inline-flex;
`;

export const NoWrap = styled.div<{lineThrough?: boolean; textAlign?: string}>`
  white-space: nowrap;
  text-align: ${({textAlign}) => textAlign || 'left'};
  ${({lineThrough}) => lineThrough && `text-decoration: line-through`};
`;

export const TextCursive = styled.div`
  font-style: italic;
`;

export const BtnShowMore = styled(NW2Button)`
  margin: ${offsetXLg} auto 0;
`;

export const TableContainer = styled.div`
  th {
    &:nth-of-type(5) {
      text-align: right;
    }

    &:nth-of-type(6) {
      text-align: center;
    }

    &:nth-of-type(7) {
      text-align: center;
    }

    &:nth-of-type(8) {
      text-align: right;
    }
  }

  td {
    &:nth-of-type(5) {
      text-align: right;
    }

    &:nth-of-type(6) {
      text-align: center;
    }

    &:nth-of-type(7) {
      text-align: center;
    }

    &:nth-of-type(8) {
      text-align: right;
    }
  }
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${offsetDef};
  height: 100%;
`;

export const OrderNumberCell = styled.div`
  align-items: center;
  display: inline-flex;
  gap: ${offsetXXSm};
  max-width: 140px;
`;

export const ExtrasCellBox = styled.div<{width?: number}>`
  max-width: ${({width}) => width}px;
`;

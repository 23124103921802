import styled from 'styled-components';
import {
  lgBp,
  mdBp,
  NW2Gray200Color,
  NW2Primary,
  offset64,
  offset80,
  offsetXLg,
  smBp,
} from 'constants/styleVars';

const NW2CardContainer = styled.div<{isGridReverse?: boolean}>`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: ${offsetXLg};

  & + & {
    margin-top: ${offset64};
  }

  @media (min-width: ${smBp}px) {
    grid-auto-flow: ${({isGridReverse}) => (isGridReverse ? 'dense' : 'row')};

    > *:nth-child(1) {
      grid-column: ${({isGridReverse}) => (isGridReverse ? '2' : '1')};
    }
    > *:nth-child(2) {
      grid-column: ${({isGridReverse}) => (isGridReverse ? '1' : '2')};
    }
  }

  @media (min-width: ${lgBp}px) {
    & + & {
      margin-top: ${offset80};
    }
  }
`;

const CardBody = styled.div`
  position: relative;
`;

const CardImageContainer = styled.div`
  position: relative;
`;

const DecorationElement1 = styled.div`
  position: absolute;
  z-index: -1;
  bottom: -10px;
  left: 0;
  transform: translateX(-10%);
  width: 50%;
  min-width: 310px;
  height: 30%;
  min-height: 190px;
  background: ${NW2Primary};

  @media (min-width: ${mdBp}px) {
    bottom: -25px;
  }
`;

const DecorationElement2 = styled.div`
  position: absolute;
  z-index: -1;
  top: -50px;
  left: -20%;
  width: 616px;
  height: 158px;
  background: ${NW2Gray200Color};

  @media (min-width: ${mdBp}px) {
    left: -42%;
  }
`;

export {
  NW2CardContainer,
  CardBody,
  DecorationElement1,
  DecorationElement2,
  CardImageContainer,
};

import {ERoomSchemaNames} from 'types/venue';
import THEATRE from 'img/theatre_schema.svg';
import USHAPE from 'img/ushape_schema.svg';
import CLASSROOM from 'img/classroom_schema.svg';
import BOARDROOM from 'img/boardroom_schema.svg';
import BANQUET from 'img/banquet_schema.svg';
import STANDING from 'img/standing_schema.svg';
import CIRCLE from 'img/circle_schema.svg';

export type TRoomSchemaOption = {
  setupStyle?: ERoomSchemaNames;
  checkbox?: boolean;
  capacity: string | number;
};

export const imageObject: Record<ERoomSchemaNames, string> = {
  [ERoomSchemaNames.THEATRE]: THEATRE,
  [ERoomSchemaNames.U_SHAPE]: USHAPE,
  [ERoomSchemaNames.CIRCLE_OF_CHAIRS]: CIRCLE,
  [ERoomSchemaNames.CLASSROOM]: CLASSROOM,
  [ERoomSchemaNames.BLOCK]: BOARDROOM,
  [ERoomSchemaNames.BANQUET]: BANQUET,
  [ERoomSchemaNames.RECEPTION]: STANDING,
};

export interface IRoomSchemaItem {
  capacity: number;
  checkbox: boolean;
}

export type TRoomSchemaItems = {
  [key in ERoomSchemaNames]: IRoomSchemaItem;
};

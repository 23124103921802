import React, {useCallback, useState} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';

import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {EBedroomExtrasNames} from 'types/dto/IExtras.type';
import {TBedroomType, TSelectedOptions} from 'types/search';
import {offsetDef, offsetXLg, offsetXSm} from 'constants/styleVars';
import {TBedroomsCatering, THandleSetBedroomsCatering} from '../types';
import {
  BEDROOM_CATERING,
  BEDROOM_EXTRAS,
  ACCOMMODATION_FORM_FIELD_NAME,
} from 'constants/bedrooms';
import {ButtonsBox, SwitchButton} from '../AddAccommodation.styles';
import {
  FormGroup,
  StyledErrorMessage,
} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {FlexContainer} from '../../AddFoodBeverageRequest/AddFoodBeverageRequest.styles';
import {MAX_EXTRAS_NUMBER, MIN_EXTRAS_NUMBER} from 'constants/app';

const EditAccommodationButtons = ({
  bedroomsCatering,
  roomType,
  handleSetBedroomsCatering,
  handleSetCateringSwitched,
  date,
  setMinQtyError,
}: {
  bedroomsCatering: TBedroomsCatering;
  roomType: TBedroomType;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  handleSetCateringSwitched: (value: boolean) => void;
  date: string;
  setMinQtyError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {WITH_BREAKFAST, NO_BREAKFAST} = BEDROOM_CATERING;

  const activeButton = bedroomsCatering[date][roomType];

  const activeButtonName = (activeButton.preName ||
    activeButton.name) as EBedroomExtrasNames;

  const isWithBreakfastSelected = [
    EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST,
    EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST,
  ].includes(activeButtonName);

  const isNoBreakfastSelected = [
    EBedroomExtrasNames.SINGLE_BEDROOM,
    EBedroomExtrasNames.DOUBLE_BEDROOM,
  ].includes(activeButtonName);

  const onWithBreakfastClick = useCallback(() => {
    if (isWithBreakfastSelected) return false;

    const preName =
      roomType === 'single'
        ? EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST
        : EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST;

    handleSetBedroomsCatering({roomType, preName, date});
    handleSetCateringSwitched(true);
    setMinQtyError(false);
  }, [
    date,
    handleSetBedroomsCatering,
    handleSetCateringSwitched,
    isWithBreakfastSelected,
    roomType,
    setMinQtyError,
  ]);

  const onNoBreakfastClick = useCallback(() => {
    if (isNoBreakfastSelected) return false;

    const preName =
      roomType === 'single'
        ? EBedroomExtrasNames.SINGLE_BEDROOM
        : EBedroomExtrasNames.DOUBLE_BEDROOM;

    handleSetBedroomsCatering({roomType, preName, date});
    handleSetCateringSwitched(true);
    setMinQtyError(false);
  }, [
    date,
    handleSetBedroomsCatering,
    handleSetCateringSwitched,
    isNoBreakfastSelected,
    roomType,
    setMinQtyError,
  ]);

  return (
    <ButtonsBox>
      <SwitchButton
        onClick={onWithBreakfastClick}
        active={isWithBreakfastSelected}
      >
        {WITH_BREAKFAST}
      </SwitchButton>
      <SwitchButton onClick={onNoBreakfastClick} active={isNoBreakfastSelected}>
        {NO_BREAKFAST}
      </SwitchButton>
    </ButtonsBox>
  );
};

type TProps = {
  onSave: (payload: Record<string, any>) => void;
  onCancel: () => void;
  participants: number;
  accommodationData: TSelectedOptions;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  date: string;
  errorMsg?: string;
  setMinQtyError: React.Dispatch<React.SetStateAction<boolean>>;
};
export function EditAccommodation({
  accommodationData,
  participants,
  bedroomsCatering,
  onCancel,
  onSave,
  handleSetBedroomsCatering,
  date,
  errorMsg,
  setMinQtyError,
}: TProps) {
  const [isCateringSwitched, setCateringSwitched] = useState(false);

  const handleSetCateringSwitched = (value: boolean) =>
    setCateringSwitched(value);

  const handleSetCateringEnabled = useCallback(
    ({formState, id, roomType}: any) => {
      /**
       * setTimeout prevents FormSpy issue
       * https://github.com/final-form/react-final-form/issues/809
       */
      setTimeout(() => {
        handleSetBedroomsCatering({
          roomType,
          isEnabled:
            formState.values[ACCOMMODATION_FORM_FIELD_NAME]?.includes(id),
          date,
          qty: formState.values[`${id}_`],
        });
      }, 0);
    },
    [date, handleSetBedroomsCatering],
  );

  return (
    <Form onSubmit={onSave} initialValues={accommodationData.data}>
      {({handleSubmit, submitting, pristine, values}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup columnNumber={1} gap={parseInt(offsetDef)}>
            {accommodationData.options.map(({id, name}) => {
              const isNumericInputVisible =
                values[ACCOMMODATION_FORM_FIELD_NAME]?.includes(id);

              const roomType =
                name === BEDROOM_EXTRAS.SINGLE ? 'single' : 'double';

              return (
                <div key={`accommodation-item-${id}`}>
                  <FlexContainer
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <NW2FormItemCheckbox
                      id={String(id)}
                      type='checkbox'
                      name='accommodation'
                      label={name}
                      value={id}
                    />

                    {isNumericInputVisible && (
                      <Field
                        name={`${id}_`}
                        initialValue={
                          values[`${id}_`] ||
                          (name === BEDROOM_EXTRAS.SINGLE ? participants : 1)
                        }
                      >
                        {({input}) => (
                          <NW2NumericInput
                            minValue={MIN_EXTRAS_NUMBER}
                            maxValue={MAX_EXTRAS_NUMBER}
                            initialValue={input.value}
                            onChange={(e) => {
                              handleSetBedroomsCatering({
                                roomType,
                                qty: e,
                                date,
                              });
                              input.onChange(e);
                              setMinQtyError(false);
                            }}
                            hasInput
                          />
                        )}
                      </Field>
                    )}
                  </FlexContainer>

                  {isNumericInputVisible && (
                    <EditAccommodationButtons
                      bedroomsCatering={bedroomsCatering}
                      roomType={roomType}
                      handleSetBedroomsCatering={handleSetBedroomsCatering}
                      handleSetCateringSwitched={handleSetCateringSwitched}
                      setMinQtyError={setMinQtyError}
                      date={date}
                    />
                  )}

                  <FormSpy
                    subscription={{values: true}}
                    onChange={(formState) => {
                      handleSetCateringEnabled({formState, id, roomType});
                    }}
                  />
                </div>
              );
            })}
          </FormGroup>

          {errorMsg && (
            <StyledErrorMessage margin={`${offsetXLg} 0 -${offsetXSm}`}>
              {errorMsg}
            </StyledErrorMessage>
          )}

          <NMMSubmitSection
            submitLabel='save'
            handleCancel={onCancel}
            disabled={(submitting || pristine) && !isCateringSwitched}
            gap={offsetDef}
          />
        </form>
      )}
    </Form>
  );
}

import React, {ReactNode} from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon/Icon';

import {
  borderRadiusDef,
  fontSizeXSm,
  lineHeightSm,
  NW2ErrorLight500Color,
  NW2Gray100Color,
  NW2Gray900Color,
  offsetDef,
  offsetSm,
} from 'constants/styleVars';

interface IProps {
  icon?: ReactNode;
  text: string | ReactNode;
}

const StyledCardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${NW2Gray900Color};
  padding: ${offsetSm};
  text-align: left;
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  background-color: ${NW2Gray100Color};
  margin-bottom: ${offsetDef};
  border-radius: ${borderRadiusDef};
  border-left: 4px solid ${NW2ErrorLight500Color};
`;

const StyledIconWrapper = styled.div`
  margin-right: ${offsetSm};

  svg {
    width: 20px;
    height: 20px;
    color: ${NW2ErrorLight500Color};
  }
`;

const SubmitErrorCard = ({
  icon = <Icon icon='TRIANGLE_WARN' />,
  text,
}: IProps) => (
  <StyledCardContainer>
    {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
    <div>{text}</div>
  </StyledCardContainer>
);

export default SubmitErrorCard;

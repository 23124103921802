import React from 'react';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  Content,
  Section,
  Title,
  Paragraph,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {NW2Gray200Color} from 'constants/styleVars';
import {NEWWORK_SUPPORT_EMAIL} from 'constants/app';

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${NW2Gray200Color};
  margin: 48px 0;
`;

const ContactUsBlock = () => {
  const contactSupport = () => {
    window.open(
      `mailto:${NEWWORK_SUPPORT_EMAIL}`,
      '_blank',
      'noreferrer noopener',
    );
  };

  return (
    <div>
      <Divider />
      <Content>
        <Section>
          <Title>Still need help?</Title>
          <Paragraph>
            If there you were not able to find help to your particular question
            let us know and we will get back to you as soon as possible.
          </Paragraph>
        </Section>
      </Content>

      <NW2Button onClick={contactSupport} size='responsiveAdaptive'>
        contact us
      </NW2Button>
    </div>
  );
};

export default ContactUsBlock;

import React, {FC} from 'react';
import {Form, FormInstance} from 'antd';

import CompanySelect from './components/CompanySelect';

import {COMPANY_ID_FIELD_NAME} from '../../../../../constants/admin';
import {ICorporateAccountCompanyForSelectCompany} from 'store/admin/types';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 24},
};

type Props = {
  form: FormInstance;
  companies: ICorporateAccountCompanyForSelectCompany[];
};

const AdminReassignCompanyUser: FC<Props> = ({form, companies}) => {
  return (
    <Form autoComplete='off' form={form} preserve={false} {...layout}>
      <Form.Item
        label='Company'
        name={COMPANY_ID_FIELD_NAME}
        rules={[{required: true, message: 'Please select company!'}]}
      >
        <CompanySelect form={form} companies={companies} />
      </Form.Item>
    </Form>
  );
};

export default React.memo(AdminReassignCompanyUser);

import React, {FC} from 'react';
import {Pagination} from 'antd';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {offsetXXLg} from 'constants/styleVars';
import {RootState} from 'store/types';
import {INITIAL_CORPORATE_ACCOUNT_COMPANY_USERS_PER_PAGE} from 'constants/admin';
import {
  setCorporateAccountCompanyUsersPage,
  setCorporateAccountCompanyUsersSize,
} from 'store/admin/adminSlice';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${offsetXXLg};
`;

const UsersPagination: FC = () => {
  const dispatch = useDispatch();

  const page: number = useSelector((state: RootState) =>
    _get(state, 'admin.corporateAccountCompanyUsersPage'),
  );

  const size: number = useSelector((state: RootState) =>
    _get(state, 'admin.corporateAccountCompanyUsersSize'),
  );

  const total: number = useSelector((state: RootState) =>
    _get(state, 'admin.totalCorporateAccountCompanyUsers'),
  );

  const setUsersPage = (page: number) =>
    dispatch(setCorporateAccountCompanyUsersPage(page - 1));

  const setUsersSize = (current: number, size: number) =>
    dispatch(setCorporateAccountCompanyUsersSize(size));

  return (
    <Container>
      <Pagination
        onChange={setUsersPage}
        onShowSizeChange={setUsersSize}
        total={total}
        showTotal={(total, range) =>
          `Showing ${range[0]}-${range[1]} out of ${total} Users`
        }
        current={+page + 1}
        pageSize={size}
        hideOnSinglePage={
          total <= INITIAL_CORPORATE_ACCOUNT_COMPANY_USERS_PER_PAGE
        }
      />
    </Container>
  );
};

export default UsersPagination;

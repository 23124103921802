import React, {useCallback} from 'react';

import {BEDROOM_CATERING} from 'constants/bedrooms';
import {
  ButtonsBox,
  SwitchButton,
} from '../NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/AddAccommodation.styles';
import {
  THandleSetBedroomsCatering,
  TBedroomsCateringItem,
} from '../NW2SearchSection/components/SearchForm/components/GroupsRooms/types';
import {TBedroomType} from 'types/search';

interface IProps {
  activeButton: TBedroomsCateringItem;
  roomType: TBedroomType;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  handleSetCateringSwitched: (value: boolean) => void;
  date: string;
}
export function EditAccommodationButtons({
  activeButton,
  roomType,
  handleSetBedroomsCatering,
  handleSetCateringSwitched,
  date,
}: IProps) {
  const isWithBreakfastSelected = activeButton.withBreakfast;
  const isNoBreakfastSelected = !activeButton.withBreakfast;

  const onWithBreakfastClick = useCallback(() => {
    if (isWithBreakfastSelected) return false;

    handleSetBedroomsCatering({
      roomType,
      withBreakfast: true,
      date,
      isEnabled: activeButton.isEnabled,
    });
    handleSetCateringSwitched(true);
  }, [
    activeButton.isEnabled,
    date,
    handleSetBedroomsCatering,
    handleSetCateringSwitched,
    isWithBreakfastSelected,
    roomType,
  ]);

  const onNoBreakfastClick = useCallback(() => {
    if (isNoBreakfastSelected) return false;

    handleSetBedroomsCatering({
      roomType,
      withBreakfast: false,
      date,
      isEnabled: activeButton.isEnabled,
    });
    handleSetCateringSwitched(true);
  }, [
    activeButton.isEnabled,
    date,
    handleSetBedroomsCatering,
    handleSetCateringSwitched,
    isNoBreakfastSelected,
    roomType,
  ]);

  return (
    <ButtonsBox>
      <SwitchButton
        onClick={onWithBreakfastClick}
        active={isWithBreakfastSelected}
      >
        {BEDROOM_CATERING.WITH_BREAKFAST}
      </SwitchButton>
      <SwitchButton onClick={onNoBreakfastClick} active={isNoBreakfastSelected}>
        {BEDROOM_CATERING.NO_BREAKFAST}
      </SwitchButton>
    </ButtonsBox>
  );
}

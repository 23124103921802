import styled from 'styled-components';
import {Form, Input} from 'antd';
import React, {FC} from 'react';
import {validateWebUrl} from '../../../utils/formFieldValidators';

type PropsType = {
  name: string;
  placeholder: string;
  functionUpdate: any;
  label?: string;
  allowClear?: boolean;
};

export const StyledFormItem = styled(Form.Item)`
  width: 100%;
`;

const FormItemUrl: FC<PropsType> = ({
  name,
  placeholder,
  functionUpdate,
  label,
  allowClear,
}: PropsType) => {
  return (
    <StyledFormItem
      name={name}
      label={label}
      rules={[
        {
          validator: validateWebUrl,
        },
      ]}
    >
      <Input
        name={name}
        allowClear={allowClear}
        placeholder={placeholder}
        onChange={functionUpdate}
      />
    </StyledFormItem>
  );
};

export default FormItemUrl;

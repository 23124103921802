import {
  EBedroomExtras,
  EBedroomExtrasNames,
  EResourcesCode,
} from 'types/dto/IExtras.type';

export const BEDROOM_EXTRAS = {
  SINGLE: 'Single use guest rooms',
  DOUBLE: 'Double use guest rooms',
};

export const ID_TO_BEDROOM_EXTRA = {
  [EBedroomExtras.SINGLE_BEDROOM]: BEDROOM_EXTRAS.SINGLE,
  [EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST]: BEDROOM_EXTRAS.SINGLE,
  [EBedroomExtras.DOUBLE_BEDROOM]: BEDROOM_EXTRAS.DOUBLE,
  [EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST]: BEDROOM_EXTRAS.DOUBLE,
};

export const BEDROOM_CATERING = {
  WITH_BREAKFAST: 'With breakfast',
  NO_BREAKFAST: 'No breakfast',
};

export const ACCOMMODATION_FORM_FIELD_NAME = 'accommodation';

export const BEDROOMS_NAMES: {[key in EResourcesCode]?: string} = {
  [EResourcesCode.SINGLE_BEDROOM]: 'Single use guest rooms (no breakfast)',
  [EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST]:
    'Single use guest rooms (with breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM]: 'Double use guest rooms (no breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    'Double use guest rooms (with breakfast)',
};

export const BEDROOMS_NAMES_SHORT: {[key in EResourcesCode]?: string} = {
  [EResourcesCode.SINGLE_BEDROOM]: 'Single use (no breakfast)',
  [EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST]: 'Single use (with breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM]: 'Double use (no breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST]: 'Double use (with breakfast)',
};

export const BEDROOMS_NAMES_FROM_BE: {[key in EBedroomExtrasNames]?: string} = {
  [EBedroomExtrasNames.SINGLE_BEDROOM]: 'Single use guest rooms (no breakfast)',
  [EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST]:
    'Single use guest rooms (with breakfast)',
  [EBedroomExtrasNames.DOUBLE_BEDROOM]: 'Double use guest rooms (no breakfast)',
  [EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    'Double use guest rooms (with breakfast)',
};

export const ID_TO_RESOURCE_CODE = {
  [EBedroomExtras.SINGLE_BEDROOM]: EResourcesCode.SINGLE_BEDROOM,
  [EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST]:
    EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST,
  [EBedroomExtras.DOUBLE_BEDROOM]: EResourcesCode.DOUBLE_BEDROOM,
  [EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST,
} as const;

export const ID_TO_BEDROOM_EXTRAS_NAMES = {
  [EBedroomExtras.SINGLE_BEDROOM]: EBedroomExtrasNames.SINGLE_BEDROOM,
  [EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST]:
    EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST,
  [EBedroomExtras.DOUBLE_BEDROOM]: EBedroomExtrasNames.DOUBLE_BEDROOM,
  [EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST,
} as const;

export const BEDROOM_EXTRAS_NAMES_TO_ID = {
  [EBedroomExtrasNames.SINGLE_BEDROOM]: EBedroomExtras.SINGLE_BEDROOM,
  [EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST]:
    EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST,
  [EBedroomExtrasNames.DOUBLE_BEDROOM]: EBedroomExtras.DOUBLE_BEDROOM,
  [EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST,
} as const;

export const ID_TO_BEDROOM_TYPE = {
  [EBedroomExtras.SINGLE_BEDROOM]: 'single',
  [EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST]: 'single',
  [EBedroomExtras.DOUBLE_BEDROOM]: 'double',
  [EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST]: 'double',
} as const;

export const oppositeIds = {
  [EBedroomExtras.SINGLE_BEDROOM]: EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST,
  [EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST]: EBedroomExtras.SINGLE_BEDROOM,
  [EBedroomExtras.DOUBLE_BEDROOM]: EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST,
  [EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST]: EBedroomExtras.DOUBLE_BEDROOM,
} as const;

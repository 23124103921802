import styled from 'styled-components';

import {
  blackColor,
  borderRadiusXLg,
  fontSizeXLg,
  fontSizeXSm,
  fontSizeXXXLg,
  fontWeightBold,
  lineHeightSm,
  lineHeightXLg,
  lineHeightXXLg,
  NW2Gray200Color,
  NW2Gray50Color,
  NW2Gray800Color,
  offset80,
  offsetDef,
  offsetXXXXXLg,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  background: linear-gradient(137.11deg, #0088a6 2.89%, #003440 59.24%);
  padding: 88px 0 0 88px;
  border-left: 1px solid ${NW2Gray200Color};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Container = styled.div`
  background-color: ${NW2Gray50Color};
  padding: ${offsetXXXXXLg} ${offsetDef} ${offset80} 44px;
  border-radius: ${borderRadiusXLg} 0 0 0;
`;

// total
export const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${blackColor};
`;

export const TotalTitle = styled.div`
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightBold};
`;

export const TotalPriceBlock = styled.div`
  text-align: right;
`;

export const TotalPrice = styled.div`
  font-size: ${fontSizeXXXLg};
  line-height: ${lineHeightXXLg};
  font-weight: ${fontWeightBold};
`;

export const TotalTaxes = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray800Color};
`;

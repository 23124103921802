import {useMemo} from 'react';

import {
  ERequestDetailsSections,
  ERequestDetailsType,
  IOfferDay,
  IRequestDay,
} from 'types/offer';

interface IProps {
  requestDetailsType: ERequestDetailsType;
  requestedDays: (IRequestDay | IOfferDay)[];
  preArrivals: (IRequestDay | IOfferDay)[];
  postEvents: (IRequestDay | IOfferDay)[];
}

export const useRequestSections = ({
  requestDetailsType,
  requestedDays,
  preArrivals,
  postEvents,
}: IProps) =>
  useMemo(() => {
    const days = requestedDays.map((_, index) => `Day ${index + 1}`);

    const preArrivalsTitleArr = preArrivals.map(
      (_, index) => `${ERequestDetailsSections.PRE_ARRIVAL}_${index}`,
    );

    const postEventsTitleArr = postEvents.map(
      (_, index) => `${ERequestDetailsSections.POST_EVENT}_${index}`,
    );

    switch (requestDetailsType) {
      // Request
      case ERequestDetailsType.SINGLE_PENDING:
        return [
          ERequestDetailsSections.OPTION_DATE,
          ERequestDetailsSections.REQUEST_DETAILS,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
        ];

      case ERequestDetailsType.MULTI_PENDING:
        return [
          ERequestDetailsSections.OPTION_DATE,
          ...preArrivalsTitleArr,
          ...days,
          ...postEventsTitleArr,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
        ];

      case ERequestDetailsType.SINGLE_DECLINED:
      case ERequestDetailsType.SINGLE_CANCELED:
        return [
          ERequestDetailsSections.STATUS,
          ERequestDetailsSections.REQUEST_DETAILS,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
        ];

      case ERequestDetailsType.MULTI_DECLINED:
      case ERequestDetailsType.MULTI_CANCELED:
        return [
          ERequestDetailsSections.STATUS,
          ...preArrivalsTitleArr,
          ...days,
          ...postEventsTitleArr,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
        ];

      case ERequestDetailsType.SINGLE_EXPIRED:
        return [
          ERequestDetailsSections.STATUS,
          ERequestDetailsSections.REQUEST_DETAILS,
        ];

      case ERequestDetailsType.MULTI_EXPIRED:
        return [
          ERequestDetailsSections.STATUS,
          ...preArrivalsTitleArr,
          ...days,
          ...postEventsTitleArr,
        ];

      // Offer
      case ERequestDetailsType.SINGLE_OFFER_CREATED:
        return [
          ERequestDetailsSections.STATUS,
          ERequestDetailsSections.OFFER_DETAILS,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
          ERequestDetailsSections.ADDITIONAL_ACTIONS,
        ];

      case ERequestDetailsType.MULTI_OFFER_CREATED:
        return [
          ERequestDetailsSections.STATUS,
          ...preArrivalsTitleArr,
          ...days,
          ...postEventsTitleArr,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
          ERequestDetailsSections.ADDITIONAL_ACTIONS,
        ];

      case ERequestDetailsType.SINGLE_OFFER_PENDING:
      case ERequestDetailsType.SINGLE_OFFER_DECLINED:
      case ERequestDetailsType.SINGLE_OFFER_CANCELED:
      case ERequestDetailsType.SINGLE_OFFER_CONFIRMED:
        return [
          ERequestDetailsSections.STATUS,
          ERequestDetailsSections.OFFER_DETAILS,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
        ];

      case ERequestDetailsType.MULTI_OFFER_PENDING:
      case ERequestDetailsType.MULTI_OFFER_DECLINED:
      case ERequestDetailsType.MULTI_OFFER_CANCELED:
      case ERequestDetailsType.MULTI_OFFER_CONFIRMED:
        return [
          ERequestDetailsSections.STATUS,
          ...preArrivalsTitleArr,
          ...days,
          ...postEventsTitleArr,
          ERequestDetailsSections.TERMS_AND_CONDITIONS,
        ];

      case ERequestDetailsType.SINGLE_OFFER_SENDING_EXPIRED:
      case ERequestDetailsType.SINGLE_OFFER_ACCEPTING_EXPIRED:
        return [
          ERequestDetailsSections.STATUS,
          ERequestDetailsSections.OFFER_DETAILS,
        ];

      case ERequestDetailsType.MULTI_OFFER_SENDING_EXPIRED:
      case ERequestDetailsType.MULTI_OFFER_ACCEPTING_EXPIRED:
        return [
          ERequestDetailsSections.STATUS,
          ...preArrivalsTitleArr,
          ...days,
          ...postEventsTitleArr,
        ];

      default:
        return Object.values(ERequestDetailsSections);
    }
  }, [preArrivals, postEvents, requestDetailsType, requestedDays]);

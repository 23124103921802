import {ReactNode} from 'react';

export enum ESpaceDetailsContentSections {
  NAME_AND_LOCATION = 'Name & Location',
  SPACE_TYPE_AND_CAPACITY = 'Space type & capacity',
  IMAGES = 'Images',
  DESCRIPTION = 'Description',
  PRICES = 'Prices',
  INCLUDED_IN_SPACE = 'Included in space',
  CONDITIONS_AND_ACCESSIBILITY = 'Conditions & Accessibility',
  SETUPS_AND_CAPACITIES = 'Setups & Capacities',
  DIMENSIONS = 'Dimensions',
  BOOKABILITY_TYPE = 'Bookability type',
  ADDITIONAL_ACTIONS = 'Additional actions',
}

export type TSpaceDetailsContent = {
  id: ESpaceDetailsContentSections;
  title: ESpaceDetailsContentSections;
  subtitle?: string;
  titleFontSize?: string;
  titleMarginBottom?: string;
  sectionPadding?: string;
  containerTitle?: ReactNode;
  isEditOn?: boolean;
  onEdit?: () => void;
  children: ReactNode;
};

// Types for Forms of sections in Space Details

export enum ESpaceDetailsFormFields {
  SPACE_NAME = 'name',
  FLOOR = 'floor',
  DESCRIPTION = 'description',
  TYPE = 'type',
  CAPACITY = 'capacity',
  FILES = 'files',
}

export interface ICommonProps {
  onEditorShowed: () => void;
  updateSpace: (values: any) => Promise<void>;
  isSending: boolean;
  isEditorShowed: boolean;
}

import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {
  NW2FormItemInput,
  NW2FormItemSelect,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  mdBp,
  lgBp,
  offsetSm,
  offsetXXXLg,
  pagePadding,
} from 'constants/styleVars';

const sharedGridStyles = css`
  display: grid;
`;

const sharedFormItemStyles = css`
  margin: 0;
  position: relative;
`;

const BillingAddressContainer = styled.div`
  ${sharedGridStyles};
  grid-gap: ${offsetXXXLg};
`;

const BillingAddressInnerBlock = styled.div`
  ${sharedGridStyles};
  grid-gap: ${pagePadding};
`;

const SearchContainer = styled(BillingAddressInnerBlock)`
  align-items: center;

  @media (min-width: ${lgBp}px) {
    grid-template-columns: 1fr auto;
  }
`;

const AddressContainer = styled(BillingAddressInnerBlock)<{
  useDesktopLayoutOnTablet: boolean;
}>`
  @media (min-width: ${({useDesktopLayoutOnTablet}) =>
      useDesktopLayoutOnTablet ? mdBp : lgBp}px) {
    > *:nth-child(1) {
      grid-column: span 2;
    }
  }
`;

const StyledNW2FormItemInput = styled(NW2FormItemInput)`
  ${sharedFormItemStyles};
`;

const StyledNW2FormItemSelect = styled(NW2FormItemSelect)`
  ${sharedFormItemStyles};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${offsetSm};
`;

export {
  AddressContainer,
  StyledNW2FormItemInput,
  StyledNW2FormItemSelect,
  StyledIcon,
  BillingAddressContainer,
  BillingAddressInnerBlock,
  SearchContainer,
};

import styled from 'styled-components';
import {Form, Input} from 'antd';
import TextValue from 'view/components/TextValue';
import {
  darkGrayColor,
  errorColor,
  lightGrayColor,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
} from 'constants/styleVars';

export const CustomInputNumber = styled(Input)`
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledItem = styled(Form.Item)`
  flex: 1;
  :first-child {
    margin-right: ${offsetDef};
  }
`;
export const LegalContactsHeader = styled(TextValue)`
  color: ${darkGrayColor};
  margin-bottom: ${offsetXLg};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${lightGrayColor};
  margin: ${offsetXXLg} 0;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchTitle = styled.div`
  margin-left: ${offsetXSm};
  display: flex;
  align-items: center;
`;

export const StyledLabelStar = styled.span`
  color: ${errorColor};
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  margin-right: ${offsetXXSm};
  font-family: SimSun, sans-serif;
`;

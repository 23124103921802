import React, {ChangeEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Country} from 'country-state-city';
import {Form} from 'antd';
import _get from 'lodash/get';

import FormItemWithoutEvent from 'view/components/FormItemWithoutEvent';
import FormItemPhoneNumber from 'view/components/FormItemPhoneNumber';
import CountrySelect from './CountrySelect';
import RegionSelect from './RegionSelect';

import {
  countryFieldRules,
  legalPhoneFieldRules,
  postalCodeFieldRules,
  stateOfProvinceFieldRules,
} from 'utils/antdFormFieldRules';
import {ILegalPhone} from 'types/venue';
import {
  PATH_TO_REDUCER_VENUE_DATA,
  phoneNumberPlaceholderForCreateVenue,
} from 'constants/venue';
import {setVenueFields} from 'store/venue/actions';
import {CustomInputNumber} from '../ContactDetailsForm.styles';

type TProps = {
  handleFormFields: (
    path: string,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleCustomNumericFormFields: (
    path: string,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  legalPhoneValue: string;
};

const BusinessAddress = ({
  handleFormFields,
  handleCustomNumericFormFields,
  legalPhoneValue,
}: TProps) => {
  const dispatch = useDispatch();
  const {Item} = Form;

  const countryCode =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.legalContact.countryCode`),
    ) || '';

  const handleCountryValueFormField = (value: string) => {
    const countryValue = Country.getCountryByCode(value);
    dispatch(setVenueFields({path: 'legalContact.region', value: null}));
    if (countryValue) {
      dispatch(
        setVenueFields({
          path: 'legalContact.country',
          value: countryValue.name,
        }),
      );
    }
    dispatch(setVenueFields({path: 'legalContact.countryCode', value}));
  };

  const handleRegionValueFormField = (value: string) => {
    dispatch(
      setVenueFields({
        path: 'legalContact.region',
        value: value,
      }),
    );
  };

  const handleLegalPhoneFields =
    (path: string) => (value: string | ILegalPhone) => {
      dispatch(setVenueFields({path, value}));
    };

  return (
    <>
      <CountrySelect
        name='countryCode'
        label='Country'
        rules={countryFieldRules}
        placeholder='Select country'
        onChange={handleCountryValueFormField}
      />

      <RegionSelect
        name='region'
        label='State or province'
        rules={stateOfProvinceFieldRules}
        placeholder='Select state or province'
        countryCode={countryCode}
        onChange={handleRegionValueFormField}
      />

      <FormItemWithoutEvent
        name='legalCity'
        placeholder='Cologne'
        message='City is required'
        functionUpdate={handleFormFields('legalContact.city')}
        label='City'
        allowClear
      />
      <FormItemWithoutEvent
        name='legalStreetName'
        placeholder='Breslauer Pl.'
        message='Street is required'
        functionUpdate={handleFormFields('legalContact.streetName')}
        label='Street name'
        allowClear
      />
      <FormItemWithoutEvent
        name='legalBuildingNumber'
        placeholder='4'
        message='Building number is required'
        functionUpdate={handleFormFields('legalContact.buildingNumber')}
        label='Building no.'
        allowClear
      />
      <Item
        name='legalPostalCode'
        label='Postal code'
        rules={postalCodeFieldRules}
      >
        <CustomInputNumber
          name='legalPostalCode'
          placeholder='50668'
          onChange={handleCustomNumericFormFields('legalContact.postalCode')}
          allowClear
        />
      </Item>
      <Item name='legalPhone' label='Phone number' rules={legalPhoneFieldRules}>
        <FormItemPhoneNumber
          name='legalPhone'
          placeholder={phoneNumberPlaceholderForCreateVenue}
          functionUpdate={handleLegalPhoneFields('legalContact.legalPhone')}
          value={legalPhoneValue}
          allowClear
        />
      </Item>
    </>
  );
};
export default BusinessAddress;

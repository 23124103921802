import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _debounce from 'lodash/debounce';

import {MenuItem} from './MenuItem';

import {useMutationObserver} from 'hooks/useMutationObserver';
import {getMenuItems} from './helpers';
import {
  AnchorMenuContainer,
  AnchorMenuList,
  AnchorMenuTitle,
  AnchorMenuWrapper,
} from './AnchorMenu.styles';
import {EMenuItemSizes} from './AnchorMenu.types';

const DEBOUNCE_TIME = 50;

type TSectionPosition = {
  top: number;
  bottom: number;
};

interface IProps {
  rootId?: string;
  sections: string[];
  highlightedSections?: string[];
  sectionsWithErrors?: string[];
}

const AnchorMenu = ({
  rootId,
  sections,
  highlightedSections,
  sectionsWithErrors,
}: IProps) => {
  /**
   * activeItem handles on scroll
   * clickedItem handles on click
   * we need it for avoiding case when we have several visible sections but clicked to someone which should be highlighted
   */
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [clickedItem, setClickedItem] = useState<string | null>(null);

  const scrollArea = rootId ? document.getElementById(rootId) : null;

  /*
   * The list of menu items, with their Y-pixel position on the page as the values
   * 'Top' generically references the top of the page
   */
  const items = useRef<{[index: string]: TSectionPosition}>(
    getMenuItems(sections),
  );

  const getRelativeParentPositions = useCallback(
    (childId: string) => {
      if (rootId) {
        const parentPos = document
          .getElementById(rootId)
          ?.getBoundingClientRect();
        const childPos = document
          .getElementById(childId)
          ?.getBoundingClientRect();

        if (parentPos && childPos) {
          return {
            top: childPos.top - parentPos.top,
            bottom: childPos.top + childPos.height - parentPos.top,
          };
        }
      }

      return {
        top: 0,
        bottom: 0,
      };
    },
    [rootId],
  );

  useEffect(() => {
    if (sections) {
      items.current = getMenuItems(sections);
    }
  }, [sections]);

  /*
   * Determine which section the user is viewing, based on their scroll-depth
   * Locating the active section allows us to update our MenuItems to show which
   * item is currently active
   */
  const handleScroll = useCallback(() => {
    const curPos = scrollArea ? scrollArea.scrollTop : window.scrollY;
    let curSection = null;
    const itemsCurrent = items.current;

    setClickedItem(null);

    /*
     * Iterate through our sections object to find which section matches with
     * the current scrollDepth of the user.
     * NOTE: This code assumes that the sections object is built with an 'ordered'
     * list of sections, with the lowest depth (top) section first and greatest
     * depth (bottom) section last
     * If your items are out-of-order, this code will not function correctly
     */

    for (const section in itemsCurrent) {
      const topPos = itemsCurrent[section]?.top;
      const bottomPos = itemsCurrent[section]?.bottom;

      curSection =
        curPos >= topPos && bottomPos > curPos ? section : curSection;

      if (curSection === section) break;
    }

    if (curPos < itemsCurrent[sections[0]]?.top && !activeItem) {
      setActiveItem(sections[0]);
    } else if (curSection && curSection !== activeItem) {
      setActiveItem(clickedItem || curSection);
    }
  }, [activeItem, clickedItem, scrollArea, sections]);

  /*
   * Programmatically determine where to set AnchorPoints
   */
  const getAnchorPoints = useCallback(() => {
    const curScrollPos = scrollArea?.scrollTop || 0;

    for (const key in items.current) {
      const el = scrollArea || document.getElementById(key);

      if (!el) break;

      const elPos = getRelativeParentPositions(key);

      items.current[key] = rootId
        ? {
            top: elPos.top + curScrollPos,
            bottom: elPos.bottom + curScrollPos,
          }
        : {
            top: el.offsetTop,
            bottom: el.offsetTop + el.clientHeight,
          };
    }
  }, [getRelativeParentPositions, rootId, scrollArea]);

  /*
   * The MutationObserver allows us to watch for a few different
   * events, including page resizing when new elements might be
   * added to the page (potentially changing the location of our
   * anchor points)
   * We also listen to the scroll event in order to update based
   * on scroll depth
   */
  useMutationObserver(document.getElementById('root'), getAnchorPoints);

  useEffect(() => {
    const debouncedHandleScroll = _debounce(handleScroll, DEBOUNCE_TIME);
    const el = scrollArea || window;

    el.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      el.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [getAnchorPoints, handleScroll, scrollArea]);

  /**
   * we need to call it once on mount for correct working of observer
   */
  const [isInitialised, setInitialised] = useState(false);
  useEffect(() => {
    if (!isInitialised) {
      getAnchorPoints();
      handleScroll();
      setInitialised(true);
    }
  }, [getAnchorPoints, handleScroll, isInitialised]);

  /*
   * Create the list of MenuItems based on the sections object we have defined above
   */

  const menuList = useMemo(() => {
    const handleClick = (value: string) => (e: SyntheticEvent) => {
      e.preventDefault();
      const el = document.getElementById(value);
      setClickedItem(value);
      setActiveItem(value);
      el?.scrollIntoView({behavior: 'smooth', block: 'start'});
    };

    return Object.keys(items.current).map((v) => (
      <MenuItem
        itemName={v}
        key={`anchor-link-${v}`}
        data-testid={`anchor-link-${v}`}
        isActive={v === activeItem}
        handleClick={handleClick(v)}
        size={EMenuItemSizes.SMALL}
        isHighlighted={highlightedSections?.includes(v)}
        hasErrors={sectionsWithErrors?.includes(v)}
      />
    ));
  }, [activeItem, highlightedSections, sectionsWithErrors]);

  return (
    <AnchorMenuWrapper>
      <AnchorMenuContainer>
        <AnchorMenuTitle>Contents</AnchorMenuTitle>
        <AnchorMenuList>{menuList}</AnchorMenuList>
      </AnchorMenuContainer>
    </AnchorMenuWrapper>
  );
};

export default AnchorMenu;

import React from 'react';

import {IVenue} from 'types/venue';

import {Title, Text} from '../../Header.styles';

const useContactsHeader = (venue: IVenue) => {
  const {email, phone} = venue.contact;

  return {
    title: 'Contacts',
    subtitle: 'No subtitle',
    items: 4,
    content: (
      <>
        <div data-testid='contacts-email'>
          <Title>Primary email</Title>
          <Text>{email || '-'}</Text>
        </div>
        <div data-testid='contacts-phone'>
          <Title>Primary phone number</Title>
          <Text>{phone || '-'}</Text>
        </div>
        <div />
      </>
    ),
  };
};

export default useContactsHeader;

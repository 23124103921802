import styled from 'styled-components';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  fontSizeSm,
  fontWeightExtraBold,
  lineHeightMd,
  mdBp,
  NW2Gray800Color,
  offsetDef,
  offsetXSm,
  offsetXXLg,
} from 'constants/styleVars';

export const StyledWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -${offsetXSm};
`;

export const StyledSwitchers = styled.div`
  display: flex;
  gap: ${offsetXXLg};
  margin-bottom: ${offsetXXLg};
`;

export const StyledButtonLink = styled(NW2Button)<{isActive: boolean}>`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: ${NW2Gray800Color};

  &:hover,
  &:active {
    > span {
      color: ${NW2Gray800Color};
    }
  }

  > span {
    &:before {
      background: ${NW2Gray800Color};

      opacity: ${({isActive}) => (isActive ? 1 : 0)};
    }
  }
`;

export const StyledImageItem = styled.div`
  width: 50%;
  position: relative;

  &:nth-child(5n + 1) {
    width: 100%;
  }

  & + div {
    margin-top: ${offsetDef};
  }

  > div {
    > div {
      padding: 0 ${offsetXSm};
    }
  }
`;

export const MorePhotosBtn = styled(NW2Button)`
  display: none;
  position: absolute;
  bottom: ${offsetDef};
  right: 35px;

  @media (min-width: ${mdBp}px) {
    display: flex;
  }
`;

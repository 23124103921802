import {useEffect} from 'react';
import {BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

export function FocusPlugin({setFocus}: {setFocus: (value: boolean) => void}) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setFocus(false);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [editor, setFocus],
  );

  useEffect(
    () =>
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setFocus(true);
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [editor, setFocus],
  );

  return null;
}

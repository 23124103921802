import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {notification} from 'antd';

import Icon from 'view/components/Icon';

import {getAcceptTypes} from './helpers';
import {
  FILE_UPLOAD_MAX_SIZE,
  FILE_UPLOAD_MIN_SIZE,
  MAX_FILES_LIMIT,
  maximumUploadFileSize,
  minimumUploadFileSize,
} from 'constants/app';
import {
  Container,
  InputLayout,
  InputLayoutSubTitle,
  InputLayoutTitle,
  StyledAnimatedIcon,
} from './Uploader.styles';
import {
  EUploadedFileType,
  TFileWithPreview,
  THandleUpdateImages,
  TUploadFile,
} from './types';

interface IProps {
  uploadedFile?: TUploadFile | null;
  uploadedFileType: EUploadedFileType;
  isMultiple?: boolean;
  label?: string;
  maxFiles?: number;
  onFileUpload: (
    file: TUploadFile,
    handleProcess: (percents: number) => void,
  ) => void;
  onRequest?: THandleUpdateImages;
  onFileDelete?: (id: string) => void;
}
export function useUploader({
  uploadedFile,
  uploadedFileType,
  isMultiple = false,
  label = 'Upload image',
  maxFiles,
  onFileUpload,
  onRequest,
  onFileDelete,
}: IProps) {
  const [files, setFiles] = useState<TFileWithPreview[]>([]);
  const [isFileSent, setFileSent] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [isUploading, setUploading] = useState(false);

  const isFile = uploadedFileType === EUploadedFileType.PDF_FILE;

  const handleProcess = useCallback((value: number) => {
    setProgress(value);
  }, []);

  const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} =
    useDropzone({
      maxFiles: maxFiles || MAX_FILES_LIMIT,
      maxSize: FILE_UPLOAD_MAX_SIZE,
      minSize: FILE_UPLOAD_MIN_SIZE,
      accept: getAcceptTypes(uploadedFileType),
      multiple: isMultiple,
      onError: (err) => {
        notification.error({
          message: err?.message,
          duration: 4,
        });
      },
      onDrop: (acceptedFiles, fileRejections) => {
        if (fileRejections.length) {
          const err = fileRejections[0].errors[0];
          const file = fileRejections[0].file;

          let message = '';
          if (err.code === 'file-too-large') {
            message = `${file.name} must be smaller than ${maximumUploadFileSize}`;
          }
          if (err.code === 'file-too-small') {
            message = `${file.name} must be bigger than ${minimumUploadFileSize}`;
          }
          if (err.code === 'file-invalid-type') {
            message = `${file.name} has invalid format`;
          }

          notification.error({
            message: message || err.message,
            duration: 4,
          });

          return;
        }

        setFiles([]);
        setFileSent(false);
        setUploading(true);
        setFiles(
          acceptedFiles.map((file: any) => {
            // TODO1: check flow with preview while connecting BE and if need delete blob or preview
            const blob = URL.createObjectURL(file);

            onFileUpload(
              {
                uid: file.uid,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                type: file.type,
                originFileObj: file,
                uploadDate: new Date(),
                blob,
              },
              handleProcess,
            );

            return Object.assign(file, {
              preview: blob,
            });
          }),
        );
      },
    });

  const handleProgress = useCallback((value: number) => {
    setProgress(value);
  }, []);

  useEffect(() => {
    // auto upload
    if (files.length > 0 && !isFileSent) {
      setFileSent(true);
      if (onRequest) onRequest({handleProgress});
    }
  }, [files.length, handleProgress, onRequest, isFileSent]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      files.forEach((file: TFileWithPreview) =>
        URL.revokeObjectURL(file.preview),
      );
    };
  }, [files]);

  const handleReset = useCallback(() => {
    setFiles([]);
    setFileSent(false);
    setUploading(false);
    setProgress(0);
    if (onFileDelete) onFileDelete(uploadedFile?.id || '');
  }, [onFileDelete, uploadedFile?.id]);

  const handleUploading = useCallback((val: boolean) => {
    setUploading(val);
  }, []);

  return {
    isUploading,
    handleUploading,
    files,
    progress,
    handleReset,
    uploadComponent: (
      <Container
        isFocused={isFocused}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        data-testid='image upload input'
      >
        {isUploading ? (
          <InputLayout>
            <StyledAnimatedIcon icon='PROCESSING' NW2Gray400Color />
            <InputLayoutTitle>Uploading...</InputLayoutTitle>
          </InputLayout>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <InputLayout>
              <Icon icon='NW2_UPLOAD' NW2Gray400Color />
              <InputLayoutTitle>{label}</InputLayoutTitle>
              <InputLayoutSubTitle>
                {isFile
                  ? 'Click or drag a PDF File here to upload'
                  : '(click or drag in image to this area)'}
              </InputLayoutSubTitle>
            </InputLayout>
          </div>
        )}
      </Container>
    ),
  };
}

import React from 'react';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';

import {IProps} from './NW2Badge.types';

const NW2Badge = ({
  isActive = false,
  label,
  fullWidth,
  disabled,
  onClick,
  size,
  iconSize = 14,
}: IProps) => {
  return (
    <NW2Button
      buttonType={isActive ? 'badge' : 'chip'}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      iconPlace='right'
      icon={isActive && <Icon icon='NW2_TICK' transparent size={iconSize} />}
      size={size}
      data-testid={`badge-${label}`}
    >
      {label}
    </NW2Button>
  );
};

export default NW2Badge;

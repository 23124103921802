import {useParams, useSearchParams} from 'react-router-dom';
import {useAppSelector} from 'store/hooks';
import {EEventType} from 'types/venue';
import {IGroupDetails, TShortListItem} from 'types/offer';

export const useGroupRequest = () => {
  const [searchParams] = useSearchParams();

  const groupDetails = useAppSelector(
    ({offers}) => offers.groupDetails,
  ) as IGroupDetails;
  const shortList = useAppSelector(({offers}) => offers.shortList);

  const requestAndOfferGroup = [
    ...(groupDetails?.requests || []),
    ...(groupDetails?.offers || []),
  ];
  const isGroupMultiRequest = requestAndOfferGroup.length > 1;
  const isSingleDay = searchParams.get('multiRooms') === 'false';
  const isMultiRequest =
    searchParams.get('isMultiRequest') === 'true' || isGroupMultiRequest;

  const {venueID = ''} = useParams<{
    venueID: string;
  }>();

  const requestGroupIds = requestAndOfferGroup.map(
    ({accommodationId}) => accommodationId,
  );

  const paramsVenueIds = isMultiRequest
    ? venueID.split(',').map((id) => Number(id))
    : [Number(venueID)];

  const multiVenueIds = venueID ? paramsVenueIds : requestGroupIds;

  const multiRequestHeader = requestAndOfferGroup.length
    ? requestAndOfferGroup.map<TShortListItem>(({venue}) => ({
        id: venue.id,
        name: venue.name,
        location: venue.location,
        img: venue.documents[0]?.url,
      }))
    : shortList.items;

  const firstRequestInGroup = groupDetails?.requests[0];

  // check if request belongs to group
  const isSingleRegisteredVenue =
    multiVenueIds.length === 1 ||
    (requestAndOfferGroup.length === 1 && !firstRequestInGroup.isAlternative);

  // flag for showing totalPrice in right section
  const isTotalGroupRequest =
    requestAndOfferGroup?.length === 1 &&
    !!firstRequestInGroup?.totalRequestPrice?.value &&
    !firstRequestInGroup.isAlternative &&
    firstRequestInGroup.days.every(
      ({eventType}) => eventType === EEventType.DAY,
    );

  return {
    isMultiRequest,
    multiVenueIds,
    multiRequestHeader,
    requestGroup: groupDetails?.requests,
    isTotalGroupRequest,
    isSingleRegisteredVenue,
    isSingleDay,
  };
};

import {
  EBookableWith,
  EPriceType,
  EResourcesType,
} from 'types/dto/IExtras.type';
import {ERoomType} from 'types/dto/ERoomType.type';

export enum EResourcesHashes {
  EQUIPMENT = 'equipment',
  FOOD_AND_BEVERAGE = 'foodBeverage',
  PACKAGES = 'packages',
}

export enum EResourceAvailability {
  MEETING_ROOMS = 'Meeting rooms',
  WORK_DESKS = 'Work desks',
}

export enum EPayOptions {
  FREE = 'Free',
  PAID = 'Paid',
}

export const ResourcesTypeObject: Record<EResourcesHashes, EResourcesType> = {
  [EResourcesHashes.EQUIPMENT]: EResourcesType.EQUIPMENT,
  [EResourcesHashes.FOOD_AND_BEVERAGE]: EResourcesType.FOOD_AND_BEVERAGE,
  [EResourcesHashes.PACKAGES]: EResourcesType.PACKAGES,
};

export const availableWithServices = {
  [EBookableWith.MEETING_ROOM]: EResourceAvailability.MEETING_ROOMS,
  [EBookableWith.WORK_SPACE]: EResourceAvailability.WORK_DESKS,
};

export enum EServiceStatus {
  ACTIVE = 'Active',
  IN_PROGRESS = 'In progress',
  DISABLED = 'Disabled',
}

export type TEditType = (id: string, isEnabled?: boolean) => void;

export interface IExtraItem {
  id: number;
  name: string;
  extraType: EResourcesType;
  bookableWith: ERoomType[];
  price: number | string;
  isEnabled: boolean;
  priceType: EPriceType;
}

import React from 'react';

import {useAppDispatch} from 'store/hooks';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import BtcRequestLink from './BtcRequestLink';

import {ERoomType} from 'types/dto/ERoomType.type';
import {
  setSearchCriteriaRoomType,
  setSearchFocusedInput,
} from 'store/search/searchSlice';
import {setHeaderCollapsed} from 'store/app/appSlice';
import {DATE_ID, PARTICIPANTS_ID} from 'constants/app';

import {StyledNavLink} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {StyledLink} from '../../NW2VenuesListComponent.styles';
import {useIsFastSearchEnabled} from 'view/venue/hooks/useIsFastSearchEnabled';

type TProps = {
  roomType: ERoomType;
  startNewSearchHandler: () => void;
};

function NoFoundBlockListItems({roomType, startNewSearchHandler}: TProps) {
  const dispatch = useAppDispatch();
  const {isBTCApp, postToFlutterApp} = useFlutterWebView();
  const [isFastSearchEnabled] = useIsFastSearchEnabled();

  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;
  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  const startDateHandler = () => {
    if (isBTCApp) return postToFlutterApp();
    dispatch(setSearchFocusedInput(DATE_ID));
    dispatch(setHeaderCollapsed(false));
    const dateInput = document.getElementById(DATE_ID);
    dateInput?.click();
  };
  const startParticipantsHandler = () => {
    if (isBTCApp) return postToFlutterApp();
    dispatch(setSearchFocusedInput(PARTICIPANTS_ID));
    dispatch(setHeaderCollapsed(false));
    const participantsInput = document.getElementById(PARTICIPANTS_ID);
    participantsInput?.focus();
  };
  const toggleSearchCriteriaRoomType =
    (roomType: ERoomType) => (event: React.SyntheticEvent) => {
      if (isBTCApp) {
        event.preventDefault();
        return postToFlutterApp();
      }
      dispatch(setHeaderCollapsed(false));
      dispatch(setSearchCriteriaRoomType(roomType));
    };

  return (
    <>
      <li>
        <StyledLink onClick={startNewSearchHandler}>
          Search for a different destination
        </StyledLink>
      </li>

      {!isBTCApp && (
        <>
          <li>
            <StyledLink onClick={startDateHandler}>
              Search for a different time
            </StyledLink>
          </li>

          {isMeetingRoom && isFastSearchEnabled && (
            <li>
              <StyledLink onClick={startParticipantsHandler}>
                Adjust number of participants
              </StyledLink>
            </li>
          )}
        </>
      )}

      <li>
        {roomType !== ERoomType.MEETING_ROOM ? (
          <StyledNavLink
            to='/'
            onClick={toggleSearchCriteriaRoomType(ERoomType.MEETING_ROOM)}
          >
            Search for a meeting space instead
          </StyledNavLink>
        ) : (
          <StyledNavLink
            to='/'
            onClick={toggleSearchCriteriaRoomType(ERoomType.WORK_SPACE)}
          >
            Search for a work desk instead
          </StyledNavLink>
        )}
      </li>

      <BtcRequestLink
        isBTCApp={isBTCApp}
        isMeetingRoom={isMeetingRoom}
        isWorkSpace={isWorkSpace}
      />
    </>
  );
}

export default NoFoundBlockListItems;

import React, {memo, ReactNode, useCallback, useMemo} from 'react';

import AdditionalActions from './AdditionalActions/AdditionalActions';
import TypeAndCapacityFormItem from './TypeAndCapacity/TypeAndCapacityFormItem';
import Icon from 'view/components/Icon';

import {NameAndFloorFormItems} from './NameAndFloor/NameAndFloorFormItems';
import {SpaceDetailsImagesWip} from './SpaceDetailsImages/SpaceDetailsImagesWip';
import {DescriptionFormItems} from './Description/DescriptionFormItems';
import {PricesFormItems} from './Price/PricesFormItems';
import {AmenitiesFormItems} from './SpaceAmenities/AmenitiesForm/AmenitiesFormItems';
import {AddRoomSchema} from 'view/venue/NW2VenueProfile/components/AddSpace/components';
import {DimensionsFormItems} from './Dimensions/DimensionsFormItems';
import {BookabilityTypeFormItems} from './BookabilityType/BookabilityTypeFormItems';

import {ISpacesDataItem, ISpacesDataItemImage, TVenueFloors} from 'types/venue';
import {ESpaceDetailsContentSections} from '../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {Section} from './SpaceDetailsContentWip.styles';
import {StyledSectionTitle} from '../NMMSpacesDetails.styles';
import {EAddMeetingRoomFormFields} from 'view/venue/NW2VenueProfile/components/AddSpace/types';

type TSectionRowProps = {
  title: ESpaceDetailsContentSections;
  errorSections?: Set<ESpaceDetailsContentSections>;
  children: ReactNode;
};

const SectionRow = memo(
  ({title, errorSections, children}: TSectionRowProps) => {
    const isInvalid = errorSections?.has(title);

    return (
      <Section id={title} isInvalid={isInvalid}>
        <StyledSectionTitle>
          {isInvalid && <Icon icon='TRIANGLE_WARN' NW2Warn500Color />}

          {title}
        </StyledSectionTitle>

        {children}
      </Section>
    );
  },
);

type TProps = {
  space: ISpacesDataItem;
  floors: TVenueFloors;
  roomType: ERoomType;
  sectionName: ESpaceDetailsContentSections;
  imagesToUpload: (TFileWithPreview | ISpacesDataItemImage)[];
  onImageUpload: (file: TFileWithPreview[]) => void;
  currencySymbol?: string;
  onFormChange: (name: any, value?: any) => void;
  amenitiesLocal: Record<string, ReactNode[]>;
  // For WIP
  errorSections: Set<ESpaceDetailsContentSections>;
};

const SpaceDetailsWipSection = ({
  space,
  floors,
  sectionName,
  roomType,
  imagesToUpload,
  onImageUpload,
  currencySymbol,
  onFormChange,
  amenitiesLocal,
  errorSections,
}: TProps) => {
  const {id} = space;

  const roomSections = useMemo(
    () => ({
      [ESpaceDetailsContentSections.NAME_AND_LOCATION]: (
        <NameAndFloorFormItems floors={floors} />
      ),
      [ESpaceDetailsContentSections.IMAGES]: (
        <SpaceDetailsImagesWip
          imagesToUpload={imagesToUpload}
          onImageUpload={onImageUpload}
        />
      ),
      [ESpaceDetailsContentSections.DESCRIPTION]: <DescriptionFormItems />,
      [ESpaceDetailsContentSections.PRICES]: (
        <PricesFormItems
          currencySymbol={currencySymbol}
          onFormChange={onFormChange}
        />
      ),
      [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: (
        <AmenitiesFormItems
          amenities={
            amenitiesLocal[ESpaceDetailsContentSections.INCLUDED_IN_SPACE]
          }
        />
      ),
      [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: (
        <AmenitiesFormItems
          amenities={
            amenitiesLocal[
              ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY
            ]
          }
        />
      ),
      [ESpaceDetailsContentSections.SETUPS_AND_CAPACITIES]: (
        <AddRoomSchema
          columns={2}
          fieldName={EAddMeetingRoomFormFields.CAPACITIES}
        />
      ),
      [ESpaceDetailsContentSections.DIMENSIONS]: (
        <DimensionsFormItems onFormChange={onFormChange} />
      ),
      [ESpaceDetailsContentSections.BOOKABILITY_TYPE]: (
        <BookabilityTypeFormItems />
      ),
      [ESpaceDetailsContentSections.ADDITIONAL_ACTIONS]: (
        <AdditionalActions spaceId={id} isWip />
      ),
    }),
    [
      amenitiesLocal,
      currencySymbol,
      floors,
      id,
      imagesToUpload,
      onFormChange,
      onImageUpload,
    ],
  );

  const deskSections = useMemo(
    () => ({
      [ESpaceDetailsContentSections.NAME_AND_LOCATION]: (
        <NameAndFloorFormItems floors={floors} />
      ),
      [ESpaceDetailsContentSections.SPACE_TYPE_AND_CAPACITY]: (
        <TypeAndCapacityFormItem />
      ),
      [ESpaceDetailsContentSections.IMAGES]: (
        <SpaceDetailsImagesWip
          imagesToUpload={imagesToUpload}
          onImageUpload={onImageUpload}
        />
      ),
      [ESpaceDetailsContentSections.DESCRIPTION]: <DescriptionFormItems />,
      [ESpaceDetailsContentSections.PRICES]: (
        <PricesFormItems
          currencySymbol={currencySymbol}
          onFormChange={onFormChange}
          hasPriceHalfDay={false}
        />
      ),
      [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: (
        <AmenitiesFormItems
          amenities={
            amenitiesLocal[ESpaceDetailsContentSections.INCLUDED_IN_SPACE]
          }
        />
      ),
      [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: (
        <AmenitiesFormItems
          amenities={
            amenitiesLocal[
              ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY
            ]
          }
        />
      ),
      [ESpaceDetailsContentSections.ADDITIONAL_ACTIONS]: (
        <AdditionalActions spaceId={id} />
      ),
    }),
    [
      floors,
      imagesToUpload,
      onImageUpload,
      amenitiesLocal,
      id,
      currencySymbol,
      onFormChange,
    ],
  );

  const getSections = useCallback(
    (sectionName: ESpaceDetailsContentSections, roomType: ERoomType) => {
      if (roomType === ERoomType.WORK_SPACE) {
        return deskSections[sectionName as keyof typeof deskSections];
      }

      if (roomType === ERoomType.MEETING_ROOM) {
        return roomSections[sectionName as keyof typeof roomSections];
      }
    },
    [deskSections, roomSections],
  );

  return (
    <SectionRow title={sectionName} errorSections={errorSections}>
      {getSections(sectionName, roomType)}
    </SectionRow>
  );
};

export default memo(SpaceDetailsWipSection);

import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppSelector} from 'store/hooks';

import MainNavigation from 'view/common/Header/components/MainNavigation';
import withBridgeBTC from 'utils/bridgeBTC/withBridgeBTC';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

// Header component includes one component MainNavigation where you pass two props:
// 1. role
// 2. location
// based on them it will build layout
// If you want to customize the layout(show/hide some elements, change order or add some styles)
// you need to know next:
// 1. menuConfig folder includes menus that based on role, menu has following fields
//    - layout: {
//        defaultLayoutType: defaultConfig, - default type that will be applied to menu of current role
//        customRoutesLayout - object where key is route to specific page and value is the layout config
//      }
//    - navList array of objects where you specify navigation url and text for navigation link
// 2. layoutConfig folder includes configs for layout where you should define
//    - type (type will be passed to StyledHeader as props in Header.styles, )
//    - components (array of components that will be displayed in header)
// 3. components folder includes all header components

const HeaderComponent: FC = () => {
  const location = useLocation();
  const cognitoUserRole: EUserRoleCognito = useAppSelector(
    ({app}) => app.user.role,
  );

  return <MainNavigation role={cognitoUserRole} location={location} />;
};

export default withBridgeBTC(HeaderComponent);

import {useEffect} from 'react';

import {getDynamicCancellationPolicy} from 'store/cancellationPolicy/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getCancellationType} from 'view/components/CancellationPolicyBlock/helpers';

import {
  IDynamicCancellationPolicy,
  IDynamicCancellationPolicyPayload,
} from 'types/dto/ICancellationPolicy.type';
import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';

const useDynamicCancellationPolicyData = ({
  participants,
  hasCatering,
  checkIn,
  venueId,
  eventType,
  orderNumber,
  requestNumber,
  offerNumber,
  isCanceledStatus,
}: IDynamicCancellationPolicyPayload) => {
  const dispatch = useAppDispatch();

  const dynamicCancellationPolicy = useAppSelector(
    ({cancellationPolicy}) => cancellationPolicy.dynamicCancellationPolicy,
  );

  const isLoading = useAppSelector(
    ({cancellationPolicy}) =>
      cancellationPolicy.isDynamicCancellationPolicyLoading,
  );

  useEffect(() => {
    dispatch(
      getDynamicCancellationPolicy({
        participants,
        hasCatering,
        checkIn,
        venueId,
        eventType,
        orderNumber,
        requestNumber,
        offerNumber,
      }),
    );
  }, [
    checkIn,
    dispatch,
    eventType,
    hasCatering,
    offerNumber,
    orderNumber,
    participants,
    requestNumber,
    venueId,
  ]);

  const getCancellationPolicyData = (
    data?: IDynamicCancellationPolicy | null,
  ) => {
    let statusData;
    let conditionsList;
    let cancellationType = EInfoCardType.warn;

    if (data && data.policies.length !== 0) {
      statusData = data.policies[0];
      conditionsList = data.policies.slice(1);

      cancellationType = getCancellationType({
        freeOfCharge: statusData.freeOfCharge,
        days: statusData.days,
        isCanceledStatus,
      });
    }

    return {
      statusData,
      conditionsList,
      cancellationType,
    };
  };

  const {statusData, conditionsList, cancellationType} =
    getCancellationPolicyData(dynamicCancellationPolicy);

  return {
    statusData,
    conditionsList,
    cancellationType,
    isLoading,
  };
};

export default useDynamicCancellationPolicyData;

import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import ForgotResetPasswordModal from './ForgotResetPasswordModal';
import LoginModal from './LoginModal';
import RegisterModal from 'view/auth/Register/RegisterModal';
import PasswordModal from 'view/auth/setPasswordFlow/PasswordModal';

import {
  setOpenForgotPasswordPopup,
  setOpenLoginPopup,
  setOpenRegisterPopup,
} from 'store/app/appSlice';

export enum EAuthActions {
  CONFIRM_REGISTRATION = 'confirm_registration',
  CONFIRM_FORGOT_PASSWORD = 'confirm_forgot_password',
  CONFIRM_ALREADY_REGISTERED = 'confirm_already_registered',
}

const Login: FC = () => {
  const dispatch = useDispatch();
  const {
    openLoginPopup,
    openRegisterPopup,
    openPasswordPopup,
    openForgotPasswordPopup,
    loginTitle,
    loginDescription,
  } = useAppSelector(({app}) => app.authorizationPopups);

  const toggleLoginRegisterModals = () => {
    dispatch(setOpenLoginPopup({openLoginPopup: !openLoginPopup}));
    dispatch(setOpenRegisterPopup({openRegisterPopup: !openRegisterPopup}));
  };

  const setIsForgotPopupVisible = (openForgotPasswordPopup: boolean) => {
    dispatch(setOpenForgotPasswordPopup({openForgotPasswordPopup}));
  };

  return (
    <>
      <LoginModal
        isLoginPopupVisible={openLoginPopup ?? false}
        setIsForgotPopupVisible={setIsForgotPopupVisible}
        toggleLoginRegisterModals={toggleLoginRegisterModals}
        loginTitle={loginTitle || ''}
        loginDescription={loginDescription || ''}
      />
      <RegisterModal
        isRegisterVisible={openRegisterPopup ?? false}
        toggleLoginRegisterModals={toggleLoginRegisterModals}
      />
      <ForgotResetPasswordModal
        isForgotPopupVisible={openForgotPasswordPopup ?? false}
        setIsForgotPopupVisible={setIsForgotPopupVisible}
      />
      <PasswordModal openPasswordModal={openPasswordPopup ?? false} />
    </>
  );
};

export default Login;

import React, {useEffect} from 'react';
import {useForm} from 'react-final-form';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import UserDetails from 'view/venue/NW2BookingPreview/components/UserDetails/UserDetails';
import BillingAddressViewWithReferenceField from 'view/components/BillingAddress/BillingAddressViewWithReferenceField';
import NW2SpaceDetails from 'view/venue/NW2VenueDetails/components/NW2SpaceDetails/NW2SpaceDetails';
import BillingAddressForm from 'view/components/BillingAddress/BillingAddressForm';
import AboutMeetingForm from 'view/venue/NW2BookingPreview/components/AboutMeetingForm/AboutMeetingForm';
import CustomerTermsAndPolicyBlock from 'view/common/CustomerTermsAndPolicyBlock';
import {OfferRequestLeftSideSpaces} from './components/OfferRequestLeftSideSpaces';
import {OfferReviewLeftSideSpaces} from './components/OfferReviewLeftSideSpaces';
import {OptionDate} from './components/OptionDate/OptionDate';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';
import {BookingTermsAndConditions} from 'view/venue/BookingTermsAndConditions/BookingTermsAndConditions';
import {WhatIsNext} from '../WhatIsNext/WhatIsNext';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EPaymentType} from 'types/bookingOrders';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EFormValidationName} from 'types/venue';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {IOfferRequestDay} from 'types/offer';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {TSearchVenuesDay} from 'types/search';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';
import {BookingPayment} from 'view/venue/BookingPayment/BookingPayment';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';

type TProps = {
  isOfferRequest?: boolean;
  isOfferPreview?: boolean;
  preArrivals?: TSearchVenuesDay[] | IOfferRequestDay[];
  postEvents?: TSearchVenuesDay[] | IOfferRequestDay[];
  isAlternative?: boolean;
  isShowLoginModal?: boolean;
};
export function OfferRequestLeftSidePreview({
  isOfferRequest,
  isOfferPreview,
  preArrivals,
  postEvents,
  isShowLoginModal,
  isAlternative,
}: TProps) {
  const dispatch = useAppDispatch();
  const form = useForm();

  const user = useAppSelector(({app}) => app.user);
  const billingAddresses = useAppSelector(
    ({app}) => app.user.billingAddress || [],
  );
  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);
  const {eventName, status, number, cancellationPolicy, termsAndConditionsId} =
    offerDetails;

  const isRegistrationAction = useAppSelector(
    ({app}) => app.isRegistrationAction,
  );

  const paymentType = useAppSelector(({payment}) => payment.paymentType);
  const corporateBillingAddress = useAppSelector(
    ({payment}) => payment.corporateBillingAddress,
  );
  const defaultBillingAddress = useAppSelector(
    ({app}) => app.user.defaultBillingAddress,
  );

  const {previewRooms} = useCustomerOrderUnits();

  const isAgentRole = user.role === EUserRoleCognito.ROLE_AGENT;
  const isGuestRole = user.role === EUserRoleCognito.ROLE_GUEST;

  const isDirectPayment = paymentType === EPaymentType.DIRECT_PAYMENT;
  const isCorporatePayment = paymentType === EPaymentType.CORPORATE_HRS_PAY;

  useEffect(() => {
    if (isShowLoginModal && !isRegistrationAction) {
      dispatch(setOpenLoginPopup({openLoginPopup: true}));
    }
  }, [dispatch, isShowLoginModal, isRegistrationAction]);

  useEffect(() => {
    if (!previewRooms.length) return;
    form.change('orderDays', previewRooms);
  }, [form, previewRooms]);

  useEffect(() => {
    const defaultBillingAddressValues = {
      companyName: '',
      costCenter: '',
      country: '',
      city: '',
      postCode: '',
      streetHouseNumber: '',
      uuid: '',
    };
    const billingAddress: Partial<ICustomerBillingAddress> =
      user.billingAddress.find(
        (address: ICustomerBillingAddress) => address.isDefault,
      ) || defaultBillingAddressValues;

    form.batch(() => {
      form.change('firstName', user.firstName);
      form.change('lastName', user.lastName);
      form.change('email', user.email);
      form.change('phone', user.phone);

      // iterate object to set keys and values to form
      for (const key in defaultBillingAddressValues) {
        form.change(key, billingAddress[key as keyof ICustomerBillingAddress]);
      }
    });
  }, [form, user]);

  const hasBillingAddress = billingAddresses.some(
    (address: ICustomerBillingAddress) => address.isDefault,
  );

  const isMultiRooms = previewRooms.length > 1;
  const singleVenueUnit =
    !isMultiRooms && previewRooms.length ? previewRooms[0].units[0] : undefined;

  const isMeetingRoom =
    isMultiRooms || singleVenueUnit?.type === ERoomType.MEETING_ROOM;

  const hasPreArrivals = !!preArrivals?.length;
  const hasPostEvents = !!postEvents?.length;

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  return (
    <StyledLeftSideContainer>
      {isOfferRequest && (
        <LeftSideItem
          item={{
            title: 'Option date',
            children: <OptionDate />,
            formValidationName: EFormValidationName.OPTION_DATE_BLOCK,
          }}
        />
      )}

      {hasPreArrivals && (
        <Bedrooms
          rooms={preArrivals}
          isOfferPreview={isOfferPreview}
          title='PRE-ARRIVAL'
        />
      )}

      {isOfferRequest && (
        <OfferRequestLeftSideSpaces
          hasPreArrivals={hasPreArrivals}
          hasPostEvents={hasPostEvents}
          isAlternative={isAlternative}
        />
      )}

      {isOfferPreview && (
        <OfferReviewLeftSideSpaces
          hasPreArrivals={hasPreArrivals}
          hasPostEvents={hasPostEvents}
          isAlternative={isAlternative}
        />
      )}

      {hasPostEvents && (
        <Bedrooms
          rooms={postEvents}
          isOfferPreview={isOfferPreview}
          title='POST-EVENT'
        />
      )}

      <LeftSideItem
        item={{
          title: 'About meeting',
          children: (
            <AboutMeetingForm
              eventName={isOfferPreview ? eventName : undefined}
            />
          ),
        }}
        isHidden={!isMeetingRoom || (isAgentRole && !eventName)}
      />

      <LeftSideItem
        item={{
          title: 'Your details',
          subtitle: isAgentRole ? '' : '(Required)',
          children: <UserDetails isShowSignInCard={isShowLoginModal} />,
          formValidationName: EFormValidationName.YOUR_DETAILS_BLOCK,
        }}
        isHidden={isGuestRole}
      />

      {isOfferPreview && (
        <>
          <LeftSideItem
            item={{
              title: 'Payment information',
              subtitle: '(Required)',
              children: <BookingPayment />,
              formValidationName: EFormValidationName.PAYMENT_BLOCK,
            }}
            isHidden={!isHrsPayEnabled}
          />

          <LeftSideItem
            item={{
              title: 'Billing address',
              subtitle: isAgentRole ? '' : '(Required)',
              children:
                hasBillingAddress || !!corporateBillingAddress ? (
                  <BillingAddressViewWithReferenceField
                    isAgentRole={isAgentRole}
                    paymentType={paymentType}
                    billingAddress={
                      isDirectPayment || !isHrsPayEnabled
                        ? defaultBillingAddress
                        : corporateBillingAddress
                    }
                  />
                ) : (
                  <BillingAddressForm />
                ),
              formValidationName: EFormValidationName.BILLING_ADDRESS_BLOCK,
            }}
            isHidden={isCorporatePayment && !corporateBillingAddress}
          />

          <LeftSideItem
            item={{
              title: 'Terms and Conditions',
              children: (
                <CustomerTermsAndPolicyBlock
                  bookingStatus={status}
                  cancellationPolicy={cancellationPolicy}
                  termsAndConditionsId={termsAndConditionsId}
                  orderNumber={number}
                />
              ),
            }}
          />
        </>
      )}

      {isOfferRequest && (
        <>
          <LeftSideItem
            item={{
              title: 'Terms and Conditions',
              children: (
                <BookingTermsAndConditions termsLinkId={termsAndConditionsId} />
              ),
            }}
          />

          <LeftSideItem
            item={{
              title: 'What’s next',
              children: <WhatIsNext isGroupRequest />,
            }}
          />
        </>
      )}

      <NW2SpaceDetails isFooterHidden />
    </StyledLeftSideContainer>
  );
}

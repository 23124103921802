import React, {ReactNode, useCallback, useState} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';

import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import NMMSubmitSection from 'view/components/NMMSubmitSection';
import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';
import {EditAccommodationButtons} from './EditAccommodationButtons';

import {
  BEDROOM_EXTRAS,
  ACCOMMODATION_FORM_FIELD_NAME,
} from 'constants/bedrooms';
import {
  TBedroomsCatering,
  THandleSetBedroomsCatering,
  TInitialFormValues,
} from '../NW2SearchSection/components/SearchForm/components/GroupsRooms/types';
import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {offsetDef, offsetXLg, offsetXSm} from 'constants/styleVars';
import {FlexContainer} from '../NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/AddFoodBeverageRequest.styles';
import {MAX_EXTRAS_NUMBER, MIN_EXTRAS_NUMBER} from 'constants/app';
import {StyledErrorMessage} from 'view/components/NW2FormItem/NW2FormItem.styles';

type TProps = {
  initialValues: TInitialFormValues | Record<string, never>;
  options: string[];
  onSave: (payload: Record<string, any>) => void;
  onCancel: () => void;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  date: string;
  errorMsg?: string;
  actionsSection?: ReactNode;
  size?: 'medium' | 'large';
};
export function EditBedrooms({
  initialValues,
  options,
  bedroomsCatering,
  onCancel,
  onSave,
  handleSetBedroomsCatering,
  date,
  errorMsg,
  actionsSection,
  size = 'medium',
}: TProps) {
  const [isCateringSwitched, setCateringSwitched] = useState(false);

  const handleSetCateringSwitched = (value: boolean) =>
    setCateringSwitched(value);

  const handleSetCateringEnabled = useCallback(
    ({formState, name, roomType}: any) => {
      /**
       * setTimeout prevents FormSpy issue
       * https://github.com/final-form/react-final-form/issues/809
       */
      setTimeout(() => {
        handleSetBedroomsCatering({
          roomType,
          isEnabled:
            formState.values?.[ACCOMMODATION_FORM_FIELD_NAME]?.includes(name),
          date,
        });
      }, 0);
    },
    [date, handleSetBedroomsCatering],
  );

  return (
    <Form onSubmit={onSave} initialValues={initialValues}>
      {({handleSubmit, submitting, pristine, values}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup columnNumber={1} gap={parseInt(offsetDef)}>
            {options.map((name) => {
              const isNumericInputVisible =
                values?.[ACCOMMODATION_FORM_FIELD_NAME]?.includes(name);

              const roomType =
                name === BEDROOM_EXTRAS.SINGLE ? 'single' : 'double';

              return (
                <div key={`accommodation-item-${name}`}>
                  <FlexContainer
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <NW2FormItemCheckbox
                      id={name}
                      type='checkbox'
                      name='accommodation'
                      label={name}
                      value={name}
                    />

                    {isNumericInputVisible && (
                      <Field name={name} initialValue={values[name]}>
                        {({input}) => (
                          <NW2NumericInput
                            minValue={MIN_EXTRAS_NUMBER}
                            maxValue={MAX_EXTRAS_NUMBER}
                            initialValue={input.value}
                            size={size}
                            onChange={(e) => {
                              handleSetBedroomsCatering({
                                roomType,
                                qty: e,
                                date,
                              });
                              input.onChange(e);
                            }}
                            hasInput
                          />
                        )}
                      </Field>
                    )}
                  </FlexContainer>

                  {isNumericInputVisible && (
                    <EditAccommodationButtons
                      activeButton={bedroomsCatering[date][roomType]}
                      roomType={roomType}
                      handleSetBedroomsCatering={handleSetBedroomsCatering}
                      handleSetCateringSwitched={handleSetCateringSwitched}
                      date={date}
                    />
                  )}

                  <FormSpy
                    subscription={{values: true}}
                    onChange={(formState) => {
                      handleSetCateringEnabled({formState, name, roomType});
                    }}
                  />
                </div>
              );
            })}
          </FormGroup>

          {errorMsg && (
            <StyledErrorMessage margin={`${offsetXLg} 0 -${offsetXSm}`}>
              {errorMsg}
            </StyledErrorMessage>
          )}

          {actionsSection || (
            <NMMSubmitSection
              submitLabel='save'
              handleCancel={onCancel}
              isLoading={submitting}
              disabled={!!errorMsg || (pristine && !isCateringSwitched)}
              gap={offsetDef}
            />
          )}
        </form>
      )}
    </Form>
  );
}

import React, {memo} from 'react';

import AccommodationBlock from './AccommodationBlock';
import OrdersAccommodationBlock from './OrdersAccommodationBlock';

import {IOfferRequestDay} from 'types/offer';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {IOrderDay} from 'types/dto/IBooking.types';

type TProps = {
  data?: IOfferRequestDay[];
  bookingData?: IOrderDay[];
  extrasOption?: IExtrasOption[];
  isOffer: boolean;
  isRequestWithUnitId?: boolean;
  titleMargin?: string;
  currency?: string;
};

const PrePostAccommodationBlock = ({
  data,
  bookingData,
  extrasOption,
  isOffer,
  isRequestWithUnitId,
  titleMargin,
  currency,
}: TProps) => (
  <>
    {bookingData?.map(
      (item, index) =>
        !!item.bedrooms.length && (
          <OrdersAccommodationBlock
            key={index}
            index={index}
            bedrooms={item.bedrooms}
            checkIn={item.checkInDate}
            isOffer={isOffer}
            titleMargin={titleMargin}
            eventType={item.eventType}
            currency={currency}
          />
        ),
    )}

    {data?.map(
      (item, index) =>
        !!item.bedrooms.length && (
          <AccommodationBlock
            key={item.checkIn + index}
            index={index}
            bedrooms={item.bedrooms}
            extrasOption={extrasOption}
            checkIn={item.checkIn}
            isOffer={isOffer}
            currency={currency}
            isRequestWithUnitId={isRequestWithUnitId}
            eventType={item.eventType}
          />
        ),
    )}
  </>
);

export default memo(PrePostAccommodationBlock);

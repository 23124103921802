import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import InputBlock from '../InputBlock/InputBlock';

import {formatDateForPicker} from 'view/components/NW2Datepicker';
import {pluralize} from 'utils/helpers';
import {useAppSelector} from 'store/hooks';
import {SEARCH_GROUPS_DATE} from '../../constants';
import {
  fontSizeXSm,
  lineHeightSm,
  NW2Gray600Color,
  offsetXLg,
} from 'constants/styleVars';
import {TDateType} from 'types/dto/ISearch.types';

export const Wrapper = styled.div<{isFullWidth?: boolean}>`
  display: flex;
  gap: ${offsetXLg};
  width: ${({isFullWidth}) => (isFullWidth ? '100%' : '260px')};
`;

export const Nights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};

  svg {
    color: ${NW2Gray600Color};
  }

  > div {
    white-space: nowrap;
  }
`;

interface IProps {
  nightsCount: number;
  startDate?: string | TDateType;
  endDate?: string | TDateType;
  isIconHidden?: boolean;
  isFullWidth?: boolean;
  onToggle?: () => void;
}
export function GroupsDateRange({
  nightsCount,
  startDate,
  endDate,
  onToggle,
  isIconHidden,
  isFullWidth,
}: IProps) {
  const focusedInput = useAppSelector(({app}) => app.searchFocusedInput);

  const inputCommonProps = {
    htmlFor: SEARCH_GROUPS_DATE,
    isFocused: focusedInput === SEARCH_GROUPS_DATE,
    flex: 1,
    isNoPadding: true,
    isNoBorder: true,
    isNoMargin: true,
    isWidthAuto: true,
    noWrap: true,
  };

  return (
    <Wrapper onClick={onToggle} isFullWidth={isFullWidth}>
      <InputBlock labelText='Arrival' {...inputCommonProps}>
        {startDate ? formatDateForPicker(startDate as string) : '-'}
      </InputBlock>

      <Nights>
        {!isIconHidden && (
          <div>
            <Icon icon='CRESCENT' />
          </div>
        )}
        <div>{pluralize('night', nightsCount)}</div>
      </Nights>

      <InputBlock
        labelText='Departure'
        alignItems='flex-end'
        {...inputCommonProps}
      >
        {endDate ? formatDateForPicker(endDate as string) : '-'}
      </InputBlock>
    </Wrapper>
  );
}

import React from 'react';

import {
  Content,
  Section,
  Title,
  Paragraph,
  StyledUnorderedList,
  StyledExternalLink,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {HRS_WIDERSPRUCH_EMAIL} from 'constants/app';
import {StyledH5} from 'view/components/Typography';

const TextContent = () => {
  return (
    <Content>
      <Section>
        <StyledH5>Legal Basis of Your Reservations </StyledH5>
      </Section>
      <Section>
        <Paragraph>General Terms & Conditions (“GTCs”) </Paragraph>
      </Section>

      <Section>
        <Title>1. Scope</Title>
        <Paragraph>
          1.1 HRS GmbH, Breslauer Platz 4, 50668 Cologne (hereinafter referred
          to as the <strong>'agent'</strong>), is part of the HRS Group, which
          owns several booking portals for travel and venue services. In the
          form of www.hrs.de & www.hrs.com (hereinafter referred to as the
          <strong> 'online portal'</strong>) and all related partner websites,
          it operates a global booking service for hotel rooms and comparable
          accommodation, travel services (e.g. holiday homes), co-working
          spaces, event locations and meeting rooms hereinafter referred to as{' '}
          <strong>'Venues'</strong>.
        </Paragraph>
        <Paragraph>
          1.2 These GTCs apply both to all non-binding booking enquiries and
          binding bookings made for Venues through the agent’s online portal.
          Venues that can be booked through the agent’s online portal may be
          arranged through the online booking systems operated by the agent’s
          partners, where applicable.
        </Paragraph>
        <Paragraph>
          1.3 These GTCs also apply in the event that users who intend to book
          Venues (hereinafter referred to as the <strong>'customer'</strong>)
          have conflicting general terms and conditions. These general terms and
          conditions are not accepted even if the agent provides services while
          aware of the customer’s conditions, unless expressly agreed otherwise.
        </Paragraph>
      </Section>
      <Section>
        <Title>2. Service rendered</Title>
        <Paragraph>2.1 Creation and Use of a User Account</Paragraph>
        <Paragraph>
          2.1.1 The customer has the option to create a user account on the
          Internet portal. The User account allows the customer to obtain the
          following benefits, among others:
        </Paragraph>
        <StyledUnorderedList>
          <li>Request Offers from Venues,</li>
          <li>Make use of potential discounts,</li>
          <li>Invite other users to collaborate at a Venue,</li>
          <li>More expedient booking, modification, and cancellation,</li>
          <li>Maintain billing addresses and payment methods,</li>
          <li>Get access to negotiated corporate rates.</li>
        </StyledUnorderedList>
        <Paragraph>
          A comprehensive overview of all benefits and functionalities of the
          User account will be provided to the customer after creating the User
          account.
        </Paragraph>
        <Paragraph>
          To create a User account, the customer must provide his or her name
          and email address and, in case the User account is created for
          business bookings, the name of his or her employer, as well as define
          a password. By clicking the “Register Now” button, the customer
          accepts the GTCs and submits an offer to enter into the contract for
          the User account. The agent accepts this offer based on its
          confirmation.
        </Paragraph>
        <Paragraph>
          The contract for the use of the User account is free-of-charge for the
          customer, is entered into for an unspecified period of time, and may
          be cancelled at any time by the customer. The agent may cancel the
          contract for the use of the User account by providing two weeks of
          advance notice.
        </Paragraph>
        <Paragraph>
          2.2 Arrangement and Conclusion of Venue and Service Contracts
        </Paragraph>
        <Paragraph>
          2.2.1 The services rendered include the arrangement and conclusion of
          a Venue or service agreement.
        </Paragraph>
        <Paragraph>
          a{')'} In case the customer makes bookings on his or her own behalf
        </Paragraph>
        <Paragraph>
          {' '}
          The contract is formed directly between the customer and the operator
          of the customer’s chosen Venue (hereinafter referred to as the{' '}
          <strong>'provider'</strong>) during the booking process. For such
          purpose, the agent provides the provider (and/or its service providers
          which are contractually bound accordingly) with the specified personal
          data of the customer.
        </Paragraph>
        <Paragraph>
          b{')'} In case the customer makes bookings on behalf of another person
        </Paragraph>
        <Paragraph>
          The contract is exclusively formed between the person who will use the
          services of the provider and/or his or her employer, in case the
          customer makes business bookings, as well as the provider. For such
          purpose, the agent provides the provider (and/or its service providers
          which are contractually bound accordingly) with the specified personal
          data of the person who will use the services of the provider.
          Therefore, the customer is under obligation to only make bookings for
          (natural or legal) persons in respect of whom the customer is entitled
          to
        </Paragraph>
        <Paragraph>a{')'} make the respective statements,</Paragraph>
        <Paragraph>
          b{')'} provide personal data and arrange for the transfer of personal
          data by the agent to the provider.
        </Paragraph>
        <Paragraph>
          Further data regarding the treatment of personal data are contained in
          number 9 as well as in the data privacy notice of the agent.
        </Paragraph>
        <Paragraph>
          {' '}
          2.2.2 All claims and obligations arising from the Venue or service
          agreement exist directly and exclusively between the customer, in case
          the customer makes bookings on his or her own behalf, and/or the
          person who will use the services of the provider and/or his or her
          employer, in case the customer makes a business booking and the
          provider. There is no Venue or service agreement between the customer
          and the agent.
        </Paragraph>
        <Paragraph>
          2.2.3 The provider of the Venue is not the agent or another booking
          system operator, but the provider chosen as the contractual partner.
          If several Venues are booked using the agent’s online portal, the
          agent does not choose or arrange the individual Venues; this is the
          customer’s responsibility. The conditions specified by the customer
          (such as location, destination, price, period of time, category) are
          taken into consideration as much as possible in the selection of
          individual Venues proposed by the online portal. However, it should
          not be interpreted, based on the selection made by the online portal,
          that there are no other Venues which might better meet the customer’s
          requests or conditions.
        </Paragraph>
        <Paragraph>
          {' '}
          2.2.4 Should the provider breach its obligations, the agent is not
          required, but is entitled to compensate losses suffered by the
          customer in individual cases in order to facilitate the smooth
          performance of the contract for the customer. In this case, the
          customer assigns its claims for compensation against the provider to
          the agent so that the agent can endeavor to obtain redress for the
          expenses incurred.
        </Paragraph>
        <Paragraph>
          2.2.5 In case of the booking of co-working spaces, event locations and
          meeting rooms, the customer, its employees or third parties who have
          been granted access to such Venues must not use those Venues for
          private purposes, including for private celebrations.
        </Paragraph>
      </Section>
      <Section>
        <Title>3. Customer reviews</Title>
        <Paragraph>
          3.1 The agent offers the customer the option to access reviews written
          by third parties as well as additional content and images relating to
          the services offered on the online portal. These merely reflect a
          personal opinion of the respective third-party related to a specific
          point in time. These opinions are also shaped by the writer’s personal
          perceptions and expectations. It should also be taken into
          consideration that the services offered may have changed since the
          content was uploaded or the review written; for example, a Venue may
          have been refurbished or renovated in the meantime. The number of
          reviews submitted should also be considered, as one individual opinion
          is often not as meaningful as an overall impression based on a number
          of reviews.
        </Paragraph>
        <Paragraph>
          3.2 The agent offers the customer and also third parties the option to
          anonymously submit such reviews or additional content and images to
          the online portal. The customer is exclusively responsible for all
          content that he/she provides (e.g. reports/images/videos).
        </Paragraph>
        <Paragraph>
          3.3 It is prohibited to upload content to the agent’s online portal
        </Paragraph>
        <Paragraph>
          a{')'} that is untrue or misleading, in particular by submitting
          inaccurate and/or unobjective reviews of Venues, persons at the
          Venues, leisure facilities and other local conditions/events which
          might affect decisions made by other guests and customers;
        </Paragraph>
        <Paragraph>
          b{')'} that is derogatory, insulting, offensive, threatening,
          pornographic or racist:
        </Paragraph>
        <Paragraph>
          c{')'} that violates third-party property and/or personal rights or is
          otherwise illegal;
        </Paragraph>
        <Paragraph>
          d{')'} that advertises products, services or companies, either overtly
          or covertly; in particular by submitting reviews for which the
          customer has been offered payment, or by reviewing Venues offered by
          providers for which the customer works or
        </Paragraph>
        <Paragraph>
          e{')'} that includes links or similar information/references that
          serve to affect the functioning of third-party computers.
        </Paragraph>
        <Paragraph>
          3.4 If the customer violates these conditions on the uploading of
          content, the agent is entitled to amend or fully delete content
          without notifying the customer and without specifying any reasons.
        </Paragraph>
      </Section>
      <Section>
        <Title>4. Booking and reservations</Title>
        <Paragraph>
          4.1 Each reservation is passed on to the respective provider through
          the agent who acts as an intermediary on behalf of the customer. In
          order to ensure smooth processing of the booking, it is therefore
          essential that the information provided by the customer (e.g. dates of
          the booking, contact details) are correct and complete.
        </Paragraph>
        <Paragraph>
          4.2 The latest possible time for the customer to check into the Venue
          varies according to the type of reservation. The various reservation
          options are described over the course of the booking procedure and are
          selected by the customer on this basis.
        </Paragraph>
        <Paragraph>
          4.3 Reselling of Venues booked via the agent is prohibited. This
          includes, in particular, reselling of Venues to third parties at a
          higher price. The agent reserves the right to exclude the customer
          from agency services in future in the event of breaches of this
          provision. Furthermore, the provider is entitled to cancel the booking
          in such cases, while the agent may also declare the cancellation of
          the booking. In addition, the customer is required to pay any
          cancellation fees and compensate any losses incurred by the agent
          and/or the provider.
        </Paragraph>
        <Paragraph>
          4.4 The Venue is reserved as soon as the booking is made, and the
          reservation is valid without confirmation. The booking confirmation
          appears on the screen and is also sent to the customer by email. The
          customer is responsible for creating the conditions to ensure that the
          confirmation sent by email can be received. The validity of the
          booking is not affected thereby; this applies, in particular, if an
          incorrect email address has been entered.
        </Paragraph>
        <Paragraph>
          4.5 During the booking process, special rates may be displayed that
          require full or partial payment in advance. In these cases, the
          precise terms can be viewed before the final booking is made. Usually,
          a booking of this sort can be made only by credit card, which will be
          charged immediately by the Provider of the booked accommodation. Over
          the course of this type of bookings, the Customer accepts that the
          booking fee or total amount, including any taxes or fees, shown on the
          booking screen will be charged directly to his or her credit card.
        </Paragraph>
        <Paragraph>
          If the provider agrees to an advance payment on the price of the
          accommodation that is not paid by credit card, the Provider is
          entitled to cancel the booking if it does not receive the advance
          payment within the period specified in the booking confirmation.
          Furthermore, failure to make an advance payment does not release the
          Customer from the obligation to properly cancel the booking and settle
          the resulting cancellation costs.
        </Paragraph>
        <Paragraph>
          4.6 Depending on the type of booking, a credit card number and the
          date of expiry of the credit card may have to be stored as part of the
          booking procedure. Explicit reference is always made on the booking
          screen that a credit card number needs to be specified. These details
          are passed on to the Provider of the accommodation in question and
          verified before confirmation of the booking. Your credit card is a
          guarantee of payment to the Provider of all costs incurred for the
          booked accommodation.
        </Paragraph>
        <Paragraph>
          4.7 The costs are directly charged to your credit card by the
          Provider, to whom the card must be presented. The time at which the
          charge is made is determined by the Provider's conditions.
        </Paragraph>
        <Paragraph>
          4.8 The credit card acts as security for the Provider and any
          cancellation costs or compensation may be charged to it by the
          Provider. If verification of the credit card details determines that
          they are not correct or that the card can not be charged, the Provider
          may cancel the booking through the Agent.
        </Paragraph>
      </Section>
      <Section>
        <Title>5. Changes and cancellations</Title>
        <Paragraph>
          5.1 Depending on the provider's conditions, after making the
          reservation, the customer is required to pay the agreed price (or a
          part thereof) for the Venue or booking if the reservation is not
          cancelled in time or the Venue or booking is not taken up without
          cancellation. The details of the cancellation conditions are specified
          separately on the relevant booking screen and accepted by the
          customer.
        </Paragraph>
        <Paragraph>
          5.2 In order to avoid misunderstandings, all changes and cancellations
          must be made via the agent's online portal. This requires direct input
          via the online portal. The cancellation period is usually based on
          local time at the location of the booked Venue. If cancellation is
          made on time and in accordance with the conditions, a cancellation
          confirmation is issued. Such confirmation provides proof of
          cancellation and must therefore be kept on file. Whether costs are
          charged for the cancellation or change, is determined by the
          cancellation conditions.
        </Paragraph>
        <Paragraph>
          5.3 Changes and cancellations are possible before expiry of the
          cancellation period set in the relevant cancellation conditions. In
          the case of certain changes, such as a reduction in the length of the
          booking, the provider may, however, pursue claims for any losses. In
          the case of special rates, services and/or dates and for bookings at
          very short notice, providers often do not allow cancellation free of
          charge.
        </Paragraph>
        <Paragraph>
          5.4 In order to prevent misuse of the online portal and avoid causing
          unnecessary problems for the provider with incorrect bookings, the
          agent reserves the right to cancel a reservation in individual cases
          if the customer cannot be contacted using the contact details provided
          or the provider declines the booking because of frequent cancellations
          or no-shows in the past. In such cases, the customer is not entitled
          to any Venue. In order to prevent this kind of cancellation, the
          customer usually has the option to make a booking by providing a
          credit card number, however.
        </Paragraph>
      </Section>
      <Section>
        <Title>6. Categories of Venues and information regarding Venues</Title>
        <Paragraph>
          6.1 All information regarding and descriptions of Venues are based on
          providers’ own information. The agent has no influence over this
          information and does not assume any responsibility for the accuracy or
          completeness thereof.
        </Paragraph>
        <Paragraph>
          6.2 The Venues offered are categorized based on the categories of the
          agent’s own classification system. In addition to categories in other
          classification systems (for example, the classifications prescribed by
          law in some federal states in some countries), this system is based
          primarily on customers’ experiences and reviews and information from
          providers that the agent may have checked. The classification merely
          provides non-binding information on the standard and features of the
          Venue.
        </Paragraph>
      </Section>
      <Section>
        <Title>7. Venue ranking/listing</Title>
        <Paragraph>
          7.1 Following a query on the website or app, the first Venue shown to
          the customer will be the Venue that best meets a variety of criteria
          (including value for money, flexible booking conditions, high level of
          customer satisfaction, broad range of offerings, good features, free
          additional benefits such as parking spaces or Wi-Fi, high conversion
          rate, extensive description and many images of the Venue, central
          location, etc.).
        </Paragraph>
        <Paragraph>
          7.2 In promotion periods, some Venues may, in exchange for a fee, have
          a more visible rank or a different rank in this list. This is just one
          of more than 80 criteria that affect the listing of Venues and by
          itself is not enough to ensure a top rank. Of course, the customer can
          filter and sort the ‘Recommendations’ list based on his/her own
          criteria (‘price per night’, for example).
        </Paragraph>
      </Section>
      <Section>
        <Title>8. Venue prices</Title>
        <Paragraph>
          8.1 Unless specified otherwise in the booking conditions, all prices
          are per hour or per day for each individual Venue. The ancillary
          services offered depend on the provider and must be paid separately on
          a price per item basis.
        </Paragraph>
        <Paragraph>
          8.2 Providers are required to display the final price, including
          taxes. However, price information is solely made available by the
          provider itself. Local provisions determine which taxes and other
          duties are incurred for Venue abroad
        </Paragraph>
        <Paragraph>
          8.3 The prices in the search results will be shown in the local
          currency selected by the provider.
        </Paragraph>
        <Paragraph>
          8.4 The option to update prices constantly and bookings made by other
          customers in the meantime on the agent’s online portal may
          occasionally mean that the prices on the Venue’s detailed page differ
          from the prices shown previously in the list of Venues. The final
          price indicated before completion of the booking process is decisive
          for the customer.
        </Paragraph>
      </Section>
      <Section>
        <Title>9. Data privacy</Title>
        <Paragraph>
          {' '}
          9.1 The agent only collects, processes and uses the customer’s
          personal data to the extent that the customer consents thereto or a
          legal provision permits the collection, processing and use of such
          data. The agent only collects, processes and uses data which are
          required for the provision of its services and the use and operation
          of the online portal and/or the services offered on the online portal.
        </Paragraph>
        <Paragraph>
          9.2 Under no circumstances does the agent sell data to third parties.
          Data are only transferred to third parties to the extent required for
          bookings/reservations with the provider chosen by the customer or the
          performance of other services which must be provided by the agent.
          This expressly includes any transfer to affiliates or other
          subcontractors of the agent for the purposes of executing the booking
          within the scope of the respective contractual structures and within
          the framework of the valid legal regulations, in particular in respect
          of data privacy.
        </Paragraph>
        <Paragraph>
          9.3 Further information regarding data privacy and the treatment of
          personal data is contained in the data privacy policy within{' '}
          <StyledExternalLink
            href='https://newwork.hrs.com'
            target='_blank'
            rel='noreferrer noopener'
          >
            https://newwork.hrs.com
          </StyledExternalLink>
        </Paragraph>
      </Section>
      <Section>
        <Title>10. Marketing</Title>
        <Paragraph>
          {' '}
          10.1 If the agent has been provided with the customer's email address
          within the context of the booking or registration for the closed area
          through individual customer log-in, the agent uses such address in
          accordance with the provisions of § 7, Paragraph 3 of the German
          Unfair Practices Act for the purpose of electronic advertising for its
          own similar services. In doing so, the agent shall make every effort
          to send the customer advertising materials that are tailored to his or
          her interests. The customer may refuse to receive electronic
          advertising at any time by sending an email to{' '}
          <StyledExternalLink
            href={`mailto:${HRS_WIDERSPRUCH_EMAIL}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            {HRS_WIDERSPRUCH_EMAIL}
          </StyledExternalLink>{' '}
          without incurring any costs other than the communication costs in
          accordance with the basic rates.
        </Paragraph>
        <Paragraph>
          10.2 In addition, the agent reserves the right to create usage
          profiles under pseudonyms within the meaning of Section 15 (3) of the
          German Telemedia Act (TMG) for the purposes of advertising, market
          research and design of the online portal in line with demand. The
          customer may object to the creation of such a usage profile at any
          time by sending a simple email to{' '}
          <StyledExternalLink
            href={`mailto:${HRS_WIDERSPRUCH_EMAIL}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            {HRS_WIDERSPRUCH_EMAIL}
          </StyledExternalLink>
          .
        </Paragraph>
      </Section>
      <Section>
        <Title>11. Warranty and liability</Title>
        <Paragraph>
          11.1 All of the agent’s information has been compiled with the
          greatest care. However, no warranty can be made for any errors in data
          collection or transfer. The transfer of data to other data carriers,
          even in part, or use for purposes other than those provided for
          hereunder is only permitted with the express consent of the agent.
        </Paragraph>
        <Paragraph>
          11.2 Some of the information on the agent’s online portal is provided
          by providers, other customers and third parties. All providers,
          customers and third parties bear sole responsibility for the accuracy,
          completeness and topicality of the information they upload, including
          specified prices and availability. Moreover, they ensure that content
          that violates the legal positions of third parties is not uploaded.
          The agent cannot review this information and therefore assumes no
          warranty for the accuracy, completeness, quality or legality of
          information usage.
        </Paragraph>
        <Paragraph>
          11.3 The customer will release the agent from all claims due to third
          parties as a result of a violation of the obligations specified under
          11.2. The customer will also support the agent in its defence of such
          claims and provide all necessary information to it.
        </Paragraph>
        <Paragraph>
          11.4 The agent does not take ownership of this content or customer
          reviews. Rather, customer reviews merely reflect the personal opinion
          of the authors. The agent has no influence on the form, content and
          user IDs used for these reviews and therefore assumes no
          responsibility in this respect.
        </Paragraph>
        <Paragraph>
          11.5 The arrangement of Venues through the agent’s online portal is
          free for the customer. At the same time, however, the customer does
          not have any claim to the temporal and spatial availability of the
          agent’s services. The agent is not liable for (partial) service
          interruptions and outages due to repair, maintenance or update work or
          for other reasons beyond its direct control or that only interfere
          with use of the services to a negligible extent.
        </Paragraph>
        <Paragraph>
          11.6 The agent is not liable for the completion of a booking, nor is
          it liable for defects or damage incurred in connection with the
          provider’s rendering of the Venue or services. The agent makes no
          assurances in respect of the fulfilment of particular requests made by
          the customer and also assumes no warranty in this respect.
        </Paragraph>
        <Paragraph>
          11.7 Moreover, the agent’s liability is excluded for all damage,
          irrespective of the legal cause, unless the damage
        </Paragraph>
        <Paragraph>
          a{')'} is based on culpable breach of material contractual duties,
          i.e. duties whose fulfilment makes proper performance of the agreement
          possible in the first place and on whose compliance the contractual
          partner should normally be able to rely;
        </Paragraph>
        <Paragraph>
          b{')'} was caused by gross negligence or intent on the part of the
          agent, or
        </Paragraph>
        <Paragraph>
          c{')'} constitutes an injury to life, limb or health, damage as
          defined by the German Act on Liability for Defective Products or other
          obligatory legal cases of liability.
        </Paragraph>
        <Paragraph>
          11.8 If the agent is liable in accordance with number 11.7 for the
          culpable violation of material contractual duties in the absence of
          gross negligence and intent, the agent’s entire liability is limited
          to such damage and the extent of damage whose occurrence the agent
          could typically foresee in accordance with the circumstances of which
          it was aware at the time of contract conclusion.
        </Paragraph>
        <Paragraph>
          11.9 Warranty claims and claims for compensation are barred by the
          statute of limitations no later than one year after the time when the
          customer became aware of the damaging event. This does not apply to
          claims resulting from tort.
        </Paragraph>
      </Section>
      <Section>
        <Title>12. Miscellaneous</Title>
        <Paragraph>
          12.1 German law applies to the exclusion of the UN Convention on
          Contracts for the International Sale of Goods and the conflict of law
          rules of international private law
        </Paragraph>
        <Paragraph>
          12.2 The online dispute resolution platform of the European Commission
          can be reached using the following link:{' '}
          <StyledExternalLink
            href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=DE'
            target='_blank'
            rel='noreferrer noopener'
          >
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=DE
          </StyledExternalLink>
          . The agent does not participate in dispute resolution proceedings
          before a consumer arbitration board and is also not required to do so.
        </Paragraph>
        <Paragraph>
          12.3 The place of performance is Cologne. Cologne is agreed as the
          place of jurisdiction for merchants and persons who do not have a
          place of general jurisdiction in Germany. The statutory place of
          jurisdiction applies in all other cases.
        </Paragraph>
        <Paragraph>
          12.4 Use of the agent’s online portal and its content is only
          permitted for individual queries relating to Venue bookings. Automated
          queries are not permitted. The data material is copyrighted, and any
          duplication thereof (particularly by means of ‘scraping’) is therefore
          prohibited. In particular, use of the data material (such as query
          results in particular) for resale purposes is also prohibited.
        </Paragraph>
        <Paragraph>
          12.5 Links on this website to the websites of other companies
          (third-party providers) are solely provided in the customer’s
          interest. If the customer clicks on such a link, he/she will leave the
          agent’s website. The agent has no control over the content of
          third-party providers’ websites. The agent therefore cannot assume any
          warranty for the accuracy, completeness or security of this
          third-party content.
        </Paragraph>
        <Paragraph>
          12.6 The agent reserves the right to modify these GTCs for holders of
          a User account, and such modifications become effective for the
          future. The agent will email customers holding a User account the new
          version of the GTCs not later than four weeks prior to their planned
          entry into force and effect and indicate the intended modifications.
          Unless the customer rejects the same within four weeks from the date
          of receipt of the aforementioned notice, the new version of the GTCs
          is deemed to have been accepted. The agent will advise the customer
          regarding the above-described option to reject the new version as well
          as the consequences of failure to reject the same.
        </Paragraph>
        <Paragraph>
          12.7 If a provision in this agreement is invalid or unenforceable,
          this provision should be interpreted in a manner that is compatible
          with the applicable law and that corresponds as much as possible to
          the original intention of the parties. The remainder of the agreement
          is not affected thereby.
        </Paragraph>
      </Section>
    </Content>
  );
};

export default TextContent;

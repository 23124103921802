import styled from 'styled-components';

import {offsetXSm, offsetXLg, lgBp} from 'constants/styleVars';

export const SectionCheckboxItem = styled.div`
  margin: ${offsetXSm} 0;
`;

export const FlexContainer = styled.div`
  @media (min-width: ${lgBp}px) {
    display: flex;
    gap: ${offsetXLg};
  }
`;

export const SubSectionContainer = styled.div`
  width: 100%;
  max-width: 442px;
`;

export const List = styled.ul`
  padding-left: ${offsetXLg};
`;

import React from 'react';

import SignInCard from './SignInCard';
import LoggedInUserDetails from './LoggedInUserDetails';

type TProps = {
  isShowSignInCard?: boolean;
};
const UserDetails: React.FC<TProps> = ({isShowSignInCard}) =>
  isShowSignInCard ? <SignInCard /> : <LoggedInUserDetails />;

export default UserDetails;

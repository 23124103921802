import React, {memo, useCallback, useState} from 'react';
import {Form} from 'react-final-form';
import {FormApi} from 'final-form';
import {notification} from 'antd';
import {Auth} from 'aws-amplify';

import NW2Button from 'view/components/NW2Button';
import CustomList from 'view/components/CustomList';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {StyledSpan} from 'view/components/Typography';

import {isPasswordValid} from 'infra/common/venueValidation';
import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {ModalActionButtons} from 'view/customer/NW2CustomerProfile/NW2CustomerProfile.styles';
import {
  list,
  StyledHelpTextContainer,
} from 'view/components/EnterPassword/helper';
import {fontSizeXSm, fontWeightBold, offsetXSm} from 'constants/styleVars';
import {ICognitoError, ICognitoExceptionCode} from 'types/errors';

interface IProps {
  isMobile: boolean;
  onClose?: () => void;
}

export const NW2ChangePasswordForm = memo(({isMobile, onClose}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const changePasswordForCustomer = useCallback(
    async (
      values: Record<string, string>,
      form: FormApi<Record<string, string>>,
    ) => {
      const {newPassword, previousPassword} = values;

      if (isPasswordValid(newPassword)) {
        try {
          setIsLoading(true);

          const user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(user, previousPassword, newPassword);

          setIsLoading(false);
          if (typeof onClose === 'function') {
            form.reset();
            onClose();
          }
          notification.success({
            message: 'The password has been successfully changed',
            duration: 4,
          });
        } catch (error: unknown) {
          const cognitoError = error as ICognitoError;
          const message =
            cognitoError?.code === ICognitoExceptionCode.NotAuthorizedException
              ? 'Current password doesn’t match'
              : cognitoError.message;

          notification.error({
            message,
            duration: 4,
          });

          setIsLoading(false);
        }
      } else {
        notification.error({
          message:
            'Password must have one letter, one Upper letter, one number, one special symbol and has 8 symbols',
          duration: 4,
        });
      }
    },
    [onClose],
  );

  return (
    <Form onSubmit={changePasswordForCustomer}>
      {({handleSubmit, form}) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormGroup columnNumber={1} gap={16}>
            <NW2FormItemInput
              name='previousPassword'
              placeholder='Current password'
              label='Current password'
              type='password'
            />
            <NW2FormItemInput
              name='newPassword'
              placeholder='New password'
              label='New password'
              type='password'
            />
          </FormGroup>
          <StyledHelpTextContainer>
            <StyledSpan fontWeight={fontWeightBold} fontSize={fontSizeXSm}>
              Build a strong Password:
            </StyledSpan>
            <CustomList
              offset={offsetXSm}
              size='small'
              list={list}
              listStyle='ordered'
            />

            <ModalActionButtons>
              {typeof onClose === 'function' && (
                <NW2Button
                  onClick={() => {
                    form.reset();
                    onClose();
                  }}
                  size='responsiveAdaptive'
                >
                  Cancel
                </NW2Button>
              )}

              <NW2Button
                type='submit'
                loading={isLoading}
                buttonType='primary'
                size='responsiveAdaptive'
              >
                {isLoading
                  ? 'Loading...'
                  : isMobile
                  ? 'Save'
                  : 'Change password'}
              </NW2Button>
            </ModalActionButtons>
          </StyledHelpTextContainer>
        </form>
      )}
    </Form>
  );
});

import {IAmenity} from 'types/dto/IAmenity';
import {EAmenityType} from 'types/amenities';

interface IProps {
  commonAmenities: IAmenity[];
  categories: EAmenityType[];
}

export const getSortedCommonAmenities = ({
  commonAmenities,
  categories,
}: IProps) =>
  categories
    .map((type) =>
      commonAmenities
        .filter((amenity) => amenity.type === type)
        .map((amenity) => amenity.id),
    )
    .flat();

import React, {ReactNode} from 'react';

import {LinkTerms} from './components/LinkTerms';

import {colorSchema, EBookingSummaryTheme} from './types';

import {
  TotalContainer,
  TotalFooter,
  TotalFooterBottom,
  TotalMain,
  TotalWrapper,
  HeaderSectionContainer,
} from './NW2SummaryDesktopBlock.styles';

interface IProps {
  isSticky?: boolean;
  isStickyDisabled?: boolean;
  linkTermsText?: string;
  buttonConfirm?: ReactNode;
  headerSection: ReactNode;
  totalPriceSection: ReactNode;
  totalDiffPriceSection?: ReactNode;
  unitsSection: ReactNode;
  theme?: EBookingSummaryTheme;
  viewPortSpacing?: string;
}

export function NW2SummaryDesktopBlock({
  isStickyDisabled,
  isSticky,
  buttonConfirm,
  headerSection,
  totalPriceSection,
  unitsSection,
  linkTermsText,
  theme = EBookingSummaryTheme.DARK,
  viewPortSpacing,
  totalDiffPriceSection,
}: IProps) {
  return (
    <TotalWrapper
      isStickyDisabled={isStickyDisabled}
      colorTheme={theme}
      viewPortSpacing={viewPortSpacing}
    >
      <TotalContainer isSticky={isSticky} colorTheme={theme}>
        <HeaderSectionContainer colorTheme={theme}>
          {headerSection}
        </HeaderSectionContainer>

        <TotalMain>{unitsSection}</TotalMain>

        <TotalFooter colorTheme={theme}>
          {!!totalDiffPriceSection && totalDiffPriceSection}
          {totalPriceSection}

          <TotalFooterBottom>
            {buttonConfirm}

            {linkTermsText && (
              <LinkTerms
                color={colorSchema[theme].linkTermsColor}
                text={linkTermsText}
                isNoPadding={!buttonConfirm}
              />
            )}
          </TotalFooterBottom>
        </TotalFooter>
      </TotalContainer>
    </TotalWrapper>
  );
}

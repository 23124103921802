import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {setOpenPasswordPopup} from 'store/app/appSlice';
import {Routes} from 'constants/routes';
import {mdBp} from 'constants/styleVars';
import {TOpenPasswordModalProps} from 'types/app';

type TProps = {
  role: EUserRoleCognito;
};

function useOpenSetPasswordModal({
  role,
  pageName,
  btnOkText,
  type,
  notificationMessage,
}: TProps & TOpenPasswordModalProps): {
  isCustomer: boolean;
  isRedirected: boolean;
} {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRedirectedToModal = window.innerWidth >= mdBp;
  const isCustomer = role === EUserRoleCognito.ROLE_CUSTOMER;

  if (isRedirectedToModal && isCustomer) {
    dispatch(
      setOpenPasswordPopup({
        openPasswordPopup: {pageName, btnOkText, type, notificationMessage},
      }),
    );
    navigate(`${Routes.mainLanding}${location.search}`);
  }

  return {isCustomer, isRedirected: isRedirectedToModal && isCustomer};
}

export default useOpenSetPasswordModal;

export enum EMenuItemSizes {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export type TMenuItemSize = {
  [key in EMenuItemSizes]: {
    fontSize: string;
    lineHeight: string;
    marginBottom: string;
    padding: string;
    paddingActive: string;
    activeMarkWidth: string;
    activeMarkHeight: string;
    activeMarkLeftSpace: string;
  };
};

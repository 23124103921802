import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {colorSchema, EBookingSummaryTheme} from './types';

import {
  borderRadiusDef,
  mdBp,
  NW2Gray200Color,
  offsetDef,
} from 'constants/styleVars';

const VIEWPORT_SPACING = '40px';

export const TotalContainer = styled.div<{
  colorTheme: EBookingSummaryTheme;
  isSticky?: boolean;
}>(
  ({colorTheme, isSticky}) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    transition: max-height 0.15s;
    color: ${colorSchema[colorTheme].containerColor};

    ${isSticky &&
    css`
      @media (min-width: ${mdBp}px) {
        max-height: calc(100vh - ${VIEWPORT_SPACING} * 2);
      }
    `}
  `,
);

export const TotalHeader = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-column-gap: ${offsetDef};
  padding: ${offsetDef};
`;

export const TotalMain = styled.div`
  display: grid;
  padding: ${offsetDef} 9px ${offsetDef} ${offsetDef};
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 100%;
`;

export const TotalFooter = styled.div<{
  colorTheme: EBookingSummaryTheme;
}>(
  ({colorTheme}) => css`
    padding: ${offsetDef};
    background-color: ${colorSchema[colorTheme].footerBgColor};
  `,
);

export const TotalFooterBottom = styled.div`
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  * + & {
    margin-top: ${offsetDef};
  }
`;

export const StyledIcon = styled(Icon)`
  color: inherit;
`;

export const TotalWrapper = styled.div<{
  isStickyDisabled?: boolean;
  colorTheme: EBookingSummaryTheme;
  viewPortSpacing?: string;
}>(({isStickyDisabled, colorTheme, viewPortSpacing}) => {
  const schema: any = colorSchema[colorTheme];

  return css`
    position: ${isStickyDisabled ? '' : 'sticky'};
    top: ${isStickyDisabled ? '' : viewPortSpacing || VIEWPORT_SPACING};
    display: grid;
    background-color: ${schema.wrapperBgColor};
    border-radius: ${borderRadiusDef};
    overflow: hidden;

    // light theme

    ${TotalHeader} {
      background-color: ${schema.headerBgColor};

      ${colorTheme === EBookingSummaryTheme.LIGHT &&
      css`
        position: relative;

        &:after {
          position: absolute;
          content: '';
          left: ${offsetDef};
          right: ${offsetDef};
          bottom: 0;
          height: 1px;
          background-color: ${NW2Gray200Color};
        }
      `}
    }
  `;
});

export const HeaderSectionContainer = styled.div<{
  colorTheme: EBookingSummaryTheme;
}>(({colorTheme}) => {
  const schema = colorSchema[colorTheme];
  return css`
    background-color: ${schema.headerBgColor};
  `;
});

import React, {useEffect, useMemo, useRef, KeyboardEvent} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Form} from 'react-final-form';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';
import NW2Loader from 'view/components/NW2Loader';
import BookPreviewComponents from 'view/venue/NW2BookingPreview/components/BookPreviewComponents';
import {NW2BookingTotal} from './components/NW2BookingTotal';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useIntersectionObserver} from 'hooks/useIntersectionObserver';
import {findCoverImage} from 'utils/helpers';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {useBookingInitialData} from './hooks/useBookingInitialData';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import useOrderSubmit from 'view/venue/hooks/useOrderSubmit';
import LocalStorageService from 'infra/common/localStorage.service';

import {TImage} from 'types/app';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {
  StyledHeader,
  StyledMain,
  StyledTitle,
  StyledWrapper,
} from './NW2BookingPreview.styles';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {useDeskBookingInitialData} from './hooks/useDeskBookingInitialData';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useQuery} from 'hooks/useQuery';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {useCustomerOrderUnits} from '../hooks/useCustomerOrderUnits';
import {useIsVenueFromUSA} from 'view/venue/hooks/useIsUSA';
import {
  cleanCustomerSelectedPackages,
  updateCustomerSelectedPackages,
} from 'store/customer/customerSlice';
import {getPackages} from 'store/packages/apiActions';
import {addPackageBookingPreview} from 'store/bookingsCustomer/apiActions';
import {EVenueType, IMultiDayPublicVenue} from 'types/dto/IPublicVenue';
import {useMultiDayVenueDetailsData} from '../NW2VenueDetails/useMultiDayVenueDetails';

const NW2BookingPreview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationData = useLocation();
  const {paramsVenueId} = useParamsVenueId();

  const {roomType = ''} = useQuery();

  const orderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );
  const packages = useAppSelector(({packages}) => packages.packages);

  const {previewUnits} = useCustomerOrderUnits();

  const isMeetingRoom =
    roomType === ERoomType.MEETING_ROOM || roomType === ERoomType.GROUPS;

  const {venueDetails, isRfp} = useVenueDetailsData();

  const {currency, documents, location, name, accommodationId, id} =
    venueDetails;
  const [isUSA] = useIsVenueFromUSA(location?.country);

  const venueAddress = getAddressStringFromLocation(location);
  const venueCoverImage = useMemo(
    () => findCoverImage((documents || []) as TImage[]),
    [documents],
  );

  useEffect(() => {
    if (!orderNumber) return;
    const query = new URLSearchParams(locationData.search);

    /**
     * sets url param venueId to the booking confirmation page
     * accommodationId for MR
     * id for WD
     */
    query.set('venueId', String(accommodationId || id));
    navigate({
      pathname: `/booking/${orderNumber}`,
      search: query.toString(),
    });
  }, [
    dispatch,
    orderNumber,
    navigate,
    accommodationId,
    locationData.search,
    id,
  ]);

  const {paramsVenueIds} = useParamsVenueId();
  const venueId = String(paramsVenueIds[0] ? paramsVenueIds : paramsVenueId);

  useMultiDayVenueDetailsData({venueId});

  const {onGoToVenueDetails, formInitialValues} = useBookingInitialData({
    id: paramsVenueId,
    isMeetingRoom,
  });

  const {formInitialValues: formDeskInitialValues} =
    useDeskBookingInitialData();

  const isInstant = [EVenueType.INSTANT, EVenueType.INSTANT_CORPORATE].includes(
    venueDetails.type,
  );

  // handle sticky total behavior
  const headerRef = useRef(null);
  const headerEntry = useIntersectionObserver(headerRef, {});
  const isTotalSticky: boolean | undefined = !headerEntry?.isIntersecting;

  const {isBTCApp} = useFlutterWebView();
  const {onFormSubmit} = useOrderSubmit();

  const {isLoggedInUser} = useLoggedInUser();

  // TODO: REFACTOR
  useEffect(() => {
    if (venueDetails?.accommodationId) {
      const id = Number(venueDetails.accommodationId);
      dispatch(getPackages(id)); // todo check packages
      dispatch(cleanCustomerSelectedPackages());
    }
  }, [venueDetails?.accommodationId, dispatch]);

  // Applying package from multidetails
  useEffect(() => {
    if (
      venueDetails &&
      packages.length &&
      previewUnits.length &&
      (venueDetails as IMultiDayPublicVenue)?.days?.some((d) => d.package)
    ) {
      (venueDetails as IMultiDayPublicVenue).days.forEach((day, index) => {
        if (day.package) {
          const unitToApplyPackage = day.rooms.find(
            (room) => room.position === day.package?.position,
          )?.units[0]?.unitId;

          const packageToApply = packages.find(
            (p) => p.id === day.package?.packageId,
          );

          if (unitToApplyPackage && packageToApply) {
            dispatch(
              updateCustomerSelectedPackages({
                newPackage: packageToApply,
                dayIndex: index,
                unitId: unitToApplyPackage,
              }),
            );

            dispatch(addPackageBookingPreview());
          }
        }
      });
    }
  }, [venueDetails, dispatch, packages, previewUnits.length]);

  useEffect(() => {
    return () => {
      LocalStorageService.removeItemByKey('bookingPreviewData');
    };
  }, []);

  // prevent keypress enter
  const onKeyPress = (e: KeyboardEvent) => {
    e.key === 'Enter' && e.preventDefault();
  };

  if (!accommodationId && !id)
    return <NW2Loader label='Loading' height='100%' />;

  return (
    <StyledWrapper>
      <NW2Container>
        <StyledHeader ref={headerRef}>
          <NW2Button
            buttonType='secondary'
            minimized
            size='small'
            onClick={onGoToVenueDetails}
            icon={<Icon transparent icon='NW2_MOVE_BACK' />}
          />
          <StyledTitle>Confirm & Book</StyledTitle>
        </StyledHeader>

        <Form
          onSubmit={onFormSubmit}
          initialValues={
            isMeetingRoom ? formInitialValues : formDeskInitialValues
          }
          mutators={{
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value);
            },
          }}
          keepDirtyOnReinitialize
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} onKeyDown={onKeyPress}>
              <StyledMain>
                <BookPreviewComponents
                  isShowLoginModal={!isLoggedInUser && !isBTCApp}
                />

                <NW2BookingTotal
                  currency={currency}
                  venueName={name}
                  venueCoverImage={venueCoverImage}
                  isRfp={!isInstant && (isMeetingRoom ? isRfp : false)}
                  isTotalSticky={isTotalSticky}
                  venueAddress={venueAddress}
                  isUSA={isUSA}
                />
              </StyledMain>
            </form>
          )}
        />
      </NW2Container>
    </StyledWrapper>
  );
};

export default NW2BookingPreview;

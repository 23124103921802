import {useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {ISpacesDataItem} from 'types/venue';
import {editNMMSpace, setLoading} from 'store/venue/actions';
import {useAppDispatch} from 'store/hooks';

interface IProps {
  space: ISpacesDataItem;
  endHandler: () => void;
  onSuccessCallback?: () => void;
}

const useUpdateSpace = ({space, endHandler, onSuccessCallback}: IProps) => {
  const [isSending, setIsSending] = useState(false);
  const dispatch = useAppDispatch();

  const isLoading = useSelector((state) => _get(state, 'venue.loading'));

  useEffect(() => {
    if (isSending && !isLoading) {
      endHandler();
      setIsSending(false);
    }
  }, [endHandler, isLoading, isSending]);

  const updateSpace = useCallback(
    async (values: any) => {
      setIsSending(true);
      dispatch(setLoading(true));

      try {
        const updatedSpace = {...space, ...values};
        dispatch(editNMMSpace({data: updatedSpace, onSuccessCallback}));
      } catch (error) {
        setIsSending(false);
      }
    },
    [dispatch, onSuccessCallback, space],
  );

  return {
    updateSpace,
    isSending,
  };
};

export default useUpdateSpace;

import {useMemo} from 'react';

import {useAppSelector} from 'store/hooks';
import {EEventType} from 'types/venue';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

export const useSearchTypeBedrooms = () => {
  const filterDays = useAppSelector(
    ({search}) => search.searchCriteria.filterDays,
  );

  const {preArrivals, postEvents} = useMemo(() => {
    return {
      preArrivals: getFilteredUnitsByEventType(
        filterDays,
        EEventType.PRE_ARRIVAL,
      ),
      postEvents: getFilteredUnitsByEventType(
        filterDays,
        EEventType.POST_EVENT,
      ),
    };
  }, [filterDays]);

  return {preArrivals, postEvents};
};

import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {
  offsetSm,
  offsetLg,
  offsetDef,
  borderRadiusDef,
  blackColor,
  whiteColor,
  fontSizeLg,
  xsBp,
  mdBp,
  fontWeightExtraBold,
  fontSizeSm,
  offsetXXSm,
  offset36,
  offset14,
  lineHeightXL,
  lineHeightMd,
  offsetXSm,
} from 'constants/styleVars';

const MAX_WIDTH = '845px';

const Container = styled.div<{white?: boolean}>`
  padding: ${offsetXSm} ${offsetLg};
  border-left: 4px solid ${({white}) => (white ? whiteColor : blackColor)};
  color: ${({white}) => (white ? whiteColor : blackColor)};
  border-radius: ${borderRadiusDef};
`;

const Title = styled.div<{titleFontSize?: string; titleFontWeight?: string}>`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  text-transform: uppercase;
  font-weight: ${({titleFontWeight}) =>
    titleFontWeight ? `${titleFontWeight}` : `${fontWeightExtraBold}`};
  letter-spacing: 2%;
  margin-bottom: ${offsetXSm};
`;

const Text = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  margin-bottom: ${offsetSm};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${offsetDef};

  @media (max-width: ${xsBp - 1}px),
    (min-width: ${mdBp}px) and (max-width: ${MAX_WIDTH}) {
    flex-direction: column;
  }
`;
export const ListWrapper = styled.ul`
  padding-left: ${offsetSm};

  > li {
    margin-top: ${offsetXXSm};

    &:last-child {
      margin-top: ${offsetDef};
    }

    @media (max-width: ${mdBp - 1}px) {
      margin-top: ${offsetLg};
      &:first-child {
        margin-top: ${offset14};
      }

      &:last-child {
        margin-top: ${offset36};
      }
    }
  }
`;
type TProps = {
  title: string;
  description: string | ReactNode;
  buttons?: ReactNode;
  listItems?: ReactNode;
  white?: boolean;
};

const NW2NoFoundBlock = ({
  title,
  description,
  listItems,
  buttons,
  white,
}: TProps) => {
  return (
    <Container white={white}>
      <Title>{title}</Title>
      <Text>{description}</Text>
      {!!listItems && <ListWrapper>{listItems}</ListWrapper>}
      {!!buttons && <ButtonsWrapper>{buttons}</ButtonsWrapper>}
    </Container>
  );
};

export default NW2NoFoundBlock;

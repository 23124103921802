import styled from 'styled-components';

import {
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetSm,
} from 'constants/styleVars';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${offsetXLg};
  justify-content: flex-end;
  padding-top: ${offsetXLg};
  margin-bottom: ${offsetSm};
`;

export const Container = styled.div`
  overflow-y: auto;
  s & div,
  ul {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.div`
  padding: ${offsetDef} ${offsetSm};
  border-bottom: 1px solid ${NW2Gray200Color};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const floorsModalBodyStyle = {
  marginBottom: 0,
  paddingBottom: 0,
  borderBottom: `1px solid ${NW2Gray200Color}`,
  borderRadius: 0,
};

import React, {useState} from 'react';

import StyledModal from 'view/components/Modal';
import {useAppDispatch} from 'store/hooks';

import {updateAnnouncementStatus} from 'store/venue/actions';
import {AnnouncementPopupContent} from './AnnouncementPopupContent';

import {contentMaxWidthXs, offsetDef} from 'constants/styleVars';

type TProps = {
  announcementBody: {
    title: string;
    subTitle: string;
    htmlString: string;
  };
  isMobile?: boolean;
  userId?: number;
  announcementId: number;
  showNextAnnouncement: () => void;
};

function NW2AnnouncementPopup({
  announcementBody,
  isMobile,
  userId,
  announcementId,
  showNextAnnouncement,
}: TProps) {
  const dispatch = useAppDispatch();
  const [isShowed, setIsShowed] = useState(true);

  const modalBodyStyle = {
    padding: `${offsetDef}`,
    display: 'flex',
  };

  const closeModal = () => setIsShowed(!isShowed);
  const handleOnSubmit = (formData: Record<string, any>) => {
    formData.AnnouncementPopupCheckbox &&
      dispatch(
        updateAnnouncementStatus({
          userId,
          announcementIds: [announcementId],
        }),
      );
    closeModal();
    showNextAnnouncement();
  };

  const {title, subTitle, htmlString} = announcementBody;

  return (
    <StyledModal
      width={contentMaxWidthXs}
      open={isShowed}
      onCancel={closeModal}
      bodyStyle={modalBodyStyle}
      wrapClassName='modal-custom'
      centered
      isHeaderHidden
      closable={false}
      maskClosable={false}
      footer={null}
    >
      <AnnouncementPopupContent
        title={title}
        subTitle={subTitle}
        htmlString={htmlString}
        handleOnSubmit={handleOnSubmit}
        announcementId={announcementId}
        isMobile={isMobile}
      />
    </StyledModal>
  );
}

export default NW2AnnouncementPopup;

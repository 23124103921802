import React, {memo} from 'react';

import {BookingUnitExtras} from 'view/supplier/Bookings/BookingDetails/components/BookingUnitExtras';

import {ExtrasTitle} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {ISummaryExtra} from 'types/dto/IBooking.types';

interface IProps {
  unitExtras: ISummaryExtra[];
  margin?: string;
  currency?: string;
}

export const OrdersAccommodationExtras = memo(
  ({
    unitExtras,
    margin,
    currency,
  }: // date
  IProps) => {
    if (!unitExtras.length) return null;

    return (
      <>
        {/* TODO: check with accommodation period */}
        <ExtrasTitle margin={margin}>Accommodation</ExtrasTitle>
        {/* <TitleContainer>
            <DatePeriod margin={`${offsetXLg} 0 0`}>
              {DateUtils.getDatePeriod(date, 1)}
            </DatePeriod>
          </TitleContainer> */}

        <BookingUnitExtras data={unitExtras} currency={currency} isBedroom />
      </>
    );
  },
);

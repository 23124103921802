import {
  EDesksAmenitiesCategories,
  ERoomAmenitiesCategories,
} from '../../../../types';
import {ESpaceDetailsContentSections} from '../../types';
import {EAmenityType} from 'types/amenities';

export type TSpaceAmenityRecord = Record<string, number[]>;

export const spaceTypes: Record<string, ESpaceDetailsContentSections> = {
  [EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT]:
    ESpaceDetailsContentSections.INCLUDED_IN_SPACE,
  [EAmenityType.CORPORATE_OFFICE_MEETING_ROOM]:
    ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY,
  [EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY]:
    ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY,
  [EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT]:
    ESpaceDetailsContentSections.INCLUDED_IN_SPACE,
  [EAmenityType.CORPORATE_OFFICE_WORK_DESK]:
    ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY,
  [EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY]:
    ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY,
};

export const amenitiesTypes: Record<string, EAmenityType[]> = {
  [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: [
    EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT,
    EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT,
  ],
  [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: [
    EAmenityType.CORPORATE_OFFICE_MEETING_ROOM,
    EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
    EAmenityType.CORPORATE_OFFICE_WORK_DESK,
    EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
  ],
};

export const itemsRoomTypeFromSection: Record<
  string,
  ERoomAmenitiesCategories[]
> = {
  [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: [
    ERoomAmenitiesCategories.CORPORATE_OFFICE_MEETING_ROOM,
    ERoomAmenitiesCategories.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
  ],
  [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: [
    ERoomAmenitiesCategories.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT,
  ],
};

export const itemsDeskTypeFromSection: Record<
  string,
  EDesksAmenitiesCategories[]
> = {
  [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: [
    EDesksAmenitiesCategories.CORPORATE_OFFICE_WORK_DESK,
    EDesksAmenitiesCategories.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
  ],
  [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: [
    EDesksAmenitiesCategories.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT,
  ],
};

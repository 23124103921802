import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  fontSizeMd,
  fontSizeXLg,
  fontSizeXSm,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightSm,
  lineHeightXLg,
  mdBp,
  NW2Gray100Color,
  NW2Gray300Color,
  NW2Gray900Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXLg,
  offsetXXSm,
  offsetXXXLg,
  offsetXXXXLg,
  whiteColor,
  xLgBp,
} from 'constants/styleVars';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';

export const Wrapper = styled.div`
  background-color: ${whiteColor};
  flex: 1;
`;

export const StyledContainer = styled(NW2Container)``;

export const TopSection = styled.div`
  background-color: ${NW2Gray900Color};
  color: ${whiteColor};
  padding: ${offsetXXXXLg} 0;
`;

export const TopSectionHeading = styled.h1`
  font-size: 34px;
  line-height: 42px;
  font-weight: ${fontWeightBold};
  color: ${whiteColor};
  margin-bottom: ${offsetXXXLg};
`;

export const TopSectionInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${offsetXXXXLg};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 340px 1fr;
    grid-row-gap: 0;
  }

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 374px 1fr;
  }
`;

export const TopSectionInfoLeft = styled.div`
  display: flex;
  gap: ${offsetXLg};
`;

export const TopSectionInfoRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  @media (min-width: ${mdBp}px) {
    flex-direction: row;
    padding-left: ${offsetXLg};
  }
`;

export const TopSectionInfoGreetings = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  color: ${NW2Gray300Color};
  text-transform: uppercase;
`;

export const TopSectionInfoName = styled.div`
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightBold};
`;

export const StyledNavLink = styled(NavLink)`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  color: ${whiteColor};
  text-decoration: underline;
`;

export const StyledButton = styled(NW2Button)``;

export const BottomSection = styled.div`
  padding: 0 ${offsetDef} ${offsetSm};
  margin: 0 auto;

  @media (min-width: ${mdBp}px) {
    max-width: 744px;
    padding: ${offsetXXLg};
  }

  @media (min-width: ${xLgBp}px) {
    max-width: 810px;
  }
`;

export const SectionRow = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  display: flex;
  align-items: center;
  gap: ${offsetDef};
  margin-bottom: ${offsetXXSm};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FormActions = styled.div`
  display: flex;
  padding-top: ${offsetXXLg};
  gap: ${offsetXLg};
  justify-content: flex-end;

  @media (min-width: ${mdBp}px) {
    justify-content: flex-start;
  }
`;

export const modalBodyStyle = {
  padding: `${offsetSm} ${offsetXLg} ${offsetXLg}`,
};

export const ModalActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${offsetSm};
  border-top: 1px solid ${NW2Gray100Color};
  padding: ${offsetXLg} ${offsetDef} 0 0;
  margin: 0 -${offsetDef};

  @media (min-width: ${mdBp}px) {
    justify-content: flex-end;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

export const BreakWordText = styled.span`
  word-break: break-word;
`;

import React, {memo, ReactNode} from 'react';
import styled from 'styled-components';

import {borderRadiusLg, NW2Gray200Color} from 'constants/styleVars';

const EmptyData = styled.span<{minHeight: string; minWidth: string}>`
  display: inline-block;
  vertical-align: middle;
  background-color: ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
  min-height: ${({minHeight}) => minHeight};
  min-width: ${({minWidth}) => minWidth};
`;

export const UnitCardRow = memo(
  ({
    data,
    minHeight = '26px',
    minWidth = '100%',
  }: {
    data: ReactNode;
    minHeight?: string;
    minWidth?: string;
  }) => <>{data || <EmptyData minHeight={minHeight} minWidth={minWidth} />}</>,
);

import styled from 'styled-components';

import {
  offsetNone,
  offsetXLg,
  offset80,
  whiteColor,
  NW2Gray200Color,
} from 'constants/styleVars';

const SIDEBAR_WIDTH = '240px';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${SIDEBAR_WIDTH} 1fr;
  background-color: ${whiteColor};
  height: 100%;
`;

export const Container = styled.div`
  padding: ${offsetXLg} 0;
`;

export const AnchorsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr ${SIDEBAR_WIDTH};
  padding: 0 ${offsetXLg};
  grid-column-gap: ${offsetXLg};
`;

export const AnchorSections = styled.div`
  section:nth-child(1) {
    padding-top: ${offsetNone};
  }
`;

export const StyledDiv = styled.div`
  section:last-child {
    padding-bottom: ${offset80};
    border-bottom: 1px solid ${NW2Gray200Color};
  }
`;

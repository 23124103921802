import {useMemo} from 'react';

import {useAppSelector} from 'store/hooks';
import {IOfferRequestUnit} from 'types/offer';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {getDayItems} from '../Offer/helpers';

export const useIsAlternativeOffer = () => {
  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);

  const offerDetailsItems = useMemo(
    () => getDayItems(getFilteredUnitsByEventType(offerDetails.days)),
    [offerDetails?.days],
  );

  const isAlternative = offerDetailsItems.some(
    (item: IOfferRequestUnit) => !item.unitId,
  );

  return {isAlternative};
};

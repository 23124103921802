import React, {FC} from 'react';
//TODO: Remove Antd as dependency
import {Card, Row, Col} from 'antd';

import Icon from 'view/components/Icon';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import {NW2Info50Color, offsetDef, offsetSm} from 'constants/styleVars';
import {
  CardIncludedTitle,
  CardPrice,
  CardTitle,
  CardTitleWrap,
  StyledDivider,
  StyledEquipment,
  StyledList,
} from '../Space.styles';
import {
  PricePerPerson,
  TotalPrice,
} from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard/NW2BookingUnitCard.styles';

const cardStyle = {
  backgroundColor: `${NW2Info50Color}`,
  marginBottom: `${offsetDef}`,
  borderRadius: `${offsetSm}`,
  border: 'none',
};
const headStyle = {
  border: 'none',
  padding: `0 ${offsetDef}`,
};
const bodyStyle = {
  padding: `${offsetDef} `,
  paddingTop: '0',
};

type TProps = {
  equipment?: string[];
  foodAndBeverage?: string[];
  price?: number;
  backgroundColor?: string;
  titleTextColor?: string;
  participants?: number;
  isSupplierView?: boolean;
  currency?: string;
};
const PackagesCard: FC<TProps> = ({
  equipment,
  foodAndBeverage,
  price,
  backgroundColor,
  titleTextColor,
  participants = 1,
  currency,
  isSupplierView,
}) => {
  const cardTitle = (
    <CardTitleWrap>
      <Icon icon='SHIPMENT_UPLOAD' size={20} info={!isSupplierView} />
      <CardTitle titleTextColor={titleTextColor}>What’s included</CardTitle>
    </CardTitleWrap>
  );

  return (
    <Card
      title={cardTitle}
      style={{
        ...cardStyle,
        backgroundColor: `${backgroundColor || cardStyle.backgroundColor}`,
      }}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
    >
      <Row gutter={16}>
        <Col xs={24} xl={8}>
          <CardIncludedTitle>Equipment</CardIncludedTitle>
        </Col>
        <Col xs={24} xl={16}>
          <StyledEquipment>{equipment?.join(', ')}</StyledEquipment>
        </Col>
        <Col xs={24} xl={8}>
          <CardIncludedTitle>Food & Beverage</CardIncludedTitle>
        </Col>
        <Col xs={24} xl={16}>
          <StyledList>
            {foodAndBeverage?.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </StyledList>
        </Col>
      </Row>
      {price && (
        <>
          <StyledDivider />
          <Row>
            <Col span={8}>
              <CardIncludedTitle>Price</CardIncludedTitle>
            </Col>
            <Col span={16}>
              <CardPrice isFlexEnd={isSupplierView}>
                {isSupplierView ? (
                  <TotalPrice>
                    <PriceDisplay
                      price={participants * price}
                      currency={currency}
                    />
                    <PricePerPerson>
                      (<PriceDisplay price={price} currency={currency} /> p/p)
                    </PricePerPerson>
                  </TotalPrice>
                ) : (
                  <div>
                    <PriceDisplay price={price} currency={currency} /> / per
                    person
                  </div>
                )}
              </CardPrice>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default PackagesCard;

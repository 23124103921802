import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {notification} from 'antd';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import ApiCommonService from 'infra/common/apiCommonService';
import useInterval from 'hooks/useInterval';

import {Routes} from 'constants/routes';
import {TextValue} from 'view/components/TextValue';
import {setCustomerBookingsTabFilter} from 'store/customer/customerSlice';
import {ECustomerBookingsTabFilter} from 'types/dto/SearchTab.types';

type TResponseNotificationType = 'RFP_DECLINED' | 'RFP_CONFIRMED';
type TNotificationType = 'error' | 'success';

interface IPopupNotification {
  data: string;
  id: number;
  notificationType: TResponseNotificationType;
  userId: number;
}

interface INotificationConfig {
  type: TNotificationType;
  message: string;
  description: string | React.ReactNode;
}

const PopupNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useAppSelector(({app}) => app.user.id);

  const fetchNotifications = useCallback(
    async (appVenueId: number) => {
      const navigateToCanceledBookingsOverview = (key: string) => () => {
        dispatch(
          setCustomerBookingsTabFilter(
            ECustomerBookingsTabFilter.ORDERS_HISTORY,
          ),
        );
        navigate(Routes.customerBookings, {replace: true});
        notification.close(key);
      };

      const navigateToConfirmedBookingsOverview = (key: string) => () => {
        dispatch(
          setCustomerBookingsTabFilter(
            ECustomerBookingsTabFilter.CUSTOMER_UPCOMING_ORDERS,
          ),
        );

        navigate(Routes.customerBookings, {replace: true});
        notification.close(key);
      };

      const NOTIFICATION_CONFIGS: Record<
        TResponseNotificationType,
        INotificationConfig
      > = {
        RFP_DECLINED: {
          type: 'error',
          message: 'Unfortunately the venue has declined your booking request',
          description: (
            <TextValue
              primary
              onClick={navigateToCanceledBookingsOverview('RFP_DECLINED')}
            >
              More details
            </TextValue>
          ),
        },
        RFP_CONFIRMED: {
          type: 'success',
          message: 'The venue has confirmed your booking request!',
          description: (
            <TextValue
              primary
              onClick={navigateToConfirmedBookingsOverview('RFP_CONFIRMED')}
            >
              More details
            </TextValue>
          ),
        },
      };

      const showNotification = (notif: IPopupNotification) => {
        const {notificationType} = notif;
        const configs = NOTIFICATION_CONFIGS[notificationType];
        if (configs) {
          return notification[configs.type]({
            type: configs.type,
            message: configs.message,
            description: configs.description,
            duration: 0,
            key: notificationType,
          });
        }
      };

      try {
        const notifications = await ApiCommonService.getNotifications(
          appVenueId,
        );
        if (notifications?.length) {
          notifications.forEach((notification: IPopupNotification) => {
            showNotification(notification);
          });
        }
      } catch (err) {}
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    if (userId) {
      fetchNotifications(+userId);
    }
  }, [userId, fetchNotifications]);

  useInterval(() => {
    if (userId) {
      fetchNotifications(+userId);
    }
  }, 60000); // 60 seconds interval

  return null;
};

export default PopupNotification;

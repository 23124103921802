import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import {useClickOutside} from 'hooks/useClickOutside';

import {useAppSelector} from 'store/hooks';
import {ERoomSchema, ERoomSchemaNames, iconsObject} from 'types/venue';
import {
  INITIAL_SEATING_STYLE,
  setSearchCriteriaSeatingStyle,
} from 'store/search/searchSlice';
import {
  borderRadiusLg,
  mdBp,
  NW2Gray500Color,
  NW2Gray800Color,
  NW2Primary,
  offsetDef,
  offsetXLg,
  whiteColor,
} from 'styles/configs';
import {
  StyledActions,
  StyledDrawerContentHeader,
  StyledDrawerContentFooter,
  StyledInputContainer,
} from 'view/components/NW2Datepicker/NW2DatePicker/NW2MultiDatePickerMobile.styles';
import useToggle from 'hooks/useToggle';

const Wrapper = styled.div`
  position: relative;
`;

const List = styled.div`
  display: flex;
  background-color: ${whiteColor};
  padding: ${offsetDef};
  border-radius: ${borderRadiusLg};
  overflow: hidden;
  top: calc(100% + ${offsetXLg});
  right: -${offsetXLg};
  position: absolute;
  z-index: 100;
`;

const DrawerContent = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, minmax(74px, 1fr));
  grid-gap: ${offsetDef};
  padding: ${offsetXLg} ${offsetDef};
`;

const Item = styled.div<{checked: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid
    ${({checked}) => (checked ? NW2Gray800Color : NW2Gray500Color)};
  color: ${({checked}) => (checked ? NW2Gray800Color : NW2Gray500Color)};
  background-color: ${({checked}) => (checked ? NW2Primary : 'none')};

  @media (min-width: ${mdBp}px) {
    &:not(&:last-child) {
      margin-right: ${offsetDef};
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

type TProps = {
  onClickInput: () => void;
};

const SeatingStyleInput = ({onClickInput}: TProps) => {
  const [showSeatingStylePopUp, setShowSeatingStylePopUp] = useState(false);
  const [showDrawer, toggleShowDrawer] = useToggle(false);
  const [seatingStyleLocal, setSeatingStyleLocal] = useState(
    INITIAL_SEATING_STYLE,
  );

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const seatingStyleFromStore = useAppSelector(
    ({search}) => search.searchCriteria.seatingStyle,
  ) as ERoomSchemaNames;

  const dispatch = useDispatch();

  const onClickInputHandler = () => {
    if (isMobile) {
      toggleShowDrawer();
    } else {
      onClickInput();
      setShowSeatingStylePopUp(!showSeatingStylePopUp);
    }
  };

  const [wrapperRef] = useClickOutside(showSeatingStylePopUp, () => {
    setShowSeatingStylePopUp(false);
    dispatch(setSearchCriteriaSeatingStyle(seatingStyleLocal));
  });

  const nextHandler = () => {
    dispatch(setSearchCriteriaSeatingStyle(seatingStyleLocal));
    toggleShowDrawer();
  };

  const clearHandler = () => {
    if (seatingStyleFromStore !== seatingStyleLocal) {
      setSeatingStyleLocal(seatingStyleFromStore);
    }
  };

  const onClose = () => {
    clearHandler();
    toggleShowDrawer();
  };

  useEffect(() => {
    setSeatingStyleLocal(seatingStyleFromStore);
  }, [seatingStyleFromStore]);

  const items = Object.values(ERoomSchemaNames).map((roomSchema) => (
    <Item
      key={roomSchema}
      checked={seatingStyleLocal === roomSchema}
      onClick={() => setSeatingStyleLocal(roomSchema)}
    >
      <Icon size={34} clickable icon={iconsObject[roomSchema]} />
    </Item>
  ));

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <input
          type='text'
          id='search_seating_style'
          onClick={onClickInputHandler}
          value={ERoomSchema[seatingStyleLocal]}
          readOnly
        />
        {showSeatingStylePopUp && <List>{items}</List>}
      </Wrapper>
      {isMobile && (
        <StyledAntdDrawer
          onClose={onClose}
          open={showDrawer}
          title={null}
          height='auto'
          bodyStyle={{
            padding: 0,
          }}
        >
          <StyledDrawerContentHeader showDivider={true}>
            <StyledInputContainer highlighted>
              <input
                type='text'
                id='search_seating_style_mobile'
                value={ERoomSchema[seatingStyleLocal]}
                readOnly
              />
              <label>
                <span>seating style</span>
              </label>
            </StyledInputContainer>
          </StyledDrawerContentHeader>

          <DrawerContent>{items}</DrawerContent>

          <StyledDrawerContentFooter showDivider={true}>
            <StyledActions>
              <NW2Button onClick={clearHandler} fullWidth>
                clear
              </NW2Button>

              <NW2Button buttonType='primary' onClick={nextHandler} fullWidth>
                next
              </NW2Button>
            </StyledActions>
          </StyledDrawerContentFooter>
        </StyledAntdDrawer>
      )}
    </>
  );
};

export default SeatingStyleInput;

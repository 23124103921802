import React, {FC} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useNavigate} from 'react-router-dom';

import TextValue from 'view/components/TextValue';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';

import {PageTitle} from 'view/components/Typography';
import {offsetDef} from 'constants/styleVars';
import {Routes} from 'constants/routes';

import img404 from '../../../img/404.svg';

const PageContainer = styled.div`
  height: 100%;
  max-width: 450px;
  padding: 0 ${offsetDef};
  margin: 100px auto;
  text-align: center;

  display: grid;
  grid-gap: ${offsetDef};
  justify-items: center;
`;

const Image = styled.img`
  display: inline-block;
  width: 300px;
`;

const Page404: FC = () => {
  const navigate = useNavigate();
  const {postToFlutterApp, isBTCApp} = useFlutterWebView();

  const handleBackToHomePage = () => {
    if (isBTCApp) {
      postToFlutterApp();
    } else {
      navigate(Routes.mainLanding);
    }
  };

  return (
    <PageContainer>
      <Image src={img404} alt='Icon' />

      <PageTitle>Oops...</PageTitle>
      <TextValue>
        The link clicked may be broken, or the page may have been removed. We're
        sorry.
      </TextValue>
      <Button type='primary' onClick={handleBackToHomePage}>
        Back to home page
      </Button>
    </PageContainer>
  );
};

export default Page404;

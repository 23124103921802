import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import styled, {css} from 'styled-components';

import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';
import AccommodationTitle from 'view/venue/components/AccommodationTitle';
import OfferExtrasList from 'view/venue/components/OfferExtrasList';
import LeftSideItemTitle from 'view/venue/components/LeftSideContainer/LeftSideItemTitle';

import {EEventType} from 'types/venue';
import {IOfferRequestDay} from 'types/offer';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {TSearchVenuesDay} from 'types/search';
import {
  borderRadiusXLg,
  offsetXLg,
  NW2Gray200Color,
  offsetXXLg,
  offsetXXXXLg,
  defaultTextColor,
} from 'constants/styleVars';
import {StyledLeftSideItem} from '../Space/Space.styles';

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${offsetXLg};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusXLg};
`;

const LeftSideItem = styled(StyledLeftSideItem)<{
  paddingTop?: string;
  paddingBottom?: string;
  isNoBorderTop?: boolean;
  isDesktop?: boolean;
  isDefaultItem?: boolean;
}>`
  ${({isDefaultItem, isDesktop}) =>
    isDesktop &&
    isDefaultItem &&
    css`
      display: grid;
      grid-template-columns: 1fr 4fr;
    `}
`;

interface IProps {
  title: 'PRE-ARRIVAL' | 'POST-EVENT';
  eventType?: EEventType;
  rooms: TSearchVenuesDay[] | IOfferRequestDay[];
  isOfferPreview?: boolean;
  hasDots?: boolean;
  isDefaultItem?: boolean;
  isDesktop?: boolean;
}
export function Bedrooms({
  rooms,
  title,
  isOfferPreview,
  hasDots,
  isDefaultItem,
  isDesktop,
}: IProps) {
  const allExtrasOptions: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  return (
    <>
      {rooms.map((item, index) => {
        const accommodationData = {
          title: <AccommodationTitle checkIn={item.checkIn} />,
          content: (
            <>
              {isOfferPreview ? (
                <OfferExtrasList bedrooms={item.bedrooms} />
              ) : (
                <PreviewExtrasList
                  extras={item.bedrooms}
                  extrasOptions={allExtrasOptions}
                  hasDots={hasDots}
                />
              )}
            </>
          ),
        };

        return (
          <LeftSideItem
            key={EEventType.PRE_ARRIVAL + item.checkIn}
            paddingBottom={
              title === 'PRE-ARRIVAL'
                ? '0 !important'
                : index === rooms.length - 1
                ? offsetXXXXLg
                : undefined
            }
            paddingTop={title === 'POST-EVENT' ? '0 !important' : offsetXXLg}
            subtitleColor={defaultTextColor}
            isNoBorderTop={title === 'POST-EVENT'}
            item={{
              title: <LeftSideItemTitle title={title} checkIn={item.checkIn} />,
              children: (
                <Item>
                  {accommodationData.title}
                  {accommodationData.content}
                </Item>
              ),
            }}
            isDefaultItem={isDefaultItem}
            isDesktop={isDesktop}
          />
        );
      })}
    </>
  );
}

import {useMemo} from 'react';

import LocalStorageService from 'infra/common/localStorage.service';
import {useAppSelector} from 'store/hooks';
import {TGroupRooms} from 'types/search';

export const useGroupsRoomsData = (): TGroupRooms => {
  const storedRooms = useAppSelector(({search}) => search.groups.rooms);
  const storageRooms = LocalStorageService.getByKey('groupsRooms');

  return useMemo(() => {
    if (storageRooms) {
      const {single, double} = JSON.parse(storageRooms);

      return {single, double};
    }

    return {single: storedRooms.single, double: storedRooms.double};
  }, [storageRooms, storedRooms.double, storedRooms.single]);
};

import React from 'react';
import styled from 'styled-components';

import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {
  fontWeightBold,
  fontSizeXLg,
  offsetXXXSm,
  blackColor,
  lineHeightXLg,
} from 'constants/styleVars';

const PriceContent = styled.div`
  text-align: right;
`;

const TotalPriceText = styled.div`
  display: inline-block;
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightBold};
  border-bottom: ${offsetXXXSm} solid black;
  padding-bottom: 1px;
  color: ${blackColor};
  white-space: nowrap;
`;

type TProps = {
  totalPrice: number;
  currency: string;
  isHidden: boolean;
};

const NW2PriceBlock = ({totalPrice, currency, isHidden}: TProps) => {
  if (isHidden || !totalPrice) return null;
  return (
    <PriceContent>
      <TotalPriceText>
        <PriceDisplay price={totalPrice} currency={currency} />
      </TotalPriceText>
    </PriceContent>
  );
};

export default NW2PriceBlock;

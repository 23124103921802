import {IAmenity} from 'types/dto/IAmenity';
import {ICommonAmenity} from 'types/dto/ICommonAmenity';
import {ERoomType} from 'types/dto/ERoomType.type';

export const sortRoomAmenities = (amenities: IAmenity[]) =>
  amenities.sort((a, b) => a.name.localeCompare(b.name));

export const getAmenitiesToUpdate = (
  amenitiesIds: number[],
  amenities: IAmenity[],
) => amenities.filter((amenity) => amenitiesIds.includes(amenity.id));

export const updateRooms = (
  amenitiesIds: number[],
  commonAmenities: ICommonAmenity[],
  roomAmenities: IAmenity[],
  roomName: ERoomType,
) =>
  commonAmenities.map((room) => {
    if (room.type === roomName) {
      return {
        ...room,
        allUnitsIncluded: getAmenitiesToUpdate(amenitiesIds, roomAmenities),
      };
    }

    return room;
  });

export const updateCommonAmenities =
  (
    commonAmenities: ICommonAmenity[],
    roomAmenities: IAmenity[],
    roomName: ERoomType,
    updateVenue: (values: any, onSuccessCallback?: () => void) => Promise<void>,
    onSuccessCallback?: () => void,
  ) =>
  (amenitiesIds: number[]) => {
    const isRoomSet =
      commonAmenities.findIndex((room) => room.type === roomName) !== -1;

    const updatedAmenities = isRoomSet
      ? updateRooms(amenitiesIds, commonAmenities, roomAmenities, roomName)
      : [
          ...commonAmenities,
          {
            type: roomName,
            allUnitsIncluded: getAmenitiesToUpdate(amenitiesIds, roomAmenities),
          },
        ];

    updateVenue({commonAmenities: updatedAmenities}, onSuccessCallback);
  };

import React from 'react';
import styled from 'styled-components';

import {TAnchorContentSections} from './helpers';
import {SectionLightContainer} from 'view/venue/NW2VenueDetails/NW2VenueDetails.styles';
import {StyledH5} from 'view/components/Typography';
import {
  offsetXXXLg,
  fontSizeMd,
  NW2Gray600Color,
  fontWeightBold,
  offsetXSm,
} from 'constants/styleVars';

const SectionTitle = styled(StyledH5)<{isSubTitle?: boolean}>`
  margin-bottom: ${({isSubTitle}) => (isSubTitle ? offsetXSm : offsetXXXLg)};
`;

const SectionSubTitle = styled.p`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetXXXLg};
`;

type TProps = {
  anchorContentSections: TAnchorContentSections[];
};

export function AnchorContentBlock({anchorContentSections}: TProps) {
  return (
    <SectionLightContainer>
      {anchorContentSections.map(
        ({contentTitle, contentSubTitle, contentComponent, sectionId}) => (
          <div key={contentTitle + sectionId} id={sectionId}>
            <SectionTitle isSubTitle={!!contentSubTitle}>
              {contentTitle}
            </SectionTitle>
            {!!contentSubTitle && (
              <SectionSubTitle>{contentSubTitle}</SectionSubTitle>
            )}
            {contentComponent}
          </div>
        ),
      )}
    </SectionLightContainer>
  );
}

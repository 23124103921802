export const DEFAULT_OPEN_FROM = '9:00';
export const DEFAULT_OPEN_TO = '20:00';
export const DEFAULT_OPEN_FROM_HOUR = '9';
export const DEFAULT_OPEN_FROM_MINUTE = '00';
export const DEFAULT_OPEN_TO_HOUR = '20';
export const DEFAULT_OPEN_TO_MINUTE = '00';
export const WHOLE_DAY_OPEN_FROM_HOUR = '0';
export const WHOLE_DAY_OPEN_FROM_MINUTE = '00';
export const WHOLE_DAY_OPEN_TO_HOUR = '23';
export const WHOLE_DAY_OPEN_TO_MINUTE = '59';

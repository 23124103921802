import styled from 'styled-components';

import {
  fontSizeMd,
  lineHeightLg,
  offsetDef,
  offsetNone,
  offsetSm,
  NW2Gray300Color,
  offsetXSm,
  fontWeightNormal,
  lineHeightXSm,
  fontSizeXXSm,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<{isMarginLeft?: boolean}>`
  display: flex;
  gap: ${offsetDef};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  ${({isMarginLeft}) => isMarginLeft && 'margin-left: 6px;'}
`;
export const ListWrapper = styled.div``;

export const ListItem = styled.div<{isLastItem?: boolean}>`
  display: flex;
  gap: ${offsetDef};
  padding: ${offsetSm} ${offsetNone};
  border-bottom: ${({isLastItem}) =>
    isLastItem ? '' : `1px solid ${NW2Gray300Color}`};
`;

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: ${offsetXSm};
`;

export const StyledBold = styled.b`
  width: 35px;
`;

export const StaticText = styled.div`
  margin: ${offsetDef} ${offsetNone} ${offsetSm};
`;

export const FootNote = styled.div`
  font-size: ${fontSizeXXSm};
  font-weight: ${fontWeightNormal};
  line-height: ${lineHeightXSm};
  margin-top: ${offsetSm};
`;

import React, {memo, useState} from 'react';
import _debounce from 'lodash/debounce';
import {Form, FormSpy} from 'react-final-form';

import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';

import {NameAndFloorFormItems} from './NameAndFloorFormItems';

import {floorLevels, getValueByKey} from 'constants/floorStorey';
import {ICommonProps} from '../../types';
import {ContentName, Text} from '../../NMMSpacesDetails.styles';

interface IProps extends ICommonProps {
  name: string;
  floors: number[] | [];
  initialValues: {
    name: string;
    floor: string;
  };
}

const NameAndFloor = ({
  name,
  updateSpace,
  isSending,
  floors,
  isEditorShowed,
  onEditorShowed,
  initialValues,
}: IProps) => {
  const [isFormDirty, setFormDirty] = useState(false);

  const onSubmit = (values: any) => {
    const floorNumber = getValueByKey(floorLevels, values.floor);

    const updatedNameAndFloor = {
      name: values.name,
      floor: floorNumber,
    };

    updateSpace(updatedNameAndFloor);
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return isEditorShowed ? (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <NameAndFloorFormItems floors={floors} />

          <NMMSubmitSection
            isLoading={isSending}
            handleCancel={onEditorShowed}
            disabled={!isFormDirty}
          />

          <FormSpy
            subscription={{dirtyFields: true}}
            onChange={processFormFields}
          />
        </form>
      )}
    />
  ) : (
    <div>
      <ContentName data-testid={`space-details-name`}>{name}</ContentName>
      <Text data-testid={`space-details-location`}>{initialValues.floor}</Text>
    </div>
  );
};

export default memo(NameAndFloor);

import React, {ChangeEvent, useState} from 'react';
import {useDispatch} from 'react-redux';

import NW2Button from 'view/components/NW2Button';
import InputCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/components/InputCheckbox';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import {InputRadioButton} from 'view/components/NW2FormItem/components';
import {Link} from 'view/components/Typography';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {OfferCancellationPolicyFileUploaderModal} from '../OfferCancellationPolicyFileUploaderModal';

import {useAppSelector} from 'store/hooks';
import useToggle from 'hooks/useToggle';
import {EPolicyType} from '../../types';
import {PageContent} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {StyledErrorMessage} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {StyledIcon} from 'view/components/Uploader/Uploader.styles';
import {offsetDef, offsetXXLg, offsetXXSm} from 'constants/styleVars';
import {StepActions} from '../../OfferCreate.styles';

import {
  Box,
  BoxTitle,
  List,
  ListItem,
  NoWrap,
  SubTitle,
  Title,
  UploadedFileNameWrap,
  UploadedFileNote,
  UploadedFileWrap,
} from './TermsPage.styles';
import {
  getCancellationPolicyLinkUrlById,
  getTermsAndConditionLinkUrlById,
} from 'utils/helpers';
import {setSupplierCancellationPolicy} from 'store/offers/offersSlice';

interface IProps {
  hasBookerTerms: boolean;
  hasBookerConditions: boolean;
  handleStart: () => void;
}
export function TermsPageUSA({
  hasBookerTerms,
  hasBookerConditions,
  handleStart,
}: IProps) {
  const dispatch = useDispatch();

  const cancellationPolicyFile = useAppSelector(
    ({offers}) => offers.cancellationPolicyFile,
  );

  const cancellationPolicyFileBlobPreview = useAppSelector(
    ({offers}) => offers.cancellationPolicyFileBlobPreview,
  );

  const customerCancellationPolicyId = useAppSelector(
    ({offers}) => offers.offerDetails.cancellationPolicy?.id || '',
  );

  const termsAndConditionsId = useAppSelector(
    ({offers}) => offers.offerDetails.termsAndConditionsId || '',
  );

  const requestDetails = useAppSelector(({offers}) => offers.offerDetails);

  const [policyType, setPolicyType] = useState<EPolicyType | ''>('');
  const [isTermsChecked, setTermsChecked] = useState(false);
  const [hasTermsError, setTermsError] = useState(false);
  const [isShowUploaderModal, toggleUploaderModal] = useToggle();
  const [isOpenCancelModal, toggleOpenCancelModal] = useToggle();
  const [isHrsPolicyShowed, toggleHrsPolicyShowed] = useToggle(false);
  const [hasPolicyError, setPolicyError] = useState(false);
  const [policyErrorMessage, setPolicyErrorMessage] = useState<string | null>(
    null,
  );

  const isFile = cancellationPolicyFile?.type === 'application/pdf'; //check type from BE

  const onPolicyTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as EPolicyType;
    setPolicyType(value);
    setPolicyError(false);
    dispatch(setSupplierCancellationPolicy(value === EPolicyType.VENUE));
  };

  const onTermsChange = () => {
    setTermsChecked((prev) => !prev);
    setTermsError(false);
  };

  const onNext = () => {
    let hasError = false;

    if (!policyType) {
      setPolicyError(true);
      setPolicyErrorMessage(
        'To proceed with this request, you need to choose one of the cancellation policies.',
      );
      hasError = true;
    }

    if (policyType === EPolicyType.VENUE && !isFile) {
      setPolicyError(true);
      setPolicyErrorMessage('Please upload your cancellation policy.');
      hasError = true;
    }

    if (hasBookerTerms && !isTermsChecked) {
      setTermsError(true);
      hasError = true;
    }

    if (!hasError) {
      handleStart();
    }
  };

  return (
    <>
      <PageContent width={442}>
        <Box>
          <div>
            <BoxTitle>
              <Title>Modification and Cancellation Conditions</Title>
              <SubTitle>(Required)</SubTitle>
            </BoxTitle>

            <List>
              <ListItem>
                <InputRadioButton
                  checked={policyType === EPolicyType.HRS}
                  onChange={onPolicyTypeChange}
                  value={EPolicyType.HRS}
                  name='policies'
                  label='I agree to '
                  hasError={hasPolicyError}
                />
                {hasBookerConditions ? (
                  <Link
                    href={getCancellationPolicyLinkUrlById(
                      customerCancellationPolicyId,
                    )}
                    target='_blank'
                    black
                  >
                    Booker's Cancellation Policy
                  </Link>
                ) : (
                  <Link onClick={toggleHrsPolicyShowed} black>
                    HRS Cancellation Policy
                  </Link>
                )}
              </ListItem>

              <ListItem direction={'column'}>
                <InputRadioButton
                  checked={policyType === EPolicyType.VENUE}
                  onChange={onPolicyTypeChange}
                  value={EPolicyType.VENUE}
                  name='policies'
                  label={
                    isFile ? (
                      <UploadedFileWrap>
                        <UploadedFileNameWrap>
                          <a
                            href={cancellationPolicyFileBlobPreview}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {cancellationPolicyFile.name}
                          </a>
                          <div>
                            <StyledIcon
                              size={20}
                              icon='NW2_CANCEL_BIN'
                              onClick={toggleOpenCancelModal}
                            />
                          </div>
                        </UploadedFileNameWrap>
                        <UploadedFileNote>
                          Note: To upload a new policy, you need to delete the
                          current one. The PDF file name is for internal use
                          only and won't be visible to the booker.
                        </UploadedFileNote>
                      </UploadedFileWrap>
                    ) : (
                      <>I would like to use my Venue’s Cancellation Policy </>
                    )
                  }
                  hasError={hasPolicyError}
                />

                {!isFile && policyType === EPolicyType.VENUE && (
                  <a
                    onClick={toggleUploaderModal}
                    style={{marginLeft: offsetXXLg}}
                  >
                    Upload Cancellation Policy
                  </a>
                )}
              </ListItem>
            </List>

            {hasPolicyError && policyErrorMessage && (
              <StyledErrorMessage margin={`${offsetDef} 0 0 0`}>
                {policyErrorMessage}
              </StyledErrorMessage>
            )}
          </div>

          {hasBookerTerms && (
            <div>
              <BoxTitle>
                <>
                  <Title>Booker's Terms and Conditions</Title>
                  <SubTitle>(Required)</SubTitle>
                </>
              </BoxTitle>
              <List>
                <ListItem>
                  <InputCheckbox
                    name='terms'
                    label={<NoWrap>I agree to</NoWrap>}
                    checked={isTermsChecked}
                    hasError={hasTermsError}
                    onChange={onTermsChange}
                  />
                  <a
                    href={getTermsAndConditionLinkUrlById(termsAndConditionsId)}
                    target='_blank'
                    rel='noreferrer noopener'
                    style={{marginLeft: offsetXXSm}}
                  >
                    Booker's Terms and Conditions
                  </a>
                </ListItem>
              </List>
              {hasTermsError && (
                <StyledErrorMessage margin={`${offsetDef} 0 0 0`}>
                  To proceed with this request, you need to agree to Booker's
                  Terms and Conditions
                </StyledErrorMessage>
              )}
            </div>
          )}
        </Box>
      </PageContent>

      <StepActions>
        <NW2Button onClick={onNext} buttonType='primary' size='small'>
          Let's go!
        </NW2Button>
      </StepActions>

      <NW2ModalDrawer
        isShowed={isHrsPolicyShowed}
        onClose={toggleHrsPolicyShowed}
        header='HRS Modification AND Cancellation conditions'
        body={<CancellationPolicyBlock requestNumber={requestDetails.number} />}
        isMobile={false}
        modalWidth={550}
      />
      <OfferCancellationPolicyFileUploaderModal
        isShowUploaderModal={isShowUploaderModal}
        isOpenCancelModal={isOpenCancelModal}
        toggleUploaderModal={toggleUploaderModal}
        toggleCancelModal={toggleOpenCancelModal}
        cancellationPolicyFile={cancellationPolicyFile}
        setPolicyError={setPolicyError}
      />
    </>
  );
}

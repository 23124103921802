import React from 'react';

import InfoPageImage from '../../NW2MultiModal/components/InfoPageImage';
import TextContent from './components/TextContent';
import useGoBackFromInfoPage from '../../NW2MultiModal/hooks/useGoBackFromInfoPage';
import image from 'img/privacyPolicy.jpg';

import {NW2ModalTwoColumnsContent} from 'view/components/NW2ModalTwoColumns/NW2ModalTwoColumnsContent';

const CorporateUserPrivacyPolicyPage = () => {
  const goBack = useGoBackFromInfoPage();

  return (
    <NW2ModalTwoColumnsContent
      title='Privacy policy'
      onToggleShow={goBack}
      colLeft={<InfoPageImage src={image} />}
      // TODO: Here is text from Customer. Need to be changed when will be text for Corporate User
      colRight={<TextContent />}
    />
  );
};

export default CorporateUserPrivacyPolicyPage;

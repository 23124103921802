import React, {FC, useEffect} from 'react';
import queryString from 'query-string';
import {useNavigate, useLocation} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import PageContent from 'view/common/PageContent';
import CompanyUsers from './components/CompanyUsers';
import SiderMenu from '../components/SiderMenu';
import NWLink from 'view/components/NWLink';

import {Routes} from 'constants/routes';
import {PageTitle} from 'view/components/Typography';
import {
  getCorporateAccountCompanyById,
  getCorporateAccountCompanyUsers,
} from 'store/admin/apiActions';
import {
  clearCorporateAccountCompany,
  setCorporateAccountCompanyUsers,
  setTotalCorporateAccountCompanyUsers,
} from 'store/admin/adminSlice';

import {EMPTY_OBJECT} from 'constants/app';

const CompanyAccount: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const queryData = queryString.parse(location.search.slice(1)) || EMPTY_OBJECT;
  const companyIdFromQuery = queryData.id || '';
  const companyId = +companyIdFromQuery;

  const totalUsers: number = useAppSelector(
    ({admin}) => admin.totalCorporateAccountCompanyUsers,
  );

  const page: number = useAppSelector(
    ({admin}) => admin.corporateAccountCompanyUsersPage,
  );

  const size: number = useAppSelector(
    ({admin}) => admin.corporateAccountCompanyUsersSize,
  );

  const loading: boolean = useAppSelector(
    ({admin}) => admin.corporateAccountCompanyUsersLoading,
  );

  const company = useAppSelector(({admin}) => admin.corporateAccountCompany);

  /* Get curent company */
  useEffect(() => {
    if (companyId && !company?.name) {
      dispatch(getCorporateAccountCompanyById(companyId));
    }
  }, [company?.name, companyId, dispatch]);

  /* Fetch company users */
  useEffect(() => {
    if (companyId) {
      dispatch(
        getCorporateAccountCompanyUsers({
          id: companyId,
          page,
          size,
        }),
      );
    }
  }, [page, size, companyId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearCorporateAccountCompany());
      dispatch(setCorporateAccountCompanyUsers([]));
      dispatch(setTotalCorporateAccountCompanyUsers(0));
    };
  }, [dispatch]);

  const goToCorporateAccounts = () =>
    navigate({pathname: Routes.companiesOverview});

  return (
    <>
      <SiderMenu />

      <PageContent hasSider>
        <NWLink
          secondary
          text='Accounts overview'
          onClick={goToCorporateAccounts}
        />
        <PageTitle>{company?.name ? company.name : ''}</PageTitle>

        <CompanyUsers
          companyId={companyId}
          loading={loading}
          totalUsers={totalUsers}
        />
      </PageContent>
    </>
  );
};

export default CompanyAccount;

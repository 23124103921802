import React, {memo, useState} from 'react';
import _debounce from 'lodash/debounce';
import {Form, FormSpy} from 'react-final-form';

import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';
import FullDescription from 'view/components/FullDescription/FullDescription';

import {offsetDef} from 'constants/styleVars';
import {ESpaceDetailsFormFields, ICommonProps} from '../../types';
import {DescriptionFormItems} from './DescriptionFormItems';

interface IProps extends ICommonProps {
  description: string;
  initialValues: {
    description: string;
  };
}

const Description = ({
  initialValues,
  description,
  updateSpace,
  isSending,
  isEditorShowed,
  onEditorShowed,
}: IProps) => {
  const [isFormDirty, setFormDirty] = useState(false);

  const onSubmit = (values: any) => {
    const updatedSpace = {
      description: values.description,
    };

    updateSpace(updatedSpace);
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return isEditorShowed ? (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <DescriptionFormItems />

          <NMMSubmitSection
            isLoading={isSending}
            handleCancel={onEditorShowed}
            disabled={!isFormDirty}
          />

          <FormSpy
            subscription={{dirtyFields: true}}
            onChange={processFormFields}
          />
        </form>
      )}
    />
  ) : (
    <FullDescription
      fontSize={offsetDef}
      description={description}
      numberOfLines={6}
      data-testid={`space-details-${ESpaceDetailsFormFields.DESCRIPTION}`}
    />
  );
};

export default memo(Description);

import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  fontSizeMd,
  NW2Gray100Color,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetSm,
  offsetXSm,
  fontWeightBold,
  borderRadiusDef,
  blackColor,
} from 'constants/styleVars';

export const RoomWrapper = styled.div`
  &:not(&:last-of-type) {
    margin-bottom: ${offsetXLg};
  }
`;

export const RoomSetupBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusDef};
  padding-left: ${offsetDef};
`;

export const AddRoomButton = styled(NW2Button)`
  margin-top: ${offsetXLg};
`;

export const AmenitiesBlock = styled.div`
  margin: ${offsetDef} 0;

  & > span {
    display: inline-flex;
    align-items: center;

    &:not(:last-of-type):after {
      content: '';
      display: block;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      margin: 0 ${offsetXSm};
      background-color: ${blackColor};
    }
  }
`;

export const InfoBlock = styled.div<{isEditMode: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({isEditMode}) => (isEditMode ? 'center' : 'start')};
  margin-bottom: ${({isEditMode}) => (isEditMode ? offsetDef : offsetXSm)};
`;

export const RoomTitle = styled.div<{isEditMode: boolean}>`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
  margin-bottom: ${({isEditMode}) => (isEditMode ? 0 : offsetSm)};
`;

export const RoomSetupBlockIconWrapper = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${NW2Gray100Color};
`;

import React from 'react';
import {useFormState} from 'react-final-form';
import styled from 'styled-components';
import {Tooltip} from 'antd';

import {TextValue} from 'view/components/TextValue';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {IAccountReference, IPaymentReferenceFields} from 'store/payment/types';
import {mdBp, offsetDef, offsetXLg} from 'styles/configs';
import {EBookingFormFields} from 'types/venue';
import {
  CORPORATE_PAYMENT_VALIDATION_RULES,
  validateDefaultReferenceField,
} from '../utils/validationRules';

const BuilderWrapper = styled.div`
  margin-top: ${offsetXLg};
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${offsetXLg};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

interface IProps {
  selectedAccount?: IAccountReference;
}

const PaymentReferenceFieldsBuilder = ({selectedAccount}: IProps) => {
  const formState = useFormState();

  if (!selectedAccount) {
    return null;
  }

  return (
    <BuilderWrapper>
      {selectedAccount.referenceFields && (
        <TextValue bold marginBottom={offsetDef}>
          Please fill the below details
        </TextValue>
      )}
      <FieldsWrapper>
        {formState.values[EBookingFormFields.PAYMENT_INFO].referenceFields.map(
          ({key, value, required}: IPaymentReferenceFields, index: number) => {
            const inputFieldName = `${EBookingFormFields.PAYMENT_INFO}.referenceFields.[${index}].value`;

            const validationRule =
              CORPORATE_PAYMENT_VALIDATION_RULES[
                selectedAccount.paymentProvider
              ]?.[key]?.(key, required) || required
                ? validateDefaultReferenceField(key)
                : undefined;

            const mandatoryStar = validationRule ? '*' : '';

            return (
              <Tooltip key={key} title={value} color='black' placement='bottom'>
                <NW2FormItemInput
                  name={inputFieldName}
                  label={`${key}${mandatoryStar}`}
                  rules={validationRule}
                  placeholder={`${key}${mandatoryStar}`}
                  showAllValidationErrors={false}
                  disabled={false}
                  variant='primary'
                  inputSize='medium'
                  type='text'
                />
              </Tooltip>
            );
          },
        )}
      </FieldsWrapper>
    </BuilderWrapper>
  );
};

export default PaymentReferenceFieldsBuilder;

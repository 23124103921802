import React from 'react';
import styled from 'styled-components';

import {StyledSpan} from 'view/components/Typography';
import {
  fontSizeDefault,
  fontWeightNormal,
  offsetXXSm,
} from 'constants/styleVars';

const HeaderSubtitle = styled(StyledSpan)`
  display: block;
  text-transform: none;
  line-height: 1.6;
  margin-top: ${offsetXXSm};
`;

function FeedbackHeader() {
  return (
    <>
      Feedback
      <HeaderSubtitle fontWeight={fontWeightNormal} fontSize={fontSizeDefault}>
        We appreciate your honest feedback on the design, usability or
        functionality of this page.
      </HeaderSubtitle>
    </>
  );
}

export default FeedbackHeader;

import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import queryString from 'query-string';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDeepCompareEffect} from 'use-deep-compare';

import NW2SearchSection from 'view/components/NW2SearchSection/NW2SearchSection';
import useSearchData from '../hooks/useSearchData';
import NW2VenuesContainer from 'view/venue/NW2VenuesList/components/NW2VenuesContainer';
import NW2VenueListGoogleMap from './components/NW2VenueListGoogleMap/NW2VenueListGoogleMap';
import CONFIG from './NW2VenuesList.config';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getVisibleRadius} from 'utils/googleMapUtils';
import {TMarkerLocation} from 'types/venue';
import {
  setGoogleMapRefetch,
  setGoogleMapVisibleRadius,
  setVenuesListActiveMarker,
} from 'store/venues/venuesSlice';
import {
  setMeetingRequestData,
  setMultiSearchTimeData,
  setSearchCriteria,
  setSearchCriteriaVisibleRadius,
  setSearchListLoaded,
} from 'store/search/searchSlice';
import {INITIAL_RADIUS} from 'constants/app';

import {
  StickyContainer,
  StyledContainer,
  StyledMap,
  StyledWrapper,
} from './NW2VenuesList.styles';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {IMergedVenue} from 'types/search';
import {ShortList} from '../Offer/customer/ShortList';
import {useShortListHandlers} from '../Offer/hooks/useShortListHandlers';
import {clearOperationalTimes} from 'store/venueDetails/venueDetailsSlice';
import {cleanWorkDesksDetails} from 'store/workDesks/workDesksSlice';
import {COUNTRY_CANADA, COUNTRY_USA} from 'constants/venue';
import useFilterVenuesList from './hooks/useFilterVenuesList';
import {useVenuesListSearch} from '../NW2VenueDetails/useVenuesListSearch';
import useScrollPosition from 'hooks/useScrollPosition';
import {Routes} from 'constants/routes';

function NW2VenuesList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {isLoggedInUser} = useLoggedInUser();
  const {
    checkIsShortListItemAdded,
    handleAddShortListItem,
    handleRemoveShortListItem,
  } = useShortListHandlers();

  const venuesContainerRef = useRef<HTMLDivElement>(null);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isGoogleMapExpanded = useAppSelector(
    ({venuesReducer}) => venuesReducer.isGoogleMapExpanded,
  );
  const isSearchTemplatePopupVisible = useAppSelector(
    ({venuesReducer}) => venuesReducer.isSearchTemplatePopupVisible,
  );
  const activeMarker = useAppSelector(
    ({venuesReducer}) => venuesReducer.venuesListActiveMarker,
  );

  // Set initial Data for single room search
  const {initialSearchData, searchData, querySearchData} = useSearchData();

  const activeMarkerId = activeMarker?.accommodationId || 0;

  // Set initial Data for multiroom search
  useDeepCompareEffect(() => {
    // skip if setting up by search template
    if (isSearchTemplatePopupVisible) return;

    if (initialSearchData) {
      const {initialTimeData, meetingRequestData, ...restSearchData} =
        initialSearchData;

      dispatch(setMultiSearchTimeData(initialTimeData));
      dispatch(setMeetingRequestData(meetingRequestData));
      dispatch(setSearchCriteria(restSearchData));
    }
  }, [dispatch, initialSearchData, isSearchTemplatePopupVisible]);

  useEffect(() => {
    return () => {
      // clear venue details in store on unmount/navigate to details page
      dispatch(cleanWorkDesksDetails()); // works only for WD
      dispatch(clearOperationalTimes());
      dispatch(setSearchListLoaded(false));
    };
  }, [dispatch]);

  useScrollPosition(Routes.venuesList);

  const {venuesList, isVenuesListLoading} = useVenuesListSearch({
    initialSearchData,
    startDate: querySearchData.startDate,
    endDate: querySearchData.endDate,
  });

  const {getVenuesWithPagination, filteredVenuesList, totalRecords} =
    useFilterVenuesList({
      data: venuesList.pageItems,
    });

  const isUSA = filteredVenuesList?.some((venue) =>
    [COUNTRY_USA, COUNTRY_CANADA].includes(venue.location.country),
  );

  //marker or venue list item click
  const onVenueItemClick = useCallback(
    (marker: IMergedVenue) => () => {
      if (activeMarker?.accommodationId === marker.accommodationId) {
        dispatch(setVenuesListActiveMarker(null));
      } else {
        dispatch(setVenuesListActiveMarker(marker));
      }
    },
    [activeMarker?.accommodationId, dispatch],
  );

  //short list
  const onRequestForOfferClick = useCallback(
    (venue: IMergedVenue) => () => {
      const {accommodationId, coverImageLink, location, name} = venue;

      const isItemAdded = checkIsShortListItemAdded?.(accommodationId);

      if (isItemAdded) {
        handleRemoveShortListItem?.(accommodationId);
        return;
      }

      onVenueItemClick(venue)();
      handleAddShortListItem?.({
        id: accommodationId,
        img: coverImageLink as string,
        location,
        name,
      });
    },
    [
      checkIsShortListItemAdded,
      handleAddShortListItem,
      handleRemoveShortListItem,
      onVenueItemClick,
    ],
  );

  const closeActiveVenueItem = useCallback(() => {
    dispatch(setVenuesListActiveMarker(null));
  }, [dispatch]);

  useEffect(() => {
    if (isVenuesListLoading) {
      closeActiveVenueItem();
    }
  }, [isVenuesListLoading, closeActiveVenueItem]);

  const getVenuesForGoogleMap = useCallback(
    ({lat, lng, map}: TMarkerLocation & {map: google.maps.Map}): void => {
      const visibleRadius = getVisibleRadius({map});

      const requestData = {
        startDate: initialSearchData.startDate,
        endDate: initialSearchData.endDate,
        meetingRoomCapacity: initialSearchData.meetingRoomCapacity,
        multiRooms: initialSearchData.multiRooms,
        roomType: initialSearchData.roomType,
        seatingStyle: initialSearchData.seatingStyle,
        page: 0,
        offerPage: 0, // todo check what is it
        latitude: lat,
        longitude: lng,
        radius: visibleRadius || INITIAL_RADIUS,
      };

      dispatch(setGoogleMapVisibleRadius(visibleRadius as number));
      dispatch(setSearchCriteriaVisibleRadius(visibleRadius as number));
      dispatch(setGoogleMapRefetch(false));
      dispatch(
        setSearchCriteria({
          latitude: String(lat),
          longitude: String(lng),
          radius: visibleRadius || INITIAL_RADIUS,
        }),
      );

      // scroll to top on new search
      window.scrollTo({
        top: venuesContainerRef.current
          ? window.scrollY +
            venuesContainerRef.current.getBoundingClientRect().top
          : 0,
        behavior: 'smooth',
      });
      // save page to query search to also use it from query params
      navigate({
        pathname: location.pathname,
        search: queryString.stringify({
          ...requestData,
          searchString: searchData.searchString,
        }),
      });
    },
    [
      dispatch,
      initialSearchData,
      location.pathname,
      navigate,
      searchData.searchString,
    ],
  );

  // Scroll left side venue item into view on its active google map marker click
  useLayoutEffect(() => {
    if (activeMarkerId && !isMobile && !isGoogleMapExpanded) {
      setTimeout(() => {
        const element = document.getElementById(`venue-${activeMarkerId}`);
        if (element) {
          const positionY =
            element.getBoundingClientRect().top +
            window.scrollY -
            CONFIG.filtersHeight;
          window.scrollTo({top: positionY, behavior: 'smooth'});
        }
      }, 100); // no other way
    }
  }, [activeMarkerId, isMobile, isGoogleMapExpanded]);

  return (
    <>
      <NW2SearchSection
        isLoggedInUser={isLoggedInUser}
        isVenuesListPage
        isMinimised
        isNoFixed
      />

      <StyledWrapper>
        {/* TODO: commented for release purpose*/}
        {/*{!isMobile && <NW2VenuesFilter />}*/}
        <StyledContainer ref={venuesContainerRef}>
          <StyledMap isMapExpanded={isGoogleMapExpanded}>
            <StickyContainer>
              <NW2VenueListGoogleMap
                venuesList={filteredVenuesList}
                checkIsShortListItemAdded={checkIsShortListItemAdded}
                onRequestForOfferClick={onRequestForOfferClick}
                closeActiveVenueItem={closeActiveVenueItem}
                onVenueItemClick={onVenueItemClick}
                getVenuesForGoogleMap={getVenuesForGoogleMap}
                isVenuesListLoading={isVenuesListLoading}
              />
            </StickyContainer>
          </StyledMap>
          {!isGoogleMapExpanded && (
            <NW2VenuesContainer
              venuesList={filteredVenuesList}
              totalRecords={totalRecords}
              getVenuesWithPagination={getVenuesWithPagination}
              onRequestForOfferClick={onRequestForOfferClick}
              isGoogleMapExpanded={isGoogleMapExpanded}
              activeMarkerId={activeMarkerId}
              isVenuesListLoading={isVenuesListLoading}
            />
          )}
        </StyledContainer>
      </StyledWrapper>

      <ShortList querySearchData={querySearchData} noCompareBtn={isUSA} />
    </>
  );
}

export default NW2VenuesList;

import React from 'react';
import styled from 'styled-components';

import Image from 'view/components/Image';
import NW2Button from 'view/components/NW2Button/NW2Button';
import GetInTouchImg from 'img/get_in-touch_section.webp';

import {
  NW2ContainerWithOffset,
  NW2ContainerRow,
} from 'view/mainLanding/MainLanding.styles';
import {
  fontWeightNormal,
  mdBp,
  offset64,
  offsetLg,
  offsetXXLg,
  offsetXXXLg,
  offsetXXXXLg,
  smBp,
} from 'constants/styleVars';
import {StyledH2, StyledSubH2} from 'view/components/Typography';
import {ISSUES_NEWWORK_EMAIL} from 'constants/app';

const GetInTouchTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ThinStyledSubH2 = styled(StyledSubH2)`
  font-weight: ${fontWeightNormal};
`;

const StyledDefaultButton = styled.div`
  margin-top: ${offset64};
  width: 100%;

  @media (min-width: ${mdBp}px) {
    margin-top: ${offsetXXLg};
  }
`;

const InTouchImageWrapper = styled.div`
  position: relative;
  min-height: 400px;
  margin-top: ${offsetXXXLg};
  margin-bottom: ${offsetXXXLg};
  margin-left: -${offsetLg};
  margin-right: -${offsetLg};

  @media (min-width: ${smBp}px) {
    grid-column: span 2;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (min-width: 678px) {
    margin-right: 0;
  }

  @media (min-width: ${mdBp}px) {
    margin-left: 0;
    grid-column: span 1;
  }

  @media (min-width: 1600px) {
    min-height: 500px;
  }
`;

function GetInTouchSection() {
  const handleGetInTouchClick = () => {
    const subject = 'Get in Touch with HRS';
    window.open(
      `mailto:${ISSUES_NEWWORK_EMAIL}?subject=${encodeURIComponent(subject)}`,
      '_blank',
      'noreferrer noopener',
    );
  };

  return (
    <NW2ContainerWithOffset>
      <NW2ContainerRow>
        <InTouchImageWrapper>
          <Image isExpandToLeft height='100%' src={GetInTouchImg} />
        </InTouchImageWrapper>
        <GetInTouchTextBlock>
          <StyledH2 NW2Gray900Color>Contact us</StyledH2>
          <ThinStyledSubH2 NW2Gray900Color offset={offsetXXXXLg}>
            Our experts are ready to answer any questions and provide more
            information about our comprehensive meeting and group management
            solutions.
          </ThinStyledSubH2>
          <StyledDefaultButton>
            <NW2Button buttonType='primary' onClick={handleGetInTouchClick}>
              get in touch
            </NW2Button>
          </StyledDefaultButton>
        </GetInTouchTextBlock>
      </NW2ContainerRow>
    </NW2ContainerWithOffset>
  );
}

export default GetInTouchSection;

import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {AmenitiesFormItems} from './AmenitiesFormItems';

import {IAmenity} from 'types/dto/IAmenity';
import {ERoomType} from 'types/dto/ERoomType.type';
import {amenitiesTypes} from '../types';
import {ESpaceDetailsContentSections} from '../../../types';
import {useLocalAmenities} from '../hooks/useLocalAmenities';

interface IProps {
  initialValues: {
    [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: number[];
    [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: number[];
  };
  section: ESpaceDetailsContentSections;
  onEditorShowed: () => void;
  amenities: IAmenity[];
  updateSpace: (values: any) => Promise<void>;
  isSending: boolean;
  roomType: ERoomType;
}

const AmenitiesForm = ({
  initialValues,
  section,
  onEditorShowed,
  amenities,
  isSending,
  updateSpace,
  roomType,
}: IProps) => {
  const roomAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.roomAmenities'),
  );
  const deskAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.deskAmenities'),
  );
  const spaceAmenities = useMemo(
    () => (roomType === ERoomType.MEETING_ROOM ? roomAmenities : deskAmenities),
    [deskAmenities, roomAmenities, roomType],
  );

  const sectionAmenities = amenities.filter((amenityItem) =>
    amenitiesTypes[section].includes(amenityItem.type),
  ).length;

  const [amenitiesLocal] = useLocalAmenities({spaceAmenities, roomType});

  const onSubmit = useCallback(
    (values: Record<ESpaceDetailsContentSections, number[]>) => {
      const updatedAmenities = spaceAmenities.filter(({id}) =>
        values[section].includes(id),
      );

      const restAmenities = amenities.filter(
        (amenityItem) => !amenitiesTypes[section].includes(amenityItem.type),
      );

      updateSpace({amenities: [...restAmenities, ...updatedAmenities]});
    },
    [amenities, spaceAmenities, section, updateSpace],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({handleSubmit, form, values}) => {
        const disableSave = _isEqual(values, initialValues);

        const handleCancelClick = () => {
          form.reset();
          if (sectionAmenities) {
            onEditorShowed();
          }
        };

        return (
          <form onSubmit={handleSubmit} noValidate>
            <AmenitiesFormItems amenities={amenitiesLocal[section]} />

            <NMMSubmitSection
              isLoading={isSending}
              handleCancel={handleCancelClick}
              disabled={disableSave}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default AmenitiesForm;

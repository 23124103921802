import React from 'react';

import {pluralize} from 'utils/helpers';
import {SchemaImage} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/AddRoomSchema.styles';
import {imageObject} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/types';
import {ERoomSchema, ERoomSchemaNames} from 'types/venue';
import {Capacity, Container, ContentBlock} from './RoomSetupView.styles';

interface ISetupItemProps {
  capacity: number;
  setupStyle: ERoomSchemaNames;
}

export const RoomSetupViewItem = ({capacity, setupStyle}: ISetupItemProps) => {
  const image = imageObject[setupStyle];
  const name = ERoomSchema[setupStyle];

  return (
    <Container>
      <ContentBlock>
        <b>{name}</b>
        <Capacity>
          <li>{`${capacity} ${pluralize('participant', capacity, false)}`}</li>
        </Capacity>
      </ContentBlock>
      <div>
        <SchemaImage src={image} />
      </div>
    </Container>
  );
};

import React, {useMemo, useState} from 'react';
import {Form} from 'react-final-form';
import {FormApi} from 'final-form';
import _isEqual from 'lodash/isEqual';

import useUpdateVenue from '../../../hooks/useUpdateVenue';
import NW2SubmitSection from 'view/components/NMMSubmitSection';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {EAmenitiesCategories, IVenue} from 'types/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {amenitiesCategoriesMap} from 'view/components/NW2AmenitiesList/types';
import {useSortedAmenities} from 'view/components/NW2AmenitiesList/useSortedAmenities';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {Group, GroupItem, GroupWrapper, Title} from './NW2AmenitiesForm.styles';

type TProps = {
  isSubmitFailed?: boolean;
  amenities: IAmenity[];
  venueAmenities: IAmenity[];
  venue: IVenue;
  isExternalVenue: boolean;
  onFinishEditing: () => void;
  isLoading?: boolean;
};

function NW2AmenitiesForm({
  venue,
  isExternalVenue,
  amenities,
  onFinishEditing,
  venueAmenities,
}: TProps) {
  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: onFinishEditing,
  });

  const sortedAmenities = useSortedAmenities({amenities});

  const sortedCheckedAmenities = useSortedAmenities({
    amenities: venueAmenities,
  });

  const sortedCheckedAmenitiesValues: Record<string, number[]> = useMemo(() => {
    return Object.entries(sortedCheckedAmenities).reduce(
      (acc, [name, options]) => {
        return {
          ...acc,
          [name]: options.map(({value}) => value),
        };
      },
      {},
    );
  }, [sortedCheckedAmenities]);

  const [localVenueAmenities, setLocalVenueAmenities] = useState<
    Record<string, number[]>
  >(sortedCheckedAmenitiesValues);

  const disableSave = _isEqual(
    localVenueAmenities,
    sortedCheckedAmenitiesValues,
  );

  const onSubmit = (values: Record<string, number[]>) => {
    const amenitiesToSave = Object.values(values)
      .flatMap((value) => value)
      .flatMap((amenityId) =>
        amenities.filter((amenitiy) => amenitiy.id === amenityId),
      );

    updateVenue({
      amenities: amenitiesToSave,
    });
    onFinishEditing();
  };

  const handleAmenityClick = (name: string, value: number) => {
    setLocalVenueAmenities((prevValue) => {
      if (prevValue[name].includes(value))
        return {
          ...localVenueAmenities,
          [name]: [...prevValue[name]].filter((i) => i !== value),
        };

      return {
        ...localVenueAmenities,
        [name]: [...prevValue[name], value],
      };
    });
  };

  const handleCancelClick =
    (
      form: FormApi<
        Record<string, number[]>,
        Partial<Record<string, number[]>>
      >,
    ) =>
    () => {
      setLocalVenueAmenities(sortedCheckedAmenitiesValues);
      form.reset(sortedCheckedAmenitiesValues);
      onFinishEditing();
    };

  return (
    <Form onSubmit={onSubmit} initialValues={localVenueAmenities}>
      {({handleSubmit, form}) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <GroupWrapper>
              {Object.entries(sortedAmenities).map(([name, options]) => (
                <Group key={name} data-testid={name}>
                  <Title>
                    {amenitiesCategoriesMap[name as EAmenitiesCategories]}
                  </Title>
                  {options.map(({label, value}) => (
                    <GroupItem key={label}>
                      <NW2FormItemCheckbox
                        name={name}
                        label={label}
                        id={`${value}`}
                        value={value}
                        disabled={isSending}
                        size={ECheckboxSizes.LARGE}
                        onChange={handleAmenityClick}
                      />
                    </GroupItem>
                  ))}
                </Group>
              ))}
            </GroupWrapper>

            <NW2SubmitSection
              isLoading={false}
              handleCancel={handleCancelClick(form)}
              disabled={disableSave}
            />
          </form>
        );
      }}
    </Form>
  );
}

export default NW2AmenitiesForm;

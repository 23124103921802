import styled from 'styled-components';
import {
  xLgBp,
  blackColor,
  sectionInsetSm,
  offsetXSm,
  offsetSm,
  offsetDef,
  offsetLg,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  fontWeightExtraBold,
  fontWeightBold,
  fontSizeXSm,
  smBp,
  mdBp,
  whiteColor,
  offsetXXSm,
} from 'constants/styleVars';
import {SubBlockTitle} from 'view/components/Typography';
import Icon from 'view/components/Icon';
import NW2SpaceDetailsSwitch from './components/NW2SpaceDetailsSwitch';

const ServiceTitle = styled(SubBlockTitle)`
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  margin-bottom: ${offsetSm};
`;

const Container = styled.div`
  margin-top: ${offsetXLg};

  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 ${offsetDef};
  }

  @media (min-width: ${xLgBp}px) {
    display: block;
    margin-top: 0;
  }
`;

const UnitName = styled.div`
  font-weight: ${fontWeightBold};
  font-size: 26px;
  line-height: 34px;
  word-break: break-word;
  margin-bottom: ${offsetDef};
`;

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: calc(${offsetLg}*2);
  grid-column-gap: ${offsetLg};
  color: ${blackColor};
  margin-top: ${offsetXXXLg};

  @media (max-width: ${xLgBp}px) and (min-width: 767px) {
    margin-top: ${sectionInsetSm};
    margin-left: ${offsetXSm};
  }

  @media (min-width: ${smBp}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${offsetXSm};
`;

const StyledNW2SpaceDetailsSwitch = styled(NW2SpaceDetailsSwitch)`
  margin-top: ${offsetXXLg};
`;

const ImagesCounter = styled.div`
  position: absolute;
  padding: ${offsetXXSm} ${offsetSm};
  bottom: ${offsetDef};
  right: ${offsetXLg};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${blackColor};
    opacity: 0.5;
  }

  & > span {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: ${fontSizeXSm};
    font-weight: ${fontWeightExtraBold};
    color: ${whiteColor};
  }
`;

export {
  ServiceTitle,
  Container,
  UnitName,
  ServicesContainer,
  StyledIcon,
  StyledNW2SpaceDetailsSwitch,
  ImagesCounter,
};

import React from 'react';
import {useNavigate} from 'react-router-dom';

import NW2Button from 'view/components/NW2Button';
import LocaleDropdown from 'view/common/LocaleDropdown';

import {guestMenu} from '../menuConfigs';
import {useAppSelector} from 'store/hooks';
import {
  GeneralMenu,
  GeneralMenuBox,
  GeneralMenuItem,
} from 'view/common/Header/Header.styles';
import {Routes} from 'constants/routes';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {NavItem} from '../types';

type TProps = {
  currentPath: string;
  role: EUserRoleCognito;
};
function GuestNavMenuList({currentPath, role}: TProps) {
  const navigate = useNavigate();

  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  const onClick = (url: string) => () => {
    !!url && navigate(url);
  };

  const isItemVisible = (url: string) =>
    isDesktop &&
    url === Routes.createPublicVenue &&
    role === EUserRoleCognito.ROLE_GUEST;

  const visibleItems = guestMenu.navList?.filter((item: NavItem) =>
    isItemVisible(item.url),
  );

  return (
    <GeneralMenu>
      <GeneralMenuBox>
        {visibleItems?.map(({url, text}) => (
          <GeneralMenuItem key={url} onClick={onClick(url)}>
            <NW2Button buttonType='link' active={currentPath === url}>
              {text}
            </NW2Button>
          </GeneralMenuItem>
        ))}
      </GeneralMenuBox>

      <LocaleDropdown />
    </GeneralMenu>
  );
}

export default GuestNavMenuList;

import React from 'react';
import {FormSpy, withTypes} from 'react-final-form';
import {DebouncedFunc} from 'lodash';

import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {
  TEditCharacterFormValues,
  TVenueCharactersOption,
} from '../NW2VenueDescription.types';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {validatorCharacters} from '../validation/editCharacter';
import {IVenue} from 'types/venue';
import useUpdateVenue from '../../../../hooks/useUpdateVenue';
import {
  FlexContainer,
  SubSectionContainer,
  SectionCheckboxItem,
} from '../NW2VenueDescription.styles';
import {EFieldNames} from './types';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';

interface IProps {
  venueCharactersOptions: TVenueCharactersOption[];
  handleCancel: () => void;
  handleEditCharacterEnd: () => void;
  venue: IVenue;
  isExternalVenue: boolean;
  isFormDirty: boolean;
  processFormFields: DebouncedFunc<({dirtyFields}: any) => void>;
}

const EditCharacter = ({
  venueCharactersOptions,
  handleCancel,
  handleEditCharacterEnd,
  venue,
  isExternalVenue,
  isFormDirty,
  processFormFields,
}: IProps) => {
  const {Form} = withTypes<TEditCharacterFormValues>();

  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: handleEditCharacterEnd,
  });

  return (
    <FlexContainer>
      <SubSectionContainer>
        <SectionSubTitle>What we are like?</SectionSubTitle>
        <div>{venue?.[EFieldNames.DESCRIPTION]}</div>
      </SubSectionContainer>

      {!isExternalVenue && (
        <SubSectionContainer>
          <SectionSubTitle>Our venue character</SectionSubTitle>
          <Form
            onSubmit={updateVenue}
            initialValues={{
              [EFieldNames.VENUE_CHARACTERS]:
                venue?.[EFieldNames.VENUE_CHARACTERS] || [],
            }}
            validate={validatorCharacters}
          >
            {({handleSubmit}) => (
              <form onSubmit={handleSubmit} noValidate>
                {venueCharactersOptions?.map(({label, value}) => (
                  <SectionCheckboxItem key={value}>
                    <NW2FormItemCheckbox
                      name={EFieldNames.VENUE_CHARACTERS}
                      label={label}
                      value={value}
                      id={value}
                      disabled={isSending}
                      size={ECheckboxSizes.LARGE}
                      data-testid={`${label}--checkbox`}
                    />
                  </SectionCheckboxItem>
                ))}

                <NMMSubmitSection
                  isLoading={isSending}
                  disabled={!isFormDirty}
                  handleCancel={handleCancel}
                />

                <FormSpy
                  subscription={{dirtyFields: true}}
                  onChange={processFormFields}
                />
              </form>
            )}
          </Form>
        </SubSectionContainer>
      )}
    </FlexContainer>
  );
};

export default EditCharacter;

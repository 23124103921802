import {ReactNode} from 'react';
import {UploadFile} from 'antd/es/upload/interface';

import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {IDay} from 'types/dto/IDay.type';
import {IUnit, TUnitCapacityStyle, TUnitFloor} from 'types/dto/IUnit.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IAmenity} from 'types/dto/IAmenity';
import {EAccommodationType, IExtraResponse} from 'types/dto/IPublicVenue';
import {IVenuePhotos} from 'store/venue/types';
import {ICommonAmenity} from './dto/ICommonAmenity';
import {TDateType} from 'types/dto/ISearch.types';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {TImage} from './app';
import {IOfferUnitExtra} from './offer';
import {TSearchCriteriaExtra} from './search';

export enum EDeskSpaceName {
  OPEN_SPACE = 'Transient lounge space',
  SHARED_SPACE = 'Open-shared space',
  MEETING_ROOM = 'Meeting room',
  GROUPS = 'Groups',
  BEDROOM = 'Bedroom',
}

export enum ESpaceSelectedTab {
  OVERVIEW = 'overview',
  DESK = 'desk',
  ROOM = 'room',
  BEDROOM = 'bedroom',
}

export type TSpaceType = 'OPEN_SPACE' | 'SHARED_SPACE' | 'MEETING_ROOM';

export enum EEventType {
  PRE_ARRIVAL = 'PRE_ARRIVAL',
  DAY = 'DAY',
  POST_EVENT = 'POST_EVENT',
}

export interface ISpaceData {
  id: number;
  name: string;
  floor: string;
  description: string;
  capacityNumber: number;
  amenities: IAmenity[];
  spaceType: ERoomType;
}

export type TVenueFloors = number[] | [];

export interface IVenueLocation {
  streetName: string;
  city: string;
  country: string;
  postalCode: string | number | null;
  buildingNumber: string | number | null;
  floors: TVenueFloors;
  timeZone: string;
  latitude?: number | string | null;
  longitude?: number | string | null;
  placeId?: string;
  id: number | null;
}

export interface IVenueContact {
  id: number | null;
  email: string;
  fax: number | null;
  phone: number | null;
  websiteUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  contactItems: IVenueContactItems[];
}

export interface IVenueContactItems {
  phoneNumber?: string;
  email?: string | null;
  fax?: string;
  firstName?: string;
  lastName?: string;
  contactType: EVenueContactItemType;
  primary: boolean;
}

export enum EVenueContactItemType {
  DIRECTOR = 'DIRECTOR',
  MANAGER = 'MANAGER',
  SALES_MANAGER = 'SALES_MANAGER',
  RESERVATIONS_MANAGER = 'RESERVATIONS_MANAGER',
  BANQUET_MANAGER = 'BANQUET_MANAGER',
  MARKETING_MANAGER = 'MARKETING_MANAGER',
  RESERVATION_CONFIRMATION = 'RESERVATION_CONFIRMATION',
  SECONDARY_CONTACT = 'SECONDARY_CONTACT',
  RECEPTION = 'RECEPTION',
}

export interface ILegalPhone {
  phoneCountryCode?: string;
  phone?: string;
  phoneCountry?: string;
  fullPhoneNumber?: string;
}

export interface ILegalExecutivesInfo {
  firstName: string;
  lastName: string;
  dateBirth: string;
  yearBirth: string;
  monthBirth: string;
  type: EPersonOfInterestType;
}

export interface IPointOfInterest {
  type: TPointsOfInterestType;
  name: string;
  distance: number;
}

export enum ELegalEntityType {
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
}

export const LegalEntityTypeCaption = {
  [ELegalEntityType.BUSINESS]: 'Business',
  [ELegalEntityType.INDIVIDUAL]: 'Individual',
};

export enum EPersonOfInterestType {
  SIGNATORY = 'SIGNATORY',
  CONTROLLER = 'CONTROLLER',
  OWNER = 'OWNER',
}

export interface IPeopleOfInterest {
  id?: number | null;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  type: EPersonOfInterestType;
}

export interface ISupplier {
  id: number | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface IVenueLegalContact {
  id: number | null;
  legalEntityType: ELegalEntityType;
  companyName?: string;
  registrationNumber?: string;
  taxNumber: string | number;
  ceoName: string;
  country: string;
  countryCode: string;
  region: string;
  city: string;
  streetName: string;
  buildingNumber: string | number | null;
  postalCode: string | number | null;
  floorNumber: number | null;
  legalPhone: ILegalPhone;
  email: string;
  // TODO: remove as a separate task
  floors: number[] | [];
  owner: ILegalExecutivesInfo;
  signatory: ILegalExecutivesInfo;
  controller: ILegalExecutivesInfo;
}

export enum ERoomSchemaNames {
  THEATRE = 'THEATRE',
  U_SHAPE = 'U_SHAPE',
  CIRCLE_OF_CHAIRS = 'CIRCLE_OF_CHAIRS',
  CLASSROOM = 'CLASSROOM',
  BLOCK = 'BLOCK',
  BANQUET = 'BANQUET',
  RECEPTION = 'RECEPTION',
}

export const ERoomSchema = {
  [ERoomSchemaNames.THEATRE]: 'Theatre',
  [ERoomSchemaNames.U_SHAPE]: 'U-shape',
  [ERoomSchemaNames.CIRCLE_OF_CHAIRS]: 'Circle of chairs',
  [ERoomSchemaNames.CLASSROOM]: 'Classroom',
  [ERoomSchemaNames.BLOCK]: 'Block/Boardroom',
  [ERoomSchemaNames.BANQUET]: 'Banquet',
  [ERoomSchemaNames.RECEPTION]: 'Standing/Reception',
};
export const iconsObject: Record<ERoomSchemaNames, string> = {
  [ERoomSchemaNames.THEATRE]: 'THEATRE',
  [ERoomSchemaNames.U_SHAPE]: 'U_SHAPE',
  [ERoomSchemaNames.CIRCLE_OF_CHAIRS]: 'CIRCLE_OF_CHAIRS',
  [ERoomSchemaNames.CLASSROOM]: 'CLASSROOM',
  [ERoomSchemaNames.BLOCK]: 'BOARDROOM',
  [ERoomSchemaNames.BANQUET]: 'BANQUET',
  [ERoomSchemaNames.RECEPTION]: 'STANDING_RECEPTION',
};

export interface IGlobalVenue {
  chosenAmenities: number[];
  createVenueStep: number;
  isVenueInformationConfirmed: boolean;
  venueTypes: TVenueType[];
  venueCharacters: TVenueCharacterType[];
  venueCurrencies: IVenueCurrencies | null;
  corporateVenues: IVenue[];
  venue: IVenue;
  venuePhotos: IVenuePhotos;
  spaces: ISpacesData[];
  spaceSelectedTab: ESpaceSelectedTab | null;
  amenities: IAmenity[] | null;
  error: any;
  loading: boolean;
  amenitiesLoading: boolean;
  setSpaceLoading: boolean;
  resourcesSelectedTab: EResourcesType;
  resourcesModalVisibility: boolean;
  resourcesFormLoading: boolean;
  extrasOption: IExtrasOption[];
}

export interface IVenue {
  legalEntityType: ELegalEntityType;
  id: number;
  userId: number;
  name: string;
  numberOfFloors: number | null;
  venueType: TVenueType;
  venueCharacters: TVenueCharacterType[];
  description: string;
  hkey: string;
  currency: string;
  contact: IVenueContact;
  floorInformation: IFloorInfo[];
  legalContact: IVenueLegalContact;
  peopleOfInterest: IPeopleOfInterest[];
  supplier: ISupplier | null;
  location: IVenueLocation;
  operationalTimes: IDay[];
  amenities: IAmenity[];
  commonAmenities: ICommonAmenity[];
  pointsOfInterest: IPointOfInterest[];
  amenitiesIds: number[];
  entertainmentOptions: number[];
  extras: IExtrasResponse[];
  units: IUnit[];
  accommodationType: EAccommodationType;
  documents: TImage[];
  isOffer: boolean;
  coverFileName: string | null;
  totalPrice?: number;
}

// todo align with TImage
export interface ISpacesDataItemImage {
  isCover: boolean | null;
  name: string;
  status: string;
  uid: string;
  url: string;
}

export interface IUnitDimensions {
  area: string | number;
  ceilingHeight: string | number;
}

// todo align with IUnit
export interface ISpacesDataItem {
  accommodationId?: number;
  id: number;
  capacity: number;
  description: string;
  name: string;
  roomType: ERoomType;
  type: ERoomType;
  documents: ISpacesDataItemImage[];
  amenities: IAmenity[];
  isRfp?: boolean;
  priceHour: number;
  priceHalfDay: number;
  priceDay: number;
  minimumPaidDuration: number;
  unitCapacities?: TUnitCapacityStyle[];
  unitDimensions: IUnitDimensions;
  floor: TUnitFloor;
}

export interface ISpacesDataItemWipPayload extends ISpacesDataItem {
  files: TFileWithPreview[] | UploadFile[];
}

export interface ISpacesData {
  floorNumber: number;
  units: ISpacesDataItem[];
}

export type TMarkerLocation = {
  lat: number;
  lng: number;
};

export interface IBookedExtra {
  id: number;
  accommodationExtraId: number;
  bookedQuantity: number;
  extraName: string;
  price: number;
  totalPrice: number;
  extraType: EResourcesType;
  quantityString: string;
  priceString?: string;
  foodAndBeverage?: boolean;
  equipment?: boolean;
  extraId: number;
  code?: EResourcesCode;
}

export enum EBookAbility {
  INSTANT = 'Instant',
  REQUEST = 'Request',
}

export enum EFormValidationName {
  YOUR_DETAILS_BLOCK = 'yourDetailsBlock',
  ATTENDEES_BLOCK = 'attendeesBlock',
  PAYMENT_BLOCK = 'paymentBlock',
  BILLING_ADDRESS_BLOCK = 'billingAddressBlock',
  OPTION_DATE_BLOCK = 'optionDateBlock',
}

export enum EBookingFormFields {
  ORDER_DAYS = 'orderDays',
  PAYMENT_TYPE = 'paymentType',
  PAYMENT_INFO = 'paymentInfo',
}

export interface IBookPreviewComponent {
  title: string | ReactNode;
  subtitle?: string;
  isHidden?: boolean;
  formValidationName?: EFormValidationName;
  children: ReactNode;
}

export enum EVenueTypes {
  HOTEL = 'Hotel',
  APARTMENT_HOTEL = 'Apartment hotel',
  EVENT_LOCATION = 'Event location',
  CO_WORKING_SPACE = 'Co-working space',
  BUSINESS_CENTER = 'Business centre',
  CONFERENCE_CENTER = 'Conference centre',
}

export enum EVenueCharacters {
  MODERN = 'Modern',
  HISTORICAL = 'Historical',
  DESIGN = 'Design',
  COUNTRY_HOUSE_STYLE = 'Country house',
  URBAN = 'Urban',
  COUNTRYSIDE = 'Countryside',
  CASTLE = 'Castle',
}

export type TVenueCharacterType = keyof typeof EVenueCharacters;
export type TVenueType = keyof typeof EVenueTypes;

type TCurrencyUnit = {
  code: string;
  name: string;
};

export interface IVenueCurrencies {
  popular: TCurrencyUnit[];
  all: TCurrencyUnit[];
}

export type TFilteredAmenities = {
  label: string;
  value: number;
};

export enum EAmenitiesCategories {
  GENERAL = 'GENERAL',
  SERVICES = 'SERVICES',
  ENTERTAINMENT = 'ENTERTAINMENT',
  ACCESSIBILITY = 'ACCESSIBILITY',
  TRANSPORTATION = 'TRANSPORTATION',
  WELLNESS_FITNESS = 'WELLNESS_FITNESS',
}

export enum EPointsOfInterest {
  AIRPORT = 'AIRPORT',
  TRAIN_STATION = 'TRAIN_STATION',
  TRADE_FAIR_GROUND = 'TRADE_FAIR_GROUND',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  CITY = 'CITY',
}

export type TPointsOfInterestType = keyof typeof EPointsOfInterest;

type TPointsOfInterestMap = {
  [key in EPointsOfInterest]: string;
};

export const pointsOfInterestMap: TPointsOfInterestMap = {
  [EPointsOfInterest.AIRPORT]: 'Closest airport',
  [EPointsOfInterest.TRAIN_STATION]: 'Main train station',
  [EPointsOfInterest.TRADE_FAIR_GROUND]: 'Trade fair ground',
  [EPointsOfInterest.PUBLIC_TRANSPORT]: 'Public transport',
  [EPointsOfInterest.CITY]: 'Nearest city',
};

export const pointsOfInterestIcons: TPointsOfInterestMap = {
  [EPointsOfInterest.AIRPORT]: 'NW2_PLANE',
  [EPointsOfInterest.TRAIN_STATION]: 'NW2_TRAIN',
  [EPointsOfInterest.TRADE_FAIR_GROUND]: 'NW2_TRADE_SHOW',
  [EPointsOfInterest.PUBLIC_TRANSPORT]: 'NW2_PUBLIC_TRANSPORT',
  [EPointsOfInterest.CITY]: 'NW2_NEAR_CITY',
};

export type TRoomDetails = {
  name: string;
  capacityMin: number;
  capacityMax: number;
};

export interface IFloorInfo {
  floorNumber: number;
  roomDetailDto: {
    [ERoomType.MEETING_ROOM]: TRoomDetails[];
    [ERoomType.WORK_SPACE]: TRoomDetails[];
  };
}

export const enum EVisibilityAreas {
  PUBLIC = 'PUBLIC',
  CORPORATE = 'CORPORATE',
}

export interface IAnnouncement {
  id: number;
  title: string;
  subtitle: string;
  body: string;
  isActive: boolean;
  visibilityAreas: EVisibilityAreas;
  dateCreated: string | TDateType;
  dateUpdated: string | TDateType;
}

export type TUpdatePublicVenueBusinessInformationPayload = {
  id: number;
  legalContact: IVenueLegalContact;
  peopleOfInterest: IPeopleOfInterest[];
};

export type TBedrooms = (
  | IOfferUnitExtra
  | TSearchCriteriaExtra
  | IExtraResponse
)[];

import styled from 'styled-components';
import {Button} from 'antd';

import {offsetXSm} from 'constants/styleVars';

export const StyledButton = styled(Button)`
  margin: ${offsetXSm} auto;
  width: 100%;
  max-width: 340px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

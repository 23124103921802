import {useMemo} from 'react';

import {ESpaceDetailsContentSections} from '../types';
import {ERoomType} from 'types/dto/ERoomType.type';

interface IProps {
  isExternalVenue: boolean;
  roomType: ERoomType;
}

export function useSpaceSections({isExternalVenue, roomType}: IProps) {
  return useMemo(() => {
    const sections = Object.values(ESpaceDetailsContentSections);

    switch (roomType) {
      case ERoomType.MEETING_ROOM: {
        const publicMeetingRoomSections = sections.filter(
          (item) =>
            item !== ESpaceDetailsContentSections.SPACE_TYPE_AND_CAPACITY,
        );

        if (isExternalVenue) return publicMeetingRoomSections;

        return publicMeetingRoomSections.filter(
          (item) =>
            item !== ESpaceDetailsContentSections.PRICES &&
            item !== ESpaceDetailsContentSections.BOOKABILITY_TYPE,
        );
      }

      case ERoomType.WORK_SPACE: {
        const publicDeskSections = sections.filter(
          (item) =>
            item !== ESpaceDetailsContentSections.SETUPS_AND_CAPACITIES &&
            item !== ESpaceDetailsContentSections.DIMENSIONS &&
            item !== ESpaceDetailsContentSections.BOOKABILITY_TYPE,
        );

        if (isExternalVenue) return publicDeskSections;

        return publicDeskSections.filter(
          (item) => item !== ESpaceDetailsContentSections.PRICES,
        );
      }

      default:
        return [];
    }
  }, [isExternalVenue, roomType]);
}

import {TLayout} from 'view/common/Header/types';

const getLogoutLayout = (): TLayout => {
  return {
    type: 'NW2SupplierLayout',
    components: [{component: 'isLogo'}, {component: 'isSignOutButton'}],
  };
};

export {getLogoutLayout};

import React from 'react';
import {EBookingStatus} from 'types/booking';
import {TERMS_OWNER} from 'types/bookingOrders';
import {TPolicyDocument} from 'types/dto/IBooking.types';
import {EOfferStatus, ERequestStatus} from 'types/offer';
import {CancellationPolicyBlock} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {
  StyledTextes,
  SubTitle,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {Link} from 'view/components/Typography';
import {IDynamicCancellationPolicyPayload} from 'types/dto/ICancellationPolicy.type';
import {
  getCancellationPolicyLinkUrlById,
  getTermsAndConditionLinkUrlById,
} from 'utils/helpers';
import useToggle from 'hooks/useToggle';
import {useAppSelector} from 'store/hooks';
import NW2ModalDrawer from '../../components/NW2ModalDrawer';

interface IProps {
  isUSA?: boolean;
  orderNumber?: string;
  termsAndConditionsId?: string | null;
  cancellationPolicy?: TPolicyDocument;
  bookingStatus?: EBookingStatus | ERequestStatus | EOfferStatus;
  cancellationPolicyProps?: IDynamicCancellationPolicyPayload;
  isSmallText?: boolean;
  isBlob?: boolean;
}

const SupplierTermsAndPolicyBlock = ({
  bookingStatus,
  cancellationPolicy,
  termsAndConditionsId,
  orderNumber,
  isUSA,
  cancellationPolicyProps,
  isSmallText,
  isBlob = false,
}: IProps) => {
  const requestDetails = useAppSelector(({offers}) => offers.offerDetails);

  const isSupplierPolicy = cancellationPolicy?.type === TERMS_OWNER.SUPPLIER;
  const isCustomerPolicy = cancellationPolicy?.type === TERMS_OWNER.CUSTOMER;
  const isCanceledStatus =
    bookingStatus === EBookingStatus.CANCELED ||
    bookingStatus === EBookingStatus.RFP_CANCELLED;
  const isRequestPending = bookingStatus === ERequestStatus.REQUEST_PENDING;
  const isPastBooking = bookingStatus === EBookingStatus.PASSED;

  const [isHrsPolicyShowed, toggleHrsPolicyShowed] = useToggle(false);

  const isShowSubtitle =
    !isCanceledStatus &&
    (!isPastBooking || isCustomerPolicy || (isSupplierPolicy && isUSA));

  const renderCancellationPolicy = () => {
    if (isCanceledStatus) {
      return '';
    }
    if (isUSA && isCustomerPolicy) {
      return (
        <StyledTextes isSmallText={isSmallText}>
          {isRequestPending ? 'You can choose between ' : 'You have accepted '}
          <Link
            href={getCancellationPolicyLinkUrlById(cancellationPolicy?.id)}
            target='_blank'
            rel='noreferrer noopener'
            black={isSmallText}
          >
            Booker's Cancellation Policy
          </Link>
          {isRequestPending &&
            ' or upload your Venue’s Cancellation Policy in the next steps.'}
        </StyledTextes>
      );
    }

    if (!isUSA && isCustomerPolicy) {
      return (
        <StyledTextes isSmallText={isSmallText}>
          {isRequestPending ? 'This request follows ' : 'You have accepted '}
          <Link
            href={getCancellationPolicyLinkUrlById(cancellationPolicy?.id)}
            target='_blank'
            rel='noreferrer noopener'
            black={isSmallText}
          >
            Booker's Cancellation Policy
          </Link>
          {isRequestPending &&
            '. To proceed with this request, you need to agree to these conditions in the next steps.'}
        </StyledTextes>
      );
    }

    if (isUSA && !isCustomerPolicy && isRequestPending) {
      return (
        <StyledTextes isSmallText={isSmallText}>
          You can choose between{' '}
          <Link onClick={toggleHrsPolicyShowed} black={isSmallText}>
            HRS Cancellation Policy
          </Link>{' '}
          or upload your Venue’s Cancellation Policy in the next steps.
        </StyledTextes>
      );
    }

    if (isUSA && isSupplierPolicy) {
      return (
        <StyledTextes isSmallText={isSmallText}>
          You have selected to use your{' '}
          <Link
            href={
              isBlob
                ? cancellationPolicy?.id
                : getCancellationPolicyLinkUrlById(cancellationPolicy?.id)
            }
            target='_blank'
            rel='noreferrer noopener'
            black={isSmallText}
          >
            Venue’s Cancellation Policy.
          </Link>
        </StyledTextes>
      );
    }

    return (
      !isPastBooking && (
        <>
          <StyledTextes isSmallText={isSmallText}>
            {isRequestPending
              ? `This request follows the HRS Cancellation Policy. The details of the policy are provided below:`
              : isUSA
              ? `You have accepted the HRS Cancellation Policy. The details of the policy are as follows:`
              : `The details of HRS Cancellation Policy are as follows:`}
          </StyledTextes>
          <StyledTextes>
            <CancellationPolicyBlock
              {...cancellationPolicyProps}
              orderNumber={orderNumber}
              isCanceledStatus={isCanceledStatus}
            />
          </StyledTextes>
        </>
      )
    );
  };

  return (
    <>
      {isShowSubtitle && (
        <SubTitle
          isSmallText={isSmallText}
          noMargin={!isSupplierPolicy && !isCustomerPolicy && !isRequestPending}
        >
          Modification and Cancellation Conditions
        </SubTitle>
      )}

      {renderCancellationPolicy()}

      {termsAndConditionsId && (
        <>
          <SubTitle isSmallText={isSmallText}>
            Booker's Terms and Conditions
          </SubTitle>
          <StyledTextes isSmallText={isSmallText}>
            {isRequestPending ? 'This request includes ' : 'You have accepted '}
            <Link
              href={getTermsAndConditionLinkUrlById(
                termsAndConditionsId as string,
              )}
              target='_blank'
              rel='noreferrer noopener'
              black={isSmallText}
            >
              Booker's Terms and Conditions
            </Link>
            {isRequestPending &&
              '. To proceed with this request, you need to agree to these terms in the next steps.'}
          </StyledTextes>
        </>
      )}

      <NW2ModalDrawer
        isShowed={isHrsPolicyShowed}
        onClose={toggleHrsPolicyShowed}
        header='HRS Modification AND Cancellation conditions'
        body={<CancellationPolicyBlock requestNumber={requestDetails.number} />}
        isMobile={false}
        modalWidth={550}
      />
    </>
  );
};

export default SupplierTermsAndPolicyBlock;

import React from 'react';
import {getCancellationPolicyStrings} from 'utils/stringUtils';

type TProps = {
  text: string;
};

const CancellationPolicyTextElement = ({text}: TProps) => {
  const {mainText, extraText} = getCancellationPolicyStrings(text);
  return (
    <>
      {mainText}
      {!!extraText && <b>{extraText}</b>}
    </>
  );
};

export default CancellationPolicyTextElement;

import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {LoaderWrapper} from '../NW2BookingConfirmation/NW2BookingConfirmation.styles';
import {
  clearGroupDetails,
  clearOfferDetails,
  clearRequestedUnits,
  setOfferDetails,
} from 'store/offers/offersSlice';
import {getGroupDetails} from 'store/offers/apiActions';
import {EGroupStatus, EOfferStatus} from 'types/offer';
import {setRedirectLink} from 'store/app/appSlice';
import {OfferRequestSecondStep} from './components/OfferRequestSecondStep/OfferRequestSecondStep';

export function OfferRequestGroupOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {id} = useParams<{
    id: string;
  }>();

  const groupDetails = useAppSelector(({offers}) => offers.groupDetails);
  const groupStatus = groupDetails?.groupStatus as EGroupStatus;
  const isLoading = useAppSelector(({offers}) => offers.isGroupDetailsLoading);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  useEffect(() => {
    if (!isLoading && id && !groupDetails) {
      dispatch(getGroupDetails(id, true));
    }
  }, [dispatch, id, groupDetails, isLoading]);

  //redirects
  //page refresh of multiRequest with single venue, when request becomes offer
  useEffect(() => {
    if (!groupDetails) return;
    if (
      groupDetails.groupStatus === EGroupStatus.GROUP_OFFER_PENDING &&
      !groupDetails.requests.length &&
      groupDetails.offers.length
    ) {
      const offer = groupDetails?.offers[0];

      dispatch(setOfferDetails(offer));
      navigate(`/offer/${offer?.id}/review`);
    }
  }, [dispatch, groupDetails, navigate]);

  //after one offer is accepted by customer, we redirect to BOOKING CONFIRMED page
  //as group becomes booking
  useEffect(() => {
    if (groupDetails?.groupStatus === EGroupStatus.GROUP_PROCESSED) {
      const confirmedOffer = groupDetails?.offers.find(
        ({status}) => status === EOfferStatus.OFFER_CONFIRMED,
      );

      navigate(
        `/booking/${confirmedOffer?.number}?venueId=${confirmedOffer?.accommodationId}`,
      );
    }
  }, [groupDetails?.groupStatus, groupDetails?.offers, navigate]);

  useEffect(() => {
    return () => {
      dispatch(setRedirectLink(''));
      dispatch(clearGroupDetails());
      dispatch(clearOfferDetails());
      dispatch(clearRequestedUnits());
    };
  }, [dispatch]);

  if (isLoading || !groupDetails || id !== groupDetails.requests[0]?.groupId)
    return (
      <LoaderWrapper isMobile={isMobile}>
        <NW2Loader label='Loading' />
      </LoaderWrapper>
    );

  return (
    <OfferRequestSecondStep
      groupStatus={groupStatus}
      id={id}
      isGroupRequestPage
    />
  );
}

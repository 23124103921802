import {Auth} from 'aws-amplify';
import {CognitoUserSession} from 'amazon-cognito-identity-js';

import {ApiType, IApi} from 'infra/common/http.service';
import {
  ICreateCustomerBillingAddressPayload,
  ICustomerBillingAddress,
  IUser,
} from 'types/dto/IUser.types';
import {TRequestToHRSSupportPayload, ISendFeedbackPayload} from 'types/app';

const appRepository = (api: IApi) => ({
  getUserDataByEmail: async (email: string) =>
    await api.get(ApiType.Inventory, `users/info?email=${email}`),
  getCustomer: async (id: string | number): Promise<IUser> => {
    return api.get(ApiType.Inventory, `customers/${id}`);
  },
  getCustomerBillingAddresses: async (
    id: number,
  ): Promise<ICustomerBillingAddress[]> =>
    await api.get(ApiType.Inventory, `billingAddress/customer/${id}`),
  createCustomerBillingAddress: async (
    data: ICreateCustomerBillingAddressPayload,
  ): Promise<ICustomerBillingAddress[]> =>
    await api.add(ApiType.Inventory, 'billingAddress', data),
  updateDefaultCustomerBillingAddress: async (
    uuid: string,
  ): Promise<ICustomerBillingAddress[]> =>
    await api.update(ApiType.Inventory, `billingAddress/${uuid}/default`),
  deleteCustomerBillingAddress: async (
    uuid: string,
  ): Promise<ICustomerBillingAddress[]> =>
    await api.delete(ApiType.Inventory, `billingAddress/${uuid}`),
  postRequestToHRSSupport: async (
    data: TRequestToHRSSupportPayload,
  ): Promise<void> =>
    await api.add(ApiType.Common, `notification/report`, data),
  postSendFeedback: async (data: ISendFeedbackPayload): Promise<void> =>
    await api.add(ApiType.Common, `notification/feedback`, data),
  signOutUser: async (): Promise<void> => await Auth.signOut(),
  getCurrentSession: async (): Promise<CognitoUserSession> =>
    await Auth.currentSession(),
});

export default appRepository;

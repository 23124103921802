import React, {useRef} from 'react';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  StyledWrapper,
  MobileContent,
  StyledColLeft,
  StyledColLeftMain,
  StyledColRight,
  StyledColLeftHeader,
  StyledFooter,
  StyledSubtitle,
  StyledTitle,
  StyledTitleBox,
} from './NW2ModalTwoColumns.styles';
import {INW2ModalTwoColumnsContentProps} from './types';
import {MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';

export function NW2ModalTwoColumnsContent({
  title,
  subTitle,
  isRightColumnBeforeLeft = false,
  isRightColumnFullHeight = false,
  isLeftColumnFixed = false,
  colLeft,
  colRight,
  footer,
  onToggleShow,
  isRefreshScroll,
  customPadding,
  gridColumns,
  leftColumnStyles,
  rightColumnStyles,
}: INW2ModalTwoColumnsContentProps) {
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const HeaderBlock = () => (
    <StyledColLeftHeader>
      <NW2Button
        buttonType='secondary'
        minimized
        size='small'
        onClick={onToggleShow}
        icon={<Icon transparent icon='NW2_MOVE_BACK' />}
      />
      <StyledTitleBox>
        <StyledTitle>{title}</StyledTitle>

        {subTitle && <StyledSubtitle>{subTitle}</StyledSubtitle>}
      </StyledTitleBox>
    </StyledColLeftHeader>
  );

  const selectedLeftColumn = useRef<HTMLElement>(null);
  const selectedRightColumn = useRef<HTMLElement>(null);
  const selectedMobileWrapper = useRef<HTMLElement>(null);

  if (isRefreshScroll) {
    if (selectedLeftColumn.current !== null) {
      selectedLeftColumn.current.scrollTo(0, 0);
    }

    if (selectedRightColumn.current !== null) {
      selectedRightColumn.current.scrollTo(0, 0);
    }

    if (selectedMobileWrapper.current !== null && !isDesktop) {
      selectedMobileWrapper.current.scrollTo(0, 0);
    }
  }

  return (
    <StyledWrapper
      isHasFooter={!!footer}
      isMobile={isMobile}
      customPadding={customPadding}
      gridColumns={gridColumns}
    >
      {isDesktop ? (
        <>
          <StyledColLeft
            ref={selectedLeftColumn as React.RefObject<HTMLDivElement>}
          >
            <HeaderBlock />
            <StyledColLeftMain leftColumnStyles={leftColumnStyles}>
              {colLeft}
            </StyledColLeftMain>
          </StyledColLeft>

          <StyledColRight
            ref={selectedRightColumn as React.RefObject<HTMLDivElement>}
            id={MODAL_TWO_COLS_RIGHT_COL_ID}
            isRightColumnFullHeight={isRightColumnFullHeight}
            rightColumnStyles={rightColumnStyles}
          >
            {colRight}
          </StyledColRight>
        </>
      ) : (
        <>
          <HeaderBlock />
          {!isRightColumnBeforeLeft && isLeftColumnFixed && (
            <StyledColLeftMain leftColumnStyles={leftColumnStyles}>
              {colLeft}
            </StyledColLeftMain>
          )}

          <MobileContent
            id={MODAL_TWO_COLS_RIGHT_COL_ID}
            isRightColumnFullHeight={isRightColumnFullHeight}
            ref={selectedMobileWrapper as React.RefObject<HTMLDivElement>}
          >
            {!isRightColumnBeforeLeft && !isLeftColumnFixed && (
              <StyledColLeftMain leftColumnStyles={leftColumnStyles}>
                {colLeft}
              </StyledColLeftMain>
            )}

            <StyledColRight
              isRightColumnFullHeight={isRightColumnFullHeight}
              rightColumnStyles={rightColumnStyles}
            >
              {colRight}
            </StyledColRight>

            {isRightColumnBeforeLeft && (
              <StyledColLeftMain>{colLeft}</StyledColLeftMain>
            )}
          </MobileContent>
        </>
      )}

      {!!footer && <StyledFooter>{footer}</StyledFooter>}
    </StyledWrapper>
  );
}

import {ApiType, IApi} from '../common/http.service';
import {IGetPaymentReferencePayload} from 'store/payment/types';

const paymentRepository = (api: IApi) => ({
  getReferenceInfo: async ({fKey, companyName}: IGetPaymentReferencePayload) =>
    await api.get(
      ApiType.Payment,
      `reference-information?fKey=${fKey}${
        companyName ? `&companyName=${companyName}` : ''
      }`,
    ),
});

export default paymentRepository;

import {ApiType, IApi} from 'infra/common/http.service';

import {
  EBookingFilterType,
  ETableSortOrder,
  IGetAmountOfBookingsPayload,
  IGetBookingsPayload,
} from 'types/dto/IBookingType.type';
import {bookingSortFields} from 'view/supplier/Bookings/BookingsList/helpers';
import {
  EBookingOrdersSortBy,
  IBookingDetails,
  IDeclineSupplierOrderPayload,
  IGetBookingOrdersPayload,
  IGetBookingOrdersResponse,
  ICustomerOverviewOrders,
  IGetCustomerOverviewOrdersPayload,
  IBookingChanges,
  EOrderShowStatus,
  IDeclineOrderChangesPayload,
  IConfirmOrderChangesPayload,
} from 'types/bookingOrders';
import {
  ICancellationPolicyConditionPayload,
  ICancellationPolicyPayload,
  IDynamicCancellationPolicy,
  IDynamicCancellationPolicyPayload,
  TCancellationPolicyConditions,
} from 'types/dto/ICancellationPolicy.type';

const setQueryString = (filter: any, queryName: string) =>
  filter && `${filter.map((tab: any) => `${queryName}=${tab}`).join('&')}`;

const bookingsRepository = (api: IApi) => ({
  getBookings: async ({
    // will be replaced to getSupplierOrders after update workdesk table
    bookingsType,
    pagination = {
      current: 1,
      pageSize: 0,
    },
    filters,
    sortField,
    sortOrder,
    venueId,
  }: IGetBookingsPayload): Promise<IGetBookingOrdersResponse> => {
    const {current, pageSize} = pagination;
    const page = (current && current - 1) || 0;
    const limit = !pageSize ? '' : pageSize;

    const sortByQuery =
      sortField && sortOrder
        ? `&sortBy=${bookingSortFields[sortField as string]}&sortingDirection=${
            ETableSortOrder[sortOrder]
          }`
        : '';

    const filterQuery = filters
      ? Object.keys(filters)
          .map((key) => {
            if (filters[key]) return `&${key}=${filters[key]}`;
          })
          .filter((el) => el)
          .join('')
      : '';

    const bookingFilterType = EBookingFilterType.WORK_SPACE;

    return await api.get(
      ApiType.Search,
      `orders?searchTab=${bookingsType}&limit=${limit}&page=${page}${sortByQuery}${filterQuery}&roomType=${bookingFilterType}&venueId=${venueId}`,
    );
  },

  getAmountOfBookings: async ({
    searchTabs,
    kpiTab,
    venueId,
  }: IGetAmountOfBookingsPayload): Promise<any> => {
    const apiFiltersSearchTabs = setQueryString(searchTabs, 'searchTabs');
    const apiFiltersKpiTab = setQueryString(kpiTab, 'kpiTab');

    return api.get(
      ApiType.Search,
      `orders/${venueId}/total?${apiFiltersSearchTabs}&${apiFiltersKpiTab}`,
    );
  },

  getCancellationPolicyText: async ({
    maxParticipants,
    isRtb,
    isMultiDay,
  }: ICancellationPolicyPayload): Promise<string> =>
    await api.get(
      ApiType.Inventory,
      `cancellation-policy?maxParticipants=${maxParticipants}&isRfp=${isRtb}&isMultiDay=${isMultiDay}`,
    ),

  getDynamicCancellationPolicy: async (
    payload: IDynamicCancellationPolicyPayload,
  ): Promise<IDynamicCancellationPolicy> =>
    await api.get(ApiType.Order, `cancellation-policy/group`, payload),

  getCancellationPolicyConditions: async (
    payload: ICancellationPolicyConditionPayload,
  ): Promise<TCancellationPolicyConditions> =>
    await api.get(ApiType.Order, `cancellation-policy/groups`, payload),

  getSupplierOrders: async ({
    page = 0,
    limit = 10,
    searchTab,
    venueId,
    sortBy = EBookingOrdersSortBy.STATUS,
    roomType,
  }: IGetBookingOrdersPayload): Promise<IGetBookingOrdersResponse> =>
    await api.get(
      ApiType.Search,
      `orders?limit=${limit}&page=${page}&searchTab=${searchTab}&sortBy=${sortBy}&venueId=${venueId}&roomType=${roomType}&sortDirection=ASC`,
    ),

  // getOrderDetails: async (id: string): Promise<IBookingDetails> =>
  //   await api.get(ApiType.Inventory, `orders/v2/${id}`),

  getSupplierOrderDetails: async (id: string): Promise<IBookingDetails> =>
    await api.get(ApiType.Order, `orders/${id}`),

  getOrderChanges: async (id: string): Promise<IBookingChanges[]> =>
    await api.get(ApiType.Order, `orders/group/${id}`),

  confirmSupplierOrder: async (orderId: string): Promise<any> =>
    await api.update(ApiType.Order, `orders/confirm/${orderId}`),

  declineSupplierOrder: async ({
    orderId,
    reason,
    message,
    isUnavailable,
  }: IDeclineSupplierOrderPayload): Promise<any> =>
    await api.add(ApiType.Order, `orders/decline/${orderId}`, {
      message: reason,
      additionalMessage: message,
      isUnavailable,
    }),

  getCustomerOrders: async ({
    page = 0,
    limit = 10,
    searchTab,
    sortBy = EBookingOrdersSortBy.STATUS,
    sortingDirection = ETableSortOrder.descend,
    userId,
  }: IGetCustomerOverviewOrdersPayload): Promise<ICustomerOverviewOrders> =>
    await api.get(
      ApiType.Search,
      `orders/customer?searchTab=${searchTab}&userId=${userId}&limit=${limit}&page=${page}&sortBy=${sortBy}&sortingDirection=${sortingDirection}`,
    ),

  patchOrderShowStatus: async (orderId: string): Promise<EOrderShowStatus> =>
    await api.update(ApiType.Order, `orders/${orderId}/showStatus`, {}),

  putDeclineOrderChanges: async ({
    orderId,
    declinedReason,
    isUnavailable,
    additionalMessage,
    approverName,
  }: IDeclineOrderChangesPayload): Promise<void> =>
    await api.update(ApiType.Order, `orders/v2/decline/${orderId}`, {
      declinedReason,
      additionalMessage,
      isUnavailable,
      approverName,
    }),
  putConfirmOrderChanges: async ({
    orderId,
    approverName,
  }: IConfirmOrderChangesPayload): Promise<void> =>
    await api.update(ApiType.Order, `orders/v2/confirm/${orderId}`, {
      approverName,
    }),
});

export default bookingsRepository;

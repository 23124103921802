import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import {setNW2MultiModalComponent} from 'store/app/appSlice';
import {
  ENW2MultiModalActiveComponentId,
  TUserRoleNW2MultiModalIdMap,
} from 'types/app';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

const TERMS_AND_CONDITIONS_MODALS_IDS: TUserRoleNW2MultiModalIdMap = {
  [EUserRoleCognito.ROLE_SUPPLIER]:
    ENW2MultiModalActiveComponentId.SUPPLIER_TERMS_AND_CONDITIONS_MODAL,
  [EUserRoleCognito.ROLE_CORPORATE_USER]:
    ENW2MultiModalActiveComponentId.CORPORATE_USER_TERMS_AND_CONDITIONS_MODAL,
  [EUserRoleCognito.ROLE_CUSTOMER]:
    ENW2MultiModalActiveComponentId.CUSTOMER_TERMS_AND_CONDITIONS_MODAL,
  [EUserRoleCognito.ROLE_ADMIN]:
    ENW2MultiModalActiveComponentId.CUSTOMER_TERMS_AND_CONDITIONS_MODAL,
  [EUserRoleCognito.ROLE_PROPERTY_MANAGER]:
    ENW2MultiModalActiveComponentId.CUSTOMER_TERMS_AND_CONDITIONS_MODAL,
  [EUserRoleCognito.ROLE_GUEST]:
    ENW2MultiModalActiveComponentId.CUSTOMER_TERMS_AND_CONDITIONS_MODAL,
  [EUserRoleCognito.ROLE_AGENT]:
    ENW2MultiModalActiveComponentId.CUSTOMER_TERMS_AND_CONDITIONS_MODAL,
};

const PRIVACY_POLICY_MODALS_IDS: TUserRoleNW2MultiModalIdMap = {
  [EUserRoleCognito.ROLE_SUPPLIER]:
    ENW2MultiModalActiveComponentId.SUPPLIER_PRIVACY_POLICY_MODAL,
  [EUserRoleCognito.ROLE_CORPORATE_USER]:
    ENW2MultiModalActiveComponentId.CORPORATE_USER_PRIVACY_POLICY_MODAL,
  [EUserRoleCognito.ROLE_CUSTOMER]:
    ENW2MultiModalActiveComponentId.CUSTOMER_PRIVACY_POLICY_MODAL,
  [EUserRoleCognito.ROLE_ADMIN]:
    ENW2MultiModalActiveComponentId.CUSTOMER_PRIVACY_POLICY_MODAL,
  [EUserRoleCognito.ROLE_PROPERTY_MANAGER]:
    ENW2MultiModalActiveComponentId.CUSTOMER_PRIVACY_POLICY_MODAL,
  [EUserRoleCognito.ROLE_GUEST]:
    ENW2MultiModalActiveComponentId.CUSTOMER_PRIVACY_POLICY_MODAL,
  [EUserRoleCognito.ROLE_AGENT]:
    ENW2MultiModalActiveComponentId.CUSTOMER_PRIVACY_POLICY_MODAL,
};

const FAQ_MODALS_IDS: TUserRoleNW2MultiModalIdMap = {
  [EUserRoleCognito.ROLE_SUPPLIER]:
    ENW2MultiModalActiveComponentId.SUPPLIER_FAQ_MODAL,
  [EUserRoleCognito.ROLE_CORPORATE_USER]:
    ENW2MultiModalActiveComponentId.CORPORATE_USER_FAQ_MODAL,
  [EUserRoleCognito.ROLE_CUSTOMER]:
    ENW2MultiModalActiveComponentId.CUSTOMER_FAQ_MODAL,
  [EUserRoleCognito.ROLE_ADMIN]:
    ENW2MultiModalActiveComponentId.CUSTOMER_FAQ_MODAL,
  [EUserRoleCognito.ROLE_PROPERTY_MANAGER]:
    ENW2MultiModalActiveComponentId.CUSTOMER_FAQ_MODAL,
  [EUserRoleCognito.ROLE_GUEST]:
    ENW2MultiModalActiveComponentId.CUSTOMER_FAQ_MODAL,
  [EUserRoleCognito.ROLE_AGENT]:
    ENW2MultiModalActiveComponentId.CUSTOMER_FAQ_MODAL,
};

export function useOpenTermsAndConditionsModal(role?: EUserRoleCognito) {
  const dispatch = useDispatch();

  const userRole: EUserRoleCognito = useAppSelector(({app}) => app.user.role);

  return useCallback(() => {
    const termsAndConditionsModalId: ENW2MultiModalActiveComponentId =
      TERMS_AND_CONDITIONS_MODALS_IDS[role || userRole];

    dispatch(setNW2MultiModalComponent({id: termsAndConditionsModalId}));
  }, [dispatch, role, userRole]);
}

export function useOpenPrivacyPolicyModal(role?: EUserRoleCognito) {
  const dispatch = useDispatch();

  const userRole: EUserRoleCognito = useAppSelector(({app}) => app.user.role);

  return useCallback(() => {
    const privacyPolicyModalId: ENW2MultiModalActiveComponentId =
      PRIVACY_POLICY_MODALS_IDS[role || userRole];

    dispatch(setNW2MultiModalComponent({id: privacyPolicyModalId}));
  }, [dispatch, role, userRole]);
}

export function useOpenFAQModal(role?: EUserRoleCognito) {
  const dispatch = useDispatch();

  const userRole: EUserRoleCognito = useAppSelector(({app}) => app.user.role);

  return useCallback(() => {
    const faqModalId: ENW2MultiModalActiveComponentId =
      FAQ_MODALS_IDS[role || userRole];

    dispatch(setNW2MultiModalComponent({id: faqModalId}));
  }, [dispatch, role, userRole]);
}

import styled, {css, keyframes} from 'styled-components';

import Icon from 'view/components/Icon';

import {
  borderRadiusDef,
  fontSizeSm,
  fontSizeXSm,
  fontSizeXXSm,
  fontWeightBold,
  lineHeightMd,
  lineHeightSm,
  lineHeightXSm,
  NW2Error50Color,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Info500Color,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXSm,
  primaryColor,
} from 'constants/styleVars';
import {IMAGE_ITEM_MIN_HEIGHT} from '../../NW2Images.styles';

export const Container = styled.div<{
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  isDisabled?: boolean;
}>(({isFocused, isDragAccept, isDragReject, isDisabled}) => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: ${IMAGE_ITEM_MIN_HEIGHT};
    text-align: center;
    padding: ${offsetXXSm};
    background: ${isFocused || isDragAccept || isDragReject
      ? NW2Gray200Color
      : NW2Gray100Color};
    color: ${NW2Gray600Color};
    border-radius: ${borderRadiusDef};
    overflow: hidden;
    border: 1px dashed
      ${isFocused || isDragAccept
        ? primaryColor
        : isDragReject
        ? NW2Error50Color
        : NW2Gray200Color};
    transition: border-color 0.3s, background-color 0.3s;
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    opacity: ${isDisabled ? 0.5 : 1};
    pointer-events: ${isDisabled ? 'none' : ''};

    &:hover {
      border-color: ${primaryColor};
      background-color: ${NW2Gray200Color};
    }

    img {
      width: 100%;
    }

    > div {
      width: 100%;
      height: 100%;
    }
  `;
});

export const InputLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadiusDef};
  pointer-events: none;
`;

export const InputLayoutTitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightBold};
  margin-top: ${offsetSm};
`;

export const InputLayoutSubTitle = styled.div`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

export const StyledAnimatedIcon = styled(Icon)`
  animation: ${rotate} 1.5s linear infinite;
`;

export const ProgressBar = styled.div<{progress: number}>`
  width: 100%;
  height: 4px;
  border-radius: ${borderRadiusDef};
  overflow: hidden;
  position: relative;
  background-color: ${NW2Gray200Color};

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: ${({progress}) => progress + '%'};
    transition: width 0.15s;
    background-color: ${NW2Info500Color};
  }
`;

export const ProgressSection = styled.div`
  margin-top: 16px;
`;

export const ProgressSectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${offsetDef};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  margin-bottom: ${offsetXSm};
`;

export const ProgressSectionTopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};
`;

export const ProgressSectionPercents = styled.div`
  font-weight: ${fontWeightBold};
`;

export const UploadMultipleWrapper = styled.div`
  margin-bottom: ${offsetDef};
`;

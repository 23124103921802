import React, {ReactNode} from 'react';

import TaxDisclaimer from 'view/common/TaxDisclaimer/TaxDisclaimer';

import {
  StyledTotalLink,
  TotalFooterLeft,
  TotalFooterRight,
  TotalFooterTop,
  TotalPrice,
  TotalPriceSubTitle,
  TotalPriceTitle,
} from './SummaryTotalPrice.styles';
import {CANCELLATION_POLICY_BLOCK_ID} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {offsetXXLg} from 'styles/configs';
import {EBookingStatus} from 'types/booking';

interface IProps {
  totalSum: string | ReactNode;
  bookingStatus?: EBookingStatus;
  isOfferRequest?: boolean;
  isUSA: boolean;
}

export function SummaryTotalPrice({
  totalSum,
  bookingStatus,
  isOfferRequest,
  isUSA,
}: IProps) {
  const scrollToPolicy = () => {
    const cancellationPolicy = document.getElementById(
      CANCELLATION_POLICY_BLOCK_ID,
    );

    if (cancellationPolicy) {
      const curScroll = window.scrollY;
      const OFFSET = parseInt(offsetXXLg);

      window.scrollTo({
        top:
          cancellationPolicy.getBoundingClientRect().top + curScroll - OFFSET,
        behavior: 'smooth',
      });
    }
  };

  const isCancellationPolicyHidden =
    (bookingStatus &&
      [
        EBookingStatus.CANCELED,
        EBookingStatus.RFP_CANCELLED,
        EBookingStatus.PASSED,
        EBookingStatus.RFP_DECLINED,
        EBookingStatus.RFP_EXPIRED,
        EBookingStatus.RTC_EXPIRED,
      ].includes(bookingStatus)) ||
    isOfferRequest;

  return (
    <TotalFooterTop>
      <TotalFooterLeft>
        <TotalPriceTitle>Total Price</TotalPriceTitle>
        {!isCancellationPolicyHidden && (
          <StyledTotalLink
            buttonType='link'
            size='small'
            onClick={() => scrollToPolicy()}
          >
            Cancellation policy
          </StyledTotalLink>
        )}
      </TotalFooterLeft>
      <TotalFooterRight>
        <TotalPrice>{totalSum}</TotalPrice>
        <TotalPriceSubTitle>
          <TaxDisclaimer isUSA={isUSA} />
        </TotalPriceSubTitle>
      </TotalFooterRight>
    </TotalFooterTop>
  );
}

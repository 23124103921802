import React, {memo} from 'react';

import BookabilityViewMode from './BookabilityViewMode';
import BookabilityTypeForm from './BookabilityTypeForm';

import {ESpaceDetailsContentSections, ICommonProps} from '../../types';
import {EBookAbility} from 'types/venue';

interface IProps extends ICommonProps {
  initialValues: {
    [ESpaceDetailsContentSections.BOOKABILITY_TYPE]: EBookAbility;
  };
  isRfp: boolean;
}

const BookabilityType = ({
  initialValues,
  isRfp,
  isEditorShowed,
  isSending,
  updateSpace,
  onEditorShowed,
}: IProps) => {
  return isEditorShowed ? (
    <BookabilityTypeForm
      initialValues={initialValues}
      isEditorShowed={isEditorShowed}
      isSending={isSending}
      updateSpace={updateSpace}
      onEditorShowed={onEditorShowed}
    />
  ) : (
    <BookabilityViewMode isRfp={isRfp} />
  );
};

export default memo(BookabilityType);

import DateUtils, {getDateTime, getISOString} from 'utils/dateUtils';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {TDay, TMeetingRoomItem} from 'types/search';
import {DEFAULT_END_TIME} from 'types/dto/ISearch.types';

export const getLabelsListOfExtras = (
  extraIds: number[],
  extrasOptions: IExtrasOption[],
) =>
  extrasOptions.reduce((prev, curr) => {
    return extraIds.includes(curr.id) ? [...prev, curr.name] : prev;
  }, [] as string[]);

export const checkIsExtraByTypePresent: (
  extras: IExtrasOption[],
  type: EResourcesType,
) => boolean = (extras, type) =>
  extras.some((extraItem) => extraItem.type === type);

export const getPriorityRoom = (rooms: TMeetingRoomItem[]) => {
  if (!rooms.length)
    return {
      timeStart: '',
      timeEnd: '',
      participants: 1,
      equipmentData: {},
      seatsSetup: '',
    };
  const participantsArr = rooms.map(({participants}) => participants);
  const isAllEqual = new Set(participantsArr).size === 1;

  const biggestParticipant = rooms.reduce((prev, current) => {
    return prev.participants > current.participants ? prev : current;
  }, {} as TMeetingRoomItem);

  const biggestTimeRange = rooms.reduce((prev, current) => {
    const prevDuration = DateUtils.getTotalHours(prev.timeStart, prev.timeEnd);
    const currentDuration = DateUtils.getTotalHours(
      current.timeStart,
      current.timeEnd,
    );
    return prevDuration > currentDuration ? prev : current;
  }, {} as TMeetingRoomItem);

  return isAllEqual ? biggestTimeRange : biggestParticipant;
};

export const getDayIndexesWithZeroRooms = (
  dayIndex: number,
  dayIndexesWithNoZeroRooms: number[],
  aroundIndexes?: boolean,
) => {
  const indexArr = aroundIndexes
    ? [
        dayIndexesWithNoZeroRooms[0] - 1,
        dayIndexesWithNoZeroRooms[0],
        dayIndexesWithNoZeroRooms[dayIndexesWithNoZeroRooms.length - 1],
        dayIndexesWithNoZeroRooms[dayIndexesWithNoZeroRooms.length - 1] + 1,
      ]
    : [
        dayIndexesWithNoZeroRooms[0] - 1,
        dayIndexesWithNoZeroRooms[dayIndexesWithNoZeroRooms.length - 1] + 1,
      ];

  return indexArr.some((i) => i === dayIndex);
};

export const getAccommodationParticipants = (day: TDay) => {
  if (!day.accommodationData) return day.participants;

  const accommodationParticipants: number = Object.entries(
    day.accommodationData?.data,
  ).reduce((sum, [key, value]) => {
    const numberKey = Number(key.replace('_', ''));

    return day.accommodationData?.ids.includes(numberKey) ? sum + value : sum;
  }, 0);

  return accommodationParticipants;
};

export const getPickerDate = (days: TDay[], targetDay: 'start' | 'end') => {
  const meetDays = days.filter(
    ({isPostMeet, isPreMeet}) => !isPostMeet && !isPreMeet,
  );
  return targetDay === 'start'
    ? meetDays[0].startDate
    : (meetDays.at(-1)?.startDate as string);
};

export const getGroupEndDate = <T extends TDay>(
  updatedState: T[],
  isGroupSearch: boolean,
) =>
  getISOString(
    getDateTime(getPickerDate(updatedState, 'end'))
      .plus({days: isGroupSearch ? 1 : 0})
      .set({
        hour: DEFAULT_END_TIME,
      }),
  );

import React from 'react';
import {formatFloatWithOneNumber} from 'utils/venueUtils';
import getSymbolFromCurrency from 'currency-symbol-map';

const defaultConfig = {
  position: 'suffix',
  thousandsSeparator: '.',
  decimalSeparator: ',',
};

const specificConfig = {
  position: 'prefix',
  thousandsSeparator: ',',
  decimalSeparator: '.',
};

type TProps = {
  price?: number | string | null;
  currency?: string | null;
};
const formatPrice = (
  price?: number | string | null,
  currencyCode?: string | null,
): string => {
  if (!currencyCode) {
    return formatFloatWithOneNumber(price);
  }
  const specificCurrencies = ['USD', 'CAD', 'GBP'];
  const config = specificCurrencies.includes(currencyCode)
    ? specificConfig
    : defaultConfig;

  const symbol = getSymbolFromCurrency(currencyCode);
  if (!symbol) {
    throw new Error(`Unknown currency code: ${currencyCode}`);
  }

  const floatedPrice = formatFloatWithOneNumber(price);
  const parts = floatedPrice.split(',');
  const integerPart = parts[0].replace(
    /\B(?=(\d{3})+(?!\d))/g,
    config.thousandsSeparator,
  );
  const decimalPart = parts[1] || '';

  const formattedPrice = `${integerPart}${config.decimalSeparator}${decimalPart}`;

  if (integerPart === '-') {
    return '-';
  }

  if (config.position === 'prefix') {
    return `${symbol}${formattedPrice}`;
  }

  if (config.position === 'suffix') {
    return `${formattedPrice} ${symbol}`;
  }

  return formattedPrice;
};

const PriceDisplay = ({price, currency}: TProps) => {
  return <>{formatPrice(price, currency)}</>;
};

export default PriceDisplay;

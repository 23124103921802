import React from 'react';
import styled from 'styled-components';

import {NW2Gray900Color} from 'constants/styleVars';

const StyledLoader = styled.div<{color?: string}>`
  display: inline-block;
  position: relative;
  width: 54px;
  height: 10px;

  div {
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({color}) => color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 24px;
      animation: ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 32px;
      animation: ellipsis3 0.6s infinite;
    }
  }

  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(16px, 0);
    }
  }
`;

export function NW2SearchLoader({color = NW2Gray900Color}: {color?: string}) {
  return (
    <StyledLoader color={color}>
      <div />
      <div />
      <div />
      <div />
    </StyledLoader>
  );
}

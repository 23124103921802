import React, {SyntheticEvent, useMemo} from 'react';
import styled from 'styled-components';
import {useForm} from 'react-final-form';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {StepHint} from './StepHint';
import {RoomSchemaItem} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/RoomSchemaItem';
import {RoomSetupForm} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/components/RoomSetup/RoomSetupForm/RoomSetupForm';

import {ECreateOfferFormFields} from '../types';
import {roomSchemaCheckboxWithInputRulesAndMinValue} from 'utils/finalFormFieldRules';
import {ERoomSchemaNames} from 'types/venue';
import {
  blackColor,
  fontSizeMd,
  fontSizeXSm,
  fontSizeXXXLg,
  fontWeightBold,
  lineHeightSm,
  modalDefaultWidth,
  offsetSm,
  offsetXLg,
  offsetXXSm,
} from 'constants/styleVars';
import {TUnitCapacityStyle} from 'types/dto/IUnit.types';
import {TRoomSchemaItems} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/types';

const Container = styled.div`
  padding: 0 ${offsetSm};
`;

const BtnLink = styled.button`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightBold};
  background: 0;
  border: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  margin-top: ${offsetXXSm};

  &:hover {
    text-decoration: none;
  }
`;

const ModalSubTitle = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${fontSizeXXXLg};
  color: ${blackColor};
  margin-bottom: ${offsetXLg};
`;

interface IProps {
  savedCapacities?: TUnitCapacityStyle[];
  requestedCapacity: number;
  requestedSeatingPlan: ERoomSchemaNames;
  isAddSeatingPlanShowed: boolean;
  toggleAddSeatingPlanShowed: () => void;
}
export function StepCapacities({
  savedCapacities,
  requestedCapacity,
  requestedSeatingPlan,
  isAddSeatingPlanShowed,
  toggleAddSeatingPlanShowed,
}: IProps) {
  const {mutators} = useForm();

  const onAddSeatingPlan = (e: SyntheticEvent) => {
    e.preventDefault();
    toggleAddSeatingPlanShowed();
  };

  const onUpdate = async (values: {unitCapacities: TUnitCapacityStyle}) => {
    mutators.setValue(
      ECreateOfferFormFields.UPDATED_CAPACITIES,
      values.unitCapacities,
    );
    toggleAddSeatingPlanShowed();
  };

  const initialValues = useMemo(() => {
    // set locally saved values
    if (savedCapacities?.length) {
      const obj = {} as TRoomSchemaItems;

      savedCapacities.forEach((item) => {
        obj[item.setupStyle] = {
          capacity: item.capacity,
          checkbox: true,
        };
      });

      return {
        [ECreateOfferFormFields.CAPACITY]: obj,
      };
    }

    return {
      [ECreateOfferFormFields.CAPACITY]: {},
    };
  }, [savedCapacities]);

  return (
    <>
      <StepHint
        title='Pro tip!'
        text={`Is this room also available in different layouts? Providing
               all possible options will help you secure bookings.`}
      >
        <BtnLink onClick={onAddSeatingPlan}>Add additional room setups</BtnLink>
      </StepHint>

      <Container>
        <RoomSchemaItem
          name={requestedSeatingPlan}
          disabled={false}
          fieldName={ECreateOfferFormFields.CAPACITY}
          rules={roomSchemaCheckboxWithInputRulesAndMinValue(
            requestedSeatingPlan,
            ECreateOfferFormFields.CAPACITY,
            requestedCapacity,
          )}
          isNoCheckbox
        />
      </Container>

      <NW2ModalDrawer
        body={
          <>
            <ModalSubTitle>
              Maximum capacities per setup will help bookers know what they can
              request.
            </ModalSubTitle>

            <RoomSetupForm
              fieldName={ECreateOfferFormFields.CAPACITY}
              initialValues={initialValues}
              onEditorShowed={toggleAddSeatingPlanShowed}
              updateSpace={onUpdate}
              isSending={false}
              excludedItems={[requestedSeatingPlan]}
            />
          </>
        }
        header='Set capacities'
        modalWidth={modalDefaultWidth}
        modalBodyStyle={{
          padding: `0 ${offsetXLg} ${offsetXLg} ${offsetXLg}`,
        }}
        drawerHeight='auto'
        isMobile={false}
        isShowed={isAddSeatingPlanShowed}
        onClose={toggleAddSeatingPlanShowed}
      />
    </>
  );
}

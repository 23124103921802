import styled from 'styled-components';
import {
  fontSizeXSm,
  fontWeightBold,
  NW2ErrorLight500Color,
  NW2Gray200Color,
  NW2SuccessLight500Color,
  offsetDef,
  offsetSm,
  offsetXSm,
} from 'styles/configs';

export const Container = styled.div`
  font-size: ${fontSizeXSm};
  text-align: left;
`;

export const StrengthInfoBlock = styled.div`
  margin: ${offsetDef} 0 0;
`;

export const ProgressBarSection = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${offsetDef};
  margin-bottom: ${offsetSm};
`;

export const ProgressBarTitle = styled.div<{isAllRulesChecked: boolean}>`
  font-weight: ${fontWeightBold};

  & > span {
    color: ${({isAllRulesChecked}) =>
      isAllRulesChecked ? NW2SuccessLight500Color : NW2ErrorLight500Color};
  }
`;

export const ProgressBar = styled.div<{
  allRulesNumber: number;
  acceptedRulesNumber: number;
}>`
  height: 4px;
  width: 100%;
  background-color: ${NW2Gray200Color};
  border-radius: 2px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${({acceptedRulesNumber, allRulesNumber}) =>
      (acceptedRulesNumber / allRulesNumber) * 100}%;
    background-color: ${({acceptedRulesNumber, allRulesNumber}) =>
      acceptedRulesNumber === allRulesNumber
        ? NW2SuccessLight500Color
        : NW2ErrorLight500Color};
  }
`;

export const RuleItem = styled.div`
  display: grid;
  grid-column-gap: ${offsetXSm};
  grid-template-columns: auto 1fr;
  align-items: center;

  &:not(&:last-child) {
    margin-bottom: 6px;
  }
`;

import React, {FC} from 'react';
import {FormInstance} from 'antd';

import GeneralForm from './components/GeneralForm';
import GeneralInfo from './components/GeneralInfo';

import {TStepMode} from 'types/app';
import {EAccommodationType} from 'types/dto/IPublicVenue';

type Props = {
  mode?: TStepMode;
  form: FormInstance;
  accommodationType: EAccommodationType;
  isHmd?: boolean;
};

const General: FC<Props> = ({mode, form, accommodationType, isHmd}) => {
  return mode === 'add' ? (
    <GeneralForm
      form={form}
      accommodationType={accommodationType}
      isHmd={isHmd}
    />
  ) : (
    <GeneralInfo accommodationType={accommodationType} />
  );
};

export default General;

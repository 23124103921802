import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useLoggedInUser} from './useLoggedInUser';

export const useRedirectLinkNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {isLoggedInUser} = useLoggedInUser();

  const redirectLink: string = useAppSelector(({app}) => app.redirectLink);

  useEffect(() => {
    if (redirectLink && isLoggedInUser) {
      navigate(redirectLink);
    }
  }, [redirectLink, dispatch, isLoggedInUser, navigate]);
};

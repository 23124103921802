import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import {
  blackColor,
  fontSizeXSm,
  fontWeightExtraBold,
  NW2Secondary,
  whiteColor,
} from 'constants/styleVars';

export const FeedbackSubjectButton = styled.button`
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;

  background: transparent;
  border: 1px solid ${blackColor};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${blackColor};
    color: ${whiteColor};
  }

  &:focus {
    background: ${NW2Secondary};
    border-color: ${NW2Secondary};
    color: ${blackColor};
  }
`;

type TProps = {
  icon: string;
  text: string;
  onClick: () => void;
};

function SubjectButton({icon, text, onClick}: TProps) {
  return (
    <FeedbackSubjectButton onClick={onClick}>
      <Icon icon={icon} size={20} transparent />
      <div>{text}</div>
    </FeedbackSubjectButton>
  );
}

export default SubjectButton;

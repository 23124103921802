import styled, {css} from 'styled-components';

import {
  blackColor,
  NW2Blue500Color,
  offsetSm,
  offsetXSm,
  offsetXXSm,
  offsetXXXSm,
  whiteColor,
} from 'constants/styleVars';

export const OverlayViewContainer = styled.div<{isSelected: boolean}>`
  position: relative;

  ${({isSelected}) => (isSelected ? 'z-index: 1;' : '')}

  &:hover,
  &:hover * {
    cursor: pointer;
  }
`;

export const OverlayViewContent = styled.div<{
  isSelected: boolean;
  noPrice?: boolean;
  isCentered?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({isSelected}) =>
    isSelected ? NW2Blue500Color : whiteColor};
  padding: ${offsetXXXSm} ${offsetXSm};
  border: 1px solid ${blackColor};
  border-radius: 20px;
  gap: ${offsetXXSm};
  justify-content: ${({isCentered}) => isCentered && 'center'};

  ${({noPrice}) =>
    noPrice &&
    css`
      padding: 6px;
      border-radius: 50%;
    `}
`;

export const OverlayViewArrow = styled.div`
  position: relative;
  left: 51%;
  transform: translateX(-50%);
  bottom: 1px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${blackColor};
`;

export const Dot = styled.div`
  position: relative;
  width: ${offsetSm};
  height: ${offsetSm};
  border: 2px solid ${whiteColor};
  border-radius: 50%;
  background-color: ${blackColor};
`;

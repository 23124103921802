import React from 'react';

import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {pluralize} from 'utils/helpers';

import {
  ContentWrapper,
  ContentSubtitle,
  Text,
} from '../../NMMSpacesDetails.styles';

interface IProps {
  priceHour: number;
  priceHalfDay: number;
  priceDay: number;
  minimumPaidDuration: number;
  currency: string | undefined;
}

const PriceViewMode = ({
  priceHour,
  priceHalfDay,
  priceDay,
  minimumPaidDuration,
  currency,
}: IProps) => {
  return (
    <>
      <ContentSubtitle>price range</ContentSubtitle>
      <ContentWrapper>
        <Text>
          <PriceDisplay price={priceHour} currency={currency} /> p/h
        </Text>
        {priceHalfDay && (
          <Text>
            <PriceDisplay price={priceHalfDay} currency={currency} /> p/hd
          </Text>
        )}
        <Text>
          <PriceDisplay price={priceDay} currency={currency} /> p/d
        </Text>
      </ContentWrapper>

      {minimumPaidDuration && (
        <>
          <ContentSubtitle>minimum spend</ContentSubtitle>
          <Text>{pluralize('hour', minimumPaidDuration)}</Text>
        </>
      )}
    </>
  );
};

export default PriceViewMode;

import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {ERoomSchemaNames} from 'types/venue';
import {IExtrasOption, IExtrasResponse} from 'types/dto/IExtras.type';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useQuery} from 'hooks/useQuery';
import {makeInitPreviewDeskUnitsData} from '../helpers';
import {useAppSelector} from 'store/hooks';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import LocalStorageService from 'infra/common/localStorage.service';
import {setIsRegistrationAction} from 'store/app/appSlice';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';
import {
  setPreviewAttendees,
  setPreviewDesks,
} from 'store/bookingsCustomer/bookingsCustomerSlice';

export function useDeskBookingInitialData() {
  const dispatch = useDispatch();
  const {isSingleRegisteredVenue} = useGroupRequest();

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const availableExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, 'venuesReducer.venueDetails.extras'),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {previewDesks} = useCustomerOrderUnits();

  const {venueUnits: unitsToBook} = useVenueDetailsData();

  const {
    meetingRoomCapacity: participants,
    startDate: searchStartDate,
    endDate: searchEndDate,
  } = searchCriteria;

  const {seatingPlan = '', roomType = '', unitIds = ''} = useQuery();

  const venueUnits = useMemo(() => {
    return unitsToBook?.filter((unit) => {
      return unitIds
        ? typeof unitIds === 'string' && unitIds?.includes(String(unit.id))
        : unit;
    });
  }, [unitIds, unitsToBook]);

  // clear store data on page leave
  useEffect(() => {
    return () => {
      dispatch(setPreviewDesks([]));
      dispatch(setPreviewAttendees([]));
      dispatch(setIsRegistrationAction(false));
    };
  }, [dispatch]);

  const isInitPreviewUnitsExist = !!previewDesks.length;

  const guestBookingPreviewData =
    LocalStorageService.getByKey('bookingPreviewData');

  const parsedPreviewUnits = JSON.parse(guestBookingPreviewData as string);

  const guestPreviewUnits = parsedPreviewUnits
    ? parsedPreviewUnits?.previewUnits
    : null;

  useEffect(() => {
    if (isInitPreviewUnitsExist || !venueUnits?.length) return;

    const initPreviewDeskUnitsData = makeInitPreviewDeskUnitsData({
      searchStartDate,
      searchEndDate,
      venueUnits,
      availableExtras,
      seatingPlan: seatingPlan as ERoomSchemaNames,
      roomType: roomType as ERoomType,
      participants,
    });

    dispatch(
      setPreviewDesks(
        guestPreviewUnits ? guestPreviewUnits : initPreviewDeskUnitsData,
      ),
    );
  }, [
    availableExtras,
    dispatch,
    extrasOption,
    guestPreviewUnits,
    isInitPreviewUnitsExist,
    isSingleRegisteredVenue,
    participants,
    roomType,
    searchEndDate,
    searchStartDate,
    seatingPlan,
    venueUnits,
  ]);

  const formInitialValues = {
    participants,
  };

  return {
    formInitialValues,
  };
}

import React from 'react';
import styled from 'styled-components';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import ActionButtons from '../ActionButtons';
import Icon from 'view/components/Icon';

import {useAppSelector} from 'store/hooks';
import {TUnitCapacityStyle} from 'types/dto/IUnit.types';
import {ERoomSchema, ERoomSchemaNames, iconsObject} from 'types/venue';

import {
  offsetDef,
  fontSizeMd,
  fontSizeXSm,
  fontWeightNormal,
  NW2Gray600Color,
} from 'styles/configs';
import {
  RoomSetupBlock,
  RoomSetupBlockIconWrapper,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddMeetingRequest/AddMeetingRequest.styles';
import {StyledFieldContainer} from '../../../../components/NW2FormItem/NW2FormItem.styles';
import {InputRadioButton} from '../../../../components/NW2FormItem/components';

const GroupWrapper = styled.div`
  overflow: hidden;

  & > *:not(& > *:last-of-type) {
    margin-bottom: ${offsetDef};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;

  & > * {
    margin: 0;
  }
`;

const SubTitle = styled.div`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightNormal};
  text-transform: none;
`;

const SubLabel = styled.div`
  font-size: ${fontSizeXSm};
  color: ${NW2Gray600Color};
`;

interface IProps {
  prevSetupStyle: ERoomSchemaNames | undefined;
  isRoomSetupModalShow: boolean;
  availableSeatPlans: TUnitCapacityStyle[] | undefined;
  onRoomSetupModalClose: () => void;
  onRoomSetupModalSave: () => void;
  onSetupStyleChange: (value: any) => void;
  isChangeSetupStyleButtonDisabled: boolean;
}

const RoomSetupModal = ({
  prevSetupStyle,
  isRoomSetupModalShow,
  availableSeatPlans,
  onRoomSetupModalClose,
  onRoomSetupModalSave,
  onSetupStyleChange,
  isChangeSetupStyleButtonDisabled,
}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const modalWidth = 400;
  const drawerHeight = 'auto';
  const isShowed = isRoomSetupModalShow && !!availableSeatPlans?.length;

  const header = (
    <div>
      <span>Room setup</span>
      <SubTitle>These are the possible setups for this room.</SubTitle>
    </div>
  );

  const body = (
    <GroupWrapper>
      {availableSeatPlans?.map(({setupStyle, capacity}) => (
        <RoomSetupBlock key={setupStyle}>
          <FlexContainer>
            <StyledFieldContainer>
              <InputRadioButton
                checked={prevSetupStyle === setupStyle}
                onChange={onSetupStyleChange}
                value={setupStyle}
                name={'setupStyle'}
                label={
                  <>
                    <span>{ERoomSchema[setupStyle]}</span>
                    <SubLabel>{`${capacity} people max.`}</SubLabel>
                  </>
                }
              />
            </StyledFieldContainer>
          </FlexContainer>
          <RoomSetupBlockIconWrapper>
            <Icon icon={iconsObject[setupStyle]} />
          </RoomSetupBlockIconWrapper>
        </RoomSetupBlock>
      ))}
    </GroupWrapper>
  );

  const footer = (
    <ActionButtons
      alignRight
      noTopOffset
      saveButtonLabel='Change setup'
      isSaveButtonDisabled={isChangeSetupStyleButtonDisabled}
      onSaveHandler={onRoomSetupModalSave}
      onCancelHandler={onRoomSetupModalClose}
    />
  );

  return (
    <NW2ModalDrawer
      header={header}
      body={body}
      footer={footer}
      isMobile={isMobile}
      isShowed={isShowed}
      onClose={onRoomSetupModalClose}
      modalWidth={modalWidth}
      drawerHeight={drawerHeight}
    />
  );
};

export default RoomSetupModal;

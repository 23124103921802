import React, {FC} from 'react';
import {FormInstance, Select} from 'antd';
import styled from 'styled-components';

import {ICorporateAccountCompanyForSelectCompany} from 'store/admin/types';
import {COMPANY_ID_FIELD_NAME} from 'constants/admin';

const {Option} = Select;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const CompanySelect: FC<{
  form: FormInstance;
  companies: ICorporateAccountCompanyForSelectCompany[];
}> = ({form, companies}) => {
  const onChange = (value: unknown): void => {
    form.setFieldsValue({[COMPANY_ID_FIELD_NAME]: value});
  };

  return (
    <StyledSelect
      placeholder='Select company'
      optionFilterProp='children'
      onChange={onChange}
      showSearch
    >
      {companies.map((company: ICorporateAccountCompanyForSelectCompany) => {
        const {id, name} = company;
        return (
          <Option key={`${id}`} value={id}>
            {name}
          </Option>
        );
      })}
    </StyledSelect>
  );
};

export default CompanySelect;

import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useForm, useFormState} from 'react-final-form';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {useInputPriceChange} from 'view/venue/hooks/useInputPriceChange';
import {BEDROOMS_NAMES_SHORT} from 'constants/bedrooms';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {ECreateOfferFormFields, TBedroomsType} from '../types';
import {requiredPriceRules} from 'utils/finalFormFieldRules';
import {offsetDef, offsetSm, offsetXLg} from 'constants/styleVars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXLg};
`;

const InputBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-column-gap: ${offsetDef};
`;

const InputBoxLabel = styled.span`
  padding-top: ${offsetSm};
`;

interface IProps {
  activeUnitId: string;
  formStateValue?: TBedroomsType;
  bedrooms: IExtrasOption[];
  isInitialized?: boolean;
}
export function StepBedroomsPrices({
  activeUnitId,
  formStateValue,
  bedrooms,
  isInitialized,
}: IProps) {
  const {
    onChangePrice,
    // onBlurPrice
  } = useInputPriceChange();
  const {mutators} = useForm();
  const {values} = useFormState();

  const formValue =
    values[activeUnitId]?.[ECreateOfferFormFields.PRICES_BEDROOMS];

  const fieldName = `${activeUnitId}.${ECreateOfferFormFields.PRICES_BEDROOMS}`;

  useEffect(() => {
    if (isInitialized && !formStateValue && formValue) {
      /**
       * reset input value inside final-form state
       * uses on go back click
       */
      mutators.setValue(fieldName, {});
    }
  }, [fieldName, formStateValue, formValue, isInitialized, mutators]);

  return (
    <Wrapper>
      {bedrooms.map(({code}) => {
        // path to final-form object
        const field = `${fieldName}.${code}`;

        return (
          <InputBox key={activeUnitId + code}>
            <NW2FormItemInput
              type='text'
              label={BEDROOMS_NAMES_SHORT[code]}
              placeholder={BEDROOMS_NAMES_SHORT[code]}
              name={field}
              rules={requiredPriceRules(
                field,
                'Please provide accommodation price',
              )}
              onChange={onChangePrice(field)}
              // onBlur={onBlurPrice(field)} // event conflicts with button back
            />
            <InputBoxLabel>per night</InputBoxLabel>
          </InputBox>
        );
      })}
    </Wrapper>
  );
}

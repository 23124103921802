import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import {setNW2MultiModalComponent} from 'store/app/appSlice';
import {Routes} from 'constants/routes';
import {ENW2MultiModalActiveComponentId} from 'types/app';

export default function useGoBackFromInfoPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isModalOpen = !!useAppSelector(
    ({app}) => app.nw2MultiModalActiveComponent.id,
  );

  return useCallback(() => {
    if (isModalOpen) {
      dispatch(
        setNW2MultiModalComponent({id: ENW2MultiModalActiveComponentId.EMPTY}),
      );
    } else {
      navigate(Routes.mainLanding);
    }
  }, [dispatch, isModalOpen, navigate]);
}

import styled from 'styled-components';
import {Layout} from 'antd';

import {
  contentMaxWidth,
  offsetXXLg,
  offsetXLg,
} from '../../../constants/styleVars';

const {Content} = Layout;

const StyledContent = styled(Content)`
  width: 100%;
  max-width: ${contentMaxWidth};
  margin: ${offsetXXLg} auto;
  padding: ${offsetXLg};
`;

export default StyledContent;

import React from 'react';
import styled from 'styled-components';

import DeskAvailabilityLabel from 'view/components/NW2DeskAvailabilityLabel/NW2DeskAvailabilityLabel';

import {mdBp} from 'constants/styleVars';

const StyledBlock = styled.div<{isPublicVenue: boolean}>`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  justify-self: end;

  @media (min-width: ${mdBp}px) {
    ${({isPublicVenue}) =>
      isPublicVenue
        ? `
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  `
        : `
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    justify-self: start;
    align-self: end;
      `};
  }
`;

type TProps = {
  capacity: number;
  isPublicVenue: boolean;
};

const DeskAvailabilityBlock = ({capacity, isPublicVenue}: TProps) => (
  <StyledBlock isPublicVenue={isPublicVenue}>
    <DeskAvailabilityLabel capacity={capacity} />
  </StyledBlock>
);

export default DeskAvailabilityBlock;

import React, {useState, useMemo, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {DateTime} from 'luxon';

import Icon from 'view/components/Icon';

import useInterval from 'hooks/useInterval';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {IVenue, EVenueTypes, IVenueCurrencies} from 'types/venue';
import {Title, Text} from '../../Header.styles';
import {useAppSelector} from 'store/hooks';
import {
  StyledIcon,
  AddressText,
  StyledDiv,
  TimeDiv,
  TimeText,
} from './useProfileGeneralInfoHeader.styles';

type TReturnProps = {
  title: string | undefined;
  subtitle?: ReactNode | string;
  items: string | number;
  content: ReactNode;
};

const useProfileGeneralInfoHeader = (venue: IVenue): TReturnProps => {
  const venueCurrencies: IVenueCurrencies | null = useSelector((state) =>
    _get(state, `venue.venueCurrencies`),
  );
  const locale = useAppSelector(({app}) => app.locale);

  const [time, setTime] = useState('-');
  useInterval(() => {
    const date = DateTime.now()
      .setZone(location.timeZone)
      .toLocaleString(DateTime.TIME_SIMPLE, {
        locale,
      });
    setTime(date);
  }, 1000);

  const {location, venueType, currency, hkey, accommodationType, name} = venue;

  const isPublicVenue = accommodationType === EAccommodationType.VENUE;
  const timezoneText = location.timeZone?.split('/').join(' / ');
  const venueAddress = getAddressStringFromLocation(location);
  const currencyText = useMemo(
    () =>
      venueCurrencies?.all
        .filter((item) => item.code === currency)
        .map(({code, name}) => `${name} (${code})`)[0],
    [venueCurrencies, currency],
  );

  return {
    title: name,
    subtitle: (
      <>
        <StyledIcon transparent icon='NW2_LOCATION_OUTLINE' size={16} />
        <AddressText>{venueAddress}</AddressText>
      </>
    ),
    items: 5,
    content: (
      <>
        <div data-testid='profile-timezone'>
          <Title>Timezone</Title>
          <StyledDiv>
            <Text data-testid='profile-timezone-name'>{timezoneText}</Text>
            <TimeDiv>
              <Icon icon='CLOCK' size={16} />
              <TimeText data-testid='profile-timezone-time'>{time}</TimeText>
            </TimeDiv>
          </StyledDiv>
        </div>

        {venueType && (
          <div data-testid='profile-venue-type'>
            <Title>Venue type</Title>
            <Text>{EVenueTypes[venueType]}</Text>
          </div>
        )}

        {isPublicVenue ? (
          <>
            <div data-testid='profile-currency'>
              <Title>Currency</Title>
              <Text>{currencyText}</Text>
            </div>
            <div data-testid='profile-hkey'>
              <Title>H-key</Title>
              <Text>{hkey || '-'}</Text>
            </div>
          </>
        ) : (
          // Need these for structure
          <>
            <div />
            <div />
          </>
        )}
      </>
    ),
  };
};

export default useProfileGeneralInfoHeader;

import {
  TEditCharacterFormValues,
  TEditCharacterFormErrors,
} from '../NW2VenueDescription.types';

export const validatorCharacters = (values: TEditCharacterFormValues) => {
  const errors = {} as TEditCharacterFormErrors;

  const venueCharacters: string[] = [];

  if (values.venueCharacters.length === 0) {
    venueCharacters.push('At least one venue character must be selected');
  }

  if (venueCharacters.length > 0) {
    errors.venueCharacters = venueCharacters;
  }

  return errors;
};

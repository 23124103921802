import React, {useCallback} from 'react';

import Header from 'view/venue/NW2VenueProfile/components/Header/Header';
import FormSection from './FormSection';
import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';

import {IGlobalVenue} from 'types/venue';
import {
  EVenueProfileContactsSections,
  EVenueSupplierPagesWithHeader,
} from '../../types';
import {
  StyledSectionTitle,
  AnchorsWrapper,
  AnchorSections,
  SectionWrapper,
} from './ContactsDetails.styles';
import {SubTitle} from 'view/components/NW2Accordion/components/NW2AccordionItem.styles';

const ContactsDetails = ({globalVenue}: {globalVenue: IGlobalVenue}) => {
  const getTitleText = useCallback((section: EVenueProfileContactsSections) => {
    switch (section) {
      case EVenueProfileContactsSections.DIRECTOR:
        return 'Management';
      case EVenueProfileContactsSections.SECONDARY_CONTACT:
        return 'Additional notification contacts';
      default:
        return '';
    }
  }, []);

  const getSubtitleText = useCallback(
    (section: EVenueProfileContactsSections) => {
      switch (section) {
        case EVenueProfileContactsSections.SECONDARY_CONTACT:
          return 'Designate additional contacts to receive copies of booking-related emails.';
        default:
          return '';
      }
    },
    [],
  );

  return (
    <>
      <Header
        globalVenue={globalVenue}
        showHeaderForSection={EVenueSupplierPagesWithHeader.CONTACTS}
      />
      <AnchorsWrapper>
        <AnchorSections>
          {Object.values(EVenueProfileContactsSections).map((section) => {
            const titleText = getTitleText(section);
            const subtitleText = getSubtitleText(section);

            return (
              <FormSection
                key={section}
                venue={globalVenue.venue}
                sectionName={section}
                containerTitle={
                  titleText && (
                    <SectionWrapper>
                      <StyledSectionTitle>{titleText}</StyledSectionTitle>
                      {subtitleText && <SubTitle>{subtitleText}</SubTitle>}
                    </SectionWrapper>
                  )
                }
              />
            );
          })}
        </AnchorSections>
        <AnchorMenu sections={Object.values(EVenueProfileContactsSections)} />
      </AnchorsWrapper>
    </>
  );
};

export default ContactsDetails;

import React, {memo, useEffect} from 'react';
import {IAmenity} from 'types/dto/IAmenity';

import AmenitiesForm from './AmenitiesForm/AmenitiesForm';

import {AmenitiesView} from './AmenitiesView/AmenitiesView';
import {spaceTypes} from './types';
import {ESpaceDetailsContentSections, ICommonProps} from '../../types';
import {ERoomType} from 'types/dto/ERoomType.type';

interface IProps extends ICommonProps {
  initialValues: {
    [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: any;
    [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: any;
  };
  section: ESpaceDetailsContentSections;
  amenities: IAmenity[];
  roomType: ERoomType;
}

const SpaceAmenities = ({
  initialValues,
  section,
  isEditorShowed,
  onEditorShowed,
  amenities,
  isSending,
  updateSpace,
  roomType,
}: IProps) => {
  const amenitiesByType = amenities.filter(
    ({type}) => spaceTypes[type] === section,
  );
  const sortedAmenities = amenities.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  useEffect(() => {
    if (!amenitiesByType.length) {
      onEditorShowed();
    }
  }, [amenitiesByType.length, onEditorShowed]);

  return isEditorShowed ? (
    <AmenitiesForm
      initialValues={initialValues}
      roomType={roomType}
      amenities={amenities}
      isSending={isSending}
      updateSpace={updateSpace}
      onEditorShowed={onEditorShowed}
      section={section}
    />
  ) : (
    <AmenitiesView
      roomType={roomType}
      amenities={sortedAmenities}
      section={section}
    />
  );
};

export default memo(SpaceAmenities);

import React, {ChangeEvent} from 'react';
import styled from 'styled-components';
import {DatePicker, Form, Input} from 'antd';

import {nameFieldRules, dateBirthFieldRules} from 'utils/antdFormFieldRules';
import {Moment} from 'moment';
import {StyledItem} from '../ContactDetailsForm.styles';
import {lightGrayColor, offsetDef, offsetXLg} from 'constants/styleVars';
import TextPopover from '../TextPopover';

const StyledDatePicker = styled(DatePicker)`
  margin-right: ${offsetDef};
`;

export const Divider = styled.div`
  width: 92%;
  height: 1px;
  background-color: ${lightGrayColor};
  margin-bottom: ${offsetXLg};
`;
export const StyledNameWrapper = styled.div<{isInfo?: boolean}>`
  display: flex;
  width: ${({isInfo}) => !isInfo && '92%'};
`;

type TRoleProps = {
  role: string;
  nameLabel: string;
  isRequired?: boolean;
  handleFormFields: (
    path: string,
    role: string,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  handleValueFromField: (path: string, value: string, role: string) => void;
  labelWithStar?: (label: string) => React.ReactElement;
  isBusinessEntityType?: boolean;
};

const EntityRoleFields = ({
  role,
  nameLabel,
  labelWithStar,
  isRequired,
  handleFormFields,
  handleValueFromField,
  isBusinessEntityType,
}: TRoleProps) => {
  const {Item} = Form;

  const handleChange =
    (fieldName: string, role: string) =>
    (date: Moment | null, dateString: string) => {
      handleValueFromField(fieldName, dateString, role);
    };
  const nameRules = isRequired ? nameFieldRules : [];
  const dateBirthRules = isRequired ? dateBirthFieldRules : [];
  return (
    <>
      <Item
        label={
          isRequired
            ? labelWithStar && labelWithStar(`${nameLabel}`)
            : nameLabel
        }
        style={{marginBottom: '0'}}
      >
        <StyledNameWrapper isInfo={role === 'signatory'}>
          <StyledItem name={`${role}FirstName`} rules={nameRules}>
            <Input
              name={`${role}FirstName`}
              placeholder='First name'
              onChange={handleFormFields(
                `legalContact.${role}.firstName`,
                role,
              )}
            />
          </StyledItem>
          <StyledItem name={`${role}LastName`} rules={nameRules}>
            <Input
              name={`${role}LastName`}
              placeholder='Last name'
              onChange={handleFormFields(`legalContact.${role}.lastName`, role)}
            />
          </StyledItem>
          {role === 'signatory' && <TextPopover textIndex={4} />}
        </StyledNameWrapper>
      </Item>

      <Item
        label={
          isRequired
            ? labelWithStar && labelWithStar('Date of birth')
            : 'Date of birth'
        }
        style={{marginBottom: '0'}}
      >
        <StyledNameWrapper>
          <Item name={`${role}YearBirth`} rules={dateBirthRules}>
            <StyledDatePicker
              picker='year'
              format='YYYY'
              onChange={handleChange(`legalContact.${role}.yearBirth`, role)}
            />
          </Item>
          <Item name={`${role}MonthBirth`} rules={dateBirthRules}>
            <StyledDatePicker
              picker='month'
              format='MM'
              mode='month'
              onChange={handleChange(`legalContact.${role}.monthBirth`, role)}
              dropdownClassName='monthPicker'
            />
          </Item>
          <Item name={`${role}DateBirth`} rules={dateBirthRules}>
            <DatePicker
              format='DD'
              mode='date'
              onChange={handleChange(`legalContact.${role}.dateBirth`, role)}
              dropdownClassName='datePicker'
            />
          </Item>
        </StyledNameWrapper>

        {isBusinessEntityType && <Divider />}
      </Item>
    </>
  );
};

export default EntityRoleFields;

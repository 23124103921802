import React, {ReactNode} from 'react';

import Icon from 'view/components/Icon';

import {
  infoColor,
  NW2Error50Color,
  NW2ErrorLight500Color,
  NW2Gray100Color,
  NW2Green50Color,
  NW2Info500Color,
  NW2Warn50Color,
  successColor,
  warnColor,
} from 'constants/styleVars';
import {IconBox, Title, Wrapper} from './NW2InfoCard.styles';

export enum EInfoCardType {
  warn = 'warn',
  info = 'info',
  success = 'success',
  error = 'error',
  live = 'live',
}

const colorSchema = {
  [EInfoCardType.warn]: warnColor,
  [EInfoCardType.info]: infoColor,
  [EInfoCardType.success]: successColor,
  [EInfoCardType.error]: NW2ErrorLight500Color,
  [EInfoCardType.live]: NW2Info500Color,
};

const backgroundSchema = {
  [EInfoCardType.warn]: NW2Warn50Color,
  [EInfoCardType.info]: infoColor,
  [EInfoCardType.success]: NW2Green50Color,
  [EInfoCardType.error]: NW2Error50Color,
  [EInfoCardType.live]: NW2Info500Color,
};

interface IProps {
  icon?: string;
  title?: string;
  type?: EInfoCardType;
  children: ReactNode;
  isColoredBackground?: boolean;
}

function NW2InfoCard({
  icon,
  title,
  type = EInfoCardType.warn,
  children,
  isColoredBackground,
}: IProps) {
  return (
    <Wrapper
      isIcon={!!icon}
      color={colorSchema[EInfoCardType[type]]}
      backgroundColor={
        isColoredBackground
          ? backgroundSchema[EInfoCardType[type]]
          : NW2Gray100Color
      }
    >
      {!!icon && (
        <IconBox color={colorSchema[EInfoCardType[type]]}>
          <Icon transparent icon={icon} />
        </IconBox>
      )}
      <div>
        {title && <Title>{title}</Title>}
        {children}
      </div>
    </Wrapper>
  );
}

export default NW2InfoCard;

import styled from 'styled-components';

import {
  borderRadiusDef,
  fontSizeMd,
  lineHeightLg,
  NW2ErrorLight500Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXSm,
} from 'constants/styleVars';

export const BodyWrapper = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetDef};
  border-left: ${offsetXXSm} solid ${NW2ErrorLight500Color};
  border-radius: ${borderRadiusDef} 0 0 ${borderRadiusDef};
  margin-top: ${offsetDef};
  padding-left: ${offsetDef};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${offsetXLg};
  padding-top: ${offsetXLg};
  margin-bottom: ${offsetSm};
`;

export const bodyStyle = {
  paddingBottom: 0,
};

import {NW2Gray600Color, offsetXLg} from 'constants/styleVars';
import React from 'react';
import {
  emailFieldRules,
  firstNameFieldRules,
  notRequiredStringFieldRules,
} from 'utils/finalFormFieldRules';
import {
  NW2FormItemInput,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import {TextAreaCounted} from 'view/components/TextAreaCounted/TextAreaCounted';
import {ERequestFormFields} from 'view/customer/types';
import {
  FormGroup,
  Container,
  Wrapper,
} from './RequestFormContactDetails.styles';

interface IProps {
  isLoggedInUser: boolean;
  isTablet?: boolean;
}

const RequestFormContactDetails = ({isLoggedInUser, isTablet}: IProps) => {
  return (
    <Wrapper isTablet={isTablet}>
      <span>
        Our suppport agents will take care of your request and will provide you
        with suitable offers for your specific details which you can review on
        the right side of this page.{' '}
      </span>

      <FormGroup gap={offsetXLg} isTablet={isTablet}>
        <Container isTablet={isTablet}>
          <NW2FormItemInput
            type='text'
            name={ERequestFormFields.DESTINATION}
            placeholder='Destination'
            label='Destination'
            labelColor={NW2Gray600Color}
            data-testid={`request-template-${ERequestFormFields.DESTINATION}`}
            disabled
          />

          <NW2FormItemInput
            type='text'
            name={ERequestFormFields.NAME}
            placeholder='Name*'
            label='Name*'
            labelColor={NW2Gray600Color}
            disabled={isLoggedInUser}
            rules={firstNameFieldRules}
            data-testid={`request-template-${ERequestFormFields.NAME}`}
          />
          <NW2FormItemInput
            type='email'
            name={ERequestFormFields.EMAIL}
            placeholder='Email address*'
            label='Email address*'
            labelColor={NW2Gray600Color}
            disabled={isLoggedInUser}
            rules={emailFieldRules}
            data-testid={`request-template-${ERequestFormFields.EMAIL}`}
          />
          <NW2FormItemPhoneNumber
            type='text'
            name={ERequestFormFields.PHONE}
            placeholder='+49 1234 123456'
            label='Phone number'
            labelColor={NW2Gray600Color}
            rules={notRequiredStringFieldRules}
            data-testid={`request-template-${ERequestFormFields.PHONE}`}
          />
        </Container>

        {isLoggedInUser && (
          <NW2FormItemInput
            type='text'
            name={ERequestFormFields.COMPANY}
            placeholder='Company name'
            label='Company name'
            labelColor={NW2Gray600Color}
            data-testid={`request-template-${ERequestFormFields.COMPANY}`}
          />
        )}
        <TextAreaCounted
          name={ERequestFormFields.COMMENT}
          label='Comment'
          placeholder='Comment'
          minHeight={80}
          variant='primary'
          description='Any other requirements or comments? Please provide them here.'
          maxCounter={1000}
        />
      </FormGroup>
    </Wrapper>
  );
};

export default RequestFormContactDetails;

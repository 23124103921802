import {ReactNode} from 'react';

export enum EVenueProfilePages {
  INFO = 'Profile information',
  PAYMENT = 'Payment',
  DETAILS = 'Legal details',
  CONTACTS = 'Contacts',
}

export enum ECorporateVenueProfilePages {
  INFO = 'Profile information',
  DETAILS = 'Legal details',
  CONTACTS = 'Contacts',
}

export enum EPublicVenueProfilePages {
  INFO = 'Profile information',
  CONTACTS = 'Contacts',
}

export const VenueProfileHash = {
  [EVenueProfilePages.INFO]: '#profileinformation',
  [EVenueProfilePages.PAYMENT]: '#payment',
  [EVenueProfilePages.CONTACTS]: '#contacts',
  [EVenueProfilePages.DETAILS]: '#legaldetails',
};

export enum EVenueSupplierPagesWithHeader {
  INFO = 'Profile information',
  CONTACTS = 'Contacts',
  MEETING_ROOM = 'Meeting rooms',
  WORK_SPACE = 'Work desks',
  EQUIPMENT = 'Equipment',
  FOOD_AND_BEVERAGE = 'Food & Beverage',
  PACKAGES = 'Packages',
}

export interface IHeaderProfileContent {
  title: string | undefined;
  subtitle?: ReactNode | string;
  items?: string | number;
  content: ReactNode;
  gridWidth?: string;
  gap?: string;
  isUppercase?: boolean;
}

export enum EVenueProfileSections {
  IMAGES = 'Images',
  DESCRIPTION = 'Description',
  OPENING_HOURS = 'Opening hours',
  AMENITIES = 'Amenities',
  FLOORS = 'Floors',
  POINTS_OF_INTEREST = 'Points of interest',
}

export enum EVenueProfilePaymentSections {
  ACCOUNT_STATUS = 'Account status',
  BUSINESS_INFORMATION = 'Business information',
  OPERATING_EXECUTIVES = 'Operating Executives',
  PAYMENT_PROCESS = 'Payment process',
}

export enum EVenueProfileContactsSections {
  PRIMARY_CONTACT_INFO = 'Primary contact information',
  SOCIAL_MEDIA = 'Social media',
  DIRECTOR = 'Director',
  MANAGER = 'Manager',
  SALES_MANAGER = 'Sales manager',
  RESERVATIONS_MANAGER = 'Reservations manager',
  BANQUET_MANAGER = 'Banquet manager',
  MARKETING_MANAGER = 'Marketing manager',
  SECONDARY_CONTACT = 'Secondary contact',
  RECEPTION = 'Reception',
}

export enum EVenueProfileSpacesPages {
  MEETING_ROOMS = 'Meeting rooms',
  PUBLIC_AREAS = 'Public areas',
}

export enum EMenuItemSizes {
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export type TMenuItemSize = {
  [key in EMenuItemSizes]: {
    fontSize: string;
    lineHeight: string;
    marginBottom: string;
    padding: string;
    paddingActive: string;
    activeMarkWidth: string;
    activeMarkHeight: string;
    activeMarkLeftSpace: string;
  };
};

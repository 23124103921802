import {message} from 'antd';
import {useNavigate} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {useDispatch} from 'react-redux';

import DateUtils, {DateFormats, getISOString} from 'utils/dateUtils';
import {openSharePopup} from 'store/app/appSlice';
import {ERoomType} from 'types/dto/ERoomType.type';

type TUseShareVenue = {
  searchString?: string;
  checkInDate?: string;
  checkOutDate?: string;
  latitude?: string | number | null;
  longitude?: string | number | null;
  venueName?: string;
  venueId?: number;
  venueCoverImage?: string;
  title?: string;
  text?: string;
  url?: string;
  roomType?: ERoomType;
  unitId?: number;
};

const useShareVenue = ({
  checkInDate,
  checkOutDate,
  latitude = '',
  longitude = '',
  venueName,
  venueId,
  venueCoverImage = '',
  title,
  text,
  url,
  roomType,
  unitId,
}: TUseShareVenue) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bookingDate = DateUtils.getDateWithoutHoursFromBE(
    checkInDate as string,
    DateFormats['01 Jan 2023'],
  );
  const checkInTime = DateUtils.getHoursAndMinutesFromBE(checkInDate as string);
  const checkOutTime = DateUtils.getHoursAndMinutesFromBE(
    checkOutDate as string,
  );

  // TODO: Something goes wrong with using `getDateTime` method, migrate back to `DateUtils.normalizeDateToBackendFormat` when it will be solved
  const venueDetailsPageParams = `?latitude=${latitude}&longitude=${longitude}&limit=&page=&startDate=${encodeURIComponent(
    getISOString(checkOutDate),
  )}&endDate=${encodeURIComponent(
    getISOString(checkOutDate),
  )}&roomType=${roomType}&unitId=${unitId || ''}`;

  const defaultTitle = 'Join me working at this venue on NewWork!';
  const defaultText = `Take a look where I will work on ${bookingDate} from ${checkInTime} to ${checkOutTime}. Please join me at ${venueName} here:`;
  const defaultUrl = `${window.location.origin}/venue/${venueId}/details${venueDetailsPageParams}`;

  const navigateToVenue = () => {
    navigate(`/venue/${venueId}/details${venueDetailsPageParams}`);
  };

  const shareVenue = async () => {
    try {
      if (isMobile && (navigator as any).share) {
        await navigator.share({
          title: (title as string) || defaultTitle,
          text: (text as string) || defaultText,
          url: (url as string) || defaultUrl,
        });
      } else {
        dispatch(
          openSharePopup({
            id: 'SHARE_VENUE',
            props: {
              title: venueName as string,
              subject: title || defaultTitle,
              text: text || defaultText,
              url: url || defaultUrl,
              imageUrl: venueCoverImage,
            },
          }),
        );
      }
    } catch (error) {
      message.error({
        type: 'error',
        content: 'Oops! Something has gone wrong sharing content!',
        key: 'errorMessage',
      });
    }
  };

  return {
    shareVenue,
    navigateToVenue,
  };
};

export default useShareVenue;

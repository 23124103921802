import styled from 'styled-components';

import {
  fontSizeMd,
  lineHeightLg,
  offsetXSm,
  offsetLg,
  offsetXLg,
  NW2Gray100Color,
  NW2Gray900Color,
  NW2Info500Color,
  borderRadiusDef,
  xLgBp,
  NW2Gray200Color,
  lineHeightXLg,
} from 'constants/styleVars';

const PHOTO_WIDTH = '326px';
const PHOTO_HEIGHT = '260px';
const MAX_WIDTH = '1235px';
const DEFAULT_PHOTO_HEIGHT = '200px';

export const StyledDiv = styled.div`
  padding-bottom: 80px;
  border-bottom: 1px solid ${NW2Gray200Color};
`;

export const NoRoomsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 4px solid ${NW2Info500Color};
  border-radius: ${borderRadiusDef};
  background-color: ${NW2Gray100Color};

  @media (min-width: ${xLgBp}px) {
    flex-direction: row;
  }
`;

export const DesktopIconContainer = styled.div`
  margin-top: ${offsetXLg};
  margin-left: ${offsetXLg};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};
`;

export const NoSpaceTextContainer = styled.div`
  margin: ${offsetLg};
  flex: 1;
`;

export const NoSpaceText = styled.p`
  margin-top: ${offsetXSm};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
`;

export const ButtonContainer = styled.div`
  margin-top: ${offsetXLg};
`;

export const PhotoCover = styled.img`
  width: 100%;
  height: ${DEFAULT_PHOTO_HEIGHT};
  object-fit: cover;
  overflow: hidden;
  border-radius: 0 ${borderRadiusDef} ${borderRadiusDef} 0;
  max-width: 100%;

  @media (min-width: ${xLgBp}px) {
    width: ${PHOTO_WIDTH};
    height: ${PHOTO_HEIGHT};
    margin-left: ${lineHeightXLg};
  }

  @media (min-width: ${xLgBp}px) and (max-width: ${MAX_WIDTH}) {
    height: auto;
  }
`;

import {useParams} from 'react-router-dom';

const useGetUrlData = () => {
  const query = new URLSearchParams(location.search);
  const paramsVenueId: string | null = query.get('venueId');
  const {orderNumber: paramsOrderNumber} = useParams<Record<string, string>>();

  return {
    paramsVenueId,
    paramsOrderNumber,
  };
};

export default useGetUrlData;

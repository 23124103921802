import {css} from 'styled-components';

import {
  blackColor,
  borderRadiusLg,
  NW2Gray200Color,
  NW2Primary,
  offsetSm,
  whiteColor,
  lightBlackColor,
  NW2Gray300Color,
} from 'constants/styleVars';

export const datePickerDropdownWidth = '96px';
export const datePickerDropdownHeight = '214px';

export const datepickerDropdownContainer = css`
  box-shadow: 0 2px 6px -1px ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
`;

export const datepickerDropdown = css`
  height: ${datePickerDropdownHeight};
  background-color: ${whiteColor};
`;

export const datepickerDropdownItemSelected = css`
  color: ${blackColor};
  font-weight: normal;
  background-color: ${NW2Primary};
`;

export const datepickerDropdownItem = css`
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  padding: ${offsetSm};
  background-color: ${whiteColor};
  color: ${lightBlackColor};
  transition: background-color 0.15s;
  cursor: pointer;

  &:hover {
    background-color: ${NW2Primary};
  }

  & + li {
    box-shadow: 0 -1px 0 0 ${NW2Gray200Color};
  }
`;

// datepicker time overrides
export const reactDatepickerTimeOverrides = css`
  &__triangle {
    display: none;
  }

  &__time {
    &-container {
      .react-datepicker {
        &__header {
          display: none;
        }

        &__time {
          border-radius: ${borderRadiusLg};
        }
      }
    }

    .react-datepicker {
      &__time-box {
        width: ${datePickerDropdownWidth};

        ${datepickerDropdownContainer}

        ul.react-datepicker__time-list {
          ${datepickerDropdown}

          li.react-datepicker__time-list-item {
            ${datepickerDropdownItem}

            &--selected {
              ${datepickerDropdownItemSelected}
            }

            &--disabled {
              cursor: default;
              color: ${NW2Gray300Color};
              pointer-events: none;

              &:hover {
                background: none;
              }
            }
          }
        }
      }
    }
  }
`;

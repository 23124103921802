import React from 'react';

import {EEventType} from 'types/venue';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {Wrapper, StyledParagraph} from './AccommodationBlock.styles';

type TProps = {
  checkIn?: string;
  eventType: EEventType;
  index: number;
};

const PrePostDateBlock = ({checkIn, eventType, index}: TProps) => {
  const formattedDate = DateUtils.getDateWithoutHours(
    checkIn,
    DateFormats['1 Jan 2023'],
  );

  const id = `${
    eventType === EEventType.PRE_ARRIVAL ? 'Pre-arrival' : 'Post event'
  }_${index}`;

  return (
    <Wrapper id={id}>
      <StyledParagraph>
        {eventType === EEventType.PRE_ARRIVAL && 'Pre-arrival'}
        {eventType === EEventType.POST_EVENT && 'Post-event'}
      </StyledParagraph>
      <span>{formattedDate}</span>
    </Wrapper>
  );
};

export default PrePostDateBlock;

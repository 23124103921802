import React, {memo} from 'react';

import {useAppSelector} from 'store/hooks';
import Image from 'view/components/Image';
import MainImage from 'img/heroImage.webp';
import MainImageDesktop from 'img/heroImage_desktop.webp';
import {
  HeroImageContainer,
  SubTitle,
  TitleWrap,
  Title,
} from '../MainLanding.styles';

const mobileHeight = '425px';
const tabletHeight = '660px';
const desktopHeight = '456px';

const HeroImage = memo(() => {
  const isMobile: boolean = useAppSelector(({app}) => app.deviceType.isMobile);
  const isTablet: boolean = useAppSelector(({app}) => app.deviceType.isTablet);

  const getImageHeightAndSource = (isMobile: boolean, isTablet: boolean) => {
    switch (true) {
      case isMobile:
        return {height: mobileHeight, img: MainImage};
      case isTablet:
        return {height: tabletHeight, img: MainImage};
      default:
        return {height: desktopHeight, img: MainImageDesktop};
    }
  };

  const {height, img} = getImageHeightAndSource(isMobile, isTablet);

  return (
    <HeroImageContainer>
      <TitleWrap>
        <Title>HRS Connect</Title>
        <SubTitle>
          Book global meetings and
          <br />
          work spaces in one go.
        </SubTitle>
      </TitleWrap>
      <Image height={height} src={img} />
    </HeroImageContainer>
  );
});

export default HeroImage;

import React from 'react';
import styled from 'styled-components';

import NW2VenueDetailsMap from './components/NW2VenueDetailsMap/NW2VenueDetailsMap';
import NW2VenueDetailsOperationalHours from './components/NW2VenueDetailsOperationalHours';
import FullDescription from 'view/components/FullDescription/FullDescription';

import {StyledH6} from 'view/components/Typography';
import {
  mdBp,
  lgBp,
  fontSizeSm,
  fontSizeDefault,
  offsetXLg,
  offsetXXXXLg,
  offsetXXXXXLg,
} from 'constants/styleVars';
import {
  venueDescriptionTitleText,
  venueMapTitleText,
  venueDetailsOperationalHoursText,
} from './constants';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const RowOne = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${lgBp}px) {
    flex-direction: row;
  }
`;

const RowTwo = styled(RowOne)`
  @media (min-width: ${mdBp}px) {
    flex-direction: row;
  }
`;

const Cell = styled.div`
  width: 100%;
  margin-bottom: ${offsetXXXXLg};

  @media (min-width: ${mdBp}px) {
    margin-bottom: ${offsetXXXXXLg};
  }

  @media (min-width: ${lgBp}px) {
    margin-bottom: ${offsetXXXXLg};
    &:first-child {
      padding-right: ${offsetXLg};
    }
  }
`;

const StyledCell = styled(Cell)`
  margin-bottom: 0;

  @media (min-width: ${mdBp}px) {
    margin-bottom: 0;
  }
`;

const Title = styled(StyledH6)`
  font-size: ${fontSizeDefault};
  margin-bottom: ${offsetXLg};
`;

export function VenueDetailsBlock() {
  const {venueDetails} = useVenueDetailsData();

  return (
    <>
      <RowOne>
        <Cell>
          <Title>{venueDescriptionTitleText}</Title>
          <FullDescription
            fontSize={fontSizeSm}
            description={venueDetails.description}
            numberOfLines={6}
          />
        </Cell>
        <Cell>
          <Title>{venueMapTitleText}</Title>
          <NW2VenueDetailsMap />
        </Cell>
      </RowOne>
      <RowTwo>
        <StyledCell>
          <Title>{venueDetailsOperationalHoursText}</Title>
          <NW2VenueDetailsOperationalHours />
        </StyledCell>
      </RowTwo>
    </>
  );
}

import React from 'react';

import InputBlock from 'view/components/NW2SearchSection/components/SearchForm/components/InputBlock/InputBlock';
import {
  NW2TimePickerInline,
  useTimeRangeValidation,
} from 'view/components/NW2Datepicker';

import {ESearchInputsId} from 'types/search';
import {TDateType} from 'types/dto/ISearch.types';

import {StyledTimeRange} from '../NW2DatepickerDetails.styles';

type TProps = {
  inputBlockColor: string | undefined;
  focusedInput: string;
  onTimeStartChange: (payload: TDateType) => void;
  onTimeEndChange: (payload: TDateType) => void;
  onInputFocus: (inputId: string) => void;
  onInputBlur: () => void;
  timeStart: TDateType;
  timeEnd: TDateType;
  isMobile: boolean;
  isChangeActivated: boolean;
};

const TimeRangePickerSection = ({
  inputBlockColor,
  focusedInput,
  timeStart,
  timeEnd,
  onTimeStartChange,
  onTimeEndChange,
  onInputFocus,
  onInputBlur,
  isMobile,
  isChangeActivated,
}: TProps) => {
  const isDisabled = !isMobile && !isChangeActivated;

  const {invalidStartValues, invalidEndValues} = useTimeRangeValidation({
    valueStart: timeStart,
    valueEnd: timeEnd,
  });

  const onFocusStartTime = () => {
    onInputFocus(ESearchInputsId.DATE_START_ID);
  };

  const onFocusEndTime = () => {
    onInputFocus(ESearchInputsId.DATE_END_ID);
  };

  const isFocusedStartTime = focusedInput === ESearchInputsId.DATE_START_ID;
  const isFocusedEndTime = focusedInput === ESearchInputsId.DATE_END_ID;

  return (
    <StyledTimeRange>
      <InputBlock
        htmlFor={ESearchInputsId.DATE_START_ID}
        color={inputBlockColor}
        labelText='Start'
        isFocused={isFocusedStartTime}
        isNoBorder
        isNoMargin
        isNoPadding
        isWidthAuto
      >
        <NW2TimePickerInline
          selected={timeStart}
          onChange={onTimeStartChange}
          onFocus={onFocusStartTime}
          onBlur={onInputBlur}
          disabled={isDisabled}
          excludeTimes={invalidStartValues}
        />
      </InputBlock>

      <InputBlock
        htmlFor={ESearchInputsId.DATE_END_ID}
        color={inputBlockColor}
        labelText='End'
        isFocused={isFocusedEndTime}
        isNoBorder
        isNoMargin
        isNoPadding
        isWidthAuto
      >
        <NW2TimePickerInline
          selected={timeEnd}
          onChange={onTimeEndChange}
          onFocus={onFocusEndTime}
          onBlur={onInputBlur}
          disabled={isDisabled}
          excludeTimes={invalidEndValues}
        />
      </InputBlock>
    </StyledTimeRange>
  );
};

export default TimeRangePickerSection;

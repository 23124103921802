import React from 'react';

import ItemTitle from './ItemTitle';
import ItemFormTitle from './ItemFormTitle';

import {ItemWrapper, Content} from './LeftSideContainer.styles';
import {IBookPreviewComponent} from 'types/venue';

type TProps = {
  item: IBookPreviewComponent;
  isHidden?: boolean;
  subtitleColor?: string;
  className?: string; // styled components needs it here
};

const LeftSideItem = ({item, isHidden, className, subtitleColor}: TProps) => {
  const {title, subtitle, children, formValidationName} = item;

  if (isHidden) return null;

  return (
    <ItemWrapper className={className}>
      {formValidationName ? (
        <ItemFormTitle
          title={title}
          subtitle={subtitle}
          formValidationName={formValidationName}
        />
      ) : (
        <ItemTitle
          title={title}
          subtitle={subtitle}
          subtitleColor={subtitleColor}
        />
      )}
      <Content>{children}</Content>
    </ItemWrapper>
  );
};

export default LeftSideItem;

import {Auth, Hub} from 'aws-amplify';

import store from 'store/config';

import {setIsAuthConfigured} from 'store/app/appSlice';
import {loginByJwtToken} from 'store/app/apiActions';
import {EAppEnvironment} from 'types/app';

const COGNITO_DEV_CONFIG = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_CORWTOiO7',
  userPoolWebClientId: 'tdgjsjkfitohmrpdvo652mh53',
};
const COGNITO_IUT_CONFIG = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_uLUV6IUg6',
  userPoolWebClientId: '640iaeoj8oi4q2g1uv97i5oa6k',
};
const COGNITO_PROD_CONFIG = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_tnopHRnxg',
  userPoolWebClientId: '3mujf74sp3qapeebehe3mu3fp2',
};

export const authConfig: Record<EAppEnvironment, Record<string, string>> = {
  [EAppEnvironment.DEV]: COGNITO_DEV_CONFIG,
  [EAppEnvironment.DEMO]: COGNITO_DEV_CONFIG,
  [EAppEnvironment.QA]: COGNITO_DEV_CONFIG,
  [EAppEnvironment.FEATURE]: COGNITO_DEV_CONFIG,
  [EAppEnvironment.NMM_DEV]: COGNITO_DEV_CONFIG,
  [EAppEnvironment.STG]: COGNITO_IUT_CONFIG,
  [EAppEnvironment.PROD]: COGNITO_PROD_CONFIG,
};

export function listener(data: any) {
  if (data.payload.event === 'configured') {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        store.dispatch(loginByJwtToken(jwtToken));
      })
      .catch(() => {
        store.dispatch(setIsAuthConfigured(true));
      });
  }
}

export function initAuthentication(env: EAppEnvironment | null): void {
  if (!env) return;

  Hub.listen('auth', listener);
  Auth.configure(authConfig[env]);
}

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form} from 'react-final-form';
import {notification} from 'antd';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button/NW2Button';
import CustomList from 'view/components/CustomList';
import AuthorizationSuccessModal from 'view/auth/AuthorizationSuccessModal';
import {NW2FormItemInput} from '../NW2FormItem/NW2FormItem';
import {StyledH6, StyledSpan} from 'view/components/Typography';

import {useQuery} from 'hooks/useQuery';
import {isPasswordValid} from 'infra/common/venueValidation';
import {IForgetPassword} from 'types/dto/IResetPassword.type';
import {setOpenPasswordPopup} from 'store/app/appSlice';
import {EOpenPasswordModalTypes, TOpenPasswordModalProps} from 'types/app';
import {substringBetween} from 'utils/stringUtils';
import {
  StyledHelpTextContainer,
  list,
  ChangePasswordSuccessModal,
  EndRegistrationSuccessModal,
} from './helper';
import {
  StyledNW2ButtonLink,
  StyledTextValue,
  TitleContainer,
} from 'view/auth/utils/auth.styles';
import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {fontSizeXSm, fontWeightBold, offsetXSm} from 'constants/styleVars';
import {Routes} from 'constants/routes';
import {TSubmitConfirmForgotPassword} from 'view/auth/utils/useForgotPassword';

type TProps = {
  moveToSuccessPagePath?: string;
  request: (data: TSubmitConfirmForgotPassword) => void;
  isMobile?: boolean;
};

export function Nw2EnterPassword({
  request,
  pageName,
  btnOkText,
  type,
  isMobile = false,
  notificationMessage,
  moveToSuccessPagePath,
}: TProps & TOpenPasswordModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const url = window.location.href;
  const code = substringBetween(url, 'code=', '&role');
  const {email} = useQuery();

  const [isLoading, setIsLoading] = useState(false);

  const customerEmail: string = useAppSelector(({app}) => app.user.email) || '';

  const onClose = () => {
    dispatch(setOpenPasswordPopup({openPasswordPopup: false}));
    navigate({pathname: Routes.mainLanding, search: ''});
  };

  const onSubmit = async (values: Record<string, string>) => {
    const {firstPassword, secondPassword} = values;
    if (!isPasswordValid(firstPassword)) {
      const notificationOptions = {
        message:
          'First password must have one letter, one Upper letter, one number, one special symbol and has 8 symbols',
      };
      return notification.error(notificationOptions);
    }

    if (!isPasswordValid(secondPassword)) {
      const notificationOptions = {
        message:
          'Second password must have one letter, one Upper letter, one number, one special symbol and has 8 symbols',
      };
      return notification.error(notificationOptions);
    }

    if (firstPassword !== secondPassword) {
      const notificationOptions = {
        message: 'The first password does not match the second one',
      };
      return notification.error(notificationOptions);
    }

    setIsLoading(true);
    const resetPasswordData: IForgetPassword = {
      email: decodeURI(email as string),
      code,
      newPassword: secondPassword,
    };

    const successHandler = () => {
      if (moveToSuccessPagePath) navigate(moveToSuccessPagePath);
      if (isMobile) {
        notification.success({
          message: notificationMessage,
        });
      }
    };

    request({
      ...resetPasswordData,
      callback: successHandler,
      finallyCallback: () => setIsLoading(false),
    });
  };

  if (customerEmail) {
    const successModal = {
      [EOpenPasswordModalTypes.REGISTER]: {
        ...EndRegistrationSuccessModal,
        closeModal: onClose,
      },
      [EOpenPasswordModalTypes.MODIFY]: {
        ...ChangePasswordSuccessModal,
        closeModal: onClose,
      },
    };

    return <AuthorizationSuccessModal {...successModal[type]} />;
  }

  return (
    <>
      <TitleContainer>
        <StyledH6>{pageName}</StyledH6>
      </TitleContainer>
      <StyledTextValue>Please type a new password below.</StyledTextValue>
      <Form onSubmit={onSubmit}>
        {({handleSubmit}) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormGroup columnNumber={1} gap={16}>
                <NW2FormItemInput
                  name='firstPassword'
                  placeholder='First password'
                  label='First password'
                  type='password'
                />
                <NW2FormItemInput
                  name='secondPassword'
                  placeholder='Repeat password'
                  label='Repeat password'
                  type='password'
                />
              </FormGroup>
              <StyledHelpTextContainer>
                <StyledSpan fontWeight={fontWeightBold} fontSize={fontSizeXSm}>
                  Build a strong Password:
                </StyledSpan>
                <CustomList
                  offset={offsetXSm}
                  size='small'
                  list={list}
                  listStyle='ordered'
                />
                <NW2Button
                  loading={isLoading}
                  buttonType='primary'
                  type='submit'
                  fullWidth
                >
                  {isLoading ? 'Loading...' : btnOkText}
                </NW2Button>
                <StyledNW2ButtonLink
                  buttonType='link'
                  inverted
                  inline
                  onClick={onClose}
                >
                  Cancel
                </StyledNW2ButtonLink>
              </StyledHelpTextContainer>
            </form>
          );
        }}
      </Form>
    </>
  );
}

import React, {useState} from 'react';

import {
  ArrowButton,
  ItemContent,
  ItemHead,
  ItemWrapper,
  Name,
  SpacesContainer,
  SpaceItem,
} from './FloorsViewItem.styles';
import {ERoomType} from 'types/dto/ERoomType.type';
import {SpaceInfo} from '../SpaceItem/SpaceItem';
import {IFloorInfo} from 'types/venue';
import {useFloorUnits} from '../../hooks/useFloorUnits';

interface IProps {
  floorNumber: number;
  units: IFloorInfo;
}

export const FloorViewItem = ({floorNumber, units}: IProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const {
    floorName,
    hasSpaces,
    meetingRoomsCount,
    meetingSpaces,
    workDesksCount,
    workDesksSpaces,
  } = useFloorUnits({units, floorNumber});

  const collapseHandler = () => setIsCollapsed(!isCollapsed);

  return (
    <ItemWrapper data-testid='floor-view-item'>
      <ItemHead>
        <Name>{floorName}</Name>
        <SpacesContainer hasSpaces={hasSpaces}>
          {hasSpaces && (
            <>
              {!!workDesksCount && (
                <SpaceItem data-testid={`workdesks-count-${floorName}`}>
                  Workdesks: {workDesksCount}
                </SpaceItem>
              )}
              {!!meetingRoomsCount && (
                <SpaceItem data-testid={`meetingrooms-count-${floorName}`}>
                  Meeting rooms: {meetingRoomsCount}
                </SpaceItem>
              )}
            </>
          )}
        </SpacesContainer>
        {hasSpaces && (
          <ArrowButton
            isCollapsed={isCollapsed}
            icon='NW2_ARROW_CIRCLE'
            data-testid={`arrow-button-${floorName}`}
            clickable
            onClick={collapseHandler}
          />
        )}
      </ItemHead>
      {!isCollapsed && (
        <ItemContent>
          {!!workDesksCount && (
            <SpaceInfo units={workDesksSpaces} type={ERoomType.WORK_SPACE} />
          )}
          {!!meetingRoomsCount && (
            <SpaceInfo units={meetingSpaces} type={ERoomType.MEETING_ROOM} />
          )}
        </ItemContent>
      )}
    </ItemWrapper>
  );
};

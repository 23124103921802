import React from 'react';

import {IInputProps} from '../../types';

import {StyledInput} from '../../NW2FormItem.styles';

const InputText = ({
  name,
  type,
  placeholder,
  placeholderIcon,
  hasError,
  inputProps,
  focused,
  inputSize,
  ...restInputProps
}: IInputProps) => {
  return (
    <StyledInput
      {...inputProps}
      name={name}
      type={type}
      placeholder={placeholder}
      placeholderIcon={placeholderIcon}
      hasError={hasError}
      focused={focused}
      inputSize={inputSize}
      {...restInputProps}
    />
  );
};

export default InputText;

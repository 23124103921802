import {useLayoutEffect, useState, useCallback, RefObject} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export interface ResizeObserverEntry {
  target: Element;
  contentRect: DOMRectReadOnly;
}

/**
 * get element sizes hook
 */

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: DOMRectReadOnly) => void,
): {width: number; height: number} => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback],
  );

  useLayoutEffect(() => {
    if (!ref?.current) {
      return;
    }

    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) =>
      handleResize(entries),
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [handleResize, ref?.current]);

  return {width, height};
};

import React from 'react';
import styled, {css} from 'styled-components';
import {useDispatch} from 'react-redux';

import CurrentLocation from './CurrentLocation';
import Prediction from './Prediction';

import {
  borderRadiusLg,
  googleMapPredictionsDropdownZIndex,
  offsetXSm,
  offsetXXSm,
  whiteColor,
  googleMapSearchPredictionsMinWidth,
  borderColor,
  offsetDef,
  sectionBoxShadow,
} from 'constants/styleVars';
// import {getGeocodeAddressData} from 'utils/googleMapUtils';
import {searchPredictionLoading} from 'store/search/searchSlice';
import {TGeocodeAddressData, TPrediction} from 'types/googleMap';
import {getGeocodeAddressData} from 'utils/googleMapUtils';
import {SEARCH_FORM_LEFT_PADDING} from '../../../constants';

type PropsType = {
  predictions: TPrediction[];
  onSearch: (data: TGeocodeAddressData) => void;
  isInModal: boolean;
  className?: string;
};

const Container = styled.div<{isInModal: boolean}>`
  ${({isInModal}) =>
    isInModal
      ? css`
          min-width: ${googleMapSearchPredictionsMinWidth};
          background-color: ${whiteColor};
          padding: 0;
          margin-top: ${offsetDef};
          border-top: 1px solid ${borderColor};
        `
      : css`
          position: absolute;
          top: 200%; // place dropdown below input block
          left: -${SEARCH_FORM_LEFT_PADDING};
          min-width: ${googleMapSearchPredictionsMinWidth};
          background-color: ${whiteColor};
          border-radius: ${borderRadiusLg};
          padding: 0 ${offsetXSm} ${offsetXXSm};
          z-index: ${googleMapPredictionsDropdownZIndex};
          box-shadow: ${sectionBoxShadow};
        `}
`;

const Predictions = ({
  predictions,
  onSearch,
  isInModal,
  className,
}: PropsType) => {
  const dispatch = useDispatch();

  const onClick = (prediction: TPrediction) => async () => {
    dispatch(searchPredictionLoading(prediction.place_id));

    const address = prediction.description;
    const geocodeFromAddress = await getGeocodeAddressData(address);

    onSearch({
      latitude: geocodeFromAddress.latitude,
      longitude: geocodeFromAddress.longitude,
      address,
      addressComponents: geocodeFromAddress.addressComponents,
    });

    dispatch(searchPredictionLoading(''));
  };

  return (
    <Container isInModal={isInModal} className={className}>
      <CurrentLocation onSearch={onSearch} />
      {predictions?.length > 0
        ? predictions.map((prediction: TPrediction) => (
            <Prediction
              key={prediction.description}
              prediction={prediction}
              onClick={onClick(prediction)}
            />
          ))
        : null}
    </Container>
  );
};

export default Predictions;

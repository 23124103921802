import styled, {css} from 'styled-components';

import {
  borderRadiusDef,
  fontWeightBold,
  fontWeightExtraBold,
  NW2Gray200Color,
  NW2Gray600Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';
import {NavLink} from 'react-router-dom';

const borderStyle = `1px solid ${NW2Gray200Color}`;

export const ItemWrapper = styled.div`
  border: ${borderStyle};
  border-radius: ${borderRadiusDef};
  margin-bottom: ${offsetDef};

  &:last-of-type {
    margin-bottom: ${offsetXLg};
  }
`;

export const ItemHead = styled.div<{hasAnyUnits: boolean}>`
  padding: ${offsetDef} ${offsetXLg};
  display: grid;

  grid-gap: ${offsetXXLg};
  grid-template-columns: 2fr 5fr ${({hasAnyUnits}) =>
      hasAnyUnits ? '123px' : '96px'};
`;

export const Name = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
`;

export const SpacesContainer = styled.div<{hasSpaces: boolean}>`
  display: flex;
  gap: ${offsetXLg};
  font-weight: ${fontWeightBold};
  color: ${NW2Gray600Color};

  ${({hasSpaces}) =>
    hasSpaces &&
    css`
      padding-left: ${offsetXXLg};
      border-left: 1px solid ${NW2Gray200Color};
    `}
`;

export const SpaceItem = styled.div`
  width: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledNavLink = styled(NavLink)`
  display: block;
  font-weight: ${fontWeightBold};
  color: inherit;
  text-decoration: underline;
  margin-top: ${offsetDef};

  &:hover {
    color: inherit;
  }
`;

export enum ENW2PopoverDrawerPlacement {
  TOP_LEFT = 'topLeft',
  TOP = 'top',
  TOP_RIGHT = 'topRight',
  LEFT_TOP = 'leftTop',
  LEFT = 'left',
  LEFT_BOTTOM = 'leftBottom',
  RIGHT_TOP = 'rightTop',
  RIGHT = 'right',
  RIGHT_BOTTOM = 'rightBottom',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM = 'bottom',
  BOTTOM_RIGHT = 'bottomRight',
}

import React from 'react';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  AuthModalFooter,
  ModalContainer,
  StyledTextValue,
} from 'view/auth/utils/auth.styles';
import {StyledH6} from 'view/components/Typography';

type TProps = {
  title: string;
  body: string | JSX.Element;
  closeButtonText: string;
  footerBlock: JSX.Element;
  closeModal: () => void;
};

function AuthorizationSuccessModal({
  title,
  body,
  closeButtonText,
  footerBlock,
  closeModal,
}: Partial<TProps>) {
  return (
    <>
      <ModalContainer>
        {title && <StyledH6>{title}</StyledH6>}
        {body && <StyledTextValue>{body}</StyledTextValue>}
        {closeButtonText && (
          <NW2Button fullWidth buttonType='primary' onClick={closeModal}>
            {closeButtonText}
          </NW2Button>
        )}
      </ModalContainer>
      {footerBlock && <AuthModalFooter>{footerBlock}</AuthModalFooter>}
    </>
  );
}

export default AuthorizationSuccessModal;

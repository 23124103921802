import {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';

import useToggle from 'hooks/useToggle';
import {bookingsContainer} from 'app';
import {ISearchOrder} from 'types/bookingOrders';

export interface IRfpHandlers {
  activeModal: string;
  handleConfirm: (orderId: string) => void;
  handleDecline: <TPayload>(payload: TPayload) => void;
  isConfirmShowed: boolean;
  isDeclineShowed: boolean;
  isSending: boolean;
  toggleShowConfirm: () => void;
  toggleShowDecline: () => void;
  onShowConfirmModal: (orderId: string) => (e?: SyntheticEvent) => void;
  onShowDeclineModal: (orderId: string) => (e?: SyntheticEvent) => void;
}

export function useRfpHandlers({
  orders,
  getOrders,
  onCloseOrderDetails,
}: {
  orders?: ISearchOrder[];
  getOrders: () => void;
  onCloseOrderDetails: () => void;
}): IRfpHandlers {
  const location = useLocation();

  const [activeModal, setActiveModal] = useState('');
  const [isConfirmShowed, toggleShowConfirm] = useToggle(false);
  const [isDeclineShowed, toggleShowDecline] = useToggle(false);
  const [isSending, setSending] = useState(false);

  const handleSuccess = useCallback(() => {
    getOrders();
    setSending(false);
    setActiveModal('');
    onCloseOrderDetails();

    setTimeout(() => {
      document.body.style.overflow = '';
    }, 1000);
  }, [getOrders, onCloseOrderDetails]);

  const handleConfirm = useCallback(
    async (orderId: string) => {
      setSending(true);

      await bookingsContainer.confirmSupplierOrder({
        payload: orderId,
        onSuccess: () => {
          handleSuccess();
          toggleShowConfirm();
        },
        onError: () => {
          setSending(false);
        },
      });
    },
    [handleSuccess, toggleShowConfirm],
  );

  const handleDecline = useCallback(
    async <TPayload>(payload: TPayload) => {
      setSending(true);

      await bookingsContainer.declineSupplierOrder({
        payload,
        onSuccess: () => {
          handleSuccess();
          toggleShowDecline();
        },
        onError: () => {
          setSending(false);
        },
      });
    },
    [handleSuccess, toggleShowDecline],
  );

  const handleShowConfirm = useCallback(
    (orderId: string) => {
      setActiveModal(orderId);
      toggleShowConfirm();
    },
    [toggleShowConfirm],
  );

  const handleShowDecline = useCallback(
    (orderId: string) => {
      setActiveModal(orderId);
      toggleShowDecline();
    },
    [toggleShowDecline],
  );

  const onShowConfirmModal = useCallback(
    (orderId: string) => (e?: SyntheticEvent) => {
      // prevent conflict click events between clicks for row & button inside row
      e?.stopPropagation();
      handleShowConfirm(orderId);
    },
    [handleShowConfirm],
  );

  const onShowDeclineModal = useCallback(
    (orderId: string) => (e?: SyntheticEvent) => {
      // prevent conflict click events between clicks for row & button inside row
      e?.stopPropagation();
      handleShowDecline(orderId);
    },
    [handleShowDecline],
  );

  // open confirm/decline modals from the email link
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  useEffect(() => {
    const isRfp = searchParams.get('rfp');
    const orderId = searchParams.get('orderId');

    if (orders?.find((order) => order.orderId === orderId) && isRfp) {
      const isConfirm = searchParams.get('confirm');
      const isDecline = searchParams.get('decline');

      if (isConfirm) {
        handleShowConfirm(orderId as string);
      }

      if (isDecline) {
        handleShowDecline(orderId as string);
      }
    }
  }, [handleShowConfirm, handleShowDecline, orders, searchParams]);

  return {
    activeModal,
    handleConfirm,
    handleDecline,
    isConfirmShowed,
    isDeclineShowed,
    isSending,
    toggleShowConfirm,
    toggleShowDecline,
    onShowConfirmModal,
    onShowDeclineModal,
  };
}

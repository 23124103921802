import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import SupplierTermsAndPolicyBlock from 'view/common/TermsAndPolicyBlock';
import Icon from 'view/components/Icon';
import DateUtils from 'utils/dateUtils';

import {Link} from 'view/components/Typography';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {useAppSelector} from 'store/hooks';
import {useOpenTermsAndConditionsModal} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';

import {TERMS_OWNER} from 'types/bookingOrders';
import {TPolicyDocument} from 'types/dto/IBooking.types';

import {PageContent} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {HowLongBox, PromoText, PromoTitle} from '../OfferCreate.styles';
import {useIsVenueFromUSA} from '../../../../hooks/useIsUSA';

export function LastStep() {
  const date = useAppSelector(({offers}) => offers.offerDetails.optionDate);

  const cancellationPolicy = useAppSelector(
    ({offers}) => offers.offerDetails.cancellationPolicy,
  );

  const supplierAttachedCancellationPolicyFile = useAppSelector(({offers}) =>
    offers.supplierCancellationPolicy && offers.cancellationPolicyFile
      ? ({
          type: TERMS_OWNER.SUPPLIER,
          id: offers.cancellationPolicyFileBlobPreview,
        } as TPolicyDocument)
      : null,
  );

  const [isUSA] = useIsVenueFromUSA();

  const termsAndConditionsId = useAppSelector(
    ({offers}) => offers.offerDetails.termsAndConditionsId,
  );

  const requestNumber = useAppSelector(
    ({offers}) => offers.offerDetails.number,
  );
  const venueZone: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.timeZone`),
  );
  const locale = useAppSelector(({app}) => app.locale);

  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();

  return (
    <PageContent>
      <PromoTitle>How long would an offer be valid?</PromoTitle>
      <HowLongBox>
        <Icon icon='TRIANGLE_WARN' />
        <span>
          If you decide to submit the offer, the booker will have until{' '}
          <b>
            {DateUtils.getFormattedDayMonthTime({
              date,
              venueZone,
              locale,
            })}
          </b>{' '}
          to finalise their decision.
        </span>
      </HowLongBox>
      <SupplierTermsAndPolicyBlock
        cancellationPolicy={
          supplierAttachedCancellationPolicyFile || cancellationPolicy
        }
        isBlob={Boolean(supplierAttachedCancellationPolicyFile)}
        isUSA={isUSA}
        termsAndConditionsId={termsAndConditionsId}
        cancellationPolicyProps={{requestNumber}}
        isSmallText
      />
      <PromoTitle>How is booker charged?</PromoTitle>
      <PromoText>
        We defer to the venue to arrange a payment method directly with the
        booker. Once this process is finalised, you may utilise the provided
        billing address to generate an invoice.
      </PromoText>

      <PromoTitle>What if there are additional charges?</PromoTitle>
      <PromoText>
        Only extras which are not part of this booking can, and should be,
        charged on-site.
      </PromoText>

      <PromoTitle>What’s HRS Connect commission?</PromoTitle>
      <PromoText>
        HRS Connect applies commission on meeting and accommodation bookings.
        For full information refer to our{' '}
        <Link onClick={openTermsAndConditionsModal} black>
          Terms & Conditions.
        </Link>
      </PromoText>
    </PageContent>
  );
}

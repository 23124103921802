import React, {memo} from 'react';
import styled from 'styled-components';

import {useSortedAmenities} from './useSortedAmenities';
import {
  fontSizeSm,
  fontSizeXSm,
  lineHeightMd,
  offsetDef,
  offsetXSm,
  secondaryColor,
} from 'constants/styleVars';
import {IAmenity} from 'types/dto/IAmenity';
import {EAmenitiesCategories} from 'types/venue';
import {amenitiesCategoriesMap} from './types';

const Group = styled.div`
  display: grid;
`;

const Title = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightMd};
  color: ${secondaryColor};
  margin-top: ${offsetDef};
`;

const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetDef};
`;

const Item = styled.span`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  margin-bottom: ${offsetXSm};
`;

interface IProps {
  amenities: IAmenity[];
}

export const NW2AmenitiesList = memo(({amenities}: IProps) => {
  const sortedAmenities = useSortedAmenities({amenities});

  const filteredAmenities = Object.entries(sortedAmenities).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, val]) => val.length,
  );

  return (
    <>
      {filteredAmenities.map(([key, val]) => (
        <Group key={key}>
          <Title>{amenitiesCategoriesMap[key as EAmenitiesCategories]}</Title>
          <Items>
            {val.map(({label}) => (
              <Item key={label}>{label}</Item>
            ))}
          </Items>
        </Group>
      ))}
    </>
  );
});

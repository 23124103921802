import React from 'react';
import ImageGallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from 'react-image-gallery';

import Image from 'view/components/Image';

type TProps = {
  imageGalleryItems: ReadonlyArray<
    ReactImageGalleryItem & {
      text?: string | JSX.Element;
    }
  >;
  imageMaxHeight?: number;
  imageBorderRadius?: string;
  startIndex?: number;
};

function Slider({
  imageGalleryItems,
  startIndex = 0,
  ...rest
}: TProps & Partial<ReactImageGalleryProps>) {
  if (!imageGalleryItems.length) return null;

  const items = imageGalleryItems.map(({text, ...item}) => ({
    ...item,
    renderItem() {
      return (
        <>
          <Image
            alt={item.originalAlt}
            src={item.original}
            maxHeight={rest.imageMaxHeight}
            borderRadius={rest.imageBorderRadius}
          />
          {text}
        </>
      );
    },
  }));

  return (
    <ImageGallery
      items={items}
      showPlayButton={false}
      showIndex
      showThumbnails={false}
      showFullscreenButton={false}
      startIndex={startIndex}
      {...rest}
    />
  );
}

export default Slider;

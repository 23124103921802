import styled from 'styled-components';
import {offsetSm} from 'constants/styleVars';

export const TopNotificationContainer = styled.div<{background?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${offsetSm};
  background: ${({background}) =>
    background || 'linear-gradient(0deg, #fff8ec, #fff8ec), #ffffff'};
`;

export default TopNotificationContainer;

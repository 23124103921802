import {TUploadFile} from '../../view/components/Uploader/types';

export interface IGetCorporateAccountCompaniesPayload {
  page: number;
  size: number;
}

export interface ICorporateAccountCompany {
  id: number;
  name: string;
  totalUsers: number;
  emailDomains: string[];
}

export interface IAddCorporateAccountCompanyPayload {
  id: number;
  name: string;
  emailDomains: string[];
}

export interface IUpdateCorporateAccountCompanyPayload
  extends IAddCorporateAccountCompanyPayload {
  totalUsers: number;
}

export interface IGetCorporateAccountCompanyUsersPayload {
  id: number;
  page: number;
  size: number;
}

export interface IGetUnassignedUsersPayload {
  page: number;
  size: number;
}

export interface ICorporateAccountCompanyUser {
  userId: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userPhone: string;
  corporateAccountId: number;
  isHighlighted?: boolean;
}

export interface ICorporateAccountTableSource
  extends ICorporateAccountCompanyUser {
  companies: ICorporateAccountCompanyForSelectCompany[];
}

export interface IGetCorporateAccountCompanyUsersResponse {
  totalFilteredRecords: number;
  pageItems: ICorporateAccountCompanyUser[];
  size: number;
}

export interface IAddCorporateAccountPropertyManagerPayload {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  accountId: number;
  userId?: number;
}

export interface IEditCorporateAccountCompanyUserPayload {
  firstName: string;
  lastName: string;
  phone: string;
  accountId: number;
  userId: number;
}

export interface IReassignCorporateAccountCompanyUserPayload {
  userId: number;
  currentAccountId: number;
  newAccountId: number;
}

export interface IUnassignCorporateAccountCompanyUserPayload {
  corporateAccountId: number;
  userId: number;
}

export interface ICorporateAccountCompanyForSelectCompany {
  id: number;
  name: string;
}

export enum EAnnouncementsVisibilityAreas {
  PUBLIC = 'PUBLIC',
  CORPORATE = 'CORPORATE',
}

export interface IAnnouncementRequest {
  visibilityAreas: Array<keyof typeof EAnnouncementsVisibilityAreas>;
  title: string;
  subtitle: string;
  body: string;
  isActive: boolean;
}

export interface IEditAnnouncementRequest extends IAnnouncementRequest {
  id: number;
}

export interface IAnnouncementRespond extends IEditAnnouncementRequest {
  dateCreated: string;
  dateUpdated: string;
}

export interface ITermsAndConditionUploadRequest {
  file: TUploadFile;
  fkey: number;
  handleProgress: (percent: number) => void;
}

export interface ICancellationPolicyToOrderUploadRequest {
  file: TUploadFile;
  orderNumber: string;
}

export interface IDocumentsFileResponse {
  fkey: number;
  id: string;
  fileName: string;
  validFrom: string;
  validTo: null;
}

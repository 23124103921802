import React from 'react';
import styled from 'styled-components';

import {
  fontWeightExtraBold,
  NW2Gray900Color,
  offsetXSm,
} from 'constants/styleVars';
import {StyledSpan} from 'view/components/Typography';

const Header = styled.div`
  text-align: center;
  padding: ${offsetXSm};
  text-transform: uppercase;
  background-color: ${NW2Gray900Color};
`;

function HeaderSection() {
  return (
    <Header>
      <StyledSpan fontWeight={fontWeightExtraBold}>summary</StyledSpan>
    </Header>
  );
}

export default HeaderSection;

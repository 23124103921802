import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {sharedHeadingStyle} from 'view/components/Typography/Typography';
import {
  offsetXSm,
  NW2Gray800Color,
  offsetSm,
  fontSizeMd,
  lineHeightLg,
  offsetXXSm,
  offsetXXXXLg,
  offsetXXLg,
  offsetXLg,
} from 'constants/styleVars';

export const MainWrapper = styled.div`
  display: flex;
  gap: 19px;
  margin: ${offsetXXLg} 0;
`;

export const Wrapper = styled.div`
  min-width: 100px;
`;

export const StyledParagraph = styled.p`
  ${sharedHeadingStyle};
  display: block;
  margin-bottom: ${offsetXSm};
`;

export const Container = styled.div`
  border: 1px solid rgb(221, 225, 230);
  border-radius: ${offsetSm};
  width: 100%;
  padding: ${offsetXLg};
`;

export const StyledBox = styled.div`
  height: ${offsetXXXXLg};
  padding: ${offsetSm};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  border-radius: ${offsetXXSm};
  background: ${NW2Gray800Color};
`;

export const AccommodationString = styled.div`
  display: block;
  margin-bottom: ${offsetXXSm};
`;

export const StyledSpan = styled.span`
  margin: 0 ${offsetXXSm};
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXSm};
  vertical-align: middle;
`;

// export const BedroomsBox = styled.div<{noTopMargin?: boolean}>`
//   padding-top: ${({noTopMargin}) => !noTopMargin && offsetXLg};
// `;

export const CardBox = styled.div`
  display: block;

  & + * {
    margin-top: 24px;
  }
`;

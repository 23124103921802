import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {
  borderRadiusLg,
  fontWeightBold,
  lighterBlueColor,
  offsetSm,
} from 'constants/styleVars';

type TProps = {
  bgColor?: string;
  text: ReactNode;
  icon: ReactNode;
};

const StyledCard = styled.div<{bgColor?: string}>`
  display: flex;
  align-items: flex-start;
  padding: ${offsetSm};
  background-color: ${({bgColor}) => bgColor || lighterBlueColor};
  border-radius: ${borderRadiusLg};
`;

const StyledTextWrapper = styled.div`
  margin-left: ${offsetSm};

  & > a {
    text-decoration: none;
    font-weight: ${fontWeightBold};
  }

  & > a:hover {
    text-decoration: underline;
  }
`;

const CancelPolicyCard = ({bgColor, text, icon}: TProps) => {
  return (
    <StyledCard bgColor={bgColor}>
      {icon}
      <StyledTextWrapper>{text}</StyledTextWrapper>
    </StyledCard>
  );
};

export default CancelPolicyCard;

import {ApiType, IApi} from 'infra/common/http.service';
import {
  ICancellationPolicyToOrderUploadRequest,
  IDocumentsFileResponse,
  ITermsAndConditionUploadRequest,
} from 'store/admin/types';

const documentRepository = (api: IApi) => ({
  getActualTermsAndConditionsByFkey: async (
    fkey: number,
  ): Promise<IDocumentsFileResponse[]> =>
    await api.get(
      ApiType.Order,
      `terms-and-conditions?fKey=${fkey}&actual=true`,
    ),

  uploadTermsAndCondition: async ({
    file,
    fkey,
  }: // handleProgress,
  ITermsAndConditionUploadRequest): Promise<IDocumentsFileResponse> => {
    const formData = new FormData();
    formData.append('file', file.originFileObj || JSON.stringify(file));
    formData.append('fKey', fkey.toString());

    // const headers: any = {
    //   'Content-Type': 'multipart/form-data',
    // };
    //
    // const url = `${ORDER()}terms-and-conditions`;
    //
    // return await serverAgent.post(url, formData, {
    //   headers,
    //   onUploadProgress: (progressEvent) => {
    //     if (typeof handleProgress === 'function' && progressEvent.total) {
    //       const percent = Math.round(
    //         (progressEvent.loaded * 100) / progressEvent.total,
    //       );
    //
    //       handleProgress(percent);
    //     }
    //   },
    // });

    return await api.add(ApiType.Order, `terms-and-conditions`, formData);
  },

  deleteTermsAndCondition: async (termsAndConditionsId: string): Promise<any> =>
    await api.delete(
      ApiType.Order,
      `terms-and-conditions/${termsAndConditionsId}`,
    ),

  getActualCancellationPolicyByFkey: async (
    fkey: number,
  ): Promise<IDocumentsFileResponse[]> =>
    await api.get(
      ApiType.Order,
      `cancellation-policy?fKey=${fkey}&actual=true`,
    ),

  uploadCancellationPolicy: async ({
    file,
    fkey,
  }: // handleProgress,
  ITermsAndConditionUploadRequest): Promise<IDocumentsFileResponse> => {
    const formData = new FormData();
    formData.append('file', file.originFileObj || JSON.stringify(file));
    formData.append('fKey', fkey.toString());

    // const headers: any = {
    //   'Content-Type': 'multipart/form-data',
    // };
    //
    // const url = `${ORDER()}cancellation-policy`;
    //
    // return await serverAgent.post(url, formData, {
    //   headers,
    //   onUploadProgress: (progressEvent) => {
    //     if (typeof handleProgress === 'function' && progressEvent.total) {
    //       const percent = Math.round(
    //         (progressEvent.loaded * 100) / progressEvent.total,
    //       );
    //
    //       handleProgress(percent);
    //     }
    //   },
    // });

    return await api.add(ApiType.Order, `cancellation-policy`, formData);
  },

  attachCancellationPolicyToOrder: async ({
    file,
    orderNumber,
  }: ICancellationPolicyToOrderUploadRequest): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file.originFileObj || JSON.stringify(file));
    formData.append('orderNumber', orderNumber.toString());

    return await api.add(ApiType.Order, `cancellation-policy`, formData);
  },

  deleteCancellationPolicy: async (
    termsAndConditionsId: string,
  ): Promise<any> =>
    await api.delete(
      ApiType.Order,
      `cancellation-policy/${termsAndConditionsId}`,
    ),
});

export default documentRepository;

import React from 'react';

import {getBookabilityType} from '../../utils';
import {List, ListItem} from '../../NMMSpacesDetails.styles';

interface IProps {
  isRfp: boolean | undefined;
}

const BookabilityViewMode = ({isRfp}: IProps) => {
  return (
    <List>
      <ListItem>{getBookabilityType(isRfp)}</ListItem>
    </List>
  );
};

export default BookabilityViewMode;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {IAccountReference} from './types';

type TPaymentReducer = {
  accountReferences: IAccountReference[];
  corporateBillingAddress: ICustomerBillingAddress | null;
  accountReferencesError: any;
  isAccountReferencesLoading: boolean;
  paymentType?: EPaymentType;
  isLoading: boolean;
  isPaymentInProcess: boolean;
};

import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {EPaymentType} from 'types/bookingOrders';

const initialState: TPaymentReducer = {
  accountReferences: [],
  corporateBillingAddress: null,
  isAccountReferencesLoading: false,
  accountReferencesError: null,
  paymentType: undefined,
  isLoading: false,
  isPaymentInProcess: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setAccountReferences(state, action: PayloadAction<IAccountReference[]>) {
      state.accountReferences = action.payload;
    },
    setAccountReferencesLoading(state, action: PayloadAction<boolean>) {
      state.isAccountReferencesLoading = action.payload;
    },
    setAccountReferencesError(state, action: PayloadAction<any>) {
      state.accountReferencesError = action.payload;
    },
    setPaymentType(state, action: PayloadAction<EPaymentType | undefined>) {
      state.paymentType = action.payload;
    },
    setCorporateBillingAddress(
      state,
      action: PayloadAction<ICustomerBillingAddress | null>,
    ) {
      state.corporateBillingAddress = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsPaymentInProcess(state, action: PayloadAction<boolean>) {
      state.isPaymentInProcess = action.payload;
    },
  },
});

export const {
  setAccountReferences,
  setAccountReferencesLoading,
  setAccountReferencesError,
  setPaymentType,
  setCorporateBillingAddress,
  setIsLoading,
  setIsPaymentInProcess,
} = paymentSlice.actions;

export default paymentSlice.reducer;

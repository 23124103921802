import styled from 'styled-components';
import {offsetXSm} from 'constants/styleVars';

export const FlexContainer = styled.div<{
  justifyContent?: string;
  alignItems?: string;
}>`
  display: flex;
  justify-content: ${({justifyContent}) => justifyContent || 'flex-start'};
  align-items: ${({alignItems}) => alignItems || 'flex-start'};

  > * {
    margin: 0;
  }

  > * + * {
    margin-left: ${offsetXSm};
  }
`;

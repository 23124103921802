import {useCallback} from 'react';

import {useActions} from 'hooks/useActions';
import {useAppSelector} from 'store/hooks';
import {SHORTLIST_MAX_ITEMS} from '../constants';
import {TShortListItem} from 'types/offer';

export interface IUseShortListReturnProps {
  hasPriceItems: boolean;
  checkIsShortListItemAdded: (venueId: number) => boolean;
  handleAddShortListItem: (props: TShortListItem) => void;
  handleRemoveShortListItem: (id: number) => void;
}

export function useShortListHandlers(): IUseShortListReturnProps {
  const {
    addShortListItem,
    removeShortListItem,
    setShortListMaxItemsModalShowed,
  } = useActions();

  const shortListItems = useAppSelector(({offers}) => offers.shortList.items);

  const checkIsShortListItemAdded = useCallback(
    (venueId: number) => !!shortListItems?.find(({id}) => id === venueId),
    [shortListItems],
  );

  const handleAddShortListItem = useCallback(
    (payload: TShortListItem) => {
      if (shortListItems.length === SHORTLIST_MAX_ITEMS) {
        setShortListMaxItemsModalShowed(true);
        return;
      }

      addShortListItem(payload);
    },
    [addShortListItem, setShortListMaxItemsModalShowed, shortListItems],
  );

  const handleRemoveShortListItem = useCallback(
    (id: number) => {
      removeShortListItem(id);
    },
    [removeShortListItem],
  );

  return {
    hasPriceItems: !!shortListItems.length,
    checkIsShortListItemAdded,
    handleAddShortListItem,
    handleRemoveShortListItem,
  };
}

import React, {useCallback, useMemo, useState} from 'react';
import {useAppSelector} from 'store/hooks';

import Image from 'view/components/Image';

import {EAspectRation} from 'view/components/Image/Image';
import {NW2ModalGallery} from '../NW2ModalGallery';
import {NW2MorePhotos} from '../NW2MorePhotos';

import {borderRadiusDef} from 'constants/styleVars';
import {TImage} from 'types/app';
import {IUnit} from 'types/dto/IUnit.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ELink} from '../../types';
import {EVenueImagesLayout, useCoverImages} from './useCoverImages';

import {
  VenueImagesContainer,
  ImageAmountBlock,
  ImageBlock,
  CoverImage,
  RestImage,
} from './VenueImages.styles';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

export const VenueImages = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<
    number | undefined
  >(undefined);
  const [isMorePhotosShowed, setMorePhotosShowed] = useState(false);
  const [isGalleryShowed, setGalleryShowed] = useState(false);
  const [activeLink, setActiveLink] = useState<ELink>(ELink.VENUE);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const {venueDetails, venueUnits} = useVenueDetailsData();

  const venueImages: TImage[] = venueDetails.documents;

  // TODO clarify about images for MR & WD
  // const units: IUnit[] = useSelector((state) =>
  //   _get(state, 'venuesReducer.venueDetails.units'),
  // );

  const {meetingRoomsImages, workSpacesImages} = useMemo(() => {
    const meetingRooms = venueUnits.filter(
      (u: IUnit) => u.roomType === ERoomType.MEETING_ROOM,
    );
    const workSpaces = venueUnits.filter(
      (u: IUnit) => u.roomType === ERoomType.WORK_SPACE,
    );

    const meetingRoomsImages = meetingRooms
      .map((u: IUnit) => u.documents)
      .flat();
    const workSpacesImages = workSpaces.map((u: IUnit) => u.documents).flat();

    return {meetingRoomsImages, workSpacesImages};
  }, [venueUnits]);

  const reorderedImages: TImage[] = useMemo(
    () => [...venueImages].sort((img) => (img.isCover ? -1 : 1)),
    [venueImages],
  );

  const {coverImages, imgLayout} = useCoverImages({
    venueImages: reorderedImages,
    meetingRoomsImages,
    workSpacesImages,
  });

  // gallery
  const galleryImages = useMemo((): TImage[] => {
    switch (activeLink) {
      case ELink.VENUE:
        return reorderedImages;

      case ELink.MEETINGS:
        return meetingRoomsImages;

      case ELink.DESKS:
        return workSpacesImages;

      default:
        return reorderedImages;
    }
  }, [activeLink, meetingRoomsImages, reorderedImages, workSpacesImages]);

  const showGallery = useCallback(
    (uid: string) => {
      const selectedIndex = galleryImages.findIndex(
        (img: TImage) => img.uid === uid,
      );

      if (selectedIndex < 0) {
        return;
      }

      setSelectedImageIndex(selectedIndex);
      setGalleryShowed((prev) => !prev);
    },
    [galleryImages],
  );

  const onImageClick = (uid: string) => () => {
    if (isMobile) {
      setMorePhotosShowed(true);
      return;
    }
    showGallery(uid);
  };

  const closeGallery = () => {
    setGalleryShowed(false);
    setSelectedImageIndex(undefined);
  };

  return (
    <VenueImagesContainer>
      <ImageBlock>
        <CoverImage
          layout={EVenueImagesLayout[imgLayout]}
          onClick={onImageClick(coverImages[0]?.uid || '')}
        >
          <Image
            src={coverImages[0]?.url}
            height='100%'
            borderRadius={borderRadiusDef}
            isSearchVenue
          />
        </CoverImage>
        <RestImage layout={EVenueImagesLayout[imgLayout]}>
          {coverImages.slice(1).map(({url, uid}) => (
            <div key={uid} onClick={onImageClick(uid)}>
              <Image
                src={url}
                ratio={EAspectRation['21x9']}
                borderRadius={borderRadiusDef}
                height='100%'
                isSearchVenue
              />
            </div>
          ))}
        </RestImage>
      </ImageBlock>

      <ImageAmountBlock>1/{venueImages.length}</ImageAmountBlock>

      <NW2MorePhotos
        isMorePhotosShowed={isMorePhotosShowed}
        setMorePhotosShowed={setMorePhotosShowed}
        activeLink={activeLink}
        setActiveLink={setActiveLink}
        showGallery={showGallery}
        images={galleryImages}
        meetingRoomsImagesNumber={meetingRoomsImages.length}
        workSpacesImagesNumber={workSpacesImages.length}
      />

      <NW2ModalGallery
        isShowed={isGalleryShowed}
        images={galleryImages}
        onClose={closeGallery}
        startIndex={selectedImageIndex}
        isNoBlockScroll={isMorePhotosShowed}
      />
    </VenueImagesContainer>
  );
};

import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';

import {ModalProps} from 'antd/lib/modal/Modal';

import Icon from '../Icon';

import {borderRadiusLg, modalDefaultWidth} from 'constants/styleVars';

const StyledModalWrapper = styled(Modal)<{
  modalHeight?: string;
  headerPaddingBottom?: string;
  isHeaderHidden?: boolean;
  headerPadding?: string;
  footerPadding?: string;
}>`
  height: ${({modalHeight}) => modalHeight || ''} !important;

  .ant-modal-content {
    border-radius: ${borderRadiusLg};
    ${({modalHeight}) =>
      modalHeight
        ? `height: 100%;
            display: grid;`
        : ''};

    & > button {
      top: 7px;
      right: 5px;
    }
  }

  .ant-modal-header {
    ${({headerPadding}) =>
      headerPadding ? `padding: ${headerPadding} !important` : ''};
    padding-bottom: ${({headerPaddingBottom}) =>
      headerPaddingBottom || `0 !important`};
    ${({isHeaderHidden}) => isHeaderHidden && 'display: none'};
  }

  .ant-modal-footer {
    ${({footerPadding}) =>
      footerPadding ? `padding: ${footerPadding} !important` : ''};
  }
`;

interface IProps extends ModalProps {
  children?: ReactNode;
  modalHeight?: string;
  headerPaddingBottom?: string;
  isHeaderHidden?: boolean;
  headerPadding?: string;
  footerPadding?: string;
}

const StyledModal = (props: IProps) => {
  const modalConfig = {
    width: modalDefaultWidth,
    style: {top: !props.centered ? '54px' : ''},
    closeIcon: <Icon icon='NW2_CLOSE' />,
  };

  return <StyledModalWrapper {...modalConfig} {...props} />;
};

export default StyledModal;

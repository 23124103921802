import {fontWeightBold, offsetSm, offsetXXSm} from 'constants/styleVars';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: ${offsetSm} 0;
`;

export const NotificationTitle = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXXSm};
`;

export const MobileWrapper = styled.div`
  overflow: auto;
  height: auto;
`;

import React, {useState} from 'react';

import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import InputCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/components/InputCheckbox';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {getExtraNumericInputVisibility} from 'utils/helpers';
import {PRICE_TYPE_MAP} from 'constants/venue';
import {EPriceType, IExtrasResponse} from 'types/dto/IExtras.type';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {IExtraState, ISummaryExtra} from 'types/dto/IBooking.types';

import {Name, Price} from '../Space.styles';
import {FlexContainer} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/AddFoodBeverageRequest.styles';
import {BEDROOMS_NAMES_FROM_BE} from 'constants/bedrooms';
import {maximumCounterNumber} from 'constants/app';

type TProps = {
  extra: IExtrasResponse;
  extrasState: IExtraState[];
  chosenExtras?: ISummaryExtra[];
  currency: string;
  onExtraChangeQuantity: (extraId: number, quantityValue: number) => void;
  isBedrooms?: boolean;
  minValue?: number;
  maxValue?: number;
};

const ExtraItem = ({
  extra,
  extrasState,
  chosenExtras,
  currency,
  onExtraChangeQuantity,
  isBedrooms,
  minValue,
  maxValue,
}: TProps) => {
  const isNewFreeExtra =
    !chosenExtras?.some(
      ({accommodationExtraId}) =>
        accommodationExtraId === extra.accommodationExtraId,
    ) && extra.priceType === EPriceType.FREE;

  const quantityFromState = isNewFreeExtra
    ? 1
    : extrasState.find(
        (extraState) =>
          extraState.accommodationExtraId === extra.accommodationExtraId,
      )?.bookedQuantity;

  const initIsChecked = isNewFreeExtra ? false : !!quantityFromState;

  const [isChecked, setIsChecked] = useState(initIsChecked);
  const {name, price, priceType, accommodationExtraId, quantity} = extra;
  const isFree = price === 0;

  const priceText = isFree ? (
    'Free'
  ) : (
    <>
      <PriceDisplay price={price} currency={currency} /> /{' '}
      {PRICE_TYPE_MAP[priceType]}
    </>
  );

  const onNumericInputChangeHandler = (value: number) =>
    onExtraChangeQuantity(accommodationExtraId, value);

  const onCheckboxClickHandler = () => {
    setIsChecked((prevState) => {
      const value = prevState ? 0 : 1;
      onExtraChangeQuantity(accommodationExtraId, value);
      return !prevState;
    });
  };

  const isNumericInputVisible = getExtraNumericInputVisibility(name);

  return (
    <div>
      <FlexContainer justifyContent='space-between'>
        {isBedrooms ? (
          <div>
            {
              BEDROOMS_NAMES_FROM_BE[
                name as keyof typeof BEDROOMS_NAMES_FROM_BE
              ]
            }
          </div>
        ) : (
          <InputCheckbox
            id={String(accommodationExtraId)}
            name='bedrooms'
            size={ECheckboxSizes.MEDIUM}
            checked={isChecked}
            onClick={onCheckboxClickHandler}
            label={
              <span>
                <Name>{name}</Name>
                <Price>{priceText}</Price>
              </span>
            }
            inputProps={null}
            hasError={false}
          />
        )}
        {((isChecked && isNumericInputVisible) || isBedrooms) && (
          <NW2NumericInput
            initialValue={quantityFromState}
            minValue={isBedrooms ? minValue || 0 : 1}
            maxValue={isBedrooms ? maxValue || quantity : maximumCounterNumber}
            onChange={onNumericInputChangeHandler}
            hasInput
          />
        )}
      </FlexContainer>
    </div>
  );
};

export default ExtraItem;

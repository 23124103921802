import React, {memo} from 'react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';

import {TDateType} from 'types/dto/ISearch.types';
import {useTimepicker} from './useTimepicker';

import {StyledLabel, StyledWrapper} from './NW2TimePicker.styles';
import {TTimePickerSize} from '../NW2DatePicker.types';

interface IProps extends ReactDatePickerProps {
  selected: TDateType;
  label?: string;
  size?: TTimePickerSize;
}
export const NW2TimePicker = memo(
  ({selected, onChange, label, size = 'small', ...rest}: IProps) => {
    const {ref, isFocused, config} = useTimepicker();

    return (
      <StyledWrapper ref={ref} isFocused={isFocused} size={size}>
        {!!label && <StyledLabel size={size}>{label}</StyledLabel>}

        <DatePicker
          selected={selected}
          onChange={onChange}
          {...config}
          {...rest}
        />
      </StyledWrapper>
    );
  },
);

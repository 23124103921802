import styled, {css} from 'styled-components';

import {
  blackColor,
  borderRadiusDef,
  fontSizeDefault,
  fontSizeLg,
  fontSizeMd,
  fontSizeXXXLg,
  fontWeightBold,
  fontWeightExtraBold,
  lg,
  lineHeightLg,
  lineHeightXL,
  lineHeightXXLg,
  NW2Gray200Color,
  NW2Gray900Color,
  offsetLg,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';

export const Wrapper = styled.div<{background: string; borderColor: string}>`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  padding: ${offsetXLg};
  background-color: ${({background}) => background};
  margin-bottom: ${offsetXLg};
  border-radius: ${borderRadiusDef};
  border-left: 4px solid ${({borderColor}) => borderColor};
`;

export const Heading = styled.div`
  display: grid;
  grid-template-columns: 44px 1fr;
  grid-column-gap: ${offsetLg};
`;

export const Divider = styled.div<{isVisible: boolean}>`
  ${({isVisible}) =>
    isVisible &&
    css`
      background-color: ${NW2Gray200Color};
      height: 1px;
      margin: ${offsetXLg} 0;
    `}
`;

export const Title = styled.div`
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${blackColor};
  margin-bottom: ${offsetXSm};

  @media (min-width: ${lg}) {
    font-size: ${fontSizeXXXLg};
    line-height: ${lineHeightXXLg};
  }
`;

export const SubTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXSm};
  color: ${NW2Gray900Color};
`;

export const TextTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXSm};
`;

export const Text = styled.div`
  font-size: ${fontSizeDefault};
`;

export const Actions = styled.div`
  margin-top: ${offsetXLg};
`;

export const Info = styled.div<{isMarginBottom: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${offsetSm};

  ${({isMarginBottom}) =>
    isMarginBottom &&
    css`
      border-bottom: 1px solid ${NW2Gray200Color};
      padding-bottom: ${offsetXLg};
      margin-bottom: ${offsetXLg};
    `}
`;

export const AdditionalMessage = styled.div`
  margin-top: ${offsetXXSm};
`;

export const IconBox = styled.div<{color?: string}>`
  > div {
    color: ${({color}) => color || ''};
  }
`;

export const StyledDiv = styled.div`
  margin-top: ${offsetXSm};
`;

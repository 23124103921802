import React from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import {Routes} from 'constants/routes';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {setRedirectLink} from 'store/app/appSlice';
import {signOutUser} from 'store/app/apiActions';

interface IProps {
  component: (args: any) => React.JSX.Element | null;
  isPrivateRoute?: boolean;
  isAdminRoute?: boolean;
  isAgentRoute?: boolean;
  isRedirectedAfterLogin?: boolean;
  isPropertyManagerRoute?: boolean;
  isCustomerRoute?: boolean;
  pageTitle?: string;
}
function RouteContainer({
  component: Component,
  isPrivateRoute,
  isAdminRoute,
  isAgentRoute,
  isRedirectedAfterLogin,
  isPropertyManagerRoute,
  isCustomerRoute,
  pageTitle,
  ...rest
}: IProps) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuthConfigured = useAppSelector(({app}) => app.isAuthConfigured);
  const cognitoUserRole: EUserRoleCognito = useAppSelector(
    ({app}) => app.user.role,
  );
  const tokenFromStore = useAppSelector(({app}) => app.token);
  const isUserRoleAdmin = cognitoUserRole === EUserRoleCognito.ROLE_ADMIN;
  const isUserRolePropertyManager =
    cognitoUserRole === EUserRoleCognito.ROLE_PROPERTY_MANAGER;
  const isGuestUserRole = cognitoUserRole === EUserRoleCognito.ROLE_GUEST;
  const isUserRoleCustomer = cognitoUserRole === EUserRoleCognito.ROLE_CUSTOMER;
  const isUserRoleAgent = cognitoUserRole === EUserRoleCognito.ROLE_AGENT;
  const isUserRoleSupplier = cognitoUserRole === EUserRoleCognito.ROLE_SUPPLIER;
  const isLandingPage = location.pathname === Routes.mainLanding;

  if (!isAuthConfigured) return <NW2Loader height='100%' />;

  if (pageTitle) {
    document.title = pageTitle;
  }

  if (
    isRedirectedAfterLogin &&
    cognitoUserRole === EUserRoleCognito.ROLE_GUEST
  ) {
    /**
     * save link for redirect after successfully login
     * need to reset this link inside component after enter
     * or need to find solution to clear ir centralized after navigate
     * maybe router subscribe
     */

    dispatch(setRedirectLink(location.pathname));
  }

  if (
    isCustomerRoute &&
    !isGuestUserRole &&
    !isUserRoleCustomer &&
    !isUserRoleAgent
  ) {
    // trigger sign out if logged in supplier goes to customer pages
    dispatch(
      signOutUser({
        onSuccess: () => {
          if (isRedirectedAfterLogin) {
            dispatch(setRedirectLink(location.pathname));
          }

          navigate(Routes.login, location);
        },
      }),
    );

    return null;
  }

  if (
    (isPropertyManagerRoute && !isUserRolePropertyManager) ||
    (isAdminRoute && !isUserRoleAdmin) ||
    (isPrivateRoute && !tokenFromStore) ||
    (isAgentRoute && !isUserRoleAgent)
  ) {
    //state={location} sets previous route to react router state before navigation to Login page,
    //needed for redirectLink functionality
    return <Navigate to={Routes.login} state={location} />;
  }

  if (isUserRoleSupplier && isLandingPage) {
    return <Navigate to={Routes.publicVenue} state={location} />;
  }

  return <Component {...rest} />;
}

export default RouteContainer;

import styled, {css} from 'styled-components';

import NW2Dropdown from 'view/components/NW2Dropdown';
import simpleCheck from 'img/icons/simple_checked.svg';
import Icon from 'view/components/Icon';

import {
  borderRadiusDef,
  fontSizeLg,
  fontSizeXSm,
  fontWeightBold,
  lineHeightSm,
  lineHeightXL,
  NW2Blue100Color,
  NW2Blue500Color,
  NW2Gray100Color,
  NW2Gray900Color,
  offset36,
  offsetDef,
  offsetLg,
  offsetSm,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

const IMAGE_SIZE = '66px';
const ITEM_WIDTH = '280px';
const OFFSET_30 = '30px';
const BUTTON_HEIGHT = '98px';
const BUTTON_WIDTH = '28px';

export const Container = styled.div`
  display: grid;
  background-color: ${NW2Gray100Color};
  grid-template-columns: 66px 1fr;
  padding: ${offsetDef};
  gap: ${offsetDef};
`;

export const StyledImage = styled.img`
  border-radius: ${borderRadiusDef};
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
  object-fit: cover;
`;

export const StyledDropdown = styled(NW2Dropdown)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  &.ant-dropdown-open div {
    background-color: ${NW2Blue500Color};
    color: ${NW2Gray900Color};
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Name = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
`;

export const ItemWrapper = styled.div<{activeItem: boolean}>`
  position: relative;
  width: ${ITEM_WIDTH};

  ${({activeItem}) =>
    activeItem &&
    css`
      background-color: ${NW2Blue500Color};
    `}
`;

export const ListItem = styled.div<{activeItem: boolean}>`
  padding: ${offsetDef} ${offsetSm};
  white-space: nowrap;

  ${({activeItem}) =>
    activeItem &&
    css`
      margin-left: ${OFFSET_30};

      &:before {
        position: absolute;
        content: url(${simpleCheck});
        top: ${offsetLg};
        left: ${offsetSm};
        transition: visibility 0.15s, opacity 0.15s;
        pointer-events: none;
        z-index: 33;
      }
    `}
`;

export const RightButton = styled.div`
  position: absolute;
  background-color: ${NW2Gray900Color};
  width: ${BUTTON_WIDTH};
  height: ${BUTTON_HEIGHT};
  right: 0;
  top: 0;
  padding: ${offset36} 0px 0px 9px;
  color: ${whiteColor};
  transition: background-color 0.15s, color 0.15s;

  &:hover {
    background-color: ${NW2Blue100Color};
    color: ${NW2Gray900Color};
  }

  &:active {
    background-color: ${NW2Blue500Color};
    color: ${NW2Gray900Color};
  }
`;

export const StyledIcon = styled(Icon)`
  transform: rotate(90deg);
  color: inherit;
`;

import React from 'react';
import styled from 'styled-components';

import ActionLinks from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationActionLinks/NW2BookingConfirmationActionLinks';
import Image from 'view/components/Image';
import Icon from 'view/components/Icon';

import {borderRadiusDef} from 'styles/configs/variables';
import {pagePadding} from 'styles/configs/offset';
import {lg} from 'styles/configs/breakpoints';

import map from 'img/map.jpg';

const VenueDetailsCardContainer = styled.div`
  display: grid;
  grid-gap: ${pagePadding};

  @media (min-width: ${lg}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const VenueDetailsCardImg = styled.div`
  position: relative;
`;
const VenueDetailsCardBody = styled.div``;
const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function VenueDetailsCard() {
  return (
    <VenueDetailsCardContainer>
      <VenueDetailsCardImg>
        <Image src={map} maxHeight={100} borderRadius={borderRadiusDef} />
        <StyledIcon icon='MAP_PIN_HOUSE' />
      </VenueDetailsCardImg>
      <VenueDetailsCardBody>
        <ActionLinks />
      </VenueDetailsCardBody>
    </VenueDetailsCardContainer>
  );
}

export default VenueDetailsCard;

import styled from 'styled-components';

import {
  fontSizeMd,
  lineHeightDefault,
  lineHeightLg,
  NW2Gray600Color,
  offset14,
  offsetDef,
  offsetXSm,
  offsetXXSm,
  offsetXXXSm,
  xLgBp,
  xsBp,
} from 'constants/styleVars';

export const List = styled.div`
  margin: 0;
  list-style: none;
  padding-left: 0;
`;

export const ListItemQty = styled.span`
  margin-right: 8px;
  display: flex;
  min-width: 32px;
`;

export const Price = styled.span`
  color: ${NW2Gray600Color};
  font-size: ${offset14};
  line-height: ${lineHeightDefault};
`;

export const ListItem = styled.div`
  display: flex;
  gap: 6px;
  margin-top: ${offsetXSm};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const ExtraItemPreview = styled.div`
  display: flex;
  font-size: ${offsetDef};
  line-height: ${lineHeightLg};
`;

export const ExtraItemPreviewQty = styled.span`
  display: flex;
  min-width: 34px;
  justify-content: space-between;
`;

export const Quantity = styled.span`
  margin-right: ${offsetXXSm};
`;

export const Name = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${offsetXXXSm};

  @media (min-width: ${xsBp}px) {
    max-width: 350px;
  }

  @media (min-width: ${xLgBp}px) {
    margin-right: ${offsetDef};
    align-items: center;
  }
`;

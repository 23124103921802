import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'store/hooks';

import {Routes} from 'constants/routes';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {HeaderLogoIcon} from 'view/common/Header/Header.styles';

function HeaderLogo() {
  const navigate = useNavigate();

  const userRole: EUserRoleCognito = useAppSelector(({app}) => app.user.role);

  const onLogoClick = useCallback(() => {
    const allowedRolesToGoHome = [
      EUserRoleCognito.ROLE_GUEST,
      EUserRoleCognito.ROLE_CUSTOMER,
    ];

    if (allowedRolesToGoHome.includes(userRole)) {
      navigate(Routes.mainLanding);
    }
  }, [navigate, userRole]);

  return <HeaderLogoIcon icon='LOGO' onClick={onLogoClick} />;
}

export default HeaderLogo;

import React from 'react';

import {
  BottomSection,
  DeclinedRow,
  StyledDeclinedRow,
} from './NW2BookingConfirmationStatus.styles';

type TProps = {
  reason: string | null | undefined;
  message: string | null | undefined;
};

const DeclinedSection = ({reason, message}: TProps) => (
  <BottomSection>
    <DeclinedRow>
      <span>Reason:</span>
      {reason}
    </DeclinedRow>
    {!!message && (
      <StyledDeclinedRow>
        <span>Additional message:</span>
        <div>{message}</div>
      </StyledDeclinedRow>
    )}
  </BottomSection>
);

export default DeclinedSection;

import {TSearchCriteriaRooms} from 'types/search';
import {AppDispatch, RootState} from 'store/types';
import {TContainer} from 'app';
import {IVenue} from 'types/venue';
import {IVenueDetailsData} from '../venues/types';

import {
  setWorkDesksDetails,
  setWorkDesksError,
  setWorkDesksLoading,
} from './workDesksSlice';

// export const searchWorkDesksAction =
//   (data: TSearchVenuePayload) =>
//   async (
//     dispatch: any,
//     getState: () => RootState,
//     {workDesksContainer}: TContainer,
//   ): Promise<void> => {
//     dispatch(searchVenuesRequest());
//
//     const corporateAccountId = getState().app.corporateAccountId;
//     const payload = {
//       ...data,
//       corporateAccountId,
//     };
//     workDesksContainer.searchWorkDesks({
//       payload,
//       onSuccess: (response: IVenuesListResponse) => {
//         dispatch(searchVenuesSuccess(response));
//       },
//       onError: (error: any) => dispatch(searchVenuesFailure(error)),
//     });
//   };

export const getVenueDetailsWorkDesksAction =
  (
    data: IVenueDetailsData & {
      isAllSpaces?: boolean;
      days?: TSearchCriteriaRooms[];
    },
  ) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {workDesksContainer}: TContainer,
  ) => {
    dispatch(setWorkDesksLoading(true));

    workDesksContainer.getWorkDesksDetails({
      payload: data,
      onSuccess: (response: IVenue) => {
        dispatch(setWorkDesksDetails(response));
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode ||
          error?.message ||
          'Failed to fetch venue data!';
        dispatch(setWorkDesksError(errorMessage));
      },
    });
  };

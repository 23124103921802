import React from 'react';
import {OverlayView, OverlayViewF} from '@react-google-maps/api';

import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import Icon from 'view/components/Icon';
import {TextValue} from 'view/components/TextValue/TextValue';

import {EAccommodationType} from 'types/dto/IPublicVenue';
import {getPixelPositionOffset} from 'utils/googleMapUtils';
import {
  OverlayViewArrow,
  OverlayViewContainer,
  OverlayViewContent,
  Dot,
} from './NW2VenueListGoogleMapMarker.styles';
import {IMergedVenue} from 'types/search';

type TProps = {
  venue: IMergedVenue;
  onVenueItemClick: (venue: IMergedVenue, hasPrice: boolean) => () => void;
  isSelected: boolean;
  hasPrice: boolean;
};

const NW2VenueListGoogleMapMarker = ({
  venue,
  onVenueItemClick,
  isSelected,
  hasPrice,
}: TProps) => {
  const {
    location: {latitude, longitude},
    currency,
    totalPrice,
    accommodationType,
  } = venue;

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  /**
   * version of @react-google-maps/api was downgraded to 2.18.1 because 2.19.0 has a bug which adds width 0 to the container
   */
  return (
    <OverlayViewF
      position={{
        lat: Number(latitude || 0),
        lng: Number(longitude || 0),
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <OverlayViewContainer
        onClick={onVenueItemClick(venue, hasPrice)}
        isSelected={isSelected}
      >
        {isOffice ? (
          <>
            <OverlayViewContent isSelected={isSelected}>
              <Icon icon='WHITE_HOUSE' />
              <TextValue size='small' marginTop='0' inline bold>
                Office
              </TextValue>
            </OverlayViewContent>
            <OverlayViewArrow />
          </>
        ) : hasPrice ? (
          <>
            <OverlayViewContent isSelected={isSelected}>
              {isSelected && <Icon icon='CHECK' />}
              <TextValue size='small' marginTop='0' inline bold>
                <PriceDisplay price={totalPrice} currency={currency} />
              </TextValue>
            </OverlayViewContent>
            <OverlayViewArrow />
          </>
        ) : isSelected ? (
          <>
            <OverlayViewContent isSelected={isSelected} noPrice={!hasPrice}>
              {isSelected && <Icon size={14} icon='CHECK' />}
            </OverlayViewContent>
            <OverlayViewArrow />
          </>
        ) : (
          <Dot />
        )}
      </OverlayViewContainer>
    </OverlayViewF>
  );
};

export default NW2VenueListGoogleMapMarker;

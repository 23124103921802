import React from 'react';
import styled from 'styled-components';
import {useForm} from 'react-final-form';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {
  fontSizeSm,
  fontWeightExtraBold,
  lineHeightMd,
  NW2Gray600Color,
  offsetSm,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';
import {useDimensions} from '../hooks/useDimensions';
import {EDimensionsFormFields} from '../../NMMSpaces/common/NMMSpacesDetails/components/Dimensions/DimensionsForm.types';
import {requiredFieldRules} from 'utils/finalFormFieldRules';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXXLg};
`;

const Row = styled.div`
  display: flex;
  gap: ${offsetSm};
`;

const Title = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetXLg};
`;

const Separator = styled.span`
  font-size: ${offsetXLg};
  margin-top: ${offsetSm};
`;

interface IProps {
  fieldName: string;
}
export function AddDimensions({fieldName}: IProps) {
  const {change} = useForm();

  const fieldAreaName = `${fieldName}.${EDimensionsFormFields.AREA}`;
  const fieldAreaFeetName = `${fieldName}.${EDimensionsFormFields.AREA_FEET}`;
  const fieldHeightName = `${fieldName}.${EDimensionsFormFields.CEILING_HEIGHT}`;
  const fieldHeightFeetName = `${fieldName}.${EDimensionsFormFields.CEILING_HEIGHT_FEET}`;

  const {
    handleSquareMetersChange,
    handleSquareFeetChange,
    handleHeightMetersChange,
    handleHeightFeetChange,
  } = useDimensions({
    fieldAreaName,
    fieldAreaFeetName,
    fieldHeightName,
    fieldHeightFeetName,
    onFormChange: change,
  });

  return (
    <Wrapper>
      <div>
        <Title>Space area</Title>

        <Row>
          <NW2FormItemInput
            type='text'
            label='Square metres'
            placeholder='Square metres'
            name={fieldAreaName}
            labelColor={NW2Gray600Color}
            rules={requiredFieldRules('area dimension')}
            onChange={handleSquareMetersChange}
            data-testid={`input-space-dimensions-${EDimensionsFormFields.AREA}`}
          />

          <Separator>=</Separator>

          <NW2FormItemInput
            type='text'
            label='Square feet'
            placeholder='Square feet'
            name={fieldAreaFeetName}
            labelColor={NW2Gray600Color}
            rules={requiredFieldRules('area dimension')}
            onChange={handleSquareFeetChange}
            data-testid={`input-space-dimensions-${EDimensionsFormFields.AREA_FEET}`}
          />
        </Row>
      </div>

      <div>
        <Title>Ceiling height</Title>

        <Row>
          <NW2FormItemInput
            type='text'
            label='Metres'
            placeholder='Metres'
            name={fieldHeightName}
            labelColor={NW2Gray600Color}
            rules={requiredFieldRules('ceiling height')}
            onChange={handleHeightMetersChange}
            data-testid={`input-space-dimensions-${EDimensionsFormFields.CEILING_HEIGHT}`}
          />

          <Separator>=</Separator>

          <NW2FormItemInput
            type='text'
            label='Feet'
            placeholder='Feet'
            name={fieldHeightFeetName}
            labelColor={NW2Gray600Color}
            rules={requiredFieldRules('ceiling height')}
            onChange={handleHeightFeetChange}
            data-testid={`input-space-dimensions-${EDimensionsFormFields.CEILING_HEIGHT_FEET}`}
          />
        </Row>
      </div>
    </Wrapper>
  );
}

import React, {FC} from 'react';
import styled from 'styled-components';
import {Form, Input, FormInstance, InputNumber} from 'antd';

import {
  COMPANY_USER_FIRST_NAME,
  COMPANY_USER_LAST_NAME,
  COMPANY_USER_EMAIL,
  COMPANY_USER_PHONE,
} from '../../../../../constants/admin';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 24},
};

type Props = {
  form: FormInstance;
};

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const AdminAddCompanyUser: FC<Props> = ({form}) => {
  return (
    <Form autoComplete='off' form={form} preserve={false} {...layout}>
      <Form.Item
        label='First name'
        name={COMPANY_USER_FIRST_NAME}
        rules={[{required: true, message: "Please input user's first name!"}]}
      >
        <Input placeholder='First name' allowClear />
      </Form.Item>

      <Form.Item
        label='Last name'
        name={COMPANY_USER_LAST_NAME}
        rules={[{required: true, message: "Please input user's last name!"}]}
      >
        <Input placeholder='Last name' allowClear />
      </Form.Item>

      <Form.Item
        label='Email address'
        name={COMPANY_USER_EMAIL}
        rules={[
          {required: true, message: "Please input user's email address!"},
          {type: 'email'},
        ]}
      >
        <Input placeholder='Email address' allowClear />
      </Form.Item>

      <Form.Item label='Phone number' name={COMPANY_USER_PHONE}>
        <FullWidthInputNumber placeholder='Phone number' />
      </Form.Item>
    </Form>
  );
};

export default React.memo(AdminAddCompanyUser);

import {useMemo} from 'react';

import {dimensionsConverter} from 'utils/venueUtils';
import {pluralize} from 'utils/helpers';
import {getNameByType} from '../utils/helpers';
import {floorLevels, getKeyByValue} from 'constants/floorStorey';

import {EAddMeetingRoomFormFields} from 'view/venue/NW2VenueProfile/components/AddSpace/types';
import {EDimensionsFormFields} from '../components/Dimensions/DimensionsForm.types';
import {EPricesFormFields} from '../components/Price/PricesForm.types';
import {
  spaceTypes,
  TSpaceAmenityRecord,
} from '../components/SpaceAmenities/types';
import {EBookAbility, ISpacesDataItem, IUnitDimensions} from 'types/venue';
import {ESpaceDetailsContentSections} from '../types';

interface IProps {
  space: ISpacesDataItem;
}

export function useInitialFormValues({space}: IProps) {
  /**
   * we should keep useMemo here for correct working of react-final-form initialValues
   */
  return useMemo(() => {
    const {
      amenities = [],
      floor,
      name,
      description,
      priceHour,
      priceHalfDay,
      priceDay,
      minimumPaidDuration,
      unitCapacities,
      unitDimensions = {} as IUnitDimensions, // can be undefined for wip flow
      isRfp,
      capacity,
      type,
    } = space;

    // name & floor
    const floorName =
      typeof floor === 'number'
        ? getKeyByValue(floorLevels, floor) || ' '
        : ' ';
    const initialNameAndFloor = {name, floor: floorName};

    // prices
    const initialPrices = {
      [EPricesFormFields.PRICE_HOUR]: priceHour,
      [EPricesFormFields.PRICE_HALF_DAY]: priceHalfDay,
      [EPricesFormFields.PRICE_DAY]: priceDay,
      [EPricesFormFields.PAID_DURATION]:
        typeof minimumPaidDuration === 'number'
          ? pluralize('hour', minimumPaidDuration)
          : undefined,
    };

    // amenities
    const getInitialAmenities = (section: ESpaceDetailsContentSections) =>
      amenities
        .filter(({type}) => spaceTypes[type] === section)
        .reduce(
          (acc, {id}) => ({
            ...acc,
            [section]: [...(acc[section] || []), id],
          }),
          {} as TSpaceAmenityRecord,
        );

    const initialAmenities = {
      [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]:
        getInitialAmenities(ESpaceDetailsContentSections.INCLUDED_IN_SPACE)[
          ESpaceDetailsContentSections.INCLUDED_IN_SPACE
        ] || [],
      [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]:
        getInitialAmenities(
          ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY,
        )[ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY] || [],
    };

    // dimensions
    const initialDimensions = {
      [EDimensionsFormFields.AREA]:
        unitDimensions[EDimensionsFormFields.AREA] || undefined,
      [EDimensionsFormFields.AREA_FEET]:
        String(
          dimensionsConverter(
            'm',
            unitDimensions[EDimensionsFormFields.AREA],
            true,
          ),
        ) || undefined,
      [EDimensionsFormFields.CEILING_HEIGHT]:
        unitDimensions[EDimensionsFormFields.CEILING_HEIGHT] || undefined,
      [EDimensionsFormFields.CEILING_HEIGHT_FEET]:
        String(
          dimensionsConverter(
            'm',
            unitDimensions[EDimensionsFormFields.CEILING_HEIGHT],
            false,
          ),
        ) || undefined,
    };

    // capacities
    const initialCapacities = {
      [EAddMeetingRoomFormFields.CAPACITIES]: unitCapacities?.reduce(
        (acc, {capacity, setupStyle}) => {
          return {
            ...acc,
            [setupStyle]: {
              checkbox: !!capacity,
              capacity,
            },
          };
        },
        {},
      ),
    };

    // bookability type
    const initialBookabilityType = {
      [ESpaceDetailsContentSections.BOOKABILITY_TYPE]: isRfp
        ? EBookAbility.REQUEST
        : EBookAbility.INSTANT,
    };

    // type Work desks
    const initialSpaceTypeAndCapacity = {type: getNameByType(type), capacity};

    // prices Work desks
    const initialPricesWorkdesks = {
      [EPricesFormFields.PRICE_HOUR]: priceHour,
      [EPricesFormFields.PRICE_DAY]: priceDay,
    };

    return {
      // Common
      initialNameAndFloor,
      initialDescription: {description},
      initialAmenities,
      // Meeting rooms
      initialPrices,
      initialCapacities,
      initialDimensions,
      initialBookabilityType,
      // Work desks
      initialSpaceTypeAndCapacity,
      initialPricesWorkdesks,
    };
  }, [space]);
}

import React from 'react';
import styled from 'styled-components';

import {Link} from '../Typography';
import {TAlignToggler} from './TruncateText';

import {fontSizeMd, lineHeightLg, offsetDef} from 'constants/styleVars';

const Container = styled(Link)<{alignToggler?: TAlignToggler}>`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin-top: ${offsetDef};

  text-align: ${({alignToggler}) => alignToggler};
`;

type TProps = {
  text?: string;
  onClick?: () => void;
  alignToggler?: TAlignToggler;
};

export function FullContentLink({text, onClick, alignToggler}: TProps) {
  if (!text) return null;
  return (
    <Container
      role='button'
      tabIndex={0}
      onClick={onClick}
      alignToggler={alignToggler}
    >
      {text}
    </Container>
  );
}

export default FullContentLink;

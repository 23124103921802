import React from 'react';
import {createPortal} from 'react-dom';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useCallback, useEffect, useRef, useState} from 'react';

import BlockOptionsDropdownList from './BlockOptionsDropdownList';
import FloatingLinkEditor from './FloatingLinkEditor';
import {
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  RangeSelection,
  TextFormatType,
  LexicalCommand,
} from 'lexical';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {$isAtNodeEnd} from '@lexical/selection';
import {$isHeadingNode} from '@lexical/rich-text';
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';

import {
  Toolbar,
  ToolbarItem,
  StyledIcon,
  Text,
} from 'view/components/WYSIWYGEditor/components/ToolbarPlugin.styles';

const supportedBlockTypes = new Set(['paragraph', 'h5', 'ul', 'ol']);

type BlockTypeToBlockName = {
  [key: string]: string;
};

const blockTypeToBlockName: BlockTypeToBlockName = {
  h5: 'Heading 5',
  ol: 'Numbered List',
  paragraph: 'Normal text',
  ul: 'Bulleted List',
};

type TProps = {
  isSmall?: boolean;
};

export function ToolbarPlugin({isSmall = false}: TProps) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState('paragraph');
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  function getSelectedNode(selection: RangeSelection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = anchor.getNode();
    const focusNode = focus.getNode();

    if (anchorNode === focusNode) return anchorNode;

    const isBackward = selection.isBackward();
    if (isBackward) return $isAtNodeEnd(focus) ? anchorNode : focusNode;

    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const toggleList = (command: LexicalCommand<string>, type: string) => () => {
    if (blockType !== type) {
      editor.dispatchCommand(command, '');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setShowBlockOptionsDropDown(false);
  };

  const handleClickFormatTextItem = (type: TextFormatType) => () => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
  };

  return (
    <Toolbar ref={toolbarRef}>
      {supportedBlockTypes.has(blockType) && (
        <>
          <ToolbarItem
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            aria-label='Formatting Options'
            isSmall={isSmall}
          >
            <Text>{blockTypeToBlockName[blockType]}</Text>
            <StyledIcon icon='CHEVRON_DOWN' isMargin />
          </ToolbarItem>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body,
            )}
        </>
      )}
      <>
        <ToolbarItem
          isActive={isBold}
          isSmall={isSmall}
          onClick={handleClickFormatTextItem('bold')}
          aria-label='Format Bold'
        >
          <StyledIcon icon='FORMAT_BOLD' />
        </ToolbarItem>
        <ToolbarItem
          isActive={isItalic}
          isSmall={isSmall}
          onClick={handleClickFormatTextItem('italic')}
          aria-label='Format Italics'
        >
          <StyledIcon icon='FORMAT_ITALIC' />
        </ToolbarItem>
        <ToolbarItem
          isActive={isUnderline}
          isSmall={isSmall}
          onClick={handleClickFormatTextItem('underline')}
          aria-label='Format Underline'
        >
          <StyledIcon icon='FORMAT_UNDERLINE' />
        </ToolbarItem>
        <ToolbarItem
          isActive={isStrikethrough}
          isSmall={isSmall}
          onClick={handleClickFormatTextItem('strikethrough')}
          aria-label='Format Strikethrough'
        >
          <StyledIcon icon='FORMAT_STRIKETHROUGH' />
        </ToolbarItem>
        <ToolbarItem
          isSmall={isSmall}
          isActive={blockType === 'ul'}
          onClick={toggleList(INSERT_UNORDERED_LIST_COMMAND, 'ul')}
        >
          <StyledIcon icon='BULLET_LIST' />
        </ToolbarItem>
        <ToolbarItem
          isSmall={isSmall}
          isActive={blockType === 'ol'}
          onClick={toggleList(INSERT_ORDERED_LIST_COMMAND, 'ol')}
        >
          <StyledIcon icon='NUMBERED_LIST' />
        </ToolbarItem>
        {!isSmall && (
          <ToolbarItem
            isActive={isLink}
            onClick={insertLink}
            aria-label='Insert Link'
          >
            <StyledIcon icon='FORMAT_LINK' />
          </ToolbarItem>
        )}
        {isLink &&
          createPortal(
            <FloatingLinkEditor
              editor={editor}
              getSelectedNode={getSelectedNode}
            />,
            document.body,
          )}
      </>
    </Toolbar>
  );
}

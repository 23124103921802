import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {message} from 'antd';
import {MessageType} from 'antd/lib/message';
import queryString from 'query-string';
import _get from 'lodash/get';
import {useSelector} from 'react-redux';

import {Routes} from 'constants/routes';
import {
  setIsFastSearchEnabled,
  setSearchCriteria,
  setSearchListLoaded,
} from 'store/search/searchSlice';
import LocalStorageService from 'infra/common/localStorage.service';
import {addShortList, clearShortList} from 'store/offers/offersSlice';
import {setHeaderCollapsed} from 'store/app/appSlice';
import {setIsSearchTemplatePopupVisible} from 'store/venues/venuesSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useQuery} from 'hooks/useQuery';
import {getVenuesListQuerySearchData} from 'utils/queryUtils';
import {shortListDefaultState} from 'view/venue/Offer/customer/ShortList/constants';
import {makeMultiSearchPayload} from './components/ExtendedMeetingRoomsPopup/utils';
import {ERoomType} from 'types/dto/ERoomType.type';
import DateUtils from 'utils/dateUtils';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {useInitialMeetingRequestData} from 'view/venue/hooks/search/useInitialMeetingRequestData';

interface IProps {
  isMinimised?: boolean;
}
export const useSearchVenuesActions = (props?: IProps) => {
  const {isMinimised} = props || {};

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const timeDataFromStore = useAppSelector(
    ({search}) => search.initialTimeData,
  );

  const {isMultiSearchOpen} = useQuery();

  const {
    searchString,
    latitude,
    longitude,
    startDate,
    endDate,
    roomType,
    meetingRoomCapacity,
  } = searchCriteria;

  const openSearchTemplatePopup = useCallback(() => {
    dispatch(setIsSearchTemplatePopupVisible(true));
  }, [dispatch]);

  const isMeetingRooms = roomType === ERoomType.MEETING_ROOM;

  const validate = useCallback(() => {
    if (!isMultiSearchOpen) {
      if (!searchString || !latitude || !longitude) {
        return message.error({
          type: 'error',
          content: 'Search and select location',
          key: 'errorMessage',
        });
      }
    }

    // ToDo check if this case possible
    if (!startDate || !endDate) {
      return message.error({
        type: 'error',
        content: 'Select booking hours',
        key: 'errorMessage',
      });
    }

    // ToDo check if this case possible
    if (!meetingRoomCapacity && isMeetingRooms) {
      return message.error({
        type: 'error',
        content: 'Select no. of attendees',
        key: 'errorMessage',
      });
    }
  }, [
    endDate,
    isMeetingRooms,
    isMultiSearchOpen,
    latitude,
    longitude,
    meetingRoomCapacity,
    searchString,
    startDate,
  ]);

  const onSearchVenues = useCallback((): MessageType | void => {
    if (validate()) return;

    if (isMeetingRooms || roomType === ERoomType.GROUPS) {
      // cleanup of bedroomsCatering data before Search template
      openSearchTemplatePopup();
    }

    if (roomType === ERoomType.WORK_SPACE) {
      const querySearchData = getVenuesListQuerySearchData({searchCriteria});
      const data = {
        ...querySearchData,
        multiRooms: false,
        page: 0,
        offerPage: 0,
        searchString: '',
      };

      dispatch(setIsFastSearchEnabled(false));

      // searchString needed for page refresh till better solution is found
      const search = {...data, searchString};

      // set first page in pagination
      dispatch(setSearchCriteria(search));
      navigate({
        pathname: Routes.venuesList,
        search: queryString.stringify(search),
      });

      // reset shortList items & add dates
      dispatch(
        addShortList({
          ...shortListDefaultState,
          checkIn: search.startDate,
          checkOut: search.endDate,
        }),
      );
    }

    // collapse after button 'Search now' pressed
    if (isMinimised) {
      dispatch(setHeaderCollapsed(true));
    }
  }, [
    validate,
    isMeetingRooms,
    roomType,
    isMinimised,
    openSearchTemplatePopup,
    searchCriteria,
    dispatch,
    searchString,
    navigate,
  ]);

  const querySearchData = getVenuesListQuerySearchData({searchCriteria});

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const filteredBedroomExtras = extrasOption.filter(
    (item) => item.type === EResourcesType.BEDROOM,
  );

  const {initialMeetingRequestData, bedroomsCatering} =
    useInitialMeetingRequestData();

  const onFastSearch = useCallback(() => {
    if (validate()) return;

    const {timeData} = makeMultiSearchPayload({
      meetingRequestData: initialMeetingRequestData,
      timeDataFromStore,
      bedroomsCatering,
      filteredBedroomExtras,
    });

    const startDate = DateUtils.normalizeDateToBackendFormat(
      timeData[0].timeStart,
    );
    const endDate = DateUtils.normalizeDateToBackendFormat(
      timeData[timeData.length - 1].timeEnd,
    );

    const search = {
      ...querySearchData,
      startDate,
      endDate,
      page: 0,
      offerPage: 0,
      multiRooms: true,
    };

    // Save multi search data to localstorage in case page refresh
    LocalStorageService.setByKey(
      'multiSearchData',
      JSON.stringify({
        meetingRequestData: initialMeetingRequestData,
        timeData: timeDataFromStore,
      }),
    );

    dispatch(setSearchListLoaded(true));
    dispatch(setIsFastSearchEnabled(true));
    dispatch(setSearchCriteria(search));

    dispatch(clearShortList());

    navigate({
      pathname: Routes.venuesList,
      search: queryString.stringify(search),
    });

    if (isMinimised) {
      dispatch(setHeaderCollapsed(true));
    }
  }, [
    bedroomsCatering,
    dispatch,
    filteredBedroomExtras,
    initialMeetingRequestData,
    isMinimised,
    navigate,
    querySearchData,
    timeDataFromStore,
    validate,
  ]);

  return {onSearchVenues, onFastSearch};
};

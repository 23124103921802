import {IAccountFieldsValidationRules} from 'store/payment/types';
import {datePickerRules, requiredFieldRules} from 'utils/finalFormFieldRules';

export const validateDefaultReferenceField = (fieldName: string) =>
  requiredFieldRules(fieldName);

export const CORPORATE_PAYMENT_VALIDATION_RULES: IAccountFieldsValidationRules =
  {
    AirPlus: {
      'Processing Date (BD)': (_fieldName: string, isRequired: boolean) =>
        datePickerRules('DDMMYY', isRequired),
    },
    AMEX: {},
    CITI: {},
  };

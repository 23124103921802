import React from 'react';

import {Uploader} from 'view/components/Uploader/Uploader';
import {useDocuments} from './useDocuments';

import {
  EUploaderLabel,
  EUploadedFileType,
} from 'view/components/Uploader/types';
import {
  Container,
  UploaderContainer,
  UploaderTitle,
  UploaderWrapper,
} from './TermsAndConditionsUploader.styles';
import {
  MODAL_BODY,
  MODAL_HEADER_CANCELLATION,
  MODAL_HEADER_TERMS,
} from 'constants/app';

export const TermsAndConditionsUploader = () => {
  const {
    cancellationPoliciesFile,
    onPolicyUpload,
    onPolicyDelete,
    termsAndConditionsFile,
    onTermsUpload,
    onTermsDelete,
  } = useDocuments();

  const commonProps = {
    uploadedFileType: EUploadedFileType.PDF_FILE,
    label: EUploaderLabel.PDF_FILE,
    maxFiles: 1,
    modalBody: MODAL_BODY,
  };

  return (
    <Container>
      <UploaderContainer>
        <UploaderWrapper>
          <UploaderTitle>
            Modification and Cancellation Conditions
          </UploaderTitle>
          <Uploader
            uploadedFile={cancellationPoliciesFile}
            onFileUpload={onPolicyUpload}
            onFileDelete={onPolicyDelete}
            isAdminUploader
            modalHeader={MODAL_HEADER_CANCELLATION}
            {...commonProps}
          />
        </UploaderWrapper>
      </UploaderContainer>

      <UploaderContainer>
        <UploaderWrapper>
          <UploaderTitle>Other Terms and Conditions</UploaderTitle>
          <Uploader
            uploadedFile={termsAndConditionsFile}
            onFileUpload={onTermsUpload}
            onFileDelete={onTermsDelete}
            isAdminUploader
            modalHeader={MODAL_HEADER_TERMS}
            {...commonProps}
          />
        </UploaderWrapper>
      </UploaderContainer>
    </Container>
  );
};

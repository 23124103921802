import React from 'react';
import styled from 'styled-components';

import {
  fontSizeMd,
  fontWeightBold,
  lineHeightLg,
  offsetDef,
} from 'constants/styleVars';

const Container = styled.span`
  display: inline-block;
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  text-decoration-line: underline;
  margin-top: ${offsetDef};
  cursor: pointer;
`;

type TProps = {
  text: string;
  onClick?: () => void;
};

export function NW2VenueDetailsBlockLink({text, onClick}: TProps) {
  if (!text) return null;
  return (
    <Container role='button' tabIndex={0} onClick={onClick}>
      {text}
    </Container>
  );
}

export default NW2VenueDetailsBlockLink;

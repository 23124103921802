import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IVenue} from 'types/venue';
import {RootState} from 'store/types';
import {useEffect} from 'react';
import {useAppDispatch} from 'store/hooks';
import {getPublicVenueById} from 'store/venue/actions';

const useVenue = (accommodationId?: number) => {
  const dispatch = useAppDispatch();
  const venue: IVenue = useSelector((state: RootState) =>
    _get(state, PATH_TO_REDUCER_VENUE_DATA),
  );

  useEffect(() => {
    if (accommodationId && !venue?.id) {
      dispatch(getPublicVenueById(accommodationId));
    }
  }, [accommodationId, dispatch, venue?.id]);

  return [venue];
};

export default useVenue;

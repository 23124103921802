import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {notification} from 'antd';
import {useAppSelector} from 'store/hooks';
import {useDispatch} from 'react-redux';
import {Auth} from 'aws-amplify';
import queryString from 'query-string';

import {LoginRegisterContainer} from 'view/auth/LoginRegisterContainer';
import FormRegister from 'view/auth/Register/FormRegister';
import {
  setIsRegistrationAction,
  setOpenRegisterPopup,
} from 'store/app/appSlice';
import {useQuery} from 'hooks/useQuery';
import NW2Button from 'view/components/NW2Button';
import {Link} from 'view/components/Typography';
import LocalStorageService from 'infra/common/localStorage.service';

import {
  AuthModalFooter,
  MainAuthTitle,
  StepCountTitle,
  StyledTextValue,
  TitleContainer,
  BoldTextValue,
  RegisterModalFooter,
  StyledRegisterTextValue,
} from 'view/auth/utils/auth.styles';
import {IFormRegisterData} from 'types/account';
import {TextValue} from 'view/components/TextValue';
import {EAuthActions} from '../LoginCustomer/Login';
import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';

type TProps = {
  isRegisterVisible: boolean;
  toggleLoginRegisterModals: () => void;
};

enum ERegistrationSteps {
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  STEP_3 = 'STEP_3',
}

function RegisterModal({isRegisterVisible, toggleLoginRegisterModals}: TProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(ERegistrationSteps.STEP_1);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);
  const [confirmedEmailAddress, setConfirmedEmailAddress] = useState('');
  const [isLocalConfirmAlreadyRegistered, setIsLocalConfirmAlreadyRegistered] =
    useState(false);

  const appEnvironment = useAppSelector(({app}) => app.environment);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localStorageGuestBookingPreviewData =
    LocalStorageService.getByKey('bookingPreviewData');

  const guestBookingPreviewData = localStorageGuestBookingPreviewData
    ? JSON.parse(localStorageGuestBookingPreviewData)
    : null;

  const unitIds = guestBookingPreviewData?.previewUnits
    ?.flatMap((item: TGroupedUnitsByDay) =>
      item.units.map((unit) => unit.unitId),
    )
    .join(',');

  const venueId = guestBookingPreviewData?.venueId;
  const querySearchData = guestBookingPreviewData?.querySearchData;

  const {code, email: username, action} = useQuery();

  const isRegistrationAction = action === EAuthActions.CONFIRM_REGISTRATION;

  // User already registered on MyHRS as customer and try to register supplier role via this app
  const isConfirmAlreadyRegisteredAction =
    action === EAuthActions.CONFIRM_ALREADY_REGISTERED;

  const sentEmailNotificationOptions = {
    message:
      'An account activation email with instructions is sent to provided email address.',
    duration: 4,
  };

  const makeErrorNotificationOptions = (error: Error) => {
    return {
      message: error?.message || 'Something went wrong',
      duration: 4,
    };
  };

  const cleanStates = () => {
    dispatch(setOpenRegisterPopup({openRegisterPopup: false}));
    setIsLocalConfirmAlreadyRegistered(false);
    setCurrentStep(ERegistrationSteps.STEP_1);
  };

  const onClose = () => {
    cleanStates();
    navigate({search: ''});
  };

  const toggleReSendEmail = () => {
    Auth.resendSignUp(confirmedEmailAddress)
      .then(() => {
        notification.success(sentEmailNotificationOptions);
      })
      .catch((error: Error) => {
        notification.error(makeErrorNotificationOptions(error));
      });
  };

  const onFinish = async ({
    firstName,
    lastName,
    email,
    password,
  }: IFormRegisterData) => {
    setIsLoading(true);
    if (isSubmitFailed) {
      setIsSubmitFailed(false);
    }

    Auth.signUp({
      username: email,
      password: password as string,
      attributes: {
        given_name: firstName,
        family_name: lastName,
      },
    })
      .then(() => {
        setCurrentStep(ERegistrationSteps.STEP_2);
        setConfirmedEmailAddress(email);
        setIsLoading(false);
      })
      .catch((error: Error) => {
        notification.error(makeErrorNotificationOptions(error));
        setIsSubmitFailed(true);
        setIsLoading(false);
      });
  };

  const toLoginFromVerification = () => {
    toggleLoginRegisterModals();
    setCurrentStep(ERegistrationSteps.STEP_1);
    setIsLocalConfirmAlreadyRegistered(false);
  };

  useEffect(() => {
    if (isConfirmAlreadyRegisteredAction) {
      setIsLocalConfirmAlreadyRegistered(true);
    }
  }, [isConfirmAlreadyRegisteredAction]);

  useEffect(() => {
    if (isLocalConfirmAlreadyRegistered) {
      setCurrentStep(ERegistrationSteps.STEP_3);
      dispatch(setOpenRegisterPopup({openRegisterPopup: true}));
      navigate({search: ''});
    }
  }, [isLocalConfirmAlreadyRegistered, navigate, dispatch]);

  useEffect(() => {
    if (appEnvironment && isRegistrationAction && username && code) {
      if (guestBookingPreviewData) {
        dispatch(setIsRegistrationAction(isRegistrationAction));
        const pathname = `/venue/${Number(venueId)}/booking`;
        navigate({
          pathname,
          search: `unitIds=${unitIds}&${queryString.stringify(
            querySearchData,
          )}`,
        });
      }
      Auth.confirmSignUp(username as string, code as string)
        .then(() => {
          setCurrentStep(ERegistrationSteps.STEP_3);
          dispatch(setOpenRegisterPopup({openRegisterPopup: true}));
        })
        .catch((error) => {
          notification.error(makeErrorNotificationOptions(error));
        });
      !guestBookingPreviewData && navigate({search: ''});
    }
  }, [
    appEnvironment,
    isRegistrationAction,
    username,
    code,
    navigate,
    dispatch,
    guestBookingPreviewData,
    querySearchData,
    unitIds,
    venueId,
  ]);

  const modalContentBySteps = {
    [ERegistrationSteps.STEP_1]: {
      title: 'Create an account',
      body: (
        <>
          <FormRegister
            isSubmitFailed={isSubmitFailed}
            onFinish={onFinish}
            isLoading={isLoading}
          />
          <AuthModalFooter>
            <div>Already have an account?</div>
            <Link href='#' onMouseDown={toggleLoginRegisterModals}>
              Log In
            </Link>
          </AuthModalFooter>
        </>
      ),
    },
    [ERegistrationSteps.STEP_2]: {
      title: 'Verify your email address',
      body: (
        <>
          <StyledRegisterTextValue>
            You're almost done! An account verification email was sent to the
            following email address:
          </StyledRegisterTextValue>
          {confirmedEmailAddress && (
            <BoldTextValue>{confirmedEmailAddress}</BoldTextValue>
          )}
          <StyledRegisterTextValue>
            If you don’t see it in your inbox, please take check your spam
            folder.
          </StyledRegisterTextValue>
          <RegisterModalFooter>
            <TextValue>Haven’t received the email?</TextValue>
            <Link onClick={toggleReSendEmail}>Re-send verification email</Link>
            <StyledTextValue>or</StyledTextValue>
            <NW2Button onClick={toLoginFromVerification}>
              Back to log in
            </NW2Button>
          </RegisterModalFooter>
        </>
      ),
    },
    [ERegistrationSteps.STEP_3]: {
      title: 'Registration successful!',
      body: (
        <>
          <StyledTextValue>
            You can now go back to the log in
            {!isLocalConfirmAlreadyRegistered ? ' to book your next venue' : ''}
          </StyledTextValue>
          <NW2Button
            fullWidth
            buttonType='primary'
            onClick={toLoginFromVerification}
          >
            Log in
          </NW2Button>
        </>
      ),
    },
  };

  const stepCountTitle = `${
    Object.keys(modalContentBySteps).indexOf(currentStep) + 1
  } / ${Object.keys(modalContentBySteps).length} Step`;

  return (
    <LoginRegisterContainer onClose={onClose} open={isRegisterVisible}>
      <>
        <TitleContainer>
          {!isLocalConfirmAlreadyRegistered && (
            <StepCountTitle>{stepCountTitle}</StepCountTitle>
          )}
          <MainAuthTitle>
            {modalContentBySteps[currentStep].title}
          </MainAuthTitle>
        </TitleContainer>
        {modalContentBySteps[currentStep].body}
      </>
    </LoginRegisterContainer>
  );
}

export default RegisterModal;

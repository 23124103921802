import React from 'react';
import styled from 'styled-components';

import TruncateText from 'view/components/TruncateText';
import CustomList from 'view/components/CustomList';
import {NW2FormItemPhoneNumber} from 'view/components/NW2FormItem/NW2FormItem';

import {useAppSelector} from 'store/hooks';
import {fontSizeMd, offsetXSm, offsetXLg} from 'constants/styleVars';
import {FormGroup} from '../../NW2BookingPreview.styles';
import {legalPhoneFieldRules} from 'utils/finalFormFieldRules';
import {ECustomerDetailsFormFields} from 'view/components/NW2Forms/NW2CustomerDetailsForm/types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

const StyledCustomList = styled(CustomList)`
  > li {
    font-size: ${fontSizeMd};

    &:not(&:last-of-type) {
      margin: 0 0 ${offsetXSm} 0;
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-top: ${offsetXLg};
`;

const LoggedInUserDetails = () => {
  const user = useAppSelector(({app}) => app.user);
  const customerOfferDetails = useAppSelector(
    ({offers}) => offers.offerDetails.customer,
  );

  const isAgentRole = user.role === EUserRoleCognito.ROLE_AGENT;

  const getUserData = () => {
    const {firstName, lastName, email, phone} = user;
    const fullName = `${firstName} ${lastName}`;

    if (isAgentRole) {
      const {
        firstName: customerFirstName,
        lastName: customerLastName,
        email: customerEmail,
        phone: customerPhone,
      } = customerOfferDetails;
      const customerFullName = `${customerFirstName} ${customerLastName}`;

      return {
        fullName: customerFullName,
        email: customerEmail,
        phone: customerPhone,
      };
    }

    return {fullName, email, phone};
  };

  const {fullName, email, phone} = getUserData();

  const requiredData = [
    {
      value: (
        <TruncateText
          text={fullName}
          tag='span'
          numberOfLines={2}
          hideToggler
          expanded
        />
      ),
      icon: 'NW2_USER',
    },
    {
      value: (
        <TruncateText
          text={email}
          tag='span'
          numberOfLines={2}
          hideToggler
          expanded
        />
      ),
      icon: 'NW2_EMAIL',
    },
  ];

  const phoneData = {
    value: phone,
    icon: 'NW2_PHONE',
  };

  const listData = phone ? [...requiredData, phoneData] : requiredData;

  return (
    <div>
      <StyledCustomList
        list={listData}
        listStyle='icon'
        iconStyle={{size: 20}}
        offset='0'
      />

      {!isAgentRole && !phone && (
        <StyledFormGroup columnNumber={1} gap={24}>
          <NW2FormItemPhoneNumber
            type='text'
            name={ECustomerDetailsFormFields.PHONE}
            placeholder='Phone number*'
            label='Phone number*'
            rules={legalPhoneFieldRules}
            isCountrySelect
          />
        </StyledFormGroup>
      )}
    </div>
  );
};

export default LoggedInUserDetails;

import styled from 'styled-components';
import {Form} from 'antd';
import {offsetDef} from 'constants/styleVars';

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  padding: ${offsetDef};
`;

export const StyledForm = styled(Form)`
  width: 100%;
  max-width: 600px;
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
`;

export const FormItem = styled(Form.Item)`
  width: 100%;
`;

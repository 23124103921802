import React from 'react';

import Icon from 'view/components/Icon';

import {
  BookingDateColumn,
  BookingStatusColumn,
  DurationColumn,
  FilterCheckboxDropdown,
  FilterSearchDropdown,
  RowMenuColumn,
  TotalColumn,
} from './BookingColumnRenderContent';
import {IBookingType} from 'types/dto/IBookingType.type';
import {FilterDropdownProps} from 'antd/es/table/interface';
import {IBooking} from 'types/dto/IBooking.types';
import NW2BookingStatusTag from 'view/common/NW2BookingStatusTag';
import {EOrderShowStatus} from 'types/bookingOrders';

const filterIcon = (filtered: any) => {
  return <Icon size={12} icon='FILTER' primary={!!filtered} secondary />;
};

const getColumnFilterSearchProps = () => ({
  filterDropdown: FilterSearchDropdown,
  filterIcon,
});

const getColumnFilterCheckboxProps = (
  dataIndex: string,
  tableName?: string,
) => ({
  filterDropdown: (props: FilterDropdownProps) => (
    <FilterCheckboxDropdown
      {...props}
      dataIndex={dataIndex}
      tableName={tableName}
    />
  ),
  filterIcon,
});

const {
  bookingNumber,
  booker,
  bookedFor,
  duration,
  guestCompany,
  workspaceType,
  total,
  bookingStatus,
  rowMenu,
  showStatus,
} = {
  bookingNumber: {
    key: 'bookingNumber',
    title: 'Booking ID',
    dataIndex: 'orderNumber',
    render: (text: string) => <>{text}</>,
    width: 120,
    ...getColumnFilterSearchProps(),
  },
  booker: {
    key: 'booker',
    title: 'Booker',
    render: ({firstName, lastName}: IBooking) => (
      <>
        {firstName} {lastName}
      </>
    ),
    width: 180,
    ...getColumnFilterSearchProps(),
  },
  bookedFor: {
    title: 'Date',
    key: 'bookedFor',
    dataIndex: 'checkInDate',
    render: BookingDateColumn,
    width: 120,
  },
  duration: {
    title: 'Duration',
    key: 'duration',
    dataIndex: 'duration',
    render: DurationColumn,
    width: 120,
  },
  guestCompany: {
    title: 'Company',
    key: 'company',
    dataIndex: 'company',
    sorter: true,
    width: 180,
    ellipsis: true,
    render: (company: IBooking) => <>{company || 'Not provided'}</>,
    ...getColumnFilterSearchProps(),
  },
  workspaceType: {
    title: 'Workspace',
    key: 'roomType',
    dataIndex: 'unitName',
    sorter: true,
    width: 200,
  },
  total: {
    title: 'Total',
    key: 'total',
    dataIndex: 'totalPrice',
    render: TotalColumn,
    align: 'right',
    ellipsis: true,
    width: 80,
  },
  bookingStatus: {
    key: 'bookingStatus',
    title: 'Status',
    dataIndex: 'bookingStatus',
    render: BookingStatusColumn,
    width: 150,
  },
  rowMenu: {
    key: 'rowMenu',
    title: '',
    dataIndex: 'rowMenu',
    render: (text: string, record: IBooking, index: number) => (
      <RowMenuColumn text={text} record={record} index={index} />
    ),
    align: 'right',
    width: 50,
  },
  showStatus: {
    key: 'showStatus',
    title: '',
    dataIndex: 'showStatus',
    className: 'show-status',
    render: (text: string, record: IBooking) =>
      record.showStatus === EOrderShowStatus.UNREAD && (
        <NW2BookingStatusTag status='NEW' size='small' />
      ),
    align: 'right',
    width: 55,
    colSpan: -1,
  },
};

export const columns: Record<string, any> = {
  [IBookingType.ARRIVING_TODAY]: {
    arriving: [
      showStatus,
      bookingNumber,
      booker,
      guestCompany,
      workspaceType,
      duration,
      {...total, align: 'left'},
      {
        ...bookingStatus,
        ...getColumnFilterCheckboxProps('bookingStatus', 'arriving'),
      },
      rowMenu,
    ],
  },
  [IBookingType.TOMORROW_OVERVIEW]: [
    showStatus,
    bookingNumber,
    booker,
    guestCompany,
    workspaceType,
    duration,
    {...total, align: 'left'},
    bookingStatus,
    rowMenu,
  ],
  [IBookingType.UPCOMING_BOOKINGS]: [
    showStatus,
    bookedFor,
    bookingNumber,
    booker,
    guestCompany,
    workspaceType,
    duration,
    {...total, align: 'left'},
    bookingStatus,
    rowMenu,
  ],
  [IBookingType.BOOKINGS_HISTORY]: [
    bookedFor,
    bookingNumber,
    booker,
    guestCompany,
    workspaceType,
    duration,
    {...total, align: 'left'},
    {
      ...bookingStatus,
      ...getColumnFilterCheckboxProps('bookingStatus', 'history'),
    },
    rowMenu,
  ],
};

export default columns;

import React, {useEffect, useState} from 'react';
import {Form, Input, FormInstance, Select, Checkbox, Divider} from 'antd';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';

import {SubPageTitle} from 'view/components/Typography';
import {Amenities} from 'view/venue/CreateVenue/components/Amenities';
import {useAppDispatch} from 'store/hooks';

import {defaultAntdFormLayout} from 'constants/app';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import venueTexts from 'texts/venueTexts';

import {offsetXSm} from 'constants/styleVars';
import {
  nameFieldRules,
  descriptionFieldRules,
  currencyFieldRules,
  venueTypeFieldRules,
  venueCharactersFieldRules,
  hKeyRules,
} from 'utils/antdFormFieldRules';
import {
  getVenueCharacters,
  getVenueCurrencies,
  getVenueTypes,
  setVenueFields,
} from 'store/venue/actions';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import useVenueCharactersOptions from 'view/venue/hooks/useVenueCharactersOptions';
import {
  EVenueTypes,
  IVenueCurrencies,
  TVenueCharacterType,
  TVenueType,
} from 'types/venue';

const StyledDivider = styled(Divider)`
  margin: ${offsetXSm} 0;
`;
const {Item} = Form;
const {Option} = Select;

type TProps = {
  form: FormInstance;
  disableName?: boolean;
  disableNumberOfFloors?: boolean;
  disableVenueCurrency?: boolean;
  disableVenueTypes?: boolean;
  disableVenueCharacters?: boolean;
  accommodationType: EAccommodationType;
  hideAmenities?: boolean;
  isHmd?: boolean;
};

const GeneralForm = ({
  form,
  accommodationType,
  disableName,
  disableVenueTypes,
  disableVenueCharacters,
  // disableNumberOfFloors, // todo clarify what to do with it on the NMM branch ?
  disableVenueCurrency,
  hideAmenities = false,
  isHmd,
}: TProps) => {
  const dispatch = useAppDispatch();

  const name = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.name`),
  );
  const description = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.description`),
  );
  const venueTypes: TVenueType[] = useSelector((state) =>
    _get(state, `venue.venueTypes`),
  );
  const venueCurrencies: IVenueCurrencies | null = useSelector((state) =>
    _get(state, `venue.venueCurrencies`),
  );
  const currency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const venueType: TVenueType = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.venueType`),
  );
  const checkedVenueCharacters: TVenueCharacterType[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.venueCharacters`),
  );
  const hkey = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.hkey`),
  );
  const isHkeyEditDisabled = useSelector((state) =>
    _get(state, 'venue.isHkeyEditDisabled'),
  );

  const handleFormFields = (path: string) => (data: any) => {
    dispatch(
      setVenueFields({
        path,
        value: data && data.target ? data.target.value : data,
      }),
    );
  };

  const initialValues = {
    name,
    description,
    currency,
    venueType,
    venueCharacters: checkedVenueCharacters,
    hkey,
  };

  const [isDataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if (isDataLoaded) return;
    dispatch(getVenueTypes());
    dispatch(getVenueCharacters());
    dispatch(getVenueCurrencies());
    setDataLoaded(true);
  }, [dispatch, isDataLoaded]);

  useEffect(() => form.setFieldsValue(initialValues));

  const venueCharactersOptions = useVenueCharactersOptions();

  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  return (
    <>
      <SubPageTitle>
        General information about the&nbsp;
        {venueTexts[accommodationType].venueType}
      </SubPageTitle>

      <Form {...defaultAntdFormLayout} form={form}>
        <Item
          name='name'
          label={`${venueTexts[accommodationType].venueTypeCapitalized} name`}
          rules={nameFieldRules}
        >
          <Input
            allowClear
            name='name'
            placeholder={`Title for your ${venueTexts[accommodationType].venueType}`}
            onChange={handleFormFields('name')}
            disabled={disableName}
          />
        </Item>

        <Item
          name='description'
          label={`${venueTexts[accommodationType].venueTypeCapitalized} description`}
          rules={descriptionFieldRules}
        >
          <Input.TextArea
            allowClear
            name='description'
            maxLength={1000}
            minLength={150}
            placeholder={`Tell us about your ${venueTexts[accommodationType].venueType}'s details`}
            onChange={handleFormFields('description')}
          />
        </Item>

        {isExternalVenue && venueTypes && (
          <Item name='venueType' label='Venue type' rules={venueTypeFieldRules}>
            <Select
              disabled={disableVenueTypes}
              placeholder='Select'
              onChange={handleFormFields('venueType')}
            >
              {venueTypes.map((type) => (
                <Option key={type} value={type}>
                  {EVenueTypes[type]}
                </Option>
              ))}
            </Select>
          </Item>
        )}

        {isExternalVenue && (
          <>
            <Item
              name='currency'
              label='Venue currency'
              rules={currencyFieldRules}
            >
              <Select
                disabled={disableVenueCurrency}
                placeholder='Choose Currency'
                onChange={handleFormFields('currency')}
              >
                {venueCurrencies?.popular.map(({code, name}) => (
                  <Option key={code} value={code}>
                    {`${name} (${code})`}
                  </Option>
                ))}
                <Option disabled style={{minHeight: 0}}>
                  <StyledDivider />
                </Option>
                {venueCurrencies?.all.map(({code, name}) => (
                  <Option key={code} value={code}>
                    {`${name} (${code})`}
                  </Option>
                ))}
              </Select>
            </Item>

            <Item name='hkey' label='H-key' rules={hKeyRules}>
              <Input
                name='hkey'
                type='number'
                placeholder='Optional'
                onChange={handleFormFields('hkey')}
                disabled={isHmd || isHkeyEditDisabled}
              />
            </Item>
            <Item
              name='venueCharacters'
              label='Venue character'
              rules={venueCharactersFieldRules}
              required
            >
              <Checkbox.Group
                disabled={disableVenueCharacters}
                name='venueCharacters'
                onChange={handleFormFields('venueCharacters')}
                options={venueCharactersOptions}
              />
            </Item>

            {!hideAmenities && <Amenities disableAddAmenities={false} />}
          </>
        )}
      </Form>
    </>
  );
};

export default GeneralForm;

import React from 'react';
import _get from 'lodash/get';
import {useSelector} from 'react-redux';

import KPI from 'view/components/KPI';

import {
  EBookingFilterType,
  ETotalFilteredRecords,
  IBookingType,
  IKpiList,
} from 'types/dto/IBookingType.type';
import {useBookingsAmount} from 'view/supplier/hooks/useBookingsAmount';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

const RFP = {
  urgent: {
    icon: 'TASK_LIST_CLOCK',
    iconText: 'Urgent',
    bodyText: 'requests',
    footerText: 'Today & Tomorrow',
    bgColor: 'error',
  },
  cancel: {
    icon: 'TASK_LIST_CLOCK',
    iconText: 'Hold-up 12h +',
    bodyText: 'requests',
    footerText: 'Susceptible to cancellation',
    bgColor: 'warning',
  },
  recent: {
    icon: 'TASK_LIST_CLOCK',
    iconText: 'Hold-up 0 - 12h',
    bodyText: 'requests',
    footerText: 'Recently came in',
    bgColor: 'default',
  },
  earning: {
    icon: 'MONETIZATION_TOUCH_COIN',
    iconText: 'Potential earnings',
    bodyText: '',
    footerText: 'confirmed requests',
    bgColor: 'default',
  },
};

export const setDataToRFPList = (
  {
    HOLD_UP_FROM_0_TO_12,
    HOLD_UP_FROM_TODAY_TO_TOMORROW,
    HOLD_UP_MORE_THAN_12,
    TOTAL_PRICE,
  }: IKpiList,
  currency: string,
  amountOfBookingPendingRequests: string,
) => {
  return {
    ...(Number(HOLD_UP_FROM_TODAY_TO_TOMORROW) && {
      urgent: {
        ...RFP.urgent,
        bodyText: `${HOLD_UP_FROM_TODAY_TO_TOMORROW} ${RFP.urgent.bodyText}`,
      },
    }),
    ...(Number(HOLD_UP_MORE_THAN_12) && {
      cancel: {
        ...RFP.cancel,
        bodyText: `${HOLD_UP_MORE_THAN_12} ${RFP.urgent.bodyText}`,
      },
    }),
    ...(Number(HOLD_UP_FROM_0_TO_12) && {
      recent: {
        ...RFP.recent,
        bodyText: `${HOLD_UP_FROM_0_TO_12} ${RFP.urgent.bodyText}`,
      },
    }),
    ...(Number(TOTAL_PRICE) && {
      earning: {
        ...RFP.earning,
        bodyText: (
          <PriceDisplay price={Number(TOTAL_PRICE)} currency={currency} />
        ),
        footerText: `${amountOfBookingPendingRequests} ${RFP.earning.footerText}`,
      },
    }),
  };
};

type TProps = {
  bookingsType: IBookingType;
  bookingFilterType: EBookingFilterType;
};

function BookingKPI({bookingsType, bookingFilterType}: TProps) {
  const isPendingRequests =
    bookingsType === IBookingType.REQUESTS &&
    bookingFilterType === EBookingFilterType.RFP_PENDING;

  const currency = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const {kpiList, searchTabsCount} = useBookingsAmount();
  const amountOfBookingPendingRequests =
    searchTabsCount?.[ETotalFilteredRecords.RFP_PENDING] || '';

  if (!isPendingRequests) return null;

  const kpiData = setDataToRFPList(
    kpiList as IKpiList,
    currency,
    amountOfBookingPendingRequests,
  );

  if (!Object.keys(kpiData).length) return null;
  return <KPI kpiData={kpiData} />;
}

export default BookingKPI;

import styled, {css} from 'styled-components';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import {NW2Card} from 'view/components/NW2Card';

import {
  borderRadiusXLg,
  fontSizeLg,
  fontWeightBold,
  lineHeightXL,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  pagePadding,
  preLgBd,
} from 'constants/styleVars';

export const StyledNW2Card = styled(NW2Card)`
  grid-template-columns: 162px auto;

  & + & {
    margin-top: ${offsetXXLg};
  }

  @media (max-width: ${preLgBd}px) {
    grid-template-columns: auto;
    > div:nth-child(n) {
      grid-column: auto;
    }
  }
`;

export const Container = styled.div`
  padding: ${pagePadding};
  border-radius: ${borderRadiusXLg};
  border: 1px solid ${NW2Gray200Color};
`;

export const Title = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
`;

export const PreviewExtraList = styled.div<{hasMarginBottom: boolean}>`
  margin-top: ${offsetDef};
  margin-bottom: ${({hasMarginBottom}) => hasMarginBottom && offsetXLg};
`;

export const TitleWrapper = styled.div<{noTopMargin?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${offsetXLg} 0 ${offsetDef};
  ${({noTopMargin}) => noTopMargin && `margin-top: 0`};
`;

export const StyledLeftSideItem = styled(LeftSideItem)<{
  paddingTop?: string;
  paddingBottom?: string;
  isNoBorderTop?: boolean;
}>`
  padding-bottom: ${({paddingBottom}) => paddingBottom};
  padding-top: ${({paddingTop}) => paddingTop};

  ${({isNoBorderTop}) =>
    isNoBorderTop &&
    css`
      &:before {
        display: none;
      }
    `};

  & + & {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
`;

export const RoomTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
  margin-bottom: ${offsetDef};
`;

export const Wrapper = styled.div<{hasMarginBottom: boolean}>`
  margin-bottom: ${({hasMarginBottom}) => hasMarginBottom && offsetXLg};

  &:not(:first-child) {
    margin-top: ${offsetXXLg};
  }
`;

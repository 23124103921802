import {useMemo} from 'react';

import {sequencesArrayByRange} from 'utils/helpers';

type TProps = {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  siblingCount?: number;
};

export const DOTS = '...';

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: TProps) => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount * 2 + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount * 2 + 5;

    // If the number of pages is less than the page numbers dont show
    if (totalPageNumbers >= totalPageCount) {
      return sequencesArrayByRange(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount,
    );

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
      Dont show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if Pagination
      component size which we dont want
    */
    const shouldShowLeftDots = leftSiblingIndex - firstPageIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = sequencesArrayByRange(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = sequencesArrayByRange(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = sequencesArrayByRange(
        leftSiblingIndex,
        rightSiblingIndex,
      );
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);
};

import React, {FC, ReactNode, useState} from 'react';
import styled from 'styled-components';
import {Button, Layout, Menu} from 'antd';
import {offsetXLg, textColor} from 'constants/styleVars';
import {DoubleLeftOutlined, DoubleRightOutlined} from '@ant-design/icons';
import {MenuInfo} from 'rc-menu/lib/interface';

export const StyledSider = styled(Layout.Sider)`
  background-color: #ffffff;
  box-shadow: inset -1px 0 0 #e8e8e8;
  ${({collapsible}) => (!collapsible ? `padding-top: ${offsetXLg};` : '')}
`;

const CollapsibleHeader = styled(Menu.Item)`
  cursor: default;
  margin: 0 !important;
  height: 48px !important;
  > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    color: ${textColor};
  }
`;

type TProps = {
  collapsible?: boolean;
  handleMenuClick?: (arg: MenuInfo) => void;
  selectedKeys?: string[];
  defaultSelectedKeys?: string[];
  label?: string;
  children?: ReactNode;
};

const SideBar: FC<TProps> = ({
  collapsible,
  handleMenuClick,
  selectedKeys,
  defaultSelectedKeys = ['1'],
  label = '',
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <StyledSider
      collapsible={collapsible}
      collapsed={isCollapsed}
      collapsedWidth={56}
      trigger={null}
    >
      <Menu
        mode='inline'
        onClick={handleMenuClick}
        selectedKeys={selectedKeys}
        defaultSelectedKeys={defaultSelectedKeys}
      >
        {collapsible && (
          <CollapsibleHeader disabled key='0'>
            {!isCollapsed && label}
            <Button
              type='link'
              icon={
                isCollapsed ? (
                  // @ts-ignore
                  <DoubleRightOutlined size={20} />
                ) : (
                  // @ts-ignore
                  <DoubleLeftOutlined size={20} />
                )
              }
              onClick={toggleCollapsed}
            />
          </CollapsibleHeader>
        )}
        {children}
      </Menu>
    </StyledSider>
  );
};

export default SideBar;

import styled from 'styled-components';
import {offsetDef} from 'styles/configs/offset';

export const Group = styled.div<{
  gap?: string;
  isInline?: boolean;
  justify?: string;
  direction?: string;
  wrap?: string;
  align?: string;
}>`
  display: ${({isInline}) => (isInline ? 'inline-flex' : 'flex')};
  gap: ${({gap}) => gap || offsetDef};
  justify-content: ${({justify}) => justify || 'start'};
  flex-direction: ${({direction}) => direction || 'row'};
  flex-wrap: ${({wrap}) => wrap || 'wrap'};
  align-items: ${({align}) => align || 'initial'};
`;

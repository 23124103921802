import React from 'react';

import NMMList, {Li} from 'view/components/NMMList';

import {getEnumByRoomType} from '../utils/getEnumByRoomType';

import {IAmenity} from 'types/dto/IAmenity';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';
import {Container} from './CommonAmenities.styles';
import {NW2Gray900Color} from 'constants/styleVars';
import {ERoomType} from 'types/dto/ERoomType.type';

interface IProps {
  roomType: ERoomType;
  activeAmenities: IAmenity[];
}

const CommonAmenitiesView = ({roomType, activeAmenities}: IProps) => {
  return (
    <Container>
      {Object.entries(getEnumByRoomType(roomType)).map(([key, label]) => {
        const filteredAmenities = activeAmenities.filter(
          (amenity) => amenity.type === key,
        );

        return (
          <div key={key}>
            <SectionSubTitle titleColor={NW2Gray900Color}>
              {label}
            </SectionSubTitle>
            {filteredAmenities.length > 0 ? (
              <NMMList>
                {filteredAmenities.map((amenity) => (
                  <Li key={amenity.id} data-testid={`${amenity.id}--list-item`}>
                    {amenity.name}
                  </Li>
                ))}
              </NMMList>
            ) : (
              <>-</>
            )}
          </div>
        );
      })}
    </Container>
  );
};

export default CommonAmenitiesView;

import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppSelector} from 'store/hooks';

import BookingsLeftMenu from './BookingsLeftMenu';
import BookingContentHeader from '../BookingsList/components/BookingContentHeader';
import BookingMainContent from '../BookingsList/components/BookingMainContent';
import BookingKPI from '../BookingsList/components/BookingKPI';

import {EBookingFilterType, IBookingType} from 'types/dto/IBookingType.type';
import {
  NW2Gray200Color,
  offsetXLg,
  pagePadding,
  whiteColor,
} from 'constants/styleVars';
import {useActions} from 'hooks/useActions';
import {
  bookingOverviewAmountSearchTabs,
  LEFT_MENU_WIDTH,
  setIsPagination,
} from 'view/supplier/Bookings/BookingsList/helpers';

import {Routes} from 'constants/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${whiteColor};
  height: 100%;
`;

const Section = styled.section`
  margin: ${offsetXLg};
  padding: ${pagePadding};
  background-color: ${whiteColor};
  border: 1px solid ${NW2Gray200Color};
  border-radius: 4px;
  width: calc(100% - ${LEFT_MENU_WIDTH});
`;

function BookingListDesks() {
  const {getBookingsAction, getAmountOfBookings} = useActions();
  const navigate = useNavigate();
  const location = useLocation();
  const reloadPage = location.state?.reloadPage;

  const bookingsType: IBookingType = useSelector((state) =>
    _get(state, 'bookingsSupplierDesks.bookingsType'),
  );

  const venueId = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );

  const isPagination = setIsPagination(
    bookingsType,
    EBookingFilterType.WORK_SPACE,
  );

  useEffect(() => {
    if (!venueId) return;

    getBookingsAction({
      bookingsType,
      pagination: {pageSize: isPagination ? 10 : 0},
    });
    getAmountOfBookings({...bookingOverviewAmountSearchTabs, venueId});
  }, [
    isPagination,
    getBookingsAction,
    getAmountOfBookings,
    venueId,
    bookingsType,
  ]);

  useEffect(() => {
    // trigger get bookings from notification component to refresh data
    if (reloadPage) {
      getBookingsAction({bookingsType});
      // clear location state
      navigate(Routes.bookingsListRooms, {replace: true});
    }
  }, [bookingsType, getBookingsAction, navigate, reloadPage]);

  return (
    <Wrapper>
      <BookingsLeftMenu />
      <Section>
        <BookingContentHeader bookingsType={bookingsType} venueId={venueId} />

        <BookingKPI
          bookingFilterType={EBookingFilterType.WORK_SPACE}
          bookingsType={bookingsType}
        />
        <BookingMainContent />
      </Section>
    </Wrapper>
  );
}

export default BookingListDesks;

import styled from 'styled-components';
import {
  modalMaskColor,
  modalMediumWidth,
  offsetLg,
  offsetXLg,
  offsetXXSm,
  offsetXXXLg,
  sectionBoxShadow,
  whiteColor,
} from 'constants/styleVars';

export const StyledModalWrapper = styled.div<{
  position?: 'center' | 'topRight';
  isMask?: boolean;
}>`
  display: flex;

  align-items: ${({position}) =>
    position === 'center' ? 'center' : 'stretch'};
  justify-content: ${({position}) =>
    position === 'topRight' ? 'flex-end' : 'center'};
  position: fixed;
  top: ${({position}) => (position === 'center' ? '50%' : '0')};
  left: ${({position}) => (position === 'center' ? '50%' : 'auto')};
  right: ${({position}) => (position === 'topRight' ? '0' : 'auto')};
  transform: ${({position}) =>
    position === 'center' ? 'translate(-50%, -50%)' : 'none'};
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

export const StyledModalMask = styled.div<{isMask?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({isMask}) =>
    isMask ? `${modalMaskColor}` : 'transparent'};
`;

export const StyledModalContent = styled.div<{
  width?: string;
  modalHeight?: string;
}>`
  position: relative;
  box-shadow: ${sectionBoxShadow};
  width: ${({width}) => width || `${modalMediumWidth}`};
  background-color: ${whiteColor};
  border-radius: ${offsetXXSm};
  max-height: ${({modalHeight}) => (modalHeight ? `${modalHeight}` : '100%')};
  overflow-y: auto;
`;

export const StyledModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const StyledCloseButton = styled.button`
  height: ${offsetLg};
  width: ${offsetLg};
  border: none;
  background: none;
  cursor: pointer;
`;

export const StyledModalBody = styled.div`
  overflow-y: auto;
  min-height: calc(100% - 186px);
`;

export const StyledModalHeader = styled.div<{
  isHeaderHidden?: boolean;
}>`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${whiteColor};
  display: flex;
  padding: ${offsetXXXLg} ${offsetXLg} 0 ${offsetXLg};
  justify-content: space-between;
  ${({isHeaderHidden}) => isHeaderHidden && 'display: none'};
`;

export const StyledModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: ${whiteColor};
  display: flex;
  justify-content: space-between;
`;

import {useAppSelector} from 'store/hooks';

export const useIsSearchWithBedrooms = () => {
  const meetingRequestData = useAppSelector(
    ({search}) => search.meetingRequestData,
  );

  const isSearchWithBedrooms =
    !!meetingRequestData?.length &&
    meetingRequestData.some((day) => day.accommodationData);

  return {isSearchWithBedrooms};
};

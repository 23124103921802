import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import _isEqual from 'lodash/isEqual';

import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {BookabilityTypeFormItems} from './BookabilityTypeFormItems';

import {EBookAbility} from 'types/venue';
import {ESpaceDetailsContentSections, ICommonProps} from '../../types';

interface IProps extends Partial<ICommonProps> {
  initialValues: {
    [ESpaceDetailsContentSections.BOOKABILITY_TYPE]: EBookAbility;
  };
  onEditorShowed: () => void;
  updateSpace: (values: any) => Promise<void>;
  isSending: boolean;
}

const BookabilityTypeForm = ({
  initialValues,
  updateSpace,
  isSending,
  onEditorShowed,
}: IProps) => {
  const onSubmit = useCallback(
    (values: Record<ESpaceDetailsContentSections, EBookAbility>) => {
      const updatedValue =
        values[ESpaceDetailsContentSections.BOOKABILITY_TYPE] ===
        EBookAbility.REQUEST;

      updateSpace({isRfp: updatedValue});
    },
    [updateSpace],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({handleSubmit, form, values}) => {
        const disableSave = _isEqual(values, initialValues);

        const handleCancelClick = () => {
          form.reset();
          onEditorShowed();
        };

        return (
          <form onSubmit={handleSubmit} noValidate>
            <BookabilityTypeFormItems />
            <NMMSubmitSection
              isLoading={isSending}
              handleCancel={handleCancelClick}
              disabled={disableSave}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default BookabilityTypeForm;

import React from 'react';
//TODO: Remove Antd as dependency
import {Card, Col, Row} from 'antd';
import {useAppSelector} from 'store/hooks';

import {EPackageText, IPackageResponse} from 'types/dto/IPackages.type';
import {getPackageExtrasDescriptions} from 'view/venue/NW2BookingPreview/helpers';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import Icon from 'view/components/Icon';

import {
  CardIncludedTitle,
  CardPrice,
  CardTitle,
  CardTitleWrap,
  StyledDivider,
  StyledEquipment,
  StyledList,
} from 'view/venue/NW2BookingPreview/components/Space/Space.styles';
import {
  PricePerPerson,
  TotalPrice,
} from 'view/venue/components/NW2BookingSummary/components/NW2BookingUnitCard/NW2BookingUnitCard.styles';
import {
  NW2ErrorLight50Color,
  NW2SuccessLight50Color,
  offsetDef,
} from 'styles/configs';
import {StyledPrevChange} from '../BookingChangeOverviewSidebar.styles';

const headStyle = {
  border: 'none',
  padding: `0 ${offsetDef}`,
};

const bodyStyle = {
  padding: `${offsetDef} `,
  paddingTop: '0',
};

type TProps = {
  packageId?: number;
  packagePrice?: number;
  participants?: number | null;
  isPrev?: boolean;
  isPackageChanged?: boolean;
  isParticipantsChanged?: boolean;
  currency?: string;
  totalPriceForPackageSet?: number | null;
};

const BookingChangeSidebarBodyInfoChanges = ({
  packageId,
  packagePrice,
  participants,
  currency,
  totalPriceForPackageSet,
  isPrev = false,
  isPackageChanged = false,
  isParticipantsChanged = false,
}: TProps) => {
  const packages = useAppSelector(({packages}) => packages.packages);

  const bookedPackage = packageId
    ? packages.find((item: IPackageResponse) => item.id === packageId)
    : undefined;

  const packageFoodAndBeverage = getPackageExtrasDescriptions(
    bookedPackage,
    'catering',
  );
  const packageEquipment = getPackageExtrasDescriptions(
    bookedPackage,
    'equipment',
  );

  const participantsTemplate = (
    <StyledEquipment>{participants}</StyledEquipment>
  );

  const bodyTemplate = (
    <Row
      gutter={16}
      style={{fontSize: '14px', whiteSpace: 'normal', paddingTop: '16px'}}
    >
      <Col xs={24} xl={8}>
        <CardIncludedTitle>Number</CardIncludedTitle>
      </Col>
      <Col xs={24} xl={16}>
        {isParticipantsChanged ? (
          <StyledPrevChange>{participantsTemplate}</StyledPrevChange>
        ) : (
          participantsTemplate
        )}
      </Col>
      <Col xs={24} xl={8}>
        <CardIncludedTitle>Equipment</CardIncludedTitle>
      </Col>
      <Col xs={24} xl={16}>
        <StyledEquipment>{packageEquipment?.join(', ')}</StyledEquipment>
      </Col>
      <Col xs={24} xl={8}>
        <CardIncludedTitle>Food & Beverage</CardIncludedTitle>
      </Col>
      <Col xs={24} xl={16}>
        <StyledList>
          {packageFoodAndBeverage?.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </StyledList>
      </Col>
    </Row>
  );

  const cardTitle = (
    <>
      <CardTitleWrap>
        <Icon icon='SHIPMENT_UPLOAD' size={20} />
        <CardTitle>
          {bookedPackage?.type && EPackageText[bookedPackage.type]}
        </CardTitle>
      </CardTitleWrap>
      {bodyTemplate}
    </>
  );

  const pricePerPersonTemplate = (
    <PricePerPerson>
      <PriceDisplay price={packagePrice} currency={currency} /> p/p
    </PricePerPerson>
  );

  return (
    <Card
      title={cardTitle}
      style={{
        backgroundColor: `${
          isPrev ? NW2ErrorLight50Color : NW2SuccessLight50Color
        }`,
        border: `none`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
    >
      {packagePrice && (
        <div>
          <StyledDivider />
          <Row>
            <Col span={8}>
              <CardIncludedTitle>Price</CardIncludedTitle>
            </Col>
            <Col span={16}>
              <CardPrice isFlexEnd>
                <TotalPrice>
                  <PriceDisplay
                    price={totalPriceForPackageSet}
                    currency={currency}
                  />
                  {isPackageChanged ? (
                    <StyledPrevChange>
                      {' '}
                      {pricePerPersonTemplate}
                    </StyledPrevChange>
                  ) : (
                    pricePerPersonTemplate
                  )}
                </TotalPrice>
              </CardPrice>
            </Col>
          </Row>
        </div>
      )}
    </Card>
  );
};

export default BookingChangeSidebarBodyInfoChanges;

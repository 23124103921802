import {useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {IVenue} from 'types/venue';
import {
  setLoading,
  updateCorporateVenueById,
  updatePublicVenueById,
} from 'store/venue/actions';
import {useAppDispatch} from 'store/hooks';

interface IProps {
  venue: IVenue;
  isExternalVenue: boolean;
  endHandler: () => void;
  onSuccessCallback?: () => void;
}

const useUpdateVenue = ({
  venue,
  isExternalVenue,
  endHandler,
  onSuccessCallback,
}: IProps) => {
  const [isSending, setIsSending] = useState(false);
  const dispatch = useAppDispatch();

  const isLoading = useSelector((state) => _get(state, 'venue.loading'));

  useEffect(() => {
    if (isSending && !isLoading) {
      endHandler();
      setIsSending(false);
    }
  }, [endHandler, isLoading, isSending]);

  const updateVenue = useCallback(
    async (values: any) => {
      setIsSending(true);
      dispatch(setLoading(true));

      try {
        if (isExternalVenue) {
          const updatedVenue = {
            venue: {
              ...venue,
              ...values,
              peopleOfInterest: null,
              legalContact: null,
            },
          };

          dispatch(updatePublicVenueById(updatedVenue, onSuccessCallback));
        } else {
          const updatedVenue = {venue: {...venue, ...values}};
          dispatch(
            updateCorporateVenueById(
              {...updatedVenue, accountId: venue.id},
              onSuccessCallback,
            ),
          );
        }
      } catch (error) {
        setIsSending(false);
      }
    },
    [dispatch, isExternalVenue, onSuccessCallback, venue],
  );

  return {
    updateVenue,
    isSending,
  };
};

export default useUpdateVenue;

import styled from 'styled-components';

import {
  fontWeightExtraBold,
  offsetXLg,
  offsetXXLg,
  offsetXXXXXLg,
  fontSizeSm,
  fontSizeXXXLg,
  lineHeightMd,
  lgBp,
  NW2Gray600Color,
} from 'constants/styleVars';

export const Section = styled.section<{
  sectionPadding?: string;
  sectionMargin?: string;
}>`
  padding: ${({sectionPadding}) => sectionPadding || `${offsetXLg} 0 0`};
  margin: ${({sectionMargin}) => sectionMargin || `0 0 ${offsetXXXXXLg}`};
`;

export const SectionTitle = styled.h3<{
  titleFontSize?: string;
  titleMarginBottom?: string;
  gap?: string;
}>`
  display: flex;
  align-items: center;
  gap: ${({gap}) => gap || offsetXLg};
  font-size: ${({titleFontSize}) => titleFontSize || fontSizeXXXLg};
  line-height: 1.6;
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${({titleMarginBottom}) => titleMarginBottom || offsetXXLg};
`;

export const SectionSubTitle = styled.h4<{titleColor?: string | undefined}>`
  display: flex;
  align-items: center;
  gap: ${offsetXLg};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({titleColor}) => titleColor || NW2Gray600Color};
  margin-top: ${offsetXLg};

  @media (min-width: ${lgBp}px) {
    margin-top: 0;
    margin-bottom: ${offsetXLg};
  }
`;

import React, {ReactNode} from 'react';

import {FlexContainer} from '../styles';

interface IProps {
  amenities: ReactNode[];
}

export const AmenitiesFormItems = ({amenities}: IProps) => (
  <FlexContainer>{amenities}</FlexContainer>
);

import {EAmenitiesCategories} from 'types/venue';

type TAmenityCategoryNames = {
  [key in EAmenitiesCategories]: string;
};

export const amenitiesCategoriesMap: TAmenityCategoryNames = {
  [EAmenitiesCategories.GENERAL]: 'General facilities',
  [EAmenitiesCategories.SERVICES]: 'Services',
  [EAmenitiesCategories.ENTERTAINMENT]: 'Cool stuff',
  [EAmenitiesCategories.ACCESSIBILITY]: 'Accessibility',
  [EAmenitiesCategories.TRANSPORTATION]: 'Transportation',
  [EAmenitiesCategories.WELLNESS_FITNESS]: 'Wellness / Fitness',
};

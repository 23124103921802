export enum ESupplierPrimaryContactInformationFormFields {
  EMAIL = 'email',
  PHONE = 'phone',
  FAX = 'fax',
  WEBSITE_URL = 'websiteUrl',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export enum ESupplierSocialMediaFormFields {
  FACEBOOK = 'facebookUrl',
  INSTAGRAM = 'instagramUrl',
  TWITTER = 'twitterUrl',
}

export enum ESupplierManagementContactsFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  FAX = 'fax',
}

export interface IEditFormProps {
  disabledItems?: string[];
  adaptiveColumnNumber?: number;
  gap?: number;
  isAdditionalContacts?: boolean;
  isExternalVenue?: boolean;
}

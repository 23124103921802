import styled from 'styled-components';

import {
  mdBp,
  blackColor,
  NW2Gray900Color,
  offsetXSm,
  offsetXLg,
  offsetXXLg,
  fontSizeSm,
  fontSizeMd,
  lineHeightMd,
  lineHeightLg,
  fontWeightExtraBold,
  offsetSm,
} from 'constants/styleVars';

const SECTION_TITLE_MARGIN_BOTTOM = '36px';
const CONTAINER_WIDTH = '443px';
const TITLE_HEIGHT = '38px';

export const Container = styled.div`
  width: ${CONTAINER_WIDTH};
  padding: ${offsetSm} ${offsetXLg} 0;
`;

export const SectionTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: ${offsetXLg};
  font-size: ${offsetXLg};
  line-height: 32px;
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${SECTION_TITLE_MARGIN_BOTTOM};
  height: ${TITLE_HEIGHT};
`;

export const Block = styled.div`
  margin-bottom: ${offsetXLg};
`;

export const BlockTitle = styled.p`
  margin-bottom: ${offsetXSm};
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${blackColor};
`;

export const BlockText = styled.p`
  margin: 0;
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
`;

export const FormActions = styled.div`
  display: flex;
  padding-top: ${offsetXXLg};
  gap: ${offsetXLg};
  justify-content: flex-end;

  @media (min-width: ${mdBp}px) {
    justify-content: flex-start;
  }
`;

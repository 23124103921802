import React from 'react';
import styled from 'styled-components';

import NW2AccordionItem from './components/NW2AccordionItem';

import {TNW2AccordionItem} from './types';

import {NW2Gray200Color} from 'constants/styleVars';

const Container = styled.div<{borderRadius: number}>`
  width: 100%;
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${({borderRadius}) => `${borderRadius}px`};
`;

type Props = {
  items: TNW2AccordionItem[];
  borderRadius?: number;
  isDivider?: boolean;
};

const NW2Accordion = ({
  items,
  isDivider = true,
  borderRadius = 4,
  ...restProps
}: Props) => {
  return (
    <Container borderRadius={borderRadius} {...restProps}>
      {items.map((item: TNW2AccordionItem, index) => {
        return (
          <NW2AccordionItem
            isDivider={isDivider}
            key={String(item.title) + index}
            {...item}
          />
        );
      })}
    </Container>
  );
};

export default NW2Accordion;

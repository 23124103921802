export enum EPackageType {
  HALF_DAY = 'HALF_DAY',
  FULL_DAY = 'FULL_DAY',
  FULL_DAY_WITH_DINNER = 'FULL_DAY_WITH_DINNER',
}

export enum EPackageText {
  HALF_DAY = 'Half day',
  FULL_DAY = 'Full day',
  FULL_DAY_WITH_DINNER = 'Full day with dinner',
}

export interface IPackageItem {
  extra: string;
  description: string;
  active: boolean;
}

export interface IPackageResponse {
  id: number | null;
  price: number | null;
  accommodationId: number | null;
  currency: string | null;
  equipment: IPackageItem[];
  catering: IPackageItem[];
  type: EPackageType;
  active: boolean;
}

export interface IPackageRequest {
  accommodationId: number;
  active: boolean;
  type: EPackageType;
  price: number;
}

export type TSelectedPackageItem = [number, IPackageResponse | null];

import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import OperationalHoursForm from './components/OperationalHoursForm';
import OperationalHoursInfo from './components/OperationalHoursInfo';

import {defaultOperationalTimes} from 'constants/defaultOperationalTimes';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IDay} from 'types/dto/IDay.type';
import {TStepMode} from 'types/app';
import {EAccommodationType} from 'types/dto/IPublicVenue';

type Props = {
  mode?: TStepMode;
  operationalTimes?: IDay[];
  accommodationType: EAccommodationType;
};

const OperationalHours: FC<Props> = ({
  mode,
  operationalTimes: operationalTimesFromProps,
  accommodationType,
}) => {
  const operationalTimesFromStore = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.operationalTimes`),
  );

  const workDays: IDay[] =
    operationalTimesFromProps ||
    operationalTimesFromStore ||
    defaultOperationalTimes;

  return mode === 'add' ? (
    <OperationalHoursForm
      workDays={workDays}
      accommodationType={accommodationType}
    />
  ) : (
    <OperationalHoursInfo workDays={workDays} />
  );
};

export default OperationalHours;

import React, {useState, useEffect, RefObject, useRef} from 'react';
import {useSelector} from 'react-redux';
import {notification} from 'antd';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button/NW2Button';
import withBridgeBTC from 'utils/bridgeBTC/withBridgeBTC';

import ApiInventoryService from 'infra/common/apiInventoryService';

import {
  ActionTitle,
  ActionSubtitle,
  StyledCollapseContainer,
  BottomSection,
} from './NW2BookingConfirmationStatus.styles';

type TProps = {
  config?: {
    buttonLabel: string;
    actionTitle: string;
    actionSubtitle: string;
  };
};

const notificationOptions = {
  message: "You're almost there! Check your email to set up a password.",
  duration: 4,
};

const defaultConfig = {
  actionTitle: 'Want to manage your bookings easier?',
  actionSubtitle:
    'Creating an account will allow you to easily manage past and future bookings, billing addresses, respond to multiple offers and a lot more.',
  buttonLabel: 'Create account',
};

const ActionSection = ({config = defaultConfig}: TProps) => {
  const [showActionSection, setShowActionSection] = useState(true);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState('');

  const {buttonLabel, actionTitle, actionSubtitle} = config;

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const {firstName, lastName, email, phone} = useSelector((state) =>
    _get(state, 'customer.customerBookingOrder'),
  );

  const onClickHandler = async () => {
    setLoading(true);
    try {
      await ApiInventoryService.registerCustomer({
        firstName,
        lastName,
        email: email.toLowerCase(),
        phone,
      });

      setLoading(false);
      setShowActionSection(false);
      notification.success({...notificationOptions, key: email});
    } catch (e) {
      setLoading(false);
    }
  };

  const ref: RefObject<any> = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref, setHeight]);

  return (
    <StyledCollapseContainer
      ref={ref}
      elementHeight={height}
      show={showActionSection}
    >
      <BottomSection loading={loading ? 1 : 0}>
        <ActionTitle>{actionTitle}</ActionTitle>
        <ActionSubtitle>{actionSubtitle}</ActionSubtitle>
        <NW2Button
          inline
          buttonType='primary'
          fullWidth={isMobile}
          onClick={onClickHandler}
        >
          {buttonLabel}
        </NW2Button>
      </BottomSection>
    </StyledCollapseContainer>
  );
};

export default withBridgeBTC(ActionSection);

import {ISpacesData, ISpacesDataItem} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';

export const makeAllSpacesArr = (data: ISpacesData[]) =>
  data.reduce((total: any, item: ISpacesData) => {
    return [...total, ...item.units];
  }, []);

export const filterSpacesByRoomType = (
  spaces: ISpacesData[],
  type: ERoomType,
) => {
  const arr = makeAllSpacesArr(spaces);
  const unitsByType = arr.filter(({roomType}) => roomType === type);
  return unitsByType;
};

export const filterSpacesWithFloorsByRoomType = (
  data: ISpacesData[],
  type: string,
): ISpacesData[] =>
  data.reduce((total: any, current) => {
    const spaces = current.units.filter((space: ISpacesDataItem) => {
      return space.roomType === type;
    });
    return spaces.length ? [...total, {...current, units: spaces}] : total;
  }, []);

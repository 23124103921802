import React, {memo, useCallback, useState} from 'react';
import {UploadFile} from 'antd/es/upload/interface';

import Image from 'view/components/Image';

import {NW2UploadListItem} from './NW2UploadListItem';
import {NW2UploadProgress} from '../NW2Upload/NW2UploadProgress';

import {borderRadiusDef} from 'constants/styleVars';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import {ISpacesDataItemImage} from 'types/venue';
import {THandleUpdateImages} from '../../types';
import {Container, Wrapper} from './NW2ImageList.styles';

interface IProps {
  coverImage?: TImageFile;
  images: Array<TImageFile | ISpacesDataItemImage | undefined>;
  onImageUpload: (file: Partial<UploadFile>) => void;
  onUpdateVenueImages: THandleUpdateImages;
  onImageRemove: (file: TImageFile) => void;
  onSetCoverImage: (file: TImageFile) => void;
  isInline?: boolean;
  isRemoveDisabled?: boolean;
  hasCoverLabel?: boolean | null;
}

export const NW2ImageList = memo(
  ({
    coverImage,
    images,
    onImageUpload,
    onUpdateVenueImages,
    onImageRemove,
    onSetCoverImage,
    isInline,
    isRemoveDisabled,
    hasCoverLabel,
  }: IProps) => {
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');

    const handleProgressChange = useCallback((v: number) => {
      setProgress(v);
    }, []);

    const handleFileNameChange = useCallback((v: string) => {
      setFileName(v);
    }, []);

    return (
      <Wrapper>
        <Container isInline={isInline}>
          {coverImage && (
            <Image
              key='cover-image'
              alt={coverImage.name}
              src={coverImage.url}
              borderRadius={borderRadiusDef}
              width='100%'
              height='100%'
            />
          )}

          {images.map((img, idx) => (
            <NW2UploadListItem
              key={`upload-item-${idx}`}
              img={img as TImageFile}
              onImageUpload={onImageUpload}
              onUpdateVenueImages={onUpdateVenueImages}
              onImageRemove={onImageRemove}
              onSetCoverImage={onSetCoverImage}
              handleProgressChange={handleProgressChange}
              handleFileNameChange={handleFileNameChange}
              hasCoverLabel={hasCoverLabel}
              isRemoveDisabled={isRemoveDisabled}
            />
          ))}
        </Container>

        {progress > 0 && fileName && (
          <NW2UploadProgress progress={progress} title={fileName} />
        )}
      </Wrapper>
    );
  },
);

import React from 'react';
import styled from 'styled-components';
import _chunk from 'lodash/chunk';

import {IAmenity} from 'types/dto/IAmenity';
import {
  EDesksAmenitiesCategories,
  ERoomAmenitiesCategories,
} from 'view/venue/NW2VenueProfile/components/NMMSpaces/types';
import {AMENITIES_COLUMN_WIDTH} from 'constants/app';
import {
  fontSizeMd,
  fontSizeSm,
  fontWeightExtraBold,
  lgBp,
  lineHeightLg,
  lineHeightMd,
  NW2Gray900Color,
  offsetNone,
  offsetXLg,
  offsetXSm,
} from 'constants/styleVars';
import {ERoomType} from 'types/dto/ERoomType.type';

const OFFSET_LIST = '18px';

export const SectionSubTitle = styled.h4`
  display: flex;
  align-items: center;
  gap: ${offsetXLg};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${NW2Gray900Color};
  margin-top: ${offsetXLg};

  @media (min-width: ${lgBp}px) {
    margin-top: 0;
    margin-bottom: ${offsetXLg};
  }
`;

const Items = styled.ul`
  padding-left: ${OFFSET_LIST};
  margin-bottom: ${offsetNone};
`;

const Item = styled.li`
  line-height: ${lineHeightLg};
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetXSm};
`;

const ItemContainer = styled.div<{columns: number}>`
  display: grid;
  grid-template-columns: repeat(
    ${({columns}) => columns},
    ${AMENITIES_COLUMN_WIDTH}
  );
`;

const DESK_AMENITIES_CHUNK = 4;
const MAX_EQUIPPED_AMENITIES_FOR_MEETING_ROOM = 5;

interface IProps {
  isSending?: boolean;
  isTitleShowed?: boolean;
  roomAmenities: IAmenity[];
  fieldName?: string;
  roomType: ERoomType;
  items?: (ERoomAmenitiesCategories | EDesksAmenitiesCategories)[];
}

export function useSortedAmenitiesView({
  items = [],
  fieldName = 'amenities',
  isTitleShowed,
  roomAmenities,
  roomType,
}: IProps) {
  const allAmenities: (
    | [string, ERoomAmenitiesCategories]
    | [string, EDesksAmenitiesCategories]
  )[] =
    roomType === ERoomType.MEETING_ROOM
      ? Object.entries(ERoomAmenitiesCategories)
      : Object.entries(EDesksAmenitiesCategories);

  const amenities = items.length
    ? allAmenities.filter(([, label]) => items?.includes(label))
    : allAmenities;

  const chunkSize =
    roomType === ERoomType.WORK_SPACE
      ? DESK_AMENITIES_CHUNK
      : MAX_EQUIPPED_AMENITIES_FOR_MEETING_ROOM;

  return amenities.map(([key, label]) => {
    const filteredAmenities = roomAmenities.filter(
      (amenity) => amenity.type === key,
    );

    const getColumnArray = (chunkSize: number) =>
      _chunk(filteredAmenities, chunkSize);

    const columnArray = getColumnArray(chunkSize);

    return (
      <div key={fieldName + key + label}>
        {isTitleShowed && <SectionSubTitle>{label}</SectionSubTitle>}

        {!filteredAmenities.length && (
          <Items key={fieldName + key + label}>-</Items>
        )}

        <ItemContainer columns={columnArray.length}>
          {roomType === ERoomType.MEETING_ROOM &&
            filteredAmenities.map((amenity, index) => (
              <Item key={fieldName + key + index}>{amenity.name}</Item>
            ))}

          {roomType === ERoomType.WORK_SPACE &&
            columnArray.map((amenityArray, index) => (
              <Items key={index}>
                {amenityArray.map((amenity, index) => (
                  <Item key={fieldName + key + index}>{amenity.name}</Item>
                ))}
              </Items>
            ))}
        </ItemContainer>
      </div>
    );
  });
}

import React, {useEffect} from 'react';

import MeetingRequestSectionTitle from '../MeetingRequestSectionTitle';
import MeetingRequestsAccordionItem from '../MeetingRequestsAccordionItem';

import {AccommodationContent} from './components/AccommodationContent';
import {TMeetingRoomItem, TSelectedOptions} from 'types/search';
import {TBedroomsCatering, THandleSetBedroomsCatering} from './types';
import {useInitBedroomsCatering} from '../../hooks/useInitBedroomsCatering';

type TProps = {
  isSectionExpanded: boolean;
  setIsSectionExpanded: (isSectionExpanded: boolean) => void;
  accommodationData: TSelectedOptions | null;
  isPreOrPostMeet: boolean;
  meetingRooms: TMeetingRoomItem[];
  addAccommodation: (formData?: Record<string, any>) => void;
  removeAccommodation: () => void;
  deleteDay: () => void;
  isDayToDelete: boolean;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  removeBedroomsCatering: (date: string) => void;
  maxParticipants: number;
  date: string;
};
export function AddPrePostAccommodationRequest({
  accommodationData,
  meetingRooms,
  addAccommodation,
  removeAccommodation,
  bedroomsCatering,
  handleSetBedroomsCatering,
  removeBedroomsCatering,
  isDayToDelete,
  deleteDay,
  maxParticipants,
  date,
  isSectionExpanded,
  setIsSectionExpanded,
  isPreOrPostMeet,
}: TProps) {
  const isRooms = !!(meetingRooms.length || isPreOrPostMeet);
  const isAccommodationEnabled = !!accommodationData && isRooms;

  useInitBedroomsCatering({
    accommodationData,
    bedroomsCatering,
    date,
    handleSetBedroomsCatering,
  });

  const toggleAccommodation = () => {
    if (isAccommodationEnabled) {
      removeAccommodation();
      removeBedroomsCatering(date);
      deleteDay();
    } else {
      addAccommodation();
    }
  };

  useEffect(() => {
    if (!accommodationData && isPreOrPostMeet) {
      addAccommodation();
    }
  }, [accommodationData, addAccommodation, isPreOrPostMeet]);

  return (
    <MeetingRequestsAccordionItem
      isDivider={false}
      expandable={isRooms}
      isExpanded={isSectionExpanded}
      onExpandHandler={setIsSectionExpanded}
      title={
        <MeetingRequestSectionTitle
          label='Accommodation'
          isSwitchDisabled={!isRooms || !isDayToDelete}
          isSwitchActive={isAccommodationEnabled}
          onClickSectionHandler={toggleAccommodation}
        />
      }
      content={
        isAccommodationEnabled ? (
          <AccommodationContent
            accommodationData={accommodationData}
            // meetingRooms={meetingRooms}
            isDayToDelete={isDayToDelete}
            addAccommodation={addAccommodation}
            bedroomsCatering={bedroomsCatering}
            handleSetBedroomsCatering={handleSetBedroomsCatering}
            isPreOrPostMeet={isPreOrPostMeet}
            deleteDay={deleteDay}
            maxParticipants={maxParticipants}
            date={date}
          />
        ) : null
      }
    />
  );
}

import {useEffect, useState} from 'react';

import {EMPTY_ARRAY} from 'constants/app';
import api, {ApiType} from 'infra/common/http.service';
import {ICorporateAccountCompanyForSelectCompany} from 'store/admin/types';

const useGetAllCorporateAccounts = () => {
  const [companies, setCompanies] =
    useState<ICorporateAccountCompanyForSelectCompany[]>(EMPTY_ARRAY);
  useEffect(() => {
    const getCompanies = async () => {
      try {
        const requestedData = await api.get(
          ApiType.Inventory,
          'corporate-accounts/all',
        );
        setCompanies(requestedData || EMPTY_ARRAY);
      } catch (error) {}
    };
    getCompanies();
  }, []);
  return companies;
};

export default useGetAllCorporateAccounts;

export enum EAmenityType {
  CORPORATE_OFFICE_MEETING_ROOM = 'CORPORATE_OFFICE_MEETING_ROOM',
  CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT = 'CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT',
  CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY = 'CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY',

  CORPORATE_OFFICE_WORK_DESK = 'CORPORATE_OFFICE_WORK_DESK',
  CORPORATE_OFFICE_WORK_DESK_EQUIPMENT = 'CORPORATE_OFFICE_WORK_DESK_EQUIPMENT',
  CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY = 'CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY',

  VENUE_ENTERTAINMENT = 'VENUE_ENTERTAINMENT',
  VENUE = 'VENUE',
  VENUE_SERVICE = 'VENUE_SERVICE',
  VENUE_ACCESSIBILITY = 'VENUE_ACCESSIBILITY',
  VENUE_TRANSPORTATION = 'VENUE_TRANSPORTATION',
  VENUE_WELLNESS_AND_FITNESS = 'VENUE_WELLNESS_AND_FITNESS',
}

export const venueAmenitiesSet = [
  EAmenityType.VENUE_ENTERTAINMENT,
  EAmenityType.VENUE,
  EAmenityType.VENUE_SERVICE,
  EAmenityType.VENUE_ACCESSIBILITY,
  EAmenityType.VENUE_TRANSPORTATION,
  EAmenityType.VENUE_WELLNESS_AND_FITNESS,
];

export const roomAmenitiesSet = [
  EAmenityType.CORPORATE_OFFICE_MEETING_ROOM,
  EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT,
  EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
];

export const deskAmenitiesSet = [
  EAmenityType.CORPORATE_OFFICE_WORK_DESK,
  EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT,
  EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
];

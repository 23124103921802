import React, {ReactNode} from 'react';
import {DrawerProps, ModalProps} from 'antd';
import {useAppSelector} from 'store/hooks';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import StyledModal from 'view/components/Modal';

import {modalSmallWidth} from 'constants/styleVars';
import {
  drawerBodyStyle,
  modalBodyStyles,
  StyledIcon,
} from 'view/auth/utils/auth.styles';

type TProps = {
  onClose: () => void;
  children: ReactNode;
};

export function LoginRegisterContainer({
  onClose,
  ...rest
}: TProps & DrawerProps & ModalProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const content = (
    <>
      <StyledIcon icon='LOGO' />
      {rest.children}
    </>
  );

  return isMobile ? (
    <StyledAntdDrawer bodyStyle={drawerBodyStyle} onClose={onClose} {...rest}>
      {content}
    </StyledAntdDrawer>
  ) : (
    <StyledModal
      width={modalSmallWidth}
      bodyStyle={modalBodyStyles}
      onCancel={onClose}
      footer={null}
      title={null}
      {...rest}
    >
      {content}
    </StyledModal>
  );
}

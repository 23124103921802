import {
  ECreateOfferFormFields,
  TBedroomsType,
  TCreateOfferItem,
  TFormState,
} from './types';
import {EEventType} from 'types/venue';
import {IOfferDay, IRequestDay} from 'types/offer';

export const getBedroomsFromState = (
  formState: TFormState,
  unitId: string,
): TBedroomsType | undefined =>
  /**
   * final-form bug, it sets not existed values from prev state
   * so bedroom prices placed by path formState[unitId]?.[unitId]
   * this path has configured through fieldName prop for StepBedroomsPrices
   */
  // @ts-ignore
  formState[unitId]?.[unitId]?.[ECreateOfferFormFields.PRICES_BEDROOMS];

/**
 * make payload for offer creation
 */
interface IGetCreateOfferPayload {
  accommodationId: number;
  creatorName: string;
  requestId: string;
  itemsToOffer: TCreateOfferItem[];
  requestDays: (IRequestDay | IOfferDay)[];
}
export const getCreateOfferPayload = ({
  accommodationId,
  creatorName,
  requestId,
  itemsToOffer,
  requestDays,
}: IGetCreateOfferPayload) => ({
  accommodationId,
  creatorName,
  requestId,
  days: requestDays.map((day) => {
    const isMeetingDay = day.eventType === EEventType.DAY && day.items?.length;

    if (isMeetingDay) {
      return {
        requestDayId: day.id as string,
        items: itemsToOffer
          .map((item) => {
            const requestedItem = day.items?.find(
              ({id}) => id === item.requestItemId,
            );

            if (requestedItem) {
              return {
                unitId: item.unitId,
                requestItemId: item.requestItemId,
              };
            }
          })
          .filter(Boolean),
        bedrooms:
          itemsToOffer.find(
            ({requestItemId}) =>
              requestItemId ===
              requestDays.find(({id}) => id === day.id)?.items?.[0].id,
          )?.bedrooms || [],
      };
    }

    const accommodationDay = itemsToOffer.find(
      ({requestItemId}) => requestItemId === day.id,
    );

    return {
      requestDayId: day.id as string,
      bedrooms: accommodationDay?.bedrooms,
    };
  }),
});

import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {EEventType} from 'types/venue';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {makeOfferPreviewUnitsData} from 'view/venue/Offer/helpers';
import {useAppSelector} from 'store/hooks';
import {clearOfferDetails} from 'store/offers/offersSlice';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {setRedirectLink} from 'store/app/appSlice';
import {setVenue} from 'store/venue/actions';
import {
  setPreviewAttendees,
  setPreviewRooms,
} from 'store/bookingsCustomer/bookingsCustomerSlice';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';

export function useOfferBookingData(venueId: number) {
  const dispatch = useDispatch();

  const [dataReady, setDataReady] = useState(false);

  const user = useAppSelector(({app}) => app.user);
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const offerDetails = useAppSelector(({offers}) => offers.offerDetails);

  const {previewRooms} = useCustomerOrderUnits();

  const {billingAddress, firstName, lastName, email, phone} = user;
  const {checkIn, checkOut, days = []} = offerDetails;

  const {preArrivals, postEvents} = useMemo(
    () => ({
      preArrivals: getFilteredUnitsByEventType(days, EEventType.PRE_ARRIVAL),
      postEvents: getFilteredUnitsByEventType(days, EEventType.POST_EVENT),
    }),
    [days],
  );

  // clear store data on page leave
  useEffect(() => {
    return () => {
      dispatch(setPreviewRooms([]));
      dispatch(setPreviewAttendees([]));
      dispatch(setRedirectLink(''));
      dispatch(clearOfferDetails());
      dispatch(setVenue(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (previewRooms.length) {
      setDataReady(true);
    }
  }, [previewRooms]);

  useEffect(() => {
    if (!venueId || !checkIn || previewRooms.length) return;

    const previewRoomsData = makeOfferPreviewUnitsData({
      checkIn,
      checkOut,
      days: getFilteredUnitsByEventType(days),
      extrasOption,
    });

    dispatch(setPreviewRooms(previewRoomsData));
  }, [
    checkIn,
    checkOut,
    days,
    dispatch,
    extrasOption,
    previewRooms.length,
    venueId,
  ]);

  const formInitialValues = useMemo(() => {
    const defaultBillingAddress = billingAddress?.find(
      (address: ICustomerBillingAddress) => address.isDefault,
    );

    return {
      firstName,
      lastName,
      email,
      phone,
      ...(defaultBillingAddress || {}),
      orderDays: previewRooms,
    };
  }, [billingAddress, email, firstName, lastName, phone, previewRooms]);

  if (!dataReady) return null;

  return {
    formInitialValues,
    preArrivals,
    postEvents,
  };
}

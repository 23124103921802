import React from 'react';

import {FormInputWithButton} from './FormInputWithButton';

import {IExtrasOption} from 'types/dto/IExtras.type';
import {ECreateOfferFormFields} from '../types';

interface IProps {
  fieldName: ECreateOfferFormFields;
  extras: IExtrasOption[];
}

export function StepExtrasPrices({fieldName, extras}: IProps) {
  return (
    <>
      {extras.map(({name}) => (
        <FormInputWithButton key={name} name={name} fieldName={fieldName} />
      ))}
    </>
  );
}

import styled from 'styled-components';

import {NW2FormItemSelect} from 'view/components/NW2FormItem/NW2FormItem';

import {
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXXXXLg,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  height: 100%;
  padding: ${offsetXXXXXLg} ${offsetXXLg} ${offsetDef} ${offsetXXLg};
`;

export const Header = styled.div``;

export const Main = styled.div`
  height: calc(100% - 36px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const PageContent = styled.div<{width?: number}>`
  width: ${({width}) => (width ? width + 'px' : '')};
`;

const IMAGES_GAP = offsetXLg;
export const PageImages = styled.div<{isInline?: boolean}>`
  display: flex;
  justify-content: ${({isInline}) => (isInline ? '' : 'center')};
  align-items: center;
  gap: ${IMAGES_GAP};
  flex-wrap: wrap;
  margin-bottom: ${offsetXXLg};
`;

export const PageImagesUploadInputWrapper = styled.div`
  max-width: 210px;
  margin: 0 auto;
`;

export const AmenitiesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: ${offsetXLg};
`;

export const PageFormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXLg};
`;

export const StyledSelect = styled(NW2FormItemSelect)<{minWidth?: string}>`
  margin: 0;

  ${({minWidth}) => `
    min-width: ${minWidth}
  `};

  input {
    text-transform: none;
  }
`;

export const ImageWrapper = styled.div<{itemWidth?: string}>`
  display: flex;
  position: relative;
  width: ${({itemWidth}) =>
    itemWidth || `calc((100% / 3) - ${IMAGES_GAP} * 2)`};

  > div {
    flex: 1;
  }
`;

export const RadioRow = styled.div`
  display: flex;
  gap: ${offsetXLg};
`;

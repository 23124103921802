import {ChangeEvent, FocusEvent, useCallback} from 'react';
import {useForm} from 'react-final-form';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {formatNumberByCurrency, inputTextToNumber} from 'utils/helpers';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {US_FORMAT_CURRENCIES} from 'constants/app';
import {parseStringToNumber} from 'utils/stringUtils';

export function useInputPriceChange(isNumberFormat?: boolean) {
  const currency = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const isUSFormat = US_FORMAT_CURRENCIES.includes(currency);

  /**
   * mutators={{ setValue: ([field, value], state, {changeValue}) => changeValue(state, field, () => value) }}
   */
  const {mutators} = useForm();

  const onChangePrice = useCallback(
    (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const onChange = (v: string) => {
        mutators.setValue(type, v);
      };

      inputTextToNumber(e.target.value, onChange, false, '', !isUSFormat);
    },
    [mutators, isUSFormat],
  );

  const onBlurPrice = useCallback(
    (type: string) => (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value) {
        const valueToSet = isNumberFormat
          ? formatNumberByCurrency(value, currency)
          : parseStringToNumber(value).toFixed(2);

        mutators.setValue(type, valueToSet);
      }
    },
    [mutators, currency, isNumberFormat],
  );

  return {onChangePrice, onBlurPrice};
}

import React, {memo, useState} from 'react';
import {Form, FormSpy} from 'react-final-form';
import _debounce from 'lodash/debounce';

import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';

import {PricesFormItems} from './PricesFormItems';

import {EPricesFormFields, TPricesFormFieldsValues} from './PricesForm.types';

interface IProps {
  initialValues: {
    [EPricesFormFields.PRICE_HOUR]: number;
    [EPricesFormFields.PRICE_HALF_DAY]: number;
    [EPricesFormFields.PRICE_DAY]: number;
    [EPricesFormFields.PAID_DURATION]?: string;
  };
  currencySymbol?: string;
  onEditorShowed: () => void;
  hasPriceHalfDay?: boolean;
  isSending: boolean;
  updateSpace: (values: any) => Promise<void>;
}

const PriceForm = ({
  initialValues,
  currencySymbol,
  onEditorShowed,
  updateSpace,
  isSending,
  hasPriceHalfDay,
}: IProps) => {
  const onSubmit = (values: TPricesFormFieldsValues) => {
    const updatedSpace = {
      [EPricesFormFields.PRICE_HOUR]: Number(
        values[EPricesFormFields.PRICE_HOUR],
      ),
      [EPricesFormFields.PRICE_HALF_DAY]: Number(
        values[EPricesFormFields.PRICE_HALF_DAY],
      ),
      [EPricesFormFields.PRICE_DAY]: Number(
        values[EPricesFormFields.PRICE_DAY],
      ),
      [EPricesFormFields.PAID_DURATION]: parseInt(
        values[EPricesFormFields.PAID_DURATION],
      ),
    };

    updateSpace(updatedSpace);
  };

  const [isFormDirty, setFormDirty] = useState(false);
  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit, form}) => {
        const {change} = form;

        return (
          <form onSubmit={handleSubmit}>
            <PricesFormItems
              currencySymbol={currencySymbol}
              onFormChange={change}
              hasPriceHalfDay={hasPriceHalfDay}
            />

            <NMMSubmitSection
              isLoading={isSending}
              handleCancel={onEditorShowed}
              disabled={!isFormDirty}
            />

            <FormSpy
              subscription={{dirtyFields: true}}
              onChange={processFormFields}
            />
          </form>
        );
      }}
    />
  );
};

export default memo(PriceForm);

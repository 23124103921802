import React, {ReactNode} from 'react';
import {DateTime} from 'luxon';

import DateRangeString from 'view/components/DateRangeString';
import NW2InfoCard from 'view/common/NW2InfoCard';
import StatusLabel from 'view/common/StatusLabel/StatusLabel';

import {getMinMaxDates} from '../helpers';
import {getISOString} from 'utils/dateUtils';

import {
  Block,
  InlineBlock,
  SummaryText,
  SummaryTitle,
} from '../BookingDetails.styles';
import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';
import {EOfferStatus, ERequestStatus, TOfferDetails} from 'types/offer';
import {NOT_PROVIDED_BY_BOOKER} from 'constants/app';
import {ICON_ARROW_MARGIN_IN_TABLES} from 'view/venue/Offer/constants';

type TProps = {
  bookingRequestDetails: TOfferDetails;
  dates: string[];
  totalPriceAndCurrency: string | ReactNode;
  expiresIn: string;
  isOffer: boolean;
  isRequestWithBedroom: boolean;
};

const NOT_PROVIDED = <i>{NOT_PROVIDED_BY_BOOKER}</i>;

const SummaryRequestInfo = ({
  bookingRequestDetails,
  dates,
  totalPriceAndCurrency,
  expiresIn,
  isOffer,
  isRequestWithBedroom,
}: TProps) => {
  const {number, eventName, customer, status, company} = bookingRequestDetails;
  const {min, max} = getMinMaxDates(dates);

  const isRequestPending = status === ERequestStatus.REQUEST_PENDING;

  return (
    <>
      {(status === ERequestStatus.REQUEST_PENDING ||
        status === EOfferStatus.OFFER_PENDING) && (
        <Block>
          <NW2InfoCard
            title='Why can’t I see who is requesting this?'
            type={EInfoCardType.info}
          >
            Booker’s name and contact information will become available as soon
            as, and only if, this offer is confirmed.
          </NW2InfoCard>
        </Block>
      )}
      {isRequestPending && (
        <Block>
          <SummaryTitle>Expires in</SummaryTitle>
          <SummaryText>{expiresIn}</SummaryText>
        </Block>
      )}
      <Block>
        <SummaryTitle>{isOffer ? 'Offer ID' : 'Request ID'}</SummaryTitle>
        <SummaryText>
          <span>#{number}</span>
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>Date</SummaryTitle>
        <SummaryText>
          <DateRangeString
            checkIn={getISOString(DateTime.fromISO(min))}
            checkOut={getISOString(DateTime.fromISO(max))}
            iconMargin={ICON_ARROW_MARGIN_IN_TABLES}
          />
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>Meeting name</SummaryTitle>
        <SummaryText>
          <span>{eventName || NOT_PROVIDED}</span>
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>Company name</SummaryTitle>
        <SummaryText>
          <span>{company || customer.companyName || NOT_PROVIDED}</span>
        </SummaryText>
      </Block>
      <Block>
        <SummaryTitle>
          {isOffer
            ? 'Total offer price'
            : isRequestPending
            ? 'Potential worth'
            : 'Business lost'}
        </SummaryTitle>
        <SummaryText>
          {isRequestWithBedroom ? '-' : totalPriceAndCurrency}
        </SummaryText>
      </Block>
      {!isRequestPending && (
        <Block>
          <SummaryTitle>Status</SummaryTitle>
          <InlineBlock>
            <StatusLabel status={status} />
          </InlineBlock>
        </Block>
      )}
    </>
  );
};

export default SummaryRequestInfo;

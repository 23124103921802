import React from 'react';

import TextValue from '../TextValue';
import TextBlockContainer from './components/TextBlockContainer';

type PropsType = {
  label: string;
  value?: string | string[] | number | JSX.Element | JSX.Element[] | null;
  values?: string[] | number[];
  defaultValue?: string | string[] | number | JSX.Element | null;
  valueId?: string;
  bold?: boolean;
};

const TextBlock = ({
  label,
  value,
  values,
  defaultValue = '-',
  bold = false,
  valueId = '',
}: PropsType) => {
  const valueToShow = value === 0 || !!value ? value : defaultValue;

  return (
    <TextBlockContainer>
      <TextValue secondary size='small'>
        {label}
      </TextValue>
      {values ? (
        values.map((val: string | number, index: number) => {
          return (
            <TextValue key={`text-block-${index}`} bold={bold}>
              {val}
            </TextValue>
          );
        })
      ) : (
        <TextValue id={valueId} bold={bold}>
          {valueToShow}
        </TextValue>
      )}
    </TextBlockContainer>
  );
};

export default TextBlock;

import React from 'react';

import {
  Content,
  Section,
  Title,
  Paragraph,
  StyledExternalLink,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import ContactUsBlock from '../../components/ContactUsBlock';

const TextContent = () => {
  return (
    <Content>
      <Section>
        <Title>What do we offer on this platform?</Title>
        <Paragraph>
          The platform offers a search and booking service for meeting rooms,
          work desks, and accommodations in hotels, event locations, and
          co-working spaces.
        </Paragraph>
      </Section>

      <Section>
        <Title>Do I need a user account to use this platform?</Title>
        <Paragraph>
          It is possible to use the tool without a user profile in guest mode.
          However, creating a user profile is required to make bookings and
          interact with suppliers, such as sending booking requests. A user
          profile provides access to your booking overview, which is necessary
          to review offers. Having a user profile also allows you to manage your
          bookings and billing addresses. Additionally, customers with user
          profiles can benefit from special offers that may not be available in
          guest mode.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I make a booking?</Title>
        <Paragraph>
          Go to&nbsp;
          <StyledExternalLink
            href='https://newwork.hrs.com'
            target='_blank'
            rel='noreferrer noopener'
          >
            the platform
          </StyledExternalLink>
          &nbsp; and search for your desired destination, date, and duration. In
          case you are planning for multiple days meeting, you have the chance
          to provide your requirements per day. The platform will automatically
          calculate the best price per supplier so you can compare them on the
          result list. The results list will show you all the venues that match
          your search criteria. If you have created a user profile and logged
          in, you will also be able to select venues that have not yet quoted
          prices for all the services you are looking for and send them a
          request for proposal to get received via the platform as well. Once
          you have selected a venue, you can view its description and services.
          It is possible to add additional services or technical equipment to
          your order before confirming. To complete your booking your details
          and a billing address are required.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What is the difference between "book" and "request for offer"?
        </Title>
        <Paragraph>
          When you conduct a search, you will find venues which provide the
          prices for all services you are asking for, while others have not
          provided all of them yet. If all prices are provided, you can send
          your booking requests immediately. In this case, the supplier is asked
          to reconfirm the availability. Once done, your booking is binding. If
          you are interested in getting an offer from a venue who has not yet
          provided the prices for all services, you can use the “request offer”
          flow. In this case, the supplier has up to 48h to provide you with an
          offer for your requested services. Such an offer is binding until the
          option date you defined before sending the request. Once you confirm
          the offer before that option date is reached, your booking is binding.
          You can also select multiple venues when requesting offers to compare
          the venues as well as their pricing at a glance.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What is the difference between "search" and "fast search"?
        </Title>
        <Paragraph>
          The platform offers two search options to cater to your specific
          needs. The "Fast Search" button is designed for planning simple,
          one-day meetings without any additional services, allowing you to
          quickly find and book a suitable venue and get a general overview of
          existing suppliers within your preferred destination. On the other
          hand, the "Search" button is the better choice for organizing more
          complex meetings that require additional services such as food and
          beverages, equipment, meeting packages, or accommodation. By using the
          search request template, you can input your specific requirements,
          ensuring that the search results only include venues that can meet
          your needs.
        </Paragraph>
        <Paragraph>
          The main benefit of using the "Search" option is that the platform
          will calculate the cost for the entire meeting package on the result
          list, making it easier for you to compare venue offers without having
          to wait until the shopping basket stage. Although the "Search" option
          may require more effort initially, it provides a better user
          experience by allowing you to create a more comprehensive and tailored
          meeting package, and the price calculation feature on the result list
          enables you to make informed decisions more efficiently.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          How can I add equipment and catering services to my meeting request?
        </Title>
        <Paragraph>
          Once you search for a venue on the platform using the "Search" option,
          you will be directed to the request template. Here, simply click on
          the "Food & Beverages" or "Equipment" button. This action will
          automatically add these services to your request based on the number
          of participants you have specified, providing a convenient starting
          point for your event planning. After the services have been added
          according to the participant count, you can activate the edit mode to
          further customize your request. In edit mode, you have the flexibility
          to tailor the automatically added services to suit your specific needs
          and preferences. You can modify the quantities, make specific
          selections, or add any additional services you require for your
          meeting, such as particular audio-visual equipment, coffee, or
          breakfast options. This allows you to fine-tune the details of your
          event, ensuring that all aspects of your meeting are addressed
          precisely as you envision.
        </Paragraph>
        <Paragraph>
          If the venue has predefined prices for these services, you will see
          the associated costs as you add them to your request. However, if the
          venue has not set prices for certain services, you can still include
          them in your RFP. After submitting your request, the venue will send
          you an offer with pricing for all the services you've selected.
          Additionally, you'll have the opportunity to review your services on
          the review and confirm booking step. At this stage, you can remove any
          services you no longer wish to include by making the necessary
          adjustments.
        </Paragraph>
        <Paragraph>
          Additionally, you'll have the opportunity to review your services on
          the review and confirm booking step. At this stage, you can remove any
          services you no longer wish to include by making the necessary
          adjustments.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I add accommodation to my meeting request?</Title>
        <Paragraph>
          When planning a multi-day meeting or event on the platform, you can
          easily add accommodation to your request. Once you search for a venue
          on the platform using the "Search" option, you will be directed to the
          request template. Here, simply click on the “accommodation” button.
          This action will automatically add accommodation to your request based
          on the number of participants you have specified, providing a
          convenient starting point for your event planning. You can also add
          pre- and post-meeting accommodation.
        </Paragraph>
        <Paragraph>
          After the accommodation has been added according to the participant
          count, you can activate the edit mode to further customize your
          request. You can select rooms for single or double occupancy. Also,
          you can choose whether the accommodation should include or exclude
          breakfast. For double use guest rooms, the breakfast charge will be
          for two people.
        </Paragraph>
        <Paragraph>
          The search results will display hotels with rooms that align with your
          accommodation needs, ensuring a precise match for your request.
        </Paragraph>
      </Section>

      <Section>
        <Title>What are the meeting packages?</Title>
        <Paragraph>
          When you book a meeting through the platform, you can take advantage
          of the meeting package feature, which is a standardized offering
          designed to simplify the booking process for corporate meetings.
          Meeting packages come in three varieties: Half-Day Package, Full-Day
          Package, and Full-Day Package + Dinner. The Half-Day Package is
          suitable for meetings lasting less than 6 hours, while the Full-Day
          Package is designed for meetings that are 6 hours or longer. If your
          meeting extends into the evening or requires dinner service, the
          Full-Day Package + Dinner is the ideal choice. Each package includes
          the essential components for your meeting, such as the rental of the
          meeting room, necessary equipment, catering, and beverages. By
          offering these standardized packages, our platform aims to provide you
          with a transparent and straightforward booking process, with upfront
          pricing that makes it easy to understand the costs associated with
          your meeting. This streamlined approach saves you time and effort in
          the booking process, allowing you to focus on the content and
          objectives of your meeting.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I add a meeting package to my reservation?</Title>
        <Paragraph>
          On the booking confirmation page, you'll find the option to activate
          the selected meeting package, which you can do by simply clicking on
          the activation button. Before finalizing your booking, take a moment
          to review the detailed overview of the selected package, including
          information about the package contents and pricing, to ensure that
          everything is accurate and meets your expectations. Please remember
          that at least 10 participants are required to activate the meeting
          packages on the platform, ensuring cost-effectiveness and suitability
          for the venue and services provided.
        </Paragraph>
      </Section>

      <Section>
        <Title>What is the option date?</Title>
        <Paragraph>
          When you receive an offer from a supplier, the option date is a
          feature that provides you, as a customer, with a designated time frame
          to make a decision. During this period, the supplier agrees to hold
          the meeting space and accommodation for you, pending your decision.
          This feature offers a clear decision-making timeline, allowing you to
          plan accordingly while ensuring that the space remains available to
          you. With the option date in place, you can have peace of mind knowing
          that the space is being held for you, giving you the time you need to
          make an informed decision without worrying about losing the
          opportunity. It's important to keep in mind that if you decide to book
          after the option date has passed, the supplier will need to reconfirm
          availability before finalizing the booking, as they are no longer
          obligated to hold the space beyond the agreed-upon option date. The
          option date feature is designed to provide you with a transparent and
          reliable booking process, offering flexibility within a set time frame
          while allowing the supplier to manage their inventory effectively.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I accept an Offer?</Title>
        <Paragraph>
          You will get both an e-mail and an update on your booking overview
          within the platform. When receiving offers from the venues, you will
          have time until the option date to accept or decline the offer.
        </Paragraph>
      </Section>

      <Section>
        <Title>Is it possible to book services instantly?</Title>
        <Paragraph>
          All work desks are booked instantly. You will receive the booking
          confirmation immediately once you click "book now”. For meeting room
          bookings, some suppliers allow instant booking as well. In those
          cases, a booking gets binding once you click "book now" as well.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I receive my booking confirmation?</Title>
        <Paragraph>
          We will send you the booking confirmation via email to the address you
          provided in your contact details. Your booking confirmation will be
          stored within your bookings overview within the platform as well.
        </Paragraph>
      </Section>

      <Section>
        <Title>Do I need a credit card to book?</Title>
        <Paragraph>
          No. The booking is binding once confirmed. You are asked to pay for
          the venue directly. The venue will give you an invoice for the booked
          services and those you order on-site.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I pay for my booking?</Title>
        <Paragraph>
          You are asked to pay for the venue directly. The venue will give you
          an invoice for the booked services and those you order on-site. Venues
          usually expect payments on-site or via bank transfer.
        </Paragraph>
      </Section>

      <Section>
        <Title>Where do I get the invoice for my booking?</Title>
        <Paragraph>
          The venues will create the invoice for the booked items and send it to
          your email address associated with your account/provided when making a
          booking
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I pay for extras I ordered at the venue?</Title>
        <Paragraph>
          Please pay for extras, which are not part of the initial booking at
          the venue directly and on-site.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I contact my booked venue?</Title>
        <Paragraph>
          You will find the contact details and the venue's address within the
          booking confirmation and the email notification.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          How can I edit my booking that I have already made with a venue?
        </Title>
        <Paragraph>
          Within the booking confirmation you can edit your existing booking by
          adding or removing services. If the free cancellation period is not
          reached already, you can instantly decrease or remove services. If you
          want to increase or add more services, the venue is asked to reconfirm
          that it can be made available. You will be notified by the platform,
          via email and within your booking confirmation, whether the venue can
          serve you with the additional items or not. If it is not possible to
          reconfirm the changes, the initial booking remains valid.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I cancel a reservation?</Title>
        <Paragraph>
          Within the booking confirmation, you will find the option to cancel
          your reservation as part of the further actions area.
        </Paragraph>
      </Section>

      <Section>
        <Title>What kind of facilities can I expect at the venue?</Title>
        <Paragraph>
          The venues share their facilities on their venue profile. These range
          from restaurants, libraries, or e-car charging stations to
          entertainment options like a pool table. Venues will also provide
          images, descriptions about what makes them special as well as venue
          type and character.
        </Paragraph>
      </Section>

      <Section>
        <Title>How much does a meeting room or a desk cost per hour?</Title>
        <Paragraph>
          The space providers set the prices independently. The price depends on
          the location and the equipment, so it is worthwhile comparing what is
          offered and the prices! The venues are asked to provide the prices per
          hour, half-day, and full day. Our platforms’ algorithm calculates the
          best price based on your defined duration of usage.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What can I expect when booking a work desk within a transient lounge
          space?
        </Title>
        <Paragraph>
          A transient lounge space is a work area, which is not separated from
          other areas within the venue. It could be a lounge area or a
          conference foyer. These areas are convenient for multi-tasker
          collaborators who like to network and get to know their neighbors. So,
          if you enjoy the occasional chat in a more relaxed, lounge type,
          environment this is for you!
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What can I expect when booking a work desk within an open shared
          space?
        </Title>
        <Paragraph>
          An open shared space is a work area, which is separated from other
          areas within the venue. This area is shared with other co-workers. It
          could be a shared office, a business center, or a converted meeting
          room. These areas are convenient for people who want to talk and
          collaborate with colleagues or clients. Depending on the venue, these
          areas could be quiet workplaces as well. Please check the venue's unit
          descriptions.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I check in at the venue?</Title>
        <Paragraph>
          The venue staff is looking forward to welcoming you at their front
          desk. Please keep your booking confirmation at hand. Staff will
          perform the check-in with you, show you to your meeting room or work
          desk and assist you whenever help is needed.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          Do the dates and times relate to the customer's time zone or to the
          local supplier's time?
        </Title>
        <Paragraph>
          Dates and times always refer to the local supplier time at the travel
          destination.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What days and times can the meeting room and work desks be reserved?
        </Title>
        <Paragraph>
          It depends on the opening hours of the venue you are booking, and the
          venue's opening hours usually indicate the availability of staff, such
          as reception. During these hours, you can book work desks as well as
          meeting rooms, which can be booked on site. All other requests require
          confirmation from the venue and are subject to availability.
        </Paragraph>
      </Section>

      <ContactUsBlock />
    </Content>
  );
};

export default TextContent;

import styled from 'styled-components';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {
  borderRadiusDef,
  borderRadiusLg,
  datepickerZIndex,
  fontSizeSm,
  fontSizeXSm,
  fontWeightBold,
  lineHeightMd,
  offsetDef,
  offsetNone,
  offsetSm,
  offsetXSm,
  whiteColor,
} from 'constants/styleVars';

const LINK_WIDTH = '160px';
const STYLED_DROPDOWN_WIDTH = '279px';
const STYLED_INPUT_HEIGHT = '36px';

export const FormGroup = styled.div<{
  columnNumber: number;
  gap: number;
  isDesktop: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({columnNumber, isDesktop}) => (isDesktop ? columnNumber : 1)},
    1fr
  );
  grid-gap: ${({gap, isDesktop}) => (isDesktop ? gap : parseInt(offsetDef))}px;
  position: relative;
`;

export const Link = styled.a`
  width: ${LINK_WIDTH};
  text-decoration: underline;
  align-self: center;
  color: inherit;
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
`;

export const StyledParagraph = styled.p`
  margin-bottom: ${offsetXSm};
`;

export const StyledDropdown = styled.div`
  width: ${STYLED_DROPDOWN_WIDTH};
  background-color: ${whiteColor};
  border-radius: ${borderRadiusLg};
  position: absolute;
  top: 37px;
  left: ${offsetNone};
  transition: width 0.15s ease 0s;
  z-index: ${datepickerZIndex};
  overflow: hidden;
  box-shadow: 0 2.9px 13.95px 0 rgba(0, 0, 0, 0.07),
    0 8.01px 38.57px 0 rgba(0, 0, 0, 0.05),
    0 19.29px 92.85px 0 rgba(0, 0, 0, 0.04), 0 64px 250px 0 rgba(0, 0, 0, 0.02);
`;

export const StyledNW2FormItemInput = styled(NW2FormItemInput)<{
  isOpened?: boolean;
}>`
  div input {
    height: ${STYLED_INPUT_HEIGHT};
    padding: ${offsetXSm} ${offsetSm};
    font-size: ${fontSizeXSm};
    border-radius: ${borderRadiusDef};
  }
`;

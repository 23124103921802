import {IDay, IDayOfWeek} from 'types/dto/IDay.type';
import {addLeadingZeroToNumber} from './stringUtils';
import {Days} from 'constants/days';
import {EMPTY_ARRAY} from 'constants/app';
import {isNull} from './helpers';
import {getRoomTimeRangeText} from './dateUtils';
import {ELocale} from 'types/locale';

export const getDayStr = (day: IDayOfWeek): IDayOfWeek => Days[day];

export const isDayActive = (workDays: IDay[], day: string): boolean => {
  const dayOfWeek = workDays.find((d) => d.dayOfWeek === day) || ({} as IDay);

  const {timeEndHour, timeEndMinutes, timeStartHour, timeStartMinutes} =
    dayOfWeek;

  const isAllTimeValid =
    !isNull(timeEndHour) &&
    !isNull(timeEndMinutes) &&
    !isNull(timeStartHour) &&
    !isNull(timeStartMinutes);

  return (dayOfWeek.active && isAllTimeValid) || false;
};

export const isFullDayAccess = (workDays: IDay[], day: string): boolean => {
  const dayOfWeek = workDays.find((d) => d.dayOfWeek === day);

  if (!dayOfWeek) return false;

  return (
    Number(dayOfWeek.timeEndHour) === 23 &&
    Number(dayOfWeek.timeEndMinutes) === 59
  );
};

export const getFromTimeDisabledHours =
  <T extends string>(day: T, workDays: IDay[] = EMPTY_ARRAY) =>
  () => {
    const d = workDays.find(
      (d) => d.dayOfWeek === getDayStr(day as IDayOfWeek),
    );
    const tillHour = (d && +(d as any).timeEndHour) || 25; // if there is no timeEndHour set - enable all time options
    return Array.from({length: 24})
      .map((val: any, index: number) => index)
      .filter((val: number) => val >= tillHour);
  };

export const getTillTimeDisabledHours =
  <T extends string>(day: T, workDays: IDay[] = EMPTY_ARRAY) =>
  () => {
    const d = workDays.find(
      (d) => d.dayOfWeek === getDayStr(day as IDayOfWeek),
    );
    const fromHour = (d && +(d as any).timeStartHour) || -1; // if there is no timeStartHour set - enable all time options
    return Array.from({length: 24})
      .map((val: any, index: number) => index)
      .filter((val: number) => val <= fromHour);
  };

export const getTimePickerValue = (
  day: IDayOfWeek,
  time: string,
  workDays: IDay[] = EMPTY_ARRAY,
) => {
  const d = workDays.find((d) => d.dayOfWeek === getDayStr(day));
  if (!d) return '00:00';
  if (time === 'timeStart')
    return `${addLeadingZeroToNumber(
      d?.timeStartHour,
    )}:${addLeadingZeroToNumber(d?.timeStartMinutes)}`;
  if (time === 'timeEnd')
    return `${addLeadingZeroToNumber(d?.timeEndHour)}:${addLeadingZeroToNumber(
      d?.timeEndMinutes,
    )}`;
};

export const setTimeToDate = (
  hours: number | string,
  minutes: number | string,
) => {
  if (!Number.isInteger(+hours) || !Number.isInteger(+minutes)) {
    return new Date();
  }

  return new Date(new Date().setHours(+hours, +minutes, 0));
};

/**
 * get Date string with added time
 * a time format is hh:mm
 */
export const setTimeFromStringToDate = (time: string): Date => {
  const splitTime = time.split(':');
  const hours = splitTime[0];
  const minutes = splitTime[1];

  return setTimeToDate(hours, minutes);
};

const getStartEndTime = (
  day: IDayOfWeek,
  workDays: IDay[],
  locale: ELocale,
): string => {
  const d = workDays.find((d) => d.dayOfWeek === getDayStr(day));
  if (!d) return '-';

  const {timeStartHour, timeStartMinutes, timeEndHour, timeEndMinutes} = d;

  const startDate = setTimeToDate(timeStartHour, timeStartMinutes);
  const endDate = setTimeToDate(timeEndHour, timeEndMinutes);

  return getRoomTimeRangeText(startDate, endDate, locale);
};

export const getDayWorkingHours = ({
  day,
  active,
  fullDayAccess,
  workDays,
  locale,
}: {
  day: IDayOfWeek;
  active?: boolean;
  fullDayAccess?: boolean;
  workDays: IDay[];
  locale: ELocale;
}) => {
  if (active) {
    if (fullDayAccess) {
      return '24 hour access';
    }

    return getStartEndTime(day, workDays, locale);
  }

  return 'Closed';
};

import React from 'react';

import {CellContainer, CellTitle, CellData} from './styles';

interface IBlockCellProps {
  title: string;
  data: string | JSX.Element;
  noWrap?: boolean;
  smallDataFont?: boolean;
  isLongData?: boolean;
}

export const BlockCell = ({
  title,
  data,
  noWrap,
  smallDataFont,
  isLongData,
}: IBlockCellProps) => {
  return (
    <CellContainer>
      <CellTitle noWrap={noWrap}>{title}</CellTitle>
      <CellData
        noWrap={noWrap}
        smallFont={smallDataFont}
        isLongData={isLongData}
      >
        {data}
      </CellData>
    </CellContainer>
  );
};

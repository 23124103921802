import React, {memo} from 'react';
import {Form} from 'react-final-form';

import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';

import {
  EDimensionsFormFields,
  TDimensionsFormFieldsValues,
} from './DimensionsForm.types';
import {DimensionsFormItems} from './DimensionsFormItems';
import {TDimensionsInitial} from './types';

interface IProps {
  initialValues: TDimensionsInitial;
  onEditorShowed: () => void;
  updateSpace: (values: any) => Promise<void>;
  isSending: boolean;
}

const DimensionsForm = ({
  initialValues,
  isSending,
  onEditorShowed,
  updateSpace,
}: IProps) => {
  const onSubmit = async (values: TDimensionsFormFieldsValues) => {
    const updatedDimensions = {
      unitDimensions: {
        [EDimensionsFormFields.AREA]: Number(
          values[EDimensionsFormFields.AREA],
        ),
        [EDimensionsFormFields.CEILING_HEIGHT]: Number(
          values[EDimensionsFormFields.CEILING_HEIGHT],
        ),
      },
    };

    updateSpace(updatedDimensions);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit, dirty, form}) => (
        <form onSubmit={handleSubmit}>
          <DimensionsFormItems onFormChange={form.change} />

          <NMMSubmitSection
            isLoading={isSending}
            handleCancel={onEditorShowed}
            disabled={!dirty}
          />
        </form>
      )}
    />
  );
};

export default memo(DimensionsForm);

import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Field} from 'react-final-form';
import {notification} from 'antd';
import _get from 'lodash/get';

import {useAppDispatch} from 'store/hooks';
import NW2FormItemRadio from 'view/components/NW2FormItem/components/NW2FormItemRadio';
import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';
import ModalOverlay from 'view/components/ModalOverlay/ModalOverlay';
import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import ErrorMessage from 'view/components/NW2FormItem/components/ErrorMessage';

import {getPublicVenueById, setNmmSpace} from 'store/venue/actions';
import {getSortedCommonAmenities} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/utils/getSortedCommonAmenities';
import {useSortedUnitAmenities} from '../NMMSpaces/common/hooks/useSortedUnitAmenities';
import {usePaidDurationOptions} from './hooks/usePaidDurationOptions';
import {FormWizard, FormWizardPage} from 'view/components/NW2Forms';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {
  AddFloor,
  AddRoomSchema,
  AddImages,
  AddDimensions,
  AddRoomPrice,
} from './components';

import {EMPTY_ARRAY} from 'constants/app';
import {
  descriptionFieldRules,
  imagesFieldRules,
  requiredRoomSchema,
  spaceNameFieldRules,
} from 'utils/finalFormFieldRules';
import {ERadioSizes} from 'view/components/NW2FormItem/components/NW2FormItemRadio/types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  EAddMeetingRoomFormFields,
  IAddSpaceProps,
  roomSchemaInitialValues,
  TAddMeetingRoomFormValues,
} from './types';
import {
  AmenitiesContainer,
  Header,
  Main,
  PageContent,
  PageImagesUploadInputWrapper,
  RadioRow,
  Wrapper,
} from './styles';
import {EAmenityType} from 'types/amenities';
import {removeFileExtension} from 'utils/stringUtils';
import {EDimensionsFormFields} from '../NMMSpaces/common/NMMSpacesDetails/components/Dimensions/DimensionsForm.types';
import {dimensionsConverter} from 'utils/venueUtils';
import {getCorporateOffices} from 'store/corporateOffice/apiActions';
import {getNumberFromFormatString} from 'utils/helpers';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {useAddSpaceHandlers} from './hooks/useAddSpaceHandlers';

export function AddMeetingRoom({
  venueId,
  spaceFloor,
  isExternalVenue,
  isShowed,
  onToggleShow,
  venueFloors,
  commonAmenities,
  spaceAmenities,
}: IAddSpaceProps) {
  const dispatch = useAppDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const currency = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const {
    corporateAccountId,
    filesState,
    page,
    skippedPages,
    handleClose,
    handleSetFiles,
    handleSetPage,
    handleSetSkippedPages,
  } = useAddSpaceHandlers({onToggleShow});

  const onSubmit = useCallback(
    (values: TAddMeetingRoomFormValues) => {
      setSubmitting(true);

      const amenities: number[] = [];

      if (
        !skippedPages.includes(
          EAddMeetingRoomFormFields.AMENITIES_ACCESSIBILITY,
        )
      ) {
        amenities.push(
          ...values[EAddMeetingRoomFormFields.AMENITIES_ACCESSIBILITY],
        );
      }

      if (
        !skippedPages.includes(EAddMeetingRoomFormFields.AMENITIES_EQUIPMENT)
      ) {
        amenities.push(
          ...values[EAddMeetingRoomFormFields.AMENITIES_EQUIPMENT],
        );
      }

      const commonData = {
        floor: +values[EAddMeetingRoomFormFields.FLOOR],
        name: values[EAddMeetingRoomFormFields.NAME],
        description: values[EAddMeetingRoomFormFields.DESCRIPTION],
        amenities: amenities.map((item) => ({id: item})),
        unitCapacities: Object.values(
          values[EAddMeetingRoomFormFields.CAPACITIES],
        )
          .filter((item) => item.checkbox)
          .map(({capacity, setupStyle}) => ({
            capacity,
            setupStyle,
          })),
        unitDimensions: values[EAddMeetingRoomFormFields.DIMENSIONS],
        type: ERoomType.MEETING_ROOM,
        roomType: ERoomType.MEETING_ROOM,
        coverImageName: removeFileExtension(
          values[EAddMeetingRoomFormFields.COVER_IMAGE],
        ),
        imageFiles: values[EAddMeetingRoomFormFields.IMAGES],
      };

      const publicVenueData = {
        priceHour: getNumberFromFormatString(values.priceHour, currency),
        priceHalfDay: getNumberFromFormatString(values.priceHalfDay, currency),
        priceDay: getNumberFromFormatString(values.priceDay, currency),
        minimumPaidDuration: parseInt(values.minimumPaidDuration),
        isRfp: values.isRfp === 'true',
      };

      const data = isExternalVenue
        ? {...commonData, ...publicVenueData}
        : commonData;

      dispatch(
        setNmmSpace({
          id: venueId,
          data,
          onSuccessHandler: () => {
            setSubmitting(false);

            if (isExternalVenue) {
              dispatch(getPublicVenueById(venueId));
            } else {
              dispatch(getCorporateOffices(corporateAccountId, false));
            }

            notification.success({
              message: 'The meeting room has been successfully created',
              duration: 4,
            });

            handleClose();
          },
          onErrorHandler: () => {
            setSubmitting(false);
          },
        }),
      );
    },
    [
      corporateAccountId,
      currency,
      dispatch,
      handleClose,
      isExternalVenue,
      skippedPages,
      venueId,
    ],
  );

  // amenities
  const amenitiesConditionsAndAccessibility = useSortedUnitAmenities({
    spaceAmenities,
    items: [
      EAmenityType.CORPORATE_OFFICE_MEETING_ROOM,
      EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
    ],
    fieldName: EAddMeetingRoomFormFields.AMENITIES_ACCESSIBILITY,
    isTitleShowed: true,
    roomType: ERoomType.MEETING_ROOM,
  });

  const amenitiesEquipment = useSortedUnitAmenities({
    spaceAmenities,
    items: [EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT],
    fieldName: EAddMeetingRoomFormFields.AMENITIES_EQUIPMENT,
    roomType: ERoomType.MEETING_ROOM,
    chunkSize: 5,
  });

  // Initial values for amenities
  const meetingRoomAmenities =
    commonAmenities.find((room) => room.type === ERoomType.MEETING_ROOM)
      ?.allUnitsIncluded || EMPTY_ARRAY;

  // paid duration options
  const paidDurationOptions = usePaidDurationOptions();

  // initial values
  const initialValues: TAddMeetingRoomFormValues = useMemo(
    () => ({
      [EAddMeetingRoomFormFields.NAME]: '',
      [EAddMeetingRoomFormFields.IMAGES]: [],
      [EAddMeetingRoomFormFields.DESCRIPTION]: '',
      [EAddMeetingRoomFormFields.FLOOR]: String(venueFloors[0]),
      [EAddMeetingRoomFormFields.CAPACITIES]: roomSchemaInitialValues,
      [EAddMeetingRoomFormFields.AMENITIES_ACCESSIBILITY]:
        getSortedCommonAmenities({
          commonAmenities: meetingRoomAmenities,
          categories: [
            EAmenityType.CORPORATE_OFFICE_MEETING_ROOM,
            EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
          ],
        }),
      [EAddMeetingRoomFormFields.AMENITIES_EQUIPMENT]: getSortedCommonAmenities(
        {
          commonAmenities: meetingRoomAmenities,
          categories: [EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT],
        },
      ),
      [EAddMeetingRoomFormFields.DIMENSIONS]: {
        [EDimensionsFormFields.AREA]: '1',
        [EDimensionsFormFields.AREA_FEET]: String(
          dimensionsConverter('m', 1, true, 5),
        ),
        [EDimensionsFormFields.CEILING_HEIGHT]: '1',
        [EDimensionsFormFields.CEILING_HEIGHT_FEET]: String(
          dimensionsConverter('m', 1, false, 5),
        ),
      },
      [EAddMeetingRoomFormFields.PRICE_HOUR]: '',
      [EAddMeetingRoomFormFields.PRICE_HALF_DAY]: '',
      [EAddMeetingRoomFormFields.PRICE_DAY]: '',
      [EAddMeetingRoomFormFields.PAID_DURATION]: paidDurationOptions[0].value,
      [EAddMeetingRoomFormFields.COVER_IMAGE]: '',
      [EAddMeetingRoomFormFields.RFP]: 'false',
    }),
    [meetingRoomAmenities, paidDurationOptions, venueFloors],
  );

  const stepPages = useMemo(() => {
    const pages = [
      {
        title: 'Which floor is this space on?',
        fieldName: EAddMeetingRoomFormFields.FLOOR,
        onBackClick: handleClose,
        content: (
          <PageContent width={150}>
            <AddFloor
              fieldName={EAddMeetingRoomFormFields.FLOOR}
              venueFloors={venueFloors}
              spaceFloor={
                typeof spaceFloor === 'number' ? spaceFloor : venueFloors[0]
              }
            />
          </PageContent>
        ),
      },
      {
        title: 'What is this space called?',
        fieldName: EAddMeetingRoomFormFields.NAME,
        content: (
          <PageContent width={284}>
            <NW2FormItemInput
              name={EAddMeetingRoomFormFields.NAME}
              type='text'
              label='Space name'
              placeholder='Space name'
              rules={spaceNameFieldRules}
              showAllValidationErrors
            />
          </PageContent>
        ),
      },
      {
        title: 'What does this space look like?',
        fieldName: EAddMeetingRoomFormFields.IMAGES,
        content: (
          <PageContent width={774}>
            <Field
              name={EAddMeetingRoomFormFields.IMAGES}
              validate={imagesFieldRules}
            >
              {({input, meta}) => {
                const hasError =
                  meta.error && (meta.touched || meta.submitFailed);

                return (
                  <>
                    <AddImages
                      fieldName={EAddMeetingRoomFormFields.IMAGES}
                      fieldCoverName={EAddMeetingRoomFormFields.COVER_IMAGE}
                      filesState={filesState}
                      handleSetFiles={handleSetFiles}
                      onChange={input.onChange}
                    />

                    {hasError && (
                      <PageImagesUploadInputWrapper>
                        <ErrorMessage
                          hasError={hasError}
                          errorData={meta.error}
                          showAllValidationErrors
                        />
                      </PageImagesUploadInputWrapper>
                    )}
                  </>
                );
              }}
            </Field>
          </PageContent>
        ),
      },
      {
        title: 'Tell bookers more about this space.',
        fieldName: EAddMeetingRoomFormFields.DESCRIPTION,
        content: (
          <PageContent width={442}>
            <NW2FormTextarea
              name={EAddMeetingRoomFormFields.DESCRIPTION}
              label='Space description'
              placeholder='Space description'
              rules={descriptionFieldRules}
              showAllValidationErrors
              minHeight={135}
            />
          </PageContent>
        ),
      },
      {
        title: 'What should organisers expect from this space?',
        fieldName: EAddMeetingRoomFormFields.AMENITIES_ACCESSIBILITY,
        content: (
          <PageContent width={473}>
            <AmenitiesContainer>
              {amenitiesConditionsAndAccessibility}
            </AmenitiesContainer>
          </PageContent>
        ),
      },
      {
        title: 'Which layout options are available within this space?',
        fieldName: EAddMeetingRoomFormFields.CAPACITIES,
        content: (
          <PageContent width={950}>
            <AddRoomSchema
              columns={3}
              fieldName={EAddMeetingRoomFormFields.CAPACITIES}
            />
          </PageContent>
        ),
      },
      {
        title: 'What are the dimensions of this space?',
        fieldName: EAddMeetingRoomFormFields.DIMENSIONS,
        content: (
          <PageContent width={312}>
            <AddDimensions fieldName={EAddMeetingRoomFormFields.DIMENSIONS} />
          </PageContent>
        ),
      },
      {
        title: 'How much do you want to charge for this space?',
        fieldName: EAddMeetingRoomFormFields.PAID_DURATION,
        content: (
          <PageContent width={280}>
            <AddRoomPrice
              paidDurationOptions={paidDurationOptions}
              priceHourFieldName={EAddMeetingRoomFormFields.PRICE_HOUR}
              priceHalfDayFieldName={EAddMeetingRoomFormFields.PRICE_HALF_DAY}
              priceDayFieldName={EAddMeetingRoomFormFields.PRICE_DAY}
              paidDurationFieldName={EAddMeetingRoomFormFields.PAID_DURATION}
            />
          </PageContent>
        ),
      },
      {
        title: 'Is there anything else that is included in this offer?',
        fieldName: EAddMeetingRoomFormFields.AMENITIES_EQUIPMENT,
        content: (
          <PageContent width={210}>
            <AmenitiesContainer>{amenitiesEquipment}</AmenitiesContainer>
          </PageContent>
        ),
      },
      {
        title: 'Will the organisers be able to book this space instantly?',
        fieldName: EAddMeetingRoomFormFields.RFP,
        content: (
          <PageContent width={484}>
            <RadioRow>
              <NW2FormItemRadio
                name={EAddMeetingRoomFormFields.RFP}
                value='false'
                id='isRfpOff'
                label='Yes, enable instant booking'
                size={ERadioSizes.MEDIUM}
              />

              <NW2FormItemRadio
                name={EAddMeetingRoomFormFields.RFP}
                value='true'
                id='isRfpOn'
                label='No, require request-to-book first'
                size={ERadioSizes.MEDIUM}
              />
            </RadioRow>
          </PageContent>
        ),
      },
    ];

    return isExternalVenue
      ? pages
      : pages.filter(
          ({fieldName}) =>
            fieldName !== EAddMeetingRoomFormFields.PAID_DURATION &&
            fieldName !== EAddMeetingRoomFormFields.RFP,
        );
  }, [
    amenitiesConditionsAndAccessibility,
    amenitiesEquipment,
    filesState,
    handleClose,
    handleSetFiles,
    isExternalVenue,
    paidDurationOptions,
    spaceFloor,
    venueFloors,
  ]);

  const validationRules = useCallback(
    (page: number) =>
      requiredRoomSchema(EAddMeetingRoomFormFields.CAPACITIES, page),
    [],
  );

  if (!isShowed) return null;

  return (
    <ModalOverlay>
      <Wrapper>
        <Header>
          <NW2Button
            buttonType='secondary'
            minimized
            size='small'
            onClick={handleClose}
            icon={<Icon transparent icon='NW2_MOVE_BACK_THIN' />}
          />
        </Header>

        <Main>
          <FormWizard
            page={page}
            handleSetPage={handleSetPage}
            initialValues={initialValues}
            isSubmitting={isSubmitting}
            pagesToSkip={[5, 9]}
            validationRules={validationRules}
            onSubmit={onSubmit}
            handleSetSkippedPages={handleSetSkippedPages}
          >
            {stepPages.map(({content, ...rest}) => (
              <FormWizardPage key={rest.title} {...rest}>
                {content}
              </FormWizardPage>
            ))}
          </FormWizard>
        </Main>
      </Wrapper>
    </ModalOverlay>
  );
}

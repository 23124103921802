import {useEffect} from 'react';

import {useQuery} from 'hooks/useQuery';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {ERequestStatus} from 'types/offer';
import {Routes} from 'constants/routes';
import {getOfferRequestByToken} from 'store/offers/apiActions';

export function useRedirectOfferLinkAfterLogin() {
  const dispatch = useAppDispatch();
  const {token} = useQuery();
  const hmdToken = localStorage.getItem('hmdToken');
  const declineModalId = useAppSelector(({offers}) => offers.declineModalId);

  useEffect(() => {
    if (token) {
      dispatch(getOfferRequestByToken(token as string));
    }
  }, [dispatch, token]);

  const offerRequestToken = token || hmdToken;
  const request = useAppSelector(({offers}) => offers.supplierOfferRequest);
  const {status} = request;
  const requestCancelledOrDeclined =
    status === ERequestStatus.REQUEST_CANCELED ||
    status === ERequestStatus.REQUEST_DECLINED;

  const link =
    (requestCancelledOrDeclined && token) || hmdToken
      ? `${Routes.bookingsListRooms}?token=${offerRequestToken}`
      : declineModalId // show decline after login for existed user
      ? Routes.bookingsListRooms
      : '';

  return [link];
}

import React, {useCallback, useState} from 'react';
import {Form} from 'react-final-form';
import styled from 'styled-components';
import {Config} from 'final-form';

import {useAppDispatch} from 'store/hooks';
import {sendFeedback} from 'store/app/apiActions';
import {TFeedbackFormPayload} from 'types/app';

import SubjectStep from './steps/SubjectStep';
import MessageStep from './steps/MessageStep';
import {offsetDef} from 'styles/configs';

const StyledForm = styled.form`
  gap: ${offsetDef};
  display: grid;
  grid-template-columns: repeat(1, 1fr);
`;

enum FeedbackFormSteps {
  SubjectStep,
  MessageStep,
}

type TProps = {
  onClose: () => void;
};

function FeedbackBody({onClose}: TProps) {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(FeedbackFormSteps.SubjectStep);

  const onBack = useCallback(() => setStep(step - 1), [step]);
  const onNext = useCallback(() => setStep(step + 1), [step]);
  const onSubmit: Config<TFeedbackFormPayload>['onSubmit'] = (values) => {
    dispatch(sendFeedback(values));
    onClose();
  };

  const currentStep = useCallback(() => {
    switch (step) {
      case FeedbackFormSteps.SubjectStep:
        return <SubjectStep onNext={onNext} />;
      case FeedbackFormSteps.MessageStep:
        return <MessageStep onBack={onBack} />;
    }
  }, [onBack, onNext, step]);

  return (
    <Form onSubmit={onSubmit}>
      {({handleSubmit}) => (
        <StyledForm onSubmit={handleSubmit} noValidate>
          {currentStep()}
        </StyledForm>
      )}
    </Form>
  );
}

export default FeedbackBody;

import {useEffect, useState} from 'react';

import {useAppDispatch} from 'store/hooks';
import {setStoredSearchCriteria} from 'store/search/searchSlice';
import {TVenuesQuerySearchData} from 'types/search';

export function useStoredSearchCriteria({
  querySearchData,
}: {
  querySearchData: TVenuesQuerySearchData;
}) {
  const dispatch = useAppDispatch();

  const [isSearchCriteriaStored, setSearchCriteriaStored] = useState(false);
  useEffect(() => {
    if (isSearchCriteriaStored) return;
    /**
     * save search params for predefine it after change on details page & go back to results
     * uses for useGoBackToStoredSearch
     */
    dispatch(setStoredSearchCriteria(querySearchData));
    setSearchCriteriaStored(true);
  }, [dispatch, isSearchCriteriaStored, querySearchData]);
}

import {useAppSelector} from 'store/hooks';

export const useLoggedInUser = () => {
  const token = useAppSelector(({app}) => app.token);
  const userRole = useAppSelector(({app}) => app.user.role);

  const isLoggedInUser = Boolean(token && userRole);

  return {isLoggedInUser};
};

import {useEffect, useState} from 'react';

import {EMPTY_ARRAY} from 'constants/app';
import {TDateType} from 'types/dto/ISearch.types';

export function useTimeRangeValidation({
  valueStart,
  valueEnd,
}: {
  valueStart: TDateType;
  valueEnd: TDateType;
}): {invalidStartValues: Date[]; invalidEndValues: Date[]} {
  const [invalidStartValues, setInvalidStartValues] =
    useState<Date[]>(EMPTY_ARRAY);
  const [invalidEndValues, setInvalidEndValues] = useState<Date[]>(EMPTY_ARRAY);

  useEffect(() => {
    // validation of hours between timepickers
    if (valueStart && valueEnd) {
      const hoursStart = valueStart.getHours();
      const hoursEnd = valueEnd.getHours();

      const resStart: number[] = [];
      const resEnd: number[] = [];

      for (let i = 0; i <= hoursStart; i++) {
        resEnd.push(i);
      }

      for (let i = hoursEnd; i <= 23; i++) {
        resStart.push(i);
      }
      setInvalidStartValues(
        resStart.map((v) => {
          const date = new Date();
          date.setHours(v);
          date.setMinutes(0);
          return date;
        }),
      );
      setInvalidEndValues(
        resEnd.map((v) => {
          const date = new Date();
          date.setHours(v);
          date.setMinutes(0);
          return date;
        }),
      );
    }
  }, [valueStart, valueEnd]);

  return {
    invalidStartValues,
    invalidEndValues,
  };
}

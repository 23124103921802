import React from 'react';
import styled from 'styled-components';

import {FeedbackSubjectTypes} from 'types/app';
import SubjectButton from '../SubjectButton';

import {
  borderRadiusLg,
  fontSizeLg,
  fontWeightBold,
  offsetDef,
  offsetXLg,
} from 'constants/styleVars';
import {useForm} from 'react-final-form';

const Title = styled.h5`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  margin-bottom: 0;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: ${offsetXLg};
  grid-template-rows: 76px 76px;
  justify-items: center;
  margin-top: ${offsetDef};

  > button {
    width: 100%;
    border-radius: ${borderRadiusLg};

    &:last-of-type {
      grid-column: span 2;
      max-width: 164px;
    }
  }
`;

type TProps = {
  onNext: () => void;
};
function SubjectStep({onNext}: TProps) {
  const subjects = [
    {
      icon: 'TRIANGLE_WARN',
      text: 'Problem',
      subjectType: FeedbackSubjectTypes.PROBLEM,
    },
    {
      icon: 'NW2_LIGHT_BULB',
      text: 'Recommendation',
      subjectType: FeedbackSubjectTypes.RECOMMENDATION,
    },
    {
      icon: 'QUESTION_CIRCLED',
      text: 'Question',
      subjectType: FeedbackSubjectTypes.QUESTION,
    },
  ];

  const form = useForm();

  const onSubjectSelect = (value: FeedbackSubjectTypes) => {
    form.change('feedbackSubject', value);
    onNext();
  };

  return (
    <>
      <Title>I have a...</Title>
      <ButtonContainer>
        {subjects.map((subject) => (
          <SubjectButton
            key={subject.subjectType}
            icon={subject.icon}
            text={subject.text}
            onClick={() => onSubjectSelect(subject.subjectType)}
          />
        ))}
      </ButtonContainer>
    </>
  );
}

export default SubjectStep;

export enum EDimensionsFormFields {
  AREA = 'area',
  AREA_FEET = 'areaFeet',
  CEILING_HEIGHT = 'ceilingHeight',
  CEILING_HEIGHT_FEET = 'ceilingHeightFeet',
}

export type TDimensionsFormFieldsValues = {
  [EDimensionsFormFields.AREA]: string;
  [EDimensionsFormFields.AREA_FEET]: string;
  [EDimensionsFormFields.CEILING_HEIGHT]: string;
  [EDimensionsFormFields.CEILING_HEIGHT_FEET]: string;
};

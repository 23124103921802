import React from 'react';

export const PolicyMessage = ({
  data,
  showStar,
}: {
  data: string | undefined;
  showStar?: boolean;
}) => {
  if (!data) return null;
  const boldTagRegex = /<b>(.*?)<\/b>/g;
  const parts = data.split(boldTagRegex);
  const elements = parts.map((part, index) => {
    const isLastPart = parts.length === index + 1;

    if (index % 2 === 1) {
      return <b key={index}>{part}</b>;
    }

    if (isLastPart && showStar) {
      return <span key={index}>{part}*</span>;
    }

    return part;
  });

  return <>{elements}</>;
};

import React from 'react';
import {Form} from 'react-final-form';

import ActionCardClosable from 'view/components/ActionCardClosable';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {EDeclineReasons} from 'view/components/RequestDecline/RequestDeclineForm';
import NW2FormItemCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox';
import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';
import {
  NW2FormItemInput,
  NW2FormItemSelect,
} from 'view/components/NW2FormItem/NW2FormItem';
import {nameFieldRules, requiredFieldRules} from 'utils/finalFormFieldRules';
import {NW2Gray600Color} from 'constants/styleVars';
import {TDeclineFormData} from '../BookingChangeOverviewSidebar';
import {
  InputWrapper,
  StyledBodyContainer,
  FormItem,
  FormItemTitle,
  RequiredLabel,
  TextareaWrapper,
} from '../BookingChangeOverviewSidebar.styles';

type TProps = {
  handleFormSubmit: (formData: TDeclineFormData) => void;
};
const BookingChangeSidebarBodyDecline = ({handleFormSubmit}: TProps) => {
  const checkBoxLabelText =
    'Make this space unavailable for this time and date for other bookers';

  const declineReasons = [
    EDeclineReasons.ROOM_IS_NOT_AVAILABLE,
    EDeclineReasons.EQUIPMENT_IS_NOT_AVAILABLE,
    EDeclineReasons.CATERING_IS_NOT_AVAILABLE,
    EDeclineReasons.VENUE_FULLY_BOOKED,
    EDeclineReasons.BEDROOMS_ARE_NOT_AVAILABLE,
    EDeclineReasons.OTHER,
  ];
  const declineReasonsOptions = declineReasons.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));
  const initialValues = {
    isUnavailable: false,
  };

  return (
    <StyledBodyContainer>
      <ActionCardClosable description='The customer may cancel the booking if you decline the request.' />
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validate={(values) => {
          const errors: Record<string, string> = {};

          if (
            values.declinedReason === EDeclineReasons.OTHER &&
            !values.additionalMessage
          ) {
            errors.additionalMessage = 'Please enter additional message';
          }

          return errors;
        }}
      >
        {({handleSubmit, values}) => (
          <form onSubmit={handleSubmit} id='declineForm'>
            <FormItem>
              <FormItemTitle>What’s the reason for declining?</FormItemTitle>
              <InputWrapper>
                <NW2FormItemSelect
                  name='declinedReason'
                  label='Reason*'
                  labelColor={NW2Gray600Color}
                  rules={requiredFieldRules('reason')}
                  showAllValidationErrors
                  options={declineReasonsOptions}
                  placeholder='Select reason'
                  className='input-wrapper'
                  readonly
                />
                <RequiredLabel>(Required)</RequiredLabel>
              </InputWrapper>

              <NW2FormItemCheckbox
                name='isUnavailable'
                label={checkBoxLabelText}
                size={ECheckboxSizes.SMALL}
                id={checkBoxLabelText}
              />
            </FormItem>

            <FormItem>
              <FormItemTitle>
                Would you like to add an additional message?
              </FormItemTitle>
              <TextareaWrapper>
                <NW2FormTextarea
                  name='additionalMessage'
                  placeholder='Additional message'
                  label='Additional message'
                  labelColor={NW2Gray600Color}
                  minLength={10}
                  minHeight={75}
                />
                {values.declinedReason === EDeclineReasons.OTHER && (
                  <RequiredLabel>(Required)</RequiredLabel>
                )}
              </TextareaWrapper>
            </FormItem>
            <FormItem>
              <FormItemTitle>
                Let the booker know who declined this change request
              </FormItemTitle>
              <InputWrapper>
                <NW2FormItemInput
                  name='approverName'
                  type='text'
                  label='Your name*'
                  labelColor={NW2Gray600Color}
                  rules={nameFieldRules}
                  placeholder='Your name'
                  className='input-wrapper'
                />
                <RequiredLabel>(Required)</RequiredLabel>
              </InputWrapper>
            </FormItem>
          </form>
        )}
      </Form>
    </StyledBodyContainer>
  );
};

export default BookingChangeSidebarBodyDecline;

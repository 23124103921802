import styled from 'styled-components';
import Predictions from 'view/components/NW2SearchSection/components/SearchForm/components/NW2GoogleMapSearchInput/components/Predictions';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {
  borderRadiusDef,
  cardBoxShadow,
  formItemDropdownContainerZIndex,
  offsetXSm,
} from 'constants/styleVars';

const AutocompleteContainer = styled.div`
  position: relative;
  z-index: ${formItemDropdownContainerZIndex};
`;

const StyledPredictions = styled(Predictions)`
  position: absolute;
  width: 100%;
  top: 100%;
  margin: 0;
  z-index: 1;
  border-radius: ${borderRadiusDef};
  box-shadow: ${cardBoxShadow};
  padding: 0 ${offsetXSm} ${offsetXSm};
  border-top: 0;
`;

const StyledNW2FormItemInput = styled(NW2FormItemInput)`
  margin: 0;
  position: relative;
  z-index: 0;
`;

export {StyledNW2FormItemInput, StyledPredictions, AutocompleteContainer};

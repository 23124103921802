import React, {FC, ReactNode, useState} from 'react';
import styled, {css} from 'styled-components';

import Icon from '../Icon';
import TextValue from '../TextValue';

import {
  NW2Gray100Color,
  NW2Blue800Color,
  offsetDef,
  offsetSm,
  offsetXXSm,
  NW2Gray800Color,
  whiteColor,
  blackColor,
} from 'constants/styleVars';
import {TIconPropsType} from '../Icon/types';
import {EMPTY_OBJECT} from 'constants/app';

type TButtonPosition = 'prefix' | 'postfix';
type TTheme = 'default' | 'dark';

type Props = {
  title?: string;
  description?: ReactNode;
  buttonText?: string;
  buttonPosition?: TButtonPosition;
  icon?: string;
  iconProps?: Partial<TIconPropsType>;
  onClick?: () => void;
  className?: string; // antd needs it
  isClosable?: boolean;
  inlineButton?: boolean;
  theme?: TTheme;
  borderColor?: string;
};

const Container = styled.div<{theme: TTheme; borderColor?: string}>`
  display: flex;
  justify-content: space-between;
  padding: ${offsetSm};
  background: ${({theme}) =>
    theme === 'dark' ? NW2Gray800Color : NW2Gray100Color};
  border-radius: 4px;
  border-left: 4px solid
    ${({theme, borderColor}) =>
      borderColor
        ? borderColor
        : theme === 'dark'
        ? whiteColor
        : NW2Blue800Color};
  color: ${({theme}) => (theme === 'dark' ? whiteColor : blackColor)};
`;

const Content = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  margin-top: ${offsetXXSm};
  margin-right: ${offsetDef};
`;

const LinkText = styled(TextValue)<{
  buttonPosition: TButtonPosition;
  inline: boolean;
}>`
  order: ${({buttonPosition}) => (buttonPosition === 'prefix' ? 0 : 1)};
  margin: ${({buttonPosition}) =>
    buttonPosition === 'prefix'
      ? `0 ${offsetXXSm} 0 0`
      : `0  0  0 ${offsetXXSm}`};
  text-decoration: underline;
  cursor: pointer;
  color: inherit;

  ${({inline}) =>
    !inline &&
    css`
      margin: 0;
    `}
`;

const CloseIcon = styled(Icon)`
  margin-left: ${offsetDef};
`;

const ActionCardClosable: FC<Props> = ({
  title = '',
  description = '',
  buttonText = '',
  buttonPosition = 'prefix',
  icon = '',
  iconProps = EMPTY_OBJECT,
  inlineButton = true,
  borderColor,
  onClick,
  className, // antd needs it
  isClosable = true,
  theme = 'default',
}: Props) => {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  const hideComponent = () => setVisible(false);

  const Button = buttonText ? (
    <LinkText
      size='small'
      bold
      inline={inlineButton}
      onClick={onClick}
      buttonPosition={buttonPosition}
    >
      {buttonText}
    </LinkText>
  ) : null;

  const Description = description ? (
    <TextValue size='small' transparent inline>
      {description}
    </TextValue>
  ) : null;

  return (
    <Container theme={theme} borderColor={borderColor} className={className}>
      <Content>
        {icon ? <StyledIcon icon={icon} {...iconProps} /> : null}

        <div>
          {title ? (
            <TextValue bold transparent size='small'>
              {title}
            </TextValue>
          ) : null}

          <div>
            {buttonPosition === 'prefix' ? (
              <>
                {Button}
                {Description}
              </>
            ) : (
              <>
                {Description}
                {Button}
              </>
            )}
          </div>
        </div>
      </Content>

      {isClosable && (
        <CloseIcon icon='CLOSE' clickable onClick={hideComponent} />
      )}
    </Container>
  );
};

export default ActionCardClosable;

import React, {useState, useCallback} from 'react';
import _debounce from 'lodash/debounce';

import NMMSection from 'view/components/NMMSection';
import useVenueCharactersOptions from 'view/venue/hooks/useVenueCharactersOptions';

import {
  Description,
  EditDescription,
  EmptyCharacter,
  EditCharacter,
} from './components';

import {EVenueProfileSections} from '../../types';
import {EDescriptionState} from './NW2VenueDescription.types';
import {IVenue} from 'types/venue';

interface IProps {
  venue: IVenue;
  isExternalVenue: boolean;
}

const NW2VenueDescription = ({venue, isExternalVenue}: IProps) => {
  const descriptionDefaultState =
    !venue.venueCharacters?.length && isExternalVenue
      ? EDescriptionState.EMPTY_CHARACTER
      : EDescriptionState.DESCRIPTION;

  const [status, setStatus] = useState<EDescriptionState>(
    descriptionDefaultState,
  );
  const [isFormDirty, setFormDirty] = useState(false);

  const venueCharactersOptions = useVenueCharactersOptions();

  const handleEditClick = useCallback(
    () => setStatus(EDescriptionState.EDIT),
    [],
  );
  const handleEditEnd = useCallback(() => {
    setStatus(EDescriptionState.DESCRIPTION);
  }, []);
  const handleEmptyCharacterClick = useCallback(
    () => setStatus(EDescriptionState.EDIT_CHARACTER),
    [],
  );
  const handleEditCharacterCancel = useCallback(
    () => setStatus(EDescriptionState.EMPTY_CHARACTER),
    [],
  );
  const handleEditCharacterEnd = useCallback(
    () => setStatus(EDescriptionState.DESCRIPTION),
    [],
  );

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return (
    <NMMSection
      id={EVenueProfileSections.DESCRIPTION}
      title='Description'
      isEditOn={status !== EDescriptionState.DESCRIPTION}
      onEdit={handleEditClick}
    >
      {status === EDescriptionState.DESCRIPTION && (
        <Description
          isExternalVenue={isExternalVenue}
          description={venue.description}
          venueCharacters={venue.venueCharacters}
        />
      )}

      {status === EDescriptionState.EDIT && (
        <EditDescription
          venueCharactersOptions={venueCharactersOptions}
          handleEditEnd={handleEditEnd}
          venue={venue}
          isExternalVenue={isExternalVenue}
          isFormDirty={isFormDirty}
          processFormFields={processFormFields}
        />
      )}

      {status === EDescriptionState.EMPTY_CHARACTER && (
        <EmptyCharacter
          isExternalVenue={isExternalVenue}
          venueCharactersOptions={venueCharactersOptions}
          handleClick={handleEmptyCharacterClick}
          description={venue.description}
        />
      )}

      {status === EDescriptionState.EDIT_CHARACTER && (
        <EditCharacter
          venueCharactersOptions={venueCharactersOptions}
          handleCancel={handleEditCharacterCancel}
          handleEditCharacterEnd={handleEditCharacterEnd}
          venue={venue}
          isExternalVenue={isExternalVenue}
          isFormDirty={isFormDirty}
          processFormFields={processFormFields}
        />
      )}
    </NMMSection>
  );
};

export default NW2VenueDescription;

import React from 'react';
import {Field} from 'react-final-form';

import InputRadio from './components/InputRadio';
import {IRadioFieldProps} from './types';

const NW2FormItemRadio = ({
  id,
  name,
  label,
  type = 'radio',
  rules,
  value,
  size,
  disabled,
  onChange,
}: IRadioFieldProps) => {
  return (
    <Field
      value={value}
      type={type}
      id={id || label}
      name={name}
      validate={rules}
    >
      {({input}) => {
        const checked = input.checked || false;

        return (
          <InputRadio
            size={size}
            id={id}
            name={name}
            label={label}
            value={value}
            type={type}
            checked={checked}
            disabled={disabled}
            inputProps={input}
            onChange={onChange}
          />
        );
      }}
    </Field>
  );
};

export default NW2FormItemRadio;

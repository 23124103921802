import {EAccommodationType} from '../types/dto/IPublicVenue';
import {ESpaceSelectedTab} from '../types/venue';
import {EResourcesType} from '../types/dto/IExtras.type';

export default {
  [EAccommodationType.VENUE]: {
    venueType: 'venue',
    venueTypeCapitalized: 'Venue',
    addSpaceModalTitle: 'Add workspace to your venue',
    welcomeModalTitle: 'Your venue profile setup is complete!',
  },
  [EAccommodationType.CORPORATE_OFFICE]: {
    venueType: 'office',
    venueTypeCapitalized: 'Office',
    addSpaceModalTitle: 'Add workspace to your office',
    welcomeModalTitle: 'Your office profile setup is complete!',
  },
  [ESpaceSelectedTab.OVERVIEW]: {
    pageTitle: 'Spaces overview',
    addButtonText: 'Add Space',
    tabName: 'Overview',
  },
  [ESpaceSelectedTab.DESK]: {
    pageTitle: 'Work desks',
    addButtonText: 'Add work desk',
    tabName: 'Work Desks',
  },
  [ESpaceSelectedTab.ROOM]: {
    pageTitle: 'Meeting rooms',
    addButtonText: 'Add meeting room',
    tabName: 'Meeting rooms',
  },
  [ESpaceSelectedTab.BEDROOM]: {
    pageTitle: 'Bedrooms',
    addButtonText: 'Add bedroom',
    tabName: 'Bedrooms',
  },
  [EResourcesType.EQUIPMENT]: {
    pageTitle: 'Equipment',
    actionCardTitle: 'Set up equipment as extra service for customers to book',
    actionCardDescription:
      'You can add equipment either free of charge or add price to them as per your venue offerings.',
    actionCardButtonText: 'Add equipment',
    tabName: 'Equipment',
    notificationTitleName: 'Equipment',
    addModalTitle: 'Add equipment',
    editModalTitle: 'Modify equipment details',
    resourcesFieldLabel: 'Equipment',
    resourcesFieldPlaceholder: 'Select equipment',
  },
  [EResourcesType.FOOD_AND_BEVERAGE]: {
    pageTitle: 'Food & beverage offerings',
    actionCardTitle: 'Set up food & beverage offerings for customers to book',
    actionCardDescription:
      'You can add food & beverage offerings either free of charge or add price to them as per your catering management',
    actionCardButtonText: 'Add offering',
    tabName: 'Food & beverage',
    notificationTitleName: 'Offering',
    addModalTitle: 'Add food & beverage offering',
    editModalTitle: 'Modify food & beverage offering',
    resourcesFieldLabel: 'Offering',
    resourcesFieldPlaceholder: 'Select offering',
  },
};

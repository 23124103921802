import React, {useState} from 'react';
import _debounce from 'lodash/debounce';

import Icon from 'view/components/Icon';
import PrimaryContactInformationEditForm from 'view/components/NW2Forms/NW2SupplierContactsDetailsForm.tsx/PrimaryContactInformationEditForm';
import useUpdateVenue from 'view/venue/hooks/useUpdateVenue';

import {ICON_SIZE, parseNameString} from 'view/venue/NW2VenueProfile/helpers';
import {ESupplierPrimaryContactInformationFormFields} from 'view/components/NW2Forms/NW2SupplierContactsDetailsForm.tsx/types';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {IVenue} from 'types/venue';
import {TFormProps} from '../FormSection';
import {SectionRow, SectionText} from '../ContactsDetails.styles';
import {getContactFields, trimPhoneNumber} from '../helpers';
import {EVenueProfileContactsSections} from '../../../types';

const usePrimaryContactInformation = (venue: IVenue): TFormProps => {
  const [isEditorShowed, setEditorShowed] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);

  const isExternalVenue = venue.accommodationType === EAccommodationType.VENUE;
  const contactFields = getContactFields(venue);

  const onEditorShow = () => {
    setEditorShowed(true);
  };
  const onEditorHide = () => {
    setEditorShowed(false);
  };

  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: onEditorHide,
  });

  const onSubmit = async (values: Record<string, string>) => {
    const phone = trimPhoneNumber(values.phone);
    const updatedVenue = {
      ...venue,
      contact: {
        ...venue.contact,
        phone,
        fax: trimPhoneNumber(values.fax),
        websiteUrl: values.websiteUrl,
      },
      ...(isExternalVenue
        ? {
            supplier: {
              ...venue.supplier,
              phone,
              firstName: values.firstName,
              lastName: values.lastName,
            },
          }
        : {}),
    };

    updateVenue(updatedVenue);

    setEditorShowed(false);
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return {
    title: EVenueProfileContactsSections.PRIMARY_CONTACT_INFO,
    onSubmit,
    initialValues: contactFields,
    editForm: (
      <PrimaryContactInformationEditForm
        disabledItems={[ESupplierPrimaryContactInformationFormFields.EMAIL]}
        isExternalVenue={isExternalVenue}
      />
    ),
    showForm: (
      <div>
        {isExternalVenue && (
          <SectionRow>
            <Icon icon='NW2_USER' size={ICON_SIZE} />
            <SectionText data-testid='contacts-user-field'>
              {parseNameString(contactFields.firstName, contactFields.lastName)}
            </SectionText>
          </SectionRow>
        )}
        <SectionRow>
          <Icon icon='NW2_EMAIL' size={ICON_SIZE} />
          <SectionText data-testid='primary-email-field'>
            {contactFields.email}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_PHONE_THIN' size={ICON_SIZE} />
          <SectionText data-testid='primary-phone-field'>
            {contactFields.phone || '-'}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_FAX' size={ICON_SIZE} />
          <SectionText data-testid='contacts-fax-field'>
            {contactFields.fax || '-'}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_NETWORK' size={ICON_SIZE} />
          <SectionText data-testid='contacts-websiteurl-field'>
            {contactFields.websiteUrl || '-'}
          </SectionText>
        </SectionRow>
      </div>
    ),
    isEditorShowed,
    onEditorShow,
    onEditorHide,
    isFormDirty,
    isFormSending: isSending,
    processFormFields,
  };
};

export default usePrimaryContactInformation;

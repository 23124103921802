import {useEffect, useState} from 'react';

export const usePreloadImages = (imageUrls: string[]) => {
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  useEffect(() => {
    let isComponentUnmounted = false;
    const preloadImage = (url: string) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    };

    Promise.all(imageUrls.map(preloadImage))
      .then(() => {
        if (!isComponentUnmounted) {
          setIsImagesLoaded(true);
        }
      })
      .catch(() => {
        if (!isComponentUnmounted) {
          setIsImagesLoaded(true);
        }
      });

    return () => {
      isComponentUnmounted = true;
    };
  }, [imageUrls]);

  return isImagesLoaded;
};

import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';

import TextBlock, {TextBlockContainer} from 'view/components/TextBlock';
import TextValue from 'view/components/TextValue/TextValue';
import TruncateText from 'view/components/TruncateText';
import venueTexts from 'texts/venueTexts';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {
  EVenueCharacters,
  EVenueTypes,
  IVenueCurrencies,
  TVenueCharacterType,
  TVenueType,
} from 'types/venue';
import {mdBp, offsetXSm} from 'constants/styleVars';

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetXSm};

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr 0.5fr 1fr 1fr;
  }
`;

type TProps = {
  accommodationType: EAccommodationType;
};

const GeneralInfo = ({accommodationType}: TProps) => {
  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  const name = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.name`),
  );
  const description = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.description`),
  );
  const venueCurrencies: IVenueCurrencies | null = useSelector((state) =>
    _get(state, `venue.venueCurrencies`),
  );
  const currency = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const hkey = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.hkey`),
  );
  const venueType: TVenueType = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.venueType`),
  );
  const venueCharacters: TVenueCharacterType[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.venueCharacters`),
  );

  const itemFieldText = venueTexts[accommodationType].venueTypeCapitalized;

  const currentCurrency = useMemo(
    () => venueCurrencies?.all.find((c) => c.code === currency),
    [currency, venueCurrencies?.all],
  );

  return (
    <>
      <TextBlock
        label={`${itemFieldText} title`}
        value={name || '-'}
        valueId={`${venueTexts[accommodationType].venueType}Name`}
      />

      <TextBlock
        label={`${itemFieldText} description`}
        value={
          description ? (
            <TruncateText
              text={description}
              tag='pre'
              numberOfLines={3}
              alignToggler='right'
            />
          ) : (
            '-'
          )
        }
        valueId={`${venueTexts[accommodationType].venueType}Description`}
      />

      <TextBlockContainer>
        <InfoRow>
          {isExternalVenue && (
            <>
              <div>
                <TextValue secondary size='small'>
                  Venue currency
                </TextValue>
                <div id={`generalInformation${itemFieldText}CurrencyValue`}>
                  {currentCurrency
                    ? `${currentCurrency.name} (${currentCurrency.code})`
                    : '-'}
                </div>
              </div>

              <div>
                <TextValue secondary size='small'>
                  H-Key
                </TextValue>
                <div id={`generalInformation${itemFieldText}HKeyValue`}>
                  {hkey || '-'}
                </div>
              </div>

              <div>
                <TextValue secondary size='small'>
                  Venue type
                </TextValue>
                <div id={`generalInformation${itemFieldText}VenueType`}>
                  {EVenueTypes[venueType]}
                </div>
              </div>

              <div>
                <TextValue secondary size='small'>
                  Venue character
                </TextValue>
                <div id={`generalInformation${itemFieldText}VenueCharacters`}>
                  {venueCharacters.map((v) => EVenueCharacters[v]).join(', ')}
                </div>
              </div>
            </>
          )}
        </InfoRow>
      </TextBlockContainer>
    </>
  );
};

export default GeneralInfo;

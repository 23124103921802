import {TEditFormValues, TEditFormErrors} from '../NW2VenueDescription.types';

export const validatorDescription = (values: TEditFormValues) => {
  const errors = {} as TEditFormErrors;

  const description: string[] = [];

  if (!values.description) {
    description.push('Description is required');
  } else if (values.description.length < 150) {
    description.push(
      'Venue description needs to have at least 150 characters.',
    );
  }

  if (description.length > 0) {
    errors.description = description;
  }

  return errors;
};

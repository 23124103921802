import {
  fontSizeSm,
  fontWeightExtraBold,
  lineHeightMd,
  offsetDef,
  offsetXLg,
  offsetXSm,
} from 'constants/styleVars';
import styled from 'styled-components';

export const GroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-column-gap: ${offsetXLg};
  grid-row-gap: ${offsetXSm};
  margin-bottom: ${offsetDef};
`;

export const Group = styled.div``;

export const GroupItem = styled.div`
  margin: ${offsetDef} 0;
`;

export const Title = styled.div`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: ${lineHeightMd};
  white-space: nowrap;
  margin-bottom: ${offsetDef};
`;

export const ButtonContainer = styled.div`
  display: flex;

  & > * {
    margin-right: ${offsetXLg};
  }
`;

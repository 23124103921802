import iconMap from 'constants/iconMap';
import {EBookingStatus} from 'types/booking';
import {
  blackColor,
  NW2Error500Color,
  NW2Error50Color,
  NW2Gray100Color,
  NW2Gray300Color,
  NW2Green500Color,
  NW2Green50Color,
  NW2Info500Color,
  NW2Info50Color,
  whiteColor,
} from 'constants/styleVars';

export interface IBookingTagSchema {
  text: string;
  bgColor: string;
  bdColor: string;
  color: string;
  icon?: keyof typeof iconMap;
}

export const EBookingTagStatus = {
  ...EBookingStatus,
  NEW: 'NEW',
};

export type TBookingTagSchema = {
  [key in keyof typeof EBookingTagStatus]?: IBookingTagSchema;
};

export const tagSchema: TBookingTagSchema = Object.freeze({
  [EBookingTagStatus.CONFIRMED]: {
    text: 'Confirmed',
    bgColor: NW2Green50Color,
    bdColor: NW2Green500Color,
    color: NW2Green500Color,
  },
  [EBookingTagStatus.CANCELED]: {
    text: 'Cancelled',
    bgColor: NW2Gray100Color,
    bdColor: NW2Gray300Color,
    color: NW2Gray300Color,
  },
  [EBookingTagStatus.RFP_PENDING]: {
    text: 'Pending',
    bgColor: NW2Info50Color,
    bdColor: NW2Info500Color,
    color: NW2Info500Color,
    icon: 'NW2_WATCH_BOLD',
  },
  [EBookingTagStatus.RFP_DECLINED]: {
    text: 'Declined',
    bgColor: NW2Error50Color,
    bdColor: NW2Error500Color,
    color: NW2Error500Color,
    icon: 'TRIANGLE_WARN',
  },
  [EBookingTagStatus.RFP_CANCELLED]: {
    text: 'Cancelled',
    bgColor: NW2Gray100Color,
    bdColor: NW2Gray300Color,
    color: NW2Gray300Color,
  },
  [EBookingTagStatus.RFP_EXPIRED]: {
    text: 'Expired',
    bgColor: NW2Error50Color,
    bdColor: NW2Error500Color,
    color: NW2Error500Color,
    icon: 'NW2_INFO',
  },
  [EBookingTagStatus.PASSED]: {
    text: 'Passed',
    bgColor: 'transparent',
    bdColor: blackColor,
    color: blackColor,
  },
  [EBookingTagStatus.NEW]: {
    text: 'NEW',
    bgColor: NW2Green500Color,
    bdColor: NW2Green500Color,
    color: whiteColor,
  },
});

import styled from 'styled-components';

import {
  blackColor,
  fontSizeMd,
  fontSizeSm,
  fontSizeXLg,
  fontSizeXSm,
  fontWeightBold,
  lineHeightLg,
  lineHeightMd,
  lineHeightSm,
  lineHeightXLg,
  NW2Gray600Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXSm,
  offsetXXXXXLg,
} from 'constants/styleVars';

export const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageHeaderWrapper = styled.div<{
  maxWidth?: number;
  paddingTop?: string;
}>`
  display: flex;
  justify-content: center;
  padding-top: ${({paddingTop}) => paddingTop || offsetXXXXXLg};
  margin: 0 auto;
  max-width: ${({maxWidth}) => (maxWidth ? maxWidth + 'px' : '')};
`;

export const PageHeader = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${offsetXXLg};
`;

export const PageActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${offsetXLg};
  margin: ${offsetXXLg} 0 ${offsetDef};
`;

export const PageCount = styled.span`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
`;

export const PageTitle = styled.h2<{
  isTitleCentered?: boolean;
}>`
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightBold};
  margin: 0;
  color: ${blackColor};
  text-align: ${({isTitleCentered}) => (isTitleCentered ? 'center' : '')};
`;

export const PageSubTitle = styled.span`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray600Color};
  padding-top: ${offsetXXSm};
  text-align: center;
`;

export const ProgressBar = styled.div<{width: number}>`
  position: fixed;
  content: '';
  top: 0;
  left: 0;
  height: 8px;
  width: ${({width}) => width}%;
  background-color: ${blackColor};
  transition: width 0.3s;
`;

export const SkipLinkWrapper = styled.div`
  text-align: center;
`;

export const SkipLink = styled.button`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightBold};
  text-decoration: underline;
  cursor: pointer;
  background: 0;
  border: 0;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
`;

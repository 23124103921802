import {Key} from 'react';

import {
  EBookingFilterType,
  EKpi,
  IBookingType,
} from 'types/dto/IBookingType.type';
import {ESortFields} from 'types/app';
import {IBooking} from 'types/dto/IBooking.types';

const minute = 60000;

const LEFT_MENU_WIDTH = '240px';

const activeBookingsTabTitles = {
  [IBookingType.ARRIVING_TODAY]: 'Today',
  [IBookingType.TOMORROW_OVERVIEW]: 'Tomorrow',
  [IBookingType.UPCOMING_BOOKINGS]: 'Upcoming',
  [IBookingType.BOOKINGS_HISTORY]: 'History',
  [IBookingType.REQUESTS]: 'Requests',
};

const bookingOverviewAmountSearchTabs = {
  searchTabs: [
    IBookingType.ARRIVING_TODAY,
    IBookingType.TOMORROW_OVERVIEW,
    EBookingFilterType.RFP_PENDING,
  ],
  kpiTab: [EKpi.RFP_KPI],
};

const bookingSortFields: Record<string, Key | readonly Key[] | string> = {
  checkInDate: ESortFields.DATE_START,
  company: ESortFields.COMPANY,
  unitName: ESortFields.UNIT_NAME,
};

const setIsPagination = (
  bookingsType: IBookingType,
  bookingFilterType: EBookingFilterType,
): boolean =>
  bookingsType === IBookingType.BOOKINGS_HISTORY ||
  bookingFilterType === EBookingFilterType.RFP_DECLINED ||
  bookingFilterType === EBookingFilterType.RFP_CANCELLED ||
  bookingFilterType === EBookingFilterType.RFP_EXPIRED;

const defBookingTables: Record<string, {data: IBooking[]; isLoading: boolean}> =
  {
    arriving: {data: [], isLoading: false},
  };

const setTodayOverviewBookingTableData = (
  data: IBooking[],
): Record<string, Record<string, IBooking[] | boolean>> =>
  data.reduce(
    (
      prev: Record<string, {data: IBooking[]; isLoading: boolean}>,
      curr: IBooking,
    ) => {
      return {
        ...prev,
        arriving: {
          isLoading: false,
          data: [...prev.arriving.data, curr],
        },
      };
    },
    defBookingTables,
  );

const addBooker = (data: any) =>
  data.reduce((acc: any, pageItem: any) => {
    const updatedObject = {
      ...pageItem,
      booker: `${pageItem.firstName} ${pageItem.lastName}`,
    };
    if (pageItem) {
      acc.push(updatedObject);
    }

    return acc;
  }, []);

export {
  minute,
  LEFT_MENU_WIDTH,
  activeBookingsTabTitles,
  bookingOverviewAmountSearchTabs,
  bookingSortFields,
  setIsPagination,
  setTodayOverviewBookingTableData,
  addBooker,
};

import _get from 'lodash/get';
import _omit from 'lodash/omit';
import {message} from 'antd';
import {DateTime} from 'luxon';

import {IPublicVenue} from 'types/dto/IPublicVenue';
import {IDay} from 'types/dto/IDay.type';
import {IVenue} from 'types/venue';

interface ICustomerContactDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
}

export enum IPasswordRecover {
  FORGET = 'FORGET',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RECOVER = 'RECOVER',
}

export const isWebUrlValid = (url: string) => {
  // https://www.regextester.com/93652
  const regex = new RegExp(
    // eslint-disable-next-line max-len
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
  );

  return regex.test(url);
};

const emailRegexp =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmailValid = (email: string) => emailRegexp.test(email);
export const isPasswordValid = (newPassword: string) => {
  const regex = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
  );
  return regex.test(newPassword);
};

const VenueValidation = {
  validateOperationalTimes: (venue: IPublicVenue | IVenue) => {
    const messageEndDayBeforeStartDate =
      'The end of the working day must be more than the time of the beginning of the working day';

    const operationalTimes = _get(venue, 'operationalTimes');
    const foundOperationalTime = operationalTimes.find((oh: IDay) => oh.active);
    if (!operationalTimes || !foundOperationalTime) {
      message.error({
        type: 'error',
        content: 'Please provide at least one operational day to proceed',
        key: 'errorMessage',
      });
      return false;
    } else {
      let doesNotHaveWorkingDays = true;
      for (let i = 0; i < operationalTimes.length; i++) {
        if (
          'timeStartHour' in operationalTimes[i] &&
          'timeStartMinutes' in operationalTimes[i] &&
          'timeEndHour' in operationalTimes[i] &&
          'timeEndMinutes' in operationalTimes[i]
        ) {
          const startTime = DateTime.fromObject({
            hour: Number(`${operationalTimes[i].timeStartHour}`),
            minute: Number(`${operationalTimes[i].timeStartMinutes}`),
          });

          const endTime = DateTime.fromObject({
            hour: Number(`${operationalTimes[i].timeEndHour}`),
            minute: Number(`${operationalTimes[i].timeEndMinutes}`),
          });

          if (doesNotHaveWorkingDays && operationalTimes[i].active) {
            doesNotHaveWorkingDays = false;
          }
          if (endTime < startTime) {
            message.error({
              type: 'error',
              content: messageEndDayBeforeStartDate,
              key: 'errorMessage',
            });
            return false;
          }
        }
      }
      if (doesNotHaveWorkingDays) {
        message.error({
          type: 'error',
          content: 'Check all time values for each week day',
          key: 'errorMessage',
        });
        return false;
      }
      return true;
    }
  },
};

export const UserCommonValidation = {
  validationUserEnterPassword: (
    previousPassword: string,
    newPassword: string,
    typeError: IPasswordRecover,
  ) => {
    if (
      !isValid({
        previousPassword,
        newPassword,
      })
    ) {
      if (typeError === IPasswordRecover.FORGET) {
        message.error({
          type: 'error',
          content: 'Please enter first password and second password',
          key: 'errorMessage',
        });
      } else {
        message.error({
          type: 'error',
          content: 'Please enter old and new password',
          key: 'errorMessage',
        });
      }
      return false;
    } else {
      return true;
    }
  },
};

export const CommonValidation = {
  validateCustomerContactDetails: (data: ICustomerContactDetails) => {
    const firstName = _get(data, 'firstName', '').trim();
    const lastName = _get(data, 'lastName', '').trim();
    const email = _get(data, 'email', '').trim();
    const phone = _get(data, 'phone', '').trim();
    const companyName = _get(data, 'companyName', '').trim();
    if (
      !isEmailValid(email) ||
      !isValid({
        firstName,
        lastName,
        email,
        phone,
        companyName,
      })
    ) {
      message.error({
        type: 'error',
        content: "Please complete all 'Your details' fields",
        key: 'errorMessage',
      });
      return false;
    } else {
      return true;
    }
  },
};

const isValid = (object: any): boolean => {
  const {description, websiteUrl, facebookUrl, instagramUrl, twitterUrl} =
    object;

  const NOT_REQUIRED_FIELDS = [
    'websiteUrl',
    'facebookUrl',
    'instagramUrl',
    'twitterUrl',
  ];

  // validate 'description' field length
  if (
    !!description &&
    (description.length < 150 || description.length > 1000)
  ) {
    return false;
  }

  // validate not required web url fields
  const hasWebUrl =
    !!websiteUrl || !!facebookUrl || !!instagramUrl || !!twitterUrl;
  if (hasWebUrl) {
    if (!!websiteUrl && !isWebUrlValid(websiteUrl)) return false;
    if (!!facebookUrl && !isWebUrlValid(facebookUrl)) return false;
    if (!!instagramUrl && !isWebUrlValid(instagramUrl)) return false;
    if (!!twitterUrl && !isWebUrlValid(twitterUrl)) return false;
  }

  // validate required fields
  // remove not required fields from object
  const filteredObject = _omit(object, NOT_REQUIRED_FIELDS);
  return object
    ? Object.values(filteredObject).every((v: any): boolean => {
        if (v === null) {
          return false;
        }
        return typeof v === 'number' ? !!v : v ? !!v.length : false;
      })
    : false;
};

export default VenueValidation;

import styled, {css} from 'styled-components';

import {
  borderRadiusLg,
  borderRadiusXLg,
  fontSizeSm,
  fontSizeXSm,
  fontSizeXXLg,
  fontWeightExtraBold,
  lg,
  lineHeightMd,
  md,
  NW2Blue500Color,
  NW2Gray100Color,
  NW2Gray500Color,
  NW2Gray900Color,
  NW2Primary,
  offsetDef,
  offsetLg,
  offsetSm,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  offsetXXXLg,
  xlg,
} from 'constants/styleVars';

const ICON_ARROW_SIZE = '52px';

export const Wrapper = styled.div<{
  isMobile: boolean;
}>(({isMobile}) => {
  return css`
    display: grid;
    grid-template-columns: repeat(3, 1fr ${ICON_ARROW_SIZE}) 1fr;
    background-color: ${NW2Gray100Color};
    border-radius: ${borderRadiusXLg};
    padding: ${offsetSm};
    overflow-x: auto;

    ${isMobile &&
    css`
      margin-bottom: ${offsetXXLg};
    `};

    @media (min-width: ${md}) {
      margin: 0 0 ${offsetXXXLg};
      padding: ${offsetSm} ${offsetXXLg};
    }

    @media (min-width: ${xlg}) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${offsetLg} ${offsetXXXLg};
    }
  `;
});

export const Step = styled.div`
  display: flex;
  gap: ${offsetSm};
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div<{isActive: boolean; isHighlighted: boolean}>`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetXXSm};
  color: ${({isActive}) => (isActive ? NW2Gray900Color : NW2Gray500Color)};
  background-color: ${({isHighlighted}) => (isHighlighted ? NW2Primary : '')};
  border-radius: ${borderRadiusLg};

  ${({isHighlighted}) =>
    isHighlighted &&
    css`
      padding: 0 ${offsetXSm};
    `};

  @media (min-width: ${lg}) {
    padding: 0 ${offsetXSm};
    margin: 0;
  }

  @media (min-width: ${xlg}) {
    margin-left: -${offsetXSm};
  }
`;

export const Text = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${fontSizeXXLg};
  color: ${NW2Gray900Color};
  white-space: nowrap;

  @media (min-width: ${md}) {
    text-align: center;
  }

  @media (min-width: ${xlg}) {
    text-align: left;
  }
`;

export const StepText = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (min-width: ${md}) {
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: ${xlg}) {
    align-items: flex-start;
    height: auto;
  }
`;

export const StepImg = styled.div<{isActive: boolean; isHighlighted: boolean}>`
  display: flex;
  flex-direction: column;
  opacity: ${({isActive}) => (isActive ? 1 : 0.6)};
  align-items: center;
  position: relative;
  margin-bottom: ${offsetXXSm};

  > div {
    position: relative;
    z-index: 1;
  }

  ${({isHighlighted}) =>
    isHighlighted &&
    css`
      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 34px;
        background-color: ${NW2Blue500Color};
        bottom: -6px;
        left: 0;
        border-radius: 30px;
        z-index: 0;
        pointer-events: none;
      }
    `}
`;

export const StepArrow = styled.div`
  display: flex;
  align-items: center;
  padding: ${offsetDef};
`;

import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';

import {
  offsetDef,
  fontSizeXSm,
  lineHeightSm,
  offsetXLg,
  lgBp,
  sectionInsetXs,
  mdBp,
  sectionInsetLg,
} from 'constants/styleVars';

const breakpoint = `${lgBp}px`;

export const NW2FooterContainer = styled.div<{bottomOffset?: string}>`
  width: 100%;
  text-transform: uppercase;
  background-color: ${({theme}) => theme.footer.background};
  color: ${({theme}) => theme.footer.color};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  padding: ${sectionInsetXs} ${offsetXLg}
    ${({bottomOffset}) => (bottomOffset ? bottomOffset : sectionInsetLg)}
    ${offsetDef};

  @media (min-width: ${mdBp}px) {
    padding: ${offsetDef} 0;
  }
`;

export const FooterRow = styled.div`
  display: grid;
  grid-gap: ${sectionInsetXs};

  @media (min-width: ${mdBp}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const sharedFooterBlockStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: ${offsetDef};
  }

  @media (min-width: ${lgBp}px) {
    > * + * {
      margin-left: ${offsetXLg};
    }
  }
`;

export const NW2FooterLinkBlock = styled.div`
  display: grid;
  grid-gap: ${offsetDef};
  text-align: center;

  @media (min-width: ${mdBp}px) {
    ${sharedFooterBlockStyles};
    grid-gap: ${offsetXLg};

    > * + * {
      margin-left: 0;
    }
  }
`;

export const NW2FooterBlock = styled.div`
  ${sharedFooterBlockStyles}
`;

export const Link = styled.a`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
`;

export const StyledNavLink = styled(NavLink)<{
  fontWeight?: string;
  textDecoration?: string;
}>`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: ${({fontWeight}) => fontWeight || '400'};
  text-decoration: ${({textDecoration}) => textDecoration || 'none'};

  &:hover {
    color: ${({theme}) => theme.primaryColor};
  }
`;

export const Text = styled.span`
  min-width: 120px;
  display: inline-block;
  white-space: nowrap;

  @media (min-width: ${breakpoint}) {
    min-width: 0;
  }
`;

export const WithMarginLeftNoWrap = styled.span`
  margin-left: ${offsetXLg};
`;

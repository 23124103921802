import React, {ReactNode} from 'react';

import {
  Title,
  Text,
} from 'view/venue/NW2VenueProfile/components/Header/Header.styles';
import {
  fontSizeXSm,
  lgBp,
  NW2Gray600Color,
  offsetXLg,
} from 'constants/styleVars';
import {EVenueSupplierPagesWithHeader} from 'view/venue/NW2VenueProfile/types';
import styled from 'styled-components';
import {useIsVenueFromUSA} from 'view/venue/hooks/useIsUSA';

const GRID_WIDTH = '210px';
const LAST_GRID_WIDTH = '290px';

const Remark = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${NW2Gray600Color};
  font-size: ${fontSizeXSm};

  @media (min-width: ${lgBp}px) {
    width: ${LAST_GRID_WIDTH};
  }
`;

type TReturnProps = {
  title: string | undefined;
  subtitle?: ReactNode | string;
  items: string | number;
  content: ReactNode;
  gridWidth?: string;
  gap?: string;
};

interface IProps {
  headerStatistics?: {
    totalAvailable: number;
    active: number;
    disabled: number;
  };
  type: EVenueSupplierPagesWithHeader;
}

const useResourceHeader = ({headerStatistics, type}: IProps): TReturnProps => {
  const {totalAvailable = 0, active = 0, disabled = 0} = headerStatistics || {};
  const [isUSA] = useIsVenueFromUSA();
  return {
    title: type,
    items: 4,
    gridWidth: GRID_WIDTH,
    gap: offsetXLg,
    content: (
      <>
        <div data-testid='space-total-count'>
          <Title>Total available</Title>
          <Text>{totalAvailable}</Text>
        </div>
        <div data-testid='space-status'>
          <Title>Active</Title>
          <Text>{active}</Text>
        </div>

        <div data-testid='space-disabled'>
          <Title>Disabled</Title>
          <Text>{disabled}</Text>
        </div>

        <Remark>
          ¹All prices provided on this page are{' '}
          {isUSA ? 'excluding taxes and fees' : 'VAT included'}
        </Remark>
      </>
    ),
  };
};

export default useResourceHeader;

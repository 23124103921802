import {FilterValue, Key, SortOrder} from 'antd/es/table/interface';
import {PaginationProps} from 'antd/es/pagination/Pagination';

export enum IBookingType {
  ARRIVING_TODAY = 'ARRIVING_TODAY',
  UPCOMING_BOOKINGS = 'UPCOMING_BOOKINGS',
  TOMORROW_OVERVIEW = 'TOMORROW_OVERVIEW',
  BOOKINGS_HISTORY = 'BOOKINGS_HISTORY',
  REQUESTS = 'REQUESTS',
}

export enum EInstantBookingFilterType {
  ALL = 'ALL',
  WORK_SPACE = 'WORK_SPACE',
  MEETING_ROOM = 'MEETING_ROOM',
}

export enum EBookingFilterType {
  WORK_SPACE = 'WORK_SPACE',
  MEETING_ROOM = 'MEETING_ROOM',
  RFP_PENDING = 'RFP_PENDING',
  RFP_DECLINED = 'RFP_DECLINED',
  RFP_CANCELLED = 'RFP_CANCELLED',
  RFP_EXPIRED = 'RFP_EXPIRED',
}

export enum EKpi {
  RFP_KPI = 'RFP_KPI',
}

export enum ERfpKpi {
  HOLD_UP_FROM_0_TO_12 = 'HOLD_UP_FROM_0_TO_12',
  HOLD_UP_FROM_TODAY_TO_TOMORROW = 'HOLD_UP_FROM_TODAY_TO_TOMORROW',
  HOLD_UP_MORE_THAN_12 = 'HOLD_UP_MORE_THAN_12',
  TOTAL_PRICE = 'TOTAL_PRICE',
  RTC_KPI_UNREAD = 'RTC_KPI_UNREAD',
}

export enum ETotalFilteredRecords {
  ARRIVING_TODAY = 'ARRIVING_TODAY',
  TOMORROW_OVERVIEW = 'TOMORROW_OVERVIEW',
  RFP_PENDING = 'RFP_PENDING',
}

export enum ETableSortOrder {
  ascend = 'ASC',
  descend = 'DESC',
}

export interface ISearchTabsCount {
  [ETotalFilteredRecords.ARRIVING_TODAY]: string;
  [ETotalFilteredRecords.TOMORROW_OVERVIEW]: string;
  [ETotalFilteredRecords.RFP_PENDING]: string;
}

export interface IKpiList {
  [ERfpKpi.HOLD_UP_FROM_0_TO_12]: string;
  [ERfpKpi.HOLD_UP_FROM_TODAY_TO_TOMORROW]: string;
  [ERfpKpi.HOLD_UP_MORE_THAN_12]: string;
  [ERfpKpi.TOTAL_PRICE]: string;
  [ERfpKpi.RTC_KPI_UNREAD]: string;
}

export interface IAmountOfBookings {
  kpiList?: Partial<IKpiList>;
  searchTabsCount?: Partial<ISearchTabsCount>;
}

export interface IGetBookingsPayload {
  bookingsType: IBookingType;
  pagination?: PaginationProps;
  filters?: Record<string, FilterValue | null>;
  sortOrder?: SortOrder;
  sortField?: Key | readonly Key[] | string;
  tableDataKey?: Key;
  venueId?: number;
}

export interface IGetAmountOfBookingsPayload {
  venueId: number;
  searchTabs?: Array<IBookingType | EBookingFilterType>;
  kpiTab?: Array<EKpi>;
}

export enum EBookingTabNames {
  TODAY = 'Today',
  TOMORROW = 'Tomorrow',
  UPCOMING = 'Upcoming',
  HISTORY = 'History',
}

export const bookingTabsToBookingTypes = {
  [EBookingTabNames.TODAY]: IBookingType.ARRIVING_TODAY,
  [EBookingTabNames.TOMORROW]: IBookingType.TOMORROW_OVERVIEW,
  [EBookingTabNames.UPCOMING]: IBookingType.UPCOMING_BOOKINGS,
  [EBookingTabNames.HISTORY]: IBookingType.BOOKINGS_HISTORY,
};

export enum ERequestCellName {
  EXPIRES_IN = 'Expires in',
  RECEIVED = 'Received',
  REQUESTED_DATE = 'Requested date',
  TOTAL_PRICE = 'Total price',
  COMPANY = 'Company',
  POTENTIAL_WORTH = 'Potential worth',
  DATE = 'Date',
  NEW_TOTAL = 'New total',
}

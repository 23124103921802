import React, {FC} from 'react';

type TProps = {
  isUSA?: boolean;
  pageWithVAT?: boolean;
};

const TaxDisclaimer: FC<TProps> = ({isUSA, pageWithVAT}) => {
  const taxDisclaimerText = isUSA
    ? 'Excluding taxes and fees'
    : pageWithVAT
    ? '(VAT included)'
    : 'Includes taxes and fees';

  return <>{taxDisclaimerText}</>;
};

export default TaxDisclaimer;

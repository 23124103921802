import React, {useLayoutEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {useAppSelector} from 'store/hooks';

import NavbarItem from './components/NavbarItem';

import {NavbarContainer} from './Navbar.styles';
import {setHeaderCollapsed, setNavbarHeight} from 'store/app/appSlice';
import {Routes} from 'constants/routes';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

export type INavbarItemProps = {
  icon: string;
  title: string;
  onClick: () => void;
  primary?: boolean;
  disabled?: boolean;
};

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRole: EUserRoleCognito = useAppSelector(({app}) => app.user.role);

  const navbarElement: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);

  const navbar = navbarElement?.current;

  useLayoutEffect(() => {
    if (!navbar) return;

    const handleResize = () => {
      dispatch(setNavbarHeight(navbar.offsetHeight));
    };

    const isUserWithNavbar = [
      EUserRoleCognito.ROLE_CUSTOMER,
      EUserRoleCognito.ROLE_GUEST,
    ].includes(userRole);

    if (isUserWithNavbar) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (isUserWithNavbar) {
        dispatch(setNavbarHeight(0));
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [dispatch, navbar, userRole]);

  const navigateToVenueSearch = () => {
    dispatch(setHeaderCollapsed(false));
    navigate(Routes.mainLanding);
  };

  const navigateToBookings = () => {
    navigate(Routes.customerBookings);
  };

  const navigateToUserAccount = () => {
    navigate(Routes.customerId);
  };

  const navbarItems: INavbarItemProps[] = [
    {
      icon: 'SEARCH',
      title: 'Search',
      onClick: navigateToVenueSearch,
      primary: location.pathname === Routes.mainLanding,
    },
    {
      icon: 'BOOK_BOOKMARK',
      title: 'Bookings',
      onClick: navigateToBookings,
      primary: location.pathname === Routes.customerBookings,
    },
    {
      icon: 'USER',
      title: 'Account',
      onClick: navigateToUserAccount,
      primary: location.pathname === Routes.customerId,
    },
  ];

  return (
    <NavbarContainer id='navbar' ref={navbarElement}>
      {navbarItems.map((item: INavbarItemProps) => {
        return <NavbarItem key={item.title} {...item} />;
      })}
    </NavbarContainer>
  );
};

export default Navbar;

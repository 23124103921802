import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2SpaceDetailsContent from './components/NW2SpaceDetailsContent';
import NW2SpaceDetailsImages from './components/NW2SpaceDetailsImages';
import NW2SpaceDetailsFooter from './components/NW2SpaceDetailsFooter';
import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';

import {IUnit} from 'types/dto/IUnit.types';
import {setSpaceDetailsId} from 'store/venues/venuesSlice';
import {useQuery} from 'hooks/useQuery';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  modalTwoColumnFooterHeight,
  offsetDef,
  offsetXXLg,
  offsetXXXXLg,
} from 'constants/styleVars';
import {useAppSelector} from 'store/hooks';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {ERoomType} from 'types/dto/ERoomType.type';

type TProps = {
  isFooterHidden?: boolean;
  hasBedroom?: boolean;
};
export function NW2SpaceDetails({isFooterHidden, hasBedroom}: TProps) {
  const dispatch = useDispatch();

  const venueUnits: IUnit[] = // todo check
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.units`),
    ) || [];

  const spaceDetailsId: number | null = useSelector((state) =>
    _get(state, 'venuesReducer.spaceDetailsId'),
  );

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  const {unitId} = useQuery();

  const {venueUnits: venueDetailsUnits} = useVenueDetailsData();

  useEffect(() => {
    if (isWorkSpace) {
      if (unitId) {
        dispatch(setSpaceDetailsId(+unitId));
      }
    }
  }, [dispatch, isWorkSpace, unitId, venueDetailsUnits]);

  const closeSpaceDetails = () => dispatch(setSpaceDetailsId(null));

  const unit = (
    venueDetailsUnits?.length ? venueDetailsUnits : venueUnits
  ).find((unit: IUnit) => {
    const unitId = isWorkSpace ? unit.id : unit.unitId;
    if (!unitId) return false;

    return unitId === spaceDetailsId;
  });

  if (!unit) return null;

  const footer =
    !isFooterHidden && !hasBedroom ? (
      <NW2SpaceDetailsFooter unit={unit} />
    ) : null;

  return (
    <NW2ModalTwoColumns
      title='Space details'
      onToggleShow={closeSpaceDetails}
      colLeft={<NW2SpaceDetailsContent unit={unit as IUnit} />}
      colRight={<NW2SpaceDetailsImages images={unit.documents} />}
      customPadding={`${offsetXXXXLg} ${offsetXXLg} calc(${offsetDef} + ${modalTwoColumnFooterHeight})`}
      footer={footer}
      isRightColumnBeforeLeft
      isRefreshScroll
      isShowed
    />
  );
}

export default NW2SpaceDetails;

import React from 'react';

import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';

import {offsetXLg} from 'styles/configs/offset';
import {NW2Gray600Color} from 'styles/configs/colors';
import {descriptionFieldRules} from 'utils/finalFormFieldRules';
import {ESpaceDetailsFormFields} from '../../types';
import {FormGroup} from '../../NMMSpacesDetails.styles';

export function DescriptionFormItems() {
  return (
    <FormGroup columnNumber={1} gap={parseInt(offsetXLg)}>
      <NW2FormTextarea
        name={ESpaceDetailsFormFields.DESCRIPTION}
        label='Space description*'
        placeholder='Space description'
        rules={descriptionFieldRules}
        showAllValidationErrors
        labelColor={NW2Gray600Color}
        minHeight={135}
        inputSize='medium'
        data-testid={`input-space-details-${ESpaceDetailsFormFields.DESCRIPTION}`}
      />
    </FormGroup>
  );
}

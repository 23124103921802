import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import DateUtils from 'utils/dateUtils';

import {useAppSelector} from 'store/hooks';

import {
  fontSizeXLg,
  fontWeightExtraBold,
  lineHeightXLg,
  offsetDef,
  offsetLg,
  offsetSm,
  offsetXLg,
} from 'constants/styleVars';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 442px;
  justify-content: center;
  margin: 0 auto;
  height: 100%;

  ul {
    padding-left: ${offsetLg};
    margin-bottom: ${offsetDef};
  }
`;

const IconBox = styled.div`
  margin-bottom: ${offsetXLg};
`;

const Title = styled.div`
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetDef};
`;

const Actions = styled.div`
  display: flex;
  gap: ${offsetXLg};
  padding-top: ${offsetSm};
`;

interface IProps {
  handleClose: () => void;
  venueZone?: string;
}
export function FinishPage({handleClose, venueZone}: IProps) {
  const optionDate = useAppSelector(
    ({offers}) => offers.offerDetails.optionDate,
  );
  const locale = useAppSelector(({app}) => app.locale);

  return (
    <Wrapper>
      <IconBox>
        <Icon icon='CHECKED' size={82} />
      </IconBox>
      <Title>Sent</Title>

      <p>Your offer has been sent to the booker.</p>
      <p>
        They will now have until
        <b>
          {' '}
          {DateUtils.getFormattedDayMonthTime({
            date: optionDate,
            venueZone,
            locale,
          })}{' '}
        </b>
        to respond to it. If they do not respond within “Response deadline” time
        this offer will be considered expire and will no longer be available to
        be booked.
      </p>

      <Actions>
        <NW2Button buttonType='primary' size='small' onClick={handleClose}>
          back to overview
        </NW2Button>
      </Actions>
    </Wrapper>
  );
}

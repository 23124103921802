import {useCallback} from 'react';

import {useShortListHandlers} from 'view/venue/Offer/hooks/useShortListHandlers';
import {findCoverImage} from 'utils/helpers';
import {useVenueDetailsData} from './useVenueDetailsData';
import {useVenueDetailsId} from './useVenueDetailsId';

export function useRequestForOffer() {
  const {venueDetails} = useVenueDetailsData();
  const [venueId] = useVenueDetailsId();

  const {documents, location, name} = venueDetails;

  const {
    checkIsShortListItemAdded,
    handleAddShortListItem,
    handleRemoveShortListItem,
  } = useShortListHandlers();

  const isShortListed = checkIsShortListItemAdded?.(venueId);

  const onRequestForOfferClick = useCallback(() => {
    if (isShortListed) {
      handleRemoveShortListItem?.(venueId);
      return;
    }

    const coverImage = findCoverImage(documents).url;

    handleAddShortListItem?.({
      id: venueId,
      name,
      img: coverImage,
      location,
    });
  }, [
    documents,
    handleAddShortListItem,
    handleRemoveShortListItem,
    venueId,
    isShortListed,
    location,
    name,
  ]);

  return {
    isShortListed,
    onRequestForOfferClick,
  };
}

import {
  blackColor,
  iconColor,
  NW2Gray100Color,
  NW2Primary,
  NW2Secondary,
  whiteColor,
} from 'constants/styleVars';

export default {
  name: 'NW2',
  bodyBackground: NW2Gray100Color,
  navigation: {
    background: blackColor,
    color: whiteColor,
  },
  logo: {
    Img1: NW2Primary,
    Img2: NW2Primary,
    Img3: NW2Primary,
    Text: whiteColor,
  },
  footer: {
    background: blackColor,
    color: whiteColor,
  },
  primaryColor: NW2Primary,
  secondary: NW2Secondary,
  iconColor,
};

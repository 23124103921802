import styled, {css} from 'styled-components';

import {fontSizeXLg, fontSizeXSm, offsetXLg} from 'constants/styleVars';

const ERROR_OFFSET = '22px';

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 216px;
  grid-column-gap: ${offsetXLg};
`;

export const InputGroupDistance = styled.div<{hasError?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({hasError}) =>
    hasError &&
    css`
      margin-bottom: ${ERROR_OFFSET};
    `}

  .ant-input-number {
    &-affix-wrapper {
      position: relative;
    }

    &-prefix {
      position: absolute;
      left: 12px;
      top: -6px;
      background-color: white;
      font-size: ${fontSizeXSm};
      line-height: 1;
      z-index: 1;
      pointer-events: none;
    }
  }
`;

export const InputGroupSeparator = styled.div`
  font-size: ${fontSizeXLg};
  line-height: 1;
`;

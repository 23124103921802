import React, {ReactNode} from 'react';
import {Form} from 'react-final-form';
import {useSelector} from 'react-redux';
import {message} from 'antd';
import styled from 'styled-components';
import _get from 'lodash/get';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import ApiInventoryService from 'infra/common/apiInventoryService';
import BillingAddressForm from 'view/components/BillingAddress/BillingAddressForm';
import NW2AddEditBillingAddressButtonsBlock from './NW2AddEditBillingAddressButtonsBlock';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import {getCustomerBillingAddresses} from 'store/app/apiActions';

import {EBillingAddressFormType} from 'view/components/BillingAddress/types';
import {RootState} from 'store/types';
import {
  ICreateCustomerBillingAddressPayload,
  ICustomerBillingAddress,
} from 'types/dto/IUser.types';

const StyledForm = styled.form`
  // Prevents select dropdown from being cut!
  & .ant-modal-content {
    overflow: visible !important; // important is used to overwrite antd nested class.
  }
`;

type TProps = {
  type: EBillingAddressFormType;
  isMobile: boolean;
  isModalShowed: boolean;
  onModalClose: () => void;
  initialData?: ICustomerBillingAddress | null;
};

const FORM_CONFIGS = {
  [EBillingAddressFormType.ADD]: {
    modalTitle: 'Add a new address',
    submitButtonTitle: 'Add address',
  },
  [EBillingAddressFormType.EDIT]: {
    modalTitle: 'Edit address',
    submitButtonTitle: 'Save changes',
  },
};

export const NW2AddEditBillingAddressModalDrawerForm = ({
  type,
  isMobile,
  isModalShowed,
  onModalClose,
  initialData = null,
}: TProps) => {
  const dispatch = useAppDispatch();

  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  const customerId = useSelector((state: RootState) =>
    _get(state, 'app.user.id'),
  );

  const isEditMode = type === EBillingAddressFormType.EDIT;

  const onSubmit = async (formData: ICustomerBillingAddress, form: any) => {
    try {
      const {
        city,
        companyName,
        costCenter,
        country,
        postCode,
        streetHouseNumber,
        uuid,
        isDefault = false,
      } = formData;

      const payload: ICreateCustomerBillingAddressPayload = {
        additionalReference: '',
        city,
        companyName: companyName || '',
        costCenter: costCenter || '',
        country,
        customerId,
        isDefault: isEditMode ? isDefault : true,
        postCode,
        streetHouseNumber,
        uuid: uuid || '',
      };

      if (isEditMode) {
        await ApiInventoryService.updateCustomerBillingAddress(payload); // update billing address
      } else {
        await ApiInventoryService.createCustomerBillingAddress(payload); // create new billing address
      }

      dispatch(getCustomerBillingAddresses(+customerId)); // refetch all billing addresses

      const messageText = isEditMode
        ? 'Your billing address was successfully updated.'
        : 'Your new billing address was successfully added.';

      message.success({
        type: 'error',
        content: messageText,
        key: messageText,
      });

      onModalClose();
      form.initialize();
    } catch (error) {}
  };

  const {modalTitle, submitButtonTitle} = FORM_CONFIGS[type];

  const modalContent = (
    <>
      <BillingAddressForm hideReferenceField useDesktopLayoutOnTablet />
      <NW2AddEditBillingAddressButtonsBlock
        onCancel={onModalClose}
        submitButtonTitle={submitButtonTitle}
      />
    </>
  );

  const getContent = (content: ReactNode = modalContent) => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialData}
        render={({handleSubmit}) => (
          <StyledForm onSubmit={handleSubmit}>{content}</StyledForm>
        )}
      />
    );
  };

  const sharedProps = {
    isMobile,
    isShowed: isModalShowed,
    header: modalTitle,
    onClose: onModalClose,
  };

  return isMobile ? (
    // antd Drawer component
    <NW2ModalDrawer {...sharedProps} body={getContent()} />
  ) : (
    // antd Modal component
    <NW2ModalDrawer
      {...sharedProps}
      body={modalContent}
      modalRender={getContent}
      modalWidth={isDesktop ? 800 : 704} // per design
    />
  );
};

export default NW2AddEditBillingAddressModalDrawerForm;

import React from 'react';
import styled from 'styled-components';

import {
  fontSizeXSm,
  fontSizeXXLg,
  fontWeightExtraBold,
  lineHeightSm,
  lineHeightXLg,
  NW2Gray600Color,
  offsetSm,
  offsetXXSm,
} from 'constants/styleVars';

const BodyContainer = styled.div``;
const Title = styled.div`
  font-size: ${fontSizeXXLg};
  font-weight: ${fontWeightExtraBold};
  line-height: ${lineHeightXLg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  word-break: break-word;
`;
const Subtitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  word-break: break-word;
  color: ${NW2Gray600Color};
  * + & {
    margin-top: ${offsetXXSm};
  }
`;
const Body = styled.div`
  margin-top: ${offsetSm};
  overflow-y: auto;
  max-height: 70vh;
`;
type TProps = {
  title: string;
  subTitle: string;
  htmlString: string;
};
const AnnouncementPopupBody = ({title, subTitle, htmlString}: TProps) => {
  return (
    <BodyContainer>
      <Title>{title}</Title>
      <Subtitle>{subTitle}</Subtitle>
      <Body dangerouslySetInnerHTML={{__html: htmlString}}></Body>
    </BodyContainer>
  );
};

export default AnnouncementPopupBody;

import React from 'react';

import ActionCardClosable from 'view/components/ActionCardClosable';

import {
  errorColor,
  NW2ErrorLight500Color,
  NW2Warn500Color,
} from 'constants/styleVars';
import {StyledText} from '../components/AdditionalActions/AdditionalActions.styles';
import {ESpaceDeletionStatuses} from '../components/AdditionalActions/types';

const RFP_BOOKING_ICON_PROPS = {
  size: 20,
  lightWarn: true,
};

const ACTIVE_BOOKINGS_ICON_PROPS = {
  size: 20,
  error: true,
};

export const getDeletionModalBody = (
  deletionStatus: ESpaceDeletionStatuses,
) => {
  const textRfpOrBooked = (
    <StyledText>
      You are about to delete this space. This action is irreversible and all of
      the space details will be lost once deleted.
    </StyledText>
  );
  const textHasRfpBooking = (
    <>
      <b>Existing requests-to-book will be declined.</b>
      <br />
      You have some pending requests-to-book. If you proceed with deleting the
      space they will be declined automatically.
    </>
  );
  const textHasActiveBookings = (
    <>
      <b>Immediate deletion not possible</b>
      <br />
      This space has confirmed future bookings and therefore cannot be deleted.
      Please make sure to handle any existing bookings first since, only then,
      the space can be deleted.
    </>
  );
  const textHasActiveOffers = (
    <>
      <b>Deletion not possible</b>
      <br />
      This space has confirmed future offers and therefore cannot be deleted.
      You can only delete spaces that do not contain any future events.
    </>
  );
  if (deletionStatus === ESpaceDeletionStatuses.HAS_RFP_BOOKINGS) {
    return (
      <>
        {textRfpOrBooked}
        <ActionCardClosable
          isClosable={false}
          borderColor={NW2Warn500Color}
          icon='WARN'
          iconProps={RFP_BOOKING_ICON_PROPS}
          description={textHasRfpBooking}
        />
      </>
    );
  }
  if (deletionStatus === ESpaceDeletionStatuses.HAS_ACTIVE_BOOKINGS) {
    return (
      <>
        {textRfpOrBooked}
        <ActionCardClosable
          isClosable={false}
          borderColor={errorColor}
          icon='WARN'
          iconProps={ACTIVE_BOOKINGS_ICON_PROPS}
          description={textHasActiveBookings}
        />
      </>
    );
  }
  if (deletionStatus === ESpaceDeletionStatuses.HAS_ACTIVE_OFFERS) {
    return (
      <>
        {textRfpOrBooked}
        <ActionCardClosable
          isClosable={false}
          borderColor={NW2ErrorLight500Color}
          icon='TRIANGLE_WARN'
          iconProps={{size: 20, NW2ErrorLight500Color: true}}
          description={textHasActiveOffers}
        />
      </>
    );
  }
  return (
    <StyledText margin='0'>
      You are about to delete this space. This action is irreversible and all of
      the space details will be lost once deleted.
    </StyledText>
  );
};

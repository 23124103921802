import {TVenueCharacterType} from 'types/venue';
import {EFieldNames} from './components/types';

export enum EDescriptionState {
  DESCRIPTION = 'description',
  EDIT = 'edit',
  EMPTY_CHARACTER = 'empty character',
  EDIT_CHARACTER = 'edit_character',
}

export type TEditFormValues = {
  [EFieldNames.DESCRIPTION]: string;
  [EFieldNames.VENUE_CHARACTERS]: TVenueCharacterType[];
};

export type TEditCharacterFormValues = {
  venueCharacters: TVenueCharacterType[];
};

export type TEditFormErrors = {
  description?: string[];
  venueCharacters?: string[];
};

export type TEditCharacterFormErrors = {
  venueCharacters?: string[];
};

export type TVenueCharactersOption = {
  label: string;
  value: TVenueCharacterType;
};

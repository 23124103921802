import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {getAmenities} from 'store/venue/actions';
import {sortRoomAmenities} from '../utils/amenities';
import {useAppDispatch} from 'store/hooks';

import {IAmenity} from 'types/dto/IAmenity';
import {ERoomType} from 'types/dto/ERoomType.type';
import {deskAmenitiesSet, roomAmenitiesSet} from 'types/amenities';

const useRoomAmenities = (roomType: ERoomType) => {
  const dispatch = useAppDispatch();

  const roomAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.roomAmenities'),
  );
  const deskAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.deskAmenities'),
  );

  useEffect(() => {
    if (roomType === ERoomType.WORK_SPACE && !deskAmenities.length) {
      dispatch(getAmenities(deskAmenitiesSet));
    }
    if (roomType === ERoomType.MEETING_ROOM && !roomAmenities.length) {
      dispatch(getAmenities(roomAmenitiesSet));
    }
  }, [deskAmenities.length, dispatch, roomAmenities.length, roomType]);

  return {
    roomAmenities: sortRoomAmenities(
      roomType === ERoomType.MEETING_ROOM ? roomAmenities : deskAmenities,
    ),
  };
};

export default useRoomAmenities;

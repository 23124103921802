import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {notification} from 'antd';

import ApiInventoryService from 'infra/common/apiInventoryService';
import getRedirectUrlByUserRole from 'view/auth/utils/getRedirectUrlByUserRole';
import LocalStorageService from 'infra/common/localStorage.service';

import {LoginUserData} from 'types/account';
import {Routes} from 'constants/routes';
import {setOpenLoginPopup, setUser} from 'store/app/appSlice';
import {useAppDispatch} from 'store/hooks';
import {loginByJwtToken} from 'store/app/apiActions';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {TSubmitConfirmForgotPassword} from 'view/auth/utils/useForgotPassword';

const notificationOptions = {
  message: 'Welcome back! You have successfully logged in.',
};

interface ILoginProps {
  isLoginModal?: boolean;
  redirectLinkAfterLogin?: string;
}

type IProps = Partial<ILoginProps> | undefined;
const useLoginInSystem = ({
  isLoginModal,
  redirectLinkAfterLogin,
}: IProps = {}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);

  const errorHandler = (error: unknown) => {
    const notificationOptions = {
      message: (error as Error)?.message || 'Something went wrong',
      duration: 4,
    };
    notification.error(notificationOptions);
  };

  const setUserData = useCallback(
    async (user: CognitoUser, email: string, password: string) => {
      const jwtToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
      if (!jwtToken) return;

      const userDataFromToken = await dispatch(loginByJwtToken(jwtToken));
      const {cognitoUserRole, expiresAt} = userDataFromToken;
      dispatch(
        setUser({
          email,
          password,
          role: cognitoUserRole,
          isLoggedIntoSystem: true,
          expiresAt,
        }),
      );

      // We need to trigger activate endpoint on every successful login
      ApiInventoryService.postActivateUser();

      if (!isLoginModal || cognitoUserRole !== EUserRoleCognito.ROLE_CUSTOMER) {
        navigate(
          redirectLinkAfterLogin || getRedirectUrlByUserRole(cognitoUserRole),
        );
      }
    },
    [dispatch, isLoginModal, navigate, redirectLinkAfterLogin],
  );

  const loginInSystem = useCallback(
    async ({email, password}: LoginUserData) => {
      setIsLoading(true);
      if (isSubmitFailed) {
        setIsSubmitFailed(false);
      }
      try {
        const user = await Auth.signIn(email, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return navigate(
            `${Routes.userCompleteRegistration}?email=${encodeURI(
              email,
            )}&code=${encodeURI(password)}`,
          );
        }
        await setUserData(user, email, password);
        dispatch(setOpenLoginPopup({openLoginPopup: false}));
        notification.success(notificationOptions);
        setIsSubmitFailed(false);
        setIsLoading(false);
      } catch (error) {
        setIsSubmitFailed(true);
        setIsLoading(false);
      }
    },
    [dispatch, isSubmitFailed, navigate, setUserData],
  );

  const completeNewPassword = useCallback(
    async ({
      email,
      code,
      newPassword,
      callback,
      finallyCallback,
    }: TSubmitConfirmForgotPassword) => {
      try {
        const user = await Auth.signIn(email, code);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, newPassword)
            .then((user) => {
              setUserData(user, email, newPassword);
              if (callback) callback();
            })
            .catch(errorHandler)
            .finally(finallyCallback);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        if (finallyCallback) finallyCallback();
        LocalStorageService.removeItemByKey('hmdToken');
      }
    },
    [setUserData],
  );

  return {
    isLoading,
    isSubmitFailed,
    loginInSystem,
    completeNewPassword,
  };
};

export default useLoginInSystem;

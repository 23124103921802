import React from 'react';

import {IAmenity} from 'types/dto/IAmenity';
import {useSortedAmenitiesView} from '../hooks/useSortedAmenitiesView';
import {itemsDeskTypeFromSection, itemsRoomTypeFromSection} from '../types';
import {GroupWrapper} from '../styles';
import {ESpaceDetailsContentSections} from '../../../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  EDesksAmenitiesCategories,
  ERoomAmenitiesCategories,
} from 'view/venue/NW2VenueProfile/components/NMMSpaces/types';

interface IProps {
  amenities: IAmenity[];
  roomType: ERoomType;
  section: ESpaceDetailsContentSections;
}

export const AmenitiesView = ({amenities, section, roomType}: IProps) => {
  const itemsType: (ERoomAmenitiesCategories | EDesksAmenitiesCategories)[] =
    roomType === ERoomType.MEETING_ROOM
      ? itemsRoomTypeFromSection[section]
      : itemsDeskTypeFromSection[section];

  const accessibilityLayout = itemsType.includes(
    ERoomAmenitiesCategories.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY ||
      EDesksAmenitiesCategories.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
  );

  const amenitiesToShow = useSortedAmenitiesView({
    roomAmenities: amenities,
    items: itemsType,
    fieldName: section,
    isTitleShowed: true,
    roomType,
  });

  return accessibilityLayout ? (
    <GroupWrapper>{amenitiesToShow}</GroupWrapper>
  ) : (
    <>{amenitiesToShow}</>
  );
};

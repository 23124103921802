import React from 'react';
import {Form} from 'react-final-form';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {nameFieldRules} from 'utils/finalFormFieldRules';
import {NW2Gray600Color} from 'constants/styleVars';
import {
  InputWrapper,
  StyledBodyContainer,
  FormItem,
  FormItemTitle,
  RequiredLabel,
} from '../BookingChangeOverviewSidebar.styles';
import {TConfirmFormData} from '../BookingChangeOverviewSidebar';

type TProps = {
  handleFormSubmit: (formData: TConfirmFormData) => void;
};
const BookingChangeSidebarBodyConfirm = ({handleFormSubmit}: TProps) => {
  return (
    <StyledBodyContainer>
      <Form onSubmit={handleFormSubmit}>
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit} id='confirmForm'>
            <FormItem>
              <FormItemTitle>
                Let the booker know who confirmed this booking
              </FormItemTitle>
              <InputWrapper>
                <NW2FormItemInput
                  name='approverName'
                  type='text'
                  label='Your name*'
                  labelColor={NW2Gray600Color}
                  rules={nameFieldRules}
                  placeholder='Your name'
                  className='input-wrapper'
                />
                <RequiredLabel>(Required)</RequiredLabel>
              </InputWrapper>
            </FormItem>
          </form>
        )}
      </Form>
    </StyledBodyContainer>
  );
};

export default BookingChangeSidebarBodyConfirm;

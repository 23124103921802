import React from 'react';

import LoginDetails from 'view/auth/LoginCustomer/LoginDetails';

import {TextValue} from 'view/components/TextValue';
import {Link, StyledH6} from 'view/components/Typography';

import {LoginUserData} from 'types/account';
import {
  AuthModalFooter,
  LoginContentWrapper,
  StyledTextValue,
  TitleContainer,
} from 'view/auth/utils/auth.styles';

interface IProps {
  loginTitle: string;
  loginDescription: string;
  isLoading: boolean;
  isSubmitFailed: boolean;
  loginInSystem: ({email, password}: LoginUserData) => Promise<void>;
  openForgotPassword: () => void;
  toggleLoginRegisterModals?: () => void;
}

function LoginContent({
  loginTitle,
  loginDescription,
  isLoading,
  isSubmitFailed,
  loginInSystem,
  openForgotPassword,
  toggleLoginRegisterModals,
}: IProps) {
  return (
    <LoginContentWrapper>
      <TitleContainer>
        <StyledH6>{loginTitle}</StyledH6>
      </TitleContainer>
      <StyledTextValue>{loginDescription}</StyledTextValue>
      <LoginDetails
        isSubmitFailed={isSubmitFailed}
        isLoading={isLoading}
        loginInSystem={loginInSystem}
        openForgotPassword={openForgotPassword}
      />

      {toggleLoginRegisterModals && (
        <AuthModalFooter>
          <TextValue>Not have an account yet?</TextValue>
          <Link href='#' onClick={toggleLoginRegisterModals}>
            Register now
          </Link>
        </AuthModalFooter>
      )}
    </LoginContentWrapper>
  );
}

export default LoginContent;

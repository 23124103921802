import api, {ApiType} from './http.service';

const ApiCommonService = {
  getNotifications: async (userId: number) =>
    await api.get(
      ApiType.Common,
      `notification/polled_notification?user_id=${userId}`,
    ),
};

export default ApiCommonService;

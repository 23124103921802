import styled from 'styled-components';

import {offsetXLg, offsetXXLg, xLgBp} from 'constants/styleVars';

export const Container = styled.div`
  display: grid;
  gap: ${offsetXLg} ${offsetXLg};
  margin-bottom: ${offsetXXLg};
  grid-template-columns: repeat(2, minmax(130px, 1fr));

  @media (min-width: ${xLgBp}px) {
    margin-bottom: 0;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

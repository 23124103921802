import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {Modal} from 'antd';
import {useAppSelector} from 'store/hooks';

import AdminAddCompany from './components/AdminAddCompany';
import AdminAddCompanyUser from './components/AdminAddCompanyUser';
import AdminEditCompanyUser from './components/AdminEditCompanyUser';
import AdminReassignCompanyUser from './components/AdminReassignCompanyUser';
import PropManagerWelcomeModal from './components/PropManagerWelcomeModal';

import {
  IMultiModalActiveComponent,
  IMultiModalComponents,
  TMultiModalActiveComponentProps,
} from '../../../types/app';
import {EMPTY_FUNC, EMPTY_OBJECT} from 'constants/app';
import {setMultiModalComponent} from 'store/app/appSlice';

const MODAL_COMPONENTS: IMultiModalComponents = {
  adminAddCompany: AdminAddCompany,
  adminAddCompanyUser: AdminAddCompanyUser,
  adminEditCompanyUser: AdminEditCompanyUser,
  adminReassignCompanyUser: AdminReassignCompanyUser,
  propManagerWelcomeModal: PropManagerWelcomeModal,
};

const MultiModal: FC = () => {
  const dispatch = useDispatch();

  const modalComponent: IMultiModalActiveComponent = useAppSelector(
    ({app}) => app.multiModalActiveComponent,
  );

  const {id, props = EMPTY_OBJECT as TMultiModalActiveComponentProps} =
    modalComponent;
  if (!id) return null;

  const ModalContent = MODAL_COMPONENTS[id];
  if (!ModalContent) return null;

  const {
    title = '',
    onOk = EMPTY_FUNC,
    onCancel = EMPTY_FUNC,
    okText,
    cancelText,
    width,
  } = props;
  const shouldHideFooter = !okText && !cancelText;

  const hideModal = () => dispatch(setMultiModalComponent({id: ''}));

  const onOkHandler = async () => {
    try {
      Promise.resolve()
        .then(() => onOk && onOk())
        .catch((error) => Promise.reject(error))
        .then(() => hideModal());
    } catch (error) {}
  };

  const onCancelHandler = async () => {
    try {
      Promise.resolve()
        .then(() => onCancel && onCancel())
        .catch((error) => Promise.reject(error))
        .then(() => hideModal());
      hideModal();
    } catch (error) {}
  };

  const modalProps = {
    title,
    visible: !!id,
    onOk: onOkHandler,
    onCancel: onCancelHandler,
    closable: true,
    width,
    okText,
    cancelText,
    ...(shouldHideFooter ? {footer: null} : {}),
    destroyOnClose: true,
  };

  return (
    <Modal {...modalProps}>
      <div id={id}>
        <ModalContent {...props} />
      </div>
    </Modal>
  );
};

export default React.memo(MultiModal);

import React, {FC, useCallback, useMemo} from 'react';
import {Field, FieldInputProps, useForm, useFormState} from 'react-final-form';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon/Icon';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {ATTENDEE_FIELD_KEY, ATTENDEES_FIELD_KEY} from 'constants/venue';
import {attendeesFieldRules} from 'utils/finalFormFieldRules';
import {NW2Gray600Color, offsetXLg, offsetXSm} from 'constants/styleVars';
import {EMPTY_ARRAY} from 'constants/app';
import {useAppSelector} from 'store/hooks';

type TPropsAttendee = FieldInputProps<any, HTMLElement>;

type TProps = {
  offerParticipants?: number;
};

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: ${offsetXSm};
`;

const MaximumReachedText = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: ${NW2Gray600Color};
`;

const StyledNW2FormItemInput = styled(NW2FormItemInput)`
  flex-grow: 1;
  margin: ${offsetXSm} 0;
  margin-right: ${offsetXLg};
`;

const Attendees: FC<TPropsAttendee & TProps> = ({offerParticipants}) => {
  const form = useForm();
  const formState = useFormState();
  const formValues: Record<string, string>[] = useMemo(() => {
    return formState.values[ATTENDEES_FIELD_KEY] || EMPTY_ARRAY;
  }, [formState]);

  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );

  const maxAttendees = (offerParticipants ?? participants) - 1;
  const fieldsNumber = formValues?.length || 0;
  const isMaxAttendees = fieldsNumber >= maxAttendees;

  const addAttendee = useCallback(() => {
    const inputState = {attendee: ''};
    const values = [...formValues, inputState];
    form.change(ATTENDEES_FIELD_KEY, values);
  }, [formValues, form]);

  const removeAttendee = useCallback(
    (index: number) => () => {
      const values = formValues?.filter((item) => item !== formValues[index]);
      form.change(ATTENDEES_FIELD_KEY, values);
    },
    [formValues, form],
  );

  const onChange = useCallback(
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const values = formValues?.map((item, itemIndex) =>
        itemIndex === index ? {[ATTENDEE_FIELD_KEY]: event.target.value} : item,
      );
      form.batch(() => {
        form.change(`${[ATTENDEE_FIELD_KEY]}${index}`, event.target.value);
        form.change(ATTENDEES_FIELD_KEY, values);
      });
    },
    [formValues, form],
  );

  return (
    <div>
      {formValues?.map((name, index) => (
        <Row key={`item-${index}`}>
          <StyledNW2FormItemInput
            name={`${ATTENDEE_FIELD_KEY}${index}`}
            type='email'
            label={`Attendee ${index + 1}`}
            placeholder={`Attendee ${index + 1}`}
            showAllValidationErrors
            onChange={onChange(index)}
            value={name.attendee}
            rules={attendeesFieldRules}
          />

          <Icon
            icon='MINUS_CIRCLED'
            onClick={removeAttendee(index)}
            size={26}
            secondaryColor
          />
        </Row>
      ))}
      {isMaxAttendees ? (
        <MaximumReachedText>
          You've reached maximum number of attendees allowed for this space.
        </MaximumReachedText>
      ) : (
        <ButtonContainer>
          <NW2Button
            onClick={addAttendee}
            icon={<Icon size={16} transparent icon='PLUS_CIRCLED' />}
            iconPlace='left'
            size='small'
            inline
            buttonType='tertiary'
          >
            Attendee
          </NW2Button>
        </ButtonContainer>
      )}
    </div>
  );
};

const NW2AttendeesContent = ({offerParticipants}: TProps) => {
  return (
    <Field
      name={ATTENDEES_FIELD_KEY}
      render={({input}) => (
        <Attendees {...input} offerParticipants={offerParticipants} />
      )}
    />
  );
};

export default NW2AttendeesContent;

import React, {ReactNode} from 'react';
import {useDispatch} from 'react-redux';

import {Link} from 'view/components/Typography';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {TColorProps} from 'view/components/Typography/Typography';

type TProps = {
  content: string | ReactNode;
  color?: TColorProps;
};

const LoginLink = ({content, color}: TProps) => {
  const dispatch = useDispatch();

  const logIn = () => {
    dispatch(setOpenLoginPopup({openLoginPopup: true}));
  };
  const linkColor = {
    ...(color ? color : {primary: true}),
  };

  return (
    <Link {...linkColor} onClick={logIn}>
      {content}
    </Link>
  );
};

export default LoginLink;

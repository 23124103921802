import React from 'react';

import NW2Button from 'view/components/NW2Button';
import WysiwygEditor, {TOnChangeArgs} from 'view/components/WYSIWYGEditor';
import {Group} from 'styles/basicStyledComponents/group';
import {useField} from 'react-final-form';

type TProps = {
  onBack: () => void;
};

function MessageStep({onBack}: TProps) {
  const editorField = useField('body');

  const onChangeHandler = (value: TOnChangeArgs) => {
    editorField.input.onChange(value.rootNodeContext ? value.htmlString : '');
  };

  return (
    <>
      <WysiwygEditor onChangeHandler={onChangeHandler} isSmall />
      <Group justify='end'>
        <NW2Button buttonType='secondary' white onClick={onBack}>
          Back
        </NW2Button>
        <NW2Button buttonType='primary' type='submit'>
          Submit
        </NW2Button>
      </Group>
    </>
  );
}

export default MessageStep;

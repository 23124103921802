import {NW2Gray900Color} from 'constants/styleVars';
import {IAmenity} from 'types/dto/IAmenity';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EAmenityType} from 'types/amenities';
import {ESpaceDetailsContentSections} from '../../../types';
import {useSortedUnitAmenities} from '../../../../hooks/useSortedUnitAmenities';

interface IProps {
  spaceAmenities: IAmenity[];
  roomType: ERoomType;
}

export function useLocalAmenities({spaceAmenities, roomType}: IProps) {
  const accessibilityAmenitiesItemType =
    roomType === ERoomType.MEETING_ROOM
      ? [
          EAmenityType.CORPORATE_OFFICE_MEETING_ROOM,
          EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_ACCESSIBILITY,
        ]
      : [
          EAmenityType.CORPORATE_OFFICE_WORK_DESK,
          EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
        ];

  const equipmentAmenitiesItemType =
    roomType === ERoomType.MEETING_ROOM
      ? [EAmenityType.CORPORATE_OFFICE_MEETING_ROOM_EQUIPMENT]
      : [EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT];

  const commonProps = {
    spaceAmenities,
    isTitleShowed: true,
    titleColor: NW2Gray900Color,
    roomType,
  };

  const amenitiesConditionsAndAccessibility = useSortedUnitAmenities({
    items: accessibilityAmenitiesItemType,
    fieldName: ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY,
    ...commonProps,
  });

  const amenitiesEquipment = useSortedUnitAmenities({
    items: equipmentAmenitiesItemType,
    fieldName: ESpaceDetailsContentSections.INCLUDED_IN_SPACE,
    ...commonProps,
  });

  const amenitiesLocal: Record<string, JSX.Element[]> = {
    [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: amenitiesEquipment,
    [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]:
      amenitiesConditionsAndAccessibility,
  };

  return [amenitiesLocal];
}

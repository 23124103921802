import styled from 'styled-components';
import {
  contentMaxWidthLg,
  fontSizeXXLg,
  fontWeightExtraBold,
  lgBp,
  mdBp,
  NW2FontSizeH3Md,
  offset120,
  offset64,
  offset80,
  offsetDef,
  offsetLg,
  offsetSm,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  sectionInsetLg,
  sectionInsetMd,
  sectionInsetXs,
  smBp,
  whiteColor,
} from 'constants/styleVars';

const NW2MainLandingContainer = styled.div`
  overflow: hidden;
`;

const NW2Container = styled.div<{offset?: string}>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({offset}) => (offset ? `margin-top: ${offset}` : '')}
  padding: 0 ${offsetDef};

  @media (min-width: ${mdBp}px) {
    padding: 0 ${offsetXXLg};
  }

  @media (min-width: ${lgBp}px) {
    max-width: calc(${contentMaxWidthLg} + 2 * ${offsetXXLg});
  }
`;

const StyledContainer = styled(NW2Container)`
  margin-top: ${offsetXLg};
`;

const NW2ContainerWithOffset = styled(NW2Container)`
  & + & {
    margin-top: ${offsetXXXLg};
  }

  @media (min-width: ${mdBp}px) {
    & + & {
      margin-top: ${offset80};
    }
  }

  @media (min-width: ${lgBp}px) {
    & + & {
      margin-top: ${offset120};
    }
  }
`;

const NW2ContainerRow = styled.div<{offset?: string}>`
  display: grid;
  grid-gap: ${offsetXLg};
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  ${({offset}) => (offset ? `margin-top: ${offset}` : '')};

  @media (min-width: ${smBp}px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const NW2Section = styled.section<{background?: string}>`
  padding: ${sectionInsetXs} 0;
  background: ${({background}) => background || 'transparent'};

  @media (min-width: ${mdBp}px) {
    padding: ${sectionInsetMd} 0;
  }

  @media (min-width: ${lgBp}px) {
    padding: ${sectionInsetLg} 0;
  }
`;
const CardBlockSection = styled.section`
  padding: ${offsetSm} 0;

  @media (min-width: ${mdBp}px) {
    padding: ${offset64} 0;
  }

  @media (min-width: ${lgBp}px) {
    padding: ${sectionInsetMd} 0;
  }
`;

const HeroImageContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.7)
    );
    z-index: 1;
  }

  img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
  }
`;

const TitleWrap = styled.div`
  position: absolute;
  left: ${offsetDef};
  right: 0;
  bottom: ${offsetLg};
  z-index: 1;
  padding: 0;

  span {
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 4px;
      background: ${whiteColor};
      @media (min-width: ${mdBp}px) {
        height: 9px;
        bottom: -${offsetDef};
      }
    }
  }

  @media (min-width: ${mdBp}px) {
    bottom: ${offsetXXLg};
    left: ${offsetXXLg};
  }
  @media (min-width: ${lgBp}px) {
    bottom: ${offsetXLg};
    left: 136px;
  }
`;

const Title = styled.div`
  font-weight: ${fontWeightExtraBold};
  color: ${whiteColor};
  font-size: 44px;
  line-height: 44px;
  text-transform: uppercase;
  margin-bottom: ${offsetSm};
  @media (min-width: ${mdBp}px) {
    font-size: 66px;
    line-height: 48px;
    margin-bottom: ${offsetLg};
  }
  @media (min-width: ${lgBp}px) {
    font-size: 87px;
    line-height: 83px;
    margin-bottom: ${offsetXXLg};
  }
`;
const SubTitle = styled.div`
  font-weight: ${fontWeightExtraBold};
  color: ${whiteColor};
  font-size: ${fontSizeXXLg};
  line-height: 1;
  text-transform: uppercase;
  @media (min-width: ${mdBp}px) {
    font-size: 46px;
  }
  @media (min-width: ${lgBp}px) {
    font-size: ${NW2FontSizeH3Md};
  }
`;

export {
  NW2MainLandingContainer,
  NW2Section,
  NW2Container,
  StyledContainer,
  NW2ContainerWithOffset,
  NW2ContainerRow,
  CardBlockSection,
  //Hero image
  HeroImageContainer,
  TitleWrap,
  Title,
  SubTitle,
};

import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';

import {Routes} from 'constants/routes';
import {useAppSelector} from 'store/hooks';

export function useGoBackToStoredSearch() {
  const navigate = useNavigate();

  const storedSearchCriteria = useAppSelector(
    ({search}) => search.storedSearchCriteria,
  );

  const onGoToSearchResults = () => {
    navigate({
      pathname: Routes.venuesList,
      search: queryString.stringify({
        ...storedSearchCriteria,
        page: 0,
        offerPage: 0,
      }),
    });
  };

  return [onGoToSearchResults];
}

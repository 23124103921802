import React from 'react';

import {ModalDeclineLeftColumn, ModalDeclineWrapper} from './styles';
import {RequestDeclineForm} from './RequestDeclineForm';
import {EDeclineItemType} from 'types/offer';

interface IProps {
  detailsModal: JSX.Element;
  orderId: string;
  isSending: boolean;
  declineItem: EDeclineItemType;
  handleDecline: <TPayload>(
    payload: TPayload,
    declineItem: EDeclineItemType,
  ) => void;
  handleCancel: () => void;
  isRequest?: boolean;
  isMobile?: boolean;
  isCustomer?: boolean;
  showButtons?: boolean;
  cancelLabel?: string;
  submitLabel?: string;
  isActionCardShowed?: boolean;
  handleShowDetails?: () => void;
}

const RequestDecline = ({
  detailsModal,
  orderId,
  isSending,
  isCustomer,
  handleDecline,
  handleCancel,
  cancelLabel,
  submitLabel,
  isRequest = false,
  isMobile = false,
  showButtons = true,
  declineItem,
  isActionCardShowed,
  handleShowDetails,
}: IProps) => {
  return (
    <ModalDeclineWrapper isMobile={isMobile}>
      <ModalDeclineLeftColumn>{detailsModal}</ModalDeclineLeftColumn>

      <RequestDeclineForm
        orderId={orderId}
        handleDecline={handleDecline}
        handleCancel={handleCancel}
        isSending={isSending}
        cancelLabel={cancelLabel}
        submitLabel={submitLabel}
        isRequest={isRequest}
        isMobile={isMobile}
        declineItem={declineItem}
        isCustomer={isCustomer}
        showButtons={showButtons}
        isActionCardShowed={isActionCardShowed}
        handleShowDetails={handleShowDetails}
      />
    </ModalDeclineWrapper>
  );
};

export default RequestDecline;

import React, {ReactNode} from 'react';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import {TDateType} from 'types/dto/ISearch.types';

import {NW2Gray50Color} from 'constants/styleVars';
import {
  StyledActions,
  StyledDrawerContent,
  StyledDrawerContentFooter,
  StyledDrawerContentHeader,
  StyledInputContainer,
} from './NW2DatepickerMobile.styles';
import {formatDate} from 'utils/stringUtils';

interface IProps {
  date: TDateType;
  dateSection: ReactNode;
  timeSection?: ReactNode;
  actions: ReactNode;
  isOpened: boolean;
  onToggleDatePicker: () => void;
}

export function NW2DatepickerMobile({
  date,
  dateSection,
  timeSection,
  actions,
  isOpened,
  onToggleDatePicker,
}: IProps) {
  return (
    <StyledAntdDrawer
      title={null}
      onClose={onToggleDatePicker}
      open={isOpened}
      height='auto'
      bodyStyle={{
        padding: 0,
        backgroundColor: NW2Gray50Color,
      }}
      padding='0'
    >
      <StyledDrawerContent>
        <StyledDrawerContentHeader>
          <StyledInputContainer highlighted>
            <input
              type='text'
              value={formatDate(date, 'd MMM, YYYY')}
              readOnly
            />
            <label>
              <span>Date</span>
            </label>
          </StyledInputContainer>
        </StyledDrawerContentHeader>

        {dateSection}

        <StyledDrawerContentFooter>
          {timeSection}

          <StyledActions>{actions}</StyledActions>
        </StyledDrawerContentFooter>
      </StyledDrawerContent>
    </StyledAntdDrawer>
  );
}

import {useCallback, useState} from 'react';

import useCorporateAccountId from 'view/venue/hooks/useCorporateAccountId';
import {TFileWithPreview} from '../../NW2Images/types';

interface IProps {
  onToggleShow: () => void;
}
export function useAddSpaceHandlers({onToggleShow}: IProps) {
  const {corporateAccountId} = useCorporateAccountId();
  const [page, setPage] = useState(0);

  const handleSetPage = (page: number) => setPage(page);

  // skip link flow
  const [skippedPages, setSkippedPages] = useState<string[]>([]);
  const handleSetSkippedPages = useCallback((step: string) => {
    setSkippedPages((prev) => {
      if (!prev.includes(step)) {
        return [...prev, step];
      }

      return prev;
    });
  }, []);

  // images
  const [filesState, setFilesState] = useState<TFileWithPreview[]>([]);
  const handleResetImages = useCallback(() => {
    filesState.forEach((file: TFileWithPreview) =>
      URL.revokeObjectURL(file.preview),
    );
    setFilesState([]);
  }, [filesState]);

  const handleClose = useCallback(() => {
    handleResetImages();
    setSkippedPages([]);
    setPage(0);
    onToggleShow();
  }, [handleResetImages, onToggleShow]);

  const handleSetFiles = useCallback((files: TFileWithPreview[]) => {
    setFilesState(files);
  }, []);

  return {
    corporateAccountId,
    filesState,
    page,
    skippedPages,
    handleClose,
    handleSetFiles,
    handleSetPage,
    handleSetSkippedPages,
  };
}

import React, {useCallback, useState} from 'react';
import {Modal} from 'antd';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import TruncateText from 'view/components/TruncateText';
import NW2VenueDetailsBlockLink from '../../VenueDetailsBlock/components/NW2VenueDetailsBlockLink';
import StyledAntdDrawer from 'view/common/StyledAntdDrawer';

import {StyledH6} from 'view/components/Typography';
import {
  fontSizeMd,
  modalDefaultWidth,
  fontSizeXSm,
  fontSizeXLg,
  mdBp,
} from 'constants/styleVars';
import {IUnit} from 'types/dto/IUnit.types';
import useToggle from 'hooks/useToggle';

const bodyStyle = {
  fontSize: fontSizeMd,
};

const Title = styled(StyledH6)`
  text-align: center;
  font-size: ${fontSizeXSm};

  @media (min-width: ${mdBp}px) {
    text-align: left;
    font-size: ${fontSizeXLg};
  }
`;

type TProps = {
  unit: IUnit;
};

export function NW2SpaceDetailsDescription({unit}: TProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const [isFullTextShowed, toggleShowFullText] = useToggle(false);

  const {name, description} = unit;

  const [isTruncated, setIsTruncated] = useState(false);
  const handleIsTruncated = useCallback((val: boolean) => {
    setIsTruncated(val);
  }, []);

  if (!description) return null;

  return (
    <>
      <TruncateText
        text={description}
        tag='div'
        numberOfLines={4}
        handleIsTruncated={handleIsTruncated}
        hideToggler
      />

      {isTruncated && (
        <NW2VenueDetailsBlockLink
          text='Show full description'
          onClick={toggleShowFullText}
        />
      )}

      {!isMobile ? (
        <Modal
          width={modalDefaultWidth}
          title={<Title>{name}</Title>}
          open={isFullTextShowed}
          onCancel={toggleShowFullText}
          footer={null}
          bodyStyle={bodyStyle}
        >
          {description}
        </Modal>
      ) : (
        <StyledAntdDrawer
          title={<Title>{name}</Title>}
          onClose={toggleShowFullText}
          open={isFullTextShowed}
          bodyStyle={bodyStyle}
          height='auto'
        >
          {description}
        </StyledAntdDrawer>
      )}
    </>
  );
}

export default NW2SpaceDetailsDescription;

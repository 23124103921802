import {TLayout} from 'view/common/Header/types';

const getCustomerLayout = (): TLayout => {
  return {
    type: 'CustomerLayout',
    components: [
      {component: 'isLogo'},
      {component: 'isCustomerNavList'},
      {component: 'isLoginRegisterBlock'},
    ],
  };
};

export {getCustomerLayout};

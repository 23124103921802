import React from 'react';
import {FormInstance} from 'antd';

import ContactDetailsForm from './components/ContactDetailsForm';
import ContactDetailsInfo from './components/ContactDetailsInfo';
import CorporateContactDetailsForm from './components/CorporateContactDetailsForm';

import {TStepMode} from 'types/app';
import {EAccommodationType} from 'types/dto/IPublicVenue';

type TProps = {
  mode?: TStepMode;
  form: FormInstance;
  accommodationType: EAccommodationType;
};

const ContactDetails = ({mode, form, accommodationType}: TProps) => {
  return mode === 'add' ? (
    accommodationType === EAccommodationType.CORPORATE_OFFICE ? (
      <CorporateContactDetailsForm form={form} />
    ) : (
      <ContactDetailsForm form={form} />
    )
  ) : (
    <ContactDetailsInfo accommodationType={accommodationType} />
  );
};

export default ContactDetails;

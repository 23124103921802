import {IVenue} from 'types/venue';

export const trimPhoneNumber = (value?: string): string =>
  value?.split(' ').join('') || '';

export const getContactFields = ({supplier, contact}: IVenue) => {
  const {email, phone} = supplier || contact;
  const {fax, websiteUrl} = contact;

  return {
    email,
    phone,
    firstName: supplier?.firstName || '',
    lastName: supplier?.lastName || '',
    fax,
    websiteUrl,
  };
};

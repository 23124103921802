import React from 'react';

import TimeRangeText from 'view/components/TimeRangeText';

import {getExtraNumericInputVisibility} from 'utils/helpers';
import {
  Body,
  ExtraDiv,
  Title,
  MeetingRoomContainer,
} from './UnitSectionContent.styles';
import {TSummaryData} from 'types/search';
import {ERoomSchema} from 'types/venue';
import {BEDROOM_EXTRAS} from 'constants/bedrooms';
import {TBedroomsCatering} from '../../../../AddAccommodationRequest/types';
import {getCateringStatusByName} from '../../../../AddAccommodationRequest/helpers';

type TProps = {
  summaryDataItem: TSummaryData;
  bedroomsCatering: TBedroomsCatering;
};

function UnitSectionContent({summaryDataItem, bedroomsCatering}: TProps) {
  const {roomsData, foodBeverage, accommodation, startDate, dayParticipants} =
    summaryDataItem;

  return (
    <>
      <Body>
        <div>Participants: {dayParticipants}</div>
      </Body>
      {roomsData.map(
        ({checkIn, checkOut, participants, seatsSetup, equipments}, index) => (
          <MeetingRoomContainer key={index}>
            <Title>Meeting Room {index + 1}</Title>
            <Body>
              <div>
                Time: <TimeRangeText checkIn={checkIn} checkOut={checkOut} />
              </div>
              <div>Room capacity: {participants}</div>
              <div>Room setup: {ERoomSchema[seatsSetup]}</div>
            </Body>
            <Body>
              {equipments?.map(([name, count]) => {
                return (
                  <ExtraDiv key={name}>
                    <span>{name}</span>
                    {getExtraNumericInputVisibility(name) && (
                      <span>{count}</span>
                    )}
                  </ExtraDiv>
                );
              })}
            </Body>
          </MeetingRoomContainer>
        ),
      )}
      {!!foodBeverage && (
        <>
          <Title>Food & beverage</Title>
          <Body>
            {foodBeverage.map(([name, count]) => (
              <ExtraDiv key={name}>
                <span>{name}</span>
                <span>{count}</span>
              </ExtraDiv>
            ))}
          </Body>
        </>
      )}

      {!!accommodation && (
        <>
          <Title>Accommodation</Title>
          <Body>
            {accommodation?.map(([name, count]) => (
              <ExtraDiv key={name}>
                <span>
                  {name} (
                  {getCateringStatusByName(
                    bedroomsCatering[startDate]?.[
                      name === BEDROOM_EXTRAS.SINGLE ? 'single' : 'double'
                    ].name,
                  ).toLowerCase()}
                  )
                </span>
                <span>{count}</span>
              </ExtraDiv>
            ))}
          </Body>
        </>
      )}
    </>
  );
}

export default UnitSectionContent;

import React from 'react';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import NW2SpaceDetailsSwitch from './NW2SpaceDetailsSwitch';
import NW2SpaceDetailsFooterPriceBlock from './NW2SpaceDetailsFooterPriceBlock';

import {IUnit} from 'types/dto/IUnit.types';
import {mdBp, xLgBp} from 'constants/styleVars';

type TProps = {
  unit: IUnit;
};

export const Container = styled.div`
  width: 100%;

  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 1fr 2fr;
  }
`;

const NW2SpaceDetailsFooter = ({unit}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  if (!unit) return null;

  return (
    <Container>
      {!isMobile && (
        <NW2SpaceDetailsSwitch
          isTextShowed={isDesktop}
          roomType={unit.roomType}
        />
      )}
      <NW2SpaceDetailsFooterPriceBlock unit={unit} />
    </Container>
  );
};

export default NW2SpaceDetailsFooter;

import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  mdBp,
  xLgBp,
  whiteColor,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXXXLg,
  fontWeightBold,
  fontSizeXLg,
  detailsModalWrapperZIndex,
  sectionBoxShadow,
  lgBp,
} from 'constants/styleVars';

const footerHeight = '86px';

export const MainWrapper = styled.div<{isHasFooter: boolean}>`
  height: 100%;
  overflow-y: auto;
  background-color: ${whiteColor};
  padding: ${offsetDef} ${offsetDef}
    ${({isHasFooter}) =>
      isHasFooter ? `calc(${footerHeight} + ${offsetXXXXLg})` : offsetXXXXLg};

  @media (min-width: ${mdBp}px) {
    padding: ${offsetXXLg} ${offsetXXLg}
      ${({isHasFooter}) =>
        isHasFooter ? `calc(${footerHeight} + ${offsetXXLg})` : `80px`};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${offsetXXLg};
`;

export const Title = styled.div`
  font-size: ${fontSizeXLg};
  font-weight: ${fontWeightBold};
  padding-left: ${offsetDef};

  @media (min-width: ${xLgBp}px) {
    width: 100%;
    text-align: center;
    margin-left: -36px;
    padding-left: 0;
  }
`;

export const ColumnsWrapper = styled.div`
  margin: auto;
  max-width: 910px;

  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  @media (min-width: ${lgBp}px) {
    display: grid;
    grid-template-columns: 1fr auto minmax(auto, 363px);
  }
`;

export const LeftColumn = styled.div``;
export const RightColumn = styled.div``;

export const Separator = styled.div`
  display: none;
  width: 1px;
  height: 100%;
  margin: 0 ${offsetXLg};
  background-color: ${NW2Gray200Color};

  @media (min-width: ${mdBp}px) {
    display: block;
  }
`;

export const StyledNW2Button = styled(NW2Button)`
  z-index: 2;
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${footerHeight};
  background-color: ${whiteColor};
  box-shadow: ${sectionBoxShadow};
  z-index: ${detailsModalWrapperZIndex};
  padding: ${offsetDef};
`;

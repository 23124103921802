import React, {useState} from 'react';

import Image from 'view/components/Image';

import {borderRadiusDef} from 'constants/styleVars';
import {
  CoverLabel,
  DropdownWrapper,
  ImageWrapper,
  StyledDropdown,
  StyledDropdownItem,
} from '../../NW2Images.styles';
import {useSelector} from 'react-redux';
import {RootState} from 'store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  id?: string;
  name?: string;
  src?: string;
  hasCoverLabel?: boolean | null;
  handleSetCover: () => void;
  handleRemove: () => void;
}

export function NW2ImageItem({
  id,
  name,
  src,
  hasCoverLabel,
  handleSetCover,
  handleRemove,
}: IProps) {
  const [isDropdownShowed, setDropdownShowed] = useState(false);

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  if (!src) return null;

  return (
    <ImageWrapper
      isDropdownShowed={isDropdownShowed}
      onMouseEnter={() => {
        setDropdownShowed(true);
      }}
      onMouseLeave={() => {
        setDropdownShowed(false);
      }}
    >
      <Image
        key={id}
        src={src}
        borderRadius={borderRadiusDef}
        width='100%'
        height='100%'
        alt={name}
      />

      {hasCoverLabel && <CoverLabel>cover</CoverLabel>}

      {!isAgent && (
        <DropdownWrapper isShowed={isDropdownShowed}>
          <StyledDropdown placement='bottomRight'>
            <StyledDropdownItem onClick={handleSetCover}>
              Set as cover
            </StyledDropdownItem>
            <StyledDropdownItem onClick={handleRemove}>
              Delete
            </StyledDropdownItem>
          </StyledDropdown>
        </DropdownWrapper>
      )}
    </ImageWrapper>
  );
}

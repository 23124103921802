import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {notification} from 'antd';

import Icon from 'view/components/Icon';

import {
  IMAGE_MAX_SIZE,
  IMAGE_MIN_SIZE,
  MAX_FILES_LIMIT,
  maximumImageFileSize,
  minimumImageFileSize,
} from 'constants/app';

import {
  Container,
  InputLayout,
  InputLayoutSubTitle,
  InputLayoutTitle,
  StyledAnimatedIcon,
} from '../../NW2Images/components/NW2Upload/NW2Upload.styles';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';

interface IProps {
  filesState: TFileWithPreview[];
  isMultiple?: boolean;
  maxFiles?: number;
  isDisabled?: boolean;
  label?: string;
  onChange?: (files: File[]) => void;
}

export function useNMMUpload({
  filesState,
  isMultiple = false,
  label = 'Upload image',
  isDisabled,
  maxFiles,
  onChange,
}: IProps) {
  const [files, setFiles] = useState<TFileWithPreview[]>(filesState);
  const [isUploading, setUploading] = useState(false);

  const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} =
    useDropzone({
      maxFiles: maxFiles || MAX_FILES_LIMIT,
      maxSize: IMAGE_MAX_SIZE,
      minSize: IMAGE_MIN_SIZE,
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      },
      multiple: isMultiple,
      disabled: isDisabled,
      onError: (err) => {
        notification.error({
          message: err?.message,
          duration: 4,
        });
      },
      onDrop: (acceptedFiles, fileRejections) => {
        if (fileRejections.length) {
          const err = fileRejections[0].errors[0];
          const file = fileRejections[0].file;

          let message = '';
          if (err.code === 'file-too-large') {
            message = `${file.name} must be smaller than ${maximumImageFileSize}`;
          }
          if (err.code === 'file-too-small') {
            message = `${file.name} must be bigger than ${minimumImageFileSize}`;
          }
          if (err.code === 'file-invalid-type') {
            message = `${file.name} has invalid format`;
          }

          notification.error({
            message: message || err.message,
            duration: 4,
          });

          return;
        }

        setFiles((prev) => [
          ...prev,
          ...acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }),
          ),
        ]);
      },
    });

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(files);
    }
  }, [files, onChange]);

  const handleUploading = useCallback((val: boolean) => {
    setUploading(val);
  }, []);

  const handleRemoveFile = useCallback(
    (file: TFileWithPreview) => () => {
      const newFiles = [...files];
      newFiles.splice(newFiles.indexOf(file), 1);
      setFiles(newFiles);
    },
    [files],
  );

  return {
    isUploading,
    handleUploading,
    files,
    handleRemoveFile,
    uploadComponent: (
      <Container
        isFocused={isFocused}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        isDisabled={isDisabled}
        data-testid='image upload input'
      >
        {isUploading ? (
          <InputLayout>
            <StyledAnimatedIcon icon='PROCESSING' NW2Gray400Color />
            <InputLayoutTitle>Uploading...</InputLayoutTitle>
          </InputLayout>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <InputLayout>
              <Icon icon='NW2_UPLOAD' NW2Gray400Color />
              <InputLayoutTitle>{label}</InputLayoutTitle>
              <InputLayoutSubTitle>
                (click or drag in image to this area)
              </InputLayoutSubTitle>
            </InputLayout>
          </div>
        )}
      </Container>
    ),
  };
}

import React from 'react';

import MultiVenueMenuButton from './MultiVenueMenuButton';

import {IVenue} from 'types/venue';
import {truncateSimpleText} from 'utils/stringUtils';
import {
  RightButton,
  StyledDropdown,
  Wrapper,
  ListItem,
  ItemWrapper,
  StyledIcon,
} from './styles';

interface IProps {
  image: string;
  handleOfficeListClick?: (id: number) => () => void;
  officeList?: IVenue[];
  selectedOfficeId?: number;
}

const MultiVenueMenuItem = ({
  officeList,
  selectedOfficeId,
  image,
  handleOfficeListClick,
}: IProps) => {
  if (!officeList || !handleOfficeListClick) return null;

  const currentOffice = officeList.filter(({id}) => id === selectedOfficeId)[0];

  return (
    <Wrapper>
      <MultiVenueMenuButton
        image={image}
        location={`${currentOffice?.location?.city}, ${currentOffice?.location?.country}`}
        venueName={truncateSimpleText(currentOffice?.name || '', 28)}
      />
      <StyledDropdown
        titleItemText='Your venues'
        triggerItem={
          <RightButton>
            <StyledIcon icon={'ARROW_UP_FILLED'} />
          </RightButton>
        }
        align={{offset: [4, 30]}}
      >
        {officeList.map(({name, id}) => {
          const activeItem = selectedOfficeId === id;
          return (
            <ItemWrapper activeItem={activeItem} key={id}>
              <ListItem
                activeItem={activeItem}
                onClick={handleOfficeListClick(id)}
              >
                {truncateSimpleText(name, 30)}
              </ListItem>
            </ItemWrapper>
          );
        })}
      </StyledDropdown>
    </Wrapper>
  );
};

export default MultiVenueMenuItem;

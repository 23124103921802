import React, {ChangeEvent} from 'react';

import InputBlock from 'view/components/NW2SearchSection/components/SearchForm/components/InputBlock/InputBlock';
import Icon from 'view/components/Icon';

import {preventNumberOperators} from 'utils/helpers';
import {ESearchInputsId} from 'types/search';
import {
  StyledIconBox,
  StyledParticipantsWrapper,
} from '../NW2DatepickerDetails.styles';

type TProps = {
  isMobile: boolean;
  isChangeActivated: boolean;
  focusedInput: string;
  inputBlockColor: string | undefined;
  participants: any;
  onInputFocus: (inputId: string) => void;
  onParticipantsChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onParticipantsBlur: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ParticipantsSection = ({
  isMobile,
  isChangeActivated,
  focusedInput,
  inputBlockColor,
  participants,
  onInputFocus,
  onParticipantsChange,
  onParticipantsBlur,
}: TProps) => {
  const isFocused = focusedInput === ESearchInputsId.PARTICIPANTS_ID;
  const isDisabled = !isMobile && !isChangeActivated;

  const labelText = isMobile ? (
    'Participants'
  ) : (
    <StyledIconBox>
      <Icon icon='NW2_GROUP' size={18} transparent />
    </StyledIconBox>
  );

  const onFocusHandler = () => {
    onInputFocus(ESearchInputsId.PARTICIPANTS_ID);
  };

  return (
    <StyledParticipantsWrapper>
      <InputBlock
        htmlFor={ESearchInputsId.PARTICIPANTS_ID}
        color={inputBlockColor}
        labelText={labelText}
        isFocused={isFocused}
        isNoBorder
        isNoPadding
        isWidthAuto
      >
        <input
          type='number'
          value={participants}
          id={ESearchInputsId.PARTICIPANTS_ID}
          onFocus={onFocusHandler}
          onKeyDown={preventNumberOperators}
          onBlur={onParticipantsBlur}
          disabled={isDisabled}
          onChange={onParticipantsChange}
        />
      </InputBlock>
    </StyledParticipantsWrapper>
  );
};

export default ParticipantsSection;

import React from 'react';

import NW2Switch from 'view/components/NW2Switch';
import NW2Badge from 'view/components/NW2Badge';
import {NW2TimeRangePicker} from 'view/components/NW2Datepicker';

import {EDays} from 'constants/days';
import {BadgesContainer, HoursContainer, RowItem} from './OpenHoursForm.styles';
import {IDay, IDayOfWeek} from 'types/dto/IDay.type';
import {capitalizeText} from 'utils/stringUtils';
import {setTimeFromStringToDate} from 'utils/operationalHoursUtils';
import DateUtils from 'utils/dateUtils';
import {TDateType} from 'types/dto/ISearch.types';

interface IProps {
  wholeDayAccess: boolean;
  openFrom: string;
  openTo: string;
  isLoading?: boolean;
  getValueFromState: (
    day: IDayOfWeek,
    fieldName: keyof IDay,
  ) => string | number | boolean;
  updateField: (day: string, fieldName: string, value: any) => void;
  handle24HourClick: () => void;
  handleOpenFromChange: (value: string) => void;
  handleOpenToChange: (value: string) => void;
}
const SameEverydayForm = ({
  wholeDayAccess,
  openFrom,
  openTo,
  isLoading,
  getValueFromState,
  updateField,
  handle24HourClick,
  handleOpenFromChange,
  handleOpenToChange,
}: IProps) => {
  const handleTimeStartChange = (date: TDateType) => {
    const value = DateUtils.getHoursAndMinutes(date);
    handleOpenFromChange(value);
  };
  const handleTimeEndChange = (date: TDateType) => {
    const value = DateUtils.getHoursAndMinutes(date);
    handleOpenToChange(value);
  };

  return (
    <>
      <BadgesContainer>
        {Object.values(EDays).map((day) => (
          <RowItem key={day}>
            <NW2Badge
              label={capitalizeText(day)}
              isActive={getValueFromState(day, 'active') as boolean}
              fullWidth
              size='small'
              disabled={isLoading}
              onClick={() =>
                updateField(day, 'active', !getValueFromState(day, 'active'))
              }
            />
          </RowItem>
        ))}
      </BadgesContainer>

      <HoursContainer>
        {!wholeDayAccess && (
          <NW2TimeRangePicker
            valueStart={setTimeFromStringToDate(openFrom)}
            valueEnd={setTimeFromStringToDate(openTo)}
            onChangeStart={handleTimeStartChange}
            onChangeEnd={handleTimeEndChange}
            labelStart='From'
            labelEnd='To'
            size='medium'
          />
        )}

        <NW2Switch
          label='24 hour access'
          isActive={wholeDayAccess}
          disabled={isLoading}
          onClick={handle24HourClick}
        />
      </HoursContainer>
    </>
  );
};

export default SameEverydayForm;

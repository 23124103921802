import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {offsetDef, whiteColor} from 'constants/styleVars';

const sharedStyles = css`
  display: flex;
  align-items: center;
  gap: ${offsetDef};
`;

const FlexContainer = styled.div`
  ${sharedStyles};
  color: ${whiteColor};
  text-align: left;

  > * {
    width: 100%;
  }
`;

const Group = styled.div`
  ${sharedStyles};
  max-width: 130px;
`;

const TimeContainer = styled.div`
  > span {
    display: inline-block;
    white-space: nowrap;
  }
`;

type TProps = {
  startDate: string;
  endDate: string;
  buttonConfirm: ReactNode;
};

function FooterMobileContent({startDate, endDate, buttonConfirm}: TProps) {
  return (
    <FlexContainer>
      <Group>
        <Icon icon='NW2_CALENDAR' whiteColor />
        <TimeContainer>
          <span>{startDate} -&nbsp;</span>
          <span>{endDate}</span>
        </TimeContainer>
      </Group>
      {buttonConfirm}
    </FlexContainer>
  );
}

export default FooterMobileContent;

import styled, {css} from 'styled-components';

import {offsetDef, offsetXLg} from 'constants/styleVars';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${offsetDef};
`;

export const Container = styled.div<{isInline?: boolean}>(
  ({isInline}) => css`
    display: grid;
    grid-template-columns: repeat(${isInline ? 3 : 4}, 1fr);
    grid-column-gap: ${offsetXLg};
    grid-row-gap: ${offsetXLg};

    ${!isInline &&
    css`
      grid-template-rows: repeat(2, 1fr);

      > div {
        &:nth-child(1) {
          grid-area: 1 / 1 / 3 / 3;
        }
        &:nth-child(2) {
          grid-area: 1 / 3 / 2 / 4;
        }
        &:nth-child(3) {
          grid-area: 1 / 4 / 2 / 5;
        }
        &:nth-child(4) {
          grid-area: 2 / 3 / 3 / 4;
        }
        &:nth-child(5) {
          grid-area: 2 / 4 / 3 / 5;
        }
      }
    `}
  `,
);

import styled, {css} from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  fontWeightBold,
  fontWeightExtraBold,
  NW2FontSizeH5Md,
  NW2Gray100Color,
  offset26,
  offsetXXLg,
  offsetXSm,
  offsetXLg,
  offsetDef,
  fontSizeMd,
  lineHeightLg,
  fontSizeXSm,
  fontSizeXLg,
  mdBp,
  NW2Gray600Color,
} from 'constants/styleVars';

export const TotalPriceWrapper = styled.div`
  width: 100%;

  @media (min-width: ${mdBp}px) {
    border-left: 1px solid ${NW2Gray600Color};
    padding-left: ${offset26};
  }
`;

export const TotalPriceBox = styled.div<{isMobile: boolean}>`
  ${({isMobile}) =>
    isMobile
      ? css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid ${NW2Gray600Color};
          padding-bottom: ${offsetXLg};
        `
      : css`
          margin-bottom: ${offsetXXLg};
        `}
`;

export const TotalPriceTitle = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
`;
export const TotalPriceSubTitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${fontSizeXLg};
`;
export const TotalPrice = styled.span`
  font-size: ${NW2FontSizeH5Md};
  font-weight: ${fontWeightBold};
  margin-top: ${offsetXSm};
  line-height: ${offsetXXLg};
`;
export const AmenitiesTitle = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
  margin-bottom: ${offsetDef};
  margin-top: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    margin-top: ${offsetXLg};
  }
`;
export const AmenitiesText = styled.div`
  color: ${NW2Gray100Color};
  font-size: ${fontSizeMd};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXSm};
  border-bottom: 1px solid ${NW2Gray600Color};

  @media (min-width: ${mdBp}px) {
    padding-bottom: ${offsetXLg};
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  margin-bottom: ${offsetXSm};

  & svg {
    margin-right: ${offsetXSm};

    & path {
      stroke: ${NW2Gray100Color};
    }
  }
`;

export const StyledButtonRequest = styled(NW2Button)`
  font-weight: ${fontWeightBold};
  text-transform: none;
`;

import React from 'react';

import BillingAddressView from 'view/components/BillingAddress/BillingAddressView';
import VenueDetailsCard from 'view/venue/NW2BookingConfirmation/components/VenueDetailsCard';
import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import AboutMeeting from 'view/venue/NW2BookingConfirmation/components/AboutMeeting';
import NW2BookingConfirmationUnitsBlock from 'view/venue/NW2BookingConfirmation/components/NW2BookingConfirmationUnitsBlock';
import ItemPaymentInformation from 'view/venue/components/LeftSideContainer/ItemPaymentInformation';
import BookingConfirmCancellationPolicy from '../BookingConfirmCancellationPolicy/BookingConfirmCancellationPolicy';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';

import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EBookingStatus} from 'types/booking';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {IOfferRequestDay} from 'types/offer';
import {IPaymentInfo} from 'types/bookingOrders';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';

import CustomerTermsAndPolicyBlock from 'view/common/CustomerTermsAndPolicyBlock';
import {TPolicyDocument} from 'types/dto/IBooking.types';
import {useAppSelector} from 'store/hooks';

type TProps = {
  freeOfCharge?: number;
  orderNumber?: string;
  termsAndConditions?: string | null;
  cancellationPolicy?: TPolicyDocument;
  meetingName?: string;
  accommodationType?: EAccommodationType;
  roomType?: ERoomType;
  preArrivals?: IOfferRequestDay[]; // todo check pre & post
  postEvents?: IOfferRequestDay[];
  paymentInfo?: IPaymentInfo;
};
function BookingConfirmationBlockList({
  orderNumber,
  accommodationType,
  meetingName,
  preArrivals,
  postEvents,
  roomType,
  paymentInfo,
  cancellationPolicy,
  termsAndConditions,
  freeOfCharge,
}: TProps) {
  const isPublicVenue = accommodationType === EAccommodationType.VENUE;

  const customerBookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );

  const isHiddenCancellationPolicy = useAppSelector(
    ({cancellationPolicy}) => cancellationPolicy.isHidden,
  );

  const {bookingStatus, billingAddressDto, customerDeleted} =
    customerBookingOrder || {};
  const passedStatus = bookingStatus === EBookingStatus.PASSED;

  const emptyTerms = passedStatus && !termsAndConditions;

  const isVenueContactsHidden = bookingStatus !== EBookingStatus.CONFIRMED;

  const isDeclinedOrExpired = [
    EBookingStatus.RFP_DECLINED,
    EBookingStatus.RFP_EXPIRED,
    EBookingStatus.RTC_EXPIRED,
  ].includes(bookingStatus as EBookingStatus);

  const isCancellationPolicyHidden =
    isHiddenCancellationPolicy ||
    !isPublicVenue ||
    emptyTerms ||
    isDeclinedOrExpired;

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const isCancelled =
    bookingStatus === EBookingStatus.CANCELED ||
    bookingStatus === EBookingStatus.RFP_CANCELLED;

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  return (
    <StyledLeftSideContainer>
      {isCancelled && (
        <LeftSideItem
          item={{
            title: 'Terms and Conditions',
            children:
              roomType === ERoomType.WORK_SPACE ? (
                <BookingConfirmCancellationPolicy />
              ) : (
                <CustomerTermsAndPolicyBlock
                  bookingStatus={bookingStatus}
                  cancellationPolicy={cancellationPolicy}
                  termsAndConditionsId={termsAndConditions}
                  orderNumber={orderNumber}
                  freeOfCharge={freeOfCharge}
                />
              ),
          }}
          isHidden={isCancellationPolicyHidden}
        />
      )}
      <LeftSideItem
        item={{
          title: 'Venue contact details',
          children: <VenueDetailsCard />,
        }}
        isHidden={isVenueContactsHidden}
      />
      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeeting meetingName={meetingName} />,
        }}
        isHidden={!meetingName}
      />
      {!!preArrivals?.length && (
        <Bedrooms rooms={preArrivals} title='PRE-ARRIVAL' />
      )}
      <NW2BookingConfirmationUnitsBlock
        hasPreArrivals={!!preArrivals?.length}
        hasPostEvents={!!postEvents?.length}
      />
      {!!postEvents?.length && (
        <Bedrooms rooms={postEvents} title='POST-EVENT' />
      )}
      <ItemPaymentInformation
        paymentInfo={paymentInfo}
        billingAddress={billingAddressDto}
        isHidden={isOffice || !isHrsPayEnabled}
      />

      {!isCancelled && (
        <LeftSideItem
          item={{
            title: 'Terms and Conditions',
            children:
              roomType === ERoomType.WORK_SPACE ? (
                <BookingConfirmCancellationPolicy />
              ) : (
                <CustomerTermsAndPolicyBlock
                  bookingStatus={bookingStatus}
                  cancellationPolicy={cancellationPolicy}
                  termsAndConditionsId={termsAndConditions}
                  orderNumber={orderNumber}
                  freeOfCharge={freeOfCharge}
                  isCancellationShown={!isCancelled && !passedStatus}
                />
              ),
          }}
          isHidden={isCancellationPolicyHidden}
        />
      )}

      <LeftSideItem
        item={{
          title: 'Billing address',
          children: <BillingAddressView billingAddress={billingAddressDto} />,
        }}
        isHidden={!billingAddressDto || customerDeleted}
      />
    </StyledLeftSideContainer>
  );
}

export default BookingConfirmationBlockList;

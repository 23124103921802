import React, {ReactNode, useCallback, useMemo, useRef} from 'react';
import {use100vh} from 'react-div-100vh';

import DRAWER_CONFIG from './NW2SummaryBlock.config';

import {NW2SummaryDesktopBlock} from './NW2SummaryDesktopBlock';
import {useResizeObserver} from 'hooks/useResizeObserver';

import {
  FooterMobile,
  FooterMobileClickArea,
  StyledDrawerButtonClose,
  TotalContainerMobile,
  TotalHeaderMobile,
  TotalMobileMain,
  TotalMobileMainUnits,
  TotalWrapperMobile,
} from 'view/components/NW2SummaryBlock/NW2SummaryBlock.styles';

type TProps = {
  isMobile: boolean;
  isSticky?: boolean;
  isDrawerShowed: boolean;
  setIsDrawerShowed: (arg0: boolean) => void;
  footerMobileContent?: ReactNode;
  buttonConfirm?: ReactNode;
  headerSection?: ReactNode;
  totalPriceSection?: ReactNode;
  totalDiffPriceSection?: ReactNode;
  unitsSection: ReactNode;
  linkTermsText?: string;
  viewPortSpacing?: string;
  footerPosition?: string;
  offsetOpened?: number;
  offsetClosed?: number;
};

function NW2SummaryBlock({
  isMobile,
  isSticky = true,
  isDrawerShowed,
  setIsDrawerShowed,
  headerSection = null,
  totalPriceSection = null,
  footerMobileContent = null,
  buttonConfirm = null,
  unitsSection,
  linkTermsText,
  viewPortSpacing,
  totalDiffPriceSection,
  footerPosition,
  offsetOpened = 0,
  offsetClosed = 0,
}: TProps) {
  const onToggleDrawer = useCallback(() => {
    setIsDrawerShowed(!isDrawerShowed);
  }, [isDrawerShowed, setIsDrawerShowed]);

  // mobile drawer calculations
  const heightVh = use100vh() || 0;
  const drawerContentRef = useRef(null);
  const {height: drawerContentHeight} = useResizeObserver(drawerContentRef);
  const drawerSizes = useMemo((): {height: string; translateY: string} => {
    const {TOTAL_FOOTER_HEIGHT, CONTROL_ITEM_HEIGHT, OFFSET} = DRAWER_CONFIG;

    const maxHeight: number =
      heightVh - DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT - OFFSET;

    const height: number =
      drawerContentHeight >= maxHeight ? maxHeight : drawerContentHeight;

    const translateYOpened: number =
      -height - TOTAL_FOOTER_HEIGHT - offsetOpened;
    const translateYClosed: number =
      -CONTROL_ITEM_HEIGHT - TOTAL_FOOTER_HEIGHT - offsetClosed;

    const translateY: number = isDrawerShowed
      ? translateYOpened
      : translateYClosed;

    return {height: `${height}px`, translateY: `${translateY}px`};
  }, [
    heightVh,
    drawerContentHeight,
    offsetOpened,
    offsetClosed,
    isDrawerShowed,
  ]);

  return isMobile ? (
    <>
      <TotalWrapperMobile
        size={drawerSizes.height}
        translateY={drawerSizes.translateY}
      >
        <TotalContainerMobile ref={drawerContentRef} isLoaded={isDrawerShowed}>
          <TotalHeaderMobile>
            {headerSection}

            <StyledDrawerButtonClose
              icon='NW2_CLOSE'
              onClick={onToggleDrawer}
              size={12}
            />
          </TotalHeaderMobile>

          <TotalMobileMain footerPosition={footerPosition}>
            <TotalMobileMainUnits>{unitsSection}</TotalMobileMainUnits>
            {!!totalDiffPriceSection && totalDiffPriceSection}
            {totalPriceSection}
          </TotalMobileMain>
        </TotalContainerMobile>
      </TotalWrapperMobile>

      <FooterMobile footerPosition={footerPosition}>
        <FooterMobileClickArea
          onClick={onToggleDrawer}
          isShowed={!isDrawerShowed}
        />
        {footerMobileContent}
      </FooterMobile>
    </>
  ) : (
    <NW2SummaryDesktopBlock
      isSticky={isSticky}
      headerSection={headerSection}
      totalPriceSection={totalPriceSection}
      buttonConfirm={buttonConfirm}
      unitsSection={unitsSection}
      linkTermsText={linkTermsText}
      viewPortSpacing={viewPortSpacing}
      totalDiffPriceSection={totalDiffPriceSection}
    />
  );
}

export default NW2SummaryBlock;

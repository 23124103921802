import React from 'react';
import styled from 'styled-components';

import NW2FormItemRadio from 'view/components/NW2FormItem/components/NW2FormItemRadio';

import {
  borderRadiusDef,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';
import {ERadioSizes} from 'view/components/NW2FormItem/components/NW2FormItemRadio/types';
import {EAddDeskFormFields, roomTypesData} from '../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EDeskSpaceName} from 'types/venue';

export const Wrapper = styled.div`
  display: flex;
  gap: ${offsetXLg};
  justify-content: center;
`;
export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${offsetXXLg} ${offsetDef} ${offsetDef};
`;

export const StyledImage = styled.img`
  border-radius: ${borderRadiusDef} ${borderRadiusDef} 0 0;
`;

export const RoomTypeItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 326px;
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusDef};
`;

export const AddDeskType = () => {
  return (
    <Wrapper>
      {[ERoomType.SHARED_SPACE, ERoomType.OPEN_SPACE].map((roomType) => {
        const data = roomTypesData[roomType];
        const name = EDeskSpaceName[roomType as keyof typeof EDeskSpaceName];

        return (
          <RoomTypeItem key={roomType}>
            <StyledImage src={data?.image} />
            <TextBlock>
              <NW2FormItemRadio
                value={roomType}
                id={roomType}
                label={name}
                name={EAddDeskFormFields.TYPE}
                size={ERadioSizes.MEDIUM}
              />
              <div>{data?.text}</div>
            </TextBlock>
          </RoomTypeItem>
        );
      })}
    </Wrapper>
  );
};

import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {borderRadiusDef} from 'constants/styleVars';

export const OfferImageBox = styled.div<{
  bgColor: string;
  iconColor: string;
  boxHeight?: string;
  boxBorder?: string;
}>`
  display: flex;
  background-color: ${({bgColor}) => bgColor};
  border-radius: ${borderRadiusDef};
  align-items: center;
  justify-content: center;
  height: ${({boxHeight}) => boxHeight};
  border: ${({boxBorder}) => boxBorder};

  div {
    color: ${({iconColor}) => iconColor};
  }
`;

export const OfferImageBoxContainer = styled.div<{
  iconWidth: string;
  spacing: string;
}>`
  display: flex;
  width: ${({iconWidth}) => iconWidth};
  padding: ${({spacing}) => spacing};

  svg {
    width: 100%;
  }
`;

interface IProps {
  iconColor: string;
  bgColor: string;
  iconWidth: string;
  spacing: string;
  boxHeight?: string;
  boxBorder?: string;
}

export function OfferUnitImage({
  bgColor,
  iconColor,
  iconWidth,
  spacing,
  boxHeight,
  boxBorder,
}: IProps) {
  return (
    <OfferImageBox
      bgColor={bgColor}
      iconColor={iconColor}
      boxHeight={boxHeight}
      boxBorder={boxBorder}
    >
      <OfferImageBoxContainer iconWidth={iconWidth} spacing={spacing}>
        <Icon icon='OFFER_UNIT_COVER' />
      </OfferImageBoxContainer>
    </OfferImageBox>
  );
}

import React, {useCallback, useEffect, useState} from 'react';
import {useAppSelector} from 'store/hooks';

import {useClickOutside} from 'hooks/useClickOutside';
import {EMPTY_ARRAY} from 'constants/app';
import {TGeocodeAddressData} from 'types/googleMap';

import {
  StyledNW2FormItemInput,
  StyledPredictions,
  AutocompleteContainer,
} from './NW2FormItemGoogleAutocomplete.styles';

type TProps = {
  callbackFunction?: (arg0: TGeocodeAddressData) => void;
};

function NW2FormItemGoogleAutocomplete({callbackFunction}: TProps) {
  const [value, setValue] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [predictions, setPredictions] = useState(EMPTY_ARRAY);

  const isGoogleMapLoaded = useAppSelector(({app}) => app.isGoogleMapLoaded);

  const google = (window as any).google;

  const displaySuggestions = useCallback(
    (predictions: React.SetStateAction<never[]>, status: any) => {
      if (
        !isGoogleMapLoaded ||
        status != google.maps.places.PlacesServiceStatus.OK ||
        !predictions?.length
      ) {
        return;
      }
      setPredictions(predictions);
    },
    [isGoogleMapLoaded, google],
  );

  const onBlur = useCallback(() => {
    setIsVisible(false);
  }, []);

  const onFocus = () => {
    if (value) {
      setIsVisible(true);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (!isVisible) setIsVisible(true);
  };

  const [searchContainer] = useClickOutside(isVisible, onBlur);

  const onSelect = useCallback(
    (searchData: TGeocodeAddressData) => {
      if (callbackFunction) callbackFunction(searchData);
      setValue(searchData.address);
      setIsVisible(false);
    },
    [callbackFunction],
  );

  useEffect(() => {
    if (value && isGoogleMapLoaded) {
      const service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({input: value}, displaySuggestions);
    } else {
      setPredictions(EMPTY_ARRAY);
    }
  }, [isGoogleMapLoaded, google, value, displaySuggestions]);

  // clear field on unmount
  useEffect(() => {
    return () => setValue('');
  }, []);

  return (
    <AutocompleteContainer ref={searchContainer}>
      <StyledNW2FormItemInput
        name='search'
        type='text'
        autoComplete='off'
        role='presentation'
        label='Search for your billing address?'
        placeholder='Search for your billing address?'
        placeholderIcon='SEARCH'
        showAllValidationErrors
        onFocus={onFocus}
        onChange={onChange}
        value={value}
      />

      {isVisible && (
        <StyledPredictions
          predictions={predictions}
          onSearch={onSelect}
          isInModal
        />
      )}
    </AutocompleteContainer>
  );
}

export default React.memo(NW2FormItemGoogleAutocomplete);

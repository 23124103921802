import React, {ReactNode} from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {
  borderRadiusMLg,
  fontSizeXSm,
  fontWeightExtraBold,
  infoColor,
  lineHeightSm,
  NW2Info50Color,
  NW2Warn500Color,
  offset14,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXSm,
} from 'constants/styleVars';

const Wrapper = styled.div<{isCentered: boolean}>`
  display: grid;
  grid-template-columns: ${offsetDef} 1fr;
  grid-column-gap: ${offset14};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  background-color: ${NW2Info50Color};
  padding: ${offsetDef} ${offsetSm};
  border-radius: ${borderRadiusMLg};
  margin: 0 ${({isCentered}) => (isCentered ? 'auto' : '0')} ${offsetXLg};
  max-width: 326px;
`;

const Title = styled.div`
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${infoColor};
  margin-bottom: ${offsetXXSm};
`;

const IconBox = styled.div<{iconColor?: string}>`
  > div {
    color: ${({iconColor}) => iconColor || NW2Warn500Color};
  }
`;

interface IProps {
  title: string;
  text: ReactNode;
  isCentered?: boolean;
  icon?: string;
  iconColor?: string;
  children?: ReactNode;
}

export function StepHint({
  title,
  text,
  icon,
  iconColor,
  isCentered = true,
  children,
}: IProps) {
  return (
    <Wrapper isCentered={isCentered}>
      <IconBox iconColor={iconColor}>
        <Icon icon={icon || 'STAR'} />
      </IconBox>
      <div>
        <Title>{title}</Title>
        <div>{text}</div>
        {children}
      </div>
    </Wrapper>
  );
}

import React, {useState} from 'react';
import {Form, notification} from 'antd';
import {useNavigate} from 'react-router-dom';
import Title from 'antd/es/typography/Title';

import Content from 'view/common/Content';
import VenueInfoBlockCard from 'view/components/VenueInfoBlockCard';
import FormItemEmail from 'view/components/FormItemEmail';
import NWLink from 'view/components/NWLink';
import useForgotPassword from 'view/auth/utils/useForgotPassword';

import {Routes} from 'constants/routes';
import {isEmailValid} from 'infra/common/venueValidation';
import {ButtonContainer, StyledButton} from './styles';

const ForgotResetPasswordSupplier = () => {
  const [form] = Form.useForm();
  const [email, updateEmail] = useState('');

  const navigate = useNavigate();
  const {submitForgotPassword} = useForgotPassword();

  const getTitle = () => {
    return (
      <div>
        <NWLink
          secondary
          text='Sign into your account'
          onClick={returnToSignIn}
        />
        <div>
          <Title level={5}>Forgot your password?</Title>
        </div>

        <span>
          Enter the email address associated with your NewWork account below to
          receive the instructions on how to reset your password
        </span>
      </div>
    );
  };

  const forgotPasswordCompleted = () => {
    const notificationOptions = {
      message: 'Go to your email and click on recover link',
    };
    notification.success(notificationOptions);
    returnToSignIn();
  };

  const forgotPassword = async () => {
    const isValid = isEmailValid(email);
    if (!isValid) return form.validateFields(['email']);

    submitForgotPassword(email, forgotPasswordCompleted);
  };

  const returnToSignIn = () => {
    navigate(Routes.login);
  };

  return (
    <Content>
      {getTitle()}
      <VenueInfoBlockCard title={''}>
        <Form form={form}>
          <FormItemEmail
            name={'email'}
            placeholder={'* E-mail address'}
            message={'Email address'}
            functionUpdate={updateEmail}
          />
        </Form>
        <ButtonContainer>
          <StyledButton type='primary' onClick={forgotPassword}>
            Submit
          </StyledButton>
        </ButtonContainer>
      </VenueInfoBlockCard>
    </Content>
  );
};

export default ForgotResetPasswordSupplier;

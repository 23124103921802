import React, {useEffect, useState} from 'react';
import {notification} from 'antd';

import NW2ModalDrawer from '../NW2ModalDrawer';
import NMMSubmitSection from '../NMMSubmitSection';

import {FileUploaderProgressBar} from './components/FileUploaderProgressBar';
import {useUploader} from './useUploader';
import {ImageUploaderProgressBar} from './components/ImageUploaderProgressBar';

import {AdditionalInfo, StyledConfirmButton} from './Uploader.styles';
import {EUploadedFileType, THandleUpdateImages, TUploadFile} from './types';
import {offsetSm, offsetXLg} from 'constants/styleVars';

interface IProps {
  uploadedFile?: TUploadFile | null;
  uploadedFileType: EUploadedFileType;
  isMultiple?: boolean;
  label?: string;
  maxFiles?: number;
  modalHeader?: string;
  modalBody?: string;
  onFileUpload: (
    file: TUploadFile,
    handleProgress: (percent: number) => void,
  ) => void;
  onRequest?: THandleUpdateImages;
  onFileDelete?: (id: string) => void;
  isAdminUploader?: boolean;
}

export function Uploader({
  uploadedFile,
  uploadedFileType,
  isMultiple,
  label,
  maxFiles,
  modalHeader,
  modalBody,
  onFileUpload,
  onRequest,
  onFileDelete,
  isAdminUploader,
}: IProps) {
  const {
    handleUploading,
    handleReset,
    files,
    progress,
    uploadComponent,
    isUploading,
  } = useUploader({
    uploadedFile,
    uploadedFileType,
    isMultiple,
    label,
    maxFiles,
    onFileUpload,
    onRequest,
    onFileDelete,
  });

  const [openModal, setOpenModal] = useState(false);

  const isFile = uploadedFileType === EUploadedFileType.PDF_FILE;

  const handleDelete = () => {
    setOpenModal(false);
    handleReset();
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (isUploading && (uploadedFile || progress === 100)) {
      handleUploading(false);

      notification.success({
        message: `Your ${
          isFile ? 'file' : 'image'
        } have now been successfully uploaded.`,
        duration: 4,
      });
    }
  }, [handleUploading, isFile, isUploading, progress, uploadedFile]);

  return (
    <>
      <div>{uploadComponent}</div>

      {isFile ? (
        <>
          <AdditionalInfo>
            The PDF file name is for internal use only and won't be visible to
            the booker{isAdminUploader && ' or the venue'}.
          </AdditionalInfo>
          <FileUploaderProgressBar
            uploadedFile={uploadedFile}
            handleReset={handleReset}
            openModal={setOpenModal}
            progress={100}
          />
        </>
      ) : (
        <ImageUploaderProgressBar
          progress={progress}
          title={`${files.length} files being uploaded`}
        />
      )}

      <NW2ModalDrawer
        header={modalHeader}
        body={modalBody}
        footer={
          <NMMSubmitSection
            cancelLabel='close'
            handleCancel={handleCancel}
            gap={offsetSm}
            justifyContent='flex-end'
            margin='0'
            customConfirmButton={
              <StyledConfirmButton
                type='button'
                buttonType='primary'
                size='small'
                data-testid='form-save-button'
                onClick={handleDelete}
              >
                Delete
              </StyledConfirmButton>
            }
          />
        }
        isShowed={openModal}
        onClose={handleCancel}
        modalWidth={440}
        isMobile={false}
        headerPadding={`${offsetXLg}`}
        modalBodyStyle={{padding: `${offsetXLg} ${offsetXLg} 0`}}
        footerPadding={`${offsetXLg}`}
      />
    </>
  );
}

import React, {useState} from 'react';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {
  BorderedContainer,
  Wrapper,
  Title,
  Link,
  Row,
  StyledLink,
  StyledIcon,
  ChargeStatusBlock,
  ConfirmationText,
  SubTitle,
} from './Header.styles';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';

interface IProps {
  isMobile: boolean;
  isIncursChangeFees: boolean;
  shouldConfirmByVenue: boolean;
  cancellationPolicyText: string;
}

const Header = ({
  isMobile,
  isIncursChangeFees,
  shouldConfirmByVenue,
  cancellationPolicyText,
}: IProps) => {
  const [isShowFAQ, setIsShowFAQ] = useState(false);
  const [isShowCancellationPolicy, setIsShowCancellationPolicy] =
    useState(false);

  const onShowFAQ = () => {
    setIsShowFAQ(true);
  };
  const onCloseFAQ = () => {
    setIsShowFAQ(false);
  };
  const onShowCancellationPolicy = () => {
    setIsShowCancellationPolicy(true);
  };
  const onCloseCancellationPolicy = () => {
    setIsShowCancellationPolicy(false);
  };

  const iconColor = false;
  const modalWidth = 400;
  const modalBodyStyle = {fontSize: 16};

  const FAQBody = (
    <>
      <SubTitle>What happens if the venue declines booking changes?</SubTitle>
      <div>
        If requested changes to a booking are rejected by a venue, then the
        booking will remain unchanged. The booking will not be cancelled unless
        you choose to do so.
      </div>
    </>
  );

  const cancellationPolicyBody = (
    <>
      <div style={{marginBottom: 20}}>{cancellationPolicyText}</div>
      <ChargeStatusBlock isIncursChangeFees={true}>
        <StyledIcon icon='TRIANGLE_WARN' iconColor={false} />
        <span style={{fontSize: 13}}>
          Removing existing services incurs change fees.&nbsp;
        </span>
      </ChargeStatusBlock>
    </>
  );

  return (
    <>
      <NW2ModalDrawer
        isShowed={isShowFAQ}
        header='FAQ'
        body={FAQBody}
        onClose={onCloseFAQ}
        isMobile={isMobile}
        modalWidth={modalWidth}
        modalBodyStyle={modalBodyStyle}
      />
      <NW2ModalDrawer
        isShowed={isShowCancellationPolicy}
        header='Cancellation policy'
        body={cancellationPolicyBody}
        onClose={onCloseCancellationPolicy}
        isMobile={isMobile}
        modalWidth={modalWidth}
        modalBodyStyle={modalBodyStyle}
      />
      <BorderedContainer>
        <NW2Container>
          <Wrapper>
            <Title>Edit booking</Title>
            {shouldConfirmByVenue && (
              <>
                <ConfirmationText>
                  The addition of extra services and an extension of the
                  reservation time will be subject to confirmation by the venue.
                </ConfirmationText>
                <StyledLink buttonType='link' onClick={onShowFAQ}>
                  What happens if the venue declines booking changes?
                </StyledLink>
              </>
            )}
            <ChargeStatusBlock isIncursChangeFees={isIncursChangeFees}>
              {isIncursChangeFees ? (
                <>
                  <StyledIcon icon='TRIANGLE_WARN' iconColor={iconColor} />
                  <div>
                    <span>
                      Removing existing services incurs change fees.&nbsp;
                    </span>
                    <Link buttonType='link' onClick={onShowCancellationPolicy}>
                      Show cancellation policy
                    </Link>
                  </div>
                </>
              ) : (
                <Row>
                  <StyledIcon icon='NW2_TICK' iconColor={iconColor} />
                  <span>You can remove services free-of-charge</span>
                </Row>
              )}
            </ChargeStatusBlock>
          </Wrapper>
        </NW2Container>
      </BorderedContainer>
    </>
  );
};

export default Header;

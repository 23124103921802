import React from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import {SpacesListBlock} from '../SpacesListBlock/SpacesListBlock';
import {MeetingRoomSpacesListBlock} from '../SpacesListBlock/MeetingRoomSpacesListBlock';
import {ShareButton} from '../ShareButton';
import NW2DatepickerDetails from '../NW2DatepickerVenueDetails/NW2DatepickerDetails';

import {EMPTY_OBJECT} from 'constants/app';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {setIsVenueDetailsMapVisible} from 'store/venues/venuesSlice';
import {ESectionIds} from 'view/venue/NW2VenueDetails/components/AnchorBlock/helpers';
import {
  VenueOffersContainer,
  Header,
  AddressBlock,
  StyledLink,
  ShareChangeButtonBlock,
  VenueOfferTitle,
} from './VenueOffers.styles';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {ERoomType} from 'types/dto/ERoomType.type';

type TProps = {
  hasBedroom: boolean;
};
export function VenueOffers({hasBedroom}: TProps) {
  const dispatch = useDispatch();

  const searchData = useAppSelector(({search}) => search.searchCriteria);

  const {venueDetails, isAlternative} = useVenueDetailsData();

  const address = getAddressStringFromLocation(
    venueDetails.location || EMPTY_OBJECT,
    true,
  );

  const isWorkDesk = searchData.roomType === ERoomType.WORK_SPACE;

  // Uncomment if datepicker will need on multiRoom details page

  // const toggleModalChangeSearchVisibility = useCallback(() => {
  //   setIsModalChangeSearchVisible((prevState) => !prevState);
  // }, []);

  // const onChangeModeToggle = () => {
  //   if (searchData.multiRooms) return toggleModalChangeSearchVisibility();
  //   dispatch(
  //     setDetailsDatepickerMobileShowed(!isDetailsDatepickerMobileShowed),
  //   );
  // };

  // const navigateToHomePage = () => {
  //   navigate(Routes.mainLanding);
  //   dispatch(setSearchFocusedInput(DATE_ID));
  // };

  const openVenueDetailsMap = () => dispatch(setIsVenueDetailsMapVisible(true));

  return (
    <VenueOffersContainer>
      <Header>
        <div>
          <VenueOfferTitle>{venueDetails.name}</VenueOfferTitle>
          <AddressBlock isAlternative={isAlternative}>
            <StyledLink onClick={openVenueDetailsMap}>{address}</StyledLink>
            <ShareButton venueDetails={venueDetails} searchData={searchData} />
          </AddressBlock>
        </div>
        {isWorkDesk && (
          <div>
            <NW2DatepickerDetails
            // Uncomment if datepicker will need on multiRoom details page
            // toggleModal={toggleModalChangeSearchVisibility}
            />
          </div>
        )}
      </Header>
      <div id={ESectionIds.VENUE_LIST}>
        {searchData.roomType === ERoomType.MEETING_ROOM ||
        searchData.roomType === ERoomType.GROUPS ? (
          <MeetingRoomSpacesListBlock hasBedroom={hasBedroom} />
        ) : (
          <SpacesListBlock />
        )}
      </div>

      <ShareChangeButtonBlock>
        <ShareButton
          venueDetails={venueDetails}
          searchData={searchData}
          rectangleStyle
          fullWidth
        />
        {/*<StyledButtonChange*/}
        {/*  onClick={onChangeModeToggle}*/}
        {/*  buttonType='secondary'*/}
        {/*  size='small'*/}
        {/*  icon={<Icon icon='NW2_EDIT' size={20} transparent />}*/}
        {/*>*/}
        {/*  change*/}
        {/*</StyledButtonChange>*/}
      </ShareChangeButtonBlock>

      {/*Uncomment if datepicker will need on multiRoom details page*/}

      {/*<NW2ModalDrawer*/}
      {/*  body={*/}
      {/*    <>*/}
      {/*      <p>*/}
      {/*        You are about to change your search parameters. In order to do*/}
      {/*        this we will need to take you back to the home search. Please*/}
      {/*        note, you will lose your existing search inputs.*/}
      {/*      </p>*/}
      {/*      <TextValue bold>Would you like proceed?</TextValue>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  footer={*/}
      {/*    <Group justify='end'>*/}
      {/*      <NW2Button*/}
      {/*        buttonType='secondary'*/}
      {/*        size='responsiveAdaptive'*/}
      {/*        onClick={toggleModalChangeSearchVisibility}*/}
      {/*      >*/}
      {/*        Cancel*/}
      {/*      </NW2Button>*/}
      {/*      <NW2Button*/}
      {/*        buttonType='primary'*/}
      {/*        size='responsiveAdaptive'*/}
      {/*        onClick={navigateToHomePage}*/}
      {/*      >*/}
      {/*        Proceed*/}
      {/*      </NW2Button>*/}
      {/*    </Group>*/}
      {/*  }*/}
      {/*  header='Change search'*/}
      {/*  modalWidth={modalSmallWidth}*/}
      {/*  drawerHeight='auto'*/}
      {/*  isMobile={isMobile}*/}
      {/*  isShowed={isModalChangeSearchVisible}*/}
      {/*  onClose={toggleModalChangeSearchVisibility}*/}
      {/*/>*/}
    </VenueOffersContainer>
  );
}

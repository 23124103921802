import React, {useMemo, useState} from 'react';
import _debounce from 'lodash/debounce';

import Icon from 'view/components/Icon';
import ContactsEditForm from 'view/components/NW2Forms/NW2SupplierContactsDetailsForm.tsx/ContactsEditForm';
import useUpdateVenue from 'view/venue/hooks/useUpdateVenue';

import {ICON_SIZE, parseNameString} from 'view/venue/NW2VenueProfile/helpers';

import {IVenueContactItems, EVenueContactItemType, IVenue} from 'types/venue';
import {TFormProps} from '../FormSection';
import {SectionRow, SectionText} from '../ContactsDetails.styles';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {trimPhoneNumber} from '../helpers';

const useContacts = (
  venue: IVenue,
  title: string,
  contactType: EVenueContactItemType,
  isAdditionalContacts?: boolean,
): TFormProps => {
  const [isEditorShowed, setEditorShowed] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);

  const isExternalVenue = venue.accommodationType === EAccommodationType.VENUE;

  const contactItem: IVenueContactItems[] = venue.contact.contactItems?.filter(
    (item) => item.contactType === contactType,
  );

  const initialValues = useMemo(() => {
    if (!isAdditionalContacts) {
      if (contactItem && contactItem.length !== 0) {
        const {phoneNumber, email, fax, firstName, lastName} = contactItem[0];
        return {phoneNumber, email, fax, firstName, lastName};
      }
      return {
        phoneNumber: '',
        email: '',
        fax: '',
        firstName: '',
        lastName: '',
      };
    } else {
      if (contactItem && contactItem.length !== 0) {
        const {phoneNumber, email, fax} = contactItem[0];
        return {phoneNumber, email, fax};
      }
      return {
        phoneNumber: '',
        email: '',
        fax: '',
      };
    }
  }, [contactItem, isAdditionalContacts]);

  const {phoneNumber, email, fax, firstName, lastName} = initialValues;

  const onEditorShow = () => {
    setEditorShowed(true);
  };
  const onEditorHide = () => {
    setEditorShowed(false);
  };

  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: onEditorHide,
  });

  const onSubmit = async (values: Record<string, string>) => {
    try {
      const updatedItem = () => {
        const commonData = {
          phoneNumber: values.phoneNumber
            ? trimPhoneNumber(values.phoneNumber)
            : '',
          email: values.email || null,
          fax: trimPhoneNumber(values.fax),
        };

        if (!isAdditionalContacts) {
          return {
            ...commonData,
            firstName: values.firstName,
            lastName: values.lastName,
          };
        }
        return commonData;
      };

      let updatedContactItems = [...venue.contact.contactItems];

      const itemWithCorrectContentType = updatedContactItems.some(
        (item) => item.contactType === contactType,
      );
      if (updatedContactItems.length === 0 || !itemWithCorrectContentType) {
        updatedContactItems.push({
          ...updatedItem(),
          contactType: contactType,
          primary: false,
        });
      } else {
        updatedContactItems = updatedContactItems.map((item) => {
          if (item.contactType === contactType) {
            return {...item, ...updatedItem()};
          }
          return item;
        });
      }

      const updatedVenue = {
        ...venue,
        contact: {
          ...venue.contact,
          contactItems: updatedContactItems,
        },
      };

      updateVenue(updatedVenue);

      setEditorShowed(false);
    } catch (error) {}
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return {
    title,
    onSubmit,
    initialValues,
    editForm: <ContactsEditForm isAdditionalContacts={isAdditionalContacts} />,
    showForm: (
      <div>
        {!isAdditionalContacts && (
          <SectionRow>
            <Icon icon='NW2_USER' size={ICON_SIZE} />
            <SectionText data-testid='contacts-user-field'>
              {parseNameString(firstName, lastName)}
            </SectionText>
          </SectionRow>
        )}
        <SectionRow>
          <Icon icon='NW2_EMAIL_SYMBOL' size={ICON_SIZE} />
          <SectionText data-testid='contacts-email-field'>
            {email || '-'}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_PHONE_THIN' size={ICON_SIZE} />
          <SectionText data-testid='contacts-phone-field'>
            {phoneNumber || '-'}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_FAX' size={ICON_SIZE} />
          <SectionText data-testid='contacts-fax-field'>
            {fax || '-'}
          </SectionText>
        </SectionRow>
      </div>
    ),
    isEditorShowed,
    onEditorShow,
    onEditorHide,
    isFormDirty,
    isFormSending: isSending,
    processFormFields,
  };
};

export default useContacts;

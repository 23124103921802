import React from 'react';

import {VenueDetailsBlock} from 'view/venue/NW2VenueDetails/components/VenueDetailsBlock';
import {AlternativesContentBlock} from '../NW2AlternativesBlock/AlternativesContentBlock';
import VenueAmenitiesBlock from './VenueAmenitiesBlock/VenueAmenitiesBlock';
import {IMergedVenue} from 'types/search';

export const offerNavigationHeight = 80;
export const debounceTime = 50;

export const SectionDefaultPosition = {
  top: 0,
  bottom: 0,
};

export enum ESectionIds {
  VENUE_DETAILS = 'details',
  VENUE_AMENITIES = 'amenities',
  ALTERNATIVES = 'alternatives',
  VENUE_LIST = 'list',
}

export type TAnchorContentSections = {
  contentTitle: string;
  contentSubTitle?: string;
  sectionId: ESectionIds;
  contentComponent: JSX.Element;
};

type TProps = {
  amenities: any;
  isAlternativeSectionVisible: boolean;
  isDetailsSectionVisible: boolean;
  alternativeList: IMergedVenue[];
};

export const getAnchorContentSections = ({
  isAlternativeSectionVisible,
  amenities,
  isDetailsSectionVisible,
  alternativeList,
}: TProps): TAnchorContentSections[] => [
  ...(isDetailsSectionVisible
    ? [
        {
          contentTitle: 'venue details',
          contentSubTitle: '',
          sectionId: ESectionIds.VENUE_DETAILS,
          contentComponent: <VenueDetailsBlock />,
        },
      ]
    : []),
  ...(amenities?.length
    ? [
        {
          contentTitle: 'amenities',
          contentSubTitle: '',
          sectionId: ESectionIds.VENUE_AMENITIES,
          contentComponent: <VenueAmenitiesBlock />,
        },
      ]
    : []),
  ...(isAlternativeSectionVisible
    ? [
        {
          contentTitle: 'alternatives',
          contentSubTitle: 'View similar venues nearby',
          sectionId: ESectionIds.ALTERNATIVES,
          contentComponent: (
            <AlternativesContentBlock alternativeList={alternativeList} />
          ),
        },
      ]
    : []),
];

export const menuItemsDefault = (contentSections: TAnchorContentSections[]) =>
  contentSections.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.sectionId]: SectionDefaultPosition,
    }),
    {},
  );

interface IGetButtonText {
  isMultiRoomsBookingNotAvailable: boolean;
  isSelectedMeetingRoomNotAvailable?: boolean;
  isMeetingRoom?: boolean;
  hasBedroom?: boolean;
  isOffice?: boolean;
  isRtb?: boolean;
  isInstant?: boolean;
}

export const getButtonText = ({
  isMultiRoomsBookingNotAvailable,
  isSelectedMeetingRoomNotAvailable,
  isMeetingRoom,
  hasBedroom,
  isOffice,
  isRtb,
  isInstant,
}: IGetButtonText) => {
  switch (true) {
    case hasBedroom && !isOffice:
      return 'Request for offer';

    case isMultiRoomsBookingNotAvailable ||
      (isMeetingRoom && isSelectedMeetingRoomNotAvailable):
      return 'Not available';

    case isRtb:
      return 'Request booking';

    case isInstant:
      return 'Book instantly';

    default:
      return 'Back to offers';
  }
};

import React from 'react';

import {pluralize} from 'utils/helpers';
import {dimensionsConverter} from 'utils/venueUtils';
import {IUnitDimensions} from 'types/venue';

import {
  ContentSubtitle,
  ListsWrapper,
  List,
  ListItem,
} from '../../NMMSpacesDetails.styles';

interface IProps {
  unitDimensions: IUnitDimensions;
}

const DimensionsViewMode = ({unitDimensions}: IProps) => {
  const {area, ceilingHeight} = unitDimensions;

  const areaInFeet = dimensionsConverter('m', String(area), true);
  const ceilingInFeet = dimensionsConverter('m', String(ceilingHeight));

  return (
    <ListsWrapper>
      <div>
        <ContentSubtitle>space area</ContentSubtitle>
        <List>
          <ListItem>
            {Number(area).toFixed(2)} square{' '}
            {pluralize('metres', Number(area), false)}
          </ListItem>
          <ListItem>
            {areaInFeet} square {pluralize('foot', areaInFeet, false)}
          </ListItem>
        </List>
      </div>

      <div>
        <ContentSubtitle>ceiling height</ContentSubtitle>
        <List>
          <ListItem>
            {Number(ceilingHeight).toFixed(2)}{' '}
            {pluralize('metres', Number(ceilingHeight), false)}
          </ListItem>
          <ListItem>{pluralize('foot', ceilingInFeet)}</ListItem>
        </List>
      </div>
    </ListsWrapper>
  );
};

export default DimensionsViewMode;

import {Routes} from 'constants/routes';
import {TNmmNavMenuList} from 'types/header';
import {ESpaceSelectedTab, ISpacesData} from 'types/venue';

export enum SupplierHeaderItems {
  MultiLocation = 'MultiLocation',
  LocationButton = 'LocationButton',
  VenueDetails = 'VenueDetails',
  CreateNewOffice = 'CreateNewOffice',
  BookingMeetingRooms = 'BookingMeetingRooms',
  BookingDesks = 'BookingDesks',
  WorkDesks = 'desk',
  MeetingRooms = 'room',
  Bedrooms = 'bedroom',
  Spaces = 'Spaces',
  Availability = 'Availability',
  AgentView = 'AgentView',
  Services = 'Services',
  Profile = 'Profile',
  LogOut = 'LogOut',
}

type TMenuListProps = {
  currentPath: string;
  role: string;
  venueId: string;
  activeItem: SupplierHeaderItems | '';
  selectMenuItem: (value: SupplierHeaderItems) => void;
  activeDropdownItem: '' | SupplierHeaderItems | ESpaceSelectedTab;
  selectActiveDropdownItem: (id: SupplierHeaderItems) => void;
};

type TMenuListPropsWithSpaces = {
  spaces: ISpacesData[] | null;
} & TMenuListProps;

type TLayoutComponent = {
  component: string;
  isButtonVisible?: boolean;
};

type TLayout = {
  type: string;
  components: TLayoutComponent[];
};

export type RouteValues = (typeof Routes)[keyof typeof Routes];

export type TCustomRoutesLayout = Partial<Record<RouteValues, TLayout>>;

interface Layout {
  defaultLayoutType: TLayout;
  customRoutesLayout: TCustomRoutesLayout;
}

export interface NavItem {
  url: string;
  text: string;
  icon?: string;
}

export type TMenuReturnType = {
  layout: Layout;
  selectLocationButton?: TNmmNavMenuList[];
  quickNavList?: TNmmNavMenuList[];
  restNavList?: TNmmNavMenuList[];
  userMenuList?: TNmmNavMenuList[];
  navList?: NavItem[];
};

export type {
  TLayout,
  TLayoutComponent,
  TMenuListProps,
  TMenuListPropsWithSpaces,
};

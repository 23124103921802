import {Routes} from 'constants/routes';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

const redirectRoutes: Record<string, string> = {
  [EUserRoleCognito.ROLE_CUSTOMER]: Routes.mainLanding,
  [EUserRoleCognito.ROLE_SUPPLIER]: Routes.publicVenue,
  [EUserRoleCognito.ROLE_ADMIN]: Routes.companiesOverview,
  [EUserRoleCognito.ROLE_AGENT]: Routes.agentView,
  [EUserRoleCognito.ROLE_PROPERTY_MANAGER]: Routes.propertyManagerProfile,
};

export const getRedirectUrlByUserRole = (role: EUserRoleCognito): string => {
  return redirectRoutes[role] || '';
};

export default getRedirectUrlByUserRole;

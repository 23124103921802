import {useAppSelector} from 'store/hooks';

const useCorporateAccountId = () => {
  const corporateAccountIdFromStore =
    useAppSelector(({app}) => app.corporateAccountId) || '';

  const corporateAccountId = +corporateAccountIdFromStore;

  return {
    corporateAccountId,
  };
};

export default useCorporateAccountId;

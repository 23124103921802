import React, {useState} from 'react';
import {Input} from 'antd';
import {
  parsePhoneNumber,
  formatPhoneNumberIntl,
  type Value,
} from 'react-phone-number-input';

import {ILegalPhone} from 'types/venue';

const replaceNonNumberValue = (value: string) => value.replace(/[^\d]+/g, '');

export const trimPhoneNumber = (value: ILegalPhone): ILegalPhone => {
  const nonNumberValue = replaceNonNumberValue(value.fullPhoneNumber || '');
  if ('phoneCountryCode' in value) {
    return {
      ...value,
      fullPhoneNumber: formatPhoneNumberIntl(`+${nonNumberValue}` as Value),
    };
  }

  return {
    ...value,
    fullPhoneNumber: nonNumberValue ? `+${nonNumberValue}` : '',
  };
};

type TProps = {
  name: string;
  placeholder: string;
  functionUpdate: (arg: any) => void;
  value: string | null;
  allowClear?: boolean;
};

function FormItemPhoneNumber({
  name,
  placeholder,
  functionUpdate,
  value,
  allowClear,
}: TProps) {
  const [inputValue, setInputValue] = useState('');

  const onChange = (event: any) => {
    const isPhoneIncludesPlus = event.target.value.includes('+');
    const parsedPhone = parsePhoneNumber(
      isPhoneIncludesPlus ? event.target.value : `+${event.target.value}` || '',
    );
    const value = parsedPhone
      ? {
          phoneCountryCode: parsedPhone.countryCallingCode,
          phone: parsedPhone.nationalNumber,
          fullPhoneNumber: parsedPhone.number,
          phoneCountry: parsedPhone.country,
        }
      : {
          fullPhoneNumber: event.target.value,
        };
    const trimmedValue = trimPhoneNumber(value);
    setInputValue(trimmedValue.fullPhoneNumber || '');

    if (functionUpdate) functionUpdate(trimmedValue);
  };

  return (
    <Input
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value || inputValue}
      allowClear={allowClear}
    />
  );
}

export default FormItemPhoneNumber;

import styled from 'styled-components';

import {
  lgBp,
  NW2Gray50Color,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray600Color,
  offsetSm,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
  fontWeightExtraBold,
} from 'constants/styleVars';

export const ListWrapper = styled.div<{isMobile: boolean}>`
  padding-bottom: ${({isMobile}) => (isMobile ? '76px' : 0)};

  & > div:first-child {
    margin-bottom: ${offsetXLg};
  }

  & > div:last-child {
    margin-top: 24px;
  }
`;

export const ItemsWrapper = styled.div`
  display: grid;
  gap: ${offsetXLg} 0;
  & + & {
    margin-top: ${offsetXLg};
  }
`;

export const ItemWrapper = styled.div`
  display: grid;
  gap: ${offsetXLg} 0;
  border-radius: ${offsetDef};
  border: 1px solid ${NW2Gray200Color};
  padding: ${offsetXLg};
`;

export const TwoColumnWrapper = styled.div`
  display: grid;
  gap: ${offsetDef} 0;

  @media (min-width: ${lgBp}px) {
    grid-template-columns: minmax(auto, 111px) 1fr;
    gap: 0 ${offsetXLg};
  }
`;

export const AddDayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${offsetSm} 0;
  border-radius: ${offsetDef};
  background-color: ${NW2Gray50Color};
  cursor: pointer;
  border: 1px dashed ${NW2Gray200Color};
`;

export const AddDayButtonIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${NW2Gray100Color};
`;

export const SubsectionTitle = styled.div`
  color: ${NW2Gray600Color};
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
  margin-bottom: ${offsetXSm};
`;

export const DateLabel = styled.div`
  margin-bottom: ${offsetXXSm};
`;

export const LeftSideItemBlock = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${lgBp}px) {
    display: block;
  }
`;
export const DateWrap = styled.div`
  @media (min-width: ${lgBp}px) {
    display: block;
  }
`;
export const ParticipantsWrap = styled.div`
  margin-top: ${offsetXLg};
`;
export const ParticipantsTitle = styled.div`
  margin-bottom: ${offsetSm};
`;

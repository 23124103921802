import {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {DateTime} from 'luxon';

import DateUtils from 'utils/dateUtils';

import {getDateStringFromTime} from 'utils/stringUtils';
import {useAppSelector} from 'store/hooks';
import {getISOString} from 'utils/dateUtils';
import {
  setEndDateForVenuesSearch,
  setStartDateForVenuesSearch,
  INITIAL_SEARCH_CRITERIA,
} from 'store/search/searchSlice';

import {
  getDefaultCheckIn,
  getDefaultCheckOut,
} from 'constants/defaultOperationalTimes';
import {TDateChangeFn} from './NW2DatePicker.types';
import {TDateType} from 'types/dto/ISearch.types';

interface IProps {
  date: TDateType;
  timeStart: TDateType;
  timeEnd: TDateType;
  dateISOString: string;
  timeStartISOString: string;
  timeEndISOString: string;
  onDateChange: TDateChangeFn;
  onTimeStartChange: TDateChangeFn;
  onTimeEndChange: TDateChangeFn;
  onReset: (callback?: () => void) => void;
  onCancel: () => void;
  isTimeRangeShowed: boolean;
  onChangeTimeComplete: (cb?: () => void) => void;
}

export function useDatePicker(
  setInputValue?: (value: TDateType) => void,
  defaultDate?: string | null,
): IProps {
  const dispatch = useDispatch();

  const {startDate, endDate, roomType} = useAppSelector(
    ({search}) => search.searchCriteria,
  );

  const [date, setDate] = useState('');
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  const [isTimeRangeShowed, setTimeRangeShowed] = useState<boolean>(false);

  useEffect(() => {
    if (defaultDate) {
      setDate(defaultDate);
      setTimeStart(defaultDate);
      if (setInputValue) {
        setInputValue(DateUtils.getDateFromISO(defaultDate));
      }
    } else {
      setDate(startDate);
      setTimeStart(startDate);
    }
  }, [defaultDate, setInputValue, startDate]);

  useEffect(() => {
    setTimeEnd(endDate);
  }, [endDate]);

  const onTimeStartChange = (payload: TDateType) => {
    setTimeStart(getISOString(payload));
  };

  const onTimeEndChange = (payload: TDateType) => {
    setTimeEnd(getISOString(payload));
  };

  const onDateChange = useCallback(
    (payload: TDateType) => {
      setDate(getISOString(payload));
      setTimeStart(getISOString(payload));

      if (setInputValue) {
        setInputValue(payload);
      }

      if (!isTimeRangeShowed) {
        setTimeRangeShowed(true); // show only when date selected
      }
    },
    [isTimeRangeShowed, setInputValue],
  );

  const onCancel = (callback?: () => void) => {
    setDate(INITIAL_SEARCH_CRITERIA.startDate);
    setTimeStart(INITIAL_SEARCH_CRITERIA.startDate);
    setTimeEnd(INITIAL_SEARCH_CRITERIA.endDate);

    if (typeof callback === 'function') {
      callback();
    }
  };

  const onReset = (callback?: () => void) => {
    if (setInputValue) {
      // reset to default in option date
      setTimeStart(startDate);
      setInputValue(null);
      dispatch(setStartDateForVenuesSearch(startDate));
    } else {
      // reset local state
      onCancel();
      setTimeRangeShowed(false);
      // reset store state
      dispatch(setStartDateForVenuesSearch(getDefaultCheckIn(roomType)));
    }

    dispatch(setEndDateForVenuesSearch(getDefaultCheckOut(roomType)));

    if (typeof callback === 'function') {
      callback();
    }
  };

  const onChangeTimeComplete = useCallback(
    (callback?: () => void) => {
      if (date && timeStart && timeEnd) {
        dispatch(
          setStartDateForVenuesSearch(getDateStringFromTime(timeStart, date)),
        );
        dispatch(
          setEndDateForVenuesSearch(getDateStringFromTime(timeEnd, date)),
        );
      }

      if (typeof callback === 'function') {
        callback();
      }
    },
    [date, timeStart, timeEnd, dispatch],
  );

  return {
    date: date ? DateTime.fromISO(date).toJSDate() : null,
    timeStart: timeStart ? DateTime.fromISO(timeStart).toJSDate() : null,
    timeEnd: timeEnd ? DateTime.fromISO(timeEnd).toJSDate() : null,
    dateISOString: date,
    timeStartISOString: timeStart,
    timeEndISOString: timeEnd,
    isTimeRangeShowed,
    onDateChange,
    onTimeStartChange,
    onTimeEndChange,
    onChangeTimeComplete,
    onReset,
    onCancel,
  };
}

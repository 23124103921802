import React, {useMemo} from 'react';

import NW2Button from 'view/components/NW2Button';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {EMPTY_OBJECT} from 'constants/app';
import {
  DEFAULT_FLOOR_STOREY_COUNTRIES,
  floorLevels,
} from 'constants/floorStorey';
import {IVenue} from 'types/venue';
import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {
  ButtonContainer,
  Container,
  floorsModalBodyStyle,
  Wrapper,
} from './AddFloorsModal.styles';

interface IProps {
  showModal: boolean;
  isSending: boolean;
  venue: IVenue;
  onAddFloors: (name: string, floor: number) => void;
  localFloors: number[] | [];
  onSaveAddFloorModal: () => void;
  onCancelAddFloorModal: () => void;
}

export const AddFloorsModal = ({
  onSaveAddFloorModal,
  onCancelAddFloorModal,
  venue,
  onAddFloors,
  localFloors,
  showModal,
  isSending,
}: IProps) => {
  const country = venue.location.country;

  const floorsOptions = useMemo(() => {
    const hasGroundFloor =
      DEFAULT_FLOOR_STOREY_COUNTRIES.includes(country) || !country;

    const options = Object.entries(floorLevels).map(([key, value]) => {
      const floors = venue.floorInformation;
      const currentFloorUnits = floors.filter(
        (floorItem) => floorItem.floorNumber === value,
      )[0];
      const hasUnits = !!Object.values(
        currentFloorUnits?.roomDetailDto || EMPTY_OBJECT,
      ).length;

      return {
        label: key,
        value,
        hasUnits,
      };
    });

    return hasGroundFloor
      ? options
      : options.filter((option) => option.label !== 'Ground Floor');
  }, [venue.floorInformation, country]);

  const modalBody = (
    <Container>
      {floorsOptions.map(({label, value, hasUnits}) => {
        return (
          <Wrapper key={value}>
            <NW2FormItemCheckbox
              name='floorsAdd'
              label={label}
              id={`${value}`}
              value={value}
              disabled={isSending || hasUnits}
              size={ECheckboxSizes.MEDIUM}
              onChange={onAddFloors}
            />
          </Wrapper>
        );
      })}
    </Container>
  );

  const modalFooter = (
    <ButtonContainer>
      <NW2Button
        buttonType='secondary'
        data-testid='floor add cancel'
        onClick={onCancelAddFloorModal}
      >
        Cancel
      </NW2Button>
      <NW2Button
        buttonType='primary'
        disabled={!localFloors.length}
        data-testid='floor add submit'
        onClick={onSaveAddFloorModal}
      >
        Save
      </NW2Button>
    </ButtonContainer>
  );

  return (
    <NW2ModalDrawer
      header='Add floor'
      body={modalBody}
      footer={modalFooter}
      isShowed={showModal}
      isMobile={false}
      modalBodyStyle={floorsModalBodyStyle}
      onClose={onCancelAddFloorModal}
      modalWidth={400}
      modalHeight='90%'
      drawerHeight='auto'
    />
  );
};

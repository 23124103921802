import {useCallback, useMemo} from 'react';

import {
  getFormDataAccommodation,
  getPreselectedAccommodation,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/helpers';
import {ACCOMMODATION_FORM_FIELD_NAME} from 'constants/bedrooms';
import {
  ISearchTemplateConfig,
  searchTemplateConfig,
  TDay,
  TSelectedOptions,
} from 'types/search';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {minimumParticipantsNumber} from 'constants/app';
import {useAppSelector} from 'store/hooks';
import {getBedroomExtraId} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {EBedroomExtras} from 'types/dto/IExtras.type';
import {useMultiSearchData} from '../useMultiSearchData';

export function useInitialMeetingRequestData() {
  const meetingRequestDataFromStore = useAppSelector(
    ({search}) => search.meetingRequestData,
  );
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const timeDataFromStore = useAppSelector(
    ({search}) => search.initialTimeData,
  );
  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );
  const {single, double} = useAppSelector(({search}) => search.groups.rooms);

  const {bedroomsCatering, defaultEquipmentData, defaultSeatsSetup} =
    useMultiSearchData();

  const maxParticipants = Math.max(
    ...meetingRequestDataFromStore.flatMap(({rooms}) =>
      rooms.map((room) => room.participants),
    ),
    minimumParticipantsNumber,
  );

  const initAccommodationData = useCallback(
    (
      isPreOrPostMeet: boolean,
      dayParticipants: number,
      formData?: Record<string, any>,
    ) => {
      const {ids, data, options} = getPreselectedAccommodation(
        isPreOrPostMeet ? maxParticipants : dayParticipants,
      );

      const formDataOptions = getFormDataAccommodation(formData);

      const accommodationData = !formData
        ? {options, ids, data}
        : formData[ACCOMMODATION_FORM_FIELD_NAME]?.length
        ? {
            options: formDataOptions || options,
            ids: formData[ACCOMMODATION_FORM_FIELD_NAME] as number[],
            data: formData,
          }
        : null;

      return {accommodationData, participants: dayParticipants};
    },
    [maxParticipants],
  );

  const initialMeetingRequestData = useMemo(() => {
    const makeInitData = ({meet, food, room}: ISearchTemplateConfig) => {
      const meetTimeData = getFilteredUnitsByEventType(timeDataFromStore);

      const singleData = single.isEnabled ? single : null;
      const doubleData = double.isEnabled ? double : null;

      const singleId = getBedroomExtraId(
        singleData,
        EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST,
        EBedroomExtras.SINGLE_BEDROOM,
      );
      const doubleId = getBedroomExtraId(
        doubleData,
        EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST,
        EBedroomExtras.DOUBLE_BEDROOM,
      );

      const initFormData = {
        accommodation: [singleId, doubleId].filter(Boolean),
        ...(singleId && {[`${singleId}_`]: singleData?.qty}),
        ...(doubleId && {[`${doubleId}_`]: doubleData?.qty}),
      };

      const groupParticipants = (singleData?.qty || 0) + (doubleData?.qty || 0);

      return meetTimeData.map(({timeStart, timeEnd}, index) => {
        const {accommodationData} = room
          ? initAccommodationData(false, groupParticipants, initFormData)
          : {accommodationData: null};

        const defaultRooms = meet
          ? [
              {
                timeStart,
                timeEnd,
                participants,
                equipmentData: defaultEquipmentData,
                seatsSetup: defaultSeatsSetup,
              },
            ]
          : [];

        return {
          startDate: timeStart,
          endDate: timeEnd,
          rooms: defaultRooms,
          foodBeverageData: food ? ({} as TSelectedOptions) : null,
          accommodationData,
          isPreMeet: false,
          isPostMeet: false,
          isSectionExpanded: !index,
          participants,
        };
      });
    };

    const config = searchTemplateConfig[roomType];

    // skip for work desks
    if (!config) return {} as TDay[];

    return makeInitData(config);
  }, [
    defaultEquipmentData,
    defaultSeatsSetup,
    double,
    initAccommodationData,
    participants,
    roomType,
    single,
    timeDataFromStore,
  ]);

  return {initialMeetingRequestData, initAccommodationData, bedroomsCatering};
}

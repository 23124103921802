import React, {ChangeEvent} from 'react';

import {Wrapper, Container, StyledSpan} from './Tabs.styles';
import {ECompanyTab, ITabData} from './types';

type TProps = {
  onTabChange: (data: ECompanyTab) => void;
  tabValue: ECompanyTab;
  tabsData: ITabData[];
};

function Tabs({onTabChange, tabValue, tabsData}: TProps) {
  const onSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as ECompanyTab;

    if (value !== tabValue) {
      onTabChange(value);
    }
  };

  return (
    <Wrapper>
      {tabsData.map(({label, value}) => {
        const isActive = tabValue === value;

        return (
          <Container key={value}>
            <input
              type='radio'
              name='mode'
              id={value}
              value={value}
              onChange={onSelect}
              checked={isActive}
            />
            <label htmlFor={value}>
              <StyledSpan isActive={isActive}>{label}</StyledSpan>
            </label>
          </Container>
        );
      })}
    </Wrapper>
  );
}

export default Tabs;

import React from 'react';

import {
  DateBlock,
  ListItem,
  ListWrapper,
  StyledBold,
} from './CancellationPolicyBlock.styles';
import {IDynamicCancellationPolicyData} from 'types/dto/ICancellationPolicy.type';
import {PolicyMessage} from './PolicyMessage';

export const ConditionsListBlock = ({
  conditionsList,
}: {
  conditionsList: IDynamicCancellationPolicyData[];
}) => {
  return (
    <ListWrapper>
      {conditionsList.map((item, index) => {
        const {freeOfCharge, message, date} = item;
        const isLastItem = conditionsList.length === index + 1;

        return (
          <ListItem key={freeOfCharge} isLastItem={isLastItem}>
            <StyledBold>{freeOfCharge}%</StyledBold>
            <DateBlock>
              <span>
                <PolicyMessage data={message} />
              </span>
              <span>{date}*</span>
            </DateBlock>
          </ListItem>
        );
      })}
    </ListWrapper>
  );
};

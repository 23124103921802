import React from 'react';
import {Form} from 'react-final-form';
import styled from 'styled-components';

import AnnouncementPopupImage from 'img/meeting_seen_through_glass_door.png';
import AnnouncementPopupBody from 'view/supplier/AnnouncementPopup/components/AnnouncementPopupBody';
import NW2FormItemCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox';
import NW2Button from 'view/components/NW2Button';

import {Group} from 'styles/basicStyledComponents/group';
import {offsetSm} from 'styles/configs';

const RightColContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const StyledImg = styled.img`
  object-fit: cover;
`;

type TProps = {
  isMobile?: boolean;
  title: string;
  subTitle: string;
  htmlString: string;
  handleOnSubmit: (formData: Record<string, any>) => void;
  announcementId?: number;
};

export const AnnouncementPopupContent = ({
  isMobile,
  title,
  subTitle,
  htmlString,
  handleOnSubmit,
  announcementId,
}: TProps) => {
  const checkBoxLabelText = 'Do not show this message again.';
  return (
    <Group wrap='nowrap'>
      {!isMobile && (
        <StyledImg src={AnnouncementPopupImage} alt='AnnouncementPopupImage' />
      )}
      <RightColContainer>
        <AnnouncementPopupBody
          title={title}
          subTitle={subTitle}
          htmlString={htmlString}
        />

        <Form onSubmit={handleOnSubmit}>
          {({handleSubmit}) => (
            <form onSubmit={handleSubmit} noValidate>
              <Group justify='space-between' align='center' gap={offsetSm}>
                <NW2FormItemCheckbox
                  name='AnnouncementPopupCheckbox'
                  label={checkBoxLabelText}
                  id={String(announcementId)}
                />
                <NW2Button buttonType='primary' type='submit'>
                  I understand
                </NW2Button>
              </Group>
            </form>
          )}
        </Form>
      </RightColContainer>
    </Group>
  );
};

import React, {memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useAppDispatch} from 'store/hooks';
import NW2InfoCard from 'view/common/NW2InfoCard/NW2InfoCard';
import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns/NW2ModalTwoColumns';

import {NW2ImageItem, NW2ImageList, NW2UploadMultiple} from './components';

import {PATH_TO_REDUCER_VENUE_PHOTOS} from 'constants/venue';
import {
  setCoverPhoto,
  setVenueImagesList,
  updateVenuePhotos,
} from 'store/venue/actions';
import {THandleUpdateImages} from './types';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import {
  InfoCardWrapper,
  RightColHeader,
  RightColHeaderSubTitle,
  RightColHeaderTitle,
  RightColImages,
  RightColMain,
  SecondaryImagesWrapper,
  StyledLink,
} from './NW2Images.styles';
import {RootState} from 'store/types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  venueId: number;
  isEditShowed: boolean;
  isExternalVenue: boolean;
  onToggleEdit: () => void;
}

export const NW2Images = memo(
  ({venueId, isExternalVenue, isEditShowed, onToggleEdit}: IProps) => {
    const dispatch = useAppDispatch();

    const images: TImageFile[] = useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.photoList`),
    );
    const coverImage: string = useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.coverPhoto`),
    );
    const idsToDelete: string[] = useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.deletedVenuePhotoIds`),
    );

    const coverPhoto = useMemo(
      () => images.find((file) => file.isCover),
      [images],
    );

    const onUpdateVenueImages = useCallback<THandleUpdateImages>(
      ({handleProgress}) => {
        const imageData = {
          venueId,
          images,
          coverImage,
          idsToDelete,
          entityName: isExternalVenue ? 'venue' : 'corporate_office',
        };

        dispatch(updateVenuePhotos({imageData, handleProgress}));
      },
      [coverImage, dispatch, idsToDelete, images, isExternalVenue, venueId],
    );

    const onImageRemove = useCallback(
      (file: TImageFile) => {
        const filteredImages = images.filter((el) => el?.uid !== file.uid);

        const imageData = {
          venueId,
          images: filteredImages,
          coverImage,
          idsToDelete: [file.uid],
          entityName: isExternalVenue ? 'venue' : 'corporate_office',
        };

        dispatch(updateVenuePhotos({imageData}));
      },
      [coverImage, dispatch, images, isExternalVenue, venueId],
    );

    const onSetCoverImage = useCallback(
      (file: TImageFile) => {
        dispatch(setCoverPhoto(file.uid as string));

        const imageData = {
          venueId,
          images,
          coverImage: file.uid,
          idsToDelete: [],
          entityName: isExternalVenue ? 'venue' : 'corporate_office',
        };

        dispatch(updateVenuePhotos({imageData}));
      },
      [dispatch, images, isExternalVenue, venueId],
    );

    const onImageUpload = useCallback(
      (file: TImageFile) => {
        dispatch(setVenueImagesList(file));
      },
      [dispatch],
    );

    const separatedImages = useMemo(() => {
      const filteredImagesByCover = images.filter((img) => !img?.isCover);

      const primary = Array.from({length: 4}).map(
        (item, idx) => filteredImagesByCover[idx],
      );

      return {
        primary,
        secondary:
          filteredImagesByCover.length > 4
            ? filteredImagesByCover.slice(4)
            : [],
      };
    }, [images]);

    const imagesSection = useMemo(() => {
      return (
        <NW2ImageList
          coverImage={coverPhoto}
          images={separatedImages.primary}
          onImageUpload={onImageUpload}
          onUpdateVenueImages={onUpdateVenueImages}
          onImageRemove={onImageRemove}
          onSetCoverImage={onSetCoverImage}
        />
      );
    }, [
      coverPhoto,
      separatedImages.primary,
      onImageUpload,
      onUpdateVenueImages,
      onImageRemove,
      onSetCoverImage,
    ]);

    // TODO: Find better solution
    const isAgent =
      useSelector((state: RootState) => _get(state, 'app.user.role')) ===
      EUserRoleCognito.ROLE_AGENT;

    const rightColumn = useMemo(() => {
      return (
        <div>
          <RightColHeader>
            <RightColHeaderTitle>venue profile images</RightColHeaderTitle>
            <RightColHeaderSubTitle>
              These images will be most customer facing and appear on your venue
              profile page.
            </RightColHeaderSubTitle>
          </RightColHeader>

          <RightColImages>{imagesSection}</RightColImages>

          <RightColMain>
            <RightColHeaderTitle>additional venue images</RightColHeaderTitle>
            <RightColHeaderSubTitle>
              These images will appear under “more images” if organisers would
              like to see more what your venue is like.
            </RightColHeaderSubTitle>

            {images.length < 5 ? (
              <InfoCardWrapper>
                <NW2InfoCard title='Not enough images!' icon='TRIANGLE_WARN'>
                  In order to maximise your business make sure you have plenty
                  of high-quality images to help organisers get a good idea what
                  your venue is like.
                </NW2InfoCard>
              </InfoCardWrapper>
            ) : (
              <SecondaryImagesWrapper>
                {separatedImages.secondary.map((img) => (
                  <NW2ImageItem
                    key={img?.uid || img?.name}
                    src={img?.url}
                    name={img?.name}
                    handleSetCover={() => onSetCoverImage(img)}
                    handleRemove={() => onImageRemove(img)}
                  />
                ))}
              </SecondaryImagesWrapper>
            )}
          </RightColMain>
        </div>
      );
    }, [
      images.length,
      imagesSection,
      onImageRemove,
      onSetCoverImage,
      separatedImages.secondary,
    ]);

    return (
      <>
        {imagesSection}

        <StyledLink
          onClick={onToggleEdit}
          role='button'
          tabIndex={0}
          data-testid='show all venue images'
        >
          Show all venue images{images.length > 5 && ` (${images.length})`}
        </StyledLink>

        <NW2ModalTwoColumns
          title='Images'
          isShowed={isEditShowed}
          onToggleShow={onToggleEdit}
          colLeft={
            isAgent ? null : (
              <NW2UploadMultiple
                onImageUpload={onImageUpload}
                onUpdateVenueImages={onUpdateVenueImages}
              />
            )
          }
          colRight={rightColumn}
          isLeftColumnFixed
          isRightColumnFullHeight
        />
      </>
    );
  },
);

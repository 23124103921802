import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import Tabs from './components/Tabs';
import SearchForm from './components/SearchForm/SearchForm';
import SearchTemplatePopup from './components/ExtendedMeetingRoomsPopup/SearchTemplatePopup';

import {Routes} from 'constants/routes';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {setHeaderCollapsed, setHeaderFixed} from 'store/app/appSlice';
import {
  setLatitudeLongitude,
  setSearchCriteriaMeetingRoomCapacity,
  setSearchCriteriaRoomType,
  setSearchText,
  setIsFastSearchEnabled,
  setSearchCriteriaSeatingStyle,
  INITIAL_SEATING_STYLE,
  clearGroupsRooms,
} from 'store/search/searchSlice';
import {setSearchOffersLoaded} from 'store/offers/offersSlice';
import {setIsSearchTemplatePopupVisible} from 'store/venues/venuesSlice';
import {TNW2GoogleMapSearchInputData} from 'types/googleMap';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useQuery} from 'hooks/useQuery';
import withBridgeBTC from 'utils/bridgeBTC/withBridgeBTC';
import LocalStorageService from 'infra/common/localStorage.service';
import {checkBoolean} from 'utils/stringUtils';
import {getSeatingPlanByParticipants} from 'utils/venueUtils';
import {Wrapper, Content, Section} from './NW2SearchSection.styles';
import {useSearchVenuesActions} from './useSearchVenuesActions';
import {resetStoreDates} from 'store/search/apiActions';

interface Props {
  isMinimised?: boolean;
  isNoFixed?: boolean;
  isLoggedInUser?: boolean;
  isVenuesListPage?: boolean;
}
function NW2SearchSection({
  isLoggedInUser,
  isMinimised,
  isNoFixed,
  isVenuesListPage,
}: Props) {
  const dispatch = useAppDispatch();

  const {pathname} = useLocation();
  const {openSearch, isMultiSearchOpen} = useQuery();

  const [isInitialised, setInitialised] = useState(false);
  const [searchInputFocused, setSearchInputFocused] = useState(!!openSearch);

  const {onSearchVenues, onFastSearch} = useSearchVenuesActions({isMinimised});

  useEffect(() => {
    if (isInitialised) {
      return;
    }

    // init
    dispatch(setHeaderCollapsed(!!isMinimised));
    setInitialised(true);
  }, [isMinimised, isInitialised, dispatch]);

  const searchSectionElement: RefObject<HTMLElement | null> = useRef(null);

  const isSearchTemplatePopupVisible: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.isSearchTemplatePopupVisible'),
  );

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isFixed = useAppSelector(({app}) => app.headerFixed);
  const isCollapsed = useAppSelector(({app}) => app.headerCollapsed);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const timeDataFromStore = useAppSelector(
    ({search}) => search.initialTimeData,
  );
  const isOffersLoaded = useAppSelector(
    ({offers}) => offers.isOffersListLoaded,
  );

  const isFastSearchEnabled = checkBoolean(
    LocalStorageService.getByKey('isFastSearchEnabled'),
  );

  const isMultiDayChosen = timeDataFromStore.length > 1;

  const isHomePage = pathname === Routes.mainLanding;

  const {searchString, roomType, meetingRoomCapacity} = searchCriteria;

  useEffect(() => {
    const searchSection = searchSectionElement?.current;

    if (typeof window === 'undefined' || !searchSection) {
      return;
    }

    const onScrollHandler = () => {
      const currScroll = window.scrollY;
      const searchOffsetTop = searchSection?.offsetTop;
      const scrollUp = currScroll <= searchOffsetTop;
      const scrollDown = currScroll > searchOffsetTop;

      // fix header state after redirect on the venues list page
      if (isFixed && isNoFixed) {
        dispatch(setHeaderFixed(false));
      }

      // minimised mode actions
      if (!isMinimised) {
        if (scrollUp && isCollapsed) {
          dispatch(setHeaderCollapsed(false));
        }
        if (scrollDown && !isCollapsed) {
          dispatch(setHeaderCollapsed(true));
        }
      } else {
        if (scrollUp && !isCollapsed) {
          dispatch(setHeaderCollapsed(true));
        }
      }

      // fixed mode actions
      if (!isNoFixed) {
        if (scrollUp && isFixed) {
          dispatch(setHeaderFixed(false));
        }

        if (scrollDown && !isFixed) {
          dispatch(setHeaderFixed(true));
        }
      }
    };

    if (!isMobile) {
      window.addEventListener('scroll', onScrollHandler);
    }

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [isMobile, isMinimised, isCollapsed, isFixed, isNoFixed, dispatch]);

  const [isDatepickerInitialised, setDatepickerInitialised] = useState(false); // todo will be removed after search store dates refactoring

  const handleSetDatepickerInitialised = (payload: boolean) =>
    setDatepickerInitialised(payload);

  const onRoomTypeChange = useCallback(
    (roomType: ERoomType): void => {
      dispatch(setSearchCriteriaRoomType(roomType));
      dispatch(setSearchCriteriaMeetingRoomCapacity(1));
      dispatch(setSearchCriteriaSeatingStyle(INITIAL_SEATING_STYLE));

      // reset datepicker
      dispatch(resetStoreDates(roomType));

      // reset groups common rooms setup
      dispatch(clearGroupsRooms());
      setDatepickerInitialised(false);
    },
    [dispatch],
  );

  const onParticipantsChange = useCallback(
    (value = 1) => {
      dispatch(setSearchCriteriaMeetingRoomCapacity(+value));

      const seatingStyleByParticipants = getSeatingPlanByParticipants(+value);
      dispatch(setSearchCriteriaSeatingStyle(seatingStyleByParticipants));
    },
    [dispatch],
  );

  const onGoogleSearch = useCallback(
    (data: TNW2GoogleMapSearchInputData) => {
      const {latitude = '', longitude = '', address = ''} = data;

      dispatch(
        setLatitudeLongitude({
          latitude,
          longitude,
        }),
      );

      dispatch(setSearchText(address));
    },
    [dispatch],
  );

  const closeSearchTemplatePopup = () =>
    dispatch(setIsSearchTemplatePopupVisible(false));

  useEffect(() => {
    if (isMultiSearchOpen) {
      onSearchVenues();
    }
  }, [isMultiSearchOpen, onSearchVenues]);

  useEffect(() => {
    return () => {
      if (isLoggedInUser && isOffersLoaded) {
        dispatch(setSearchOffersLoaded(false));
      }
    };
  }, [dispatch, isLoggedInUser, isOffersLoaded]);

  useEffect(() => {
    if (isHomePage && isFastSearchEnabled) {
      dispatch(setIsFastSearchEnabled(false));
    }
  }, [dispatch, isHomePage, isFastSearchEnabled]);

  const onChangeInputAddress = useCallback(() => {
    if (isMinimised) {
      // expand after location is selected for datepicker showing reasons
      dispatch(setHeaderCollapsed(false));
    }
  }, [isMinimised, dispatch]);

  const handleSearchInputFocused = useCallback((isFocused: boolean) => {
    setSearchInputFocused(isFocused);
  }, []);

  return (
    <>
      <Wrapper isFixed={isMinimised || isFixed}>
        <Section
          isFixed={isFixed}
          ref={searchSectionElement as RefObject<HTMLDivElement>}
        >
          <Content minimized={isCollapsed}>
            <div>
              <Tabs
                minimized={isCollapsed}
                onRoomTypeChange={onRoomTypeChange}
                roomTypeValue={roomType}
              />
              <SearchForm
                isMultiDayChosen={isMultiDayChosen}
                minimized={isCollapsed}
                setSearchInputFocused={handleSearchInputFocused}
                searchInputFocused={searchInputFocused}
                onSearch={onGoogleSearch}
                onFastSearch={onFastSearch}
                onSearchVenues={onSearchVenues}
                googleMapSearchInputValue={searchString}
                onParticipantsChange={onParticipantsChange}
                participants={meetingRoomCapacity}
                onChangeInputLocation={onChangeInputAddress}
                isDatepickerInitialised={isDatepickerInitialised}
                handleSetDatepickerInitialised={handleSetDatepickerInitialised}
                isVenuesListPage={isVenuesListPage}
              />
            </div>
          </Content>
        </Section>
      </Wrapper>
      {isSearchTemplatePopupVisible && (
        <SearchTemplatePopup
          onClose={closeSearchTemplatePopup}
          isVenuesListPage={isVenuesListPage}
        />
      )}
    </>
  );
}

export default withBridgeBTC(NW2SearchSection);

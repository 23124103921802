import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as History from 'history';
import _get from 'lodash/get';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import HeaderInnerContainer from './HeaderInnerContainer';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useCorporateOffices} from 'hooks/useCorporateOffices';
import {
  getActiveDropdownItemFromRoute,
  getActiveItemFromRoute,
  getIsExactPath,
  setHeaderStyle,
} from '../helpers';
import {
  getAnnouncementByUserId,
  getSpaces,
  getSupplierVenueProfileDetails,
  getWorkInProgress,
  setSpaceSelectedTab,
} from 'store/venue/actions';
import {getCorporateOffices} from 'store/corporateOffice/apiActions';
import {clearOfficesPayload} from 'store/corporateOffice/corporateOfficeSlice';
import {getRequestsCount} from 'store/offers/apiActions';
import {setTopNotification} from 'store/app/appSlice';
import {signOutUser} from 'store/app/apiActions';
import {ETopNotificationId} from 'types/app';
import {ESpaceSelectedTab, ISpacesData} from 'types/venue';
import {RouteValues, SupplierHeaderItems} from '../types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {StyledHeader, NW2HeaderContainer} from '../Header.styles';
import {Routes} from 'constants/routes';

type TProps = {
  role: EUserRoleCognito;
  location: History.Location;
};

const HIDDEN_ROUTES = [Routes.shortListOverview, Routes.compareOverview];

const MainNavigation = ({role, location}: TProps) => {
  const dispatch = useAppDispatch();
  const pathname = location.pathname as RouteValues;

  const isHeaderHidden = !!getIsExactPath(HIDDEN_ROUTES, pathname);

  const {isLoggedInUser} = useLoggedInUser();
  const {corporateAccountId, corporateOffices} = useCorporateOffices();

  const [activeItem, setActiveItem] = useState<SupplierHeaderItems | ''>(
    getActiveItemFromRoute(pathname),
  );
  const [activeDropdownItem, setActiveDropdownItem] = useState<
    ESpaceSelectedTab | SupplierHeaderItems | ''
  >(getActiveDropdownItemFromRoute(location));

  const venueId = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`),
  );
  const supplierVenueId = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );
  const userRole = useAppSelector(({app}) => app.user.role);
  const spaces: ISpacesData[] = useSelector((state) =>
    _get(state, 'venue.spaces'),
  );

  // Need to get venue data here
  // as venue data is needed by header on all pages.
  // Then fetching venue images is called in VenueProfile.tsx
  // as images are only needed by VenueProfile page.
  useEffect(() => {
    setActiveItem(getActiveItemFromRoute(pathname));
    setActiveDropdownItem(getActiveDropdownItemFromRoute(location));
  }, [location, pathname]);

  // Handle customer's notifications
  useEffect(() => {
    if (userRole === EUserRoleCognito.ROLE_CUSTOMER) {
      dispatch(
        setTopNotification({
          id: ETopNotificationId.POPUP_NOTIFICATION,
        }),
      );
    }
  }, [userRole, dispatch]);

  useEffect(() => {
    if (userRole === EUserRoleCognito.ROLE_SUPPLIER) {
      if (supplierVenueId) {
        dispatch(getSupplierVenueProfileDetails(supplierVenueId));
        dispatch(getWorkInProgress(supplierVenueId));
        dispatch(
          getRequestsCount(supplierVenueId, (count) => {
            if (count) {
              // enable top notification
              dispatch(
                setTopNotification({
                  id: ETopNotificationId.PENDING_REQUEST,
                }),
              );
            }
          }),
        );
      }
    }
  }, [supplierVenueId, userRole, dispatch]);

  useEffect(() => {
    if (userRole === EUserRoleCognito.ROLE_PROPERTY_MANAGER) {
      if (corporateOffices.length) return;

      if (corporateAccountId) {
        dispatch(getCorporateOffices(Number(corporateAccountId), true));
        dispatch(getAnnouncementByUserId(Number(corporateAccountId)));
      } else {
        dispatch(clearOfficesPayload()); // clear venue data on sign out
      }
    }
  }, [corporateAccountId, corporateOffices.length, dispatch, userRole]);

  const {components, type} = setHeaderStyle(role, pathname, venueId);

  const getActionFromMenuItem = useCallback(
    (menuItem: string) => {
      switch (menuItem) {
        case SupplierHeaderItems.LogOut:
          dispatch(signOutUser());
          break;
        case ESpaceSelectedTab.DESK:
        case ESpaceSelectedTab.ROOM:
        case ESpaceSelectedTab.BEDROOM:
          dispatch(setSpaceSelectedTab(menuItem));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  const selectMenuItem = useCallback(
    (id: SupplierHeaderItems) => {
      const excludedButtons = [
        SupplierHeaderItems.LocationButton,
        SupplierHeaderItems.Profile,
        SupplierHeaderItems.Spaces,
      ].includes(id);

      getActionFromMenuItem(id);

      if (activeItem !== id && !excludedButtons) {
        setActiveDropdownItem('');
      }

      if (!excludedButtons) {
        setActiveItem(id);
      }
    },
    [activeItem, getActionFromMenuItem],
  );

  const selectDropdownItem = useCallback(
    (id: SupplierHeaderItems) => {
      getActionFromMenuItem(id);
      setActiveDropdownItem(id);
    },
    [getActionFromMenuItem],
  );

  // get spaces
  useEffect(() => {
    if (venueId && !spaces && isLoggedInUser) {
      dispatch(getSpaces(Number(venueId)));
    }
  }, [venueId, spaces, dispatch, pathname, isLoggedInUser]);

  if (isHeaderHidden) return null;

  return (
    <StyledHeader datatype={type}>
      <NW2HeaderContainer datatype={type}>
        {components.map((el) => (
          <HeaderInnerContainer
            key={el.component}
            activeItem={activeItem}
            activeDropdownItem={activeDropdownItem}
            selectMenuItem={selectMenuItem}
            selectActiveDropdownItem={selectDropdownItem}
            currentPath={pathname}
            role={role}
            spaces={spaces}
            venueId={venueId}
            {...el}
          />
        ))}
      </NW2HeaderContainer>
    </StyledHeader>
  );
};

export default MainNavigation;

import React from 'react';

import NW2Switch from 'view/components/NW2Switch';

import {FlexContainer} from './AddFoodBeverageRequest/AddFoodBeverageRequest.styles';

type TProps = {
  label: string;
  isSwitchActive: boolean;
  onClickSectionHandler: () => void;
  isSwitchDisabled?: boolean;
};

const MeetingRequestSectionTitle = ({
  label,
  isSwitchActive,
  isSwitchDisabled = false,
  onClickSectionHandler,
}: TProps) => {
  return (
    <FlexContainer>
      <NW2Switch
        isActive={isSwitchActive}
        onClick={onClickSectionHandler}
        disabled={isSwitchDisabled}
      />
      <div>{label}</div>
    </FlexContainer>
  );
};

export default MeetingRequestSectionTitle;

import styled, {css} from 'styled-components';

import NW2Dropdown from 'view/components/NW2Dropdown';

import {
  blackColor,
  borderRadiusDef,
  fontSizeMd,
  fontSizeSm,
  fontSizeXSm,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightMd,
  lineHeightSm,
  NW2Blue100Color,
  NW2Gray200Color,
  NW2Gray900Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

export const IMAGE_ITEM_MIN_HEIGHT = '118px';

export const RightColHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightColHeaderTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${blackColor};
  margin-bottom: ${offsetXSm};
`;

export const RightColHeaderSubTitle = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
`;

export const RightColImages = styled.div`
  padding: ${offsetXLg} 0;
  margin-bottom: ${offsetXLg};
  border-bottom: 1px solid ${NW2Gray200Color};
`;

export const RightColMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownWrapper = styled.div<{isShowed: boolean}>`
  ${({isShowed}) => css`
    opacity: ${isShowed ? 1 : 0};
    visibility: ${isShowed ? 'visible' : 'hidden'};
    pointer-events: ${isShowed ? 'auto' : 'none'};
  `}
`;

export const StyledDropdown = styled(NW2Dropdown)`
  position: absolute;
  top: ${offsetDef};
  right: ${offsetDef};
  z-index: 2;
`;

export const StyledDropdownItem = styled.span`
  display: block;
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  padding: ${offsetDef} ${offsetSm};

  &:hover {
    background-color: ${NW2Blue100Color};
  }
`;

export const ImageWrapper = styled.div<{isDropdownShowed: boolean}>`
  position: relative;
  min-height: ${IMAGE_ITEM_MIN_HEIGHT};

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    pointer-events: none;
    border-radius: ${borderRadiusDef};
    z-index: 1;

    ${({isDropdownShowed}) => css`
      opacity: ${isDropdownShowed ? 1 : 0};
      visibility: ${isDropdownShowed ? 'visible' : 'hidden'};
    `}
  }
`;

export const CoverLabel = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${whiteColor};
  background-color: ${NW2Gray900Color};
  padding: ${offsetXXSm} ${offsetXSm};
  border-top-left-radius: ${borderRadiusDef};
  pointer-events: none;
`;

export const InfoCardWrapper = styled.div`
  max-width: 442px;
  margin-top: ${offsetXLg};
`;

export const SecondaryImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: ${offsetXLg};
  grid-row-gap: ${offsetXLg};
  margin-top: ${offsetXLg};
`;

export const StyledLink = styled.span`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

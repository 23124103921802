import React from 'react';
import {Menu} from 'antd';
import styled from 'styled-components';

import {
  borderColor,
  errorColor,
  NW2GrayTextColor,
  offsetDef,
} from 'constants/styleVars';

const menuItemStyle = {margin: 0, height: 48, paddingLeft: offsetDef};

const StyledMenuItem = styled((props) => (
  <Menu.Item style={menuItemStyle} {...props} />
))`
  display: flex;
  align-items: center;
  border-top: 1px solid ${borderColor};
  color: ${NW2GrayTextColor};

  > span {
    margin-left: 0 !important;
  }

  > div {
    line-height: 0 !important;
    font-size: 14px;
  }

  svg {
    flex-shrink: 0;
  }

  ${({ispendingrequests}) =>
    ispendingrequests === 'true' &&
    `
    > div:first-of-type {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${errorColor}
      }
    }
  `}
`;

export default StyledMenuItem;

import styled from 'styled-components';
import {Radio} from 'antd';

import Icon from 'view/components/Icon/Icon';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {SubBlockTitle} from 'view/components/Typography';

import {
  mdBp,
  xLgBp,
  offsetXSm,
  offsetSm,
  offsetDef,
  offsetXLg,
  offsetXXXLg,
  fontSizeXXSm,
  fontSizeMd,
  fontWeightExtraBold,
  NW2Gray200Color,
  NW2Gray600Color,
} from 'constants/styleVars';

export const Title = styled(SubBlockTitle)`
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  margin-bottom: ${offsetDef};
`;

export const Text = styled.p`
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetXXXLg};
`;

export const Wrapper = styled.div`
  width: 100%;
  text-align: end;

  @media (min-width: ${xLgBp}px) {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    grid-gap: 20px;
    text-align: start;
  }
`;

export const Container = styled.div`
  padding: ${offsetXLg};
  border: 1px solid ${NW2Gray200Color};
  margin-bottom: ${offsetXXXLg};
`;

export const StyledParagraph = styled.p`
  display: inline-block;
  color: ${NW2Gray600Color};
  text-transform: uppercase;
  text-align: start;
  font-size: ${fontSizeXXSm};
  font-weight: bold;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  border-top: 1px solid ${NW2Gray200Color};
  padding-top: ${offsetXLg};
`;

export const Section = styled.div`
  margin-bottom: ${offsetXLg};

  @media (min-width: ${mdBp}px) {
    display: flex;
  }

  @media (min-width: ${xLgBp}px) {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    grid-gap: 20px;
  }
`;

export const StyledRadio = styled(Radio)`
  width: 100%;

  span.ant-radio + * {
    width: 100%;
    font-size: ${fontSizeMd};
  }
`;

export const AddressRow = styled.p`
  margin: 0 0 0 ${offsetXSm};
`;

export const ExtraRow = styled.div`
  margin-left: ${offsetXSm};
`;

export const ExtraText = styled.span`
  color: ${NW2Gray600Color};
  font-size: ${fontSizeMd};
  font-weight: bold;
`;

export const ExtraParagraph = styled.p`
  font-size: ${fontSizeMd};
  margin-bottom: 0;
`;

export const ActionSection = styled.div`
  text-align: end;
  margin-top: ${offsetDef};

  @media (min-width: ${mdBp}px) and (max-width: ${xLgBp}px) {
    display: flex;
    margin-top: ${offsetSm};
  }

  @media (min-width: ${xLgBp}px) {
    text-align: start;
    margin-top: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXLg};
`;

export const BodyBox = styled.div`
  font-size: ${fontSizeMd};

  p:nth-child(2) {
    margin-bottom: ${offsetXSm};
  }

  p:nth-child(3) {
    margin-bottom: 0;
  }

  p:nth-child(4) {
    margin: ${offsetXSm} 0 0;
  }
`;

export const FooterBox = styled.div`
  text-align: end;
  margin-bottom: ${offsetSm};
`;

export const StyledSpan = styled.span`
  font-weight: bold;
`;

export const StyledNW2Button = styled(NW2Button)`
  margin-right: ${offsetSm};
`;

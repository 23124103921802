import React from 'react';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import DateUtils from 'utils/dateUtils';
import Nw2BookingUnits from './NW2BookingUnits';
import PreviewExtrasList from 'view/venue/components/PreviewExtrasList';
import AccommodationTitle from 'view/venue/components/AccommodationTitle';
import LeftSideItemTitle from 'view/venue/components/LeftSideContainer/LeftSideItemTitle';

import {ERoomType} from 'types/dto/ERoomType.type';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {
  Container,
  StyledLeftSideItem,
  Title,
  TitleWrapper,
} from './NW2BookingConfirmationUnitsBlock.styles';
import {offsetXXLg} from 'constants/styleVars';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

interface IProps {
  hasPreArrivals?: boolean;
  hasPostEvents?: boolean;
}

function NW2BookingConfirmationUnitsBlock({
  hasPreArrivals,
  hasPostEvents,
}: IProps) {
  const extrasOption: IExtrasOption[] = useAppSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const customerBookedUnits = useAppSelector(
    ({customer}) => customer.customerBookedUnits,
  );

  const dayUnits = customerBookedUnits
    ? getFilteredUnitsByEventType(customerBookedUnits)
    : null;

  if (!dayUnits) return null;

  const isMultiDay = dayUnits.length > 1;

  return (
    <>
      {dayUnits.map(
        (
          {checkInDate, checkOutDate, unitBookings, foodAndBeverage, bedrooms},
          index,
        ) => {
          const timeStart = DateUtils.findMinMaxTime(
            unitBookings,
            'checkInDate',
            false,
          );
          const timeEnd = DateUtils.findMinMaxTime(
            unitBookings,
            'checkOutDate',
            true,
          );

          const singleBookingTitle =
            unitBookings[0]?.unitType === ERoomType.MEETING_ROOM ||
            !!bedrooms?.length
              ? 'Your room'
              : 'Your desk';

          const multiBookingTitle = (
            <LeftSideItemTitle
              title={`Day ${index + 1}`}
              checkIn={unitBookings.length ? timeStart : checkInDate}
              checkOut={unitBookings.length ? timeEnd : checkOutDate}
              hasTimeRange={!!unitBookings.length}
            />
          );

          return (
            <StyledLeftSideItem
              key={checkInDate}
              paddingTop={
                hasPreArrivals && index === 0
                  ? `${offsetXXLg} !important`
                  : undefined
              }
              paddingBottom={
                !hasPostEvents && index === dayUnits.length - 1
                  ? undefined
                  : `${offsetXXLg} !important`
              }
              isNoBorderTop={hasPreArrivals}
              item={{
                title: isMultiDay ? multiBookingTitle : singleBookingTitle,
                children: (
                  <Container>
                    <Nw2BookingUnits
                      units={unitBookings}
                      hasCateringAndBedrooms={
                        !!(foodAndBeverage?.length && bedrooms?.length)
                      }
                    />

                    {!!foodAndBeverage?.length && (
                      <>
                        <TitleWrapper noTopMargin={!unitBookings?.length}>
                          <Title>Food & Beverage</Title>
                        </TitleWrapper>
                        <PreviewExtrasList
                          extras={foodAndBeverage}
                          extrasOptions={extrasOption}
                        />
                      </>
                    )}

                    {!!bedrooms?.length && (
                      <>
                        <AccommodationTitle checkIn={checkInDate} />
                        <PreviewExtrasList
                          extras={bedrooms}
                          extrasOptions={extrasOption}
                        />
                      </>
                    )}
                  </Container>
                ),
              }}
            />
          );
        },
      )}
    </>
  );
}

export default NW2BookingConfirmationUnitsBlock;

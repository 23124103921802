import React from 'react';

import OperationalHoursInfo from 'view/venue/components/OperationalHoursInfo/OpenHoursInfo';
import {useOperationalTimes} from '../../../../hooks/useOperationalTimes';

export function NW2VenueDetailsOperationalHours() {
  const {operationalTimes} = useOperationalTimes();

  if (!operationalTimes?.length) return null;

  return <OperationalHoursInfo workDays={operationalTimes} />;
}

export default NW2VenueDetailsOperationalHours;

import styled, {css} from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  offsetLg,
  NW2Gray900Color,
  offsetDef,
  offsetXSm,
  borderRadiusMLg,
  blackColor,
  whiteColor,
} from 'constants/styleVars';

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXSm};
`;

export const ListContentItem = styled.div`
  position: relative;
  padding-left: ${offsetLg};

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: 6px;
    width: 4px;
    height: 4px;
    background: ${NW2Gray900Color};
    border-radius: 50%;
  }
`;

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetDef};
  margin: ${offsetLg} 0 ${offsetXSm};
`;

export const SwitchButton = styled(NW2Button)`
  padding: ${offsetDef} ${offsetXSm};
  border-radius: ${borderRadiusMLg};
  text-transform: none;

  &:active {
    background-color: ${blackColor};
    border-color: ${blackColor};
    color: ${whiteColor};
  }

  ${({active}) =>
    active &&
    css`
      background-color: ${blackColor};
      border-color: ${blackColor};
      color: ${whiteColor};
    `}
`;

import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';

export const useCustomerOrderUnits = () => {
  const previewRooms = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.previewRooms,
  );
  const previewDesks = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.previewDesks,
  );
  const isTotalDrawerVisible = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.isTotalDrawerVisible,
  );

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const previewUnits = [ERoomType.MEETING_ROOM, ERoomType.GROUPS].includes(
    roomType,
  )
    ? previewRooms
    : previewDesks;

  return {
    previewRooms,
    previewDesks,
    previewUnits,
    isTotalDrawerVisible,
  };
};

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-final-form';
import styled from 'styled-components';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {IAccountReference, IPaymentReferenceFields} from 'store/payment/types';
import {setCorporateBillingAddress} from 'store/payment/paymentSlice';

import PaymentReferenceFieldsBuilder from './PaymentReferenceFieldsBuilder';
import {NW2FormItemSelectSearch} from 'view/components/NW2FormItem/NW2FormItem';
import {ISearchDropdownListOption} from 'view/components/NW2FormItem/types';

import {EBookingFormFields} from 'types/venue';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {requiredSelectFieldRules} from 'utils/finalFormFieldRules';
import {getAddressStringFromBillingAddress} from 'utils/stringUtils';

const PaymentReferencesBox = styled.div`
  padding-left: 34px;

  > div {
    margin-bottom: 0;
  }
`;

const CorporatePaymentBlock = () => {
  const dispatch = useAppDispatch();

  const {mutators} = useForm();

  const accountReferences = useAppSelector(
    ({payment}) => payment.accountReferences,
  );
  const isAccountReferencesLoading = useAppSelector(
    ({payment}) => payment.isAccountReferencesLoading,
  );
  const corporateBillingAddress = useAppSelector(
    ({payment}) => payment.corporateBillingAddress,
  );

  const selectedCorporateBillingAddress = corporateBillingAddress
    ? ({
        text: corporateBillingAddress.companyName,
        subText: getAddressStringFromBillingAddress({
          ...corporateBillingAddress,
          companyName: '',
        }),
        value: corporateBillingAddress.companyName,
      } as ISearchDropdownListOption)
    : undefined;

  const [selectedAccount, setSelectedAccount] = useState<IAccountReference>();

  const mappedAccountReferences = useMemo(
    () =>
      accountReferences?.map(({address, companyName}) => ({
        text: companyName,
        subText: address,
        value: companyName,
      })) || [],
    [accountReferences],
  );

  const onPaymentReferenceChange = useCallback(
    (accountReference: ISearchDropdownListOption | string) => {
      if (typeof accountReference === 'object') {
        const selectedAccountReference = accountReferences.find(
          ({companyName}) => companyName === accountReference.text,
        );

        if (selectedAccountReference) {
          setSelectedAccount(selectedAccountReference);

          const [streetHouseNumber, cityWithCode, country] =
            accountReference.subText.split(', ');

          const [postCode, city] = cityWithCode.split(' ');

          const corporateBillingAddress: ICustomerBillingAddress = {
            city,
            companyName: accountReference.value,
            country,
            streetHouseNumber,
            costCenter: '',
            postCode,
            additionalReference: '',
          };

          dispatch(setCorporateBillingAddress(corporateBillingAddress));

          mutators.setValue(EBookingFormFields.PAYMENT_INFO, {
            companyName: accountReference.value,
            paymentProvider: selectedAccountReference.paymentProvider,
            referenceFields: [],
          });

          setTimeout(() => {
            mutators.setValue(
              `${EBookingFormFields.PAYMENT_INFO}.referenceFields`,
              selectedAccountReference.referenceFields.map(
                ({key}: IPaymentReferenceFields) => ({
                  key,
                  value: '',
                }),
              ),
            );
          }, 0);
        }
      }
    },
    [accountReferences, dispatch, mutators],
  );

  useEffect(() => {
    return () => {
      dispatch(setCorporateBillingAddress(null));
    };
  }, [dispatch]);

  return (
    <>
      <PaymentReferencesBox>
        <NW2FormItemSelectSearch
          name={EBookingFormFields.PAYMENT_INFO}
          label='Select a corporate payment account'
          placeholder='Select a corporate payment account'
          rules={requiredSelectFieldRules('a corporate payment account')}
          options={mappedAccountReferences}
          isLoading={isAccountReferencesLoading}
          onChange={onPaymentReferenceChange}
          selectedValue={selectedCorporateBillingAddress}
        />
      </PaymentReferencesBox>
      <PaymentReferenceFieldsBuilder selectedAccount={selectedAccount} />
    </>
  );
};

export default CorporatePaymentBlock;

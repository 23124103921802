import {ChangeEvent, useCallback} from 'react';

import {dimensionsConverter} from 'utils/venueUtils';
import {inputTextToNumber} from 'utils/helpers';

type TChangeHandler = (e: ChangeEvent<HTMLInputElement>) => void;

export type TDimensionsType = {
  handleSquareMetersChange: TChangeHandler;
  handleSquareFeetChange: TChangeHandler;
  handleHeightMetersChange: TChangeHandler;
  handleHeightFeetChange: TChangeHandler;
};

interface IProps {
  onFormChange: (name: any, value?: any) => void;
  fieldAreaName: string;
  fieldAreaFeetName: string;
  fieldHeightName: string;
  fieldHeightFeetName: string;
}
export function useDimensions({
  onFormChange,
  fieldAreaName,
  fieldAreaFeetName,
  fieldHeightName,
  fieldHeightFeetName,
}: IProps): TDimensionsType {
  const handleSquareMetersChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const setValues = (v: string) => {
        const valueInFeet = value
          ? String(dimensionsConverter('m', value, true, 5))
          : '';

        onFormChange(fieldAreaName, v);
        onFormChange(fieldAreaFeetName, valueInFeet);
      };

      inputTextToNumber(value, setValues);
    },
    [fieldAreaFeetName, fieldAreaName, onFormChange],
  );

  const handleSquareFeetChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const setValues = (v: string) => {
        const valueInMeters = value
          ? String(dimensionsConverter('ft', value, true, 5))
          : '';

        onFormChange(fieldAreaFeetName, v);
        onFormChange(fieldAreaName, valueInMeters);
      };

      inputTextToNumber(value, setValues);
    },
    [fieldAreaFeetName, fieldAreaName, onFormChange],
  );

  const handleHeightMetersChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const setValues = (v: string) => {
        const valueInFeet = value
          ? String(dimensionsConverter('m', value, false, 5))
          : '';

        onFormChange(fieldHeightName, v);
        onFormChange(fieldHeightFeetName, valueInFeet);
      };

      inputTextToNumber(value, setValues);
    },
    [fieldHeightFeetName, fieldHeightName, onFormChange],
  );

  const handleHeightFeetChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const setValues = (v: string) => {
        const valueInMeters = value
          ? String(dimensionsConverter('ft', value, false, 5))
          : '';

        onFormChange(fieldHeightFeetName, v);
        onFormChange(fieldHeightName, valueInMeters);
      };

      inputTextToNumber(value, setValues);
    },
    [fieldHeightFeetName, fieldHeightName, onFormChange],
  );

  return {
    handleSquareMetersChange,
    handleSquareFeetChange,
    handleHeightMetersChange,
    handleHeightFeetChange,
    // handleResetDimensions,
  };
}

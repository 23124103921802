import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import NW2Button from 'view/components/NW2Button';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';

import {ELocale} from 'types/locale';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {ENW2PopoverDrawerPlacement} from 'view/components/NW2PopoverDrawer/types';
import LocalStorageService from 'infra/common/localStorage.service';
import {setLocale} from 'store/app/appSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {borderRadiusDef, offsetDef, offsetSm} from 'constants/styleVars';
import {ILocaleDropdownProps} from './types';
import {
  PopoverActions,
  PopoverGlobalStyle,
  PopoverText,
} from './LocaleDropdown.styles';

export const withLocalePopover =
  (Component: React.ComponentType<ILocaleDropdownProps>) => () => {
    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
    const isAuthConfigured = useAppSelector(({app}) => app.isAuthConfigured);
    const user = useAppSelector(({app}) => app.user);

    const {
      i18n: {changeLanguage, language},
    } = useTranslation();

    const isFormatInfoShowed =
      LocalStorageService.getByKey('isFormatInfoShowed') || '';

    const [isPopoverOpened, setPopoverOpened] = useState(
      !Number(isFormatInfoShowed),
    );

    const handleSetFormatInfoShowed = () => {
      LocalStorageService.setByKey('isFormatInfoShowed', '1');
      setPopoverOpened(false);
    };

    if (
      !isAuthConfigured ||
      (user.role === EUserRoleCognito.ROLE_CUSTOMER && !user.id)
    )
      return null;

    return (
      <>
        <NW2PopoverDrawer
          body={
            <>
              <PopoverText>
                You are currently viewing content in the{' '}
                <b>24-hour format and kilometers</b>. To switch to the{' '}
                <b>12-hour format and miles</b>, please adjust your settings.
              </PopoverText>

              <PopoverActions>
                <NW2Button size='small' onClick={handleSetFormatInfoShowed}>
                  dismiss
                </NW2Button>
                <NW2Button
                  size='small'
                  buttonType='primary'
                  onClick={() => {
                    handleSetFormatInfoShowed();
                    changeLanguage(ELocale.EN_US).then(() => {
                      dispatch(setLocale(ELocale.EN_US));
                    });
                  }}
                >
                  SWITCH to 12-hour Format and miles
                </NW2Button>
              </PopoverActions>
            </>
          }
          isMobile={false}
          popoverWidth={isMobile ? '100%' : '452px'}
          popoverBodyStyle={{padding: `${offsetDef} ${offsetSm}`}}
          overlayInnerStyle={{
            borderRadius: borderRadiusDef,
          }}
          overlayClassName='locale-popover'
          placement={ENW2PopoverDrawerPlacement.BOTTOM_RIGHT}
          open={isPopoverOpened}
        >
          <Component changeLanguage={changeLanguage} language={language} />
        </NW2PopoverDrawer>
        <PopoverGlobalStyle />
      </>
    );
  };

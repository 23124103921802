import {useEffect} from 'react';
import {useLoadScript} from '@react-google-maps/api';
import {useDispatch} from 'react-redux';

import {setIsGoogleMapLoaded} from 'store/app/appSlice';
import {Libraries} from '@react-google-maps/api/dist/utils/make-load-script-url';

const GOOGLE_MAP_LIBRARIES: Libraries = ['geometry', 'places'];

const GoogleMapLoader = () => {
  const dispatch = useDispatch();

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
    libraries: GOOGLE_MAP_LIBRARIES,
  });

  useEffect(() => {
    if (isLoaded) {
      dispatch(setIsGoogleMapLoaded(isLoaded));
    }
  }, [isLoaded, dispatch]);

  return null;
};

export default GoogleMapLoader;

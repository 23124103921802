import styled from 'styled-components';

import {
  NW2Gray100Color,
  whiteColor,
  mdBp,
  offsetDef,
  offsetXSm,
  lgBp,
  pagePadding,
  xLgBp,
  offsetXXXLg,
  NW2Gray600Color,
  offsetXLg,
  offsetXXLg,
  offsetXXSm,
  fontWeightExtraBold,
} from 'constants/styleVars';

export const StyledList = styled.div`

  @media (min-width: ${mdBp}px) {
    grid-column: 2/3;

    & + * {
      max-width: 282px;
    }
`;

export const StyledRoomSetupFitting = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${NW2Gray100Color};
  margin-bottom: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    grid-column: 1/2;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledParagraph = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
  display: inline-block;
  margin-right: ${offsetXSm};

  @media (min-width: ${mdBp}px) {
    display: block;
    margin-bottom: ${offsetXSm};
  }
`;

export const SpacesListBlockWrapper = styled.div`
  color: ${whiteColor};

  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: auto 218px;
    grid-gap: ${pagePadding};
    margin-bottom: ${offsetXXLg};
  }
  @media (min-width: ${lgBp}px) {
    grid-template-columns: 1fr minmax(220px, 275px);
  }
`;

export const SpacesListBlockInner = styled.div`
  & + & {
    margin-top: ${offsetXXXLg};
  }
  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: 105px auto;
    grid-gap: ${pagePadding};
  }
  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 175px 1fr;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${offsetXLg};
  @media (min-width: ${mdBp}px) {
    grid-column: 2/3;
    margin-top: 0;
  }
`;

// export const StyledNoFoundBlock = styled.div`
//   width: 100%;
//   padding-left: ${offset26};
//
//   & > div {
//     background-color: ${NW2Gray800Color};
//     border-radius: ${offsetXXSm};
//     padding-left: ${offsetSm};
//
//     & + * {
//       font-size: ${fontSizeXSm};
//       line-height: ${offsetLg};
//     }
//   }
//
//   ul {
//     margin-top: ${offsetXXSm};
//     padding-left: 0;
//   }
// `;

export const StyledDiv = styled.div`
  border-top: 1px solid ${NW2Gray600Color};
  margin-top: ${offsetXLg};
`;

export const HeadingParticipants = styled.div`
  white-space: nowrap;

  @media (min-width: ${mdBp}px) {
    padding-top: ${offsetXXSm};
  }
`;

import React, {memo, useCallback} from 'react';
import {useAppSelector} from 'store/hooks';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {Link} from 'view/components/Typography';
import {useAdditionalActionsModal} from '../../../hooks/useAdditionalActionsModal';

import {EAdditionalActionsModal} from './types';
import {StyledCustomList} from './AdditionalActions.styles';

type TProps = {
  spaceId: number;
  isWip?: boolean;
};

const AdditionalActions = ({spaceId, isWip}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const {
    isModalShowed,
    onModalShow,
    onModalClose,
    modalTitle,
    modalBody,
    modalFooter,
  } = useAdditionalActionsModal({spaceId, isWip});

  const handleModalShow = useCallback(
    (arg: EAdditionalActionsModal) => () => {
      onModalShow(arg);
    },
    [onModalShow],
  );

  const list = [
    // {
    //   // TODO: add logic Duplicate space
    //   value: <Link>Duplicate space</Link>,
    //   icon: 'NMM_PICTURE_DOUBLE',
    // },
    // {
    //   // TODO: add logic Temporarily disable space
    //   value: <Link>Temporarily disable space</Link>,
    //   icon: 'EYE_CLOSED',
    // },
    {
      value: (
        <Link onClick={handleModalShow(EAdditionalActionsModal.DELETE_SPACE)}>
          Delete space
        </Link>
      ),
      icon: 'NW2_CANCEL_BIN',
    },
  ];
  return (
    <>
      <StyledCustomList list={list} listStyle='icon' iconStyle={{size: 20}} />
      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        modalWidth={400}
        drawerHeight='auto'
        body={modalBody}
        footer={modalFooter}
        onClose={onModalClose}
        headerPaddingBottom='0'
      />
    </>
  );
};

export default memo(AdditionalActions);

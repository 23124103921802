import React from 'react';

import BillingAddressView from './BillingAddressView';

import {
  StyledNW2FormItemInput,
  BillingAddressContainer,
  BillingAddressInnerBlock,
} from './BillingAddress.styles';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {EPaymentType} from 'types/bookingOrders';

interface IProps {
  isAgentRole?: boolean;
  paymentType?: EPaymentType;
  billingAddress?: null | ICustomerBillingAddress;
}

function BillingAddressViewWithReferenceField({
  isAgentRole,
  paymentType,
  billingAddress,
}: IProps) {
  const isDirectPayment = paymentType === EPaymentType.DIRECT_PAYMENT;

  if (!billingAddress) return null;

  const isDirectPaymentForNonAgent = !isAgentRole && isDirectPayment;

  return (
    <BillingAddressContainer>
      <BillingAddressInnerBlock>
        <BillingAddressView
          billingAddress={billingAddress}
          hasEditButton={isDirectPaymentForNonAgent}
        />
        {isDirectPaymentForNonAgent && (
          <StyledNW2FormItemInput
            name='additionalReference'
            type='text'
            label='Additional reference'
            placeholder='Additional reference'
          />
        )}
      </BillingAddressInnerBlock>
    </BillingAddressContainer>
  );
}

export default BillingAddressViewWithReferenceField;

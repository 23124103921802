import React from 'react';
import {useAppSelector} from 'store/hooks';

import {NW2MultiDatePicker} from 'view/components/NW2Datepicker';

import {StyledColumnCalendar} from './NW2SearchDatePicker.styles';
import {ReactDatePickerProps} from 'react-datepicker';

interface IProps extends ReactDatePickerProps {
  isSingleDayDatePicker?: boolean;
}

const NW2DateSection = (props: IProps) => {
  const isMobile: boolean = useAppSelector(({app}) => app.deviceType.isMobile);

  return (
    <StyledColumnCalendar isMobile={isMobile}>
      <NW2MultiDatePicker {...props} />
    </StyledColumnCalendar>
  );
};

export default NW2DateSection;

import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  fontSizeLg,
  fontSizeMd,
  fontSizeXLg,
  fontSizeXSm,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightSm,
  lineHeightXL,
  lineHeightXLg,
  NW2ErrorLight50Color,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Gray800Color,
  NW2Gray900Color,
  NW2SuccessLight50Color,
  offset40,
  offset80,
  offsetDef,
  offsetNone,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
  offsetXXXLg,
} from 'constants/styleVars';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.div`
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const HeaderSubTitle = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  display: flex;
  color: ${NW2Gray800Color};
`;
export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px -9.14px 19.78px rgba(0, 0, 0, 0.035),
    0px -4.85px 10.51px rgba(0, 0, 0, 0.0283),
    0px -2.02px 4.37px rgba(0, 0, 0, 0.0197);
  padding: ${offsetXLg};
  margin-top: auto;
`;

export const PriceBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${offsetSm};
`;

export const ButtonBlock = styled.div`
  display: flex;
`;
export const ConfirmButton = styled(NW2Button)`
  margin-left: ${offsetSm};
`;
//
export const StyledTitle = styled.div`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightExtraBold};
  line-height: ${lineHeightLg};
  letter-spacing: 0;
  text-align: left;
  margin-bottom: ${offsetXLg};
`;
export const StyledPrevChange = styled.div`
  background: ${NW2ErrorLight50Color};
  border-radius: ${offsetXXSm};
  text-decoration-line: line-through;
  padding: ${offsetNone} ${offsetXSm};
`;
export const StyledNewChange = styled.div`
  background: ${NW2SuccessLight50Color};
  border-radius: ${offsetXXSm};
  padding: ${offsetNone} ${offsetXSm};
`;
export const StyledLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 22px 1fr;
  align-items: center;
  column-gap: ${offsetXSm};
  margin-bottom: ${offsetSm};
  letter-spacing: ${offsetNone};
  text-align: left;
  color: ${NW2Gray900Color};
`;
export const StyledSection = styled.div`
  margin-bottom: ${offsetXLg};
`;
export const StyledDate = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  display: flex;
  color: ${NW2Gray800Color};
`;
export const StyledDateLine = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const FoodAndBeverageTitle = styled.div`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightLg};
  letter-spacing: ${offsetNone};
  margin-bottom: ${offsetDef};
  margin-top: ${offsetXLg};
`;
export const StyledExtrasTotalPrice = styled(StyledLine)`
  text-align: end;
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;
export const StyledTotalPrice = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
`;
export const StyledTotalPriceSubtitle = styled.div`
  color: ${NW2Gray800Color};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
`;

export const StyledPriceTitle = styled(StyledTitle)`
  margin-bottom: ${offsetDef};
`;
export const StyledBlock = styled.div`
  margin-bottom: ${offset40};
`;
export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${NW2Gray200Color};
  margin-top: ${offset40};
`;
export const StyledMeetingRoomName = styled.div`
  color: ${NW2Gray900Color};
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightLg};
  text-decoration-line: underline;
  margin-bottom: ${offsetDef};
`;
export const StyledBodyWrapper = styled.div`
  padding: ${offsetXLg};
`;
//
export const StyledBodyContainer = styled.div`
  padding: ${offsetXXXLg} ${offset80} 0 ${offsetXLg};
`;
export const FormItem = styled.div`
  margin-top: ${offsetXXXLg};
`;
export const FormItemTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
  margin-bottom: ${offsetDef};
`;
export const InputWrapper = styled.div`
  display: flex;

  & .input-wrapper {
    width: 80%;
    margin: 0 0 ${offsetDef};
  }
`;
export const RequiredLabel = styled.div`
  margin-left: ${offsetXSm};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
`;
export const TextareaWrapper = styled.div`
  display: grid;
  grid-template-columns: 80% 1fr;
`;
//
export const DeclineFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: ${offsetXLg};
  margin-top: auto;
`;
export const DeclineBody = styled.div`
  padding: ${offsetXLg} ${offsetXLg} 0 ${offsetXLg};
  display: flex;
  align-items: center;
`;
export const DeclineBodyText = styled.div`
  padding-left: ${offsetXLg};
`;
export const DeclineHeaderTitle = styled(HeaderTitle)`
  letter-spacing: 0.04em;
`;

import React from 'react';
import styled from 'styled-components';

import {offsetXXXLg} from 'constants/styleVars';
import {NW2Card} from 'view/components/NW2Card';
import Card1Img from 'img/card_1.webp';
import Card2Img from 'img/card_2.webp';

const Wrapper = styled.div`
  margin-top: ${offsetXXXLg};
`;

function CardsBlock() {
  return (
    <Wrapper>
      <NW2Card
        isPrimaryDecorationElement
        image={Card1Img}
        title='Find venues for simple meetings and events'
        bodyText={`Explore a curated selection of global venues, each vetted for quality and sustainability,
            to discover the perfect space for your next meeting or event. With instant pricing,
            our platform helps you eliminate the guesswork and seamlessly compare multiple venues based on your event
            requirements. Send multiple RFPs and manage all of your bookings in one place for
            complete transparency and control.`}
      />
      <NW2Card
        isGridReverse
        image={Card2Img}
        title='Find flexible office and coworking spaces'
        bodyText={`Instant book your ideal desk space amongst our global network of office and coworking locations
            that offer quality, convenience, and premium amenities. Enjoy unparalleled flexibility with various booking
            options to suit your schedule, from hourly to long-term. With instant pricing, our platform helps you easily
            compare spaces, offering a more streamlined booking process.`}
      />
    </Wrapper>
  );
}

export default CardsBlock;

import {getCustomerLayout} from '../layoutConfigs';
import {Routes} from 'constants/routes';
import {TMenuReturnType} from '../types';

export const customerMenu: TMenuReturnType = {
  layout: {
    customRoutesLayout: {},
    defaultLayoutType: getCustomerLayout(),
  },
  navList: [
    {
      url: Routes.customerBookings,
      icon: 'NW2_BOOKMARK',
      text: 'Bookings',
    },
  ],
};

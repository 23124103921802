import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  IBookingOrderResponse,
  IOrderDay,
  TAttendee,
} from 'types/dto/IBooking.types';
import {TInitialState} from 'store/customer/types';
import {IBookingChanges, ICustomerOverviewOrders} from 'types/bookingOrders';
import {ECustomerBookingsTabFilter} from 'types/dto/SearchTab.types';
import {IPackageResponse, TSelectedPackageItem} from 'types/dto/IPackages.type';

const initialState: TInitialState = {
  customerBookingOrder: null,
  customerPreviousBookingOrder: null,
  customerBookedUnits: null,
  customerBookedUnitsToReCalculate: [],
  isCustomerBookedUnitsReCalculateLoading: false,
  isLoading: false,
  cancellationPolicyText: '',
  isCancellationPolicyTextLoading: false,
  isUpdateAttendeesLoading: false,
  error: '',
  customerOverviewOrders: null,
  customerBookingsTabFilter:
    ECustomerBookingsTabFilter.CUSTOMER_UPCOMING_ORDERS,
  customerSelectedPackages: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCustomerBookingOrder(
      state,
      action: PayloadAction<IBookingOrderResponse>,
    ) {
      state.customerBookingOrder = action.payload;
      state.isLoading = false;
    },
    setCustomerBookingOrderFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    cleanCustomerBookingOrder(state) {
      state.customerBookingOrder = null;
    },
    setCancellationPolicyTextLoading(state, action: PayloadAction<boolean>) {
      state.isCancellationPolicyTextLoading = action.payload;
    },
    getCancellationPolicyTextSuccess(state, action: PayloadAction<string>) {
      state.cancellationPolicyText = action.payload;
      state.isCancellationPolicyTextLoading = false;
    },
    setIsUpdateAttendeesLoading(state, action: PayloadAction<boolean>) {
      state.isUpdateAttendeesLoading = action.payload;
    },
    setUpdatedAttendees(state, action: PayloadAction<TAttendee[]>) {
      if (state.customerBookingOrder)
        state.customerBookingOrder.orderDays[0].unitBookings[0].attendees =
          action.payload;
    },
    setCustomerBookedUnits(state, action: PayloadAction<any>) {
      state.customerBookedUnits = action.payload;
    },
    cleanCustomerBookedUnits(state) {
      state.customerBookedUnits = null;
    },
    setCustomerOverviewOrders(
      state,
      action: PayloadAction<ICustomerOverviewOrders>,
    ) {
      state.customerOverviewOrders = action.payload;
      state.isLoading = false;
    },
    setCustomerBookingsTabFilter(
      state,
      action: PayloadAction<ECustomerBookingsTabFilter>,
    ) {
      state.customerBookingsTabFilter = action.payload;
    },
    setCustomerBookedUnitsToReCalculate(
      state,
      action: PayloadAction<IOrderDay[]>,
    ) {
      state.customerBookedUnitsToReCalculate = action.payload;
    },

    cleanCustomerBookedUnitsToReCalculate(state) {
      state.customerBookedUnitsToReCalculate = [];
    },
    setIsCustomerBookedUnitsReCalculateLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isCustomerBookedUnitsReCalculateLoading = action.payload;
    },
    setCustomerPreviousBookingOrder(
      state,
      action: PayloadAction<IBookingChanges>,
    ) {
      state.customerPreviousBookingOrder = action.payload;
      state.isLoading = false;
    },
    cleanCustomerPreviousBookingOrder(state) {
      state.customerPreviousBookingOrder = null;
    },
    setCustomerSelectedPackages(
      state,
      action: PayloadAction<TSelectedPackageItem[][]>,
    ) {
      state.customerSelectedPackages = action.payload;
    },
    updateCustomerSelectedPackages(
      state,
      action: PayloadAction<{
        newPackage: IPackageResponse | null;
        dayIndex: number;
        unitId: number;
      }>,
    ) {
      const {newPackage, dayIndex, unitId} = action.payload;
      state.customerSelectedPackages = state.customerSelectedPackages?.map(
        (packagesByDay, index) =>
          index === dayIndex
            ? packagesByDay.map((packageDataByUnit) =>
                packageDataByUnit[0] === unitId
                  ? [packageDataByUnit[0], newPackage]
                  : packageDataByUnit,
              )
            : packagesByDay,
      ) as TSelectedPackageItem[][];
    },
    cleanCustomerSelectedPackages(state) {
      state.customerSelectedPackages = null;
    },
    setOfferCreator(state, action: PayloadAction<string | null>) {
      if (state.customerBookingOrder) {
        state.customerBookingOrder.offerCreatorName = action.payload;
      }
      state.isLoading = false;
    },
  },
});

export const {
  setIsLoading,
  setCustomerBookingOrder,
  setCustomerBookingOrderFailure,
  cleanCustomerBookingOrder,
  setCancellationPolicyTextLoading,
  getCancellationPolicyTextSuccess,
  setIsUpdateAttendeesLoading,
  setUpdatedAttendees,
  setCustomerBookedUnits,
  cleanCustomerBookedUnits,
  setCustomerOverviewOrders,
  setCustomerBookingsTabFilter,
  setCustomerBookedUnitsToReCalculate,
  cleanCustomerBookedUnitsToReCalculate,
  setIsCustomerBookedUnitsReCalculateLoading,
  setCustomerPreviousBookingOrder,
  cleanCustomerPreviousBookingOrder,
  setCustomerSelectedPackages,
  updateCustomerSelectedPackages,
  cleanCustomerSelectedPackages,
  setOfferCreator,
} = customerSlice.actions;

export default customerSlice.reducer;

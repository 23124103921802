import React from 'react';
import {useSelector} from 'react-redux';
import {Divider} from 'antd';
import _get from 'lodash/get';

import TextBlock, {TextBlockContainer} from 'view/components/TextBlock';
import TextValue from 'view/components/TextValue/TextValue';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IContact} from 'types/dto/IContact.types';
import {ELegalEntityType, LegalEntityTypeCaption} from 'types/venue';
import {EMPTY_OBJECT} from 'constants/app';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {getLegalPhoneNumber} from 'view/venue/CreateVenue/pages/ContactDetails/helpers';

type TProps = {
  accommodationType: EAccommodationType;
};

const ContactDetailsInfo = ({accommodationType}: TProps) => {
  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  const contact: IContact =
    useSelector((state) =>
      _get(
        state,
        `${PATH_TO_REDUCER_VENUE_DATA}.${
          isExternalVenue ? 'supplier' : 'contact'
        }`,
      ),
    ) || EMPTY_OBJECT;

  const legalContact =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.legalContact`),
    ) || EMPTY_OBJECT;

  const legalEntityType: ELegalEntityType = useSelector((state) =>
    _get(state, 'venue.legalEntityType'),
  );

  const address = getAddressStringFromLocation(legalContact);
  const legalPhoneValue = getLegalPhoneNumber(legalContact.legalPhone);
  const isBusinessEntity = legalEntityType === ELegalEntityType.BUSINESS;

  return (
    <>
      {isExternalVenue && (
        <>
          <div className='bold-text'>Legal Entity Type</div>
          <TextBlock
            label='Legal entity'
            value={LegalEntityTypeCaption[legalEntityType]}
            valueId='venueCompanyDetailsLegalEntityType'
          />
          <Divider />
        </>
      )}

      <div className='bold-text'>
        {isExternalVenue ? 'Platform log-in' : 'Property contact details'}
      </div>

      {isExternalVenue && (
        <>
          <TextBlock
            label='First Name'
            value={contact.firstName}
            valueId='venueContactDetailsFirstName'
          />
          <TextBlock
            label='Last Name'
            value={contact.lastName}
            valueId='venueContactDetailsLastName'
          />
        </>
      )}

      <TextBlock
        label='Email address'
        value={contact.email}
        valueId='venueContactDetailsEmail'
      />

      <TextBlock
        label='Phone number'
        value={contact.phone}
        valueId='venueContactDetailsPhone'
      />

      {!isExternalVenue && (
        <>
          <TextBlock
            label='Fax number'
            value={contact.fax}
            valueId='venueContactDetailsFax'
          />
          <TextBlockContainer>
            <TextValue secondary size='small'>
              Website URL
            </TextValue>
            <div>
              {contact.websiteUrl ? (
                <a
                  href={contact.websiteUrl}
                  target='_blank'
                  rel='noreferrer noopener'
                  id='venueContactDetailsWebsiteUrl'
                >
                  {contact.websiteUrl}
                </a>
              ) : (
                '-'
              )}
            </div>
          </TextBlockContainer>
        </>
      )}

      <Divider />

      <div className='bold-text'>
        {isExternalVenue ? 'Business details' : 'Legal contact details'}
      </div>

      {!isExternalVenue && (
        <>
          <TextBlock
            label='Managing company '
            value={legalContact.companyName}
            valueId='venueContactDetailsCompanyName'
          />

          <TextBlock
            label='Email address'
            value={legalContact.email}
            valueId='venueContactDetailsCompanyEmail'
          />
        </>
      )}

      {isExternalVenue && isBusinessEntity && (
        <>
          <TextBlock
            label='Legal business name'
            value={legalContact.companyName}
            valueId='venueContactDetailsCompanyName'
          />

          <TextBlock
            label='Registration number'
            value={legalContact.registrationNumber}
            valueId='venueContactDetailsRegistrationNumber'
          />
        </>
      )}
      {isExternalVenue && (
        <>
          {' '}
          <TextBlock
            label={isBusinessEntity ? 'Company Tax ID' : 'Tax ID'}
            value={legalContact.taxNumber}
            valueId='venueContactDetailsCompanyTaxId'
          />
          <Divider />
          <div className='bold-text'>Business address</div>
        </>
      )}
      <TextBlock
        label={
          isExternalVenue ? 'Business address' : 'Managing company address'
        }
        value={address}
        valueId='venueContactDetailsCompanyAddress'
      />

      <TextBlock
        label='Phone number'
        value={legalPhoneValue}
        valueId='venueContactDetailsCompanyPhone'
      />

      {!isExternalVenue && (
        <TextBlock
          label='CEO'
          value={legalContact.ceoName}
          valueId='venueContactDetailsCompanyCeoName'
        />
      )}

      {!isExternalVenue && (
        <TextBlock
          label='Tax number'
          value={legalContact.taxNumber}
          valueId='venueContactDetailsCompanyTaxNumber'
        />
      )}
    </>
  );
};

export default ContactDetailsInfo;

import React from 'react';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  mdBp,
  sectionInsetXs,
  sectionInsetSm,
  NW2Primary,
  lgBp,
  offsetXLg,
  fontWeightBold,
  NW2FontSizeH5Md,
  NW2FontSizeH4Xs,
  smBp,
} from 'constants/styleVars';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {LOCATION_ID} from 'constants/app';
import {setSearchFocusedInput} from 'store/search/searchSlice';
import {useAppDispatch} from 'store/hooks';
import {setHeaderCollapsed} from 'store/app/appSlice';

const CallToActionContainer = styled.div<{backgroundColor?: string}>`
  padding: ${sectionInsetXs} 0;
  background: ${({backgroundColor}) => backgroundColor};

  @media (min-width: ${mdBp}px) {
    padding: ${sectionInsetSm} 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${offsetXLg};
  align-items: center;

  @media (min-width: ${smBp}px) {
    grid-template-columns: 1fr auto;
  }
`;

const Title = styled.div`
  font-size: ${NW2FontSizeH5Md};
  font-weight: ${fontWeightBold};
  line-height: 1.2;

  @media (min-width: ${lgBp}px) {
    font-size: ${NW2FontSizeH4Xs};
  }
`;

type TProps = {
  text: string;
  buttonText: string;
  backgroundColor?: string;
};

export function CallToAction({
  text,
  buttonText,
  backgroundColor = NW2Primary,
}: TProps) {
  const dispatch = useAppDispatch();

  const onClickHandler = () => {
    document.getElementById(LOCATION_ID)?.focus();
    dispatch(setSearchFocusedInput(LOCATION_ID));
    dispatch(setHeaderCollapsed(false));
  };

  return (
    <CallToActionContainer backgroundColor={backgroundColor}>
      <NW2Container>
        <Grid>
          <Title>{text}</Title>

          <NW2Button onClick={onClickHandler}>{buttonText}</NW2Button>
        </Grid>
      </NW2Container>
    </CallToActionContainer>
  );
}

import {useQuery} from 'hooks/useQuery';
import {useParams} from 'react-router-dom';

const useParamsVenueId = () => {
  const {venueID: paramVenueId = ''} = useParams<{
    venueID: string;
  }>();
  const {venueId = ''} = useQuery();

  //taking venueId from params or query
  const venueIds = ((paramVenueId || venueId) as string).split(',');

  return {
    paramsVenueId: venueIds[0],
    paramsVenueIds: venueIds,
  };
};

export default useParamsVenueId;

import React, {FC} from 'react';
import styled from 'styled-components';
import {Form, Input} from 'antd';

type PropsType = {
  name: string;
  placeholder: string;
  functionUpdate: (value: React.SetStateAction<string>) => void;
  message: string;
  label?: string;
};

export const StyledFormItem = styled(Form.Item)`
  width: 100%;
`;

const FormItemEmail: FC<PropsType> = ({
  name,
  placeholder,
  functionUpdate,
  message,
  label,
}: PropsType) => {
  const onUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    functionUpdate(event.target.value);
  };
  return (
    <StyledFormItem
      name={name}
      rules={[{required: true, message: message}, {type: 'email'}]}
      label={label}
    >
      <Input name={name} placeholder={placeholder} onChange={onUpdate} />
    </StyledFormItem>
  );
};

export default FormItemEmail;

import React, {memo} from 'react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';

import {TDateType} from 'types/dto/ISearch.types';

import {StyledWrapper} from './NW2TimePickerInline.styles';
import {useTimepicker} from './useTimepicker';

interface IProps extends ReactDatePickerProps {
  selected: TDateType;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const NW2TimePickerInline = memo(
  ({selected, onChange, onFocus, onBlur, ...rest}: IProps) => {
    const {ref, config} = useTimepicker({onFocus, onBlur});

    return (
      <StyledWrapper ref={ref}>
        <DatePicker
          selected={selected}
          onChange={onChange}
          {...config}
          {...rest}
        />
      </StyledWrapper>
    );
  },
);

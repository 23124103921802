import axios from 'axios';
import {message} from 'antd';
import {DateTime} from 'luxon';

import {AppDispatch, RootState} from '../types';
import {TContainer} from '../../app';
import {setVenueDetails} from '../venueDetails/venueDetailsSlice';
import {
  getVenueDetailsRequest,
  getVenueDetailsSuccess,
  getVenueDetailsFailure,
  getMultiVenueDetailsSuccess,
  setVenueUnitBookingStatistics,
  getRegistrationLinkDataFailure,
  getRegistrationLinkDataRequest,
  getRegistrationLinkDataSuccess,
} from './venuesSlice';
import {
  IMultiDayVenueDetailsRequestData,
  IVenueDetailsDataWithDays,
} from './types';
import {IMergedVenue, TSearchVenuesDay} from 'types/search';
import LocalStorageService from 'infra/common/localStorage.service';
import {IVenue} from 'types/venue';
import {
  IGetVenueUnitBookingStatisticsByDatesPayload,
  ISetVenueUnitBookingStatusPayload,
  IVenueUnitBookingStatisticsItem,
  TVenueUnitBookingStatistics,
} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';
import {getISOString} from 'utils/dateUtils';
import {ApiType, createBaseUrl} from 'infra/common/http.service';
import {EIgnoreInterceptorCodes} from 'utils/axiosInterceptors/types';
import {setVenueAlreadyExists} from 'store/venue/actions';

export const getPublicVenue =
  (payload: {id: string | null; isMultiRooms: boolean}): any =>
  (dispatch: any, _: unknown, {venueContainer}: TContainer): void => {
    const {id, isMultiRooms} = payload;
    dispatch(getVenueDetailsRequest());
    venueContainer.getPublicVenueById({
      payload: id,
      onSuccess: (response: any) => {
        dispatch(
          getVenueDetailsSuccess({
            response,
            isMultiRooms,
          }),
        );
      },
      onError: () => {},
    });
  };

interface IGetMultiVenueDetailsAction {
  data: IVenueDetailsDataWithDays[];
  filterDays: TSearchVenuesDay[];
  getSelectedVenueUnitId: (props: {
    venueId: number;
    checkIn: string;
  }) => number | undefined;
  withoutPackages?: boolean;
}

export const getMultiVenueDetailsAction =
  ({
    data,
    filterDays,
    getSelectedVenueUnitId,
    withoutPackages = true,
  }: IGetMultiVenueDetailsAction) =>
  (
    dispatch: AppDispatch,
    getState: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(getVenueDetailsRequest());

    const state = getState();

    const locallySavedMultiVenuesPayload =
      LocalStorageService.getByKey('multiVenuesPayload');

    const multiRoomPayload = data.reduce(
      (
        result: (IMergedVenue | IMultiDayVenueDetailsRequestData)[],
        item: IVenueDetailsDataWithDays,
      ) => {
        const findVenue = (venue: IVenue) =>
          Number(venue.id) === Number(item.id);

        const findVenueFromLocalStorage = (
          venue: IMultiDayVenueDetailsRequestData,
        ) => {
          if (venue) {
            return Number(venue.accommodationIds[0]) === Number(item.id);
          }
        };

        const multiVenueDetails = state.offers.compareList.items;
        const venue = multiVenueDetails.find(findVenue);

        const parsedDataFromLocalStorage =
          locallySavedMultiVenuesPayload &&
          JSON.parse(locallySavedMultiVenuesPayload);

        const venueFromLocalStorage = parsedDataFromLocalStorage?.find(
          findVenueFromLocalStorage,
        );

        return [
          ...result,
          !venue && locallySavedMultiVenuesPayload
            ? {...venueFromLocalStorage, withoutPackages}
            : {
                accommodationIds: [item.id],
                filterData: filterDays.map((day) => ({
                  ...day,
                  roomFilters: day.roomFilters?.map((room) => {
                    const selectedVenueUnitId = getSelectedVenueUnitId({
                      venueId: +item.id,
                      checkIn: room.checkIn,
                    });

                    return {
                      ...room,
                      ...(selectedVenueUnitId
                        ? {unitId: selectedVenueUnitId}
                        : {}),
                    };
                  }),
                })),
                withoutPackages,
              },
        ];
      },
      [],
    );

    LocalStorageService.setByKey(
      'multiVenuesPayload',
      JSON.stringify(multiRoomPayload),
    );

    venuesContainer.getMultiDayMultiVenueDetails({
      payload: multiRoomPayload,
      onSuccess: async (data) => {
        Promise.all(data).then((result) => {
          dispatch(getMultiVenueDetailsSuccess({response: result}));
        });
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode ||
          error?.message ||
          'Failed to fetch venue data!';
        dispatch(getVenueDetailsFailure(errorMessage));
      },
    });
  };

export const getMultiVenueDetailsMultiRequest =
  (data: IMultiDayVenueDetailsRequestData[]) =>
  (
    dispatch: AppDispatch,
    _: () => RootState,
    {venuesContainer}: TContainer,
  ) => {
    dispatch(getVenueDetailsRequest());

    venuesContainer.getMultiDayMultiVenueDetails({
      payload: data,
      onSuccess: async (data) => {
        Promise.all(data).then((result) => {
          //setting single venue details for UI creation
          dispatch(setVenueDetails(result[0]));
          dispatch(getMultiVenueDetailsSuccess({response: result}));
        });
      },
      onError: (error: any) => {
        const errorMessage =
          error?.data?.reasonCode ||
          error?.message ||
          'Failed to fetch venue data!';
        dispatch(getVenueDetailsFailure(errorMessage));
      },
    });
  };

export const getVenueUnitBookingStatisticsByDates =
  (payload: IGetVenueUnitBookingStatisticsByDatesPayload): any =>
  (dispatch: any, _: unknown, {venuesContainer}: TContainer): void => {
    const {requestDate, venueId} = payload;
    const FROM_DATE_OFFSET = 7; // previous month days
    const TO_DATE_OFFSET = 14; // next month days
    const daysInMonth = DateTime.fromISO(requestDate).daysInMonth || 30;
    const selectedDate = DateTime.fromISO(requestDate).day;
    const fromDate = getISOString(
      DateTime.fromISO(requestDate).plus({
        days: 1 - selectedDate - FROM_DATE_OFFSET,
      }),
    );
    const toDate = getISOString(
      DateTime.fromISO(requestDate).plus({
        days: daysInMonth - selectedDate + TO_DATE_OFFSET,
      }),
    );
    const requestData = {
      venueId,
      from: fromDate,
      to: toDate,
    };
    venuesContainer.getVenueUnitBookingStatisticsByDates({
      payload: requestData,
      onSuccess: (data: IVenueUnitBookingStatisticsItem[] = []) => {
        const itemMap = data.reduce(
          (
            acc: TVenueUnitBookingStatistics,
            item: IVenueUnitBookingStatisticsItem,
          ) => {
            acc[item.date] = item;
            return acc;
          },
          {},
        );
        dispatch(setVenueUnitBookingStatistics(itemMap));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };
export const setVenueUnitBookingStatus =
  (actionPayload: ISetVenueUnitBookingStatusPayload): any =>
  (dispatch: any, _: unknown, {venuesContainer}: TContainer): void => {
    venuesContainer.setVenueUnitBookingStatus({
      payload: actionPayload,
      onSuccess: () => {
        const {
          venueId,
          payload: {date},
        } = actionPayload;
        dispatch(
          getVenueUnitBookingStatisticsByDates({
            requestDate: date || getISOString(DateTime.now()),
            venueId,
          }),
        );
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const getHMDRegistrationLink =
  ({
    accommodationId,
    isMultiRooms,
    requestId,
    onError,
    onGetLinkCallback,
  }: {
    accommodationId: number;
    isMultiRooms: boolean;
    requestId: string;
    onError?: () => void;
    onGetLinkCallback?: (link?: string) => void;
  }) =>
  (
    dispatch: AppDispatch,
    getState: any,
    {venueContainer}: TContainer,
  ): void => {
    dispatch(getVenueDetailsRequest());
    venueContainer.getPublicVenueById({
      payload: accommodationId,
      onSuccess: async (response: IVenue) => {
        dispatch(getVenueDetailsSuccess({response, isMultiRooms}));
        dispatch(getRegistrationLinkDataRequest());

        try {
          const hkey: string = getState().venuesReducer.venueDetails.hkey;
          const unInterceptedRequest = axios.create();

          const {data} = await unInterceptedRequest.get(
            createBaseUrl(
              ApiType.Inventory,
              `venues/hkey/${hkey}/onboarding-link?requestId=${requestId}`,
            ),
          );

          dispatch(getRegistrationLinkDataSuccess(data));
          onGetLinkCallback?.(data.registrationLink);
        } catch (error: any) {
          dispatch(getRegistrationLinkDataFailure(error?.response));

          if (
            error?.response?.data?.reasonCode ===
            EIgnoreInterceptorCodes.ACCOUNT_ALREADY_REGISTERED
          ) {
            // set venue already exists for handling it on the Login page
            dispatch(setVenueAlreadyExists(true));
            onError?.();
          }

          onGetLinkCallback?.();
        }
      },
    });
  };
export const removeMultiVenueDetailsItem =
  (venueId: number | string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    const filterItems = <T extends {accommodationId: number}>({
      accommodationId,
    }: T) => {
      return accommodationId !== venueId;
    };

    const updatedMultiVenueDetails =
      // @ts-ignore
      state.venuesReducer.multiVenueDetails.filter(filterItems);

    dispatch(
      getMultiVenueDetailsSuccess({
        response: updatedMultiVenueDetails,
      }),
    );
  };

import styled from 'styled-components';

import {
  blackColor,
  fontSizeSm,
  fontWeightExtraBold,
  fontWeightNormal,
  lgBp,
  lineHeightMd,
  mdBp,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  offsetXXXXLg,
  xLgBp,
} from 'constants/styleVars';

export const SectionBody = styled.div`
  color: ${NW2Gray900Color};
  border-bottom: 1px solid ${NW2Gray200Color};
  padding-bottom: 56px;
`;

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${offsetXLg};
  padding: ${offsetXXXXLg} 0 0;

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 142px 1fr;
    grid-row-gap: 0;
    grid-column-gap: ${offsetDef};
  }

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 158px 1fr;
  }

  &:last-of-type {
    ${SectionBody} {
      border: none;
    }
  }
`;

export const SectionTitle = styled.h2`
  display: flex;
  flex-direction: column;
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${blackColor};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 0;
`;

export const SectionSubTitle = styled.span`
  color: ${NW2Gray600Color};
  font-weight: ${fontWeightNormal};
  text-transform: none;
`;

export const SectionRowColumns = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${lgBp}px) {
    flex-direction: row;
  }
`;

export const SectionRowColumnsActions = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  padding-top: ${offsetXLg};

  @media (min-width: ${lgBp}px) {
    width: auto;
    padding-top: 0;
  }
`;

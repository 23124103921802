import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  borderRadiusDef,
  fontSizeMd,
  fontSizeXSm,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightXSm,
  NW2Gray300Color,
  NW2SecondaryDisabledBgColor,
  NW2SecondaryDisabledBorderColor,
  offsetDef,
  offsetXLg,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';

export const StatusTag = styled.button<{
  bgColor: string;
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${offsetXSm};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightXSm};
  padding: ${offsetXSm} ${offsetDef};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: ${({bgColor}) => bgColor};
  color: ${({color}) => color};
  border-radius: ${borderRadiusDef};
  border: 0;

  &:disabled {
    background-color: ${NW2SecondaryDisabledBgColor};
    border-color: ${NW2SecondaryDisabledBorderColor};
    color: ${NW2Gray300Color};
  }
`;

export const Text = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin-bottom: ${offsetXSm};
`;

export const StyledButton = styled(NW2Button)`
  margin-top: ${offsetXLg};
`;

export const StatusTagBtnText = styled.span`
  margin-right: ${offsetXXSm};
`;

import React from 'react';

import ActionCardClosable from 'view/components/ActionCardClosable';
import CancelPolicyCard from 'view/components/CancelPolicyCard/CancelPolicyCard';
import Icon from 'view/components/Icon';

import {CANCEL_POLICY_MEETING_ROOM_CAPACITY} from 'constants/app';
import {IUnitBooking} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ECancelPolicyInfoCardComponent} from 'view/components/CancelPolicyInfoCard/types';

import {errorColor} from 'constants/styleVars';
import {IUnitBookingDetails} from 'types/bookingOrders';

const ACTION_CARD_CLOSABLE_ICON_PROPS = {
  size: 20,
  error: true,
};

type TProps = {
  unitData?: IUnitBooking[] | IUnitBookingDetails[];
  componentType: ECancelPolicyInfoCardComponent;
};

interface ICancelPolicyInfoCardComponent {
  contentComponent: JSX.Element;
}

type TCancelPolicyInfoCardComponent = {
  [key in ECancelPolicyInfoCardComponent]: ICancelPolicyInfoCardComponent;
};

const CancelPolicyWarnCard = ({componentType, unitData}: TProps) => {
  if (!unitData || !unitData.length) return null;

  const isMultiRooms = unitData.length > 1;

  const isSingleMeetingRoom =
    !isMultiRooms && unitData[0].unitInfo?.roomType === ERoomType.MEETING_ROOM;

  const roomCapacity = unitData[0].maxParticipants || 0;

  const isMeetingRoomBigOrMultiRooms = isSingleMeetingRoom
    ? roomCapacity >= CANCEL_POLICY_MEETING_ROOM_CAPACITY
    : isMultiRooms;

  const cancelPolicyText = (
    <>
      <b>Cancellation is no longer free of charge.</b>
      <br />
      Please note, as the start time is now closer than{' '}
      {isMeetingRoomBigOrMultiRooms ? '7' : '24'}
      &nbsp;days cancellation is no longer free of charge.
    </>
  );

  const content: TCancelPolicyInfoCardComponent = {
    [ECancelPolicyInfoCardComponent.CANCEL_POLICY_CARD]: {
      contentComponent: (
        <CancelPolicyCard
          text={cancelPolicyText}
          icon={<Icon error size={22} icon='INFO' />}
        />
      ),
    },
    [ECancelPolicyInfoCardComponent.ACTION_CARD_CLOSABLE]: {
      contentComponent: (
        <ActionCardClosable
          isClosable={false}
          borderColor={errorColor}
          icon='WARN'
          iconProps={ACTION_CARD_CLOSABLE_ICON_PROPS}
          description={cancelPolicyText}
        />
      ),
    },
  };

  return content[componentType].contentComponent;
};

export default CancelPolicyWarnCard;

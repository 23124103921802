import React, {useState} from 'react';
import _get from 'lodash/get';
import {useSelector} from 'react-redux';

import NMMSection from 'view/components/NMMSection';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {IVenue} from 'types/venue';
import {EVenueProfileSections} from '../../types';
import NW2AmenitiesForm from './NW2AmenitiesForm';
import {NW2NewAmenitiesList} from 'view/components/NW2AmenitiesList/NW2NewAmenitiesList';
import {RootState} from 'store/types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  venue: IVenue;
  isExternalVenue: boolean;
  amenities: IAmenity[];
}

const NW2AmenitiesWrapper = ({venue, amenities, isExternalVenue}: IProps) => {
  const [editAmenities, setEditAmenities] = useState(false);

  const venueAmenities: IAmenity[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.amenities`),
  );

  const onStartEditingAmenities = () => {
    setEditAmenities(true);
  };

  const onFinishEditingAmenities = () => {
    setEditAmenities(false);
  };

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  return (
    <NMMSection
      id={EVenueProfileSections.AMENITIES}
      title='Amenities'
      isEditOn={!(!editAmenities && !!venueAmenities.length)}
      onEdit={onStartEditingAmenities}
    >
      {!isAgent && (editAmenities || !venueAmenities.length) ? (
        <NW2AmenitiesForm
          venueAmenities={venueAmenities}
          amenities={amenities}
          venue={venue}
          isExternalVenue={isExternalVenue}
          onFinishEditing={onFinishEditingAmenities}
        />
      ) : (
        <NW2NewAmenitiesList amenities={venueAmenities} />
      )}
    </NMMSection>
  );
};

export default NW2AmenitiesWrapper;

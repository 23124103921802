import styled from 'styled-components';

import {StyledH6} from 'view/components/Typography';
import {fontSizeXSm, fontSizeXLg, mdBp} from 'constants/styleVars';

export const StyledDiv = styled.div<{fontSize?: string}>`
  font-size: ${({fontSize}) => fontSize || 'inherit'};
`;

export const Title = styled(StyledH6)`
  text-align: center;
  font-size: ${fontSizeXSm};

  @media (min-width: ${mdBp}px) {
    text-align: left;
    font-size: ${fontSizeXLg};
  }
`;

import styled, {css, keyframes} from 'styled-components';

import Icon from 'view/components/Icon';
import NW2Button from '../NW2Button';

import {
  borderRadiusDef,
  fontSizeSm,
  fontSizeXSm,
  fontSizeXXSm,
  fontWeightBold,
  fontWeightNormal,
  lineHeightMd,
  lineHeightSm,
  lineHeightXSm,
  NW2Error50Color,
  NW2ErrorLight500Color,
  NW2ErrorLight50Color,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Info500Color,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXSm,
  primaryColor,
  whiteColor,
} from 'constants/styleVars';
import {IMAGE_ITEM_MIN_HEIGHT} from 'view/venue/NW2VenueProfile/components/NW2Images/NW2Images.styles';

export const Container = styled.div<{
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  isDisabled?: boolean;
}>(({isFocused, isDragAccept, isDragReject, isDisabled}) => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: ${IMAGE_ITEM_MIN_HEIGHT};
    text-align: center;
    padding: ${offsetXXSm};
    background: ${isFocused || isDragAccept || isDragReject
      ? NW2Gray200Color
      : NW2Gray100Color};
    color: ${NW2Gray600Color};
    border-radius: ${borderRadiusDef};
    overflow: hidden;
    border: 1px dashed
      ${isFocused || isDragAccept
        ? primaryColor
        : isDragReject
        ? NW2Error50Color
        : NW2Gray200Color};
    transition: border-color 0.3s, background-color 0.3s;
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    opacity: ${isDisabled ? 0.5 : 1};
    pointer-events: ${isDisabled ? 'none' : ''};

    &:hover {
      border-color: ${primaryColor};
      background-color: ${NW2Gray200Color};
    }

    img {
      width: 100%;
    }

    > div {
      width: 100%;
      height: 100%;
    }
  `;
});

export const InputLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadiusDef};
  pointer-events: none;
`;

export const InputLayoutTitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightBold};
  margin-top: ${offsetSm};
`;

export const InputLayoutSubTitle = styled.div`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

export const StyledAnimatedIcon = styled(Icon)`
  animation: ${rotate} 1.5s linear infinite;
`;

export const ProgressBar = styled.div<{progress: number; marginTop?: boolean}>`
  width: 100%;
  height: ${offsetXXSm};
  border-radius: ${borderRadiusDef};
  overflow: hidden;
  position: relative;
  background-color: ${NW2Gray200Color};
  margin-top: ${({marginTop}) => (marginTop ? offsetXSm : 0)};

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: ${({progress}) => progress + '%'};
    transition: width 0.15s;
    background-color: ${NW2Info500Color};
  }
`;

export const ProgressSection = styled.div`
  margin-top: ${offsetDef};
`;

export const ProgressSectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${offsetDef};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  margin-bottom: ${offsetXSm};
`;

export const ProgressSectionTopLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};
`;

export const ProgressSectionPercents = styled.div`
  font-weight: ${fontWeightBold};
`;

export const AdditionalInfo = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightNormal};
  color: ${NW2Gray600Color};

  margin-top: ${offsetXSm};
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetDef};

  margin-top: ${offsetDef};
  padding: ${offsetSm} ${offsetDef};

  border: 1px solid ${NW2Gray200Color};
  border-radius: ${offsetXSm};
`;

export const Left = styled.div`
  width: 100%;
`;

export const StyledIcon = styled(Icon)`
  padding: 3px;
  border-radius: 100px;
  background-color: ${NW2Gray100Color};

  &:hover {
    border-color: ${primaryColor};
    background-color: ${NW2Gray200Color};
  }
`;

export const StyledConfirmButton = styled(NW2Button)`
  color: ${whiteColor};

  background-color: ${NW2ErrorLight500Color};
  border: none;

  &:active {
    background-color: ${NW2ErrorLight50Color};
  }

  @media (hover: hover) {
    &:hover {
      background-color: #d3593b;
    }
  }
`;

import {RefObject, useEffect, useState} from 'react';

interface OptionsProps extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export function useIntersectionObserver(
  elementRef: RefObject<HTMLElement | null>,
  options: OptionsProps,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const {
    threshold = 0,
    root = null,
    rootMargin = '0%',
    freezeOnceVisible = false,
  } = options;

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window?.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = {threshold, root, rootMargin};

    const observer = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]): void => {
        setEntry(entry);
      },
      observerParams,
    );

    observer.observe(node);

    return () => observer.disconnect();
  }, [root, rootMargin, frozen, threshold, elementRef]);

  return entry;
}

import {useCallback} from 'react';

import {useAppDispatch} from 'store/hooks';
import {
  ECreateOfferFormFields,
  TAssignRoomType,
  TCreateOfferFormValues,
} from '../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IAmenity} from 'types/dto/IAmenity';
import {
  getSpaces,
  getWorkInProgress,
  setSpaceSuccess,
} from 'store/venue/actions';
import {
  ERoomSchemaNames,
  ISpaceData,
  ISpacesDataItem,
  ISpacesDataItemImage,
  IUnitDimensions,
} from 'types/venue';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import venueRepository from 'infra/repository/VenueRepository';
import api from 'infra/common/http.service';
import {TUnitCapacityStyle} from 'types/dto/IUnit.types';
import {removeFileExtension} from 'utils/stringUtils';

interface IProps {
  venueId: number;
  requestedSeatingPlan: ERoomSchemaNames;
  assignRoomType: TAssignRoomType;
  editedSpace: ISpacesDataItem;
  roomAmenities: IAmenity[];
}
export function useCreateSpace({
  venueId,
  requestedSeatingPlan,
  editedSpace,
  assignRoomType,
  roomAmenities,
}: IProps) {
  const dispatch = useAppDispatch();

  const onCreateSpace = useCallback(
    async (values: TCreateOfferFormValues) => {
      const getUnitCapacities = (): TUnitCapacityStyle[] => {
        const result = [];

        if (values[ECreateOfferFormFields.UPDATED_CAPACITIES]) {
          result.push(...values[ECreateOfferFormFields.UPDATED_CAPACITIES]);
        }

        const capacityValue = values[ECreateOfferFormFields.CAPACITY];

        if (capacityValue && capacityValue[requestedSeatingPlan]) {
          result.push({
            setupStyle: requestedSeatingPlan,
            capacity: capacityValue[requestedSeatingPlan]?.capacity,
          });
        }

        return result as TUnitCapacityStyle[];
      };

      // space data
      const data = {
        floor: +values[ECreateOfferFormFields.FLOOR],
        name: values[ECreateOfferFormFields.NAME],
        description: values[ECreateOfferFormFields.DESCRIPTION],
        unitCapacities: getUnitCapacities(),
        unitDimensions: values[
          ECreateOfferFormFields.DIMENSIONS
        ] as IUnitDimensions,
        type: ERoomType.MEETING_ROOM,
        roomType: ERoomType.MEETING_ROOM,
        coverImageName: removeFileExtension(
          values[ECreateOfferFormFields.COVER_IMAGE],
        ),
        isRfp: true,
        priceHour: Number(values[ECreateOfferFormFields.PRICE_HOUR]),
        priceHalfDay: Number(values[ECreateOfferFormFields.PRICE_HALF_DAY]),
        priceDay: Number(values[ECreateOfferFormFields.PRICE_DAY]),
        minimumPaidDuration: parseInt(
          values[ECreateOfferFormFields.PAID_DURATION],
        ),
      };

      if (assignRoomType === 'new') {
        // add space
        const createdSpace: ISpaceData = await venueRepository(api).setNmmSpace(
          {
            id: venueId,
            data: {
              ...data,
              amenities: values[ECreateOfferFormFields.AMENITIES].map(
                (item: number) => ({id: item}),
              ),
              imageFiles: values[ECreateOfferFormFields.IMAGES],
            },
          },
        );

        dispatch(setSpaceSuccess(createdSpace));
        dispatch(getSpaces(venueId));

        return createdSpace;
      }

      if (assignRoomType === 'wip') {
        // update wip space
        const files = values[ECreateOfferFormFields.IMAGES];

        // existed images after migration
        const updatedDocuments = editedSpace.documents?.filter((document) =>
          files.some(
            (f) => (f as unknown as ISpacesDataItemImage).uid === document.uid,
          ),
        );

        // added images
        const updatedFiles = (files as TFileWithPreview[]).filter(
          (f: TFileWithPreview) => f.preview,
        );

        const updatedSpace = await venueRepository(api).editNMMSpace({
          data: {
            ...editedSpace,
            ...data,
            amenities: roomAmenities.filter(({id}) =>
              values[ECreateOfferFormFields.AMENITIES].includes(id),
            ),
            files: updatedFiles,
            documents: updatedDocuments || [],
          },
        });

        dispatch(getSpaces(venueId));
        dispatch(getWorkInProgress(venueId));

        return updatedSpace;
      }
    },
    [
      assignRoomType,
      dispatch,
      editedSpace,
      requestedSeatingPlan,
      roomAmenities,
      venueId,
    ],
  );

  return [onCreateSpace];
}

import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import _get from 'lodash/get';
import queryString from 'query-string';

import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import useSearchData from '../hooks/useSearchData';

import {Routes} from 'constants/routes';
import {useQuery} from 'hooks/useQuery';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useIsSearchWithBedrooms} from '../hooks/useIsSearchWithBedrooms';
import {useGetSearchDateDifference} from '../hooks/useGetSearchDateDifference';
import {
  AnchorContentBlock,
  AnchorNavigationBlock,
  GoBackLink,
  NW2SpaceDetails,
  VenueImages,
  VenueOffers,
} from './components';
import {
  getVenueDetailsFailure,
  setIsVenueDetailsMapVisible,
} from 'store/venues/venuesSlice';
import {getAnchorContentSections} from './components/AnchorBlock/helpers';
import {IMergedVenue, ISearchedVenue} from 'types/search';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {
  HeroHeaderContainer,
  SectionDark,
  SectionLight,
} from './NW2VenueDetails.styles';
import {EFrontendErrors} from 'types/errors';
import {EIgnoreInterceptorCodes} from 'utils/axiosInterceptors/types';
import {ShortList} from '../Offer/customer/ShortList';
import {useStoredSearchCriteria} from '../hooks/useStoredSearchCriteria';
import {useMultiDayVenueDetailsData} from './useMultiDayVenueDetails';
import {useVenueDetailsId} from '../hooks/useVenueDetailsId';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {EVenueType} from 'types/dto/IPublicVenue';
import {ERoomType} from 'types/dto/ERoomType.type';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {setSearchCriteriaRoomType} from 'store/search/searchSlice';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import {useVenuesListSearch} from './useVenuesListSearch';
import {COUNTRY_CANADA, COUNTRY_USA} from 'constants/venue';

function NW2VenueDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {showWhatsAround, roomType: roomTypeFromQuery} = useQuery();
  const {paramsVenueId} = useParamsVenueId();

  const error: unknown = useSelector((state) =>
    _get(state, 'venuesReducer.error'),
  );
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);

  const {initialSearchData, querySearchData} = useSearchData();

  const {venuesList} = useVenuesListSearch({
    initialSearchData,
    startDate: querySearchData.startDate,
    endDate: querySearchData.endDate,
  });

  useMultiDayVenueDetailsData({venueId: paramsVenueId});

  const {venueDetails, isVenueDetailsLoading} = useVenueDetailsData();
  const [venueDetailsId] = useVenueDetailsId();

  const amenities = venueDetails.amenities;

  // New hook for fetching venue details for rooms only

  const {venueID: paramsId = ''} = useParams<{venueID: string}>();

  useStoredSearchCriteria({querySearchData});

  // Accommodation existing on perfect matches
  const {isSearchWithBedrooms} = useIsSearchWithBedrooms();

  const [diffInDays] = useGetSearchDateDifference();

  const anchorContentSections = useMemo(() => {
    let alternativeList =
      (venuesList.pageItems?.filter(
        (venue: IMergedVenue) => venue.accommodationId !== +paramsId,
      ) as IMergedVenue[]) || [];

    if (diffInDays > ALLOWED_DAYS_DIFFERENCE_FOR_RFP) {
      alternativeList = alternativeList.filter(
        (venue) =>
          venue.type !== EVenueType.RFP &&
          venue.type !== EVenueType.RFP_WITH_WIP,
      );
    }

    return getAnchorContentSections({
      isAlternativeSectionVisible:
        searchCriteria.roomType === ERoomType.MEETING_ROOM &&
        alternativeList.length > 0,
      amenities,
      isDetailsSectionVisible: !!venueDetailsId,
      alternativeList,
    });
  }, [
    amenities,
    venuesList.pageItems,
    diffInDays,
    paramsId,
    searchCriteria.roomType,
    venueDetailsId,
  ]);

  // Open what's around map
  useEffect(() => {
    if (showWhatsAround) {
      dispatch(setIsVenueDetailsMapVisible(true));
    }
  }, [dispatch, showWhatsAround]);

  // Navigate to venues list page if user has no access to this venue
  useEffect(() => {
    if (
      error ===
        EIgnoreInterceptorCodes.FORBIDDEN_ACCESS_TO_VENUE_DETAILS_ERROR ||
      error === EIgnoreInterceptorCodes.UNAUTHORIZED_NO_ACCESS_DATA_ERROR
    ) {
      // Set ACCESS_TO_VENUE_DENIED_ERROR error to show no access block in venues list page
      dispatch(
        getVenueDetailsFailure(EFrontendErrors.ACCESS_TO_VENUE_DENIED_ERROR),
      );

      navigate({
        pathname: Routes.venuesList,
        search: queryString.stringify(querySearchData),
      });
    }
  }, [error, dispatch, navigate, querySearchData]);

  useEffect(() => {
    if (roomTypeFromQuery && roomTypeFromQuery !== searchCriteria.roomType) {
      dispatch(setSearchCriteriaRoomType(roomTypeFromQuery as ERoomType));
    }
  }, [dispatch, roomTypeFromQuery, searchCriteria.roomType]);

  if (!venueDetailsId || isVenueDetailsLoading)
    return <NW2Loader height='100%' />;

  const isUSA = venuesList.pageItems?.some((venue: ISearchedVenue) =>
    [COUNTRY_USA, COUNTRY_CANADA].includes(venue.location.country),
  );

  return (
    <>
      <SectionDark>
        <HeroHeaderContainer>
          <GoBackLink />
          <VenueImages />
        </HeroHeaderContainer>

        <NW2Container>
          <VenueOffers hasBedroom={isSearchWithBedrooms} />
        </NW2Container>
      </SectionDark>

      <AnchorNavigationBlock
        anchorContentSections={anchorContentSections}
        roomType={searchCriteria.roomType}
        hasBedroom={isSearchWithBedrooms}
      />

      <SectionLight>
        <AnchorContentBlock anchorContentSections={anchorContentSections} />
      </SectionLight>

      {venueDetails.type !== EVenueType.RFP_WITH_WIP && (
        <NW2SpaceDetails hasBedroom={isSearchWithBedrooms} isFooterHidden />
      )}

      <ShortList
        hasBackNav
        querySearchData={querySearchData}
        noCompareBtn={isUSA}
      />
    </>
  );
}

export default NW2VenueDetails;

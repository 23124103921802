import styled, {css} from 'styled-components';

import {
  blackColor,
  datepickerZIndex,
  NW2Gray200Color,
  NW2Gray300Color,
  NW2Gray600Color,
  NW2Primary,
  NW2Blue50Color,
  whiteColor,
  offsetNone,
  offsetXXXSm,
  offsetSm,
  lineHeightLg,
  offsetXSm,
  lineHeightSm,
  fontSizeSm,
  mdBp,
  offsetXXSm,
  fontSizeXSm,
  offsetDef,
  NW2Blue800Color,
  fontWeightExtraBold,
} from 'constants/styleVars';
import iconChevron from 'img/icons/chevron.svg';

import 'react-datepicker/dist/react-datepicker.min.css';

const dayItemSize = '38px';
const dayItemSizeMobile = '13vw';
const dayItemSizeTablet = '11vw';
const dayItemFontSizeMobile = '3.5vw';
const bpExtraSmall = '360px';
const MONTH_YEAR_DROPDOWN_WIDTH = '190px';

export const Wrapper = styled.div<{
  isMobile?: boolean;
  withMonthYearDropdown?: boolean;
}>(
  ({isMobile, withMonthYearDropdown}) => css`
    position: relative;
    width: 100%;
    background-color: ${whiteColor};

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      bottom: 0;
      width: ${isMobile ? '30.5vw' : '83px'};
      pointer-events: none;
    }

    // datepicker calendar overrides
    .react-datepicker {
      font-family: 'Inter', sans-serif;
      font-size: ${fontSizeSm};
      border: 0;
      background: none;
      width: 100%;
      z-index: ${datepickerZIndex};
      height: ${isMobile ? '458px' : ''};
      overflow: hidden;

      &__header--custom {
        position: initial;

        & .react-datepicker__current-month {
          display: block;
        }
      }

      &__header {
        padding-top: ${isMobile ? '' : offsetDef};
        border-color: ${NW2Gray200Color};
        background-color: ${whiteColor};
        border-bottom: none;
      }

      &__current-month {
        font-size: ${isMobile ? dayItemFontSizeMobile : fontSizeXSm};
        line-height: ${isMobile ? ' 1.5' : lineHeightSm};
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: ${offsetXSm};
        user-select: none;
        display: ${isMobile ? 'flex' : ''};
        padding-left: ${isMobile ? '30px' : ''};
      }

      &__navigation {
        top: ${isMobile ? '' : offsetXSm};
        line-height: ${lineHeightLg};
        display: ${isMobile ? 'none' : ''};

        &--previous {
          left: ${isMobile ? offsetDef : offsetXXSm};
        }

        &--next {
          right: ${isMobile ? offsetDef : offsetXXSm};
        }

        &-icon {
          &--previous,
          &--next {
            &:before {
              top: 50%;
              width: ${isMobile ? '2vw' : '8px'};
              height: ${isMobile ? '3.5vw' : '14px'};
              background: url(${iconChevron}) no-repeat;
              border: 0;
            }
          }

          &--previous {
            &:before {
              right: 50%;
              transform: rotate(180deg) translate(-50%, 50%);
            }
          }

          &--next {
            &:before {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      &__month {
        .react-datepicker__week {
          &:last-child {
            .react-datepicker__day {
              margin-bottom: 0;
            }
          }
        }
      }

      &__day,
      &__day-name {
        color: ${NW2Gray600Color};
        width: ${isMobile ? dayItemSizeMobile : dayItemSize};
        font-size: ${isMobile ? dayItemFontSizeMobile : fontSizeXSm};
        user-select: none;
      }

      &__day {
        line-height: ${isMobile ? dayItemSizeMobile : dayItemSize};
        margin: ${offsetXXSm} 0;
        cursor: pointer;

        &--outside-month {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }

        &--today {
          box-shadow: inset 0 0 0 1px ${NW2Gray200Color};
          border-radius: 50%;
        }
      }

      @media (min-width: 520px) and (max-width: ${mdBp - 1}px) {
        &__day,
        &__day-name {
          width: ${dayItemSizeTablet};
        }

        &__day {
          line-height: ${dayItemSizeTablet};
        }
      }

      &__day-name {
        font-weight: bold;
        line-height: ${isMobile ? 1 : lineHeightSm};
        margin: ${isMobile ? offsetSm : offsetXXXSm} 0;

        @media (max-width: ${bpExtraSmall}) {
          margin: ${isMobile ? offsetXXSm : offsetXXXSm} 0;
        }
      }

      &__day,
      &__month-text,
      &__quarter-text,
      &__year-text {
        border-radius: 50%;
        transition: background-color 0.15s, color 0.15s, box-shadow 0.15s;

        &:hover {
          background-color: ${NW2Blue50Color};
          box-shadow: inset 0 0 0 1px ${NW2Blue800Color};
          color: ${NW2Blue800Color};
          border-radius: 50%;
        }

        &--selected,
        &--disabled {
          cursor: default;

          &:hover {
            box-shadow: none;
            background: none;
          }
        }

        &--selected {
          &:hover {
            color: ${blackColor};
          }
        }

        &--disabled {
          &:hover {
            color: ${NW2Gray300Color};
          }
        }

        &--keyboard-selected {
          background: none;
          color: ${blackColor};
        }

        &--in-range,
        &--in-selecting-range {
          border-radius: 0;
          font-weight: bold;
          background-color: ${NW2Blue50Color};
          color: ${blackColor};
          box-shadow: none;

          &:hover {
            border-radius: 0;
            background-color: ${NW2Blue50Color};
          }
        }

        &--selected,
        &--range-end {
          font-weight: bold;
          background-color: ${NW2Primary};
          color: ${blackColor};
          border-radius: 50%;
          box-shadow: none;

          &:hover {
            border-radius: 50%;
            background-color: ${NW2Primary};
          }
        }

        &--range-start,
        &--range-end {
          position: relative;

          &::after {
            content: '';
            background: ${NW2Blue50Color};
            width: 16px;
            height: 100%;
            top: 0;
            z-index: -1;
            position: absolute;
          }
        }

        &--range-start {
          &::after {
            right: 0;
          }
        }

        &--range-end {
          &::after {
            left: 0;
          }
        }

        &--range-start {
          &.react-datepicker__day--range-end {
            &::after {
              width: 0;
            }
          }
        }

        .some-class {
          &.another-class {
          }
        }

        &--disabled {
          background: none;
          color: ${NW2Gray300Color};
        }
      }

      // &__day, // uncomment for highlighting every day that is the same as the current one
      &__month-text,
      &__quarter-text,
      &__year-text {
        &--keyboard-selected {
          border-radius: 50%;
          background: none;
          color: ${blackColor};
          font-weight: bold;
          box-shadow: inset 0 0 0 1px ${NW2Gray200Color};
        }
      }

      ${withMonthYearDropdown &&
      css`
        &__month-year-dropdown {
          background-color: ${whiteColor};
          width: ${MONTH_YEAR_DROPDOWN_WIDTH};
          top: ${offsetNone};
          left: -31px;
        }
        &__month-year-dropdown--scrollable {
          max-height: 150px;
          height: inherit;
          overflow-y: auto;
        }
        &__current-month {
          visibility: hidden;
        }
        &__month-year-dropdown-container--scroll {
          position: absolute;
          top: ${offsetSm};
          left: 60px;
          width: 120px;
          text-transform: uppercase;
          font-weight: ${fontWeightExtraBold};
          font-size: ${fontSizeXSm};
        }
        &__month-year-read-view {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          gap: 10px;
          width: 140px;
        }
        &__month-year-read-view--down-arrow {
          display: block;
          height: 9px;
          width: 9px;
          background-color: ${blackColor};
          border: inherit;
          position: static;
          clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
          transform: rotate(-45deg);
          border-radius: 0 0 0 0.25em;
        }
      `}
    }
  `,
);

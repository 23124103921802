import React, {useCallback, useState} from 'react';

import {OfferUnitImage} from 'view/venue/Offer/components/OfferUnitImage/OfferUnitImage';

import Image from 'view/components/Image';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import TruncateText from 'view/components/TruncateText';
import TimeRangeText from 'view/components/TimeRangeText';

import {matchNumberToSecondDecimal} from 'utils/stringUtils';
import {dimensionsConverter} from 'utils/venueUtils';
import {pluralize} from 'utils/helpers';
import {setSpaceDetailsId} from 'store/venues/venuesSlice';
import {getCapacityFromCapacityDto} from 'view/customer/helpers';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {
  ImageWrapper,
  Item,
  ItemTitle,
  StyledLink,
  PricesBlock,
  BestPrice,
  ItemSubTitle,
  CSSTruncatedText,
  ButtonWrapper,
  SelectButton,
  RadioButtonSelect,
  TextWrapper,
  PriceWrapper,
  StyledIcon,
  StyledDiv,
} from './MeetingRoomSpaceItem.styles';
import {
  borderRadiusDef,
  NW2Gray600Color,
  offsetXXLg,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';
import {Group} from 'styles/basicStyledComponents/group';
import {EAccommodationType, IUnitResponse} from 'types/dto/IPublicVenue';
import {ERoomSchema, ERoomSchemaNames, iconsObject} from 'types/venue';
import {setSelectedRoom} from 'store/venueDetails/venueDetailsSlice';
import {getVenueDetails} from 'store/venueDetails/apiActions';
import {useVenueDetailsId} from 'view/venue/hooks/useVenueDetailsId';
import {useSelectedRoomId} from 'view/venue/hooks/useSelectedRoomId';

type TProps = {
  unit: IUnitResponse;
  isMobile: boolean;
  currency: string;
  accommodationType: EAccommodationType;
  isMultiDay: boolean;
  index: number;
  arrayLength: number;
  isRfp: boolean;
  isAlternative: boolean;
  isSelectUnitHidden: boolean;
};

export function MeetingRoomSpaceItem({
  unit,
  isMobile,
  accommodationType,
  currency,
  isMultiDay,
  index,
  arrayLength,
  isRfp,
  isAlternative,
  isSelectUnitHidden,
}: TProps) {
  const dispatch = useAppDispatch();

  const [venueId] = useVenueDetailsId();

  const {
    unitId,
    name,
    documents,
    unitDimensions = {area: 0, cealingHeight: 0},
    unitCapacities,
    isRtb,
    unitPrice,
    checkIn,
    checkOut,
    requestedCapacity,
    requestedSetupStyle,
  } = unit;

  const roomSetupText = ERoomSchema[requestedSetupStyle];

  const roomSetupIcon = ERoomSchemaNames[requestedSetupStyle];

  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const capacityBySetup = getCapacityFromCapacityDto(
    unitCapacities,
    requestedSetupStyle,
  );

  const coverImage = documents?.find((img) => img.isCover);

  const bookingTypeText =
    isRfp || isAlternative
      ? 'Request pricing'
      : isRtb
      ? 'Request booking'
      : 'Book instantly';

  const venueDetailsSpaceHeight = isMobile ? 238 : '100%';
  const radioButtonValue = String(unitId);

  const [getSelectedVenueUnitId] = useSelectedRoomId();
  const checkedRoomId = useAppSelector(
    ({venueDetails}) => venueDetails.selectedRoom?.unitId,
  );
  const isActive =
    String(getSelectedVenueUnitId({venueId, checkIn}) || checkedRoomId) ===
    radioButtonValue;

  const roomCounter = arrayLength > 1 ? index + 1 : '';

  const titleIsOffer = isMultiDay
    ? `Meeting Room ${roomCounter}`
    : `Meeting Room`;

  const filterDays = useAppSelector(
    ({search}) => search.searchCriteria.filterDays,
  );

  const [isLoading, setIsLoading] = useState(false);
  const getDetails = useCallback(
    (unitId: number) => {
      setIsLoading(true);
      dispatch(
        getVenueDetails({
          payload: {
            accommodationIds: [Number(venueId)],
            filterData: filterDays.map((item) => ({
              ...item,
              roomFilters: item.roomFilters?.map((room) => ({...room, unitId})),
            })),
          },
          isNoLoading: true,
          onSuccessCallback() {
            setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, filterDays, venueId],
  );

  const openSpaceDetails = () => dispatch(setSpaceDetailsId(unitId));
  const onChangeHandler = () => {
    dispatch(setSelectedRoom({unit, venueId}));

    /**
     * get updated total price & unit type based on selected unit
     */
    getDetails(unitId);
  };

  return (
    <Item isMultiDay={isMultiDay}>
      <ImageWrapper>
        {coverImage?.url ? (
          <Image
            height={venueDetailsSpaceHeight}
            borderRadius={borderRadiusDef}
            src={coverImage?.url}
            alt={`${name} photo`}
          />
        ) : (
          <OfferUnitImage
            iconColor={whiteColor}
            bgColor={NW2Gray600Color}
            iconWidth='175px'
            spacing={`${offsetXXLg} 0`}
          />
        )}
      </ImageWrapper>

      <TextWrapper>
        <ItemTitle>
          {isAlternative ? (
            <TruncateText text={titleIsOffer} hideToggler />
          ) : (
            <StyledLink onClick={openSpaceDetails}>
              {name && (
                <>
                  {isMobile ? (
                    <CSSTruncatedText>{name}</CSSTruncatedText>
                  ) : (
                    <TruncateText text={name} hideToggler />
                  )}
                </>
              )}
            </StyledLink>
          )}
        </ItemTitle>
        <ItemSubTitle>
          <Group direction='column' gap={offsetXXSm}>
            <div>
              {isMultiDay ? (
                <TimeRangeText checkIn={checkIn} checkOut={checkOut} />
              ) : (
                bookingTypeText
              )}
            </div>

            {!!roomSetupText && (
              <div>
                <span>Room setup:&nbsp;</span>
                {roomSetupText}
              </div>
            )}
            <div>
              {!!requestedCapacity &&
                `Participants: ${pluralize('people', requestedCapacity)}`}
              {!!capacityBySetup && ` (${capacityBySetup} max)`}
            </div>
            {isAlternative ? (
              <StyledDiv>Room details provided on request</StyledDiv>
            ) : (
              <div>
                {unitDimensions && (
                  <>
                    Size: {matchNumberToSecondDecimal(unitDimensions?.area)}{' '}
                    square metres /{' '}
                    {String(
                      dimensionsConverter('m', unitDimensions?.area, true, 0),
                    )}{' '}
                    square feet
                  </>
                )}
              </div>
            )}
            {/*{!isOffer && (isRoomCapacityLow || isNotAvailable) && (*/}
            {/*  <LowCapacityWrapper>*/}
            {/*    <LowCapacityIcon>*/}
            {/*      {isNotAvailable ? (*/}
            {/*        <NotAvailable />*/}
            {/*      ) : (*/}
            {/*        <Icon icon='TRIANGLE' size={12} />*/}
            {/*      )}*/}
            {/*    </LowCapacityIcon>*/}
            {/*    <LowCapacityText>*/}
            {/*      {isNotAvailable ? 'Booked' : 'Low capacity'}*/}
            {/*    </LowCapacityText>*/}
            {/*  </LowCapacityWrapper>*/}
            {/*)}*/}
          </Group>

          {isMultiDay && !isAlternative && (
            <StyledIcon
              icon={iconsObject[roomSetupIcon]}
              size={36}
              transparent
            />
          )}
        </ItemSubTitle>
        {!isMultiDay && !isAlternative && (
          <PriceWrapper>
            {!isOffice && !!unitPrice && (
              <PricesBlock>
                <BestPrice>
                  <PriceDisplay price={unitPrice} currency={currency} />
                </BestPrice>{' '}
                / per stay
              </PricesBlock>
            )}
            {!isSelectUnitHidden && (
              <ButtonWrapper>
                <RadioButtonSelect
                  key={radioButtonValue}
                  isActive={isActive}
                  isDisabled={isLoading}
                >
                  <input
                    type='radio'
                    name='space'
                    id={radioButtonValue}
                    value={radioButtonValue}
                    onChange={onChangeHandler}
                    checked={isActive}
                    disabled={isLoading}
                  />
                  <label htmlFor={radioButtonValue}>
                    <SelectButton isActive={isActive} isDisabled={isLoading}>
                      {isLoading
                        ? 'Loading...' // !isNotAvailable && isActive && (
                        : //   <Icon transparent icon='CHECK' />
                        // )
                        isActive
                        ? 'Selected'
                        : 'Select'}
                    </SelectButton>
                  </label>
                </RadioButtonSelect>
              </ButtonWrapper>
            )}
          </PriceWrapper>
        )}
      </TextWrapper>
    </Item>
  );
}

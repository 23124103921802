import {DateTime} from 'luxon';

import {IDay} from '../types/dto/IDay.type';
import {IDayOfWeek} from '../types/dto/IDayOfWeek.type';
import {TDateType} from 'types/dto/ISearch.types';
import {ELocale} from 'types/locale';

//https://moment.github.io/luxon/#/formatting?id=table-of-tokens - Luxon date and time formats

export enum DateFormats {
  '01 Jan, 2023' = 'dd MMM, yyyy',
  '1 Jan, 2023' = 'd MMM, yyyy',
  '01 Jan 2023' = 'dd MMM yyyy',
  '(Wed)' = '(ccc)',
  '22:50' = 'HH:mm',
  '1 Jan 2023' = 'd MMM, yyyy',
  '31.12.2000' = 'dd.MM.yyyy',
  '22/05/1997' = 'dd/LL/yy',
  "22/05/1997 'at' 22:22 (+02:00)" = "d/LL/yy 'at' T (ZZZZ)",
  "01 Jan, 2023 'at' 22:22 (+02:00)" = "dd MMM, yyyy 'at' T (ZZZZ)",
  '22:50 (EST)' = 'T (ZZZZ)',
  '1 Jan' = 'd MMM',
}

type TDate = string | Date | null;

/**
 * Returns the ISO 8601 string representation of a Date or DateTime object.
 * @param {DateTime | Date | null} date - A Date or DateTime object or null.
 * @returns {string} The ISO string representation of the Date or DateTime object, or an empty string if
 * Luxon toISO() method returns null or the param is null.
 *
 * @example
 * getISOString(new Date('2022-01-01T00:00:00Z')); // => '2022-01-01T00:00:00.000Z'
 * getISOString(DateTime.fromISO('2022-01-01T00:00:00.000Z')); // => '2022-01-01T00:00:00.000Z'
 * getISOString(null); // => ''
 * getISOString('invalid input'); // => ''
 */
export const getISOString = (
  date: DateTime | Date | string | null | undefined,
): string => {
  if (!date) return '';

  if (typeof date === 'string') return date;

  if ('toISO' in date) {
    return date.toISO() || '';
  }

  if (typeof date === 'object') {
    return DateTime.fromJSDate(date).toISO() || '';
  }
  return '';
};

export const getDateTime = (date?: string | Date) => {
  if (typeof date === 'string') {
    return DateTime.fromISO(date, {setZone: true});
  }

  if (typeof date === 'object') {
    return DateTime.fromJSDate(date);
  }

  return DateTime.now();
};

export const getLocaleString = (date: string | Date, locale = ELocale.EN_UK) =>
  getDateTime(date).toLocaleString(DateTime.TIME_SIMPLE, {
    locale,
  });

export const getRoomTimeRangeText = (
  timeStart: string | Date,
  timeEnd: string | Date,
  locale = ELocale.EN_UK,
) =>
  `${getLocaleString(timeStart, locale)} - ${getLocaleString(timeEnd, locale)}`;

export const getTimeString = (date: DateTime, locale: ELocale) => {
  const timeZone = date.toFormat('(ZZZZ)', {
    locale: 'default',
  });

  return (
    date.toLocaleString(DateTime.TIME_SIMPLE, {
      locale,
    }) + ` ${timeZone}`
  );
};

const DateUtils = {
  getDateWithoutHours: (date?: TDate, format = DateFormats['01 Jan, 2023']) => {
    if (!date) return '';

    return getDateTime(date).toFormat(format);
  },
  getDateWithoutHoursFromBE: (
    date?: string,
    format = DateFormats['01 Jan, 2023'],
  ): string => {
    return date ? DateTime.fromISO(date, {setZone: true}).toFormat(format) : '';
  },
  getHoursAndMinutes: (date: TDate, format = 'HH:mm') => {
    //TODO: replace with getDateWithoutHours, and rename getDateWithoutHours accordingly
    if (!date) return '';

    return getDateTime(date).toFormat(format);
  },
  // 21st of May, 10:50 or 22nd of April, 2023
  getFormattedDayMonthTime: ({
    date,
    venueZone,
    showYear,
    showDateOnly,
    locale = ELocale.EN_UK,
  }: {
    date: string | Date | TDateType | undefined;
    venueZone?: string;
    showYear?: boolean;
    showDateOnly?: boolean;
    locale?: ELocale;
  }) => {
    if (!date) return '';
    const zonedDate = DateTime.fromISO(date as string, {
      zone: venueZone || 'local',
    });

    const day = zonedDate.toFormat('d');
    const month = zonedDate.toFormat('MMMM');
    const year = zonedDate.toFormat('yyyy');
    const time = zonedDate.toFormat('HH:mm');
    const zone = zonedDate.toFormat('ZZZZ', {
      locale: ELocale.EN_UK,
    });

    const getDayEnding = () => {
      const lastSymbol = day.at(-1);
      switch (lastSymbol) {
        case '1':
          return 'st';
        case '2':
          return 'nd';
        case '3':
          return 'rd';
        default:
          return `th`;
      }
    };

    const ending = getDayEnding();

    const yearAndTimeAndZone = showDateOnly
      ? ''
      : showYear
      ? ', ' + year
      : `, ${getLocaleString(time, locale)} (${zone})`;

    switch (day) {
      case '11':
      case '12':
      case '13':
        return `${day}th of ${month}${yearAndTimeAndZone}`;
      default:
        return `${day}${ending} of ${month}${yearAndTimeAndZone}`;
    }
  },
  getHoursAndMinutesFromBE: (date?: string) => {
    //TODO: replace with getDateWithoutHoursFromBE, and rename getDateWithoutHoursFromBE accordingly
    return date
      ? DateTime.fromISO(date, {setZone: true}).toFormat('HH:mm')
      : '';
  },
  getTotalHours: (
    checkIn: string | TDateType,
    checkOut: string | TDateType,
  ): number => {
    if (!checkOut || !checkIn) return 0;
    return getDateTime(checkOut).diff(getDateTime(checkIn), 'hours').hours;
  },
  getBookedIDay: (operationalTimes: IDay[], checkIn: string) => {
    const dayOfWeek = DateTime.fromISO(checkIn).day;

    return operationalTimes.find(
      (day) => day.dayOfWeek === IDayOfWeek[dayOfWeek],
    );
  },
  normalizeDateToBackendFormat: (date: string | TDateType): string => {
    if (!date) return '';
    if (!getDateTime(date).isValid) return String(date);

    return getISOString(getDateTime(date));
  },
  checkIsCurrentDayToday: (day: string) => {
    const todayWeekDay = DateTime.now()
      .setLocale('en-US')
      .weekdayLong?.toUpperCase();

    return day.toUpperCase() === todayWeekDay;
  },
  setDateSupplierBookings: (date: string) => {
    const dateTime = DateTime.fromISO(date);
    const relativeDate = dateTime.toRelativeCalendar({locale: 'en-GB'});

    const object = {
      today: 'Today',
      tomorrow: 'Tomorrow',
    };

    return (
      object[relativeDate as keyof typeof object] ??
      dateTime.toFormat(DateFormats['22/05/1997'])
    );
  },
  isSameDay(firstDate?: string | TDateType, secondDate?: string | TDateType) {
    if (!firstDate || !secondDate) return false;

    return getDateTime(firstDate)
      .startOf('day')
      .equals(getDateTime(secondDate).startOf('day'));
  },
  isSameMomentInTime(
    firstDate: string | TDateType,
    secondDate: string | TDateType,
  ) {
    if (!firstDate || !secondDate) return false;

    const dateFirst = getDateTime(firstDate).toFormat('x');
    const dateSecond = getDateTime(secondDate).toFormat('x');

    return dateFirst === dateSecond;
  },
  convertMinutesToHours(minutes: number): string {
    if (minutes < 60) return `${Math.round(minutes)} min`;
    const h = Math.floor(minutes / 60);
    const m = Math.floor(minutes % 60);
    return `${h} h ${m} min`;
  },
  getDifferenceInDaysAndHours(
    startTime: string | Date,
    endTime: string | Date,
  ) {
    const start = getDateTime(startTime);
    const end = getDateTime(endTime);

    return end.diff(start, ['days', 'hours', 'minutes']).toObject();
  },
  isBefore(startTime?: TDate, endTime?: TDate) {
    if (!startTime || !endTime) return false;

    const start = getDateTime(startTime);
    const end = getDateTime(endTime);

    return start < end;
  },
  isAfter(startTime?: string | Date, endTime?: string | Date): boolean {
    if (!startTime || !endTime) return false;

    const start = getDateTime(startTime);
    const end = getDateTime(endTime);

    return start > end;
  },
  getISOFromDate(date: Date) {
    return getISOString(DateTime.fromJSDate(date));
  },
  getDateFromISO(date: string) {
    return DateTime.fromISO(date).toJSDate();
  },
  convertDateToLocal(date: string) {
    return getISOString(DateTime.fromISO(date, {zone: 'utc'}).setZone('local'));
  },
  getFormattedDateTime(
    date: string | undefined,
    timeZone: string,
    pattern: DateFormats,
    locale?: ELocale,
  ) {
    if (!date) return '';

    const dateWithZone = DateTime.fromISO(date).setZone(timeZone);

    if (locale) {
      return (
        dateWithZone.toFormat(pattern) +
        ' at ' +
        getTimeString(dateWithZone, locale)
      );
    }

    return dateWithZone.toFormat(pattern);
  },
  // 1 Jan to 2 Jan, 2023
  getDatePeriod(date: string, days: number) {
    if (!date) return '';
    const time = getDateTime(date);
    const firstDay = time.toFormat(DateFormats['1 Jan']);
    const secondDay = time.plus({days}).toFormat(DateFormats['1 Jan, 2023']);
    return `${firstDay} to ${secondDay}`;
  },
  getBedroomDateRange(checkInDate: string) {
    const todayDate = getDateTime(checkInDate).toFormat('d LLL');
    const nextDate = getDateTime(checkInDate)
      .plus({days: 1})
      .toFormat('d LLL, yyyy');

    return `${todayDate} to ${nextDate}`;
  },
  findMinMaxTime<T extends Record<string, any>>(
    array: T[],
    field: keyof T,
    isMax = true,
  ) {
    if (!array.length) return '';

    const result = array.reduce((acc, curr) => {
      const currTime = DateTime.fromISO(curr[field]);
      const accTime = DateTime.fromISO(acc[field]);

      if (isMax ? currTime > accTime : currTime < accTime) {
        return curr;
      } else {
        return acc;
      }
    }, array[0]);

    return result[field] as string;
  },
  addDays(date: Date | string, days: number) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  },
};

export default DateUtils;

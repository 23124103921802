import {AppDispatch} from '../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  setEndDateForVenuesSearch,
  setMultiSearchTimeData,
  setStartDateForVenuesSearch,
} from './searchSlice';
import {
  getDefaultCheckIn,
  getDefaultCheckOut,
  getDefaultTimeData,
} from 'constants/defaultOperationalTimes';

export const resetStoreDates =
  (roomType: ERoomType) => (dispatch: AppDispatch) => {
    const defaultCheckIn = getDefaultCheckIn(roomType);
    const defaultCheckOut = getDefaultCheckOut(roomType);

    dispatch(setStartDateForVenuesSearch(defaultCheckIn));
    dispatch(setEndDateForVenuesSearch(defaultCheckOut));
    dispatch(setMultiSearchTimeData([getDefaultTimeData(defaultCheckIn)]));
  };

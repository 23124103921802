import React from 'react';
import {IBooking} from 'types/dto/IBooking.types';
import TextValue from 'view/components/TextValue';
import DateUtils from 'utils/dateUtils';

export function DurationColumn(text: string, row: IBooking, index: number) {
  const duration = DateUtils.getTotalHours(row.checkInDate, row.checkOutDate);
  return (
    <div key={index}>
      {DateUtils.getHoursAndMinutesFromBE(row.checkInDate)} -
      {DateUtils.getHoursAndMinutesFromBE(row.checkOutDate)}
      <TextValue secondary size='small'>
        ({duration} Hour{Number(duration) > 1 && 's'})
      </TextValue>
    </div>
  );
}

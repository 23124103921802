import React from 'react';
import Icon from 'view/components/Icon';

import {
  StyledLine,
  StyledNewChange,
  StyledPrevChange,
} from '../BookingChangeOverviewSidebar.styles';

type TProps = {
  previousValue?: string;
  newValue?: string;
};

const BookingChangeSidebarBodyInfoChanges = ({
  previousValue,
  newValue,
}: TProps) => (
  <StyledLine>
    <StyledPrevChange>{previousValue || '-'}</StyledPrevChange>
    <Icon justify='center' disabledColor icon='NW2_ARROW_RIGHT_THIN' />
    <StyledNewChange>{newValue || '-'}</StyledNewChange>
  </StyledLine>
);

export default BookingChangeSidebarBodyInfoChanges;

import React, {useCallback} from 'react';

import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {ModalText} from '../../supplier/Bookings/BookingListRooms/Requests/styles';
import {offsetDef} from 'constants/styleVars';
import {EDeclineItemType} from 'types/offer';

interface IProps {
  orderId: string;
  handleDecline: <TPayload>(
    payload: TPayload,
    declineItem: EDeclineItemType,
  ) => void;
  handleCancel: () => void;
  isSending: boolean;
  declineItem: EDeclineItemType;
  submitLabel?: string;
  cancelLabel?: string;
}

export function RequestDeclineView({
  orderId,
  handleDecline,
  handleCancel,
  isSending,
  submitLabel = 'decline',
  cancelLabel = 'cancel',
  declineItem,
}: IProps) {
  const onSubmit = useCallback(() => {
    handleDecline(orderId, declineItem);
  }, [declineItem, handleDecline, orderId]);

  return (
    <>
      <ModalText>
        You are about to cancel your request. Confirming this cancellation will
        automatically decline/cancel any pending offer/request. All of the
        requested venues will be notified.
        <br />
        <b>This action is irreversible.</b>
      </ModalText>

      <NMMSubmitSection
        submitLabel={submitLabel}
        cancelLabel={cancelLabel}
        handleSubmit={onSubmit}
        handleCancel={handleCancel}
        isLoading={isSending}
        gap={offsetDef}
        justifyContent='flex-end'
        margin='48px 0 0 0'
      />
    </>
  );
}

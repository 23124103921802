import React from 'react';
import styled from 'styled-components';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {
  fontSizeMd,
  lineHeightLg,
  NW2Gray900Color,
  offsetXLg,
} from 'constants/styleVars';

export const FormGroup = styled.div<{columnNumber: number; gap: number}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => gap}px;

  > div {
    margin: 0;
  }
`;

export const Name = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
`;

interface IProps {
  eventName?: string;
  adaptiveColumnNumber?: number;
  gap?: number;
}

const AboutMeetingForm = ({gap = parseInt(offsetXLg), eventName}: IProps) => {
  return eventName ? (
    <Name>{eventName}</Name>
  ) : (
    <FormGroup columnNumber={1} gap={gap}>
      <NW2FormItemInput
        type='text'
        name='aboutMeeting'
        placeholder='Your meeting name'
      />
    </FormGroup>
  );
};

export default AboutMeetingForm;

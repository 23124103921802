export enum EBookingStatus {
  CONFIRMED = 'CONFIRMED',
  PASSED = 'PASSED',
  CANCELED = 'CANCELED',
  RFP_PENDING = 'RFP_PENDING',
  RFP_DECLINED = 'RFP_DECLINED',
  RFP_CANCELLED = 'RFP_CANCELLED',
  RFP_EXPIRED = 'RFP_EXPIRED',
  PENDING_AUTHORISATION = 'PENDING_AUTHORISATION',
  EDIT_CANCELED_BY_CONFIRM = 'EDIT_CANCELED_BY_CONFIRM', // increase edit mode confirmed by supplier
  EDIT_MODE_CANCELED = 'EDIT_MODE_CANCELED', // decrease edit mode
  RTC_PENDING = 'RTC_PENDING',
  RTC_DECLINED = 'RTC_DECLINED',
  RTC_CANCELLED = 'RTC_CANCELLED',
  RTC_EXPIRED = 'RTC_EXPIRED',
}

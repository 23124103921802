import React, {FC} from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import TextValue from '../TextValue';

import {offsetXSm} from 'constants/styleVars';
import {EMPTY_FUNC} from 'constants/app';

type Props = {
  text?: string;
  icon?: string;
  onClick?: () => void;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  warn?: boolean;
  lightText?: boolean;
  className?: string;
  iconPosition?: 'prefix' | 'postfix';
};

const StyledBackIconContainer = styled(TextValue)`
  display: flex;
  align-items: center;
  margin-bottom: ${offsetXSm};
  cursor: pointer;
  white-space: nowrap;
`;

const PrefixIcon = styled(Icon)`
  margin-right: ${offsetXSm};
  cursor: pointer;

  & svg {
    cursor: pointer;
  }
`;

const PostfixIcon = styled(PrefixIcon)`
  margin-right: 0;
  margin-left: ${offsetXSm};
`;

const NWLink: FC<Props> = ({
  text = 'Back',
  icon = 'GO_BACK',
  onClick = EMPTY_FUNC,
  iconPosition = 'prefix',
  className, // it is needed by styled-components!
  ...rest
}: Props) => {
  return (
    <StyledBackIconContainer
      {...rest}
      id='backLink'
      onClick={onClick}
      className={className}
    >
      {iconPosition === 'prefix' ? <PrefixIcon icon={icon} {...rest} /> : null}
      <span>{text}</span>
      {iconPosition === 'postfix' ? (
        <PostfixIcon icon={icon} {...rest} />
      ) : null}
    </StyledBackIconContainer>
  );
};

export default NWLink;

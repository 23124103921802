import React from 'react';

import {useAppSelector} from 'store/hooks';
import {getRoomTimeRangeText} from 'utils/dateUtils';

interface IProps {
  checkIn: string;
  checkOut: string;
  hasBrackets?: boolean;
}
function TimeRangeText({checkIn, checkOut, hasBrackets}: IProps) {
  const locale = useAppSelector(({app}) => app.locale);

  const timeRange = getRoomTimeRangeText(checkIn, checkOut, locale);

  return <>{hasBrackets ? `(${timeRange})` : timeRange}</>;
}

export default TimeRangeText;

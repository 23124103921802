import {useMemo} from 'react';

import {pluralize} from 'utils/helpers';
import {TPaidDuration} from '../types';

const MAX_HOURS_DURATION = 6;

export function usePaidDurationOptions(): TPaidDuration[] {
  return useMemo(
    () =>
      Array.from({length: MAX_HOURS_DURATION}).map((hour, idx) => {
        const value = pluralize('hour', idx + 1);

        return {
          key: value,
          value,
          text: value,
        };
      }),
    [],
  );
}

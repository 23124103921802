import styled, {createGlobalStyle} from 'styled-components';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';

import {
  blackColor,
  mdBp,
  navbarZIndex,
  NW2Primary,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';
import {ModalTitle} from 'view/components/NW2ModalDrawer/NW2ModalDrawer.styles';

export const Wrapper = styled.div`
  display: inline-flex;

  @media (min-width: ${mdBp}px) {
    border-right: 1px solid ${whiteColor};
    padding-right: ${offsetXLg};
  }
`;

export const StyledDrawer = styled(StyledAntdDrawer)`
  z-index: ${navbarZIndex - 1};
`;

export const TriggerItemBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
  color: ${whiteColor};
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;

  @media (min-width: ${mdBp}px) {
    width: 242px;
  }
`;

export const ListItem = styled.li<{isActive: boolean}>`
  display: flex;
  align-items: center;
  gap: ${offsetSm};
  padding: ${offsetDef} ${offsetSm};
  background-color: ${({isActive}) => isActive && NW2Primary};
  color: ${blackColor};
`;

export const DrawerTitle = styled(ModalTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const PopoverText = styled.div`
  margin-bottom: ${offsetSm};
`;

export const PopoverActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PopoverGlobalStyle = createGlobalStyle`
  .locale-popover {
    .ant-popover {
      @media (min-width: ${mdBp}px) {
        &-content {
          transform: translateX(2px);
        }   
      }

      @media (max-width: ${mdBp - 1}px) {
        &-arrow {
          right: 20px;
          top: 1px
        }
      }
    }
  }
`;

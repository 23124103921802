import styled from 'styled-components';

import {NW2Container} from 'view/mainLanding/MainLanding.styles';

import {
  whiteColor,
  sectionBoxShadow,
  NW2Gray200Color,
  NW2Gray900Color,
  NW2Gray800Color,
  offsetXLg,
  offsetXXXSm,
  offsetXSm,
  fontSizeXSm,
  lineHeightSm,
  fontWeightBold,
  fontSizeXXLg,
  offerReviewFooterZIndex,
  lgBp,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${whiteColor};
  box-shadow: ${sectionBoxShadow};
  color: ${NW2Gray900Color};
  z-index: ${offerReviewFooterZIndex};
`;

export const StyledContainer = styled(NW2Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
`;

export const TimeBox = styled.div`
  display: flex;
  gap: ${offsetXLg};
`;

export const TimeLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXXXSm};
`;

export const TimeLeftTitle = styled.span`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
`;

export const TimeLeftText = styled.span`
  font-size: ${fontSizeXXLg};
  line-height: 30px;
  font-weight: ${fontWeightBold};
`;

export const ReceivedOn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid ${NW2Gray200Color};
  padding-left: ${offsetXLg};

  @media (max-width: ${lgBp - 1}px) {
    display: none;
  }
`;

export const ReceivedOnRow = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 120px;
  grid-column-gap: ${offsetXSm};

  b {
    color: ${NW2Gray800Color};
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: ${offsetXLg};
`;

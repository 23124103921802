import React, {ChangeEvent, FC, useEffect} from 'react';
import {Form, Input, Button, FormInstance} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import {
  COMPANY_NAME_FIELD_NAME,
  COMPANY_ID_FIELD_NAME,
  EMAIL_DOMAINS_FIELD_NAME,
} from 'constants/admin';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getImportedCompany} from 'store/admin/apiActions';

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
};

const buttonWrapperCol = {span: 24};

type Props = {
  form: FormInstance;
  isEdit?: boolean;
  setFormId?: (value: number) => void;
};

const initialValue = ['']; // initially show one email_domain field

const AdminAddCompany: FC<Props> = ({form, isEdit, setFormId}) => {
  const dispatch = useAppDispatch();

  const importedCompanyName = useAppSelector(
    ({admin}) => admin.importedCompany?.name,
  );
  const companyName = useAppSelector(
    ({admin}) => admin.corporateAccountCompany?.name,
  );
  const onCompanyIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof setFormId === 'function') {
      setFormId(Number(e.target.value));
    }
  };

  const importCompany = () => {
    const companyId = form.getFieldsValue()[COMPANY_ID_FIELD_NAME];
    if (companyId) {
      dispatch(getImportedCompany(Number(companyId)));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      [COMPANY_NAME_FIELD_NAME]: importedCompanyName || companyName,
    });
  }, [form, importedCompanyName, companyName]);

  return (
    <Form autoComplete='off' form={form} preserve={false} {...layout}>
      <Form.Item
        label='Company ID'
        name={COMPANY_ID_FIELD_NAME}
        rules={[{required: true, message: 'Please input company ID!'}]}
      >
        <Input.Search
          placeholder='Company ID'
          type='number'
          min={1}
          onChange={onCompanyIdChange}
          onSearch={importCompany}
          disabled={isEdit}
        />
      </Form.Item>

      <Form.Item
        label='Company name'
        name={COMPANY_NAME_FIELD_NAME}
        rules={[{required: true, message: 'Please input company full name!'}]}
      >
        <Input placeholder='Full name of the company' allowClear disabled />
      </Form.Item>

      <Form.List name={EMAIL_DOMAINS_FIELD_NAME} initialValue={initialValue}>
        {(fields, {add, remove}) => (
          <>
            {fields.map(({key, name, ...restField}, index) => {
              const addonAfter =
                fields.length > 1 ? (
                  // @ts-ignore
                  <MinusCircleOutlined onClick={() => remove(name)} />
                ) : null;
              return (
                <Form.Item
                  {...restField}
                  name={name}
                  label={`Email domain ${index + 1}`}
                  rules={[{message: 'Please input email domain!'}]}
                  key={key}
                  initialValue=''
                >
                  <Input
                    allowClear
                    placeholder='domain.com'
                    addonAfter={addonAfter}
                  />
                </Form.Item>
              );
            })}

            <Form.Item wrapperCol={buttonWrapperCol}>
              <Button
                type='dashed'
                onClick={() => add()} // 'add' should be called from inline function! Otherwise it won't work! This is ant design.
                block
                // @ts-ignore
                icon={<PlusOutlined />}
              >
                Add email domain
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default React.memo(AdminAddCompany);

import React from 'react';

import ContactUsBlock from '../../components/ContactUsBlock';

import {
  Content,
  Section,
  Title,
  Paragraph,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';

// TODO: Here is old text. Check and change content if needed
const TextContent = () => {
  return (
    <Content>
      <Section>
        <Title>What is HRS NewWork?</Title>
        <Paragraph>
          HRS NewWork is a Search and Book application that offers work desks in
          hotels and co-working spaces.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I search for workspace?</Title>
        <Paragraph>
          Go to Search Venue page and start searching for your required
          destination, date and the period of usage. You can decide between
          daily and hourly bookings.
        </Paragraph>
      </Section>

      <ContactUsBlock />
    </Content>
  );
};

export default TextContent;

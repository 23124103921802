import React, {useEffect} from 'react';
import {notification} from 'antd';

import {useNW2Upload} from './useNW2Upload';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import {THandleUpdateImages} from '../../types';
import {UploadMultipleWrapper} from './NW2Upload.styles';
import {NW2UploadProgress} from './NW2UploadProgress';

interface IProps {
  onImageUpload: (file: TImageFile) => void;
  onUpdateVenueImages: THandleUpdateImages;
}

export function NW2UploadMultiple({
  onImageUpload,
  onUpdateVenueImages,
}: IProps) {
  const {isUploading, handleUploading, files, progress, uploadComponent} =
    useNW2Upload({
      isMultiple: true,
      label: 'Upload more images',
      onImageUpload,
      onUpdateVenueImages,
    });

  useEffect(() => {
    if (isUploading && progress === 100) {
      handleUploading(false);

      notification.success({
        message: 'Your images have now been successfully uploaded.',
        duration: 4,
      });
    }
  }, [handleUploading, isUploading, progress]);

  return (
    <>
      <UploadMultipleWrapper>{uploadComponent}</UploadMultipleWrapper>

      {files.length > 0 && (
        <NW2UploadProgress
          progress={progress}
          title={`${files.length} files being uploaded`}
        />
      )}
    </>
  );
}

import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import queryString from 'query-string';

import DatePickerSection from './components/DatePickerSection';
import TimeRangePickerSection from './components/TimeRangePickerSection';
import ParticipantsSection from './components/ParticipantsSection';
import ButtonsSection from './components/ButtonsSection';
import MultiRoomRequirementsSection from './components/MultiRoomRequirementsSection';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useQuery} from 'hooks/useQuery';
import {IVenueDetailsData} from 'store/venues/types';
import {
  NW2DatePicker,
  NW2DatepickerMobile,
  useDatePicker,
} from 'view/components/NW2Datepicker';
import {
  maximumParticipantsNumber,
  minimumParticipantsNumber,
} from 'constants/app';
import {TDateType} from 'types/dto/ISearch.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  setInitialLocation,
  setSearchCriteriaMeetingRoomCapacity,
} from 'store/search/searchSlice';
import {
  setDatepickerDetailsShowed,
  setDetailsDatepickerMobileShowed,
} from 'store/venues/venuesSlice';
import {getDateStringFromTime} from 'utils/stringUtils';
import DateUtils from 'utils/dateUtils';
import {
  restrictNoNumericString,
  restrictOutOfRangeAndFloat,
} from 'utils/helpers';
import {NW2Gray200Color} from 'constants/styleVars';
import {
  StyledColumnCalendar,
  StyledTimeApplyBtn,
  StyledTimeCancelBtn,
} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker/NW2SearchDatePicker.styles';
import {
  StyledWrapper,
  StyledWrapperMobile,
  StyledWrapperMobileHeader,
  StyledWrapperMobileMain,
  StyledWrapperMobileFooter,
  StyledWrapperMobileHeaderDot,
  StyledDrawer,
  StyledWrapperMobileMainTop,
} from './NW2DatepickerDetails.styles';
import {clearShortList} from 'store/offers/offersSlice';
import {useVenueDetailsId} from 'view/venue/hooks/useVenueDetailsId';
import {getVenueDetailsWorkDesksAction} from 'store/workDesks/apiActions';
import {ESearchInputsId} from 'types/search';

const NW2DatepickerDetails = () => {
  const isDetailsDatepickerMobileShowed = useAppSelector(
    ({venuesReducer}) => venuesReducer.isDetailsDatepickerMobileShowed,
  );
  const isDatepickerOpened: boolean = useAppSelector(
    ({venuesReducer}) => venuesReducer.isDetailsDatepickerShowed,
  );
  const selectedMeetingRoomSeatingPlan = useAppSelector(
    ({venuesReducer}) => venuesReducer.selectedMeetingRoomSeatingPlan,
  );
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const initialSearch = useAppSelector(({app}) => app.initialLocation.search);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);

  const {meetingRoomCapacity, roomType, startDate, endDate, multiRooms} =
    searchCriteria;

  const meetingRoomCapacityString = String(meetingRoomCapacity);

  const [venueDetailsId] = useVenueDetailsId();

  const [isChangeActivated, setChangeActivated] = useState(isDatepickerOpened);
  const [isPanelTouched, setPanelTouched] = useState(false);
  const [focusedInput, setFocusedInput] = useState('');
  const [isDatepickerMobileOpened, setDatepickerMobileOpened] = useState(false);
  const [participants, setParticipants] = useState(meetingRoomCapacityString);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const venuesContainerRef = useRef<HTMLDivElement>(null);

  const participantsFromUrl = query.meetingRoomCapacity;
  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;
  const inputBlockColor: string | undefined =
    isMobile || isChangeActivated ? undefined : NW2Gray200Color;

  const {
    date,
    timeStart,
    timeEnd,
    dateISOString,
    timeStartISOString,
    timeEndISOString,
    onDateChange,
    onTimeStartChange,
    onTimeEndChange,
    onChangeTimeComplete,
  } = useDatePicker();

  const drawerParentStyle = useMemo(() => {
    // fix antd multi-drawer https://github.com/ant-design/ant-design/issues/18781
    const style: React.CSSProperties = {position: 'fixed'};
    if (isDetailsDatepickerMobileShowed) {
      style.transform = undefined; // set to undefined here
    }
    return style;
  }, [isDetailsDatepickerMobileShowed]);

  // HANDLERS ----------------

  const onChangeModeToggle = useCallback(() => {
    // Uncomment if datepicker will need on multiRoom details page

    // if (multiRooms) return toggleModal();
    setChangeActivated(!isChangeActivated);
  }, [isChangeActivated]);

  const onInputFocus = useCallback(
    (inputId: string) => {
      if (!isChangeActivated && !isMobile) return;
      setFocusedInput(inputId);
    },
    [isChangeActivated, isMobile],
  );

  const onInputBlur = useCallback(() => {
    setFocusedInput('');
  }, []);

  const onParticipantsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const valueToSet = restrictNoNumericString(inputValue);
      setParticipants(valueToSet);
    },
    [],
  );

  const onParticipantsBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const valueToSet = restrictOutOfRangeAndFloat(
        inputValue,
        minimumParticipantsNumber,
        maximumParticipantsNumber,
      );
      setParticipants(valueToSet.toString());
      onInputBlur();
    },
    [onInputBlur],
  );

  const handleDateChange = useCallback(
    (val: TDateType) => {
      onDateChange(val);
      dispatch(setDatepickerDetailsShowed(false));
      setFocusedInput('');
    },
    [dispatch, onDateChange],
  );

  const onToggleDatePicker = useCallback(() => {
    if (isMobile) {
      setDatepickerMobileOpened(!isDatepickerMobileOpened);
    } else {
      if (!isChangeActivated) {
        return;
      }

      if (!isDatepickerOpened) {
        onInputFocus(ESearchInputsId.DATE_ID);
      } else {
        onInputBlur();
      }

      dispatch(setDatepickerDetailsShowed(!isDatepickerOpened));
    }
  }, [
    isMobile,
    isDatepickerMobileOpened,
    isChangeActivated,
    isDatepickerOpened,
    dispatch,
    onInputFocus,
    onInputBlur,
  ]);

  const handleCancel = useCallback(() => {
    if (isMobile) {
      dispatch(setDetailsDatepickerMobileShowed(false));
    } else {
      setChangeActivated(false);
    }
  }, [dispatch, isMobile]);

  const handleUpdate = useCallback(() => {
    // set initialLocation in redux store
    if (!initialSearch) {
      dispatch(setInitialLocation({search: location.search}));
    }

    const startDate = DateUtils.normalizeDateToBackendFormat(
      getDateStringFromTime(timeStartISOString, dateISOString),
    );
    const endDate = DateUtils.normalizeDateToBackendFormat(
      getDateStringFromTime(timeEndISOString, dateISOString),
    );

    const data = {
      ...searchCriteria,
      startDate,
      endDate,
      meetingRoomCapacity: +participants || +meetingRoomCapacityString,
      seatingPlan: selectedMeetingRoomSeatingPlan,
    };

    // save to store
    onChangeTimeComplete();

    dispatch(
      getVenueDetailsWorkDesksAction({
        id: venueDetailsId,
        ...data,
      } as unknown as IVenueDetailsData),
    );

    // update url search params
    navigate({
      search: queryString.stringify({
        ...data,
        meetingRoomCapacity: participants || meetingRoomCapacityString,
      }),
    });

    dispatch(setSearchCriteriaMeetingRoomCapacity(data.meetingRoomCapacity));

    // reset shortlist if some params changed
    dispatch(clearShortList());

    setPanelTouched(false);

    if (isMobile) {
      dispatch(setDetailsDatepickerMobileShowed(false));
      window.scrollTo({
        top: venuesContainerRef.current
          ? window.scrollY +
            venuesContainerRef.current.getBoundingClientRect().top
          : 0,
        behavior: 'smooth',
      });
    } else {
      setChangeActivated(false);
    }
  }, [
    initialSearch,
    timeStartISOString,
    dateISOString,
    timeEndISOString,
    searchCriteria,
    participants,
    meetingRoomCapacityString,
    selectedMeetingRoomSeatingPlan,
    onChangeTimeComplete,
    dispatch,
    venueDetailsId,
    navigate,
    isMobile,
    location.search,
  ]);

  const onDetailsDatepickerDrawerClose = useCallback(() => {
    dispatch(setDetailsDatepickerMobileShowed(false));
  }, [dispatch]);

  const onClickMobileDataPiker = () => {
    onDateChange(new Date(startDate));
    onToggleDatePicker();
  };

  // EFFECTS ----------------

  useEffect(() => {
    setParticipants(meetingRoomCapacityString);
  }, [meetingRoomCapacityString]);

  useEffect(() => {
    // handle if some field changed
    const isDateEqual: boolean =
      date?.toDateString() === new Date(startDate).toDateString();

    const isTimeStartEqual: boolean =
      timeStart?.toTimeString() === new Date(startDate).toTimeString();

    const isTimeEndEqual: boolean =
      timeEnd?.toTimeString() === new Date(endDate).toTimeString();

    const isRoomCapacityEqual: boolean = participants === participantsFromUrl;

    if (
      !isDateEqual ||
      !isTimeStartEqual ||
      !isTimeEndEqual ||
      !isRoomCapacityEqual
    ) {
      setPanelTouched(true);
    } else {
      setPanelTouched(false);
    }
  }, [
    participantsFromUrl,
    date,
    timeStart,
    timeEnd,
    participants,
    startDate,
    endDate,
    meetingRoomCapacityString,
  ]);

  // SECTIONS ----------------

  const datePickerSection = (
    <DatePickerSection
      date={date}
      multiRooms={multiRooms}
      isMobile={isMobile}
      isChangeActivated={isChangeActivated}
      inputBlockColor={inputBlockColor}
      focusedInput={focusedInput}
      isDatepickerOpened={isDatepickerOpened}
      handleDateChange={handleDateChange}
      onToggleDatePicker={onToggleDatePicker}
    />
  );

  const timeRangePickerSection = !multiRooms && (
    <TimeRangePickerSection
      isMobile={isMobile}
      isChangeActivated={isChangeActivated}
      timeStart={timeStart}
      timeEnd={timeEnd}
      focusedInput={focusedInput}
      inputBlockColor={inputBlockColor}
      onInputFocus={onInputFocus}
      onInputBlur={onInputBlur}
      onTimeStartChange={onTimeStartChange}
      onTimeEndChange={onTimeEndChange}
    />
  );

  const participantsSection =
    isMeetingRoom && !multiRooms ? (
      <ParticipantsSection
        isMobile={isMobile}
        isChangeActivated={isChangeActivated}
        participants={participants}
        inputBlockColor={inputBlockColor}
        focusedInput={focusedInput}
        onInputFocus={onInputFocus}
        onParticipantsBlur={onParticipantsBlur}
        onParticipantsChange={onParticipantsChange}
      />
    ) : null;

  const buttonsSection = (
    <ButtonsSection
      isMobile={isMobile}
      isChangeActivated={isChangeActivated}
      isPanelTouched={isPanelTouched}
      handleCancel={handleCancel}
      handleUpdate={handleUpdate}
      onChangeModeToggle={onChangeModeToggle}
    />
  );

  const datepickerMobileActions = (
    <>
      <StyledTimeCancelBtn type='button' onClick={onClickMobileDataPiker}>
        cancel
      </StyledTimeCancelBtn>

      <StyledTimeApplyBtn
        buttonType='primary'
        fullWidth={false}
        onClick={onToggleDatePicker}
        size='small'
      >
        save
      </StyledTimeApplyBtn>
    </>
  );

  return (
    <>
      {/*There is no trigger for open Drawer on mobile now, should be asked to designers for interface such as button*/}

      {isMobile ? (
        <StyledDrawer
          title={null}
          placement='top'
          height='auto'
          closable={false}
          onClose={onDetailsDatepickerDrawerClose}
          open={isDetailsDatepickerMobileShowed}
          style={drawerParentStyle}
        >
          <StyledWrapperMobile>
            <StyledWrapperMobileHeader>
              WORK <StyledWrapperMobileHeaderDot />
            </StyledWrapperMobileHeader>
            <StyledWrapperMobileMain>
              <StyledWrapperMobileMainTop>
                {datePickerSection}
                {participantsSection}
              </StyledWrapperMobileMainTop>

              <div>{timeRangePickerSection}</div>
            </StyledWrapperMobileMain>

            <StyledWrapperMobileFooter>
              {buttonsSection}
            </StyledWrapperMobileFooter>

            <NW2DatepickerMobile
              date={date}
              isOpened={isDatepickerMobileOpened}
              dateSection={
                <StyledColumnCalendar>
                  <NW2DatePicker date={date} onChange={handleDateChange} />
                </StyledColumnCalendar>
              }
              actions={datepickerMobileActions}
              onToggleDatePicker={onToggleDatePicker}
            />
          </StyledWrapperMobile>
        </StyledDrawer>
      ) : (
        <StyledWrapper inverted={isChangeActivated}>
          {datePickerSection}

          <MultiRoomRequirementsSection />

          {timeRangePickerSection}

          {participantsSection}

          {buttonsSection}
        </StyledWrapper>
      )}
    </>
  );
};

export default NW2DatepickerDetails;

import styled from 'styled-components';

import {offsetXLg, whiteColor} from 'constants/styleVars';

const SIDEBAR_WIDTH = '240px';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${SIDEBAR_WIDTH} 1fr;
  background-color: ${whiteColor};
  width: 100%;
  height: 100%;
`;

// anchors
export const AnchorsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr ${SIDEBAR_WIDTH};
  padding: 0 0 0 ${offsetXLg};
  grid-column-gap: ${offsetXLg};
`;

export const AnchorSections = styled.div``;

//loader
export const LoaderContainer = styled.div`
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

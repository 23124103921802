import React from 'react';

import {TNW2AccordionItem} from '../types';

import useToggle from 'hooks/useToggle';
import {
  Container,
  TitleContainer,
  SubTitle,
  Content,
  Title,
  Divider,
  StyledIcon,
  Header,
} from './NW2AccordionItem.styles';

const NW2AccordionItem = ({
  title,
  subTitle,
  expandable = true,
  initiallyExpanded = true,
  isDivider = true,
  icon = 'ARROW_UP_FILLED',
  content,
  className,
}: TNW2AccordionItem) => {
  const [expanded, toggleExpanded] = useToggle(initiallyExpanded);

  return (
    <Container isDivider={isDivider} className={className}>
      <Header>
        <TitleContainer>
          <Title>{title}</Title>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </TitleContainer>
        {expandable && (
          <StyledIcon
            icon={icon}
            expanded={expanded}
            onClick={toggleExpanded}
            transparent
          />
        )}
      </Header>
      {expanded && !!content && (
        <>
          {isDivider && <Divider />}
          <Content isDivider={isDivider}>{content}</Content>
        </>
      )}
    </Container>
  );
};

export default NW2AccordionItem;

import React, {memo} from 'react';

import {FormGroup} from './NW2SupplierContactsDetailsForm.styles';
import {
  NW2FormItemInput,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  notRequiredEmailFieldRules,
  notRequiredStringFieldRules,
} from 'utils/finalFormFieldRules';

import {ESupplierManagementContactsFormFields, IEditFormProps} from './types';
import {NW2Gray600Color, offsetDef} from 'constants/styleVars';

const ContactsEditForm = memo(
  ({
    disabledItems,
    gap = parseInt(offsetDef),
    isAdditionalContacts,
  }: IEditFormProps) => {
    return (
      <FormGroup columnNumber={1} gap={gap}>
        {!isAdditionalContacts && (
          <>
            <NW2FormItemInput
              type='text'
              name={ESupplierManagementContactsFormFields.FIRST_NAME}
              placeholder='First name'
              label='First name'
              labelColor={NW2Gray600Color}
              rules={notRequiredStringFieldRules}
              disabled={disabledItems?.includes(
                ESupplierManagementContactsFormFields.FIRST_NAME,
              )}
              data-testid={`contacts-input-${ESupplierManagementContactsFormFields.FIRST_NAME}`}
            />
            <NW2FormItemInput
              type='text'
              name={ESupplierManagementContactsFormFields.LAST_NAME}
              placeholder='Last name'
              label='Last name'
              labelColor={NW2Gray600Color}
              rules={notRequiredStringFieldRules}
              disabled={disabledItems?.includes(
                ESupplierManagementContactsFormFields.LAST_NAME,
              )}
              data-testid={`contacts-input-${ESupplierManagementContactsFormFields.LAST_NAME}`}
            />
          </>
        )}
        <NW2FormItemInput
          type='text'
          name={ESupplierManagementContactsFormFields.EMAIL}
          placeholder='Email address'
          label='Email address'
          labelColor={NW2Gray600Color}
          rules={notRequiredEmailFieldRules}
          disabled={disabledItems?.includes(
            ESupplierManagementContactsFormFields.EMAIL,
          )}
          data-testid={`contacts-input-${ESupplierManagementContactsFormFields.EMAIL}`}
        />
        <NW2FormItemPhoneNumber
          type='text'
          name={ESupplierManagementContactsFormFields.PHONE_NUMBER}
          placeholder='+49 1234 123456'
          label='Phone number'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierManagementContactsFormFields.PHONE_NUMBER,
          )}
          data-testid={`contacts-input-${ESupplierManagementContactsFormFields.PHONE_NUMBER}`}
        />
        <NW2FormItemPhoneNumber
          type='text'
          name={ESupplierManagementContactsFormFields.FAX}
          placeholder='Fax number'
          label='Fax number'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierManagementContactsFormFields.FAX,
          )}
          data-testid={`contacts-input-${ESupplierManagementContactsFormFields.FAX}`}
        />
      </FormGroup>
    );
  },
);

export default ContactsEditForm;

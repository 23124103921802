import React, {ReactNode} from 'react';

import {EFormValidationName} from 'types/venue';
import {
  StyledIcon,
  TextBlock,
  Title,
  SubTitle,
} from './LeftSideContainer.styles';

type TProps = {
  id?: EFormValidationName;
  title: string | ReactNode;
  subtitleColor?: string;
  subtitle?: string;
  hasError?: boolean;
};

const ItemTitle = ({id, title, subtitle, hasError, subtitleColor}: TProps) => {
  return (
    <TextBlock id={id} hasError={hasError}>
      {hasError && <StyledIcon icon='TRIANGLE_WARN' error={hasError} />}
      <div>
        <Title hasError={hasError}>{title}</Title>
        {subtitle && (
          <SubTitle color={subtitleColor} hasError={hasError}>
            {subtitle}
          </SubTitle>
        )}
      </div>
    </TextBlock>
  );
};

export default ItemTitle;

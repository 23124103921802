import React from 'react';

import Image from 'view/components/Image';

import {borderRadiusDef} from 'constants/styleVars';
import {
  NW2CardContainer,
  CardBody,
  DecorationElement1,
  DecorationElement2,
  CardImageContainer,
} from './NW2Card.styles';
import {CopyBlock} from 'view/components/CopyBlock';
import {TImageProps} from 'view/components/Image/Image';

type TProps = {
  image: string;
  imageProps: TImageProps;
  title?: string;
  subTitle: string;
  bodyText: string | JSX.Element;
  isGridReverse: boolean;
  isPrimaryDecorationElement: boolean;
  isGrayDecorationElement: boolean;
  className?: string;
};

export function NW2Card({
  image,
  imageProps = {borderRadius: borderRadiusDef},
  title = '',
  subTitle,
  bodyText,
  isGridReverse,
  isPrimaryDecorationElement,
  isGrayDecorationElement,
  className,
}: Partial<TProps>) {
  return (
    <NW2CardContainer className={className} isGridReverse={isGridReverse}>
      {image && (
        <CardImageContainer>
          <Image height='100%' src={image} {...imageProps} />
          {isPrimaryDecorationElement && <DecorationElement1 />}
        </CardImageContainer>
      )}
      <CardBody>
        <CopyBlock title={title} subTitle={subTitle} bodyText={bodyText} />
        {isGrayDecorationElement && <DecorationElement2 />}
      </CardBody>
    </NW2CardContainer>
  );
}

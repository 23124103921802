import React, {ReactNode} from 'react';

import styled from 'styled-components';

import {
  borderColor,
  borderRadiusDef,
  fontSizeXXLg,
  mdBp,
  offsetLg,
  offsetXSm,
  offsetXXXLg,
  primaryColor,
  contentMaxWidthSm,
  whiteColor,
} from 'constants/styleVars';
import {BlockTitle, PageTitle} from '../Typography';

const ImageCardContainer = styled.div`
  max-width: ${contentMaxWidthSm};
  border-radius: ${borderRadiusDef};
  overflow: hidden;
  border: 1px solid ${borderColor};
  background: ${whiteColor};

  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } ;
`;

const ImgCoverContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: ${offsetXXXLg} ${offsetLg};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${primaryColor};
    opacity: 0.48;
  }

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  @media (min-width: ${mdBp}px) {
    padding: ${offsetXXXLg};
  } ;
`;

const ImgCoverBody = styled.div`
  position: relative;
  z-index: 1;
  & > * {
    color: ${whiteColor};
  }
`;

const StyledPageTitle = styled(PageTitle)`
  font-size: ${fontSizeXXLg};
  margin-bottom: ${offsetXSm};
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin-bottom: 0;
`;

const ImgCardBody = styled.div`
  padding: ${offsetXXXLg} ${offsetLg};

  @media (min-width: ${mdBp}px) {
    padding: ${offsetXXXLg};
  } ;
`;

type TProps = {
  coverTitle?: string;
  coverText?: string;
  coverImage: string;
  coverImageAlt?: string;
  children: ReactNode;
};

const ImageCard = ({
  coverTitle,
  coverText,
  coverImage,
  coverImageAlt = 'Cover Image',
  children,
}: TProps) => {
  return (
    <ImageCardContainer>
      <ImgCoverContainer>
        <img src={coverImage} alt={coverImageAlt} />

        <ImgCoverBody>
          {coverTitle && <StyledPageTitle>{coverTitle}</StyledPageTitle>}
          {coverText && <StyledBlockTitle>{coverText}</StyledBlockTitle>}
        </ImgCoverBody>
      </ImgCoverContainer>
      <ImgCardBody>{children}</ImgCardBody>
    </ImageCardContainer>
  );
};

export default ImageCard;

import {getCountries} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import {useMemo} from 'react';

function UseCountriesList() {
  const getCountryList = () =>
    getCountries()
      .map((countryCode) => ({
        countryCode,
        country: en[countryCode],
      }))
      .sort((a, b) => {
        if (a.country < b.country) return -1;
        if (a.country > b.country) return 1;
        return 0;
      });

  return useMemo(
    () => ({
      countriesObject: en as Record<string, string>,
      countriesList: getCountryList(),
    }),
    [],
  );
}

export default UseCountriesList;

import React, {memo} from 'react';

import {FormGroup} from './NW2SupplierContactsDetailsForm.styles';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {notRequiredStringFieldRules} from 'utils/finalFormFieldRules';

import {ESupplierSocialMediaFormFields, IEditFormProps} from './types';
import {NW2Gray600Color, offsetDef} from 'constants/styleVars';

const SocialMediaEditForm = memo(
  ({disabledItems, gap = parseInt(offsetDef)}: IEditFormProps) => {
    return (
      <FormGroup columnNumber={1} gap={gap}>
        <NW2FormItemInput
          type='text'
          name={ESupplierSocialMediaFormFields.FACEBOOK}
          placeholder='Facebook'
          label='Facebook'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierSocialMediaFormFields.FACEBOOK,
          )}
          data-testid={`social-input-${ESupplierSocialMediaFormFields.FACEBOOK}`}
        />
        <NW2FormItemInput
          type='text'
          name={ESupplierSocialMediaFormFields.INSTAGRAM}
          placeholder='Instagram'
          label='Instagram'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierSocialMediaFormFields.INSTAGRAM,
          )}
          data-testid={`social-input-${ESupplierSocialMediaFormFields.INSTAGRAM}`}
        />
        <NW2FormItemInput
          type='text'
          name={ESupplierSocialMediaFormFields.TWITTER}
          placeholder='Twitter'
          label='Twitter'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierSocialMediaFormFields.TWITTER,
          )}
          data-testid={`social-input-${ESupplierSocialMediaFormFields.TWITTER}`}
        />
      </FormGroup>
    );
  },
);

export default SocialMediaEditForm;

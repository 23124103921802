import React, {useCallback, useEffect, useMemo} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useActions} from 'hooks/useActions';

import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import ConfirmationStatus from './components/NW2BookingConfirmationStatus/NW2BookingConfirmationStatus';
import NW2BookingConfirmationActionButtons from './components/NW2BookingConfirmationActionButtons/NW2BookingConfirmationActionButtons';
import NW2BookingConfirmationSummary from './components/NW2BookingConfirmationSummary/NW2BookingConfirmationSummary';
import BookingConfirmationBlockList from './components/BookingConfirmationBlockList/BookingConfirmationBlockList';
import NW2Loader from 'view/components/NW2Loader';
import {NW2SpaceDetails} from 'view/venue/NW2VenueDetails/components';

import useUpdateCancellationPolicyText from '../hooks/useUpdateCancellationPolicyText';
import {getFlatMapUnitBookings} from 'view/customer/helpers';
import {useBookingConfirmationModal} from './hooks/useBookingConfirmationModal';
import {clearVenueDetails} from 'store/venues/venuesSlice';
import {getPublicVenue} from 'store/venues/apiActions';
import {findCoverImage} from 'utils/helpers';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {
  cleanCustomerBookedUnits,
  cleanCustomerBookingOrder,
  cleanCustomerPreviousBookingOrder,
  setCustomerBookedUnits,
} from 'store/customer/customerSlice';
import {
  clearSearchCriteria,
  setSearchCriteriaRoomType,
} from 'store/search/searchSlice';
import {getPackages} from 'store/packages/apiActions';
import {TImage} from 'types/app';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EBookingConfirmationModal} from './types';

import {EBookingStatus} from 'types/booking';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {
  Divider,
  GoBackBlock,
  LoaderWrapper,
  Main,
  StyledContainer,
  Title,
  Wrapper,
} from './NW2BookingConfirmation.styles';
import {getCustomerBookedUnitsFromOrderDays} from 'store/customer/apiActions';
import {useVenueDetailsData} from '../hooks/useVenueDetailsData';
import {useVenueDetailsId} from '../hooks/useVenueDetailsId';
import {useMultiDayVenueDetailsData} from '../NW2VenueDetails/useMultiDayVenueDetails';
import useParamsVenueId from '../hooks/useParamsVenueId';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {useRequestBedrooms} from '../Offer/hooks/useRequestBedrooms';
import {clearMultiSearchData} from 'store/search/searchSlice';
import {setVenueDetails} from 'store/venueDetails/venueDetailsSlice';
import {Routes} from 'constants/routes';
import {cleanWorkDesksDetails} from 'store/workDesks/workDesksSlice';
import {TGroupedUnitsByDay} from 'types/dto/IBooking.types';

function NW2BookingConfirmation() {
  const dispatch = useAppDispatch();

  const {getCustomerBookingOrder} = useActions();

  const navigate = useNavigate();
  const location = useLocation();
  const {orderNumber: paramsOrderNumber} = useParams<Record<string, string>>();
  const {paramsVenueId} = useParamsVenueId();

  const query = new URLSearchParams(location.search);
  const showShareBooking: string | null = query.get('showShareBooking');

  useMultiDayVenueDetailsData({
    venueId: paramsVenueId,
    withoutSearchData: true,
  });

  const {venueDetails, isVenueDetailsLoading} = useVenueDetailsData();
  const [venueDetailsId] = useVenueDetailsId();

  const venueId = query.get('venueId');

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const userRole = useAppSelector(({app}) => app.user.role);
  const isCustomerLoading = useAppSelector(({customer}) => customer.isLoading);

  const customerBookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );
  const packages = useAppSelector(({packages}) => packages.packages);
  const isMultiRooms = useAppSelector(
    ({search}) => search.searchCriteria.multiRooms,
  );
  const customerBookedUnits = useAppSelector(
    ({customer}) => customer.customerBookedUnits,
  );
  const freeOfCharge = useAppSelector(
    ({cancellationPolicy}) =>
      cancellationPolicy.dynamicCancellationPolicy?.policies[0]?.freeOfCharge,
  );

  const {isLoggedInUser} = useLoggedInUser();

  const isAgentRole = userRole === EUserRoleCognito.ROLE_AGENT;

  const {
    bookingStatus,
    cancellationPolicy,
    termsAndConditionsId,
    eventName,
    isRfp,
    orderDays,
    orderNumber,
    paymentInfo,
  } = customerBookingOrder || {};

  const {accommodationType, documents, location: venueLocation} = venueDetails;

  const unitBookings = orderDays?.[0].unitBookings;
  const unitType = unitBookings?.[0]?.unitType;
  const roomType = unitBookings?.[0]?.unitInfo?.roomType;

  const venueAddress = getAddressStringFromLocation(venueLocation);
  const venueCoverImage = useMemo(
    () => findCoverImage((documents || []) as TImage[]),
    [documents],
  );

  const unitData = useMemo(
    () => getFlatMapUnitBookings(customerBookedUnits as TGroupedUnitsByDay[]),
    [customerBookedUnits],
  );

  useUpdateCancellationPolicyText({
    unitData,
    isRtb: !!isRfp,
  });

  useEffect(() => {
    if (customerBookingOrder) {
      const customerBookedUnits = getCustomerBookedUnitsFromOrderDays(
        customerBookingOrder,
        packages,
      );
      dispatch(setCustomerBookedUnits(customerBookedUnits));
    }
  }, [dispatch, packages, customerBookingOrder]);

  // we need to set roomType for search criteria when we go from booking overview page
  useEffect(() => {
    if (!unitType) return;

    dispatch(
      setSearchCriteriaRoomType(
        unitType !== ERoomType.MEETING_ROOM ? ERoomType.WORK_SPACE : unitType,
      ),
    );
  }, [unitType, dispatch]);

  // fetch venue details
  useEffect(() => {
    if (Number(venueId) && !venueDetailsId && !isVenueDetailsLoading) {
      dispatch(getPublicVenue({id: String(venueId), isMultiRooms}));
      dispatch(getPackages(Number(venueId)));
    }
  }, [
    dispatch,
    isCustomerLoading,
    isMultiRooms,
    venueDetailsId,
    isVenueDetailsLoading,
    venueId,
  ]);

  // fetch booking data(need to fetch only when we have data for venueDetails)
  useEffect(() => {
    if (!orderNumber && venueDetailsId) {
      getCustomerBookingOrder(paramsOrderNumber || '');
    }
  }, [getCustomerBookingOrder, orderNumber, paramsOrderNumber, venueDetailsId]);

  // refresh status after canceled RTC
  useEffect(() => {
    if (orderNumber && bookingStatus === EBookingStatus.RTC_CANCELLED) {
      getCustomerBookingOrder(orderNumber);
    }
  }, [orderNumber, getCustomerBookingOrder, bookingStatus]);

  const goToCustomerBookings = () => {
    if (isAgentRole) {
      navigate({
        pathname: Routes.agentView,
      });
      return;
    }

    navigate({
      pathname: Routes.customerBookings,
    });
  };

  // modals
  const {
    isModalShowed,
    onModalShow,
    onModalClose,
    modalTitle,
    modalBody,
    modalFooter,
  } = useBookingConfirmationModal();

  const handleModalShow = useCallback(
    (arg?: EBookingConfirmationModal) => () => {
      if (onModalShow && arg) {
        onModalShow(arg);
        return;
      }
    },
    [onModalShow],
  );

  // Open share booking modal
  useEffect(() => {
    if (showShareBooking) {
      // show modal
      onModalShow(EBookingConfirmationModal.SHARE_BOOKING);
      // clear 'showShareBooking' search parameter
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('showShareBooking');
      navigate({
        pathname: location.pathname,
        search: '?' + new URLSearchParams(searchParams).toString(),
      });
    }
  }, [
    navigate,
    location.pathname,
    location.search,
    showShareBooking,
    onModalShow,
  ]);

  useEffect(() => {
    return () => {
      dispatch(cleanCustomerBookingOrder());
      dispatch(cleanCustomerBookedUnits());
      dispatch(clearVenueDetails());
      dispatch(cleanCustomerPreviousBookingOrder());
      dispatch(cleanWorkDesksDetails()); // WD's
      dispatch(setVenueDetails(null)); // MR's
      dispatch(clearSearchCriteria());
      dispatch(clearMultiSearchData());
    };
  }, [dispatch]);

  const {preArrivals, postEvents} = useRequestBedrooms();

  if (isCustomerLoading || !orderNumber || !venueDetailsId)
    return (
      <LoaderWrapper isMobile={isMobile}>
        <NW2Loader label='Loading' />
      </LoaderWrapper>
    );

  const summaryBlock = (
    <NW2BookingConfirmationSummary
      venueAddress={venueAddress}
      venueDetails={venueDetails}
      venueCoverImage={venueCoverImage}
      postEvents={postEvents}
      preArrivals={preArrivals}
      linkTermsText={
        'Cancellation policy becomes binding once request is confirmed by the venue. You have already accepted the HRS '
      }
    />
  );

  return (
    <Wrapper>
      <StyledContainer>
        {isLoggedInUser && (
          <GoBackBlock>
            <NW2Button
              buttonType='secondary'
              minimized
              size='small'
              icon={<Icon transparent icon='NW2_MOVE_BACK' />}
              onClick={goToCustomerBookings}
            />
            <Title>bookings overview</Title>
          </GoBackBlock>
        )}

        <Main>
          <div>
            <ConfirmationStatus />

            {!isAgentRole && !!orderNumber && (
              <NW2BookingConfirmationActionButtons
                onModalShow={handleModalShow}
              />
            )}

            {isMobile && summaryBlock}

            <Divider />

            <BookingConfirmationBlockList
              orderNumber={orderNumber}
              termsAndConditions={termsAndConditionsId}
              cancellationPolicy={cancellationPolicy}
              accommodationType={accommodationType}
              meetingName={eventName}
              preArrivals={preArrivals}
              postEvents={postEvents}
              roomType={roomType}
              paymentInfo={paymentInfo}
              freeOfCharge={freeOfCharge}
            />
          </div>

          {!isMobile && summaryBlock}
        </Main>
      </StyledContainer>

      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isModalShowed}
        header={modalTitle}
        modalWidth='auto'
        drawerHeight='auto'
        body={modalBody}
        footer={modalFooter}
        onClose={onModalClose}
      />
      <NW2SpaceDetails isFooterHidden />
    </Wrapper>
  );
}

export default NW2BookingConfirmation;

import React from 'react';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import AboutMeeting from 'view/venue/NW2BookingConfirmation/components/AboutMeeting';
import {OfferRequestReviewUnits} from 'view/venue/Offer/components/OfferRequestReviewUnits/OfferRequestReviewUnits';
import OfferOptionDate from 'view/venue/Offer/components/OfferOptionDate/OfferOptionDate';
import ItemPaymentInformation from 'view/venue/components/LeftSideContainer/ItemPaymentInformation';
import {WhatIsNext} from 'view/venue/Offer/components/WhatIsNext/WhatIsNext';
import {Bedrooms} from 'view/venue/NW2BookingPreview/components/Bedrooms/Bedrooms';
import {BookingTermsAndConditions} from 'view/venue/BookingTermsAndConditions/BookingTermsAndConditions';

import {useAppSelector} from 'store/hooks';
import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import {
  EGroupStatus,
  EOfferStatus,
  ERequestStatus,
  IOfferRequestDay,
} from 'types/offer';
import {IPaymentInfo} from 'types/bookingOrders';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';

interface IProps {
  requestStatus: ERequestStatus | EOfferStatus;
  eventName: string;
  isOffer?: boolean;
  isGroupRequestPage?: boolean;
  groupStatus?: EGroupStatus | null;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
  paymentInfo?: IPaymentInfo;
  termsAndConditionsId?: string | null;
  customerCancellationPolicyId?: string | null;
  isUSA: boolean;
}
function OfferRequestConfirmationBlockList({
  requestStatus,
  eventName,
  isOffer,
  preArrivals,
  postEvents,
  isGroupRequestPage,
  groupStatus,
  paymentInfo,
  termsAndConditionsId,
  customerCancellationPolicyId,
  isUSA = false,
}: IProps) {
  const billingAddress = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.billingAddressDto,
  );

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  const isRequestPending =
    requestStatus === ERequestStatus.REQUEST_PENDING ||
    groupStatus === EGroupStatus.GROUP_REQUEST_PENDING;

  return (
    <StyledLeftSideContainer>
      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeeting meetingName={eventName} />,
        }}
        isHidden={!eventName}
      />

      {requestStatus === ERequestStatus.REQUEST_PENDING && (
        <>
          <LeftSideItem
            item={{
              title: 'Option date',
              children: <OfferOptionDate />,
            }}
          />
        </>
      )}

      {!!preArrivals?.length && (
        <Bedrooms rooms={preArrivals} title='PRE-ARRIVAL' />
      )}

      <OfferRequestReviewUnits
        isOffer={isOffer}
        isGroupRequestPage={isGroupRequestPage}
        hasPreArrivals={!!preArrivals?.length}
        hasPostEvents={!!postEvents?.length}
      />

      {!!postEvents?.length && (
        <Bedrooms rooms={postEvents} title='POST-EVENT' />
      )}

      <ItemPaymentInformation
        paymentInfo={paymentInfo}
        billingAddress={billingAddress}
        isHidden={!isHrsPayEnabled}
      />

      {isRequestPending && (
        <>
          <LeftSideItem
            item={{
              title: 'Terms and Conditions',
              children: (
                <BookingTermsAndConditions
                  termsLinkId={termsAndConditionsId}
                  customerCancellationPolicyId={customerCancellationPolicyId}
                  isUSA={isUSA}
                />
              ),
            }}
          />

          <LeftSideItem
            item={{
              title: 'What’s next',
              children: <WhatIsNext isGroupRequest={isGroupRequestPage} />,
            }}
          />
        </>
      )}
    </StyledLeftSideContainer>
  );
}

export default OfferRequestConfirmationBlockList;

import React, {useState} from 'react';
import {FilterDropdownProps} from 'antd/es/table/interface';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {Button, Checkbox, CheckboxOptionType} from 'antd';
import {
  StyledFilterButton,
  ButtonWrapper,
  CheckboxContainer,
} from './ColumnRenderContentStyles';
import {EBookingStatus} from 'types/booking';

const bookingStatusAllOptions = {
  [EBookingStatus.CONFIRMED]: {
    value: EBookingStatus.CONFIRMED,
    label: 'Confirmed',
  },
  [EBookingStatus.CANCELED]: {
    value: EBookingStatus.CANCELED,
    label: 'Cancelled',
  },
};

const options: Record<string, Record<string, CheckboxOptionType[]>> = {
  bookingStatus: {
    arriving: [
      bookingStatusAllOptions[EBookingStatus.CONFIRMED],
      bookingStatusAllOptions[EBookingStatus.CANCELED],
    ],
    history: [
      bookingStatusAllOptions[EBookingStatus.CONFIRMED],
      bookingStatusAllOptions[EBookingStatus.CANCELED],
    ],
  },
};

export const plainOptions: Record<
  string,
  {allBtn: string; data: Record<string, string[]>}
> = {
  bookingStatus: {
    allBtn: 'All status',
    data: {
      default: [`${EBookingStatus.CONFIRMED}`, `${EBookingStatus.CANCELED}`],
      history: [`${EBookingStatus.CONFIRMED}`, `${EBookingStatus.CANCELED}`],
      arriving: [`${EBookingStatus.CONFIRMED}`, `${EBookingStatus.CANCELED}`],
    },
  },
};

export function FilterCheckboxDropdown({
  setSelectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  tableName,
}: FilterDropdownProps & {dataIndex: string; tableName?: string}) {
  const currentPlainOptions =
    plainOptions[dataIndex].data[`${tableName || 'default'}`];

  const currentOptions = options[dataIndex][`${tableName || 'default'}`];

  const [checkboxDropdownState, setCheckboxDropdownState] = useState({
    checkedOptions: currentPlainOptions,
    indeterminate: false,
    checkAll: true,
  });

  const handleApply = () => {
    confirm();
  };

  const handleReset = () => {
    if (clearFilters) clearFilters();
    confirm();
    setSelectedKeys(currentPlainOptions);
    setCheckboxDropdownState({
      checkedOptions: currentPlainOptions,
      indeterminate: false,
      checkAll: true,
    });
  };

  const onChange = (checkedValues: any) => {
    setSelectedKeys(checkedValues);

    setCheckboxDropdownState({
      checkedOptions: checkedValues,
      indeterminate:
        !!checkedValues.length &&
        checkedValues.length < currentPlainOptions.length,
      checkAll: checkedValues.length === currentPlainOptions.length,
    });
  };

  const onCheckAllChange = ({target}: CheckboxChangeEvent) => {
    setSelectedKeys(target.checked ? currentPlainOptions : []);

    setCheckboxDropdownState({
      checkedOptions: target.checked ? currentPlainOptions : [],
      indeterminate: false,
      checkAll: target.checked,
    });
  };

  return (
    <div>
      <CheckboxContainer>
        <Checkbox
          indeterminate={checkboxDropdownState.indeterminate}
          onChange={onCheckAllChange}
          checked={checkboxDropdownState.checkAll}
        >
          {plainOptions[dataIndex].allBtn}
        </Checkbox>
        <Checkbox.Group
          options={currentOptions}
          defaultValue={currentPlainOptions}
          value={checkboxDropdownState.checkedOptions}
          onChange={onChange}
        />
      </CheckboxContainer>

      <ButtonWrapper>
        <Button onClick={handleReset} size='small' type='link'>
          Reset
        </Button>
        <StyledFilterButton type='primary' onClick={handleApply} size='small'>
          Apply
        </StyledFilterButton>
      </ButtonWrapper>
    </div>
  );
}

import React, {FC} from 'react';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import ModalOverlay from 'view/components/ModalOverlay';
import ImprintPage from '../ImprintPage/ImprintPage';
import CookiePolicyPage from '../CookiePolicyPage/CookiePolicyPage';
import CustomerTermsAndConditionsPage from '../TermsAndConditions/CustomerTermsAndConditionsPage/CustomerTermsAndConditionsPage';
import SupplierTermsAndConditionsPage from '../TermsAndConditions/SupplierTermsAndConditionsPage/SupplierTermsAndConditionsPage';
import CorporateUserTermsAndConditionsPage from '../TermsAndConditions/CorporateUserTermsAndConditionsPage/CorporateUserTermsAndConditionsPage';
import CustomerPrivacyPolicyPage from '../PrivacyPolicy/CustomerPrivacyPolicyPage/CustomerPrivacyPolicyPage';
import SupplierPrivacyPolicyPage from '../PrivacyPolicy/SupplierPrivacyPolicyPage/SupplierPrivacyPolicyPage';
import CorporateUserPrivacyPolicyPage from '../PrivacyPolicy/CorporateUserPrivacyPolicyPage/CorporateUserPrivacyPolicyPage';
import CustomerFAQPage from '../FAQ/CustomerFAQPage/CustomerFAQPage';
import SupplierFAQPage from '../FAQ/SupplierFAQPage/SupplierFAQPage';
import CorporateUserFAQPage from '../FAQ/CorporateUserFAQPage/CorporateUserFAQPage';

import {
  ENW2MultiModalActiveComponentId,
  INW2MultiModalActiveComponent,
  INW2MultiModalComponents,
  TNW2MultiModalActiveComponentProps,
} from 'types/app';
import {EMPTY_OBJECT} from 'constants/app';
import {infoPagesZIndex} from 'constants/styleVars';
import AnnouncementAddEditModal from 'view/admin/AnnouncementAddEditModal';

const getModalComponent = (
  id: ENW2MultiModalActiveComponentId,
): (() => JSX.Element) => {
  const MODAL_COMPONENTS: INW2MultiModalComponents = {
    [ENW2MultiModalActiveComponentId.IMPRINT_MODAL]: ImprintPage,
    [ENW2MultiModalActiveComponentId.COOKIE_POLICY_MODAL]: CookiePolicyPage,
    [ENW2MultiModalActiveComponentId.CUSTOMER_TERMS_AND_CONDITIONS_MODAL]:
      CustomerTermsAndConditionsPage,
    [ENW2MultiModalActiveComponentId.SUPPLIER_TERMS_AND_CONDITIONS_MODAL]:
      () => <SupplierTermsAndConditionsPage />,
    [ENW2MultiModalActiveComponentId.CORPORATE_USER_TERMS_AND_CONDITIONS_MODAL]:
      CorporateUserTermsAndConditionsPage,
    [ENW2MultiModalActiveComponentId.CUSTOMER_PRIVACY_POLICY_MODAL]:
      CustomerPrivacyPolicyPage,
    [ENW2MultiModalActiveComponentId.SUPPLIER_PRIVACY_POLICY_MODAL]:
      SupplierPrivacyPolicyPage,
    [ENW2MultiModalActiveComponentId.CORPORATE_USER_PRIVACY_POLICY_MODAL]:
      CorporateUserPrivacyPolicyPage,
    [ENW2MultiModalActiveComponentId.CUSTOMER_FAQ_MODAL]: CustomerFAQPage,
    [ENW2MultiModalActiveComponentId.SUPPLIER_FAQ_MODAL]: SupplierFAQPage,
    [ENW2MultiModalActiveComponentId.CORPORATE_USER_FAQ_MODAL]:
      CorporateUserFAQPage,
    [ENW2MultiModalActiveComponentId.ADMIN_ANNOUNCEMENT_ADD_EDIT_MODAL]:
      AnnouncementAddEditModal,
  };

  return MODAL_COMPONENTS[id];
};

const StyledModalOverlay = styled(ModalOverlay)`
  z-index: ${infoPagesZIndex};
`;

const NW2MultiModal: FC = () => {
  const modalComponent: INW2MultiModalActiveComponent = useAppSelector(
    ({app}) => app.nw2MultiModalActiveComponent,
  );

  const {
    id,
    props = EMPTY_OBJECT as TNW2MultiModalActiveComponentProps, // In case we need to pass some dynamic props
  } = modalComponent;
  if (!id) return null;

  const ModalContent = getModalComponent(id);
  if (!ModalContent) return null;

  return (
    <StyledModalOverlay>
      <ModalContent {...props} />
    </StyledModalOverlay>
  );
};

export default React.memo(NW2MultiModal);

import React from 'react';

import TextRow from 'view/components/TextRow';

import {TextValue} from 'view/components/TextValue';

import {EDays} from 'constants/days';
import {IDay} from 'types/dto/IDay.type';
import {capitalizeText} from 'utils/stringUtils';
import {useOpeningHoursValue} from 'view/venue/hooks/useOpeningHoursValue';

interface Props {
  workDays: IDay[];
}

const OperationalHoursInfo = ({workDays}: Props) => {
  const [getValue] = useOpeningHoursValue({workDays});

  return (
    <div id='operationalHoursInfo'>
      <TextRow label='Days' value='Timings' secondaryLabel secondaryValue />

      {Object.values(EDays).map((d) => {
        const capitalizedValue = capitalizeText(d);
        const textValue = getValue(d);
        const isClosed = textValue === 'Closed';

        const textProps = {
          lightText: !isClosed,
          error: isClosed,
        };

        return (
          <TextRow
            key={d}
            label={capitalizedValue + ':'}
            value={<TextValue {...textProps}>{textValue}</TextValue>}
            valueId={`operationalHoursInfo${capitalizedValue}`}
          />
        );
      })}
    </div>
  );
};

export default OperationalHoursInfo;

import styled from 'styled-components';

import {offsetXLg} from 'constants/styleVars';

export const ModalDeclineWrapper = styled.div<{isMobile?: boolean}>`
  display: grid;
  grid-template-columns: ${({isMobile}) => (isMobile ? '1fr' : '209px 1fr')};
  grid-column-gap: ${offsetXLg};
`;

export const ModalDeclineLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXLg};
`;

import React from 'react';

import {FullDescription} from '.';
import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {TVenueCharactersOption} from '../NW2VenueDescription.types';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {
  FlexContainer,
  SubSectionContainer,
  SectionCheckboxItem,
} from '../NW2VenueDescription.styles';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';

interface IProps {
  isExternalVenue: boolean;
  venueCharactersOptions: TVenueCharactersOption[];
  handleClick: () => void;
  description: string;
}

const EmptyCharacter = ({
  isExternalVenue,
  venueCharactersOptions,
  handleClick,
  description,
}: IProps) => {
  return (
    <FlexContainer>
      <SubSectionContainer>
        <SectionSubTitle>What we are like?</SectionSubTitle>
        <FullDescription description={description} />
      </SubSectionContainer>

      {isExternalVenue && (
        <SubSectionContainer>
          <SectionSubTitle>Our venue character</SectionSubTitle>
          {venueCharactersOptions?.map(({label, value}) => (
            <SectionCheckboxItem key={value} onClick={handleClick}>
              <NW2FormItemCheckbox
                id={value}
                name='venueCharacter'
                label={label}
                value={value}
                size={ECheckboxSizes.LARGE}
                data-testid={`${label}--checkbox`}
              />
            </SectionCheckboxItem>
          ))}
        </SubSectionContainer>
      )}
    </FlexContainer>
  );
};

export default EmptyCharacter;

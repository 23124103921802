import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import TimeRangeText from 'view/components/TimeRangeText';

import DateUtils, {DateFormats} from 'utils/dateUtils';

import {
  fontSizeXSm,
  fontWeightNormal,
  lineHeightSm,
  offsetSm,
  offsetXXSm,
} from 'constants/styleVars';

const HeaderContainer = styled.div`
  display: flex;
  gap: ${offsetSm};
`;

const DateContainer = styled.span`
  font-weight: ${fontWeightNormal};
  line-height: 1;
`;
const DayOfWeek = styled.span`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  margin: 0 ${offsetXXSm};
`;

const TimeRange = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  white-space: nowrap;
  margin: ${offsetXXSm} 0 -8px;
`;

type TProps = {
  checkIn: string;
  checkOut: string;
};

function UnitSectionTitle({checkIn, checkOut}: TProps) {
  const dateText = DateUtils.getDateWithoutHours(checkIn);
  const dayOfWeek = DateUtils.getDateWithoutHours(
    checkIn,
    DateFormats['(Wed)'],
  );

  return (
    <HeaderContainer>
      <Icon icon='NW2_CALENDAR' transparent />
      <DateContainer>
        {dateText}
        <DayOfWeek>{dayOfWeek}</DayOfWeek>
        <TimeRange>
          <TimeRangeText checkIn={checkIn} checkOut={checkOut} />
        </TimeRange>
      </DateContainer>
    </HeaderContainer>
  );
}

export default UnitSectionTitle;

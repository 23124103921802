import React, {Fragment, memo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';

import ICON_MAP from 'constants/iconMap';
import {
  Step,
  StepArrow,
  StepImg,
  StepText,
  Text,
  Title,
  Wrapper,
} from './OfferSteps.styles';

const STEPS = (
  isDesktop?: boolean,
): {
  title: string;
  text: string;
  icon: keyof typeof ICON_MAP;
}[] => [
  {
    title: 'step 1',
    text: 'You send a request',
    icon: 'OFFER_STEP_1',
  },

  {
    title: 'step 2',
    text: 'Venue reviews it',
    icon: 'OFFER_STEP_2',
  },

  {
    title: 'step 3',
    text: 'Review venue’s offer',
    icon: 'OFFER_STEP_3',
  },

  {
    title: 'step 4',
    text: `${isDesktop ? 'Booking confirmed!' : 'Confirmation'}`,
    icon: 'OFFER_STEP_4',
  },
];

export const OfferSteps = memo(({activeStep}: {activeStep: number}) => {
  const {isDesktop, isTablet, isMobile} = useSelector((state) =>
    _get(state, 'app.deviceType'),
  );

  const getIcon = (idx: number, icon: keyof typeof ICON_MAP) => (
    <StepImg
      isActive={activeStep > idx}
      isHighlighted={isTablet && activeStep === idx + 1}
    >
      <Icon icon={icon} />
    </StepImg>
  );

  return (
    <Wrapper isMobile={isMobile}>
      {STEPS(isDesktop).map(({title, text, icon}, idx) => (
        <Fragment key={title}>
          <Step>
            <StepText>
              <Title
                isActive={activeStep > idx}
                isHighlighted={!isTablet && activeStep === idx + 1}
              >
                {title}
              </Title>

              <Text>
                {isTablet && getIcon(idx, icon)}

                {text}
              </Text>
            </StepText>
            {isDesktop && getIcon(idx, icon)}
          </Step>

          {idx !== STEPS().length - 1 && (
            <StepArrow>
              <Icon icon='OFFER_ARROW' NW2Gray400Color />
            </StepArrow>
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
});

import {
  BEDROOM_CATERING,
  BEDROOM_EXTRAS,
  ID_TO_BEDROOM_EXTRA,
  ID_TO_RESOURCE_CODE,
} from 'constants/bedrooms';
import {
  EBedroomExtras,
  EBedroomExtrasNames,
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from 'types/dto/IExtras.type';
import {TBedroomsCateringDay} from './types';
import {TSelectedOptions} from 'types/search';

export const getPreselectedAccommodation = (participants: number) => {
  // after enabling accommodation this one should be preselected
  const defaultExtraId = EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST;

  return {
    data: {
      accommodation: [defaultExtraId],
      [defaultExtraId + '_']: participants,
    },
    ids: [defaultExtraId],
    options: [
      {
        id: EBedroomExtras.SINGLE_BEDROOM_WITH_BREAKFAST,
        isCustom: false,
        code: EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST,
        name: BEDROOM_EXTRAS.SINGLE,
        type: EResourcesType.BEDROOM,
      },
      {
        id: EBedroomExtras.DOUBLE_BEDROOM_WITH_BREAKFAST,
        isCustom: false,
        code: EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST,
        name: BEDROOM_EXTRAS.DOUBLE,
        type: EResourcesType.BEDROOM,
      },
    ],
  };
};

export const getFormDataAccommodation = (formData?: Record<string, any>) => {
  if (!formData) return [];

  const isOneOptionEnabled = formData.accommodation.length === 1 ? true : false;

  const getCompleteFormData = () => {
    const completeFormData: EBedroomExtras[] = structuredClone(
      formData.accommodation,
    );

    if (isOneOptionEnabled) {
      const {
        SINGLE_BEDROOM,
        SINGLE_BEDROOM_WITH_BREAKFAST,
        DOUBLE_BEDROOM_WITH_BREAKFAST,
      } = EBedroomExtras;

      const singleBedroomArray = [
        SINGLE_BEDROOM,
        SINGLE_BEDROOM_WITH_BREAKFAST,
      ];

      const onlySingleBedroom = formData.accommodation.some(
        (id: EBedroomExtras) => singleBedroomArray.includes(id),
      );

      if (onlySingleBedroom) {
        completeFormData.push(DOUBLE_BEDROOM_WITH_BREAKFAST);
      } else {
        completeFormData.unshift(SINGLE_BEDROOM_WITH_BREAKFAST);
      }
    }

    return completeFormData;
  };

  const getFormOptions = (completeFormData: EBedroomExtras[]) =>
    completeFormData.map((id: EBedroomExtras) => {
      return {
        id,
        isCustom: false,
        code: ID_TO_RESOURCE_CODE[id],
        name: ID_TO_BEDROOM_EXTRA[id],
        type: EResourcesType.BEDROOM,
      };
    });

  const completeFormData = getCompleteFormData();

  return getFormOptions(completeFormData);
};

export const getAccommodationData = ({
  accommodationData,
  bedroomsCateringDay,
  filteredBedroomExtras,
}: {
  bedroomsCateringDay: TBedroomsCateringDay;
  accommodationData: TSelectedOptions;
  filteredBedroomExtras: IExtrasOption[];
}) => {
  if (!accommodationData) return;

  const enabledItems = Object.values(bedroomsCateringDay).filter(
    ({isEnabled}) => isEnabled,
  );

  const ids: number[] = [];
  const data: Record<string, number> = {};

  enabledItems.forEach((item) => {
    const itemId = filteredBedroomExtras.find(
      ({name}) => name === item.name,
    )?.id;

    if (itemId && item.qty) {
      ids.push(itemId);
      data[`${itemId}_`] = item.qty;
    }
  });

  return {
    data,
    ids,
    options: filteredBedroomExtras,
  };
};

export const getCateringStatusByName = (name?: EBedroomExtrasNames) => {
  switch (name) {
    case EBedroomExtrasNames.SINGLE_BEDROOM_WITH_BREAKFAST:
    case EBedroomExtrasNames.DOUBLE_BEDROOM_WITH_BREAKFAST:
      return BEDROOM_CATERING.WITH_BREAKFAST;

    case EBedroomExtrasNames.SINGLE_BEDROOM:
    case EBedroomExtrasNames.DOUBLE_BEDROOM:
      return BEDROOM_CATERING.NO_BREAKFAST;

    default:
      return '';
  }
};

export const extraIsAccommodation = (code?: EResourcesCode) => {
  if (!code) return false;

  return [
    EResourcesCode.DOUBLE_BEDROOM,
    EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST,
    EResourcesCode.SINGLE_BEDROOM,
    EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST,
  ].includes(code);
};

import React, {useEffect, useState} from 'react';
import {IState, State} from 'country-state-city';
import {Select, Form} from 'antd';

interface RegionSelectProps {
  name: string;
  label: string;
  rules: any[];
  placeholder: string;
  countryCode: string;
  onChange: (value: string) => void;
}

const RegionSelect: React.FC<RegionSelectProps> = ({
  name,
  label,
  rules,
  placeholder,
  countryCode,
  onChange,
}) => {
  const [regionList, setRegionList] = useState<IState[]>([]);

  useEffect(() => {
    if (countryCode) {
      const regions = State.getStatesOfCountry(countryCode);
      setRegionList(regions);
    }
  }, [countryCode]);

  if (regionList.length === 0) {
    return null;
  }
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select placeholder={placeholder} onChange={onChange}>
        {regionList.map(({isoCode, name}) => {
          return (
            <Select.Option key={isoCode} value={name}>
              {name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default RegionSelect;

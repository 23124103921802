import {useEffect, useMemo, useState} from 'react';

import {TImage} from 'types/app';

export enum EVenueImagesLayout {
  'FIVE' = 'FIVE',
  'THREE' = 'THREE',
  'TWO' = 'TWO',
}

interface IProps {
  venueImages: TImage[];
  meetingRoomsImages: TImage[];
  workSpacesImages: TImage[];
}

export function useCoverImages({
  venueImages,
  meetingRoomsImages,
  workSpacesImages,
}: IProps) {
  const [imgLayout, setImgLayout] = useState<EVenueImagesLayout>(
    EVenueImagesLayout.TWO,
  );

  const coverImages = useMemo((): TImage[] => {
    // need to return uniq and images that is set as cover image
    const unitsImgs: TImage[] = [...meetingRoomsImages, ...workSpacesImages]
      .filter((img) => img.isCover)
      .filter(
        (el, index, self) =>
          self.findIndex((selfEl) => selfEl.uid === el.uid) === index,
      );

    const result: TImage[] = [...venueImages, ...unitsImgs];

    if (result.length === 4) return result.slice(0, 3);
    return result.slice(0, 5);
  }, [meetingRoomsImages, venueImages, workSpacesImages]);

  useEffect(() => {
    // set layout
    switch (true) {
      case coverImages.length >= 5:
        setImgLayout(EVenueImagesLayout.FIVE);
        break;

      case coverImages.length >= 3:
        setImgLayout(EVenueImagesLayout.THREE);
        break;

      default:
        setImgLayout(EVenueImagesLayout.TWO);
        break;
    }
  }, [coverImages]);

  return {
    coverImages,
    imgLayout,
  };
}

export enum EPricesFormFields {
  PRICE_HOUR = 'priceHour',
  PRICE_HALF_DAY = 'priceHalfDay',
  PRICE_DAY = 'priceDay',
  PAID_DURATION = 'minimumPaidDuration',
}

export type TPricesFormFieldsValues = {
  [EPricesFormFields.PRICE_HOUR]: string;
  [EPricesFormFields.PRICE_HALF_DAY]: string;
  [EPricesFormFields.PRICE_DAY]: string;
  [EPricesFormFields.PAID_DURATION]: string;
};

import styled from 'styled-components';

import {fontSizeMd, fontWeightBold, lineHeightLg} from 'constants/styleVars';
import Icon from 'view/components/Icon';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 185px 1fr 150px;
  grid-gap: 16px;
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;

export const PointType = styled.div`
  font-weight: ${fontWeightBold};
`;

export const ItemsContainer = styled.div`
  @media (min-width: 1200px) {
    width: 75%;
  }
  width: 100%;
`;

export const Distance = styled.div`
  justify-self: end;
`;

export const NameIcon = styled.div`
  display: flex;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 16px;
`;

import {useMemo} from 'react';

import {floorLevels, getKeyByValue} from 'constants/floorStorey';
import {filterSpacesWithFloorsByRoomType} from '../NW2VenueProfile/components/Header/helpers/NMMSpaceHelper';

import {ERoomType} from 'types/dto/ERoomType.type';
import {ISpacesData, ISpacesDataItem} from 'types/venue';
import {ESpacesHashes} from '../NW2VenueProfile/components/NMMSpaces/types';

export const ALL_SPACES_INCLUDE = 'All spaces include';
export const WORK_IN_PROGRESS = 'Work in progress';

const useAnchorsSpacesSection = (
  spaces: ISpacesData[],
  wip: ISpacesDataItem[],
  pageType: string,
) => {
  const anchorSections = useMemo(() => {
    let spacesMap: string[] = [];
    if (spaces) {
      const indexOfS = Object.values(ESpacesHashes).indexOf(
        pageType as unknown as ESpacesHashes,
      );
      const key = Object.keys(ERoomType)[indexOfS];
      const filteredSpaces = filterSpacesWithFloorsByRoomType(spaces, key);
      spacesMap = filteredSpaces.map((item) => {
        return getKeyByValue(floorLevels, +item.floorNumber) || ' ';
      });
      spacesMap.push(ALL_SPACES_INCLUDE);

      // WIP
      const wipByType = wip.filter(({roomType}) => roomType === key);
      if (wipByType.length !== 0) {
        spacesMap.unshift(WORK_IN_PROGRESS);
      }
    }
    return Object.values(spacesMap);
  }, [pageType, spaces, wip]);
  return [anchorSections];
};

export default useAnchorsSpacesSection;

import React, {useState} from 'react';
import styled from 'styled-components';
import {Popover} from 'antd';
import {TooltipPlacement} from 'antd/es/tooltip';

import Icon from 'view/components/Icon/Icon';
import {
  offsetXXLg,
  offsetDef,
  offsetXSm,
  offsetSm,
} from 'styles/configs/offset';

const StyledIcon = styled.div<{
  marginLeft?: string;
  height: string;
  width: string;
}>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  display: flex;
  align-items: center;
  margin-left: ${({marginLeft}) => (marginLeft ? marginLeft : offsetDef)};
`;

const StyledContent = styled.div`
  white-space: normal;
  word-wrap: break-word;
  width: 280px;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${offsetXSm};
`;
const StyledSvg = styled(Icon)`
  margin-right: ${offsetSm};
`;
type TProps = {
  textIndex: number;
  marginLeft?: string;
  iconHeight?: string;
  iconWidth?: string;
  placement?: TooltipPlacement;
};
const TextPopover = ({
  textIndex,
  marginLeft,
  iconHeight = offsetXXLg,
  iconWidth = offsetXXLg,
  placement = 'top',
}: TProps) => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };
  const {title, text} = baseTexts[textIndex];
  return (
    <Popover
      content={<StyledContent>{text}</StyledContent>}
      trigger='hover'
      title={title}
      open={visible}
      onOpenChange={handleVisibleChange}
      placement={placement}
    >
      <StyledIcon marginLeft={marginLeft} height={iconHeight} width={iconWidth}>
        <Icon icon='INFORMATION_CIRCLE' />
      </StyledIcon>
    </Popover>
  );
};

export default TextPopover;
const baseTexts = [
  {
    title: "What's legal entity?",
    text:
      ' Businesses are registered as such in the respective commercial register and have an officially ' +
      'assigned registration number. Individual entrepreneurs usually do not have to be registered in ' +
      'the commercial register, but are subject to different regulations.',
  },
  {
    title: 'What’s a legal business name?',
    text: (
      <p>
        The legal name is the official business name of your company that
        identifies it to the government of the country it is registered in. It
        must include the abbreviation of the legal entity form.
        <StyledIconWrapper>
          <StyledSvg icon='CHECK' iconColor NW2Green600Color size={17} />
          Grace Hotel Management SE
        </StyledIconWrapper>
        <StyledIconWrapper>
          <StyledSvg icon='CLOSE' iconColor errorColor size={17} />
          Grace Hotel
        </StyledIconWrapper>
      </p>
    ),
  },
  {
    title: 'What’s a registration number?',
    text:
      'The registration number is the number that was assigned to your' +
      ' company when it was registered with the government. It varies based' +
      ' on the country your business is registered in.',
  },
  {
    title: 'What’s a company tax identification number?',
    text:
      'Company Tax Identification Number (Tax ID) is a number that was assigned' +
      ' to your company at the time of registration and varies based on the country' +
      ' your business is registered in. ',
  },
  {
    title: "What's a signatory?",
    text:
      'Signatory holds the power of attorney and is entitled to sign contracts.' +
      'Details of this person are required to enable our payment solution for your ' +
      'business to receive customer bookings and, subsequently, get paid.',
  },
  {
    title: "What's a business owner?",
    text:
      'Business Owners are Individuals who directly or indirectly own 25% or more of the total shares, voting rights or ' +
      'other equity in the organization. If no one owns 25% or more of the organization, information about a ' +
      'controller must get provided.',
  },
  {
    title: "What's a controller?",
    text:
      'Controller cannot be substituted for a signatory but still has the' +
      ' power to make important business decisions within your company and, in some contexts, could be considered as second in charge.',
  },
  {
    title: (
      <div
        style={{
          textTransform: 'uppercase',
          fontWeight: '900',
        }}
      >
        Show announcement
      </div>
    ),
    text:
      'On activation, your selected venues will view this announcement for the first time they log in until the checkbox' +
      ' ‘Do not show this message again’ is confirmed. Activation and deactivation is possible anytime. ',
  },
];

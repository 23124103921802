import * as History from 'history';
import {matchPath} from 'react-router-dom';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {
  RouteValues,
  SupplierHeaderItems,
  TLayout,
  TLayoutComponent,
  TMenuReturnType,
} from 'view/common/Header/types';
import {Routes} from 'constants/routes';
import {
  adminMenu,
  customerMenu,
  guestMenu,
  agentMenu,
  propertyManagerMenu,
  supplierMenu,
} from './menuConfigs';

const getIsExactPath = (
  customRoutes: RouteValues[],
  currentPath: RouteValues,
) =>
  customRoutes.find((route: RouteValues) => {
    return matchPath(route, currentPath);
  }) || '';

export const getCurrentMenu = (
  role: EUserRoleCognito,
  venueId: string,
): TMenuReturnType => {
  switch (role) {
    case EUserRoleCognito.ROLE_SUPPLIER:
      return supplierMenu({venueId});
    case EUserRoleCognito.ROLE_PROPERTY_MANAGER:
      return propertyManagerMenu({venueId});
    case EUserRoleCognito.ROLE_CUSTOMER:
      return customerMenu;
    case EUserRoleCognito.ROLE_ADMIN:
      return adminMenu();
    case EUserRoleCognito.ROLE_AGENT:
      return agentMenu({venueId});
    default:
      return guestMenu;
  }
};

const setHeaderStyle = (
  role: EUserRoleCognito,
  currentPath: string,
  venueId?: string,
): TLayout => {
  const {
    layout: {defaultLayoutType, customRoutesLayout},
  } = getCurrentMenu(role, venueId || '');
  let menuComponents = defaultLayoutType.components;
  let layoutType = defaultLayoutType.type;

  const currentExactPath = getIsExactPath(
    Object.keys(customRoutesLayout) as RouteValues[],
    currentPath as RouteValues,
  );

  const isCustomRoutesLayout = customRoutesLayout[currentPath as RouteValues];

  if (isCustomRoutesLayout && currentExactPath) {
    menuComponents = customRoutesLayout[currentExactPath]
      ?.components as TLayoutComponent[];
    layoutType = customRoutesLayout[currentExactPath]?.type as string;
  }

  return {
    type: layoutType,
    components: menuComponents,
  };
};

const getActiveItemFromRoute = (pathname: RouteValues): SupplierHeaderItems => {
  switch (pathname) {
    case Routes.publicVenue:
    case Routes.corporateVenue:
      return SupplierHeaderItems.LocationButton;
    case Routes.bookingsListRooms:
      return SupplierHeaderItems.BookingMeetingRooms;
    case Routes.bookingsListDesks:
      return SupplierHeaderItems.BookingDesks;
    case Routes.publicVenueSpaces:
    case Routes.corporateVenueSpaces:
      return SupplierHeaderItems.Spaces;
    case Routes.publicVenueAvailability:
      return SupplierHeaderItems.Availability;
    case Routes.resources:
      return SupplierHeaderItems.Services;
    default:
      return SupplierHeaderItems.VenueDetails;
  }
};

const getActiveDropdownItemFromRoute = (
  location: History.Location<unknown>,
): SupplierHeaderItems | '' => {
  if (location.hash) {
    switch (location.hash) {
      case '#desk':
        return SupplierHeaderItems.WorkDesks;
      case '#room':
        return SupplierHeaderItems.MeetingRooms;
      default:
        return '';
    }
  } else {
    switch (location.pathname) {
      case Routes.publicVenue:
      case Routes.corporateVenue:
        return SupplierHeaderItems.VenueDetails;
      default:
        return '';
    }
  }
};

export {
  setHeaderStyle,
  getIsExactPath,
  getActiveItemFromRoute,
  getActiveDropdownItemFromRoute,
};

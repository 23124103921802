import React from 'react';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button';

import {
  Content,
  Section,
  Container,
  StyledTitle,
  Paragraph,
  Title,
  SecondaryText,
  StyledUnorderedList,
  StyledList,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {offsetXLg} from 'styles/configs/offset';

const StyledNW2Button = styled(NW2Button)`
  margin-top: ${offsetXLg};
`;

const TextContent = () => {
  const openCookieSettings = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    (window as any).Optanon && (window as any).Optanon.ToggleInfoDisplay();
  };

  return (
    <Content>
      <Section>
        <Container>
          <Title>Data Controller</Title>
          <Paragraph>HRS GmbH</Paragraph>
          <Paragraph>Breslauer Platz 4</Paragraph>
          <Paragraph>50668 Köln</Paragraph>
          <Paragraph>Deutschland</Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Paragraph>
            is responsible for the data processing on this service.
          </Paragraph>
          <Paragraph>in following referenced as "we", "our", "us".</Paragraph>
        </Container>
      </Section>

      <Section>
        <Title>What are Cookies?</Title>
        <Paragraph>
          A cookie is a small file attachment, which is placed in the browser of
          your computer or on your mobile device. So-called "first party
          cookies" are cookies controlled by the company, which operates the
          domain on which the cookies are placed. Our cookies are therefore
          "first party cookies". Whenever we allow third parties to use cookies
          through the web pages and apps (in following referenced as "Products")
          of us, these cookies are "third party cookies".
        </Paragraph>
        <Paragraph>
          In addition to cookies, other comparable tracking technologies may be
          used. For example, they include tracking pixels (called pixel tags,
          web bugs, or GIFs), tracking URLs, local storage objects (LSO) or
          software developer kits (SDKs). A tracking pixel is a tiny graphic
          only one pixel in size which is sent either via a web page request, in
          an app, or in an advertisement to your computer. Pixels can be used to
          request data from your device, for example your type of device or your
          operating system, the IP address, and the time of your visit. Pixels
          are also used to operate and access cookies in your Browser. Tracking
          URLs can be used to determine via which referring website our Products
          are visited. LSO works in a similar manner as cookies, but the
          information is stored locally on your browser. SDKs, however, are
          small code components within apps, which work in a manner similar to
          cookies and tracking pixels.
        </Paragraph>
        <Paragraph>
          For the purposes of the present rules regarding cookies, all of these
          technologies are collectively referred to as "cookies".
        </Paragraph>
        <Paragraph>
          Depending on their function and their intended use, cookies can be
          divided into four categories: Strictly Necessary Cookies, Functional
          Cookies, Performance Cookies, and Targeting Cookies.
        </Paragraph>
      </Section>

      <Section>
        <Title>Your Cookie Settings on this Product</Title>
        <Paragraph>
          Click below button to view your current settings of cookies on this
          Product and manage your preference including the option to withdraw
          previously given consent and object to cookies.
        </Paragraph>
        <StyledNW2Button buttonType='secondary' onClick={openCookieSettings}>
          Cookies settings
        </StyledNW2Button>
      </Section>

      <Section>
        <Title>Cookies on This Product Which Do Not Require Consent</Title>
        <StyledTitle>Strictly Necessary Cookies</StyledTitle>
        <StyledUnorderedList>
          <StyledList>
            Strictly Necessary Cookies ensure functions without which you could
            not use this site as intended.
          </StyledList>
          <StyledList>
            <Paragraph>
              Purpose of using Strictly Necessary Cookies includes:
            </Paragraph>
            <StyledUnorderedList>
              <StyledList>
                displaying our Products to you and ensuring proper functioning
              </StyledList>
              <StyledList>
                creating user account, log in, and managing your bookings
              </StyledList>
              <StyledList>
                exchange information with partners if strictly necessary (e.g.
                payment / refund in case of contractual agreements)
              </StyledList>
              <StyledList>
                serving content according to your preferred language and
                currency settings as well as your search and view history for
                your convenient, efficient and user-friendly surfing experience
                on our Products
              </StyledList>
              <StyledList>
                storing your log-in data to ensure that you do not need to
                reenter them again every time you visit our page. Your password,
                however, is always encrypted.
              </StyledList>
              <StyledList>
                preparing reports about our Products' activities and
                functionality
              </StyledList>
              <StyledList>
                management of cookie settings and cookie consent
              </StyledList>
            </StyledUnorderedList>
          </StyledList>
          <StyledList>
            Above purposes are indispensable for correct functionality of our
            Products considering that they enhance user-friendliness and ensure
            that your visit to us is as pleasant as possible or is necessary to
            fulfill a contract with you or our partner. The legal basis for use
            of these cookies necessary for the purpose of the company is Article
            6, Paragraph 1, letter f of the General Data Protection Regulation.
          </StyledList>
          <StyledList>
            Strictly necessary cookies can not be deactivated using the function
            of this page. You can deactivate cookies in general in your browser
            at all times. However, this may result in the website not
            functioning properly.
          </StyledList>
        </StyledUnorderedList>
      </Section>

      <Section>
        <Container>
          <Title>Cookies on This Product Which Require Consent</Title>
          <Paragraph>
            In addition to strictly necessary cookies, we also uses functional
            cookies, performance cookies, and targeting cookies for various
            purposes. We also integrates third party contents in our Products.
            For example, this includes the installation of social plugins of
            different social networks, videos from video platforms, special
            features like chat windows or maps. These third party providers may
            install cookies while you visit our Products and, as a result, for
            example, receive information that you have accessed our Products.
            Please visit the websites of such third party providers to receive
            more information regarding their use of cookies. Whenever you have
            decided to basically not grant or withdraw your consent for the use
            of cookies which require consent, we will ensure that these cookies
            are no longer installed on your computer in the future. Whenever you
            decide against functional cookies, performance cookies, and
            targeting cookies, under some circumstances, you may not be able to
            use some functions on our Products.
          </Paragraph>
        </Container>
        <Container>
          <StyledTitle>Functional Cookies</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Functional cookies enable us to provide enhanced functionality and
              personalisation. Apart from the universal functionality, we from
              time to time develop features that we offer to part of our
              customers based on their profile and interest. As such, we collect
              information such as your language and country settings, whether or
              not you registered our user account, what hotels you have viewed
              and booked with functional cookies to provide features and/or
              content that fits best for your personal need and experience. We
              also provide additional communication channel for example web and
              app push notification, in which functional cookies is required to
              deliver the messages to your browser or device.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Performance Cookies</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use performance cookies to obtain an idea of how visitors use
              our Products to improve and further develop our Products, to
              analyze the effectiveness of advertisements and notices, and to
              ensure that we continue to remain interesting and relevant. The
              collected information is aggregated and therefore analyzed in an
              anonymous manner. Performance Cookies collect information about
              how our Products are used. The data collected by us cover pages
              you have visited, referring and exit pages, platforms used, and
              emails you have opened and to which you have reacted. This also
              means that we may collect data regarding your usage behavior on
              our Products, including the number of mouse clicks on a specific
              page, mouse movements, scrolling activities, search terms entered
              by you, and the text entered by you in the respective fields. In
              addition, we use performance cookies as a part of our
              online/mobile advertising campaigns to find out how users use our
              Products after viewing an online ad and analyse the performance of
              different campaigns. This also includes advertisement on
              third-party pages.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Targeting Cookies</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Targeting Cookies are used to display ads which are relevant for
              the user and target his or her specific interests.
            </StyledList>
          </StyledUnorderedList>
        </Container>
      </Section>

      <Section>
        <Container>
          <Title>Types of Information</Title>
          <Paragraph>
            When you use our Products, we possess below types of information
            about you. We will share the information to partners according to
            your consent of different cookies category.
          </Paragraph>
        </Container>
        <Container>
          <StyledTitle>Technical Data</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Including but not limited to device type, browser, operation
              system and its version, network and connections
            </StyledList>
            <StyledList>
              Technical performance of our Products, including but not limited
              to the page loading time, bounce rate, time on site, error
              information our Products produced
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Traffic Data</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Information about from which channel or marketing campaign you
              reached our Products
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Page Data</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Information of the page settings, including but not limited to
              URL, page type, name, title, version, language, currency
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>User Account Data</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Type of account you registered or logged in, method you used to
              register or login, register or login status
            </StyledList>
            <StyledList>
              Number of booking and/or cancellation you made with the account /
              device
            </StyledList>
            <StyledList>
              Type of loyalty program you add or use with your account
            </StyledList>
            <StyledList>Your newsletter sign up status</StyledList>
            <StyledList>
              User Account Data does not include your personal information,
              contact information and credit card information.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Search Data</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Information you provided for a search, including but not limited
              to location, hotel, date of travel, number of rooms and number of
              persons you searched for
            </StyledList>
            <StyledList>
              Your interaction with the search result, including but not limited
              to viewing, clicking, filtering and sorting
            </StyledList>
            <StyledList>
              Information of the search result, including but not limited to
              hotel name, hotel location, hotel type, price, type of room
              offers, guest rating, breakfast option and cancellation option
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Booking Data</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Information of your booking, including but not limited to date of
              travel, number of rooms, number of persons, loyalty program,
              booking type, payment method and additional request to hotel
            </StyledList>
            <StyledList>
              Information of the hotel you have booked or cancelled, e.g. hotel
              name, hotel location, hotel type, price, type of room offers,
              guest rating, breakfast option and cancellation option
            </StyledList>
            <StyledList>
              Booking Data does not include your personal information, contact
              information and credit card information.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Unique Identifiers</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              MyHRS ID, My Hotel.de ID, Booking ID, Device ID, Google ID, Adjust
              ID, Accengage ID, Dynamid Yield ID, Adobe ID.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Paragraph>
          Further on we will only reference to these categories.
        </Paragraph>
      </Section>

      <Section>
        <Title>Use of Cookies on this Product</Title>
        <Container>
          <Paragraph>Partners using Strictly Necessary Cookies</Paragraph>
        </Container>
        <Container>
          <StyledTitle>Google Tag Manager</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use Google Tag Manager, a service of Google Inc., 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"), to
              control what scripts to be executed on our Products. Google Tag
              Manager allows website tags to be managed through an interface.
              Google Tag Manager only implements tags. This means, no cookies
              are used and no personal data is collected. Google Tag Manager
              triggers other tags, which in turn may collect data. However,
              Google Tag Manager does not access this data. In order to monitor
              system stability and performance, Google Tag Manager may collect
              some aggregated data about tag firing. This data does not include
              any user-specific identifiers that could be associated with a
              particular individual. For more information about Google Tag
              Manager, please visit
              https://www.google.com/intl/de/tagmanager/use-policy.html.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>OneTrust</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              OneTrust, a service by OneTrust Technology Limited located in
              Dixon House, 1 Lloyd’s Avenue, London EC3N 3DQ, United Kingdom, is
              a service we use to manage users' consent on cookies usage. Once
              you are on our products, your preference of cookies usage will be
              stored in the "OptanonConsent" cookie created by OneTrust in your
              browser. We will further create / block the cookies of other
              partners according to your preference. OneTrust only collects
              consent and it does not process personal information or share this
              information.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Partner Program</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We are in collaboration with a list of affiliate and strategic
              partners to provide you with our services. If you want to book
              through our partners, you will be redirected to our products and
              be able to use our hotel search and booking services. If you
              subsequently book a hotel with our products after such redirect,
              we have to inform our partners with some booking details, which we
              will use to fulfill our contractual and/or payment obligation with
              our partners. The information shared does not include any of your
              personal information.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to our partners:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Traffic Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              <Paragraph>
                The following partners are examples of such collaborations:
              </Paragraph>
              <Paragraph>
                1. Allgemeiner Deutscher Automobil-Club e.V. (ADAC), Hansastraße
                19, 80686 München
              </Paragraph>
              <Paragraph>
                2. Deutsche Bahn Vertrieb GmbH, Stephensonstr. 1, 60326
                Frankfurt a. Main
              </Paragraph>
              <Paragraph>
                3. idealo internet GmbH, Zimmerstraße 50, 10117 Berlin
              </Paragraph>
            </StyledList>
            <StyledList>
              There are several more minor affiliate or strategic partners.
              Usually you are redirected from the partners site to our website.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Awin Conversion Pixel</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use Awin (AWIN AG, Eichhornstraße 3, 10785 Berlin) as one of
              our advertising partners and use Awin Conversion Pixel for the
              purpose of correctly assign the success of an advertising medium
              and the corresponding billing of hotel bookings within Awin
              network. Personal data is not collected, processed or used by Awin
              in this context.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Awin Conversion Pixel:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Traffic Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              If you would like to know more information about Awin's data
              processing, you can find it here:
              https://www.awin.com/de/rechtliches
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Google Hotel Ads</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Google Hotel Ads is one of the advertising partners we use to
              provide advertising which might be of actual interest for you. A
              conversion pixel is used for the purpose of correctly assign the
              success of an advertising medium and the corresponding billing of
              hotel bookings to Google Hotel Ads. Personal data is not
              collected, processed or used by Google Hotel Ads in this context.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Google Hotel Ads:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Traffic Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Trivago</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Trivago (trivago N.V., Kesselstraße 5 - 7, 40221 Düsseldorf,
              Germany) is one of the advertising partners we use to provide
              advertising which might be of actual interest for you. A
              conversion pixel is used for the purpose of correctly assign the
              success of an advertising medium and the corresponding billing of
              hotel bookings to Trivago. Personal data is not collected,
              processed or used by Trivago in this context.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Trivago:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Traffic Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Dynamic Yield</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Dynamic Yield is a service of Dynamic Yield Ltd., Highlands House,
              Basingstoke Road, Spencers Wood, Reading, Berkshire, England RG7
              1NT. The Dynamic Yield referral tool optimizes our Products
              offering to turn your visit of our Products into a personal
              experience through targeted referrals and contents. In the
              process, we use the page contents you have accessed earlier to
              recommend equivalent or thematically similar hotels or other
              services which may possibly be relevant for you.
            </StyledList>
            <StyledList>
              For such purpose, Dynamic Yield collects pseudonymized information
              about your usage activities on our Products. Cookies are used
              which exclusively store pseudonymized information under a
              random-generated ID (pseudonym). Your exclusively anonymized IP
              address is stored for such purpose. Identification of specific
              persons is therefore not possible and can also not be (re)created
              afterwards.
            </StyledList>
            <StyledList>
              You can prevent data collection by Dynamic Yield by clicking on
              the link below. In this case, an opt-out cookie is installed which
              prevents future collection of data regarding your visit on the
              Products.
            </StyledList>
            <StyledList>
              In addition, you may find additional information regarding the
              tracking technology that is used at the following link:
              https://www.dynamicyield.com/platform-privacy-policy/
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Dynamic Yield:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Page Data, User Account Data, Search Data,
                  Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
      </Section>

      <Section>
        <Title>Partners using Functional Cookies</Title>
        <StyledTitle>Accengage</StyledTitle>
        <StyledUnorderedList>
          <StyledList>
            Accengage is a service of ACCENGAGE S.A.S, located at 31 rue du 4
            septembre, 75 002 Paris, France. We use Accengage on our Products to
            reach you via Push Notifications to inform you about your booking
            reminder, our latest product updates, timely hotel offers and other
            content that may interest you. In order to be able to provide you
            with content relating to your bookings and account, Accengage
            processes your browser ID, and your device ID for mobile devices, on
            our behalf. In addition, Accengage processes additional information
            regarding your interaction with the push notifications, type of
            device, operating system, browser, other device settings that you
            use (if applicable) and the content of your interest. Accengage does
            not process nor store personal data classified as “sensitive”.
          </StyledList>
          <StyledList>
            <Paragraph>
              We are providing below types of information regarding your use of
              our Products to Accengage:
            </Paragraph>
            <StyledUnorderedList>
              <StyledList>
                Technical Data, Traffic Data, Page Data, User Account Data,
                Search Data, Booking Data and Unique Identifiers
              </StyledList>
            </StyledUnorderedList>
          </StyledList>
          <StyledList>
            <Paragraph>
              You can revoke your consent to receive push notifications at any
              time. To do this, you can deactivate push notifications in your
              browser or your mobile device settings.
            </Paragraph>
            <StyledUnorderedList>
              <StyledList>
                <Paragraph>Mobile Push Notification:</Paragraph>
                <StyledUnorderedList>
                  <StyledList>
                    iOS:
                    https://support.apple.com/guide/iphone/change-notification-settings-iph7c3d96bab/ios
                  </StyledList>
                  <StyledList>
                    Android: https://support.google.com/android/answer/9079661
                  </StyledList>
                </StyledUnorderedList>
              </StyledList>
              <StyledList>
                <Paragraph>
                  Web Push Notification: Please open the following link for
                  directions of how to do this according to different browser
                  types:
                </Paragraph>
                <StyledUnorderedList>
                  <StyledList>
                    Google Chrome:
                    https://support.google.com/chrome/answer/3220216
                  </StyledList>
                  <StyledList>
                    Apple Safari:
                    https://support.apple.com/de-de/guide/safari/manage-website-notificationssfri40734/mac
                  </StyledList>
                  <StyledList>
                    Mozilla Firefox:
                    https://support.mozilla.org/de/kb/push-benachrichtigungen-firefox
                  </StyledList>
                </StyledUnorderedList>
              </StyledList>
            </StyledUnorderedList>
          </StyledList>
        </StyledUnorderedList>
      </Section>

      <Section>
        <Title>Partners using Performance Cookies</Title>
        <Container>
          <StyledTitle>Google Analytics</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Google Analytics is a service of Google Inc., 1600 Amphitheatre
              Parkway, Mountain View, CA 94043, USA ("Google").
            </StyledList>
            <StyledList>
              We use Google Analytics to collect information about your behavior
              on our Products to better understand your needs and interest.
              Google Analytics uses "cookies", meaning text files which are
              stored on your computer and permit analysis by Google of the use
              of our offering. The information collected by the cookie regarding
              use of our Products (including your anonymized IP address) is
              usually sent to a Google server in the US and stored there. Data
              in Google Analytics associated with cookies, user identifiers or
              advertising identifiers are retained for 38 months.
            </StyledList>
            <StyledList>
              Google will use the aforementioned information to analyze your use
              of our Products, prepare reports about the Products activities for
              us, and provide other services for us in connection with the use
              of web pages/apps and the Internet. The IP address, which is sent
              by your browser within the scope of Google Analytics, is not
              combined with other Google data. These data are only transferred
              by Google to third parties according to the legal regulations or
              within the scope of contract data processing. In no case will
              Google combine the data with other data collected by Google.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Google Analytics:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              By accepting the use of Performance Cookies, you agree to
              processing of the data collected about you by Google, the
              above-described manner of data processing, as well as the
              specified purpose. You can prevent the storage of cookies based on
              the respective setting of your browser software; however, we
              herewith inform you that, in this case, it may not be possible to
              fully use all functions of our Products. You may also prevent
              collection of the data generated by the cookie and related with
              your use of these web pages (including your IP address) for Google
              as well as the processing of such data by Google by downloading
              and installing the browser plugin available at the following link.
            </StyledList>
            <StyledList>
              For more information regarding Google Analytics and data privacy,
              please see: http://tools.google.com/dlpage/gaoptout?hl=en
            </StyledList>
          </StyledUnorderedList>
        </Container>

        <Container>
          <StyledTitle>Firebase Analytics</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Firebase is a real time database which makes it possible to embed
              real-time information on the website/apps. We use Firebase
              Analytics to collect information about your usage of our Mobile
              Apps. In the process, anonymized user data are transmitted to
              Firebase. Firebase is a subsidiary of Google and is domiciled in
              San Francisco (CA), US.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Firebase Analytics:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              The Data Privacy Notice of Firebase can be found at
              https://www.firebase.com/terms/privacy-policy.html.
            </StyledList>
          </StyledUnorderedList>
        </Container>

        <Container>
          <StyledTitle>Adobe Analytics</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Adobe Analytics is a service of Adobe Systems Software Ireland
              Limited: 4-6 Riverwalk, Citywest Business Campus, Dublin 24.
            </StyledList>
            <StyledList>
              We use Adobe Analytics to collect information about your behavior
              on our Products to better understand your needs and interest.
              Adobe Analytics uses cookies which are stored on your computer and
              enable analysis of the use of our offering by Adobe. The
              information collected by the cookie regarding use of our Products
              (including your anonymized IP address) is usually sent to an Adobe
              server in the US and stored there. Please note that the
              information regarding use of our Products generated by the cookie
              is sent to a server of Adobe, and the settings ensure that the IP
              address is anonymized prior to geolocation and replaced by a
              generic IP address prior to storage. Data in Adobe Analytics
              associated are retained for 25 months.
            </StyledList>
            <StyledList>
              Based on instructions by us, Adobe will use the information to
              analyze use of our Products by users and compile reports regarding
              the website activities. The IP address which is sent by your
              browser within the scope of Adobe Analytics is not combined with
              any other Adobe data. These data are only transmitted by Adobe to
              third parties in accordance with the legal regulations or within
              the scope of contract data processing. By accepting the use of
              Performance Cookies, you consent to processing of the data
              collected about you by Adobe, the type of data processing
              described above, as well as the specified purpose.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Adobe Analytics:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              You can prevent the storage of cookies by adjusting the settings
              of your browser software accordingly; please note, however, that,
              in this case, you may possibly be unable to fully use all
              functions of our Products.
            </StyledList>
            <StyledList>
              For more information regarding Adobe Analytics und data privacy,
              please see: https://www.adobe.com/uk/privacy.html
            </StyledList>
          </StyledUnorderedList>
        </Container>

        <Container>
          <StyledTitle>Adjust - Mobile Measurement Partner</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Among others, our Products also uses the analytic technology
              “adjust.com ” of Adjust GmbH (“Adjust”, Saarbrücker Straße 36,
              10405 Berlin, Germany). For analysis, Adjust uses IP addresses,
              device ID and advertising ID of users, which are exclusively used
              in a pseudonymized manner, however. This does not permit
              identification of any individual without additional information
              being provided. Such additional information is kept separate and
              protected by appropriate technical and organizational measures.
            </StyledList>
            <StyledList>
              The usage information which is collected is solely used by us to
              analyze the function and use of our Products through pseudonymized
              analyses and the generation of reports and/or graphics regarding
              the number of visits, number of pages accessed per user,
              performance of marketing campaign etc. The analyses are
              exclusively used for our own market research, performance review
              as well as optimization and need-based design of our Products.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Adjust:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              You may reject the collection and storage of data at all times
              effective for the future by sending any e-mail to
              optout@adjust.com and mentioning the name of the app.
            </StyledList>
          </StyledUnorderedList>
        </Container>

        <Container>
          <StyledTitle>Content Square</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We also use ContentSquare, a web analytics service provided by
              ContentSquare SAS (50 avenue Montaigne, 75008 Paris, France),
              which uses so-called "cookies". If you visited our Products, the
              following data in particular are stored: the IP address,
              information on the operating system, screen resolution, the pages
              you visited, the referrer URL from which you reach our Products,
              the date, time and duration you visited our Products, information
              about interactions with website elements (clicks, mouse position,
              scroll, hover, blur, focus, anonymized HTML content), booking
              information. IP Address will be stored by Content Sqaure for no
              longer than 3 days. Following such period IP is deleted. Content
              Square use IP address only for: Geo location up to city level,
              blacklist unwanted IP Address and log for system troubleshooting
              purposes. We will use aforementioned information to evaluate your
              use of our Products, to compile reports on website activity for us
              and to provide further services related to website and internet
              use. This use is pseudonymised. Data is retained up to 13 months.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Content Square:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Page Data, User Account Data, Search Data, and
                  Booking Data
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              If you do not want ContentSquare to have insight into your usage
              behaviour as just described, you can integrate a deactivation
              add-on into your browser. You can find further information at:
              http://optout.contentsquare.net/.
            </StyledList>
          </StyledUnorderedList>
        </Container>
      </Section>

      <Section>
        <Title>Partners using Targeting Cookies</Title>
        <Container>
          <StyledTitle>Act-On Beacon Tracker</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Google Analytics is a service of Google Inc., 1600 Amphitheatre
              Parkway, Mountain View, CA 94043, USA ("Google").
            </StyledList>
            <StyledList>
              The Act-On Beacon Tracker (Act-On Software, Inc.) is used by us to
              identifies visitors and sends behavioral data from our Products
              back to Act-On to power segmentation, reports and complete our
              view of contact engagement. It works by placing a cookie on your
              browser. The cookie identifies and stores all behaviors that you
              take on our Products, Act-On landing pages and forms. Page visit
              data is sent to Act-On and is connected to your Act-On profile
              once Act-On can associate a cookie ID with an email address. This
              association is often the result of clicking through an email we
              sent to you from Act-On or a form submission. The Act-On beacon
              code originates from and is managed by Act-On, so this is
              considered a third-party cookie and Act-On is the cookie provider.
            </StyledList>
            <StyledList>
              For more information about Act-On Privacy Policy, please check:
              https://act-on.com/privacy-policy/
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Act-On Beacon Tracker:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>Page Data</StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Facebook Custom Audiences</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use Facebook Custom Audiences product (Facebook Inc. 1601 S.
              California Avenue, Palo Alto, CA 94304, USA) for Facebook in the
              context of usage-based online advertising. With your usage data, a
              non-reversible identification is generated, which can be
              transmitted to Facebook for analysis and marketing purposes. A
              Facebook cookie is set as part of this process. Information about
              your activities on our Products (e.g. surfing behavior, visited
              sub-pages etc.) is collected. Your anonymized IP address is saved
              and used for a geographical adjustment. We can also use the
              geographic information that Facebook has collected from your
              profile for advertising segmentation.
            </StyledList>
            <StyledList>
              You can find further information on the purpose and scope of data
              collection and the further processing and use of data as well as
              data protection settings in the data protection guidelines of
              Facebook (https://www.facebook.com/privacy/explanation). In
              general, data will be erased after 180 days.
            </StyledList>
            <StyledList>
              Please click here if you do not want data collection via Facebook
              pixels. Alternatively, you can deactivate the Facebook pixel on
              the page of the Digital Advertising Alliance under the following
              link: http://www.aboutads.info/choices/
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Facebook Custom Audiences:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data
                  (User Type), Search Data (searched location, travelling date,
                  number of guests, search term, hotels displayed according to
                  your search criteria, hotel ID, location and price of hotels
                  you viewed), and Booking Data (booked hotel ID, booking price,
                  hotel location, number of guests and travelling date)
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>DoubleClick by Google</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Doubleclick by Google is a service of Google Inc., 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google"). We
              use Doubleclick by Google to target internet users who may have
              interest in our Products and serve them our ads. Doubleclick by
              Google uses cookies to show you ads which are relevant for you.
              Your browser is assigned a pseudonymous identification number (ID)
              to check which ads are displayed in your browser and which ads are
              viewed. The cookies contain no personal information. The use of
              DoubleClick cookies only allows Google and its partner web pages
              to display ads on the basis of prior visits of our or other web
              pages on the Internet. The information generated by the cookies is
              sent by Google to a server in the US for analysis and is stored
              there. Data are only transferred by Google to third parties in
              compliance with the legal regulations or within the scope of
              contract data processing. In no case will Google combine your data
              with other data collected by Google.
            </StyledList>
            <StyledList>
              By giving your consent to Targeting Cookies, you consent to the
              processing of the data collected about you by Google, the above
              said type of data processing, as well as the specified purpose.
              You may prevent the storage of cookies by using the respective
              setting of your browser software; however, we herewith inform you
              that, in this case, it may not be possible to fully use all
              functions of our Products. You may also prevent the collection of
              data generated by the cookies and related to your use of these web
              pages for Google as well as the processing of such data by Google
              by downloading and installing the browser plugin available via the
              following link under the Item DoubleClick Deactivation Plugin.
              Alternatively, you may deactivate the Doubleclick cookies on the
              page of the Digital Advertising Alliance at the following link.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to DoubleClick by Google:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data, and Booking Data
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Google Ads Conversion Tracking</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              In addition, we use Google Ads Conversion Tracking to attribute a
              hotel booking to a correct advertising medium. Google Ads installs
              a cookie on your computer whenever you accessed our Products via a
              Google Ad. These cookies expire after 30 days and are not used for
              personal identification. When a user visits certain pages of our
              Products and the cookie has not yet expired, Google and we can
              determine that the user clicked on an ad and was forwarded to this
              page. Each and every Google Ads customer receives a different
              cookie. Cookies can therefore not be tracked via the web pages of
              Google Ads customers. The information which is collected through
              conversion cookies serves to prepare conversion statistics for
              Google Ads customers who have opted for conversion tracking.
              Google Ads customers (We) find out about the total number of users
              who clicked on their ad and who were forwarded to a page with a
              conversion tracking tag. However, they do not receive any
              information which can be used to personally identify any users.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Google Ads Conversion Tracking:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data, Booking Data and Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              If you do not want to participate in the tracking procedure, just
              click the upper bottom to conveniently accept or reject cookies
              which require consent.
            </StyledList>
            <StyledList>
              You can also block and delete cookies by changing your browser
              settings. To manage cookies, most browsers make it possible to
              accept or reject all cookies or only accept certain types of
              cookies. The procedure to manage and delete cookies can be found
              in the Help function, which is integrated in the browser. In case
              you restrict the use of cookies, you will not be able to use all
              interactive functions of our Products. To deactivate Google
              Conversion Pixels, please block the “googleadservices.com” cookie
              in your preferred browser. Click here for Google’s Data Privacy
              Notice regarding conversion tracking.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Google Remarketing</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use the remarketing function of Google Inc. to retarget website
              & app visitors within the Google advertising network and show
              interest-based ads to visitors who may be interested in our
              Products. For such purpose, a so-called cookie is installed, which
              makes it possible to recognize a visitor as long as such visitor
              accesses web pages belonging to the advertising network of Google.
              On such pages, the visitor may be shown ads which refer to
              contents accessed earlier by the visitor on our Products, which
              use the remarketing function of Google. According to its own
              statement, Google does not collect any personal data in the
              process.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Google Remarketing:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Traffic Data, Page Data, User Account Data,
                  Search Data and Booking Data
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              If you do not wish to participate in the tracking procedure, click
              the upper button to conveniently accept or reject cookies which
              require consent. You may also adjust the respective settings at
              http://www.google.com/settings/ads. Alternatively, you may
              deactivate the use of cookies for interest-based advertising via
              the Network Advertising Initiative by following the instructions
              at http://www.networkadvertising.org/managing/opt_out.asp.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>AWIN Journey</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use Awin (AWIN AG, Eichhornstraße 3, 10785 Berlin) as one of
              our advertising partners and use Awin Journey Pixel to let our
              partners showing advertising that is of your interest to you. As
              such the pixel is collecting anonymized information about the page
              you have visited on our Products. Personal data is not collected,
              processed or used by Awin in this context.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Awin Conversion Pixel:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>Traffic Data and Page Data</StyledList>
              </StyledUnorderedList>
            </StyledList>
            <StyledList>
              If you would like to know more information about Awin's data
              processing, you can find it here:
              https://www.awin.com/de/rechtliches
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Criteo Retargeting</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              Our Products use Criteo with its re-targeting technologies. We use
              these technologies to make our Internet offering more interesting
              for you. This technology makes it possible to target Internet
              users who have already expressed an interest in our Products with
              advertising on the websites and apps of Criteo. We are convinced
              that displaying personalized, interest-based advertising is
              usually more interesting for internet users than advertising which
              lacks such a personal element. The display of such advertising
              elements on the pages of our partners is based on cookie
              technology and analysis of prior usage behavior. This type of
              advertising is entirely pseudonymous. No usage profiles are
              combined with your personal data. If you do not wish to
              participate in this tracking procedure, simply click the upper
              button to conveniently reject or accept cookies which require
              consent. You may also adjust the respective settings at
              https://www.criteo.com/privacy/.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Criteo Retargeting:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Technical Data, Page Data, Search Data, Booking Data and
                  Unique Identifiers
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>Microsoft Bing Conversion Tracking</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We use conversion tracking of Bing Ads (Microsoft Corporation, One
              Microsoft Way, Redmond, WA 98052-6399, USA) to let Bing show you
              advertising that is of your interest and attribute a hotel booking
              to the correct advertising medium. Whenever you click on a Bing
              ad, a cookie is installed on your computer. As a result, both Bing
              as well as we can determine that a user was forwarded to our
              Products by the Bing ads and has reached a pre-specified
              destination page (conversion page). If you do not want to
              participate in the described tracking procedure, you may reject
              the installation of a cookie, which is required for such purpose
              by adjusting the browser setting accordingly. Other information
              regarding data privacy and cookies used at Microsoft Bing can be
              found on the Microsoft website: Data Privacy Notice
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to Microsoft Bing Conversion Tracking:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>
                  Traffic Data, Page Data and Booking Data
                </StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <StyledTitle>LinkedIn</StyledTitle>
          <StyledUnorderedList>
            <StyledList>
              We are using the insight-tag/Retargeting tag of LinkedIn to let
              LinkedIn show you advertising that is of your interest and
              attribute a hotel booking to the correct advertising medium. The
              LinkedIn Insight Tag drops a cookie on your web browsers when you
              click on our ad banner in LinkedIn and visit our Products. Cookie
              information is matched against LinkedIn’s member data for analysis
              and marketing purposes. Information about your activities on our
              Products (e.g. surfing behavior, visited sub-pages, downloaded a
              white paper etc.) is collected. Your IP address, truncated or
              hashed, is saved and used for a geographical adjustment. You can
              find further information on the purpose and scope of data
              collection here: https://www.linkedin.com/legal/cookie-policy. We
              will process these data to learn more on our audience. You can
              object to this data transfer under
              https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out?trk.
              In general, the data will be erased after 180 days. We don’t
              combine the LinkedIn Data with data in our databases.
            </StyledList>
            <StyledList>
              <Paragraph>
                We are providing below types of information regarding your use
                of our Products to LinkedIn:
              </Paragraph>
              <StyledUnorderedList>
                <StyledList>Page Data</StyledList>
              </StyledUnorderedList>
            </StyledList>
          </StyledUnorderedList>
        </Container>
      </Section>

      <Section>
        <Title>Managing and Deleting Cookies</Title>
        <Paragraph>
          Use the upper button to easily reject or accept categories of cookies
          which require consent. You may also block and delete cookies by
          changing your browser settings. To manage cookies, most browsers allow
          accepting or rejecting all cookies or only accept certain types of
          cookies. The process to manage and delete cookies can be found in the
          Help function integrated in your browser. Whenever you restrict the
          use of cookies, you may not be able to use all interactive functions
          of our Products.
        </Paragraph>
      </Section>

      <Section>
        <Title>Deactivating or Managing Your Browser Settings</Title>
        <StyledUnorderedList>
          <StyledList>
            <Paragraph>
              The following list provides additional information on how to
              deactivate or manage your cookie settings in your browser:
            </Paragraph>
            <StyledUnorderedList>
              <StyledList>
                Google Chrome:
                https://support.google.com/chrome/answer/95647?hl=de
              </StyledList>
              <StyledList>
                Firefox:
                https://support.mozilla.org/de-de/kb/enable-and-disable-cookies-website-preferences
              </StyledList>
              <StyledList>
                Internet Explorer:
                https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
              </StyledList>
              <StyledList>
                Safari:
                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              </StyledList>
            </StyledUnorderedList>
          </StyledList>
        </StyledUnorderedList>
      </Section>

      <Section>
        <Container>
          <SecondaryText>Version v1.1</SecondaryText>
        </Container>
        <Container>
          <SecondaryText>Effective date: 2.12.2020</SecondaryText>
        </Container>
      </Section>
    </Content>
  );
};

export default TextContent;

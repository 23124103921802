import React, {memo, useCallback, useMemo} from 'react';

import NMMSection from 'view/components/NMMSection';
import SpaceAmenities from './SpaceAmenities/SpaceAmenities';
import useToggle from 'hooks/useToggle';
import useUpdateSpace from 'view/venue/hooks/useUpdateSpace';
import NameAndFloor from './NameAndFloor/NameAndFloor';
import Description from './Description/Description';
import Price from './Price/Price';
import SetupsAndCapacities from './SetupsAndCapacity/SetupsAndCapacities';
import Dimensions from './Dimensions/Dimensions';
import BookabilityType from './BookabilityType/BookabilityType';
import AdditionalActions from './AdditionalActions/AdditionalActions';
import TypeAndCapacity from './TypeAndCapacity/TypeAndCapacity';

import {ISpacesDataItem, TVenueFloors} from 'types/venue';
import {ESpaceDetailsContentSections} from '../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {SpaceDetailsImages} from './SpaceDetailsImages/SpaceDetailsImages';
import {useInitialFormValues} from '../hooks/useInitialFormValues';

type TProps = {
  space: ISpacesDataItem;
  floors: TVenueFloors;
  roomType: ERoomType;
  sectionName: ESpaceDetailsContentSections;
};

const SpaceDetailsSection = ({
  space,
  floors,
  sectionName,
  roomType,
}: TProps) => {
  const {
    id,
    amenities,
    documents,
    name,
    description,
    priceHour,
    priceHalfDay,
    priceDay,
    minimumPaidDuration,
    unitCapacities,
    unitDimensions,
    isRfp,
    type,
    capacity,
  } = space;

  const {
    initialDescription,
    initialNameAndFloor,
    initialPrices,
    initialAmenities,
    initialCapacities,
    initialDimensions,
    initialBookabilityType,
  } = useInitialFormValues({space});

  const [isEditorShowed, onEditorShowed] = useToggle(false);

  const {updateSpace, isSending} = useUpdateSpace({
    space,
    endHandler: onEditorShowed,
  });

  const commonProps = useMemo(
    () => ({
      onEditorShowed,
      updateSpace,
      isSending,
      isEditorShowed,
    }),
    [isEditorShowed, isSending, onEditorShowed, updateSpace],
  );

  const noEditModeSections = [
    ESpaceDetailsContentSections.IMAGES,
    ESpaceDetailsContentSections.ADDITIONAL_ACTIONS,
  ];

  const roomSections = useMemo(
    () => ({
      [ESpaceDetailsContentSections.NAME_AND_LOCATION]: (
        <NameAndFloor
          name={name}
          floors={floors}
          initialValues={initialNameAndFloor}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.IMAGES]: (
        <SpaceDetailsImages spaceId={id} images={documents} />
      ),
      [ESpaceDetailsContentSections.DESCRIPTION]: (
        <Description
          description={description}
          initialValues={initialDescription}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.PRICES]: (
        <Price
          initialValues={initialPrices}
          priceHour={priceHour}
          priceHalfDay={priceHalfDay}
          priceDay={priceDay}
          minimumPaidDuration={minimumPaidDuration}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: (
        <SpaceAmenities
          initialValues={initialAmenities}
          roomType={roomType}
          section={sectionName}
          amenities={amenities}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: (
        <SpaceAmenities
          initialValues={initialAmenities}
          roomType={roomType}
          section={sectionName}
          amenities={amenities}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.SETUPS_AND_CAPACITIES]: (
        <SetupsAndCapacities
          initialValues={initialCapacities}
          unitCapacities={unitCapacities}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.DIMENSIONS]: (
        <Dimensions
          initialValues={initialDimensions}
          unitDimensions={unitDimensions}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.BOOKABILITY_TYPE]: (
        <BookabilityType
          initialValues={initialBookabilityType}
          isRfp={!!isRfp}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.ADDITIONAL_ACTIONS]: (
        <AdditionalActions spaceId={id} />
      ),
    }),
    [
      amenities,
      commonProps,
      description,
      documents,
      floors,
      id,
      initialAmenities,
      initialBookabilityType,
      initialCapacities,
      initialDescription,
      initialDimensions,
      initialNameAndFloor,
      initialPrices,
      isRfp,
      minimumPaidDuration,
      name,
      priceDay,
      priceHalfDay,
      priceHour,
      roomType,
      sectionName,
      unitCapacities,
      unitDimensions,
    ],
  );

  const deskSections = useMemo(
    () => ({
      [ESpaceDetailsContentSections.NAME_AND_LOCATION]: (
        <NameAndFloor
          name={name}
          floors={floors}
          initialValues={initialNameAndFloor}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.SPACE_TYPE_AND_CAPACITY]: (
        <TypeAndCapacity type={type} capacity={capacity} {...commonProps} />
      ),
      [ESpaceDetailsContentSections.IMAGES]: (
        <SpaceDetailsImages spaceId={id} images={documents} />
      ),
      [ESpaceDetailsContentSections.DESCRIPTION]: (
        <Description
          description={description}
          initialValues={initialDescription}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.PRICES]: (
        <Price
          initialValues={initialPrices}
          priceHour={priceHour}
          priceHalfDay={priceHalfDay}
          priceDay={priceDay}
          minimumPaidDuration={minimumPaidDuration}
          hasPriceHalfDay={false}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.INCLUDED_IN_SPACE]: (
        <SpaceAmenities
          initialValues={initialAmenities}
          roomType={roomType}
          section={sectionName}
          amenities={amenities}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY]: (
        <SpaceAmenities
          initialValues={initialAmenities}
          roomType={roomType}
          section={sectionName}
          amenities={amenities}
          {...commonProps}
        />
      ),
      [ESpaceDetailsContentSections.ADDITIONAL_ACTIONS]: (
        <AdditionalActions spaceId={id} />
      ),
    }),
    [
      name,
      floors,
      initialNameAndFloor,
      commonProps,
      id,
      documents,
      description,
      initialDescription,
      initialPrices,
      priceHour,
      priceHalfDay,
      priceDay,
      minimumPaidDuration,
      initialAmenities,
      roomType,
      sectionName,
      amenities,
      type,
      capacity,
    ],
  );

  const getSections = useCallback(
    (sectionName: ESpaceDetailsContentSections, roomType: ERoomType) => {
      if (roomType === ERoomType.WORK_SPACE) {
        return deskSections[sectionName as keyof typeof deskSections];
      }

      if (roomType === ERoomType.MEETING_ROOM) {
        return roomSections[sectionName as keyof typeof roomSections];
      }
    },
    [deskSections, roomSections],
  );

  return (
    <NMMSection
      id={sectionName}
      title={sectionName}
      noEditModeSections={noEditModeSections}
      isEditOn={isEditorShowed}
      onEdit={onEditorShowed}
      data-testid={`section-space-details-${sectionName}`}
    >
      {getSections(sectionName, roomType)}
    </NMMSection>
  );
};

export default memo(SpaceDetailsSection);

import styled, {css} from 'styled-components';

import {
  borderRadiusLg,
  fontSizeXSm,
  lineHeightSm,
  mdBp,
  NW2Gray200Color,
  nw2VenuesListZIndex,
  offsetDef,
  offsetLg,
  offsetSm,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  shortListHeight,
  whiteColor,
  xLgBp,
} from 'constants/styleVars';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${whiteColor};
  flex: 1;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  @media (max-width: ${mdBp - 1}px) {
    transform: translate3d(0, 0, 0); // fix position fixed children for IOS
    overflow: hidden;
  }

  @media (min-width: ${mdBp}px) {
    flex-direction: row;
    flex-flow: row-reverse;
  }
`;

// map
export const StyledMap = styled.div<{isMapExpanded: boolean}>(
  ({isMapExpanded}) => css`
    width: 100%;

    @media (max-width: ${mdBp - 1}px) {
      display: flex;
      flex: 1;
    }

    @media (min-width: ${mdBp}px) {
      padding-left: ${isMapExpanded ? '' : offsetXXLg};
      width: ${isMapExpanded ? '100%' : '50%'};
      transition: width 0.15s;
    }

    @media (min-width: ${xLgBp}px) {
      width: ${isMapExpanded ? '100%' : `calc(100% - 825px)`};
    }
  `,
);

// list
export const StyledListWrapper = styled.div`
  padding-top: ${offsetDef};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const StickyContainer = styled.div`
  position: relative;

  @media (max-width: ${mdBp - 1}px) {
    display: flex;
    flex: 1;

    > div {
      flex: 1;
    }
  }

  @media (min-width: ${mdBp}px) {
    position: sticky;
    top: 0;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    z-index: 1;
  }
`;

export const StyledList = styled.div<{
  isMapExpanded: boolean;
  hasShortList: boolean;
}>(
  ({isMapExpanded, hasShortList}) => css`
    display: flex;
    flex-direction: column;
    background-color: ${whiteColor};
    width: 50%;
    padding: ${isMapExpanded ? 0 : `0 0 0 ${offsetXXLg}`};
    transition: width 0.15s;
    padding-bottom: ${hasShortList && offsetXXLg};

    @media (min-width: ${mdBp}px) {
      width: ${isMapExpanded ? 0 : '50%'};
    }
    @media (min-width: ${xLgBp}px) {
      width: ${isMapExpanded ? 0 : '825px'};
    }
  `,
);

// mobile
export const StyledListMobile = styled.div<{
  height: string;
  translateY: string;
  hasShortList: boolean;
}>(
  ({height, translateY, hasShortList}) =>
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: ${whiteColor};
      border-top-left-radius: ${borderRadiusLg};
      border-top-right-radius: ${borderRadiusLg};
      position: fixed;
      left: 0;
      right: 0;
      top: ${hasShortList ? `calc(100% - ${shortListHeight})` : '100%'};
      padding-bottom: ${hasShortList && shortListHeight};
      transform: translateY(${translateY});
      transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1); // from antd
      will-change: transform;
      height: ${height};
      z-index: ${nw2VenuesListZIndex};
    `,
);

export const StyledListHeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding: 0 ${offsetDef};
`;

export const StyledListHeaderTitleMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${offsetXSm} ${offsetDef};
  margin: 0 -${offsetDef};
  border-bottom: 1px solid ${NW2Gray200Color};
  user-select: none;
`;

export const StyledListHeaderTitleMobileCount = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
`;

export const StyledControlItem = styled.div`
  padding: 0 ${offsetXSm} ${offsetXSm};

  span {
    display: block;
    width: 60px;
    height: 3px;
    background-color: ${NW2Gray200Color};
    border-radius: ${borderRadiusLg};
  }
`;

export const StyledSortMobileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  text-transform: uppercase;
  padding: ${offsetXXSm} ${offsetSm};

  @media (min-width: ${mdBp}px) {
    padding: ${offsetXXSm} ${offsetLg};
  }

  & + * {
    border-left: 1px solid ${NW2Gray200Color};
  }
`;

export const StyledSortMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${offsetSm} 0;
`;

export const StyledDiv = styled.div`
  padding: 0 ${offsetDef};
`;

import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2Button from 'view/components/NW2Button/NW2Button';
import useVenueId from 'view/venue/hooks/useVenueId';
import {useAppDispatch} from 'store/hooks';

import {getDeletionModalBody} from '../NMMSpacesDetails/utils/getDeletionModalBody';
import {deleteSpace, getSpaceDeletionStatus} from 'store/venue/actions';

import {
  EAdditionalActionsModal,
  ESpaceDeletionStatuses,
} from '../NMMSpacesDetails/components/AdditionalActions/types';
import {
  Container,
  StyledNW2Button,
} from '../NMMSpacesDetails/components/AdditionalActions/AdditionalActions.styles';
import {PATH_TO_REDUCER_SPACE_DELETION_STATUS} from 'constants/venue';

export const useAdditionalActionsModal = ({
  spaceId,
  isWip,
}: {
  spaceId: number;
  isWip?: boolean;
}): {
  isModalShowed: boolean;
  onModalShow: (arg: EAdditionalActionsModal) => void;
  onModalClose: () => void;
  modalTitle: string | null;
  modalBody: ReactNode | null;
  modalFooter: ReactNode | null;
} => {
  const dispatch = useAppDispatch();
  const {venueId} = useVenueId();
  const [showedModal, setShowedModal] =
    useState<EAdditionalActionsModal | null>(null);
  const deletionStatus = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_SPACE_DELETION_STATUS}`),
  );

  // Modal actions
  const onModalShow = (modalId: EAdditionalActionsModal) => {
    if (modalId === EAdditionalActionsModal.DELETE_SPACE && !deletionStatus) {
      dispatch(getSpaceDeletionStatus(spaceId));
    }
    setShowedModal(modalId);
  };

  const onModalClose = useCallback(() => {
    setShowedModal(null);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(deleteSpace({spaceId, venueId, isWip}));
    onModalClose();
  }, [dispatch, isWip, onModalClose, spaceId, venueId]);

  const {modalTitle, modalBody, modalFooter} = useMemo(() => {
    switch (showedModal) {
      // TODO: add logic Duplicate space
      // case EAdditionalActionsModal.DUPLICATE_SPACE: {
      //   return {
      //     modalTitle: 'Duplicate space',
      //   };
      // }

      // // TODO: add logic Temporarily disable space
      // case EAdditionalActionsModal.TEMPORARILY_DISABLE_SPACE:
      //   return {
      //     modalTitle: `Temporarily disable space`,
      //   };

      case EAdditionalActionsModal.DELETE_SPACE:
        return {
          modalTitle: 'Delete space',
          modalBody: getDeletionModalBody(deletionStatus),
          modalFooter: ![
            ESpaceDeletionStatuses.HAS_ACTIVE_BOOKINGS,
            ESpaceDeletionStatuses.HAS_ACTIVE_OFFERS,
          ].includes(deletionStatus) && (
            <Container>
              <StyledNW2Button
                buttonType='secondary'
                onClick={onModalClose}
                size='small'
                inline
              >
                Cancel
              </StyledNW2Button>
              <NW2Button
                buttonType='primary'
                onClick={onDelete}
                size='small'
                inline
              >
                Delete space
              </NW2Button>
            </Container>
          ),
        };

      default: {
        return {
          modalTitle: null,
          modalBody: null,
          modalFooter: null,
        };
      }
    }
  }, [deletionStatus, onDelete, onModalClose, showedModal]);

  return {
    isModalShowed: !!showedModal,
    onModalShow,
    onModalClose,
    modalTitle,
    modalBody,
    modalFooter,
  };
};

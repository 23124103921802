import styled from 'styled-components';

import {
  NW2Gray200Color,
  offsetXXLg,
  offsetNone,
  offsetXLg,
} from 'constants/styleVars';

const BORDER_RADIUS = '99px';

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${offsetXLg};
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${offsetXXLg} ${offsetNone};
  background: ${NW2Gray200Color};
  border-radius: ${BORDER_RADIUS};
`;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {EFormValidationName} from 'types/venue';
import {TAmount} from 'store/payment/types';
import {ICustomerOrdersReducer} from './types';
import {
  TAttendee,
  TGroupedDeskUnitsByDay,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';

const initialState: ICustomerOrdersReducer = {
  previewRooms: [],
  previewDesks: [],
  attendees: [],
  errorFormSections: [],
  isTotalDrawerVisible: false,
  totalAmount: {
    value: 0,
    currency: '',
  },
};

const bookingsCustomerSlice = createSlice({
  name: 'bookingsCustomer',
  initialState,
  reducers: {
    setPreviewRooms(state, action: PayloadAction<TGroupedUnitsByDay[]>) {
      state.previewRooms = action.payload;
    },
    setPreviewDesks(state, action: PayloadAction<TGroupedDeskUnitsByDay[]>) {
      state.previewDesks = action.payload;
    },
    setPreviewAttendees(state, action: PayloadAction<TAttendee[]>) {
      state.attendees = action.payload;
    },
    addPreviewDataFormError(state, action: PayloadAction<EFormValidationName>) {
      state.errorFormSections = [...state.errorFormSections, action.payload];
    },
    removePreviewDataFormError(
      state,
      action: PayloadAction<EFormValidationName>,
    ) {
      state.errorFormSections = [...state.errorFormSections].filter(
        (e) => e !== action.payload,
      );
    },
    setPreviewTotalDrawerVisible(state, action: PayloadAction<boolean>) {
      state.isTotalDrawerVisible = action.payload;
    },
    setPreviewTotalAmount(state, action: PayloadAction<TAmount>) {
      state.totalAmount = action.payload;
    },
  },
});

export const {
  setPreviewRooms,
  setPreviewDesks,
  setPreviewAttendees,
  addPreviewDataFormError,
  removePreviewDataFormError,
  setPreviewTotalDrawerVisible,
  setPreviewTotalAmount,
} = bookingsCustomerSlice.actions;

export default bookingsCustomerSlice.reducer;

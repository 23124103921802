import React, {FC, useState, useEffect} from 'react';
import {Calendar, Select} from 'antd';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import DateCell from './components/DateCell';
import Icon from 'view/components/Icon';
import PageContent from 'view/common/PageContent';

import {StyledRow, StyledCol, StyledSelect} from './VenueAvailability.styles';
import {PageTitle} from 'view/components/Typography';
import {EMPTY_OBJECT} from 'constants/app';
import {getVenueUnitBookingStatisticsByDates} from 'store/venues/apiActions';
import {setVenueUnitBookingStatistics} from 'store/venues/venuesSlice';

const initialDate = moment(); //TODO: we can change moment to Luxon, when we replace Antd

const VenueAvailability: FC = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState('');
  const [currentMonth, setCurrentMonth] = useState(initialDate.month());

  const venueId: number = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );

  useEffect(() => {
    if (selectedDate) {
      const month = moment(selectedDate).month();
      setCurrentMonth(month);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (venueId) {
      const requestDate = selectedDate || initialDate.format();

      dispatch(
        getVenueUnitBookingStatisticsByDates({
          requestDate,
          venueId,
        }),
      );
    }

    return () => {
      dispatch(setVenueUnitBookingStatistics(EMPTY_OBJECT));
    };
  }, [venueId, currentMonth, selectedDate, dispatch]);

  const onChange = (value: moment.Moment) => setSelectedDate(value.format());

  const dateCellRender = (value: moment.Moment) => {
    const cellValue = value.format();
    const dateValue = value.date();
    const isCurrent = value.isSame(moment().format(), 'day');
    const isSelected = value.isSame(selectedDate, 'day');

    return (
      <DateCell
        date={dateValue}
        value={cellValue}
        isSelected={isSelected}
        isCurrent={isCurrent}
      />
    );
  };

  const setMonth =
    ({
      onChange,
      value,
      month,
    }: {
      onChange: (month: number) => void;
      value: any;
      month: number;
    }) =>
    () => {
      const newValue = value.clone();
      newValue.month(month);
      onChange(newValue);
    };

  const headerRender = ({value, onChange}: any) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];
    const yearOptions = [];
    const months = [];

    const current = value.clone();
    const localeData = value.localeData();

    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let i = start; i < end; i++) {
      const monthOptionValue = moment.monthsShort()[i];
      monthOptions.push(
        <Select.Option value={i} key={`${monthOptionValue}`}>
          {monthOptionValue}
        </Select.Option>,
      );
    }

    const year = value.year();
    const yearOffset = 10; // defines range of years displayed as options
    for (let i = year - yearOffset; i < year + yearOffset; i += 1) {
      yearOptions.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>,
      );
    }

    const onSelectMonth = (selectedMonth: number | unknown) => {
      const newValue = value.clone();
      newValue.month(selectedMonth);
      onChange(newValue);
    };

    const onSelectYear = (newYear: number | unknown) => {
      const selectedYear = value.clone().year(newYear);
      onChange(selectedYear);
    };

    return (
      <StyledRow gutter={8} align='middle'>
        <StyledCol>
          <div>View by</div>
        </StyledCol>
        <StyledCol>
          <StyledSelect
            dropdownMatchSelectWidth={false}
            value={value.month()}
            onChange={onSelectMonth}
          >
            {monthOptions}
          </StyledSelect>
        </StyledCol>
        <StyledCol>
          <StyledSelect
            dropdownMatchSelectWidth={false}
            onChange={onSelectYear}
            value={value.year()}
          >
            {yearOptions}
          </StyledSelect>
        </StyledCol>
        <StyledCol>
          <Icon
            icon='LEFT_ARROW_CIRCLED'
            onClick={setMonth({onChange, value, month: value.month() - 1})}
          />
        </StyledCol>
        <StyledCol>
          <Icon
            icon='RIGHT_ARROW_CIRCLED'
            onClick={setMonth({onChange, value, month: value.month() + 1})}
          />
        </StyledCol>
      </StyledRow>
    );
  };

  return (
    <PageContent>
      <PageTitle>Manage Availability</PageTitle>
      <Calendar
        dateFullCellRender={dateCellRender}
        onChange={onChange}
        headerRender={headerRender}
      />
    </PageContent>
  );
};

export default VenueAvailability;

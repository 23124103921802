import styled from 'styled-components';

import {
  fontSizeMd,
  lineHeightDefault,
  offsetXSm,
  offsetXLg,
  offsetDef,
} from '../../constants/styleVars';

export const NoItemsBlock = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const NoItemsContent = styled.div`
  text-align: center;
`;

export const NoItemsText = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightDefault};
  margin-top: ${offsetXSm};
  margin-bottom: ${offsetXLg};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${offsetDef};
`;

import styled from 'styled-components';

import {
  fontSizeXSm,
  fontWeightExtraBold,
  lineHeightSm,
  NW2Gray200Color,
  NW2Gray800Color,
  offsetXXLg,
} from 'constants/styleVars';

export const Wrapper = styled.aside`
  height: 100%;
  padding: ${offsetXXLg} 0;
  border-right: 1px solid ${NW2Gray200Color};
`;

export const Container = styled.div`
  position: sticky;
  top: ${offsetXXLg};
`;

export const Title = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: ${fontWeightExtraBold};
  padding-left: ${offsetXXLg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${NW2Gray800Color};
  margin-bottom: ${offsetXXLg};
`;

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

import React from 'react';
import BookingChangeOverviewSidebarUnit, {
  extrasChangeLine,
} from './BookingChangeOverviewSidebarUnit';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import BookingChangeSidebarBodyInfoChanges from './BookingChangeSidebarBodyInfoChanges';
import Icon from 'view/components/Icon';
import {
  formatDate,
  getAddressStringFromBillingAddress,
} from 'utils/stringUtils';
import {
  getExtrasTotalPrice,
  getUnitsExtrasTotalPrice,
  shouldRenderUnit,
  useCompareOrders,
} from '../helpers';
import {IBookingChanges, IUnitBookingDetails} from 'types/bookingOrders';
import {EEventType, IBookedExtra} from 'types/venue';
import {
  FoodAndBeverageTitle,
  StyledBodyWrapper,
  StyledDate,
  StyledDateLine,
  StyledExtrasTotalPrice,
  StyledNewChange,
  StyledPrevChange,
  StyledPriceTitle,
  StyledSection,
  StyledTitle,
  StyledTotalPrice,
  StyledTotalPriceSubtitle,
} from '../BookingChangeOverviewSidebar.styles';
import TaxDisclaimer from 'view/common/TaxDisclaimer/TaxDisclaimer';
import {useIsVenueFromUSA} from 'view/venue/hooks/useIsUSA';

type TProps = {
  newCustomerOrder: IBookingChanges;
  prevCustomerOrder: IBookingChanges;
  currency?: string;
};

const BookingChangeOverviewSidebarBody = ({
  newCustomerOrder,
  prevCustomerOrder,
  currency,
}: TProps) => {
  const {
    unitInfoChanges,
    foodAndBeverageChanges,
    billingAddressChanges,
    bedroomsChanges,
  } = useCompareOrders(prevCustomerOrder, newCustomerOrder);
  const [isUSA] = useIsVenueFromUSA();

  if (
    !unitInfoChanges ||
    !newCustomerOrder ||
    !prevCustomerOrder ||
    !foodAndBeverageChanges ||
    !bedroomsChanges
  )
    return null;

  const previousBillingAddressString = getAddressStringFromBillingAddress(
    prevCustomerOrder.billingAddressDto,
  );
  const newBillingAddressString = getAddressStringFromBillingAddress(
    newCustomerOrder.billingAddressDto,
  );
  const {
    additionalReference: previousBillingAddressAdditionalReference,
    costCenter: previousBillingAddressCostCenter,
    companyName: previousBillingAddressCompanyName,
  } = prevCustomerOrder.billingAddressDto || {};

  const {
    additionalReference: newBillingAddressAdditionalReference,
    costCenter: newBillingAddressCostCenter,
    companyName: newBillingAddressCompanyName,
  } = newCustomerOrder.billingAddressDto || {};

  const {eventName: previousEventName, totalPrice: previousTotalPrice} =
    prevCustomerOrder || {};
  const {eventName: newEventName, totalPrice: newTotalPrice} =
    newCustomerOrder || {};
  const isEventNameChanged = previousEventName !== newEventName;
  const isTotalPriceChanged = previousTotalPrice !== newTotalPrice;
  const {
    isAddressChanged,
    isBillingAddressAdditionalReferenceChanged,
    isBillingAddressCostCenterChanged,
    isBillingAddressCompanyChanged,
  } = billingAddressChanges;
  const isShowBillingAddressChanges =
    isAddressChanged ||
    isBillingAddressAdditionalReferenceChanged ||
    isBillingAddressCostCenterChanged ||
    isBillingAddressCompanyChanged;
  let dayCounter = 0;

  return (
    <StyledBodyWrapper>
      {isEventNameChanged && (
        <StyledSection>
          <StyledTitle>Meeting name change</StyledTitle>
          <BookingChangeSidebarBodyInfoChanges
            previousValue={previousEventName}
            newValue={newEventName}
          />
        </StyledSection>
      )}

      {isShowBillingAddressChanges && (
        <StyledSection>
          <StyledTitle>Billing address change</StyledTitle>

          {isAddressChanged && (
            <BookingChangeSidebarBodyInfoChanges
              previousValue={previousBillingAddressString}
              newValue={newBillingAddressString}
            />
          )}
          {isBillingAddressAdditionalReferenceChanged && (
            <BookingChangeSidebarBodyInfoChanges
              previousValue={previousBillingAddressAdditionalReference}
              newValue={newBillingAddressAdditionalReference}
            />
          )}

          {isBillingAddressCostCenterChanged && (
            <BookingChangeSidebarBodyInfoChanges
              previousValue={previousBillingAddressCostCenter}
              newValue={newBillingAddressCostCenter}
            />
          )}
          {isBillingAddressCompanyChanged && (
            <BookingChangeSidebarBodyInfoChanges
              previousValue={previousBillingAddressCompanyName}
              newValue={newBillingAddressCompanyName}
            />
          )}
        </StyledSection>
      )}
      {newCustomerOrder.orderDays.map((day, dayIndex) => {
        let titleText = '';

        switch (day.eventType) {
          case EEventType.PRE_ARRIVAL:
            titleText = 'Pre-arrival';
            break;
          case EEventType.POST_EVENT:
            titleText = 'Post-event';
            break;
          default:
            dayCounter++;
            titleText = `Day ${dayCounter}`;
            break;
        }
        const foodAndBeverageChange = foodAndBeverageChanges[dayIndex];
        const bedroomsChange = bedroomsChanges[dayIndex];

        const dayUnitsChange = unitInfoChanges[dayIndex];

        const prevPriceEquipment = getUnitsExtrasTotalPrice(
          prevCustomerOrder.orderDays[dayIndex]
            .unitBookings as IUnitBookingDetails[],
        );
        const newPriceEquipment = getUnitsExtrasTotalPrice(
          day.unitBookings as IUnitBookingDetails[],
        );

        const prevPriceFoodAndBeverage = getExtrasTotalPrice(
          prevCustomerOrder.orderDays[dayIndex]
            .foodAndBeverage as IBookedExtra[],
        );

        const newPriceFoodAndBeverage = getExtrasTotalPrice(
          day.foodAndBeverage as IBookedExtra[],
        );

        const prevPriceBedrooms = getExtrasTotalPrice(
          prevCustomerOrder.orderDays[dayIndex].bedrooms as IBookedExtra[],
        );
        const newPriceBedrooms = getExtrasTotalPrice(
          day.bedrooms as IBookedExtra[],
        );

        const shouldRenderSection =
          day.unitBookings.some((dayBooking, bookingIndex) => {
            return shouldRenderUnit(
              dayBooking as IUnitBookingDetails,
              dayUnitsChange?.[bookingIndex],
              foodAndBeverageChange,
            );
          }) || bedroomsChange?.length > 0;

        if (shouldRenderSection) {
          return (
            <section key={`${day.orderId}_${dayIndex}`}>
              <StyledDateLine>
                <StyledTitle>{titleText}</StyledTitle>
                <StyledDate>
                  {formatDate(day.date, 'd MMM, YYYY', 'en-US')}
                </StyledDate>
              </StyledDateLine>
              {day.unitBookings.map((dayBooking, bookingIndex) => {
                const unitInfoChange = dayUnitsChange[bookingIndex];

                if (
                  shouldRenderUnit(
                    dayBooking as IUnitBookingDetails,
                    unitInfoChange,
                    unitInfoChange.bookedExtrasChanges || [],
                  )
                ) {
                  return (
                    <BookingChangeOverviewSidebarUnit
                      key={`${dayBooking.unitId} ${bookingIndex}`}
                      checkInDate={
                        unitInfoChange?.checkInDate || dayBooking.checkInDate
                      }
                      checkOutDate={
                        unitInfoChange?.checkOutDate || dayBooking.checkOutDate
                      }
                      participants={unitInfoChange?.participants}
                      setupStyle={unitInfoChange?.setupStyle}
                      equipmentChanges={unitInfoChange.bookedExtrasChanges}
                      prevPriceEquipment={prevPriceEquipment[bookingIndex]}
                      newPriceEquipment={newPriceEquipment[bookingIndex]}
                      packageId={unitInfoChange?.packageId}
                      packagePrice={unitInfoChange?.packagePrice}
                      currentParticipants={dayBooking.participants}
                      prevTotalPriceForPackageSet={
                        prevCustomerOrder.orderDays[dayIndex]
                          .totalPriceForPackageSet
                      }
                      newTotalPriceForPackageSet={day.totalPriceForPackageSet}
                      currency={currency}
                      unitInfo={(dayBooking as IUnitBookingDetails).unitInfo}
                    />
                  );
                }
              })}

              {foodAndBeverageChanges?.[dayIndex] &&
                foodAndBeverageChanges[dayIndex].length > 0 && (
                  <FoodAndBeverageTitle>
                    Food And Beverage{' '}
                  </FoodAndBeverageTitle>
                )}
              <StyledSection>
                {foodAndBeverageChanges[dayIndex]?.map(extrasChangeLine)}
              </StyledSection>
              {prevPriceFoodAndBeverage !== newPriceFoodAndBeverage && (
                <StyledExtrasTotalPrice>
                  <StyledPrevChange>
                    <PriceDisplay
                      price={prevPriceFoodAndBeverage}
                      currency={currency}
                    />
                  </StyledPrevChange>
                  <Icon
                    justify='center'
                    disabledColor
                    icon='NW2_ARROW_RIGHT_THIN'
                  />
                  <StyledNewChange>
                    <PriceDisplay
                      price={newPriceFoodAndBeverage}
                      currency={currency}
                    />
                  </StyledNewChange>
                </StyledExtrasTotalPrice>
              )}

              {bedroomsChange?.length > 0 && (
                <FoodAndBeverageTitle>Accommodation </FoodAndBeverageTitle>
              )}
              <StyledSection>
                {bedroomsChanges[dayIndex]?.map(extrasChangeLine)}
              </StyledSection>
              {prevPriceBedrooms !== newPriceBedrooms && (
                <StyledExtrasTotalPrice>
                  <StyledPrevChange>
                    <PriceDisplay
                      price={prevPriceBedrooms}
                      currency={currency}
                    />
                  </StyledPrevChange>
                  <Icon
                    justify='center'
                    disabledColor
                    icon='NW2_ARROW_RIGHT_THIN'
                  />
                  <StyledNewChange>
                    <PriceDisplay
                      price={newPriceBedrooms}
                      currency={currency}
                    />
                  </StyledNewChange>
                </StyledExtrasTotalPrice>
              )}
            </section>
          );
        }
        return null;
      })}

      {isTotalPriceChanged && (
        <>
          <StyledPriceTitle>Booking total</StyledPriceTitle>

          <StyledExtrasTotalPrice>
            <StyledPrevChange>
              <StyledTotalPrice>
                {' '}
                <PriceDisplay price={previousTotalPrice} currency={currency} />
                &nbsp;
              </StyledTotalPrice>
              <StyledTotalPriceSubtitle>
                <TaxDisclaimer isUSA={isUSA} />
              </StyledTotalPriceSubtitle>
            </StyledPrevChange>

            <Icon justify='center' disabledColor icon='NW2_ARROW_RIGHT_THIN' />
            <StyledNewChange>
              <StyledTotalPrice>
                <PriceDisplay price={newTotalPrice} currency={currency} />
                &nbsp;
              </StyledTotalPrice>
              <StyledTotalPriceSubtitle>
                <TaxDisclaimer isUSA={isUSA} />
              </StyledTotalPriceSubtitle>
            </StyledNewChange>
          </StyledExtrasTotalPrice>
        </>
      )}
    </StyledBodyWrapper>
  );
};

export default BookingChangeOverviewSidebarBody;

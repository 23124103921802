import React, {Fragment} from 'react';

import AccommodationBlock from './AccommodationBlock';

import {getAccommodationsData} from '../../helpers';
import {IDayResponse} from 'types/dto/IPublicVenue';

type TProps = {
  data: IDayResponse[];
  isPreArrival?: boolean;
};

const PrePostAccommodationBlock = ({data, isPreArrival}: TProps) => {
  return (
    <>
      {data.map((item, index) => {
        const {singleBedrooms, doubleBedrooms} = getAccommodationsData(
          item.bedrooms,
        );

        return (
          <Fragment key={index}>
            {!!(singleBedrooms.length || doubleBedrooms.length) && (
              <AccommodationBlock
                isPreArrival={isPreArrival}
                singleBedrooms={singleBedrooms}
                doubleBedrooms={doubleBedrooms}
                date={item.checkIn}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default PrePostAccommodationBlock;

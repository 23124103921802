import React from 'react';

import NW2Accordion from 'view/components/NW2Accordion/NW2Accordion';
import NW2AttendeesContent from './components/NW2AttendeesContent';

import {IPreviewUnit, IPreviewUnitResponse} from 'types/dto/IBooking.types';

type TProps = {
  unit?: IPreviewUnitResponse | IPreviewUnit;
  offerParticipants?: number;
};

const NW2BookingAttendees = ({unit, offerParticipants}: TProps) => {
  if (!unit) return null;
  const items = [
    {
      title: 'Share with attendees',
      subTitle:
        'Individuals added below will receive an email with venue and meeting details.',
      content: <NW2AttendeesContent offerParticipants={offerParticipants} />,
      expandable: false,
    },
  ];

  return <NW2Accordion items={items} />;
};

export default NW2BookingAttendees;

import styled from 'styled-components';

import {offsetXSm, navbarZIndex} from '../../../constants/styleVars';

export const NavbarContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${({theme}) => theme.navigation.background};
  color: ${({theme}) => theme.navigation.color};
  width: 100%;
  padding: ${offsetXSm};
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.15);
  z-index: ${navbarZIndex};
`;

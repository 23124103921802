import React from 'react';
import styled from 'styled-components';

import {NW2Gray200Color} from 'constants/styleVars';
import {
  TitleContainer,
  Content,
  Title,
  Divider,
  StyledIcon,
  Header,
} from 'view/components/NW2Accordion/components/NW2AccordionItem.styles';

export type TAccordionItem = {
  title: string | React.ReactNode;
  content: React.ReactNode;
  isExpanded: boolean;
  onExpandHandler: (expanded: boolean) => void;
  expandable?: boolean;
  isDivider?: boolean;
  className?: string;
};

const Container = styled.div`
  width: 100%;

  &:not(:first-child) {
    border-top: 1px solid ${NW2Gray200Color};
  }
`;

const MeetingRequestsAccordionItem = ({
  title,
  content,
  isExpanded,
  expandable = true,
  isDivider = true,
  onExpandHandler,
  className,
}: TAccordionItem) => {
  const toggleExpanded = () => {
    onExpandHandler(!isExpanded);
  };

  return (
    <Container className={className}>
      <Header>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        {expandable && (
          <StyledIcon
            icon='ARROW_UP_FILLED'
            expanded={isExpanded}
            onClick={toggleExpanded}
            transparent
          />
        )}
      </Header>
      {isExpanded && expandable ? (
        <>
          {isDivider && <Divider />}
          <Content isDivider={isDivider}>{content}</Content>
        </>
      ) : null}
    </Container>
  );
};

export default MeetingRequestsAccordionItem;

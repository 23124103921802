import React from 'react';
import {Dropdown} from 'antd';
import styled, {CSSProp} from 'styled-components';

import Icon from '../Icon';

import {
  borderRadiusDef,
  cardBoxShadow,
  darkenGrayColor,
  fontSizeSm,
  fontSizeXSm,
  fontWeightSemiBold,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXSm,
  textColor,
  whiteColor,
} from 'constants/styleVars';
import {SubBlockTitle} from 'view/components/Typography';
import {TColorProps} from 'view/components/Typography/Typography';
import {TextValue} from 'view/components/TextValue';
import {TIconPropsType} from 'view/components/Icon/types';

const Content = styled.div<{width?: number; styles?: CSSProp}>`
  color: ${textColor};
  box-sizing: border-box;
  box-shadow: ${cardBoxShadow};
  background-color: ${whiteColor};
  border-radius: ${borderRadiusDef};
  width: ${({width}) => (width ? width : 280)}px;
  padding: ${offsetXSm} ${offsetDef} ${offsetSm};

  ${({styles}) => styles}
`;

const Title = styled.div<{styles?: CSSProp}>`
  font-size: ${fontSizeSm};
  margin-bottom: ${offsetXSm};
  padding-bottom: ${offsetXXSm};
  font-weight: ${fontWeightSemiBold};
  border-bottom: 1px solid ${darkenGrayColor};

  ${({styles}) => styles}
`;

const SubTitle = styled(SubBlockTitle)`
  font-size: ${fontSizeXSm};
`;

const Row = styled.div`
  margin: ${offsetXXSm} 0 ${offsetSm};
  font-size: ${fontSizeXSm};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

type TContent = {
  subTitle?: string;
  subTitleColor?: TColorProps;
  caption: string | JSX.Element;
};

type TProps = {
  config: {
    title?: string;
    description?: string;
    content: TContent[];
    styles?: CSSProp;
    titleStyles?: CSSProp;
  };
  iconProps?: TIconPropsType;
  width?: number;
};

const ToolTipContent = ({
  config,
  width,
  iconProps = {icon: 'INFO', secondary: true},
}: TProps) => {
  const overlay = () => (
    <Content width={width} styles={config.styles}>
      {!!config.title && (
        <Title styles={config.titleStyles}>{config.title}</Title>
      )}
      {!!config.description && (
        <TextValue size='small'>{config.description}</TextValue>
      )}
      {config.content.map(({subTitle, caption, subTitleColor}, index) => (
        <Row key={index}>
          {!!subTitle && <SubTitle {...subTitleColor}>{subTitle}</SubTitle>}
          <div>{caption}</div>
        </Row>
      ))}
    </Content>
  );

  return (
    <Dropdown dropdownRender={overlay} placement='top' arrow>
      <Icon clickable {...iconProps} />
    </Dropdown>
  );
};

export default ToolTipContent;

import {useEffect, useState} from 'react';

import {IBookingChanges, IUnitBookingDetails} from 'types/bookingOrders';
import {
  IAddressChanges,
  IBookedChanges,
  IBookedExtrasChanges,
  IUnitInfoChanges,
} from './types';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {IBookedExtra} from 'types/venue';

export function compareExtras(
  previousBookedExtras: IBookedExtra[] = [],
  newBookedExtras: IBookedExtra[] = [],
): IBookedExtrasChanges[] {
  const bookedExtrasChanges: IBookedExtrasChanges[] = [];

  previousBookedExtras.forEach((previousExtra) => {
    const newExtra = newBookedExtras.find(
      (extra) =>
        extra.accommodationExtraId === previousExtra.accommodationExtraId,
    );
    const change: IBookedExtrasChanges = {} as IBookedExtrasChanges;

    if (newExtra) {
      if (previousExtra.bookedQuantity !== newExtra.bookedQuantity) {
        change.bookedQuantity = {
          prev: previousExtra.bookedQuantity,
          new: newExtra.bookedQuantity,
        };
        change.extraName = {
          prev: previousExtra.extraName,
          new: newExtra.extraName || '',
        };
      }
    } else {
      change.bookedQuantity = {
        prev: previousExtra.bookedQuantity,
        new: 0,
      };
      change.extraName = {
        prev: previousExtra.extraName,
        new: '',
      };
    }

    if (Object.keys(change).length !== 0) {
      bookedExtrasChanges.push(change);
    }
  });

  newBookedExtras.forEach((newExtra) => {
    const matchedExtra = previousBookedExtras.find(
      (extra) => extra.accommodationExtraId === newExtra.accommodationExtraId,
    );
    if (!matchedExtra) {
      const newChange: IBookedExtrasChanges = {
        bookedQuantity: {
          prev: 0,
          new: newExtra.bookedQuantity,
        },
        extraName: {
          prev: '',
          new: newExtra.extraName,
        },
      };
      bookedExtrasChanges.push(newChange);
    }
  });

  return bookedExtrasChanges;
}

export function compareUnitBookingInfo(
  previousUnitInfo: IUnitBookingDetails[] = [],
  newUnitInfo: IUnitBookingDetails[] = [],
): IUnitInfoChanges[] {
  const changes: IUnitInfoChanges[] = [];

  previousUnitInfo.forEach((prevInfo: IUnitBookingDetails, index: number) => {
    const newInfo = newUnitInfo[index];
    const change: IUnitInfoChanges = {};

    const fieldsToCompare: (keyof IUnitBookingDetails &
      keyof IUnitInfoChanges)[] = [
      'setupStyle',
      'checkInDate',
      'checkOutDate',
      'participants',
      'packageId',
      'packagePrice',
    ];

    fieldsToCompare.forEach((field) => {
      if (prevInfo[field] !== newInfo[field]) {
        (change[field] as IBookedChanges<string | number | undefined | null>) =
          {
            prev: prevInfo[field],
            new: newInfo[field],
          };
      }
    });

    const extrasChanges = compareExtras(
      prevInfo.bookedExtras,
      newInfo.bookedExtras,
    );

    if (extrasChanges.length) {
      change['bookedExtrasChanges'] = extrasChanges;
    }

    changes[index] = change;
  });

  return changes;
}

function compareBillingAddress(
  previousBillingAddress: ICustomerBillingAddress,
  newBillingAddress: ICustomerBillingAddress,
): IAddressChanges {
  let isAddressChanged = false;
  const fieldsToCompare: (keyof ICustomerBillingAddress)[] = [
    'companyName',
    'streetHouseNumber',
    'postCode',
    'city',
    'country',
  ];
  previousBillingAddress &&
    newBillingAddress &&
    fieldsToCompare.forEach((field) => {
      if (previousBillingAddress[field] !== newBillingAddress[field]) {
        isAddressChanged = true;
      }
    });
  const isBillingAddressAdditionalReferenceChanged =
    previousBillingAddress?.additionalReference !==
    newBillingAddress?.additionalReference;
  const isBillingAddressCostCenterChanged =
    previousBillingAddress?.costCenter !== newBillingAddress?.costCenter;
  const isBillingAddressCompanyChanged =
    previousBillingAddress?.companyName !== newBillingAddress?.companyName;

  return {
    isAddressChanged,
    isBillingAddressAdditionalReferenceChanged,
    isBillingAddressCostCenterChanged,
    isBillingAddressCompanyChanged,
  };
}

export function getUnitsExtrasTotalPrice(
  unitBooking: IUnitBookingDetails[] = [],
) {
  return unitBooking.map((booking) =>
    getExtrasTotalPrice(booking.bookedExtras),
  );
}

export function getExtrasTotalPrice(extras: IBookedExtra[]) {
  return extras?.reduce(
    (totalPriceSum, extra) => totalPriceSum + extra.totalPrice,
    0,
  );
}

export const useCompareOrders = (
  prevOrder: IBookingChanges,
  newOrder: IBookingChanges,
) => {
  const [unitInfoChanges, setUnitInfoChanges] = useState<IUnitInfoChanges[][]>(
    [],
  );
  const [foodAndBeverageChanges, setFoodAndBeverageChanges] = useState<
    IBookedExtrasChanges[][]
  >([]);
  const [billingAddressChanges, setBillingAddressChanges] =
    useState<IAddressChanges>({} as IAddressChanges);

  const [bedroomsChanges, setBedroomsChanges] = useState<
    IBookedExtrasChanges[][]
  >([]);

  useEffect(() => {
    const compareCustomerBooking = (
      prevCustomerOrder: IBookingChanges,
      newCustomerOrder: IBookingChanges,
    ) => {
      const mergedDays = newCustomerOrder.orderDays?.map((day, index) => ({
        new: day,
        prev: prevCustomerOrder.orderDays[index],
      }));

      const unitInfoChanges: IUnitInfoChanges[][] = [];

      const foodAndBeverageChanges: IBookedExtrasChanges[][] = [];
      const bedroomsChanges: IBookedExtrasChanges[][] = [];

      mergedDays?.map((day) => {
        unitInfoChanges.push(
          compareUnitBookingInfo(
            day.prev.unitBookings as IUnitBookingDetails[],
            day.new.unitBookings as IUnitBookingDetails[],
          ),
        );
        foodAndBeverageChanges.push(
          compareExtras(
            day.prev.foodAndBeverage as IBookedExtra[],
            day.new.foodAndBeverage as IBookedExtra[],
          ),
        );
        bedroomsChanges.push(
          compareExtras(
            day.prev.bedrooms as IBookedExtra[],
            day.new.bedrooms as IBookedExtra[],
          ),
        );
      });

      const previousBillingAddress = prevCustomerOrder.billingAddressDto;
      const newBillingAddress = newCustomerOrder.billingAddressDto;
      const billingAddressChanges = compareBillingAddress(
        previousBillingAddress,
        newBillingAddress,
      );

      setUnitInfoChanges(unitInfoChanges);
      setFoodAndBeverageChanges(foodAndBeverageChanges);
      setBedroomsChanges(bedroomsChanges);
      setBillingAddressChanges(billingAddressChanges);
    };
    compareCustomerBooking(prevOrder, newOrder);
  }, [prevOrder, newOrder]);

  return {
    unitInfoChanges,
    foodAndBeverageChanges,
    billingAddressChanges,
    bedroomsChanges,
  };
};

export const convertToObject = <T extends Record<string, any>>(
  arr: T[],
  key: string,
) =>
  arr.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {} as Record<string, T>);

export const shouldRenderUnit = (
  dayBooking: IUnitBookingDetails,
  unitInfoChange: IUnitInfoChanges,
  bookedExtrasChange: IBookedExtrasChanges[],
) => {
  const hasUnitInfoChanges =
    unitInfoChange && Object.keys(unitInfoChange).length > 0;
  const hasBookedExtrasChanges =
    bookedExtrasChange && bookedExtrasChange.length > 0;

  return dayBooking && (hasUnitInfoChanges || hasBookedExtrasChanges);
};

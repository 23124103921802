import {pluralize} from 'utils/helpers';

import {ISpacesData, ISpacesDataItem, ISpacesDataItemImage} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IPreviewExtra} from 'store/venues/types';
import {IOfferUnitExtra} from 'types/offer';

const makeAllSpacesArr = (data: ISpacesData[]) =>
  data.reduce((total: any, item: ISpacesData) => {
    return [...total, ...item.units];
  }, []);

export const getSpaceById = (data: ISpacesData[], id: number) => {
  const allSpaces = makeAllSpacesArr(data);
  return allSpaces.find((space: ISpacesDataItem) => space.id === id);
};

export const getCoverImage = (images: ISpacesDataItemImage[]) =>
  images.find((image: ISpacesDataItemImage) => image.isCover);

export const getCapacityRange = (
  data: any,
  capacity: number,
  roomType: ERoomType,
) => {
  if (roomType === ERoomType.WORK_SPACE)
    return `${pluralize('desks', capacity)}`;

  const pluralizedCapacity = pluralize('participants', capacity);

  if (!data) return '-';

  const makeAllCapacitiesArr = (data: any) =>
    data.reduce((total: any, item: any) => {
      return [...total, item.capacity];
    }, []);

  const allCapacities = makeAllCapacitiesArr(data);
  if (allCapacities.length === 0) return `${pluralizedCapacity}`;

  const min = Math.min(...allCapacities);
  if (min === capacity) return `${pluralizedCapacity}`;

  return `${min}-${capacity} participants`;
};

export const moveToEndWhere = <T>(
  arr: T[],
  fieldName: keyof T,
  fieldValue: string | number,
): T[] =>
  arr.concat(
    arr.splice(
      arr.findIndex((item) => item[fieldName] === fieldValue),
      1,
    ),
  );

export const getPriceFromExtras = (extra: IPreviewExtra | IOfferUnitExtra) => {
  if (typeof extra.price === 'object') {
    return extra.price.value;
  }
  return extra.price;
};

export const getIcon = (isItemAdded: boolean, hasPrice: boolean) => {
  if (isItemAdded) {
    return 'CHECK';
  }
  if (hasPrice) {
    return 'PLUS';
  }
  return '';
};

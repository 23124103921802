import {EAppTheme} from 'types/app';
import whiteTheme from './whiteTheme';
import darkTheme from './darkTheme';

const APP_THEME = {
  [EAppTheme.THEME_WHITE]: whiteTheme,
  [EAppTheme.THEME_DARK]: darkTheme,
};

export default APP_THEME;

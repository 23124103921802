import React, {memo} from 'react';
import _get from 'lodash/get';

import Image from 'view/components/Image';

import {OfferUnitImage} from 'view/venue/Offer/components/OfferUnitImage/OfferUnitImage';

import {TImage} from 'types/app';
import {useAppSelector} from 'store/hooks';
import {
  borderRadiusDef,
  lgBp,
  NW2Gray100Color,
  NW2Gray800Color,
  offsetLg,
} from 'constants/styleVars';

interface IProps {
  images: TImage[];
  isPlaceholder?: boolean;
}
export const SpaceImage = memo(({images, isPlaceholder}: IProps) => {
  const viewportWidth = useAppSelector((state) =>
    _get(state, 'app.deviceType.width'),
  );

  const spaceImgHeight = viewportWidth <= lgBp ? '164px' : '106px';
  const spaceImgPlaceholderWidth = viewportWidth <= lgBp ? '152px' : '98px';

  const coverImage = images?.find((img) => img.isCover);

  return coverImage?.url && !isPlaceholder ? (
    <Image
      height={spaceImgHeight}
      borderRadius={borderRadiusDef}
      src={coverImage.url}
      alt='Unit photo'
      data-testid='booking-details-unit-card-image'
    />
  ) : (
    <OfferUnitImage
      iconColor={NW2Gray800Color}
      bgColor={NW2Gray100Color}
      iconWidth={spaceImgPlaceholderWidth}
      boxHeight={spaceImgHeight}
      spacing={`${offsetLg} 0`}
    />
  );
});

import React from 'react';
import {ModalProps} from 'antd';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import StyledModal from 'view/components/Modal';
import useLoginInSystem from 'hooks/useLoginInSystem';

import {modalSmallWidth} from 'constants/styleVars';
import {setOpenPasswordPopup} from 'store/app/appSlice';
import {Nw2EnterPassword} from 'view/components/EnterPassword';
import {
  ModalContainer,
  modalBodyStyles,
  StyledIcon,
} from 'view/auth/utils/auth.styles';
import {EOpenPasswordModalTypes, TOpenPasswordModalProps} from 'types/app';
import {Routes} from 'constants/routes';

type TProps = {
  openPasswordModal: boolean | TOpenPasswordModalProps;
};

function PasswordModal({openPasswordModal}: TProps & ModalProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeEndRegisterModal = () => {
    dispatch(setOpenPasswordPopup({openPasswordPopup: false}));
    navigate({pathname: Routes.mainLanding, search: ''});
  };

  const {completeNewPassword} = useLoginInSystem();

  let pageName = '';
  let btnOkText = '';
  let modalType = EOpenPasswordModalTypes.REGISTER;
  let notificationMessage = '';
  if (typeof openPasswordModal !== 'boolean') {
    pageName = openPasswordModal.pageName;
    btnOkText = openPasswordModal.btnOkText;
    modalType = openPasswordModal.type;
    notificationMessage = openPasswordModal.notificationMessage;
  }

  return (
    <StyledModal
      width={modalSmallWidth}
      bodyStyle={modalBodyStyles}
      onCancel={closeEndRegisterModal}
      footer={null}
      title={null}
      open={!!openPasswordModal}
      maskClosable={false}
    >
      <ModalContainer>
        <StyledIcon icon='LOGO' />
        <Nw2EnterPassword
          moveToSuccessPagePath={Routes.mainLanding}
          pageName={pageName}
          btnOkText={btnOkText}
          type={modalType}
          request={completeNewPassword}
          notificationMessage={notificationMessage}
        />
      </ModalContainer>
    </StyledModal>
  );
}

export default PasswordModal;

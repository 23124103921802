import styled, {css} from 'styled-components';

import NW2Accordion from 'view/components/NW2Accordion';

import {Link} from 'view/components/Typography';
import NW2Switch from 'view/components/NW2Switch';
import Icon from 'view/components/Icon';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';

import {
  NW2Gray600Color,
  fontWeightBold,
  fontSizeMd,
  offsetDef,
  offsetXLg,
  xsBp,
  fontSizeLg,
  fontWeightNormal,
  offsetXXSm,
  fontSizeSm,
  lgBp,
  offsetSm,
  offsetXSm,
  lineHeightLg,
  lineHeightDefault,
  offsetXXXSm,
  xlg,
  offsetXXLg,
  fontWeightExtraBold,
  blackColor,
  whiteColor,
  NW2Gray100Color,
  fontSizeXSm,
  NW2Gray900Color,
  NW2Gray200Color,
  borderRadiusXLg,
  offsetNone,
  mdBp,
  xLgBp,
} from 'constants/styleVars';

export const StyledExtrasList = styled.div<{isPreview?: boolean}>`
  & > div {
    margin-bottom: ${({isPreview}) => (isPreview ? offsetXSm : offsetSm)};
    margin-top: ${({isPreview}) => (isPreview ? offsetXSm : offsetXLg)};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const Price = styled.span`
  color: ${NW2Gray600Color};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightDefault};
`;

export const Name = styled.span`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${offsetXXXSm};

  @media (min-width: ${xsBp}px) {
    max-width: 280px;
  }

  @media (min-width: ${xLgBp}px) {
    margin-right: ${offsetDef};
    align-items: center;
  }
`;

export const UnitTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
`;

const Box = styled.div`
  display: grid;
  gap: ${offsetDef} 0;
`;

export const UnitBox = styled(Box)`
  @media (min-width: ${lgBp}px) {
    grid-template-columns: 162px 1fr;
    gap: 0 ${offsetXLg};
  }
`;

export const DefaultUnitBox = styled(Box)`
  @media (min-width: ${mdBp}px) {
    justify-content: start;
  }

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 162px 1fr;
    gap: 0 ${offsetXLg};
  }
`;

export const EquipmentsBox = styled.div`
  padding-top: ${offsetDef};
`;

export const UnitNameLink = styled(Link)<{hasMargin?: boolean}>`
  display: block;
  font-size: ${fontSizeMd};
  margin-bottom: ${({hasMargin}) => (hasMargin ? offsetXSm : offsetXXSm)};
`;

export const UnitInfo = styled.div`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightBold};

  & > span {
    font-weight: ${fontWeightNormal};
  }
`;

export const UnitName = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXSm};
`;

export const StyledAccordion = styled(NW2Accordion)`
  padding: ${offsetSm} 0;

  & > div {
    padding: ${offsetSm} ${offsetXLg};
  }
`;

export const ExtrasTitle = styled.span`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
`;

export const ExtraItemPreview = styled.div`
  display: flex;
  gap: 6px;
  font-size: ${offsetDef};
  line-height: ${lineHeightLg};
`;

export const ExtraItemPreviewQty = styled.span`
  display: flex;
  min-width: 34px;
  justify-content: space-between;
`;

export const Quantity = styled.span`
  margin-right: ${offsetXXSm};
`;

export const PackageSwitchContainer = styled.div<{isPackagesOptions?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({isPackagesOptions}) =>
    isPackagesOptions ? offsetNone : offsetXLg};
  margin-top: ${offsetXLg};

  @media (min-width: ${xlg}) {
    justify-content: flex-start;
  }
`;
export const StyledPackageSwitch = styled(NW2Switch)`
  gap: ${offsetXSm};
`;

export const StyledInfoIcon = styled.div`
  width: ${offsetXXLg};
  height: ${offsetXXLg};
  display: flex;
  align-items: center;
  margin-left: ${offsetXSm};
`;

export const StyledPopoverTitle = styled.div`
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const StyledPopoverContent = styled.div`
  white-space: normal;
  word-wrap: break-word;
`;

export const RadioBtnGroup = styled.div`
  display: grid;
  grid-gap: ${offsetDef};
  margin: ${offsetDef} 0;

  @media (min-width: ${xlg}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${xlg}) {
    grid-template-columns: 1fr 1fr;

    & > label:nth-child(3) {
      grid-column: span 2;
    }
  }
`;

export const RadioBtnLabel = styled.label<{checked: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: ${({checked}) =>
    checked ? `${blackColor}` : `${whiteColor}`};
  color: ${({checked}) => (checked ? `${whiteColor}` : `${blackColor}`)};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: ${offsetSm};
  border: 1px solid ${blackColor};

  &:hover {
    background-color: ${({checked}) =>
      checked ? `${blackColor}` : `${NW2Gray100Color}`};
  }

  input {
    display: none;
  }
`;

export const StyledRadioBtnText = styled.div`
  font-weight: ${fontWeightBold};
`;

export const StyledRadioBtnIcon = styled(Icon)`
  padding: ${offsetXXXSm};
  margin-bottom: ${offsetXXSm};
`;

export const StyledRadioBtnPrice = styled.div`
  font-size: ${fontSizeXSm};
`;

export const CardTitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const CardTitle = styled.div<{titleTextColor?: string}>`
  margin-left: ${offsetXSm};
  font-weight: ${fontWeightExtraBold};
  color: ${({titleTextColor}) => `${titleTextColor || NW2Gray900Color}`};
  letter-spacing: 0.02em;
`;

export const CardIncludedTitle = styled.p`
  font-weight: ${fontWeightBold};
  color: ${NW2Gray900Color};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const StyledEquipment = styled.div`
  margin-bottom: ${offsetXSm};
`;

export const StyledDivider = styled.div`
  margin-bottom: ${offsetDef};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const CardPrice = styled.div<{isFlexEnd?: boolean}>`
  display: ${({isFlexEnd}) => isFlexEnd && 'flex'};
  justify-content: ${({isFlexEnd}) => isFlexEnd && 'flex-end'};
  @media (max-width: ${xlg}) {
    display: flex;
    justify-content: flex-end;
  }
`;

// preview mode
export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusXLg};
  padding-bottom: ${offsetXLg};
`;

export const Container = styled.div`
  width: 100%;
  padding: ${offsetXLg} ${offsetXLg} 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${offsetXSm};
  margin-bottom: ${offsetDef};
`;

export const TitleContainer = styled.div`
  flex-grow: 1;
`;

export const Title = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
`;

export const StyledLeftSideItem = styled(LeftSideItem)<{
  paddingTop?: string;
  paddingBottom?: string;
  isNoBorderTop?: boolean;
}>`
  padding-bottom: ${({paddingBottom}) => paddingBottom};
  padding-top: ${({paddingTop}) => paddingTop};

  ${({isNoBorderTop}) =>
    isNoBorderTop &&
    css`
      &:before {
        display: none;
      }
    `};

  & + & {
    padding-top: 0;

    &:before {
      display: none;
    }
  }
`;

export const DefaultLeftSideItem = styled(StyledLeftSideItem)<{
  isDesktop?: boolean;
}>`
  ${({isDesktop}) =>
    isDesktop &&
    css`
      display: grid;
      grid-template-columns: 1fr 4fr;
    `}
`;

import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  ECreateOfferFormFields,
  TCreateOfferFormValues,
  TInitialExtras,
} from '../types';
import {ERoomSchemaNames, ISpacesDataItem} from 'types/venue';
import {dimensionsConverter} from 'utils/venueUtils';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {EDimensionsFormFields} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/components/Dimensions/DimensionsForm.types';
import {TPaidDuration} from 'view/venue/NW2VenueProfile/components/AddSpace/types';

interface IProps {
  handleSetFiles: (files: TFileWithPreview[]) => void;
  handleSetEditedSpace?: (space: ISpacesDataItem) => void;
  initialExtras?: Record<string, TInitialExtras>;
  allInitialExtras?: TInitialExtras;
  paidDurationOptions: TPaidDuration[];
  requestedSeatingPlan: ERoomSchemaNames;
}
export function useInitialData({
  handleSetFiles,
  handleSetEditedSpace,
  initialExtras,
  allInitialExtras,
  paidDurationOptions,
  requestedSeatingPlan,
}: IProps) {
  const venueFloors: number[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.floors`),
  );

  const [initialState, setInitialState] = useState<TCreateOfferFormValues>({});

  const handleSetInitialState = useCallback(
    (values: TCreateOfferFormValues) => {
      setInitialState(values);
    },
    [],
  );

  const defaultVenueFloors = venueFloors?.[0] || 0;

  const handleInitUnit = useCallback(
    (unit: ISpacesDataItem) => {
      handleSetEditedSpace?.(unit);

      // set images
      handleSetFiles(unit.documents as unknown as TFileWithPreview[]);

      const coverImgName = unit.documents.find(({isCover}) => isCover);

      const formatInitialPrice = (price: number) => price.toFixed(2);

      const capacity = unit.unitCapacities?.find(
        ({setupStyle}) => setupStyle === requestedSeatingPlan,
      )?.capacity;

      setInitialState({
        [ECreateOfferFormFields.NAME]: unit.name || '',
        [ECreateOfferFormFields.IMAGES]: [...unit.documents],
        [ECreateOfferFormFields.COVER_IMAGE]: coverImgName?.name || '',
        [ECreateOfferFormFields.AMENITIES]:
          unit.amenities?.map(({id}) => id) || [],
        [ECreateOfferFormFields.CAPACITY]: {
          [requestedSeatingPlan]: {
            capacity: capacity || '',
            checkbox: true,
          },
        },
        [ECreateOfferFormFields.UPDATED_CAPACITIES]: unit.unitCapacities
          ?.filter(({setupStyle}) => setupStyle !== requestedSeatingPlan)
          .map(({capacity, setupStyle}) => ({
            setupStyle,
            capacity,
          })),
        [ECreateOfferFormFields.FLOOR]: String(
          unit.floor ?? defaultVenueFloors,
        ),
        [ECreateOfferFormFields.DIMENSIONS]: {
          [EDimensionsFormFields.AREA]: String(unit.unitDimensions.area),
          [EDimensionsFormFields.AREA_FEET]: String(
            dimensionsConverter('m', unit.unitDimensions.area, true, 5),
          ),
          [EDimensionsFormFields.CEILING_HEIGHT]: String(
            unit.unitDimensions.ceilingHeight,
          ),
          [EDimensionsFormFields.CEILING_HEIGHT_FEET]: String(
            dimensionsConverter(
              'm',
              unit.unitDimensions.ceilingHeight,
              false,
              5,
            ),
          ),
        },
        [ECreateOfferFormFields.DESCRIPTION]: unit.description || '',
        [ECreateOfferFormFields.PRICE_HOUR]: unit.priceHour
          ? formatInitialPrice(unit.priceHour)
          : '',
        [ECreateOfferFormFields.PRICE_HALF_DAY]: unit.priceHalfDay
          ? formatInitialPrice(unit.priceHalfDay)
          : '',
        [ECreateOfferFormFields.PRICE_DAY]: unit.priceDay
          ? formatInitialPrice(unit.priceDay)
          : '',
        [ECreateOfferFormFields.PAID_DURATION]: paidDurationOptions[0].value,
        [ECreateOfferFormFields.SUPPLIER]: '',
        ...(initialExtras ? initialExtras[unit.id] : allInitialExtras || {}),
      } as TCreateOfferFormValues);
    },
    [
      defaultVenueFloors,
      handleSetEditedSpace,
      handleSetFiles,
      initialExtras,
      allInitialExtras,
      paidDurationOptions,
      requestedSeatingPlan,
    ],
  );

  return {initialState, handleSetInitialState, handleInitUnit};
}

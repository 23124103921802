import React from 'react';

import useProfileGeneralInfoHeader from './components/ProfileGeneralInfoHeader/useProfileGeneralInfoHeader';
import useContactsHeader from './components/ContactsHeader/useContactsHeader';

import {
  EVenueSupplierPagesWithHeader,
  IHeaderProfileContent,
} from '../../types';
import {IGlobalVenue} from 'types/venue';
import {InfoBlock, MainTitle, Section, SubTitle} from './Header.styles';
import useResourceHeader from './components/useResourceHeader/useResourceHeader';
import usePackagesHeader from './components/usePackagesHeader/usePackagesHeader';
import {useSpaceHeader} from './components/MeetingRoomsHeader/useSpaceHeader';
import {ERoomType} from 'types/dto/ERoomType.type';

type TProps = {
  globalVenue: IGlobalVenue;
  showHeaderForSection: EVenueSupplierPagesWithHeader;
  headerStatistics?: {
    totalAvailable: number;
    active: number;
    disabled: number;
  };
  handleCreateRoom?: () => void;
};

interface IVenuePages {
  headerContent: IHeaderProfileContent;
}

type TVenuePages = {
  [key in EVenueSupplierPagesWithHeader]: IVenuePages;
};

function Header({
  globalVenue,
  showHeaderForSection,
  headerStatistics,
  handleCreateRoom,
}: TProps) {
  const profileGeneralInfoContent = useProfileGeneralInfoHeader(
    globalVenue.venue,
  );
  const contactsDetailsContent = useContactsHeader(globalVenue.venue);
  const meetingRoomsContent = useSpaceHeader({
    spaces: globalVenue.spaces,
    accommodationType: globalVenue.venue.accommodationType,
    handleCreateRoom,
    roomType: ERoomType.MEETING_ROOM,
  });
  const workDesksContent = useSpaceHeader({
    spaces: globalVenue.spaces,
    accommodationType: globalVenue.venue.accommodationType,
    handleCreateRoom,
    roomType: ERoomType.WORK_SPACE,
  });
  const equipmentContent = useResourceHeader({
    headerStatistics,
    type: EVenueSupplierPagesWithHeader.EQUIPMENT,
  });
  const foodBeverageContent = useResourceHeader({
    headerStatistics,
    type: EVenueSupplierPagesWithHeader.FOOD_AND_BEVERAGE,
  });
  const packagesContent = usePackagesHeader({
    spaces: globalVenue.spaces,
    accommodationType: globalVenue.venue.accommodationType,
    handleCreateRoom,
    type: EVenueSupplierPagesWithHeader.PACKAGES,
  });

  const sections: TVenuePages = {
    [EVenueSupplierPagesWithHeader.INFO]: {
      headerContent: {
        title: profileGeneralInfoContent.title,
        subtitle: profileGeneralInfoContent.subtitle,
        items: profileGeneralInfoContent.items,
        content: profileGeneralInfoContent.content,
      },
    },
    [EVenueSupplierPagesWithHeader.CONTACTS]: {
      headerContent: {
        title: contactsDetailsContent.title,
        items: contactsDetailsContent.items,
        content: contactsDetailsContent.content,
        isUppercase: true,
      },
    },
    [EVenueSupplierPagesWithHeader.MEETING_ROOM]: {
      headerContent: {
        title: meetingRoomsContent.title,
        items: meetingRoomsContent.items,
        content: meetingRoomsContent.content,
        gridWidth: meetingRoomsContent.gridWidth,
        gap: meetingRoomsContent.gap,
      },
    },
    [EVenueSupplierPagesWithHeader.WORK_SPACE]: {
      headerContent: {
        title: workDesksContent.title,
        items: workDesksContent.items,
        content: workDesksContent.content,
        gridWidth: workDesksContent.gridWidth,
        gap: workDesksContent.gap,
      },
    },
    [EVenueSupplierPagesWithHeader.EQUIPMENT]: {
      headerContent: {
        title: equipmentContent.title,
        items: equipmentContent.items,
        content: equipmentContent.content,
        gridWidth: equipmentContent.gridWidth,
        gap: equipmentContent.gap,
      },
    },
    [EVenueSupplierPagesWithHeader.FOOD_AND_BEVERAGE]: {
      headerContent: {
        title: foodBeverageContent.title,
        items: foodBeverageContent.items,
        content: foodBeverageContent.content,
        gridWidth: foodBeverageContent.gridWidth,
        gap: foodBeverageContent.gap,
      },
    },
    [EVenueSupplierPagesWithHeader.PACKAGES]: {
      headerContent: {
        title: packagesContent.title,
        items: packagesContent.items,
        content: packagesContent.content,
        gridWidth: packagesContent.gridWidth,
        gap: packagesContent.gap,
      },
    },
  };

  const allContent = sections[showHeaderForSection];
  const {title, subtitle, items, content, gridWidth, gap, isUppercase} =
    allContent.headerContent;

  return (
    <Section>
      <MainTitle
        noSubtitle={!subtitle}
        data-testid='header-title'
        isUppercase={isUppercase}
      >
        {title}
      </MainTitle>
      {subtitle && (
        <SubTitle data-testid='header-subtitle'>{subtitle}</SubTitle>
      )}
      <InfoBlock items={items} gridWidth={gridWidth} gap={gap}>
        {content}
      </InfoBlock>
    </Section>
  );
}

export default Header;

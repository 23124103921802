import {EMPTY_ARRAY} from 'constants/app';
import {Routes} from 'constants/routes';
import {
  getCenteredLayout,
  getLogoutLayout,
  getSupplierLayout,
} from '../layoutConfigs';
import {TdropdownItem} from 'view/components/NW2MenuItem/types';
import {TNmmNavMenuList} from 'types/header';

import {SupplierHeaderItems, TMenuReturnType} from '../types';
import {EResourcesHashes} from 'view/venue/NW2VenueProfile/components/NMMResources/types';

const getLocationButtonDropDownItems = (): TdropdownItem[] => {
  return [
    {
      id: SupplierHeaderItems.VenueDetails,
      title: 'Venue details',
      link: `${Routes.publicVenue}`,
      isExternal: false,
    },
  ];
};

export const getNavigationDropdownItems = (
  venueId: string | undefined,
): TdropdownItem[] => {
  return [
    {
      id: SupplierHeaderItems.MeetingRooms,
      title: 'Meeting rooms',
      link: `${Routes.publicVenueSpaces}?id=${venueId}#room`,
      isExternal: false,
    },
    {
      id: SupplierHeaderItems.WorkDesks,
      title: 'Workdesks',
      link: `${Routes.publicVenueSpaces}?id=${venueId}#desk`,
      isExternal: false,
    },
    // {
    //   id: SupplierHeaderItems.Bedrooms,
    //   title: 'Bedrooms',
    //   link: `${Routes.publicVenueSpaces}?id=${venueId}#bedroom`,
    //   disabled: true,
    //   isExternal: false,
    // },
  ];
};

const getUserMenuDropdownItems = (): TdropdownItem[] => {
  return [
    {
      id: SupplierHeaderItems.LogOut,
      title: 'Log out',
      link: Routes.login,
      isExternal: false,
    },
  ];
};

const selectLocationButtonList = (
  venueId?: string,
  venueName?: string,
): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.LocationButton,
    text: `${venueName}`,
    isVisible: true,
    dropDownItems: getLocationButtonDropDownItems(),
    icon: 'NW2_LOCATION_OUTLINE',
  },
];

const quickNavigationList = (): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.BookingMeetingRooms,
    url: Routes.bookingsListRooms,
    text: 'Meetings',
    isVisible: true,
    icon: 'SEARCH_MEETING_ROOM',
  },
  {
    id: SupplierHeaderItems.BookingDesks,
    url: Routes.bookingsListDesks,
    text: 'Workdesks',
    isVisible: true,
    icon: 'SEARCH_WORK_DESK',
  },
];

export const restNavigationList = (venueId?: string): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.Spaces,
    text: 'Spaces',
    isVisible: true,
    dropDownItems: getNavigationDropdownItems(venueId),
    icon: 'NW2_SPACES',
  },
  {
    id: SupplierHeaderItems.Availability,
    url: Routes.publicVenueAvailability,
    text: 'Availability',
    isVisible: true,
    icon: 'NW2_CALENDAR',
  },
  {
    id: SupplierHeaderItems.Services,
    url: `${Routes.resources}?id=${venueId}#${EResourcesHashes.EQUIPMENT}`,
    text: 'Services',
    isVisible: true,
    icon: 'NW2_SERVICES',
  },
];
const userMenuList = (): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.Profile,
    text: 'Profile',
    isVisible: true,
    dropDownItems: getUserMenuDropdownItems(),
    icon: 'USER',
  },
];

export const supplierMenu = ({
  venueId,
  venueName,
}: {
  venueId: string;
  venueName?: string;
  isSpaces?: boolean;
}): TMenuReturnType => {
  return {
    layout: {
      defaultLayoutType: getSupplierLayout(),
      customRoutesLayout: {
        [Routes.createPublicVenue]: getCenteredLayout(),
        [Routes.login]: getCenteredLayout(),
        [Routes.userCompleteRegistration]: getCenteredLayout(),
        [Routes.mainLanding]: getLogoutLayout(),
      },
    },
    selectLocationButton: venueId
      ? selectLocationButtonList(venueId, venueName)
      : EMPTY_ARRAY,
    quickNavList: venueId ? quickNavigationList() : EMPTY_ARRAY,
    restNavList: venueId ? restNavigationList(venueId) : EMPTY_ARRAY,
    userMenuList: userMenuList(),
  };
};

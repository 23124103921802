import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useNavigate} from 'react-router-dom';
import _get from 'lodash/get';
import styled from 'styled-components';
import {Spin} from 'antd';

import welcomeImage from 'img/welcomeOfficeSpace.png';
import {setMultiModalComponent} from 'store/app/appSlice';
import {contentMaxWidthSm} from 'constants/styleVars';
import {Routes} from 'constants/routes';
import {getPropertyManagerInfo} from 'store/venue/actions';
import {IUser} from 'types/dto/IUser.types';
import {usePrevious} from 'hooks/usePrevious';
import {useCorporateOffices} from 'hooks/useCorporateOffices';
import {setOfficesLoading} from 'store/corporateOffice/corporateOfficeSlice';

const StyledSpin = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NameContainer = styled.span`
  text-transform: capitalize;
`;

const PropertyManagerProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {corporateAccountId, isLoading, selectedOfficeId} =
    useCorporateOffices();

  const propertyManagerId = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );

  const {firstName: userFirstName}: IUser = useSelector((state) =>
    _get(state, 'app.user'),
  );

  const iLoadingPrevValue = usePrevious(isLoading);
  const isCorporateVenueDataLoaded = iLoadingPrevValue && !isLoading;

  useEffect(() => {
    // set loading venue to true to wait until we get response
    // from getCorporateVenues api call
    dispatch(setOfficesLoading(true));
  }, [dispatch]);

  const [isUerLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (isLoading) return;
    if (!isUerLoaded && propertyManagerId) {
      dispatch(getPropertyManagerInfo(propertyManagerId));
      setUserLoaded(true);
    }

    if (selectedOfficeId) {
      navigate(Routes.corporateVenue);
    }
  }, [
    dispatch,
    isLoading,
    isUerLoaded,
    navigate,
    propertyManagerId,
    selectedOfficeId,
  ]);

  // show hint modal if no single office exists
  useEffect(() => {
    const navigateToCreateCorporateVenuePage = () => {
      dispatch(setMultiModalComponent({id: ''}));
    };

    if (corporateAccountId && !selectedOfficeId && isCorporateVenueDataLoaded) {
      navigate(Routes.createCorporateVenue); // navigate to create venue page
      dispatch(
        setMultiModalComponent({
          id: 'propManagerWelcomeModal',
          props: {
            width: contentMaxWidthSm,
            welcomeTitle: (
              <p>
                Your account is all set
                <NameContainer>
                  {userFirstName ? `, ${userFirstName}` : ''}
                </NameContainer>
                !
              </p>
            ),
            welcomeBodyText:
              'Let’s get started by setting up your first office profile. You can also add multiple office profiles by location and switch between them later',
            image: welcomeImage,
            btnText: 'Let’s get started',
            hideModal: navigateToCreateCorporateVenuePage,
          },
        }),
      );
    }
  }, [
    corporateAccountId,
    selectedOfficeId,
    userFirstName,
    navigate,
    dispatch,
    isCorporateVenueDataLoaded,
  ]);

  return <StyledSpin tip='Loading ...' spinning={true} />;
};

export default PropertyManagerProfile;

import React, {useState} from 'react';

import NW2Button from 'view/components/NW2Button';
import InputCheckbox from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/components/InputCheckbox';

import {offsetDef, offsetXXSm} from 'constants/styleVars';
import {PageContent} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {StyledErrorMessage} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {StepActions} from '../../OfferCreate.styles';
import {Box, BoxTitle, List, ListItem, NoWrap} from './TermsPage.styles';
import {useAppSelector} from 'store/hooks';
import {
  getCancellationPolicyLinkUrlById,
  getTermsAndConditionLinkUrlById,
} from 'utils/helpers';

interface IProps {
  hasBookerConditions: boolean;
  hasBookerTerms: boolean;
  handleStart: () => void;
}
export function TermsPageEU({
  hasBookerConditions,
  hasBookerTerms,
  handleStart,
}: IProps) {
  const termsAndConditionsId = useAppSelector(
    ({offers}) => offers.offerDetails.termsAndConditionsId || '',
  );
  const customerCancellationPolicyId = useAppSelector(
    ({offers}) => offers.offerDetails.cancellationPolicy?.id || '',
  );

  const [isTermsChecked, setTermsChecked] = useState(false);
  const [isConditionsChecked, setConditionsChecked] = useState(false);
  const [hasTermsError, setTermsError] = useState(false);
  const [hasConditionsError, setConditionsError] = useState(false);

  const onTermsChange = () => {
    setTermsChecked((prev) => !prev);
    setTermsError(false);
  };

  const onConditionsChange = () => {
    setConditionsChecked((prev) => !prev);
    setConditionsError(false);
  };

  const onNext = () => {
    let hasError = false;

    if (hasBookerConditions && !isConditionsChecked) {
      setConditionsError(true);
      hasError = true;
    }

    if (hasBookerTerms && !isTermsChecked) {
      setTermsError(true);
      hasError = true;
    }

    if (!hasError) {
      handleStart();
    }
  };

  return (
    <>
      <PageContent width={442}>
        <Box>
          <div>
            <BoxTitle>
              <>
                To proceed with this request, please accept the following Terms
                and Conditions:
              </>
            </BoxTitle>
            <List>
              {hasBookerConditions && (
                <ListItem>
                  <InputCheckbox
                    name='cancellation_conditions'
                    label={<NoWrap>I agree to</NoWrap>}
                    checked={isConditionsChecked}
                    hasError={hasTermsError}
                    onChange={onConditionsChange}
                  />
                  <a
                    href={getCancellationPolicyLinkUrlById(
                      customerCancellationPolicyId,
                    )}
                    target='_blank'
                    rel='noreferrer noopener'
                    style={{marginLeft: offsetXXSm}}
                  >
                    Booker's Modification and Cancellation Conditions
                  </a>
                </ListItem>
              )}

              {hasBookerTerms && (
                <ListItem>
                  <InputCheckbox
                    name='terms'
                    label={<NoWrap>I agree to</NoWrap>}
                    checked={isTermsChecked}
                    hasError={hasTermsError}
                    onChange={onTermsChange}
                  />
                  <a
                    href={getTermsAndConditionLinkUrlById(termsAndConditionsId)}
                    target='_blank'
                    rel='noreferrer noopener'
                    style={{marginLeft: offsetXXSm}}
                  >
                    Booker's Terms and Conditions
                  </a>
                </ListItem>
              )}
            </List>

            {(hasTermsError || hasConditionsError) && (
              <StyledErrorMessage margin={`${offsetDef} 0 0 0`}>
                To proceed with this request, you need to agree to the above
                Terms and Conditions
              </StyledErrorMessage>
            )}
          </div>
        </Box>
      </PageContent>

      <StepActions>
        <NW2Button onClick={onNext} buttonType='primary' size='small'>
          Let's go!
        </NW2Button>
      </StepActions>
    </>
  );
}

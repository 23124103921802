import styled from 'styled-components';

import {
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXSm,
  xLgBp,
} from 'constants/styleVars';

const CONTAINER_MAX_WIDTH = '820px';
const CONTAINER_BADGE_WIDTH = '122px';

export const RowContainer = styled.div<{isActive: boolean}>`
  display: grid;
  grid-column-gap: ${offsetXLg};
  align-items: center;
  margin-bottom: ${offsetDef};

  @media (max-width: ${xLgBp - 1}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${({isActive}) => (isActive ? 3 : 1)}, 1fr);
    grid-row-gap: ${offsetXSm};
    max-width: 450px;

    > div {
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 3;
      }
      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
      }
      &:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
      }
      &:nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
      }
    }
  }

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: ${CONTAINER_BADGE_WIDTH} 1fr 1fr 1fr;
    grid-template-rows: auto;
    max-width: ${CONTAINER_MAX_WIDTH};
  }
`;

export const RowItem = styled.div`
  display: flex;
`;

export const RowContainerWholeDay = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: ${offsetDef};
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: ${offsetSm};
  max-width: 450px;

  @media (min-width: ${xLgBp}px) {
    grid-template-columns: ${CONTAINER_BADGE_WIDTH} 1fr;
    grid-template-rows: auto;
    grid-column-gap: ${offsetXLg};
    max-width: ${CONTAINER_MAX_WIDTH};
  }
`;

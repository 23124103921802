import styled, {css} from 'styled-components';

import {
  colorSchema,
  EBookingSummaryTheme,
} from 'view/components/NW2SummaryBlock/types';

import Icon from 'view/components/Icon';

import {
  borderRadiusDef,
  fontSizeSm,
  fontSizeXSm,
  fontSizeXXLg,
  fontSizeXXSm,
  fontWeightExtraBold,
  lineHeightSm,
  lineHeightXSm,
  offsetDef,
  offsetXSm,
  offsetXXSm,
  offsetXXLg,
  offsetXXXSm,
} from 'constants/styleVars';

export const Main = styled.div<{
  isCollapsed: boolean;
  colorTheme: EBookingSummaryTheme;
}>`
  display: ${({isCollapsed}) => (isCollapsed ? 'none' : '')};
  padding-top: ${offsetDef};
  margin-top: ${offsetXSm};
  color: ${({colorTheme}) => colorSchema[colorTheme].unitCardMainColor};
  border-top: 1px solid
    ${({colorTheme}) => colorSchema[colorTheme].unitCardBdColor};
`;

export const UnitTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${fontSizeSm};
  line-height: ${fontSizeXXLg};
`;

export const ExtraUnitPrice = styled.div`
  white-space: nowrap;
`;

export const UnitInfo = styled.div<{colorTheme: EBookingSummaryTheme}>`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  padding-left: ${offsetXSm};
  color: ${({colorTheme}) => colorSchema[colorTheme].unitCardInfoColor};
`;

export const UnitQuantity = styled.div`
  margin-right: ${offsetXSm};
  display: flex;
  min-width: ${offsetXXLg};
`;

export const UnitName = styled.div`
  padding-right: ${offsetDef};
`;
export const UnitSubtitle = styled.div`
  display: flex;
`;

export const MainTitle = styled.div`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  margin-bottom: ${offsetXXSm};
`;

export const ExtrasTitle = styled(MainTitle)`
  margin-top: ${offsetXSm};
  margin-bottom: ${offsetXXSm};
`;

export const Wrapper = styled.div<{colorTheme: EBookingSummaryTheme}>(
  ({colorTheme}) => css`
    padding: ${offsetXSm} ${offsetDef};
    border: 1px solid ${colorSchema[colorTheme].unitCardBdColor};
    border-radius: ${borderRadiusDef};
    margin-top: ${offsetDef};

    & + div {
      margin-top: ${offsetDef};
    }

    &:first-of-type {
      margin-top: 0;
    }
  `,
);

export const UnitBodyContainer = styled.div`
  & + & {
    margin-top: ${offsetXSm};
  }
`;

export const FlexContainer = styled.div<{marginBottom?: number}>`
  display: flex;
  align-items: center;
  ${({marginBottom}) =>
    marginBottom ? `margin-bottom: ${marginBottom}px` : ''};
`;

export const Separator = styled.span<{colorTheme: EBookingSummaryTheme}>`
  display: block;
  width: 2px;
  height: 2px;
  margin-left: -1px;
  border-radius: 1px;
  position: relative;
  background: ${({colorTheme}) => colorSchema[colorTheme].dashesAndArrows};
`;

export const UnitTimeAndParticipants = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${fontSizeXXLg};
  margin-top: ${offsetXXXSm};
  margin-bottom: ${offsetXSm};
`;

export const MiddleDot = styled.span`
  margin: 0 6px 0 ${offsetXXSm};
`;

export const HighlightRow = styled.div<{
  isHighlighted?: boolean;
  isRemoved?: boolean;
  marginBottom?: number;
  colorTheme: EBookingSummaryTheme;
}>`
  ${({isHighlighted, colorTheme}) =>
    isHighlighted
      ? `background-color: ${colorSchema[colorTheme].highlightColor}`
      : ''};
  ${({isRemoved, colorTheme}) =>
    isRemoved
      ? `
    &:before {
    content: '';
    height: 1px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
    background-color: ${colorSchema[colorTheme].dashesAndArrows};
    margin: 0 ${offsetXXSm};
    position: absolute;
  }
  `
      : ''};
  position: relative;
  padding: 0 ${offsetXSm};
  margin: ${offsetXXSm} -${offsetXSm} ${({marginBottom}) => (marginBottom ? `${marginBottom}px` : '')};
`;

export const TimeRangeContainer = styled.div<{
  isHighlighted?: boolean;
  colorTheme: EBookingSummaryTheme;
}>`
  ${({isHighlighted, colorTheme}) =>
    isHighlighted
      ? `background-color: ${colorSchema[colorTheme].highlightColor}`
      : ''};
  margin-left: -${offsetXSm};
  padding: 0 ${offsetXSm};
`;

export const ParticipantsContainer = styled.div<{
  isHighlighted?: boolean;
  colorTheme: EBookingSummaryTheme;
}>`
  ${({isHighlighted, colorTheme}) =>
    isHighlighted
      ? `background-color: ${colorSchema[colorTheme].highlightColor}`
      : ''};
  margin-left: -1px;
  padding: 0 ${offsetXSm};
  display: flex;
  align-items: center;
`;

export const ChangeIcon = styled(Icon)<{
  marginLeft?: number;
  marginRight?: number;
}>`
  ${({marginLeft}) => (marginLeft ? `margin-left: ${marginLeft}px` : '')};
  ${({marginRight}) => (marginRight ? `margin-right: ${marginRight}px` : '')};
`;

export const TotalPricePackage = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${offsetXSm};
  align-items: center;
`;
export const Section = styled.div`
  margin: ${offsetXSm} 0;
`;
export const PricePerPerson = styled.div`
  font-size: ${fontSizeXSm};
`;
export const TotalPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

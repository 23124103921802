import {TDayTime} from 'types/dto/IDay.type';
import {
  WHOLE_DAY_OPEN_FROM_HOUR,
  WHOLE_DAY_OPEN_FROM_MINUTE,
  WHOLE_DAY_OPEN_TO_HOUR,
  WHOLE_DAY_OPEN_TO_MINUTE,
} from 'constants/NW2OpenHours';

export const getInitially24HoursAccess = ({
  timeStartHour,
  timeStartMinutes,
  timeEndHour,
  timeEndMinutes,
}: {
  timeStartHour: TDayTime;
  timeStartMinutes: TDayTime;
  timeEndHour: TDayTime;
  timeEndMinutes: TDayTime;
}): boolean =>
  timeStartHour == WHOLE_DAY_OPEN_FROM_HOUR &&
  timeStartMinutes == WHOLE_DAY_OPEN_FROM_MINUTE &&
  timeEndHour == WHOLE_DAY_OPEN_TO_HOUR &&
  timeEndMinutes == WHOLE_DAY_OPEN_TO_MINUTE;

import React, {useEffect, useState} from 'react';
import {notification} from 'antd';

import {NW2ImageItem} from '../NW2ImageItem/NW2ImageItem';

import {useNW2Upload} from '../NW2Upload/useNW2Upload';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import {THandleUpdateImages} from '../../types';
import {useSelector} from 'react-redux';
import {RootState} from 'store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  img?: TImageFile;
  onImageUpload: (file: TImageFile) => void;
  onImageRemove: (file: TImageFile) => void;
  onSetCoverImage: (file: TImageFile) => void;
  onUpdateVenueImages: THandleUpdateImages;
  handleProgressChange: (v: number) => void;
  handleFileNameChange: (v: string) => void;
  hasCoverLabel?: boolean | null;
  isRemoveDisabled?: boolean;
}

export function NW2UploadListItem({
  img,
  onImageUpload,
  onUpdateVenueImages,
  onImageRemove,
  onSetCoverImage,
  handleProgressChange,
  handleFileNameChange,
  hasCoverLabel,
  isRemoveDisabled,
}: IProps) {
  const [imgSrc, setSrc] = useState('');

  const {
    files,
    isUploading,
    handleUploading,
    progress,
    handleReset,
    uploadComponent,
  } = useNW2Upload({
    onImageUpload,
    onUpdateVenueImages,
    maxFiles: 1,
  });

  useEffect(() => {
    if (isUploading && progress === 100 && (imgSrc || files[0]?.preview)) {
      handleUploading(false);

      notification.success({
        message: 'Your image has now been successfully uploaded.',
        duration: 4,
      });
    }
  }, [files, handleUploading, imgSrc, isUploading, progress]);

  useEffect(() => {
    // update after remove
    if (!img && imgSrc) {
      setSrc('');
    }
  }, [img, imgSrc]);

  useEffect(() => {
    // image preload handler
    const onLoad = () => {
      if (!img?.url) return;
      setSrc(img.url);
    };

    const image = new Image();
    image.src = img?.url as string;
    image.addEventListener('load', onLoad);

    return () => {
      image.removeEventListener('load', onLoad);
    };
  }, [img?.url]);

  useEffect(() => {
    // handle progress bar for single item
    if (progress) {
      handleProgressChange(progress);
    }

    if (isUploading && progress > 0) {
      handleFileNameChange(
        img?.originFileObj?.name || img?.fileName || files[0]?.name || '',
      );
    }

    const timeout = setTimeout(() => {
      // hide after 3 sec
      handleProgressChange(0);
      handleFileNameChange('');
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    files,
    handleFileNameChange,
    handleProgressChange,
    handleReset,
    img?.fileName,
    img?.name,
    img?.originFileObj?.name,
    isUploading,
    progress,
  ]);

  const handleRemove = () => {
    if (img) {
      onImageRemove(img);
    }

    // if isRemoveDisabled no need to clear state
    if (isRemoveDisabled) return;

    handleReset();
    setSrc('');
  };

  const handleSetCover = () => {
    if (img) {
      onSetCoverImage(img);
    }
  };

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  return imgSrc && !isUploading ? (
    <NW2ImageItem
      id={img?.uid}
      name={img?.name}
      src={imgSrc}
      handleSetCover={handleSetCover}
      handleRemove={handleRemove}
      hasCoverLabel={hasCoverLabel && img?.isCover}
    />
  ) : isAgent ? null : (
    uploadComponent
  );
}

import React, {FC} from 'react';
import styled from 'styled-components';

import TextBlock from 'view/components/TextBlock';
import venueTexts from 'texts/venueTexts';

import {getAddressStringFromLocation} from 'utils/stringUtils';
import {ILocation} from 'types/dto/ILocation.types';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {Tag} from 'antd';
import {floorLevels, getKeyByValue} from 'constants/floorStorey';
import {offsetXSm} from 'constants/styleVars';

const StyledTag = styled(Tag)`
  margin-bottom: ${offsetXSm};
`;

type Props = {
  location: ILocation;
  accommodationType?: EAccommodationType;
};

const AddressInfo: FC<Props> = ({
  location,
  accommodationType = EAccommodationType.VENUE,
}) => {
  const address = getAddressStringFromLocation(location);

  const floorTags = location.floors?.map((floorNumber: number) => {
    const floorName = getKeyByValue(floorLevels, floorNumber);

    return <StyledTag key={floorName}>{floorName}</StyledTag>;
  });

  return (
    <>
      <TextBlock
        label={`${venueTexts[accommodationType].venueTypeCapitalized} address`}
        value={address}
        valueId={`${venueTexts[accommodationType].venueType}AddressValue`}
      />

      <TextBlock
        label='Floor(s) in building'
        value={floorTags}
        valueId={`${venueTexts[accommodationType].venueType}FloorValues`}
      />

      <TextBlock
        label='Timezone'
        value={location.timeZone || '-'}
        valueId={`${venueTexts[accommodationType].venueType}TimeZoneValue`}
      />
    </>
  );
};

export default AddressInfo;

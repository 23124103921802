import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {GoogleMap, Circle, OverlayView} from '@react-google-maps/api';
import styled from 'styled-components';

import Icon from 'view/components/Icon/Icon';
import DistanceIndicator from '../components/DistanceIndicator';

import {
  getGoogleMapPinCenteredPositionOffset,
  getVisibleRadius,
} from 'utils/googleMapUtils';
import {NW2SecondaryPurple} from 'constants/styleVars';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

type TProps = {
  mapHeight: number;
};

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const INITIAL_ZOOM = 16;

const MAP_OPTIONS = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: true,
  minZoom: INITIAL_ZOOM - 2,
  maxZoom: INITIAL_ZOOM + 2,
};

export const NW2VenueDetailsExpandedMapGoogleMap = ({
  mapHeight = 0,
}: TProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [visibleRadius, setVisibleRadius] = useState<number>(0);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const {venueDetails} = useVenueDetailsData();

  const latitude = venueDetails.location.latitude;
  const longitude = venueDetails.location.longitude;

  const getRadius = useCallback(() => {
    if (map) {
      const mapRadius = getVisibleRadius({map}) || 0;
      setVisibleRadius(Math.floor(mapRadius * 1000)); // meters
    }
  }, [map]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (map) {
      // map needs to be rendered
      timeout = setTimeout(() => {
        getRadius();
      }, 0);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [getRadius, map]);

  const getMarkerLocation = () => ({
    lat: Number(latitude || 0),
    lng: Number(longitude || 0),
  });

  const markerLocation = getMarkerLocation();

  const getMapStyle = () => {
    return {
      width: '100%',
      height: mapHeight + 'px',
    };
  };

  const onZoomChanged = () => {
    getRadius();
  };

  const CIRCLE_OPTIONS = useMemo(
    () => ({
      strokeColor: NW2SecondaryPurple,
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: NW2SecondaryPurple,
      fillOpacity: 0.05,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: visibleRadius,
      zIndex: 1,
    }),
    [visibleRadius],
  );

  return (
    <Container>
      <GoogleMap
        mapContainerStyle={getMapStyle()}
        center={markerLocation}
        zoom={INITIAL_ZOOM}
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
        options={MAP_OPTIONS}
      >
        {latitude && longitude && (
          <OverlayView
            position={markerLocation}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getGoogleMapPinCenteredPositionOffset}
          >
            <Icon icon='MAP_PIN_HOUSE' />
          </OverlayView>
        )}

        <Circle
          center={markerLocation}
          options={CIRCLE_OPTIONS}
          radius={visibleRadius}
        />
      </GoogleMap>
      <DistanceIndicator distance={visibleRadius} />
    </Container>
  );
};

export default NW2VenueDetailsExpandedMapGoogleMap;

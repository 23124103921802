import {
  blackColor,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray300Color,
  NW2Gray600Color,
  NW2Gray800Color,
  NW2Gray900Color,
  whiteColor,
} from 'constants/styleVars';

export enum EBookingSummaryTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export interface IBookingSummaryTheme {
  wrapperBgColor: string;
  containerColor: string;
  headerBgColor: string;
  titleColor: string;
  subTitleColor: string;
  footerBgColor: string;
  linkTermsColor: string;
  unitCardBdColor: string;
  unitCardHeaderColor: string;
  unitCardDateColor: string;
  unitCardMainColor: string;
  unitCardInfoColor: string;
  iconColor: string;
  highlightColor: string;
  dashesAndArrows: string;
}

export type TBookingSummaryTheme = {
  [key in EBookingSummaryTheme]: IBookingSummaryTheme;
};

export const colorSchema: TBookingSummaryTheme = Object.freeze({
  [EBookingSummaryTheme.LIGHT]: {
    wrapperBgColor: NW2Gray100Color,
    containerColor: NW2Gray900Color,
    headerBgColor: NW2Gray100Color,
    titleColor: NW2Gray900Color,
    subTitleColor: NW2Gray900Color,
    footerBgColor: NW2Gray100Color,
    linkTermsColor: NW2Gray800Color,
    unitCardBdColor: NW2Gray200Color,
    unitCardHeaderColor: NW2Gray800Color,
    unitCardDateColor: NW2Gray900Color,
    unitCardMainColor: NW2Gray900Color,
    unitCardInfoColor: NW2Gray800Color,
    iconColor: blackColor,
    highlightColor: NW2Gray200Color,
    dashesAndArrows: blackColor,
  },
  [EBookingSummaryTheme.DARK]: {
    wrapperBgColor: NW2Gray800Color,
    containerColor: NW2Gray100Color,
    headerBgColor: NW2Gray900Color,
    titleColor: whiteColor,
    subTitleColor: NW2Gray200Color,
    footerBgColor: blackColor,
    linkTermsColor: NW2Gray200Color,
    unitCardBdColor: NW2Gray600Color,
    unitCardHeaderColor: NW2Gray200Color,
    unitCardDateColor: NW2Gray100Color,
    unitCardMainColor: NW2Gray100Color,
    unitCardInfoColor: NW2Gray300Color,
    iconColor: NW2Gray300Color,
    highlightColor: NW2Gray600Color,
    dashesAndArrows: whiteColor,
  },
});

import {ERoomType} from 'types/dto/ERoomType.type';

type TSpaceTexts = Partial<
  Record<ERoomType, {title: string; description: string}>
>;

export const spaceTexts: TSpaceTexts = {
  [ERoomType.MEETING_ROOM]: {
    title: 'meeting rooms',
    description: 'people',
  },
  [ERoomType.WORK_SPACE]: {
    title: 'workdesks',
    description: 'desks',
  },
};

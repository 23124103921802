import React from 'react';

import {
  Container,
  StyledBox,
  AccommodationString,
  StyledSpan,
  StyledIcon,
  AccommodationStringBox,
} from './AccommodationBlock.styles';
import {EResourcesCode} from 'types/dto/IExtras.type';
import {IExtraResponse} from 'types/dto/IPublicVenue';

type TProps = {
  singleBedrooms: IExtraResponse[];
  doubleBedrooms: IExtraResponse[];
};

const accFn = (arr: IExtraResponse[]) =>
  arr.reduce((acc, item) => acc + item?.quantity, 0);

const AccommodationBlockItem = ({singleBedrooms, doubleBedrooms}: TProps) => {
  const amountOfSingleBedrooms = accFn(singleBedrooms);
  const amountOfDoubleBedrooms = accFn(doubleBedrooms);

  const hasSingleBreakfast = singleBedrooms.some(
    ({code}) => code === EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST,
  );
  const hasDoubleBreakfast = doubleBedrooms.some(
    ({code}) => code === EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST,
  );

  const hasSingleBedrooms = singleBedrooms.length > 0;
  const hasDoubleBedrooms = doubleBedrooms.length > 0;

  const breakfastString = (
    <AccommodationString>
      <StyledIcon icon='NW2_CHECKMARK' NW2Gray100Color />
      Breakfast included
    </AccommodationString>
  );

  return (
    <Container isBothBedrooms={hasSingleBedrooms && hasDoubleBedrooms}>
      <StyledBox>Accommodation required:</StyledBox>

      {hasSingleBedrooms && (
        <AccommodationStringBox>
          <AccommodationString>
            {amountOfSingleBedrooms}
            <StyledSpan>x</StyledSpan>Single use bedrooms
          </AccommodationString>
          {hasSingleBreakfast && breakfastString}
        </AccommodationStringBox>
      )}

      {hasDoubleBedrooms && (
        <AccommodationStringBox>
          <AccommodationString>
            {amountOfDoubleBedrooms}
            <StyledSpan>x</StyledSpan>Double use bedrooms
          </AccommodationString>
          {hasDoubleBreakfast && breakfastString}
        </AccommodationStringBox>
      )}
    </Container>
  );
};

export default AccommodationBlockItem;

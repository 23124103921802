import React from 'react';

import {IVenue} from 'types/venue';
import {FloorViewItem} from '../FloorsViewItem/FloorViewItem';

interface IProps {
  venue: IVenue;
}

export const FloorsView = ({venue}: IProps) => {
  const floors = venue.floorInformation;
  return (
    <>
      {floors?.map(({floorNumber}) => {
        const floorUnits = floors.filter(
          (floorItem) => floorItem.floorNumber === floorNumber,
        )[0];

        return (
          <FloorViewItem
            key={floorNumber}
            floorNumber={floorNumber}
            units={floorUnits}
          />
        );
      })}
    </>
  );
};

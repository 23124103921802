import {useSelector} from 'react-redux';
import queryString from 'query-string';
import _get from 'lodash/get';

import {EMPTY_OBJECT} from 'constants/app';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';

const useVenueId = () => {
  const queryData = queryString.parse(location.search.slice(1)) || EMPTY_OBJECT;
  const venueIdFromQuery = queryData.id || '';
  const venueIdFromStore =
    useSelector((state) => _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`)) ||
    '';
  const venueId = +venueIdFromQuery || +venueIdFromStore;

  return {
    venueId,
  };
};

export default useVenueId;

import React from 'react';
import styled from 'styled-components';

import SearchableDropdown from './components/SearchableDropdown';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {useClickOutside} from 'hooks/useClickOutside';
import {ISearchDropdownListOption} from 'view/components/NW2FormItem/types';
import useToggle from 'hooks/useToggle';
import {useAppSelector} from 'store/hooks';
import {
  INPUT_BORDER_COLOR,
  INPUT_HEIGHT,
  StyledInputContainer,
} from '../../NW2FormItem.styles';
import {
  borderRadiusDef,
  fontSizeSm,
  fontSizeXSm,
  lineHeightMd,
  lineHeightSm,
  modalSmallWidth,
  NW2Gray500Color,
  NW2Gray600Color,
  offsetDef,
  offsetSm,
  offsetXXLg,
} from 'constants/styleVars';

const Wrapper = styled.div`
  position: relative;
`;

const InputBox = styled.div`
  padding: ${offsetSm} ${offsetXXLg} ${offsetSm} ${offsetSm};
  height: ${INPUT_HEIGHT};
  border: 1px solid ${INPUT_BORDER_COLOR};
  border-radius: ${borderRadiusDef};
  cursor: pointer;
`;

const InputBoxText = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    font-size: ${fontSizeXSm};
    line-height: ${lineHeightSm};
    color: ${NW2Gray600Color};
  }
`;

const IconTriangle = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  right: ${offsetDef};
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${NW2Gray500Color};
  pointer-events: none;
`;

interface IProps {
  options: ISearchDropdownListOption[];
  placeholder?: string;
  isLoading?: boolean;
  value: string | ISearchDropdownListOption;
  onChange: (value: string | ISearchDropdownListOption) => void;
}
function SelectSearch({
  options,
  placeholder,
  isLoading,
  value,
  onChange,
}: IProps) {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const [focused, toggleFocused] = useToggle(false);

  const onBlurHandler = () => {
    toggleFocused();
  };

  const [selectRef] = useClickOutside(focused, onBlurHandler);

  const selectedValue =
    typeof value === 'string' ? (
      value || placeholder
    ) : typeof value === 'object' ? (
      <>
        {value.text}
        {value.subText ? ', ' : ''}
        {value.subText ? <span>{value.subText}</span> : ''}
      </>
    ) : (
      placeholder
    );

  const dropdownComponent = (
    <SearchableDropdown
      options={options}
      onChange={onChange}
      selectedValue={value}
      onClose={toggleFocused}
      isLoading={isLoading}
    />
  );

  return (
    <Wrapper ref={isMobile ? null : selectRef}>
      <StyledInputContainer>
        <InputBox onClick={toggleFocused}>
          <InputBoxText>{selectedValue}</InputBoxText>
        </InputBox>

        <IconTriangle />
      </StyledInputContainer>

      {isMobile ? (
        <NW2ModalDrawer
          header='company credit card '
          body={dropdownComponent}
          onClose={toggleFocused}
          isMobile={isMobile}
          modalWidth={modalSmallWidth}
          modalBodyStyle={{padding: 0}}
          drawerHeight='auto'
          isShowed={focused}
        />
      ) : (
        focused && dropdownComponent
      )}
    </Wrapper>
  );
}

export default SelectSearch;

import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {
  offsetDef,
  offsetXLg,
  NW2Gray200Color,
  NW2Gray600Color,
  fontSizeMd,
  fontWeightBold,
  fontSizeSm,
  offsetXSm,
  offsetXXSm,
  lineHeightMd,
  lineHeightLg,
} from 'constants/styleVars';

export const Container = styled.div<{isDivider: boolean}>`
  width: 100%;
  padding: ${offsetXLg};
  ${({isDivider}) =>
    isDivider
      ? `&:not(:first-child) {
         border-top: 1px solid ${NW2Gray200Color};
             }`
      : ''};
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${offsetXSm};
`;

export const TitleContainer = styled.div`
  flex-grow: 1;
`;

export const Title = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
`;

export const SubTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray600Color};
`;

export const Divider = styled.div`
  margin-top: ${offsetDef};
  margin-bottom: ${offsetDef};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const Content = styled.div<{isDivider?: boolean}>`
  width: 100%;
  ${({isDivider}) => !isDivider && `margin-top: ${offsetXLg}`};
`;

const ICON_SIZE = '32px';
export const StyledIcon = styled(Icon)<{expanded: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  margin-top: -${offsetXXSm};
  margin-right: -${offsetXSm};

  ${({expanded}) =>
    !expanded &&
    `
    transform: rotate(180deg);
  `}
`;

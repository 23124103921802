import {TLayout} from '../types';

export const getPropertyManagerLayout = (): TLayout => {
  return {
    type: 'NW2PropertyManagerLayout',
    components: [
      {component: 'isLogo'},
      {component: 'isSelectLocationButton'},
      {component: 'isRestNavList'},
      {component: 'isSignOutButton'},
    ],
  };
};

import React from 'react';
import {useAppSelector} from 'store/hooks';

import TruncateText from 'view/components/TruncateText';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import useToggle from 'hooks/useToggle';

import {fontSizeMd} from 'constants/styleVars';
import {StyledDiv, Title} from './FullDescription.styles';

const modalBodyStyle = {
  fontSize: fontSizeMd,
};

interface IProps {
  description: string;
  numberOfLines?: number;
  fontSize?: string;
}

const FullDescription = ({description, fontSize, numberOfLines}: IProps) => {
  const [isShowed, setShowed] = useToggle(false);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  if (!description) return null;

  return (
    <StyledDiv fontSize={fontSize} data-testid='description-preview'>
      <TruncateText
        text={description}
        tag='div'
        numberOfLines={numberOfLines ? numberOfLines : isDesktop ? 8 : 6}
        showFullModal={setShowed}
        fullContentLinkTitle='Show full description'
      />
      <NW2ModalDrawer
        isMobile={isMobile}
        isShowed={isShowed}
        header={<Title>Description</Title>}
        modalWidth={800}
        modalBodyStyle={modalBodyStyle}
        drawerHeight='auto'
        body={
          <TruncateText text={description} tag='div' hideToggler expanded />
        }
        onClose={setShowed}
        headerPaddingBottom='0'
      />
    </StyledDiv>
  );
};

export default FullDescription;

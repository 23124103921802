import React, {useMemo, useState} from 'react';
import {useAppSelector} from 'store/hooks';

import LeftSideItem from 'view/venue/components/LeftSideContainer/LeftSideItem';
import UserDetails from 'view/venue/NW2BookingPreview/components/UserDetails/UserDetails';
import AboutMeetingForm from 'view/venue/NW2BookingPreview/components/AboutMeetingForm/AboutMeetingForm';
import SpaceList from './SpaceList';
import Space from './Space/Space';
// import PaymentMethod from './PaymentMethod';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EFormValidationName} from 'types/venue';
import {IUnit} from 'types/dto/IUnit.types';
import {ICustomerBookedUnits} from 'types/dto/IBooking.types';
import {EPaymentType} from 'types/bookingOrders';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';

import {StyledLeftSideContainer} from 'view/venue/components/LeftSideContainer/LeftSideContainer.styles';
import useFormBatchChangeBookingState from 'view/venue/hooks/useFormBatchChangeBookingState';
import CancelPolicyInfoCard from 'view/components/CancelPolicyInfoCard/CancelPolicyInfoCard';
import CancellationPolicyTextElement from 'view/components/CancellationPolicyTextElement/CancellationPolicyTextElement';
import {ECancelPolicyInfoCardComponent} from 'view/components/CancelPolicyInfoCard/types';
import ItemPaymentInformation from '../components/LeftSideContainer/ItemPaymentInformation';
import BillingAddressViewWithReferenceField from 'view/components/BillingAddress/BillingAddressViewWithReferenceField';
import CustomerTermsAndPolicyBlock from 'view/common/CustomerTermsAndPolicyBlock';

interface IProps {
  venueUnits: IUnit[];
  bookedUnits: ICustomerBookedUnits[];
  isOffice: boolean;
  isMultiRooms: boolean;
  cancellationPolicyText: string;
}

function PreviewComponents({
  venueUnits,
  bookedUnits,
  isOffice,
  isMultiRooms,
  cancellationPolicyText,
}: IProps) {
  const token = useAppSelector(({app}) => app.token);
  const role = useAppSelector(({app}) => app.user.role);
  const defaultBillingAddress = useAppSelector(
    ({app}) => app.user.defaultBillingAddress,
  );
  const bookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );
  const customerBookedUnitsToReCalculate = useAppSelector(
    ({customer}) => customer.customerBookedUnitsToReCalculate,
  );

  const {
    orderNumber,
    paymentInfo,
    billingAddressDto: billingAddress,
    bookingStatus,
    cancellationPolicy,
    termsAndConditionsId,
  } = bookingOrder ?? {};

  const singleVenueUnit = bookedUnits[0]?.unitBookings[0];

  const paymentType = paymentInfo?.paymentType;

  const isDirectPayment = paymentType === EPaymentType.DIRECT_PAYMENT;

  const isDetailsHidden = !!(token && role === EUserRoleCognito.ROLE_GUEST);

  const isMeetingRoom =
    isMultiRooms || singleVenueUnit?.unitType === ERoomType.MEETING_ROOM;

  const [isHrsPayEnabled] = useFeatureToggle(EFeatureToggles.HRS_PAY);

  const [isPresetEditModeOn, setIsPresetEditModeOn] = useState(false);

  const userData = useMemo(
    () => ({
      firstName: bookingOrder?.firstName,
      lastName: bookingOrder?.lastName,
      email: bookingOrder?.email,
      phone: bookingOrder?.phone,
      aboutMeeting: bookingOrder?.eventName || undefined,
    }),
    [bookingOrder],
  );

  const billingAddressData = useMemo(
    () => ({
      ...defaultBillingAddress,
      additionalReference:
        defaultBillingAddress?.additionalReference || undefined,
    }),
    [defaultBillingAddress],
  );

  useFormBatchChangeBookingState({
    orderDays: customerBookedUnitsToReCalculate,
    billingAddress: billingAddressData,
    user: userData,
  });

  return (
    <StyledLeftSideContainer>
      {isMultiRooms ? (
        <SpaceList
          venueUnits={venueUnits}
          bookedUnits={bookedUnits}
          setIsPresetEditModeOn={setIsPresetEditModeOn}
          isPresetEditModeOn={isPresetEditModeOn}
        />
      ) : (
        <LeftSideItem
          item={{
            title: 'Your space',
            children: (
              <Space
                venueUnits={venueUnits}
                bookedUnits={bookedUnits[0]?.unitBookings}
                bookedFoodAndBeverage={bookedUnits[0]?.foodAndBeverage}
                bookedBedrooms={bookedUnits[0]?.bedrooms}
                setIsPresetEditModeOn={setIsPresetEditModeOn}
                isPresetEditModeOn={isPresetEditModeOn}
              />
            ),
          }}
        />
      )}
      <LeftSideItem
        item={{
          title: 'About meeting',
          children: <AboutMeetingForm />,
        }}
        isHidden={!isMeetingRoom}
      />
      <LeftSideItem
        item={{
          title: 'Your details',
          subtitle: '(Required)',
          children: <UserDetails />,
          formValidationName: EFormValidationName.YOUR_DETAILS_BLOCK,
        }}
        isHidden={isDetailsHidden}
      />

      <ItemPaymentInformation
        paymentInfo={paymentInfo}
        billingAddress={billingAddress}
        isHidden={isOffice || !isHrsPayEnabled}
      />

      <LeftSideItem
        item={{
          title: 'Billing address',
          subtitle: '(Required)',
          children: (
            <BillingAddressViewWithReferenceField
              paymentType={paymentType}
              billingAddress={
                isDirectPayment ? defaultBillingAddress : billingAddress
              }
            />
          ),
          formValidationName: EFormValidationName.BILLING_ADDRESS_BLOCK,
        }}
        isHidden={isOffice}
      />
      {orderNumber && (
        <LeftSideItem
          item={{
            title: 'Terms and Conditions',
            children: cancellationPolicyText ? (
              <CancelPolicyInfoCard
                text={
                  <CancellationPolicyTextElement
                    text={cancellationPolicyText}
                  />
                }
                componentType={
                  ECancelPolicyInfoCardComponent.ACTION_CARD_CLOSABLE
                }
              />
            ) : (
              <CustomerTermsAndPolicyBlock
                bookingStatus={bookingStatus}
                cancellationPolicy={cancellationPolicy}
                termsAndConditionsId={termsAndConditionsId}
                orderNumber={orderNumber}
              />
            ),
          }}
          isHidden={isOffice}
        />
      )}
    </StyledLeftSideContainer>
  );
}

export default PreviewComponents;

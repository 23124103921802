/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
export function scrollTo(offset: number, callback: () => void) {
  const onScroll = () => {
    if (window.scrollY === offset) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();

  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
}

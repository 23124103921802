import styled, {keyframes, css} from 'styled-components';

import {
  NW2SuccessColor,
  offsetXLg,
  borderRadiusDef,
  NW2Green500Color,
  NW2Gray300Color,
  NW2Gray600Color,
  NW2Gray100Color,
  NW2FontSizeH5Md,
  fontSizeMd,
  fontSizeLg,
  fontWeightBold,
  offsetXSm,
  offsetDef,
  NW2Info50Color,
  NW2Info500Color,
  xLgBp,
  fontWeightExtraBold,
  offsetXXXSm,
  fontSizeXSm,
  fontSizeXXXLg,
  fontSizeSm,
  offsetSm,
  lgBp,
  fontSizeXLg,
  offsetXXSm,
  NW2Gray200Color,
} from 'constants/styleVars';
import {EBookingStatus} from 'types/booking';

const confirmedStyles = `background-color: ${NW2SuccessColor};
                               border-color: ${NW2Green500Color};`;

const defaultStyles = `background-color: ${NW2Gray100Color};
                               border-color: ${NW2Gray600Color};`;

const infoStyles = `background-color: ${NW2Info50Color};
                                 border-color: ${NW2Info500Color};`;

const colorsMap: Record<EBookingStatus, string> = {
  [EBookingStatus.CONFIRMED]: confirmedStyles,
  [EBookingStatus.PASSED]: confirmedStyles,
  [EBookingStatus.PENDING_AUTHORISATION]: confirmedStyles,
  [EBookingStatus.EDIT_CANCELED_BY_CONFIRM]: confirmedStyles,
  [EBookingStatus.EDIT_MODE_CANCELED]: confirmedStyles,
  [EBookingStatus.RFP_PENDING]: infoStyles,
  [EBookingStatus.RTC_PENDING]: infoStyles,
  [EBookingStatus.CANCELED]: defaultStyles,
  [EBookingStatus.RFP_DECLINED]: defaultStyles,
  [EBookingStatus.RFP_CANCELLED]: defaultStyles,
  [EBookingStatus.RFP_EXPIRED]: defaultStyles,
  [EBookingStatus.RTC_EXPIRED]: defaultStyles,
  [EBookingStatus.RTC_DECLINED]: defaultStyles,
  [EBookingStatus.RTC_CANCELLED]: defaultStyles,
};

export const Container = styled.div<{type: EBookingStatus}>`
  padding: ${offsetXLg};
  margin-bottom: ${offsetXLg};
  border-radius: ${borderRadiusDef};
  border-left: 4px solid;
  ${({type}) => colorsMap[type]}
`;

export const InfoSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${offsetDef};
`;

export const InfoTitle = styled.div`
  text-transform: uppercase;
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightExtraBold};
  line-height: 1.2;

  @media (min-width: ${xLgBp}px) {
    font-size: ${NW2FontSizeH5Md};
  }
`;

export const InfoSubtitle = styled.div<{offsetTop?: string}>`
  font-size: ${fontSizeMd};
  margin-top: ${({offsetTop}) => offsetTop || 0};
`;

export const BottomSection = styled.div<{loading?: number}>`
  margin-top: ${offsetXLg};
  padding-top: ${offsetXLg};
  border-top: 1px solid ${NW2Gray300Color};
  transition: opacity 0.25s ease;
  ${({loading}) => {
    if (loading) return `opacity: .5; pointer-events: none;`;
  }}
`;

export const StyledBottomSection = styled(BottomSection)`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 24px;
`;

export const CancellationTitle = styled.div`
  text-transform: uppercase;
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightExtraBold};
  margin-bottom: ${offsetXSm};
  display: none;

  @media (min-width: ${lgBp}px) {
    display: block;
  }
`;

export const ChargeMessage = styled.div`
  font-size: ${fontSizeSm};
`;

export const ChargeTitle = styled.div`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXXXSm};
  text-align: end;
`;

export const ChargeSubTitle = styled.div`
  font-size: ${fontSizeXSm};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetSm};
  text-align: end;
`;

export const ChargePrice = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXLg};
  text-align: end;

  @media (min-width: ${lgBp}px) {
    font-size: ${fontSizeXXXLg};
  }
`;

export const ActionTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXSm};
`;

export const ActionSubtitle = styled.div`
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetXLg};
`;

export const ContentContainer = styled.div`
  margin-top: ${offsetXLg};
  padding-top: ${offsetXLg};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const StyledSubTitle = styled.div`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetXSm};
`;

export const StyledUl = styled.ul`
  font-size: ${fontSizeMd};
  padding-left: ${offsetXLg};
  margin: 0;
`;

export const StyledCollapseContainer = styled.div<{
  show: boolean;
  elementHeight: string;
}>`
  overflow: hidden;
  ${({show}) => {
    if (!show) return collapseAnimation;
  }};
`;

export const DeclinedRow = styled.div`
  font-size: ${fontSizeMd};

  &:not(&:last-child) {
    margin-bottom: ${offsetXXSm};
  }

  & > span {
    font-weight: ${fontWeightBold};
    margin-right: ${offsetXSm};
  }
`;

export const StyledDeclinedRow = styled(DeclinedRow)`
  margin-top: ${offsetDef};

  & > span {
    display: block;
    margin-bottom: ${offsetXXSm};
  }
`;

const collapseAnimation = (props: any) => css`
  animation: 0.55s ease-out ${collapseKeyFrame(props.elementHeight)} forwards;
`;

const collapseKeyFrame = (elementHeight: string) => keyframes`
  0% {
    height: ${elementHeight}px;
    opacity: 1;
  }
  5% {
    opacity: .75;
    height: ${elementHeight}px;
  }
  100% {
    height: 0;
    opacity: 0;
  }
`;

import {INITIAL_SEARCH_CRITERIA} from 'store/search/searchSlice';

export const shortListDefaultState = {
  items: [],
  isMaxItemsReached: false,
  checkIn: INITIAL_SEARCH_CRITERIA.startDate,
  checkOut: INITIAL_SEARCH_CRITERIA.endDate,
  isMultiDay: false,
};

export const compareListDefaultState = {
  items: [],
  isLoading: false,
};

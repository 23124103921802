import React from 'react';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {StyledH5} from 'view/components/Typography';
import {
  NoRoomsContainer,
  DesktopIconContainer,
  TitleContainer,
  NoSpaceTextContainer,
  NoSpaceText,
  ButtonContainer,
  PhotoCover,
  StyledDiv,
} from './NoRooms.styles';
import noRooms from 'img/noRooms.jpg';
import noDesks from 'img/noDesks.jpg';
import {useSelector} from 'react-redux';
import {RootState} from 'store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IProps {
  handleCreateRoom: () => void;
  isWorkdesk?: boolean;
}

const NoRooms = ({handleCreateRoom, isWorkdesk}: IProps) => {
  const isMobile: boolean = useAppSelector(({app}) => app.deviceType.isMobile);

  const iconSize = isMobile ? 30 : 44;

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  return (
    <StyledDiv>
      <NoRoomsContainer>
        {!isMobile && (
          <DesktopIconContainer>
            <Icon icon='BRIEFCASE' justify='center' size={iconSize} />
          </DesktopIconContainer>
        )}
        <NoSpaceTextContainer>
          <TitleContainer>
            {isMobile && (
              <Icon icon='BRIEFCASE' justify='center' size={iconSize} />
            )}
            <StyledH5>no spaces created</StyledH5>
          </TitleContainer>
          <NoSpaceText>
            Show organisers what your venue has to offer and start receiving
            bookings now!
          </NoSpaceText>
          <ButtonContainer>
            {!isAgent && (
              <NW2Button
                buttonType='primary'
                data-testid='meeting rooms create new room'
                onClick={handleCreateRoom}
              >
                create new
              </NW2Button>
            )}
          </ButtonContainer>
        </NoSpaceTextContainer>
        <PhotoCover alt='no rooms image' src={isWorkdesk ? noDesks : noRooms} />
      </NoRoomsContainer>
    </StyledDiv>
  );
};

export default NoRooms;

import React, {memo, useMemo, useState} from 'react';
import _debounce from 'lodash/debounce';
import {Form, FormSpy} from 'react-final-form';
import {pluralize} from 'utils/helpers';

import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer/NW2PopoverDrawer';
import Icon from 'view/components/Icon/Icon';
import useToggle from 'hooks/useToggle';

import {
  getNameByType,
  getPopoverTypeConfig,
  getTypeByName,
} from '../../utils/helpers';
import {
  NW2FormItemInput,
  NW2FormItemSelect,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  requiredFieldRules,
  deskCountFieldRules,
} from 'utils/finalFormFieldRules';

import {offsetXLg, NW2Gray600Color} from 'constants/styleVars';
import {ESpaceDetailsFormFields, ICommonProps} from '../../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {EDeskSpaceName} from 'types/venue';
import {
  ContentSubtitle,
  ContentWrapper,
  FormGroup,
  Text,
  TypeContainer,
} from '../../NMMSpacesDetails.styles';

interface IProps extends ICommonProps {
  type: ERoomType;
  capacity: number;
}

const TypeAndCapacity = ({
  type,
  capacity,
  updateSpace,
  isSending,
  isEditorShowed,
  onEditorShowed,
}: IProps) => {
  const [isFormDirty, setFormDirty] = useState(false);
  const [isPopoverShowed, setPopoverShowed] = useToggle(false);

  const handlePopoverToggle = () => {
    setPopoverShowed();
  };

  const typeName = getNameByType(type);
  const {header, body} = getPopoverTypeConfig(type);

  const initialValues = useMemo(() => {
    return {type: typeName, capacity};
  }, [typeName, capacity]);

  const arrayOfTypes = [EDeskSpaceName.OPEN_SPACE, EDeskSpaceName.SHARED_SPACE];
  const typesOptions = arrayOfTypes.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));

  const showForm = (
    <ContentWrapper numberOfColumns={2}>
      <TypeContainer>
        <Text>{typeName}</Text>
        <NW2PopoverDrawer
          header={header}
          body={body}
          placement='bottomLeft'
          open={isPopoverShowed}
          handleVisibleChange={handlePopoverToggle}
          onClose={handlePopoverToggle}
          isMobile={false}
        >
          <Icon icon={'NW2_INFO_CIRCLE'} size={20} lightBlackColor />
        </NW2PopoverDrawer>
      </TypeContainer>

      <Text>{pluralize('desks', capacity)}</Text>
    </ContentWrapper>
  );

  const editForm = (
    <FormGroup columnNumber={2} gap={parseInt(offsetXLg)}>
      <ContentSubtitle marginBottom='0'>Space type</ContentSubtitle>
      <ContentSubtitle marginBottom='0'>Capacity</ContentSubtitle>
      <NW2FormItemSelect
        name={ESpaceDetailsFormFields.TYPE}
        options={typesOptions}
        placeholder='Space'
        label='Space*'
        labelColor={NW2Gray600Color}
        rules={requiredFieldRules('space type')}
        data-testid={`input-space-details-${ESpaceDetailsFormFields.TYPE}`}
      />
      <NW2FormItemInput
        type='number'
        name={ESpaceDetailsFormFields.CAPACITY}
        placeholder='Number of desks'
        label='Number of desks*'
        labelColor={NW2Gray600Color}
        rules={deskCountFieldRules('number of desks')}
        data-testid={`input-space-details-${ESpaceDetailsFormFields.CAPACITY}`}
      />
    </FormGroup>
  );

  const onSubmit = async (values: any) => {
    const type = getTypeByName(values.type);

    const updatedSpace = {
      type,
      capacity: values.capacity,
    };

    updateSpace(updatedSpace);
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return isEditorShowed ? (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          {editForm}

          <NMMSubmitSection
            isLoading={isSending}
            handleCancel={onEditorShowed}
            disabled={!isFormDirty}
          />

          <FormSpy
            subscription={{dirtyFields: true}}
            onChange={processFormFields}
          />
        </form>
      )}
    />
  ) : (
    showForm
  );
};

export default memo(TypeAndCapacity);

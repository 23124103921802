import {TContainer} from 'app';
import {IPackageRequest, IPackageResponse} from 'types/dto/IPackages.type';
import {
  getPackagesRequest,
  getPackagesSuccess,
  packagesFailure,
  packagesRequest,
  updatePackagesSuccess,
} from './packagesSlice';

export const getPackages =
  (accommodationId: number) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(getPackagesRequest());
    venueContainer.getPackages({
      payload: accommodationId,
      onSuccess: (data: IPackageResponse[]) => {
        dispatch(getPackagesSuccess(data));
      },
      onError: (error: any) => dispatch(packagesFailure(error)),
    });
  };

export const activatePackage =
  (accommodationId: number, packageData: IPackageRequest) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(packagesRequest(packageData.type));
    venueContainer.activatePackage({
      payload: {accommodationId, packageData},
      onSuccess: (data: IPackageResponse) => {
        dispatch(updatePackagesSuccess(data));
      },
      onError: (error: any) => dispatch(packagesFailure(error)),
    });
  };

export const updatePackage =
  (accommodationId: number, packageId: number, packageData: IPackageRequest) =>
  (dispatch: any, _: any, {venueContainer}: TContainer) => {
    dispatch(packagesRequest(packageData.type));
    venueContainer.updatePackage({
      payload: {accommodationId, packageId, packageData},
      onSuccess: (data: IPackageResponse) => {
        dispatch(updatePackagesSuccess(data));
      },
      onError: (error: any) => dispatch(packagesFailure(error)),
    });
  };

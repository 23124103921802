import {AxiosResponse} from 'axios';

import {ApiType, IApi} from 'infra/common/http.service';
import {
  IAnnouncement,
  ISpaceData,
  ISpacesData,
  ISpacesDataItem,
  ISpacesDataItemWipPayload,
  IVenue,
  TUpdatePublicVenueBusinessInformationPayload,
} from 'types/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {IPublicVenue} from 'types/dto/IPublicVenue';
import {encodePlusCharacterInEmail} from 'utils/stringUtils';
import {
  ICreateUpdateImage,
  TCreateCorporateVenuePayload,
  TCreateVenuePayload,
} from 'types/app';
import {IUser} from 'types/dto/IUser.types';
import {
  EBookableWith,
  EPriceType,
  EResourcesType,
  IExtrasOption,
  IExtrasRequest,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {UploadFile} from 'antd/es/upload/interface';
import {
  findCoverImageFile,
  removeFileTypeFromCoverImage,
} from 'view/components/ImageUploaderComponent';
import {INVENTORY} from 'infra/common/config.service';
import {serverAgent} from 'store/config';
import {EAmenityType} from 'types/amenities';
import {IUpdateAnnouncementStatusPayload} from 'store/venue/types';
import {IPackageRequest, IPackageResponse} from 'types/dto/IPackages.type';

const getFormDataCreateVenue = ({
  venue,
  files = [],
  fieldName = 'venue',
  isRegistration,
}: TCreateVenuePayload) => {
  const formData = new FormData();

  formData.append(
    fieldName,
    new Blob([JSON.stringify(venue)], {
      type: 'application/json',
    }),
  );

  if (!isRegistration) {
    files
      .filter((file) => file.originFileObj)
      .forEach((file) => {
        formData.append('file', file.originFileObj || '');
      });
  } else {
    files.forEach((file) => {
      // we are able to send existed or already added for registration flow
      formData.append('file', file.originFileObj || JSON.stringify(file));
    });
  }

  return formData;
};

const venueRepository = (api: IApi) => ({
  getAmenities: async (amenityTypes: EAmenityType[]): Promise<IAmenity[]> =>
    await api.get(
      ApiType.Inventory,
      `amenities?types=${amenityTypes.join(',')}`,
    ),
  getIsEmailAvailable: async (email: string): Promise<AxiosResponse> =>
    await api.get(
      ApiType.Inventory,
      `customers/isAvailable?email=${encodePlusCharacterInEmail(
        email.toLowerCase(),
      )}`,
    ),
  getVenueTypes: async () =>
    await api.get(ApiType.Inventory, 'accommodations/building-types'),

  getVenueCharacters: async () =>
    await api.get(ApiType.Inventory, 'accommodations/characters'),

  getVenueCurrencies: async () => await api.get(ApiType.Inventory, 'currency'),
  getPublicVenueById: async (venueId: number): Promise<IPublicVenue> => {
    return await api.get(ApiType.Inventory, `accommodations/${venueId}`);
  },

  createPublicVenue: async ({venue, files}: TCreateVenuePayload) => {
    const formData = getFormDataCreateVenue({venue, files});

    return await api.add(ApiType.Inventory, 'venues', formData);
  },

  createCorporateVenue: async ({
    accountId,
    venue,
    files,
  }: TCreateCorporateVenuePayload) => {
    const formData = getFormDataCreateVenue({
      venue,
      files,
      fieldName: 'office',
    });
    return await api.add(
      ApiType.Inventory,
      `corporate-offices?corporate-account-id=${accountId}`,
      formData,
    );
  },

  updateVenueById: async ({
    venue,
    files = [],
  }: TCreateVenuePayload): Promise<number> => {
    const formData = getFormDataCreateVenue({venue, files});
    return await api.add(
      ApiType.Inventory,
      `venues/profile/${venue.id}`,
      formData,
    );
  },
  updatePublicVenueBusinessInformation: async (
    data: TUpdatePublicVenueBusinessInformationPayload,
  ) => {
    return await api.update(
      ApiType.Inventory,
      `venues/payment/${data.id}`,
      data,
    );
  },

  updateCorporateVenueById: async ({
    accountId,
    venue,
    files = [],
  }: TCreateCorporateVenuePayload) => {
    const formData = getFormDataCreateVenue({
      venue,
      files,
      fieldName: 'office',
    });
    return await api.add(
      ApiType.Inventory,
      `corporate-offices/${accountId}`,
      formData,
    );
  },

  getCorporateVenues: async (id: number): Promise<IVenue[]> => {
    return await api.get(
      ApiType.Inventory,
      `corporate-offices?corporate-account-id=${id}`,
    );
  },

  getServicesSpaces: async (id: number): Promise<ISpacesData> => {
    return await api.get(ApiType.Inventory, `units?accommodation-id=${id}`);
  },

  getWorkInProgressById: async (id: number): Promise<ISpacesDataItem[]> => {
    return await api.get(
      ApiType.Inventory,
      `units/work-in-progress?accommodation-id=${id}`,
    );
  },

  setNmmSpace: async ({
    id,
    data,
  }: {
    id: number;
    data: any;
  }): Promise<ISpaceData> => {
    const {imageFiles, ...formData} = data;

    const form = new FormData();

    form.append(
      'formData',
      new Blob([JSON.stringify(formData)], {
        type: 'application/json',
      }),
    );

    imageFiles.forEach((file: File) => {
      form.append('file', file);
    });

    return await api.add(
      ApiType.Inventory,
      `units?accommodationId=${id}`,
      form,
    );
  },

  editNMMSpace: async ({
    data,
  }: {
    data: ISpacesDataItemWipPayload;
  }): Promise<ISpacesDataItem> => {
    const formData = new FormData();

    formData.append(
      'formData',
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      }),
    );

    const {id, files} = data;

    if (files?.length) {
      files.forEach((file) => {
        formData.append(
          'file',
          ((file as UploadFile).originFileObj || file) as Blob,
        );
      });
    }

    return await api.add(ApiType.Inventory, `units/${id}`, formData);
  },

  getSpaceDeletionStatus: async (id: number) =>
    await api.get(ApiType.Inventory, `units/${id}/validate-for-deletion`),

  deleteSpace: async (id: number) =>
    await api.delete(ApiType.Inventory, `units/${id}`),

  getCorporateVenueById: async (id: number): Promise<IVenue> => {
    return await api.get(ApiType.Inventory, `accommodations/${id}`);
  },

  getPropertyManagerInfo: async (id: number): Promise<IUser> => {
    return await api.get(ApiType.Inventory, `property-managers/${id}`);
  },

  getAvailableExtras: async (accommodationId: number) =>
    await api.get(
      ApiType.Inventory,
      `accommodations/${accommodationId}/extras`,
    ),

  getPackages: async (accommodationId: number): Promise<IPackageResponse[]> => {
    return await api.get(
      ApiType.Inventory,
      `accommodations/${accommodationId}/packages`,
    );
  },

  activatePackage: async (payload: {
    accommodationId: number;
    packageData: IPackageRequest;
  }): Promise<IPackageRequest> => {
    const {accommodationId, packageData} = payload;
    return await api.add(
      ApiType.Inventory,
      `accommodations/${accommodationId}/packages`,
      packageData,
    );
  },

  updatePackage: async (payload: {
    accommodationId: number;
    packageId: number;
    packageData: IPackageRequest;
  }): Promise<IPackageRequest> => {
    const {accommodationId, packageId, packageData} = payload;
    return await api.update(
      ApiType.Inventory,
      `accommodations/${accommodationId}/packages/${packageId}`,
      packageData,
    );
  },

  getExtrasOption: async (
    resourcesTypes: EResourcesType[],
  ): Promise<IExtrasOption[]> => {
    return await api.get(
      ApiType.Inventory,
      `extras?type=${resourcesTypes.join(',')}`,
    );
  },

  addExtra: async (payload: {
    accommodationId: number;
    extraId: number;
    extraData: IExtrasRequest;
  }): Promise<IExtrasResponse> => {
    const {accommodationId, extraId, extraData} = payload;
    return await api.add(
      ApiType.Inventory,
      `accommodations/${accommodationId}/extras?extra_id=${extraId}`,
      extraData,
    );
  },

  addExtrasList: async (payload: {
    accommodationId: number;
    data: {
      extraId: number;
      bookableWith: EBookableWith[];
      priceType: EPriceType;
      price: number;
      isEnabled: boolean;
    }[];
  }): Promise<IExtrasResponse[]> => {
    const {accommodationId, data} = payload;
    return await api.add(
      ApiType.Inventory,
      `accommodations/${accommodationId}/extras-list`,
      data,
    );
  },

  // deleteExtra: async (payload: {
  //   accommodationId: number;
  //   extraItemId: number;
  // }): Promise<null> => {
  //   const {accommodationId, extraItemId} = payload;
  //   return await api.delete(
  //     ApiType.Inventory,
  //     `accommodations/${accommodationId}/extras/${extraItemId}`,
  //   );
  // },

  // updateExtraStatus: async (
  //   accommodationId: number,
  //   extraItemId: number,
  //   status: boolean,
  // ): Promise<IExtrasResponse> => {
  //   return await api.update(
  //     ApiType.Inventory,
  //     `accommodations/${accommodationId}/extras/${extraItemId}/active-status?is_enabled=${status}`,
  //     {},
  //   );
  // },

  updateExtra: async (
    accommodationId: number,
    extraItemId: number,
    data: IExtrasRequest,
  ): Promise<IExtrasResponse> => {
    return await api.update(
      ApiType.Inventory,
      `accommodations/${accommodationId}/extras/${extraItemId}`,
      data,
    );
  },

  registerHmdVenue: async ({
    venue,
    files,
    isOfferRequest,
  }: TCreateVenuePayload) => {
    const formData = getFormDataCreateVenue({
      venue,
      files,
      isRegistration: true,
    });

    const appendOffer = isOfferRequest ? '?isOfferRequest=true' : '';
    return await api.add(
      ApiType.Inventory,
      `venues/register${appendOffer}`,
      formData,
    );
  },

  updateVenueImages: async ({
    venueId,
    spaceId,
    entityName,
    images = [],
    coverImage = '',
    idsToDelete,
    handleProgress,
  }: ICreateUpdateImage) => {
    const formData = new FormData();
    images.forEach((image: any) => {
      formData.append('file', image.originFileObj || image);
    });
    const isFormData = formData instanceof FormData;
    const data: FormData | string = isFormData
      ? formData
      : JSON.stringify(formData);
    const headers: any = {
      'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
    };
    const coverImageFile = findCoverImageFile(images, coverImage);
    const coverImageWithoutFileType =
      removeFileTypeFromCoverImage(coverImageFile);

    headers['coverFileName'] = coverImageWithoutFileType
      ? coverImageWithoutFileType
      : coverImageFile && coverImageFile.name;
    headers['deletedIds'] = idsToDelete;

    /**
     * we use same endpoint for update venue photos & space photos
     * in case for space we use spaceId like entityId
     */
    const entityId = spaceId || venueId;

    const url =
      INVENTORY() +
      `storage/${entityName}/${entityId}/documents/existed/${venueId}`;

    return await serverAgent.post(url, data, {
      headers,
      onUploadProgress: (progressEvent) => {
        if (typeof handleProgress === 'function' && progressEvent.total) {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );

          handleProgress(percent);
        }
      },
    });
  },

  getAnnouncementByUserId: async (userId: number): Promise<IAnnouncement[]> => {
    return await api.get(ApiType.Notification, `announcement/user/${userId}`);
  },

  updateAnnouncementStatus: async (
    data: IUpdateAnnouncementStatusPayload,
  ): Promise<IAnnouncement[]> => {
    return await api.update(ApiType.Notification, `announcement/user`, data);
  },
});

export default venueRepository;

import React from 'react';
import styled from 'styled-components';
import {pluralize} from 'utils/helpers';

import {
  fontWeightBold,
  fontSizeXSm,
  whiteColor,
  NW2Green500Color,
  NW2Gray500Color,
  NW2Gray200Color,
  blackColor,
} from 'constants/styleVars';

const StyledText = styled.div<{isCapacityLow: boolean; isDark?: boolean}>`
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
  color: ${({isDark}) => (isDark ? blackColor : whiteColor)};
  border-radius: 6px;
  border: 1px solid ${({isDark}) => (isDark ? NW2Gray200Color : whiteColor)};
  padding: 1px 6px;
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 6px;
    margin-right: 6px;
    ${({isCapacityLow}) =>
      isCapacityLow
        ? `
    height: 3px;
    background-color: ${NW2Gray500Color}
  `
        : `
    height: 6px;
    border-radius: 50%;
    background-color: ${NW2Green500Color}`}
  }
`;

type TProps = {
  capacity: number;
  isDark?: boolean;
};

const DeskAvailabilityLabel = ({capacity, isDark, ...restProps}: TProps) => {
  return (
    <StyledText isCapacityLow={!capacity} isDark={isDark} {...restProps}>
      {`${capacity} ${pluralize('desk', capacity, false)} free`}
    </StyledText>
  );
};

export default DeskAvailabilityLabel;

import React, {ReactNode} from 'react';

import SidebarMenu from './components/SidebarMenu';
import Header from 'view/venue/NW2VenueProfile/components/Header/Header';

import {Wrapper} from './SupplierLayout.styles';
import {ISection} from './SupplierLayout.types';
import {IGlobalVenue} from 'types/venue';
import {EVenueSupplierPagesWithHeader} from 'view/venue/NW2VenueProfile/types';

interface IProps {
  globalVenue: IGlobalVenue;
  showHeaderForSection: EVenueSupplierPagesWithHeader;
  children: ReactNode;
  initialActiveSection: string;
  sections: ISection[];
  headerStatistics?: {
    totalAvailable: number;
    active: number;
    disabled: number;
  };
  sideBarTitle: string;
  handleCreateRoom?: () => void;
  handleSectionChange: any;
}

const SupplierLayout = ({
  globalVenue,
  showHeaderForSection,
  children,
  initialActiveSection,
  sections,
  sideBarTitle,
  headerStatistics,
  handleCreateRoom,
  handleSectionChange,
}: IProps) => {
  return (
    <Wrapper>
      <SidebarMenu
        activeSection={initialActiveSection}
        sections={sections}
        handleSectionChange={handleSectionChange}
        title={sideBarTitle}
      />

      <main>
        <Header
          globalVenue={globalVenue}
          headerStatistics={headerStatistics}
          showHeaderForSection={showHeaderForSection}
          handleCreateRoom={handleCreateRoom}
        />

        {children}
      </main>
    </Wrapper>
  );
};

export default SupplierLayout;

import React from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import NW2BillingAddressOptions from './components/NW2BillingAddressOptions';
import NW2BillingAddressList from './components/NW2BillingAddressList';
import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';

import {setCustomerBillingAddressPopup} from 'store/app/appSlice';

function NW2BillingAddressPopup() {
  const dispatch = useDispatch();
  const isShowed: boolean =
    useAppSelector(
      ({app}) => app.authorizationPopups.setCustomerBillingAddressPopup,
    ) ?? false;
  const closeBillingAddressDetails = () =>
    dispatch(
      setCustomerBillingAddressPopup({setCustomerBillingAddressPopup: false}),
    );

  return (
    <NW2ModalTwoColumns
      title='Billing address'
      isShowed={isShowed}
      onToggleShow={closeBillingAddressDetails}
      colLeft={<NW2BillingAddressOptions />}
      colRight={<NW2BillingAddressList />}
      isRightColumnBeforeLeft
    />
  );
}

export default NW2BillingAddressPopup;

import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {FormInstance} from 'antd';
import _get from 'lodash/get';

import AddressInfo from './components/AddressInfo';
import AddressForm from './components/AddressForm';

import {EMPTY_OBJECT} from 'constants/app';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {ILocation} from 'types/dto/ILocation.types';
import {TStepMode} from 'types/app';
import {EAccommodationType} from 'types/dto/IPublicVenue';

type Props = {
  mode?: TStepMode;
  location?: ILocation; // we can pass custom office location data from any parent component
  form: FormInstance;
  accommodationType: EAccommodationType;
};

const Address: FC<Props> = ({
  mode,
  location: officeLocation,
  form,
  accommodationType,
}: Props) => {
  const locationFromStore = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location`),
  );

  const location = officeLocation || locationFromStore || EMPTY_OBJECT;

  return mode === 'add' ? (
    <AddressForm
      location={location}
      form={form}
      accommodationType={accommodationType}
    />
  ) : (
    <AddressInfo location={location} accommodationType={accommodationType} />
  );
};

export default Address;

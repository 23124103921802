import React from 'react';

import {
  Content,
  Section,
  Container,
  SubSubTitle,
  StyledTitle,
  Paragraph,
  Text,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';

const TextContent = () => {
  return (
    <Content>
      <Section>
        <Container>
          <Paragraph>HRS GmbH</Paragraph>
          <Paragraph>Breslauer Platz 4</Paragraph>
          <Paragraph>50668 Köln</Paragraph>
          <Paragraph>Germany</Paragraph>
        </Container>
        <Container>
          <StyledTitle>Telephone</StyledTitle>
          <Paragraph>+49 221 2077 600</Paragraph>
        </Container>
        <Container>
          <StyledTitle>Fax</StyledTitle>
          <Paragraph>+49 221 2077 388</Paragraph>
        </Container>
        <Container>
          <StyledTitle>Email</StyledTitle>
          <Paragraph>office@hrs.de</Paragraph>
        </Container>
        <Container>
          <StyledTitle>Internet</StyledTitle>
          <Paragraph>www.hrs.de / www.hrs.com / www.hrs.cn</Paragraph>
        </Container>
        <Container>
          <StyledTitle>Administrative court</StyledTitle>
          <Paragraph>Register HRB 90154</Paragraph>
          <Paragraph>CEO/Managing director: Tobias Ragge</Paragraph>
          <Paragraph>VAT ID no.: DE 252323088</Paragraph>
        </Container>
        <Container>
          <SubSubTitle>
            Link to the European Commission's platform for Online Dispute
            Resolution:
          </SubSubTitle>
          <Paragraph>http://ec.europa.eu/consumers/odr/</Paragraph>
        </Container>
      </Section>
      <Text>© HRS - HOTEL RESERVATION SERVICE All rights reserved</Text>
    </Content>
  );
};

export default TextContent;

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

interface IUserDataFromToken {
  cognitoUserRole: EUserRoleCognito;
  profileId: string;
  expiresAt: number;
  email: string;
}

const tokenService = {
  parseJwt: (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  },
  getRoles: (token: string): EUserRoleCognito[] => {
    const tokenValues = tokenService.parseJwt(token);
    return tokenValues['cognito:groups'];
  },
  getUserData: (token: string): IUserDataFromToken => {
    const tokenValues = tokenService.parseJwt(token);
    const cognitoUserRole = tokenValues['cognito:groups']
      ? tokenValues['cognito:groups'][0]
      : EUserRoleCognito.ROLE_CUSTOMER;
    const profileId = tokenValues.profile_id;

    return {
      cognitoUserRole,
      profileId,
      // need multiply by 1000 because Date constructor expects milliseconds
      expiresAt: tokenValues.exp * 1000,
      email: tokenValues.email,
    };
  },
};

export default tokenService;

import React from 'react';
import styled from 'styled-components';
import {useForm} from 'react-final-form';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {mdBp, offsetSm, offsetXLg} from 'constants/styleVars';

type TProps = {
  onCancel: () => void;
  isSubmitDisabled?: boolean;
  submitButtonTitle: string;
};

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${offsetXLg};

  @media (min-width: ${mdBp}px) {
    justify-content: flex-end;
  } ;
`;

const SaveButton = styled(NW2Button)`
  margin-left: ${offsetSm};
`;

export const NW2AddEditBillingAddressButtonsBlock = ({
  onCancel,
  isSubmitDisabled = false,
  submitButtonTitle,
}: TProps) => {
  const form = useForm();

  const onCancelForm = () => {
    onCancel();
    form.reset();
  };

  return (
    <ButtonsContainer>
      <NW2Button
        type='button'
        inline
        buttonType='secondary'
        onClick={onCancelForm}
        size='responsiveAdaptive'
      >
        Cancel
      </NW2Button>

      <SaveButton
        type='submit'
        inline
        buttonType='primary'
        disabled={isSubmitDisabled}
        size='responsiveAdaptive'
      >
        {submitButtonTitle}
      </SaveButton>
    </ButtonsContainer>
  );
};

export default NW2AddEditBillingAddressButtonsBlock;

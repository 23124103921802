import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {EVenueCharacters, TVenueCharacterType} from 'types/venue';

const useVenueCharactersOptions = () => {
  const venueCharacters: TVenueCharacterType[] = useSelector((state) =>
    _get(state, `venue.venueCharacters`),
  );

  return useMemo(
    () =>
      venueCharacters.reduce<{label: string; value: TVenueCharacterType}[]>(
        (acc, character) => {
          const characterFromEnum = EVenueCharacters[character];

          if (characterFromEnum) {
            acc.push({label: characterFromEnum, value: character});
          }
          return acc;
        },
        [],
      ),
    [venueCharacters],
  );
};

export default useVenueCharactersOptions;

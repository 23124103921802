import React, {ChangeEvent} from 'react';

import {ERoomType} from 'types/dto/ERoomType.type';
import {Container, Tab} from './Tabs.styles';

const tabsData = [
  {
    label: 'Meeting rooms',
    value: ERoomType.MEETING_ROOM,
  },
  {
    label: 'Groups',
    value: ERoomType.GROUPS,
  },
  {
    label: 'Work desks',
    value: ERoomType.WORK_SPACE,
  },
];

type TProps = {
  minimized: boolean;
  onRoomTypeChange: (data: ERoomType) => void;
  roomTypeValue: ERoomType;
};
function Tabs({minimized, onRoomTypeChange, roomTypeValue}: TProps) {
  const onSelectTabHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as ERoomType;

    if (value !== roomTypeValue) {
      onRoomTypeChange(value);
    }
  };

  if (minimized) return null;

  return (
    <Container>
      {tabsData.map(({label, value}) => (
        <Tab key={value}>
          <input
            type='radio'
            name='mode'
            id={value}
            value={value}
            onChange={onSelectTabHandler}
            checked={roomTypeValue === value}
          />
          <label htmlFor={value}>
            <span>{label}</span>
          </label>
        </Tab>
      ))}
    </Container>
  );
}

export default Tabs;

import React from 'react';

import {EDeskSpaceName} from 'types/venue';
import {blackColor, NW2Primary} from 'constants/styleVars';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ItemWithIcon, PrefixIcon} from './NMMSpacesList.styles';
import {TStatusContent} from './types';

export const getStatusConfig = (): TStatusContent => ({
  bgColor: NW2Primary,
  color: blackColor,
  size: 'small',
  icon: 'NW2_INFO',
  statusText: 'live',
  text: `This space is live, which means it will appear in bookers search results and can be booked. `,
});

export const getTypeConfig = (
  roomType: ERoomType,
  type: ERoomType,
  isExternalVenue: boolean,
  isRfp?: boolean,
) => {
  switch (roomType) {
    case ERoomType.WORK_SPACE:
      return (
        <ItemWithIcon data-testid='space-card-rfp'>
          {type === ERoomType.SHARED_SPACE && (
            <>
              <PrefixIcon icon='NMM_CO_WORKING' />
              {EDeskSpaceName.SHARED_SPACE}
            </>
          )}
          {type === ERoomType.OPEN_SPACE && (
            <>
              <PrefixIcon icon='NMM_CO_WORKING' />
              {EDeskSpaceName.OPEN_SPACE}
            </>
          )}
        </ItemWithIcon>
      );
    default:
      return (
        isExternalVenue && (
          <ItemWithIcon data-testid='space-card-rfp'>
            {isRfp ? (
              <>
                <PrefixIcon icon='CLOCK' />
                Request-to-book
              </>
            ) : (
              <>
                <PrefixIcon icon='LIGHTENING' />
                Instant book
              </>
            )}
          </ItemWithIcon>
        )
      );
  }
};

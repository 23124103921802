import React from 'react';

import {
  Content,
  Section,
  Container,
  StyledContainer,
  Title,
  SubTitle,
  StyledTitle,
  Paragraph,
  StyledUnorderedList,
  StyledList,
  StyledExternalLink,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {
  HRS_GDPR_URL,
  PRODUCT_SECURITY_EMAIL,
  POLICIES_GOOGLE_URL,
} from 'constants/app';

const TextContent = () => {
  return (
    <Content>
      <Section>
        <SubTitle>Data Privacy Notice by HRS GmbH</SubTitle>
        <Title>Responsibility for the Processing of Personal Data</Title>
        <Paragraph>
          HRS GmbH, domiciled at Breslauer Platz 4, 50668 Cologne, Germany
          (hereinafter referred to as "HRS") is responsible for the processing
          of your personal data on its web pages and mobile apps.
        </Paragraph>
      </Section>
      <Section>
        <Title>In detail</Title>
        <Paragraph>
          Responsibility for the collection, processing, and use of your
          personal data as defined by the German Federal Data Privacy Act rests
          with HRS GmbH, Breslauer Platz 4, 50668 Cologne (hereinafter "HRS").
        </Paragraph>
        <Paragraph>
          If you wish to object to the collection, processing, or use of your
          data by HRS in accordance with these data privacy rules in general or
          for individual measures, you may send your objection via e-mail, fax,
          or mail to the following address:
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>HRS GmbH</Paragraph>
        <Paragraph>Breslauer Platz 4</Paragraph>
        <Paragraph>50668 Cologne</Paragraph>
      </Section>
      <Section>
        <Paragraph>Fax: +49 221 2077 666</Paragraph>
        <Paragraph>E-mail: datenschutz@hrs.de</Paragraph>
      </Section>
      <Section>
        <Paragraph>
          You can reach our Data Protection Officer under datenschutz@hrs.de
        </Paragraph>
        <Paragraph>
          The most important issue first: data privacy is a matter of trust, and
          your trust is important to us. We respect your privacy and your
          personal sphere. Protection and legal processing of your personal data
          are therefore important issues to us. To ensure that you feel secure
          when visiting our web pages, whenever we process your personal data,
          we strictly comply with the legal provisions, and want to let you know
          how we process your personal data.
        </Paragraph>
        <Paragraph>
          In this document you will find out more on how we use and process your
          personal data. We are certain that the information is easy to read and
          transparent, so that you will understand what we want to say, without
          having a hard time. Furthermore, you will learn how you can contact us
          in case you have any questions regarding your personal data, which we
          will be more than happy to answer. Therefore, please read our rules
          regarding cookies, where we inform you how HRS uses cookies and
          similar technologies. You will find our cookie policy in the footer
          navigation at the bottom of our website.
        </Paragraph>
        <Paragraph>
          In case you have already used our service, you already know that HRS
          offers travel-related online services on its own web page and via
          mobile apps as well as other online platforms, such as partner
          websites. The data privacy notice below explains which data are
          collected on our platforms, which data are processed and used, and
          how.
        </Paragraph>
        <Paragraph>
          You can print or store this document by using the usual functionality
          of your Internet service program (= browser: in most cases, "File" →
          "Save as"). Our data privacy rules may change from time to time. As
          long as you care about your privacy, we recommend that you visit this
          page on a regular basis to ensure that you are always aware of the
          latest developments. We wish you a relaxing and nice trip!
        </Paragraph>
      </Section>
      <Section>
        <Title>
          Which types of personal data are collected by HRS, and on what kind of
          legal basis?
        </Title>
        <Paragraph>
          Without any information, we are unable to find the perfect
          accommodation and venue (hereinafter together "Premises") for you.
          When you use our service, we ask you for specific information. This is
          basic information such as your name, your contact data, the name of
          guests accompanying you, and your payment information. This
          information is required to carry out the respective agency orders as
          defined by Article 6, Paragraph 1, letter b Alt. 1 of the General Data
          Protection Regulation. However, we herewith expressly inform you that
          you are not legally required to provide any personal data to us – if
          you refrain from doing so, however, this may impact the services we
          are able to offer to you. For example, you will certainly understand
          that, in case you do not specify any destination, we will be unable to
          make any bookings of Premises.
        </Paragraph>
        <Paragraph>
          In addition, we collect information regarding your computer. This may
          be your IP address, the browser you use, and your language settings.
          It is also possible that we receive information about you from third
          parties or automatically collect other kinds of information. As long
          as we have received your consent in this respect, data processing is
          based on Article 6, Paragraph 1, Letter a of the General Data
          Protection Regulation; as long as these measures are necessary for
          technical reasons to ensure the functionality of our web pages and
          services, this is covered by Article 6, Paragraph 1, letter f of the
          General Data Protection Regulation. Our interest in the security of
          our offers is essential considering that, in the case of insufficient
          IT security, its operation as such – just like your personal data –
          would be greatly jeopardized. We additionally ensure that your
          interests in this regard are protected through reasonable measures,
          e.g. data anonymization or pseudonymization. Finally, please note that
          there is no automatic decision-making process, e.g. within the scope
          of automatic profiling.
        </Paragraph>
      </Section>
      <Section>
        <Title>In detail:</Title>
        <SubTitle>Personal information we receive from you</SubTitle>
        <Container>
          <Paragraph>
            HRS.de collects and processes information you provide to us. When
            you make a booking, you are asked to provide (at least) your name
            and your e-mail address. We may possibly ask you to indicate your
            residence, your phone number, payment information, the name of any
            guests traveling and staying with you, and possible preferences you
            may have regarding your stay.
          </Paragraph>
          <Paragraph>
            When you contact our Customer Service team or contact us in another
            manner (for example via social media or through communication with
            the Premises booked by you via our website), we also collect
            information regarding these events about you. After a stay, you may
            receive a request to provide a guest rating to assist future guests
            to find the Premises they are specifically looking for.
          </Paragraph>
          <Paragraph>
            You also provide us with information in other ways. For example,
            when surfing with your mobile device, you may allow HRS to access
            your current location or your contact details - this helps us offer
            you the best possible service. You may also create a user account to
            store your personal settings, view prior bookings, or plan and
            manage future bookings. You may possibly participate in referral
            programs or sweepstakes, which once again means that you provide us
            with personal information. In addition, you may send us feedback or
            ask us to assist you to use the HRS service.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>
            Personal information we receive from you about others
          </SubTitle>
          <Paragraph>
            Of course, you may possibly not just book for yourself, but are
            rather joined by other guests about whom you provide information
            when making a booking, or you may possibly make a booking for
            someone else. In some cases, you can use HRS to share information
            with others, for example, when sharing the desired Premises or
            participating in a referral program. All of these data, however, are
            only processed in the same manner as indicated there. In this
            respect, you can find additional information on the respective
            pages.
          </Paragraph>
          <Paragraph>
            However, we must inform you that it is your responsibility to ensure
            that the persons about whom you provide personal information are
            aware of such action, and that they have accepted the manner in
            which HRS will use such information.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>
            Personal information automatically collected by us
          </SubTitle>
          <Paragraph>
            When you visit our websites or use our apps, and even if you do not
            make any booking, we automatically process the following data: IP
            address, date and time of your access to our services, hardware,
            software or browser type, operating system of your computer,
            application version, and language settings, as well as information
            regarding clicks and the pages accessed by you.
          </Paragraph>
          <Paragraph>
            When using a mobile device, the following data may be collected:
            type of mobile device and its device-specific settings and
            properties as well as your geographical location, information
            regarding app crashes, as well as other system activities. Whenever
            you complete a booking, our system records which website and/or apps
            carried out this booking or via which other web pages you made your
            booking on HRS.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>
            Personal information we receive from other sources
          </SubTitle>
          <Paragraph>
            However, we not only receive the data you provide to us – we may
            possibly also receive information about you from other sources. This
            includes business partners such as our affiliate partners and other
            independent third parties, and all information we receive from them
            may be compiled with the information you provide to us. Perhaps you
            do think that it is strange that we receive information about you
            from our business partners. No worries, everything is OK, and this
            is easy to explain.
          </Paragraph>
          <Paragraph>
            The Premises booking service of HRS is not only available to you on
            HRS and via the HRS apps, but it is actually also offered on partner
            sites as an integrated part of their online service. Whenever you
            use them, you provide your booking details to our business partners
            who, in turn, provide this information to us. We also integrate the
            services of other providers to process payment between the booking
            party and the accommodation. Providers share payment information to
            allow us to process your booking and ensure that the payment process
            is as smooth as possible for you.
          </Paragraph>
          <Paragraph>
            Another example would be that we possibly integrate calling services
            on our platforms, which allow you to contact the Premises booked by
            you about your booking. In this case, we receive metadata regarding
            the call (for example place of the call, your identity, and the date
            as well as length of the call).
          </Paragraph>
          <Paragraph>
            Premises partners may also share information about you with HRS. For
            example, this may be the case if you have any questions about your
            pending booking, whenever there are disputes regarding a booking, or
            in the case of other communications about your booking via HRS.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>Why does HRS collect and use your personal data?</Title>
          <Paragraph>
            We ask you to provide personal data for practical reasons, for
            administration and processing of your bookings, to ensure that you
            receive the best possible service and protect your data. We use your
            personal data to contact you or inform you about the latest offers.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>In detail:</SubTitle>
          <Paragraph>
            We use the information collected about you for different purposes.
            Your personal data may be used as follows:
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Bookings</SubTitle>
          <Paragraph>
            First and foremost, we use your personal data to administer and
            complete your online booking - this is important to ensure that we
            can achieve the purpose of our company. We may also offer other
            products and services of other providers to you. This takes place
            via our online service, and in case you decide to use it, we use
            your information to process and complete your order in the same
            manner as if you had exclusively made a booking with the other
            provider.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Customer Service</SubTitle>
          <Paragraph>
            We offer international customer service. Whenever you share your
            details with our Service employees, you allow us to reply to you
            whenever this is necessary. This includes answering any questions
            you may have regarding your booking - or also answering other
            questions.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Customer Account</SubTitle>
          <Paragraph>
            You can create a user account on our web pages or via our apps. We
            use this information to administer this account, and you can use
            different useful functions. For example, you can manage your
            bookings or other orders, use special offers and services, easily
            make bookings for the future, or manage your personal settings.
            Managing your personal settings includes the preparation of a list
            of favorites, displaying bookings made by you, your booking
            specifications, and other travel- and Premises-relevant information
            you have provided to us. Therefore, we can also view any ratings you
            have provided. In case you have an HRS account for business
            purposes, you can store your contact data, manage your bookings for
            business customers, and store additional information regarding your
            company to ensure smooth processing.
          </Paragraph>
        </Container>
        <Container>
          <Title>Marketing</Title>
          <Paragraph>
            We also use your information for marketing purposes. These
            activities cover the following:
          </Paragraph>
          <StyledUnorderedList>
            <StyledList>
              The use of your contact information for the sending of travel- and
              Premises-related products and services whenever you make a booking
              through us or create a user account. You may unsubscribe from
              these marketing newsletters at any time in a quick and simple
              manner – to do so, simply click on the "Unsubscribe" link
              contained in each and every newsletter.
            </StyledList>
            <StyledList>
              Based on the information you share with us, you will see
              personalized offers on the HRS website, in mobile apps, or on
              third-party web pages/apps (including social media web pages).
              These may be offers which you can book directly on the HRS website
              as well as offers by third-party providers, and products or
              services which we believe might be interesting for you.
            </StyledList>
            <StyledList>
              Whenever you participate in other promotional activities (for
              example sweepstakes, referral programs, or competitions), the
              information that is necessary for such purpose is used for the
              performance of these activities.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <Title>Other Communication</Title>
          <Paragraph>
            We may possibly contact you via e-mail, mail, telephone, or text
            message, depending on which contact information you have shared with
            us. We will also process the communication you have shared with us.
            This may be based on the following reasons:
          </Paragraph>
          <StyledUnorderedList>
            <StyledList>
              Answering and processing of all requests made by you or your
              booked Premises. HRS furthermore offers guests and Premises
              partners different options to exchange information, requests, and
              comments regarding Premises and existing bookings made via HRS. In
              case you wish to receive more information, scroll down to the
              section "How does HRS use communications which you and the
              Premises booked by you send to HRS?"
            </StyledList>
            <StyledList>
              In case you have not completed your booking, we may send you an
              e-mail to remind you to continue with your booking. We believe
              that this additional service will help you continue a booking
              without having to look up the Premises again or having to re-enter
              the booking data.
            </StyledList>
            <StyledList>
              Whenever you use our services, we may send you a questionnaire or
              an invitation to rate your experience with HRS.
            </StyledList>
            <StyledList>
              We may also send you other booking-relevant data, for example the
              contact data of HRS, in case you need our support during a
              booking, or we may also send you information which may be helpful
              to plan your booking, to make the best of your stay. We may also
              send you information regarding bookings which you have already
              made via HRS.
            </StyledList>
            <StyledList>
              Likewise, even if you have no existing booking, we may possibly
              send you other messages of an administrative nature, including
              security information.
            </StyledList>
          </StyledUnorderedList>
        </Container>
        <Container>
          <SubTitle>Market Research</SubTitle>
          <Paragraph>
            Occasionally, we ask our customers to participate in surveys. All
            additional personal data that are provided within the scope of such
            surveys are only used with your consent.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Ensuring Secure and Reliable Service</SubTitle>
          <Paragraph>
            To create a reliable environment for you, other travellers, the
            business partners of HRS, and our Premises partners, we may use
            personal data to detect and prevent fraud and other illegal and/or
            undesired activities. We may additionally use personal data for risk
            assessments and security purposes - including authentication of
            users and bookings. At times, we may therefore have to put a
            temporary hold on certain bookings.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Improvement of our Service</SubTitle>
          <Paragraph>
            We also use personal data for analytic purposes. This not only
            serves to improve our service and optimize the experience of users,
            but may also be carried out for testing purposes, troubleshooting,
            and improvement of the functionalities and quality of our online
            service. In this respect, it is our goal to optimize our online
            platform and customize it according to your needs and thereby make
            our web pages and apps even more user-friendly and attractive.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>
            Guest Ratings and Other Destination- and Premises-Relevant
            Information
          </SubTitle>
          <Paragraph>
            During and after your stay at a Premise booked via us, you may be
            invited to provide a guest rating. We may also allow guests who are
            booked with you or under your name to provide a guest rating. This
            invitation may ask you to provide information regarding the Premises
            and other services booked by you via HRS.de. As the account holder,
            you can decide whether you wish to provide a personal or an
            anonymous rating. By posting a guest rating, you agree that the
            rating can be displayed for example on the information page of the
            Premises on our web pages, in our mobile apps, on social media
            channels and apps, or on the website of the respective Premises
            and/or the website of the business partner. This advises other
            travellers about the quality of your booked Premises or other
            services used by you.
          </Paragraph>
          <Paragraph>
            Whenever you indicate whether a specific guest rating is useful for
            you, we consider this information together with feedback of other
            users to sort and prioritize the guest rating. Under certain
            circumstances, we use information in your lists or other booking-
            and travel-related data you share with us anonymously as long as you
            have not stored this information in your profile. In this manner, we
            assist other travellers and customers who search for the right
            travel destination and Premises which best matches their needs.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Quality Management</SubTitle>
          <Paragraph>
            Whenever you call the HRS Customer Service, the phone call may be
            monitored or recorded for training purposes or quality management.
            Recorded calls are stored for a limited period of time and will be
            automatically deleted as long as HRS has no justified interest to
            keep such recording for a longer period of time (in its reasonable
            discretion), for example within the scope of the detection of
            fraudulent activities. All call records are processed pursuant to
            applicable data privacy rules.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Legal Purposes</SubTitle>
          <Paragraph>
            In certain cases, we may use your data for the handling and
            resolution of legal conflicts or use the same within the scope of
            investigations and to ensure conformity with the compliance
            guidelines. We may also use your data to enforce the terms of use of
            the online booking service of HRS in our reasonable discretion.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Title>
          Processing of your data as described is supported by the following
          legal basis
        </Title>
        <Container>
          <StyledTitle>Contractual Performance</StyledTitle>
          <Paragraph>
            The use of your data may be necessary to perform the contract into
            which you enter with us. For example, as long as you use our service
            to make an online booking, we will use your data to comply with our
            duty to complete and carry out the booking according to our mutual
            agreement.
          </Paragraph>
        </Container>
        <Container>
          <StyledTitle>Legitimate Interests</StyledTitle>
          <Paragraph>
            We may use your data for our legitimate business interests. This
            allows us to provide you with the most personalized content on the
            website & apps, in e-mails, and in newsletters or optimize and
            promote our products and services as well as the contents on our
            website; in addition, this may serve for administrative or legal
            purposes as well as the detection of fraudulent activities.
          </Paragraph>
        </Container>
        <Container>
          <StyledTitle>Consent</StyledTitle>
          <Paragraph>
            Under certain circumstances, your consent to use your personal data
            for certain direct marketing activities is presumed. You are
            entitled to withdraw your consent at any time by contacting us at
            the addresses at the bottom of this data privacy notice.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Title>How long does HRS keep your personal data on file?</Title>
        <Paragraph>
          We only keep your data as long as the data privacy-related purpose for
          retaining such data continues to exist. For example, for the
          performance of bookings, this means that your data are deleted after
          completion of the booking process and/or your stay – of course, only
          as long as these data are not subject to longer compulsory retention
          periods by law (for example, for tax purposes). If this is the case,
          we keep the data on file until the end of the corresponding retention
          periods.
        </Paragraph>
        <Paragraph>
          In all cases, information regarding your computer, which is necessary
          to ensure IT security (compare above), is also only kept on file as
          long as this is required for the above-mentioned purpose – meaning
          that, if data of use are only required for the respective session,
          they are automatically deleted as soon as you close your browser
          and/or leave our web pages.
        </Paragraph>
        <Paragraph>
          In case you granted your consent to any more comprehensive data
          processing (compare above), we will basically only store your data
          until you withdraw your consent. The e-mail address datenschutz@hrs.de
          may also be used for such purpose.
        </Paragraph>
      </Section>
      <Section>
        <Container>
          <Title>How does HRS share your data with third parties?</Title>
          <Paragraph>
            The HRS services integrate different third parties in different
            manners and for different reasons. The main purpose is to forward
            your booking data to the Premises booked by you to complete your
            booking. There are other parties which may receive a part of your
            data. They are parties, which assist HRS with different tasks,
            financial institutions, advertising companies, or authorities, in
            some cases. Your personal data will not be transferred to areas
            outside of the European Union and/or the European Economic Area.
            Click the link below to find out more about how this information
            that we receive from you is used and shared with these parties.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <SubTitle>The Premises booked by you</SubTitle>
          <Paragraph>
            This issue is indispensable for our work. To complete your booking,
            we forward the relevant booking data to the Premises booked by you.
            This may be information such as your name, your contact data, your
            payment information, your special requests regarding the booking, as
            well as the name of guests who are booked with you. In case you have
            any question regarding your booking, we may contact the operator of
            your Premises and ask him or her to address this question. In case
            you provide no payment during the booking process via the HRS web
            pages and apps, we will forward your credit card details to the
            booked Premises for further processing (as long as you have provided
            us with these data during the booking process). In the case of
            booking-related disputes, we may provide the operator of your
            Premises with data regarding the booking process, as required. This
            may contain a copy of your booking confirmation, which serves as a
            receipt for actual completion of the booking.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Third-Party Service Providers</SubTitle>
          <Paragraph>
            We may use service providers to process your personal data on our
            behalf. This may serve different purposes, such as, for example, the
            sending of promotional contents. All third-party service providers
            are bound by data processing agreements and are only authorized to
            process your personal data exclusively for the aforementioned
            purposes.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>
            Payment Providers and (Other) Financial Institutions
          </SubTitle>
          <Paragraph>
            In case a chargeback is requested for your booking either by
            yourself or by the holder of the credit card that was used for the
            booking, we may need to share certain booking details with the
            payment provider and the respective financial institution to process
            the chargeback. These details may include a copy of your booking
            confirmation or the IP address which was used for your booking. In
            addition, we may share information with the respective financial
            institutions as long as, in our opinion, this is indispensable to
            detect or prevent fraudulent activities.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Competent Authorities</SubTitle>
          <Paragraph>
            We disclose personal data to enforce the law as long as this is
            required by law or urgently necessary for the prevention, detection,
            or legal prosecution of criminal acts or fraud. In addition, we may
            share personal data with the competent authorities under certain
            circumstances to protect our rights, Premises or the rights and
            Premises of our business partners.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Business Partners</SubTitle>
          <Paragraph>
            We cooperate with business partners throughout the entire world.
            Some of these business partners have been retained to market and/or
            promote our services and assist our business partners during the
            marketing and promotion of their services. Business partners may
            also include the HRS Group of which HRS is a part. This may mean
            that business partners’ services are integrated in our website and
            apps, that they have the option to display an individual offer on
            our page, or that the online booking service of HRS is integrated on
            their website and/or apps.
          </Paragraph>
          <Paragraph>
            Whenever you make a booking via the web pages or apps of our
            business partners, certain personal data provided to them by you are
            forwarded to us. As long as the business partner provides customer
            service, HRS will share booking-relevant data with the business
            partners (as needed) to offer you the best possible and most
            appropriate service. Whenever you make a booking via the web pages
            of our business partners, the business partners may receive certain
            elements of your personal data, which are relevant for the
            respective booking. This serves their internal purposes (including
            analytic purposes) and, whenever you request this, the
            implementation of marketing actions.
          </Paragraph>
          <Paragraph>
            Whenever you make a booking on the website of a business partner,
            please familiarize yourself with the data privacy rules of such
            business partner to find out how business partners may process your
            personal data. Whenever we offer you other products and/or services,
            your data will be shared with the business partners offering such
            products and/or services under certain circumstances to process or
            carry out your order. To detect and prevent fraud, we may share
            information regarding our users with business partners, as long as
            this is indispensable for such purpose.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>How does HRS use mobile devices?</Title>
          <Paragraph>
            We offer free apps through which we also collect and process
            personal data. Such collection and processing is similar to the
            manner in which personal data are collected and processed on our web
            pages, and these apps also make it possible to determine your
            location.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            We offer free apps for different mobile devices and versions of our
            normal web pages adapted for mobile devices. These apps and mobile
            web pages collect and process your personal data in a manner which
            is very similar to our web pages. They furthermore make it possible
            to determine your location to find nearby Premises. With your
            consent, we will send you push messages with information regarding
            your booking. You may grant us access to your location data to allow
            us to provide the desired services to you. Please read the
            instructions of your mobile device to find out how settings can be
            modified and how the transfer of data as well as the receipt of push
            messages can be activated.
          </Paragraph>
          <Paragraph>
            HRS uses cross-device tracking to optimize its services and
            marketing activities. This may or may not involve the use of
            cookies. To obtain more information about cookies and comparable
            technical elements, please read our rules regarding cookies below.
            Using cross-device tracking allows HRS to track usage on several
            devices and permits combining data of a specific browser or mobile
            device with another computer or device which is connected with the
            computer or device from which the data are queried.
          </Paragraph>
          <Paragraph>
            To optimize the contents of the HRS newsletter, HRS collects
            information regarding searches and bookings which are carried out
            from different computers and devices while, in your user account,
            you are logged in on each and every one of these computers or
            devices. If you do not want HRS to use such data for the preparation
            of newsletters or optimization of the contents of our web pages
            and/or apps, you can log off from your device or computer or
            unsubscribe from the HRS newsletter.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>How does HRS use social media?</Title>
          <Paragraph>
            The use of social media has been integrated in the services of HRS
            in different ways as a result of which we participate in the
            collection of your personal data, or the social media provider
            directly receives some of your information. To obtain more
            information on how this information is used and exchanged, please
            continue reading.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            Our website uses social media plugins ("plugins") of different
            social networks. Use of such plugins makes it possible, for example,
            to share contents or recommend products to others. Once these
            plugins are activated, your browser establishes a direct connection
            with the servers of the respective social network as soon as you
            access a web page of our website. The content of the plugin is
            directly transferred to your browser by the social network and
            integrated by the same on the website.
          </Paragraph>
          <Paragraph>
            Through integration of the plugin, the social network receives
            information that you accessed the corresponding page of our website.
            As long as you are logged in at the social network, it can associate
            the visit with your account. Once you interact with the plugins, for
            example by pressing the Facebook "Like" button or providing a
            comment, the corresponding information is transmitted directly by
            your browser to the social network, where it is stored.
          </Paragraph>
          <Paragraph>
            Regarding the purpose and scope of data collection and further
            processing and use of the data by social networks as well as your
            rights in this respect and the setting options to protect your
            privacy, please consult the data privacy notices of the respective
            networks and/or web pages. You will also find the respective links
            below.
          </Paragraph>
          <Paragraph>
            Even if you are not logged into the social networks, web pages with
            active social plugins can send data to the networks. Whenever a
            website is accessed, an active plugin creates a cookie with an
            identifier. Considering that your browser automatically sends the
            cookie during any connection with a network server, the network
            might use it to basically create a profile based on the web pages
            which were accessed by the user associated with the identifier.
            Then, it would also be quite possible to later once again associate
            this identifier – during subsequent login at the social network -
            with a specific person.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>
            On our web pages and/or apps, we use the following plugins:
            Facebook, Twitter, Google+ & Youtube
          </Title>
          <Paragraph>
            If you do not want social networks to use active plugins to collect
            data about you, you can either simply select the social plugins by
            clicking the "Block Third-Party Cookies" function in your browser
            settings. Then, in the case of embedded contents of other providers,
            the browser will not send cookies to the server. With this setting,
            however, except for the plugins, other functions across multiple
            pages may also no longer work under certain circumstances.
          </Paragraph>
        </Container>
        <StyledContainer>
          <SubTitle>Facebook</SubTitle>
          <Paragraph>
            We use plugins of the social network facebook.com, which is operated
            by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA
            ("Facebook"). The link to the data privacy notice of Facebook can be
            found here: Data Privacy Notices Facebook.
          </Paragraph>
        </StyledContainer>
        <StyledContainer>
          <SubTitle>Twitter</SubTitle>
          <Paragraph>
            We use plugins of the social network Twitter, which is operated by
            Twitter Inc., 795 Folsom Str., Suite 600, San Francisco, CA 94107,
            USA ("Twitter"). The link to the data privacy notice of Twitter can
            be found here: Data Privacy Notices Twitter.
          </Paragraph>
        </StyledContainer>
        <StyledContainer>
          <SubTitle>Google+ & Youtube</SubTitle>
          <Paragraph>
            We use plugins of the social networks Google+ & Youtube, which are
            operated by Google Inc., 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA ("Google"). The link to the data privacy notice of
            Google can be found here:{' '}
            <StyledExternalLink href={`${POLICIES_GOOGLE_URL}`} target='_blank'>
              Data Privacy Notices Google
            </StyledExternalLink>
            .
          </Paragraph>
        </StyledContainer>
      </Section>
      <Section>
        <Container>
          <Title>
            What security measures does HRS take to protect your personal data?
          </Title>
          <Paragraph>
            According to the European data protection laws, we engage in
            reasonable measures to ensure protection of your personal data
            against abuse and unauthorized access. Your credit card data are not
            stored completely, and are only stored in encrypted form and for no
            longer than 90 days.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            According to the European data protection laws, we implement
            reasonable procedures to ensure the protection of your personal data
            against abuse and unauthorized access.
          </Paragraph>
          <Paragraph>
            To protect your personal data, we use the respective business
            systems and processes. Additionally, we use security procedures as
            well as technical and access restrictions whenever our servers
            access and use your personal data. Only authorized employees have
            access to your personal data for proper performance of their tasks.
          </Paragraph>
          <Paragraph>
            Whenever your credit card details must be transmitted within the
            scope of a booking, they are stored for a maximum of 90 days after
            completion of your booking. At the end of this period, they are
            either (completely or partially) deleted in an irrevocable manner or
            stored in our system for fraud detection subject to restricted
            access. You may also store you credit card details in your user
            account. Whenever you decide to do so, credit card details are
            stored as a hash code, except for the last four digits of your
            credit card number. That way, you know which card you are currently
            using for payment.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>How does HRS handle personal data of children?</Title>
          <Paragraph>
            The use of HRS’ service is permitted for individuals of at least 18
            years of age. We exclusively process information about children
            based on the consent of their parents or legal representatives.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            The services of HRS are not intended for children less than 18 years
            of age. Our services may only be used with the valid consent of one
            parent or a legal representative. In the event that data of children
            less than 18 years of age are transmitted to us, we reserve the
            right to delete such data. In certain cases in connection with a
            booking or the purchase of other services or under exceptional
            circumstances (e.g. functions intended for families), HRS is
            entitled to collect and use data of children exclusively with the
            consent of the parent or legal representative.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>
            Which rights do you have regarding personal data transmitted to HRS
            by you?
          </Title>
          <Paragraph>
            You are at all times entitled to request information regarding the
            personal data which we have collected about you. You may request a
            summary of your personal data by sending such a request to the
            website https://gdpr.hrs.com/HRS. Please fill in any and all
            information requested in the form so that we can process your
            request.
          </Paragraph>
          <Paragraph>
            Please note that – in case you do use contact forms – we collect,
            handle and store the personal information that you provide via such
            contact form for the purpose of processing your request.
          </Paragraph>
          <Paragraph>
            Under special circumstances, e.g. in the case of a dispute regarding
            the legality of processing, you have an additional right to request
            the restricted processing of personal data. Furthermore, you are
            entitled to request the access to your personal data in a
            structured, common, and machine-readable format. Furthermore, under
            certain circumstances, you may be entitled at any time to object to
            any data processing on the basis of Article 6, Paragraph 1, letters
            e, f as well as for purposes of direct advertising. As long as you
            have given us your consent for a more comprehensive processing
            (compare above), you may withdraw this consent at any time – e.g. by
            sending a message to the above-specified e-mail address.
          </Paragraph>
          <Paragraph>
            Finally, you are entitled to file a complaint with the competent
            data protection authority in case of any disagreements.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            You are at all times entitled to view your personal data which are
            stored by us. To receive an overview of your personal data, please
            request a summary of your personal data by sending such a request to
            the website{' '}
            <StyledExternalLink href={`${HRS_GDPR_URL}`} target='_blank'>
              {HRS_GDPR_URL}
            </StyledExternalLink>
            . Please fill in any and all information requested in the form so
            that we can process your request.
          </Paragraph>
          <Paragraph>
            You may also contact us if you believe that your personal data
            stored by us are incorrect or as long as you believe that we are no
            longer entitled to have your personal information under the present
            data privacy rules. Please request a summary of your personal data
            by sending such a request to the website{' '}
            <StyledExternalLink href={`${HRS_GDPR_URL}`} target='_blank'>
              {HRS_GDPR_URL}
            </StyledExternalLink>
            . Please fill in any and all information requested in the form so
            that we can process your request. We will process your request
            according to the applicable European Data Protection laws.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>Rules Regarding Cookies</Title>
          <Paragraph>
            Cookies and other tracking technologies may be used on our web pages
            and in our apps in many different ways. For example, they ensure
            that the HRS web pages are operational, they analyze traffic, or
            they are used for advertising purposes. These technologies are
            either used directly by us or by our business partners, including
            third-party service providers and advertisers who work with us. For
            more information regarding cookies, their use, and your options in
            this respect, please read our cookie policy. You will find the
            cookie policy in the footer navigation at the bottom of our website.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>Google Maps</Title>
          <Paragraph>
            We include a map into our website for the purpose of visualisation
            provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA via the Google Maps API. If you have the map
            displayed, a connection to the Google Servers is being made under
            which the IP-Adress is transferred to Google. Google can analyse the
            use of the Google Maps functionality by the visitor. In addition,
            Google can write and read Cookies. These Cookies can be Google User
            Cookies which are directly connected to your identity. We are using
            these on the basis of our legitimate interest according to Art. 6
            Abs. 1 lit. GDPR.
          </Paragraph>
          <Paragraph>
            Further information can be found in the Google privacy documents
            which can be found here:{' '}
            <StyledExternalLink href={`${HRS_GDPR_URL}`} target='_blank'>
              {HRS_GDPR_URL}
            </StyledExternalLink>
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>Logfiles</Title>
          <Paragraph>
            Whenever you access the HRS web pages or apps, user data are
            transmitted by the respective Internet browser and stored in log
            files, the so-called server log files. The datasets which are stored
            in the process contain the following data: date and time of the
            visit, name of the page visited, IP address, referrer URL (original
            URL from which you accessed the website), data volume transmitted,
            as well as product and information on the browser version used.
          </Paragraph>
          <Paragraph>
            The IP addresses of users are deleted or anonymized after completion
            of use. In the case of anonymization, IP addresses are modified in
            such a manner that individual information regarding personal or
            factual circumstances can no longer be associated with a specific
            natural person or a natural person which can be determined or where
            such an association would require an unreasonable amount of time,
            costs, and effort.
          </Paragraph>
          <Paragraph>
            We analyze these logfile data sets in an anonymized manner to
            further improve our offering and the web pages and apps, and make
            them more user-friendly, to find and correct errors more quickly, as
            well as manage server capacities. For example, we may determine at
            what time use of the HRS web pages and apps is particularly popular
            and provide a data volume to ensure the fastest possible booking
            process for you. In addition, by analyzing the log files, we can
            also speed up the detection and correction of any errors of the web
            pages or apps.
          </Paragraph>
        </Container>
      </Section>
      <Title>Report a vulnerability or security incident</Title>
      <Paragraph>
        You are a security researcher or white hat hacker and you are already
        discovering a security vulnerability (for example, in the case of
        products, services, websites and other infrastructure components) that
        you would like to have closed by the affected company at short notice?
      </Paragraph>
      <Paragraph>
        Or you have evidence of a security incident and would like to contact
        the responsible security department?
      </Paragraph>
      <Paragraph>
        Should the company concerned be the HRS Group (incl. our HRS-Portals,
        HRS Holidays, hotel.de and Tiscover) then please contact us by e-mail:{' '}
        <StyledExternalLink
          href={`mailto:${PRODUCT_SECURITY_EMAIL}`}
          target='_blank'
          rel='noreferrer noopener'
        >
          {PRODUCT_SECURITY_EMAIL}
        </StyledExternalLink>
      </Paragraph>
      <Paragraph>
        We take reported vulnerabilities and security incidents very seriously.
        Your information helps our security professionals in particular to
        continue their work to ensure a high level of security for the HRS
        Group.
      </Paragraph>
    </Content>
  );
};

export default TextContent;

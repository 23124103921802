import React, {memo, useCallback} from 'react';
import styled from 'styled-components';
import {AxiosResponse} from 'axios';
import {Field, useForm, useFormState} from 'react-final-form';

import LoginLink from 'view/auth/LoginCustomer/LoginLink';

import {ErrorMessage, Input} from 'view/components/NW2FormItem/components';
import {
  NW2FormItemInput,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import api from 'infra/common/http.service';
import venueRepository from 'infra/repository/VenueRepository';
import {asyncDebounce} from 'utils/asyncDebounce';
import {
  emailFieldRules,
  firstNameFieldRules,
  lastNameFieldRules,
  legalPhoneFieldRules,
} from 'utils/finalFormFieldRules';

import {
  offsetXLg,
  errorColor,
  fontSizeXSm,
  fontWeightBold,
} from 'constants/styleVars';
import {ECustomerDetailsFormFields} from './types';

export const FormGroup = styled.div<{columnNumber: number; gap: number}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => gap}px;

  > div {
    margin: 0;
  }
`;

const StyledDiv = styled.div`
  color: ${errorColor};
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
`;

interface IProps {
  disabledItems?: ECustomerDetailsFormFields[];
  adaptiveColumnNumber?: number;
  gap?: number;
}

const checkEmail = async (values: string): Promise<AxiosResponse> =>
  await venueRepository(api).getIsEmailAvailable(values);
const debouncedSearch = asyncDebounce(
  async (value: string) => await checkEmail(value),
  300,
);

const CUSTOMER_EXISTS_EMAIL_ERROR = 'Email already exists';

export const NW2CustomerDetailsForm = memo(
  ({
    disabledItems,
    adaptiveColumnNumber = 1,
    gap = parseInt(offsetXLg),
  }: IProps) => {
    const form = useForm();
    const state = useFormState();

    const validateEmail = useCallback(
      async (value: any, _: any, meta: any) => {
        const errors = emailFieldRules(value, _, meta);

        if (
          state.active !== ECustomerDetailsFormFields.EMAIL &&
          typeof state.errors === 'object' &&
          state.errors[ECustomerDetailsFormFields.EMAIL]
        ) {
          return state.errors[ECustomerDetailsFormFields.EMAIL];
        }

        if (
          !errors &&
          !meta.pristine &&
          state.active === ECustomerDetailsFormFields.EMAIL
        ) {
          /**
           * Pause the validation while the Final Form field is registered to prevent a form state desynchronization bug
           * when using async validators.
           * Since the field is not registered directly because of the introspection, Final Form is using the previous
           * form state (without the field) when notifying after the async validation done during the registration.
           * See also https://github.com/final-form/react-final-form/issues/780.
           */
          form.pauseValidation();

          const isAlreadyExists = !(await debouncedSearch(value));

          form.resumeValidation();

          return isAlreadyExists ? CUSTOMER_EXISTS_EMAIL_ERROR : errors;
        }

        return errors;
      },
      [form, state.active, state.errors],
    );

    return (
      <FormGroup columnNumber={1} gap={gap}>
        <FormGroup columnNumber={adaptiveColumnNumber} gap={gap}>
          <NW2FormItemInput
            type='text'
            name={ECustomerDetailsFormFields.FIRST_NAME}
            placeholder='First name*'
            label='First name*'
            rules={firstNameFieldRules}
            disabled={disabledItems?.includes(
              ECustomerDetailsFormFields.FIRST_NAME,
            )}
          />
          <NW2FormItemInput
            type='text'
            name={ECustomerDetailsFormFields.LAST_NAME}
            placeholder='Last name*'
            label='Last name*'
            rules={lastNameFieldRules}
            disabled={disabledItems?.includes(
              ECustomerDetailsFormFields.LAST_NAME,
            )}
          />
        </FormGroup>
        <Field name={ECustomerDetailsFormFields.EMAIL} validate={validateEmail}>
          {({input, meta}) => {
            const isCustomerAlreadyExists =
              meta.error === CUSTOMER_EXISTS_EMAIL_ERROR;
            const hasError = meta.error && meta.touched;
            const disabled = disabledItems?.includes(
              ECustomerDetailsFormFields.EMAIL,
            );

            return (
              <div>
                <Input
                  name={ECustomerDetailsFormFields.EMAIL}
                  type='email'
                  label='Email address*'
                  placeholder='Email address*'
                  hasError={hasError}
                  value={input.value}
                  onChange={input.onChange}
                  disabled={disabled}
                  inputProps={{...input, disabled}}
                />
                {!isCustomerAlreadyExists ? (
                  <ErrorMessage
                    hasError={hasError}
                    errorData={meta.error}
                    showAllValidationErrors={false}
                  />
                ) : (
                  <StyledDiv>
                    This email is already in use. Please try another email
                    address, or login if you already have an account.&nbsp;
                    <LoginLink content='Log in' color={{error: true}} />
                  </StyledDiv>
                )}
              </div>
            );
          }}
        </Field>
        <NW2FormItemPhoneNumber
          type='text'
          name={ECustomerDetailsFormFields.PHONE}
          placeholder='Phone number*'
          label='Phone number*'
          rules={legalPhoneFieldRules}
          disabled={disabledItems?.includes(ECustomerDetailsFormFields.PHONE)}
          isCountrySelect
        />
      </FormGroup>
    );
  },
);

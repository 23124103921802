import React from 'react';

import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {getExtraNumericInputVisibility} from 'utils/helpers';
import {
  Extras,
  ExtrasContainer,
  ExtrasName,
  MissingSpan,
  PricePerRoom,
  StyledSpan,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';

import {IOfferRequestReviewUnitExtra} from 'view/venue/Offer/components/OfferRequestReviewUnitCard/types';
import {EResourcesType} from 'types/dto/IExtras.type';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';

export const RequestUnitExtras = ({
  data,
  isOffer,
  currency,
  isBedroom,
  isRequestWithUnitId,
}: {
  data: IOfferRequestReviewUnitExtra[];
  isOffer: boolean | undefined;
  currency?: string;
  isBedroom?: boolean;
  isRequestWithUnitId?: boolean;
}) => (
  <>
    {data.map((extras: IOfferRequestReviewUnitExtra) => {
      const {id, quantity, name, type, code, totalPrice} = extras;
      const isQuantityVisible = getExtraNumericInputVisibility(name);
      const price = totalPrice?.value || 0;

      const pricePerRoom = price / quantity;
      const isPricePerRoom = isOffer && isBedroom && pricePerRoom !== 0;

      const isBedroomMissing = isRequestWithUnitId && isBedroom;

      return (
        <ExtrasContainer isPricePerRoom={isPricePerRoom} key={id}>
          <Extras
            isQuantityVisible={isQuantityVisible}
            isRequest={!isOffer}
            isRequestWithUnitId={isRequestWithUnitId}
            isBedroomMissing={isBedroomMissing}
            isPricePerRoom={isPricePerRoom}
          >
            {isQuantityVisible && (
              <>
                <span>{quantity}</span>
                <span>x</span>
              </>
            )}
            <ExtrasName isQuantityVisible={isQuantityVisible}>
              {type === EResourcesType.BEDROOM
                ? CODE_TO_EXTRA_NAME[code]
                : name}
            </ExtrasName>
            {(isOffer || isRequestWithUnitId) && (
              <StyledSpan>
                {price !== 0 ? (
                  <PriceDisplay price={price} currency={currency} />
                ) : !isBedroom && totalPrice?.value === 0 ? (
                  'Free'
                ) : (
                  <MissingSpan>Missing</MissingSpan>
                )}
              </StyledSpan>
            )}
          </Extras>
          {isPricePerRoom && (
            <PricePerRoom>
              <PriceDisplay price={price} currency={currency} /> / room
            </PricePerRoom>
          )}
        </ExtrasContainer>
      );
    })}
  </>
);

import styled from 'styled-components';

import {
  fontWeightBold,
  fontWeightExtraBold,
  NW2Gray600Color,
  NW2Primary,
  offsetDef,
  offsetXSm,
  offsetXXLg,
  whiteColor,
} from 'constants/styleVars';

export const Wrapper = styled.div`
  display: flex;
  gap: ${offsetXXLg};
  border-bottom: 2px solid ${NW2Gray600Color};
  margin-bottom: ${offsetDef};
`;

export const Container = styled.div`
  display: flex;

  &:last-of-type {
    margin-right: 0;
  }

  input {
    display: none;
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${whiteColor};
    text-transform: uppercase;
    font-weight: ${fontWeightBold};
    padding-bottom: ${offsetXSm};
    transition: color 0.15s, border-bottom 0.15s;

    &:after {
      content: '';
      height: 5px;
      width: 100%;
      position: absolute;
      bottom: -2px;
      left: 0;
      background-color: transparent;
      transition: background-color 0.15s;
    }
  }

  input:checked + label {
    color: ${NW2Primary};
    transition: color 0.15s, border-bottom 0.15s;
  }

  input:checked + label:after {
    background-color: #1890ff;
    transition: background-color 0.15s;
  }

  input + label svg path.styled-stroke,
  input + label svg circle.styled-stroke {
    transition: stroke 0.15s;
    stroke: ${whiteColor};
  }

  input:checked + label svg path.styled-stroke,
  input:checked + label svg circle.styled-stroke {
    transition: stroke 0.15s;
    stroke: ${NW2Primary};
  }
`;

export const StyledSpan = styled.span<{isActive?: boolean}>`
  font-weight: ${({isActive}) =>
    isActive ? fontWeightExtraBold : fontWeightBold};
  font-size: 14px;
  color: #262626;
  line-height: 22px;
`;

import React from 'react';

import {ERoomSchemaNames} from 'types/venue';
import {Wrapper} from './RoomSetupView.styles';
import {RoomSetupViewItem} from './RoomSetupViewItem';

interface IProps {
  columns: number;
  unitCapacities?: {capacity: number; setupStyle: ERoomSchemaNames}[];
}

export const RoomSetupView = ({unitCapacities, columns}: IProps) => {
  const sortingArr = Object.values(ERoomSchemaNames);
  const sortedUnits = unitCapacities?.sort(
    (a, b) =>
      sortingArr.indexOf(a.setupStyle) - sortingArr.indexOf(b.setupStyle),
  );

  return (
    <Wrapper columns={columns}>
      {sortedUnits?.map(({capacity, setupStyle}) => {
        return (
          <RoomSetupViewItem
            key={setupStyle}
            capacity={capacity}
            setupStyle={setupStyle}
          />
        );
      })}
    </Wrapper>
  );
};

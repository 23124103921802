import React, {FC} from 'react';
import styled from 'styled-components';

import Icon from '../Icon/Icon';

import {BlockTitle} from '../Typography';
import {offsetDef} from '../../../constants/styleVars';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${offsetDef};
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin-bottom: 0;
`;

type Props = {
  text: string;
  icon?: string;
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  warn?: boolean;
};

const ConfirmationTitle: FC<Props> = ({icon, text, ...restProps}) => {
  return (
    <Container>
      {icon ? <StyledIcon icon={icon} {...restProps} /> : null}
      <StyledBlockTitle>{text}</StyledBlockTitle>
    </Container>
  );
};

export default ConfirmationTitle;

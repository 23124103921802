import React, {FC} from 'react';
import {Button, Form, Modal} from 'antd';

import {useAppDispatch} from 'store/hooks';
import useToggle from 'hooks/useToggle';
import AdminAddCompanyUser from 'view/common/MultiModal/components/AdminAddCompanyUser';
import {IAddCorporateAccountPropertyManagerPayload} from 'store/admin/types';
import {
  addCorporateAccountPropertyManager,
  reimportCorporateAccountCompany,
} from 'store/admin/apiActions';
import {
  COMPANY_USER_FIRST_NAME,
  COMPANY_USER_LAST_NAME,
  COMPANY_USER_EMAIL,
  COMPANY_USER_PHONE,
} from 'constants/admin';
import {ButtonContainer} from 'view/admin/common.styles';

const AddUserButton: FC<{companyId: number}> = ({companyId}) => {
  const [isVisible, setIsVisible] = useToggle();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const addUser = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const bodyData: IAddCorporateAccountPropertyManagerPayload = {
        firstName: values[COMPANY_USER_FIRST_NAME],
        lastName: values[COMPANY_USER_LAST_NAME],
        email: values[COMPANY_USER_EMAIL],
        phoneNumber: values[COMPANY_USER_PHONE],
        accountId: companyId, // company id
      };
      dispatch(addCorporateAccountPropertyManager(bodyData));
      setIsVisible();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onReimportClick = () => {
    dispatch(reimportCorporateAccountCompany(companyId));
  };

  const showAddUserPopup = () => {
    setIsVisible();
  };

  const modalProps = {
    title: 'Add Property Manager',
    okText: 'Add',
    cancelText: 'Cancel',
    visible: isVisible,
    onOk: addUser,
    onCancel: setIsVisible,
    closable: true,
    destroyOnClose: false,
    forceRender: true,
  };

  return (
    <>
      <ButtonContainer>
        <Button type='primary' onClick={onReimportClick}>
          Re-Import
        </Button>
        <Button type='primary' onClick={showAddUserPopup}>
          Add Property Manager
        </Button>
      </ButtonContainer>
      <Modal {...modalProps}>
        <AdminAddCompanyUser form={form} />
      </Modal>
    </>
  );
};

export default AddUserButton;

import _get from 'lodash/get';

import {useAppSelector} from 'store/hooks';
import {
  COUNTRY_CANADA,
  COUNTRY_USA,
  PATH_TO_REDUCER_VENUE_DATA,
} from 'constants/venue';

export const useIsVenueFromUSA = (countryArg?: string) => {
  const country = useAppSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.country`),
  );
  const isUSA = [COUNTRY_USA, COUNTRY_CANADA].includes(country || countryArg);

  return [isUSA];
};

import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';

import {
  EBookableWith,
  EPriceType,
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {TExtraPriceField} from '../types';
import venueRepository from 'infra/repository/VenueRepository';
import api from 'infra/common/http.service';
import {addExtraItemSuccess, updateExtraSuccess} from 'store/venue/actions';
import {FREE, PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {parseStringToNumber} from 'utils/stringUtils';

interface IProps {
  venueId: number;
}
export function useUpdateExtras({venueId}: IProps) {
  const dispatch = useDispatch();

  const venueExtras: IExtrasResponse[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.extras`),
  );
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const onUpdateExtras = useCallback(
    async (formValues: TExtraPriceField) => {
      const filteredVenueExtras = venueExtras.filter(
        ({extraType}) => extraType !== EResourcesType.BEDROOM,
      );

      const extrasData = Object.entries(Object.assign({}, formValues)).map(
        ([itemName, itemPrice]) => {
          const extraItem = extrasOption.find(({name}) => name === itemName);
          const isFree = itemPrice === FREE;

          const venueExtra = filteredVenueExtras.find(
            ({accommodationExtraId}) => accommodationExtraId === extraItem?.id,
          );

          const bookableWith = [EBookableWith.MEETING_ROOM];

          if (venueExtra) {
            if (venueExtra.bookableWith.includes(EBookableWith.WORK_SPACE)) {
              bookableWith.push(EBookableWith.WORK_SPACE);
            }
          }

          return {
            extraId: extraItem?.id as number,
            bookableWith,
            priceType: isFree
              ? EPriceType.FREE
              : extraItem?.type === EResourcesType.EQUIPMENT
              ? EPriceType.PER_UNIT
              : EPriceType.PER_PERSON,
            price: isFree ? 0 : parseStringToNumber(itemPrice),
            isEnabled: true,
          };
        },
      );

      // space request can be without extras
      if (!extrasData.length) return;

      const response = await venueRepository(api).addExtrasList({
        accommodationId: venueId,
        data: extrasData,
      });

      response.forEach((item) => {
        if (
          filteredVenueExtras.find(
            ({accommodationExtraId}) =>
              accommodationExtraId === item.accommodationExtraId,
          )
        ) {
          dispatch(updateExtraSuccess(item));
        } else {
          dispatch(addExtraItemSuccess(item));
        }
      });
    },
    [dispatch, extrasOption, venueExtras, venueId],
  );

  return [onUpdateExtras];
}

import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import TaxDisclaimer from 'view/common/TaxDisclaimer/TaxDisclaimer';
import DateUtils from 'utils/dateUtils';
import PrePostAccommodationBlock from '../OfferRequestAccommodationBlock/PrePostAccommodationBlock';

import {Status} from 'view/supplier/Bookings/BookingDetails/components/Status/Status';
import {useAppSelector} from 'store/hooks';
import {useIsVenueFromUSA} from 'view/venue/hooks/useIsUSA';
import {useRequestSections} from 'view/venue/Offer/hooks/useRequestSections';
import {
  checkExpirationForStatuses,
  getRequestDetailsType,
  getDayItems,
} from '../../helpers';
import {OfferRequestDetailsUnits} from './OfferRequestDetailsUnits';
import {useOpenTermsAndConditionsModal} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {useRequestBedrooms} from '../../hooks/useRequestBedrooms';

import {
  Container,
  MainTitle,
  Wrapper,
  DaysBlock,
  Section,
  SubTitle,
  StyledTextes,
  TotalSection,
  TotalTitle,
  FeesText,
  TotalPrice,
  DayTitle,
  StyledText,
  Link,
  OptionDateBlock,
} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {
  EOfferStatus,
  ERequestDetailsSections,
  ERequestStatus,
  IOfferDay,
} from 'types/offer';
import {FULL_FREE_OF_CHARGE, MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {offsetDef} from 'constants/styleVars';
import {EEventType} from 'types/venue';
import SupplierTermsAndPolicyBlock from 'view/common/TermsAndPolicyBlock';

type TProps = {
  totalPriceAndCurrency: string | ReactNode;
  holdUp: string | null;
  venueTimeZone: string;
  isOffer: boolean;
  currency?: string;
  isRequestWithBedroom: boolean;
};

const RequestDetailsInfo = ({
  currency,
  holdUp,
  venueTimeZone,
  totalPriceAndCurrency,
  isOffer,
  isRequestWithBedroom,
}: TProps) => {
  const requestDetails = useAppSelector(({offers}) => offers.offerDetails);
  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );
  const venueZone: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.timeZone`),
  );
  const locale = useAppSelector(({app}) => app.locale);

  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();
  const [isUSA] = useIsVenueFromUSA();

  const {
    number,
    resolvedAt,
    days,
    declinedReason,
    status,
    declinedMessage,
    resolverName,
    creatorName,
    sentAt,
    expirationDate,
    optionDate,
    cancellationPolicy,
    termsAndConditionsId,
  } = requestDetails;

  const filteredMeetingDays = getFilteredUnitsByEventType(days);

  const filteredPostEvents = getFilteredUnitsByEventType(
    days,
    EEventType.POST_EVENT,
  );

  const filteredPreArrivals = getFilteredUnitsByEventType(
    days,
    EEventType.PRE_ARRIVAL,
  );

  const items = getDayItems(days);

  const isSingle = days.length === 1;

  const {preArrivals, postEvents} = useRequestBedrooms(true);

  const requestDetailsType = getRequestDetailsType(isSingle, status);

  const sections = useRequestSections({
    requestDetailsType,
    requestedDays: filteredMeetingDays,
    preArrivals: filteredPreArrivals,
    postEvents: filteredPostEvents,
  }).filter(Boolean);

  // Need for case when there is request with all data(with unitIds)
  // and at the same time with accommodations(bedrooms), which hadn't have prices yet
  const isRequestWithUnitId = !isOffer && items.some(({unitId}) => !!unitId);

  const commonPropsForPrePostBlock = {
    extrasOption,
    isOffer,
    currency,
    isRequestWithUnitId,
    titleMargin: `0 0 ${offsetDef}`,
  };
  const cancellationPolicyProps = isOffer
    ? {offerNumber: number}
    : {requestNumber: number};

  return (
    <Container>
      <div>
        {status !== ERequestStatus.REQUEST_PENDING && (
          <Section id={ERequestDetailsSections.STATUS}>
            <Status
              bookingStatus={status}
              currency={currency}
              freeOfCharge={FULL_FREE_OF_CHARGE}
              venueTimeZone={venueTimeZone}
              totalPriceAndCurrency={totalPriceAndCurrency}
              declinedReason={declinedReason}
              declinedMessage={declinedMessage}
              declinedBy={resolverName}
              creatorName={creatorName}
              holdUp={holdUp}
              sentAt={sentAt}
              resolvedAt={resolvedAt}
              expirationDate={expirationDate}
              isRequestWithBedroom={isRequestWithBedroom}
            />
          </Section>
        )}

        <MainTitle>
          {isOffer
            ? ERequestDetailsSections.OFFER_DETAILS
            : ERequestDetailsSections.REQUEST_DETAILS}
        </MainTitle>

        {status === ERequestStatus.REQUEST_PENDING && (
          <section
            key={ERequestDetailsSections.OPTION_DATE}
            id={ERequestDetailsSections.OPTION_DATE}
          >
            <Wrapper>
              <DaysBlock>
                <DayTitle>Option date</DayTitle>
              </DaysBlock>

              <OptionDateBlock>
                <StyledText>
                  The booker has requested{' '}
                  <b>
                    {DateUtils.getFormattedDayMonthTime({
                      date: optionDate,
                      venueZone,
                      locale,
                    })}
                  </b>{' '}
                  to be their option deadline.
                </StyledText>
                You will be notified as soon as the booker makes their decision.
              </OptionDateBlock>
            </Wrapper>
          </section>
        )}

        {!isSingle && (
          <section
            key={ERequestDetailsSections.PRE_ARRIVAL}
            id={ERequestDetailsSections.PRE_ARRIVAL}
          >
            <PrePostAccommodationBlock
              data={preArrivals}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        <OfferRequestDetailsUnits
          currency={currency}
          extrasOption={extrasOption}
          isSingle={isSingle}
          isOffer={isOffer}
          days={filteredMeetingDays as IOfferDay[]}
          isRequestWithUnitId={isRequestWithUnitId}
          isRequestWithBedroom={isRequestWithBedroom}
        />

        {!isSingle && (
          <section
            key={ERequestDetailsSections.POST_EVENT}
            id={ERequestDetailsSections.POST_EVENT}
          >
            <PrePostAccommodationBlock
              data={postEvents}
              {...commonPropsForPrePostBlock}
            />
          </section>
        )}

        {isOffer && (
          <TotalSection>
            <TotalTitle>Offer total</TotalTitle>
            <div>
              <TotalPrice>{totalPriceAndCurrency}</TotalPrice>
              <FeesText>
                <TaxDisclaimer isUSA={isUSA} />
              </FeesText>
            </div>
          </TotalSection>
        )}

        {![
          ERequestStatus.REQUEST_EXPIRED,
          EOfferStatus.OFFER_ACCEPTING_EXPIRED,
        ].includes(status) && (
          <>
            <Section id={ERequestDetailsSections.TERMS_AND_CONDITIONS}>
              <MainTitle>Terms & Conditions</MainTitle>
              {checkExpirationForStatuses(status) && (
                <>
                  <SubTitle>How long is the offer valid?</SubTitle>
                  <StyledTextes>
                    The offer is valid for the option period requested by the
                    booker defined above. Please note, that if the booker
                    confirms the offer within the stated period, it becomes
                    binding for both parties, you and the booker.
                  </StyledTextes>
                </>
              )}

              {[
                ERequestStatus.REQUEST_PENDING,
                EOfferStatus.OFFER_PENDING,
              ].includes(status) && (
                <SupplierTermsAndPolicyBlock
                  bookingStatus={status}
                  cancellationPolicy={cancellationPolicy}
                  termsAndConditionsId={termsAndConditionsId}
                  isUSA={isUSA}
                  cancellationPolicyProps={cancellationPolicyProps}
                />
              )}

              <SubTitle>How is the booker charged?</SubTitle>
              <StyledTextes>
                The booker will select the payment method upon confirming the
                offer. Once this process is finilized, you may utilise the
                provided billing address to generate an invoice.
              </StyledTextes>
              <SubTitle>What if there are additional charges?</SubTitle>
              <StyledTextes>
                Only extras which are not part of this booking can, and should
                be, charged on-site.
              </StyledTextes>
              <SubTitle>What’s HRS Connect commission?</SubTitle>
              <StyledTextes>
                HRS Connect applies commission on meeting and accommodation
                bookings. For full information refer to our{' '}
                <Link onClick={openTermsAndConditionsModal}>
                  Terms & Conditions
                </Link>
                .
              </StyledTextes>
              {status === ERequestStatus.REQUEST_PENDING &&
                !isRequestWithBedroom && (
                  <>
                    <SubTitle>How is potential worth calculate?</SubTitle>
                    <StyledTextes>
                      We have looked into the latest market trends within your
                      area and calculated what you should expect to earn from
                      creating an offer for this request. The real price
                      submitted with this offer, however, depends entirely on
                      you and your venue revenue requirements.
                    </StyledTextes>
                  </>
                )}
            </Section>
          </>
        )}
      </div>

      <AnchorMenu
        rootId={MODAL_TWO_COLS_RIGHT_COL_ID}
        sections={sections as string[]}
      />
    </Container>
  );
};

export default RequestDetailsInfo;

import React from 'react';
import {FormSpy} from 'react-final-form';
import styled from 'styled-components';

import {useAppSelector} from 'store/hooks';
import {AnnouncementPopupContent} from 'view/supplier/AnnouncementPopup/AnnouncementPopupContent';

import {
  borderRadiusLg,
  modalDefaultWidth,
  offsetDef,
  whiteColor,
} from 'styles/configs';

const PreviewWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
`;

const AnnouncementContainer = styled.div`
  background: ${whiteColor};
  border-radius: ${borderRadiusLg};
  padding: ${offsetDef};
  max-width: ${modalDefaultWidth};
  width: 100%;
`;

export function PreviewAnnouncementModal() {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  return (
    <FormSpy subscription={{values: true}}>
      {({values}) => (
        <PreviewWrapper>
          <AnnouncementContainer>
            <AnnouncementPopupContent
              title={values.title || 'This is a title.'}
              subTitle={values.subtitle}
              htmlString={
                values.htmlString ||
                '<p>This is the body text. You can fill it with all information you would like to share with your suppliers</p>'
              }
              handleOnSubmit={() => {}}
              isMobile={isMobile}
            />
          </AnnouncementContainer>
        </PreviewWrapper>
      )}
    </FormSpy>
  );
}

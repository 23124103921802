import React, {useCallback, useMemo, useState} from 'react';
import _orderBy from 'lodash/orderBy';
import {notification} from 'antd';

import {useAppDispatch} from 'store/hooks';
import {NW2ImageList} from 'view/venue/NW2VenueProfile/components/NW2Images/components';

import {TImageFile} from 'view/components/ImageUploaderComponent';
import {THandleUpdateImages} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {getSpaces, updateVenuePhotos} from 'store/venue/actions';
import {ISpacesDataItemImage} from 'types/venue';
import useVenueId from 'view/venue/hooks/useVenueId';

interface IProps {
  spaceId: number;
  images: Array<ISpacesDataItemImage | undefined>;
}
type TImageData = {
  venueId: number;
  images: (TImageFile | ISpacesDataItemImage | null | undefined)[];
  coverImage: string | undefined;
  idsToDelete: (string | undefined)[];
  entityName: string;
};
interface IHandelUpdate {
  imageData: TImageData;
  handleProgress?: ((v: number) => void) | undefined;
}

export function SpaceDetailsImages({spaceId, images}: IProps) {
  const dispatch = useAppDispatch();

  const {venueId} = useVenueId();

  const [imageToUpload, setImageToUpload] = useState<TImageFile | null>(null);

  const sortedImages = useMemo(
    () =>
      _orderBy(
        Array.from({length: images.length + 1}).map((item, idx) => images[idx]),
        ['name'],
        ['asc'],
      ),
    [images],
  );

  const coverImage = useMemo(
    () => images.find((img) => img?.isCover),
    [images],
  );

  const onImageUpload = useCallback((file: TImageFile) => {
    setImageToUpload(file);
  }, []);

  const handleUpdate = useCallback(
    ({imageData, handleProgress}: IHandelUpdate) => {
      dispatch(
        updateVenuePhotos({
          venueId,
          spaceId,
          imageData,
          handleProgress,
          onSuccessCallback: () => {
            dispatch(getSpaces(venueId));
          },
        }),
      );
    },
    [dispatch, spaceId, venueId],
  );

  const onUpdateVenueImages = useCallback<THandleUpdateImages>(
    ({handleProgress}) => {
      const imageData = {
        venueId: spaceId,
        images: [...images, imageToUpload],
        coverImage: coverImage?.uid,
        idsToDelete: [],
        entityName: 'unit',
      };

      handleUpdate({imageData, handleProgress});
    },
    [coverImage?.uid, handleUpdate, imageToUpload, images, spaceId],
  );

  const onImageRemove = useCallback(
    (file: TImageFile) => {
      if (images.length === 1) {
        notification.error({
          message: 'At least one image is mandatory',
        });
      } else {
        const coverImageId =
          file.uid === coverImage?.uid ? sortedImages[0]?.uid : coverImage?.uid;

        const imageData: TImageData = {
          venueId: spaceId,
          images,
          coverImage: coverImageId,
          idsToDelete: [file.uid],
          entityName: 'unit',
        };

        handleUpdate({imageData});
      }
    },
    [coverImage?.uid, handleUpdate, images, sortedImages, spaceId],
  );

  const onImageSetCover = useCallback(
    (file: TImageFile) => {
      const imageData = {
        venueId: spaceId,
        images,
        coverImage: file.uid,
        idsToDelete: [],
        entityName: 'unit',
      };

      handleUpdate({imageData});
    },
    [handleUpdate, images, spaceId],
  );

  return (
    <NW2ImageList
      images={sortedImages}
      onImageUpload={onImageUpload}
      onUpdateVenueImages={onUpdateVenueImages}
      onImageRemove={onImageRemove}
      onSetCoverImage={onImageSetCover}
      isRemoveDisabled={images.length === 1}
      hasCoverLabel
      isInline
    />
  );
}

export enum EUnauthenticatedAccountTextVariant {
  default = 'default',
  corporateUserIntro = 'corporateUserIntro',
  anotherCompanyUser = 'anotherCompanyUser',
}

export enum ERequestFormFields {
  DESTINATION = 'destination',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  COMPANY = 'company',
  COMMENT = 'comment',
}

export interface IRequestFormValues {
  destination: string;
  name: string;
  email: string;
  phone: string;
  company: string;
  comment: string;
}

import styled from 'styled-components';
import Icon from '../../Icon';

import {
  blackColor,
  fontSizeMd,
  lighterGrayColor,
  lineHeightSm,
  offset120,
  offsetDef,
  offsetLg,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  offsetXXXLg,
  offsetXXXSm,
  primaryColor,
  whiteColor,
} from 'constants/styleVars';

export const ToolbarItem = styled.button.attrs(() => ({
  type: 'button',
}))<{isActive?: boolean; isSmall?: boolean}>`
  border: 0;
  background: none;
  display: flex;
  border-radius: ${offsetSm};
  padding: ${offsetXSm};
  cursor: pointer;
  vertical-align: middle;
  margin-right: ${offsetXXXSm};
  align-items: center;
  ${({isActive}) => isActive && `background-color:${lighterGrayColor}`};
  ${({isSmall}) => isSmall && `padding: 0 ${offsetXXSm}`};

  &:hover {
    background-color: ${lighterGrayColor};
  }
`;
export const StyledIcon = styled(Icon)<{isMargin?: boolean}>`
  width: ${offsetLg};
  justify-content: center;
  ${({isMargin}) => isMargin && `margin:${offsetXSm}`};
`;
export const Dropdown = styled.div`
  z-index: 9999;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: ${offsetXSm};
  min-width: ${offset120};
  min-height: ${offsetXXXLg};
  background: ${whiteColor};
`;

export const DropdownItem = styled.button.attrs(() => ({
  type: 'button',
}))<{isActive?: boolean}>`
  background: ${whiteColor};
  padding: ${offsetXSm};
  color: ${blackColor};
  cursor: pointer;
  line-height: ${lineHeightSm};
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  border-radius: ${offsetXSm};
  border: 0;
  ${({isActive}) => isActive && `background-color:${lighterGrayColor}`};
  width: 200px;

  &:hover {
    background-color: ${lighterGrayColor};
  }
`;

export const Text = styled.span`
  display: flex;
  line-height: ${lineHeightSm};
  flex-grow: 1;
  margin-left: ${offsetXSm};
`;
export const LinkEditorContainer = styled.div`
  position: absolute;
  z-index: 999999;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: ${whiteColor};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: ${offsetXSm};
  transition: opacity 0.5s;
`;

const linkStyles = `
  width: calc(100% - ${offsetXLg});
  box-sizing: border-box;
  margin: ${offsetXSm} ${offsetSm};
  padding: ${offsetXSm} ${offsetSm};
  border-radius: ${offsetDef};
  background-color: #eee;
  font-size: ${fontSizeMd};
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
`;
export const LinkInput = styled.input`
  display: block;
  ${linkStyles}
`;

export const LinkWrapper = styled.div`
  display: flex;
  ${linkStyles}
`;
export const LinkEditButton = styled.div`
  width: ${offsetXXLg};
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.a`
  color: ${primaryColor};
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${offsetXXLg};
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  padding: ${offsetXXSm};
  border-top-left-radius: ${offsetSm};
  border-top-right-radius: ${offsetSm};
  vertical-align: middle;
`;

import styled, {css} from 'styled-components';

import {
  blackColor,
  datepickerZIndex,
  fontSizeMd,
  fontSizeXSm,
  fontWeightBold,
  mdBp,
  NW2Gray300Color,
  NW2Gray50Color,
  NW2Gray600Color,
  NW2Primary,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXLg,
  offsetXXXSm,
  textColor,
  whiteColor,
} from 'constants/styleVars';

export const StyledDrawerContent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
`;

export const StyledDrawerContentHeader = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: ${offsetDef} ${offsetDef} 0;
  background-color: ${whiteColor};

  input {
    cursor: default;

    & + label {
      color: ${blackColor};
    }
  }
`;

export const StyledDrawerContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${offsetDef};
  background-color: ${NW2Gray50Color};

  .react-datepicker {
    &__input-container {
      input {
        @media (max-height: 620px) and (max-width: ${mdBp - 1}px) {
          padding: ${offsetXSm} calc(${offsetXXLg} - 4px) ${offsetXSm}
            ${offsetSm};
          height: 40px;
        }
      }
    }
  }
`;

export const StyledInputContainer = styled.div<{highlighted?: boolean}>(
  ({highlighted}) => css`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;

    input {
      padding: ${offsetXXXSm} 0 0;
      border: none;
      outline: none;
      width: 100%;
      font-size: ${fontSizeMd};

      &::placeholder {
        color: ${NW2Gray300Color};
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus + label {
        color: ${textColor};
        transition: width 0.15s, background-color 0.15s;

        &:before {
          width: 100%;
          background-color: ${NW2Primary};
        }
      }

      & + label:before {
        content: '';
        position: absolute;
        height: 10px;
        width: ${highlighted ? '100%' : 0};
        top: 50%;
        left: 2px;
        z-index: 0;
        background-color: ${highlighted ? NW2Primary : 'transparent'};
        transition: background-color 0.15s, width 0.15s;
      }
    }

    label {
      text-transform: uppercase;
      color: ${NW2Gray600Color};
      font-weight: ${fontWeightBold};
      font-size: ${fontSizeXSm};
      position: relative;

      span {
        position: relative;
        z-index: ${datepickerZIndex + 1};
      }
    }
  `,
);

export const StyledActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';
import NW2Dropdown from 'view/components/NW2Dropdown';

import {
  offsetNone,
  offsetXXSm,
  offsetXSm,
  offsetSm,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  fontWeightNormal,
  fontWeightBold,
  fontSizeSm,
  fontSizeLg,
  lineHeightXL,
  lightBlackColor,
  fontSizeMd,
  xxlg,
} from 'constants/styleVars';

const CARD_MAX_WIDTH = '210px';
const DROPDOWN_LINK_WIDTH = '280px';
const CARD_CONTAINER_MIN_HEIGHT = '66px';

export const StyledDiv = styled.div`
  & div:last-child {
    margin-bottom: 0;
  }
`;

export const StyledButtonsDiv = styled.div`
  margin-top: ${offsetXXLg};
`;

export const Card = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${offsetXLg};
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  max-width: ${CARD_MAX_WIDTH};
  margin-right: ${offsetXLg};
`;

export const CardContent = styled.div`
  position: relative;
  width: 100%;
`;

export const CardTitle = styled.h3`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  margin-bottom: ${offsetXSm};
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${offsetXXSm};
`;

export const ItemWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${offsetXLg};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const PriceText = styled.span`
  font-size: ${fontSizeSm};
  line-height: ${fontSizeMd};
`;

export const PrefixIcon = styled(Icon)`
  margin-right: ${offsetXSm};
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${offsetXLg};
  min-height: ${CARD_CONTAINER_MIN_HEIGHT};

  @media (min-width: ${xxlg}) {
    grid-template-columns: 1fr ${CARD_MAX_WIDTH};
  }
`;

export const Text = styled.div`
  margin: ${offsetNone};
`;

// Styles for Dropdown

export const DropdownWrapper = styled.div<{isShowed: boolean}>`
  ${({isShowed}) => css`
    opacity: ${isShowed ? 1 : 0};
    visibility: ${isShowed ? 'visible' : 'hidden'};
    pointer-events: ${isShowed ? 'auto' : 'none'};
  `}
`;

export const StyledDropdown = styled(NW2Dropdown)`
  position: absolute;
  top: ${offsetNone};
  right: ${offsetNone};
  z-index: 2;
`;

export const StyledLink = styled.a`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightNormal};
  color: ${lightBlackColor};
  text-decoration: none;

  display: block;
  width: ${DROPDOWN_LINK_WIDTH};
  padding: ${offsetDef} ${offsetSm};

  &:hover {
    color: ${lightBlackColor};
  }
`;

export const ActionsDiv = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;

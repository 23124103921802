import React from 'react';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-final-form';

import {useAppSelector} from 'store/hooks';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {setPreviewTotalDrawerVisible} from 'store/bookingsCustomer/bookingsCustomerSlice';
import {StyledButtonConfirm} from 'view/venue/NW2BookingPreview/components/NW2BookingTotal/NW2BookingTotal.styles';
import {offsetXXLg} from 'constants/styleVars';

type TProps = {
  btnText: string;
  isDrawerShowed: boolean;
  submitting: boolean;
  extrasCount: number;
  isLoggedInUser: boolean;
  isLoading: boolean;
  isOfferPreview?: boolean;
};
export function ButtonConfirm({
  btnText,
  submitting,
  isDrawerShowed,
  extrasCount,
  isOfferPreview,
  isLoading,
  isLoggedInUser,
}: TProps) {
  const form = useForm();
  const dispatch = useDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const errorSections = useAppSelector(
    ({bookingsCustomer}) => bookingsCustomer.errorFormSections,
  );

  const handleSubmitClick = () => {
    // scroll to the section with error
    if (isDrawerShowed) {
      dispatch(setPreviewTotalDrawerVisible(false));
    }
    if (typeof window !== 'undefined' && errorSections.length) {
      const section = document.getElementById(errorSections[0]);
      if (section) {
        const curScroll = window.scrollY;
        const OFFSET = parseInt(offsetXXLg);

        window.scrollTo({
          top: section.getBoundingClientRect().top + curScroll - OFFSET,
          behavior: 'smooth',
        });
      }
    }
  };

  const onClick = () => {
    if (!isLoggedInUser) {
      dispatch(setOpenLoginPopup({openLoginPopup: true}));
      return;
    }
    if (isMobile && !isDrawerShowed) {
      dispatch(setPreviewTotalDrawerVisible(true));
      return;
    }

    form.submit();
    handleSubmitClick();
  };

  const buttonText = submitting
    ? 'sending...'
    : isLoading
    ? 'loading...'
    : btnText;

  if (typeof submitting === 'undefined') return null;

  return (
    <StyledButtonConfirm
      type='button'
      disabled={submitting}
      buttonType='primary'
      hasCart={!!extrasCount}
      onClick={onClick}
      loading={isLoading}
      size={isOfferPreview ? 'medium' : undefined}
      fullWidth
    >
      {buttonText}
    </StyledButtonConfirm>
  );
}

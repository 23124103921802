import React from 'react';

import {ESwitchSizes, IProps} from './NW2Switch.types';

import {
  SwitchContainer,
  Label,
  ControlContainer,
  Control,
} from './NW2Switch.styles';

const NW2Switch = ({
  isActive = false,
  label,
  disabled,
  onClick,
  size = ESwitchSizes.LARGE,
  labelPosition = 'left',
  className,
}: IProps) => {
  return (
    <SwitchContainer labelPosition={labelPosition} className={className}>
      {label && <Label size={size}>{label}</Label>}
      <ControlContainer
        isActive={isActive}
        disabled={disabled}
        size={size}
        onClick={onClick}
        data-testid={`switch-${label}`}
        type='button'
      >
        <Control isActive={isActive} size={size} disabled={disabled} />
      </ControlContainer>
    </SwitchContainer>
  );
};

export default NW2Switch;

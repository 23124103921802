import {useMemo} from 'react';
import _get from 'lodash/get';

import {useAppSelector} from 'store/hooks';
import {IExtrasOption} from 'types/dto/IExtras.type';
import {EEventType} from 'types/venue';
import {
  convertOrderDayToOfferRequestDay,
  convertRequestArrivalItemsToSummaryItems,
} from '../helpers';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';

export const useRequestBedrooms = (isOfferOrRequest?: boolean) => {
  const offerDetailsDays = useAppSelector(
    ({offers}) => offers.offerDetails.days,
  );
  const orderDays = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderDays,
  );

  const allExtrasOption: IExtrasOption[] = useAppSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  return useMemo(
    () => ({
      preArrivals: isOfferOrRequest
        ? convertRequestArrivalItemsToSummaryItems(
            getFilteredUnitsByEventType(
              offerDetailsDays,
              EEventType.PRE_ARRIVAL,
            ),
            allExtrasOption,
          )
        : convertOrderDayToOfferRequestDay(EEventType.PRE_ARRIVAL, orderDays),
      postEvents: isOfferOrRequest
        ? convertRequestArrivalItemsToSummaryItems(
            getFilteredUnitsByEventType(
              offerDetailsDays,
              EEventType.POST_EVENT,
            ),
            allExtrasOption,
          )
        : convertOrderDayToOfferRequestDay(EEventType.POST_EVENT, orderDays),
    }),
    [allExtrasOption, isOfferOrRequest, offerDetailsDays, orderDays],
  );
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  IOfferDay,
  IRequestDay,
  IRequestV2,
  TOfferDetails,
  TCompareList,
  TShortList,
  TShortListItem,
  IGroupDetails,
} from 'types/offer';
import {ISearchRequestOfferDto} from 'types/dto/ISearchRequestOfferDto.type';
import LocalStorageService from 'infra/common/localStorage.service';
import {
  compareListDefaultState,
  shortListDefaultState,
} from 'view/venue/Offer/customer/ShortList/constants';
import {IVenue} from 'types/venue';
import {ISearchGroupDto} from 'types/dto/ISearchGroupDto.type';
import {TUploadFile} from '../../view/components/Uploader/types';

interface IOffersInitialState {
  isLoading: boolean;
  isRequestsLoading: boolean;
  error: unknown;
  isOffersListLoaded: boolean;
  requests: ISearchRequestOfferDto[];
  offers: ISearchRequestOfferDto[];
  offerDetails: TOfferDetails;
  requestedUnits: (IRequestDay | IOfferDay)[];
  supplierOfferRequest: IRequestV2 | Record<string, never>;
  requestToken: string;
  pendingRequestsCount: number;
  declineModalId: string;
  cancellationPolicyFile: TUploadFile | null;
  cancellationPolicyFileBlobPreview: string;
  supplierCancellationPolicy: boolean;

  // groups
  shortList: TShortList;
  compareList: TCompareList;
  groups: ISearchGroupDto[];
  isGroupsLoading: boolean;
  groupDetails: IGroupDetails | null;
  isGroupDetailsLoading: boolean;
}

const initialState: IOffersInitialState = {
  isLoading: false,
  isRequestsLoading: false,
  error: null,
  isOffersListLoaded: false,
  requests: [],
  offers: [],
  offerDetails: {},
  requestedUnits: [],
  supplierOfferRequest: {},
  requestToken: '',
  pendingRequestsCount: 0,
  declineModalId: '',
  cancellationPolicyFile: null,
  cancellationPolicyFileBlobPreview: '',
  supplierCancellationPolicy: false,

  // groups
  shortList: shortListDefaultState,
  compareList: compareListDefaultState,
  groups: [],
  isGroupsLoading: false,
  groupDetails: null,
  isGroupDetailsLoading: false,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOffersLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setRequestLoading(state, action: PayloadAction<boolean>) {
      state.isRequestsLoading = action.payload;
    },
    setOffersError(state, action: PayloadAction<unknown>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setSearchOffersLoaded(state, action: PayloadAction<boolean>) {
      state.isOffersListLoaded = action.payload;
    },
    setOfferDetails(state, action: PayloadAction<TOfferDetails>) {
      state.offerDetails = action.payload;
      state.isLoading = false;
    },
    clearOfferDetails(state) {
      state.offerDetails = {};
    },
    setGroups(state, action: PayloadAction<ISearchGroupDto[]>) {
      state.groups = action.payload;
      state.isGroupsLoading = false;
    },
    setGroupsLoading(state, action: PayloadAction<boolean>) {
      state.isGroupsLoading = action.payload;
    },

    setSupplierOfferRequests(
      state,
      action: PayloadAction<ISearchRequestOfferDto[]>,
    ) {
      state.requests = action.payload;
    },
    setSupplierOffers(state, action: PayloadAction<ISearchRequestOfferDto[]>) {
      state.offers = action.payload;
    },
    setRequestedUnits(state, action: PayloadAction<IRequestDay[]>) {
      state.requestedUnits = action.payload; // todo check & remove after merge
    },
    clearRequestedUnits(state) {
      state.requestedUnits = [];
    },
    setSupplierOfferRequest(state, action: PayloadAction<IRequestV2>) {
      state.supplierOfferRequest = action.payload;
      state.isLoading = false;
    },
    setTokenForRequest(state, action: PayloadAction<string>) {
      state.requestToken = action.payload;
    },
    setRequestsCount(state, action: PayloadAction<number>) {
      state.pendingRequestsCount = action.payload;
    },
    setDeclineModalId(state, action: PayloadAction<string>) {
      state.declineModalId = action.payload;
    },

    // shortlist
    addShortList(state, action: PayloadAction<TShortList>) {
      state.shortList = action.payload;
      LocalStorageService.setByKey('shortList', JSON.stringify(action.payload));
    },
    addShortListItem(state, action: PayloadAction<TShortListItem>) {
      const updatedShortList = {
        ...state.shortList,
        items: [...state.shortList.items, action.payload],
      };
      LocalStorageService.setByKey(
        'shortList',
        JSON.stringify(updatedShortList),
      );
      state.shortList = updatedShortList;
    },
    removeShortListItem(state, action: PayloadAction<number>) {
      const filterItems = <T extends {id: number}>({id}: T) =>
        id !== action.payload;

      const filteredShortList = state.shortList.items.filter(filterItems);

      const updatedShortList = {...state.shortList, items: filteredShortList};

      LocalStorageService.setByKey(
        'shortList',
        JSON.stringify(updatedShortList),
      );
      state.shortList = updatedShortList;

      if (state.compareList.items.length) {
        state.compareList = {
          ...state.compareList,
          items: state.compareList.items.filter(filterItems),
        };
      }
    },
    setShortListMaxItemsModalShowed(state, action: PayloadAction<boolean>) {
      state.shortList = {...state.shortList, isMaxItemsReached: action.payload};
    },
    clearShortList(state) {
      LocalStorageService.removeItemByKey('shortList');
      state.shortList = shortListDefaultState;
      state.compareList = compareListDefaultState;
    },
    addCompareList(state, action: PayloadAction<IVenue[]>) {
      state.compareList = {
        ...state.compareList,
        items: action.payload,
        isLoading: false,
      };
    },
    clearCompareList(state) {
      state.compareList = compareListDefaultState;
    },
    setGroupDetailsLoading(state, action: PayloadAction<boolean>) {
      state.isGroupDetailsLoading = action.payload;
    },
    setCompareListLoading(state, action: PayloadAction<boolean>) {
      state.compareList = {...state.compareList, isLoading: action.payload};
    },
    setCancellationPolicyFileBlobPreview(state, action: PayloadAction<string>) {
      state.cancellationPolicyFileBlobPreview = action.payload;
    },
    setCancellationPolicyFile(state, action: PayloadAction<TUploadFile>) {
      state.cancellationPolicyFile = action.payload;
    },
    clearCancellationPolicyFile(state) {
      state.cancellationPolicyFile = null;
    },
    setSupplierCancellationPolicy(state, action: PayloadAction<boolean>) {
      state.supplierCancellationPolicy = action.payload;
    },
    // shortlist end
    setGroupDetails(state, action: PayloadAction<IGroupDetails>) {
      state.groupDetails = action.payload;
      state.isGroupDetailsLoading = false;
    },
    clearGroupDetails(state) {
      state.groupDetails = null;
    },
  },
});

export const {
  clearOfferDetails,
  setOffersLoading,
  setRequestLoading,
  setOffersError,
  setSearchOffersLoaded,
  setSupplierOfferRequests,
  setSupplierOffers,
  setRequestedUnits,
  clearRequestedUnits,
  setOfferDetails,
  setSupplierOfferRequest,
  setRequestsCount,
  setDeclineModalId,
  setGroups,
  setGroupsLoading,
  addShortList,
  addShortListItem,
  removeShortListItem,
  setShortListMaxItemsModalShowed,
  clearShortList,
  addCompareList,
  clearCompareList,
  setCompareListLoading,
  setGroupDetailsLoading,
  setGroupDetails,
  clearGroupDetails,
  setCancellationPolicyFile,
  clearCancellationPolicyFile,
  setCancellationPolicyFileBlobPreview,
  setSupplierCancellationPolicy,
} = offersSlice.actions;

export default offersSlice.reducer;

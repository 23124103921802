import React, {useEffect, useState} from 'react';

import CustomList from 'view/components/CustomList';
import EditFoodAndBeverage from './EditFoodAndBeverage';
import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import {TSelectedOptions} from 'types/search';
import {setDefaultExtras} from '../helpers';

import {offsetXSm} from 'constants/styleVars';
import {FlexContainer} from '../AddFoodBeverageRequest.styles';

type TProps = {
  foodBeverageData: TSelectedOptions;
  setSelectedFoodBeverage: (formData?: Record<string, any>) => void;
  setEditedFoodBeverageDaysIndex: React.Dispatch<
    React.SetStateAction<number[]>
  >;
  dayIndex: number;
  dayParticipants: number;
};

function FoodAndBeverageContent({
  foodBeverageData,
  setSelectedFoodBeverage,
  setEditedFoodBeverageDaysIndex,
  dayIndex,
  dayParticipants,
}: TProps) {
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    setIsEditMode((prevState) => {
      if (prevState) return !prevState;
      return prevState;
    });
  }, [foodBeverageData]);

  const list = foodBeverageData ? setDefaultExtras(foodBeverageData) : null;

  const onClickEdit = () => {
    setIsEditMode(true);
  };
  const onSave = (formData: Record<string, any>) => {
    setEditedFoodBeverageDaysIndex((prevIndexes: number[]) => [
      ...prevIndexes,
      dayIndex,
    ]);
    setSelectedFoodBeverage(formData);
    setIsEditMode(false);
  };
  const onCancel = () => {
    setIsEditMode(false);
  };

  if (!!foodBeverageData && !foodBeverageData.options.length)
    return <NW2Loader />;

  return (
    <div>
      {!isEditMode && list ? (
        <FlexContainer justifyContent='space-between'>
          <CustomList list={list} listStyle='unordered' offset={offsetXSm} />
          <NW2Button
            minimized
            buttonType='tertiary'
            size='small'
            icon={<Icon transparent icon='NW2_EDIT' />}
            onClick={onClickEdit}
          />
        </FlexContainer>
      ) : (
        <EditFoodAndBeverage
          onSave={onSave}
          onCancel={onCancel}
          participants={dayParticipants}
          foodBeverageData={foodBeverageData}
        />
      )}
    </div>
  );
}

export default FoodAndBeverageContent;

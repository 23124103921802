import React from 'react';

import {useAppSelector} from 'store/hooks';
import useToggle from 'hooks/useToggle';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import RequestModalForm from './RequestModalForm';

import {StyledLink} from 'view/venue/NW2VenuesList/components/NW2VenuesListComponent/NW2VenuesListComponent.styles';

type TProps = {
  isBTCApp: boolean;
  isMeetingRoom: boolean;
  isWorkSpace: boolean;
};
function BtcRequestLink({isBTCApp, isMeetingRoom, isWorkSpace}: TProps) {
  const [isRequestModal, toggleRequestModal] = useToggle();
  const {isMobile} = useAppSelector(({app}) => app.deviceType);

  if (!isBTCApp) return null;

  return (
    <>
      <li>
        <StyledLink onClick={toggleRequestModal}>
          {isMeetingRoom && 'Submit request to HRS support team'}
          {isWorkSpace && 'Let us know your favorite venue'}
        </StyledLink>
      </li>

      <NW2ModalDrawer
        header={
          isMeetingRoom
            ? 'Submit request to HRS support team'
            : 'Let us know your favorite venue'
        }
        body={
          <RequestModalForm
            isMeetingRoom={isMeetingRoom}
            isWorkSpace={isWorkSpace}
            toggleRequestModal={toggleRequestModal}
          />
        }
        isShowed={isRequestModal}
        onClose={toggleRequestModal}
        isMobile={isMobile}
        drawerHeight='98%'
      />
    </>
  );
}
export default BtcRequestLink;

import React from 'react';
import styled from 'styled-components';

import {StyledH5} from 'view/components/Typography';
import {
  fontSizeLg,
  fontWeightBold,
  mdBp,
  NW2Gray50Color,
  NW2Gray800Color,
  offsetDef,
  offsetSm,
  offsetXXLg,
  textColor,
  whiteColor,
} from 'constants/styleVars';

const SubTitle = styled.div<{isDarkBackground?: boolean}>`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  color: ${({isDarkBackground}) =>
    isDarkBackground ? NW2Gray50Color : NW2Gray800Color};

  * + & {
    margin-top: ${offsetSm};
  }
`;

const BodyText = styled.div<{isDarkBackground?: boolean}>`
  color: ${({isDarkBackground}) => (isDarkBackground ? whiteColor : textColor)};

  * + & {
    margin-top: ${offsetDef};
  }

  @media (min-width: ${mdBp}px) {
    * + & {
      margin-top: ${offsetXXLg};
    }
  }
`;

type TProps = {
  title: string;
  subTitle: string;
  bodyText: string | JSX.Element;
  isDarkBackground: boolean;
};

export function CopyBlock({
  title,
  subTitle,
  bodyText,
  isDarkBackground,
}: Partial<TProps>) {
  const headingColor = isDarkBackground ? {white: true} : {black: true};

  return (
    <>
      {title && <StyledH5 {...headingColor}>{title}</StyledH5>}
      {subTitle && (
        <SubTitle isDarkBackground={isDarkBackground}>{subTitle}</SubTitle>
      )}
      {bodyText && (
        <BodyText isDarkBackground={isDarkBackground}>{bodyText}</BodyText>
      )}
    </>
  );
}

import React from 'react';

import InfoPageImage from '../../NW2MultiModal/components/InfoPageImage';
import TextContent from './components/TextContent';
import useGoBackFromInfoPage from '../../NW2MultiModal/hooks/useGoBackFromInfoPage';
import image from 'img/privacyPolicy.jpg';

import {NW2ModalTwoColumnsContent} from 'view/components/NW2ModalTwoColumns/NW2ModalTwoColumnsContent';

const CustomerPrivacyPolicyPage = () => {
  const goBack = useGoBackFromInfoPage();

  return (
    <NW2ModalTwoColumnsContent
      title='Privacy policy'
      onToggleShow={goBack}
      colLeft={<InfoPageImage src={image} />}
      colRight={<TextContent />}
    />
  );
};

export default CustomerPrivacyPolicyPage;

import {useCallback, useState} from 'react';

import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';

export function useSpaceImages({activeUnitId}: {activeUnitId: string}) {
  const [filesState, setFilesState] = useState<
    Record<string, TFileWithPreview[]>
  >({});
  const handleResetImages = useCallback(() => {
    filesState[activeUnitId]?.forEach((file: TFileWithPreview) =>
      URL.revokeObjectURL(file.preview),
    );
    setFilesState((prev) => ({...prev, [activeUnitId]: []}));
  }, [activeUnitId, filesState]);

  const handleSetFiles = useCallback(
    (files: TFileWithPreview[]) => {
      setFilesState((prev) => ({...prev, [activeUnitId]: files}));
    },
    [activeUnitId],
  );

  return {
    filesState,
    handleResetImages,
    handleSetFiles,
  };
}

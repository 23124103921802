import styled, {css} from 'styled-components';

import {
  blackColor,
  infoColor,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Gray800Color,
  NW2Gray900Color,
  NW2SuccessLight500Color,
  whiteColor,
  borderRadiusDef,
  borderRadiusLg,
  fontSizeSm,
  fontSizeXXSm,
  fontWeightBold,
  lineHeightMd,
  lineHeightXSm,
  offsetDef,
  offsetSm,
  offsetXXSm,
  fontSizeXSm,
} from 'constants/styleVars';

const DISABLED_OPACITY = 0.3;

export const ButtonCreate = styled.button`
  width: 100%;
  background-color: ${NW2Gray100Color};
  border: 1px dashed ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${offsetDef} ${offsetSm};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  color: ${NW2Gray600Color};
  transition: background-color 0.15s, border-color 0.15s, color 0.15s;
  cursor: pointer;

  &:hover {
    background-color: ${NW2Gray200Color};
    color: ${NW2Gray800Color};
    border-color: ${infoColor};
  }

  span {
    font-size: ${fontSizeXXSm};
    line-height: ${lineHeightXSm};
    pointer-events: none;
  }
`;

export const ExistedRoomsWrapper = styled.div`
  border-top: 1px solid ${NW2Gray200Color};
  padding-top: ${offsetDef};
  margin-top: ${offsetDef};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExistedRoomsTitle = styled.div`
  font-weight: ${fontWeightBold};
  color: ${NW2Gray900Color};
  text-transform: uppercase;
  margin-bottom: ${offsetXXSm};
`;

export const ExistedRoomsSubTitle = styled.div`
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetDef};
`;

export const RoomCard = styled.button<{isDisabled?: boolean}>`
  width: 100%;
  background: 0;
  display: grid;
  grid-template-columns: 66px 1fr;
  grid-column-gap: ${offsetDef};
  margin-bottom: ${offsetDef};
  padding: ${offsetSm};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
  color: ${NW2Gray900Color};
  transition: border-color 0.15s;
  cursor: ${({isDisabled}) => (isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    border-color: ${blackColor};
  }

  b {
    display: flex;
    text-align: left;

    ${({isDisabled}) =>
      isDisabled &&
      css`
        opacity: ${DISABLED_OPACITY};
      `};
  }

  * {
    pointer-events: none;
  }
`;

export const RoomCardImg = styled.span<{isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${NW2Gray100Color};
  border-radius: ${borderRadiusDef};
  height: 50px;
  overflow: hidden;

  ${({isDisabled}) =>
    isDisabled &&
    css`
      opacity: ${DISABLED_OPACITY};
    `};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RoomCardInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-top: 6px;
`;

export const RoomCardParticipants = styled.span<{isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 6px;

  ${({isDisabled}) =>
    isDisabled &&
    css`
      opacity: ${DISABLED_OPACITY};
    `};

  > span {
    font-size: ${fontSizeXSm};
    line-height: 14px;
    text-align: left;
  }
`;

export const RoomCardLabel = styled.span<{isDisabled?: boolean}>`
  display: inline-block;
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  font-weight: ${fontWeightBold};
  color: ${({isDisabled}) => (isDisabled ? NW2Gray600Color : whiteColor)};
  text-align: center;
  background-color: ${({isDisabled}) =>
    isDisabled ? NW2Gray100Color : NW2SuccessLight500Color};
  border-radius: ${borderRadiusDef};
  padding: 0 ${offsetXXSm};
  white-space: nowrap;

  ${({isDisabled}) => isDisabled && 'pointer-events: auto;'};
`;

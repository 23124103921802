import styled from 'styled-components';
import {
  blackColor,
  darkGrayColor,
  fontSizeMd,
  fontSizeSm,
  offsetDef,
  offsetSm,
  whiteColor,
} from 'styles/configs';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {
  THasError,
  TInputSize,
  TInputVariant,
} from 'view/components/NW2FormItem/types';
import {
  InputStyles,
  inputStylesMap,
} from 'view/components/NW2FormItem/NW2FormItem.styles';

export const EditorInner = styled.div`
  background: ${whiteColor};
  position: relative;
`;

export const EditorInput = styled(ContentEditable)`
  min-height: 150px;
  resize: none;
  font-size: ${fontSizeSm};
  caret-color: ${blackColor};
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: ${offsetDef} ${offsetSm};
  caret-color: ${darkGrayColor};
`;

export const EditorPlaceholder = styled.div`
  color: ${darkGrayColor};
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: ${offsetDef};
  left: ${offsetSm};
  font-size: ${fontSizeMd};
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

export const EditorContainer = styled.div<{
  hasError?: THasError;
  focused?: boolean;
  variant: TInputVariant;
  inputSize: TInputSize;
}>`
  ${InputStyles};
  border-color: ${({hasError, focused, variant}) => {
    const inputStyle: any = inputStylesMap[variant];
    return `${
      hasError
        ? inputStyle.borderErrorColor
        : focused
        ? inputStyle.borderFocusedColor
        : inputStyle.borderColor
    }`;
  }};
  outline: none;
`;

import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import ArrowLeft from 'img/icons/nw2/nw2_arrow_left.svg';
import ArrowRight from 'img/icons/nw2/nw2_arrow_right.svg';

import {
  borderRadiusDef,
  fontSizeMd,
  fontSizeXXLg,
  fontWeightBold,
  fontWeightExtraBold,
  lgBp,
  lineHeightLg,
  lineHeightXLg,
  mdBp,
  NW2Gray100Color,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXXXXLg,
  whiteColor,
} from 'constants/styleVars';

const closeBtnSize = '32px';

export const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${NW2Gray900Color};
  padding: ${offsetDef};

  @media (min-width: ${lgBp}px) {
    justify-content: flex-start;
  }

  @media (min-width: ${mdBp}px) {
    padding: ${offsetXXXXXLg} ${offsetXXLg};
  }

  // react-image-gallery lib overrides
  .image-gallery-index {
    left: 0;
    background: none;
    text-align: center;
    top: 100%;
    padding: ${offsetXLg};
    font-size: ${fontSizeMd};
    line-height: ${lineHeightLg};

    &-current {
      font-weight: ${fontWeightExtraBold};
    }

    &-slides {
      border-radius: ${borderRadiusDef};
    }
  }
`;

const StyledArrowButton = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  background: transparent;
  border: 0;
  appearance: none;
  z-index: 1;
`;

export const StyledButtonLeft = styled(StyledArrowButton)`
  left: 0;
  cursor: url(${ArrowLeft}) 32 32, auto;
`;

export const StyledButtonRight = styled(StyledArrowButton)`
  right: 0;
  cursor: url(${ArrowRight}) 32 32, auto;
`;

export const StyledHeader = styled.div`
  position: relative;
  padding: ${offsetXXXXXLg} 0 0;

  @media (min-width: ${mdBp}px) {
    padding: 0 ${offsetXXLg};
  }
`;

export const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-bottom: 72px;
`;

export const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1162px;
  margin: 0 auto;
`;

export const StyledCloseButton = styled(NW2Button)`
  position: absolute;
  top: 0;
  right: 0;
  min-width: ${closeBtnSize};
  padding: 0;
  width: ${closeBtnSize};
  height: ${closeBtnSize};
  display: flex;
  align-content: center;
  justify-content: center;
  border: 1px solid ${whiteColor};
  border-radius: 50%;
  color: ${whiteColor};

  > span {
    display: flex;
  }
`;

export const StyledTitle = styled.div`
  font-size: ${fontSizeXXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightBold};
  text-align: center;
  margin-bottom: ${offsetDef};
  color: ${NW2Gray100Color};
  text-transform: capitalize;
`;

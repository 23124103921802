import React, {useEffect, useRef} from 'react';
import {DateTime} from 'luxon';

import {
  CurrentYearMonth,
  MonthYearPickerOptions,
  Option,
} from '../DesktopDatePicker.styles';

type TProps = {
  date: Date;
  onChangeYear: (year: number) => void;
  onClick: () => void;
  isActive: boolean;
  isDisabled: boolean;
  years: number[];
};

export function YearPicker({
  onChangeYear,
  date,
  years,
  onClick,
  isActive,
  isDisabled,
}: TProps) {
  const optionRef = useRef<HTMLDivElement | null>(null);
  const currentYear = DateTime.fromJSDate(date).year;

  useEffect(() => {
    if (isActive)
      optionRef?.current?.scrollIntoView({block: 'nearest', inline: 'center'});
  }, [isActive]);

  return (
    <div>
      <CurrentYearMonth
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onClick}
      >
        {currentYear}
      </CurrentYearMonth>

      <MonthYearPickerOptions isActive={isActive} columns={4}>
        <div>
          {years.map((option) => (
            <div key={option} ref={currentYear === option ? optionRef : null}>
              <Option
                value={option}
                onClick={() => onChangeYear(Number(option))}
                isActive={currentYear === option}
              >
                {option}
              </Option>
            </div>
          ))}
        </div>
      </MonthYearPickerOptions>
    </div>
  );
}

import React, {useEffect, useMemo} from 'react';
import {Form} from 'react-final-form';
import {useLocation, useNavigate} from 'react-router-dom';
import _get from 'lodash/get';

import Header from './Header/Header';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import {NW2SpaceDetails} from '../NW2VenueDetails/components';
import {PreFooter} from 'view/components/PreFooter';
import useGetUrlData from './hooks/useGetUrlData';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import FormComponent from 'view/venue/NW2BookingEdit/FormComponent/FormComponent';

import {confirmBookingRequest} from 'store/venues/venuesSlice';
import {getPublicVenue} from 'store/venues/apiActions';
import {
  getCustomerBookingOrder,
  handleOnSuccessPostCustomerBookingOrder,
} from 'store/customer/apiActions';
import {
  cleanCustomerBookedUnitsToReCalculate,
  cleanCustomerBookingOrder,
  setCustomerBookedUnitsToReCalculate,
  setIsLoading,
} from 'store/customer/customerSlice';
import {StyledWrapper} from '../NW2BookingPreview/NW2BookingPreview.styles';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {LoaderWrapper} from '../NW2BookingConfirmation/NW2BookingConfirmation.styles';
import {setCustomerDefaultBillingAddress} from 'store/app/appSlice';
import {IEditFormState} from 'view/venue/NW2BookingEdit/helpers';

import {venuesContainer} from 'app';
import {IBookingOrderResponse} from 'types/dto/IBooking.types';

import {EBookingStatus} from 'types/booking';
import {getPackages} from 'store/packages/apiActions';
import {getEditPaymentDataPayload} from '../helpers/helpers';

const NW2BookingEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locationData = useLocation();
  const {paramsVenueId, paramsOrderNumber} = useGetUrlData();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isLoggedIntoSystem = useAppSelector(
    ({app}) => app.user.isLoggedIntoSystem,
  );
  const bookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );
  const customerBookedUnitsToReCalculate = useAppSelector(
    ({customer}) => customer.customerBookedUnitsToReCalculate,
  );
  const cancellationPolicyText = useAppSelector(
    ({customer}) => customer.cancellationPolicyText,
  );
  const orderNumber = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderNumber,
  );
  const userId = useAppSelector(({app}) => app.user.id);

  const venueId = useAppSelector((state) =>
    _get(state, 'venuesReducer.venueDetails.id', 0),
  );

  useEffect(() => {
    if (paramsVenueId && !venueId && bookingOrder?.orderId) {
      dispatch(
        getPublicVenue({
          id: paramsVenueId,
          isMultiRooms: bookingOrder.unitBookingDtos.length > 1,
        }),
      );
    }
  }, [
    dispatch,
    bookingOrder?.orderId,
    venueId,
    paramsVenueId,
    bookingOrder?.unitBookingDtos.length,
  ]);

  useEffect(() => {
    if (
      !customerBookedUnitsToReCalculate.length &&
      bookingOrder?.orderDays.length
    ) {
      dispatch(setCustomerBookedUnitsToReCalculate(bookingOrder.orderDays));
    }
  }, [dispatch, bookingOrder, customerBookedUnitsToReCalculate]);

  useEffect(() => {
    if (paramsOrderNumber && isLoggedIntoSystem) {
      dispatch(getPackages(Number(paramsVenueId)));
      dispatch(getCustomerBookingOrder(paramsOrderNumber, true));
    }
  }, [dispatch, paramsOrderNumber, isLoggedIntoSystem, paramsVenueId]);

  useEffect(() => {
    return () => {
      dispatch(cleanCustomerBookingOrder());
      dispatch(cleanCustomerBookedUnitsToReCalculate());
      dispatch(setCustomerDefaultBillingAddress());
    };
  }, [dispatch]);

  // ToDo
  const isIncursChangeFees = false;
  const shouldConfirmByVenue = true;

  const formInitialValues = useMemo(
    () => ({
      orderDays: [],
    }),
    [],
  );

  const onStatusConfirmed = (bookingResponse: IBookingOrderResponse) => {
    const query = new URLSearchParams(locationData.search);

    query.set('venueId', String(paramsVenueId));
    navigate({
      pathname: `/booking/${orderNumber}`,
      search: query.toString(),
    });
    dispatch(handleOnSuccessPostCustomerBookingOrder(bookingResponse));
  };

  const handleOnSuccess: Record<string, any> = {
    [EBookingStatus.CONFIRMED]: onStatusConfirmed,
    [EBookingStatus.RTC_PENDING]: onStatusConfirmed,
  };

  const onFormSubmit = (formData: IEditFormState) => {
    const data = getEditPaymentDataPayload({
      userId,
      venueID: Number(paramsVenueId),
      formData,
      bookingOrder: bookingOrder || ({} as IBookingOrderResponse),
    });

    dispatch(confirmBookingRequest());
    dispatch(setIsLoading(true));

    venuesContainer.postEditCustomerOrder({
      payload: {...data, orderNumber},

      onSuccess: (response: IBookingOrderResponse) => {
        handleOnSuccess[response.bookingStatus](response);
        dispatch(setIsLoading(false));
      },
      onError: () => {
        dispatch(setIsLoading(false));
      },
    });
  };

  const isLoader =
    !bookingOrder || venueId === 0 || !customerBookedUnitsToReCalculate.length;

  if (isLoader) {
    return (
      <LoaderWrapper isMobile={isMobile}>
        <NW2Loader label='Loading' />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Header
        isMobile={isMobile}
        isIncursChangeFees={isIncursChangeFees}
        shouldConfirmByVenue={shouldConfirmByVenue}
        cancellationPolicyText={cancellationPolicyText}
      />
      <StyledWrapper>
        <NW2Container>
          <Form
            onSubmit={onFormSubmit}
            initialValues={formInitialValues}
            render={FormComponent}
            keepDirtyOnReinitialize
          />
        </NW2Container>
      </StyledWrapper>
      <PreFooter />
      <NW2SpaceDetails isFooterHidden />
    </>
  );
};

export default NW2BookingEdit;

import {getGuestLayout, getDefaultLayout} from '../layoutConfigs';
import {Routes} from 'constants/routes';
import {TCustomRoutesLayout, TMenuReturnType} from 'view/common/Header/types';
import {logoLayout} from '../layoutConfigs/LogoLayout';

const customRoutes = [
  Routes.mainLanding,
  Routes.customerId,
  Routes.customerBookings,
  Routes.userAccountVerificationDashboard,
  // Routes.userCompleteRegistration,
  Routes.userForgotPassword,
  Routes.userChangePassword,
  Routes.venuesList,
  Routes.venueDetails,
  Routes.bookVenuePreview,
  Routes.bookConfirmation,
];

const mainCustomRoutesLayout = customRoutes.reduce((prev, next) => {
  return {
    ...prev,
    [next.toLowerCase()]: getGuestLayout(),
  };
}, {} as TCustomRoutesLayout);

export const guestMenu: TMenuReturnType = {
  layout: {
    defaultLayoutType: getDefaultLayout(),
    customRoutesLayout: {
      ...mainCustomRoutesLayout,
      [Routes.requestDecline]: logoLayout(),
      [Routes.requestDeclineSuccess]: logoLayout(),
    },
  },
  navList: [
    {
      url: Routes.createPublicVenue,
      text: 'Become a supplier',
    },
  ],
};

import React, {useEffect, ReactNode} from 'react';
import styled from 'styled-components';

import {whiteColor, modalOverlayZIndex} from 'constants/styleVars';

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${whiteColor};
  z-index: ${modalOverlayZIndex};
`;

type TProps = {
  isNoBlockScroll?: boolean; // uses when we have overlay inside another one
  children: ReactNode;
  className?: string; // styled-components needs it
};

function ModalOverlay({isNoBlockScroll, children, className}: TProps) {
  useEffect(() => {
    if (isNoBlockScroll) return;

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isNoBlockScroll]);

  return <Container className={className}>{children}</Container>;
}

export default ModalOverlay;

import styled, {css} from 'styled-components';

import {
  offsetXSm,
  fontSizeSm,
  fontSizeXXLg,
  fontSizeXSm,
  fontWeightBold,
  NW2Gray100Color,
  NW2Gray300Color,
  NW2Gray900Color,
  NW2Secondary,
  offsetXXXSm,
  fontSizeXLg,
  lineHeightXLg,
  offsetXXSm,
  offsetXLg,
  blackColor,
} from 'constants/styleVars';

const LG_HEIGHT = '44px';

const getInputSize = (size?: 'medium' | 'large') => {
  switch (size) {
    case 'large':
      return css`
        font-size: ${fontSizeXLg};
        line-height: ${lineHeightXLg};
        font-weight: ${fontWeightBold};
        height: ${LG_HEIGHT};
      `;

    case 'medium':
    default:
      return css`
        font-size: ${fontSizeSm};
        line-height: ${fontSizeXXLg};
        height: ${offsetXLg};
      `;
  }
};
export const StyledInput = styled.input<{
  inputSize?: 'medium' | 'large';
  readOnly: boolean;
  inputLength: number;
}>`
  width: ${({inputLength}) => inputLength}px;
  outline: none;
  appearance: textfield;
  border: 0;
  cursor: default;
  text-align: center;
  padding: 0 ${offsetXXXSm};

  ${({inputSize}) => getInputSize(inputSize)};

  ::selection {
    color: inherit;
    background: transparent;
  }

  ${({readOnly}) =>
    !readOnly &&
    css`
      &:hover {
        border-radius: 4px;
        outline: 1px solid ${blackColor};
      }

      &:focus {
        border-radius: 4px;
        outline: 2px solid ${blackColor};
      }
    `}

  /* Disable arrows for number type input */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${offsetXXSm};
`;

export const AddButton = styled.button<{disabled?: boolean}>`
  border: none;
  width: 84px;
  height: 36px;
  cursor: pointer;
  border-radius: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${NW2Gray900Color};
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
  text-transform: uppercase;
  background-color: ${NW2Gray100Color};
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: ${NW2Secondary};
    background-color: ${NW2Gray900Color};
  }

  & * {
    cursor: pointer;
  }

  ${({disabled}) => {
    if (disabled) {
      return css`
        cursor: default;
        pointer-events: none;
        color: ${NW2Gray300Color};
        & * {
          cursor: default;
        }
      `;
    }
  }}
`;

export const Label = styled.span`
  margin-left: ${offsetXSm};

  ::selection {
    color: inherit;
    background: transparent;
  }
`;

const getChangeButtonSize = (size?: 'medium' | 'large') => {
  switch (size) {
    case 'large':
      return '36px';

    case 'medium':
    default:
      return '24px';
  }
};

export const ChangeButton = styled.button<{
  disabled?: boolean;
  size?: 'medium' | 'large';
}>`
  width: ${({size}) => getChangeButtonSize(size)};
  height: ${({size}) => getChangeButtonSize(size)};
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${NW2Gray900Color};
  font-size: ${fontSizeXXLg};
  background-color: ${NW2Gray100Color};
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: ${NW2Secondary};
    background-color: ${NW2Gray900Color};
  }

  & * {
    cursor: pointer;
  }

  ${({disabled}) => {
    if (disabled) {
      return css`
        cursor: default;
        pointer-events: none;
        color: ${NW2Gray300Color};
        & * {
          cursor: default;
        }
      `;
    }
  }}
`;

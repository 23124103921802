import React from 'react';

import {
  Content,
  Section,
  Container,
  Title,
  SubTitle,
  Paragraph,
  StyledUnorderedList,
  StyledCircleUnorderedList,
  StyledList,
  StyledExternalLink,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {
  HRS_GDPR_URL,
  POLICIES_GOOGLE_URL,
  HRS_DATENSCHUTZ_EMAIL,
} from 'constants/app';

// TODO: Check for new content
const TextContent = () => {
  return (
    <Content>
      <Section>
        <SubTitle>Data Privacy Notice by HRS GmbH</SubTitle>
        <Title>Responsibility for the Processing of Personal Data</Title>
        <Paragraph>
          HRS GmbH, domiciled at Breslauer Platz 4, 50668 Cologne, Germany
          (hereinafter referred to as "HRS") is responsible for the processing
          of your personal data on its web pages and mobile apps.
        </Paragraph>
      </Section>
      <Section>
        <Title>In detail</Title>
        <Paragraph>
          Responsibility for the collection, processing, and use of your
          personal data as defined by the General Data Protection Regulation
          rests with HRS GmbH, Breslauer Platz 4, 50668 Cologne (hereinafter
          "HRS").
        </Paragraph>
        <Paragraph>
          If you wish to object to the collection, processing, or use of your
          data by HRS in accordance with these data privacy rules in general or
          for individual measures, you may send your objection via e-mail, fax,
          or mail to the following address:
        </Paragraph>
      </Section>
      <Section>
        <Paragraph>HRS GmbH</Paragraph>
        <Paragraph>Breslauer Platz 4</Paragraph>
        <Paragraph>50668 Cologne</Paragraph>
      </Section>
      <Section>
        <Paragraph>Fax: +49 221 2077 666</Paragraph>
        <Paragraph>E-mail: datenschutz@hrs.de</Paragraph>
      </Section>
      <Section>
        <Paragraph>
          Insofar as data privacy is concerned, we are always there for you.
        </Paragraph>
        <Paragraph>
          You can reach our Data Protection Officer under{' '}
          <StyledExternalLink
            href={`mailto:${HRS_DATENSCHUTZ_EMAIL}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            {HRS_DATENSCHUTZ_EMAIL}
          </StyledExternalLink>
        </Paragraph>
        <Paragraph>
          The most important issue first: data privacy is a matter of trust, and
          your trust is important to us. We respect your privacy and your
          personal sphere. Protection and legal processing of your personal data
          are therefore important issues to us. To ensure that you feel secure
          when visiting our web pages, whenever we process your personal data,
          we strictly comply with the legal provisions, and want to let you know
          how we process your personal data.
        </Paragraph>
        <Paragraph>
          In this document, you will find out more on how we use and process
          your personal data. We are certain that the information is easy to
          read and transparent, so that you will understand what we want to say,
          without having a hard time. Furthermore, you will learn how you can
          contact us in case you have any questions regarding your personal
          data, which we will be more than happy to answer. Therefore, please
          read our rules regarding cookies, where we inform you how HRS uses
          cookies and similar technologies.
        </Paragraph>
        <Paragraph>
          You can print or store this document by using the usual functionality
          of your Internet service program (= browser: in most cases, "File"{' '}
          {'->'} "Save as").
        </Paragraph>
        <Paragraph>
          Our data privacy rules may change from time to time. As long as you
          care about your privacy, we recommend that you visit this page on a
          regular basis to ensure that you are always aware of the latest
          developments. Which types of personal data are collected by HRS, and
          on what kind of legal basis?
        </Paragraph>
        <Paragraph>
          We collect information regarding your computer. This is your IP
          address, the browser you use, and your language settings. It is also
          possible that we receive information about you from third parties or
          automatically collect other kinds of information. As long as we have
          received your consent in this respect, data processing is based on
          Article 6, Paragraph 1, Letter a of the General Data Protection
          Regulation; as long as these measures are necessary for technical
          reasons to ensure the functionality of our web pages and services,
          this is covered by Article 6, Paragraph 1, letter f of the General
          Data Protection Regulation. Our interest in the security of our offers
          is essential considering that, in the case of insufficient IT
          security, its operation as such – just like your personal data – would
          be greatly jeopardized. We additionally ensure that your interests in
          this regard are protected through reasonable measures, e.g. data
          anonymization or pseudonymization. Finally, please note that there is
          no automatic decision-making process, e.g. within the scope of
          automatic profiling.
        </Paragraph>
      </Section>
      <Section>
        <Title>In detail</Title>
        <Container>
          <SubTitle>Personal information we receive from you</SubTitle>
          <Paragraph>
            When you contact our Customer Service team or contact us in another
            manner (for example via social media or through communication with
            the accommodation ("Hotel") or venue ("Venue") (hereinafter together
            "Premises") – that you booked via our website), we collect
            information about you regarding these events.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>
            Personal information we receive from you about others
          </SubTitle>
          <Paragraph>
            In our Premises management platform various functions and contact
            persons for Premises are stored. You may have been entered by your
            Premises (usually your employer) in a specific role for your
            Premises. The data processing of this data is based on Art. 6 para.
            1 lit. f GDPR. The legitimate interest of us and your employer is
            communication. The data processed by us within the scope of this
            task will be deleted after change of the responsible person at the
            Premises.
          </Paragraph>
        </Container>
        <Title>
          Communication content will be deleted by us after expiry of the legal
          retention period.
        </Title>
      </Section>
      <Section>
        <Container>
          <SubTitle>
            Personal information automatically collected by us
          </SubTitle>
          <Paragraph>
            When you visit our websites or use our apps, and even if you do not
            make any booking, we automatically process the following data: IP
            address, date and time of your access to our services, hardware,
            software or browser type, operating system of your computer,
            application version, and language settings, as well as information
            regarding clicks and the pages accessed by you.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>Why does HRS collect and use your personal data?</SubTitle>
          <Paragraph>
            We ask you to provide personal data for practical reasons, for
            administration and processing of your user account, to ensure that
            you receive the best possible service and protect your data. We use
            your personal data to contact you or inform you about the latest
            offers.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Title>In detail</Title>
        <Paragraph>
          We use the information collected about you for different purposes.
          Your personal data may be used as follows:
        </Paragraph>
        <StyledUnorderedList>
          <StyledList>
            <strong>Customer Service</strong>: We offer international customer
            service and are available for you around the clock. Whenever you
            share your details with our Service employees, you allow us to reply
            to you whenever this is necessary. This includes answering any
            questions you may have regarding your user account - or also
            answering other questions.
            <StyledCircleUnorderedList>
              <StyledList>
                <strong>User Account</strong>: As a representative of a Premise,
                you are asked to create a user account on our web portal. We use
                this information to administer this account, and you can use
                different useful functions. As part of the user account, your
                name, e-mail address and function in the Premises as well as
                your permissions are stored. Based on the user account, we log
                user activity and evaluate the activity logs for security
                purposes. Especially when accessing credit card data, each
                access is logged and evaluated in case of suspected illegal use
                as well as in random samples in order to detect criminal and
                illegal uses. The data processing is in our legitimate interest
                in accordance with Art. 6 para. 1 lit. f{')'} GDPR.
              </StyledList>
            </StyledCircleUnorderedList>
          </StyledList>
          <StyledList>
            <strong>Other Communication</strong>: we may possibly contact you
            via e-mail, mail, telephone, or text message, depending on which
            contact information you have shared with us. We will also process
            the communication you have shared with us. This may be based on the
            following reasons:
            <StyledCircleUnorderedList>
              <StyledList>
                Answering and processing of all requests made by you. We may
                possibly send you other messages of an administrative nature,
                including security information.
              </StyledList>
            </StyledCircleUnorderedList>
          </StyledList>
          <StyledList>
            <strong>Ensuring Secure and Reliable Service</strong>: To create a
            reliable environment for you, other travellers, the business
            partners of HRS, and our Premises partners, we may use personal data
            to detect and prevent fraud and other illegal and/or undesired
            activities. We may additionally use personal data for risk
            assessment and security purposes - including authentication of
            users.
          </StyledList>
          <StyledList>
            <strong>Improvement of our Service</strong>: We also use personal
            data for analytic purposes. This not only serves to improve our
            service and optimize the experience of users, but may also be
            carried out for testing purposes, troubleshooting, and improvement
            of the functionalities and quality of our online service. In this
            respect, it is our goal to optimize our online platform and
            customize it according to your needs and thereby make our web pages
            and apps even more user-friendly and attractive.
          </StyledList>
          <StyledList>
            Whenever you call the HRS Customer Service, the phone call may be
            monitored or recorded for training purposes or quality management.
            Recorded calls are stored for a limited period of time and will be
            automatically deleted as long as HRS has no justified interest to
            keep such recording for a longer period of time (in HRS’ reasonable
            discretion), for example within the scope of the detection of
            fraudulent activities. All call records are processed pursuant to
            these data privacy rules.
          </StyledList>
          <StyledList>
            <strong>Legal Purposes</strong>: In certain cases, we may use your
            data for the handling and resolution of legal conflicts or use the
            same within the scope of investigations and to ensure conformity
            with the compliance guidelines. We may also use your data to enforce
            the terms of use of the online booking service of HRS in our
            reasonable discretion.
          </StyledList>
        </StyledUnorderedList>
      </Section>
      <Section>
        <Title>
          Processing of your data as described is supported by the following
          legal bases
        </Title>
        <StyledUnorderedList>
          <StyledList>
            Contractual Performance: The use of your data may be necessary to
            perform the contract into which you enter with us. For example, as
            long as you use our service to make and receive online bookings, we
            will use your data to comply with our duty to complete and carry out
            the booking according to our mutual agreement.
          </StyledList>
          <StyledList>
            Legitimate Interests: We may use your data for our legitimate
            business interests. This allows us to provide you with the most
            personalized content on the website & apps, in e-mails, and in
            newsletters or optimize and promote our products and services as
            well as the contents on our website; in addition, this may serve for
            administrative or legal purposes as well as the detection of
            fraudulent activities.
          </StyledList>
          <StyledList>
            Consent: Under certain circumstances, your consent to use your
            personal data for certain direct marketing activities is presumed.
            You are entitled to withdraw your consent at any time by contacting
            us at the address at the top of this data privacy notice.
          </StyledList>
        </StyledUnorderedList>
      </Section>
      <Section>
        <Title>How long does HRS keep your personal data on file?</Title>
        <Paragraph>
          {' '}
          We only keep your data as long as the data privacy-related purpose for
          retaining those continues to exist. For example, for the performance
          of bookings of Premises, this means that your data are deleted as soon
          as your Premises are not a partner of HRS anymore– of course, only as
          long as these data are not subject to longer compulsory retention
          periods by law (for example, for tax purposes). If this is the case,
          we keep the data on file until the end of the corresponding retention
          periods.
        </Paragraph>
        <Paragraph>
          In all cases, information regarding your computer, which is necessary
          to ensure IT security (compare above), is also only kept on file as
          long as this is required for the above-mentioned purpose – meaning
          that, if data of use are only required for the respective session,
          they are automatically deleted as soon as you close your browser
          and/or leave our web pages.
        </Paragraph>
        <Paragraph>
          In case you granted your consent to any more comprehensive data
          processing (compare above), we will basically only store your data
          until you withdraw your consent. The e-mail address{' '}
          <StyledExternalLink
            href={`mailto:${HRS_DATENSCHUTZ_EMAIL}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            {HRS_DATENSCHUTZ_EMAIL}
          </StyledExternalLink>{' '}
          may also be used for such purpose.
        </Paragraph>
      </Section>
      <Section>
        <Container>
          <Title>How does HRS share your data with third parties?</Title>
          <Paragraph>
            The HRS services involve different third parties in different
            manners and for different reasons. They are parties who assist HRS
            with different tasks, such as financial institutions, advertising
            companies, or authorities in some cases. Your personal data will not
            be transferred to areas outside of the European Union and/or the
            European Economic Area. Click the link below to find out more about
            how this information that we receive from you is used and shared
            with these parties.
          </Paragraph>
        </Container>
        <Container>
          <SubTitle>In detail:</SubTitle>
          <Title>Third-Party Service Providers</Title>
          <Paragraph>
            We may use service providers to process your personal data on our
            behalf. This may serve different purposes, such as, for example, the
            sending of promotional contents as well as administration and
            support. All third-party service providers are bound by data
            processing agreements and are only authorized to process your
            personal data exclusively for the aforementioned purposes.
          </Paragraph>
        </Container>
        <Container>
          <Title>Competent Authorities</Title>
          <Paragraph>
            We disclose personal data to enforce the law as long as this is
            required by law or necessary for the prevention, detection, or legal
            prosecution of criminal acts or fraud. In addition, we may share
            personal data with the competent authorities under certain
            circumstances to protect our rights and Premises or the rights and
            Premises of our business partners.
          </Paragraph>
        </Container>
        <Container>
          <Title>Business Partners</Title>
          <Paragraph>
            We cooperate with business partners throughout the entire world.
            Some of these business partners have been retained to market and/or
            promote our services and assist our business partners during the
            marketing and promotion of their services. Business partners may
            also include the HRS Group of which HRS is a part. This may mean
            that such business partners’ services are integrated in our website
            and apps that business partners have the option to display an
            individual offer on our page, or that the online booking service of
            HRS is integrated on the business partner’s website and/or apps.
          </Paragraph>
          <Paragraph>
            Whenever you make a booking via the web pages or apps of our
            business partners, certain personal data provided to them by you are
            forwarded to us. As long as the business partner provides customer
            service, HRS will share booking-relevant data with the business
            partners (as needed) to offer you the best possible and most
            appropriate service. Whenever you make a booking via the web pages
            of our business partners, the business partners may receive certain
            elements of your personal data which are relevant for the respective
            booking. This serves their internal purposes (including analytic
            purposes) and, whenever you request this, the implementation of
            marketing actions.
          </Paragraph>
          <Paragraph>
            Whenever you make a booking on the website of a business partner,
            please familiarize yourself with the data privacy rules of such
            business partner to find out how such business partner may process
            your personal data. Whenever we offer you other products and/or
            services, your data will be shared with the business partner
            offering such products and/or services under certain circumstances
            to process or carry out your order. To detect and prevent fraud, we
            may share information regarding our users with business partners, as
            long as this is indispensable for such purpose.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>
            What security measures does HRS take to protect your personal data?
          </Title>
          <Paragraph>
            According to the European data protection laws, we undertake
            reasonable measures to ensure the protection of your personal data
            against abuse and unauthorized access.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            According to the European data protection laws, we implement
            reasonable procedures to ensure the protection of your personal data
            against abuse and unauthorized access.
          </Paragraph>
          <Paragraph>
            To protect your personal data, we use the respective business
            systems and processes. Additionally, we use security procedures as
            well as technical and access restrictions whenever our servers
            access and use your personal data. Only authorized employees have
            access to your personal data for proper performance of their tasks.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>How does HRS handle personal data of children?</Title>
          <Paragraph>
            HRS is a service whose use is permitted for people at least 18 years
            of age. We exclusively process information about children based on
            the consent of their parents or legal representatives.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            The services of HRS are not intended for children less than 18 years
            of age. Our services may only be used with the valid consent of one
            parent or a legal representative. In the event that data of children
            less than 18 years of age are transmitted to us, we reserve the
            right to delete such data. In certain cases in connection with a
            booking or the purchase of other services or under exceptional
            circumstances (e.g. functions intended for families), HRS is
            entitled to collect and use data of children exclusively with the
            consent of the parent or legal representative.
          </Paragraph>
        </Container>
      </Section>
      <Section>
        <Container>
          <Title>
            Which rights do you have regarding personal data transmitted to HRS
            by you?
          </Title>
          <Paragraph>
            You are at all times entitled to request information regarding the
            personal data which we have collected about you. You may request a
            summary of your personal data by sending such a request to the
            website{' '}
            <StyledExternalLink href={`${HRS_GDPR_URL}`} target='_blank'>
              {HRS_GDPR_URL}
            </StyledExternalLink>
            . Please fill in any and all information requested in the form so
            that we can process your request.
          </Paragraph>
          <Paragraph>
            Please note that – in case you do use contact forms – we collect,
            handle and store the personal information that you provide via such
            contact form for the purpose of processing your request.
          </Paragraph>
          <Paragraph>
            Under special circumstances, e.g. in the case of a dispute regarding
            the legality of processing, you have an additional right to request
            the restricted processing of personal data. Furthermore, you are
            entitled to request the access to your personal data in a
            structured, common, and machine-readable format. Furthermore, under
            certain circumstances, you may be entitled at any time to object to
            any data processing on the basis of Article 6, Paragraph 1, letters
            e, f as well as for purposes of direct advertising. As long as you
            have given us your consent for a more comprehensive processing
            (compare above), you may withdraw this consent at any time – e.g. by
            sending a message to the above-specified e-mail address.
          </Paragraph>
          <Paragraph>
            Finally, you are entitled to file a complaint with the competent
            data protection authority in case of any disagreements.
          </Paragraph>
        </Container>
        <Container>
          <Title>In detail:</Title>
          <Paragraph>
            You are at all times entitled to view your personal data which are
            stored by us. To receive an overview of your personal data, please
            request a summary of your personal data by sending such a request to
            the{' '}
            <StyledExternalLink href={`${HRS_GDPR_URL}`} target='_blank'>
              website
            </StyledExternalLink>
            . Please fill in any and all information requested in the form so
            that we can process your request.
          </Paragraph>
          <Paragraph>
            You may also contact us if you believe that your personal data
            stored by us are incorrect or as long as you believe that we are no
            longer entitled to have your personal information under the present
            data privacy rules. Please request a summary of your personal data
            by sending such a request to the website{' '}
            <StyledExternalLink href={`${HRS_GDPR_URL}`} target='_blank'>
              {HRS_GDPR_URL}
            </StyledExternalLink>
            . Please fill in any and all information requested in the form so
            that we can process your request. We will process your request
            according to the applicable data protection laws.
          </Paragraph>
          <Paragraph>
            You can delete your user account at any time by logging into your
            account on the HRS website or apps and selecting the option to
            delete your account.
          </Paragraph>
        </Container>
        <Container>
          <Title>Rules Regarding Cookies</Title>
          <Paragraph>
            Cookies and other tracking technologies may be used on our web pages
            and in our apps in many different ways. For example, they ensure
            that the HRS web pages are operational, they analyze traffic, or
            they are used for advertising purposes. These technologies are
            either used directly by us or by our business partners, including
            third-party service providers and advertisers who work with us. For
            more information regarding cookies, their use, and your options in
            this respect, please click{' '}
            <StyledExternalLink
              href='https://www.hrs.de/web3/showCmsPage.do?clientId=ZW5fX05FWFQ-&cid=44-4&pageId=legals'
              target='_blank'
            >
              here
            </StyledExternalLink>
            .
          </Paragraph>
        </Container>
        <Container>
          <Title>Google Maps</Title>
          <Paragraph>
            We include a map into our website for the purpose of visualization
            provided by Google Inc., 1600 Amphitheatre Parkway, Mountain View,
            CA 94043, USA via the Google Maps API. If you have the map
            displayed, a connection to the Google Servers is being made under
            which the IP-Address is transferred to Google. Google can analyze
            the use of the Google Maps functionality by the visitor. In
            addition, Google can write and read Cookies. These Cookies can be
            Google User Cookies which are directly connected to your identity.
            We are using these on the basis of our legitimate interest according
            to Art. 6 Abs. 1 lit. GDPR. Further information can be found in the
            Google privacy documents which can be found{' '}
            <StyledExternalLink href={`${POLICIES_GOOGLE_URL}`} target='_blank'>
              here
            </StyledExternalLink>
            .
          </Paragraph>
        </Container>
      </Section>
      <Title>Log Files</Title>
      <Paragraph>
        Whenever you access the HRS web pages or apps, user data are transmitted
        by the respective Internet browser and stored in log files, the
        so-called server log files. The datasets which are stored in the process
        contain the following data: date and time of the visit, name of the page
        visited, IP address, referrer URL (original URL from which you accessed
        the website), data volume transmitted, as well as product and
        information on the browser version used.
      </Paragraph>
      <Paragraph>
        The IP addresses of users are deleted or anonymized after completion of
        use. In the case of anonymization, IP addresses are modified in such a
        manner that individual information regarding personal or factual
        circumstances can no longer be associated with a specific natural person
        or a natural person which can be determined or where such an association
        would require an unreasonable amount of time, costs, and effort.
      </Paragraph>
      <Paragraph>
        We analyze these logfile data sets in an anonymized manner to further
        improve our offering and the web pages and apps, and make them more
        user-friendly, to find and correct errors more quickly, as well as
        manage server capacities. For example, we may determine at what time use
        of the HRS web pages and apps is particularly popular and provide a data
        volume to ensure the fastest possible booking process for you. In
        addition, by analyzing the log files, we can also speed up the detection
        and correction of any errors of the web pages or apps.
      </Paragraph>
    </Content>
  );
};

export default TextContent;

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import LocalStorageService from 'infra/common/localStorage.service';
import {ELocale} from '../types/locale';
import enUkJSON from 'locale/en-uk.json';
import enUsJSON from 'locale/en-us.json';
import deDeJSON from 'locale/de-de.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      [ELocale.EN_UK]: {...enUkJSON},
      [ELocale.EN_GB]: {...enUkJSON},
      [ELocale.EN_US]: {...enUsJSON},
      [ELocale.DE_DE]: {...deDeJSON},
    },
    lng: LocalStorageService.getByKey('i18nextLng') || ELocale.EN_UK,
    pluralSeparator: '_',
  });

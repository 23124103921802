import {EResourcesCode} from 'types/dto/IExtras.type';

// export const CODE_TO_EXTRA_NAME: Partial<Record<EResourcesCode, string>> = {
export const CODE_TO_EXTRA_NAME: Record<EResourcesCode, string> = {
  [EResourcesCode.WHITEBOARD]: 'Whiteboard',
  [EResourcesCode.VIDEO_CONFERENCING_EQUIPMENT]: 'Video conferencing equipment',
  [EResourcesCode.MICROPHONE]: 'Handheld wireless microphone',
  [EResourcesCode.PENS_AND_PAPERS]: 'Pens & paper',
  [EResourcesCode.PROJECTOR]: 'Projector',
  [EResourcesCode.OFFICE_SPEAKERPHONES]: 'Speaker for laptop/PC',
  [EResourcesCode.DOCKING_STATION]: 'Docking station',
  [EResourcesCode.MOUSE]: 'Mouse',
  [EResourcesCode.KEYWORD]: 'Keyboard',
  [EResourcesCode.FLIP_CHART]: 'Flip chart',
  [EResourcesCode.PRINTER]: 'Printer',
  [EResourcesCode.COMPUTERS]: 'Computers',
  [EResourcesCode.SCANNER]: 'Scanner',
  [EResourcesCode.UNLIMITED_DRINKING_WATER]: 'Unlimited drinking water',
  [EResourcesCode.UNLIMITED_COFFEE_AND_TEA]: 'Unlimited coffee and tea',
  [EResourcesCode.BREAKFAST]: 'Breakfast',
  [EResourcesCode.LUNCH_STANDARD]: 'Lunch standard',
  [EResourcesCode.DRINKS]: 'Drinks',
  [EResourcesCode.LUNCH]: 'Lunch',
  [EResourcesCode.SNACKS]: 'Snacks',
  [EResourcesCode.COFFEE_AND_SNACKS]: 'Coffee and snacks break',
  [EResourcesCode.LUNCH_MENU_OR_BUFFET]:
    'Lunch as 3-course menu or buffet including one soft drink',
  [EResourcesCode.UNLIMITED_CONFERENCE_DRINKS_HALF_DAY]:
    'Unlimited conference drinks (half day)',
  [EResourcesCode.UNLIMITED_CONFERENCE_DRINKS_FULL_DAY]:
    'Unlimited conference drinks (full day)',
  [EResourcesCode.DINNER_OR_BUFFET]:
    'Dinner including one soft drink or one glass of wine/beer',
  [EResourcesCode.WELCOME_COFFEE]: 'Welcome coffee',
  [EResourcesCode.SCREEN_FOR_VIDEO_PROJECTION]: 'Screen for video projection',
  [EResourcesCode.WIFI]: 'WiFi',
  [EResourcesCode.SOUND_SYSTEM]: 'Sound system',
  [EResourcesCode.HANDED_WIRED_MICROPHONE]: 'Handheld wired microphone',
  [EResourcesCode.CLIP_MICROPHONE]: 'Clip microphone',
  [EResourcesCode.LECTERN]: 'Lectern',
  [EResourcesCode.PINBOARD]: 'Pinboard',
  [EResourcesCode.MONITOR]: 'Monitor',
  [EResourcesCode.SINGLE_BEDROOM]: 'Single use guest rooms (no breakfast)',
  [EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST]:
    'Single use guest rooms (with breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM]: 'Double use guest rooms (no breakfast)',
  [EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST]:
    'Double use guest rooms (with breakfast)',
};

import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {getColorFromProps, TColorProps} from '../Typography/Typography';
import {
  blackColor,
  fontSizeXSm,
  NW2Secondary,
  whiteColor,
  NW2Gray300Color,
  NW2Gray100Color,
  fontWeightExtraBold,
  lineHeightSm,
  borderRadiusLg,
  offsetDef,
  offsetNone,
} from 'constants/styleVars';

const BUTTON_WIDTH = '100px';
const BUTTON_HEIGHT = '76px';
const ICON_OFFSET = '6px';

type TActionButton = {
  invertIcon?: boolean;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  fontSize?: number;
  height?: number;
} & TColorProps;

const BaseActionButton = styled.button<TActionButton>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${offsetNone} ${offsetDef};
  cursor: pointer;
  box-sizing: border-box;
  min-width: ${BUTTON_WIDTH};
  height: ${({height}) => (height ? `${height}px` : `${BUTTON_HEIGHT}`)};
  font-size: ${({fontSize}) => (fontSize ? `${fontSize}px` : `${fontSizeXSm}`)};
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  transition: background-color 0.25s, color 0.25s, border-color 0.25s;
  border-radius: ${borderRadiusLg};
  line-height: ${lineHeightSm};
  background-color: transparent;
  color: ${blackColor};
  border: 1px solid ${blackColor};

  ${({invertIcon}) => invertIcon && `flex-direction: column-reverse;`}

  ${(props) =>
    props &&
    css`
      border-color: ${getColorFromProps(props)};
      color: ${getColorFromProps(props)};

      svg path.styled-stroke,
      svg circle.styled-stroke {
        stroke: currentColor;
      }
    `}

  &:hover {
    background-color: ${blackColor};
    color: ${whiteColor};
    border-color: ${blackColor};
  }

  &:active {
    background-color: ${NW2Secondary};
    border-color: ${NW2Secondary};
    color: ${blackColor};
  }

  &:disabled,
  &.disabled {
    background-color: ${NW2Gray100Color};
    border-color: ${NW2Gray100Color};
    color: ${NW2Gray300Color};
  }

  ${({disabled}) =>
    disabled &&
    css`
      background-color: ${NW2Gray100Color};
      border-color: ${NW2Gray100Color};
      color: ${NW2Gray300Color};
      pointer-events: none;
      cursor: not-allowed;
    `}

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({loading}) =>
    loading &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}

  > div {
    pointer-events: none;
  }
`;

type TProps = {
  title: string;
  fontSize?: number;
  height?: number;
  icon?: string;
  iconSize?: number;
  invertIcon?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  children?: ReactNode;
  onClick?: () => void;
} & TColorProps;

const Title = styled.span`
  margin-top: ${ICON_OFFSET};
`;

function NW2ActionButton({
  title = 'action button',
  icon,
  iconSize,
  ...restProps
}: TProps) {
  const content = (
    <>
      {icon && <Icon transparent icon={icon} size={iconSize} />}
      <Title>{title}</Title>
    </>
  );

  return <BaseActionButton {...restProps}>{content}</BaseActionButton>;
}

export default NW2ActionButton;

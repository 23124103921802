import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import queryString from 'query-string';
import {useNavigate} from 'react-router-dom';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';

import {setSpaceDetailsId} from 'store/venues/venuesSlice';
import {Routes} from 'constants/routes';
import {
  fontSizeMd,
  fontSizeSm,
  fontWeightBold,
  lineHeightLg,
  mdBp,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  pagePadding,
  xLgBp,
} from 'constants/styleVars';
import {ERoomType} from 'types/dto/ERoomType.type';
// import {filterUnitsBySeatingPlan} from '../../SpacesListBlock/helpers';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

export const Container = styled.div<{isContent?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${({isContent}) => (isContent ? pagePadding : 0)};

  @media (min-width: ${mdBp}px) {
    justify-content: initial;
  }
`;

const TextBlock = styled.div<{moreVenuesQty: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${({moreVenuesQty}) => (moreVenuesQty ? offsetXLg : 0)};
`;

const TopText = styled.div`
  font-size: ${fontSizeMd};
  color: ${NW2Gray900Color};

  @media (min-width: ${mdBp}px) {
    font-size: ${fontSizeSm};
  }

  @media (min-width: ${xLgBp}px) {
    font-size: ${fontSizeMd};
  }
`;

const BottomText = styled(TopText)`
  font-weight: ${fontWeightBold};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${mdBp}px) {
    justify-content: space-between;
    flex-grow: 1;
  }
`;

const Arrows = styled.div`
  display: flex;
  align-items: center;
`;

const GoToSearchButton = styled.span`
  display: inline-block;
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  text-decoration-line: underline;
  cursor: pointer;

  @media (min-width: ${mdBp}px) {
    font-size: ${fontSizeSm};
  }

  @media (min-width: ${xLgBp}px) {
    font-size: ${fontSizeMd};
  }
`;

const RightIcon = styled(Icon)`
  margin-left: ${offsetDef};
`;

const RightButton = styled(NW2Button)`
  margin-left: ${offsetDef};
`;

type TProps = {
  roomType: ERoomType;
  isTextShowed: boolean;
  className?: string; // needed by styled components
};

export function NW2SpaceDetailsSwitch({
  className,
  // roomType,
  isTextShowed,
}: TProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const spaceDetailsId: number | null = useSelector((state) =>
    _get(state, 'venuesReducer.spaceDetailsId'),
  );
  // const seatingPlan: ERoomSchemaNames = useSelector((state) =>
  //   _get(state, 'venuesReducer.selectedMeetingRoomSeatingPlan'),
  // );
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);

  const {venueUnits} = useVenueDetailsData();

  const {multiRooms: isMultiRooms} = searchCriteria;

  const arrowIconSize = isDesktop ? 36 : 50;

  const unitIds = useMemo(() => {
    // if multi room we need to get only uniq units
    const unitsByType = isMultiRooms
      ? venueUnits.filter(
          (el, index, self) =>
            self.findIndex((selfEl) => selfEl.id === el.id) === index,
        )
      : venueUnits;

    // const units =
    //   roomType === ERoomType.MEETING_ROOM
    //     ? filterUnitsBySeatingPlan(unitsByType, seatingPlan)
    //     : unitsByType;

    return unitsByType.map((unit) => unit.id);
  }, [isMultiRooms, venueUnits]);

  const moreVenuesQty = unitIds.length - 1;

  const isTextBlockShowed =
    (isTextShowed || !moreVenuesQty) && !isMultiRooms && isDesktop;

  const currentIndex = unitIds.findIndex(
    (item: number) => item === spaceDetailsId,
  );
  const previousId = unitIds[currentIndex - 1];
  const nextId = unitIds[currentIndex + 1];

  const goToPrevUnit = () => {
    if (!previousId) return;
    dispatch(setSpaceDetailsId(previousId));
  };

  const goToNextUnit = () => {
    if (!nextId) return;
    dispatch(setSpaceDetailsId(nextId));
  };

  const goToSearchResults = () => {
    navigate({
      pathname: Routes.venuesList,
      search: queryString.stringify({
        ...searchCriteria,
      }),
    });
  };

  return (
    <Container
      className={className}
      isContent={isTextBlockShowed || !!moreVenuesQty}
    >
      {isTextBlockShowed && (
        <TextBlock moreVenuesQty={!!moreVenuesQty}>
          <TopText>Not quite right?</TopText>
          {moreVenuesQty ? (
            <BottomText>{`Check ${moreVenuesQty} more!`}</BottomText>
          ) : (
            <GoToSearchButton onClick={goToSearchResults}>
              Back to search results
            </GoToSearchButton>
          )}
        </TextBlock>
      )}
      <Buttons>
        {!!moreVenuesQty && (
          <Arrows>
            {isDesktop && isMultiRooms ? (
              <>
                <NW2Button onClick={goToPrevUnit} disabled={!previousId}>
                  Previous
                </NW2Button>
                <RightButton onClick={goToNextUnit} disabled={!nextId}>
                  Next space
                </RightButton>
              </>
            ) : (
              <>
                <Icon
                  size={arrowIconSize}
                  icon='LEFT_ARROW_CIRCLED'
                  disabled={!previousId}
                  onClick={goToPrevUnit}
                />
                <RightIcon
                  size={arrowIconSize}
                  icon='RIGHT_ARROW_CIRCLED'
                  disabled={!nextId}
                  onClick={goToNextUnit}
                />
              </>
            )}
          </Arrows>
        )}
      </Buttons>
    </Container>
  );
}

export default NW2SpaceDetailsSwitch;

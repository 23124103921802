import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Field} from 'react-final-form';
import {notification} from 'antd';
import _get from 'lodash/get';

import {useAppDispatch} from 'store/hooks';
import NW2FormTextarea from 'view/components/NW2FormItem/components/Textarea';
import ModalOverlay from 'view/components/ModalOverlay/ModalOverlay';
import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import ErrorMessage from 'view/components/NW2FormItem/components/ErrorMessage';

import {getPublicVenueById, setNmmSpace} from 'store/venue/actions';
import {FormWizard, FormWizardPage} from 'view/components/NW2Forms';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {
  descriptionFieldRules,
  imagesFieldRules,
  spaceNameFieldRules,
} from 'utils/finalFormFieldRules';

import {ERoomType} from 'types/dto/ERoomType.type';
import {
  EAddDeskFormFields,
  EAddMeetingRoomFormFields,
  IAddSpaceProps,
  TAddDeskFormValues,
} from './types';
import {
  AmenitiesContainer,
  Header,
  Main,
  PageContent,
  PageImagesUploadInputWrapper,
  Wrapper,
} from './styles';
import {useSortedUnitAmenities} from '../NMMSpaces/common/hooks/useSortedUnitAmenities';
import {getSortedCommonAmenities} from '../NMMSpaces/common/utils/getSortedCommonAmenities';
import {
  AddDeskCount,
  AddDeskPrice,
  AddFloor,
  AddImages,
  AddDeskType,
} from './components';
import {EAmenityType} from 'types/amenities';
import {removeFileExtension} from 'utils/stringUtils';
import {getCorporateOffices} from 'store/corporateOffice/apiActions';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {EMPTY_ARRAY} from 'constants/app';
import {getNumberFromFormatString} from 'utils/helpers';
import {useAddSpaceHandlers} from './hooks/useAddSpaceHandlers';

export function AddDesk({
  venueId,
  spaceFloor,
  isExternalVenue,
  isShowed,
  onToggleShow,
  venueFloors,
  commonAmenities,
  spaceAmenities,
}: IAddSpaceProps) {
  const dispatch = useAppDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const currency = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const {
    corporateAccountId,
    filesState,
    page,
    skippedPages,
    handleClose,
    handleSetFiles,
    handleSetPage,
    handleSetSkippedPages,
  } = useAddSpaceHandlers({onToggleShow});

  const onSubmit = useCallback(
    (values: TAddDeskFormValues) => {
      setSubmitting(true);

      const amenities: number[] = [];

      if (!skippedPages.includes(EAddDeskFormFields.AMENITIES_ACCESSIBILITY)) {
        amenities.push(...values[EAddDeskFormFields.AMENITIES_ACCESSIBILITY]);
      }

      if (!skippedPages.includes(EAddDeskFormFields.AMENITIES_EQUIPMENT)) {
        amenities.push(...values[EAddDeskFormFields.AMENITIES_EQUIPMENT]);
      }

      const commonData = {
        floor: +values[EAddDeskFormFields.FLOOR],
        name: values[EAddDeskFormFields.NAME],
        description: values[EAddDeskFormFields.DESCRIPTION],
        amenities: amenities.map((item) => ({id: item})),
        capacity: +values[EAddDeskFormFields.CAPACITY],
        type: values[EAddDeskFormFields.TYPE],
        roomType: ERoomType.WORK_SPACE,
        coverImageName: removeFileExtension(
          values[EAddDeskFormFields.COVER_IMAGE],
        ),
        imageFiles: values[EAddDeskFormFields.IMAGES],
      };

      const publicVenueData = {
        priceHour: getNumberFromFormatString(values.priceHour, currency),
        priceDay: getNumberFromFormatString(values.priceDay, currency),
        isRfp: values.isRfp === 'true',
      };

      const data = isExternalVenue
        ? {...commonData, ...publicVenueData}
        : commonData;

      dispatch(
        setNmmSpace({
          id: venueId,
          data,
          onSuccessHandler: () => {
            setSubmitting(false);

            if (isExternalVenue) {
              dispatch(getPublicVenueById(venueId));
            } else {
              dispatch(getCorporateOffices(corporateAccountId, false));
            }

            notification.success({
              message: `The space ${
                values[EAddDeskFormFields.NAME]
              } has been successfully created`,
              duration: 4,
            });

            handleClose();
          },
          onErrorHandler: () => {
            setSubmitting(false);
          },
        }),
      );
    },
    [
      corporateAccountId,
      currency,
      dispatch,
      handleClose,
      isExternalVenue,
      skippedPages,
      venueId,
    ],
  );

  // amenities
  const amenitiesConditionsAndAccessibility = useSortedUnitAmenities({
    spaceAmenities,
    items: [
      EAmenityType.CORPORATE_OFFICE_WORK_DESK,
      EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
    ],
    fieldName: EAddDeskFormFields.AMENITIES_ACCESSIBILITY,
    isTitleShowed: true,
    roomType: ERoomType.WORK_SPACE,
  });

  const amenitiesEquipment = useSortedUnitAmenities({
    spaceAmenities,
    items: [EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT],
    fieldName: EAddDeskFormFields.AMENITIES_EQUIPMENT,
    roomType: ERoomType.WORK_SPACE,
    chunkSize: 5,
  });

  // Initial values for amenities
  const filteredAmenities =
    commonAmenities.find((room) => room.type === ERoomType.WORK_SPACE)
      ?.allUnitsIncluded || EMPTY_ARRAY;

  // initial values
  const initialValues: TAddDeskFormValues = useMemo(
    () => ({
      [EAddDeskFormFields.NAME]: '',
      [EAddDeskFormFields.IMAGES]: [],
      [EAddDeskFormFields.DESCRIPTION]: '',
      [EAddDeskFormFields.FLOOR]: String(venueFloors[0]),
      [EAddDeskFormFields.TYPE]: ERoomType.SHARED_SPACE,
      [EAddDeskFormFields.AMENITIES_ACCESSIBILITY]: getSortedCommonAmenities({
        commonAmenities: filteredAmenities,
        categories: [
          EAmenityType.CORPORATE_OFFICE_WORK_DESK,
          EAmenityType.CORPORATE_OFFICE_WORK_DESK_ACCESSIBILITY,
        ],
      }),
      [EAddDeskFormFields.AMENITIES_EQUIPMENT]: getSortedCommonAmenities({
        commonAmenities: filteredAmenities,
        categories: [EAmenityType.CORPORATE_OFFICE_WORK_DESK_EQUIPMENT],
      }),
      [EAddDeskFormFields.CAPACITY]: '',
      [EAddDeskFormFields.PRICE_HOUR]: '',
      [EAddDeskFormFields.PRICE_DAY]: '',
      [EAddDeskFormFields.COVER_IMAGE]: '',
      [EAddDeskFormFields.RFP]: 'false',
    }),
    [filteredAmenities, venueFloors],
  );

  const stepPages = useMemo(() => {
    const pages = [
      {
        title: 'Which floor is this space on?',
        fieldName: EAddDeskFormFields.FLOOR,
        onBackClick: handleClose,
        content: (
          <PageContent width={150}>
            <AddFloor
              fieldName={EAddMeetingRoomFormFields.FLOOR}
              venueFloors={venueFloors}
              spaceFloor={
                typeof spaceFloor === 'number' ? spaceFloor : venueFloors[0]
              }
            />
          </PageContent>
        ),
      },
      {
        title: 'What is this space called?',
        fieldName: EAddDeskFormFields.NAME,
        content: (
          <PageContent width={284}>
            <NW2FormItemInput
              name={EAddDeskFormFields.NAME}
              type='text'
              label='Space name'
              placeholder='Space name'
              rules={spaceNameFieldRules}
              showAllValidationErrors
            />
          </PageContent>
        ),
      },
      {
        title: 'What does this space look like?',
        fieldName: EAddDeskFormFields.IMAGES,
        content: (
          <PageContent width={774}>
            <Field name={EAddDeskFormFields.IMAGES} validate={imagesFieldRules}>
              {({input, meta}) => {
                const hasError =
                  meta.error && (meta.touched || meta.submitFailed);

                return (
                  <>
                    <AddImages
                      fieldName={EAddMeetingRoomFormFields.IMAGES}
                      fieldCoverName={EAddMeetingRoomFormFields.COVER_IMAGE}
                      filesState={filesState}
                      handleSetFiles={handleSetFiles}
                      onChange={input.onChange}
                    />

                    {hasError && (
                      <PageImagesUploadInputWrapper>
                        <ErrorMessage
                          hasError={hasError}
                          errorData={meta.error}
                          showAllValidationErrors
                        />
                      </PageImagesUploadInputWrapper>
                    )}
                  </>
                );
              }}
            </Field>
          </PageContent>
        ),
      },
      {
        title: 'Tell bookers more about this space.',
        fieldName: EAddDeskFormFields.DESCRIPTION,
        content: (
          <PageContent width={442}>
            <NW2FormTextarea
              name={EAddDeskFormFields.DESCRIPTION}
              label='Space description'
              placeholder='Space description'
              rules={descriptionFieldRules}
              showAllValidationErrors
              minHeight={135}
            />
          </PageContent>
        ),
      },
      {
        title: 'What kind of space should bookers expect?',
        fieldName: EAddDeskFormFields.TYPE,
        content: (
          <PageContent width={950}>
            <AddDeskType />
          </PageContent>
        ),
      },
      {
        title: 'How many desks does this space have?',
        fieldName: EAddDeskFormFields.CAPACITY,
        content: (
          <PageContent width={280}>
            <AddDeskCount />
          </PageContent>
        ),
      },
      {
        title: 'What should organisers expect from this space?',
        fieldName: EAddDeskFormFields.AMENITIES_ACCESSIBILITY,
        content: (
          <PageContent width={473}>
            <AmenitiesContainer>
              {amenitiesConditionsAndAccessibility}
            </AmenitiesContainer>
          </PageContent>
        ),
      },
      {
        title: 'How much do you want to charge for this space?',
        fieldName: EAddDeskFormFields.PAID_DURATION,
        content: (
          <PageContent width={280}>
            <AddDeskPrice />
          </PageContent>
        ),
      },
      {
        title: 'Is there anything else that is included in this offer?',
        fieldName: EAddDeskFormFields.AMENITIES_EQUIPMENT,
        content: <PageContent width={444}>{amenitiesEquipment}</PageContent>,
      },
    ];

    return isExternalVenue
      ? pages
      : pages.filter(
          ({fieldName}) =>
            fieldName !== EAddDeskFormFields.PAID_DURATION &&
            fieldName !== EAddDeskFormFields.RFP,
        );
  }, [
    amenitiesConditionsAndAccessibility,
    amenitiesEquipment,
    filesState,
    handleClose,
    handleSetFiles,
    isExternalVenue,
    spaceFloor,
    venueFloors,
  ]);

  if (!isShowed) return null;

  const pagesToSkip = isExternalVenue ? [7, 9] : [7, 8];

  return (
    <ModalOverlay>
      <Wrapper>
        <Header>
          <NW2Button
            buttonType='secondary'
            minimized
            size='small'
            onClick={handleClose}
            icon={<Icon transparent icon='NW2_MOVE_BACK_THIN' />}
          />
        </Header>

        <Main>
          <FormWizard
            page={page}
            handleSetPage={handleSetPage}
            initialValues={initialValues}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            pagesToSkip={pagesToSkip}
            handleSetSkippedPages={handleSetSkippedPages}
          >
            {stepPages.map(({content, ...rest}) => (
              <FormWizardPage key={rest.title} {...rest}>
                {content}
              </FormWizardPage>
            ))}
          </FormWizard>
        </Main>
      </Wrapper>
    </ModalOverlay>
  );
}

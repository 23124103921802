import React from 'react';

import SeatsSetupItem from './SeatsSetupItem';
import {NW2FormItemRadioButton} from 'view/components/NW2FormItem/NW2FormItem';

import {ERoomSchemaNames, ERoomSchema} from 'types/venue';

import {FormGroup} from 'view/components/NW2FormItem/NW2FormItem.styles';

const SeatsSetupGroup = () => {
  return (
    <FormGroup columnNumber={1} gap={16}>
      {Object.values(ERoomSchemaNames).map((roomSchema) => (
        <SeatsSetupItem
          key={roomSchema}
          type={roomSchema}
          radioButton={
            <NW2FormItemRadioButton
              name='seatsSetup'
              value={roomSchema}
              label={ERoomSchema[roomSchema]}
            />
          }
        />
      ))}
    </FormGroup>
  );
};

export default SeatsSetupGroup;

import styled, {css} from 'styled-components';

import {
  blackColor,
  whiteColor,
  offsetXSm,
  offsetSm,
  offsetXXSm,
  fontSizeXSm,
  lineHeightSm,
  fontSizeMd,
  lineHeightLg,
  fontWeightBold,
} from 'constants/styleVars';

const IMAGE_WIDTH = '100px';

export const Card = styled.div`
  width: 316px;
  background-color: ${whiteColor};
  padding: ${offsetXSm};
  border: 1px solid ${blackColor};
  border-radius: 10px;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const Row = styled.div<{marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({marginBottom}) => marginBottom && `${marginBottom}px`};
`;

export const AmenitiesRowContainer = styled(Row)`
  margin-top: ${offsetXXSm};
`;

export const ImageContainer = styled.div<{disabled?: boolean}>`
  position: relative;
  margin-right: ${offsetSm};
  height: ${IMAGE_WIDTH};
  width: ${IMAGE_WIDTH};

  ${({disabled}) =>
    disabled
      ? css`
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: linear-gradient(
              0,
              rgba(255, 255, 255, 0.68),
              rgba(255, 255, 255, 0.68)
            );
          }
        `
      : ''}
`;

export const VenueName = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  font-weight: bold;
  word-break: break-word;
`;

export const TotalPriceText = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  color: ${blackColor};
  white-space: nowrap;
  text-align: right;
`;

export const BookInstantlyContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: ${offsetXSm};
`;

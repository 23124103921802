import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Form, Field} from 'react-final-form';
import {FormApi} from 'final-form';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import NW2Button from 'view/components/NW2Button/NW2Button';
import Icon from 'view/components/Icon';
import Input from 'view/components/NW2FormItem/components/Input/Input';
import NW2InfoCard from 'view/common/NW2InfoCard';

import {ErrorMessage} from 'view/components/NW2FormItem/components';

import {isEmailValid} from 'infra/common/venueValidation';
import {updateBookingAttendeesAction} from 'store/customer/apiActions';

import {
  Actions,
  AlreadyInvited,
  AlreadyInvitedRow,
  AlreadyInvitedTitle,
  FormRow,
  InfoBlock,
  InvitesLeft,
  JustInvited,
  JustInvitedRow,
  NoInvites,
  ScrollArea,
  ScrollContainer,
  StyledButtonIcon,
  Wrapper,
} from './NW2BookingInviteAttendees.styles';

interface IProps {
  bookingOrderId?: string;
  capacity: number;
  onClose: () => void;
}

const FORM_FIELD_EMAIL = 'invite_attendee_email';

export function NW2BookingInviteAttendees({
  bookingOrderId,
  capacity,
  onClose,
}: IProps) {
  const dispatch = useAppDispatch();

  const bookVenueAttendees = useAppSelector(
    (state) =>
      state.customer.customerBookingOrder?.orderDays[0].unitBookings[0]
        .attendees || [],
  );

  const bookVenueAttendeesLoading = useAppSelector(
    ({customer}) => customer.isUpdateAttendeesLoading,
  );

  const initialInvited = useMemo(
    () => bookVenueAttendees.map((item) => item.email),
    [bookVenueAttendees],
  );

  const [justInvited, setJustInvited] = useState<string[]>([]);
  const [alreadyInvited, setAlreadyInvited] =
    useState<string[]>(initialInvited);

  const alreadyInvitedLength = alreadyInvited.length;
  const justInvitedLength = justInvited.length;

  const onSubmit = useCallback(
    (values: Record<string, string>, form: FormApi<Record<string, string>>) => {
      setJustInvited([...justInvited, values[FORM_FIELD_EMAIL]]);

      // reset form state
      form.blur(FORM_FIELD_EMAIL);
      form.resetFieldState(FORM_FIELD_EMAIL);
      form.reset();
    },
    [justInvited],
  );

  const maxAttendees = capacity - 1;
  const invitesLeft = maxAttendees - alreadyInvitedLength - justInvitedLength;

  const isActionsShowed: boolean =
    !!justInvitedLength || alreadyInvitedLength !== bookVenueAttendees.length;

  const handleRemoveJustInvited = (email: string) => {
    setJustInvited((prev) => prev.filter((item) => item !== email));
  };

  useEffect(() => {
    // move just invited to the already invited if store updated
    if (justInvited.length) {
      justInvited.forEach((item) => {
        if (initialInvited.includes(item)) {
          handleRemoveJustInvited(item);
          setAlreadyInvited((prev) => [...prev, item]);
        }
      });
    }
  }, [alreadyInvited, initialInvited, justInvited]);

  const handleApply = useCallback(() => {
    if (!bookingOrderId) return;
    dispatch(
      updateBookingAttendeesAction(bookingOrderId, [
        ...alreadyInvited,
        ...justInvited,
      ]),
    );
  }, [alreadyInvited, bookingOrderId, dispatch, justInvited]);

  const handleCancel = useCallback(() => {
    // reset all
    setJustInvited([]);
    setAlreadyInvited(initialInvited);
    onClose();
  }, [initialInvited, onClose]);

  const attendeesInviteFieldRules = useCallback(
    (value: string) => {
      const valueLength = value?.length || 0;
      const attendeesList = [...alreadyInvited, ...justInvited];

      if (attendeesList.includes(value)) {
        return 'This attendee has already been added';
      }

      if (!valueLength || (valueLength && !isEmailValid(value))) {
        return 'Incorrect email domain. Please check.';
      }

      return '';
    },
    [alreadyInvited, justInvited],
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const contentHeight = contentRef?.current?.clientHeight || 0;

  const isMaxReached: boolean = invitesLeft <= 0;

  // scroll area start
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(
    null,
  );
  useEffect(() => {
    if (scrollAreaRef?.current) {
      setScrollContainer(scrollAreaRef.current);
    }
  }, [justInvitedLength, alreadyInvitedLength, bookVenueAttendees.length]);

  const isHasScroll =
    Number(scrollContainer?.clientHeight) <
    Number(scrollContainer?.scrollHeight);
  // scroll area end

  const justInvitedSection = useMemo(() => {
    if (!justInvitedLength) return null;

    const onRemoveJustInvited = (email: string) => () => {
      handleRemoveJustInvited(email);
    };

    return (
      <JustInvited>
        {justInvited.map((email: string, idx) => (
          <JustInvitedRow key={email}>
            <Input
              name={`${FORM_FIELD_EMAIL} ${alreadyInvitedLength + idx + 1}`}
              type='email'
              label={`Attendee ${alreadyInvitedLength + idx + 1}`}
              hasError={false}
              inputProps={{readOnly: true, value: email}}
            />

            <StyledButtonIcon
              size='small'
              minimized
              onClick={onRemoveJustInvited(email)}
              icon={<Icon transparent icon='MINUS' />}
            />
          </JustInvitedRow>
        ))}
      </JustInvited>
    );
  }, [alreadyInvitedLength, justInvited, justInvitedLength]);

  const actionsSection = useMemo(() => {
    if (!isActionsShowed) return null;

    return (
      <Actions isHasScroll={isHasScroll}>
        <NW2Button onClick={handleCancel} size='small' inline>
          Cancel
        </NW2Button>
        <NW2Button
          onClick={handleApply}
          buttonType='primary'
          size='small'
          disabled={bookVenueAttendeesLoading}
          inline
        >
          Apply
        </NW2Button>
      </Actions>
    );
  }, [
    bookVenueAttendeesLoading,
    handleApply,
    handleCancel,
    isActionsShowed,
    isHasScroll,
  ]);

  const alreadyInvitedSection = useMemo(() => {
    const handleRemoveAlreadyInvited = (email: string) => () => {
      setAlreadyInvited((prev) => prev.filter((item) => item !== email));
    };

    return (
      <AlreadyInvited>
        <AlreadyInvitedTitle>Already invited</AlreadyInvitedTitle>
        {alreadyInvitedLength ? (
          alreadyInvited.map((email) => (
            <AlreadyInvitedRow key={email}>
              <div>{email}</div>
              <StyledButtonIcon
                size='small'
                minimized
                onClick={handleRemoveAlreadyInvited(email)}
                icon={<Icon transparent icon='MINUS' />}
              />
            </AlreadyInvitedRow>
          ))
        ) : (
          <NoInvites>No email invites sent yet.</NoInvites>
        )}
      </AlreadyInvited>
    );
  }, [alreadyInvited, alreadyInvitedLength]);

  return (
    <Wrapper ref={wrapperRef} hasActions={isActionsShowed}>
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, submitFailed}) => (
          <form onSubmit={handleSubmit}>
            <FormRow>
              <Field
                name={FORM_FIELD_EMAIL}
                validate={attendeesInviteFieldRules}
              >
                {({input, meta}) => {
                  const hasError =
                    (submitFailed && !!meta.error) ||
                    (!!meta.error && !meta.pristine && meta.touched);

                  return (
                    <div>
                      <Input
                        name={FORM_FIELD_EMAIL}
                        type='email'
                        label='Attendee email'
                        placeholder='Attendee email'
                        hasError={hasError}
                        value={input.value}
                        onChange={input.onChange}
                        inputProps={{...input, disabled: isMaxReached}}
                        disabled={isMaxReached}
                      />
                      <ErrorMessage
                        hasError={hasError}
                        errorData={meta.error}
                        showAllValidationErrors={false}
                      />
                    </div>
                  );
                }}
              </Field>

              <StyledButtonIcon
                type='submit'
                size='small'
                disabled={isMaxReached}
                minimized
                icon={<Icon transparent icon='PLUS' />}
              />
            </FormRow>
          </form>
        )}
      />

      <InvitesLeft isMaxReached={isMaxReached}>
        <span>Invites left</span>
        <b>{invitesLeft}</b>
      </InvitesLeft>

      <ScrollArea ref={scrollAreaRef}>
        <ScrollContainer
          ref={contentRef}
          maxHeight={isHasScroll ? `${contentHeight}px` : ''}
        >
          {isMaxReached && (
            <InfoBlock>
              <NW2InfoCard
                icon='TRIANGLE_WARN'
                title='Maximum number of attendees reached.'
              >
                This space does not allow for more attendees to be added. If
                your attendee list has changed please remove them from the list
                below to activate new attendee email input field.{' '}
              </NW2InfoCard>
            </InfoBlock>
          )}

          {justInvitedSection}

          {alreadyInvitedSection}
        </ScrollContainer>
      </ScrollArea>

      {actionsSection}
    </Wrapper>
  );
}

import React, {memo} from 'react';
import {
  NW2FormItemInput,
  NW2FormItemSelect,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  requiredFieldRules,
  deskCountFieldRules,
} from 'utils/finalFormFieldRules';

import {offsetXLg, NW2Gray600Color} from 'constants/styleVars';
import {ESpaceDetailsFormFields} from '../../types';
import {EDeskSpaceName} from 'types/venue';
import {ContentSubtitle, FormGroup} from '../../NMMSpacesDetails.styles';

const TypeAndCapacityFormItem = () => {
  const arrayOfTypes = [EDeskSpaceName.OPEN_SPACE, EDeskSpaceName.SHARED_SPACE];
  const typesOptions = arrayOfTypes.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));

  return (
    <FormGroup columnNumber={2} gap={parseInt(offsetXLg)}>
      <ContentSubtitle marginBottom='0'>Space type</ContentSubtitle>
      <ContentSubtitle marginBottom='0'>Capacity</ContentSubtitle>
      <NW2FormItemSelect
        name={ESpaceDetailsFormFields.TYPE}
        options={typesOptions}
        placeholder='Space'
        label='Space*'
        labelColor={NW2Gray600Color}
        rules={requiredFieldRules('space type')}
        data-testid={`input-space-details-${ESpaceDetailsFormFields.TYPE}`}
      />
      <NW2FormItemInput
        type='number'
        name={ESpaceDetailsFormFields.CAPACITY}
        placeholder='Number of desks'
        label='Number of desks*'
        labelColor={NW2Gray600Color}
        rules={deskCountFieldRules('number of desks')}
        data-testid={`input-space-details-${ESpaceDetailsFormFields.CAPACITY}`}
      />
    </FormGroup>
  );
};

export default memo(TypeAndCapacityFormItem);

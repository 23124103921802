export const getButtonType = (hasPriceItems: boolean) => {
  switch (true) {
    case hasPriceItems:
      return 'tertiary';
    default:
      return 'primary';
  }
};

export const getButtonSize = (isRoomSearch: boolean, isDesktop: boolean) => {
  switch (true) {
    case isRoomSearch && !isDesktop:
      return 'medium';
    default:
      return 'responsiveAdaptive';
  }
};

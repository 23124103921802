import styled from 'styled-components';
import {contentMaxWidthSm, offsetDef, offsetXLg} from 'constants/styleVars';
import React from 'react';

const Container = styled.div`
  max-width: ${contentMaxWidthSm};
  margin: 0 auto;
  padding: ${offsetXLg};
  text-align: center;
`;

const StyledHelpTextContainer = styled.div`
  text-align: left;
  display: grid;
  grid-gap: ${offsetDef};
  margin-top: ${offsetDef};
`;

const list = [
  {
    value: 'At least 8 characters',
  },
  {
    value:
      'Contain at least a number, an uppercase letter, and a lowercase letter',
  },
  {
    value: (
      <div>
        Contains at least one special character from below set:
        <br />
        {`= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \``}
      </div>
    ),
  },
];

const EndRegistrationSuccessModal = {
  title: 'Welcome to NewWork!',
  body: 'Your account has been successfully created. You can now book work spaces and have booking data stored in one place.',
  closeButtonText: 'Close',
};

const ChangePasswordSuccessModal = {
  title: 'password changed',
  body: 'Your password has been changed successfully!',
  closeButtonText: 'Close',
};

export {
  Container,
  StyledHelpTextContainer,
  list,
  EndRegistrationSuccessModal,
  ChangePasswordSuccessModal,
};

import React, {FC, useRef, useEffect} from 'react';
import {Input} from 'antd';

type Props = {
  google: any;
  onPlacesChangedHandler: any;
  value?: string;
  clearable?: boolean;
  onChangeHandler?: any;
  placeholder?: string;
  size?: any;
};

const GoogleMapSearchInput: FC<Props> = ({
  value,
  onChangeHandler,
  onPlacesChangedHandler,
  clearable,
  google,
  placeholder,
  size,
}: Props) => {
  const inputElement: any = useRef(null);
  let searchBox: any = null;

  const onPlacesChanged = () => {
    if (onChangeHandler) {
      // specific path for the antd input value
      onChangeHandler(inputElement.current.input.value);
    }
    if (onPlacesChangedHandler) {
      onPlacesChangedHandler(searchBox.getPlaces());
    }
  };

  const onChange = (e?: any) => {
    if (onChangeHandler) {
      onChangeHandler(e.target.value);
    }
  };

  useEffect(() => {
    if (google && inputElement) {
      try {
        // eslint-disable-next-line
        searchBox = new google.maps.places.SearchBox(
          // specific path for the antd input element
          inputElement.current.input as any,
        );
        searchBox.addListener('places_changed', onPlacesChanged);
      } catch (error) {}
    }
    return () => {
      // https://developers.google.com/maps/documentation/javascript/events#removing
      if (google) {
        try {
          google.maps.event.clearInstanceListeners(searchBox);
        } catch (error) {}
      }
    };
  }, [google, inputElement]);

  return (
    <Input
      size={size}
      value={value}
      ref={inputElement}
      onChange={onChange}
      allowClear={clearable}
      placeholder={placeholder}
    />
  );
};

export default GoogleMapSearchInput;

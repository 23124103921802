import React, {useState} from 'react';
import {useAppSelector} from 'store/hooks';

import Image from 'view/components/Image';
import {NW2ModalGallery} from '../../NW2ModalGallery';

import {TImage} from 'types/app';
import {borderRadiusDef} from 'constants/styleVars';

import {
  StyledImageItem,
  StyledWrapper,
} from '../../NW2MorePhotos/NW2MorePhotos.styles';
import {ImagesCounter} from '../NW2SpaceDetails.styles';

type TProps = {
  images: TImage[];
};

export function NW2SpaceDetailsImages({images}: TProps) {
  const [isGalleryShowed, setGalleryShowed] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<
    number | undefined
  >(undefined);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const coverImages = images.filter((doc: TImage) => doc.isCover);
  const extraImages = images.filter((doc: TImage) => !doc.isCover);

  const imagesToShow = [...coverImages, ...(!isMobile ? [...extraImages] : [])];

  const isSingleImage = images.length === 1;

  const imagesCounter = `1/${images.length}`;

  const onImageClick = (uid: string) => () => {
    if (isMobile && !isSingleImage) {
      const selectedIndex = images.findIndex((img: TImage) => img.uid === uid);

      if (selectedIndex < 0) {
        return;
      }

      setSelectedImageIndex(selectedIndex);
      setGalleryShowed((prev) => !prev);
    }
  };

  const closeGallery = () => {
    setGalleryShowed(false);
    setSelectedImageIndex(undefined);
  };

  if (!images) return null;

  return (
    <>
      <StyledWrapper>
        {imagesToShow.map(({uid, name, url}: TImage) => (
          <StyledImageItem key={`photo-${uid}`} onClick={onImageClick(uid)}>
            <Image src={url} borderRadius={borderRadiusDef} alt={name} />
            {isMobile && !isSingleImage && (
              <ImagesCounter>
                <span>{imagesCounter}</span>
              </ImagesCounter>
            )}
          </StyledImageItem>
        ))}
      </StyledWrapper>
      <NW2ModalGallery
        isShowed={isGalleryShowed}
        images={images}
        onClose={closeGallery}
        startIndex={selectedImageIndex}
      />
    </>
  );
}

export default NW2SpaceDetailsImages;

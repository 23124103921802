import React from 'react';
import styled, {keyframes} from 'styled-components';

import Icon from 'view/components/Icon';

import {offsetXSm, offsetDef} from 'constants/styleVars';

type TProps = {
  label?: string;
  icon?: string;
  height?: string;
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div<{height: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({height}) => height};
`;

const StyledIcon = styled(Icon)`
  animation: ${rotate} 0.8s linear infinite;
  margin-right: ${offsetXSm};
`;

export const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${offsetDef};
  height: 100%;
`;

const NW2Loader = ({
  label = 'Loading',
  icon = 'PROCESSING',
  height = 'auto',
}: TProps) => (
  <StyledLoader height={height}>
    <StyledIcon icon={icon} black />
    <span>{label}</span>
  </StyledLoader>
);

export default NW2Loader;

import {offsetDef, offsetXLg, offsetXXLg} from 'constants/styleVars';
import styled, {css} from 'styled-components';

export const FormGroup = styled.div<{gap: string; isTablet?: boolean}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({gap}) => gap}px;
  margin: ${offsetXLg} 0;
  gap: ${offsetXLg};

  > div {
    margin: 0;
  }

  ${({isTablet}) =>
    isTablet &&
    css`
      margin-bottom: ${offsetXXLg};
    `}
`;

export const Container = styled.div<{isTablet?: boolean}>`
  display: ${({isTablet}) => (isTablet ? 'grid' : 'contents')};
  grid-template-columns: 1fr 1fr;
  gap: ${offsetXLg};
`;

export const Wrapper = styled.div<{isTablet?: boolean}>`
  ${({isTablet}) =>
    isTablet &&
    css`
      padding-left: 68px;
      margin-top: ${offsetDef};
    `};
`;

import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';

import {EMPTY_OBJECT} from 'constants/app';

export function useQuery() {
  const {search} = useLocation();
  return useMemo(
    () =>
      queryString.parse(search.slice(1), {parseBooleans: true}) || EMPTY_OBJECT,
    [search],
  );
}

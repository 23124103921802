import styled from 'styled-components';
import {errorColor, offsetDef} from 'constants/styleVars';
import {Button} from 'antd';
import {TAlignment} from './types';

const IconContainer = styled.span`
  cursor: pointer;
  width: 100%;

  > div {
    pointer-events: none;
  }

  & svg {
    height: 16px;
    width: 16px;
  }
`;

const ItemRenderContainer = styled.div<{
  error: boolean;
  isCoverImage?: boolean;
  isLastImageInUploader?: boolean;
}>`
  position: relative;

  ${({error}) =>
    error &&
    `
      color: ${errorColor};
      border: 1px solid ${errorColor};
    `}

  ${({isCoverImage}) =>
    isCoverImage &&
    `
      & .ant-upload-list-item-actions button[title="Download file"] {
        display: none;
      }
    `}
      
   ${({isLastImageInUploader}) =>
    isLastImageInUploader &&
    `
      & .ant-upload-list-item-actions button[title="Remove file"] {
        display: none;
      }
    `}
`;

const UploadContainer = styled.div<{
  alignment: TAlignment;
  isImgUploaded: boolean;
}>`
  line-height: 0;
  & .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: ${({alignment}) => alignment};
  }

  & .ant-upload-list-picture-card-container > div {
    height: 100%;
  }

  & .ant-upload-list-picture-card-container,
  & .ant-upload-select {
    width: calc(25% - ${offsetDef});
    height: 113px;
    margin-right: ${offsetDef};
  }

  & .ant-upload-list-picture-card-container {
    margin-bottom: ${offsetDef};
  }

  & .ant-upload-select {
    ${({isImgUploaded}) =>
      !isImgUploaded &&
      `width: 100%; height: auto; padding: ${offsetDef}; margin-right: 0;`};
  }

  & .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  & .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }

  & .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  & .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    opacity: 1;
  }

  & .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn,
  & .ant-upload-list-item-actions > * {
    opacity: 0.6;
    transition: 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

const ButtonContainer = styled.div`
  text-align: right;
`;

export {
  StyledButton,
  ButtonContainer,
  UploadContainer,
  ItemRenderContainer,
  IconContainer,
};

import React, {ChangeEvent, useEffect} from 'react';
import {Form, Input, FormInstance} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';
import {Country} from 'country-state-city';

import FullWidthInputNumber from 'view/components/FullWidthInputNumber';
import CountrySelect from '../ContactDetailsForm/components/CountrySelect';
import FormItemUrl from 'view/components/FormItemUrl';
import FormItemWithoutEvent from 'view/components/FormItemWithoutEvent';
import TextValue from 'view/components/TextValue';

import {SubPageTitle} from 'view/components/Typography';
import {defaultAntdFormLayout, EMPTY_OBJECT} from 'constants/app';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {
  countryFieldRules,
  emailFieldRules,
  legalPhoneFieldRules,
  phoneFieldRules,
  postalCodeFieldRules,
} from 'utils/antdFormFieldRules';
import {IContact} from 'types/dto/IContact.types';
import {trimPhoneNumber} from 'utils/stringUtils';
import {offsetDef} from 'constants/styleVars';
import {setVenueFields} from 'store/venue/actions';
import FormItemPhoneNumber from 'view/components/FormItemPhoneNumber';
import {ILegalPhone} from 'types/venue';
import {getLegalPhoneNumber} from 'view/venue/CreateVenue/pages/ContactDetails/helpers';

const {Item} = Form;

const CustomInputNumber = styled(Input)`
  width: 100%;
`;

const LegalContactsHeader = styled(TextValue)`
  margin-bottom: ${offsetDef};
`;

type TProps = {
  form: FormInstance;
};

const CorporateContactDetailsForm = ({form}: TProps) => {
  const dispatch = useDispatch();

  const contact: IContact =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.contact`),
    ) || EMPTY_OBJECT;

  const legalContact =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.legalContact`),
    ) || EMPTY_OBJECT;

  const legalPhoneValue = getLegalPhoneNumber(legalContact.legalPhone);

  useEffect(() => {
    const shouldShow = !!contact && !!legalContact;

    if (!shouldShow) return;

    const initialValues = {
      email: contact.email,
      phone: contact.phone,
      fax: contact.fax,
      websiteUrl: contact.websiteUrl,
      facebookUrl: contact.facebookUrl,
      twitterUrl: contact.twitterUrl,
      instagramUrl: contact.instagramUrl,
      companyName: legalContact.companyName,
      legalEmail: legalContact.email,
      legalPhone: legalPhoneValue,
      ceoName: legalContact.ceoName,
      taxNumber: legalContact.taxNumber,
      country: legalContact.country,
      countryCode: legalContact.countryCode,
      city: legalContact.city,
      streetName: legalContact.streetName,
      buildingNumber: legalContact.buildingNumber,
      postalCode: legalContact.postalCode,
      floorNumber: legalContact.floorNumber,
    };

    form.setFieldsValue(initialValues);
  });

  const handleFormFields =
    (path: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.persist();
      try {
        dispatch(
          setVenueFields({
            path,
            value:
              path === 'contact.email'
                ? event.target.value.trim()
                : event.target.value,
          }),
        );
      } catch (error) {}
    };

  // this function is meant to keep leading zeros and +
  const handlePhoneFields =
    (path: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const normalizedValue = trimPhoneNumber(event.target.value);
      dispatch(setVenueFields({path, value: normalizedValue}));
    };
  const handleLegalPhoneFields =
    (path: string) => (value: string | ILegalPhone) => {
      dispatch(setVenueFields({path, value}));
    };

  // this function is meant to keep leading zeros
  const handleCustomNumericFormFields =
    (path: string) => (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setVenueFields({path, value: event.target.value}));
    };

  const handleValueFormFields =
    (path: string) => (value: string | number | null) => {
      if (value !== null) dispatch(setVenueFields({path, value}));
    };

  const handleCountryValueFormField = (value: string) => {
    const countryValue = Country.getCountryByCode(value);

    if (countryValue) {
      dispatch(
        setVenueFields({
          path: 'legalContact.country',
          value: countryValue.name,
        }),
      );
    }

    dispatch(setVenueFields({path: 'legalContact.countryCode', value}));
  };

  return (
    <>
      <SubPageTitle>Contact details</SubPageTitle>

      <Form {...defaultAntdFormLayout} form={form}>
        <Item name='email' label='Email address' rules={emailFieldRules}>
          <>
            <Input
              allowClear
              name='email'
              placeholder='Email address'
              onChange={handleFormFields('contact.email')}
              value={contact.email}
            />
          </>
        </Item>

        <Item name='phone' label='Phone number' rules={phoneFieldRules}>
          <CustomInputNumber
            name='phone'
            placeholder='Phone number'
            onChange={handlePhoneFields('contact.phone')}
          />
        </Item>

        <Item name='fax' label='Fax'>
          <CustomInputNumber
            name='fax'
            placeholder='Fax'
            onChange={handlePhoneFields('contact.fax')}
          />
        </Item>

        <FormItemUrl
          name='websiteUrl'
          label='Website'
          allowClear={true}
          placeholder='Office Website URL'
          functionUpdate={handleFormFields('contact.websiteUrl')}
        />

        <LegalContactsHeader>
          Provide legal contact details of Managing Company:
        </LegalContactsHeader>

        <FormItemWithoutEvent
          name='companyName'
          placeholder='Name of Managing Company incl. Legal Form'
          message='Company name is required'
          functionUpdate={handleFormFields('legalContact.companyName')}
          label='Company name'
          allowClear={true}
        />

        <Item name='legalEmail' label='Email address' rules={emailFieldRules}>
          <Input
            allowClear
            name='legalEmail'
            placeholder='Email address'
            onChange={handleFormFields('legalContact.email')}
            value={legalContact.email}
          />
        </Item>

        <Item
          name='legalPhone'
          label='Phone number'
          rules={legalPhoneFieldRules}
        >
          <FormItemPhoneNumber
            name='legalPhone'
            placeholder='+1 234 567 890'
            functionUpdate={handleLegalPhoneFields('legalContact.legalPhone')}
            value={legalPhoneValue}
          />
        </Item>

        <FormItemWithoutEvent
          name='ceoName'
          placeholder='Full name'
          message='Ceo name is required'
          functionUpdate={handleFormFields('legalContact.ceoName')}
          label='CEO name'
          allowClear={true}
        />

        <FormItemWithoutEvent
          name='taxNumber'
          placeholder='Tax Number'
          message='Tax number is required'
          functionUpdate={handleFormFields('legalContact.taxNumber')}
          label='Tax Number'
          allowClear={true}
        />

        <CountrySelect
          name='countryCode'
          label='Country'
          rules={countryFieldRules}
          placeholder='Select country'
          onChange={handleCountryValueFormField}
        />

        <FormItemWithoutEvent
          name='city'
          placeholder='City'
          message='City is required'
          functionUpdate={handleFormFields('legalContact.city')}
          label='City'
          allowClear={true}
        />

        <FormItemWithoutEvent
          name='streetName'
          placeholder='Street address'
          message='Street is required'
          functionUpdate={handleFormFields('legalContact.streetName')}
          label='Street name'
          allowClear={true}
        />

        <FormItemWithoutEvent
          name='buildingNumber'
          placeholder='Building number'
          message='Building number is required'
          functionUpdate={handleFormFields('legalContact.buildingNumber')}
          label='Building number'
          allowClear={true}
        />

        <Item
          name='postalCode'
          label='Postal code'
          rules={postalCodeFieldRules}
        >
          <CustomInputNumber
            name='postalCode'
            placeholder='Postal code'
            onChange={handleCustomNumericFormFields('legalContact.postalCode')}
          />
        </Item>

        <Item name='floorNumber' label='Unit / Floor number'>
          <FullWidthInputNumber
            name='floorNumber'
            type='number'
            precision={0}
            placeholder='Unit / Floor number'
            onChange={handleValueFormFields('legalContact.floorNumber') as any}
          />
        </Item>
      </Form>
    </>
  );
};

export default CorporateContactDetailsForm;

import React, {memo} from 'react';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {
  ContentSubtitle,
  FlexGroup,
  InputSeparator,
} from '../../NMMSpacesDetails.styles';
import {EDimensionsFormFields} from './DimensionsForm.types';
import {NW2Gray600Color} from 'styles/configs/colors';
import {requiredFieldRules} from 'utils/finalFormFieldRules';
import {useDimensions} from 'view/venue/NW2VenueProfile/components/AddSpace/hooks/useDimensions';

interface IProps {
  onFormChange: (name: any, value?: string) => void;
}

export const DimensionsFormItems = memo(({onFormChange}: IProps) => {
  const {
    handleSquareMetersChange,
    handleSquareFeetChange,
    handleHeightMetersChange,
    handleHeightFeetChange,
  } = useDimensions({
    fieldAreaName: EDimensionsFormFields.AREA,
    fieldAreaFeetName: EDimensionsFormFields.AREA_FEET,
    fieldHeightName: EDimensionsFormFields.CEILING_HEIGHT,
    fieldHeightFeetName: EDimensionsFormFields.CEILING_HEIGHT_FEET,
    onFormChange,
  });

  return (
    <>
      <ContentSubtitle>space area</ContentSubtitle>
      <FlexGroup>
        <NW2FormItemInput
          type='text'
          label='Square metres'
          placeholder='Square metres'
          name={EDimensionsFormFields.AREA}
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('area dimension')}
          onChange={handleSquareMetersChange}
          data-testid={`input-space-dimensions-${EDimensionsFormFields.AREA}`}
        />

        <InputSeparator>=</InputSeparator>

        <NW2FormItemInput
          type='text'
          label='Square feet'
          placeholder='Square feet'
          name={EDimensionsFormFields.AREA_FEET}
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('area dimension')}
          onChange={handleSquareFeetChange}
          data-testid={`input-space-dimensions-${EDimensionsFormFields.AREA_FEET}`}
        />
      </FlexGroup>

      <ContentSubtitle>ceiling height</ContentSubtitle>
      <FlexGroup>
        <NW2FormItemInput
          type='text'
          label='Metres'
          placeholder='Metres'
          name={EDimensionsFormFields.CEILING_HEIGHT}
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('ceiling height')}
          onChange={handleHeightMetersChange}
          data-testid={`input-space-dimensions-${EDimensionsFormFields.CEILING_HEIGHT}`}
        />

        <InputSeparator>=</InputSeparator>

        <NW2FormItemInput
          type='text'
          label='Feet'
          placeholder='Feet'
          name={EDimensionsFormFields.CEILING_HEIGHT_FEET}
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules('ceiling height')}
          onChange={handleHeightFeetChange}
          data-testid={`input-space-dimensions-${EDimensionsFormFields.CEILING_HEIGHT_FEET}`}
        />
      </FlexGroup>
    </>
  );
});

import {IUnit} from 'types/dto/IUnit.types';

const BookVenueService = {
  getUnitByMinimumCost: (unitList: IUnit[]): number => {
    unitList.sort((a, b) => a.priceDay - b.priceDay);
    return unitList[0]?.priceDay;
  },
};

export default BookVenueService;

import {
  EDefaultExtras,
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
  IExtrasResponse,
} from 'types/dto/IExtras.type';
import {TSelectedOptions} from 'types/search';
import {
  convertExtraNameToEnum,
  getPreselectedExtrasIds,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';

const preselectedFoodBeverageGroupOnlyAccommodation = [
  EDefaultExtras.dinner_including_one_soft_drink_or_one_glass_of_wine_beer,
];

const preselectedFoodBeverageLessThan3Hours = [
  EDefaultExtras.unlimited_conference_drinks_half_day,
];
const preselectedFoodBeverageMoreThan3Hours = [
  EDefaultExtras.coffee_and_snacks_break,
  EDefaultExtras.lunch_as_3_course_menu_or_buffet_including_one_soft_drink,
  EDefaultExtras.unlimited_conference_drinks_half_day,
];
const preselectedFoodBeverageMoreThan6Hours = [
  EDefaultExtras.coffee_and_snacks_break,
  EDefaultExtras.lunch_as_3_course_menu_or_buffet_including_one_soft_drink,
  EDefaultExtras.unlimited_conference_drinks_full_day,
];

export const setDefaultExtras = (data: TSelectedOptions) =>
  data.options.reduce((prev, curr) => {
    if (data.ids.includes(curr.id))
      return [...prev, {...curr, value: curr.name}];
    return [...prev];
  }, [] as IExtrasOption[]);

export const filterFoodAndBeverage = (extrasOptions: IExtrasOption[]) =>
  extrasOptions.filter(
    (item) => item.type === EResourcesType.FOOD_AND_BEVERAGE,
  );

export const getCoffeeBreak = <T,>(
  option: T & {name: string; code: EResourcesCode},
) => {
  return (
    convertExtraNameToEnum(option.name) ===
      EDefaultExtras.coffee_and_snacks_break ||
    option.code === EResourcesCode.COFFEE_AND_SNACKS
  );
};

const formatDefaultData =
  (options: IExtrasOption[], participants: number) =>
  (preselectedFoodBeverageEnum: string[], isMoreThan6 = false) => {
    const preselectedFoodBeverageIds = getPreselectedExtrasIds(
      preselectedFoodBeverageEnum,
      options,
    );
    const coffeeBreakId = options.find(getCoffeeBreak)?.id || participants;
    return {
      options,
      ids: preselectedFoodBeverageIds,
      data: {
        foodAndBeverage: preselectedFoodBeverageIds,
        ...preselectedFoodBeverageIds.reduce(
          (prev, curr) => ({
            ...prev,
            [`${curr}_`]:
              // Need to double the coffee_and_snacks_break when we are searching room  for more than 6 hours
              curr === coffeeBreakId && isMoreThan6
                ? participants * 2
                : participants,
          }),
          {},
        ),
      },
    };
  };

export const getPreselectedFoodBeverage = (
  duration: number,
  participants: number,
  allExtrasOptions: IExtrasOption[],
  isAccommodationDayOnly?: boolean,
) => {
  const isLessThan3Hours = duration <= 3;
  const isMoreThan3AndLessThan6 = duration > 3 && duration < 6;
  const isMoreThan6 = duration >= 6;

  const options = filterFoodAndBeverage(allExtrasOptions);
  const formatPreselectedData = formatDefaultData(options, participants);

  if (isAccommodationDayOnly) {
    return formatPreselectedData(preselectedFoodBeverageGroupOnlyAccommodation);
  }

  if (isLessThan3Hours) {
    return formatPreselectedData(preselectedFoodBeverageLessThan3Hours);
  }
  if (isMoreThan3AndLessThan6) {
    return formatPreselectedData(preselectedFoodBeverageMoreThan3Hours);
  }
  if (isMoreThan6) {
    return formatPreselectedData(
      preselectedFoodBeverageMoreThan6Hours,
      isMoreThan6,
    );
  }

  return formatPreselectedData(preselectedFoodBeverageLessThan3Hours);
};

export const getFoodAndBeverageQuantity = ({
  extra,
  duration,
  participants,
}: {
  extra: IExtrasResponse;
  duration: number;
  participants: number;
}) => {
  const isCoffeeBreak = getCoffeeBreak(extra);
  return isCoffeeBreak && duration >= 6 ? participants * 2 : participants;
};

import React from 'react';
import styled, {css} from 'styled-components';

import {useOpenTermsAndConditionsModal} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';

import {
  fontSizeXXSm,
  fontWeightBold,
  lineHeightXSm,
  mdBp,
  lgBp,
  offsetXLg,
  offsetXSm,
  offsetDef,
} from 'constants/styleVars';

const TotalTerms = styled.div<{color?: string; isNoPadding?: boolean}>`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  text-align: center;
  color: ${({color}) => color};

  ${({isNoPadding}) =>
    !isNoPadding &&
    css`
      padding-top: ${offsetXLg};

      @media (min-width: ${mdBp}px) {
        padding-top: ${offsetDef};
      }
      @media (min-width: ${lgBp}px) {
        padding-top: ${offsetXSm};
      }
    `}

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: ${fontWeightBold};

    &:hover {
      text-decoration: none;
    }
  }
`;

interface IProps {
  color?: string;
  text?: string;
  isNoPadding?: boolean;
}

export const LinkTerms = ({color, text, isNoPadding}: IProps) => {
  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();

  return (
    <TotalTerms color={color} isNoPadding={isNoPadding}>
      {text}
      <a onClick={openTermsAndConditionsModal}>Terms & Conditions.</a>
    </TotalTerms>
  );
};

import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import NMMSubmitSection from '../NMMSubmitSection';
import NW2ModalDrawer from '../NW2ModalDrawer';
import RequestModalDetails from '../RequestModalDetails';
import RequestDecline from './RequestDecline';

import {offsetDef, offsetSm} from 'constants/styleVars';
import {EDeclineItemType} from 'types/offer';
import {
  ModalConfirmWrapper,
  ButtonContainer,
} from 'view/supplier/Bookings/BookingListRooms/Requests/styles';
import {IRfpHandlers} from 'view/supplier/hooks/useRfpHandlers';
import {IOfferHandlers} from 'view/venue/Offer/hooks/useOfferHandlers';
import {setDeclineModalId} from 'store/offers/offersSlice';

interface IProps {
  orderId: string;
  orderNumber: string;
  checkInDate: string;
  checkOutDate: string;
  company: string;
  currency: string;
  holdUp: string;
  totalPrice: number;
  declineItem: EDeclineItemType;
  isOffer?: boolean;
  headerText?: string;
  isCustomer?: boolean;
  isRequest?: boolean;
  isMobile?: boolean;
  alreadyVisitedDetails?: string[];
  onShowOrderDetails?: (
    orderId: string,
    orderNumber: string,
    isRequest?: boolean,
  ) => () => void;
  changeHandlers: {
    rfpHandlers: IRfpHandlers;
    offerHandlers: IOfferHandlers;
  };
  isRequestWithBedroom?: boolean;
}

export const DeclineModal = ({
  alreadyVisitedDetails,
  checkInDate,
  checkOutDate,
  company,
  currency,
  holdUp,
  isRequest,
  orderId,
  orderNumber,
  totalPrice,
  changeHandlers,
  isOffer,
  declineItem,
  headerText = 'decline request',
  isCustomer,
  isMobile,
  onShowOrderDetails,
  isRequestWithBedroom,
}: IProps) => {
  const dispatch = useDispatch();
  const {offerHandlers, rfpHandlers} = changeHandlers;
  const isRequestOrOffer = isRequest || isOffer;

  const {
    activeModal,
    handleConfirm,
    handleDecline,
    isConfirmShowed,
    isDeclineShowed,
    toggleShowConfirm,
    toggleShowDecline,
    isSending,
  } = isRequestOrOffer ? offerHandlers : rfpHandlers;

  const handleHideDecline = () => {
    dispatch(setDeclineModalId(''));
    toggleShowDecline();
  };

  const isActionCardShowed =
    isRequest && !alreadyVisitedDetails?.includes(orderId);
  const isDeclineModalShowed = isDeclineShowed && activeModal === orderId;

  const handleShowDetails = useCallback(() => {
    if (typeof onShowOrderDetails === 'function') {
      onShowOrderDetails(orderId, orderNumber, isRequest)();
    }
    toggleShowDecline();
  }, [isRequest, onShowOrderDetails, orderId, orderNumber, toggleShowDecline]);

  const onHandleConfirm = () => handleConfirm(orderId);

  const handleSubmit = () => {
    const formElem = document.getElementById(`decline-form-${orderId}`);
    // { cancelable: true } required for Firefox
    // https://github.com/facebook/react/issues/12639#issuecomment-382519193
    formElem?.dispatchEvent(
      new Event('submit', {cancelable: true, bubbles: true}),
    );
  };

  const detailsModal = (
    <RequestModalDetails
      number={orderNumber}
      checkInDate={checkInDate}
      checkOutDate={checkOutDate}
      company={company}
      currency={currency}
      holdUp={holdUp}
      totalPrice={totalPrice}
      isRequest={isRequestOrOffer}
      isCustomer={isCustomer}
      isRequestWithBedroom={isRequestWithBedroom}
    />
  );

  return (
    <>
      {isMobile ? (
        <NW2ModalDrawer
          header={headerText}
          body={
            <RequestDecline
              detailsModal={<></>}
              orderId={orderId}
              isSending={isSending}
              handleDecline={handleDecline}
              handleCancel={toggleShowDecline}
              cancelLabel='cancel'
              declineItem={declineItem}
              isRequest={isRequestOrOffer}
              isCustomer={isCustomer}
              isActionCardShowed={isActionCardShowed}
              handleShowDetails={handleShowDetails}
              isMobile={isMobile}
              showButtons={false}
            />
          }
          footer={
            <ButtonContainer>
              <NMMSubmitSection
                size='medium'
                cancelLabel='cancel'
                submitLabel='decline'
                handleSubmit={handleSubmit}
                handleCancel={toggleShowDecline}
                isLoading={isSending}
                gap={offsetSm}
                justifyContent='center'
                margin='0'
              />
            </ButtonContainer>
          }
          drawerHeight='auto'
          isShowed={isDeclineModalShowed}
          isMobile={isMobile}
          onClose={toggleShowDecline}
        />
      ) : (
        <>
          <NW2ModalDrawer
            header='confirm request'
            body={<ModalConfirmWrapper>{detailsModal}</ModalConfirmWrapper>}
            footer={
              <NMMSubmitSection
                submitButtonType='button'
                submitLabel='confirm'
                cancelLabel='cancel'
                handleSubmit={onHandleConfirm}
                handleCancel={toggleShowConfirm}
                isLoading={isSending}
                gap={offsetDef}
                justifyContent='flex-end'
                margin='0'
              />
            }
            isShowed={isConfirmShowed && activeModal === orderId}
            isMobile={false}
            onClose={toggleShowConfirm}
            modalWidth={600}
          />

          <NW2ModalDrawer
            header={headerText}
            body={
              <RequestDecline
                detailsModal={isMobile ? <></> : detailsModal}
                orderId={orderId}
                isSending={isSending}
                handleDecline={handleDecline}
                handleCancel={handleHideDecline}
                cancelLabel='cancel'
                declineItem={declineItem}
                isRequest={isRequestOrOffer}
                isCustomer={isCustomer}
                isActionCardShowed={isActionCardShowed}
                handleShowDetails={handleShowDetails}
                isMobile={isMobile}
              />
            }
            isShowed={isDeclineModalShowed}
            isMobile={!!isMobile}
            onClose={handleHideDecline}
          />
        </>
      )}
    </>
  );
};

import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {sharedHeadingStyle} from 'view/components/Typography/Typography';
import {
  mdBp,
  offsetDef,
  offsetXSm,
  offsetXXXLg,
  offsetXXLg,
  offsetXLg,
  pagePadding,
  xLgBp,
  NW2Gray800Color,
  offsetSm,
  fontSizeMd,
  lineHeightLg,
  offsetXXSm,
  offsetXXXXLg,
  offsetNone,
  lgBp,
} from 'constants/styleVars';

export const MainWrapper = styled.div<{
  isPreArrival?: boolean;
  isMobile?: boolean;
}>`
  margin-top: ${({isMobile, isPreArrival}) =>
    isPreArrival ? offsetNone : isMobile ? offsetXXLg : offsetXXXLg};
  margin-bottom: ${({isMobile, isPreArrival}) =>
    isPreArrival ? offsetXXXLg : isMobile ? offsetXXLg : offsetNone};

  & + & {
    margin-top: ${offsetXXXLg};
  }
  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: 105px auto;
    grid-gap: ${pagePadding};
  }
  @media (min-width: ${xLgBp}px) {
    grid-template-columns: 175px 1fr;
  }
`;

export const Wrapper = styled.div<{
  isMobile?: boolean;
}>`
  margin-bottom: ${({isMobile}) => (isMobile ? offsetXLg : offsetNone)};

  @media (min-width: ${mdBp}px) {
    grid-column: 1/2;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledParagraph = styled.p`
  ${sharedHeadingStyle};
  display: inline-block;
  margin-right: ${offsetXSm};

  @media (min-width: ${mdBp}px) {
    display: block;
    margin-bottom: ${offsetXSm};
  }
`;

export const Container = styled.div<{
  isBothBedrooms: boolean;
}>`
  display: grid;
  grid-template-columns: auto;
  gap: ${offsetDef};

  @media (min-width: ${lgBp}px) {
    grid-template-columns: min(280px) ${({isBothBedrooms}) =>
        isBothBedrooms
          ? 'minmax(180px, 1fr) minmax(180px, 1fr)'
          : 'minmax(280px, 1fr)'};
  }
`;

export const StyledBox = styled.div`
  height: ${offsetXXXXLg};
  padding: ${offsetSm};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  border-radius: ${offsetXXSm};
  background: ${NW2Gray800Color};
`;

export const AccommodationStringBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXXSm};
`;

export const AccommodationString = styled.div`
  display: block;
`;

export const StyledSpan = styled.span`
  margin: 0 ${offsetXXSm};
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXSm};
  vertical-align: middle;
`;

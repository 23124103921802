import React, {ReactNode} from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import {BlockTitle} from 'view/components/Typography';

import {
  borderRadiusDef,
  cellHoverColor,
  fontSizeXSm,
  fontSizeXXSm,
  lightBlueColor,
  lightErrorColor,
  lightTextColor,
  lightWarnColor,
  offsetDef,
  offsetXLg,
  offsetXSm,
} from 'constants/styleVars';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 200px));
  grid-gap: ${offsetXLg};
  margin-bottom: ${offsetXLg};
`;

const KPIIconBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};
  font-size: ${fontSizeXSm};
  color: ${lightTextColor};
`;

const StyledIcon = styled(Icon)`
  color: inherit;
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin-bottom: 0;
  font-weight: 400;
`;

const KPIFooterBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSizeXXSm};
  color: ${lightTextColor};
`;

const KPIBgColors: Record<string, string> = {
  error: lightErrorColor,
  warning: lightWarnColor,
  default: lightBlueColor,
  secondary: cellHoverColor,
};

const KPIBlock = styled.div<{state?: string}>`
  padding: ${offsetXSm} ${offsetDef};
  border-radius: ${borderRadiusDef};

  ${({state}) =>
    state
      ? `background: ${KPIBgColors[`${state}`]}`
      : `background: ${lightBlueColor}`};
`;

type TKPIItem = {
  icon: string;
  iconText: string;
  bodyText: string | ReactNode;
  footerText: string | JSX.Element;
  bgColor: string;
};

type TProps = {
  kpiData: Record<string, TKPIItem>;
};

function KPI({kpiData}: TProps) {
  return (
    <Container>
      {Object.values(kpiData).map(
        ({icon, iconText, footerText, bodyText, bgColor}) => {
          return (
            <KPIBlock key={iconText} state={bgColor}>
              <KPIIconBlock>
                <StyledIcon icon={icon} />
                <span>{iconText}</span>
              </KPIIconBlock>
              <StyledBlockTitle>{bodyText}</StyledBlockTitle>
              <KPIFooterBlock>{footerText}</KPIFooterBlock>
            </KPIBlock>
          );
        },
      )}
    </Container>
  );
}

export default KPI;

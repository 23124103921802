import React from 'react';
import {Navigate} from 'react-router-dom';

import CreateVenue from 'view/venue/CreateVenue';
import NW2BookingPreview from 'view/venue/NW2BookingPreview/NW2BookingPreview';
import NW2BookingConfirmation from 'view/venue/NW2BookingConfirmation/NW2BookingConfirmation';
import NW2BookingEdit from 'view/venue/NW2BookingEdit/NW2BookingEdit';
import EndVenueRegistration from 'view/venue/EndVenueRegistraion/EndVenueRegistration';
import AccountVerificationDashboard from 'view/auth/Register/AccountVerificationDashboard';
import ChangePassword from 'view/customer/ChangePassword';
import EndRegistrationSetPassword from 'view/auth/setPasswordFlow/EndRegistrationSetPassword';
import EndRegistrationSetPasswordSuccess from 'view/auth/setPasswordFlow/EndRegistrationSetPasswordSuccess';
import ForgotPassword from 'view/auth/setPasswordFlow/ForgotPassword';
import ForgotPasswordSuccess from 'view/auth/setPasswordFlow/ForgotPasswordSuccess';
import LoginSupplier from 'view/auth/LoginSupplier/LoginSupplier';
import ForgotResetPasswordSupplier from 'view/auth/LoginSupplier/ForgotResetPasswordSupplier';
import Register from 'view/auth/Register/Register';
import VenueAvailability from 'view/supplier/VenueAvailability';
import CompanyAccount from 'view/admin/CompanyAccount';
import PropertyManagerProfile from 'view/propertyManager/PropertyManagerProfile';
import NW2VenuesList from 'view/venue/NW2VenuesList/NW2VenuesList';
import NW2VenueDetails from 'view/venue/NW2VenueDetails';
import NW2VenuePreview from 'view/venue/NW2VenuePreview';
import MainLanding from 'view/mainLanding/MainLanding';
import Page404 from 'view/common/Error/Page404';
import ImprintPage from 'view/common/ImprintPage/ImprintPage';
import CookiePolicyPage from 'view/common/CookiePolicyPage/CookiePolicyPage';

import {Routes} from 'constants/routes';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import NW2VenueProfile from '../view/venue/NW2VenueProfile';
import NMMSpaces from 'view/venue/NW2VenueProfile/components/NMMSpaces/NMMSpaces';
import BookingsListDesks from 'view/supplier/Bookings/BookingListDesks/BookingsListDesks';
import BookingsListRooms from 'view/supplier/Bookings/BookingListRooms/BookingsListRooms';

import {
  OfferRequest,
  OfferRequestGroupOverview,
  OfferRequestOverview,
  OfferReview,
} from 'view/venue/Offer';

// lazy routes
import NW2CustomerBookings from 'view/customer/NW2CustomerBookings';
import TermAndCondition from 'view/supplier/TermAndCondition';
import NW2CustomerProfile from 'view/customer/NW2CustomerProfile';
import CompaniesOverview from 'view/admin/CompaniesOverview';
import UnmanagedAccounts from 'view/admin/UnmanagedAccounts';
import Announcements from 'view/admin/Announcements';
import NMMResources from 'view/venue/NW2VenueProfile/components/NMMResources';
import TermsAndConditions from 'view/common/TermsAndConditions';
import PrivacyPolicy from 'view/common/PrivacyPolicy';
import FAQ from 'view/common/FAQ';
import CancellationPolicy from 'view/common/CancellationPolicy';
import RequestDecline from 'view/venue/Offer/supplier/RequestDeclinePage';
import RequestDeclineSuccess from 'view/venue/Offer/supplier/RequestDeclineSuccessPage';

import ShortListOverview from 'view/venue/Offer/customer/ShortListOverview';
import CompareOverview from 'view/venue/Offer/customer/CompareOverview';
import AgentView from 'view/agent/AgentView';
import AgentSupplierBookingDetails from 'view/agent/AgentSupplierBookingDetails';
import AgentSupplierSpaces from 'view/agent/AgentSuplierMasterData/AgentSupplierSpaces';
import AgentSupplierProfile from 'view/agent/AgentSuplierMasterData/AgentSupplierProfile';
import AgentSupplierAvailability from 'view/agent/AgentSuplierMasterData/AgentSupplierAvailability';
import AgentSupplierServices from 'view/agent/AgentSuplierMasterData/AgentSupplierServices';
import AgentSupplierBookings from 'view/agent/AgentSuplierMasterData/AgentSupplierBookings';

const RouteComponents = [
  {
    exact: true,
    path: Routes.createPublicVenue,
    component: () => (
      <CreateVenue accommodationType={EAccommodationType.VENUE} />
    ),
    pageTitle: 'Registration - Venue',
  },
  {
    exact: true,
    path: Routes.registerVenue,
    component: () => (
      <CreateVenue accommodationType={EAccommodationType.VENUE} isHmd />
    ),
    pageTitle: 'Registration - Venue',
  },
  {
    exact: true,
    path: Routes.venuesList,
    component: NW2VenuesList,
    pageTitle: 'Result List',
  },
  {
    exact: true,
    path: Routes.venuePreview,
    component: NW2VenuePreview,
    pageTitle: 'Venue Details',
  },
  {
    exact: true,
    path: Routes.venueDetails,
    component: NW2VenueDetails,
    pageTitle: 'Venue Details',
  },
  {
    exact: true,
    path: Routes.bookVenuePreview,
    component: NW2BookingPreview,
    pageTitle: 'Booking Preview',
  },
  {
    exact: true,
    path: Routes.bookConfirmation,
    component: NW2BookingConfirmation,
    pageTitle: 'Booking Details',
  },
  {
    exact: true,
    path: Routes.bookEdit,
    component: NW2BookingEdit,
    pageTitle: 'Booking Edit',
    isPrivateRoute: true,
  },
  {
    exact: true,
    path: Routes.endVenueRegistration,
    component: EndVenueRegistration,
    pageTitle: 'Registration - Venue',
  },
  {
    exact: true,
    path: Routes.userAccountVerificationDashboard,
    component: AccountVerificationDashboard,
    pageTitle: 'Account Verification Dashboard',
  },
  {
    exact: true,
    path: Routes.termAndCondition,
    component: TermAndCondition,
    pageTitle: 'Terms - Venue',
  },
  {
    exact: true,
    path: Routes.customerTermAndCondition,
    component: TermAndCondition,
    pageTitle: 'Terms',
  },
  {
    exact: true,
    path: Routes.corporateUserTermAndCondition,
    component: TermAndCondition,
    pageTitle: 'Terms',
  },
  {
    exact: true,
    path: Routes.customerBookings,
    component: NW2CustomerBookings,
    pageTitle: 'Booking Overview',
  },
  {
    exact: true,
    path: Routes.customerId,
    component: NW2CustomerProfile,
    pageTitle: 'Account',
  },
  {
    exact: true,
    path: Routes.userChangePassword,
    component: ChangePassword,
    pageTitle: 'Change Password',
  },
  {
    exact: true,
    path: Routes.userCompleteRegistration,
    component: EndRegistrationSetPassword,
    pageTitle: 'Complete Registration',
  },
  {
    exact: true,
    path: Routes.userCompleteRegistrationSuccess,
    component: EndRegistrationSetPasswordSuccess,
    pageTitle: 'Complete Registration',
  },
  {
    exact: true,
    path: Routes.userForgotPassword,
    component: ForgotPassword,
    pageTitle: 'Forgot Password',
  },
  {
    exact: true,
    path: Routes.userForgotPasswordSuccess,
    component: ForgotPasswordSuccess,
    pageTitle: 'Forgot Password',
  },
  {
    exact: true,
    path: Routes.login,
    component: LoginSupplier,
    pageTitle: 'Sign in',
  },
  {
    exact: true,
    path: Routes.forgotPassword,
    component: ForgotResetPasswordSupplier,
    pageTitle: 'Forgot Password',
  },
  {
    exact: true,
    path: Routes.register,
    component: Register,
    pageTitle: 'Register',
  },
  {
    exact: true,
    path: Routes.requestDecline,
    component: RequestDecline,
    pageTitle: 'Request decline',
  },
  {
    exact: true,
    path: Routes.requestDeclineSuccess,
    component: RequestDeclineSuccess,
    pageTitle: 'Request decline success',
  },

  {
    exact: true,
    path: Routes.shortListOverview,
    component: ShortListOverview,
    pageTitle: 'Compare Venues',
  },
  {
    exact: true,
    path: Routes.compareOverview,
    component: CompareOverview,
    pageTitle: 'Compare Venues',
  },

  {
    exact: true,
    path: Routes.offerRequest,
    component: OfferRequest,
    pageTitle: 'Offer request',
    isCustomerRoute: true,
  },

  // private routes

  {
    exact: true,
    path: Routes.offerRequestOverview,
    component: OfferRequestOverview,
    pageTitle: 'Offer request overview',
    isPrivateRoute: true,
    isRedirectedAfterLogin: true,
    isCustomerRoute: true,
  },
  {
    exact: true,
    path: Routes.OfferRequestGroupOverview,
    component: OfferRequestGroupOverview,
    pageTitle: 'Offer request group overview',
    isPrivateRoute: true,
    isRedirectedAfterLogin: true,
    isCustomerRoute: true,
  },
  {
    exact: true,
    path: Routes.offerReview,
    component: OfferReview,
    pageTitle: 'Offer review',
    isPrivateRoute: true,
    isRedirectedAfterLogin: true,
    isCustomerRoute: true,
  },
  {
    exact: true,
    path: Routes.bookingsListRooms,
    component: BookingsListRooms,
    pageTitle: 'Booking Overview - Meeting Rooms',
    isPrivateRoute: true,
  },
  {
    exact: true,
    path: Routes.bookingsListDesks,
    component: BookingsListDesks,
    pageTitle: 'Booking Overview - Work Desks',
    isPrivateRoute: true,
  },
  {
    exact: true,
    path: Routes.publicVenue,
    component: () => (
      <NW2VenueProfile accommodationType={EAccommodationType.VENUE} />
    ),
    pageTitle: 'Profile - Venue',
    isPrivateRoute: true,
  },
  {
    exact: true,
    path: Routes.publicVenueAvailability,
    component: VenueAvailability,
    pageTitle: 'Availability Manager - Venue',
    isPrivateRoute: true,
  },

  // Agent routes
  {
    exact: true,
    path: Routes.agentView,
    component: AgentView,
    pageTitle: 'Agent View',
    isAgentView: true,
  },
  {
    exact: true,
    path: Routes.agentSupplierBookingDetails,
    component: AgentSupplierBookingDetails,
    pageTitle: 'Agent View',
    isAgentView: true,
  },
  {
    exact: true,
    path: Routes.agentSupplierSpaces,
    component: AgentSupplierSpaces,
    pageTitle: 'Supplier Agent View',
    isAgentView: true,
  },
  {
    exact: true,
    path: Routes.agentSupplierProfile,
    component: AgentSupplierProfile,
    pageTitle: 'Supplier Agent View',
    isAgentView: true,
  },
  {
    exact: true,
    path: Routes.agentSupplierAvailability,
    component: AgentSupplierAvailability,
    pageTitle: 'Supplier Agent View',
    isAgentView: true,
  },
  {
    exact: true,
    path: Routes.agentSupplierServices,
    component: AgentSupplierServices,
    pageTitle: 'Supplier Agent View',
    isAgentView: true,
  },
  {
    exact: true,
    path: Routes.agentSupplierBookings,
    component: AgentSupplierBookings,
    pageTitle: 'Supplier Agent View',
    isAgentView: true,
  },

  // admin routes
  {
    exact: true,
    path: Routes.companiesOverview,
    component: CompaniesOverview,
    pageTitle: 'Companies Overview',
    isAdminRoute: true,
  },
  {
    exact: true,
    path: Routes.corporateCompanyAccount,
    component: CompanyAccount,
    pageTitle: 'Companies Account',
    isAdminRoute: true,
  },
  {
    exact: true,
    path: Routes.unmanagedAccounts,
    component: UnmanagedAccounts,
    pageTitle: 'Unmanaged Accounts',
    isAdminRoute: true,
  },
  {
    exact: true,
    path: Routes.announcements,
    component: Announcements,
    pageTitle: 'Announcements',
    isAdminRoute: true,
  },

  // property manager routes
  {
    exact: true,
    path: Routes.propertyManagerProfile,
    component: PropertyManagerProfile,
    pageTitle: 'Profile - Office',
    isPropertyManagerRoute: true,
  },
  {
    exact: true,
    path: Routes.createCorporateVenue,
    component: () => (
      <CreateVenue accommodationType={EAccommodationType.CORPORATE_OFFICE} />
    ),
    pageTitle: 'Create Office',
    isPropertyManagerRoute: true,
  },
  {
    exact: true,
    path: Routes.corporateVenue,
    component: () => (
      <NW2VenueProfile
        accommodationType={EAccommodationType.CORPORATE_OFFICE}
      />
    ),
    pageTitle: 'Profile - Office',
    isPropertyManagerRoute: true,
  },
  {
    exact: true,
    path: Routes.publicVenueSpaces,
    component: () => <NMMSpaces accommodationType={EAccommodationType.VENUE} />,
    pageTitle: 'Spaces - Public',
  },
  {
    exact: true,
    path: Routes.corporateVenueSpaces,
    component: () => (
      <NMMSpaces accommodationType={EAccommodationType.CORPORATE_OFFICE} />
    ),
    pageTitle: 'Spaces - Public',
  },
  {
    exact: true,
    path: Routes.resources,
    component: NMMResources,
    pageTitle: 'Resources',
  },
  {
    exact: true,
    path: Routes.mainLanding,
    component: MainLanding,
    pageTitle: 'HRS',
    index: true,
  },
  {
    exact: true,
    path: Routes.page404,
    component: Page404,
    pageTitle: '404',
  },
  {
    exact: true,
    path: Routes.imprint,
    component: ImprintPage,
    pageTitle: 'Imprint',
  },
  {
    exact: true,
    path: Routes.cookiePolicy,
    component: CookiePolicyPage,
    pageTitle: 'Cookie Policy',
  },
  // Terms and Conditions paths for different roles, different types of emails (MyHRS cognito, NWRK cognito)
  {
    exact: true,
    path: Routes.termsAndConditions,
    component: TermsAndConditions,
    pageTitle: 'Terms',
  },
  {
    exact: true,
    path: Routes.cancellationPolicy,
    component: CancellationPolicy,
    pageTitle: 'Modification & Cancellation conditions',
  },
  {
    exact: true,
    path: Routes.supplierTermsAndConditions,
    component: () => (
      <Navigate
        to={`${Routes.termsAndConditions}?role=${EUserRoleCognito.ROLE_SUPPLIER}`}
        replace
      />
    ),
  },
  {
    exact: true,
    path: Routes.customerTermsAndConditions,
    component: () => (
      <Navigate
        to={`${Routes.termsAndConditions}?role=${EUserRoleCognito.ROLE_CUSTOMER}`}
        replace
      />
    ),
  },
  {
    exact: true,
    path: Routes.corporateUserTermsAndConditions,
    component: () => (
      <Navigate
        to={`${Routes.termsAndConditions}?role=${EUserRoleCognito.ROLE_CORPORATE_USER}`}
        replace
      />
    ),
  },
  // Privacy Policy paths for different roles, different types of emails (MyHRS cognito, NWRK cognito)
  {
    exact: true,
    path: Routes.privacyPolicy,
    component: PrivacyPolicy,
    pageTitle: 'Privacy Policy',
  },
  {
    exact: true,
    path: Routes.supplierPrivacyPolicy,
    component: () => (
      <Navigate
        to={`${Routes.privacyPolicy}?role=${EUserRoleCognito.ROLE_SUPPLIER}`}
        replace
      />
    ),
  },
  {
    exact: true,
    path: Routes.customerPrivacyPolicy,
    component: () => (
      <Navigate
        to={`${Routes.privacyPolicy}?role=${EUserRoleCognito.ROLE_CUSTOMER}`}
        replace
      />
    ),
  },
  {
    exact: true,
    path: Routes.corporateUserPrivacyPolicy,
    component: () => (
      <Navigate
        to={`${Routes.privacyPolicy}?role=${EUserRoleCognito.ROLE_CORPORATE_USER}`}
        replace
      />
    ),
  },
  // FAQ paths for different roles, different types of emails (MyHRS cognito, NWRK cognito)
  {
    exact: true,
    path: Routes.faq,
    component: FAQ,
    pageTitle: 'FAQ',
  },
  {
    exact: true,
    path: Routes.supplierFAQ,
    component: () => (
      <Navigate
        to={`${Routes.faq}?role=${EUserRoleCognito.ROLE_SUPPLIER}`}
        replace
      />
    ),
  },
  {
    exact: true,
    path: Routes.customerFAQ,
    component: () => (
      <Navigate
        to={`${Routes.faq}?role=${EUserRoleCognito.ROLE_CUSTOMER}`}
        replace
      />
    ),
  },
  {
    exact: true,
    path: Routes.corporateUserFAQ,
    component: () => (
      <Navigate
        to={`${Routes.faq}?role=${EUserRoleCognito.ROLE_CORPORATE_USER}`}
        replace
      />
    ),
  },
].map((item, index) => ({...item, key: index}));

export {RouteComponents};

import React, {useState} from 'react';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';
import {DateTime} from 'luxon';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import {MonthPicker} from './MonthPicker';
import {YearPicker} from './YearPicker';
import {months, range} from '../helpers';

import {StyledIcon, StyledNW2Button} from '../DesktopDatePicker.styles';
import {offsetDef, offsetSm, pagePadding} from 'styles/configs/offset';
import {NW2Gray300Color} from 'styles/configs/colors';

const HeaderWrapper = styled.div<{isActive: boolean}>`
  display: flex;
  padding: ${pagePadding} ${offsetDef} ${offsetSm};
  justify-content: space-between;
  align-items: center;
  position: relative;

  border-bottom: 1px solid
    ${({isActive}) => (isActive ? NW2Gray300Color : 'transparent')};
`;

const MonthYearPickerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
`;

type TProps = ReactDatePickerCustomHeaderProps & {
  minDate: Date;
  maxDate: Date;
};

export function DesktopDatePickerHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minDate,
  maxDate,
}: TProps) {
  const [isMonthActive, setIsMonthActive] = useState(false);
  const [isYearActive, setIsYearActive] = useState(false);

  const years = range(
    DateTime.fromJSDate(minDate).year - 1,
    DateTime.fromJSDate(maxDate).year,
  );

  const toggleIsMonthActive = () => {
    setIsMonthActive((prevState) => !prevState);
    if (isYearActive) setIsYearActive(false);
  };

  const toggleIsYearActive = () => {
    setIsYearActive((prevState) => !prevState);
    if (isMonthActive) setIsMonthActive(false);
  };

  const onChangeMonth = (value: keyof typeof months) => {
    setIsMonthActive(false);
    changeMonth(Object.keys(months).indexOf(value));
  };
  const onChangeYear = (value: number) => {
    setIsYearActive(false);
    changeYear(value);
  };

  return (
    <HeaderWrapper isActive={isMonthActive || isYearActive}>
      <StyledNW2Button
        buttonType='tertiary'
        size='extraSmall'
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        icon={<StyledIcon icon='ARROW_MOVE' size={28} NW2Gray900Color />}
        inverted
        minimized
      />

      <MonthYearPickerContainer>
        <MonthPicker
          date={date}
          onChangeMonth={onChangeMonth}
          onClick={toggleIsMonthActive}
          isActive={isMonthActive}
          isDisabled={isYearActive}
        />
        <YearPicker
          date={date}
          years={years}
          onChangeYear={onChangeYear}
          onClick={toggleIsYearActive}
          isActive={isYearActive}
          isDisabled={isMonthActive}
        />
      </MonthYearPickerContainer>

      <StyledNW2Button
        buttonType='tertiary'
        size='extraSmall'
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        icon={<Icon icon='ARROW_MOVE' size={28} NW2Gray900Color />}
        inverted
        minimized
      />
    </HeaderWrapper>
  );
}

import React, {useState} from 'react';

import NMMSection from 'view/components/NMMSection';

import {IVenue} from 'types/venue';
import {FloorsView} from './components/FloorsView/FloorsView';
import {FloorsEdit} from './components/FloorsEdit/FloorsEdit';
import {EVenueProfileSections} from '../../types';

interface IProps {
  venue: IVenue;
  isExternalVenue: boolean;
}

const FloorsWrapper = ({venue, isExternalVenue}: IProps) => {
  const [editFloors, setEditFloors] = useState(false);

  const onToggleFloorsEdit = () => {
    setEditFloors((prev) => !prev);
  };

  return (
    <NMMSection
      id={EVenueProfileSections.FLOORS}
      title='Floors'
      isEditOn={editFloors}
      onEdit={onToggleFloorsEdit}
    >
      {editFloors ? (
        <FloorsEdit
          venue={venue}
          isExternalVenue={isExternalVenue}
          onFinishEditing={onToggleFloorsEdit}
        />
      ) : (
        <FloorsView venue={venue} />
      )}
    </NMMSection>
  );
};

export default FloorsWrapper;

import React, {useMemo} from 'react';
import {FormSpy, withTypes} from 'react-final-form';
import {DebouncedFunc} from 'lodash';

import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {
  NW2FormItemCheckbox,
  Textarea,
} from 'view/components/NW2FormItem/components';

import useUpdateVenue from '../../../../hooks/useUpdateVenue';
import {
  TEditFormValues,
  TVenueCharactersOption,
} from '../NW2VenueDescription.types';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {validatorDescriptionWithCharacters} from '../validation/editDescriptionWthCharacters';
import {IVenue, TVenueCharacterType} from 'types/venue';
import {
  FlexContainer,
  SubSectionContainer,
  SectionCheckboxItem,
} from '../NW2VenueDescription.styles';
import {EFieldNames} from './types';
import {validatorDescription} from '../validation/editDescription';
import {StyledErrorMessage} from 'view/components/NW2FormItem/NW2FormItem.styles';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';

interface IProps {
  venueCharactersOptions: TVenueCharactersOption[];
  handleEditEnd: () => void;
  venue: IVenue;
  isExternalVenue: boolean;
  isFormDirty: boolean;
  processFormFields: DebouncedFunc<({dirtyFields}: any) => void>;
}

const EditDescription = ({
  venueCharactersOptions,
  handleEditEnd,
  venue,
  isExternalVenue,
  isFormDirty,
  processFormFields,
}: IProps) => {
  const {Form} = withTypes<TEditFormValues>();
  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: handleEditEnd,
  });

  const initialValues = useMemo(() => {
    const result: {[key in EFieldNames]?: string | TVenueCharacterType[]} = {
      [EFieldNames.DESCRIPTION]: venue[EFieldNames.DESCRIPTION] || '',
    };

    if (isExternalVenue) {
      result[EFieldNames.VENUE_CHARACTERS] =
        venue[EFieldNames.VENUE_CHARACTERS] || [];
    }

    return result;
  }, [isExternalVenue, venue]);

  return (
    <Form
      onSubmit={updateVenue}
      initialValues={initialValues as TEditFormValues}
      validate={
        isExternalVenue
          ? validatorDescriptionWithCharacters
          : validatorDescription
      }
    >
      {({handleSubmit, errors}) => {
        const charactersError = errors?.[EFieldNames.VENUE_CHARACTERS]?.[0];

        return (
          <form onSubmit={handleSubmit} noValidate>
            <FlexContainer>
              <SubSectionContainer>
                <SectionSubTitle>What we are like?</SectionSubTitle>
                <Textarea
                  name={EFieldNames.DESCRIPTION}
                  label='Description'
                  disabled={isSending}
                  data-testid='description textarea'
                />
                {charactersError && (
                  <StyledErrorMessage>{charactersError}</StyledErrorMessage>
                )}
              </SubSectionContainer>

              {isExternalVenue && (
                <SubSectionContainer>
                  <SectionSubTitle>Our venue character</SectionSubTitle>
                  {venueCharactersOptions?.map(({value, label}) => (
                    <SectionCheckboxItem key={value}>
                      <NW2FormItemCheckbox
                        name={EFieldNames.VENUE_CHARACTERS}
                        label={label}
                        value={value}
                        id={value}
                        disabled={isSending}
                        size={ECheckboxSizes.LARGE}
                        data-testid={`${label}--checkbox`}
                        noErrorMessage
                      />
                    </SectionCheckboxItem>
                  ))}
                </SubSectionContainer>
              )}
            </FlexContainer>

            <NMMSubmitSection
              isLoading={isSending}
              disabled={!isFormDirty}
              handleCancel={handleEditEnd}
            />

            <FormSpy
              subscription={{dirtyFields: true}}
              onChange={processFormFields}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default EditDescription;

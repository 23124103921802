import React from 'react';

import {
  Content,
  ImageWrapper,
  SkeletonLeftSide,
  SkeletonRightSide,
  SkeletonRow,
  StyledCard,
} from '../../../NW2VenuesListCard/NW2VenuesListCard.styles';
import {venueImageHeight} from 'styles/configs';
import {useAppSelector} from 'store/hooks';
import {VenuesGridContainer} from '../../NW2VenuesListComponent.styles';

type TProps = {
  itemsQuantity?: number;
  isPublicVenue?: boolean;
};

const SkeletonPreloaderList = ({itemsQuantity = 5, isPublicVenue}: TProps) => {
  const {isDesktop, isTablet} = useAppSelector(({app}) => app.deviceType);

  const skeletonItem = (
    <StyledCard isDesktop={isDesktop} isTablet={isTablet}>
      <ImageWrapper showSkeletons style={{height: venueImageHeight}} />
      <Content>
        <>
          <SkeletonRow />
          <SkeletonLeftSide>
            <SkeletonRow />
            <SkeletonRightSide />
          </SkeletonLeftSide>
        </>
      </Content>
    </StyledCard>
  );

  return (
    <VenuesGridContainer isPublicVenue={isPublicVenue}>
      {Array(itemsQuantity)
        .fill(skeletonItem)
        .map((item, index) => (
          <div id={`venue-${index}`} key={index}>
            {item}
          </div>
        ))}
    </VenuesGridContainer>
  );
};

export default SkeletonPreloaderList;

import {TInitialState} from 'store/cancellationPolicy/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  IDynamicCancellationPolicy,
  TCancellationPolicyConditions,
} from 'types/dto/ICancellationPolicy.type';

const initialState: TInitialState = {
  isCancellationPolicyConditionLoading: false,
  cancellationPolicyCondition: null,
  isDynamicCancellationPolicyLoading: false,
  dynamicCancellationPolicy: null,
  isHidden: false,
};

export const cancellationPolicySlice = createSlice({
  name: 'cancellationPolicy',
  initialState,
  reducers: {
    setCancellationPolicyConditionLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isCancellationPolicyConditionLoading = action.payload;
    },
    getCancellationPolicyConditionSuccess(
      state,
      action: PayloadAction<TCancellationPolicyConditions>,
    ) {
      state.cancellationPolicyCondition = action.payload;
      state.isCancellationPolicyConditionLoading = false;
    },
    setDynamicCancellationPolicyLoading(state, action: PayloadAction<boolean>) {
      state.isDynamicCancellationPolicyLoading = action.payload;
    },
    getDynamicCancellationPolicySuccess(
      state,
      action: PayloadAction<IDynamicCancellationPolicy>,
    ) {
      state.dynamicCancellationPolicy = action.payload;
      state.isHidden = action.payload.policies.length === 0;
      state.isDynamicCancellationPolicyLoading = false;
    },
  },
});

export const {
  setDynamicCancellationPolicyLoading,
  getDynamicCancellationPolicySuccess,
  setCancellationPolicyConditionLoading,
  getCancellationPolicyConditionSuccess,
} = cancellationPolicySlice.actions;

export default cancellationPolicySlice.reducer;

import styled, {css} from 'styled-components';

import {Link} from 'view/components/Typography';

import {
  NW2Gray200Color,
  NW2Gray50Color,
  NW2SuccessLight500Color,
  NW2SuccessLight50Color,
  borderRadiusMLg,
  offsetXLg,
  whiteColor,
  NW2Gray900Color,
  blackColor,
  fontWeightExtraBold,
  fontSizeXXXLg,
  lineHeightXXLg,
  offsetXSm,
  lineHeightSm,
  fontSizeXSm,
  NW2Gray600Color,
  fontWeightBold,
  fontSizeMd,
  lineHeightLg,
  offsetXXSm,
  offset80,
  offset40,
  fontWeightNormal,
  offsetDef,
  NW2ErrorLight500Color,
  NW2ErrorLight50Color,
  NW2Info500Color,
  NW2Info50Color,
  lineHeightXXXXLg,
  fontSizeXLg,
  lineHeightXLg,
} from 'constants/styleVars';
import {EBookingStatus} from 'types/booking';
import {Group} from 'styles/basicStyledComponents/group';
import {EOfferStatus, ERequestStatus} from 'types/offer';

export type TWrapperStyles = {
  borderColor: string;
  backgroundColor: string;
  padding?: string;
  borderWidth?: string;
};

type TWrapperSchema = {
  [key in EBookingStatus | ERequestStatus | EOfferStatus]: TWrapperStyles;
};

export const wrapperStylesSchema: Partial<TWrapperSchema> = Object.freeze({
  [EBookingStatus.RFP_DECLINED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: NW2Gray50Color,
  },
  [EBookingStatus.RFP_CANCELLED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: NW2Gray50Color,
  },
  [EBookingStatus.RFP_EXPIRED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: whiteColor,
  },
  [EBookingStatus.CANCELED]: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    padding: '0',
    borderWidth: '0',
  },
  [EBookingStatus.PASSED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: whiteColor,
  },
  [EBookingStatus.CONFIRMED]: {
    borderColor: NW2SuccessLight500Color,
    backgroundColor: NW2SuccessLight50Color,
  },
  [EBookingStatus.RTC_PENDING]: {
    borderColor: NW2Info500Color,
    backgroundColor: NW2Info50Color,
  },

  // Requests
  [ERequestStatus.REQUEST_DECLINED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: NW2Gray50Color,
  },
  [ERequestStatus.REQUEST_CANCELED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: NW2Gray50Color,
  },
  [ERequestStatus.REQUEST_EXPIRED]: {
    borderColor: NW2ErrorLight500Color,
    backgroundColor: NW2ErrorLight50Color,
  },

  // Offers
  [EOfferStatus.OFFER_PENDING]: {
    borderColor: NW2SuccessLight500Color,
    backgroundColor: NW2SuccessLight50Color,
  },
  [EOfferStatus.OFFER_DECLINED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: NW2Gray50Color,
  },
  [EOfferStatus.OFFER_ACCEPTING_EXPIRED]: {
    borderColor: NW2Gray200Color,
    backgroundColor: NW2Gray50Color,
  },
});

export const BorderContainer = styled.div`
  padding-bottom: ${offset40};
  margin-bottom: ${offset80};
  border-bottom: 1px solid ${NW2Gray200Color};
`;

export const Wrapper = styled(Group)<{
  bookingStatus: EBookingStatus | ERequestStatus | EOfferStatus;
}>(({bookingStatus}) => {
  const schema = wrapperStylesSchema[bookingStatus];
  return css`
    padding: ${schema?.padding || offsetXLg};
    border-color: ${schema?.borderColor};
    border-style: solid;
    border-width: ${schema?.borderWidth || '1px'};
    background-color: ${schema?.backgroundColor};
    border-radius: ${borderRadiusMLg};
    color: ${NW2Gray900Color};
  `;
});

export const Title = styled.div`
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeXXXLg};
  line-height: ${lineHeightXXLg};
  color: ${blackColor};
  margin-bottom: ${offsetXSm};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const SubTitle = styled.b`
  margin-right: ${offsetXSm};
`;

export const Columns = styled.div<{divided: string}>`
  display: grid;
  gap: ${offsetXLg};
  grid-template-columns: ${({divided}) => divided && divided};
`;

export const TotalBlock = styled.div<{alignBottom?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  ${({alignBottom}) => alignBottom && 'align-self: self-end;'}
`;
export const TotalTitle = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;

export const TotalCancellationTitle = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
`;

export const Taxes = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetXSm};
`;

export const TotalSum = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXXXLg};
  line-height: ${lineHeightXXLg};
`;

export const DeclineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXXSm};
`;

export const Message = styled.div`
  margin-top: ${offsetXSm};
`;

export const Description = styled.div<{marginBottom?: string}>`
  margin-bottom: ${({marginBottom}) => marginBottom || offsetXSm};
`;

export const Span = styled.span`
  display: inline-block;
`;

export const StyledSpan = styled.span`
  margin-right: ${offsetXSm};
`;

export const Container = styled(Columns)`
  gap: ${offsetXSm};
  margin-bottom: ${offsetXXSm};
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;

export const StyledContainer = styled(Container)`
  margin: ${offsetDef} 0 0;
  padding-top: ${offsetDef};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const StyledLink = styled(Link)`
  display: block;
  line-height: ${lineHeightXXXXLg};
`;

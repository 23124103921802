import styled from 'styled-components';

import {sharedHeadingStyle} from 'view/components/Typography/Typography';
import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';

import {
  NW2ErrorLight500Color,
  NW2FontSizeH5Md,
  NW2SuccessLight500Color,
  offsetDef,
  offsetXLg,
  mdBp,
  offsetXSm,
  fontWeightBold,
  offsetSm,
  NW2Gray200Color,
} from 'styles/configs';

export const BorderedContainer = styled.div`
  border-bottom: 1px solid ${NW2Gray200Color};
`;

export const Wrapper = styled.div`
  padding: ${offsetXLg} 0;

  @media (min-width: ${mdBp}px) {
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    gap: 0 ${offsetXLg};
    align-items: start;
  }
`;

export const Title = styled.div`
  ${sharedHeadingStyle};
  font-size: ${NW2FontSizeH5Md};
  margin-bottom: ${offsetDef};
  grid-column: 1 / 3;
`;

export const ChargeStatusBlock = styled.div<{isIncursChangeFees?: boolean}>`
  grid-column: 1 / 3;
  font-weight: ${fontWeightBold};
  color: ${({isIncursChangeFees}) =>
    isIncursChangeFees ? NW2ErrorLight500Color : NW2SuccessLight500Color};
  display: flex;
  align-items: start;

  @media (min-width: ${mdBp}px) {
    align-items: center;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitle = styled.div`
  font-weight: ${fontWeightBold};
  margin-bottom: ${offsetSm};
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXSm};
`;

export const ConfirmationText = styled.div`
  grid-column: 1 / 2;
  margin-bottom: ${offsetXSm};

  @media (min-width: ${mdBp}px) {
    margin-bottom: ${offsetDef};
  }
`;

export const Link = styled(NW2Button)`
  padding: 0;
  text-transform: none;
  text-decoration: underline;
  font-size: inherit;
  color: inherit;
  text-align: start;
  font-weight: ${fontWeightBold};

  &:hover {
    text-decoration: none;
  }

  > span {
    &:before {
      display: none;
    }
  }
`;

export const StyledLink = styled(Link)`
  grid-column: 2 / 3;
  margin-bottom: ${offsetDef};

  @media (min-width: ${mdBp}px) {
    margin-bottom: 0;
  }
`;

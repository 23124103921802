import React from 'react';

import Icon from 'view/components/Icon';

import {
  ProgressBar,
  ProgressSection,
  ProgressSectionPercents,
  ProgressSectionTop,
  ProgressSectionTopLeft,
} from '../Uploader.styles';

interface IProps {
  progress: number;
  title: string;
}

export function ImageUploaderProgressBar({progress, title}: IProps) {
  return (
    <ProgressSection>
      <ProgressSectionTop>
        <ProgressSectionTopLeft>
          <Icon icon='NW2_IMAGE' />
          {title}
        </ProgressSectionTopLeft>
        <ProgressSectionPercents>{progress}%</ProgressSectionPercents>
      </ProgressSectionTop>
      <ProgressBar progress={progress} />
    </ProgressSection>
  );
}

import React, {memo, ReactNode} from 'react';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';

import {Section, SectionTitle, SectionSubTitle} from './NMMSection.styles';
import {ESpaceDetailsContentSections} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/types';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from '../../../types/dto/EUserRoleCognito';

interface IProps {
  id: string;
  title: string;
  subtitle?: string;
  isEditOn?: boolean;
  sectionPadding?: string;
  sectionMargin?: string;
  titleFontSize?: string;
  titleMarginBottom?: string;
  containerTitle?: ReactNode;
  onEdit?: () => void;
  children: ReactNode;
  noEditModeSections?: ESpaceDetailsContentSections[];
}

const NMMSection = ({
  id,
  title,
  subtitle,
  isEditOn,
  sectionPadding,
  sectionMargin,
  titleFontSize,
  titleMarginBottom,
  containerTitle,
  onEdit,
  children,
  noEditModeSections,
}: IProps) => {
  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  const noEditMode =
    noEditModeSections?.includes(id as ESpaceDetailsContentSections) || isAgent;

  return (
    <Section
      id={id}
      sectionPadding={sectionPadding}
      sectionMargin={sectionMargin}
    >
      {containerTitle && <div>{containerTitle}</div>}

      <SectionTitle
        titleFontSize={titleFontSize}
        titleMarginBottom={titleMarginBottom}
      >
        {title}{' '}
        {!isEditOn && onEdit && !noEditMode && (
          <NW2Button
            minimized
            size='small'
            buttonType='tertiary'
            icon={<Icon transparent icon='NW2_EDIT' />}
            data-testid={`edit ${id}`}
            onClick={onEdit}
          />
        )}
      </SectionTitle>

      {subtitle && <SectionSubTitle>{subtitle}</SectionSubTitle>}

      <div>{children}</div>
    </Section>
  );
};

export default memo(NMMSection);

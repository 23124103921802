import React from 'react';
import {useField, useForm} from 'react-final-form';

import WysiwygEditor, {TOnChangeArgs} from 'view/components/WYSIWYGEditor';
import ErrorMessage from 'view/components/NW2FormItem/components/ErrorMessage';

type TProps = {
  initialHtmlString: string;
  isTouched?: boolean;
  htmlStringErrors?: any;
};

function AnnouncementWysiwygEditor({
  initialHtmlString,
  isTouched,
  htmlStringErrors,
}: TProps) {
  const form = useForm();
  const EditorField = useField('htmlString');

  const onChangeHandler = (value: TOnChangeArgs) => {
    EditorField.input.onChange(value.rootNodeContext ? value.htmlString : '');
    form.change('rootNodeContext', value.rootNodeContext);
  };

  return (
    <div>
      <WysiwygEditor
        onChangeHandler={onChangeHandler}
        initialHtmlString={initialHtmlString}
        hasError={htmlStringErrors && isTouched}
        label='Body'
      />
      <ErrorMessage
        hasError={htmlStringErrors && isTouched}
        errorData={htmlStringErrors}
        showAllValidationErrors
      />
    </div>
  );
}

export default AnnouncementWysiwygEditor;

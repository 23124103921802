import {TEditFormValues} from '../NW2VenueDescription.types';
import {validatorDescription} from './editDescription';
import {validatorCharacters} from './editCharacter';

export const validatorDescriptionWithCharacters = (
  values: TEditFormValues,
) => ({
  ...validatorDescription(values),
  ...validatorCharacters(values),
});

import {
  iconColor,
  whiteColor,
  primaryColor,
  secondaryColor,
  antdLayoutBackgroundColor,
  textColor,
  footerColor,
  NW2Primary,
  blackColor,
} from 'constants/styleVars';

export default {
  bodyBackground: antdLayoutBackgroundColor,
  navigation: {
    background: whiteColor,
    color: textColor,
  },
  logo: {
    Img1: NW2Primary,
    Img2: NW2Primary,
    Img3: NW2Primary,
    Text: blackColor,
  },
  footer: {
    background: footerColor,
    color: whiteColor,
  },
  primaryColor,
  secondary: secondaryColor,
  iconColor,
};

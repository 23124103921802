import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';

import {
  offsetDef,
  offsetXLg,
  fontSizeXXLg,
  infoModalWidth,
  offsetXSm,
} from 'constants/styleVars';

export const Content = styled.div`
  text-align: center;
  margin-top: ${offsetDef};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: ${fontSizeXXLg};
  margin-bottom: ${offsetXSm};
`;

export const Capture = styled.img`
  margin: ${offsetXLg} 0;
`;

export const StyledButton = styled(Button)`
  min-width: ${infoModalWidth};
`;

type TProps = {
  hideModal: () => void;
  image: string;
  welcomeTitle: string;
  welcomeBodyText: string;
  btnText: string;
};

const PropManagerWelcomeModal = ({
  hideModal,
  image,
  welcomeTitle,
  welcomeBodyText,
  btnText,
}: TProps) => (
  <Content>
    <Title>{welcomeTitle}</Title>
    <p>{welcomeBodyText}</p>
    <Capture alt='newSpace' src={image} />

    <StyledButton type='primary' onClick={hideModal}>
      {btnText}
    </StyledButton>
  </Content>
);

export default PropManagerWelcomeModal;

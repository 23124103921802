import {useCallback, useEffect, useState} from 'react';

type TProps = {
  isOnPageLoad?: boolean;
};

const defaultProps: TProps = {isOnPageLoad: false};

export const useBeforeUnload = ({isOnPageLoad} = defaultProps) => {
  const [isBeforeUnload, setIsBeforeUnload] = useState(false);
  const confirm = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    return (e.returnValue =
      'All changes will be lost. Do you really want to close this page?');
  }, []);

  const addBeforeUnload = useCallback(() => {
    window.addEventListener('beforeunload', confirm);
    setIsBeforeUnload(true);
  }, [confirm]);

  const removeBeforeUnload = useCallback(() => {
    if (isBeforeUnload) {
      window.removeEventListener('beforeunload', confirm);
    }
  }, [isBeforeUnload, confirm]);

  useEffect(() => {
    if (isOnPageLoad) addBeforeUnload();

    return () => removeBeforeUnload();
  }, [isOnPageLoad, addBeforeUnload, removeBeforeUnload]);

  return {
    addBeforeUnload,
    removeBeforeUnload,
  };
};

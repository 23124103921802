import React, {useEffect} from 'react';
import styled from 'styled-components';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';

import {NW2DateSection} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker';

import {mdBp, NW2Gray50Color, offsetXXLg} from 'constants/styleVars';
import {
  StyledDrawerContent,
  StyledDrawerContentHeader,
} from 'view/components/NW2Datepicker/NW2DatePicker/NW2MultiDatePickerMobile.styles';
import {TMultiDateChangeFn} from 'view/components/NW2Datepicker/NW2DatePicker/NW2MultiDatePicker.types';
import {TDateType} from 'types/dto/ISearch.types';
import {GroupsDateRange} from './GroupsDateRange';

const HeaderBox = styled.div`
  width: 100%;

  @media (min-width: ${mdBp}px) {
    padding-top: ${offsetXXLg};
  }
`;

const bodyStyle = {
  padding: 0,
  backgroundColor: NW2Gray50Color,
};

interface IProps {
  startDate: TDateType;
  endDate: TDateType;
  isOpened: boolean;
  nightsCount: number;
  onDateChange: TMultiDateChangeFn;
  onToggleDatePicker: () => void;
}
export const GroupsDatePickerMobile = ({
  startDate,
  endDate,
  isOpened,
  onDateChange,
  onToggleDatePicker,
  nightsCount,
}: IProps) => {
  useEffect(() => {
    if (isOpened) {
      document.body.style.width = '100%'; // remove the padding added by antd drawer to body
    }
  }, [isOpened]);

  return (
    <StyledAntdDrawer
      title={null}
      onClose={onToggleDatePicker}
      open={isOpened}
      height='auto'
      bodyStyle={bodyStyle}
      padding='0'
    >
      <StyledDrawerContentHeader showDivider>
        <HeaderBox>
          <GroupsDateRange
            startDate={startDate}
            endDate={endDate}
            nightsCount={nightsCount}
            onToggle={onToggleDatePicker}
          />
        </HeaderBox>
      </StyledDrawerContentHeader>

      <StyledDrawerContent>
        <NW2DateSection
          startDate={startDate}
          endDate={endDate}
          onChange={onDateChange}
        />
      </StyledDrawerContent>
    </StyledAntdDrawer>
  );
};

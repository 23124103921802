import React, {useCallback, useMemo} from 'react';

import Icon from 'view/components/Icon';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';

import {getCapacityRange} from 'view/venue/helpers/spaces';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  ECreateOfferFormFields,
  TAssignRoomType,
  TCreateOfferExtraItem,
  TCreateOfferFormValues,
  TInitialExtras,
} from '../../types';
import {EEventType, ERoomSchemaNames, ISpacesDataItem} from 'types/venue';
import useSpaces from 'view/venue/hooks/useSpaces';
import useWorkInProgress from 'view/venue/hooks/useWorkInProgress';
import {TPaidDuration} from 'view/venue/NW2VenueProfile/components/AddSpace/types';

import {
  ButtonCreate,
  ExistedRoomsSubTitle,
  ExistedRoomsTitle,
  ExistedRoomsWrapper,
  RoomCard,
  RoomCardImg,
  RoomCardInfo,
  RoomCardLabel,
  RoomCardParticipants,
} from './StepSpaceSelection.styles';
import {EDimensionsFormFields} from 'view/venue/NW2VenueProfile/components/NMMSpaces/common/NMMSpacesDetails/components/Dimensions/DimensionsForm.types';
import {dimensionsConverter} from 'utils/venueUtils';

import {getFlatMapUnits} from 'view/customer/helpers';

interface IProps {
  initialExtras: TInitialExtras;
  paidDurationOptions: TPaidDuration[];
  requestedCapacity: number;
  requestedSeatingPlan: ERoomSchemaNames;
  defaultVenueFloors: number;
  isFirstUnit: boolean;
  handleSetAssignRoomType: (roomType: TAssignRoomType) => void;
  handleSetInitialState: (formValues: TCreateOfferFormValues) => void;
  handleSetItemsToOffer: (props: {
    unitId?: number;
    extras?: TCreateOfferExtraItem[];
    eventType?: EEventType;
  }) => void;
  checkExistedItemAvailability: (unitId: number) => boolean;
  handleInitUnit: (unit: ISpacesDataItem) => void;
}
export function StepSpaceSelection({
  initialExtras,
  paidDurationOptions,
  requestedCapacity,
  requestedSeatingPlan,
  defaultVenueFloors,
  isFirstUnit,
  handleSetAssignRoomType,
  handleSetInitialState,
  handleSetItemsToOffer,
  checkExistedItemAvailability,
  handleInitUnit,
}: IProps) {
  const [spaces] = useSpaces();
  const [wip] = useWorkInProgress();

  const existedSpaces = useMemo(
    () =>
      getFlatMapUnits(spaces).filter(({unitCapacities}) => {
        const item = unitCapacities?.find(
          ({setupStyle}) => setupStyle === requestedSeatingPlan,
        );

        if (!item) return false;

        return (
          item.capacity >= requestedCapacity &&
          item.setupStyle === requestedSeatingPlan
        );
      }),
    [requestedCapacity, requestedSeatingPlan, spaces],
  );

  const wipSpaces = useMemo(
    () =>
      wip.filter(
        ({capacity, unitCapacities}) =>
          capacity >= requestedCapacity &&
          unitCapacities?.some(
            ({setupStyle}) => setupStyle === requestedSeatingPlan,
          ),
      ),
    [requestedCapacity, requestedSeatingPlan, wip],
  );

  const handleCreateNewRoom = useCallback(() => {
    handleSetAssignRoomType('new');

    handleSetInitialState({
      [ECreateOfferFormFields.NAME]: '',
      [ECreateOfferFormFields.IMAGES]: [],
      [ECreateOfferFormFields.COVER_IMAGE]: '',
      [ECreateOfferFormFields.AMENITIES]: [],
      [ECreateOfferFormFields.CAPACITY]: {
        [requestedSeatingPlan]: {
          capacity: requestedCapacity,
          checkbox: true,
        },
      },
      [ECreateOfferFormFields.FLOOR]: String(defaultVenueFloors),
      [ECreateOfferFormFields.DIMENSIONS]: {
        [EDimensionsFormFields.AREA]: '1',
        [EDimensionsFormFields.AREA_FEET]: String(
          dimensionsConverter('m', 1, true, 5),
        ),
        [EDimensionsFormFields.CEILING_HEIGHT]: '1',
        [EDimensionsFormFields.CEILING_HEIGHT_FEET]: String(
          dimensionsConverter('m', 1, false, 5),
        ),
      },
      [ECreateOfferFormFields.DESCRIPTION]: '',
      [ECreateOfferFormFields.PRICE_HOUR]: '',
      [ECreateOfferFormFields.PRICE_HALF_DAY]: '',
      [ECreateOfferFormFields.PRICE_DAY]: '',
      [ECreateOfferFormFields.PAID_DURATION]: paidDurationOptions[0].value,
      [ECreateOfferFormFields.SUPPLIER]: '',
      ...initialExtras,
    });
  }, [
    defaultVenueFloors,
    handleSetAssignRoomType,
    handleSetInitialState,
    initialExtras,
    paidDurationOptions,
    requestedCapacity,
    requestedSeatingPlan,
  ]);

  const handleSetExistedRoom = (unit: ISpacesDataItem) => {
    handleSetAssignRoomType('existed');
    handleInitUnit(unit);
    handleSetItemsToOffer({unitId: unit.id, eventType: EEventType.DAY});
  };

  const handleCreateWipRoom = (unit: ISpacesDataItem) => {
    handleSetAssignRoomType('wip');
    handleInitUnit(unit);
  };

  return (
    <>
      <ButtonCreate onClick={handleCreateNewRoom}>
        <b>Create a new room</b>
        <span>(room details will be automatically saved)</span>
      </ButtonCreate>

      {(wipSpaces.length > 0 || existedSpaces.length > 0) && (
        <ExistedRoomsWrapper>
          <ExistedRoomsTitle>OR</ExistedRoomsTitle>
          <ExistedRoomsSubTitle>
            Choose available & fitting room
          </ExistedRoomsSubTitle>

          {existedSpaces.map((unit) => {
            const image = unit.documents.find(({isCover}) => isCover);
            const isDisabled =
              !isFirstUnit && checkExistedItemAvailability(unit.id);

            return (
              <RoomCard
                key={unit.id}
                onClick={(e) => {
                  isDisabled ? e.preventDefault() : handleSetExistedRoom(unit);
                }}
                isDisabled={isDisabled}
              >
                <RoomCardImg isDisabled={isDisabled}>
                  <img src={image?.url} alt={image?.name} />
                </RoomCardImg>
                <span>
                  <b>{unit.name}</b>
                  <RoomCardInfo>
                    <RoomCardParticipants isDisabled={isDisabled}>
                      <Icon icon='NW2_THREE_PEOPLE' />{' '}
                      <span>
                        {getCapacityRange(
                          unit.unitCapacities,
                          unit.capacity,
                          ERoomType.MEETING_ROOM,
                        )}
                      </span>
                    </RoomCardParticipants>

                    {isDisabled ? (
                      <NW2PopoverDrawer
                        isMobile={false}
                        header='Not available'
                        placement='topRight'
                        clickable={false}
                        body={
                          <>
                            This space has already been booked or offered.
                            Please select another available space or create a
                            new one.
                          </>
                        }
                      >
                        <RoomCardLabel isDisabled>Not available</RoomCardLabel>
                      </NW2PopoverDrawer>
                    ) : (
                      <RoomCardLabel>Save 8 steps!</RoomCardLabel>
                    )}
                  </RoomCardInfo>
                </span>
              </RoomCard>
            );
          })}

          {wipSpaces.map((unit) => (
            <RoomCard key={unit.id} onClick={() => handleCreateWipRoom(unit)}>
              <RoomCardImg>
                <Icon icon='IMAGE' />
              </RoomCardImg>
              <span>
                <b>{unit.name}</b>
                <RoomCardInfo>
                  <RoomCardParticipants>
                    <Icon icon='NW2_THREE_PEOPLE' />{' '}
                    <span>
                      {getCapacityRange(
                        unit.unitCapacities,
                        unit.capacity,
                        ERoomType.MEETING_ROOM,
                      )}
                    </span>
                  </RoomCardParticipants>
                </RoomCardInfo>
              </span>
            </RoomCard>
          ))}
        </ExistedRoomsWrapper>
      )}
    </>
  );
}

import styled from 'styled-components';

import {fontSizeSm, lineHeightMd, offsetXSm} from 'constants/styleVars';

export const ButtonsSection = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const RegErrorText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXSm};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
`;

import React from 'react';
import styled from 'styled-components';

import {
  errorColor,
  fontWeightBold,
  whiteColor,
  fontWeightNormal,
} from 'constants/styleVars';

import {pluralize} from 'utils/helpers';

export const StyledDiv = styled.div<{isCapacityLow: boolean}>`
  color: ${({isCapacityLow}) => (isCapacityLow ? errorColor : whiteColor)};
  font-weight: ${({isCapacityLow}) =>
    isCapacityLow ? fontWeightBold : fontWeightNormal};
`;

type TProps = {
  capacity: number;
  isRoomCapacityLow: boolean;
  isNotAvailable: boolean;
};

function RoomCapacityText({
  capacity,
  isRoomCapacityLow,
  isNotAvailable,
}: TProps) {
  return (
    <StyledDiv isCapacityLow={isRoomCapacityLow && !isNotAvailable}>
      {`Max capacity: ${pluralize('people', capacity)} ${
        capacity > 1 ? `(${capacity + 1} max)` : ''
      }`}
    </StyledDiv>
  );
}

export default RoomCapacityText;

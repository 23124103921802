import {useMemo} from 'react';

import {ERoomType} from 'types/dto/ERoomType.type';
import {EResourcesType} from 'types/dto/IExtras.type';
import {
  makeFastSearchFilterDaysPayload,
  makeGroupsFastSearchFilterDaysPayload,
  makeMultiSearchPayload,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import useSearchData from 'view/venue/hooks/useSearchData';
import {useIsFastSearchEnabled} from 'view/venue/hooks/useIsFastSearchEnabled';
import {useCommonExtras} from 'view/venue/hooks/useCommonExtras';
import {useGroupsRoomsData} from 'view/venue/hooks/search/useGroupsRoomsData';

export function useSearchFilterDays() {
  const [extrasOption] = useCommonExtras();

  const {initialSearchData, bedroomsCatering} = useSearchData();

  const groupsRooms = useGroupsRoomsData();

  const [isFastSearchEnabled] = useIsFastSearchEnabled();

  const filteredBedroomExtras = useMemo(
    () => extrasOption.filter((item) => item.type === EResourcesType.BEDROOM),
    [extrasOption],
  );

  const filterDays = useMemo(() => {
    const isGroups = initialSearchData.roomType === ERoomType.GROUPS;
    const {multiSearchPayload} = makeMultiSearchPayload({
      meetingRequestData: initialSearchData.meetingRequestData,
      bedroomsCatering,
      filteredBedroomExtras,
    });

    return isFastSearchEnabled
      ? isGroups
        ? makeGroupsFastSearchFilterDaysPayload({
            multiSearchPayload,
            groupsRooms,
            extrasOption,
          })
        : makeFastSearchFilterDaysPayload(multiSearchPayload)
      : multiSearchPayload;
  }, [
    bedroomsCatering,
    extrasOption,
    filteredBedroomExtras,
    groupsRooms,
    initialSearchData.meetingRequestData,
    initialSearchData.roomType,
    isFastSearchEnabled,
  ]);

  return [filterDays];
}

import React, {useState, useCallback, useEffect} from 'react';
import {RadioChangeEvent, message} from 'antd';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon/Icon';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import NW2Button from 'view/components/NW2Button/NW2Button';
import NW2AddEditBillingAddressModalDrawerForm from '../../NW2CustomerProfile/components/NW2AddEditBillingAddressModalDrawerForm';

import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {getAddressStringFromBillingAddress} from 'utils/stringUtils';
import {setCustomerBillingAddressPopup} from 'store/app/appSlice';
import {
  updateDefaultCustomerBillingAddress,
  deleteCustomerBillingAddress,
} from 'store/app/apiActions';

import {EBillingAddressFormType} from '../../../components/BillingAddress/types';
import {modalSmallWidth} from 'constants/styleVars';
import {
  Title,
  Container,
  Wrapper,
  StyledParagraph,
  StyledRadioGroup,
  Section,
  StyledRadio,
  AddressRow,
  ExtraRow,
  ExtraText,
  ExtraParagraph,
  ActionSection,
  BodyBox,
  StyledIcon,
  FooterBox,
  StyledSpan,
  StyledNW2Button,
} from '../NW2BillingAddressPopup.styles';

const TOAST_MESSAGE = 'Your billing address was successfully deleted.';

function NW2BillingAddressList() {
  const dispatch = useAppDispatch();
  const [addressFormData, setAddressFormData] =
    useState<ICustomerBillingAddress | null>(null);
  const [billingAddressData, setBillingAddressData] =
    useState<ICustomerBillingAddress | null>(null);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);
  const billingAddress: ICustomerBillingAddress[] = useAppSelector(
    ({app}) => app.user.billingAddress,
  );

  const defaultBillingAddress = useCallback(() => {
    return billingAddress?.filter((item) => item.isDefault)[0].uuid;
  }, [billingAddress]);

  const [value, setValue] = useState(defaultBillingAddress);

  const showAddAddressForm = (address: ICustomerBillingAddress) => () =>
    setAddressFormData(address);
  const closeAddAddressForm = () => setAddressFormData(null);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    dispatch(updateDefaultCustomerBillingAddress(e.target.value));
  };

  const onCloseDeleteModal = () => {
    setBillingAddressData(null);
  };

  const onShowDeleteModal = (data: ICustomerBillingAddress) => () => {
    setBillingAddressData(data);
  };

  const onDelete = (uuid: string | undefined) => () => {
    if (uuid) {
      dispatch(
        deleteCustomerBillingAddress(uuid, () => {
          setBillingAddressData(null);
          message.success({
            type: 'success',
            content: TOAST_MESSAGE,
            key: TOAST_MESSAGE,
          });
        }),
      );
    }
  };

  useEffect(() => {
    billingAddress.length !== 0
      ? setValue(defaultBillingAddress)
      : dispatch(
          setCustomerBillingAddressPopup({
            setCustomerBillingAddressPopup: false,
          }),
        );
  }, [billingAddress.length, defaultBillingAddress, dispatch]);

  const modalBody = (
    <BodyBox>
      <p>
        You are about to delete the below billing address.&nbsp;
        <StyledSpan>Do you want to proceed?</StyledSpan>
      </p>
      <p>{getAddressStringFromBillingAddress(billingAddressData)}</p>
      {billingAddressData?.costCenter && (
        <p>
          <ExtraText>Cost centre: </ExtraText>
          <span>{`${billingAddressData?.costCenter}`}</span>
        </p>
      )}
      {billingAddress.length === 1 && (
        <p>Once deleted you will be returned to the previous page.</p>
      )}
    </BodyBox>
  );

  const modalFooter = (
    <FooterBox>
      <StyledNW2Button
        inline
        buttonType='secondary'
        onClick={onCloseDeleteModal}
      >
        Cancel
      </StyledNW2Button>
      <NW2Button
        inline
        buttonType='primary'
        onClick={onDelete(billingAddressData?.uuid)}
      >
        Delete address
      </NW2Button>
    </FooterBox>
  );

  return (
    <Container>
      <Title>Your billing addresses</Title>
      {isDesktop && (
        <Wrapper>
          <div />
          <StyledParagraph>Cost centre</StyledParagraph>
          <StyledParagraph>Actions</StyledParagraph>
        </Wrapper>
      )}
      <StyledRadioGroup onChange={onChange} value={value}>
        {billingAddress.map((item) => (
          <Section key={item.uuid}>
            <StyledRadio value={item.uuid}>
              <div>
                <AddressRow>
                  {getAddressStringFromBillingAddress(item)}
                </AddressRow>
                {!isDesktop && item.costCenter && (
                  <ExtraRow>
                    <ExtraText>Cost centre:</ExtraText> {item?.costCenter}
                  </ExtraRow>
                )}
              </div>
            </StyledRadio>
            {isDesktop && <ExtraParagraph> {item.costCenter} </ExtraParagraph>}
            <ActionSection>
              <StyledIcon
                icon='NW2_CANCEL_BIN'
                onClick={onShowDeleteModal(item)}
              />
              <Icon icon='NW2_EDIT' onClick={showAddAddressForm(item)} />
            </ActionSection>
          </Section>
        ))}
      </StyledRadioGroup>

      <NW2ModalDrawer
        header='Delete Billing Address'
        body={modalBody}
        footer={modalFooter}
        isShowed={!!billingAddressData}
        onClose={onCloseDeleteModal}
        isMobile={isMobile}
        modalWidth={modalSmallWidth}
        drawerHeight='auto'
      />

      <NW2AddEditBillingAddressModalDrawerForm
        type={EBillingAddressFormType.EDIT}
        isMobile={isMobile}
        isModalShowed={!!addressFormData}
        onModalClose={closeAddAddressForm}
        initialData={addressFormData}
      />
    </Container>
  );
}

export default NW2BillingAddressList;

import React, {useEffect, useRef} from 'react';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import {
  Dropdown,
  DropdownItem,
  Text,
} from 'view/components/WYSIWYGEditor/components/ToolbarPlugin.styles';
import {$wrapNodes} from '@lexical/selection';
import {$createHeadingNode, HeadingTagType} from '@lexical/rich-text';

type TBlockOptionsDropdownListProps = {
  editor: LexicalEditor;
  blockType: string;
  toolbarRef: React.MutableRefObject<HTMLElement | null>;
  setShowBlockOptionsDropDown: React.Dispatch<React.SetStateAction<boolean>>;
};

function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}: TBlockOptionsDropdownListProps) {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar && dropDown) {
      const {top, left} = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;

      const handle = (event: Event) => {
        const target = event?.target;

        if (
          target instanceof HTMLElement &&
          !dropDown.contains(target) &&
          !toolbar.contains(target)
        ) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, toolbarRef, setShowBlockOptionsDropDown]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, $createParagraphNode);
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatHeading = (headingType: HeadingTagType) => () => {
    if (blockType !== headingType) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(headingType));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };
  const headings: {type: HeadingTagType; label: string}[] = [
    {type: 'h5', label: 'Heading 5'},
  ];

  return (
    <Dropdown ref={dropDownRef}>
      <DropdownItem
        onClick={formatParagraph}
        isActive={blockType === 'paragraph'}
      >
        <Text>Normal text</Text>
      </DropdownItem>
      {headings.map(({type, label}) => (
        <DropdownItem
          key={type}
          onClick={formatHeading(type)}
          isActive={blockType === type}
        >
          <Text>{label}</Text>
        </DropdownItem>
      ))}
    </Dropdown>
  );
}

export default BlockOptionsDropdownList;

import React, {FC} from 'react';
import styled from 'styled-components';

import TextValue from '../TextValue';

import {offsetSm} from 'constants/styleVars';

type Props = {
  label: string;
  value: string | number | JSX.Element;
  labelWidth?: number;
  primaryLabel?: boolean;
  secondaryLabel?: boolean;
  errorLabel?: boolean;
  successLabel?: boolean;
  boldLabel?: boolean;
  primaryValue?: boolean;
  secondaryValue?: boolean;
  errorValue?: boolean;
  successValue?: boolean;
  boldValue?: boolean;
  valueId?: string;
  fontSize?: string;
  lineHeight?: string;
  color?: string;
};

const Container = styled.div<{labelWidth: number}>`
  display: grid;
  grid-template-columns: ${({labelWidth}) => labelWidth}px 1fr;
  align-items: center;
  margin-bottom: ${offsetSm};
`;

const TextRow: FC<Props> = (props: Props) => {
  const {
    label = '',
    value = '',
    labelWidth = 120, // in case of changes to 120px, test that all usages are good with this new default width.
    primaryLabel = false,
    secondaryLabel = false,
    errorLabel = false,
    successLabel = false,
    boldLabel = false,
    primaryValue = false,
    secondaryValue = false,
    errorValue = false,
    successValue = false,
    boldValue = false,
    valueId = '',
    fontSize,
    lineHeight,
    color,
  } = props;

  return (
    <Container labelWidth={labelWidth}>
      <TextValue
        primary={primaryLabel}
        secondary={secondaryLabel}
        error={errorLabel}
        success={successLabel}
        bold={boldLabel}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
      >
        {label}
      </TextValue>
      <TextValue
        primary={primaryValue}
        secondary={secondaryValue}
        error={errorValue}
        success={successValue}
        bold={boldValue}
        id={valueId}
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
      >
        {value}
      </TextValue>
    </Container>
  );
};

export default TextRow;

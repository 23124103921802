import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2ModalMeetingsTwoColumns from 'view/components/NW2ModalMeetingsTwoColumns/NW2ModalMeetingsTwoColumns';
import NW2MeetingRequestsList from './components/MeetingRequestsList';
import SummaryRequestBlock from './components/SummaryRequestBlock';
import NW2Loader from 'view/components/NW2Loader';

import useSearchTemplateData from './hooks/useSearchTemplateData';
import {useAppSelector} from 'store/hooks';
import {useCommonExtras} from 'view/venue/hooks/useCommonExtras';

type TProps = {
  onClose: () => void;
  isVenuesListPage?: boolean;
};
const SearchTemplatePopup = ({onClose, isVenuesListPage}: TProps) => {
  const timeData = useAppSelector(({search}) => search.initialTimeData);
  const searchTemplateData = useSearchTemplateData(timeData, isVenuesListPage);

  const firstDayDate = timeData[0]?.timeStart;
  const lastDayDate = timeData[timeData.length - 1]?.timeEnd;

  const isExtrasLoading = useSelector((state) =>
    _get(state, 'venue.resourcesFormLoading'),
  );

  useCommonExtras();

  return (
    <NW2ModalMeetingsTwoColumns
      title='Let us know your meeting needs'
      onToggle={onClose}
      colLeft={
        isExtrasLoading ? (
          <NW2Loader height='100%' />
        ) : (
          <NW2MeetingRequestsList
            firstDayDate={firstDayDate}
            lastDayDate={lastDayDate}
            {...searchTemplateData}
          />
        )
      }
      colRight={
        <SummaryRequestBlock
          meetingRequestData={searchTemplateData.meetingRequestData}
          bedroomsCatering={searchTemplateData.bedroomsCatering}
          closePopupHandler={onClose}
          setIsSectionExpanded={searchTemplateData.setIsSectionExpanded}
        />
      }
    />
  );
};

export default SearchTemplatePopup;

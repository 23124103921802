import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';

export function useVenueDetailsId() {
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const venueDetailsWorkDeskId: number = useSelector((state) =>
    _get(state, 'workDesks.workDesksDetails.id', 0),
  );
  const venueDetailsMeetingRoomId = useAppSelector(
    ({venueDetails}) => venueDetails.details?.accommodationId,
  );

  const venueDetailsId =
    roomType === ERoomType.WORK_SPACE
      ? venueDetailsWorkDeskId
      : venueDetailsMeetingRoomId || 0;

  return [venueDetailsId];
}

import React from 'react';
import {Field} from 'react-final-form';

import ErrorMessage from 'view/components/NW2FormItem/components/ErrorMessage';

import {AddImages} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddImages';

import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {ISpacesDataItemImage} from 'types/venue';
import {imagesFieldRules} from 'utils/finalFormFieldRules';
import {PageImagesUploadInputWrapper} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {ESpaceDetailsFormFields} from '../../types';

interface IProps {
  imagesToUpload: (TFileWithPreview | ISpacesDataItemImage)[];
  onImageUpload: (file: TFileWithPreview[]) => void;
}

export function SpaceDetailsImagesWip({imagesToUpload, onImageUpload}: IProps) {
  return (
    <>
      <Field name={ESpaceDetailsFormFields.FILES} validate={imagesFieldRules}>
        {({input, meta}) => {
          const hasError = meta.error && (meta.touched || meta.submitFailed);

          return (
            <>
              <AddImages
                fieldName={ESpaceDetailsFormFields.FILES}
                fieldCoverName='coverImageName'
                filesState={imagesToUpload as TFileWithPreview[]}
                handleSetFiles={onImageUpload}
                onChange={input.onChange}
                itemWidth='30%'
                isInline
              />

              {hasError && (
                <PageImagesUploadInputWrapper>
                  <ErrorMessage
                    hasError={hasError}
                    errorData={meta.error}
                    showAllValidationErrors
                  />
                </PageImagesUploadInputWrapper>
              )}
            </>
          );
        }}
      </Field>
    </>
  );
}

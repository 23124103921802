import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {setNW2MultiModalComponent} from 'store/app/appSlice';
import {ENW2MultiModalActiveComponentId} from 'types/app';

export default function useNavigateFromInfoPage(page: string) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCallback(() => {
    dispatch(
      setNW2MultiModalComponent({id: ENW2MultiModalActiveComponentId.EMPTY}),
    );
    if (page) {
      navigate(page);
    }
  }, [page, dispatch, navigate]);
}

import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {MODAL_HEADER_HEIGHT} from 'constants/app';

import {
  borderRadiusLg,
  errorColor,
  fontSizeMd,
  fontSizeSm,
  fontSizeXXLg,
  fontWeightExtraBold,
  lineHeightLg,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Info500Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  whiteColor,
  scrollBarWidth,
} from 'constants/styleVars';

const ACTIONS_HEIGHT = '88px';
const ICON_SIZE = '26px';

export const Wrapper = styled.div<{hasActions: boolean}>`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - ${MODAL_HEADER_HEIGHT}px - ${offsetXXLg}*2);
  ${({hasActions}) =>
    hasActions && `padding-bottom: calc(${ACTIONS_HEIGHT} - ${offsetSm});`};
`;

export const ScrollArea = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: ${scrollBarWidth};
  margin-right: -${scrollBarWidth};
`;

export const ScrollContainer = styled.div<{maxHeight: string}>`
  max-height: ${({maxHeight}) => maxHeight};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr ${ICON_SIZE};
  grid-column-gap: ${offsetXLg};
  align-items: center;
`;

export const FormRow = styled(Row)`
  align-items: start;

  button {
    margin-top: ${offsetXSm};
  }
`;

export const JustInvitedRow = styled(Row)`
  margin-top: ${offsetXLg};

  &:last-child {
    margin-top: 16px;
  }
`;

export const AlreadyInvitedRow = styled(Row)`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin-top: ${offsetXLg};
`;

export const AlreadyInvited = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${offsetXLg};
  margin-top: ${offsetDef};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const JustInvited = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
`;

export const AlreadyInvitedTitle = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${fontSizeXXLg};
  font-weight: ${fontWeightExtraBold};
  color: ${NW2Gray600Color};
  margin-bottom: ${offsetSm};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const Actions = styled.div<{isHasScroll: boolean}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${offsetSm};
  height: ${ACTIONS_HEIGHT};
  border-top: 1px solid ${NW2Gray200Color};
  padding: ${offsetXLg};
  background-color: ${whiteColor};

  ${({isHasScroll}) =>
    isHasScroll &&
    `box-shadow: 0px -9.14px 19.78px rgba(0, 0, 0, 0.035), 0px -4.85px 10.51px rgba(0, 0, 0, 0.0283), 0px -2.02px 4.37px rgba(0, 0, 0, 0.0197);`}
`;

export const StyledButtonIcon = styled(NW2Button)`
  padding: 0;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
`;

export const InvitesLeft = styled.div<{isMaxReached: boolean}>`
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
  margin: ${offsetXLg} 0 ${offsetXSm};
  padding-left: ${offsetSm};
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: ${({isMaxReached}) =>
      isMaxReached ? errorColor : NW2Info500Color};
    border-radius: ${borderRadiusLg} 0 0 ${borderRadiusLg};
  }
`;

export const NoInvites = styled.div`
  color: ${NW2Gray600Color};
`;

export const InfoBlock = styled.div`
  margin: ${offsetDef} 0 ${offsetSm};
`;

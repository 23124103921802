import React, {useMemo} from 'react';
import {useFormState} from 'react-final-form';

import {RoomSchemaItem} from './RoomSchemaItem';

import {ERoomSchemaNames} from 'types/venue';
import {Wrapper, Error} from './AddRoomSchema.styles';

export const AddRoomSchema = ({
  fieldName,
  columns,
  excludedItems,
}: {
  fieldName: string;
  columns?: number;
  excludedItems?: ERoomSchemaNames[];
}) => {
  const {values, submitFailed, errors} = useFormState();
  const formError = !!(errors?.error && submitFailed);

  const items = useMemo(
    () =>
      excludedItems?.length
        ? Object.values(ERoomSchemaNames).filter(
            (item) => !excludedItems.includes(item),
          )
        : Object.values(ERoomSchemaNames),
    [excludedItems],
  );

  return (
    <>
      <Wrapper columns={columns}>
        {items.map((roomSchema) => {
          const enabled: boolean = values[fieldName][roomSchema]?.checkbox;

          return (
            <RoomSchemaItem
              key={roomSchema}
              disabled={!enabled}
              hasError={formError}
              name={roomSchema}
              fieldName={fieldName}
              width='100%'
            />
          );
        })}
      </Wrapper>
      {formError && (
        <Error columns={columns || 2}>
          Please select at least 1 room setup style.
        </Error>
      )}
    </>
  );
};

// TODO: Remove
import {
  EPersonOfInterestType,
  IPeopleOfInterest,
  IVenueLegalContact,
  LegalEntityTypeCaption,
} from 'types/venue';

export enum ESupplierLegalDetailsFormFields {
  CEO_NAME = 'ceoName',
  LEGAL_PHONE = 'legalPhone',
  EMAIL = 'email',
  COMPANY_NAME = 'companyName',
  TAX_NUMBER = 'taxNumber',
  STREET_HOUSE_NUMBER = 'streetHouseNumber',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  COUNTRY = 'country',
}

export enum ESupplierBusinessInfoFormFields {
  LOCAL_LEGAL_ENTITY_TYPE = 'localLegalEntityType',
  LEGAL_ENTITY_TYPE = 'legalContact.legalEntityType',
  COMPANY_NAME = 'legalContact.companyName',
  STREET_NAME = 'legalContact.streetName',
  BUILDING_NUMBER = 'legalContact.buildingNumber',
  COUNTRY = 'legalContact.country',
  COUNTRY_CODE = 'legalContact.countryCode',
  REGION = 'legalContact.region',
  CITY = 'legalContact.city',
  POSTAL_CODE = 'legalContact.postalCode',
  LEGAL_PHONE = 'legalContact.legalPhone',
  REGISTRATION_NUMBER = 'legalContact.registrationNumber',
  TAX_NUMBER = 'legalContact.taxNumber',
  BUSINESS_OWNER_SWITCH = 'isBusinessSwitchActive',
  CONTROLLER_SWITCH = 'isControllerSwitchActive',
}

export type TSupplierBusinessInfo = {
  legalContact: Omit<IVenueLegalContact, 'legalPhone'> & {legalPhone: string};
  [EPersonOfInterestType.OWNER]: IPeopleOfInterest;
  [EPersonOfInterestType.CONTROLLER]: IPeopleOfInterest;
  [EPersonOfInterestType.SIGNATORY]: IPeopleOfInterest;
  isBusinessSwitchActive: boolean;
  isControllerSwitchActive: boolean;
  localLegalEntityType: keyof typeof LegalEntityTypeCaption;
};

export interface IProps {
  disabledItems?: ESupplierLegalDetailsFormFields[];
  adaptiveColumnNumber?: number;
  gap?: number;
}

export interface ILegalDetailsBlocks {
  title: string;
  content: string | number | null;
}

import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';

import {PageTitle} from 'view/components/Typography';
import {offsetDef, offsetXLg} from 'constants/styleVars';
import {SyncOutlined} from '@ant-design/icons';
import {useActions} from 'hooks/useActions';
import {IBookingType} from 'types/dto/IBookingType.type';
import {
  activeBookingsTabTitles,
  bookingOverviewAmountSearchTabs,
  minute,
} from '../helpers';
import {TextValue} from 'view/components/TextValue';

const ContentHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  grid-gap: ${offsetDef};
  margin-bottom: ${offsetXLg};
`;

const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 0;
`;

type TProps = {
  bookingsType: IBookingType;
  venueId: number;
};

function BookingContentHeader({bookingsType, venueId}: TProps) {
  const [lastRefreshedTime, setLastRefreshedTime] = useState(0);
  const {getBookingsAction, getAmountOfBookings} = useActions();

  const lastRefreshInterval = useRef<NodeJS.Timeout>();

  const clearRefreshedTime = () => {
    setLastRefreshedTime(0);
    clearInterval(Number(lastRefreshInterval.current));
  };

  useEffect(() => {
    lastRefreshInterval.current = setInterval(() => {
      setLastRefreshedTime((prevState) => prevState + 1);
    }, minute);

    return () => {
      clearRefreshedTime();
    };
  }, [bookingsType]);

  const handleRefreshData = () => {
    clearRefreshedTime();
    getBookingsAction({bookingsType});
    getAmountOfBookings({...bookingOverviewAmountSearchTabs, venueId});
  };

  return (
    <ContentHeaderContainer>
      <div>
        <StyledPageTitle>
          {activeBookingsTabTitles[bookingsType]} overview
        </StyledPageTitle>

        <TextValue secondary size='small'>
          Last refreshed: {lastRefreshedTime} min ago
          <Button
            type='link'
            // @ts-ignore
            icon={<SyncOutlined />}
            onClick={handleRefreshData}
          />
        </TextValue>
      </div>
    </ContentHeaderContainer>
  );
}

export default BookingContentHeader;

import React, {useMemo} from 'react';

import {NW2BookingUnitCard} from '../components/NW2BookingUnitCard';
import {SummaryTotalPrice} from '../components/SummaryTotalPrice/SummaryTotalPrice';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {useAppSelector} from 'store/hooks';
import {IPreviewExtra} from 'store/venues/types';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {ICustomerBookedUnits, ISummaryUnit} from 'types/dto/IBooking.types';
import {TotalDiffPriceSection} from '../NW2BookingSummary.styles';
import {SummaryVenueSection} from '../components/VenueSummarySection/VenueSummarySection';
import {IOfferRequestDay} from 'types/offer';
import {EBookingStatus} from 'types/booking';

interface IProps {
  participants?: number;
  checkInDate?: string;
  checkOutDate?: string;
  currency: string;
  extras?: IPreviewExtra[];
  venueUnits?: ISummaryUnit[];
  isRfp?: boolean;
  isTotalPriceVisible?: boolean;
  theme?: EBookingSummaryTheme;
  totalPrice?: number;
  prevTotalPrice?: number;
  venueAddress: string;
  venueName: string;
  venueCoverImage: {
    url: string;
    name: string;
  };
  submitting?: boolean;
  handleSubmitClick?: () => void;
  unitBookings: ICustomerBookedUnits[] | null;
  showTimeRangeAndParticipants?: boolean;
  preArrivals?: IOfferRequestDay[];
  postEvents?: IOfferRequestDay[];
  editMode?: boolean;
  bookingStatus?: EBookingStatus;
  isUSA: boolean;
}

export function useBookingSummaryContent({
  currency,
  isTotalPriceVisible,
  theme = EBookingSummaryTheme.DARK,
  totalPrice,
  prevTotalPrice,
  venueAddress,
  venueName,
  venueCoverImage,
  unitBookings,
  preArrivals,
  postEvents,
  showTimeRangeAndParticipants,
  editMode,
  bookingStatus,
  isUSA,
}: IProps) {
  const customerSelectedPackages = useAppSelector(
    ({customer}) => customer.customerSelectedPackages,
  );

  const totalSum = useMemo(
    () =>
      totalPrice ? <PriceDisplay price={totalPrice} currency={currency} /> : '',
    [currency, totalPrice],
  );

  const headerSection = useMemo(() => {
    return (
      <SummaryVenueSection
        theme={theme}
        venueAddress={venueAddress}
        venueCoverImage={venueCoverImage.url}
        venueName={venueName}
      />
    );
  }, [theme, venueAddress, venueCoverImage.url, venueName]);

  const totalPriceSection = useMemo(
    () =>
      isTotalPriceVisible && (
        <SummaryTotalPrice
          bookingStatus={bookingStatus}
          totalSum={totalSum}
          isUSA={isUSA}
        />
      ),
    [bookingStatus, isTotalPriceVisible, totalSum, isUSA],
  );

  const unitsSection = useMemo(() => {
    if (!unitBookings) return null;

    const units = unitBookings.map((venueUnit, index) => {
      const currentPackagesByDay = customerSelectedPackages
        ? customerSelectedPackages[index]
        : null;

      return (
        <NW2BookingUnitCard
          key={venueUnit.checkInDate}
          theme={theme}
          units={venueUnit.unitBookings}
          bedrooms={venueUnit.bedrooms}
          checkIn={venueUnit.checkInDate}
          checkOut={venueUnit.checkOutDate}
          foodBeverage={venueUnit.foodAndBeverage}
          index={index + (preArrivals?.length || 0)}
          isPriceHidden={!isTotalPriceVisible}
          showTimeRangeAndParticipants={showTimeRangeAndParticipants}
          editMode={editMode}
          customerPackagesByDay={currentPackagesByDay}
          totalPriceForPackageSet={venueUnit.totalPriceForPackageSet}
          isTimeShowed={!!venueUnit.unitBookings?.length}
          currency={currency}
        />
      );
    });

    const makeArrivals = (items: IOfferRequestDay[], isPostEvent = false) => {
      return items.map((item, index) => {
        const eventIndex = isPostEvent
          ? (preArrivals?.length || 0) + units.length + index
          : index;

        return (
          <NW2BookingUnitCard
            key={item.checkIn}
            theme={theme}
            bedrooms={item.bedrooms}
            units={[]}
            checkIn={item.checkIn}
            checkOut={item.checkOut}
            currency={currency}
            index={eventIndex}
            isTimeShowed={false}
            showTimeRangeAndParticipants={false}
            editMode={editMode}
            customerPackagesByDay={null}
          />
        );
      });
    };

    let preArrivalDays = [] as JSX.Element[];
    let postEventDays = [] as JSX.Element[];

    if (preArrivals?.length) {
      preArrivalDays = makeArrivals(preArrivals);
    }

    if (postEvents?.length) {
      postEventDays = makeArrivals(postEvents, true);
    }

    return [...preArrivalDays, ...units, ...postEventDays];
  }, [
    currency,
    customerSelectedPackages,
    editMode,
    isTotalPriceVisible,
    postEvents,
    preArrivals,
    showTimeRangeAndParticipants,
    theme,
    unitBookings,
  ]);

  const priceChange =
    totalPrice && prevTotalPrice ? totalPrice - prevTotalPrice : 0;

  const symbol = priceChange > 0 ? '+' : priceChange < 0 ? '-' : '';

  const totalDiffPriceSection =
    editMode && !!priceChange ? (
      <TotalDiffPriceSection>
        <div>
          <span>Original booking total</span>
          <span>
            {' '}
            <PriceDisplay price={prevTotalPrice} currency={currency} />
          </span>
        </div>
        <div>
          <span>Total price change</span>
          <span>
            {`${symbol}`}
            <PriceDisplay price={Math.abs(priceChange)} currency={currency} />
          </span>
        </div>
      </TotalDiffPriceSection>
    ) : null;

  return {
    headerSection,
    unitsSection,
    totalPriceSection,
    totalDiffPriceSection,
  };
}

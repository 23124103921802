import React, {useRef, useState} from 'react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';

import {TDateType, TMultiDateChangeFn} from './NW2MultiDatePicker.types';
import {TDateChangeFn} from '../NW2DatePicker.types';

type DatePickerProps = Omit<ReactDatePickerProps, 'selectsRange'>;

interface IProps extends DatePickerProps {
  selectsRange?: boolean;
  startDate?: TDateType | null;
  endDate?: TDateType | null;
  onChange: TMultiDateChangeFn | TDateChangeFn;
}

export const NW2MultiDatePickerMobileWithCustomHeader = ({
  startDate = null,
  endDate = null,
  onChange,
  ...rest
}: IProps) => {
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const btnDecrRef = useRef<HTMLButtonElement | null>(null);
  const btnIncrRef = useRef<HTMLButtonElement | null>(null);
  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: React.TouchEvent) =>
    setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    isDownSwipe && btnDecrRef.current?.click();
    isUpSwipe && btnIncrRef.current?.click();
  };

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        monthsShown={2}
        {...rest}
        renderCustomHeader={({decreaseMonth, increaseMonth, monthDate}) => {
          return (
            <div className='react-datepicker__header'>
              <button
                className='react-datepicker__navigation'
                ref={btnDecrRef}
                onClick={decreaseMonth}
              />
              <div className='react-datepicker__current-month'>
                {monthDate.toLocaleString('en-US', {
                  month: 'long',
                  year: 'numeric',
                })}
              </div>
              <button
                className={'react-datepicker__navigation'}
                ref={btnIncrRef}
                onClick={increaseMonth}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

import React from 'react';

import DateUtils, {DateFormats} from 'utils/dateUtils';

import {useAppSelector} from 'store/hooks';

import {Wrapper, StyledParagraph} from './AccommodationBlock.styles';

type TProps = {
  date?: string;
  isPreArrival?: boolean;
};

const PrePostBlock = ({date, isPreArrival}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const formattedDate = DateUtils.getDateWithoutHours(
    date,
    DateFormats['1 Jan 2023'],
  );

  return (
    <Wrapper isMobile={isMobile}>
      <StyledParagraph>
        {isPreArrival ? 'Pre-arrival' : 'Post-event'}
      </StyledParagraph>
      <span>{formattedDate}</span>
    </Wrapper>
  );
};

export default PrePostBlock;

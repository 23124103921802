import {ReactNode} from 'react';

import {
  fontSizeMd,
  fontSizeSm,
  fontSizeXSm,
  lineHeightLg,
  lineHeightMd,
  lineHeightSm,
} from 'constants/styleVars';

export enum ERadioSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

type TSize = keyof typeof ERadioSizes;

export enum ERadioActualSize {
  SMALL = '20px',
  MEDIUM = '22px',
  LARGE = '24px',
}

export enum ERadioInnerSize {
  SMALL = '8px',
  MEDIUM = '10px',
  LARGE = '12px',
}

export interface IRadioInputProps {
  id?: string;
  name: string;
  value?: any;
  type: 'radio';
  checked: boolean;
  disabled?: boolean;
  label?: string | ReactNode;
  onChange?: (name: string, value: string) => void;
  showAllValidationErrors?: boolean;
  inputProps: any;
  size: ERadioSizes;
}

export interface IRadioFieldProps {
  name: string;
  value?: any;
  id?: string;
  type?: 'radio';
  size: ERadioSizes;
  disabled?: boolean;
  label?: string | ReactNode;
  onChange?: (name: string, value: string) => void;
  rules?: (value: any, _: any, meta: any) => string;
}

export type TRadioSize = {
  [key in ERadioSizes]: {
    width: string;
    height: string;
    innerWidth: string;
    innerHeight: string;
    labelFontSize: string;
    labelLineHeight: string;
  };
};

export type TSchemaType = TRadioSize[TSize];

export const radioSizeSchema: TRadioSize = Object.freeze({
  [ERadioSizes.SMALL]: {
    width: ERadioActualSize.SMALL,
    height: ERadioActualSize.SMALL,
    innerWidth: ERadioInnerSize.SMALL,
    innerHeight: ERadioInnerSize.SMALL,
    labelFontSize: fontSizeXSm,
    labelLineHeight: lineHeightSm,
  },
  [ERadioSizes.MEDIUM]: {
    width: ERadioActualSize.MEDIUM,
    height: ERadioActualSize.MEDIUM,
    innerWidth: ERadioInnerSize.MEDIUM,
    innerHeight: ERadioInnerSize.MEDIUM,
    labelFontSize: fontSizeSm,
    labelLineHeight: lineHeightMd,
  },
  [ERadioSizes.LARGE]: {
    width: ERadioActualSize.LARGE,
    height: ERadioActualSize.LARGE,
    innerWidth: ERadioInnerSize.LARGE,
    innerHeight: ERadioInnerSize.LARGE,
    labelFontSize: fontSizeMd,
    labelLineHeight: lineHeightLg,
  },
});

import React from 'react';
import DateUtils from 'utils/dateUtils';
import Space from '../Space';
import LeftSideItemTitle from 'view/venue/components/LeftSideContainer/LeftSideItemTitle';

import {
  TGroupedDeskUnitsByDay,
  TGroupedUnitsByDay,
} from 'types/dto/IBooking.types';
import {offsetXXLg} from 'constants/styleVars';
import {StyledLeftSideItem} from '../Space.styles';

type TProps = {
  previewUnits: (TGroupedUnitsByDay | TGroupedDeskUnitsByDay)[];
};

const BookingLeftSideSpaces = ({previewUnits}: TProps) => {
  return (
    <>
      {previewUnits.map(
        (
          {
            checkInDate,
            selectedPackage,
            checkOutDate,
            units,
            foodAndBeverage,
            bedrooms,
          },
          index,
        ) => {
          const duration = DateUtils.getTotalHours(checkInDate, checkOutDate);

          const children = (
            <Space
              isMultiDay
              units={units}
              dayIndex={index}
              previewFoodAndBeverage={foodAndBeverage}
              bedrooms={bedrooms}
              duration={duration}
              packagesPreview={selectedPackage}
            />
          );

          return (
            <StyledLeftSideItem
              key={index}
              item={{
                title: (
                  <LeftSideItemTitle
                    title={`DAY ${index + 1}`}
                    checkIn={checkInDate}
                    checkOut={checkOutDate}
                    hasTimeRange={!!units.length}
                  />
                ),
                children,
              }}
              isHidden={false}
              paddingTop={index === 0 ? `${offsetXXLg} !important` : undefined}
              paddingBottom={
                index === previewUnits.length - 1
                  ? undefined
                  : `${offsetXXLg} !important`
              }
            />
          );
        },
      )}
    </>
  );
};

export default BookingLeftSideSpaces;

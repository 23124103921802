import {
  deleteCancellationPolicyById,
  deleteTermsAndConditionById,
  getActualTermsAndConditionsByFkey,
  getCancellationPolicyByFkey,
  uploadCancellationPolicyByFkey,
  uploadTermsAndConditionByFkey,
} from 'store/admin/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import {TUploadFile} from 'view/components/Uploader/types';
import queryString from 'query-string';
import {EMPTY_OBJECT} from 'constants/app';
import {useEffect} from 'react';

export const useDocuments = () => {
  const dispatch = useAppDispatch();

  const queryData = queryString.parse(location.search.slice(1)) || EMPTY_OBJECT;
  const companyIdFromQuery = queryData.id || '';

  useEffect(() => {
    if (companyIdFromQuery) {
      dispatch(getActualTermsAndConditionsByFkey(+companyIdFromQuery));
      dispatch(getCancellationPolicyByFkey(+companyIdFromQuery));
    }
  }, [companyIdFromQuery, dispatch]);

  const cancellationPoliciesFile = useAppSelector(
    ({admin}) => admin.cancellationPoliciesFile,
  );

  const onPolicyUpload = (
    file: TUploadFile,
    handleProgress: (percent: number) => void,
  ) => {
    dispatch(
      uploadCancellationPolicyByFkey({
        file,
        fkey: +companyIdFromQuery,
        handleProgress,
      }),
    );
  };

  const onPolicyDelete = (policyId: string) => {
    dispatch(deleteCancellationPolicyById(policyId));
  };

  const termsAndConditionsFile = useAppSelector(
    ({admin}) => admin.termsAndConditionsFile,
  );

  const onTermsUpload = (
    file: TUploadFile,
    handleProgress: (percent: number) => void,
  ) => {
    dispatch(
      uploadTermsAndConditionByFkey({
        file,
        fkey: +companyIdFromQuery,
        handleProgress,
      }),
    );
  };

  const onTermsDelete = (termsId: string) => {
    dispatch(deleteTermsAndConditionById(termsId));
  };

  return {
    cancellationPoliciesFile,
    onPolicyUpload,
    onPolicyDelete,
    termsAndConditionsFile,
    onTermsUpload,
    onTermsDelete,
  };
};

import styled, {css} from 'styled-components';

import {
  ESwitchSizes,
  IControlProps,
  switchSizeSchema,
  TLabelPosition,
  TSchemaType,
} from './NW2Switch.types';

import {
  offsetXLg,
  NW2Gray300Color,
  NW2Primary,
  whiteColor,
  offsetXXSm,
} from 'constants/styleVars';

const CONTROL_SIZE_ACTIVE = '16px';
const CONTROL_SIZE_INACTIVE = '-4px';

export const SwitchContainer = styled.div<{labelPosition: TLabelPosition}>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({labelPosition}) =>
    labelPosition === 'left' ? 'row' : 'row-reverse'};
  ${({labelPosition}) =>
    labelPosition === 'right' && `justify-content: flex-end`};
  column-gap: ${offsetXLg};
  row-gap: ${offsetXXSm};
`;

export const Label = styled.span<{size: ESwitchSizes}>(({size}) => {
  const {labelLineHeight, labelFontSize}: TSchemaType = switchSizeSchema[size];
  return css`
    font-size: ${labelFontSize};
    line-height: ${labelLineHeight};
  `;
});

export const ControlContainer = styled.button<IControlProps>(
  ({isActive, size}) => {
    const {height, width}: TSchemaType = switchSizeSchema[size];

    return css`
      position: relative;
      width: ${width};
      height: ${height};
      border: none;
      border-radius: 40px;
      background-color: ${NW2Gray300Color};

      ${isActive &&
      css`
        background-color: ${NW2Primary};
      `}

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        cursor: not-allowed;
      }
    `;
  },
);

export const Control = styled.div<IControlProps>(
  ({isActive, disabled, size}) => {
    const {controlSize}: TSchemaType = switchSizeSchema[size];

    return css`
      width: ${controlSize};
      height: ${controlSize};
      background-color: ${whiteColor};
      position: absolute;
      top: 2px;
      left: 6px;
      transform: translateX(${CONTROL_SIZE_INACTIVE});

      ${isActive &&
      css`
        transform: translateX(${CONTROL_SIZE_ACTIVE});
      `};

      border-radius: 50%;
      transition: transform 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        ${disabled &&
        css`
          cursor: not-allowed;
        `};
      }
    `;
  },
);

import React, {FC} from 'react';
import {setOpenLoginPopup} from 'store/app/appSlice';
import {LoginRegisterContainer} from 'view/auth/LoginRegisterContainer';
import {useAppDispatch} from 'store/hooks';

import LoginContent from '../components/LoginContent';
import useLoginInSystem from 'hooks/useLoginInSystem';

const LoginModal: FC<{
  isLoginPopupVisible: boolean;
  setIsForgotPopupVisible: (openForgotPasswordPopup: boolean) => void;
  toggleLoginRegisterModals: () => void;
  loginTitle: string;
  loginDescription: string;
}> = ({
  isLoginPopupVisible,
  setIsForgotPopupVisible,
  toggleLoginRegisterModals,
  loginTitle,
  loginDescription,
}) => {
  const dispatch = useAppDispatch();

  const openForgotPassword = () => {
    dispatch(setOpenLoginPopup({openLoginPopup: false}));
    setIsForgotPopupVisible(true);
  };

  const {isLoading, isSubmitFailed, loginInSystem} = useLoginInSystem({
    isLoginModal: true,
  });

  const closeLoginModal = () => {
    dispatch(setOpenLoginPopup({openLoginPopup: false}));
  };

  return (
    <LoginRegisterContainer
      onClose={closeLoginModal}
      open={isLoginPopupVisible}
    >
      <LoginContent
        loginTitle={loginTitle}
        loginDescription={loginDescription}
        isLoading={isLoading}
        isSubmitFailed={isSubmitFailed}
        loginInSystem={loginInSystem}
        openForgotPassword={openForgotPassword}
        toggleLoginRegisterModals={toggleLoginRegisterModals}
      />
    </LoginRegisterContainer>
  );
};

export default LoginModal;

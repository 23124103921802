import LocalStorageService from 'infra/common/localStorage.service';
import {ISelectedStorageVenueUnit} from 'store/venueDetails/types';
import {useCallback} from 'react';

type TGetSelectedVenueUnitIdProps = {venueId: number; checkIn: string};

export const useSelectedRoomId = (): [
  ({venueId, checkIn}: TGetSelectedVenueUnitIdProps) => number | undefined,
] => {
  const storedSelectedUnitsData =
    LocalStorageService.getByKey('selectedVenueUnits');

  const getSelectedVenueUnitId = useCallback(
    ({venueId, checkIn}: TGetSelectedVenueUnitIdProps) => {
      const selectedUnitsData = storedSelectedUnitsData
        ? JSON.parse(storedSelectedUnitsData)
        : {};

      return selectedUnitsData[venueId]?.find(
        (unit: ISelectedStorageVenueUnit) => unit.checkIn === checkIn,
      )?.unitId;
    },
    [storedSelectedUnitsData],
  );

  return [getSelectedVenueUnitId];
};

import React, {useState, useEffect} from 'react';
import {DateTime} from 'luxon';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import NW2Button from 'view/components/NW2Button';

import {
  NW2DateSection,
  NW2TimeSection,
} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker';

import {NW2Gray50Color} from 'constants/styleVars';
import {
  StyledActions,
  StyledDrawerContent,
  StyledDrawerContentFooter,
  StyledDrawerContentHeader,
  StyledInputContainer,
  DateInput,
} from './NW2MultiDatePickerMobile.styles';
import {ESteps} from './NW2MultiDatePickerMobile.types';
import {formatDateForPicker} from '../utils';
import {
  StyledTimeTitle,
  StyledMobileTimeWrapper,
} from '../NW2TimePicker/NW2TimePicker.styles';
import {TMultiDateChangeFn} from 'view/components/NW2Datepicker/NW2DatePicker/NW2MultiDatePicker.types';
import {ITimeData, TDateType} from 'types/dto/ISearch.types';
import {TTimeChangeFn} from './NW2MultiDatePicker.types';
import {getISOString} from 'utils/dateUtils';

interface IProps {
  startDate: TDateType;
  endDate: TDateType;
  timeData: ITimeData[];
  isOpened: boolean;
  onDateChange: TMultiDateChangeFn;
  onTimeStartChange: TTimeChangeFn;
  onTimeEndChange: TTimeChangeFn;
  onToggleDatePicker: () => void;
  onNextClick: () => void;
  onClearClick: () => void;
  roomTypeString?: string;
}

const bodyStyle = {
  padding: 0,
  backgroundColor: NW2Gray50Color,
};

export const NW2MultiDatePickerMobile = ({
  startDate,
  endDate,
  timeData,
  isOpened,
  onDateChange,
  onTimeStartChange,
  onTimeEndChange,
  onToggleDatePicker,
  onNextClick,
  onClearClick,
  roomTypeString,
}: IProps) => {
  const [step, setStep] = useState(ESteps.datePicker);

  const startJSDate = startDate ? DateTime.fromJSDate(startDate) : '';
  const endJSDate = endDate ? DateTime.fromJSDate(endDate) : '';

  useEffect(() => {
    if (isOpened) {
      document.body.style.width = '100%'; // remove the padding added by antd drawer to body
    }
  }, [isOpened]);

  const goNext = () => {
    setStep(step + 1);
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const closeHandler = () => {
    setStep(ESteps.datePicker);
    onToggleDatePicker();
  };

  const lastStepNextHandler = () => {
    setStep(ESteps.datePicker);
    onNextClick();
  };

  const nextHandler = step === ESteps.datePicker ? goNext : lastStepNextHandler;
  const clearHandler = step === ESteps.datePicker ? onClearClick : goBack;

  return (
    <StyledAntdDrawer
      title={null}
      onClose={closeHandler}
      open={isOpened}
      height='auto'
      bodyStyle={bodyStyle}
      padding='0'
    >
      <StyledDrawerContentHeader showDivider>
        <StyledInputContainer highlighted>
          <DateInput
            type='text'
            value={formatDateForPicker(
              getISOString(startJSDate),
              getISOString(endJSDate),
            )}
            data-testid='datepicker-mobile open picker'
            readOnly
          />
          <label>
            <span>Date</span>
          </label>
        </StyledInputContainer>
      </StyledDrawerContentHeader>

      <StyledDrawerContent>
        {step === ESteps.datePicker ? (
          <NW2DateSection
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange}
          />
        ) : (
          <StyledMobileTimeWrapper>
            <StyledTimeTitle>{`When do you plan to ${roomTypeString}?`}</StyledTimeTitle>
            <NW2TimeSection
              timeData={timeData}
              onTimeStartChange={onTimeStartChange}
              onTimeEndChange={onTimeEndChange}
            />
          </StyledMobileTimeWrapper>
        )}
      </StyledDrawerContent>

      <StyledDrawerContentFooter showDivider={step === ESteps.datePicker}>
        <StyledActions>
          <NW2Button
            type='button'
            onClick={clearHandler}
            data-testid='datepicker-mobile clear'
            fullWidth
          >
            {step === ESteps.datePicker ? 'clear' : 'back'}
          </NW2Button>

          <NW2Button
            buttonType='primary'
            onClick={nextHandler}
            data-testid='datepicker-mobile next'
            fullWidth
          >
            {step === ESteps.datePicker ? 'next' : 'apply'}
          </NW2Button>
        </StyledActions>
      </StyledDrawerContentFooter>
    </StyledAntdDrawer>
  );
};

import styled from 'styled-components';

import {offsetSm, offsetXXLg, lgBp} from 'constants/styleVars';

export const ButtonsWrapper = styled.div<{
  gap?: string;
  margin?: string;
  justifyContent?: string;
}>`
  margin: ${({margin}) => margin || `${offsetXXLg} 0 0 0`};
  display: flex;
  gap: ${({gap}) => gap || offsetSm};
  justify-content: ${({justifyContent}) => justifyContent};

  @media (min-width: ${lgBp}px) {
    display: flex;
    gap: ${({gap}) => gap || offsetXXLg};
  }
`;

import React, {memo, useMemo} from 'react';

import NMMSpacesListItem from './NMMSpacesListItem';
import NW2Button from 'view/components/NW2Button';
import NMMSection from 'view/components/NMMSection';

import {floorLevels, getKeyByValue} from 'constants/floorStorey';

import {ISpacesData, ISpacesDataItem} from 'types/venue';
import {StyledButtonsDiv, StyledDiv} from './NMMSpacesList.styles';
import {offsetXLg} from 'constants/styleVars';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../../../store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from '../../../../../../../types/dto/EUserRoleCognito';

type TProps = {
  spaces: ISpacesData[];
  roomType: ERoomType;
  wip: ISpacesDataItem[];
  isExternalVenue: boolean;
  handleCreateRoom: (floor?: number) => void;
};

const WORK_IN_PROGRESS = 'Work in progress';

const NMMSpacesList = ({
  spaces,
  roomType,
  wip,
  isExternalVenue,
  handleCreateRoom,
}: TProps) => {
  const onCreateRoom = (floorNumber: number) => () => {
    handleCreateRoom(floorNumber);
  };

  const wipSpaces = useMemo(
    () => wip.filter((space) => space.roomType === roomType),
    [roomType, wip],
  );

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  return (
    <>
      {wipSpaces.length > 0 && (
        <NMMSection
          id={WORK_IN_PROGRESS}
          title={WORK_IN_PROGRESS}
          titleFontSize={offsetXLg}
        >
          <StyledDiv>
            {wipSpaces.map((itemData: ISpacesDataItem, key: number) => (
              <NMMSpacesListItem
                itemData={itemData}
                roomType={roomType}
                isExternalVenue={isExternalVenue}
                key={key}
                data-testid={itemData.id}
                isWIP
              />
            ))}
          </StyledDiv>
        </NMMSection>
      )}

      {spaces.map((space: ISpacesData, idx: number) => {
        const floor = getKeyByValue(floorLevels, space.floorNumber) || ' ';

        return (
          <NMMSection
            id={floor}
            title={floor}
            key={`space-section-${floor}-${idx}`}
            titleFontSize={offsetXLg}
            sectionMargin={idx === spaces.length - 1 ? '0' : undefined}
          >
            {space.units.map((itemData: ISpacesDataItem, key: number) => (
              <NMMSpacesListItem
                itemData={itemData}
                roomType={roomType}
                isExternalVenue={isExternalVenue}
                key={key}
                data-testid={itemData.id}
              />
            ))}
            <StyledButtonsDiv>
              {!isAgent && (
                <NW2Button
                  type='button'
                  buttonType='secondary'
                  size='responsiveAdaptive'
                  onClick={onCreateRoom(space.floorNumber)}
                  data-testid='space-button-create-by-floors'
                >
                  Create new
                </NW2Button>
              )}
            </StyledButtonsDiv>
          </NMMSection>
        );
      })}
    </>
  );
};

export default memo(NMMSpacesList);

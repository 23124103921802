import styled from 'styled-components';

import {Link} from 'view/components/Typography';

import {
  whiteColor,
  errorColor,
  fontWeightBold,
  fontSizeLg,
  fontSizeXSm,
  fontSizeXXSm,
  offsetDef,
  offsetXLg,
  offsetXXSm,
  mdBp,
  lgBp,
  lineHeightXSm,
  fontSizeSm,
  fontSizeMd,
  NW2Gray500Color,
  offsetXXLg,
} from 'constants/styleVars';

const breakpoint = `${mdBp}px`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: ${whiteColor};
  position: relative;

  &:not(&:last-of-type) {
    margin-bottom: ${offsetXXLg};
    padding-bottom: ${offsetXXLg};
    border-bottom: 1px solid ${NW2Gray500Color};
  }

  @media (min-width: ${breakpoint}) {
    gap: 0 18px;
    grid-template-columns: minmax(140px, 175px) minmax(150px, 1fr) minmax(
        130px,
        1fr
      );

    &:not(&:last-of-type) {
      margin-bottom: ${offsetXLg};
      padding-bottom: ${offsetXLg};
    }
  }

  @media (min-width: ${lgBp}px) {
    margin-bottom: ${offsetXXLg};

    &:not(&:last-of-type) {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: ${offsetXXLg};
    }
  }
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  margin-bottom: ${offsetDef};

  @media (min-width: ${breakpoint}) {
    margin-bottom: 0;
    grid-column: 1 / 2;
    grid-row: 1 / 4;
  }
`;

export const ItemTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  margin-bottom: ${offsetDef};

  @media (min-width: ${breakpoint}) {
    margin-bottom: 0;
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }
`;

export const ItemSubTitle = styled.div`
  font-size: ${fontSizeSm};
  grid-column: 1 / 2;
  grid-row: 3 / 4;

  @media (min-width: ${breakpoint}) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
`;

export const PricesBlock = styled.div`
  font-size: ${fontSizeXSm};
  grid-column: 1 / 3;
  grid-row: 4 / 5;

  @media (min-width: ${breakpoint}) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    align-self: end;
  }
`;

export const BestPrice = styled.span`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
`;

export const ButtonWrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 5 / 6;
  margin-top: ${offsetDef};

  @media (min-width: ${breakpoint}) {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    align-self: end;
    justify-self: end;
    margin-top: 0;
  }
`;

export const StyledLink = styled(Link)`
  color: ${whiteColor};
  cursor: pointer;
`;

export const RoomStatusText = styled.div`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  color: ${errorColor};
  text-align: right;
  margin-top: ${offsetXXSm};
`;

export const CSSTruncatedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

import React, {memo} from 'react';

import {NW2TimeRangePicker} from 'view/components/NW2Datepicker/NW2TimePicker';

import DateUtils from 'utils/dateUtils';
import {formatDate} from 'utils/stringUtils';
import {ITimeData} from 'types/dto/ISearch.types';
import {TTimeChangeFn} from 'view/components/NW2Datepicker/NW2DatePicker/NW2MultiDatePicker.types';
import {StyledTimeRow, StyledTimeRowDate} from './NW2SearchDatePicker.styles';

interface Props {
  timeData: ITimeData[];
  onTimeStartChange: TTimeChangeFn;
  onTimeEndChange: TTimeChangeFn;
}

const NW2TimeSection = ({
  timeData,
  onTimeStartChange,
  onTimeEndChange,
}: Props) => {
  return (
    <>
      {timeData.map(({timeStart, timeEnd}, index) => {
        const formattedDate = formatDate(timeStart, 'dd MM', 'en-US');
        const timeStartAsDate = timeStart
          ? DateUtils.getDateFromISO(timeStart)
          : null;
        const timeEndAsDate = timeEnd
          ? DateUtils.getDateFromISO(timeEnd)
          : null;

        return (
          <StyledTimeRow key={timeStart}>
            <StyledTimeRowDate>{formattedDate}</StyledTimeRowDate>

            <NW2TimeRangePicker
              valueStart={timeStartAsDate}
              valueEnd={timeEndAsDate}
              onChangeStart={onTimeStartChange(index)}
              onChangeEnd={onTimeEndChange(index)}
            />
          </StyledTimeRow>
        );
      })}
    </>
  );
};

export default memo(NW2TimeSection);

import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {RootState} from '../types';
import {
  BaseQueryMeta,
  BaseQueryResult,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  ICalculatedVenue,
  IMergedVenue,
  IVenuesListResponse,
  TSearchVenuesPayload,
} from 'types/search';
import {TCalcVenuePayload} from './types';
import {COMMON} from 'infra/common/config.service';

const DEFAULT_CACHE_TIME = 1800; // 30 min

// Define a service using a base URL and expected endpoints
export const venuesSearchApi = createApi({
  reducerPath: 'venuesSearchApi',
  keepUnusedDataFor: DEFAULT_CACHE_TIME,
  baseQuery: fetchBaseQuery({
    baseUrl: COMMON(),
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState)?.app?.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  // tagTypes: ['venuesSearchList'],
  endpoints: (builder) => ({
    getSearchDesksList: builder.query<IVenuesListResponse, string>({
      query: (params) => `work-desks-search/${params}`,
      // providesTags: (result, error, {id}) => [{type: 'venuesDesksSearchList', id}],
    }),

    getSearchList: builder.query<
      IVenuesListResponse,
      {body: TSearchVenuesPayload}
    >({
      query: ({body}) => ({
        url: 'search/venues',
        method: 'POST',
        body,
      }),
      // providesTags: (result, error, {id}) => [{type: 'venuesSearchList', id}],
      // async onQueryStarted({body}, {dispatch, queryFulfilled}) {
      //   const result = await queryFulfilled;
      //   const data = result.data;
      //   dispatch();
      // },
    }),

    getCalculatedList: builder.query<
      any,
      {
        body: TCalcVenuePayload;
      }
    >({
      query: ({body}) => ({
        url: 'inventory/v2/calculation',
        method: 'POST',
        body: {
          accommodationIds: body.accommodationIds,
          filterData: body.filterData,
        },
      }),
      transformResponse(
        response: BaseQueryResult<any>,
        meta: BaseQueryMeta<any>,
        arg: {
          body: TCalcVenuePayload;
          pagination: any;
        },
      ) {
        const {
          accommodationIds,
          size,
          filterData: filterDays,
          totalFilteredRecords,
          pageItems,
        } = arg.body;

        return {
          size,
          totalFilteredRecords,
          filterDays,
          pageItems:
            !size || !accommodationIds.length
              ? pageItems
              : pageItems.reduce((acc: IMergedVenue[], item: any) => {
                  const prices = response.find(
                    ({accommodationId}: any) =>
                      accommodationId === item.accommodationId,
                  ) as ICalculatedVenue;

                  acc.push({
                    ...prices,
                    ...item,
                  });
                  return acc;
                }, []),
        };
      },
      // invalidatesTags: (result, error, {id}) => [
      //   {type: 'venuesSearchList', id},
      // ],
    }),
  }),
});

export const useGetSearchCalculatedListState =
  venuesSearchApi.endpoints.getCalculatedList.useQueryState;
// export const useGetSearchCalculatedListQuerySubscription =
//   venuesSearchApi.endpoints.getCalculatedList.useQuerySubscription;

export const {
  useGetSearchListQuery,
  useGetCalculatedListQuery,
  useGetSearchDesksListQuery,
} = venuesSearchApi;

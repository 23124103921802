import {useCallback} from 'react';

import {IDay, IDayOfWeek} from 'types/dto/IDay.type';
import {
  getDayStr,
  isDayActive,
  isFullDayAccess,
} from 'utils/operationalHoursUtils';
import {addLeadingZeroToNumber} from 'utils/stringUtils';

// TODO remove helpers after adding locale for CreateVenue flow
const getStartTime = (d?: IDay): string => {
  if (!d) return '';
  return `${addLeadingZeroToNumber(d?.timeStartHour)}:${addLeadingZeroToNumber(
    d?.timeStartMinutes,
  )}`;
};

const getEndTime = (d?: IDay): string => {
  if (!d) return '';
  return `${addLeadingZeroToNumber(d?.timeEndHour)}:${addLeadingZeroToNumber(
    d?.timeEndMinutes,
  )}`;
};

const getStartEndTime = (day: IDayOfWeek, workDays: IDay[] = []): string => {
  const d = workDays.find((d) => d.dayOfWeek === getDayStr(day));
  if (!d) return '-';
  return `${getStartTime(d)} - ${getEndTime(d)}`;
};

const getDayWorkingHours = ({
  day,
  active,
  fullDayAccess,
  workDays,
}: {
  day: IDayOfWeek;
  active?: boolean;
  fullDayAccess?: boolean;
  workDays: IDay[];
}) => {
  if (active) {
    if (fullDayAccess) {
      return '24 hour access';
    }

    return getStartEndTime(day, workDays);
  }

  return 'Closed';
};

export function useOpeningHoursValue({workDays}: {workDays: IDay[]}) {
  const getValue = useCallback(
    (day: IDayOfWeek) => {
      const active = isDayActive(workDays, day);
      const fullDayAccess = isFullDayAccess(workDays, day);

      return getDayWorkingHours({day, active, fullDayAccess, workDays});
    },
    [workDays],
  );

  return [getValue];
}

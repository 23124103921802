import React, {FC} from 'react';
import {Form, Input, Tooltip, Checkbox, Button, Typography} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';

import {validateWebUrl} from '../../../utils/formFieldValidators';

import {StyledForm, Container, FormItem} from './Register.styles';

const {Title} = Typography;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18},
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const StyledInput = styled(Input)`
  width: 100%;
`;

const RegistrationForm: FC = () => {
  const [form] = Form.useForm();

  const onFinish = () => {};

  return (
    <Container>
      <StyledForm
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        initialValues={{
          residence: ['zhejiang', 'hangzhou', 'xihu'],
          prefix: '86',
        }}
        scrollToFirstError
      >
        <Title>Sign Up</Title>
        <FormItem
          name='email'
          label='E-mail'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name='password'
          label='Password'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </FormItem>

        <FormItem
          name='confirm'
          label='Confirm'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({getFieldValue}) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!',
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </FormItem>

        <FormItem
          name='nickname'
          label={
            <span>
              Nickname&nbsp;
              <Tooltip title='What do you want others to call you?'>
                {/* @ts-ignore */}
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: 'Please input your nickname!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name='phone'
          label='Phone Number'
          rules={[{required: true, message: 'Please input your phone number!'}]}
        >
          <StyledInput />
        </FormItem>

        <FormItem
          name='website'
          label='Website'
          rules={[
            {required: true, message: 'Please input website!'},
            {
              validator: validateWebUrl,
            },
          ]}
        >
          <Input />
        </FormItem>

        <FormItem
          name='agreement'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject('Should accept agreement'),
            },
          ]}
          {...tailFormItemLayout}
        >
          <Checkbox>I have read the agreement</Checkbox>
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit'>
            Register
          </Button>
        </FormItem>
      </StyledForm>
    </Container>
  );
};

export default RegistrationForm;

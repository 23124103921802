import styled from 'styled-components';

import {offsetDef} from 'constants/styleVars';

const TextBlockContainer = styled.div`
  margin: ${offsetDef} 0;
  word-break: break-word;
`;

export default TextBlockContainer;

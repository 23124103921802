import {EBookingStatus} from 'types/booking';
import {useAppSelector} from 'store/hooks';
import {FULL_FREE_OF_CHARGE} from 'constants/app';

type TStatusDataItem = {
  icon: string;
  infoTitle: string;
  infoSubtitle: string;
  chargeMessage: string;
  isDeclinedSectionShowed: boolean;
  isAskToRegistration: boolean;
  isInfoContent: boolean;
  currentStatus: EBookingStatus;
  offerCreatorName?: string | null;
  isEdited?: boolean;
};

const useGetStatusConfig = () => {
  const bookingStatus = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.bookingStatus,
  );

  const customerBookingOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder,
  );

  const bookingEmail = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.email,
  );

  const freeOfCharge =
    useAppSelector(
      ({cancellationPolicy}) =>
        cancellationPolicy?.dynamicCancellationPolicy?.policies[0]
          ?.freeOfCharge,
    ) || 0;

  const token = useAppSelector(({app}) => app.token);

  const statusConfirmedConfig = {
    icon: 'CHECKED',
    infoTitle: 'Booking confirmed',
    infoSubtitle:
      'Your booking is now confirmed. You should have received a confirmation email.',
    chargeMessage: '',
    isDeclinedSectionShowed: false,
    isAskToRegistration: !!bookingEmail && !token,
    isInfoContent: false,
    currentStatus: EBookingStatus.CONFIRMED,
    offerCreatorName: customerBookingOrder?.offerCreatorName,
    isEdited: !!(
      customerBookingOrder?.previousOrderVersion ||
      customerBookingOrder?.nextOrderVersion
    ),
  };

  const statusPendingConfig = {
    icon: 'COMMENT',
    infoTitle: 'Request sent',
    infoSubtitle:
      'Your booking request was now sent. You should have received a confirmation email.',
    chargeMessage: '',
    isDeclinedSectionShowed: false,
    isAskToRegistration: !!bookingEmail && !token,
    isInfoContent: true,
    currentStatus: EBookingStatus.RFP_PENDING,
    isEdited: !!(
      customerBookingOrder?.previousOrderVersion ||
      customerBookingOrder?.nextOrderVersion
    ),
  };

  const statusConfig: Partial<Record<EBookingStatus, TStatusDataItem>> = {
    [EBookingStatus.PENDING_AUTHORISATION]: {
      ...statusConfirmedConfig,
      currentStatus: EBookingStatus.PENDING_AUTHORISATION,
    },
    [EBookingStatus.RTC_PENDING]: {
      ...statusPendingConfig,
      infoTitle: 'Changes requested',
      infoSubtitle:
        'Your booking change request has now been sent. You should have received a confirmation email.',
      currentStatus: EBookingStatus.RTC_PENDING,
    },
    [EBookingStatus.CONFIRMED]: statusConfirmedConfig,
    [EBookingStatus.PASSED]: {
      icon: 'CHECKED',
      infoTitle: 'Past booking',
      infoSubtitle:
        'This booking has already occurred, we hope you enjoyed it! The venue will provide you with an invoice separately.',
      chargeMessage: '',
      isDeclinedSectionShowed: false,
      isAskToRegistration: false,
      isInfoContent: false,
      currentStatus: EBookingStatus.PASSED,
    },
    [EBookingStatus.RFP_PENDING]: statusPendingConfig,
    [EBookingStatus.CANCELED]: {
      icon: 'STATUS_CANCEL',
      infoTitle: 'Booking cancelled',
      infoSubtitle:
        'This booking is cancelled. You should have received a cancellation confirmation email.',
      chargeMessage:
        freeOfCharge === FULL_FREE_OF_CHARGE
          ? 'As the cancellation was made within the free cancellation period, no fee will be charged.'
          : 'According to the cancellation policy, the provider has permission to create a cancellation invoice.',
      isDeclinedSectionShowed: false,
      isAskToRegistration: false,
      isInfoContent: false,
      currentStatus: EBookingStatus.CANCELED,
    },
    [EBookingStatus.RFP_CANCELLED]: {
      icon: 'STATUS_CANCEL',
      infoTitle: 'Request cancelled',
      infoSubtitle:
        'This request has been cancelled. You should have received a cancellation confirmation email.',
      chargeMessage:
        'You are able to cancel unconfirmed booking requests anytime.',
      isDeclinedSectionShowed: false,
      isAskToRegistration: false,
      isInfoContent: false,
      currentStatus: EBookingStatus.RFP_CANCELLED,
    },
    [EBookingStatus.RFP_DECLINED]: {
      icon: 'STATUS_CANCEL',
      infoTitle: 'Request declined',
      infoSubtitle:
        'The venue was not able to accommodate this booking request.',
      chargeMessage:
        'Since this request was declined by the venue no cancellation policy was applied and your payment method will not be charged.',
      isDeclinedSectionShowed: true,
      isAskToRegistration: false,
      isInfoContent: false,
      currentStatus: EBookingStatus.RFP_DECLINED,
    },
    [EBookingStatus.RFP_EXPIRED]: {
      icon: 'STATUS_CANCEL',
      infoTitle: 'Request expired',
      infoSubtitle: 'The venue was not able to respond to this request.',
      chargeMessage:
        'We are sorry, but the venue did not respond to your request. As such, your booking was cancelled and you were not charged.',
      isDeclinedSectionShowed: false,
      isAskToRegistration: false,
      isInfoContent: false,
      currentStatus: EBookingStatus.RFP_EXPIRED,
    },
  };

  return bookingStatus ? statusConfig[bookingStatus] : null;
};

export default useGetStatusConfig;

import React from 'react';

import TextValue from 'view/components/TextValue';

import {getExtraNumericInputVisibility} from 'utils/helpers';

import {DetailsContent} from 'view/supplier/Bookings/BookingsList/components/BookingColumnRenderContent/ColumnRenderContentStyles';
import {IBookingOrderResponse} from 'types/dto/IBooking.types';
import {getAddressStringFromBillingAddress} from 'utils/stringUtils';
import {IBookedExtra} from 'types/venue';
import {offsetSm} from 'styles/configs';
import Icon from 'view/components/Icon';
import DateUtils, {DateFormats} from 'utils/dateUtils';
import {Group} from 'styles/basicStyledComponents/group';

interface IContactDetailsContentItem {
  label: string;
  value?: string | JSX.Element | string[];
}

interface IContactDetailsContent extends IContactDetailsContentItem {
  isVisible?: boolean;
}

const contactDetailsContent = (
  record: IBookingOrderResponse,
): IContactDetailsContent[] => {
  // getting first element for WD, because they don't have multi booking
  const bookedExtras: Partial<IBookedExtra>[] =
    record.unitBookingDtos[0]?.bookedExtras || [];

  return [
    {
      isVisible: true,
      label: 'Booking ID',
      value: record.orderNumber,
    },
    {
      isVisible: true,
      label: 'Booker',
      value: `${record.firstName} ${record.lastName}`,
    },
    {
      isVisible: true,
      label: 'Email Address',
      value: (
        <a
          href={`mailto:${record.email}`}
          target='_blank'
          rel='noreferrer noopener'
        >
          {record.email}
        </a>
      ),
    },
    {
      isVisible: true,
      label: 'Contact Number',
      value: record.phone,
    },
    {
      isVisible: !!bookedExtras.length,
      label: 'Extras',
      value: bookedExtras.map((el) => {
        return getExtraNumericInputVisibility(el.extraName)
          ? `${el.extraName} x ${el.bookedQuantity}`
          : `${el.extraName}`;
      }),
    },
  ];
};

type TProps = {
  record: IBookingOrderResponse;
};

const ContactDetailsContentItem = ({
  label,
  value,
}: IContactDetailsContentItem) => (
  <div>
    <TextValue secondary size='small'>
      {label}
    </TextValue>

    {Array.isArray(value) ? (
      value.map((el) => <TextValue key={el}>{el}</TextValue>)
    ) : (
      <TextValue>{value}</TextValue>
    )}
  </div>
);

export function CustomerDetailsContent({record}: TProps) {
  const {billingAddressDto, createdAt} = record;

  return (
    <Group gap={offsetSm}>
      <Group gap={offsetSm} align='center'>
        <Icon icon='CHECK' />
        <div>
          Last updated on{' '}
          {DateUtils.getDateWithoutHours(createdAt, DateFormats['1 Jan, 2023'])}
        </div>
      </Group>
      <DetailsContent>
        {contactDetailsContent(record).map(
          ({isVisible, label, value}) =>
            isVisible && (
              <ContactDetailsContentItem
                key={label}
                label={label}
                value={value}
              />
            ),
        )}

        {billingAddressDto && (
          <>
            <ContactDetailsContentItem
              label='Billing address'
              value={
                getAddressStringFromBillingAddress(billingAddressDto) as string
              }
            />

            {billingAddressDto.costCenter && (
              <ContactDetailsContentItem
                label='Cost centre'
                value={billingAddressDto.costCenter}
              />
            )}

            {billingAddressDto.additionalReference && (
              <ContactDetailsContentItem
                label='Additional billing address reference'
                value={billingAddressDto.additionalReference}
              />
            )}
          </>
        )}
      </DetailsContent>
    </Group>
  );
}

import styled from 'styled-components';

const ICON_WIDTH = '26px';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_WIDTH};
  min-width: ${ICON_WIDTH};
`;

import {defaultOperationalTimes} from 'constants/defaultOperationalTimes';
import {IPublicVenue} from 'types/dto/IPublicVenue';
import {ELegalEntityType, EPersonOfInterestType} from 'types/venue';

export const INITIAL_VENUE_DETAILS: IPublicVenue = {
  accommodationId: 0,
  amenities: [],
  amenitiesIds: [],
  contact: {
    id: 0,
    isEmailAvailable: false,
    firstName: '',
    lastName: '',
    email: '',
    fax: 0,
    phone: 0,
    websiteUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
  },
  currency: '',
  description: '',
  documents: [],
  hkey: '',
  legalContact: {
    id: 0,
    companyName: '',
    ceoName: '',
    taxNumber: '',
    legalEntityType: ELegalEntityType.BUSINESS,
    registrationNumber: '',
    countryCode: '',
    region: '',
    country: '',
    city: '',
    streetName: '',
    buildingNumber: '',
    postalCode: '',
    floorNumber: 0,
    floors: [],
    email: '',
    legalPhone: {},
    owner: {
      firstName: '',
      lastName: '',
      dateBirth: '',
      yearBirth: '',
      monthBirth: '',
      type: EPersonOfInterestType.OWNER,
    },
    signatory: {
      firstName: '',
      lastName: '',
      dateBirth: '',
      yearBirth: '',
      monthBirth: '',
      type: EPersonOfInterestType.SIGNATORY,
    },
    controller: {
      firstName: '',
      lastName: '',
      dateBirth: '',
      yearBirth: '',
      monthBirth: '',
      type: EPersonOfInterestType.CONTROLLER,
    },
  },
  personOfInterests: [],
  supplier: {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  },
  location: {
    streetName: '',
    city: '',
    country: '',
    postalCode: '',
    buildingNumber: '',
    floors: [],
    timeZone: '',
    distance: '',
  },
  name: '',
  operationalTimes: defaultOperationalTimes,
  timeZone: '',
  units: [],
  services: [],
  venueId: 0,
  totalBedrooms: 0,
  totalPrice: 0,
  extras: [],
  accommodationBuildingType: 'BUSINESS_CENTER',
  accommodationCharacters: [],
  pointsOfInterest: null,
};

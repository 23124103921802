import React, {ReactNode} from 'react';

import {List} from './NMMList.styles';

interface IProps {
  children: ReactNode;
}

const NMMList = ({children}: IProps) => {
  return <List>{children}</List>;
};

export default NMMList;

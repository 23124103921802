import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {requiredPriceRules} from 'utils/finalFormFieldRules';
import {useInputPriceChange} from '../../../../hooks/useInputPriceChange';
import {EAddDeskFormFields} from '../types';

import {PageFormSection} from '../styles';

export function AddDeskPrice() {
  const currency = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );

  const {onChangePrice, onBlurPrice} = useInputPriceChange(true);

  return (
    <PageFormSection>
      <NW2FormItemInput
        type='text'
        label={`Per hour in ${currency}`}
        placeholder={`Per hour in ${currency}`}
        name={EAddDeskFormFields.PRICE_HOUR}
        rules={requiredPriceRules(
          EAddDeskFormFields.PRICE_HOUR,
          'Please provide per hour price',
        )}
        onChange={onChangePrice(EAddDeskFormFields.PRICE_HOUR)}
        onBlur={onBlurPrice(EAddDeskFormFields.PRICE_HOUR)}
      />

      <NW2FormItemInput
        type='text'
        label={`Per day in ${currency}`}
        placeholder={`Per day in ${currency}`}
        name={EAddDeskFormFields.PRICE_DAY}
        rules={requiredPriceRules(
          EAddDeskFormFields.PRICE_DAY,
          'Please provide per day price',
        )}
        onChange={onChangePrice(EAddDeskFormFields.PRICE_DAY)}
        onBlur={onBlurPrice(EAddDeskFormFields.PRICE_DAY)}
      />
    </PageFormSection>
  );
}

import React from 'react';

import PrePostBlock from './PrePostBlock';
import AccommodationBlockItem from './AccommodationBlockItem';

import {useAppSelector} from 'store/hooks';

import {MainWrapper} from './AccommodationBlock.styles';
import {IExtraResponse} from 'types/dto/IPublicVenue';

type TProps = {
  singleBedrooms: IExtraResponse[];
  doubleBedrooms: IExtraResponse[];
  isPreArrival?: boolean;
  date?: string;
};

const AccommodationBlock = ({
  singleBedrooms,
  doubleBedrooms,
  isPreArrival,
  date,
}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  return (
    <MainWrapper isMobile={isMobile} isPreArrival={isPreArrival}>
      <PrePostBlock date={date} isPreArrival={isPreArrival} />
      <AccommodationBlockItem
        singleBedrooms={singleBedrooms}
        doubleBedrooms={doubleBedrooms}
      />
    </MainWrapper>
  );
};

export default AccommodationBlock;

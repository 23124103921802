import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {Card} from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import {Typography} from 'antd/es';
import styled from 'styled-components';
import _get from 'lodash/get';

import questionImg from '../../../img/endRegistrationQuestion.svg';
import Icon from 'view/components/Icon';

import {NEWWORK_SUPPORT_EMAIL} from 'constants/app';

import styles from 'view/venue/EndVenueRegistraion/index.module.css';
import {offsetDef, offsetXSm} from 'constants/styleVars';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';

const {Text} = Typography;

const ImageQuestion = styled.img`
  height: 16px;
  width: 16px;
  margin-bottom: 4px;
`;

const ImageSuccess = styled(Icon)`
  display: block;
  margin-bottom: 8px;
  text-align: center;
`;

const InstructionTitle = styled(Text)`
  display: block;
  margin-top: ${offsetDef};
  margin-bottom: ${offsetXSm};
`;

const InstructionText = styled.div`
  margin-bottom: ${offsetXSm};

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionContainer = styled.section`
  display: flex;
  justify-content: center;
`;

const EndVenueRegistration: FC = () => {
  const name = useSelector(
    (state) => _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.name`) || '',
  );

  return (
    <SectionContainer>
      <Card className={styles.wizardContainer}>
        <Title level={4} className={styles.center}>
          Thank you for registering your venue!
        </Title>
        <ImageSuccess icon='BUILDING_SUCCESS' />
        <Paragraph className={styles.textOnCenter}>
          <div>
            Your venue <span className={styles.spanBolt}>{name}</span> is
            registered successfully with the NewWork network.
          </div>
          <div>Thank you for joining NewWork.</div>
        </Paragraph>
        <p className={styles.borderGrey}>
          <Title level={5}>You're almost there!</Title>
          <div>
            Shortly you will receive an email to verify your email address, in
            order to activate it for public use and to set up your NewWork user
            account.
          </div>

          <InstructionTitle strong>As next step, please</InstructionTitle>

          <InstructionText>
            1. Check your indicated email's inbox / spam for the email with the
            subject "Your temporary password"
          </InstructionText>
          <InstructionText>
            2. Click on 'Create Password' button in email received
          </InstructionText>
          <InstructionText>
            3. Set up your password and automatically activate your venue. There
            you go!
          </InstructionText>
        </p>

        <p className={styles.borderGreyAllColor}>
          <ImageQuestion src={questionImg} alt='Question' />
          <Text strong className={styles.headOfTextMoveLeft}>
            Didn't receive the email?
          </Text>
          <div className={styles.textMoveLeft}>
            Please check the spam folder of your mailbox. If you still don't
            find any email, please contact the&nbsp;
            <a href={`mailto:${NEWWORK_SUPPORT_EMAIL}`}>
              customer support here
            </a>
            &nbsp;in order to activate your account.
          </div>
        </p>
      </Card>
    </SectionContainer>
  );
};

export default EndVenueRegistration;

import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';

import {Nw2EnterPassword} from 'view/components/EnterPassword';
import useOpenSetPasswordModal from 'view/auth/utils/useOpenSetPasswordModal';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {Container} from 'view/components/EnterPassword/helper';
import {EOpenPasswordModalTypes} from 'types/app';
import useForgotPassword from 'view/auth/utils/useForgotPassword';
import {Routes} from 'constants/routes';

const modalProps = {
  btnOkText: 'change password',
  pageName: 'Change your password',
  type: EOpenPasswordModalTypes.MODIFY,
  notificationMessage: 'Welcome back! Password reset successfully',
};

const ForgotPassword: FC = () => {
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const role: EUserRoleCognito = query.get('role');

  const redirectUrl = Routes.mainLanding;
  const {isRedirected} = useOpenSetPasswordModal({
    role,
    ...modalProps,
  });

  const {submitConfirmationForgotPassword} = useForgotPassword();

  if (isRedirected) return null;
  return (
    <Container>
      <Nw2EnterPassword
        moveToSuccessPagePath={redirectUrl}
        request={submitConfirmationForgotPassword}
        isMobile
        {...modalProps}
      />
    </Container>
  );

  // old venue forgot password flow
  // lets keep it for now
  // <EnterPassword
  //   moveToSuccessPagePath={redirectUrl}
  //   pageName='Recover your password'
  //   request={submitConfirmationForgotPassword}
  // />
};

export default ForgotPassword;

import styled from 'styled-components';

import {
  fontWeightExtraBold,
  lgBp,
  mdBp,
  NW2Gray200Color,
  offsetXLg,
  offsetXXLg,
  offsetXXXLg,
  offsetXXXXLg,
  whiteColor,
  NW2BackIconSize,
  NW2SummaryBlockMdWidth,
  NW2SummaryBlockLgWidth,
} from 'constants/styleVars';

import {NW2Container} from 'view/mainLanding/MainLanding.styles';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${whiteColor};
  padding-top: ${offsetXXLg};
  padding-bottom: ${offsetXXXLg};
`;

export const StyledContainer = styled(NW2Container)``;

export const GoBackBlock = styled.div`
  display: grid;
  grid-template-columns: ${NW2BackIconSize} 1fr;
  grid-column-gap: ${offsetXXLg};
  align-items: center;
  margin-bottom: ${offsetXXXLg};
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  letter-spacing: 0.02em;
`;

export const Main = styled.div`
  flex-grow: 1;
  display: grid;
  grid-column-gap: ${offsetXLg};
  align-items: start;

  @media (min-width: ${mdBp}px) {
    grid-template-columns: 1fr ${NW2SummaryBlockMdWidth};
  }

  @media (min-width: ${lgBp}px) {
    grid-template-columns: 1fr ${NW2SummaryBlockLgWidth};
  }
`;

export const Divider = styled.div`
  margin-top: ${offsetXXXXLg};
  margin-bottom: ${offsetXXXXLg};
  border-top: 1px solid ${NW2Gray200Color};
`;

export const LoaderWrapper = styled.div<{isMobile: boolean}>`
  position: relative;
  top: ${({isMobile}) => (isMobile ? '50%' : '40%')};
  left: 50%;
  transform: translate(-50%, -50%);
`;

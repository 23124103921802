import styled from 'styled-components';

import {offsetXSm} from 'constants/styleVars';
import {AMENITIES_COLUMN_WIDTH} from 'constants/app';

export const GroupWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: calc(${AMENITIES_COLUMN_WIDTH} * 2 + ${offsetXSm});
  grid-column-gap: ${offsetXSm};
`;

export const FlexContainer = styled.div`
  display: flex;
`;

import React, {FC, PropsWithChildren, useEffect} from 'react';
import {Layout} from 'antd';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';
import Div100vh from 'react-div-100vh';

import Header from '../Header';
import Login from '../../auth/LoginCustomer/Login';
import TopNotification from '../TopNotification';
import MultiModal from '../MultiModal';
import NW2MultiModal from '../NW2MultiModal';
import NW2Share from '../NW2Share';
import NW2Feedback from '../NW2Feedback';
import GoogleMapLoader from '../App/components/GoogleMapLoader';
import NW2BillingAddressPopup from 'view/customer/NW2BillingAddressDetails/NW2BillingAddressPopup';

import {EAppTheme} from 'types/app';
import NW2Footer from '../NW2Footer';
import {useDeviceType} from 'hooks/useDeviceType';
import {setAppTheme} from 'store/app/appSlice';
import 'view/common/Layout/Layout.css';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

const AppContainer = styled(Div100vh)`
  display: flex;
  flex-direction: column;
`;

const PageContentContainer = styled(Layout)`
  background: ${(props) => props.theme.bodyBackground};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
`;

// MAIN LAYOUT COMPONENT
const LayoutDefault: FC<PropsWithChildren<any>> = (props) => {
  useDeviceType();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRole: EUserRoleCognito = useAppSelector(({app}) => app.user.role);

  const darkThemeUserRole = [
    EUserRoleCognito.ROLE_CUSTOMER,
    EUserRoleCognito.ROLE_GUEST,
    EUserRoleCognito.ROLE_SUPPLIER,
    EUserRoleCognito.ROLE_PROPERTY_MANAGER,
    EUserRoleCognito.ROLE_ADMIN,
    EUserRoleCognito.ROLE_AGENT,
  ].includes(userRole as EUserRoleCognito);

  // Apply dark or white theme
  useEffect(() => {
    if (darkThemeUserRole) {
      dispatch(setAppTheme(EAppTheme.THEME_DARK));
    } else {
      dispatch(setAppTheme(EAppTheme.THEME_WHITE));
    }
  }, [darkThemeUserRole, dispatch]);

  // Scroll to top on page change
  useEffect(() => {
    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      if (typeof window !== 'undefined') {
        const appElement = document.getElementById('app');
        appElement && appElement.scrollIntoView();
      }
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location.pathname]);

  return (
    <AppContainer id='app'>
      <TopNotification />
      <Header />
      <PageContentContainer id='main'>{props.children}</PageContentContainer>
      <NW2Footer />
      <Login />
      {/* Top level multi modal container */}
      <MultiModal />
      {/* Top level NW2 multi modal container */}
      <NW2MultiModal />
      <GoogleMapLoader />
      <NW2BillingAddressPopup />
      <NW2Share />
      <NW2Feedback />
    </AppContainer>
  );
};

export default LayoutDefault;

import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import LocalStorageService from 'infra/common/localStorage.service';

import {makeMultiSearchPayload} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/utils';
import {setInitialTimeRange} from 'view/components/NW2Datepicker';
import {useQuery} from 'hooks/useQuery';
import {ERoomSchemaNames} from 'types/venue';
import {TBedroomsCatering} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/types';
import {TDay} from 'types/search';
import {ITimeData} from 'types/dto/ISearch.types';
import {EResourcesType, IExtrasOption} from 'types/dto/IExtras.type';
import {
  DEFAULT_CHECK_IN,
  DEFAULT_CHECK_OUT,
} from 'constants/defaultOperationalTimes';

function useInitialMultiRoomData() {
  const {multiRooms, startDate, endDate, seatingPlan} = useQuery();

  const multiSearchData = LocalStorageService.getByKey('multiSearchData');
  const storageBedroomsCatering =
    LocalStorageService.getByKey('bedroomsCatering');

  const parsedMultiSearchData = useMemo(
    (): {meetingRequestData: TDay[]; timeData: ITimeData[]} =>
      multiSearchData ? JSON.parse(multiSearchData) : {},
    [multiSearchData],
  );

  const bedroomsCatering: TBedroomsCatering = useMemo(
    () => (storageBedroomsCatering ? JSON.parse(storageBedroomsCatering) : {}),
    [storageBedroomsCatering],
  );

  const {meetingRequestData = [], timeData} = parsedMultiSearchData;

  const initialTimeData = useMemo(
    () =>
      setInitialTimeRange({
        startTime: (startDate as string) || DEFAULT_CHECK_IN,
        endTime: (endDate as string) || DEFAULT_CHECK_OUT,
        isMultiSearchRoom: Boolean(multiRooms),
        timeData,
      }),
    [endDate, multiRooms, timeData, startDate],
  );

  const extrasOption: IExtrasOption[] = useSelector((state) =>
    _get(state, 'venue.extrasOption'),
  );

  const {multiSearchPayload} = useMemo(() => {
    if (meetingRequestData.length) {
      const filteredBedroomExtras = extrasOption.filter(
        (item) => item.type === EResourcesType.BEDROOM,
      );
      return makeMultiSearchPayload({
        meetingRequestData,
        filteredBedroomExtras,
        bedroomsCatering,
      });
    }

    const initialData = initialTimeData.map(({timeStart, timeEnd}, index) => ({
      startDate: timeStart || DEFAULT_CHECK_IN,
      endDate: timeEnd || DEFAULT_CHECK_OUT,
      rooms: [
        {
          timeStart: timeStart || DEFAULT_CHECK_IN,
          timeEnd: timeEnd || DEFAULT_CHECK_OUT,
          participants: 1,
          equipmentData: {},
          seatsSetup: seatingPlan || ERoomSchemaNames.BLOCK,
        },
      ],
      foodBeverageData: meetingRequestData[index]?.foodBeverageData || null,
      accommodationData: meetingRequestData[index]?.accommodationData || null,
      isSectionExpanded: !index,
    })) as TDay[];

    return makeMultiSearchPayload({meetingRequestData: initialData});
  }, [
    bedroomsCatering,
    extrasOption,
    initialTimeData,
    meetingRequestData,
    seatingPlan,
  ]);

  return {
    multiSearchPayload,
    meetingRequestData,
    isMultiSearchRoom: multiRooms,
    initialTimeData,
    bedroomsCatering,
  };
}

export default useInitialMultiRoomData;

import React, {ReactNode} from 'react';

import LeftSideItem from './LeftSideItem';
import TextValue from 'view/components/TextValue';

import {getAddressStringFromBillingAddress} from 'utils/stringUtils';
import {
  fontSizeMd,
  fontSizeSm,
  NW2Gray600Color,
  offsetDef,
  offsetNone,
  offsetSm,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';
import {Group} from 'styles/basicStyledComponents/group';
import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {EPaymentType, IPaymentInfo} from 'types/bookingOrders';

type TProps = {
  paymentInfo?: IPaymentInfo;
  billingAddress?: ICustomerBillingAddress;
  isHidden?: boolean;
};

export const PAYMENT_HEADER_DATA: {[key in EPaymentType]?: ReactNode} = {
  [EPaymentType.FREE]: (
    <Group direction='column'>
      <TextValue fontSize={fontSizeMd} bold>
        Make your payment directly to the venue.
      </TextValue>
      <TextValue fontSize={fontSizeMd}>
        The venue will verify your payment method after your booking has been
        confirmed, and they will contact you directly to arrange for it.
      </TextValue>
    </Group>
  ),
  [EPaymentType.DIRECT_PAYMENT]: (
    <Group direction='column' gap={offsetXSm}>
      <TextValue
        color={NW2Gray600Color}
        fontSize={fontSizeSm}
        marginTop={offsetNone}
        bold
      >
        Payment method:
      </TextValue>
      <TextValue fontSize={fontSizeMd} marginTop={offsetNone} bold>
        Direct payment to venue
      </TextValue>
    </Group>
  ),
  [EPaymentType.CORPORATE_HRS_PAY]: (
    <Group direction='column' gap={offsetXSm}>
      <TextValue
        color={NW2Gray600Color}
        fontSize={fontSizeSm}
        marginTop={offsetNone}
        bold
      >
        Payment method:
      </TextValue>
      <TextValue fontSize={fontSizeMd} marginTop={offsetNone} bold>
        Corporate payment
      </TextValue>
    </Group>
  ),
};

const ItemPaymentInformation = ({
  paymentInfo,
  billingAddress,
  isHidden,
}: TProps) => {
  if (!paymentInfo || !paymentInfo.paymentType || !billingAddress || isHidden)
    return null;

  const {paymentType, referenceFields} = paymentInfo;

  const address = getAddressStringFromBillingAddress(billingAddress);

  return (
    <LeftSideItem
      item={{
        title: 'Payment information',
        children: (
          <div>
            {PAYMENT_HEADER_DATA[paymentType]}
            {paymentType === EPaymentType.CORPORATE_HRS_PAY && (
              <>
                <TextValue
                  fontSize={fontSizeMd}
                  marginTop={offsetXSm}
                  marginBottom={offsetDef}
                >
                  {address}
                </TextValue>

                {referenceFields?.map((field) => {
                  const {key, value} = field;
                  return (
                    <Group key={key} gap={offsetXXSm}>
                      <TextValue
                        color={NW2Gray600Color}
                        fontSize={fontSizeSm}
                        bold
                        inline
                      >
                        {key}:
                      </TextValue>
                      <TextValue fontSize={fontSizeSm} inline>
                        {value}
                      </TextValue>
                    </Group>
                  );
                })}

                <TextValue
                  color={NW2Gray600Color}
                  fontSize={offsetSm}
                  marginTop={offsetDef}
                >
                  <b>Note:</b> Corporate payments are managed by HRS Pay. Any
                  information related to refunds, deductions, adjustments, or
                  other payment changes will be provided by HRS Pay.
                </TextValue>
              </>
            )}
          </div>
        ),
      }}
    />
  );
};

export default ItemPaymentInformation;

import styled, {css} from 'styled-components';

import {
  blackColor,
  NW2Blue500Color,
  NW2Gray400Color,
  offsetXSm,
  NW2Gray100Color,
  NW2Gray50Color,
} from 'constants/styleVars';
import {ERadioSizes, radioSizeSchema, TSchemaType} from './types';

export const RadioWrapper = styled.div`
  display: inline-block;
`;

export const Mark = styled.span<{
  checked: boolean;
  size: ERadioSizes;
  disabled: boolean;
}>(({checked, size, disabled}) => {
  const schema: TSchemaType = radioSizeSchema[size];

  return css`
    display: inline-block;
    position: relative;
    border: 1px solid ${NW2Gray400Color};
    width: ${schema.width};
    height: ${schema.height};
    left: 0;
    border-radius: 50%;
    margin-right: ${offsetXSm};
    vertical-align: middle;

    ${!checked &&
    !disabled &&
    css`
      &:hover {
        border-color: ${blackColor};
        transition: border-color 0.15s;
      }
    `};

    ${checked &&
    css`
      border-color: ${NW2Blue500Color};
      transition: border-color 0.15s;

      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: ${NW2Blue500Color};
        opacity: 0;
        left: 50%;
        top: 50%;
        position: absolute;
      }
    `}

    ${disabled &&
    css`
      border-color: ${NW2Gray100Color};
      background-color: ${NW2Gray50Color};
    `}
  `;
});

export const Input = styled.input<{size: ERadioSizes}>(({size}) => {
  const schema: TSchemaType = radioSizeSchema[size];

  return css`
    position: absolute;
    visibility: hidden;
    display: none;
    appearance: none;
    transition: opacity 0.15s;

    &:checked + ${Mark} {
      &::after {
        width: ${schema.innerWidth};
        height: ${schema.innerHeight};
        opacity: 1;
        left: 5px;
        top: 5px;
      }
    }
  `;
});

export const Label = styled.label<{size: ERadioSizes; disabled: boolean}>(
  ({size, disabled}) => {
    const schema: TSchemaType = radioSizeSchema[size];
    return css`
      display: flex;
      cursor: pointer;
      font-size: ${schema.labelFontSize};
      line-height: ${schema.labelLineHeight};
      position: relative;

      ${disabled &&
      css`
        pointer-events: none;
        cursor: not-allowed;
      `}
    `;
  },
);

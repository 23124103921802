import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';
import {FULL_FREE_OF_CHARGE} from 'constants/app';

export const getCancellationType = ({
  freeOfCharge,
  days,
  isCanceledStatus,
}: {
  freeOfCharge: number;
  days: number | null;
  isCanceledStatus?: boolean;
}) => {
  if (freeOfCharge === 0 && !days) {
    return EInfoCardType.error;
  }

  if (freeOfCharge === FULL_FREE_OF_CHARGE && (days || isCanceledStatus)) {
    return EInfoCardType.success;
  }

  return EInfoCardType.warn;
};

import {useState, useCallback} from 'react';

import {IDay, IDayOfWeek} from 'types/dto/IDay.type';

const useHoursState = (workDays: IDay[]) => {
  const [operationalTimes, setLocalOperationalTimes] = useState(workDays);

  const getDayFromState = useCallback(
    (day: IDayOfWeek) => {
      const operationalDay = operationalTimes.find(
        (time) => time.dayOfWeek === day,
      );

      return operationalDay || ({} as IDay);
    },
    [operationalTimes],
  );

  const getValueFromState = useCallback(
    (day: IDayOfWeek, fieldName: keyof IDay) => {
      return getDayFromState(day)?.[fieldName];
    },
    [getDayFromState],
  );

  const updateField = useCallback(
    (day: string, fieldName: string, value: any) => {
      const updatedState = operationalTimes.map((time) => {
        if (time.dayOfWeek === day) {
          return {
            ...time,
            [fieldName]: value,
          };
        }

        return time;
      });

      setLocalOperationalTimes(updatedState);
    },
    [operationalTimes],
  );

  const updateMultipleFields = useCallback(
    (day: string, valuesToUpdate: Partial<IDay>) => {
      const updatedState = operationalTimes.map((time) => {
        if (time.dayOfWeek === day) {
          return {
            ...time,
            ...valuesToUpdate,
          };
        }

        return time;
      });

      setLocalOperationalTimes(updatedState);
    },
    [operationalTimes],
  );

  return {
    operationalTimes,
    setLocalOperationalTimes,
    getDayFromState,
    getValueFromState,
    updateField,
    updateMultipleFields,
  };
};

export default useHoursState;

import {IUnit, TUnitCapacityStyle} from 'types/dto/IUnit.types';

import {EEventType, ERoomSchemaNames} from 'types/venue';
import {EResourcesCode} from 'types/dto/IExtras.type';
import {IDayResponse, IExtraResponse} from 'types/dto/IPublicVenue';

// const unitStatusMap: Record<EVenueUnitStatus, number> = {
//   AVAILABLE: -1,
//   SOLD_OUT: 1,
//   CLOSED: 1,
//   NONE: 1,
// };
//
// type TSortUnitsByPriceAndStatus = {
//   spaces: IUnit[];
//   selectedMeetingRoomSeatingPlan: ERoomSchemaNames;
//   meetingRoomCapacity: number;
// };
// export const sortUnitsByPriceAndStatus = ({
//   spaces,
//   selectedMeetingRoomSeatingPlan,
//   meetingRoomCapacity,
// }: TSortUnitsByPriceAndStatus) =>
//   [...spaces]
//     .sort((a, b) => (a.totalSearchPrice > b.totalSearchPrice ? 1 : -1))
//     .sort((a, b) => {
//       if (
//         a.unitStatus === EVenueUnitStatus.SOLD_OUT ||
//         b.unitStatus === EVenueUnitStatus.SOLD_OUT
//       )
//         return unitStatusMap[a.unitStatus] - unitStatusMap[b.unitStatus];
//
//       const capacityA = getCapacityFromCapacityDto(
//         a.unitCapacities,
//         selectedMeetingRoomSeatingPlan,
//       );
//       const capacityB = getCapacityFromCapacityDto(
//         b.unitCapacities,
//         selectedMeetingRoomSeatingPlan,
//       );
//
//       if (capacityA && capacityA < meetingRoomCapacity) return 1;
//       if (capacityB && capacityB < meetingRoomCapacity) return -1;
//       return 0;
//     });

export const getAvailableSeatingPlan = (
  spaces: IUnit[],
  searchedRoomCapacity?: number,
  isAlternative?: boolean,
) => {
  const availableSeatingPlan: ERoomSchemaNames[] = [];
  spaces.forEach((space) => {
    space.unitCapacities?.forEach((item) => {
      const doArrayIncludeSetupStyle = availableSeatingPlan.includes(
        item.setupStyle,
      );
      if (isAlternative && searchedRoomCapacity) {
        if (
          !doArrayIncludeSetupStyle &&
          item.capacity >= searchedRoomCapacity
        ) {
          availableSeatingPlan.push(item.setupStyle);
        }
      } else {
        if (!doArrayIncludeSetupStyle)
          availableSeatingPlan.push(item.setupStyle);
      }
    });
  });
  return availableSeatingPlan;
};

export const filterUnitsBySeatingPlan = (
  units: IUnit[],
  selectedMeetingRoomSeatingPlan: ERoomSchemaNames,
) =>
  units?.filter((units) =>
    units.unitCapacities?.some(
      (capacityDto: TUnitCapacityStyle) =>
        capacityDto.setupStyle === selectedMeetingRoomSeatingPlan,
    ),
  );

// const filterUnitsByRoomCapacity = (
//   spaces: IUnit[],
//   selectedMeetingRoomSeatingPlan: ERoomSchemaNames,
//   searchedRoomCapacity: number,
// ) =>
//   spaces?.filter((item) => {
//     const capacityBySetup = getCapacityFromCapacityDto(
//       item.unitCapacities,
//       selectedMeetingRoomSeatingPlan,
//     );
//     const meetingRoomCapacity = capacityBySetup
//       ? capacityBySetup
//       : item.capacity;
//     return !(searchedRoomCapacity > meetingRoomCapacity);
//   });

export const filterAccommodationsByType = (
  unitExtras: IExtraResponse[],
  type: EResourcesCode,
) => unitExtras.filter((extra) => extra.code === type)[0];

export const getPrePostAccommodation = (
  venueDays: IDayResponse[],
  eventType: EEventType,
) =>
  venueDays
    .filter((unit) => unit.eventType === eventType)
    .sort((a, b) => +new Date(a?.checkIn) - +new Date(b?.checkIn));

export const getBedroomsArray = (
  bedrooms: IExtraResponse,
  bedroomsWithBreakfast: IExtraResponse,
) =>
  [bedrooms, bedroomsWithBreakfast].filter((element) => element !== undefined);

export const getAccommodationsData = (unitExtras: IExtraResponse[]) => {
  const singleBedroom = filterAccommodationsByType(
    unitExtras,
    EResourcesCode.SINGLE_BEDROOM,
  );
  const doubleBedroom = filterAccommodationsByType(
    unitExtras,
    EResourcesCode.DOUBLE_BEDROOM,
  );
  const singleBedroomWithBreakfast = filterAccommodationsByType(
    unitExtras,
    EResourcesCode.SINGLE_BEDROOM_WITH_BREAKFAST,
  );
  const doubleBedroomWithBreakfast = filterAccommodationsByType(
    unitExtras,
    EResourcesCode.DOUBLE_BEDROOM_WITH_BREAKFAST,
  );

  const singleBedrooms = getBedroomsArray(
    singleBedroom,
    singleBedroomWithBreakfast,
  );
  const doubleBedrooms = getBedroomsArray(
    doubleBedroom,
    doubleBedroomWithBreakfast,
  );

  return {
    singleBedrooms,
    doubleBedrooms,
  };
};

import React, {memo} from 'react';

import {
  NW2FormItemInput,
  NW2FormItemSelect,
} from 'view/components/NW2FormItem/NW2FormItem';

import {getArrayOfFloorNames} from 'constants/floorStorey';
import {NW2Gray600Color} from 'styles/configs/colors';
import {offsetXLg} from 'styles/configs/offset';
import {
  requiredFieldRules,
  spaceNameFieldRules,
} from 'utils/finalFormFieldRules';
import {ESpaceDetailsFormFields} from '../../types';
import {FormBox, FormGroup} from '../../NMMSpacesDetails.styles';

interface IProps {
  floors: number[] | [];
}

export const NameAndFloorFormItems = memo(({floors}: IProps) => {
  const arrayOfFloorNames = getArrayOfFloorNames(floors);

  const floorOptions = arrayOfFloorNames.map((item) => ({
    key: item,
    value: item,
    text: item,
  }));

  return (
    <FormBox>
      <FormGroup columnNumber={1} gap={parseInt(offsetXLg)}>
        <NW2FormItemInput
          type='text'
          name={ESpaceDetailsFormFields.SPACE_NAME}
          placeholder='Space name'
          label='Space name*'
          labelColor={NW2Gray600Color}
          rules={spaceNameFieldRules}
          data-testid={`input-space-details-${ESpaceDetailsFormFields.SPACE_NAME}`}
        />
        <NW2FormItemSelect
          name={ESpaceDetailsFormFields.FLOOR}
          options={floorOptions}
          placeholder='Floor'
          label='Floor*'
          labelColor={NW2Gray600Color}
          rules={requiredFieldRules(ESpaceDetailsFormFields.FLOOR)}
          data-testid={`input-space-details-${ESpaceDetailsFormFields.FLOOR}`}
          readonly
        />
      </FormGroup>
    </FormBox>
  );
});

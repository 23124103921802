import React from 'react';
import styled, {css} from 'styled-components';
import {useDispatch} from 'react-redux';

import Icon from 'view/components/Icon';
import NW2Button from '../NW2Button';

import {ICustomerBillingAddress} from 'types/dto/IUser.types';
import {
  fontSizeMd,
  fontWeightBold,
  lineHeightLg,
  NW2Gray600Color,
  NW2Gray900Color,
  offsetSm,
  offsetXSm,
  offsetXXXSm,
} from 'constants/styleVars';
import {setCustomerBillingAddressPopup} from 'store/app/appSlice';
import {getAddressStringFromBillingAddress} from 'utils/stringUtils';
import {
  SectionRowColumns,
  SectionRowColumnsActions,
} from 'view/components/NW2ContentBlock/NW2ContentBlock.styles';

const AddressWrapper = styled.div<{showIcon?: boolean}>`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};

  ${({showIcon}) =>
    showIcon
      ? css`
          display: grid;
          grid-template-columns: 24px 1fr;
          grid-column-gap: ${offsetSm};
        `
      : css`
          display: block;
        `};
`;

const IconBox = styled.div`
  padding-top: ${offsetXXXSm};
  text-align: center;
`;

const Row = styled.div`
  margin-bottom: ${offsetXSm};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RowTitle = styled.span`
  color: ${NW2Gray600Color};
  font-weight: ${fontWeightBold};
`;

function BillingAddressView({
  billingAddress,
  hasEditButton,
  showIcon,
}: {
  billingAddress?: ICustomerBillingAddress;
  hasEditButton?: boolean;
  showIcon?: boolean;
}) {
  const dispatch = useDispatch();

  if (!billingAddress) return null;

  const {costCenter, additionalReference} = billingAddress;

  const showEditPopup = () => {
    dispatch(
      setCustomerBillingAddressPopup({setCustomerBillingAddressPopup: true}),
    );
  };

  return (
    <SectionRowColumns>
      <AddressWrapper showIcon={showIcon}>
        {showIcon && (
          <IconBox>
            <Icon transparent icon='NW2_BOOK_HOUSE' />
          </IconBox>
        )}
        <div>
          <Row>{getAddressStringFromBillingAddress(billingAddress)}</Row>

          {costCenter && (
            <Row>
              <RowTitle>Cost centre:</RowTitle> {costCenter}
            </Row>
          )}

          {additionalReference && (
            <Row>
              <RowTitle>Additional reference:</RowTitle> {additionalReference}
            </Row>
          )}
        </div>
      </AddressWrapper>
      {hasEditButton && (
        <SectionRowColumnsActions>
          <NW2Button
            onClick={showEditPopup}
            buttonType='tertiary'
            icon={<Icon icon='NW2_EDIT' transparent size={17} />}
            size='responsiveAdaptive'
          >
            Change
          </NW2Button>
        </SectionRowColumnsActions>
      )}
    </SectionRowColumns>
  );
}

export default BillingAddressView;

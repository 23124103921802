import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';

import {TextValue} from 'view/components/TextValue';

import TopNotificationContainer from './TopNotificationContainer';
import useInterval from 'hooks/useInterval';
import {pluralize} from 'utils/helpers';
import {Routes} from 'constants/routes';
import {ERfpKpi, ETotalFilteredRecords} from 'types/dto/IBookingType.type';
import {bookingOverviewAmountSearchTabs} from 'view/supplier/Bookings/BookingsList/helpers';
import {useBookingsAmount} from 'view/supplier/hooks/useBookingsAmount';
import {useActions} from 'hooks/useActions';

const getParsedAmount = (amount: string | undefined) => parseInt(amount || '0');

const TopNotification = () => {
  const navigate = useNavigate();
  const {getAmountOfBookings, getRequestsCount} = useActions();

  const pendingRequestsCount = useAppSelector(
    ({offers}) => offers.pendingRequestsCount,
  );
  const venueId = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );

  const {searchTabsCount, kpiList} = useBookingsAmount();
  const amountOfBookingPendingRequests = getParsedAmount(
    searchTabsCount?.[ETotalFilteredRecords.RFP_PENDING],
  );
  const amountOfEditedUnreadOrders = getParsedAmount(
    kpiList?.[ERfpKpi.RTC_KPI_UNREAD],
  );

  const parsedPRNumber = amountOfBookingPendingRequests + pendingRequestsCount;

  useEffect(() => {
    if (venueId) {
      getAmountOfBookings({...bookingOverviewAmountSearchTabs, venueId});
    }
  }, [venueId, getAmountOfBookings]);

  useInterval(() => {
    if (venueId) {
      getAmountOfBookings({...bookingOverviewAmountSearchTabs, venueId});

      if (pendingRequestsCount) {
        getRequestsCount(venueId);
      }
    }
  }, 60000);

  if ((!parsedPRNumber && !amountOfEditedUnreadOrders) || !venueId) return null;

  const navigateToBookings = () => {
    navigate(Routes.bookingsListRooms, {state: {reloadPage: true}});
  };

  return (
    <TopNotificationContainer>
      <Icon error icon='WARN' />
      &nbsp;You have&nbsp;
      {!!parsedPRNumber && (
        <>
          <b>{parsedPRNumber}</b>
          &nbsp;
          {pluralize('pending requests', parsedPRNumber, false)}
        </>
      )}
      {!!(parsedPRNumber && amountOfEditedUnreadOrders) && <> and&nbsp;</>}
      {!!amountOfEditedUnreadOrders && (
        <>
          <b>{amountOfEditedUnreadOrders}</b>&nbsp;updated{' '}
          {pluralize('booking', Number(amountOfEditedUnreadOrders), false)}
        </>
      )}
      . &nbsp;
      <TextValue primary onClick={navigateToBookings} marginTop='0'>
        Respond now
      </TextValue>
    </TopNotificationContainer>
  );
};

export default TopNotification;

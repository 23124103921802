import {UploadFile} from 'antd/es/upload/interface';

export type THandleUpdateImages = ({
  handleProgress,
}: {
  handleProgress?: (v: number) => void;
}) => void;

export type TFileWithPreview = {
  preview: string;
} & File;

export type TUploadFile = Partial<UploadFile> & {
  isCover?: boolean | null;
  uploadDate?: Date;
  blob?: string;
  id?: string;
};

export enum EUploadedFileType {
  PDF_FILE = 'PDF_FILE',
  IMAGE = 'IMAGE',
}

export enum EUploaderLabel {
  PDF_FILE = 'Upload a PDF File',
  IMAGE = 'Upload image',
}

import React, {memo} from 'react';

import DimensionsForm from './DimensionsForm';
import {IUnitDimensions} from 'types/venue';
import DimensionsViewMode from './DimensionsViewMode';
import {ICommonProps} from '../../types';
import {TDimensionsInitial} from './types';

interface IProps extends ICommonProps {
  initialValues: TDimensionsInitial;
  unitDimensions: IUnitDimensions;
}

const Dimensions = ({
  initialValues,
  unitDimensions,
  updateSpace,
  isSending,
  isEditorShowed,
  onEditorShowed,
}: IProps) => {
  return isEditorShowed ? (
    <DimensionsForm
      initialValues={initialValues}
      onEditorShowed={onEditorShowed}
      updateSpace={updateSpace}
      isSending={isSending}
    />
  ) : (
    <DimensionsViewMode unitDimensions={unitDimensions} />
  );
};

export default memo(Dimensions);

import React, {useMemo, useState} from 'react';
import _debounce from 'lodash/debounce';

import Icon from 'view/components/Icon';
import SocialMediaEditForm from 'view/components/NW2Forms/NW2SupplierContactsDetailsForm.tsx/SocialMediaEditForm';
import useUpdateVenue from 'view/venue/hooks/useUpdateVenue';

import {ICON_SIZE} from 'view/venue/NW2VenueProfile/helpers';
import {IVenue} from 'types/venue';
import {SectionRow, SectionText} from '../ContactsDetails.styles';
import {TFormProps} from '../FormSection';
import {EAccommodationType} from 'types/dto/IPublicVenue';

const useSocialMedia = (venue: IVenue): TFormProps => {
  const [isEditorShowed, setEditorShowed] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);

  const isExternalVenue = venue.accommodationType === EAccommodationType.VENUE;

  const {facebookUrl, instagramUrl, twitterUrl} = venue.contact;
  const initialValues = useMemo(() => {
    return {facebookUrl, instagramUrl, twitterUrl};
  }, [facebookUrl, instagramUrl, twitterUrl]);

  const onEditorShow = () => {
    setEditorShowed(true);
  };
  const onEditorHide = () => {
    setEditorShowed(false);
  };

  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: onEditorHide,
  });

  const onSubmit = async (values: Record<string, string>) => {
    try {
      const updatedVenue = {
        ...venue,
        contact: {
          ...venue.contact,
          facebookUrl: values.facebookUrl,
          instagramUrl: values.instagramUrl,
          twitterUrl: values.twitterUrl,
        },
      };

      updateVenue(updatedVenue);

      setEditorShowed(false);
    } catch (error) {}
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  return {
    title: 'Social media',
    onSubmit,
    initialValues,
    editForm: <SocialMediaEditForm />,
    showForm: (
      <div>
        <SectionRow>
          <Icon icon='NW2_FACEBOOK' size={ICON_SIZE} />
          <SectionText data-testid='social-facebook-field'>
            {facebookUrl || '-'}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_INSTAGRAM' size={ICON_SIZE} />
          <SectionText data-testid='social-instagram-field'>
            {instagramUrl || '-'}
          </SectionText>
        </SectionRow>
        <SectionRow>
          <Icon icon='NW2_TWITTER' size={ICON_SIZE} />
          <SectionText data-testid='social-twitter-field'>
            {twitterUrl || '-'}
          </SectionText>
        </SectionRow>
      </div>
    ),
    isEditorShowed,
    onEditorShow,
    onEditorHide,
    isFormDirty,
    isFormSending: isSending,
    processFormFields,
  };
};

export default useSocialMedia;

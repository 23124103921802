import React, {useState} from 'react';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import {ListItemNode, ListNode} from '@lexical/list';
import {CodeHighlightNode, CodeNode} from '@lexical/code';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {TRANSFORMERS} from '@lexical/markdown';
import {$generateHtmlFromNodes} from '@lexical/html';
import {$getRoot, EditorState, LexicalEditor} from 'lexical';

import {
  ToolbarPlugin,
  editorTheme,
  PrePopulateTextPlugin,
  FocusPlugin,
} from './components';

import {
  EditorInner,
  EditorInput,
  EditorPlaceholder,
  EditorContainer,
} from './WysiwygEditor.styles';
import {
  StyledLabel,
  StyledLabelContainer,
} from 'view/components/NW2FormItem/NW2FormItem.styles';

const editorConfig = {
  namespace: 'WysiwygEditor',
  // The editor theme
  theme: editorTheme,
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export type TOnChangeArgs = {
  htmlString: string;
  rootNodeContext: string;
};

type TProps = {
  onChangeHandler: (args: TOnChangeArgs) => void;
  initialHtmlString?: string;
  hasError?: boolean;
  label?: string;
  placeholder?: string;
  isSmall?: boolean;
};

function WysiwygEditor({
  onChangeHandler,
  initialHtmlString,
  hasError = false,
  label,
  placeholder,
  isSmall = false,
}: TProps) {
  const [focus, setFocus] = useState(false);
  const onChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      const rootNodeContext = $getRoot().getTextContent();
      onChangeHandler({
        htmlString,
        rootNodeContext,
      });
    });
  };

  return (
    <EditorContainer
      variant='primary'
      inputSize='medium'
      focused={focus}
      hasError={hasError}
    >
      {focus && label && (
        <StyledLabelContainer hasError={hasError} focused={focus}>
          <StyledLabel
            hasError={hasError}
            variant='primary'
            inputSize='medium'
            focused={focus}
          >
            {label}
          </StyledLabel>
        </StyledLabelContainer>
      )}
      <LexicalComposer initialConfig={editorConfig}>
        <ToolbarPlugin isSmall={isSmall} />

        <EditorInner>
          <RichTextPlugin
            contentEditable={<EditorInput />}
            placeholder={
              !focus ? (
                <EditorPlaceholder>{placeholder}</EditorPlaceholder>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />

          <HistoryPlugin />
          <LinkPlugin />
          <ListPlugin />
          <PrePopulateTextPlugin initialHtmlString={initialHtmlString} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </EditorInner>
        <OnChangePlugin onChange={onChange} />
        <FocusPlugin setFocus={setFocus} />
      </LexicalComposer>
    </EditorContainer>
  );
}

export default WysiwygEditor;

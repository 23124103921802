import {useMemo} from 'react';

import {useAppSelector} from 'store/hooks';
import {ERequestStatus} from 'types/offer';

export function useRequestPendingList() {
  const allRequests = useAppSelector(({offers}) => offers.requests);

  // after offer creation we have offerId, but status isn't changed until payment data has been resolved
  const pendingRequestsList = useMemo(
    () =>
      allRequests.filter(
        (item) =>
          item.status === ERequestStatus.REQUEST_PENDING && !item.offerId,
      ),
    [allRequests],
  );

  return {pendingRequestsList};
}

import React from 'react';

import Icon from 'view/components/Icon';

import {ERoomSchemaNames, iconsObject} from 'types/venue';

import {
  RoomSetupBlock,
  RoomSetupBlockIconWrapper,
} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddMeetingRequest/AddMeetingRequest.styles';
import {FlexContainer} from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddFoodBeverageRequest/AddFoodBeverageRequest.styles';

type TProps = {
  type: ERoomSchemaNames;
  label?: React.ReactNode | string;
  radioButton?: React.ReactNode;
};

const SeatsSetupItem = ({radioButton, label, type}: TProps) => {
  return (
    <RoomSetupBlock>
      <FlexContainer>
        {!!radioButton && radioButton}
        {!!label && label}
      </FlexContainer>
      <RoomSetupBlockIconWrapper>
        <Icon icon={iconsObject[type]} />
      </RoomSetupBlockIconWrapper>
    </RoomSetupBlock>
  );
};

export default SeatsSetupItem;

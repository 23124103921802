import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {Form, Input, InputNumber} from 'antd';
import _get from 'lodash/get';

import {FormIntroText} from '../AddressForm';

import {
  PATH_TO_REDUCER_VENUE_DATA,
  PATH_TO_REDUCER_VENUE_POI_DATA,
} from 'constants/venue';
import {RootState} from 'store/types';
import {
  EPointsOfInterest,
  IPointOfInterest,
  pointsOfInterestMap,
} from 'types/venue';
import {poiFieldRules} from 'utils/antdFormFieldRules';
import {
  InputGroup,
  InputGroupDistance,
  InputGroupSeparator,
} from './PointsOfInterest.styles';
import {usePointsOfInterestFormItem} from 'view/venue/hooks/usePointsOfInterestFormItem';

const {Item} = Form;

interface IItemProps {
  type: EPointsOfInterest;
  data: IPointOfInterest[];
}

const PointsOfInterestItem = memo(({data = [], type}: IItemProps) => {
  const {
    kmValue,
    miValue,
    name,
    onDistanceChangeKm,
    onDistanceChangeMi,
    handleFormFieldName,
  } = usePointsOfInterestFormItem({
    data,
    type,
  });

  return (
    <Item
      name={type}
      label={pointsOfInterestMap[type]}
      rules={poiFieldRules[type]}
    >
      <InputGroup>
        <Input
          defaultValue={name}
          name={type}
          onChange={handleFormFieldName}
          data-testid={`poi-input-${type}`}
        />

        <InputGroupDistance>
          <InputNumber
            value={kmValue}
            defaultValue={0}
            min={0}
            max={999}
            onChange={onDistanceChangeKm as any}
            data-testid={`poi-input-${type}-km`}
            prefix='km'
          />

          <InputGroupSeparator>=</InputGroupSeparator>

          <InputNumber
            value={miValue}
            defaultValue={0}
            min={0}
            max={999}
            onChange={onDistanceChangeMi as any}
            data-testid={`poi-input-${type}-mi`}
            prefix='mi'
          />
        </InputGroupDistance>
      </InputGroup>
    </Item>
  );
});

function PointsOfInterest() {
  const poiData: IPointOfInterest[] | [] = useSelector((state: RootState) =>
    _get(
      state,
      `${PATH_TO_REDUCER_VENUE_DATA}.${PATH_TO_REDUCER_VENUE_POI_DATA}`,
    ),
  );

  return (
    <>
      <FormIntroText>Points of interest:</FormIntroText>

      {Object.values(EPointsOfInterest).map((item) => (
        <PointsOfInterestItem key={item} type={item} data={poiData} />
      ))}
    </>
  );
}

export default PointsOfInterest;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IMultiDayPublicVenue, IUnitResponse} from 'types/dto/IPublicVenue';
import LocalStorageService from '../../infra/common/localStorage.service';
import {ISelectedStorageVenueUnit} from './types';
import {IDay} from 'types/dto/IDay.type';

interface IOffersInitialState {
  error: string | null;
  isLoading: boolean;
  details: IMultiDayPublicVenue | null;
  selectedRoom: IUnitResponse | Record<string, never>;
  operationalTimes: IDay[];
  operationalTimesLoading: boolean;
  supplierVenueId: number;
}

const initialState: IOffersInitialState = {
  error: null,
  isLoading: false,
  details: null,
  selectedRoom: {},
  operationalTimes: [],
  operationalTimesLoading: false,
  supplierVenueId: 0,
};

const venueDetailsSlice = createSlice({
  name: 'venueDetails',
  initialState,
  reducers: {
    setSupplierVenueId(state, action: PayloadAction<number>) {
      state.supplierVenueId = action.payload;
    },
    setVenueDetails(state, action: PayloadAction<IMultiDayPublicVenue | null>) {
      state.details = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setVenueDetailsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setVenueDetailsError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setSelectedRoom(
      state,
      action: PayloadAction<{unit: IUnitResponse; venueId?: number}>,
    ) {
      const unit = action.payload.unit;
      const venueId = action.payload.venueId;

      state.selectedRoom = unit;

      if (venueId) {
        /**
         * set selected unit to storage for page update or call on another pages reasons
         * totalPrice depends on selected unit, so we pass selected unitId to /multibook-details
         */
        const storedSelectedUnitsData =
          LocalStorageService.getByKey('selectedVenueUnits');
        const selectedUnitsData = storedSelectedUnitsData
          ? JSON.parse(storedSelectedUnitsData)
          : {};

        const venueSelectedUnitsData = selectedUnitsData[venueId];

        let storageDataToUpdate;

        if (venueSelectedUnitsData) {
          const existedUnit = venueSelectedUnitsData.find(
            ({checkIn}: ISelectedStorageVenueUnit) => checkIn === unit.checkIn,
          );

          if (existedUnit) {
            storageDataToUpdate = venueSelectedUnitsData.map(
              (item: ISelectedStorageVenueUnit) =>
                item.checkIn === unit.checkIn
                  ? {
                      ...item,
                      unitId: unit.unitId,
                    }
                  : item,
            );
          } else {
            storageDataToUpdate = [
              ...venueSelectedUnitsData,
              {
                unitId: unit.unitId,
                checkIn: unit.checkIn,
                checkOut: unit.checkOut,
              },
            ];
          }
        } else {
          storageDataToUpdate = [
            {
              unitId: unit.unitId,
              checkIn: unit.checkIn,
              checkOut: unit.checkOut,
            },
          ];
        }

        LocalStorageService.setByKey(
          'selectedVenueUnits',
          JSON.stringify({[venueId]: storageDataToUpdate}),
        );
      }
    },
    setOperationalTimesRequest(state) {
      state.operationalTimesLoading = true;
    },
    setOperationalTimesSuccess(state, action: PayloadAction<IDay[]>) {
      state.operationalTimes = action.payload;
      state.operationalTimesLoading = false;
    },
    setOperationalTimesError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.operationalTimesLoading = false;
    },
    clearOperationalTimes(state) {
      state.operationalTimes = [];
    },
    clearSupplierVenueId(state) {
      state.supplierVenueId = 0;
    },
  },
});

export const {
  setSupplierVenueId,
  setVenueDetails,
  setVenueDetailsLoading,
  setVenueDetailsError,
  setSelectedRoom,
  setOperationalTimesRequest,
  setOperationalTimesSuccess,
  setOperationalTimesError,
  clearOperationalTimes,
  clearSupplierVenueId,
} = venueDetailsSlice.actions;

export default venueDetailsSlice.reducer;

import React from 'react';
import {NavLink} from 'react-router-dom';

import Icon from '../Icon';

import {truncateSimpleText} from 'utils/stringUtils';
import {TProps, TMenuProps} from './types';
import {
  ArrowBlock,
  DropdownWrapper,
  MenuItem,
  SimpleMenuItem,
  StyledDropdown,
  StyledMenu,
  StyledNavLink,
  Wrapper,
  OuterWrapper,
  StyledIcon,
} from './NW2MenuItem.styles';
import {SupplierHeaderItems} from 'view/common/Header/types';

const getDropdownMenu = ({
  dropdownItems,
  onDropdownClick,
  itemStyle,
  width,
  activeDropdownItem,
}: TMenuProps) => {
  const handleDropDownClick = (id: SupplierHeaderItems) => () =>
    onDropdownClick(id);

  const items =
    dropdownItems?.map(
      ({id, title, disabled, onClick, customComponent, link = ''}) => {
        if (customComponent) {
          return {
            key: id,
            label: customComponent,
          };
        }

        return {
          label: (
            <>
              {disabled ? (
                <>{title}</>
              ) : (
                <NavLink onClick={onClick} to={link}>
                  {title}
                </NavLink>
              )}
            </>
          ),
          key: id,
          onClick: handleDropDownClick(id),
        };
      },
    ) || [];

  return (
    <StyledMenu
      selectedKeys={[activeDropdownItem]}
      itemstyle={itemStyle}
      width={width}
      items={items}
    />
  );
};

export const NW2MenuItem = ({
  title,
  icon,
  activeDropdownItem,
  dropdownItems,
  color = 'whiteColor',
  position = 'center',
  iconSize = 20,
  itemStyle = 'default',
  url = '',
  isActive = false,
  width,
  onClick,
  onDropdownClick,
}: TProps) => {
  const hasDropdown = !!dropdownItems?.length;

  const overlay = () =>
    getDropdownMenu({
      dropdownItems,
      onDropdownClick,
      itemStyle,
      width,
      activeDropdownItem,
    });

  if (hasDropdown) {
    return (
      <OuterWrapper position={position} onClick={onClick}>
        <DropdownWrapper
          color={color}
          isActive={isActive}
          itemStyle={itemStyle}
        >
          <StyledDropdown dropdownRender={overlay} trigger={['click']}>
            <ArrowBlock color={color} isActive={isActive}>
              <MenuItem itemstyle={itemStyle} isActive={isActive}>
                <StyledIcon icon={icon} size={iconSize} whiteColor />
                <span data-testid={'header-dropdown-title-venue-name'}>
                  {truncateSimpleText(title || '', 44)}
                </span>
              </MenuItem>
            </ArrowBlock>
          </StyledDropdown>
        </DropdownWrapper>
      </OuterWrapper>
    );
  }

  return (
    <OuterWrapper position={position} onClick={onClick}>
      <StyledNavLink to={url}>
        <Wrapper color={color} isActive={isActive} itemStyle={itemStyle}>
          <SimpleMenuItem
            color={color}
            isActive={isActive}
            itemstyle={itemStyle}
          >
            <Icon icon={icon} size={iconSize} whiteColor />
            <span data-testid={`dropdown-title-${title}`}>{title}</span>
          </SimpleMenuItem>
        </Wrapper>
      </StyledNavLink>
    </OuterWrapper>
  );
};

import {useAppSelector} from 'store/hooks';

export const useOfferRequestDetails = (isSingleRequest?: boolean) => {
  const groupDetails = useAppSelector(({offers}) => offers.groupDetails);
  const singleOfferDetails = useAppSelector(({offers}) => offers.offerDetails);

  const offerDetails = isSingleRequest
    ? singleOfferDetails
    : groupDetails?.requests[0] || singleOfferDetails;

  return {offerDetails};
};

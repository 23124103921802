import React, {useMemo} from 'react';
import {withTypes} from 'react-final-form';

import NW2SubmitSection from 'view/components/NMMSubmitSection';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {NW2Gray900Color} from 'constants/styleVars';
import {getEnumByRoomType} from '../utils/getEnumByRoomType';
import {IAmenity} from 'types/dto/IAmenity';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ICommonAmenitiesFormValues} from './types';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';
import {Container, SectionCheckboxItem} from './CommonAmenities.styles';

interface IProps {
  roomType: ERoomType;
  roomAmenities: IAmenity[];
  activeAmenities: number[];
  isSending: boolean;
  handleEnd: () => void;
  updateVenue: (values: any) => void;
}

const CommonAmenitiesForm = ({
  roomType,
  roomAmenities,
  activeAmenities,
  isSending,
  handleEnd,
  updateVenue,
}: IProps) => {
  const {Form} = withTypes<ICommonAmenitiesFormValues>();

  const onSubmit = ({amenities}: any) => {
    updateVenue(amenities);
  };

  const initialValues = useMemo(
    () => ({
      amenities: activeAmenities || [],
    }),
    [activeAmenities],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({handleSubmit, dirty}) => (
        <form onSubmit={handleSubmit} noValidate>
          {roomAmenities.length > 0 && (
            <Container>
              {Object.entries(getEnumByRoomType(roomType)).map(
                ([key, label]) => (
                  <div key={key}>
                    <SectionSubTitle titleColor={NW2Gray900Color}>
                      {label}
                    </SectionSubTitle>

                    {roomAmenities
                      .filter((amenity) => amenity.type === key)
                      .map((amenity) => (
                        <SectionCheckboxItem key={amenity.id}>
                          <NW2FormItemCheckbox
                            name='amenities'
                            label={amenity.name}
                            value={amenity.id}
                            id={`${amenity.id}`}
                            disabled={isSending}
                            size={ECheckboxSizes.LARGE}
                            data-testid={`${amenity.id}--checkbox`}
                            noErrorMessage
                          />
                        </SectionCheckboxItem>
                      ))}
                  </div>
                ),
              )}
            </Container>
          )}

          <NW2SubmitSection
            isLoading={isSending}
            handleCancel={handleEnd}
            disabled={!dirty}
          />
        </form>
      )}
    </Form>
  );
};

export default CommonAmenitiesForm;

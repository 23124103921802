const range = (start: number, end: number) => {
  return new Array(end - start).fill('').map((d, i) => end - i);
};

const months: Record<string, string> = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sept: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};

const maskDatePickerValue = (value: string) => {
  const cardValue: string[] =
    value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/) || [];

  return !cardValue[2]
    ? cardValue[1]
    : `${cardValue[1]}.${cardValue[2]}${`${
        cardValue[3] ? `.${cardValue[3]}` : ''
      }`}`;
};

export {range, months, maskDatePickerValue};

import React from 'react';

import NavBackButton from 'view/common/Header/components/NavBackButton';
import CustomerNavMenuList from 'view/common/Header/components/CustomerNavMenuList';
import NavSelectLocationButton from 'view/common/Header/components/NavSelectLocationButton';
import HeaderLogo from 'view/common/Header/components/HeaderLogo';
import SupplierUserMenu from 'view/common/Header/components/SupplierUserMenu';
import HeaderLoginRegisterBlock from 'view/common/Header/components/HeaderLoginRegisterBlock';
import RestNavMenuList from './RestNavMenuList';
import QuickNavMenuList from './QuickNavMenuList';

import {ESpaceSelectedTab, ISpacesData} from 'types/venue';
import {SupplierHeaderItems, TMenuListProps} from '../types';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import GuestNavMenuList from './GuestNavMenuList';

const containerComponents = (
  currentPath: string,
  role: EUserRoleCognito,
  venueId: string,
  activeItem: SupplierHeaderItems | '',
  selectMenuItem: (value: SupplierHeaderItems) => void,
  activeDropdownItem: SupplierHeaderItems | ESpaceSelectedTab | '',
  selectActiveDropdownItem: (id: SupplierHeaderItems) => void,
  spaces: ISpacesData[],
  rest: Partial<Record<string, unknown>>,
): Record<string, React.JSX.Element> => {
  const commonProps: TMenuListProps = {
    role,
    activeItem,
    selectMenuItem,
    activeDropdownItem,
    selectActiveDropdownItem,
    currentPath,
    venueId,
  };

  return {
    isLogo: <HeaderLogo />,
    isBackButton: <NavBackButton {...rest} />,
    isSelectLocationButton: <NavSelectLocationButton {...commonProps} />,
    isCustomerNavList: (
      <CustomerNavMenuList role={role} currentPath={currentPath} />
    ),
    isQuickNavList: <QuickNavMenuList {...commonProps} />,
    isRestNavList: <RestNavMenuList spaces={spaces} {...commonProps} />,
    isGuestNavList: <GuestNavMenuList role={role} currentPath={currentPath} />,
    isSignOutButton: <SupplierUserMenu spaces={spaces} {...commonProps} />,
    isLoginRegisterBlock: (
      <HeaderLoginRegisterBlock spaces={spaces} {...commonProps} />
    ),
  };
};

type TProps = {
  currentPath: string;
  role: EUserRoleCognito;
  component: string;
  activeItem: SupplierHeaderItems | '';
  activeDropdownItem: SupplierHeaderItems | ESpaceSelectedTab | '';
  spaces: ISpacesData[];
  selectMenuItem: (value: SupplierHeaderItems) => void;
  selectActiveDropdownItem: (id: SupplierHeaderItems) => void;
  venueId: string;
};

function HeaderInnerContainer({
  currentPath,
  role,
  component,
  venueId,
  activeItem,
  activeDropdownItem,
  spaces,
  selectActiveDropdownItem,
  selectMenuItem,
  ...rest
}: TProps) {
  const ComponentToRender = containerComponents(
    currentPath,
    role,
    venueId,
    activeItem,
    selectMenuItem,
    activeDropdownItem,
    selectActiveDropdownItem,
    spaces,
    rest,
  )[component];
  return ComponentToRender || null;
}

export default HeaderInnerContainer;

import {useEffect} from 'react';

import {
  ID_TO_BEDROOM_EXTRAS_NAMES,
  ID_TO_BEDROOM_TYPE,
} from 'constants/bedrooms';
import {EBedroomExtras} from 'types/dto/IExtras.type';
import {THandleSetBedroomsCatering} from '../../SearchForm/components/GroupsRooms/types';
import {TSelectedOptions} from 'types/search';
import {TBedroomsCatering} from '../components/AddAccommodationRequest/types';

interface IProps {
  accommodationData: TSelectedOptions | null;
  bedroomsCatering: TBedroomsCatering;
  date: string;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
}

/** init day switchers for day if accommodation enabled*/
export const useInitBedroomsCatering = ({
  accommodationData,
  bedroomsCatering,
  date,
  handleSetBedroomsCatering,
}: IProps) => {
  useEffect(() => {
    if (!accommodationData) return;

    if (!bedroomsCatering[date]) {
      const {data, options, ids} = accommodationData;

      const setInitCatering = (id: number) =>
        handleSetBedroomsCatering({
          roomType: ID_TO_BEDROOM_TYPE[id as EBedroomExtras],
          name: ID_TO_BEDROOM_EXTRAS_NAMES[id as EBedroomExtras],
          isEnabled: ids.includes(id),
          qty: data[`${id}_`],
          date,
        });

      options.forEach(({id}) => setInitCatering(id));
    }
  }, [accommodationData, bedroomsCatering, date, handleSetBedroomsCatering]);
};

import React, {memo, useEffect, useState} from 'react';
import _get from 'lodash/get';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import _isEqual from 'lodash/isEqual';

import NW2Button from 'view/components/NW2Button';
import PointsOfInterestList from './components/PointsOfInterestList/PointsOfInterestList';
import NMMSection from 'view/components/NMMSection';

import {RootState} from 'store/types';
import {IPointOfInterest, IVenue} from 'types/venue';
import {ButtonContainer} from './PointsOfInterest.styles';
import {
  PATH_TO_REDUCER_VENUE_DATA,
  PATH_TO_REDUCER_VENUE_POI_DATA,
} from 'constants/venue';
import {
  clearVenuePointsOfInterest,
  setVenuePointsOfInterest,
} from 'store/venue/actions';
import useUpdateVenue from '../../../hooks/useUpdateVenue';
import {EPointsOfInterestMode, TFormObject} from './types';
import {PointsOfInterestForm} from './components/PointsOfInterestForm/PointsOfInterestForm';
import {EVenueProfileSections} from '../../types';

interface IProps {
  venue: IVenue;
  isExternalVenue: boolean;
}

const PointsOfInterestWrapper = ({venue, isExternalVenue}: IProps) => {
  const [mode, setMode] = useState<EPointsOfInterestMode>(
    EPointsOfInterestMode.VIEW,
  );

  const changeToEditMode = () => setMode(EPointsOfInterestMode.EDIT);

  const changeToViewMode = () => setMode(EPointsOfInterestMode.VIEW);

  const dispatch = useDispatch();
  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: changeToViewMode,
  });

  const poiData: IPointOfInterest[] | [] = useSelector((state: RootState) =>
    _get(
      state,
      `${PATH_TO_REDUCER_VENUE_DATA}.${PATH_TO_REDUCER_VENUE_POI_DATA}`,
    ),
  );

  const formPoiData: TFormObject = (poiData as IPointOfInterest[])?.reduce(
    (acc, object) => {
      return {
        ...acc,
        [object.type]: {
          ...object,
        },
      };
    },
    {} as TFormObject,
  );

  const [propsPoiData, setPropsPoiData] = useState<TFormObject>();
  const disableSave = _isEqual(propsPoiData, formPoiData);

  useEffect(() => {
    if (mode === EPointsOfInterestMode.EDIT) {
      setPropsPoiData(formPoiData);
    } else {
      setPropsPoiData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleCancelClick = () => {
    if (propsPoiData) {
      dispatch(clearVenuePointsOfInterest());

      Object.values(propsPoiData).map(({distance, name, type}) => {
        dispatch(
          setVenuePointsOfInterest({
            key: type,
            value: {
              distance,
              name,
              type,
            },
          }),
        );
      });
    } else {
      dispatch(clearVenuePointsOfInterest());
    }

    changeToViewMode();
  };

  const handleConfirmClick = (formValues: TFormObject) => {
    updateVenue({pointsOfInterest: Object.values(formValues)});
  };

  return (
    <NMMSection
      id={EVenueProfileSections.POINTS_OF_INTEREST}
      title='Points of interest'
      isEditOn={mode === EPointsOfInterestMode.EDIT}
      onEdit={changeToEditMode}
    >
      {mode === EPointsOfInterestMode.EDIT ? (
        <Form onSubmit={handleConfirmClick} initialValues={formPoiData}>
          {({handleSubmit, values, errors, touched}) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <PointsOfInterestForm
                  values={values}
                  touched={touched}
                  errors={errors as TFormObject | undefined}
                />

                <ButtonContainer>
                  <NW2Button
                    buttonType='secondary'
                    size='small'
                    onClick={handleCancelClick}
                    data-testid={`poi-cancel-button`}
                  >
                    Cancel
                  </NW2Button>
                  <NW2Button
                    disabled={disableSave}
                    loading={isSending}
                    buttonType='primary'
                    type='submit'
                    size='small'
                    data-testid={`poi-confirm-button`}
                  >
                    Save changes
                  </NW2Button>
                </ButtonContainer>
              </form>
            );
          }}
        </Form>
      ) : (
        <PointsOfInterestList poiFormData={formPoiData} />
      )}
    </NMMSection>
  );
};

export default memo(PointsOfInterestWrapper);

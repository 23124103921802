import React from 'react';
import styled from 'styled-components';

import DateUtils from 'utils/dateUtils';
import {EEventType} from 'types/venue';
import {
  fontSizeLg,
  fontSizeSm,
  fontWeightBold,
  fontWeightNormal,
  lgBp,
  lineHeightMd,
  lineHeightXL,
  offsetDef,
  offsetXSm,
} from 'constants/styleVars';

const Wrapper = styled.div<{noMargin?: boolean}>`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightNormal};
  margin-bottom: ${({noMargin}) => !noMargin && offsetDef};

  @media (min-width: ${lgBp}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${offsetXSm};
  }
`;

const Title = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightXL};
`;

interface IProps {
  checkIn: string;
  eventType?: EEventType;
  noMargin?: boolean;
}
function AccommodationTitle({
  checkIn,
  eventType = EEventType.DAY,
  noMargin,
}: IProps) {
  const formattedDate = DateUtils.getBedroomDateRange(checkIn);

  return (
    <Wrapper noMargin={noMargin}>
      <Title>
        {eventType === EEventType.PRE_ARRIVAL && 'Pre-arrival'}
        {eventType === EEventType.POST_EVENT && 'Post-event'}
        {eventType === EEventType.DAY && 'Accommodation'}
      </Title>
      <span>{formattedDate}</span>
    </Wrapper>
  );
}

export default AccommodationTitle;

import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import _isEqual from 'lodash/isEqual';

import NMMSubmitSection from 'view/components/NMMSubmitSection';

import {AddRoomSchema} from 'view/venue/NW2VenueProfile/components/AddSpace/components';

import {IRoomSchemaItem} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/types';
import {requiredRoomSchema} from 'utils/finalFormFieldRules';
import {ERoomSchemaNames} from 'types/venue';
import {getRoomSchemaForSubmit} from '../helpers';

interface IProps {
  fieldName: string;
  initialValues: {
    [key: string]: {
      [key in ERoomSchemaNames]?: IRoomSchemaItem;
    };
  };
  onEditorShowed: () => void;
  updateSpace: (values: any) => Promise<void>;
  isSending: boolean;
  columns?: number;
  excludedItems?: ERoomSchemaNames[];
}

export const RoomSetupForm = ({
  fieldName,
  initialValues,
  onEditorShowed,
  updateSpace,
  columns,
  excludedItems,
  isSending,
}: IProps) => {
  const handleConfirmClick = useCallback(
    (values: any) => {
      const formValuesToSave = getRoomSchemaForSubmit({
        capacities: values[fieldName],
      });

      const updatedSpace = {
        unitCapacities: formValuesToSave,
      };

      updateSpace(updatedSpace);
    },
    [fieldName, updateSpace],
  );

  return (
    <Form
      onSubmit={handleConfirmClick}
      validate={requiredRoomSchema(fieldName)}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, {changeValue}) => {
          changeValue(state, field, () => value);
        },
      }}
    >
      {({handleSubmit, form, values}) => {
        const disableSave = _isEqual(values, initialValues);

        const handleCancelClick = () => {
          form.reset();
          onEditorShowed();
        };

        return (
          <form onSubmit={handleSubmit} noValidate>
            <AddRoomSchema
              columns={columns}
              excludedItems={excludedItems}
              fieldName={fieldName}
            />
            <NMMSubmitSection
              isLoading={isSending}
              handleCancel={handleCancelClick}
              disabled={disableSave}
            />
          </form>
        );
      }}
    </Form>
  );
};

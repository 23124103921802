import styled, {css} from 'styled-components';

import {
  blackColor,
  fontSizeMd,
  fontSizeXSm,
  fontSizeXXSm,
  fontWeightBold,
  lightBlackColor,
  lineHeightLg,
  lineHeightSm,
  mdBp,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray600Color,
  NW2Gray900Color,
  NW2Primary,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXXLg,
  shortListHeight,
  whiteColor,
} from 'constants/styleVars';

export const Wrapper = styled.div<{
  isActive?: boolean;
  isDesktop: boolean;
  isMobile: boolean;
}>(
  ({isActive, isDesktop, isMobile}) =>
    css`
      display: flex;
      gap: ${isDesktop ? offsetXLg : offsetDef};
      align-items: center;
      justify-content: space-between;

      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      border-top: 1px solid ${NW2Gray200Color};
      background-color: ${whiteColor};
      padding: 0 ${isMobile ? offsetDef : offsetXXLg};
      height: ${isActive ? shortListHeight : 0};
      overflow: hidden;
      transition: height 0.15s;
      z-index: 1;
    `,
);

export const ListBox = styled.div<{isDesktop?: boolean}>`
  display: flex;
  gap: ${({isDesktop}) => (isDesktop ? offsetXLg : offsetDef)};
  align-items: center;
`;

export const ListTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListLabel = styled.div<{isMobile: boolean}>`
  font-size: ${({isMobile}) => (isMobile ? '12px' : '14px')};
  color: ${NW2Gray600Color};

  @media (max-width: 374px) {
    display: none;
  }
`;

export const ListText = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  color: ${NW2Gray900Color};

  @media (max-width: 380px) {
    letter-spacing: -0.35px;
  }

  @media (min-width: ${mdBp}px) {
    min-width: 220px;
  }
`;

export const ListItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: ${offsetDef};
`;

export const ListActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXLg};
`;

export const CartWrapper = styled.div`
  position: relative;
  border-right: 1px solid ${NW2Gray200Color};
  padding-right: ${offsetDef};
`;

export const CartCount = styled.div`
  position: absolute;
  top: -4px;
  left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${NW2Primary};
  font-size: ${fontSizeXXSm};
  font-weight: ${fontWeightBold};
`;

export const BackNavBox = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid ${NW2Gray200Color};
  width: 115px;

  span {
    font-size: ${fontSizeXSm};
    line-height: ${lineHeightSm};
    color: ${NW2Gray600Color};
    padding: 0 ${offsetSm};
  }
`;

export const ButtonRemove = styled.div<{isLarge?: boolean}>`
  position: absolute;
  width: ${({isLarge}) => (isLarge ? '26px' : '16px')};
  height: ${({isLarge}) => (isLarge ? '26px' : '16px')};
  display: flex;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: -8px;
  background-color: ${NW2Gray100Color};
  color: ${lightBlackColor};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.15s, color 0.15s;

  &:hover {
    cursor: pointer;
    background-color: ${blackColor};
    color: ${whiteColor};
  }

  > * {
    color: inherit;
    pointer-events: none;
  }
`;

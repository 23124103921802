import styled, {css} from 'styled-components';

import {
  fontSizeXSm,
  fontWeightExtraBold,
  lgBp,
  lightTextColor,
  mdBp,
  offsetDef,
  offsetXLg,
  pagePadding,
  whiteColor,
} from 'constants/styleVars';
import {EVenueImagesLayout} from './useCoverImages';

const VenueImagesContainer = styled.div`
  min-height: 224px;
  display: grid;
  grid-gap: ${pagePadding};
  grid-template-columns: 1fr;

  margin: ${offsetXLg} 0 ${offsetDef};

  @media (min-width: ${mdBp}px) {
    margin: ${offsetXLg} 0;
  }
`;

const ImageAmountBlock = styled.div`
  position: absolute;
  bottom: ${offsetDef};
  right: ${offsetDef};
  padding: 6px 12px;

  background: ${lightTextColor};
  color: ${whiteColor};
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeXSm};
  border-radius: 2px;

  @media (min-width: ${mdBp}px) {
    display: none;
  }
`;

const ImageBlock = styled.div`
  overflow: hidden;

  @media (min-width: ${lgBp}px) {
    display: flex;
  }
`;

const CoverImage = styled.div<{layout: EVenueImagesLayout}>(({layout}) => {
  switch (layout) {
    case EVenueImagesLayout.FIVE:
      return css`
        height: 100%;

        @media (min-width: ${lgBp}px) {
          width: calc(50% - calc(${pagePadding} / 2));
        }
      `;

    case EVenueImagesLayout.THREE:
    case EVenueImagesLayout.TWO:
    default:
      return css`
        height: 100%;

        @media (min-width: ${lgBp}px) {
          width: 50%;
        }
      `;
  }
});

const RestImage = styled.div<{layout: EVenueImagesLayout}>(({layout}) => {
  switch (layout) {
    case EVenueImagesLayout.FIVE:
      return css`
        display: none;

        @media (min-width: ${lgBp}px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-auto-flow: row;
          grid-gap: ${pagePadding};
          margin-left: ${pagePadding};
          width: calc(50% - calc(${pagePadding} / 2));
        }
      `;

    case EVenueImagesLayout.THREE:
      return css`
        display: none;

        @media (min-width: ${lgBp}px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: ${pagePadding};
          margin-left: ${pagePadding};
          width: 50%;
        }
      `;

    case EVenueImagesLayout.TWO:
    default:
      return css`
        display: none;

        @media (min-width: ${lgBp}px) {
          display: grid;
          width: 50%;
          margin-left: ${pagePadding};
        }
      `;
  }
});

export {
  VenueImagesContainer,
  ImageAmountBlock,
  ImageBlock,
  CoverImage,
  RestImage,
};

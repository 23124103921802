import styled, {css} from 'styled-components';

import NW2AccordionItem from 'view/components/NW2Accordion/components/NW2AccordionItem';

import {
  fontSizeLg,
  fontWeightExtraBold,
  lineHeightXL,
  offsetDef,
  offsetXXLg,
} from 'constants/styleVars';

export const Title = styled.div<{isMobile: boolean}>`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightExtraBold};
  line-height: ${lineHeightXL};
  letter-spacing: 0.02em;
  margin-bottom: ${offsetXXLg};

  ${({isMobile}) =>
    !isMobile &&
    css`
      text-transform: uppercase;
    `};
`;

export const Wrapper = styled.div<{isDesktop?: boolean; isTablet?: boolean}>`
  padding-left: ${({isDesktop, isTablet}) =>
    (isTablet && '68px') || (isDesktop && offsetDef)};
`;

export const StyledNW2AccordionItem = styled(NW2AccordionItem)`
  padding: 0 8px 0 0;
`;

import {TLayout} from 'view/common/Header/types';

const getAdminLayout = (): TLayout => {
  return {
    type: 'AdminLayout',
    components: [
      {component: 'isLogo'},
      {component: 'isSelectLocationButton'},
      {component: 'isNavList'},
      {component: 'isSignOutButton'},
    ],
  };
};

export {getAdminLayout};

import React from 'react';
import styled from 'styled-components';

import TextValue from 'view/components/TextValue';
import Icon from 'view/components/Icon';

import {IBooking} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {UNIT_NAME_TYPE_MAP} from 'constants/venue';
import {offsetXSm} from 'constants/styleVars';
import {pluralize} from 'utils/helpers';

const WorkspaceTypeContainer = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-gap: ${offsetXSm};
`;

const StyledIcon = styled(Icon)`
  margin-top: 3px;
`;

export function WorkspaceTypeColumn(
  workspaceTitle: string,
  row: IBooking,
  index: number,
) {
  const isMeetingRoom = row.unitType === ERoomType.MEETING_ROOM;

  return (
    <WorkspaceTypeContainer key={index}>
      <StyledIcon
        size={16}
        icon={isMeetingRoom ? 'WHITEBOARD' : 'OFFICE_DESK'}
      />
      <div>
        {workspaceTitle}
        <TextValue secondary size='small'>
          {isMeetingRoom
            ? pluralize('participant', row.participants)
            : `${UNIT_NAME_TYPE_MAP[row.unitType]}`}
        </TextValue>
      </div>
    </WorkspaceTypeContainer>
  );
}

import {DateTime} from 'luxon';
import {ISearchOrder} from 'types/bookingOrders';
import {ISearchRequestOfferDto} from 'types/dto/ISearchRequestOfferDto.type';
import {getISOString} from 'utils/dateUtils';

const isTObj = <T extends object, U extends object>(
  data: T | U,
  key: keyof T,
): data is T => {
  return key in data;
};

export const getRequestDataFromType = <
  T extends ISearchOrder,
  U extends ISearchRequestOfferDto,
>(
  data: T | U,
  key: keyof T,
) => {
  const commonValues = {
    isMultiDay: data.isMultiDay,
    status: data.status,
    hasAccommodation: data.hasAccommodation,
  };

  return isTObj(data, key)
    ? {
        ...commonValues,
        totalPrice: data.totalPrice,
        company: data.company,
        checkInDate: data.checkInDate,
        checkOutDate: data.checkOutDate,
        currency: data.currency,
        orderNumber: data.orderNumber,
        orderId: data.orderId,
        createdAt: data.createdAt,
        expirationDate: null,
      }
    : {
        ...commonValues,
        totalPrice: data?.totalRequestPrice?.value || data.price?.value || 0,
        company: data.company || data.customer.companyName,
        checkInDate: getISOString(DateTime.fromISO(data.checkIn)), //Here and others, is a transformation from Z (UTC timezone) to local timezone
        checkOutDate: getISOString(DateTime.fromISO(data.checkOut)),
        createdAt: getISOString(DateTime.fromISO(data.createdAt)),
        currency: data.price?.currency,
        orderNumber: data.number,
        orderId: data.id,
        expirationDate: data.expirationDate,
      };
};

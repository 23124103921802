import React, {FC} from 'react';
import {Radio, RadioChangeEvent} from 'antd';
import styled from 'styled-components';

import {
  offsetXSm,
  googleMapSearchPredictionsMinWidth,
} from 'constants/styleVars';
import {EUnitBookingStatus} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';

const Container = styled.div`
  min-width: ${googleMapSearchPredictionsMinWidth};
`;

const Title = styled.div`
  margin: ${offsetXSm} 0 0;
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin: ${offsetXSm} 0;
`;

type Props = {
  status: EUnitBookingStatus;
  setStatus: (value: React.SetStateAction<EUnitBookingStatus>) => void;
};

const PopoverTitle: FC<Props> = ({status, setStatus}) => {
  const onChange = (event: RadioChangeEvent) => setStatus(event.target.value);

  const options = [
    {label: 'Open', value: EUnitBookingStatus.OPEN},
    {label: 'Closed', value: EUnitBookingStatus.CLOSED},
  ];

  return (
    <Container>
      <Title>Update availability</Title>
      <StyledRadioGroup
        options={options}
        onChange={onChange}
        value={status}
        optionType='button'
      />
    </Container>
  );
};

export default PopoverTitle;

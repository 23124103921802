import React from 'react';

import {IRadioInputProps} from '../types';
import {Input, Label, Mark, RadioWrapper} from '../NW2FormItemRadio.styles';

const InputRadio = ({
  name,
  label,
  checked,
  inputProps,
  size,
  disabled = false,
  onChange,
}: IRadioInputProps) => {
  const handleRadioClick = () => (event: any) => {
    if (typeof onChange === 'function') {
      onChange(event.target.name, event.target.value);
    }
  };

  return (
    <RadioWrapper>
      <Label size={size} disabled={disabled}>
        <Input
          name={name}
          type='radio'
          size={size}
          onClick={handleRadioClick()}
          {...inputProps}
        />
        <Mark size={size} checked={checked} disabled={disabled} />
        {label}
      </Label>
    </RadioWrapper>
  );
};

export default InputRadio;

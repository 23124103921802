import styled, {css} from 'styled-components';

import {
  blackColor,
  datepickerZIndex,
  NW2Gray100Color,
  NW2Gray200Color,
  NW2Gray300Color,
  NW2Primary,
  whiteColor,
  offsetXXXSm,
  offsetSm,
  lineHeightLg,
  offsetXSm,
  lineHeightSm,
  fontSizeSm,
  mdBp,
  offsetXXSm,
  fontSizeXSm,
  offsetDef,
  NW2Gray600Color,
} from 'constants/styleVars';
import iconChevron from 'img/icons/chevron.svg';

import 'react-datepicker/dist/react-datepicker.min.css';

export const dayItemSize = '34px';
const dayItemSizeMobile = '13vw';
const dayItemSizeTablet = '11vw';
const dayItemFontSizeMobile = '3.5vw';
const highlightDayColor = '#4a90e2';
const highlightDayHoverColor = '#e5fbff';
const bpExtraSmall = '360px';

export const StyledWrapper = styled.div<{isMobile?: boolean}>(
  ({isMobile}) => css`
    position: relative;
    width: 100%;
    background-color: ${whiteColor};

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      bottom: 0;
      background-color: ${NW2Gray100Color};
      width: ${isMobile ? '30.5vw' : '83px'};
      pointer-events: none;
    }

    // datepicker calendar overrides
    .react-datepicker {
      font-family: 'Inter', sans-serif;
      font-size: ${fontSizeSm};
      border: 0;
      background: none;
      width: 100%;
      z-index: ${datepickerZIndex};

      &__header {
        padding-top: ${isMobile ? '' : offsetSm};
        border-color: ${NW2Gray200Color};
        background-color: ${whiteColor};
      }

      &__current-month {
        font-size: ${isMobile ? dayItemFontSizeMobile : fontSizeXSm};
        line-height: ${isMobile ? ' 1.5' : lineHeightSm};
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: ${offsetXSm};
        user-select: none;
      }

      &__navigation {
        top: ${isMobile ? '' : offsetXSm};
        line-height: ${lineHeightLg};

        &--previous {
          left: ${isMobile ? offsetDef : offsetXXSm};
        }

        &--next {
          right: ${isMobile ? offsetDef : offsetXXSm};
        }

        &-icon {
          &--previous,
          &--next {
            &:before {
              top: 50%;
              width: ${isMobile ? '2vw' : '8px'};
              height: ${isMobile ? '3.5vw' : '14px'};
              background: url(${iconChevron}) no-repeat;
              border: 0;
            }
          }

          &--previous {
            &:before {
              right: 50%;
              transform: rotate(180deg) translate(-50%, 50%);
            }
          }

          &--next {
            &:before {
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      &__month {
        &-container {
          width: 100%;
        }

        .react-datepicker__week {
          &:last-child {
            .react-datepicker__day {
              margin-bottom: 0;
            }
          }
        }
      }

      &__day,
      &__day-name {
        font-size: ${isMobile ? dayItemFontSizeMobile : fontSizeXSm};
        user-select: none;
      }

      &__day {
        line-height: ${isMobile
          ? `calc(${dayItemSizeMobile} - 2 * ${offsetXXXSm})`
          : dayItemSize};
        width: ${isMobile
          ? `calc(${dayItemSizeMobile} - 2 * ${offsetXXXSm})`
          : dayItemSize};
        margin: ${offsetXXXSm};
        cursor: pointer;

        &--outside-month {
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        }

        &--today {
          box-shadow: inset 0 0 0 1px ${NW2Gray200Color};
          border-radius: 50%;
        }
      }

      @media (min-width: 520px) and (max-width: ${mdBp - 1}px) {
        &__day,
        &__day-name {
          width: ${dayItemSizeTablet};
        }

        &__day {
          line-height: ${dayItemSizeTablet};
        }
      }

      &__day-name {
        font-weight: bold;
        line-height: ${isMobile ? 1 : lineHeightSm};
        margin: ${isMobile ? offsetSm : offsetXXXSm};
        color: ${NW2Gray600Color};
        width: ${isMobile
          ? `calc(${dayItemSizeMobile} - 2 * ${offsetSm})`
          : dayItemSize};

        @media (max-width: ${bpExtraSmall}) {
          margin: ${isMobile ? offsetXXSm : offsetXXXSm};
        }
      }

      &__day,
      &__month-text,
      &__quarter-text,
      &__year-text {
        border-radius: 50%;
        transition: background-color 0.15s, color 0.15s, box-shadow 0.15s;

        &:hover {
          background-color: ${highlightDayHoverColor};
          box-shadow: inset 0 0 0 1px ${highlightDayColor};
          color: ${highlightDayColor};
          border-radius: 50%;
        }

        &--selected,
        &--disabled {
          cursor: default;

          &:hover {
            box-shadow: none;
            background: none;
          }
        }

        &--selected {
          &:hover {
            color: ${blackColor};
          }
        }

        &--disabled {
          &:hover {
            color: ${NW2Gray300Color};
          }
        }

        &--keyboard-selected {
          background: none;
          color: ${blackColor};
        }

        &--selected,
        &--in-selecting-range,
        &--in-range {
          font-weight: bold;
          background-color: ${NW2Primary};
          color: ${blackColor};
          border-radius: 50%;
          box-shadow: none;

          &:hover {
            background-color: ${NW2Primary};
          }
        }

        &--disabled {
          background: none;
          color: ${NW2Gray300Color};
        }
      }

      // &__day, // uncomment for highlighting every day that is the same as the current one
      &__month-text,
      &__quarter-text,
      &__year-text {
        &--keyboard-selected {
          border-radius: 50%;
          background: none;
          color: ${blackColor};
          font-weight: bold;
          box-shadow: inset 0 0 0 1px ${NW2Gray200Color};
        }
      }
    }
  `,
);

import styled from 'styled-components';

import {
  blackColor,
  navigationVenueDetailsZIndex,
  NW2Gray600Color,
  NW2Gray900Color,
  offsetXXLg,
} from 'constants/styleVars';
import {offerNavigationHeight} from './helpers';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';

const AnchorWrapper = styled.div`
  display: inline-flex;
  align-self: flex-end;
  border-bottom: 2px solid ${NW2Gray600Color};

  > * + * {
    margin-left: ${offsetXXLg};
  }
`;

const NavigationBlock = styled.div<{
  isMobile?: boolean;
  isBackToOffers?: boolean;
}>`
  display: ${({isBackToOffers}) => (isBackToOffers ? 'none' : 'flex')};
  position: ${({isMobile}) => (isMobile ? 'fixed' : 'sticky')};
  background: ${({isMobile}) => (isMobile ? blackColor : NW2Gray900Color)};
  ${({isMobile}) =>
    isMobile ? 'bottom: 0; transform: translateY(0);' : 'top: 0;'};

  left: 0;
  width: 100%;
  min-height: ${offerNavigationHeight}px;
  z-index: ${navigationVenueDetailsZIndex};
  transition: 0.25s;
`;

const NavigationInnerBlock = styled(NW2Container)`
  display: flex;
  justify-content: space-between;
`;

export {AnchorWrapper, NavigationBlock, NavigationInnerBlock};

import styled from 'styled-components';

import {offsetXXLg, offsetXXXXLg, lgBp, offsetXXSm} from 'constants/styleVars';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${offsetXXLg};

  @media (min-width: ${lgBp}px) {
    gap: ${offsetXXXXLg};
  }
`;

export const SectionCheckboxItem = styled.div`
  padding: ${offsetXXSm} 0;
`;

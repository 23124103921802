import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IVenue} from '../../types/venue';

interface IVenueWorkDesksState {
  workDesksDetails: IVenue | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: IVenueWorkDesksState = {
  workDesksDetails: null,
  isLoading: false,
  error: null,
};

export const workDesksSlice = createSlice({
  name: 'workDesksSlice',
  initialState,
  reducers: {
    setWorkDesksDetails(state, action: PayloadAction<IVenue>) {
      state.workDesksDetails = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setWorkDesksLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setWorkDesksError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    cleanWorkDesksDetails(state) {
      state.workDesksDetails = null;
    },
  },
});
export const {
  setWorkDesksDetails,
  setWorkDesksLoading,
  setWorkDesksError,
  cleanWorkDesksDetails,
} = workDesksSlice.actions;

export default workDesksSlice.reducer;

import styled, {css} from 'styled-components';

import {Link} from 'view/components/Typography';

import {
  whiteColor,
  fontWeightBold,
  fontSizeLg,
  fontSizeXSm,
  offsetDef,
  lgBp,
  fontSizeSm,
  fontSizeMd,
  NW2Primary,
  offset64,
  NW2Gray800Color,
  blackColor,
  offset36,
  offsetXXSm,
  offsetSm,
  offsetXSm,
  offsetLg,
  NW2Gray400Color,
  offsetXXLg,
  NW2Gray500Color,
  mdBp,
  NW2Gray200Color,
  NW2Gray100Color,
  NW2Gray300Color,
  borderRadiusDef,
  offsetXXXLg,
} from 'constants/styleVars';
import Icon from 'view/components/Icon';

const breakpoint = `${lgBp}px`;

export const Item = styled.div<{isMultiDay: boolean}>`
  display: grid;
  grid-template-columns: auto;
  color: ${whiteColor};
  position: relative;

  &:not(&:last-of-type) {
    ${({isMultiDay}) =>
      !isMultiDay &&
      css`
        padding-bottom: ${offsetXXXLg};
        border-bottom: 1px solid ${NW2Gray500Color};
      `}
    margin-bottom: ${offsetXXLg};
  }

  @media (min-width: ${mdBp}px) {
    &:not(&:last-of-type) {
      padding-bottom: 0;
      border: none;
    }
  }

  @media (min-width: ${breakpoint}) {
    gap: 0 ${offsetDef};
    grid-template-columns: minmax(110px, 280px) minmax(280px, 1fr);
  }
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  margin-bottom: ${offsetDef};

  @media (min-width: ${breakpoint}) {
    margin-bottom: 0;
    min-height: 170px;
  }
`;
export const TextWrapper = styled.div`
  @media (min-width: ${breakpoint}) {
    display: flex;
    flex-direction: column;
  }
`;
export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${offsetDef};
`;

export const ItemTitle = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
`;

export const ItemSubTitle = styled.div`
  display: grid;
  grid-template-columns: auto 48px;
  grid-gap: ${offsetDef};
  font-size: ${fontSizeSm};

  * + & {
    margin-top: ${offsetDef};
  }
`;

export const PricesBlock = styled.div`
  font-size: ${fontSizeXSm};
`;

export const BestPrice = styled.span`
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightBold};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
export const SelectButton = styled.span<{
  isActive?: boolean;
  isDisabled?: boolean;
}>(
  ({isActive, isDisabled}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${isActive || isDisabled ? `104px` : `${offset64}`};
    height: ${offset36};
    border-radius: 100px;
    font-size: ${offsetSm};
    line-height: ${offsetLg};
    color: ${isDisabled ? `${NW2Gray300Color}` : `${blackColor}`};
    background-color: ${isDisabled
      ? `${NW2Gray100Color}`
      : isActive
      ? `${NW2Primary}`
      : `${whiteColor}`};
    font-weight: ${fontWeightBold};
    text-transform: none;

    &:hover {
      background-color: ${!isDisabled ? `${NW2Primary}` : ''};
    }
  `,
);

export const RadioButtonSelect = styled.div<{
  isDisabled: boolean;
  isActive: boolean;
}>`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: ${({isDisabled}) => (isDisabled ? 'default' : 'pointer')};
    border: 1px solid
      ${({isActive}) => (isActive ? 'transparent' : `${NW2Gray400Color}`)};
    border-radius: 100px;
    transition: color 0.15s, border-bottom 0.15s;

    & svg {
      margin-right: ${offsetXSm};
      width: ${offsetDef};
      height: ${offsetDef};
    }
  }

  input:checked + label {
    background-color: ${NW2Primary};
    transition: color 0.15s, border-bottom 0.15s;
    color: ${NW2Gray800Color};
  }
`;
export const StyledLink = styled(Link)`
  color: ${whiteColor};
  cursor: pointer;
`;

export const CSSTruncatedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledIcon = styled(Icon)`
  width: 48px;
  height: 48px;
  padding: 6px;
  color: ${NW2Gray200Color};
  border: 1px solid;
  border-radius: ${borderRadiusDef};
`;

export const StyledDiv = styled.div`
  font-style: italic;
  margin-top: ${offsetDef};
`;

export const StyledSpan = styled.span`
  vertical-align: text-top;
  margin-left: ${offsetXXSm};
`;

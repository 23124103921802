import React, {useState} from 'react';
import {useAppSelector} from 'store/hooks';

import NW2Button from 'view/components/NW2Button/NW2Button';
import NW2AddEditBillingAddressModalDrawerForm from '../../NW2CustomerProfile/components/NW2AddEditBillingAddressModalDrawerForm';

import {EBillingAddressFormType} from 'view/components/BillingAddress/types';

import {Title, Text} from '../NW2BillingAddressPopup.styles';

function NW2BillingAddressOptions() {
  const [isAddAddressFormVisible, setIsAddAddressFormVisible] = useState(false);

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const showAddAddressForm = () => setIsAddAddressFormVisible(true);
  const closeAddAddressForm = () => setIsAddAddressFormVisible(false);

  return (
    <div>
      <Title>More billing options</Title>
      <Text>Would you like to add a new billing address?</Text>
      <NW2Button buttonType='secondary' onClick={showAddAddressForm}>
        Add a new address
      </NW2Button>

      <NW2AddEditBillingAddressModalDrawerForm
        type={EBillingAddressFormType.ADD}
        isMobile={isMobile}
        isModalShowed={isAddAddressFormVisible}
        onModalClose={closeAddAddressForm}
      />
    </div>
  );
}

export default NW2BillingAddressOptions;

import {Accept} from 'react-dropzone';
import {EUploadedFileType} from './types';

export const getAcceptTypes = (uploadedFileType: EUploadedFileType): Accept => {
  switch (uploadedFileType) {
    case EUploadedFileType.PDF_FILE:
      return {
        'application/pdf': ['.pdf'],
      };
    default:
      return {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      };
  }
};

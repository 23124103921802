import React from 'react';
import {Switch, TimePicker} from 'antd';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import moment from 'moment';

import TextRow from 'view/components/TextRow';
import venueTexts from 'texts/venueTexts';

import {SubPageTitle} from 'view/components/Typography';
import {TextValue} from 'view/components/TextValue';
import {SecondaryButton, DefaultButton} from 'view/components/Button';

import {
  offsetSm,
  offsetDef,
  lightTextColor,
  offsetXSm,
  borderRadiusDef,
} from 'constants/styleVars';
import {Days, EDays, OPERATIONAL_HOURS_TIME_FORMAT} from 'constants/days';
import {setVenueFields} from 'store/venue/actions';
import {IDay, IDayOfWeek, TDayTime} from 'types/dto/IDay.type';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {
  isDayActive,
  getFromTimeDisabledHours,
  getTillTimeDisabledHours,
  getTimePickerValue,
  isFullDayAccess,
} from 'utils/operationalHoursUtils';
import {capitalizeText} from 'utils/stringUtils';
import {isNull} from 'utils/helpers';
import {
  TIME_END_HOUR,
  TIME_END_MINUTES,
  TIME_START_HOUR,
  TIME_START_MINUTES,
} from 'constants/defaultOperationalTimes';

interface Props {
  workDays: IDay[];
  accommodationType: EAccommodationType;
}

const Hint = styled.div`
  display: flex;
  margin-bottom: ${offsetDef};
`;

const buttonMargin = offsetSm;

const StyledDefaultButton = styled(DefaultButton)`
  border-radius: ${borderRadiusDef};
  margin-right: ${buttonMargin};
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  border-radius: ${borderRadiusDef};
  margin-right: ${buttonMargin};
`;

const StyledSwitch = styled(Switch)`
  margin-right: ${offsetXSm};
`;

const StyledTimePicker = styled(TimePicker)`
  border-radius: ${borderRadiusDef};
  .ant-picker-input > input {
    color: ${lightTextColor};
  }
`;

const RightTimePicker = styled(StyledTimePicker)`
  margin-right: ${offsetDef};
`;

const LeftTimePicker = styled(StyledTimePicker)`
  margin-right: ${buttonMargin};
`;

const getTimeValue = (time: TDayTime, defaultTime: string) =>
  // some fields of time can be null for HMD registration flow
  isNull(time) ? defaultTime : time;

const OperationalHoursForm = ({workDays, accommodationType}: Props) => {
  const dispatch = useDispatch();

  const venueTypeAsCapitalizedText =
    venueTexts[accommodationType].venueTypeCapitalized;

  const toggleActive = (day: IDayOfWeek) => () => {
    const active = isDayActive(workDays, day);
    const updatedOperationalHours = workDays.map((d: IDay) => {
      if (d.dayOfWeek === day) {
        return {
          ...d,
          active: !active,
          open: !active,
          timeStartHour: getTimeValue(d.timeStartHour, TIME_START_HOUR),
          timeEndHour: getTimeValue(d.timeEndHour, TIME_END_HOUR),
          timeStartMinutes: getTimeValue(
            d.timeStartMinutes,
            TIME_START_MINUTES,
          ),
          timeEndMinutes: getTimeValue(d.timeEndMinutes, TIME_END_MINUTES),
        };
      }
      return d;
    });

    dispatch(
      setVenueFields({
        path: `operationalTimes`,
        value: updatedOperationalHours,
      }),
    );
  };

  const _setTime = (
    date: Date,
    dateString: string,
    when: string,
    day: IDayOfWeek,
  ) => {
    const updatedDays = workDays.map((value) => {
      if (value.dayOfWeek === Days[day]) {
        const [hour, minutes] = dateString.split(':');
        if (when === 'timeStart') {
          value.timeStartHour = hour;
          value.timeStartMinutes = minutes;
        } else if (when === 'timeEnd') {
          value.timeEndHour = hour;
          value.timeEndMinutes = minutes;
        }
      }
      return value;
    });
    dispatch(
      setVenueFields({
        path: 'operationalTimes',
        value: updatedDays,
      }),
    );
  };

  //TODO: We can only remove Moment when replace Antd TimePicker with some other one
  const onSelect =
    (when: string, day: IDayOfWeek) => (value: moment.Moment) => {
      const time = value.format
        ? value.format(OPERATIONAL_HOURS_TIME_FORMAT)
        : '';
      if (time) {
        _setTime(new Date(), time, when, day);
      }
    };

  const toggleFullDayAccess = (day: IDayOfWeek, isActive: boolean) => () => {
    const active = isDayActive(workDays, day);
    const updatedOperationalHoursToFullDay = workDays.map((d: IDay) => {
      if (d.dayOfWeek === day) {
        return {
          ...d,
          active: isActive,
          open: !active,
          timeStartHour: isActive ? '0' : '9',
          timeStartMinutes: isActive ? '0' : '0',
          timeEndHour: isActive ? '23' : '20',
          timeEndMinutes: isActive ? '59' : '0',
        };
      }
      return d;
    });

    dispatch(
      setVenueFields({
        path: `operationalTimes`,
        value: updatedOperationalHoursToFullDay,
      }),
    );
  };

  const getContent = (day: IDayOfWeek) => {
    const active = isDayActive(workDays, day);
    const fullDayAccess = isFullDayAccess(workDays, day);

    return fullDayAccess ? (
      <>
        <StyledSwitch
          checked={fullDayAccess}
          onChange={toggleFullDayAccess(day, false)}
        />
        <TextValue lightText inline>
          24 hour access
        </TextValue>
      </>
    ) : active ? (
      <>
        <StyledDefaultButton
          onClick={toggleActive(day)}
          id={`open${venueTypeAsCapitalizedText}${day}Button`}
        >
          Closed
        </StyledDefaultButton>
        <StyledSecondaryButton
          onClick={toggleActive(day)}
          id={`open${venueTypeAsCapitalizedText}${day}Button`}
        >
          Open
        </StyledSecondaryButton>
        <LeftTimePicker
          placeholder='From'
          format={OPERATIONAL_HOURS_TIME_FORMAT}
          allowClear={false}
          showNow={false}
          minuteStep={60}
          onChange={(date: any, dateString: string) =>
            _setTime(date, dateString, 'timeStart', day)
          }
          onSelect={onSelect('timeStart', day)}
          value={moment(
            getTimePickerValue(day, 'timeStart', workDays),
            OPERATIONAL_HOURS_TIME_FORMAT,
          )}
          disabledHours={getFromTimeDisabledHours(day, workDays)}
          id={`timeStart${day}TimePicker`}
        />
        <RightTimePicker
          placeholder='To'
          format={OPERATIONAL_HOURS_TIME_FORMAT}
          allowClear={false}
          showNow={false}
          minuteStep={60}
          onChange={(date: any, dateString: string) =>
            _setTime(date, dateString, 'timeEnd', day)
          }
          onSelect={onSelect('timeEnd', day)}
          value={moment(
            getTimePickerValue(day, 'timeEnd', workDays),
            OPERATIONAL_HOURS_TIME_FORMAT,
          )}
          disabledHours={getTillTimeDisabledHours(day, workDays)}
          id={`timeEnd${day}TimePicker`}
        />
        <StyledSwitch
          checked={fullDayAccess}
          onChange={toggleFullDayAccess(day, true)}
        />
        <TextValue lightText inline>
          24 hour access
        </TextValue>
      </>
    ) : (
      <>
        <StyledSecondaryButton
          onClick={toggleActive(day)}
          id={`open${venueTypeAsCapitalizedText}${day}Button`}
        >
          Closed
        </StyledSecondaryButton>
        <StyledDefaultButton
          onClick={toggleActive(day)}
          id={`open${venueTypeAsCapitalizedText}${day}Button`}
        >
          Open
        </StyledDefaultButton>
      </>
    );
  };

  return (
    <>
      <SubPageTitle>{`${venueTypeAsCapitalizedText}’s operational hours`}</SubPageTitle>

      <Hint>
        <TextValue error>*</TextValue>
        <TextValue>
          {`Add operational hours for your ${venueTexts[accommodationType].venueType}`}
        </TextValue>
      </Hint>

      <TextRow label='Days' value='Timings' secondaryLabel secondaryValue />

      {Object.values(EDays).map((d) => (
        <TextRow
          key={d}
          label={capitalizeText(d) + ':'}
          value={getContent(d)}
        />
      ))}
    </>
  );
};

export default OperationalHoursForm;

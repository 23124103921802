import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import ShareVenueContent from './components/ShareVenueContent/ShareVenueContent';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {closeSharePopup} from 'store/app/appSlice';

import {
  IShareComponentData,
  IShareComponents,
  TSharePopupId,
  TSharePopupProps,
} from 'types/app';

export enum EShareComponents {
  SHARE_VENUE = 'SHARE_VENUE',
  EMPTY = 'EMPTY',
}

export const SHARE_COMPONENTS: IShareComponents = {
  [EShareComponents.SHARE_VENUE]: {
    title: 'share with friends',
    content: ShareVenueContent,
  },
  [EShareComponents.EMPTY]: {
    title: null,
    content: null,
  },
};

const NW2Share = () => {
  const dispatch = useDispatch();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const sharePopupProps: TSharePopupProps = useSelector((state) =>
    _get(state, 'app.sharePopupProps'),
  );

  const onClose = () => {
    dispatch(closeSharePopup());
  };

  const id: TSharePopupId = sharePopupProps.id;
  const isVisible = id !== EShareComponents.EMPTY;
  const componentData: IShareComponentData = SHARE_COMPONENTS[id];
  const Content = componentData?.content;

  useEffect(() => {
    if (isVisible && isMobile) {
      document.body.style.width = '100%';
    }
  }, [isVisible, isMobile]);

  return (
    <NW2ModalDrawer
      isMobile={isMobile}
      isShowed={isVisible}
      header={componentData?.title || null}
      modalWidth={400}
      drawerHeight='auto'
      body={
        componentData?.content ? (
          <Content {...sharePopupProps.props} closePopup={onClose} />
        ) : null
      }
      onClose={onClose}
    />
  );
};

export default NW2Share;

import {TRoomSchemaItems} from 'view/venue/NW2VenueProfile/components/AddSpace/components/AddRoomSchema/types';
import {ERoomSchemaNames} from 'types/venue';

export const getRoomSchemaForSubmit = ({
  capacities,
}: {
  capacities: TRoomSchemaItems;
}) => {
  const objectKeys = Object.keys(capacities) as ERoomSchemaNames[];

  return objectKeys.reduce<{capacity: number; setupStyle: string}[]>(
    (acc, key) => {
      const valuesObject = capacities[key];

      if (valuesObject.checkbox) {
        acc.push({capacity: +valuesObject.capacity, setupStyle: key});
      }
      return acc;
    },
    [],
  );
};

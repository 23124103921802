import styled from 'styled-components';

import {errorColor, fontSizeXSm, fontWeightBold} from 'constants/styleVars';

export const FormGroup = styled.div<{columnNumber: number; gap: number}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => gap}px;

  > div {
    margin: 0;
  }
`;

export const StyledDiv = styled.div`
  color: ${errorColor};
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
`;

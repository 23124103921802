import React, {ChangeEvent, useCallback, FocusEvent} from 'react';
import {useForm} from 'react-final-form';

import {deskCountFieldRules} from 'utils/finalFormFieldRules';
import {inputTextToNumber} from 'utils/helpers';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {EAddDeskFormFields} from '../types';

export const AddDeskCount = () => {
  const {mutators} = useForm();

  const onChangeCount = useCallback(
    (type: EAddDeskFormFields) => (e: ChangeEvent<HTMLInputElement>) => {
      const onChange = (value: string) => {
        mutators.setValue(type, value);
      };

      inputTextToNumber(e.target.value, onChange, true);
    },
    [mutators],
  );

  const onBlurCount = useCallback(
    (type: EAddDeskFormFields) => (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value) {
        mutators.setValue(type, Number(value));
      }
    },
    [mutators],
  );

  return (
    <NW2FormItemInput
      name={EAddDeskFormFields.CAPACITY}
      type='text'
      label='Number of desks'
      placeholder='Number of desks'
      onChange={onChangeCount(EAddDeskFormFields.CAPACITY)}
      onBlur={onBlurCount(EAddDeskFormFields.CAPACITY)}
      rules={deskCountFieldRules('number of desks')}
    />
  );
};

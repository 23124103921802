import React from 'react';

import DateUtils from 'utils/dateUtils';

import {removeExtension} from 'utils/helpers';

import {
  ProgressBar,
  Section,
  ProgressSectionTopLeft,
  Left,
  StyledIcon,
  AdditionalInfo,
} from '../Uploader.styles';
import {TUploadFile} from '../types';
import {DownloadLink} from 'view/components/Typography/Typography';

interface IProps {
  uploadedFile?: TUploadFile | null;
  progress: number;
  handleReset?: () => void;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function FileUploaderProgressBar({
  uploadedFile,
  progress,
  handleReset,
  openModal,
}: IProps) {
  if (!uploadedFile) return null;

  const {name, uploadDate, blob} = uploadedFile;
  const inProgress = progress !== 100;

  return (
    <Section>
      <Left>
        <ProgressSectionTopLeft>
          <DownloadLink href={blob} target='_blank' rel='noopener noreferrer'>
            {removeExtension(name || '')}
          </DownloadLink>
        </ProgressSectionTopLeft>

        {inProgress ? (
          <ProgressBar progress={progress} marginTop={true} />
        ) : (
          <AdditionalInfo>
            Uploaded on {DateUtils.getDateWithoutHours(uploadDate)}
          </AdditionalInfo>
        )}
      </Left>

      <div>
        {handleReset && (
          <StyledIcon
            size={20}
            icon={inProgress ? 'CLOSE' : 'NW2_CANCEL_BIN'}
            onClick={() => openModal(true)}
          />
        )}
      </div>
    </Section>
  );
}

import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useFormState} from 'react-final-form';
import {useAppSelector} from 'store/hooks';

import NW2SummaryBlock from 'view/components/NW2SummaryBlock';

import {LinkTerms} from 'view/components/NW2SummaryBlock/components/LinkTerms';
import {useBookingSummaryContent} from 'view/venue/components/NW2BookingSummary/hooks';
import {
  setPreviewTotalAmount,
  setPreviewTotalDrawerVisible,
} from 'store/bookingsCustomer/bookingsCustomerSlice';
import {EAccommodationType, IExtraResponse} from 'types/dto/IPublicVenue';
import {ButtonConfirm} from 'view/venue/components/NW2BookingSummary/components/ButtonConfirm';
import {getPreviewTotalPrice} from './helpers';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {useCustomerOrderUnits} from 'view/venue/hooks/useCustomerOrderUnits';

interface IProps {
  currency: string;
  isTotalSticky: boolean;
  venueAddress: string;
  venueName: string;
  venueCoverImage: {
    url: string;
    name: string;
  };
  isRfp: boolean;
  isUSA: boolean;
}

export function NW2BookingTotal({
  currency,
  isTotalSticky,
  venueCoverImage,
  venueName,
  venueAddress,
  isRfp,
  isUSA,
}: IProps) {
  const dispatch = useDispatch();

  const {isLoggedInUser} = useLoggedInUser();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const {previewUnits, isTotalDrawerVisible} = useCustomerOrderUnits();

  const isRtb = previewUnits.some((day) =>
    day.units.some((unit) => unit.isRtb),
  );
  const participants = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity || 1,
  );
  const isPaymentInProcess = useAppSelector(({payment}) => payment.isLoading);
  const isBookingInProcess = useAppSelector(({customer}) => customer.isLoading);
  const isAccountReferencesLoading = useAppSelector(
    ({payment}) => payment.isAccountReferencesLoading,
  );

  const {venueDetails} = useVenueDetailsData();
  const accommodationType = venueDetails.accommodationType;

  const formState = useFormState();
  const {submitting} = formState;

  const getPreviewExtras = useCallback(() => {
    const extras: IExtraResponse[] = [];
    previewUnits.forEach((venueUnit) => {
      venueUnit.units.forEach((unit) => {
        unit.chosenExtras.forEach((extra) => {
          extras.push(extra as IExtraResponse);
        });
      });
    });
    return extras;
  }, [previewUnits]);

  const getPreviewTotalPriceMemo = useCallback(
    () => getPreviewTotalPrice(previewUnits, participants),
    [previewUnits, participants],
  );
  // hide price when CORPORATE_OFFICE
  const isOffice: boolean =
    accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const extras = getPreviewExtras();
  const extrasCount = extras.length;

  const totalAmount: number = getPreviewTotalPriceMemo();

  useEffect(() => {
    dispatch(setPreviewTotalAmount({value: totalAmount, currency}));
  }, [dispatch, totalAmount, currency]);

  const onToggleDrawer = useCallback(() => {
    dispatch(setPreviewTotalDrawerVisible(!isTotalDrawerVisible));
  }, [dispatch, isTotalDrawerVisible]);

  const termsText = isRfp
    ? `Cancellation policy becomes binding once the request is confirmed by the venue. 
        By proceeding you accept the HRS `
    : 'By proceeding you accept HRS ';

  const {headerSection, totalPriceSection, unitsSection} =
    useBookingSummaryContent({
      currency,
      isRfp,
      unitBookings: previewUnits,
      isTotalPriceVisible: !isOffice,
      totalPrice: totalAmount,
      venueAddress,
      venueName,
      venueCoverImage,
      submitting,
      isUSA,
    });

  const buttonConfirm = (
    <ButtonConfirm
      submitting={submitting}
      btnText={
        isRfp || isRtb ? (isMobile ? 'Request' : 'Send request') : 'Book now'
      }
      isLoading={
        isPaymentInProcess || isBookingInProcess || isAccountReferencesLoading
      }
      isDrawerShowed={isTotalDrawerVisible}
      extrasCount={extrasCount}
      isLoggedInUser={isLoggedInUser}
    />
  );

  return (
    <NW2SummaryBlock
      isSticky={isTotalSticky}
      isMobile={isMobile}
      isDrawerShowed={isTotalDrawerVisible}
      setIsDrawerShowed={onToggleDrawer}
      headerSection={headerSection}
      totalPriceSection={
        <>
          {totalPriceSection}
          {!isOffice && isMobile && <LinkTerms text={termsText} />}
        </>
      }
      unitsSection={unitsSection}
      linkTermsText={termsText}
      buttonConfirm={buttonConfirm}
      footerMobileContent={buttonConfirm}
    />
  );
}

import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import {ValidationErrors} from 'final-form';

import {isEmailValid} from 'infra/common/venueValidation';
import {IBookingPreviewFormData, TAttendeeFormField} from 'store/venues/types';
import {ATTENDEE_FIELD_KEY, ATTENDEES_FIELD_KEY} from 'constants/venue';
import {pointsOfInterestMap, ERoomSchemaNames} from 'types/venue';
import {DateTime} from 'luxon';
import {TCheckListItem} from '../view/auth/NW2PasswordFieldWithCheckList/NW2PasswordFieldWithCheckList';

export const required = (value: any) => !value || value?.length === 0;
const minChar = (value: string, min: number) => value?.length < min;
const maxChar = (value: string, max: number) => value?.length >= max;
const noLowCase = (value: string) => !/[a-z]/.test(value);
const noUpperCase = (value: string) => !/[A-Z]/.test(value);
const consistOnlySpaces = (value: string) =>
  !!value && !value.replace(/\s/g, '').length;
export const numbersOneToThousand = (value: string) =>
  !/^([1-9][0-9]{0,2}|1000)$/.test(value);

// ToDo refactor
export const firstNameFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter your first name`);
  if (!required(value) && minChar(value, 2))
    errors.push(`Minimum number of symbols 2`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  return errors.join('--');
};

export const lastNameFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter your last name`);
  if (!required(value) && minChar(value, 2))
    errors.push(`Minimum number of symbols 2`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  return errors.join('--');
};

export const companyNameFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter your company name`);
  if (!required(value) && minChar(value, 1))
    errors.push(`Minimum number of symbols 1`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  if (maxChar(value, 250)) errors.push('Maximum number of symbols 250');

  return errors.join('--');
};

export const spaceNameFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter your space name`);
  if (!required(value) && minChar(value, 2))
    errors.push(`Space name needs to have at least 2 characters`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  return errors.join('--');
};

export const legalPhoneFieldRules = (value: string) => {
  const errors: string[] = [];
  if (value) {
    const isPhoneNumberValid = isPossiblePhoneNumber(value);
    const parsedPhoneNumber = parsePhoneNumber(value);
    if (!isPhoneNumberValid || !parsedPhoneNumber?.country)
      errors.push(`The phone number you entered isn't valid`);
  }
  if (required(value)) errors.push(`Please enter your phone`);
  return errors.join('--');
};

export const emailFieldRules = (value: string, _: any, meta: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter your ${meta.name}`);
  if (!isEmailValid(value) && !required(value))
    errors.push(`Please enter correct ${meta.name}`);
  return errors.join('--');
};

export const ceoNameFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter CEO name`);
  if (!required(value) && minChar(value, 2))
    errors.push(`Minimum number of symbols 2`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  return errors.join('--');
};

export const attendeesFieldRules = (
  value: string,
  formValues: IBookingPreviewFormData,
) => {
  const errors: string[] = [];
  if (required(value)) {
    errors.push('Please enter attendee email');
  }

  if (!isEmailValid(value) && !required(value)) {
    errors.push('Incorrect email domain. Please check.');
  }

  const allAttendees = formValues[ATTENDEES_FIELD_KEY] || [];
  const sameValues = allAttendees
    .map((att: TAttendeeFormField) => att[ATTENDEE_FIELD_KEY])
    .filter((email: string) => email === value);
  const isEmailNotUniq = !!value && sameValues.length > 1;

  if (isEmailNotUniq) {
    errors.push('Email is not unique.');
  }
  return errors.join('--');
};

type TRequiredFieldRulesProps = {
  maxLength: number;
  minLength: number;
};

export const requiredFieldRules =
  (fieldName: string, props?: Partial<TRequiredFieldRulesProps>) =>
  (value: any) => {
    const errors: string[] = [];
    if (required(value)) errors.push(`Please enter ${fieldName}`);
    if (props?.maxLength && maxChar(value, props.maxLength)) {
      errors.push(`Maximum number of symbols ${props.maxLength - 1}`);
    }
    if (props?.minLength && minChar(value, props.minLength)) {
      errors.push(`Minimum number of symbols ${props.minLength}`);
    }
    return errors.join('--');
  };

export const requiredSelectFieldRules = (fieldName: string) => (value: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please select ${fieldName}`);
  return errors.join('--');
};

// remove after replace with new password field in all places
export const passwordFieldRules = (value: any, _: any, meta: any) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter your ${meta.name}`);
  if (minChar(value, 8)) errors.push('At least 8 characters');
  if (noLowCase(value) || noUpperCase(value)) {
    errors.push('At least one uppercase and lowercase letter');
  }
  return errors.join('--');
};

export const passwordFieldWithCheckListRules =
  (checkList: TCheckListItem[], label: string) => (value: any) => {
    const errors: string[] = [];
    if (required(value))
      errors.push(`Please enter your ${label.toLowerCase()}`);

    const isCheckListNotDone = checkList.some(({checkFun}) => !checkFun(value));
    if (isCheckListNotDone)
      errors.push(`Please finish the list of requirements`);

    return errors.join('--');
  };

export const termsConditionsFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value))
    errors.push(
      'Please agree to the terms & conditions and our privacy policy',
    );
  return errors.join('--');
};

export const notRequiredStringFieldRules = (value: any) => {
  const errors: string[] = [];
  if (!required(value) && minChar(value, 2))
    errors.push(`Minimum number of symbols 2`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  return errors.join('--');
};

export const notRequiredEmailFieldRules = (
  value: string,
  _: any,
  meta: any,
) => {
  const errors: string[] = [];
  if (!isEmailValid(value) && !required(value))
    errors.push(`Please enter correct ${meta.name}`);
  return errors.join('--');
};

export const pointsOfInterestFieldRules = (point: string) => (value: any) => {
  if (point === pointsOfInterestMap.CITY) return '';

  const errors: string[] = [];
  if (required(value)) errors.push(`Please provide ${point.toLowerCase()}`);
  if (!required(value) && minChar(value, 2))
    errors.push(`Minimum number of symbols 2`);
  if (consistOnlySpaces(value)) errors.push(`Can't consist only from spaces`);
  return errors.join('--');
};

const rfpOrderMessageRules = (value: string, errorsArr: string[]) => {
  const errors = [...errorsArr];

  if (minChar(value, 2)) {
    errors.push('Additional message needs to have at least 2 characters.');
  }

  if (maxChar(value, 150)) {
    errors.push('Additional message must contain no more than 150 characters.');
  }

  return errors.join('--');
};

export const declineRfpOrderMessage = (value: string) => {
  if (!value) return '';

  const errors: string[] = [];

  return rfpOrderMessageRules(value, errors);
};

export const declineRfpOrderMessageRequired = (value: string) => {
  const errors: string[] = [];

  if (required(value)) {
    errors.push('Additional message is required');
  }

  return rfpOrderMessageRules(value, errors);
};

// nmm space creation
export const nameFieldRules = (value: any) => {
  const errors: string[] = [];
  if (required(value) || minChar(value, 2))
    errors.push('Name needs to have at least 2 characters');
  return errors.join('--');
};

export const descriptionFieldRules = (value: any) => {
  const errors: string[] = [];

  if (required(value)) {
    errors.push('Description is required');
  } else if (minChar(value, 50)) {
    errors.push('Description needs to have at least 50 characters.');
  }

  return errors.join('--');
};

export const imagesFieldRules = (value: any) => {
  const errors: string[] = [];

  if (required(value)) {
    errors.push('Please upload at least 1 image.');
  }

  return errors.join('--');
};

export const roomSchemaCheckboxWithInputRules =
  (category: ERoomSchemaNames, fieldName: string) =>
  (value: number | string, allValues: any) => {
    const errors: string[] = [];

    const toCheck: boolean = allValues[fieldName][category]?.checkbox;

    if (toCheck) {
      if (required(value)) {
        errors.push('Enter number of participants');
      } else if (numbersOneToThousand(String(value))) {
        errors.push('Enter from 1 to 1000');
      }
    }

    return errors.join('--');
  };

export const roomSchemaCheckboxWithInputRulesAndMinValue =
  (category: ERoomSchemaNames, fieldName: string, minValue: number) =>
  (value: number, allValues: any) => {
    const errors: string[] = [];

    const toCheck: boolean = allValues[fieldName][category]?.checkbox;

    if (toCheck) {
      if (required(value)) {
        errors.push('Enter number of participants');
      } else if (value < minValue) {
        errors.push(
          'The minimum number of capacity cannot be less than requested by the booker',
        );
      } else if (numbersOneToThousand(String(value))) {
        errors.push('Enter from 1 to 1000');
      }
    }

    return errors.join('--');
  };

export const requiredRoomSchema =
  (fieldName: string, page = 5) =>
  (values: any) => {
    const ROOM_SCHEMA_PAGE = 5;
    if (page !== ROOM_SCHEMA_PAGE) return;

    let errors: ValidationErrors = {};

    const someChecked = Object.values(values[fieldName]).some(
      (record: any) => record.checkbox,
    );

    if (!someChecked) {
      errors.error = 'required';
    } else {
      errors = {};
    }
    return errors;
  };

export const requiredPriceRules =
  (fieldName: string, message = 'Please provide price') =>
  (value: any) => {
    const minPrice = 0.01;

    const errors: string[] = [];
    if (required(value)) {
      errors.push(message);
    } else if (Number(value) < minPrice) {
      errors.push('Price should not be 0');
    }
    return errors.join('--');
  };

export const deskCountFieldRules =
  (fieldName: string) => (value: string | number) => {
    const errors: string[] = [];
    const minNumber = 1;

    if (required(value) || value < minNumber)
      errors.push(`Please enter ${fieldName}`);

    return errors.join('--');
  };

// nmm space creation end

export const datePickerRules =
  (dateFormat: string, isRequired?: boolean) => (value: string) => {
    const errors: string[] = [];
    if (isRequired && required(value)) errors.push(`Please enter a valid date`);
    if (value && !DateTime.fromFormat(value, dateFormat).isValid) {
      errors.push(
        `Please enter a valid date in ${dateFormat.toUpperCase()} format`,
      );
    }
    return errors.join('--');
  };

export const optionDateRules = (value: string) => {
  const errors: string[] = [];
  if (required(value)) errors.push(`Please enter option date`);
  return errors.join('--');
};

export const notRequiredEmailFieldRule = (value: string) => {
  const errors: string[] = [];
  if (!isEmailValid(value) && !required(value))
    errors.push(`Please enter correct email`);
  return errors.join('--');
};

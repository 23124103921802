import {useEffect, useState} from 'react';

import {pushToGoogleAnalytics} from 'utils/helpers';

interface IProps {
  currentStep: number;
}
export const useCreationVenueMetrics = ({currentStep}: IProps) => {
  const [maxSteps, setMaxSteps] = useState(0);

  useEffect(() => {
    if (currentStep > maxSteps) {
      setMaxSteps(currentStep);
      pushToGoogleAnalytics(`VenueCreation-Step${currentStep}`);
    }
  }, [currentStep, maxSteps]);

  useEffect(() => {
    pushToGoogleAnalytics(`VenueCreation-InitialStep`);
  }, []);
};

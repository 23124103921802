import React from 'react';

import {TermsPageUSA} from './TermsPageUSA';
import {TermsPageEU} from './TermsPageEU';

import {DeclineLink} from './TermsPage.styles';
import {PageWrapper} from 'view/components/NW2Forms/FormWizard/FormWizard.styles';
import {StepTitle} from '../../OfferCreate.styles';

interface IProps {
  isUSA: boolean;
  hasBookerConditions: boolean;
  hasBookerTerms: boolean;
  handleStart: () => void;
  onShowDeclineModal: () => void;
}
export function TermsPage({
  isUSA,
  hasBookerConditions,
  hasBookerTerms,
  handleStart,
  onShowDeclineModal,
}: IProps) {
  return (
    <PageWrapper>
      <StepTitle>Verify the Terms and Conditions</StepTitle>

      {isUSA ? (
        <TermsPageUSA
          handleStart={handleStart}
          hasBookerConditions={hasBookerConditions}
          hasBookerTerms={hasBookerTerms}
        />
      ) : (
        <TermsPageEU
          handleStart={handleStart}
          hasBookerConditions={hasBookerConditions}
          hasBookerTerms={hasBookerTerms}
        />
      )}

      <DeclineLink onClick={onShowDeclineModal}>Decline request</DeclineLink>
    </PageWrapper>
  );
}

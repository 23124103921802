import {useMemo} from 'react';

import {
  EBookingDetailsSections,
  EBookingDetailsType,
  IUnitBookingDayData,
} from 'types/bookingOrders';
import {IOrderDay} from 'types/dto/IBooking.types';

interface IProps {
  bookingDetailsType: EBookingDetailsType;
  units: Record<string, IUnitBookingDayData>;
  preArrivalData?: IOrderDay[];
  postEventData?: IOrderDay[];
}

export function useBookingSections({
  bookingDetailsType,
  units,
  preArrivalData,
  postEventData,
}: IProps) {
  return useMemo(() => {
    const days = Object.keys(units).map((item, index) => `Day ${index + 1}`);

    const isPreArrival = preArrivalData?.length !== 0;
    const isPostEvent = postEventData?.length !== 0;

    switch (bookingDetailsType) {
      case EBookingDetailsType.SINGLE: {
        return [
          EBookingDetailsSections.STATUS,
          EBookingDetailsSections.BOOKING_DETAILS,
          EBookingDetailsSections.CONFIRMATION_DETAILS,
          EBookingDetailsSections.PAYMENT_INFORMATION,
          EBookingDetailsSections.BILLING_ADDRESS,
          EBookingDetailsSections.TERMS_AND_CONDITIONS,
        ];
      }
      case EBookingDetailsType.SINGLE_REQUEST: {
        return [
          EBookingDetailsSections.REQUEST_DETAILS,
          EBookingDetailsSections.PAYMENT_INFORMATION,
          EBookingDetailsSections.BILLING_ADDRESS,
          EBookingDetailsSections.TERMS_AND_CONDITIONS,
        ];
      }
      case EBookingDetailsType.MULTI: {
        return [
          EBookingDetailsSections.STATUS,
          isPreArrival && EBookingDetailsSections.PRE_ARRIVAL,
          ...days,
          isPostEvent && EBookingDetailsSections.POST_EVENT,
          EBookingDetailsSections.CONFIRMATION_DETAILS,
          EBookingDetailsSections.PAYMENT_INFORMATION,
          EBookingDetailsSections.BILLING_ADDRESS,
          EBookingDetailsSections.TERMS_AND_CONDITIONS,
        ];
      }
      case EBookingDetailsType.MULTI_REQUEST: {
        return [
          isPreArrival && EBookingDetailsSections.PRE_ARRIVAL,
          ...days,
          isPostEvent && EBookingDetailsSections.POST_EVENT,
          EBookingDetailsSections.PAYMENT_INFORMATION,
          EBookingDetailsSections.BILLING_ADDRESS,
          EBookingDetailsSections.TERMS_AND_CONDITIONS,
        ];
      }
      case EBookingDetailsType.SINGLE_REQUEST_DECLINED:
      case EBookingDetailsType.SINGLE_REQUEST_CANCELLED_BOOKER:
      case EBookingDetailsType.SINGLE_REQUEST_EXPIRED: {
        return [
          EBookingDetailsSections.STATUS,
          EBookingDetailsSections.REQUEST_DETAILS,
          EBookingDetailsSections.PAYMENT_INFORMATION,
          EBookingDetailsSections.BILLING_ADDRESS,
          EBookingDetailsSections.TERMS_AND_CONDITIONS,
        ];
      }
      case EBookingDetailsType.MULTI_REQUEST_DECLINED:
      case EBookingDetailsType.MULTI_REQUEST_CANCELLED_BOOKER:
      case EBookingDetailsType.MULTI_REQUEST_EXPIRED: {
        return [
          EBookingDetailsSections.STATUS,
          isPreArrival && EBookingDetailsSections.PRE_ARRIVAL,
          ...days,
          isPostEvent && EBookingDetailsSections.POST_EVENT,
          EBookingDetailsSections.PAYMENT_INFORMATION,
          EBookingDetailsSections.BILLING_ADDRESS,
          EBookingDetailsSections.TERMS_AND_CONDITIONS,
        ];
        break;
      }

      default:
        return Object.values(EBookingDetailsSections);
    }
  }, [
    bookingDetailsType,
    postEventData?.length,
    preArrivalData?.length,
    units,
  ]);
}

import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

import {useAppDispatch} from 'store/hooks';
import NW2Button from 'view/components/NW2Button';
import NMMSection from 'view/components/NMMSection';
import useVenue from '../../../hooks/useVenue';

import {getLegalPhoneNumber, setLegalPhoneNumber} from '../../helpers';
import {NW2SupplierLegalDetailsForm} from 'view/components/NW2Forms';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {updateCorporateVenueById} from 'store/venue/actions';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {EMPTY_OBJECT} from 'constants/app';
import {IVenueLegalContact} from 'types/venue';
import {ILegalDetailsBlocks} from 'view/components/NW2Forms/NW2SupplierLegalDetailsForm/types';
import {
  Container,
  Block,
  BlockTitle,
  BlockText,
  FormActions,
} from './LegalDetails.styles';

// This page is used for corporate venues
const LegalDetails = () => {
  const dispatch = useAppDispatch();
  const [venue] = useVenue();

  const venueId =
    useSelector((state) => _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.id`)) ||
    '';

  // Data
  const legalContact: IVenueLegalContact =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.legalContact`),
    ) || EMPTY_OBJECT;

  const {
    ceoName,
    email,
    companyName,
    taxNumber,
    legalPhone,
    postalCode,
    city,
    country,
    streetName,
    buildingNumber,
  } = legalContact;
  const address = getAddressStringFromLocation(legalContact);
  const streetHouseNumber = `${streetName} ${buildingNumber}`;
  const isPhoneExist = !!legalPhone?.phoneCountryCode && !!legalPhone?.phone;
  const legalPhoneNumber = getLegalPhoneNumber(legalContact.legalPhone);

  // Details edit
  const [isChangeDetailsShowed, setChangeDetailsShowed] = useState(false);

  const onChangeDetailsShow = () => {
    setChangeDetailsShowed(true);
  };

  const onChangeDetailsHide = () => {
    setChangeDetailsShowed(false);
  };

  // Form
  const [isFormDirty, setFormDirty] = useState(false);
  const [isFormSending, setFormSending] = useState(false);
  const formInitialValues = useMemo(() => {
    return {
      ceoName,
      email,
      companyName,
      taxNumber,
      legalPhone: legalPhoneNumber,
      postalCode,
      city,
      country,
      streetHouseNumber,
    };
  }, [
    ceoName,
    email,
    companyName,
    taxNumber,
    legalPhoneNumber,
    postalCode,
    city,
    country,
    streetHouseNumber,
  ]);

  const onChangeDetailsSubmit = async (values: any) => {
    try {
      setFormSending(true);

      const {
        ceoName,
        city,
        companyName,
        country,
        email,
        postalCode,
        streetHouseNumber,
        taxNumber,
      } = values;

      const streetHouseNumberArray = streetHouseNumber.split(/(\d+)/g);
      const buildingNumber = streetHouseNumberArray[1];
      const streetName = streetHouseNumber.replace(buildingNumber, '').trim();

      const updatedVenue = {
        venue: {
          ...venue,
          legalContact: {
            ...venue.legalContact,
            ceoName,
            email,
            companyName,
            taxNumber,
            postalCode,
            city,
            country,
            buildingNumber,
            streetName,
            legalPhone: setLegalPhoneNumber(values.legalPhone),
          },
        },
      };

      // put updated data
      await dispatch(
        updateCorporateVenueById({
          ...updatedVenue,
          accountId: venueId,
        } as any),
      );

      setFormSending(false);
      setChangeDetailsShowed(false);
    } catch (error) {}
  };

  const processFormFields = _debounce(({dirtyFields}) => {
    setFormDirty(!!Object.keys(dirtyFields).length);
  }, 150);

  // legal details blocks
  const blocks: ILegalDetailsBlocks[] = useMemo(
    () => [
      {
        title: 'CEO',
        content: ceoName,
      },
      {
        title: 'Phone number',
        content: isPhoneExist ? legalPhoneNumber : '-',
      },
      {
        title: 'Email address',
        content: email || '-',
      },
      {
        title: 'Managing company',
        content: companyName || '',
      },
      {
        title: 'Managing company address',
        content: address,
      },
      {
        title: 'Tax number',
        content: taxNumber,
      },
    ],
    [
      address,
      ceoName,
      companyName,
      email,
      isPhoneExist,
      legalPhoneNumber,
      taxNumber,
    ],
  );

  return (
    <Container>
      <NMMSection
        id='legal details'
        title='Legal details'
        isEditOn={isChangeDetailsShowed}
        onEdit={onChangeDetailsShow}
      >
        {isChangeDetailsShowed ? (
          <Form
            onSubmit={onChangeDetailsSubmit}
            initialValues={formInitialValues}
            render={({handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <NW2SupplierLegalDetailsForm adaptiveColumnNumber={2} />

                <FormActions>
                  <NW2Button
                    buttonType='secondary'
                    onClick={onChangeDetailsHide}
                    size='responsiveAdaptive'
                    data-testid={'cancel'}
                  >
                    Cancel
                  </NW2Button>
                  <NW2Button
                    type='submit'
                    buttonType='primary'
                    loading={isFormSending}
                    disabled={!isFormDirty}
                    size='responsiveAdaptive'
                    data-testid={'save-changes'}
                  >
                    Save changes
                  </NW2Button>
                </FormActions>

                <FormSpy
                  subscription={{dirtyFields: true}}
                  onChange={processFormFields}
                />
              </form>
            )}
          />
        ) : (
          blocks.map(({title, content}) => (
            <Block key={title} data-testid={title}>
              <BlockTitle>{title}</BlockTitle>
              <BlockText>{content}</BlockText>
            </Block>
          ))
        )}
      </NMMSection>
    </Container>
  );
};

export default LegalDetails;

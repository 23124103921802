import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  ReactElement,
} from 'react';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button/NW2Button';
import NW2Pagination from 'view/components/NW2Pagination/NW2Pagination';
import NW2VenuesListCard from 'view/venue/NW2VenuesList/components/NW2VenuesListCard';
import useSearchData from 'view/venue/hooks/useSearchData';

import {setVenueDetails} from 'store/venueDetails/venueDetailsSlice';

import {
  Wrapper,
  StyledSection,
  PaginationContainer,
} from './AlternativesContentBlock.style';
import {IMergedVenue} from 'types/search';

const INIT_PAGE = 1;
const VENUES_PAGE_SIZE = 3;
const TABLET_VENUES_PAGE_SIZE = 2;
const INIT_ITEMS_TO_SHOW = 3;
const iconProps = {size: 44, icon: 'RIGHT_ARROW_CIRCLED'};

type TProps = {
  alternativeList: IMergedVenue[];
};

export const AlternativesContentBlock = ({
  alternativeList,
}: TProps): ReactElement | null => {
  const [showAllItems, setShowAllItems] = useState<boolean>(false);

  const {searchData, querySearchData} = useSearchData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {isDesktop, isTablet, isMobile} = useAppSelector(
    ({app}) => app.deviceType,
  );

  const [currentPage, setCurrentPage] = useState(INIT_PAGE);
  const [itemsToShow, setItemsToShow] = useState(INIT_ITEMS_TO_SHOW);

  const currentVenuesData = useMemo(() => {
    if (isMobile) {
      return alternativeList.slice(0, itemsToShow);
    }
    const firstPageIndex =
      (currentPage - 1) *
      (isTablet ? TABLET_VENUES_PAGE_SIZE : VENUES_PAGE_SIZE);
    const lastPageIndex =
      firstPageIndex + (isTablet ? TABLET_VENUES_PAGE_SIZE : VENUES_PAGE_SIZE);
    return alternativeList.slice(firstPageIndex, lastPageIndex);
  }, [isMobile, currentPage, isTablet, alternativeList, itemsToShow]);

  const venuesTotalCount = alternativeList.length;
  const isShowAllItems = itemsToShow < venuesTotalCount;

  const showMoreHandler = useCallback(() => {
    const isOutOfCount = itemsToShow + INIT_ITEMS_TO_SHOW > venuesTotalCount;
    const itemsToShowValue = isOutOfCount
      ? venuesTotalCount
      : itemsToShow + INIT_ITEMS_TO_SHOW;
    setItemsToShow(itemsToShowValue);
  }, [itemsToShow, venuesTotalCount]);

  useEffect(() => {
    if (isMobile) {
      setCurrentPage(INIT_PAGE);
      setItemsToShow(INIT_ITEMS_TO_SHOW);
    } else if (isDesktop) {
      setCurrentPage(INIT_PAGE);
    } else {
      setShowAllItems(true);
    }
  }, [isDesktop, isMobile]);

  useEffect(() => {
    setShowAllItems(isShowAllItems);
  }, [isShowAllItems]);

  const showLessVenuesThanByDefault =
    isTablet &&
    currentVenuesData.length <
      (isTablet ? TABLET_VENUES_PAGE_SIZE : VENUES_PAGE_SIZE);

  if (!alternativeList.length) return null;

  const onPageChange = (page: number) => setCurrentPage(page);

  const viewDetails = (venueId: number) => {
    navigate({
      pathname: `/venue/${venueId}/details`,
      search: queryString.stringify(querySearchData),
    });
    dispatch(setVenueDetails(null));
  };

  return (
    <>
      <Wrapper>
        <StyledSection
          showLessVenuesThanByDefault={showLessVenuesThanByDefault}
          isTablet={isTablet}
        >
          {currentVenuesData.map((venue) => (
            <NW2VenuesListCard
              key={venue.id || venue.accommodationId}
              venue={venue}
              roomType={searchData.roomType}
              viewDetails={viewDetails}
              buttonProps={{inverted: true, ...(!isMobile && {size: 'small'})}}
              isAlternativesSection
            />
          ))}
        </StyledSection>
      </Wrapper>

      {/* Desktop and Tablet */}
      {!isMobile && showAllItems && (
        <PaginationContainer>
          <NW2Pagination
            currentPage={currentPage}
            totalCount={venuesTotalCount}
            pageSize={isTablet ? TABLET_VENUES_PAGE_SIZE : VENUES_PAGE_SIZE}
            onPageChange={onPageChange}
            iconProps={iconProps}
            hidePageNumbers
          />
        </PaginationContainer>
      )}

      {/* Mobile */}
      {isMobile && showAllItems && (
        <NW2Button
          buttonType='secondary'
          icon={<Icon transparent icon='DOUBLE_ARROW_DOWN' />}
          size='large'
          onClick={showMoreHandler}
          fullWidth
        >
          show more
        </NW2Button>
      )}
    </>
  );
};

import React from 'react';

import Icon from 'view/components/Icon';

import {EMPTY_OBJECT} from 'constants/app';
import {usePagination, DOTS} from 'hooks/useNW2Pagination';
import {TIconPropsType} from '../Icon/types';

import {
  StyledUl,
  ArrowButton,
  PageButton,
  StyledLi,
} from './NW2Pagination.styles';

type TProps = {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  onPageChange: (page: number) => void;
  hidePageNumbers?: boolean;
  iconProps?: Partial<TIconPropsType>;
};

function NW2Pagination({
  currentPage,
  totalCount,
  pageSize,
  siblingCount = 1,
  onPageChange,
  hidePageNumbers,
  iconProps = EMPTY_OBJECT,
}: TProps) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || pageSize === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const goToPreviousPage = () => onPageChange(currentPage - 1);
  const goToNextPage = () => onPageChange(currentPage + 1);
  const changePage = (page: number) => () => {
    onPageChange(page);
  };

  const defaultIconProps: any = {
    icon: 'ARROW_MOVE',
    clickable: true,
  };

  const iconPropsToUse = {
    ...defaultIconProps,
    ...iconProps,
  };

  return (
    <StyledUl>
      <ArrowButton
        key='previous'
        disabled={currentPage === 1}
        onClick={goToPreviousPage}
      >
        <Icon {...iconPropsToUse} />
      </ArrowButton>
      {!hidePageNumbers &&
        paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <StyledLi key={`dots_${index}`}>{DOTS}</StyledLi>;
          }
          return (
            <PageButton
              key={pageNumber}
              active={pageNumber === currentPage}
              onClick={changePage(+pageNumber)}
            >
              {pageNumber}
            </PageButton>
          );
        })}
      <ArrowButton
        key='next'
        disabled={currentPage === lastPage}
        onClick={goToNextPage}
      >
        <Icon {...iconPropsToUse} />
      </ArrowButton>
    </StyledUl>
  );
}

export default NW2Pagination;

import {Button, Checkbox, Form} from 'antd';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import _get from 'lodash/get';

import Icon from 'view/components/Icon';
import TextValue from 'view/components/TextValue';
import {useAppDispatch} from 'store/hooks';

import {StyledFormItem} from 'view/components/FormItem';

import {RootState} from 'store/types';
import {getAmenities, setVenueAmenity} from 'store/venue/actions';
import {IAmenity} from 'types/dto/IAmenity';
import {EAmenitiesCategories, TFilteredAmenities} from 'types/venue';
import {useSortedAmenities} from 'view/components/NW2AmenitiesList/useSortedAmenities';
import {amenitiesCategoriesMap} from 'view/components/NW2AmenitiesList/types';
import {defaultAntdFormLayout, EMPTY_ARRAY} from 'constants/app';
import {
  PATH_TO_REDUCER_GLOBAL_VENUE_DATA,
  PATH_TO_REDUCER_VENUE_DATA,
} from 'constants/venue';
import {offsetXSm, offsetXXSm} from 'constants/styleVars';
import {IPublicVenue} from 'types/dto/IPublicVenue';
import {venueAmenitiesSet} from 'types/amenities';

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXSm};
`;

export const AmenitiesRowContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${offsetXSm} 0;
`;

export const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShowMoreButtonContainer = styled.div`
  text-align: left;
  & button {
    padding-left: 0;
  }
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  padding-top: ${offsetXXSm};
  column-count: 2;
  width: 100%;
`;

interface IProps {
  disableAddAmenities: boolean;
  showMonoAmenities?: boolean;
  venue?: IPublicVenue;
  amenities?: IAmenity[];
  useShowMore?: boolean;
  title?: string;
}

export const Amenities = memo((props: IProps) => {
  const {
    amenities: amenitiesFromProps, // we can pass custom data from parent component
    disableAddAmenities,
    title = 'General amenities:',
    useShowMore = false, // whether to show 'Show More' button or not (collapse/expand items)
  } = props;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const amenitiesFromStore = useSelector(
    (state: RootState) =>
      _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.amenities`) || EMPTY_ARRAY,
  );
  const amenities: IAmenity[] =
    useSelector((state) =>
      _get(state, `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.amenities`),
    ) || EMPTY_ARRAY;
  const isAmenitiesLoading: boolean = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_GLOBAL_VENUE_DATA}.amenitiesLoading`),
  );

  const venueAmenities: IAmenity[] =
    amenitiesFromProps || amenitiesFromStore || EMPTY_ARRAY;

  useEffect(() => {
    if (!amenities.length && !isAmenitiesLoading) {
      dispatch(getAmenities(venueAmenitiesSet));
    }
  }, [dispatch, amenities, isAmenitiesLoading]);

  /* 'useShowMore === true' case logic. Handle 'Show More' button display */
  const [collapsed, setCollapsed] = useState(useShowMore);
  const toggleExpand = () => setCollapsed(!collapsed);
  const maxVisibleAmenities = 5; // show this number in collapsed mode
  const shouldApplyShowMore = venueAmenities.length > maxVisibleAmenities;
  /* End */

  const sortedAmenities = useSortedAmenities({amenities});

  useEffect(() => {
    const getCheckedValues = (options: TFilteredAmenities[]): number[] =>
      options
        .filter((item) =>
          venueAmenities.some((am: IAmenity) => am.id === item.value),
        )
        .map((item: any) => item.value);

    form.setFieldsValue(
      Object.assign(
        {},
        ...Object.values(EAmenitiesCategories).map((v) => ({
          [v]: getCheckedValues(sortedAmenities[v]),
        })),
      ),
    );
  }, [form, sortedAmenities, venueAmenities]);

  const amenitiesList = useMemo(() => {
    const updateOptions = (
      checkedValues: CheckboxValueType[],
      key: EAmenitiesCategories,
    ): void => {
      const filteredItems = amenities.filter((item: any) =>
        checkedValues.includes(item.id),
      );
      dispatch(setVenueAmenity({key, value: filteredItems}));
    };

    return Object.values(EAmenitiesCategories).map((v) => ({
      name: v,
      label: amenitiesCategoriesMap[v],
      onChange: (values: CheckboxValueType[]) => {
        updateOptions(values, v);
      },
      options: sortedAmenities[v],
    }));
  }, [amenities, dispatch, sortedAmenities]);

  const venueAmenitiesList = useMemo(
    () =>
      venueAmenities
        .sort((a: IAmenity, b: IAmenity) => a.id - b.id)
        .slice(
          0,
          collapsed && shouldApplyShowMore
            ? maxVisibleAmenities
            : venueAmenities.length,
        ),
    [collapsed, shouldApplyShowMore, venueAmenities],
  );

  return disableAddAmenities ? (
    <div>
      <TextValue secondary size='small'>
        {title}
      </TextValue>

      <AmenitiesContainer id='amenitiesContainer'>
        {venueAmenitiesList.map((amenity: IAmenity) => (
          <AmenitiesRowContainer key={`amenity-${amenity.id}`}>
            <span data-test-class='amenitiesItemName'>{amenity.name}</span>
          </AmenitiesRowContainer>
        ))}
      </AmenitiesContainer>

      {/* Show More button */}
      <ShowMoreButtonContainer>
        {useShowMore && shouldApplyShowMore ? (
          <Button type='link' onClick={toggleExpand}>
            {collapsed ? 'Show More' : 'Show Less'}
          </Button>
        ) : null}
      </ShowMoreButtonContainer>
    </div>
  ) : (
    <Form
      form={form}
      name='amenitiesForm'
      component={false}
      {...defaultAntdFormLayout}
    >
      {amenitiesList.map(({name, label, options, onChange}) => (
        <StyledFormItem key={name} name={name} label={label}>
          <StyledCheckboxGroup
            name={name}
            onChange={onChange}
            options={options}
          />
        </StyledFormItem>
      ))}
    </Form>
  );
});

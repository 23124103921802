import {useCallback, useEffect, useState} from 'react';
import {DateTime} from 'luxon';

import {
  setEndDateForVenuesSearch,
  setMeetingRequestData,
  setMultiSearchTimeData,
  setStartDateForVenuesSearch,
} from 'store/search/searchSlice';
import {
  getDefaultCheckIn,
  getDefaultCheckOut,
  getDefaultTimeData,
} from 'constants/defaultOperationalTimes';
import {
  TDateType,
  TMultiDateChangeFn,
  TMultiDateType,
  TTimeChangeFn,
} from './NW2DatePicker/NW2MultiDatePicker.types';
import {ITimeData} from 'types/dto/ISearch.types';
import {loopOverDates} from './utils';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {getISOString} from 'utils/dateUtils';
import {getDateStringFromTime} from 'utils/stringUtils';
import {getFilteredUnitsByEventType} from 'utils/venueUtils';
import {resetStoreDates} from 'store/search/apiActions';

interface IReturnProps {
  startDate: Date | null;
  endDate: Date | null;
  timeData: ITimeData[];
  onDateChange: TMultiDateChangeFn;
  onTimeStartChange: TTimeChangeFn;
  onTimeEndChange: TTimeChangeFn;
  onReset: () => void;
  onChangeTimeComplete: (callback?: () => void) => void;
}

interface IProps {
  isDatepickerInitialised: boolean;
  handleSetDatepickerInitialised: (data: boolean) => void;
}
export function useMultiDatePicker({
  isDatepickerInitialised,
  handleSetDatepickerInitialised,
}: IProps): IReturnProps {
  const dispatch = useAppDispatch();

  const storeTimeData = useAppSelector(({search}) => search.initialTimeData);
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);

  const [startPickerDate, setStartPickerDate] = useState('');
  const [endPickerDate, setEndPickerDate] = useState('');
  const [timeData, setTimeData] = useState<ITimeData[]>([]);

  useEffect(() => {
    // init datepicker local state
    if (isDatepickerInitialised) return;

    // filtering pre- & post- events, for displaying days only
    const meetDaysTimeData = getFilteredUnitsByEventType(storeTimeData);

    const timeStart = meetDaysTimeData[0].timeStart;
    const timeEnd = meetDaysTimeData.at(-1)?.timeEnd || '';

    setStartPickerDate(timeStart);
    setEndPickerDate(timeEnd);
    setTimeData(meetDaysTimeData);

    handleSetDatepickerInitialised(true);
  }, [handleSetDatepickerInitialised, isDatepickerInitialised, storeTimeData]);

  const onTimeChange = useCallback(
    ({
      key,
      index,
      payload,
    }: {
      key: 'timeStart' | 'timeEnd';
      index: number;
      payload: TDateType;
    }) => {
      const newTimeData = timeData.map((data, dateIndex) => {
        if (index === dateIndex) {
          return {
            ...data,
            [key]: getISOString(payload),
          };
        }

        return data;
      });

      dispatch(setMultiSearchTimeData(newTimeData));
      setTimeData(newTimeData);
    },
    [dispatch, timeData],
  );

  const onTimeStartChange = useCallback(
    (index: number) => (payload: TDateType) => {
      onTimeChange({key: 'timeStart', index, payload});
    },
    [onTimeChange],
  );

  const onTimeEndChange = useCallback(
    (index: number) => (payload: TDateType) => {
      onTimeChange({key: 'timeEnd', index, payload});
    },
    [onTimeChange],
  );

  const onDateChange = useCallback(
    (payload: TMultiDateType) => {
      const handleSetData = (startDate: string, endDate = '') => {
        const timeDates = loopOverDates(startDate, endDate);

        setStartPickerDate(startDate);
        setEndPickerDate(endDate);
        setTimeData(timeDates);

        dispatch(setMultiSearchTimeData(timeDates));
        dispatch(
          setStartDateForVenuesSearch(
            getDateStringFromTime(timeDates[0].timeStart, startDate),
          ),
        );
        dispatch(
          setEndDateForVenuesSearch(
            getDateStringFromTime(timeDates[0].timeEnd, endDate || startDate),
          ),
        );
      };

      if (Array.isArray(payload)) {
        const [start, end] = payload;

        const startDate = start ? getISOString(DateTime.fromJSDate(start)) : '';
        const endDate = end ? getISOString(DateTime.fromJSDate(end)) : '';

        handleSetData(startDate, endDate);
      } else {
        handleSetData(getISOString(payload));
      }
    },
    [dispatch],
  );

  const onReset = useCallback(() => {
    // reset local state
    const defaultCheckIn = getDefaultCheckIn(roomType);
    const defaultCheckOut = getDefaultCheckOut(roomType);

    setStartPickerDate(defaultCheckIn);
    setEndPickerDate(defaultCheckOut);
    setTimeData([getDefaultTimeData(defaultCheckIn)]);

    dispatch(resetStoreDates(roomType));
  }, [dispatch, roomType]);

  const onChangeTimeComplete = useCallback(
    (callback?: () => void) => {
      dispatch(setMeetingRequestData([]));

      const timeStartForBackend = timeData[0]?.timeStart;
      const timeEndForBackend = timeData[timeData.length - 1]?.timeEnd;

      dispatch(
        setStartDateForVenuesSearch(
          getDateStringFromTime(timeStartForBackend, timeStartForBackend),
        ),
      );
      dispatch(
        setEndDateForVenuesSearch(
          getDateStringFromTime(timeEndForBackend, timeEndForBackend),
        ),
      );

      dispatch(setMultiSearchTimeData(timeData));

      if (typeof callback === 'function') {
        callback();
      }
    },
    [dispatch, timeData],
  );

  return {
    startDate: startPickerDate
      ? DateTime.fromISO(startPickerDate).toJSDate()
      : null,
    endDate: endPickerDate ? DateTime.fromISO(endPickerDate).toJSDate() : null,
    timeData,
    onDateChange,
    onTimeStartChange,
    onTimeEndChange,
    onChangeTimeComplete,
    onReset,
  };
}

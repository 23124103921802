import styled from 'styled-components';

import {
  NW2Gray100Color,
  NW2Gray600Color,
  offsetDef,
  offsetXXSm,
  offsetLg,
  offsetXLg,
  offsetXXLg,
  offsetXXXXLg,
  NW2FontSizeH2Xs,
  fontWeightBold,
  fontWeightExtraBold,
  fontSizeXSm,
  fontSizeMd,
  lineHeightSm,
  lineHeightLg,
  lineHeightXXXLg,
  lineHeightXXXXLg,
} from 'constants/styleVars';

const FONT_SIZE = '32px';
const TITLE_MARGIN_WITHOUT_SUBTITLE = '38px';
const TEXT_MIN_WIDTH = '110px';

export const Section = styled.section`
  padding: ${offsetXXXXLg} ${offsetXXLg} ${offsetXLg} ${offsetXLg};
  background-color: ${NW2Gray100Color};
`;

export const MainTitle = styled.h1<{
  noSubtitle: boolean;
  isUppercase?: boolean;
  marginBottom?: number;
}>`
  margin-bottom: ${({noSubtitle, marginBottom}) =>
    marginBottom
      ? `${marginBottom}px`
      : noSubtitle
      ? `${TITLE_MARGIN_WITHOUT_SUBTITLE}`
      : `${offsetXXSm}`};

  ${({isUppercase}) =>
    isUppercase
      ? `font-size: ${FONT_SIZE};
         line-height: ${lineHeightXXXLg};
         text-transform: uppercase;
         font-weight: ${fontWeightExtraBold};
         letter-spacing: 0.02em;`
      : `font-size: ${NW2FontSizeH2Xs};
         line-height: ${lineHeightXXXXLg};
         text-transform: none;
         font-weight: ${fontWeightBold};`}
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${offsetLg};
`;

export const InfoBlock = styled.div<{
  items?: string | number;
  gridWidth?: string;
  gap?: string;
}>`
  display: grid;
  grid-template-columns: ${({items}) => `repeat(${items || 1}, 1fr)`};
  grid-gap: ${offsetXXXXLg};
`;

// Contents title and text
export const Title = styled.h2`
  margin: 0;
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${NW2Gray600Color};
`;

export const Text = styled.span`
  min-width: ${TEXT_MIN_WIDTH};
  margin: 0;
`;

export const PaymentContent = styled.div`
  display: grid;
  justify-content: end;
  grid-template-columns: 1.8fr 1fr;
  gap: ${offsetDef};

  & > * {
    justify-self: end;
    align-self: end;
  }

  p {
    font-size: ${fontSizeMd};
    line-height: ${lineHeightLg};
    margin: 0;
  }
`;

import React, {useState} from 'react';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import EditMeetingRoom from './EditMeetingRoom';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import SeatsSetupItem from './SeatsSetupGroup/SeatsSetupItem';
import TimeRangeText from 'view/components/TimeRangeText';

import {pluralize} from 'utils/helpers';
import {getLabelsListOfExtras} from '../../utils';
import {ERoomSchemaNames, ERoomSchema} from 'types/venue';
import {TMeetingRoomItem} from 'types/search';

import {
  AmenitiesBlock,
  InfoBlock,
  RoomTitle,
  RoomWrapper,
} from '../AddMeetingRequest.styles';

type TProps = {
  meetingRoom: TMeetingRoomItem;
  indexOfRoom: number;
  isSingleRoom: boolean;
  isRemovableAll: boolean;
  deleteMeetingRoom: (roomIndex: number) => void;
  setSelectedEquipment: (
    roomIndex: number,
    formData: Record<string, any>,
    isAccommodationEnabled: boolean,
    isCustomerChangedFoodAndBeverage: boolean,
    isCustomerChangedAccommodation: boolean,
  ) => void;
  isAccommodationEnabled: boolean;
  isCustomerChangedFoodAndBeverage: boolean;
  isCustomerChangedAccommodation: boolean;
};

const MeetingRoomContent = ({
  meetingRoom,
  indexOfRoom,
  isSingleRoom,
  isRemovableAll,
  deleteMeetingRoom,
  setSelectedEquipment,
  isAccommodationEnabled,
  isCustomerChangedFoodAndBeverage,
  isCustomerChangedAccommodation,
}: TProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const meetingSectionEditHandler = () => {
    setIsEditMode(true);
  };

  const meetingSectionDeleteHandler = () => {
    deleteMeetingRoom(indexOfRoom);
  };

  const {
    timeStart,
    timeEnd,
    participants,
    seatsSetup,
    equipmentData: {ids, options},
  } = meetingRoom;

  const showRemoveButton = isRemovableAll || !isSingleRoom;

  const labelsListOfExtras = getLabelsListOfExtras(ids, options);

  const onSave = (formData: Record<string, any>) => {
    setSelectedEquipment(
      indexOfRoom,
      formData,
      isAccommodationEnabled,
      isCustomerChangedFoodAndBeverage,
      isCustomerChangedAccommodation,
    );
    setIsEditMode(false);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  if (!options.length) return <NW2Loader />;

  return (
    <RoomWrapper>
      <InfoBlock isEditMode={isEditMode}>
        <div>
          <RoomTitle isEditMode={isEditMode}>
            Meeting Room {indexOfRoom + 1}
          </RoomTitle>
          {!isEditMode && (
            <div>
              Time:&nbsp;
              <b>
                <TimeRangeText checkIn={timeStart} checkOut={timeEnd} />
              </b>
            </div>
          )}
        </div>
        {!isEditMode ? (
          <NW2Button
            minimized
            size='small'
            buttonType='tertiary'
            icon={<Icon transparent icon='NW2_EDIT' />}
            onClick={meetingSectionEditHandler}
          />
        ) : (
          showRemoveButton && (
            <NW2Button
              size='small'
              buttonType='tertiary'
              onClick={meetingSectionDeleteHandler}
            >
              Remove
            </NW2Button>
          )
        )}
      </InfoBlock>

      {isEditMode ? (
        <EditMeetingRoom
          onSave={onSave}
          onCancel={onCancel}
          meetingRoom={meetingRoom}
        />
      ) : (
        <>
          <div>
            Room capacity:&nbsp;
            <b>{pluralize('people', participants)}</b>
          </div>
          <AmenitiesBlock>
            {labelsListOfExtras.map((item) => (
              <span key={item}>{item}</span>
            ))}
          </AmenitiesBlock>
          <SeatsSetupItem
            type={seatsSetup}
            label={ERoomSchema[seatsSetup as ERoomSchemaNames]}
          />
        </>
      )}
    </RoomWrapper>
  );
};

export default MeetingRoomContent;

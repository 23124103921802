import React from 'react';
import styled from 'styled-components';

import {TextValue} from '../TextValue/TextValue';
import {offsetXLg} from 'constants/styleVars';
import {UploadFile} from 'antd/es/upload/interface';
import {TImageFile} from 'view/components/ImageUploaderComponent/types';
import {RcFile} from 'antd/lib/upload/interface';
import Resizer from 'react-image-file-resizer';

const uploadAlignment: Record<string, string> = {
  center: 'center',
  start: 'flex-start',
  end: 'flex-end',
};

const maxAmountOfImages = 12;

const getBase64 = (file: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const StyledTextValue = styled(TextValue)`
  margin-bottom: ${offsetXLg};
`;

const getHelperText = () => (
  <StyledTextValue inline>
    <TextValue inline error>
      *
    </TextValue>
    At least one venue image is mandatory in order to continue
  </StyledTextValue>
);

const sliceImageFiles = (imageFiles: UploadFile[]) => {
  return imageFiles.slice(0, maxAmountOfImages);
};

const findCoverImageFile = (fileList: TImageFile[], coverImage: string) =>
  fileList.find((file) => file.uid === coverImage) || fileList[0];

const removeFileTypeFromCoverImage = (
  coverImageFile: TImageFile | undefined,
) => {
  if (coverImageFile && coverImageFile.name) {
    const matchName = coverImageFile.name.match(/.*(?=\.(jpeg|png|jpg))/g);
    return matchName ? matchName[0] : null;
  }
};

const resizeFile = async (file: RcFile): Promise<Blob> => {
  if (file.size <= 1000000) return file;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1000,
      'JPEG',
      98,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      'blob',
    );
  });
};

export {
  getBase64,
  uploadAlignment,
  getHelperText,
  sliceImageFiles,
  maxAmountOfImages,
  findCoverImageFile,
  removeFileTypeFromCoverImage,
  resizeFile,
};

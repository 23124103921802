import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {MenuInfo} from 'rc-menu/lib/interface';

import SideBar from 'view/common/SideBar';
import MenuItem from 'view/components/MenuItem';

import {Routes} from 'constants/routes';

enum ENavMenuNames {
  COMPANIES_OVERVIEW = 'Companies overview',
  UNMANAGED_ACCOUNTS = 'Unmanaged accounts',
  ANNOUNCEMENTS = 'Announcements',
}

const navMenu = [
  {
    key: '0',
    tab: ENavMenuNames.COMPANIES_OVERVIEW,
    link: Routes.companiesOverview,
  },
  {
    key: '1',
    tab: ENavMenuNames.UNMANAGED_ACCOUNTS,
    link: Routes.unmanagedAccounts,
  },
  {
    key: '2',
    tab: ENavMenuNames.ANNOUNCEMENTS,
    link: Routes.announcements,
  },
];

const SiderMenu: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentKey, setCurrentKey] = useState('0');
  const {pathname} = location;

  useEffect(() => {
    const key = navMenu.find(({link}) => link === pathname)?.key || '0';
    setCurrentKey(key);
  }, [pathname]);

  const handleMenuClick = ({key}: MenuInfo) => {
    const selectedKey = Number(key);
    const currentLink = navMenu[selectedKey].link;
    navigate(currentLink);
  };

  return (
    <SideBar
      label='Browse by:'
      handleMenuClick={handleMenuClick}
      selectedKeys={[currentKey]}
    >
      {navMenu.map(({tab, key}) => (
        <MenuItem key={key}>{tab}</MenuItem>
      ))}
    </SideBar>
  );
};

export default SiderMenu;

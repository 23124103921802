import React, {memo} from 'react';

import {FormGroup} from './NW2SupplierContactsDetailsForm.styles';
import {
  NW2FormItemInput,
  NW2FormItemPhoneNumber,
} from 'view/components/NW2FormItem/NW2FormItem';
import {
  legalPhoneFieldRules,
  emailFieldRules,
  notRequiredStringFieldRules,
  requiredFieldRules,
} from 'utils/finalFormFieldRules';

import {
  ESupplierPrimaryContactInformationFormFields,
  IEditFormProps,
} from './types';
import {NW2Gray300Color, NW2Gray600Color, offsetDef} from 'constants/styleVars';

const PrimaryContactInformationEditForm = memo(
  ({
    disabledItems,
    gap = parseInt(offsetDef),
    isExternalVenue,
  }: IEditFormProps) => {
    return (
      <FormGroup columnNumber={1} gap={gap}>
        {isExternalVenue && (
          <>
            <NW2FormItemInput
              type='text'
              name={ESupplierPrimaryContactInformationFormFields.FIRST_NAME}
              placeholder='First name*'
              label='First name*'
              labelColor={NW2Gray600Color}
              rules={requiredFieldRules(
                ESupplierPrimaryContactInformationFormFields.FIRST_NAME,
              )}
              data-testid={`primary-input-${ESupplierPrimaryContactInformationFormFields.FIRST_NAME}`}
            />

            <NW2FormItemInput
              type='text'
              name={ESupplierPrimaryContactInformationFormFields.LAST_NAME}
              placeholder='Last name*'
              label='Last name*'
              labelColor={NW2Gray600Color}
              rules={requiredFieldRules(
                ESupplierPrimaryContactInformationFormFields.LAST_NAME,
              )}
              data-testid={`primary-input-${ESupplierPrimaryContactInformationFormFields.LAST_NAME}`}
            />
          </>
        )}
        <NW2FormItemInput
          type='text'
          name={ESupplierPrimaryContactInformationFormFields.EMAIL}
          placeholder='Email address*'
          label='Email address*'
          labelColor={NW2Gray300Color}
          rules={emailFieldRules}
          disabled={disabledItems?.includes(
            ESupplierPrimaryContactInformationFormFields.EMAIL,
          )}
          data-testid={`primary-input-${ESupplierPrimaryContactInformationFormFields.EMAIL}`}
        />
        <NW2FormItemPhoneNumber
          type='text'
          name={ESupplierPrimaryContactInformationFormFields.PHONE}
          placeholder='+49 1234 123456'
          label='Phone number*'
          labelColor={NW2Gray600Color}
          rules={legalPhoneFieldRules}
          disabled={disabledItems?.includes(
            ESupplierPrimaryContactInformationFormFields.PHONE,
          )}
          data-testid={`primary-input-${ESupplierPrimaryContactInformationFormFields.PHONE}`}
        />
        <NW2FormItemPhoneNumber
          type='text'
          name={ESupplierPrimaryContactInformationFormFields.FAX}
          placeholder='Fax number'
          label='Fax number'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierPrimaryContactInformationFormFields.FAX,
          )}
          data-testid={`primary-input-${ESupplierPrimaryContactInformationFormFields.FAX}`}
        />
        <NW2FormItemInput
          type='text'
          name={ESupplierPrimaryContactInformationFormFields.WEBSITE_URL}
          placeholder='Venue website'
          label='Venue website'
          labelColor={NW2Gray600Color}
          rules={notRequiredStringFieldRules}
          disabled={disabledItems?.includes(
            ESupplierPrimaryContactInformationFormFields.WEBSITE_URL,
          )}
          data-testid={`primary-input-${ESupplierPrimaryContactInformationFormFields.WEBSITE_URL}`}
        />
      </FormGroup>
    );
  },
);

export default PrimaryContactInformationEditForm;

import {useEffect, useState} from 'react';
import _debounce from 'lodash/debounce';

const DEFAULT_OPTIONS = {
  config: {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true,
    debounceTime: 50,
  },
};

export function useMutationObserver(
  targetEl: HTMLElement | null,
  cb: (args?: any) => void,
  options = DEFAULT_OPTIONS,
) {
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    if (!cb || typeof cb !== 'function') {
      console.warn(
        `You must provide a valida callback function, instead you've provided ${cb}`,
      );
      return;
    }

    const {debounceTime} = options.config;
    const obs = new MutationObserver(
      debounceTime > 0 ? _debounce(cb, debounceTime) : cb,
    );
    setObserver(obs);
  }, [cb, options, setObserver]);

  useEffect(() => {
    if (!observer) return;
    const {config} = options;

    if (!targetEl) return;
    observer.observe(targetEl, config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, options]);
}

import React from 'react';

import {useAppDispatch} from 'store/hooks';
import {Card} from 'antd';
import TextValue from 'view/components/TextValue/TextValue';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {setOpenLoginPopup, setOpenRegisterPopup} from 'store/app/appSlice';
import {
  fontSizeXSm,
  NW2Info50Color,
  offsetDef,
  offsetSm,
} from 'constants/styleVars';

import {CardText, CardTitle} from '../../NW2BookingPreview.styles';

const cardStyle = {
  backgroundColor: `${NW2Info50Color}`,
  marginBottom: `${offsetDef}`,
  borderRadius: `${offsetSm}`,
  border: 'none',
};
const headStyle = {
  border: 'none',
  padding: `0 ${offsetDef}`,
  fontSize: `${fontSizeXSm}`,
};
const bodyStyle = {
  padding: `${offsetDef} `,
  paddingTop: '0',
  fontSize: `${fontSizeXSm}`,
  display: 'flex',
  alignItems: 'center',
};

const SignInCard = () => {
  const dispatch = useAppDispatch();
  const onRegisterClick = () => {
    dispatch(setOpenRegisterPopup({openRegisterPopup: true}));
  };

  const onLogInButtonClick = () => {
    dispatch(setOpenLoginPopup({openLoginPopup: true}));
  };
  const cardTitle = <CardTitle>Already have an account?</CardTitle>;
  const LinkText = (
    <TextValue
      size='small'
      bold
      inline
      textDecoration='underline'
      onClick={onRegisterClick}
    >
      register now
    </TextValue>
  );
  return (
    <Card
      title={cardTitle}
      style={cardStyle}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
    >
      <CardText>
        In order to complete the booking you need to have an account and be
        logged in. Don’t have an account yet? Don’t worry, {LinkText}.
      </CardText>
      <NW2Button
        size='small'
        buttonType='secondary'
        onClick={onLogInButtonClick}
      >
        Log in
      </NW2Button>
    </Card>
  );
};

export default SignInCard;

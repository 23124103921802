import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import TimeRangeText from 'view/components/TimeRangeText';

import DateUtils, {DateFormats} from 'utils/dateUtils';
import {formatDate} from 'utils/stringUtils';
import {
  colorSchema,
  EBookingSummaryTheme,
} from 'view/components/NW2SummaryBlock/types';
import {
  lgBp,
  mdBp,
  fontSizeMd,
  fontSizeXSm,
  lineHeightLg,
  lineHeightSm,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';

const Header = styled.div<{
  colorTheme: EBookingSummaryTheme;
  isHighlighted?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${({colorTheme}) => colorSchema[colorTheme].unitCardHeaderColor};
  padding: ${offsetXSm};
  margin: 0 -${offsetXSm};
  user-select: none;
  background-color: ${({colorTheme, isHighlighted}) =>
    isHighlighted ? colorSchema[colorTheme].highlightColor : 'transparent'};
`;

const HeaderLeft = styled.div`
  display: flex;
`;

const HeaderDate = styled.div<{colorTheme: EBookingSummaryTheme}>`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};
  font-size: ${fontSizeMd};
  color: ${({colorTheme}) => colorSchema[colorTheme].unitCardDateColor};
  line-height: ${lineHeightLg};
  margin-right: ${offsetXXSm};

  @media (min-width: ${mdBp}px) and (max-width: ${lgBp - 1}px) {
    gap: ${offsetXXSm};
  }
`;

const HeaderTime = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};

  @media (min-width: ${mdBp}px) and (max-width: ${lgBp - 1}px) {
    gap: ${offsetXXSm};
  }
`;

const StyledHeaderIcon = styled(Icon)<{
  isTurned: boolean;
  colorTheme: EBookingSummaryTheme;
}>`
  transform: rotate(${({isTurned}) => (isTurned ? '180deg' : '0')});
  color: ${({colorTheme}) => colorSchema[colorTheme].iconColor};
`;

interface IProps {
  onToggle: () => void;
  theme: EBookingSummaryTheme;
  isCollapsed: boolean;
  checkIn: string;
  checkOut: string;
  isTimeShowed?: boolean;
  isHighlighted?: boolean;
}

export function BookingUnitCardHeader({
  onToggle,
  theme,
  checkIn,
  checkOut,
  isCollapsed,
  isHighlighted,
  isTimeShowed = true,
}: IProps) {
  return (
    <Header onClick={onToggle} colorTheme={theme} isHighlighted={isHighlighted}>
      <HeaderLeft>
        <HeaderDate colorTheme={theme}>
          {DateUtils.getDateWithoutHours(checkIn, DateFormats['1 Jan 2023'])}
        </HeaderDate>

        <HeaderTime>
          ({formatDate(checkIn, 'DDD')})
          {isTimeShowed && (
            <span>
              <TimeRangeText checkIn={checkIn} checkOut={checkOut} />
            </span>
          )}
        </HeaderTime>
      </HeaderLeft>

      <StyledHeaderIcon
        transparent
        icon='ARROW_UP_FILLED'
        isTurned={isCollapsed}
        colorTheme={theme}
      />
    </Header>
  );
}

import React, {useState} from 'react';

import NW2Switch from 'view/components/NW2Switch';
import NW2Badge from 'view/components/NW2Badge';
import {NW2TimeRangePicker} from 'view/components/NW2Datepicker';

import {capitalizeText} from 'utils/stringUtils';
import {IDay} from 'types/dto/IDay.type';
import {EDays} from 'constants/days';
import {
  DEFAULT_OPEN_FROM_HOUR,
  DEFAULT_OPEN_FROM_MINUTE,
  DEFAULT_OPEN_TO_HOUR,
  DEFAULT_OPEN_TO_MINUTE,
  WHOLE_DAY_OPEN_FROM_HOUR,
  WHOLE_DAY_OPEN_FROM_MINUTE,
  WHOLE_DAY_OPEN_TO_HOUR,
  WHOLE_DAY_OPEN_TO_MINUTE,
} from 'constants/NW2OpenHours';
import {setTimeToDate} from 'utils/operationalHoursUtils';
import {
  RowContainer,
  RowContainerWholeDay,
  RowItem,
} from './OpenHoursRow.styles';
import {getInitially24HoursAccess} from './helpers';
import DateUtils from 'utils/dateUtils';
import {TDateType} from 'types/dto/ISearch.types';

interface IProps {
  operationalTime: IDay;
  name: EDays;
  isLoading?: boolean;
  onBadgeClick: () => void;
  updateFields: (day: string, values: Partial<IDay>) => void;
}

const OpenHoursRow = ({
  operationalTime,
  name,
  isLoading,
  onBadgeClick,
  updateFields,
}: IProps) => {
  const {timeStartHour, timeStartMinutes, timeEndHour, timeEndMinutes, active} =
    operationalTime;

  const initially24HoursAccess = getInitially24HoursAccess({
    timeStartHour,
    timeStartMinutes,
    timeEndHour,
    timeEndMinutes,
  });

  const [wholeDayAccess, setWholeDayAccess] = useState(initially24HoursAccess);

  const handle24HourClick = () => {
    updateFields(name, {
      ['timeStartHour']: wholeDayAccess
        ? DEFAULT_OPEN_FROM_HOUR
        : WHOLE_DAY_OPEN_FROM_HOUR,
      ['timeStartMinutes']: wholeDayAccess
        ? DEFAULT_OPEN_FROM_MINUTE
        : WHOLE_DAY_OPEN_FROM_MINUTE,
      ['timeEndHour']: wholeDayAccess
        ? DEFAULT_OPEN_TO_HOUR
        : WHOLE_DAY_OPEN_TO_HOUR,
      ['timeEndMinutes']: wholeDayAccess
        ? DEFAULT_OPEN_TO_MINUTE
        : WHOLE_DAY_OPEN_TO_MINUTE,
    });

    setWholeDayAccess(!wholeDayAccess);
  };

  const handleOpenFromChange = (value: TDateType) => {
    const time = DateUtils.getHoursAndMinutes(value);
    const splitTime = time.split(':');

    updateFields(name, {
      ['timeStartHour']: splitTime[0],
      ['timeStartMinutes']: splitTime[1],
    });
  };

  const handleOpenToChange = (value: TDateType) => {
    const time = DateUtils.getHoursAndMinutes(value);
    const splitTime = time.split(':');

    updateFields(name, {
      ['timeEndHour']: splitTime[0],
      ['timeEndMinutes']: splitTime[1],
    });
  };

  return !wholeDayAccess ? (
    <RowContainer isActive={active}>
      <RowItem>
        <NW2Badge
          label={capitalizeText(name)}
          isActive={active}
          fullWidth
          disabled={isLoading}
          onClick={onBadgeClick}
          size='small'
        />
      </RowItem>
      {active && (
        <>
          <NW2TimeRangePicker
            valueStart={setTimeToDate(timeStartHour, timeStartMinutes)}
            valueEnd={setTimeToDate(timeEndHour, timeEndMinutes)}
            onChangeStart={handleOpenFromChange}
            onChangeEnd={handleOpenToChange}
            labelStart='From'
            labelEnd='To'
            size='medium'
          />

          <RowItem>
            <NW2Switch
              label='24 hour access'
              isActive={initially24HoursAccess}
              disabled={isLoading}
              onClick={handle24HourClick}
            />
          </RowItem>
        </>
      )}
    </RowContainer>
  ) : (
    <RowContainerWholeDay>
      <RowItem>
        <NW2Badge
          label={capitalizeText(name)}
          isActive={active}
          fullWidth
          disabled={isLoading}
          onClick={onBadgeClick}
          size='small'
        />
      </RowItem>
      {active && (
        <RowItem>
          <NW2Switch
            label='24 hour access'
            isActive={initially24HoursAccess}
            disabled={isLoading}
            onClick={handle24HourClick}
          />
        </RowItem>
      )}
    </RowContainerWholeDay>
  );
};

export default OpenHoursRow;

import React, {useState} from 'react';
import styled from 'styled-components';
import {useAppSelector} from 'store/hooks';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import FeedbackHeader from './components/FeedbackHeader';
import FeedbackBody from './components/FeedbackBody';
import {FeedbackSubjectButton} from './components/SubjectButton';

import {
  borderRadiusLg,
  fontSizeLg,
  modalSmallWidth,
  navbarZIndex,
  NW2Gray100Color,
  offsetSm,
  offsetXXLg,
} from 'constants/styleVars';

const FeedbackContainer = styled(FeedbackSubjectButton)`
  position: fixed;
  top: 50%;
  right: 0;
  transform: rotate(270deg) translateX(50%);
  transform-origin: right bottom;
  z-index: ${navbarZIndex};

  font-size: ${fontSizeLg};
  border-radius: ${borderRadiusLg} ${borderRadiusLg} 0 0;
  background: ${NW2Gray100Color};
  padding: ${offsetSm} ${offsetXXLg};
`;

function NW2Feedback() {
  const [isShowed, setIsShowed] = useState(false);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const triggerModal = () => setIsShowed(!isShowed);
  return !isMobile ? (
    <>
      <FeedbackContainer onClick={triggerModal}>
        <div>Feedback</div>
      </FeedbackContainer>

      <NW2ModalDrawer
        isShowed={isShowed}
        isMobile={false}
        onClose={triggerModal}
        modalWidth={modalSmallWidth}
        header={<FeedbackHeader />}
        body={<FeedbackBody onClose={triggerModal} />}
      />
    </>
  ) : null;
}

export default NW2Feedback;

import React from 'react';

import {FeatureToggleContext} from 'context/FeatureToggleContext';
import {EFeatureToggles} from 'constants/featureToggles';

type Props = {
  children: any;
  enabledFeatures: EFeatureToggles[] | null;
};

const FeatureToggleProvider = ({children, enabledFeatures}: Props) => (
  <FeatureToggleContext.Provider value={{enabledFeatures}}>
    {children}
  </FeatureToggleContext.Provider>
);

export default FeatureToggleProvider;

import React, {memo} from 'react';

import {RoomSetupForm} from '../RoomSetup/RoomSetupForm/RoomSetupForm';
import {RoomSetupView} from '../RoomSetup/RoomSetupView/RoomSetupView';
import {ERoomSchemaNames} from 'types/venue';
import {ICommonProps} from '../../types';
import {EAddMeetingRoomFormFields} from 'view/venue/NW2VenueProfile/components/AddSpace/types';

interface IProps extends ICommonProps {
  initialValues: {
    [EAddMeetingRoomFormFields.CAPACITIES]: any;
  };
  unitCapacities?: {capacity: number; setupStyle: ERoomSchemaNames}[];
}

const SetupsAndCapacities = ({
  initialValues,
  unitCapacities,
  updateSpace,
  isSending,
  isEditorShowed,
  onEditorShowed,
}: IProps) => {
  return isEditorShowed ? (
    <RoomSetupForm
      fieldName={EAddMeetingRoomFormFields.CAPACITIES}
      initialValues={initialValues}
      isSending={isSending}
      updateSpace={updateSpace}
      onEditorShowed={onEditorShowed}
      columns={2}
    />
  ) : (
    <RoomSetupView unitCapacities={unitCapacities} columns={2} />
  );
};

export default memo(SetupsAndCapacities);

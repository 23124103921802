import React from 'react';

import Icon from 'view/components/Icon';
import NW2Dropdown from 'view/components/NW2Dropdown';
import {withLocalePopover} from './withLocalePopover';

import {ELocale} from 'types/locale';
import {useClickOutside} from 'hooks/useClickOutside';
import useToggle from 'hooks/useToggle';
import {setLocale} from 'store/app/appSlice';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {ILocaleDropdownProps} from './types';
import {
  DrawerTitle,
  List,
  ListItem,
  StyledDrawer,
  TriggerItemBox,
  Wrapper,
} from './LocaleDropdown.styles';

const TITLE = 'Display settings';

const dropdownItems = [
  {
    code: ELocale.EN_UK,
    title: '24-Hour Format | Kilometers',
  },
  {
    code: ELocale.EN_US,
    title: '12-Hour Format | Miles',
  },
];

function LocaleDropdown(props = {} as ILocaleDropdownProps) {
  const dispatch = useAppDispatch();

  const {changeLanguage, language} = props;

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const [isOpened, toggleOpened] = useToggle(false);
  const [ref] = useClickOutside(isOpened, toggleOpened);

  const triggerItem = (
    <TriggerItemBox>
      <Icon icon='EARTH' />
    </TriggerItemBox>
  );

  const list = (
    <div ref={ref}>
      <List>
        {dropdownItems.map(({code, title}) => (
          <ListItem
            key={title}
            isActive={language === code}
            onClick={(e) => {
              e.preventDefault();
              changeLanguage(code).then(() => {
                dispatch(setLocale(code));

                setTimeout(toggleOpened, 500);
              });
            }}
          >
            {language === code && <Icon icon='NW2_TICK' />}
            {title}
          </ListItem>
        ))}
      </List>
    </div>
  );

  return isMobile ? (
    <>
      <div onClick={toggleOpened}>{triggerItem}</div>
      <StyledDrawer
        title={
          <DrawerTitle>
            <Icon icon='EARTH' />
            {TITLE}
          </DrawerTitle>
        }
        onClose={toggleOpened}
        open={isOpened}
        height='auto'
        closeIcon={<Icon icon='NW2_CLOSE' />}
        placement='bottom'
        destroyOnClose
        bodyStyle={{padding: 0}}
      >
        {list}
      </StyledDrawer>
    </>
  ) : (
    <Wrapper>
      <NW2Dropdown
        placement='bottomRight'
        onClick={toggleOpened}
        open={isOpened}
        titleItemText={TITLE}
        triggerItem={triggerItem}
      >
        {list}
      </NW2Dropdown>
    </Wrapper>
  );
}

export default withLocalePopover(LocaleDropdown);

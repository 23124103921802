import React from 'react';
import {DateTime} from 'luxon';

import Icon from 'view/components/Icon';
import NW2NumericInput from 'view/components/NW2NumericButtonInput/NW2NumericButtonInput';
import ToolTip from 'view/components/ToolTip';
import TimeRangeText from 'view/components/TimeRangeText';
import {NW2TimeRangePicker} from 'view/components/NW2Datepicker';

import {
  EDeskSpaceName,
  ERoomSchema,
  ERoomSchemaNames,
  iconsObject,
  TSpaceType,
} from 'types/venue';
import {ISummaryUnit} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {maximumCounterNumber} from 'constants/app';
import {pluralize} from 'utils/helpers';

import {
  ParticipantsInputWrapper,
  Row,
  SetupStyleButton,
  SpaceTypeValueWrapper,
  TimeRangeWrapper,
  UnitInfo,
} from '../Space.styles';

interface IProps {
  unit: ISummaryUnit;
  checkInDate: string;
  checkOutDate: string;
  setupStyle: ERoomSchemaNames;
  participants: number;
  isSpaceEditMode: boolean;
  isMeetingRoom: boolean;
  isPackageSelected: boolean;
  onTimeStartChange: (val: string) => void;
  onTimeEndChange: (val: string) => void;
  onParticipantsChange: (val: number) => void;
  openRoomSetupModalHandler: () => void;
}

const HeadInfo = ({
  unit,
  checkInDate,
  checkOutDate,
  setupStyle,
  participants,
  isMeetingRoom,
  isSpaceEditMode,
  isPackageSelected,
  onTimeStartChange,
  onTimeEndChange,
  onParticipantsChange,
  openRoomSetupModalHandler,
}: IProps) => {
  const {unitType, maxParticipants} = unit;

  const roomSetupText = setupStyle
    ? ERoomSchema[setupStyle as ERoomSchemaNames]
    : '';

  const maxCapacityText = maxParticipants ? `(${maxParticipants} max)` : '';

  const participantsText = `${pluralize(
    'people',
    unit.unitFilter?.capacity || participants || 0,
  )} ${maxCapacityText}`;

  const tipIconProps = {icon: 'INFO', secondary: false};

  const unitTypeText = EDeskSpaceName[unitType as TSpaceType];

  const tipSpaceTypeConfigMap: Record<ERoomType, any> = {
    [ERoomType.SHARED_SPACE]: {
      title: EDeskSpaceName.SHARED_SPACE,
      content: [
        {
          caption: `This space is a typical co-working area, where different people share one office. 
            This space is for people to comfortably focus on work and collaborate with friends, colleagues, 
            associates and people you’ve just met.`,
        },
      ],
    },
    [ERoomType.OPEN_SPACE]: {
      title: EDeskSpaceName.OPEN_SPACE,
      content: [
        {
          caption: `This space has a lounge character, similar to our open-shared spaces, but with a greater variety of seating types. 
            This allows you to work and collaborate comfortably.`,
        },
      ],
    },
    [ERoomType.MEETING_ROOM]: null,
    [ERoomType.WORK_SPACE]: null,
    [ERoomType.GROUPS]: null,
    [ERoomType.BEDROOM]: null,
  };

  const currentSetupStyleIcon = iconsObject[setupStyle as ERoomSchemaNames];
  const minCounterNumber = isPackageSelected ? 10 : 1;

  const getValueForTimeRangePicker = (date: string) => {
    const convertedDate = DateTime.fromISO(date, {setZone: true}).toISO({
      includeOffset: false,
    });
    return typeof convertedDate === 'string' ? new Date(convertedDate) : null;
  };

  const timeRangeField = (
    <TimeRangeWrapper>
      <NW2TimeRangePicker
        valueStart={getValueForTimeRangePicker(checkInDate)}
        valueEnd={getValueForTimeRangePicker(checkOutDate)}
        onChangeStart={onTimeStartChange as any}
        onChangeEnd={onTimeEndChange as any}
      />
    </TimeRangeWrapper>
  );

  return isSpaceEditMode ? (
    <div>
      <Row>
        {timeRangeField}
        {isMeetingRoom && (
          <SetupStyleButton
            minimized
            onClick={openRoomSetupModalHandler}
            icon={<Icon transparent size={34} icon={currentSetupStyleIcon} />}
          />
        )}
      </Row>
      {isMeetingRoom && (
        <ParticipantsInputWrapper>
          <span>Number of participants</span>
          <NW2NumericInput
            initialValue={participants}
            minValue={minCounterNumber}
            maxValue={maximumCounterNumber}
            onChange={onParticipantsChange}
            hasInput
          />
        </ParticipantsInputWrapper>
      )}
    </div>
  ) : (
    <>
      <UnitInfo>
        <span>Time:&nbsp;</span>
        <TimeRangeText checkIn={checkInDate} checkOut={checkOutDate} />
      </UnitInfo>

      {isMeetingRoom ? (
        <>
          {!!roomSetupText && (
            <UnitInfo>
              <span>Room setup:&nbsp;</span>
              {roomSetupText}
            </UnitInfo>
          )}
          <UnitInfo>
            <span>Participants:&nbsp;</span>
            {participantsText}
          </UnitInfo>
        </>
      ) : (
        <UnitInfo>
          <span>Space type:&nbsp;</span>
          <SpaceTypeValueWrapper>
            <span>{unitTypeText}</span>
            <ToolTip
              config={tipSpaceTypeConfigMap[unitType as ERoomType]}
              iconProps={tipIconProps}
            />
          </SpaceTypeValueWrapper>
        </UnitInfo>
      )}
    </>
  );
};

export default HeadInfo;

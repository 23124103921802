import React from 'react';
import {DateTime} from 'luxon';

import {months} from 'view/components/NW2Datepicker/DesktopDatePicker/helpers';

import {
  CurrentYearMonth,
  Option,
  MonthYearPickerOptions,
} from '../DesktopDatePicker.styles';
import {offsetXXLg} from 'styles/configs/offset';

type TProps = {
  date: Date;
  onChangeMonth: (month: keyof typeof months) => void;
  onClick: () => void;
  isActive: boolean;
  isDisabled: boolean;
};

export function MonthPicker({
  onChangeMonth,
  date,
  onClick,
  isActive,
  isDisabled,
}: TProps) {
  const currentDate = DateTime.fromJSDate(date);
  const currentMonthShort = currentDate.monthShort;
  const currentMonthLong = currentDate.monthLong;

  return (
    <div>
      <CurrentYearMonth
        isActive={isActive}
        isDisabled={isDisabled}
        onClick={onClick}
      >
        {currentMonthLong}
      </CurrentYearMonth>

      <MonthYearPickerOptions
        isActive={isActive}
        gap={offsetXXLg}
        alignItems='center'
      >
        <div>
          {Object.keys(months).map((option) => (
            <div key={option}>
              <Option
                value={option}
                onClick={() => onChangeMonth(option)}
                isActive={currentMonthShort === option}
              >
                {option}
              </Option>
            </div>
          ))}
        </div>
      </MonthYearPickerOptions>
    </div>
  );
}

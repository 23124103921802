import styled, {css} from 'styled-components';

import {EMenuItemSizes, TMenuItemSize} from './MenuItem.types';
import {
  blackColor,
  fontSizeSm,
  fontSizeXSm,
  fontWeightBold,
  fontWeightNormal,
  lineHeightMd,
  lineHeightSm,
  NW2Blue200Color,
  NW2Blue500Color,
  NW2Gray600Color,
  NW2Warn500Color,
  NW2ErrorLight500Color,
  offsetDef,
  offsetSm,
  offsetXXLg,
  offsetXXSm,
} from 'constants/styleVars';

const INDICATOR_SIZE = '8px';

const menuItemSizeSchema: TMenuItemSize = Object.freeze({
  [EMenuItemSizes.SMALL]: {
    fontSize: fontSizeXSm,
    lineHeight: lineHeightSm,
    marginBottom: offsetXXSm,
    padding: `${offsetXXSm} ${offsetDef}`,
    paddingActive: `${offsetXXSm} ${offsetDef} ${offsetXXSm} 28px`,
    activeMarkWidth: '4px',
    activeMarkHeight: '12px',
    activeMarkLeftSpace: offsetDef,
  },
  [EMenuItemSizes.MEDIUM]: {
    fontSize: fontSizeSm,
    lineHeight: lineHeightMd,
    marginBottom: offsetSm,
    padding: `10px ${offsetDef} 10px ${offsetXXLg}`,
    paddingActive: `10px ${offsetDef} 10px 48px`,
    activeMarkWidth: '6px',
    activeMarkHeight: '14px',
    activeMarkLeftSpace: '30px',
  },
});

export const MenuListItem = styled.li<{
  isActive: boolean;
  size: EMenuItemSizes;
  isHighlighted?: boolean;
  hasErrors?: boolean;
}>(({isActive, isHighlighted, size, hasErrors}) => {
  const schema = menuItemSizeSchema[size];

  return css`
    ${hasErrors &&
    !isHighlighted &&
    css`
      display: grid;
      grid-template-columns: 1fr ${offsetDef};
    `}

    margin-bottom: ${schema.marginBottom};

    &:hover {
      font-weight: ${fontWeightBold};
      color: ${blackColor};
      background-color: ${isActive ? 'none' : NW2Blue200Color};
    }

    a {
      position: relative;
      font-size: ${schema.fontSize};
      line-height: ${schema.lineHeight};
      font-weight: ${isActive ? fontWeightBold : fontWeightNormal};
      display: block;
      color: ${isActive ? blackColor : NW2Gray600Color};
      padding: ${isActive ? schema.paddingActive : schema.padding};
      padding-right: ${hasErrors && !isHighlighted ? '0' : ''};
      transition: padding-left 0.3s;

      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: ${schema.activeMarkLeftSpace};
        width: ${schema.activeMarkWidth};
        height: ${schema.activeMarkHeight};
        opacity: ${isActive ? 1 : 0};
        visibility: ${isActive ? 'visible' : 'hidden'};
        transform: translateY(-50%);
        background-color: ${NW2Blue500Color};
        transition: visibility 0.3s, opacity 0.3s;
        pointer-events: none;
      }

      ${isHighlighted &&
      css`
        &:after {
          position: absolute;
          content: '';
          right: ${offsetXXSm};
          top: 50%;
          transform: translateY(-50%);
          width: ${INDICATOR_SIZE};
          height: ${INDICATOR_SIZE};
          background-color: ${NW2Warn500Color};
          border-radius: 50%;
          pointer-events: none;
        }
      `}
    }
  `;
});

export const Triangle = styled.div`
  position: relative;
  background-color: ${NW2ErrorLight500Color};
  text-align: left;
  top: 10px;
  left: ${offsetXXSm};
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &,
  &:before,
  &:after {
    width: 7px;
    height: 7px;
    border-top-right-radius: 30%;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;

import React, {Component} from 'react';
import log from 'loglevel';

import Page404 from './Page404';
import {FeedbackSubjectTypes} from 'types/app';

log.enableAll();

class ErrorBoundary extends Component<any, {hasError: boolean; src: string}> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      src: `https://jira.hrs.io/plugins/servlet/issueCollectorBootstrap.js?collectorId=${FeedbackSubjectTypes.CRASH_REPORT}&locale=en_UK`,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error};
  }

  componentDidCatch(error: any, info: any) {
    // log the error to our server with loglevel
    log.error({error, info});

    if (process.env.NODE_ENV === 'development') return;

    // TODO: Commented till issue with Jira collector is fixed
    // let script: ScriptElt = document.querySelector(
    //   `script[src="${this.state.src}"]`,
    // );
    //
    // if (!script) {
    //   // Create script
    //   script = document.createElement('script');
    //   script.src = this.state.src;
    //   script.async = true;
    //   // Add script to document body
    //   document.body.appendChild(script);
    // }
    //
    // script.addEventListener('load', function () {
    //   window.ATL_JQ_PAGE_PROPS = {
    //     // ==== crash report collector ==== //
    //     [EJiraCollectorIds.CRASH_REPORT]: {
    //       triggerFunction(showCollectorDialog: () => void) {
    //         showCollectorDialog();
    //       },
    //       fieldValues: {
    //         summary: error.message || 'Crash Report',
    //         description: info.componentStack,
    //       },
    //     },
    //   };
    // });
  }

  // TODO: Commented till issue with Jira collector is fixed
  // componentWillUnmount() {
  //   const script: ScriptElt = document.querySelector(
  //     `script[src="${this.state.src}"]`,
  //   );
  //   if (script) document.body.removeChild(script);
  // }

  render() {
    if (this.state.hasError) return <Page404 />;
    return this.props.children;
  }
}

export default ErrorBoundary;

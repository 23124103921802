import React, {FC} from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'store/hooks';

import {setVenueUnitBookingStatus} from 'store/venues/apiActions';
import {offsetDef, offsetXXSm} from 'constants/styleVars';
import {EUnitBookingStatus} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${offsetXXSm} 0;
`;

const CancelButton = styled(Button)`
  margin-right: ${offsetDef};
`;

type Props = {
  status: EUnitBookingStatus;
  dateValue: string;
  setVisible: (visible: boolean) => void;
  disabled: boolean;
};

const BUTTON_SIZE = 'small';

const PopoverContent: FC<Props> = ({
  setVisible,
  disabled,
  status,
  dateValue,
}) => {
  const dispatch = useDispatch();

  const venueId: number = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );

  const onCancel = () => setVisible(false);

  const onApply = () => {
    dispatch(
      setVenueUnitBookingStatus({
        venueId,
        payload: {
          date: dateValue,
          workedStatus: status,
        },
      }),
    );
    setVisible(false);
  };

  return (
    <Container>
      <CancelButton type='link' size={BUTTON_SIZE} onClick={onCancel}>
        Cancel
      </CancelButton>
      <Button
        type='primary'
        size={BUTTON_SIZE}
        onClick={onApply}
        disabled={disabled}
      >
        Apply
      </Button>
    </Container>
  );
};

export default PopoverContent;

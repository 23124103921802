import styled from 'styled-components';
import Icon from 'view/components/Icon';

import {
  borderColor,
  NW2Blue500Color,
  offsetDef,
  offsetSm,
  whiteColor,
} from 'constants/styleVars';

export const List = styled.ul<{maxHeight: number}>`
  list-style: none;
  width: 100%;
  max-height: ${({maxHeight}) => maxHeight && `${maxHeight}px`};
  height: auto;
  overflow-y: auto;
  z-index: 1;
  background: ${whiteColor};
  padding: 0;
`;

export const ListItem = styled.li<{active?: boolean}>`
  display: flex;
  align-items: center;
  background: ${({active}) => (active ? NW2Blue500Color : 'transparent')};
  padding: ${offsetDef} ${offsetSm};
  cursor: pointer;
  border-top: 1px solid
    ${({active}) => (active ? NW2Blue500Color : borderColor)};
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetSm};
`;

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {GoogleMap, OverlayView} from '@react-google-maps/api';
import _get from 'lodash/get';
import {useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2VenueDetailsBlockLink from '../NW2VenueDetailsBlockLink';
import NW2VenueDetailsExpandedMap from './components/NW2VenueDetailsExpandedMap/NW2VenueDetailsExpandedMap';

import {getGoogleMapPinCenteredPositionOffset} from 'utils/googleMapUtils';
import {DEFAULT_GOOGLE_MAP_ZOOM} from 'constants/app';
import {VENUE_DETAILS_DESKTOP_HEIGHT} from '../../constants';
import {setIsVenueDetailsMapVisible} from 'store/venues/venuesSlice';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MapContainer = styled.div`
  height: ${VENUE_DETAILS_DESKTOP_HEIGHT};
`;

const mapContainerStyle = {
  height: VENUE_DETAILS_DESKTOP_HEIGHT,
};

const MAP_OPTIONS = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
};

export const NW2VenueDetailsMap = () => {
  const dispatch = useDispatch();

  const isVenueDetailsMapVisible: boolean = useSelector((state) =>
    _get(state, 'venuesReducer.isVenueDetailsMapVisible'),
  );
  const isGoogleMapLoaded = useAppSelector(({app}) => app.isGoogleMapLoaded);

  const {venueDetails} = useVenueDetailsData();

  const venueLocation = venueDetails.location;
  const latitude = venueLocation.latitude;
  const longitude = venueLocation.longitude;

  const getMarkerLocation = () => ({
    lat: Number(latitude || 0),
    lng: Number(longitude || 0),
  });

  const markerLocation = getMarkerLocation();

  const openMap = () => dispatch(setIsVenueDetailsMapVisible(true));
  const closeMap = () => dispatch(setIsVenueDetailsMapVisible(false));

  if (!isGoogleMapLoaded || !latitude || !longitude) return null;

  return (
    <>
      <Container>
        <MapContainer>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={markerLocation}
            zoom={DEFAULT_GOOGLE_MAP_ZOOM}
            options={MAP_OPTIONS}
          >
            {latitude && longitude && (
              <OverlayView
                position={markerLocation}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getGoogleMapPinCenteredPositionOffset}
              >
                <Icon icon='MAP_PIN_HOUSE' />
              </OverlayView>
            )}
          </GoogleMap>
        </MapContainer>
        <NW2VenueDetailsBlockLink text="See what's around" onClick={openMap} />
      </Container>

      {isVenueDetailsMapVisible && (
        <NW2VenueDetailsExpandedMap closeMap={closeMap} />
      )}
    </>
  );
};

export default React.memo(NW2VenueDetailsMap);

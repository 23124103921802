import {useSelector} from 'react-redux';
import React, {ReactNode, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {Button, Form, FormInstance, message} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';
import _get from 'lodash/get';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useQuery} from 'hooks/useQuery';

import Content from 'view/common/Content/Content';
import StepsNavigation from 'view/common/StepsNavigation';
import Address from './pages/Address';
import General from './pages/General';
import ContactDetails from './pages/ContactDetails';
import Photos from './pages/Photos';
import OperationalHours from './pages/OperationalHours';
import Review from './pages/Review';
import VenueValidation from 'infra/common/venueValidation';
import venueTexts from 'texts/venueTexts';
import {venueContainer} from 'app';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';

import usePeopleOfInterest from './pages/ContactDetails/components/ContactDetailsForm/components/usePeopleOfInterest';
import {RootState} from 'store/types';
import {SecondaryButton} from 'view/components/Button';
import {
  setCreateVenueStep,
  setLoading,
  getCorporateVenueById,
} from 'store/venue/actions';
import {
  PATH_TO_REDUCER_VENUE_DATA,
  PATH_TO_REDUCER_VENUE_PHOTOS,
} from 'constants/venue';
import {Routes} from 'constants/routes';
import {ButtonsSection} from './CreateVenue.styles';
import {contentMaxWidth, contentMaxWidthSm} from 'constants/styleVars';
import {useBeforeUnload} from 'hooks/useBeforeUnload';
import {IVenue} from 'types/venue';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {removeExtension} from 'utils/helpers';
import LocalStorageService from 'infra/common/localStorage.service';
import {useHmdMigration} from './useHmdMigration';
import {useCreationVenueMetrics} from './useCreationVenueMetrics';

const StyledContent = styled(Content)<{step: number}>`
  max-width: ${({step}) => (step === 5 ? contentMaxWidth : contentMaxWidthSm)};
`;

interface IStep {
  title: string;
  content: ReactNode;
  key: string;
}

type TProps = {
  accommodationType: EAccommodationType;
  isHmd?: boolean;
};

export const getStepsList = (
  form: FormInstance,
  accommodationType: EAccommodationType,
  isHmd?: boolean,
): IStep[] => [
  {
    title: 'General info.',
    content: (
      <General
        mode='add'
        form={form}
        accommodationType={accommodationType}
        isHmd={isHmd}
      />
    ),
    key: 'general',
  },
  {
    title: `${venueTexts[accommodationType].venueTypeCapitalized} address`,
    content: (
      <Address mode='add' form={form} accommodationType={accommodationType} />
    ),
    key: 'address',
  },
  {
    title: 'Company details',
    content: (
      <ContactDetails
        mode='add'
        form={form}
        accommodationType={accommodationType}
      />
    ),
    key: 'details',
  },
  {
    title: `${venueTexts[accommodationType].venueTypeCapitalized} photos`,
    content: (
      <Photos mode='add' form={form} accommodationType={accommodationType} />
    ),
    key: 'images',
  },
  {
    title: 'Operational hours',
    content: (
      <OperationalHours mode='add' accommodationType={accommodationType} />
    ),
    key: 'operationalTimes',
  },
  {
    title: 'Review & create',
    content: <Review form={form} accommodationType={accommodationType} />,
    key: 'review',
  },
];

const CreateVenue = ({accommodationType, isHmd}: TProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {isOfferRequest} = useQuery(); //Used to identify that user came from offer email

  useBeforeUnload({isOnPageLoad: true});

  const accountId = useAppSelector(({app}) => app.corporateAccountId);
  const loading: boolean = useSelector((state: RootState) =>
    _get(state, 'venue.loading'),
  );
  const currentStep: number = useSelector((state: RootState) =>
    _get(state, 'venue.createVenueStep'),
  );
  const isInformationConfirmed: boolean = useSelector((state: RootState) =>
    _get(state, 'venue.isVenueInformationConfirmed'),
  );
  const venue: IVenue = useSelector((state: RootState) =>
    _get(state, PATH_TO_REDUCER_VENUE_DATA),
  );
  const photoList: UploadFile[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.photoList`),
  );
  const coverPhoto: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.coverPhoto`),
  );
  const legalEntityType = useSelector((state) =>
    _get(state, 'venue.legalEntityType'),
  );

  useCreationVenueMetrics({currentStep});

  const {hmdToken, isHmdLoading, hasHmdError} = useHmdMigration({
    form,
    venueId: venue?.id,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const peopleOfInterest = usePeopleOfInterest(venue?.legalContact);

  if (isHmdLoading) {
    return <NW2Loader height='100%' />;
  }

  const createVenue = async () => {
    dispatch(setLoading(true));

    const coverFile = photoList.find((item) => item.uid === coverPhoto);
    const coverFileName = coverFile?.name
      ? removeExtension(coverFile.name)
      : '';

    // Create internal venue (office)
    if (accommodationType === EAccommodationType.CORPORATE_OFFICE) {
      venueContainer.createCorporateVenue({
        payload: {
          accountId,
          venue: {
            ...venue,
            coverFileName,
            corporateAccountId: accountId,
          },
          files: photoList,
        },
        onSuccess: (officeId: number) => {
          dispatch(getCorporateVenueById(officeId));
          navigate(`${Routes.corporateVenue}?id=${officeId}`);
          dispatch(setLoading(false));
        },
        onError: () => {
          dispatch(setLoading(false));
        },
      });
    } else {
      const {supplier, legalContact, contact} = venue;

      const reqParams = {
        payload: {
          venue: {
            ...venue,
            peopleOfInterest,
            contact: {...contact, ...supplier},
            legalContact: {
              ...legalContact,
              legalEntityType,
            },
            coverFileName,
            documents: photoList.filter((file) => !file.originFileObj),
          },
          files: photoList.filter((file) => file.originFileObj),
        },
        onSuccess: () => {
          if (isOfferRequest) {
            LocalStorageService.setByKey(
              'hmdToken',
              encodeURIComponent(hmdToken as string),
            );
          }
          navigate(Routes.endVenueRegistration);
          dispatch(setLoading(false));
        },
        onError: () => {
          dispatch(setLoading(false));
        },
      };

      if (isHmd) {
        // Register HMD public venue
        await venueContainer.registerHmdVenue(reqParams);
      } else {
        // Create external (public) venue
        await venueContainer.createPublicVenue(reqParams);
      }
    }
  };

  const onCreateVenueClick = () => {
    if (!isInformationConfirmed) {
      message.error({
        type: 'error',
        content:
          'You need to agree/check with Terms and Conditions & Privacy Policy',
        key: 'errorMessage',
      });
      return;
    }
    createVenue();
  };

  const goPrev = () => {
    dispatch(setCreateVenueStep(currentStep - 1));
  };

  const goNext = () => {
    dispatch(setCreateVenueStep(currentStep + 1));
  };

  const validateDataAndMoveNext = async () => {
    try {
      await form.validateFields();

      if (
        currentStep === 4 &&
        !VenueValidation.validateOperationalTimes(venue)
      ) {
        return;
      }

      goNext();
    } catch (error) {
      message.error({
        type: 'error',
        content: `Check all form fields in step ${currentStep + 1}`,
        key: 'submitError',
      });
    }
  };

  const stepsList = getStepsList(form, accommodationType, isHmd);

  const items = stepsList.map(({key, title}) => ({
    key,
    title,
  }));

  if (!stepsList[currentStep]) return null;

  return (
    <div>
      <StepsNavigation
        current={currentStep}
        direction='horizontal'
        items={items}
      />

      <StyledContent step={currentStep}>
        <section>{stepsList[currentStep].content}</section>

        <ButtonsSection>
          {currentStep < stepsList.length - 1 && (
            <Button
              disabled={hasHmdError}
              type='primary'
              onClick={validateDataAndMoveNext}
            >
              Continue
            </Button>
          )}
          {currentStep === stepsList.length - 1 && (
            <Button
              type='primary'
              onClick={onCreateVenueClick}
              disabled={loading}
              loading={loading}
            >
              {isHmd ? 'Register ' : 'Create '}
              {venueTexts[accommodationType].venueType}
            </Button>
          )}
          {currentStep > 0 && (
            <SecondaryButton onClick={goPrev}>Previous</SecondaryButton>
          )}
        </ButtonsSection>
      </StyledContent>
    </div>
  );
};

export default CreateVenue;

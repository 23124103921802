import {TLayout} from 'view/common/Header/types';

const getDefaultLayout = (): TLayout => {
  return {
    type: 'default',
    components: [
      {component: 'isLogo'},
      {component: 'isSelectLocationButton'},
      {component: 'isNavList'},
      {component: 'isSignOutButton'},
    ],
  };
};

export {getDefaultLayout};

import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {
  colorSchema,
  EBookingSummaryTheme,
} from 'view/components/NW2SummaryBlock/types';

import {
  borderRadiusLg,
  fontSizeLg,
  fontSizeMd,
  fontSizeXXSm,
  fontWeightBold,
  infoColor,
  lineHeightLg,
  offsetDef,
  offsetXSm,
  warnColor,
  NW2Gray200Color,
  fontSizeSm,
  offsetXXXSm,
  offsetSm,
} from 'constants/styleVars';

export const TotalHeader = styled.div<{isMultiRequest?: boolean}>`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-column-gap: ${offsetDef};
  padding: ${({isMultiRequest}) => !isMultiRequest && offsetDef};

  &:not(:first-child) {
    padding-top: ${offsetSm};
  }
`;

export const TotalHeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalHeaderTitle = styled.div<{colorTheme: EBookingSummaryTheme}>`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  color: ${({colorTheme}) => colorSchema[colorTheme].titleColor};
  word-break: break-word;
`;

export const TotalHeaderSubTitle = styled.div<{
  colorTheme: EBookingSummaryTheme;
}>`
  display: flex;
  gap: ${offsetXSm};
  font-size: ${fontSizeXXSm};
  line-height: ${fontSizeLg};
  color: ${({colorTheme}) => colorSchema[colorTheme].subTitleColor};
`;

export const StyledIcon = styled(Icon)`
  color: inherit;
`;

// modals
const StyledModalCardWrapper = styled.div`
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    border-radius: ${borderRadiusLg} 0 0 ${borderRadiusLg};
  }
`;

export const StyledPolicyCardWrapper = styled(StyledModalCardWrapper)`
  &:before {
    background-color: ${warnColor};
  }
`;

export const StyledRfpCardWrapper = styled(StyledModalCardWrapper)`
  &:before {
    background-color: ${infoColor};
  }
`;

export const TotalDiffPriceSection = styled.div`
  margin-bottom: ${offsetDef};
  padding: 0 0 ${offsetDef};
  border-bottom: 1px solid ${NW2Gray200Color};
  font-size: ${fontSizeSm};

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(&:last-child) {
      margin-bottom: ${offsetXXXSm};
    }
  }
`;

import React from 'react';
import {Field} from 'react-final-form';

import {useTimeRangeValidation} from 'view/components/NW2Datepicker';
import {NW2TimePicker} from 'view/components/NW2Datepicker/NW2TimePicker';
import {TDateType} from 'types/dto/ISearch.types';

type TProps = {
  timeRange: {
    timeStart: TDateType;
    timeEnd: TDateType;
  };
};

const TimeRangeField = ({timeRange}: TProps) => {
  const {timeStart, timeEnd} = timeRange;
  const {invalidStartValues, invalidEndValues} = useTimeRangeValidation({
    valueStart: timeStart,
    valueEnd: timeEnd,
  });
  return (
    <>
      <Field name='timeRange.timeStart'>
        {({input}) => (
          <NW2TimePicker
            selected={input.value}
            onChange={input.onChange}
            excludeTimes={invalidStartValues}
            popperPlacement='auto'
          />
        )}
      </Field>

      <Field name='timeRange.timeEnd'>
        {({input}) => (
          <NW2TimePicker
            selected={input.value}
            onChange={input.onChange}
            excludeTimes={invalidEndValues}
            popperPlacement='auto'
          />
        )}
      </Field>
    </>
  );
};

export default TimeRangeField;

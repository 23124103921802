import React from 'react';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';

import {PaginationContainer} from '../../NW2VenuesListComponent.styles';

interface IProps {
  isVisible: boolean;
  getItemsWithPagination: () => void;
}

const PaginationBlock = ({isVisible, getItemsWithPagination}: IProps) => {
  if (!isVisible) return null;

  return (
    <PaginationContainer>
      <NW2Button
        icon={<Icon icon='DOUBLE_ARROW_DOWN' />}
        onClick={getItemsWithPagination}
      >
        view more offers
      </NW2Button>
    </PaginationContainer>
  );
};

export default PaginationBlock;

import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import DateUtils from 'utils/dateUtils';

import {
  NW2Gray300Color,
  offsetDef,
  offsetXSm,
  whiteColor,
} from 'constants/styleVars';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 ${offsetDef};
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
  background: ${whiteColor};
  border: 2px solid ${NW2Gray300Color};
  box-sizing: border-box;
  border-radius: 100px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
`;

const Text = styled.span`
  margin: 0 ${offsetXSm};
`;

type TProps = {
  distance: number;
};

const DistanceIndicator = ({distance}: TProps) => {
  const processedDistance = (distance / 1000).toFixed(1);
  const minutes = distance / 67; // walking speed here is 67 meters per minute
  const processedMinutes = DateUtils.convertMinutesToHours(minutes);

  return (
    <Container>
      <Icon icon='WALKING' />
      <Text>
        {processedDistance}&nbsp;km / {processedMinutes}
      </Text>
    </Container>
  );
};

export default DistanceIndicator;

import {DateTime} from 'luxon';
import {useEffect, useMemo, useState} from 'react';

import {ITimeData} from 'types/dto/ISearch.types';
import {checkInTime} from 'constants/defaultOperationalTimes';
import {getDateTime, getISOString} from 'utils/dateUtils';
import {TSearchCriteria} from 'types/search';
import {getVenuesListQuerySearchData} from 'utils/queryUtils';
import {EEventType} from 'types/venue';
import {IOfferDay, IRequestDay} from 'types/offer';
import {useOfferRequestDetails} from './useOfferRequestDetails';
import useSearchTemplateData from 'view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/hooks/useSearchTemplateData';

interface IProps {
  searchCriteria: TSearchCriteria;
  meetingRoomCapacity: number;
  searchString: string;
  latitude: string;
  longitude: string;
}

export const useShowAlternatives = ({
  searchCriteria,
  meetingRoomCapacity,
  searchString,
  latitude,
  longitude,
}: IProps) => {
  const {offerDetails} = useOfferRequestDetails();

  const requestedUnits: (IRequestDay | IOfferDay)[] = offerDetails.days;

  const [requestedTimeData, setRequestedTimeData] = useState<ITimeData[]>([]);
  const {meetingRequestData} = useSearchTemplateData(requestedTimeData);

  const startDate = DateTime.fromISO(
    getISOString(requestedUnits.at(0)?.checkIn),
  );
  const endDate = DateTime.fromISO(
    getISOString(requestedUnits.at(-1)?.checkOut),
  );
  const isMultiRooms = !startDate.hasSame(endDate, 'day');

  const startDayStartOf = startDate.startOf('day');

  // adjust past dates
  const initStartDate = useMemo(
    () =>
      DateTime.fromISO(checkInTime).set({
        hour: startDate.hour,
      }),
    [startDate.hour],
  );

  const isDateCurrent = startDayStartOf >= DateTime.now().startOf('day');

  const currentStartDate = useMemo<string>(
    () => getISOString(isDateCurrent ? startDate : initStartDate),
    [initStartDate, isDateCurrent, startDate],
  );

  const currentEndDate = useMemo(() => {
    const startEndDifference = Math.round(
      endDate.endOf('day').diff(startDayStartOf, 'days').days,
    );

    return getISOString(
      isDateCurrent
        ? endDate
        : initStartDate
            .plus({days: startEndDifference - 1})
            .set({hour: endDate.hour}),
    );
  }, [endDate, initStartDate, isDateCurrent, startDayStartOf]);
  //end adjust past dates

  const querySearchData = getVenuesListQuerySearchData({
    searchCriteria: {
      ...searchCriteria,
      endDate: currentEndDate,
      startDate: currentStartDate,
      meetingRoomCapacity,
      searchString,
      latitude,
      longitude,
      multiRooms: isMultiRooms,
    },
  });

  useEffect(() => {
    const updatedRequestedTimeData = requestedUnits.reduce(
      (acc: ITimeData[], day: IRequestDay, index) => {
        const timeStart = getISOString(
          getDateTime(day.checkIn).set({
            year: DateTime.fromISO(currentStartDate).year,
            month: DateTime.fromISO(currentStartDate).month,
            day: DateTime.fromISO(currentStartDate).day + index,
          }),
        );
        const timeEnd = getISOString(
          getDateTime(day.checkOut).set({
            year: DateTime.fromISO(currentStartDate).year,
            month: DateTime.fromISO(currentStartDate).month,
            day: DateTime.fromISO(currentStartDate).day + index,
          }),
        );

        const isMeetingDay = day.eventType === EEventType.DAY;

        if (isMeetingDay) {
          acc.push({
            timeStart,
            timeEnd,
            eventType: EEventType.DAY,
          });
        }
        return acc;
      },
      [],
    );

    setRequestedTimeData(updatedRequestedTimeData);
  }, [currentEndDate, currentStartDate, isMultiRooms, requestedUnits]);

  return {
    querySearchData,
    isMultiRooms,
    requestedTimeData,
    meetingRequestData,
  };
};

import {useAppSelector} from 'store/hooks';

export const useCorporateOffices = () => {
  const offices = useAppSelector(({corporate}) => corporate.offices);
  const selectedOfficeId = useAppSelector(
    ({corporate}) => corporate.selectedOfficeId,
  );
  const isLoading = useAppSelector(({corporate}) => corporate.isLoading);
  const corporateAccountId = useAppSelector(({app}) => app.corporateAccountId);

  const selectedOffice = offices.filter(
    ({id}) => id === Number(selectedOfficeId),
  )[0];

  return {
    selectedOfficeId,
    corporateOffices: offices,
    corporateAccountId,
    selectedOffice,
    isLoading,
  };
};

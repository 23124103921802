import {ILegalExecutivesInfo, IVenueLegalContact} from 'types/venue';
import {EMPTY_ARRAY} from 'constants/app';
import {DateTime} from 'luxon';

const usePeopleOfInterest = (legalContact: IVenueLegalContact) => {
  if (!legalContact) {
    return EMPTY_ARRAY;
  }
  const transformPerson = (person: ILegalExecutivesInfo) => {
    const {firstName, lastName, yearBirth, monthBirth, dateBirth, type} =
      person;
    if (
      ![firstName, lastName, yearBirth, monthBirth, dateBirth, type].every(
        Boolean,
      )
    ) {
      return undefined;
    }
    const dateOfBirth = DateTime.fromISO(
      `${yearBirth}-${monthBirth}-${dateBirth}`,
    ).toISO();
    return {firstName, lastName, dateOfBirth, type};
  };
  const peopleOfInterest = [
    legalContact.signatory,
    legalContact.owner,
    legalContact.controller,
  ]
    .filter(Boolean)
    .map(transformPerson)
    .filter(Boolean);

  return peopleOfInterest.length > 0 ? peopleOfInterest : [];
};

export default usePeopleOfInterest;

export enum EAdditionalActionsModal {
  DUPLICATE_SPACE = 'DUPLICATE_SPACE',
  TEMPORARILY_DISABLE_SPACE = 'TEMPORARILY_DISABLE_SPACE',
  DELETE_SPACE = 'DELETE_SPACE',
}

export enum ESpaceDeletionStatuses {
  READY_FOR_DELETION = 'READY_FOR_DELETION',
  HAS_RFP_BOOKINGS = 'HAS_RFP_BOOKINGS',
  HAS_ACTIVE_BOOKINGS = 'HAS_ACTIVE_BOOKINGS',
  HAS_ACTIVE_OFFERS = 'HAS_ACTIVE_OFFERS',
}

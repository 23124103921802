import React from 'react';

import Space from './Space/Space';
import LeftSideItemTitle from '../components/LeftSideContainer/LeftSideItemTitle';

import {ICustomerBookedUnits} from 'types/dto/IBooking.types';
import {IUnit} from 'types/dto/IUnit.types';
import {EEventType} from 'types/venue';
import {offsetXXLg} from 'constants/styleVars';
import {StyledLeftSideItem} from '../NW2BookingPreview/components/Space/Space.styles';
import {getFilteredUnitsByEventType} from '../../../utils/venueUtils';

type TProps = {
  bookedUnits: ICustomerBookedUnits[];
  venueUnits: IUnit[];
  isPresetEditModeOn: boolean;
  setIsPresetEditModeOn: (value: boolean) => void;
};

const SpaceList = ({
  bookedUnits,
  venueUnits,
  isPresetEditModeOn,
  setIsPresetEditModeOn,
}: TProps) => {
  const filteredDays = getFilteredUnitsByEventType(bookedUnits);

  return (
    <>
      {bookedUnits.map(
        (
          {
            checkInDate,
            checkOutDate,
            unitBookings,
            foodAndBeverage,
            bedrooms,
            eventType,
          },
          index,
        ) => {
          const idx = filteredDays.findIndex(
            (item) => item.checkInDate === checkInDate,
          );

          const title =
            eventType === EEventType.POST_EVENT
              ? 'Post-event'
              : eventType === EEventType.PRE_ARRIVAL
              ? 'Pre-arrival'
              : `DAY ${idx + 1}`;

          return (
            <StyledLeftSideItem
              key={index}
              paddingBottom={offsetXXLg}
              item={{
                title: (
                  <LeftSideItemTitle
                    title={title}
                    checkIn={checkInDate}
                    checkOut={checkOutDate}
                    hasTimeRange={!!unitBookings.length}
                  />
                ),
                children: (
                  <Space
                    isMultiDay
                    dayIndex={index}
                    venueUnits={venueUnits}
                    bookedUnits={unitBookings}
                    bookedFoodAndBeverage={foodAndBeverage}
                    setIsPresetEditModeOn={setIsPresetEditModeOn}
                    isPresetEditModeOn={isPresetEditModeOn}
                    bookedBedrooms={bedrooms}
                    eventType={eventType}
                  />
                ),
              }}
            />
          );
        },
      )}
    </>
  );
};

export default SpaceList;

import {EAnnouncementsVisibilityAreas} from 'store/admin/types';

const maxEditorLength = 10000;

export type TFormData = {
  title: string;
  subtitle: string;
  htmlString: string;
  rootNodeContext: string;
  isActiveSwitch: boolean;
  visibilityAreas: Array<keyof typeof EAnnouncementsVisibilityAreas>;
};
export const validateAnnouncementForm = (values: TFormData) => {
  const errors: Record<string, string> = {};
  if (!values.htmlString) {
    errors.htmlString = 'Please fill out the body content.';
  }
  if (values.rootNodeContext?.length >= maxEditorLength) {
    errors.htmlString = `You've reached the maximum character limit of ${maxEditorLength} characters.`;
  }
  if (!values.visibilityAreas?.length) {
    errors.visibilityAreasErrors =
      'Please select either public venues or corporate venues.';
  }
  return errors;
};

import React, {memo} from 'react';
import styled from 'styled-components';

import {useSortedAmenities} from './useSortedAmenities';
import {AMENITIES_COLUMN_WIDTH} from 'constants/app';
import {
  fontSizeSm,
  fontWeightExtraBold,
  lineHeightLg,
  lineHeightMd,
  offsetDef,
  offsetNone,
  offsetXLg,
  offsetXSm,
} from 'constants/styleVars';
import {IAmenity} from 'types/dto/IAmenity';
import {EAmenitiesCategories} from 'types/venue';
import {amenitiesCategoriesMap} from './types';

const OFFSET_LIST = '18px';

const GroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${AMENITIES_COLUMN_WIDTH}, 1fr)
  );
  grid-column-gap: ${offsetXLg};
  grid-row-gap: ${offsetXSm};
`;

const Group = styled.div`
  font-size: ${fontSizeSm};
  line-height: ${lineHeightLg};
`;

const Title = styled.div`
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: ${lineHeightMd};
  white-space: nowrap;
  margin-bottom: ${offsetDef};
`;

const Items = styled.ul`
  padding-left: ${OFFSET_LIST};
  margin-bottom: ${offsetNone};
`;

const Item = styled.li`
  margin-bottom: ${offsetXSm};
`;

interface IProps {
  amenities: IAmenity[];
}

export const NW2NewAmenitiesList = memo(({amenities}: IProps) => {
  const sortedAmenities = useSortedAmenities({amenities});

  return (
    <GroupWrapper data-testid='venue-amenities-preview'>
      {Object.entries(sortedAmenities).map(([key, values]) => (
        <Group key={key}>
          <Title>{amenitiesCategoriesMap[key as EAmenitiesCategories]}</Title>
          {values.length ? (
            <Items>
              {values.map(({label}) => (
                <Item key={label}>{label}</Item>
              ))}
            </Items>
          ) : (
            <span>-</span>
          )}
        </Group>
      ))}
    </GroupWrapper>
  );
});

import styled from 'styled-components';
import {Button} from 'antd';

import {
  offsetDef,
  offsetXSm,
  primaryColor,
  secondaryColor,
} from 'constants/styleVars';

export const FullWidthButton = styled(Button)`
  width: 100%;
  margin: ${offsetDef} 0;
`;

export const SecondaryButton = styled(Button)`
  border: 1px solid ${primaryColor};
  color: ${primaryColor};
`;

export const DefaultButton = styled(Button)<{type?: string}>`
  border: 1px solid ${secondaryColor};
  color: ${secondaryColor};

  ${({type}) =>
    type === 'link' &&
    `
      border: 0;
      pointer-events: none;
    `}

  ${({icon}) =>
    icon &&
    `
      & > * {vertical-align: middle}
      & > * + * {margin-left: ${offsetXSm}}
    `}
`;

import React from 'react';
import useFlutterWebView from './useFlutterWebView';

const withBridgeBTC = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    const {isBTCApp} = useFlutterWebView();
    return isBTCApp ? null : <Component {...(props as P)} />;
  };
};

export default withBridgeBTC;

import styled from 'styled-components';

import {Steps} from 'antd';

import {offsetDef, whiteColor, offsetXXLg} from '../../../constants/styleVars';

const StepsNavigation = styled(Steps)`
  background: ${whiteColor};
  margin: 0 auto ${offsetXXLg};
  padding: ${offsetDef};
`;

export default StepsNavigation;

import React, {ReactNode, useMemo} from 'react';

import NW2InfoCard from 'view/common/NW2InfoCard';

import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';
import {InfoCardWrapper} from '../NMMSpacesDetails.styles';
import {ESpaceDetailsContentSections} from '../types';

enum EInfoCardStatuses {
  LIVE = 'Live',
  READY_TO_GO_LIVE = 'Ready to go live',
  WORK_IN_PROGRESS = 'Work in progress',
}

interface IInfoCardsContent {
  title: string;
  type: EInfoCardType;
  icon: string;
  text: string | ReactNode;
}

type TProps = {
  errorSections: Set<ESpaceDetailsContentSections>;
  isWip?: boolean;
};

const StatusAndPreviewContent = ({isWip, errorSections}: TProps) => {
  const showStatus = useMemo(() => {
    if (isWip) {
      if (errorSections.size) {
        return EInfoCardStatuses.WORK_IN_PROGRESS;
      } else {
        return EInfoCardStatuses.READY_TO_GO_LIVE;
      }
    }

    return EInfoCardStatuses.LIVE;
  }, [errorSections.size, isWip]);

  const infoCards: {
    [key in EInfoCardStatuses]: IInfoCardsContent;
  } = {
    [EInfoCardStatuses.LIVE]: {
      title: EInfoCardStatuses.LIVE,
      type: EInfoCardType.live,
      icon: 'NW2_TICK',
      text: 'This space is now live and will appear in the organisers’ search.',
    },
    [EInfoCardStatuses.READY_TO_GO_LIVE]: {
      title: EInfoCardStatuses.READY_TO_GO_LIVE,
      type: EInfoCardType.live,
      icon: 'NW2_TICK',
      text: 'You can now save your changes. All of the mandatory input fields had been provided.',
    },
    [EInfoCardStatuses.WORK_IN_PROGRESS]: {
      title: 'Work in progress',
      type: EInfoCardType.warn,
      icon: 'TRIANGLE_WARN',
      text: `There is still some important information missing which is required before this space can go live.`,
    },
  };

  const allContent = infoCards[showStatus];
  const {title, type, icon, text} = allContent;

  return (
    <InfoCardWrapper>
      <NW2InfoCard title={title} type={type} icon={icon}>
        {text}
      </NW2InfoCard>
    </InfoCardWrapper>
  );
};

export default StatusAndPreviewContent;

import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  $getSelection,
  $isRangeSelection,
  ElementNode,
  GridSelection,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from 'lexical';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {mergeRegister} from '@lexical/utils';
import Icon from '../../Icon';

import {
  Link,
  LinkEditButton,
  LinkEditorContainer,
  LinkInput,
  LinkWrapper,
} from 'view/components/WYSIWYGEditor/components/ToolbarPlugin.styles';

type TFloatingLinkEditorProps = {
  editor: LexicalEditor;
  getSelectedNode: (selection: RangeSelection) => ElementNode | TextNode;
};
function FloatingLinkEditor({
  editor,
  getSelectedNode,
}: TFloatingLinkEditorProps) {
  const editorRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState<
    RangeSelection | GridSelection | NodeSelection | null
  >(null);
  const LowPriority = 1;

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }
    function positionEditorElement(editor: HTMLElement, rect: DOMRect | null) {
      if (rect === null) {
        editor.style.opacity = '0';
        editor.style.top = '-1000px';
        editor.style.left = '-1000px';
      } else {
        editor.style.opacity = '1';
        editor.style.top = `${
          rect.top + rect.height + window.pageYOffset + 10
        }px`;
        editor.style.left = `${
          rect.left +
          window.pageXOffset -
          editor.offsetWidth / 8 +
          rect.width / 8
        }px`;
      }
    }
    const rootElement = editor.getRootElement();
    if (
      selection &&
      nativeSelection &&
      !nativeSelection?.isCollapsed &&
      rootElement?.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner: Element | null = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange?.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.id !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor, getSelectedNode]);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setLinkUrl(event.target.value);
  };

  const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (lastSelection !== null) {
        if (linkUrl !== '') {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
        }
        setEditMode(false);
      }
    } else if (event.key === 'Escape') {
      event.preventDefault();
      setEditMode(false);
    }
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority,
      ),
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <LinkEditorContainer ref={editorRef}>
      {isEditMode ? (
        <LinkInput
          ref={inputRef}
          id='link-input'
          value={linkUrl}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
        />
      ) : (
        <LinkWrapper>
          <Link href={linkUrl} target='_blank' rel='noopener noreferrer'>
            {linkUrl}
          </Link>
          <LinkEditButton
            role='button'
            tabIndex={0}
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => {
              setEditMode(true);
            }}
          >
            <Icon icon='PENCIL_FILL' />
          </LinkEditButton>
        </LinkWrapper>
      )}
    </LinkEditorContainer>
  );
}
export default FloatingLinkEditor;

import React, {useState} from 'react';
import {Dropdown, Form, MenuProps, Modal} from 'antd';

import ConfirmationTitle from 'view/components/ConfirmationTitle';
import Icon from 'view/components/Icon';
import AdminEditCompanyUser from 'view/common/MultiModal/components/AdminEditCompanyUser/AdminEditCompanyUser';
import AdminReassignCompanyUser from 'view/common/MultiModal/components/AdminReassignCompanyUser/AdminReassignCompanyUser';
import {useAppDispatch} from 'store/hooks';

import {
  editCorporateAccountCompanyUser,
  reassignCorporateAccountCompanyUser,
  unassignCorporateAccountCompanyUser,
} from 'store/admin/apiActions';
import {
  IEditCorporateAccountCompanyUserPayload,
  IReassignCorporateAccountCompanyUserPayload,
  ICorporateAccountTableSource,
} from 'store/admin/types';
import {
  COMPANY_USER_FIRST_NAME,
  COMPANY_USER_LAST_NAME,
  COMPANY_USER_PHONE,
  COMPANY_ID_FIELD_NAME,
} from 'constants/admin';

const {confirm} = Modal;

type RowMenuProps = {
  data: ICorporateAccountTableSource;
};

const RowMenu: React.FC<RowMenuProps> = ({data}) => {
  const [isEditCompanyUserVisible, setIsEditCompanyUserVisible] =
    useState(false);
  const [isReassignCompanyUserVisible, setIsReassignCompanyUserVisible] =
    useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const {corporateAccountId, userId} = data;

  const editUser = async () => {
    try {
      await form.validateFields([
        COMPANY_USER_FIRST_NAME,
        COMPANY_USER_LAST_NAME,
        COMPANY_USER_PHONE,
      ]);
      const values = form.getFieldsValue();
      const bodyData: IEditCorporateAccountCompanyUserPayload = {
        firstName: values[COMPANY_USER_FIRST_NAME],
        lastName: values[COMPANY_USER_LAST_NAME],
        phone: values[COMPANY_USER_PHONE],
        accountId: corporateAccountId, // company id
        userId,
      };
      dispatch(editCorporateAccountCompanyUser(bodyData));
      setIsEditCompanyUserVisible(false);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const reassignUser = async () => {
    try {
      await form.validateFields([COMPANY_ID_FIELD_NAME]);
      const values = form.getFieldsValue();
      const payload: IReassignCorporateAccountCompanyUserPayload = {
        userId,
        currentAccountId: corporateAccountId,
        newAccountId: values[COMPANY_ID_FIELD_NAME],
      };
      dispatch(reassignCorporateAccountCompanyUser(payload));
      setIsReassignCompanyUserVisible(false);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const unassignUser = () => {
    dispatch(unassignCorporateAccountCompanyUser({corporateAccountId, userId}));
  };

  const showUnassignUserConfirm = () => {
    confirm({
      title: (
        <ConfirmationTitle icon='WARN' warn text='Deleting selected user' />
      ),
      content: 'Please note that you cannot revert this action once applied.',
      okText: 'Confirm',
      okButtonProps: {
        danger: true,
        type: 'default',
      },
      onOk: unassignUser,
      cancelText: 'Close',
      cancelButtonProps: {
        type: 'link',
      },
      icon: null,
    });
  };

  const items = [
    {label: 'Edit', key: '1'},
    {label: 'Re-assign', key: '2'},
    {label: 'Remove', key: '3'},
  ];
  const onClick: MenuProps['onClick'] = ({key}) => {
    if (key === '1') {
      setIsEditCompanyUserVisible(true);
    } else if (key === '2') {
      setIsReassignCompanyUserVisible(true);
    } else if (key === '3') {
      showUnassignUserConfirm();
    }
  };

  const modalEditCompanyUserProps = {
    title: 'Edit selected employee details',
    okText: 'Update',
    cancelText: 'Cancel',
    onOk: editUser,
    visible: isEditCompanyUserVisible,
    onCancel: () => setIsEditCompanyUserVisible(false),
    closable: true,
    destroyOnClose: false,
    forceRender: true,
  };

  const modalReAssignCompanyUserProps = {
    title: `Re-assign user ${userId} to`,
    okText: 'Assign',
    onOk: reassignUser,
    cancelText: 'Cancel',
    visible: isReassignCompanyUserVisible,
    onCancel: () => setIsReassignCompanyUserVisible(false),
    closable: true,
    destroyOnClose: false,
    forceRender: true,
  };

  return (
    <>
      <Dropdown menu={{items, onClick}} trigger={['click']}>
        <Icon icon='MORE_VERTICAL' primary />
      </Dropdown>
      <Modal {...modalEditCompanyUserProps}>
        <AdminEditCompanyUser form={form} initialValues={data} />
      </Modal>
      <Modal {...modalReAssignCompanyUserProps}>
        <AdminReassignCompanyUser form={form} companies={data.companies} />
      </Modal>
    </>
  );
};

export default React.memo(RowMenu);

import {
  ICancellationPolicyConditionPayload,
  IDynamicCancellationPolicy,
  IDynamicCancellationPolicyPayload,
  TCancellationPolicyConditions,
} from 'types/dto/ICancellationPolicy.type';
import {AppDispatch, RootState} from 'store/types';
import {TContainer} from 'app';
import {encodePlusCharacterInEmail} from 'utils/stringUtils';
import {
  getCancellationPolicyConditionSuccess,
  getDynamicCancellationPolicySuccess,
  setCancellationPolicyConditionLoading,
  setDynamicCancellationPolicyLoading,
} from 'store/cancellationPolicy/cancellationPolicySlice';

export const getCancellationPolicyCondition =
  (payload: ICancellationPolicyConditionPayload) =>
  async (
    dispatch: AppDispatch,
    _: () => RootState,
    {bookingsContainer}: TContainer,
  ) => {
    dispatch(setCancellationPolicyConditionLoading(true));

    await bookingsContainer.getCancellationPolicyConditions({
      payload,
      onSuccess: (data: TCancellationPolicyConditions) =>
        dispatch(getCancellationPolicyConditionSuccess(data)),
      onError: () => dispatch(setCancellationPolicyConditionLoading(false)),
    });
  };

export const getDynamicCancellationPolicy =
  (payload: IDynamicCancellationPolicyPayload) =>
  async (
    dispatch: AppDispatch,
    _: () => RootState,
    {bookingsContainer}: TContainer,
  ) => {
    const params = (
      Object.keys(payload) as Array<keyof IDynamicCancellationPolicyPayload>
    ).reduce((acc, param) => {
      if (payload[param] !== undefined) {
        if (param === 'checkIn') {
          acc[param] = encodePlusCharacterInEmail(payload[param] as string);
        } else {
          acc[param] = payload[param];
        }
      }

      return acc;
    }, {} as any) as IDynamicCancellationPolicyPayload;

    dispatch(setDynamicCancellationPolicyLoading(true));
    await bookingsContainer.getDynamicCancellationPolicy({
      payload: params,
      onSuccess: (data: IDynamicCancellationPolicy) =>
        dispatch(getDynamicCancellationPolicySuccess(data)),
      onError: () => dispatch(setDynamicCancellationPolicyLoading(false)),
    });
  };

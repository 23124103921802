import React from 'react';

import {ERoomType} from 'types/dto/ERoomType.type';
import {TRoomDetails} from 'types/venue';
import {Container, SpaceInfoItem, Title} from './SpaceItem.styles';
import {pluralize} from 'utils/helpers';
import {spaceTexts} from '../../types';

interface ISpaceInfoProps {
  units: TRoomDetails[];
  type: ERoomType;
}

export const SpaceInfo = ({units, type}: ISpaceInfoProps) => {
  const title = spaceTexts[type]?.title || '';
  const capacityItems = spaceTexts[type]?.description || '';

  return (
    <Container>
      <Title>
        <div>{title}</div>
        <div>capacity</div>
      </Title>
      {units.map(({name, capacityMin, capacityMax}) => {
        const capacity =
          capacityMin === capacityMax
            ? capacityMin
            : `${capacityMin}-${capacityMax}`;

        const pluralizedDescription = pluralize(
          capacityItems || '',
          +capacity,
          false,
        );

        return (
          <SpaceInfoItem key={name}>
            <div data-testid={`${type}-details-name`}>{name || '-'}</div>
            <div data-testid={`${type}-details-count`}>
              {capacity ? `${capacity} ${pluralizedDescription}` : '-'}
            </div>
          </SpaceInfoItem>
        );
      })}
    </Container>
  );
};

import {useEffect, useState} from 'react';

export function useGoogleTranslationStatus() {
  const [isTranslated, setIsTranslated] = useState(false);

  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')[0];
    const checkTranslation = () =>
      setIsTranslated(htmlElement.classList.contains('translated-ltr'));

    // check status on initialization
    checkTranslation();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          checkTranslation();
        }
      });
    });

    observer.observe(htmlElement, {attributes: true});

    // clear observer on unmount
    return () => observer.disconnect();
  }, []);

  return isTranslated;
}

import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {
  fontSizeDefault,
  fontSizeXSm,
  offsetSm,
  offsetLg,
  offsetDef,
  NW2Gray900Color,
  fontSizeMd,
} from 'constants/styleVars';
import {getColorFromProps, TColorProps} from '../Typography/Typography';
import {TSize} from 'types/app';
import {TIconPropsType} from 'view/components/Icon/types';

const CustomListContainer = styled.ul<{
  size?: TSize;
  color?: string;
  listStyle?: string;
  offset?: string;
  offsetLeft?: string;
  offsetItemBottom?: string;
  listColumns?: number;
}>`
  padding-left: ${({listStyle, offsetLeft}) =>
    listStyle === 'unordered' || listStyle === 'ordered'
      ? offsetLeft
        ? offsetLeft
        : offsetLg
      : '0'};
  list-style: none;
  margin-bottom: 0;
  text-align: left;
  columns: ${({listColumns}) => listColumns || 1};

  & li {
    position: relative;
    font-size: ${({size}) =>
      size === 'small'
        ? fontSizeXSm
        : size === 'large'
        ? fontSizeMd
        : fontSizeDefault};
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: ${({offsetItemBottom}) =>
      offsetItemBottom ? offsetItemBottom : 0};

    p {
      margin-bottom: 0;
    }

    ${({listStyle, size}) =>
      listStyle === 'unordered' &&
      `
          &:before {
          content: '';
          position: absolute;
          top: 9px;
          left: -${size === 'large' ? offsetDef : offsetSm};
          width:  ${size === 'large' ? '6px' : '4px'};
          height:  ${size === 'large' ? '6px' : '4px'};
          background: ${NW2Gray900Color};
          border-radius: 50%;
        }
        `};

    ${({listStyle}) =>
      listStyle === 'ordered' &&
      `
      counter-increment: li;
          &:before {
          content: counter(li)".";
          position: absolute;
          top: 0;
          left: -20px;
          border-radius: 50%;
        }
        `};

    ${({listStyle}) =>
      listStyle === 'icon' &&
      `
        & + li {
          margin-top: ${offsetSm}
        }
        display: flex;
        align-items: flex-start;
        
        &:before {
          content: none;
        }
        `};

    ${({offset}) =>
      offset
        ? `& + li {
          margin-top: ${offset}
        }`
        : ''}
    color: ${(props) => getColorFromProps(props)};
  }
`;

const Value = styled.span`
  flex-grow: 1;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${offsetSm};
`;

type TListStyle = 'unordered' | 'ordered' | 'noStyles' | 'icon';

type TProps = TColorProps & {
  list: Record<string, any>[];
  size?: TSize;
  listStyle?: TListStyle;
  iconStyle?: Partial<TIconPropsType>;
  offset?: string;
  offsetLeft?: string;
  offsetItemBottom?: string;
  listColumns?: number;
};

const CustomList = ({
  list,
  size,
  listStyle = 'unordered',
  iconStyle,
  ...rest
}: TProps) => {
  return (
    <CustomListContainer size={size} listStyle={listStyle} {...rest}>
      {list.map(({value, icon}, index) => {
        if (!value) return;
        return (
          <li key={`${value}${index}`}>
            {icon && <StyledIcon icon={icon} {...iconStyle} />}
            <Value>{value}</Value>
          </li>
        );
      })}
    </CustomListContainer>
  );
};

export default CustomList;

import {useCallback, useState} from 'react';

import {Routes} from 'constants/routes';
import {useLoggedInUser} from 'hooks/useLoggedInUser';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'store/hooks';
import {getHMDRegistrationLink} from 'store/venues/apiActions';
import DateUtils from 'utils/dateUtils';

interface IProps {
  token: string;
  accommodationId: number;
  checkIn: string;
  checkOut: string;
  requestId: string;
}

export const useHMDRegistrationLink = ({
  token,
  accommodationId,
  checkIn,
  checkOut,
  requestId,
}: IProps) => {
  const {isLoggedInUser} = useLoggedInUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isHmdLinkChecked, setHmdLinkChecked] = useState(false);

  const getHMDLink = useCallback(() => {
    if (isHmdLinkChecked) return;

    const isMultiRooms = !DateUtils.isSameDay(checkIn, checkOut);

    const onError = () => {
      if (isLoggedInUser) {
        navigate(`${Routes.bookingsListRooms}?token=${token}`);
      } else {
        navigate(`${Routes.login}?token=${token}`);
      }

      setHmdLinkChecked(true);
    };

    dispatch(
      getHMDRegistrationLink({
        accommodationId,
        isMultiRooms,
        requestId,
        onError,
        onGetLinkCallback: () => {
          setHmdLinkChecked(true);
        },
      }),
    );
  }, [
    accommodationId,
    checkIn,
    checkOut,
    dispatch,
    isHmdLinkChecked,
    isLoggedInUser,
    navigate,
    requestId,
    token,
  ]);

  return {getHMDLink, isHmdLinkChecked};
};

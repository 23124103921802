import React from 'react';
import {Country} from 'country-state-city';
import {Select, Form} from 'antd';
import {getFilteredOption} from 'view/venue/CreateVenue/helpers';

interface CountrySelectProps {
  name: string;
  label: string;
  rules: any[];
  placeholder: string;
  onChange: (value: string) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = ({
  name,
  label,
  rules,
  placeholder,
  onChange,
}) => {
  const countriesList = Country.getAllCountries();

  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Select
        showSearch
        placeholder={placeholder}
        onChange={onChange}
        filterOption={getFilteredOption}
      >
        {countriesList.map(({isoCode, name}) => (
          <Select.Option key={isoCode} value={isoCode}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CountrySelect;

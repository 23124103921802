import React from 'react';

import Icon from 'view/components/Icon';
import ModalOverlay from 'view/components/ModalOverlay';

import {
  MainWrapper,
  Header,
  ColumnsWrapper,
  Title,
  LeftColumn,
  Footer,
  Separator,
  RightColumn,
  StyledNW2Button,
} from './NW2ModalMeetingsTwoColumns.styles';
import {INW2ModalMeetingsTwoColumnsContentProps} from './types';

const Content = ({
  title,
  footer,
  colLeft,
  colRight,
  onToggle,
}: INW2ModalMeetingsTwoColumnsContentProps) => {
  return (
    <ModalOverlay>
      <MainWrapper isHasFooter={!!footer}>
        <Header>
          <StyledNW2Button
            buttonType='secondary'
            minimized
            size='small'
            onClick={onToggle}
            icon={<Icon transparent icon='NW2_MOVE_BACK' />}
          />
          <Title>{title}</Title>
        </Header>
        <ColumnsWrapper>
          <LeftColumn>{colLeft}</LeftColumn>
          <Separator />
          <RightColumn>{colRight}</RightColumn>
        </ColumnsWrapper>
        {!!footer && <Footer>{footer}</Footer>}
      </MainWrapper>
    </ModalOverlay>
  );
};

export default Content;

import React from 'react';

import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {getAddressStringFromLocation} from 'utils/stringUtils';
import {SummaryVenueSection} from '../VenueSummarySection/VenueSummarySection';
import {usePreloadImages} from 'view/venue/components/NW2BookingSummary/hooks';
import {
  Wrapper,
  Title,
  ImageCircle,
  StyledAccordionItem,
  MobileHeaderTitle,
  VenuesImage,
} from './MultiVenueHeader.style';
import {TShortListItem} from 'types/offer';
import {searchVenuePlaceholderImage} from 'constants/venue';

interface IHeaderTitleProps {
  multiVenueIds: number[];
  multiRequestHeader: TShortListItem[];
}
const HeaderTitle = ({
  multiVenueIds,
  multiRequestHeader,
}: IHeaderTitleProps) => {
  const venueImageUrls = multiVenueIds.map(
    (venueId) =>
      multiRequestHeader.find(({id}) => id === venueId)?.img ||
      searchVenuePlaceholderImage,
  );

  const isImagesLoaded = usePreloadImages(venueImageUrls);
  return (
    <Wrapper>
      {isImagesLoaded ? (
        <VenuesImage>
          {venueImageUrls.map((img, index) => (
            <ImageCircle key={index} src={img} alt='venue preview' />
          ))}
        </VenuesImage>
      ) : (
        <div>Loading...</div>
      )}
      <Title>{`${multiVenueIds.length} venues selected`}</Title>
    </Wrapper>
  );
};

interface IProps {
  multiVenueIds: number[];
  multiRequestHeader: TShortListItem[];
  theme: EBookingSummaryTheme;
  isExpandable?: boolean;
}
export const MultiVenueHeader = ({
  multiVenueIds,
  theme,
  isExpandable,
  multiRequestHeader,
}: IProps) => {
  return (
    <>
      {isExpandable ? (
        <StyledAccordionItem
          title={
            <HeaderTitle
              multiVenueIds={multiVenueIds}
              multiRequestHeader={multiRequestHeader}
            />
          }
          content={multiVenueIds.map((venueId) => {
            const currentVenue = multiRequestHeader.filter(
              ({id}) => id === venueId,
            )[0];
            if (!currentVenue) return null;

            const venueAddress = getAddressStringFromLocation(
              currentVenue?.location,
            );

            return (
              <SummaryVenueSection
                key={venueId}
                theme={theme}
                venueAddress={venueAddress}
                venueCoverImage={currentVenue?.img}
                venueName={currentVenue?.name}
                isMultiRequest
              />
            );
          })}
          initiallyExpanded={false}
        />
      ) : (
        <MobileHeaderTitle>
          <HeaderTitle
            multiVenueIds={multiVenueIds}
            multiRequestHeader={multiRequestHeader}
          />
        </MobileHeaderTitle>
      )}
    </>
  );
};

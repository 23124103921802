export enum EUnitBookingStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  FULLY_BOOKED = 'FULLY_BOOKED',
  NONE = '',
}

interface IUnitBookingStatisticsUnitType {
  description: string;
  id: number;
  name: string;
}

interface IUnitBookingStatistics {
  bookedPlace: number;
  id: number;
  quantity: number;
  unitType: IUnitBookingStatisticsUnitType;
}

export interface IVenueUnitBookingStatisticsItem {
  date: string;
  pastDate: boolean;
  unitBookingStatistics: IUnitBookingStatistics[];
  workedStatus: EUnitBookingStatus;
}

export type TVenueUnitBookingStatistics = {
  [key: string]: IVenueUnitBookingStatisticsItem;
};

export interface IGetVenueUnitBookingStatisticsByDatesPayload {
  requestDate: string;
  venueId: number;
}

export interface IGetVenueUnitBookingStatisticsByDatesRequestData {
  from: string;
  to: string;
  venueId: number;
}

export interface ISetVenueUnitBookingStatusPayloadData {
  date: string;
  workedStatus: EUnitBookingStatus;
}

export interface ISetVenueUnitBookingStatusPayload {
  venueId: number;
  payload: ISetVenueUnitBookingStatusPayloadData;
}

import React from 'react';
import styled from 'styled-components';
import _chunk from 'lodash/chunk';

import {NW2FormItemCheckbox} from 'view/components/NW2FormItem/components';

import {IAmenity} from 'types/dto/IAmenity';
import {ECheckboxSizes} from 'view/components/NW2FormItem/components/NW2FormItemCheckbox/types';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';
import {SectionCheckboxItem} from '../CommonAmenitiesInfoForm/CommonAmenities.styles';
import {allAmenitiesCategories, TRoomAmenitiesCategories} from '../../types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {offsetXLg} from 'constants/styleVars';
import {AMENITIES_COLUMN_WIDTH} from 'constants/app';
import {EAmenityType} from 'types/amenities';

const ItemContainer = styled.div<{columns: number}>`
  display: grid;
  grid-template-columns: repeat(
    ${({columns}) => columns},
    minmax(auto, ${AMENITIES_COLUMN_WIDTH})
  );
  grid-column-gap: ${offsetXLg};
`;

interface IProps {
  isSending?: boolean;
  isTitleShowed?: boolean;
  spaceAmenities: IAmenity[];
  fieldName?: string;
  items?: EAmenityType[];
  titleColor?: string;
  roomType: ERoomType;
  chunkSize?: number;
}

export function useSortedUnitAmenities({
  items = [],
  fieldName = 'amenities',
  isSending,
  isTitleShowed,
  spaceAmenities,
  titleColor,
  roomType,
  chunkSize = 4,
}: IProps) {
  const allAmenities = Object.entries(EAmenityType).filter(([key]) =>
    items.includes(key as EAmenityType),
  );

  return allAmenities.map(([key, label], index) => {
    const typeAmenities = spaceAmenities.filter(({type}) => type === key);
    const getColumnArray = (chunkSize: number) =>
      _chunk(typeAmenities, chunkSize);

    const columnArray = getColumnArray(chunkSize);
    const columnsCount = Math.ceil(typeAmenities.length / chunkSize);

    return (
      <div key={fieldName + key + index}>
        {isTitleShowed && (
          <SectionSubTitle titleColor={titleColor}>
            {allAmenitiesCategories[label as TRoomAmenitiesCategories]}
          </SectionSubTitle>
        )}

        <ItemContainer columns={columnsCount} key={fieldName + key + index}>
          {roomType === ERoomType.WORK_SPACE &&
            columnArray.map((amenityArray, index) => (
              <div key={fieldName + key + index}>
                {amenityArray.map(({name, id}) => (
                  <SectionCheckboxItem key={fieldName + id}>
                    <NW2FormItemCheckbox
                      name={fieldName}
                      label={name}
                      value={id}
                      id={`${fieldName} - ${id}`}
                      disabled={isSending}
                      size={ECheckboxSizes.LARGE}
                      data-testid={`${fieldName}-${id}-checkbox`}
                      noErrorMessage
                    />
                  </SectionCheckboxItem>
                ))}
              </div>
            ))}
        </ItemContainer>

        {roomType === ERoomType.MEETING_ROOM &&
          typeAmenities.map((amenity) => (
            <SectionCheckboxItem key={fieldName + amenity.id}>
              <NW2FormItemCheckbox
                name={fieldName}
                label={amenity.name}
                value={amenity.id}
                id={`${fieldName} - ${amenity.id}`}
                disabled={isSending}
                size={ECheckboxSizes.LARGE}
                data-testid={`${fieldName}-${amenity.id}-checkbox`}
                noErrorMessage
              />
            </SectionCheckboxItem>
          ))}
      </div>
    );
  });
}

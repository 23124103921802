import styled from 'styled-components';

import {
  offsetXSm,
  offsetXLg,
  fontSizeMd,
  lineHeightLg,
} from 'constants/styleVars';

export const List = styled.ul`
  padding-left: ${offsetXLg};
`;

export const Li = styled.li`
  margin-bottom: ${offsetXSm};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
`;

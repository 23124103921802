import {TLayout} from 'view/common/Header/types';

const getGuestLayout = (): TLayout => {
  return {
    type: 'GuestLayout',
    components: [
      {component: 'isLogo'},
      {component: 'isGuestNavList'},
      {component: 'isLoginRegisterBlock'},
    ],
  };
};

export {getGuestLayout};

import styled from 'styled-components';

import {
  fontSizeXLg,
  fontWeightSemiBold,
  lineHeightXLg,
  offsetXLg,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

const BORDER_COLOR = '#e8e8e8';
const WRAPPER_WIDTH = '600px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXLg};
`;

export const UploaderContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: ${offsetXLg} ${offsetXLg};

  background-color: ${whiteColor};
  border: 1px solid ${BORDER_COLOR};
  border-radius: ${offsetXXSm};
`;

export const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: ${WRAPPER_WIDTH};
`;

export const UploaderTitle = styled.div<{withoutFile?: boolean}>`
  font-size: ${fontSizeXLg};
  line-height: ${lineHeightXLg};
  font-weight: ${fontWeightSemiBold};
  margin: 0 auto ${offsetXLg};
`;

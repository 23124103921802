import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Dropdown} from 'antd';

import NW2Button from 'view/components/NW2Button';
import {OverlayMenu} from './components/OverlayMenu';

import {EMPTY_OBJECT} from 'constants/app';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {IUser} from 'types/dto/IUser.types';

import {offsetXLg} from 'constants/styleVars';
import {AvatarContainer, MenuIcon} from 'view/common/Header/Header.styles';
import {AvatarInnerBlock} from 'view/components/NW2MenuItem/NW2MenuItem.styles';

const StyledAvatarInnerBlock = styled(AvatarInnerBlock)`
  margin-left: ${offsetXLg};
`;

type TProps = {
  role?: string;
  user?: IUser;
};

function HeaderUserMenu({role, user}: TProps) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const isCustomer = role === EUserRoleCognito.ROLE_CUSTOMER;

  const {firstName, lastName} = user || (EMPTY_OBJECT as IUser);
  const customersInitials = useMemo(() => {
    if (!firstName || !lastName) return '';

    return firstName.substring(0, 1) + lastName.substring(0, 1).toUpperCase();
  }, [firstName, lastName]);

  const overlayMenu = () => (
    <OverlayMenu isCustomer={isCustomer} setIsMenuVisible={setIsMenuVisible} />
  );

  return (
    <AvatarContainer>
      <Dropdown
        dropdownRender={overlayMenu}
        trigger={['click']}
        open={isMenuVisible}
        onOpenChange={setIsMenuVisible}
      >
        <StyledAvatarInnerBlock id='headerUserMenuAvatarBlock'>
          <MenuIcon icon='USER' size={24} />
          {isCustomer && (
            <NW2Button buttonType='link'>{customersInitials}</NW2Button>
          )}
        </StyledAvatarInnerBlock>
      </Dropdown>
    </AvatarContainer>
  );
}

export default React.memo(HeaderUserMenu);

import React from 'react';

import NWTag from 'view/components/NWTag';

import {IBooking} from 'types/dto/IBooking.types';
import {EBookingStatus} from 'types/booking';

const tagColor: Record<
  string,
  Record<string, string | Record<string, string> | boolean>
> = {
  [EBookingStatus.CANCELED]: {
    disabled: true,
  },
  [EBookingStatus.CONFIRMED]: {
    success: true,
  },
};

export function BookingStatusColumn(
  text: string,
  record: IBooking,
  index: number,
) {
  return (
    <NWTag key={index} {...tagColor[record.status]}>
      {record.status.replace('_', ' ')}
    </NWTag>
  );
}

import {
  borderRadiusDef,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';
import styled, {css} from 'styled-components';

export const Wrapper = styled.div<{columns: number}>`
  display: grid;
  grid-template-columns: ${({columns}) =>
    columns && `repeat(${columns}, minmax(auto, 326px))`};
  grid-auto-columns: min-content;
  grid-column-gap: ${offsetXLg};
  grid-row-gap: ${offsetXXLg};
  justify-items: start;
  align-items: center;

  ${({columns}) =>
    columns === 3 &&
    css`
      > div {
        &:last-child {
          grid-column-start: 2;
          grid-column-end: 3;
        }
      }
    `};

  @media (max-width: 991px) {
    grid-template-columns: 326px;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 326px;
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusDef};
`;

export const ContentBlock = styled.div`
  width: 100%;
  padding: ${offsetDef};
`;

export const Capacity = styled.ul`
  margin-top: 8px;
  padding-left: 20px;
  white-space: nowrap;
`;

import styled, {css} from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {Link} from 'view/components/Typography';

import {
  offsetLg,
  offsetSm,
  offsetXLg,
  NW2Gray200Color,
  NW2Gray900Color,
  offsetDef,
  lineHeightMd,
  offsetXSm,
  xsBp,
  mdBp,
  fontSizeLg,
  fontWeightBold,
  pagePadding,
  offsetXXXSm,
} from 'constants/styleVars';

const MAX_WIDTH = '845px';

export const PaginationContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${pagePadding};
`;

export const StyledLink = styled(Link)`
  color: ${NW2Gray900Color};
  display: flex;
  width: fit-content;
`;

export const StyledButton = styled(NW2Button)`
  margin-bottom: ${offsetSm};

  &:first-of-type {
    margin-right: ${offsetLg};

    @media (max-width: ${xsBp}px),
      (min-width: ${mdBp}px) and (max-width: ${MAX_WIDTH}) {
      margin-right: 0;
    }
  }
`;

export const NoFoundBlockWrapper = styled.div<{noBorder?: boolean}>`
  margin-top: ${offsetXLg};
  padding-bottom: ${offsetXLg};
  ${({noBorder}) => !noBorder && `border-bottom: 1px solid ${NW2Gray200Color}`};
`;

export const StyledListHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: ${offsetDef};
  padding-top: ${pagePadding};
`;

export const ListHeaderInner = styled.div<{textAlign?: string}>`
  text-align: ${({textAlign}) => textAlign || 'left'};
  line-height: 1.2;
`;

export const StyledListHeaderTitleCount = styled.div`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightMd};
  margin-bottom: ${offsetXXXSm};
`;

export const VenuesGridContainer = styled.div<{isPublicVenue?: boolean}>`
  display: grid;
  grid-gap: ${offsetXLg};

  ${({isPublicVenue}) =>
    isPublicVenue &&
    css`
      [id^='venue-'] {
        padding-bottom: ${offsetXLg};
        border-bottom: 1px solid ${NW2Gray200Color};

        &:last-of-type {
          border-bottom: 0;
          padding-bottom: 0;
        }
      }
    `}
`;

export const VenuesSection = styled.section`
  padding-bottom: ${pagePadding};

  & + & {
    border-top: 1px solid ${NW2Gray200Color};
    padding-top: ${pagePadding};
  }
`;

export const TextWrapper = styled.div`
  display: grid;
  grid-template-columns: 7fr 1fr;
  gap: ${offsetXSm};
`;

import React, {useCallback} from 'react';

import TextRow from 'view/components/TextRow';
import TextValue from 'view/components/TextValue';

import {EDays} from 'constants/days';
import {IDay, IDayOfWeek} from 'types/dto/IDay.type';
import {capitalizeText} from 'utils/stringUtils';

import {fontSizeMd, lineHeightLg, NW2Gray800Color} from 'constants/styleVars';
import {RowContainer} from './OpenHoursInfo.styles';
import {useAppSelector} from 'store/hooks';
import {
  getDayWorkingHours,
  isDayActive,
  isFullDayAccess,
} from 'utils/operationalHoursUtils';

interface Props {
  workDays: IDay[];
}
const OperationalHoursInfo = ({workDays}: Props) => {
  const locale = useAppSelector(({app}) => app.locale);

  const getValue = useCallback(
    (day: IDayOfWeek) => {
      const active = isDayActive(workDays, day);
      const fullDayAccess = isFullDayAccess(workDays, day);

      return getDayWorkingHours({day, active, fullDayAccess, workDays, locale});
    },
    [locale, workDays],
  );

  return (
    <div id='operationalHoursInfo'>
      {Object.values(EDays).map((day: IDayOfWeek) => {
        const capitalizedValue = capitalizeText(day);

        return (
          <RowContainer key={day}>
            <TextRow
              label={capitalizedValue}
              value={
                <TextValue
                  fontSize={fontSizeMd}
                  lineHeight={lineHeightLg}
                  color={NW2Gray800Color}
                  marginTop='0'
                >
                  {getValue(day)}
                </TextValue>
              }
              valueId={`operationalHoursInfo${capitalizedValue}`}
              fontSize={fontSizeMd}
              lineHeight={lineHeightLg}
              color={NW2Gray800Color}
            />
          </RowContainer>
        );
      })}
    </div>
  );
};

export default OperationalHoursInfo;

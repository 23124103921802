import styled from 'styled-components';
import {Button} from 'antd';

import {
  borderColor,
  offsetDef,
  offsetSm,
  offsetXXXLg,
} from 'constants/styleVars';

export const BlockTotal = styled.div`
  text-align: left;
  margin-bottom: ${offsetXXXLg};
`;

export const DetailsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
  grid-gap: ${offsetDef};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${offsetSm};
  border-top: 1px solid ${borderColor};
`;

export const StyledFilterButton = styled(Button)`
  width: 75px;
  margin-left: ${offsetSm};
`;

export const CheckboxContainer = styled.div`
  padding: ${offsetSm};
  max-width: 200px;
  display: flex;
  flex-direction: column;
`;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Form, FormSpy} from 'react-final-form';
import getSymbolFromCurrency from 'currency-symbol-map';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import {notification} from 'antd';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import SpaceDetailsWipSection from './SpaceDetailsWipSection';
import useUpdateSpace from 'view/venue/hooks/useUpdateSpace';

import {useSpaceSections} from '../hooks/useSpaceSections';
import {useLocalAmenities} from './SpaceAmenities/hooks/useLocalAmenities';
import {useInitialFormValues} from '../hooks/useInitialFormValues';
import {getRoomSchemaForSubmit} from './RoomSetup/helpers';
import {setSpaceDetailsId} from 'store/venues/venuesSlice';
import {getWorkInProgress} from 'store/venue/actions';
import {removeExtension} from 'utils/helpers';
import {getTypeByName} from '../utils/helpers';

import {
  EBookAbility,
  ISpacesDataItem,
  ISpacesDataItemImage,
  TVenueFloors,
} from 'types/venue';
import {ESpaceDetailsContentSections, ESpaceDetailsFormFields} from '../types';
import {MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';
import {
  floorLevels,
  getArrayOfFloorNames,
  getValueByKey,
} from 'constants/floorStorey';
import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {EPricesFormFields} from './Price/PricesForm.types';
import {EDimensionsFormFields} from './Dimensions/DimensionsForm.types';
import {EAddMeetingRoomFormFields} from 'view/venue/NW2VenueProfile/components/AddSpace/types';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {EDIT_SPACE_FORM_WIP} from '../NMMSpacesDetails';
import {
  AnchorSections,
  AnchorsWrapper,
} from 'view/components/SupplierLayout/SupplierLayout.styles';
import {StyledContainer} from '../NMMSpacesDetails.styles';
import {ERoomType} from 'types/dto/ERoomType.type';

type TProps = {
  errorSections: Set<ESpaceDetailsContentSections>;
  space: ISpacesDataItem;
  isExternalVenue: boolean;
  floors: TVenueFloors;
  handleCheckSectionFields: (
    name: ESpaceDetailsContentSections,
    condition: boolean,
  ) => void;
};

const SpaceDetailsWipEditForm = ({
  errorSections,
  space,
  floors,
  isExternalVenue,
  handleCheckSectionFields,
}: TProps) => {
  const dispatch = useAppDispatch();

  const currency: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.currency`),
  );
  const venueId = useAppSelector(
    ({venueDetails}) => venueDetails.supplierVenueId,
  );
  const currencySymbol = getSymbolFromCurrency(currency);

  const {documents, roomType} = space;

  const spaceSections = useSpaceSections({isExternalVenue, roomType});

  const roomAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.roomAmenities'),
  );
  const deskAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.deskAmenities'),
  );
  const spaceAmenities = useMemo(
    () => (roomType === ERoomType.MEETING_ROOM ? roomAmenities : deskAmenities),
    [deskAmenities, roomAmenities, roomType],
  );
  const [amenitiesLocal] = useLocalAmenities({
    spaceAmenities,
    roomType,
  });

  const {updateSpace} = useUpdateSpace({
    space,
    endHandler: () => {},
    onSuccessCallback: () => {
      dispatch(getWorkInProgress(venueId));
      dispatch(setSpaceDetailsId(null));
      notification.success({
        message:
          roomType === ERoomType.MEETING_ROOM
            ? 'The meeting room has been updated'
            : 'The work desk has been updated',
      });
    },
  });

  // images
  const [imagesToUpload, setImagesToUpload] = useState<
    (TFileWithPreview | ISpacesDataItemImage)[]
  >([...documents]);
  const onImageUpload = useCallback((files: TFileWithPreview[]) => {
    setImagesToUpload(files);
  }, []);

  const onSubmit = useCallback(
    async (values: any) => {
      const checkedAmenities = [
        ...values[ESpaceDetailsContentSections.INCLUDED_IN_SPACE],
        ...values[ESpaceDetailsContentSections.CONDITIONS_AND_ACCESSIBILITY],
      ];

      const amenitiesMap = roomAmenities.filter(({id}) =>
        checkedAmenities.includes(id),
      );

      const {coverImageName, description, files, floor, name, type, capacity} =
        values;

      const typeName = getTypeByName(type);

      // already existed & updated images
      const updatedDocuments = documents.filter((document) =>
        files.some((f: ISpacesDataItemImage) => f.uid === document.uid),
      );

      // already added images
      const updatedFiles = values.files.filter(
        (f: TFileWithPreview) => f.preview,
      );

      const coverImgName = updatedFiles.find(
        (f: TFileWithPreview) => f.name === coverImageName,
      )
        ? removeExtension(coverImageName)
        : coverImageName;

      if (roomType === ERoomType.MEETING_ROOM) {
        const meetingRoomPayload = {
          name,
          description,
          floor: getValueByKey(floorLevels, floor),
          amenities: amenitiesMap,
          [EPricesFormFields.PRICE_HOUR]: Number(
            values[EPricesFormFields.PRICE_HOUR],
          ),
          [EPricesFormFields.PRICE_HALF_DAY]: Number(
            values[EPricesFormFields.PRICE_HALF_DAY],
          ),
          [EPricesFormFields.PRICE_DAY]: Number(
            values[EPricesFormFields.PRICE_DAY],
          ),
          [EPricesFormFields.PAID_DURATION]: parseInt(
            values[EPricesFormFields.PAID_DURATION],
          ),
          unitCapacities: getRoomSchemaForSubmit({
            [EAddMeetingRoomFormFields.CAPACITIES]:
              values[EAddMeetingRoomFormFields.CAPACITIES],
          }),
          unitDimensions: {
            [EDimensionsFormFields.AREA]: Number(
              values[EDimensionsFormFields.AREA],
            ),
            [EDimensionsFormFields.CEILING_HEIGHT]: Number(
              values[EDimensionsFormFields.CEILING_HEIGHT],
            ),
          },
          isRfp:
            values[ESpaceDetailsContentSections.BOOKABILITY_TYPE] ===
            EBookAbility.REQUEST,

          documents: updatedDocuments,
          files: updatedFiles,
          coverImageName: coverImgName,
        };

        updateSpace(meetingRoomPayload);
      }

      if (roomType === ERoomType.WORK_SPACE) {
        const workDeskPayload = {
          name,
          description,
          floor: getValueByKey(floorLevels, floor),
          amenities: amenitiesMap,
          [EPricesFormFields.PRICE_HOUR]: Number(
            values[EPricesFormFields.PRICE_HOUR],
          ),
          [EPricesFormFields.PRICE_DAY]: Number(
            values[EPricesFormFields.PRICE_DAY],
          ),
          isRfp:
            values[ESpaceDetailsContentSections.BOOKABILITY_TYPE] ===
            EBookAbility.REQUEST,
          type: typeName,
          capacity,
          documents: updatedDocuments,
          files: updatedFiles,
          coverImageName: coverImgName,
        };

        updateSpace(workDeskPayload);
      }
    },
    [documents, roomAmenities, roomType, updateSpace],
  );

  const {
    // Common
    initialNameAndFloor,
    initialDescription,
    initialAmenities,
    // Meeting rooms
    initialPrices,
    initialCapacities,
    initialDimensions,
    initialBookabilityType,
    // Work desks
    initialSpaceTypeAndCapacity,
    initialPricesWorkdesks,
  } = useInitialFormValues({space});

  const initialFormValues = useMemo(() => {
    if (roomType === ERoomType.WORK_SPACE) {
      return {
        ...initialNameAndFloor,
        ...initialDescription,
        ...initialAmenities,
        ...initialSpaceTypeAndCapacity,
        ...initialPricesWorkdesks,
        files: [],
      };
    }

    return {
      ...initialDescription,
      ...initialNameAndFloor,
      ...initialPrices,
      ...initialAmenities,
      ...initialCapacities,
      ...initialDimensions,
      ...initialBookabilityType,
      files: [],
    };
  }, [
    initialAmenities,
    initialBookabilityType,
    initialCapacities,
    initialDescription,
    initialDimensions,
    initialNameAndFloor,
    initialPrices,
    initialPricesWorkdesks,
    initialSpaceTypeAndCapacity,
    roomType,
  ]);

  const checkSections = useCallback(
    (values: any) => {
      const {
        capacities,
        name,
        floor,
        files,
        description,
        priceDay,
        priceHalfDay,
        priceHour,
        minimumPaidDuration,
        area,
        areaFeet,
        ceilingHeight,
        ceilingHeightFeet,
        type,
        capacity,
      } = values;

      if (roomType === ERoomType.MEETING_ROOM) {
        const sectionValidateConditions: {
          [key in ESpaceDetailsContentSections]?: boolean;
        } = {
          [ESpaceDetailsContentSections.NAME_AND_LOCATION]:
            !name || !floor || !getArrayOfFloorNames(floors).includes(floor),
          [ESpaceDetailsContentSections.IMAGES]:
            !documents.length && !files.length,
          [ESpaceDetailsContentSections.DESCRIPTION]: !description?.length,
          [ESpaceDetailsContentSections.PRICES]:
            !priceDay || !priceHalfDay || !priceHour || !minimumPaidDuration,
          [ESpaceDetailsContentSections.SETUPS_AND_CAPACITIES]: !Object.values(
            capacities,
          ).some((item: any) => item?.checkbox && item?.capacity),
          [ESpaceDetailsContentSections.DIMENSIONS]:
            !area || !areaFeet || !ceilingHeight || !ceilingHeightFeet,
          [ESpaceDetailsContentSections.BOOKABILITY_TYPE]:
            !values[ESpaceDetailsContentSections.BOOKABILITY_TYPE],
        };

        Object.entries(sectionValidateConditions).forEach(([key, value]) => {
          handleCheckSectionFields(key as ESpaceDetailsContentSections, value);
        });
      }

      if (roomType === ERoomType.WORK_SPACE) {
        const sectionValidateConditionsWorkDesks: {
          [key in ESpaceDetailsContentSections]?: boolean;
        } = {
          [ESpaceDetailsContentSections.NAME_AND_LOCATION]:
            !name || !floor || !getArrayOfFloorNames(floors).includes(floor),
          [ESpaceDetailsContentSections.SPACE_TYPE_AND_CAPACITY]:
            !type || !capacity || capacity === 0,
          [ESpaceDetailsContentSections.IMAGES]:
            !documents.length && !files.length,
          [ESpaceDetailsContentSections.DESCRIPTION]: !description?.length,
          [ESpaceDetailsContentSections.PRICES]: !priceDay || !priceHour,
        };

        Object.entries(sectionValidateConditionsWorkDesks).forEach(
          ([key, value]) => {
            handleCheckSectionFields(
              key as ESpaceDetailsContentSections,
              value,
            );
          },
        );
      }
    },
    [floors, documents.length, roomType, handleCheckSectionFields],
  );

  const [errorSection, setErrorSection] = useState('');

  useEffect(() => {
    if (!errorSection) return;

    const section = document.getElementById(errorSection);

    if (section) {
      section.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
  }, [errorSection]);

  const getErrorSection = useCallback((errorFields: string[]) => {
    switch (true) {
      case errorFields.includes(ESpaceDetailsFormFields.SPACE_NAME):
      case errorFields.includes(ESpaceDetailsFormFields.FLOOR):
        return ESpaceDetailsContentSections.NAME_AND_LOCATION;

      case errorFields.includes(ESpaceDetailsFormFields.TYPE):
      case errorFields.includes(ESpaceDetailsFormFields.CAPACITY):
        return ESpaceDetailsContentSections.SPACE_TYPE_AND_CAPACITY;

      case errorFields.includes(ESpaceDetailsFormFields.FILES):
        return ESpaceDetailsContentSections.IMAGES;

      case errorFields.includes(ESpaceDetailsFormFields.DESCRIPTION):
        return ESpaceDetailsContentSections.DESCRIPTION;

      case errorFields.includes(EPricesFormFields.PRICE_HOUR):
      case errorFields.includes(EPricesFormFields.PRICE_HALF_DAY):
      case errorFields.includes(EPricesFormFields.PRICE_DAY):
      case errorFields.includes(EPricesFormFields.PAID_DURATION):
        return ESpaceDetailsContentSections.PRICES;

      case errorFields.includes(EDimensionsFormFields.AREA):
      case errorFields.includes(EDimensionsFormFields.AREA_FEET):
      case errorFields.includes(EDimensionsFormFields.CEILING_HEIGHT):
      case errorFields.includes(EDimensionsFormFields.CEILING_HEIGHT_FEET):
        return ESpaceDetailsContentSections.DIMENSIONS;

      default:
        return '';
    }
  }, []);

  const getArrayOfSectionsWithErrors = useCallback((errorFields: string[]) => {
    const arrayOfSectionsWithErrors: ESpaceDetailsContentSections[] = [];

    if (
      errorFields.includes(ESpaceDetailsFormFields.SPACE_NAME) ||
      errorFields.includes(ESpaceDetailsFormFields.FLOOR)
    ) {
      arrayOfSectionsWithErrors.push(
        ESpaceDetailsContentSections.NAME_AND_LOCATION,
      );
    }

    if (
      errorFields.includes(ESpaceDetailsFormFields.TYPE) ||
      errorFields.includes(ESpaceDetailsFormFields.CAPACITY)
    ) {
      arrayOfSectionsWithErrors.push(
        ESpaceDetailsContentSections.SPACE_TYPE_AND_CAPACITY,
      );
    }

    if (errorFields.includes(ESpaceDetailsFormFields.FILES)) {
      arrayOfSectionsWithErrors.push(ESpaceDetailsContentSections.IMAGES);
    }

    if (errorFields.includes(ESpaceDetailsFormFields.DESCRIPTION)) {
      arrayOfSectionsWithErrors.push(ESpaceDetailsContentSections.DESCRIPTION);
    }

    if (
      errorFields.includes(EPricesFormFields.PRICE_HOUR) ||
      errorFields.includes(EPricesFormFields.PRICE_HALF_DAY) ||
      errorFields.includes(EPricesFormFields.PRICE_DAY) ||
      errorFields.includes(EPricesFormFields.PAID_DURATION)
    ) {
      arrayOfSectionsWithErrors.push(ESpaceDetailsContentSections.PRICES);
    }

    if (
      errorFields.includes(EDimensionsFormFields.AREA) ||
      errorFields.includes(EDimensionsFormFields.AREA_FEET) ||
      errorFields.includes(EDimensionsFormFields.CEILING_HEIGHT) ||
      errorFields.includes(EDimensionsFormFields.CEILING_HEIGHT_FEET)
    ) {
      arrayOfSectionsWithErrors.push(ESpaceDetailsContentSections.DIMENSIONS);
    }

    return arrayOfSectionsWithErrors;
  }, []);

  const [sectionsWithErrors, setSectionsWithErrors] = useState<string[]>();

  const processFormFields = _debounce(({values, errors, submitFailed}) => {
    checkSections(values);

    if (submitFailed) {
      const errorFields = Object.keys(errors);
      const errorSection = getErrorSection(errorFields);
      const arrayOfSectionsWithErrors =
        getArrayOfSectionsWithErrors(errorFields);

      setSectionsWithErrors(arrayOfSectionsWithErrors);
      setErrorSection(errorSection);
    }
  }, 150);

  return (
    <StyledContainer>
      <AnchorsWrapper>
        <AnchorSections>
          <Form
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            mutators={{
              setValue: ([field, value], state, {changeValue}) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({handleSubmit, form}) => (
              <form onSubmit={handleSubmit} id={EDIT_SPACE_FORM_WIP}>
                {spaceSections.map((section) => (
                  <SpaceDetailsWipSection
                    key={section}
                    space={space}
                    floors={floors}
                    sectionName={section}
                    roomType={roomType}
                    imagesToUpload={imagesToUpload}
                    onImageUpload={onImageUpload}
                    currencySymbol={currencySymbol}
                    onFormChange={form.change}
                    amenitiesLocal={amenitiesLocal}
                    errorSections={errorSections}
                  />
                ))}

                <FormSpy
                  subscription={{
                    values: true,
                    errors: true,
                    submitFailed: true,
                  }}
                  onChange={processFormFields}
                />
              </form>
            )}
          />
        </AnchorSections>

        <AnchorMenu
          rootId={MODAL_TWO_COLS_RIGHT_COL_ID}
          sections={spaceSections}
          highlightedSections={[...errorSections]}
          sectionsWithErrors={sectionsWithErrors}
        />
      </AnchorsWrapper>
    </StyledContainer>
  );
};

export default SpaceDetailsWipEditForm;

import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import YAML from 'yaml';

import {setAppEnvironment} from 'store/app/appSlice';
import {initAuthentication} from 'infra/common/aws.config';

function useSetInitialConfig() {
  const dispatch = useDispatch();

  useEffect(() => {
    const path = '/config.yml';

    fetch(path)
      .then((res) => res.blob())
      .then((blob) => blob.text())
      .then((resp) => {
        const parsedYamlFile = YAML.parse(resp);
        if (parsedYamlFile.ENV) {
          dispatch(setAppEnvironment(parsedYamlFile.ENV));
          initAuthentication(parsedYamlFile.ENV);
        }
      });
  }, [dispatch]);
}

export default useSetInitialConfig;

import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _get from 'lodash/get';
import styled from 'styled-components';
import {Alert, FormInstance, Form} from 'antd';

import ImageUploader from 'view/components/ImageUploaderComponent';
import venueTexts from 'texts/venueTexts';

import {PATH_TO_REDUCER_VENUE_PHOTOS} from 'constants/venue';
import {SubPageTitle} from 'view/components/Typography';
import {
  removePhotoError,
  removePhotoFromList,
  setCoverPhoto,
  setPhotoError,
  setPhotoToList,
} from 'store/venue/actions';
import {UploadFile} from 'antd/es/upload/interface';
import {offsetDef, offsetXSm} from 'constants/styleVars';
import {TStepMode} from 'types/app';
import {
  TFunc,
  getHelperText,
  TOnError,
} from 'view/components/ImageUploaderComponent';
import {imageFieldRules} from 'utils/antdFormFieldRules';
import {EAccommodationType} from 'types/dto/IPublicVenue';

const {Item} = Form;

const StyledAlert = styled(Alert)`
  margin-bottom: ${offsetXSm};
`;

const ImageUploaderContainer = styled(Item)`
  margin-bottom: ${offsetDef};
`;

type TPhotos = {
  mode: TStepMode | 'edit';
  form?: FormInstance;
  accommodationType: EAccommodationType;
};

const Photos = ({mode, form, accommodationType}: TPhotos) => {
  const dispatch = useDispatch();

  const photoList: UploadFile[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.photoList`),
  );

  const coverPhoto: string = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.coverPhoto`),
  );

  const imageErrors: TOnError[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_PHOTOS}.errors`),
  );

  const onError = ({uid, message}: TOnError) => {
    dispatch(setPhotoError({uid, message}));
  };

  const onImageUpload = (file: UploadFile) => {
    dispatch(setPhotoToList(file));
  };

  const onRemove: TFunc<Partial<UploadFile>> = (file) => {
    if (imageErrors && imageErrors.find((item) => item.uid === file.uid))
      dispatch(removePhotoError(file.uid as string));
    dispatch(removePhotoFromList(file.uid as string));
  };

  const handleCoverImage: TFunc<Partial<UploadFile>> = useCallback(
    (file) => {
      dispatch(setCoverPhoto(file.uid as string));
    },
    [dispatch],
  );

  const isModeAdd = mode !== 'view';

  return (
    <Form form={form}>
      {isModeAdd && (
        <>
          {imageErrors &&
            imageErrors.map(({message, uid}) => (
              <StyledAlert key={uid} type='error' showIcon message={message} />
            ))}
          <SubPageTitle>
            {`Upload your ${venueTexts[accommodationType].venueType}’s images`}
          </SubPageTitle>

          {getHelperText()}
        </>
      )}

      <ImageUploaderContainer
        required
        noStyle
        name={`${venueTexts[accommodationType].venueType}Photos`}
        rules={imageFieldRules}
      >
        <ImageUploader
          imageFileList={photoList}
          coverImage={coverPhoto}
          onError={onError}
          onImageUpload={onImageUpload}
          setCoverImage={handleCoverImage}
          onRemove={onRemove}
          mode={mode}
          disableUploadBtn={!isModeAdd}
          align={isModeAdd ? 'center' : 'start'}
        />
      </ImageUploaderContainer>
    </Form>
  );
};

export default Photos;

import React, {memo} from 'react';
import styled from 'styled-components';

import Icon from '../Icon';

import {formatDate} from 'utils/stringUtils';
import DateUtils from 'utils/dateUtils';

interface IProps {
  checkIn: string;
  checkOut: string;
  iconMargin?: string;
  noWrap?: boolean;
}

const StyledIcon = styled(Icon)<{iconMargin?: string}>`
  margin: ${({iconMargin}) => iconMargin || '0'};
`;

const Wrapper = styled.div<{noWrap?: boolean}>`
  ${({noWrap}) => noWrap && `white-space: nowrap;`};
`;

const DateBox = styled.span`
  white-space: nowrap;
`;

function DateRangeString({checkIn, checkOut, iconMargin, noWrap}: IProps) {
  const isMultiDay =
    checkIn && checkOut && !DateUtils.isSameDay(checkIn, checkOut);

  return (
    <Wrapper noWrap={noWrap}>
      {isMultiDay ? (
        <>
          <DateBox>{`${formatDate(checkIn, 'd MMM, YYYY')} `}</DateBox>
          <StyledIcon icon='NW2_ARROW_RIGHT_THIN' iconMargin={iconMargin} />
          <DateBox> {` ${formatDate(checkOut, 'd MMM, YYYY')}`}</DateBox>
        </>
      ) : (
        <DateBox>{formatDate(checkIn || checkOut, 'd MMM, YYYY')}</DateBox>
      )}
    </Wrapper>
  );
}

export default memo(DateRangeString);

import sharedSpace from 'img/shared_space.png';
import loungeSpace from 'img/lounge_space.png';

import {TRoomSchemaOption} from './components/AddRoomSchema/types';
import {TFileWithPreview} from '../NW2Images/types';
import {ERoomSchemaNames, IUnitDimensions} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IAmenity} from 'types/dto/IAmenity';
import {ICommonAmenity} from 'types/dto/ICommonAmenity';

export interface IAddSpaceProps {
  venueId: number;
  spaceFloor?: number;
  spaceAmenities: IAmenity[];
  isExternalVenue: boolean;
  isShowed: boolean;
  onToggleShow: () => void;
  venueFloors: number[];
  commonAmenities: ICommonAmenity[];
}

export enum EAddMeetingRoomFormFields {
  NAME = 'name',
  IMAGES = 'images',
  DESCRIPTION = 'description',
  FLOOR = 'floor',
  CAPACITIES = 'capacities',
  AMENITIES_ACCESSIBILITY = 'amenitiesAccessibility',
  AMENITIES_EQUIPMENT = 'amenitiesEquipment',
  DIMENSIONS = 'dimensions',
  PRICE_HOUR = 'priceHour',
  PRICE_HALF_DAY = 'priceHalfDay',
  PRICE_DAY = 'priceDay',
  PAID_DURATION = 'minimumPaidDuration',
  COVER_IMAGE = 'coverImageName',
  RFP = 'isRfp',
}

const DEFAULT_CAPACITY = '';

export const roomSchemaInitialValues = {
  [ERoomSchemaNames.THEATRE]: {
    setupStyle: ERoomSchemaNames.THEATRE,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
  [ERoomSchemaNames.U_SHAPE]: {
    setupStyle: ERoomSchemaNames.U_SHAPE,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
  [ERoomSchemaNames.CIRCLE_OF_CHAIRS]: {
    setupStyle: ERoomSchemaNames.CIRCLE_OF_CHAIRS,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
  [ERoomSchemaNames.CLASSROOM]: {
    setupStyle: ERoomSchemaNames.CLASSROOM,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
  [ERoomSchemaNames.BLOCK]: {
    setupStyle: ERoomSchemaNames.BLOCK,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
  [ERoomSchemaNames.BANQUET]: {
    setupStyle: ERoomSchemaNames.BANQUET,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
  [ERoomSchemaNames.RECEPTION]: {
    setupStyle: ERoomSchemaNames.RECEPTION,
    checkbox: false,
    capacity: DEFAULT_CAPACITY,
  },
};

export type TAddMeetingRoomFormValues = {
  [EAddMeetingRoomFormFields.NAME]: string;
  [EAddMeetingRoomFormFields.IMAGES]: TFileWithPreview[];
  [EAddMeetingRoomFormFields.DESCRIPTION]: string;
  [EAddMeetingRoomFormFields.FLOOR]: string;
  [EAddMeetingRoomFormFields.CAPACITIES]: {
    [key in ERoomSchemaNames]: TRoomSchemaOption;
  };
  [EAddMeetingRoomFormFields.AMENITIES_ACCESSIBILITY]: number[];
  [EAddMeetingRoomFormFields.AMENITIES_EQUIPMENT]: number[];
  [EAddMeetingRoomFormFields.DIMENSIONS]: IUnitDimensions;
  [EAddMeetingRoomFormFields.PRICE_HOUR]: string;
  [EAddMeetingRoomFormFields.PRICE_HALF_DAY]: string;
  [EAddMeetingRoomFormFields.PRICE_DAY]: string;
  [EAddMeetingRoomFormFields.PAID_DURATION]: string;
  [EAddMeetingRoomFormFields.COVER_IMAGE]: string;
  [EAddMeetingRoomFormFields.RFP]: 'false' | 'true';
};

export enum EAddDeskFormFields {
  NAME = 'name',
  IMAGES = 'images',
  DESCRIPTION = 'description',
  FLOOR = 'floor',
  TYPE = 'type',
  AMENITIES_ACCESSIBILITY = 'amenitiesAccessibility',
  AMENITIES_EQUIPMENT = 'amenitiesEquipment',
  CAPACITY = 'capacity',
  PRICE_HOUR = 'priceHour',
  PRICE_HALF_DAY = 'priceHalfDay',
  PRICE_DAY = 'priceDay',
  PAID_DURATION = 'minimumPaidDuration',
  COVER_IMAGE = 'coverImageName',
  RFP = 'isRfp',
}

export type TAddDeskFormValues = {
  [EAddDeskFormFields.NAME]: string;
  [EAddDeskFormFields.IMAGES]: TFileWithPreview[];
  [EAddDeskFormFields.DESCRIPTION]: string;
  [EAddDeskFormFields.FLOOR]: string;
  [EAddDeskFormFields.TYPE]: string;
  [EAddDeskFormFields.AMENITIES_ACCESSIBILITY]: number[];
  [EAddDeskFormFields.AMENITIES_EQUIPMENT]: number[];
  [EAddDeskFormFields.CAPACITY]: string;
  [EAddDeskFormFields.PRICE_HOUR]: string;
  [EAddDeskFormFields.PRICE_DAY]: string;
  [EAddDeskFormFields.COVER_IMAGE]: string;
  [EAddDeskFormFields.RFP]: 'false' | 'true';
};

export enum AddDeskRoomTypes {
  OPEN_SHARED = 'Open-shared space',
  TRANSIENT_LOUNGE = 'Transient lounge space',
}

export enum EAddSpaces {
  DESK = 'DESK',
  MEETING_ROOM = 'MEETING_ROOM',
}

export const roomTypesData: Partial<
  Record<ERoomType, {image: string; text: string}>
> = {
  [ERoomType.SHARED_SPACE]: {
    image: sharedSpace,
    text: `This space type is a typical co-working area, with different people sharing one office. People can work here
     comfortably, and collaborate with friends, colleagues, and people they just met for extended periods of time.`,
  },
  [ERoomType.OPEN_SPACE]: {
    image: loungeSpace,
    text: `This space type has a lounge character which 
    might not consist of an entire office-like setup but nonetheless allows your guests to work 
    and collaborate comfortably and creatively.`,
  },
};

export type TPaidDuration = {
  key: string;
  text: string;
  value: string;
};

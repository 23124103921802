import React, {useMemo} from 'react';
import styled from 'styled-components';

import NW2ActionButton from 'view/components/NW2ActionButton/NW2ActionButton';
import useShareVenue from 'hooks/useShareVenue';

import {getAddressStringFromLocation} from 'utils/stringUtils';
import {TImage} from 'types/app';
import {EOfferStatus, ERequestStatus} from 'types/offer';
import {useOfferRequestDetails} from 'view/venue/Offer/hooks/useOfferRequestDetails';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {useAppSelector} from 'store/hooks';
import {findCoverImage} from 'utils/helpers';

const StyledNW2ActionButton = styled(NW2ActionButton)`
  white-space: nowrap;
`;

type TProps = {
  checkInDate?: string;
  checkOutDate?: string;
  title: string;
  unitId?: number;
  isRfp?: boolean;
  status?: ERequestStatus | EOfferStatus;
};

export function ShareVenueButton({
  checkInDate,
  checkOutDate,
  title,
  unitId,
  status,
  isRfp,
}: TProps) {
  const searchRoomType = useAppSelector(
    ({search}) => search.searchCriteria.roomType,
  );

  const {offerDetails} = useOfferRequestDetails();

  const {venueDetails} = useVenueDetailsData();

  const venueCoverImage = useMemo(
    () => findCoverImage((venueDetails.documents || []) as TImage[]),
    [venueDetails.documents],
  );

  const venueId = isRfp ? offerDetails.venue.id : venueDetails.accommodationId;
  const venueName = isRfp ? offerDetails.venue.name : venueDetails.name;
  const venueLocation = isRfp
    ? offerDetails.venue.location
    : venueDetails.location;
  const roomType = isRfp ? ERoomType.MEETING_ROOM : searchRoomType;
  const venueAddress = getAddressStringFromLocation(venueLocation);

  const titleMessage =
    status === EOfferStatus.OFFER_ACCEPTING_EXPIRED
      ? ' '
      : "Check out this venue's offerings on HRS Connect!";
  const textMessage =
    status === EOfferStatus.OFFER_ACCEPTING_EXPIRED
      ? `\n I found a cool remote workplace ${venueName} for us to collaborate at on HRS Connect! \n\n It's at ${venueAddress} \n\n Check it out:\n\n`
      : `\n I found a cool remote workplace for us to collaborate at! \n\n It's at ${venueAddress} \n\n Check it out:\n\n`;

  const {shareVenue} = useShareVenue({
    searchString: getAddressStringFromLocation(venueLocation),
    checkInDate,
    checkOutDate,
    latitude: venueLocation.latitude,
    longitude: venueLocation.longitude,
    venueName,
    venueId,
    roomType,
    unitId,
    title: titleMessage,
    text: textMessage,
    venueCoverImage: venueCoverImage.url || '',
  });

  return (
    <StyledNW2ActionButton
      icon='NW2_SHARE'
      title={title}
      onClick={shareVenue}
      fullWidth
    />
  );
}

import React from 'react';
import {EMPTY_ARRAY} from 'constants/app';
import {Routes} from 'constants/routes';
import {
  getCenteredLayout,
  getLogoutLayout,
  getPropertyManagerLayout,
} from '../layoutConfigs';
import {TdropdownItem} from 'view/components/NW2MenuItem/types';
import {TNmmNavMenuList} from 'types/header';
import {SupplierHeaderItems, TMenuReturnType} from '../types';
import MultiVenueMenuItem from '../components/MultiVenueMenuItem/MultiVenueMenuItem';
import {IVenue} from 'types/venue';
import {truncateSimpleText} from 'utils/stringUtils';

interface ILocationButtonDropDownItemsProps {
  venueId: string | undefined;
  selectedOfficeId?: number;
  officeList?: IVenue[];
  image?: string;
  dropdownOpen?: boolean | undefined;
  handleOfficeListClick?: (id: number) => () => void;
}

const getLocationButtonDropDownItems = ({
  venueId,
  selectedOfficeId,
  officeList,
  image,
  handleOfficeListClick,
}: ILocationButtonDropDownItemsProps): TdropdownItem[] => {
  const menuItems = [
    {
      id: SupplierHeaderItems.MultiLocation,
      title: 'Venue details',
      isExternal: false,
      customComponent: (
        <MultiVenueMenuItem
          officeList={officeList}
          image={image || ''}
          selectedOfficeId={selectedOfficeId}
          handleOfficeListClick={handleOfficeListClick}
          key={SupplierHeaderItems.MultiLocation}
        />
      ),
    },
    {
      id: SupplierHeaderItems.VenueDetails,
      title: 'Office profile',
      link: `${Routes.corporateVenue}?id=${venueId}`,
      isExternal: false,
    },
  ];

  return !officeList || !officeList?.length || officeList?.length === 1
    ? menuItems.slice(1)
    : menuItems;
};

export const getNavigationDropdownItems = (
  venueId: string | undefined,
): TdropdownItem[] => [
  {
    id: SupplierHeaderItems.MeetingRooms,
    title: 'Meeting rooms',
    link: `${Routes.corporateVenueSpaces}?id=${venueId}#room`,
    isExternal: false,
  },
  {
    id: SupplierHeaderItems.WorkDesks,
    title: 'Workdesks',
    link: `${Routes.corporateVenueSpaces}?id=${venueId}#desk`,
    isExternal: false,
  },
];
const getUserMenuDropdownItems = (): TdropdownItem[] => [
  {
    id: SupplierHeaderItems.LogOut,
    title: 'Log out',
    link: Routes.login,
    isExternal: false,
  },
];

interface ISelectLocationButtonListProps {
  selectedOfficeId?: number;
  officeList?: IVenue[];
  image?: string;
  venueId?: string;
  venueName?: string;
  handleOfficeListClick?: (id: number) => () => void;
}

const selectLocationButtonList = ({
  selectedOfficeId,
  officeList,
  image,
  venueId,
  venueName,
  handleOfficeListClick,
}: ISelectLocationButtonListProps): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.LocationButton,
    text: truncateSimpleText(`${venueName}`, 35),
    isVisible: true,
    width: officeList ? '400px' : undefined,
    dropDownItems: getLocationButtonDropDownItems({
      venueId,
      selectedOfficeId,
      officeList,
      image,
      handleOfficeListClick,
    }),
    icon: 'NW2_LOCATION_OUTLINE',
  },
];

export const restNavigationList = (venueId?: string): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.Spaces,
    text: 'Spaces',
    isVisible: true,
    dropDownItems: getNavigationDropdownItems(venueId),
    icon: 'NW2_SPACES',
  },
];

const userMenuList = (): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.Profile,
    text: 'Profile',
    isVisible: true,
    dropDownItems: getUserMenuDropdownItems(),
    icon: 'USER',
  },
];

interface IPropertyManagerMenuProps {
  venueId: string;
  selectedOfficeId?: number;
  officeList?: IVenue[];
  image?: string;
  venueName?: string;
  isSpaces?: boolean;
  handleOfficeListClick?: (id: number) => () => void;
}

export const propertyManagerMenu = ({
  venueId,
  selectedOfficeId,
  officeList,
  venueName,
  image,
  handleOfficeListClick,
}: IPropertyManagerMenuProps): TMenuReturnType => ({
  layout: {
    defaultLayoutType: getPropertyManagerLayout(),
    customRoutesLayout: {
      [Routes.createCorporateVenue]: getLogoutLayout(),
      [Routes.login]: getCenteredLayout(),
      [Routes.userCompleteRegistration]: getCenteredLayout(),
      [Routes.mainLanding]: getLogoutLayout(),
    },
  },
  selectLocationButton: venueId
    ? selectLocationButtonList({
        selectedOfficeId,
        officeList,
        image,
        venueId,
        venueName,
        handleOfficeListClick,
      })
    : EMPTY_ARRAY,
  restNavList: venueId ? restNavigationList(venueId) : EMPTY_ARRAY,
  userMenuList: userMenuList(),
});

import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';
import CustomList from 'view/components/CustomList';

import {StyledH5, StyledH6, StyledSpan} from 'view/components/Typography';
import {Content} from 'view/common/PageContent/PageContent';
import TextValue from 'view/components/TextValue';
import {
  offsetDef,
  disabledColor,
  offsetXSm,
  offsetXLg,
  fontWeightBold,
} from 'constants/styleVars';

const StyledContent = styled(Content)`
  text-align: center;
  display: grid;
  grid-gap: ${offsetXLg};
`;

const IconBlock = styled.div`
  display: grid;
  grid-gap: ${offsetXSm};
  justify-content: center;
`;

const StyledIconBlockLoading = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: ${offsetXSm};
  vertical-align: middle;

  & > span,
  &:before,
  &:after {
    width: 6px;
    height: 6px;
    background: ${disabledColor};
    border-radius: 50%;
  }

  &:before,
  &:after {
    content: '';
    display: inline-block;
    margin: 3px;
  }

  & > span {
    transform: translateY(-1px);
  }

  &:after {
    transform: translateY(-2px);
  }
`;

const StyledIcon = styled(Icon)`
  display: block;
  line-height: 1;
`;

const ContentFooter = styled.div`
  display: grid;
  grid-gap: ${offsetDef};
  text-align: left;
`;

const list = [
  {
    value:
      'Check your email’s inbox / spam for an email from us with the subject line “Verify your account to complete registration”.',
  },
  {
    value: 'Click on the “VERIFY ACCOUNT” button in the email.',
  },
  {
    value: 'Start booking!',
  },
];

function AccountVerificationDashboard() {
  return (
    <StyledContent>
      <StyledH5>Almost there!</StyledH5>
      <TextValue>
        Your account activation is pending. Please activate your account through
        an activation email to enjoy full potential of NewWork services.
      </TextValue>
      <IconBlock>
        <StyledIcon icon='COMMENT' size={48} />
        <StyledSpan NW2Gray800 fontWeight={fontWeightBold}>
          Awaiting verification
          <StyledIconBlockLoading>
            <span />
          </StyledIconBlockLoading>
        </StyledSpan>
      </IconBlock>

      <ContentFooter>
        <StyledH6>next steps:</StyledH6>
        <CustomList list={list} listStyle='ordered' offset={offsetDef} />
      </ContentFooter>
    </StyledContent>
  );
}

export default AccountVerificationDashboard;

import React, {ChangeEvent, useState} from 'react';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import Icon from 'view/components/Icon';

import {passwordFieldWithCheckListRules} from 'utils/finalFormFieldRules';

import {
  Container,
  ProgressBar,
  ProgressBarSection,
  ProgressBarTitle,
  StrengthInfoBlock,
  RuleItem,
} from './NW2PasswordFieldWithCheckList.styles';

export type TCheckListItem = {
  description: string;
  checkFun: (value: string) => boolean;
};

type TProps = {
  checkList?: TCheckListItem[];
  isSubmitFailed: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
};

const defaultCheckList = [
  {
    description: 'At least 8 characters.',
    checkFun: (value: string) => /.{8,}/.test(value),
  },
  {
    description: 'At least one number.',
    checkFun: (value: string) => /.*[0-9].*/.test(value),
  },
  {
    description: 'At least one uppercase and lowercase letter.',
    checkFun: (value: string) => /(?=.*[a-z])(?=.*[A-Z])/.test(value),
  },
  {
    description: 'At least one special character.',
    checkFun: (value: string) => /(?=.*[\W_]+)/.test(value),
  },
];

function NW2PasswordFieldWithCheckList({
  checkList = defaultCheckList,
  isSubmitFailed,
  name = 'password',
  label = 'Password',
  placeholder = 'Password',
}: TProps) {
  const [isStrengthInfoShow, setIsStrengthInfoShow] = useState(false);
  const [value, setValue] = useState('');

  const onFocusHandler = () => {
    setIsStrengthInfoShow(true);
  };

  const onBlurHandler = () => {
    setIsStrengthInfoShow(false);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const checkRules = (value: string) => {
    return checkList.filter(({checkFun}) => checkFun(value)).length;
  };

  const allRulesNumber = checkList.length;
  const acceptedRulesNumber = checkRules(value);
  const isAllRulesChecked = allRulesNumber === acceptedRulesNumber;

  return (
    <Container onChange={onChangeHandler}>
      <NW2FormItemInput
        type='password'
        name={name}
        label={label}
        placeholder={placeholder}
        highlightAsError={!isAllRulesChecked && isSubmitFailed}
        rules={passwordFieldWithCheckListRules(checkList, label)}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
      />
      {isStrengthInfoShow && (
        <StrengthInfoBlock>
          <ProgressBarSection>
            <ProgressBarTitle isAllRulesChecked={isAllRulesChecked}>
              Password strength:&nbsp;
              <span>
                {acceptedRulesNumber}/{allRulesNumber}
              </span>
            </ProgressBarTitle>
            <ProgressBar
              allRulesNumber={allRulesNumber}
              acceptedRulesNumber={acceptedRulesNumber}
            />
          </ProgressBarSection>
          <div>
            {checkList.map(({description, checkFun}) => {
              const isChecked = checkFun(value);
              return (
                <RuleItem key={description}>
                  {isChecked ? (
                    <Icon icon={'NW2_TICK'} NW2SuccessLight500Color />
                  ) : (
                    <Icon icon={'NW2_CLOSE'} NW2ErrorLight500Color />
                  )}
                  {description}
                </RuleItem>
              );
            })}
          </div>
        </StrengthInfoBlock>
      )}
    </Container>
  );
}

export default NW2PasswordFieldWithCheckList;

import React from 'react';
import styled from 'styled-components';

import {
  fontSizeMd,
  fontWeightBold,
  lineHeightLg,
  offsetDef,
} from 'constants/styleVars';
import {EBookingStatus} from 'types/booking';
import {TERMS_OWNER} from 'types/bookingOrders';

import {EOfferStatus, ERequestStatus} from 'types/offer';
import {
  CANCELLATION_POLICY_BLOCK_ID,
  CancellationPolicyBlock,
} from 'view/components/CancellationPolicyBlock/CancellationPolicyBlock';
import {StyledTextes} from 'view/supplier/Bookings/BookingDetails/BookingDetails.styles';
import {StyledExternalLink} from '../NW2MultiModal/components/InfoPageTypography';
import {TPolicyDocument} from 'types/dto/IBooking.types';
import {
  getCancellationPolicyLinkUrlById,
  getTermsAndConditionLinkUrlById,
} from 'utils/helpers';
import {FULL_FREE_OF_CHARGE} from 'constants/app';

export const Title = styled.h4`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin: 0 0 ${offsetDef};
`;

interface IProps {
  freeOfCharge?: number;
  isCancellationShown?: boolean;
  orderNumber?: string;
  termsAndConditionsId?: string | null;
  cancellationPolicy?: TPolicyDocument;
  bookingStatus?: EBookingStatus | ERequestStatus | EOfferStatus;
}

const CustomerTermsAndPolicyBlock = ({
  bookingStatus,
  cancellationPolicy,
  termsAndConditionsId,
  isCancellationShown = true,
  orderNumber,
  freeOfCharge,
}: IProps) => {
  const isCustomerPolicy = cancellationPolicy?.type === TERMS_OWNER.CUSTOMER;
  const isSupplierPolicy = cancellationPolicy?.type === TERMS_OWNER.SUPPLIER;

  const isHRSPolicy = !isCustomerPolicy && !isSupplierPolicy;

  const isCanceledStatus =
    bookingStatus === EBookingStatus.CANCELED ||
    bookingStatus === EBookingStatus.RFP_CANCELLED;

  const isOfferStatus =
    EOfferStatus[bookingStatus as keyof typeof EOfferStatus];

  const getCancellationPolicyByCountryAndHolder = (
    isCustomerPolicy: boolean,
    isSupplierPolicy: boolean,
    isHRSPolicy: boolean,
  ) => {
    switch (true) {
      case isCustomerPolicy:
        return (
          <StyledTextes>
            Please review your{' '}
            <StyledExternalLink
              href={getCancellationPolicyLinkUrlById(cancellationPolicy?.id)}
              target='_blank'
            >
              Company’s Cancellation Policy.
            </StyledExternalLink>
          </StyledTextes>
        );

      case isSupplierPolicy:
        return (
          <StyledTextes>
            Please review the{' '}
            <StyledExternalLink
              href={getCancellationPolicyLinkUrlById(cancellationPolicy?.id)}
              target='_blank'
            >
              Venue’s Cancellation Policy.
            </StyledExternalLink>
          </StyledTextes>
        );

      case isHRSPolicy:
      default:
        return (
          <>
            <StyledTextes>
              Please review the HRS Cancellation Policy as provided below:
            </StyledTextes>
            <StyledTextes>
              <CancellationPolicyBlock
                isCanceledStatus={isCanceledStatus}
                {...{
                  ...(isOfferStatus
                    ? {offerNumber: orderNumber}
                    : {orderNumber}),
                }}
              />
            </StyledTextes>
          </>
        );
    }
  };

  const cancellationBlock = getCancellationPolicyByCountryAndHolder(
    isCustomerPolicy,
    isSupplierPolicy,
    isHRSPolicy,
  );

  const getCancellationMessage = (
    isHRSPolicy: boolean,
    freeOfCharge?: number,
  ) => {
    if (isHRSPolicy) {
      return freeOfCharge === FULL_FREE_OF_CHARGE
        ? ''
        : 'According to the cancellation policy, the venue has permission to create a cancellation invoice.';
    }
    return 'If the booking was cancelled outside the free cancellation period, the venue has permission to create a cancellation invoice.';
  };

  const cancellationMessage = getCancellationMessage(isHRSPolicy, freeOfCharge);

  return (
    <>
      {isCancellationShown && (
        <div id={CANCELLATION_POLICY_BLOCK_ID}>
          <Title>Modification and Cancellation Conditions</Title>
          {cancellationBlock}

          {bookingStatus === EBookingStatus.CANCELED && (
            <StyledTextes>{cancellationMessage}</StyledTextes>
          )}
        </div>
      )}

      {termsAndConditionsId && (
        <>
          <Title>Company’s Terms and Conditions</Title>
          <StyledTextes>
            The venue has accepted your{' '}
            <StyledExternalLink
              href={getTermsAndConditionLinkUrlById(
                termsAndConditionsId as string,
              )}
              target='_blank'
            >
              Company’s Terms and Conditions.
            </StyledExternalLink>
          </StyledTextes>
        </>
      )}
    </>
  );
};

export default CustomerTermsAndPolicyBlock;

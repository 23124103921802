import React, {useCallback, useEffect} from 'react';
import {useForm, useFormState} from 'react-final-form';

import {NW2ImageItem} from 'view/venue/NW2VenueProfile/components/NW2Images/components';

import {
  ImageWrapper,
  PageImages,
  PageImagesUploadInputWrapper,
} from '../styles';
import {TFileWithPreview} from 'view/venue/NW2VenueProfile/components/NW2Images/types';
import {useNMMUpload} from '../hooks/useNMMUpload';
import {CoverLabel} from '../../NW2Images/NW2Images.styles';

interface IProps {
  fieldName: string;
  fieldCoverName: string;
  filesState: TFileWithPreview[];
  handleSetFiles: (files: TFileWithPreview[]) => void;
  onChange: (files: File[]) => void;
  isInline?: boolean;
  itemWidth?: string;
}

const MAX_FILES = 6;

export function AddImages({
  fieldName,
  fieldCoverName,
  filesState,
  handleSetFiles,
  onChange,
  isInline,
  itemWidth,
}: IProps) {
  const {mutators} = useForm();
  const {values} = useFormState();

  const images = values[fieldName];
  const coverImage = values[fieldCoverName];

  const {files, uploadComponent, handleRemoveFile} = useNMMUpload({
    filesState,
    isMultiple: true,
    isDisabled: images.length >= MAX_FILES,
    maxFiles: MAX_FILES,
    label: 'Upload more images',
    onChange,
  });

  useEffect(() => {
    // keep local preview images on the parent level for back button reasons
    handleSetFiles(files);

    if (files.length && !files.find((img) => img.name === coverImage)) {
      // set cover for first after remove
      mutators.setValue(fieldCoverName, files[0].name);
    }
  }, [coverImage, fieldCoverName, files, handleSetFiles, mutators]);

  const handleSetCoverImage = useCallback(
    (fileName: string) => () => {
      mutators.setValue(fieldCoverName, fileName);
    },
    [fieldCoverName, mutators],
  );

  useEffect(() => {
    // set default cover image
    if (files.length && !values[fieldCoverName]) {
      mutators.setValue(fieldCoverName, files[0].name);
    }
  }, [fieldCoverName, files, mutators, values]);

  const isImagesShowed: boolean = isInline || filesState.length > 0;

  return (
    <>
      {isImagesShowed && (
        <PageImages isInline={isInline}>
          {filesState.map((file, idx) => (
            <ImageWrapper key={file.name + idx} itemWidth={itemWidth}>
              <NW2ImageItem
                id={file.preview}
                name={file.name}
                // @ts-ignore
                src={file.url || file.preview}
                handleSetCover={handleSetCoverImage(file.name)}
                handleRemove={handleRemoveFile(file)}
              />

              {file.name === coverImage && <CoverLabel>cover</CoverLabel>}
            </ImageWrapper>
          ))}

          {isInline && (
            <ImageWrapper itemWidth={itemWidth}>{uploadComponent}</ImageWrapper>
          )}
        </PageImages>
      )}

      {!isInline && (
        <PageImagesUploadInputWrapper>
          {uploadComponent}
        </PageImagesUploadInputWrapper>
      )}
    </>
  );
}

import React from 'react';

import {EMPTY_OBJECT} from 'constants/app';
import {EBookingTagStatus, IBookingTagSchema, tagSchema} from './types';

import {Tag, StyledIcon} from './NW2BookingStatusTag.styles';
import {TSize} from 'types/app';

interface IProps {
  status: keyof typeof EBookingTagStatus;
  onClick?: () => void;
  size?: TSize;
}

function NW2BookingStatusTag({status, onClick = () => null, size}: IProps) {
  const {text, icon} = tagSchema[status] || (EMPTY_OBJECT as IBookingTagSchema);

  return (
    <Tag status={status} onClick={onClick} size={size}>
      {text}
      {!!icon && <StyledIcon transparent icon={icon} />}
    </Tag>
  );
}

export default NW2BookingStatusTag;

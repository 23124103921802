import _uniqBy from 'lodash/uniqBy';
import {useMemo} from 'react';
import {useAppSelector} from 'store/hooks';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

export const useVenueDetailsExtras = () => {
  const workDesksAvailableExtras = useSelector((state) =>
    _get(state, 'workDesks.workDesksDetails.extras'),
  );

  const venueDetails = useAppSelector(({venueDetails}) => venueDetails.details);

  const allUnitsVenueExtras = useMemo(
    () => [
      ...(venueDetails?.days.flatMap(({foodAndBeverage, rooms}) =>
        rooms
          .flatMap(({units}) => units)
          .flatMap(({extras}) => [...foodAndBeverage, ...(extras || [])]),
      ) || []),
      ...(venueDetails?.extras || workDesksAvailableExtras || []),
    ],
    [workDesksAvailableExtras, venueDetails?.days, venueDetails?.extras],
  );

  const venueExtras = _uniqBy(allUnitsVenueExtras, 'code');

  return {venueExtras};
};

import React from 'react';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {NW2MenuItem} from 'view/components/NW2MenuItem/NW2MenuItem';
import {TNmmNavMenuList} from 'types/header';
import {SupplierHeaderItems, TMenuListPropsWithSpaces} from '../types';
import {
  adminMenu,
  propertyManagerMenu,
  supplierMenu,
  agentMenu,
} from '../menuConfigs';

const getUserMenu = (
  role: string,
  venueId: string,
  isSpaces: boolean,
): TNmmNavMenuList[] | undefined => {
  switch (role) {
    case EUserRoleCognito.ROLE_SUPPLIER:
      return supplierMenu({venueId, isSpaces}).userMenuList?.filter(
        ({isVisible}) => isVisible,
      );
    case EUserRoleCognito.ROLE_PROPERTY_MANAGER:
      return propertyManagerMenu({venueId, isSpaces}).userMenuList?.filter(
        ({isVisible}) => isVisible,
      );
    case EUserRoleCognito.ROLE_ADMIN:
      return adminMenu().userMenuList;
    case EUserRoleCognito.ROLE_AGENT:
      return agentMenu({venueId}).userMenuList;
    default:
      return undefined;
  }
};

function SupplierUserMenu({
  role,
  activeItem,
  selectMenuItem,
  activeDropdownItem,
  selectActiveDropdownItem,
  venueId,
  spaces,
}: TMenuListPropsWithSpaces) {
  const userMenuList = getUserMenu(role, venueId, !!spaces?.length);

  const handleMenuClick = (id: SupplierHeaderItems) => () => {
    selectMenuItem(id);
  };

  if (!userMenuList) return null;

  return (
    <>
      {userMenuList.map(({url, text, dropDownItems, icon, id}) => (
        <NW2MenuItem
          id={id}
          key={id}
          isActive={activeItem === id}
          title={text}
          activeDropdownItem={activeDropdownItem}
          onDropdownClick={selectActiveDropdownItem}
          url={url}
          color='NW2Primary'
          onClick={handleMenuClick(id)}
          dropdownItems={dropDownItems}
          icon={icon}
        />
      ))}
    </>
  );
}

export default React.memo(SupplierUserMenu);

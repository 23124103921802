import React, {useMemo, useRef} from 'react';

import InputBlock from 'view/components/NW2SearchSection/components/SearchForm/components/InputBlock/InputBlock';
import {
  formatDateForPicker,
  NW2DatePicker,
} from 'view/components/NW2Datepicker';

import {useAppSelector} from 'store/hooks';
import {formatDate} from 'utils/stringUtils';
import {TDateType} from 'types/dto/ISearch.types';
import {ESearchInputsId} from 'types/search';
import {StyledDropdown} from '../NW2DatepickerDetails.styles';
import {StyledColumnCalendar} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker/NW2SearchDatePicker.styles';

type TProps = {
  date: TDateType;
  multiRooms: boolean;
  inputBlockColor: string | undefined;
  focusedInput: string;
  isMobile: boolean;
  isChangeActivated: boolean;
  isDatepickerOpened: boolean;
  onToggleDatePicker: () => void;
  handleDateChange: (val: TDateType) => void;
};

const DatePickerSection = ({
  date,
  multiRooms,
  inputBlockColor,
  isMobile,
  isChangeActivated,
  isDatepickerOpened,
  handleDateChange,
  onToggleDatePicker,
  focusedInput,
}: TProps) => {
  const datePickerRef = useRef(null);

  const searchStartDate = useAppSelector(
    ({search}) => search.searchCriteria.startDate,
  );
  const searchEndDate = useAppSelector(
    ({search}) => search.searchCriteria.endDate,
  );

  const inputValue = useMemo(
    () =>
      multiRooms
        ? formatDateForPicker(searchStartDate, searchEndDate)
        : formatDate(date, 'd MMM, YYYY'),
    [date, searchEndDate, multiRooms, searchStartDate],
  );

  return (
    <InputBlock
      htmlFor={ESearchInputsId.DATE_ID}
      color={inputBlockColor}
      labelText='Date'
      isFocused={focusedInput === ESearchInputsId.DATE_ID}
      isNoMargin
      isNoBorder
      isWidthAuto
    >
      <div ref={datePickerRef}>
        <input
          type='text'
          value={inputValue}
          id={ESearchInputsId.DATE_ID}
          onClick={onToggleDatePicker}
          readOnly
          disabled={!isMobile && !isChangeActivated}
        />

        {isDatepickerOpened && (
          <StyledDropdown>
            <StyledColumnCalendar>
              <NW2DatePicker date={date} onChange={handleDateChange} />
            </StyledColumnCalendar>
          </StyledDropdown>
        )}
      </div>
    </InputBlock>
  );
};

export default DatePickerSection;

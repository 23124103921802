import styled from 'styled-components';
import {
  fontWeightExtraBold,
  lgBp,
  mdBp,
  NW2Gray200Color,
  NW2Gray900Color,
  offset64,
  offsetXXLg,
  pagePadding,
  sectionInsetMd,
  sectionInsetSm,
  whiteColor,
} from 'constants/styleVars';
import {NW2Container} from 'view/mainLanding/MainLanding.styles';
import {SubBlockTitle} from 'view/components/Typography';

const SectionDark = styled.section`
  display: inline-block;
  width: 100%;
  background: ${NW2Gray900Color};
  padding-bottom: ${offsetXXLg};

  @media (min-width: ${mdBp}px) {
    padding-bottom: 0;
  }
`;

const HeroHeaderContainer = styled.div`
  position: relative;
`;

const SectionLight = styled.section`
  background-color: ${whiteColor};
`;

const SectionLightContainer = styled(NW2Container)`
  > * {
    padding-top: ${sectionInsetSm};
    padding-bottom: ${sectionInsetSm};

    @media (min-width: ${mdBp}px) {
      padding-top: ${offset64};
      padding-bottom: ${offset64};
    }
    @media (min-width: ${lgBp}px) {
      padding-top: ${sectionInsetMd};
      padding-bottom: ${sectionInsetMd};
    }
  }
  > * + * {
    border-top: 1px solid ${NW2Gray200Color};
  }
`;

const StyledSubBlockTitle = styled(SubBlockTitle)`
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  margin-bottom: ${pagePadding};
`;

export {
  SectionDark,
  SectionLight,
  HeroHeaderContainer,
  SectionLightContainer,
  StyledSubBlockTitle,
};

import {useNavigate} from 'react-router-dom';
import {IBookingOrderResponse} from 'types/dto/IBooking.types';

interface IUseOfferRedirect {
  venueId: number;
  offerId?: string;
}

export const useOfferRedirect = ({venueId, offerId}: IUseOfferRedirect) => {
  const navigate = useNavigate();

  const onSuccessRedirect = (
    bookingResponse?: IBookingOrderResponse,
    isOrderRedirect?: boolean,
  ) => {
    const query = new URLSearchParams(location.search);

    if (isOrderRedirect) {
      query.set('venueId', venueId?.toString());
    } else {
      query.set('isOffer', 'true');
    }

    navigate({
      pathname: isOrderRedirect
        ? `/booking/${bookingResponse?.orderNumber}`
        : `/request-overview/${offerId}`,
      search: query.toString(),
    });
  };

  return {onSuccessRedirect};
};

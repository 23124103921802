import {useMemo} from 'react';

import {EMPTY_ARRAY} from 'constants/app';
import {EAmenitiesCategories, TFilteredAmenities} from 'types/venue';
import {IAmenity} from 'types/dto/IAmenity';
import {EAmenityType} from 'types/amenities';

interface IProps {
  amenities: IAmenity[];
}

type TSortedAmenities = {
  [key in EAmenitiesCategories]: {
    label: string;
    value: number;
  }[];
};

export const useSortedAmenities = ({
  amenities = EMPTY_ARRAY,
}: IProps): TSortedAmenities =>
  useMemo(() => {
    const getFilteredAmenities = (
      amenityType: EAmenityType,
    ): TFilteredAmenities[] =>
      amenities
        .filter(({type}) => type === amenityType)
        .map(({name, id}) => ({
          label: name,
          value: id,
        }));

    return {
      [EAmenitiesCategories.GENERAL]: getFilteredAmenities(EAmenityType.VENUE),
      [EAmenitiesCategories.SERVICES]: getFilteredAmenities(
        EAmenityType.VENUE_SERVICE,
      ),
      [EAmenitiesCategories.ENTERTAINMENT]: getFilteredAmenities(
        EAmenityType.VENUE_ENTERTAINMENT,
      ),
      [EAmenitiesCategories.ACCESSIBILITY]: getFilteredAmenities(
        EAmenityType.VENUE_ACCESSIBILITY,
      ),
      [EAmenitiesCategories.TRANSPORTATION]: getFilteredAmenities(
        EAmenityType.VENUE_TRANSPORTATION,
      ),
      [EAmenitiesCategories.WELLNESS_FITNESS]: getFilteredAmenities(
        EAmenityType.VENUE_WELLNESS_AND_FITNESS,
      ),
    };
  }, [amenities]);

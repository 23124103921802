import {TUnitCapacityStyle} from 'types/dto/IUnit.types';
import {ERoomSchemaNames} from 'types/venue';
import {IPreviewUnit} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IUnitResponse} from 'types/dto/IPublicVenue';

export const getCapacityFromCapacityDto = (
  unitCapacities: TUnitCapacityStyle[] | undefined,
  seatingPlan: string | ERoomSchemaNames,
) =>
  unitCapacities?.find((item) => item.setupStyle === seatingPlan)?.capacity ||
  0;

export const getCapacityFromCapacityDtoMultiRooms = (
  units: IPreviewUnit[] | IUnitResponse[],
) => {
  const arrOfMaxCapacities = units.map((unit) =>
    getCapacityFromCapacityDto(
      unit.unitCapacities,
      (unit as IPreviewUnit).chosenSetupStyle ||
        (unit as IUnitResponse).requestedSetupStyle,
    ),
  );
  return Math.max(...arrOfMaxCapacities);
};

export const getFlatMapUnits = <T, M>(
  previewUnits: (T & {units: M[]})[],
): M[] =>
  previewUnits?.length ? previewUnits.flatMap(({units}) => units) : [];

export const getFlatMapUnitBookings = <T, M>(
  previewUnits: (T & {unitBookings: M[]})[],
): M[] =>
  previewUnits?.length
    ? previewUnits.flatMap(({unitBookings}) => unitBookings)
    : [];

export const SPACE_NAMES: Record<string, string> = {
  [ERoomType.WORK_SPACE]: 'Work desk',
  [ERoomType.MEETING_ROOM]: 'Meeting',
};

import api, {ApiType} from './http.service';
import {IResetPassword} from 'types/dto/IResetPassword.type';
import {IFormRegisterData, LoginUserData} from 'types/account';
import {
  ICreateCustomerBillingAddressPayload,
  ICustomer,
} from 'types/dto/IUser.types';

const ApiInventoryService = {
  registerCustomer: async (data: IFormRegisterData) =>
    await api.add(ApiType.Inventory, 'customers', data),

  deleteCustomerAccount: async (email: string) =>
    await api.delete(ApiType.Inventory, `customers/${email}`),

  // TODO: remove this method after BE will be ready
  loginInSystem: async (data: LoginUserData) =>
    await api.add(ApiType.Inventory, 'auth/login', data),

  // TODO: remove this method after BE will be ready
  editPassword: async (data: IResetPassword) =>
    await api.update(ApiType.Inventory, 'auth/password', data),

  forgotPassword: async (email: string) =>
    await api.remove(ApiType.Inventory, `register/forgot-password/${email}`),

  // TODO: remove this method after BE will be ready
  getCustomer: async (id: string | number) =>
    await api.get(ApiType.Inventory, `customers/${id}`),
  postActivateUser: async (): Promise<void> =>
    await api.add(ApiType.Inventory, `register/activate`, {}),
  updateCustomer: async (id: string | number, customer: ICustomer) =>
    await api.update(ApiType.Inventory, `customers/${id}`, customer),
  getCustomerBillingAddresses: async (id: number) =>
    await api.get(ApiType.Inventory, `billingAddress/customer/${id}`),
  createCustomerBillingAddress: async (
    data: ICreateCustomerBillingAddressPayload,
  ) => await api.add(ApiType.Inventory, 'billingAddress', data),
  updateCustomerBillingAddress: async (
    data: ICreateCustomerBillingAddressPayload,
  ) => await api.update(ApiType.Inventory, `billingAddress/${data.uuid}`, data),
};

export default ApiInventoryService;

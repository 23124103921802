import React from 'react';

import {FullDescription} from '.';

import {EVenueCharacters, TVenueCharacterType} from 'types/venue';
import {
  FlexContainer,
  SubSectionContainer,
  List,
} from '../NW2VenueDescription.styles';
import {EFieldNames} from './types';
import {SectionSubTitle} from 'view/components/NMMSection/NMMSection.styles';

interface IProps {
  isExternalVenue: boolean;
  [EFieldNames.DESCRIPTION]: string;
  [EFieldNames.VENUE_CHARACTERS]: TVenueCharacterType[];
}

const Description = ({
  isExternalVenue,
  venueCharacters,
  description,
}: IProps) => {
  return (
    <FlexContainer>
      <SubSectionContainer>
        <SectionSubTitle>What we are like?</SectionSubTitle>
        <FullDescription description={description} />
      </SubSectionContainer>

      {isExternalVenue && (
        <SubSectionContainer>
          <SectionSubTitle>Our venue character</SectionSubTitle>
          <List data-testid='venue character preview'>
            {venueCharacters.map((venueCharacter) => (
              <li key={venueCharacter}>{EVenueCharacters[venueCharacter]}</li>
            ))}
          </List>
        </SubSectionContainer>
      )}
    </FlexContainer>
  );
};

export default Description;

import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import DRAWER_CONFIG from 'view/components/NW2SummaryBlock/NW2SummaryBlock.config';
import {
  blackColor,
  borderRadiusLg,
  NW2Gray100Color,
  NW2Gray600Color,
  NW2Gray800Color,
  NW2Gray900Color,
  nw2TotalDrawerZIndex,
  offsetDef,
  offsetLg,
  offsetXSm,
  offsetXXXLg,
  whiteColor,
} from 'constants/styleVars';

const OFFSET_MOBILE_DRAWER_INDICATOR = '6px';

// mobile
export const FooterMobile = styled.div<{footerPosition?: string}>`
  position: fixed;
  bottom: ${({footerPosition}) => footerPosition || 0};
  left: 0;
  right: 0;
  background-color: ${blackColor};
  height: ${DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT}px;
  padding: ${offsetXSm} ${offsetDef};
  z-index: ${Number(nw2TotalDrawerZIndex) + 1};
  display: flex;
`;

export const FooterMobileClickArea = styled.div<{isShowed: boolean}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT +
  DRAWER_CONFIG.CONTROL_ITEM_HEIGHT +
  DRAWER_CONFIG.OFFSET}px;
  display: ${({isShowed}) => (isShowed ? 'flex' : 'none')};
`;

export const StyledDrawerButtonClose = styled(Icon)`
  position: absolute;
  top: ${offsetLg};
  right: ${offsetLg};
  color: ${whiteColor};
`;

export const TotalContainerMobile = styled.div<{isLoaded: boolean}>(
  ({isLoaded}) =>
    isLoaded &&
    css`
      display: flex;
      flex-direction: column;
      height: 100%;
    `,
);

export const TotalHeaderMobile = styled.div`
  position: relative;
  background-color: ${NW2Gray900Color};
  padding-top: ${offsetXSm};
`;

export const TotalMobileMain = styled.div<{footerPosition?: string}>`
  padding: ${offsetDef} ${offsetDef} ${offsetXXXLg} ${offsetDef};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100% - ${DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT}px);

  ${({footerPosition}) =>
    footerPosition &&
    css`
      padding-bottom: ${footerPosition};
    `}
`;

export const TotalMobileMainUnits = styled.div`
  margin-bottom: ${offsetDef};
`;

export const TotalWrapperMobile = styled.div<{
  size: string;
  translateY: string;
}>(
  ({size, translateY}) =>
    css`
      position: fixed;
      left: 0;
      right: 0;
      bottom: -${size};
      width: 100%;

      color: ${NW2Gray100Color};
      background-color: ${NW2Gray800Color};
      border-top-left-radius: ${borderRadiusLg};
      border-top-right-radius: ${borderRadiusLg};
      transform: translateY(${translateY});
      transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1); // from antd
      will-change: transform;
      height: ${size};
      max-height: calc(
        100vh - ${DRAWER_CONFIG.TOTAL_FOOTER_HEIGHT}px -
          ${DRAWER_CONFIG.OFFSET}px
      );
      z-index: ${nw2TotalDrawerZIndex};
      overflow: hidden;

      &:before {
        position: absolute;
        content: '';
        top: ${OFFSET_MOBILE_DRAWER_INDICATOR};
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 4px;
        background-color: ${NW2Gray600Color};
        border-radius: 100px;
        pointer-events: none;
        z-index: 1;
      }
      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        border-top: 1px solid black;
        border-top-left-radius: ${borderRadiusLg};
        border-top-right-radius: ${borderRadiusLg};
        height: 10px;
        pointer-events: none;
      }
    `,
);

import styled from 'styled-components';

import {
  NW2Primary,
  offsetXSm,
  offsetXXSm,
  fontSizeMd,
  blackColor,
  whiteColor,
  fontWeightBold,
} from 'constants/styleVars';

export const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: ${fontSizeMd};
  margin: 0 ${offsetXXSm};
  font-weight: ${fontWeightBold};
  color: ${whiteColor};
`;

export const PageButton = styled(StyledLi)<{active?: boolean}>`
  color: ${({active}) => (active ? blackColor : whiteColor)};
  background-color: ${({active}) => (active ? NW2Primary : 'transparent')};
  cursor: ${({active}) => (active ? 'default' : 'pointer')};
  transition: background-color 0.1s, color 0.1s;

  &:hover {
    background-color: ${NW2Primary};
  }
`;

export const ArrowButton = styled(StyledLi)<{
  disabled?: boolean;
}>`
  opacity: ${({disabled}) => (disabled ? '.4' : '1')};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  transition: background-color 0.1s, color 0.1s;
  margin: 0 0 0 ${offsetXXSm};

  &:first-of-type {
    margin: 0 ${offsetXSm} 0 0;
  }

  &:hover {
    background-color: ${NW2Primary};
  }

  &:first-of-type svg {
    transform: rotate(180deg);
  }

  & div svg {
    cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  }
`;

import React from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import Icon from 'view/components/Icon/Icon';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import TaxDisclaimer from 'view/common/TaxDisclaimer/TaxDisclaimer';
// import NW2NoFoundBlock from 'view/components/NW2NoFoundBlock/NW2NoFoundBlock';
import WhatIsNextBlock from './WhatIsNextBlock';

import {ActionButtons} from '../../ActionButtons/ActionButtons';

import {useAppSelector} from 'store/hooks';
import {useRequestForOffer} from 'view/venue/hooks/useRequestForOffer';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import useNavigateToBookingPreview from '../../../hooks/useNavigateToBookingPreview';
import {TDay} from 'types/search';
import {EAccommodationType, IUnitResponse} from 'types/dto/IPublicVenue';
import {ERoomType} from 'types/dto/ERoomType.type';

import {
  AmenitiesText,
  AmenitiesTitle,
  StyledIcon,
  TotalPrice,
  TotalPriceWrapper,
  TotalPriceSubTitle,
  TotalPriceTitle,
  Actions,
  StyledButtonRequest,
  TotalPriceBox,
} from './MeetingRoomTotalPrice.styles';
// import {StyledNoFoundBlock} from 'view/venue/NW2VenueDetails/components/SpacesListBlock/MeetingRoomSpacesListBlock.styles';

const findExtras = (meetingRequestData: TDay[]) => {
  let isEquipment = false,
    isFoodAndBeverage = false;

  meetingRequestData?.forEach((item) => {
    if (!isFoodAndBeverage) isFoodAndBeverage = !!item.foodBeverageData;
    if (!isEquipment) {
      isEquipment = item.rooms.some(
        ({equipmentData}) => equipmentData.ids?.length,
      );
    }
  });

  return {
    isEquipment,
    isFoodAndBeverage,
  };
};

type TProps = {
  currency: string;
  hasBedroom?: boolean;
  isUSA: boolean;
};

function MeetingRoomTotalPrice({currency, hasBedroom, isUSA}: TProps) {
  const selectedMeetingRoom = useAppSelector(
    ({venueDetails}) => venueDetails.selectedRoom,
  );
  const isMultiRoomsBookingNotAvailable = useSelector((state) =>
    _get(state, 'venuesReducer.isMultiRoomsBookingNotAvailable'),
  );
  const meetingRequestData = useAppSelector(
    ({search}) => search.meetingRequestData,
  );
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  const searchData = useAppSelector(({search}) => search.searchCriteria);

  const {venueDetails, isAlternative, isRfp} = useVenueDetailsData();

  const {accommodationType, totalPrice, days} = venueDetails;

  const isGroups = searchData.roomType === ERoomType.GROUPS;

  const amenities = selectedMeetingRoom?.amenities;
  const hasAmenities = !!amenities?.length;
  const onRedirectToBooking = useNavigateToBookingPreview(
    selectedMeetingRoom as IUnitResponse,
  );
  const isOffice = accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const {isEquipment, isFoodAndBeverage} = findExtras(meetingRequestData);

  const isMultiDay = days.length > 1;

  const amenitiesList = isMultiDay
    ? [
        {id: 0, name: 'Meeting rooms'},
        ...(isEquipment ? [{id: 1, name: 'Equipment'}] : []),
        ...(isFoodAndBeverage ? [{id: 3, name: 'Food & beverage'}] : []),
      ]
    : amenities;

  // shortList handlers
  const {isShortListed, onRequestForOfferClick} = useRequestForOffer();

  // if (!availableRoom.length && !isMultiRooms)
  // return (
  //   <StyledNoFoundBlock>
  //     <NW2NoFoundBlock
  //       title='No spaces available'
  //       description='Unfortunately there are no available spaces for the time selected.'
  //       white
  //       listItems={
  //         <StyledNavLink to='/'>Try different date & time</StyledNavLink>
  //       }
  //       titleFontSize={fontSizeXSm}
  //       titleFontWeight={fontWeightBold}
  //     />
  //   </StyledNoFoundBlock>
  // );

  return (
    <TotalPriceWrapper>
      {!isOffice && !!totalPrice && !hasBedroom && (
        <TotalPriceBox isMobile={isMobile}>
          <div>
            <TotalPriceTitle>Total Price</TotalPriceTitle>
            <TotalPriceSubTitle>
              <TaxDisclaimer isUSA={isUSA} />
            </TotalPriceSubTitle>
            <TotalPrice>
              <PriceDisplay price={totalPrice} currency={currency} />
            </TotalPrice>
          </div>

          {isMobile && (
            <StyledButtonRequest
              buttonType={isShortListed ? 'chip' : 'tertiary'}
              onClick={onRequestForOfferClick}
              icon={
                isShortListed && <Icon icon='NW2_TICK' transparent size={14} />
              }
              active={isShortListed}
              noUppercase
            >
              {isShortListed ? 'Shortlisted' : 'Shortlist this venue'}
            </StyledButtonRequest>
          )}
        </TotalPriceBox>
      )}

      {!isMobile && (
        <Actions>
          <ActionButtons
            hasBedroom={hasBedroom}
            isOffice={isOffice}
            isShortListed={isShortListed}
            onRedirectToBooking={onRedirectToBooking}
            isMultiRoomsBookingNotAvailable={isMultiRoomsBookingNotAvailable}
            onRequestForOfferClick={onRequestForOfferClick}
            isBothShowed={!isAlternative && !isRfp && !hasBedroom}
          />
        </Actions>
      )}

      {isAlternative || hasBedroom || isGroups ? (
        <WhatIsNextBlock />
      ) : (
        (hasAmenities || isMultiDay) && (
          <>
            <AmenitiesTitle>Included in offer:</AmenitiesTitle>
            {amenitiesList.map(({id, name}) => (
              <StyledIcon key={id}>
                <Icon size={17} icon='CHECK' />
                <AmenitiesText>{name}</AmenitiesText>
              </StyledIcon>
            ))}
          </>
        )
      )}
    </TotalPriceWrapper>
  );
}

export default MeetingRoomTotalPrice;

import styled from 'styled-components';

import {
  fontSizeXSm,
  fontWeightBold,
  NW2ErrorLight500Color,
  NW2Gray200Color,
  NW2Gray300Color,
  NW2Gray500Color,
  NW2Gray800Color,
  NW2SuccessLight500Color,
  NW2Warn500Color,
  offsetXSm,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

const SIX_PIXELS = '6px';

export const StatusTag = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${fontSizeXSm};
  line-height: 1.2;
  font-weight: ${fontWeightBold};
  color: ${NW2Gray800Color};
  padding: ${offsetXXSm} ${offsetXSm};
  gap: ${offsetXSm};
  border: 1px solid ${NW2Gray200Color};
  border-radius: 6px;
`;

export const ConfirmedIcon = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${NW2SuccessLight500Color};
`;

export const CompletedIcon = styled.span`
  width: 8px;
  height: 4px;
  background-color: ${NW2Gray300Color};
`;

export const DeclinedIcon = styled.span`
  width: 8px;
  height: 4px;
  background-color: ${NW2Gray500Color};
`;

export const CanceledIcon = styled.span`
  position: relative;
  background-color: ${NW2ErrorLight500Color};
  text-align: left;
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
  }

  &,
  &:before,
  &:after {
    width: 5px;
    height: 5px;
    border-top-right-radius: 30%;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;

export const DisabledIcon = styled.span`
  width: ${SIX_PIXELS};
  height: 3px;
  background: ${NW2Gray500Color};
`;

export const InProgressIcon = styled.span`
  width: ${SIX_PIXELS};
  height: ${SIX_PIXELS};
  border-radius: 50%;
  background: conic-gradient(
    ${whiteColor} 90deg,
    ${NW2Warn500Color} 90deg 325deg,
    ${whiteColor} 325deg 360deg
  );
`;

import {useMemo} from 'react';
import _omit from 'lodash/omit';

import {
  useGetCalculatedListQuery,
  useGetSearchDesksListQuery,
  useGetSearchListQuery,
} from 'store/venues/venuesSearchApi';
import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';
import {VENUE_LIST_LIMIT} from 'constants/app';
import {TDay, TSearchVenuesPayload, TVenuesQuerySearchData} from 'types/search';
import {ITimeData} from 'types/dto/ISearch.types';
import {useQuery} from 'hooks/useQuery';
import {useSearchFilterDays} from './hooks/useSearchFilterDays';

interface IProps {
  initialSearchData: TVenuesQuerySearchData & {
    initialTimeData: ITimeData[];
    meetingRequestData: TDay[];
  };
  startDate: string;
  endDate: string;
}
export function useVenuesListSearch({
  initialSearchData,
  startDate,
  endDate,
}: IProps) {
  const corporateAccountId = useAppSelector(({app}) => app.corporateAccountId);

  const queryString = useQuery();

  const searchData = useMemo(
    () => ({
      ...initialSearchData,
      startDate:
        queryString.startDate || startDate || initialSearchData.startDate,
      endDate: queryString.endDate || endDate || initialSearchData.endDate,
      searchString:
        queryString.searchString || initialSearchData.searchString || '',
      latitude: Number(queryString.latitude) || initialSearchData.latitude,
      longitude: Number(queryString.longitude) || initialSearchData.longitude,
      radius: Number(queryString.radius) || initialSearchData.radius,
    }),
    [
      endDate,
      initialSearchData,
      queryString.endDate,
      queryString.latitude,
      queryString.longitude,
      queryString.radius,
      queryString.searchString,
      queryString.startDate,
      startDate,
    ],
  );

  const desksQueryParams = `accommodations/?searchString=&latitude=${
    searchData.latitude
  }&longitude=${searchData.longitude}
      &startDate=${searchData.startDate}&endDate=${
    searchData.endDate
  }&page=0&limit=${searchData.limit || 500}${
    corporateAccountId ? `&corporate-account-id=${corporateAccountId}` : ''
  }&radius=${searchData.radius}`;

  // const isMeetingRoom =
  // roomType === ERoomType.MEETING_ROOM ||
  // queryString['roomType'] === ERoomType.MEETING_ROOM;

  // const isGroups =
  // roomType === ERoomType.GROUPS ||
  // queryString['roomType'] === ERoomType.GROUPS;

  // todo need to improve search tabs switching... now it switch store value, but search may not be started
  const isWorkDesk =
    // roomType === ERoomType.WORK_SPACE ||
    queryString['roomType'] === ERoomType.WORK_SPACE;

  const [filterDays] = useSearchFilterDays();

  const searchVenuesPayload = useMemo(() => {
    return {
      corporateAccountId,
      filterDays: filterDays.map((day) => ({
        ...day,
        roomFilters: day.roomFilters?.map((room) =>
          _omit(room, ['checkIn', 'checkOut']),
        ),
      })),
      latitude: searchData.latitude,
      longitude: searchData.longitude,
      limit: searchData.limit || VENUE_LIST_LIMIT,
      radius: searchData.radius,
      page: 0,
    };
  }, [
    corporateAccountId,
    filterDays,
    searchData.latitude,
    searchData.limit,
    searchData.longitude,
    searchData.radius,
  ]);

  const isSomeDayEmpty = filterDays.some(
    ({bedrooms, roomFilters}) => !bedrooms.length && !roomFilters?.length,
  );

  // search venues list
  const searchListQuery = useGetSearchListQuery(
    {
      body: searchVenuesPayload as TSearchVenuesPayload,
    },
    {
      skip: isWorkDesk || isSomeDayEmpty || !filterDays.length,
    },
  );

  const {
    data: searchListQueryData,
    isFetching: isVenuesListFetching,
    isSuccess: isVenuesListSuccess,
  } = searchListQuery;

  const {
    totalFilteredRecords,
    pageItems = [],
    size,
  } = searchListQueryData || {};

  const accommodationIds = pageItems.map(
    ({accommodationId}) => accommodationId,
  );

  const {data: calculatedList, isFetching: isCalcListFetching} =
    useGetCalculatedListQuery(
      {
        body: {
          accommodationIds,
          filterData: filterDays,
          pageItems,
          size,
          totalFilteredRecords,
        },
        // pagination: {},
      },
      {
        skip:
          isWorkDesk ||
          isVenuesListFetching ||
          !isVenuesListSuccess ||
          !pageItems.length,
        // selectFromResult: ({data, ...rest}) => ({data, ...rest}),
      },
    );

  const {data: desksList, isFetching: isDesksListFetching} =
    useGetSearchDesksListQuery(desksQueryParams, {
      skip: !isWorkDesk,
    });

  /**
   * search list switching from full to empty
   * If we already get the list of venues from some request & then change the request to somewhere no venues are found
   * we need to update the result of the list from the previous response on an empty obj
   */
  const meetingRoomsList = pageItems.length ? calculatedList : {};

  return {
    venuesList: (isWorkDesk ? desksList : meetingRoomsList) || {},
    isVenuesListLoading: isWorkDesk
      ? isDesksListFetching
      : isVenuesListFetching || isCalcListFetching,
  };
}

import _get from 'lodash/get';
import {message} from 'antd';

import {
  addCorporateAccountCompanyUser,
  setAnnouncements,
  updateAnnouncements,
  setAnnouncementsLoading,
  setAnnouncementUpdateLoadingId,
  setCorporateAccountCompanies,
  setCorporateAccountCompaniesLoading,
  setCorporateAccountCompanyUsers,
  setCorporateAccountCompanyUsersLoading,
  setTotalCorporateAccountCompanies,
  setTotalCorporateAccountCompanyUsers,
  setTotalUnassignedUsers,
  setUnassignedUsers,
  setUnassignedUsersLoading,
  setAnnouncementIdToEdit,
  setImportedCompany,
  setIsLoading,
  setCorporateAccountCompany,
  setTermsAndConditionsFile,
  clearTermsAndConditionsFile,
  setCancellationPoliciesFile,
  clearCancellationPoliciesFile,
} from './adminSlice';

import {
  IAddCorporateAccountCompanyPayload,
  IAddCorporateAccountPropertyManagerPayload,
  IAnnouncementRequest,
  IAnnouncementRespond,
  ICancellationPolicyToOrderUploadRequest,
  ICorporateAccountCompany,
  IDocumentsFileResponse,
  IEditAnnouncementRequest,
  IEditCorporateAccountCompanyUserPayload,
  IGetCorporateAccountCompaniesPayload,
  IGetCorporateAccountCompanyUsersPayload,
  IGetCorporateAccountCompanyUsersResponse,
  IGetUnassignedUsersPayload,
  IReassignCorporateAccountCompanyUserPayload,
  ITermsAndConditionUploadRequest,
  IUnassignCorporateAccountCompanyUserPayload,
  IUpdateCorporateAccountCompanyPayload,
} from './types';
import {TContainer} from 'app';
import {AppDispatch, RootState} from 'store/types';
import {
  getCancellationPolicyLinkUrlById,
  getTermsAndConditionLinkUrlById,
} from 'utils/helpers';
import {TUploadFile} from '../../view/components/Uploader/types';

export const getAnnouncements =
  () =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setAnnouncementsLoading(true));
    await adminContainer.getAnnouncements({
      onSuccess: (response: IAnnouncementRespond[]) => {
        dispatch(setAnnouncements(response));
        dispatch(setAnnouncementsLoading(false));
      },
      onError: () => {
        dispatch(setAnnouncementsLoading(false));
      },
    });
  };

export const editAnnouncement =
  (data: IEditAnnouncementRequest, callback?: () => void) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    const {id} = data;
    dispatch(setAnnouncementUpdateLoadingId(id));
    await adminContainer.editAnnouncement({
      payload: data,
      onSuccess: (response: IAnnouncementRespond) => {
        dispatch(updateAnnouncements(response));
        dispatch(setAnnouncementUpdateLoadingId(0));
        dispatch(setAnnouncementIdToEdit(0));
        if (callback) callback();
      },
      onError: () => {
        dispatch(setAnnouncementUpdateLoadingId(0));
      },
    });
  };

export const addAnnouncement =
  (data: IAnnouncementRequest, callback: () => void) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.addAnnouncement({
      payload: data,
      onSuccess: (response: IAnnouncementRespond) => {
        const state = getState();
        dispatch(setAnnouncements([response, ...state.admin.announcements]));
        callback();
      },
      onError: () => {},
    });
  };

export const getCorporateAccountCompanies =
  (data: IGetCorporateAccountCompaniesPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setCorporateAccountCompaniesLoading(true));
    await adminContainer.getCorporateAccountCompanies({
      payload: data,
      onSuccess: (response: ICorporateAccountCompany[]) => {
        dispatch(setCorporateAccountCompanies(response));
      },
      onError: () => {},
    });
  };

export const reimportCorporateAccountCompany =
  (companyId: number) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setCorporateAccountCompaniesLoading(true));
    await adminContainer.reimportCorporateAccountCompany({
      payload: companyId,
      onSuccess: (response: ICorporateAccountCompany) => {
        dispatch(setCorporateAccountCompany(response));

        const companies = getState().admin.corporateAccountCompanies;
        const updatedCompanies = companies.map((company) => {
          if (company.id === response.id) return response;
          return company;
        });
        dispatch(setCorporateAccountCompanies(updatedCompanies));
      },
      onError: () => {
        dispatch(setCorporateAccountCompaniesLoading(false));
      },
    });
  };

export const getImportedCompany =
  (companyId: number) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setIsLoading(true));
    await adminContainer.getImportedCompany({
      payload: companyId,
      onSuccess: (response: ICorporateAccountCompany) => {
        dispatch(setImportedCompany(response));
      },
      onError: () => {
        dispatch(setIsLoading(false));
        dispatch(setImportedCompany(null));
      },
    });
  };

export const getCorporateAccountCompanyById =
  (companyId: number) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setIsLoading(true));
    await adminContainer.getCorporateAccountCompanyById({
      payload: companyId,
      onSuccess: (company: ICorporateAccountCompany) => {
        dispatch(setCorporateAccountCompany(company));
      },
      onError: () => {
        dispatch(setIsLoading(false));
      },
    });
  };

export const addCorporateAccountCompany =
  (data: IAddCorporateAccountCompanyPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.addCorporateAccountCompany({
      payload: data,
      onSuccess: () => {
        const size = _get(getState(), 'admin.corporateAccountCompaniesSize');
        dispatch(getCorporateAccountCompanies({page: 0, size}));
        dispatch(getTotalCorporateAccountCompanies());
        message.success('The company has been added!');
      },
      onError: () => {},
    });
  };

export const updateCorporateAccountCompany =
  (data: IUpdateCorporateAccountCompanyPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.updateCorporateAccountCompany({
      payload: data,
      onSuccess: () => {
        const size = _get(getState(), 'admin.corporateAccountCompaniesSize');
        const page = _get(getState(), 'admin.corporateAccountCompaniesPage');
        dispatch(getCorporateAccountCompanies({page, size}));
        const successMsg = `The company ${data.name} has been updated!`;
        message.success(successMsg);
      },
      onError: () => {},
    });
  };

export const getTotalCorporateAccountCompanies =
  () =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setCorporateAccountCompaniesLoading(true));
    await adminContainer.getTotalCorporateAccountCompanies({
      onSuccess: (response: number) => {
        dispatch(setTotalCorporateAccountCompanies(response));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong fetching companies!',
          key: 'errorMessage',
        });
        dispatch(setCorporateAccountCompaniesLoading(false));
      },
    });
  };

export const getCorporateAccountCompanyUsers =
  (data: IGetCorporateAccountCompanyUsersPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setCorporateAccountCompanyUsersLoading(true));
    await adminContainer.getCorporateAccountCompanyUsers({
      payload: data,
      onSuccess: (response: IGetCorporateAccountCompanyUsersResponse) => {
        dispatch(setCorporateAccountCompanyUsers(response.pageItems));
        dispatch(
          setTotalCorporateAccountCompanyUsers(response.totalFilteredRecords),
        );
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong fetching company users!',
          key: 'errorMessage',
        });
        dispatch(setCorporateAccountCompanyUsersLoading(false));
      },
    });
  };

export const getUnassignedUsers =
  (data: IGetUnassignedUsersPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    dispatch(setUnassignedUsersLoading(true));
    await adminContainer.getUnassignedUsers({
      payload: data,
      onSuccess: (response: IGetCorporateAccountCompanyUsersResponse) => {
        dispatch(setUnassignedUsers(response.pageItems));
        dispatch(setTotalUnassignedUsers(response.totalFilteredRecords));
      },
      onError: (error: any) => {
        console.error(error);
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong fetching unassigned users!',
          key: 'errorMessage',
        });
        dispatch(setUnassignedUsersLoading(false));
      },
    });
  };

export const addCorporateAccountPropertyManager =
  (data: IAddCorporateAccountPropertyManagerPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.addCorporateAccountPropertyManager({
      payload: data,
      onSuccess: (response: IAddCorporateAccountPropertyManagerPayload) => {
        const {firstName, lastName, email, phoneNumber, accountId, userId} =
          response;

        dispatch(
          addCorporateAccountCompanyUser({
            userId: userId || 0,
            userFirstName: firstName,
            userLastName: lastName,
            userEmail: email,
            userPhone: phoneNumber,
            corporateAccountId: accountId,
            isHighlighted: true,
          }),
        );

        message.success('The user has been added!');
      },
      onError: () => {},
    });
  };

export const editCorporateAccountCompanyUser =
  (data: IEditCorporateAccountCompanyUserPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.editCorporateAccountCompanyUser({
      payload: data,
      onSuccess: () => {
        const {accountId} = data;
        const page = _get(getState(), 'admin.corporateAccountCompanyUsersPage');
        const size = _get(getState(), 'admin.corporateAccountCompanyUsersSize');
        dispatch(getCorporateAccountCompanyUsers({page, size, id: +accountId}));
        message.success('The user has been edited!');
      },
      onError: () => {},
    });
  };

export const unassignCorporateAccountCompanyUser =
  (data: IUnassignCorporateAccountCompanyUserPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.unassignCorporateAccountCompanyUser({
      payload: data,
      onSuccess: () => {
        const {corporateAccountId} = data;
        const page = _get(getState(), 'admin.corporateAccountCompanyUsersPage');
        const size = _get(getState(), 'admin.corporateAccountCompanyUsersSize');
        dispatch(
          getCorporateAccountCompanyUsers({page, size, id: corporateAccountId}),
        );
        message.success('The user has been unassigned!');
      },
      onError: () => {},
    });
  };

export const reassignCorporateAccountCompanyUser =
  (data: IReassignCorporateAccountCompanyUserPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {adminContainer}: TContainer,
  ) => {
    await adminContainer.reassignCorporateAccountCompanyUser({
      payload: data,
      onSuccess: () => {
        const {currentAccountId} = data;
        const page = _get(getState(), 'admin.corporateAccountCompanyUsersPage');
        const size = _get(getState(), 'admin.corporateAccountCompanyUsersSize');
        const unassignedUsersPage = _get(
          getState(),
          'admin.unassignedUsersPage',
        );
        const unassignedUsersSize = _get(
          getState(),
          'admin.unassignedUsersSize',
        );
        // we are in some companyAccount page
        if (currentAccountId) {
          dispatch(
            getCorporateAccountCompanyUsers({page, size, id: currentAccountId}),
          );
          // we are in unmanagedAccounts page
        } else {
          dispatch(
            getUnassignedUsers({
              page: unassignedUsersPage,
              size: unassignedUsersSize,
            }),
          );
        }
        message.success('The user has been reassigned!');
      },
      onError: () => {},
    });
  };

const mapDocumentFile = (
  data: IDocumentsFileResponse,
  isCancellationPolicy = false,
): Partial<TUploadFile> | null => {
  if (!data) {
    return null;
  }
  return {
    id: data.id,
    name: data.fileName,
    uploadDate: new Date(data.validFrom),
    blob: isCancellationPolicy
      ? getCancellationPolicyLinkUrlById(data.id)
      : getTermsAndConditionLinkUrlById(data.id),
  };
};

export const uploadTermsAndConditionByFkey =
  ({file, fkey}: ITermsAndConditionUploadRequest) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.uploadTermsAndCondition({
      payload: {file, fkey},
      onSuccess: (data) => {
        dispatch(setTermsAndConditionsFile(mapDocumentFile(data)));
      },
      onError: () => {},
    });
  };

export const deleteTermsAndConditionById =
  (termsAndConditionsId: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.deleteTermsAndCondition({
      payload: termsAndConditionsId,
      onSuccess: () => {
        message.success('The file has been deleted!');
        dispatch(clearTermsAndConditionsFile());
      },
      onError: () => {
        message.error('The file has not been deleted!');
      },
    });
  };

export const getActualTermsAndConditionsByFkey =
  (fkey: number) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.getActualTermsAndConditionsByFkey({
      payload: fkey,
      onSuccess: (data: IDocumentsFileResponse[]) => {
        dispatch(setTermsAndConditionsFile(mapDocumentFile(data[0])));
      },
      onError: () => {},
    });
  };

export const uploadCancellationPolicyByFkey =
  ({file, fkey}: ITermsAndConditionUploadRequest) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.uploadCancellationPolicy({
      payload: {file, fkey},
      onSuccess: (data) => {
        dispatch(setCancellationPoliciesFile(mapDocumentFile(data, true)));
      },
      onError: () => {},
    });
  };

export const deleteCancellationPolicyById =
  (termsAndConditionsId: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.deleteCancellationPolicy({
      payload: termsAndConditionsId,
      onSuccess: () => {
        message.success('The file has been deleted!');
        dispatch(clearCancellationPoliciesFile());
      },
      onError: () => {
        message.error('The file has not been deleted!');
      },
    });
  };

export const getCancellationPolicyByFkey =
  (fkey: number) =>
  async (
    dispatch: AppDispatch,
    _getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.getActualCancellationPolicyByFkey({
      payload: fkey,
      onSuccess: (data: IDocumentsFileResponse[]) => {
        dispatch(setCancellationPoliciesFile(mapDocumentFile(data[0], true)));
      },
      onError: () => {},
    });
  };

export const attachCancellationPolicyToOrder =
  (
    {file, orderNumber}: ICancellationPolicyToOrderUploadRequest,
    onSuccess?: () => void,
  ) =>
  async (
    _dispatch: AppDispatch,
    _getState: () => RootState,
    {documentsContainer}: TContainer,
  ) => {
    await documentsContainer.attachCancellationPolicyToOrder({
      payload: {file, orderNumber},
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: () => {},
    });
  };

import styled from 'styled-components';

import {
  fontSizeXXSm,
  fontWeightExtraBold,
  lineHeightXSm,
  NW2Gray200Color,
  NW2Gray600Color,
  offsetDef,
  offsetLg,
  offsetXLg,
} from 'constants/styleVars';

export const AnchorMenuWrapper = styled.aside`
  margin-top: ${offsetXLg};
`;

export const AnchorMenuTitle = styled.div`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  font-weight: ${fontWeightExtraBold};
  color: ${NW2Gray600Color};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetLg};
  padding-left: ${offsetDef};
`;

export const AnchorMenuContainer = styled.div`
  position: sticky;
  top: ${offsetXLg};
  border-left: 1px solid ${NW2Gray200Color};
`;

export const AnchorMenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

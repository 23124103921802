import styled from 'styled-components';

import {LinkStyles} from 'view/components/Typography/Typography';

import {
  blackColor,
  borderColor,
  borderRadiusXLg,
  fontSizeMd,
  fontSizeXSm,
  fontWeightBold,
  lineHeightLg,
  lineHeightSm,
  NW2Gray600Color,
  NW2Gray900Color,
  offsetDef,
  offsetXLg,
  offsetXXSm,
} from 'constants/styleVars';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetXLg};
  padding: ${offsetXLg};
  border: 1px solid ${borderColor};
  border-radius: ${borderRadiusXLg};
  margin-bottom: ${offsetDef};
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
  margin-bottom: ${offsetDef};
`;

export const Title = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  color: ${NW2Gray900Color};
  font-weight: ${fontWeightBold};
`;

export const SubTitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  color: ${NW2Gray600Color};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${offsetDef};
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    text-decoration: underline;
    font-weight: bold;
    color: ${blackColor};

    &:hover {
      text-decoration: none;
    }
  }
`;

export const ListItem = styled.li<{direction?: 'column'}>`
  display: flex;
  flex-direction: ${({direction}) => direction || 'row'};
  gap: 4px;
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const DeclineLink = styled.span`
  ${LinkStyles};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const UploadedFileNote = styled.div`
  color: #697077;
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
`;
export const UploadedFileWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
export const UploadedFileNameWrap = styled.div`
  display: flex;
  gap: ${offsetDef};
`;

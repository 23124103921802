import React from 'react';
import Title from 'antd/es/typography/Title';
import styled from 'styled-components';

import {Card} from 'antd';

const StyledTitle = styled(Title)`
  text-align: center;
`;

type PropsType = {
  titleText: string;
};

const InfoCard = ({titleText}: PropsType) => {
  return (
    <Card>
      <StyledTitle level={4}>{titleText}</StyledTitle>
    </Card>
  );
};

export default InfoCard;

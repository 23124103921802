import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Button, Form, notification} from 'antd';

import FormItemPassword from '../FormItemPassword';
import GetStartedImg from 'img/getStartedImg.jpg';
import ImageCard from '../ImageCard';
import CustomList from '../CustomList';

import {
  IPasswordRecover,
  isPasswordValid,
  UserCommonValidation,
} from 'infra/common/venueValidation';
import {IForgetPassword} from 'types/dto/IResetPassword.type';
import {activeColor, borderRadiusDef, offsetDef} from 'constants/styleVars';
import {PageTitle} from '../Typography';
import {TVerticalAlign} from 'types/app';
import {list} from './helper';
import {TSubmitConfirmForgotPassword} from 'view/auth/utils/useForgotPassword';

type PropsType = {
  moveToSuccessPagePath: string;
  pageName: string;
  request: (data: TSubmitConfirmForgotPassword) => void;
};

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledHelpTextContainer = styled.div`
  background: ${activeColor};
  padding: ${offsetDef};
  margin-top: ${offsetDef};
  border-radius: ${borderRadiusDef};
`;

const StyledContainer = styled.section<{verticalAlign?: TVerticalAlign}>`
  display: flex;
  justify-content: center;

  ${({verticalAlign}) =>
    verticalAlign
      ? `
        min-height: calc(100vh - 65px);
        align-items: ${verticalAlign};
      `
      : ''}
`;

const EnterPassword = ({
  moveToSuccessPagePath,
  request,
  pageName,
}: PropsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const query: any = new URLSearchParams(location.search);

  const email = decodeURI(query.get('email'));
  const code = query.get('code');
  const [firstPassword, updateFirstPassword] = useState('');
  const [secondPassword, updateSecondPassword] = useState('');

  const moveToSuccessPage = () => {
    navigate({
      pathname: moveToSuccessPagePath,
    });
  };

  const onSubmit = async () => {
    const isValid = UserCommonValidation.validationUserEnterPassword(
      firstPassword,
      secondPassword,
      IPasswordRecover.FORGET,
    );
    if (!isValid) {
      form.validateFields(['firstPassword', 'secondPassword']);
    } else {
      if (isPasswordValid(firstPassword)) {
        if (isPasswordValid(secondPassword)) {
          if (firstPassword === secondPassword) {
            const iResetPassword: IForgetPassword = {
              email,
              code,
              newPassword: secondPassword,
            };

            request({
              ...iResetPassword,
              callback: () => {
                moveToSuccessPage();
                // dispatch(setCognitoUserRole(role));
              },
            });
          } else {
            const notificationOptions = {
              message: 'The first password does not match the second one',
            };
            notification.error(notificationOptions);
          }
        } else {
          const notificationOptions = {
            message:
              'Second password must have one letter, one Upper letter, one number, one special symbol and has 8 symbols',
          };
          notification.error(notificationOptions);
        }
      } else {
        const notificationOptions = {
          message:
            'First password must have one letter, one Upper letter, one number, one special symbol and has 8 symbols',
        };
        notification.error(notificationOptions);
      }
    }
  };

  return (
    <StyledContainer verticalAlign='center'>
      <ImageCard
        coverImage={GetStartedImg}
        coverImageAlt='Get started cover image'
        coverTitle='Get started with NewWork'
        coverText='A modern digital service to manage your distributed workplace'
      >
        <PageTitle>{pageName}</PageTitle>
        <Form form={form} onFinish={onSubmit}>
          <section>
            <FormItemPassword
              name='firstPassword'
              placeholder='First password'
              message='Password is required'
              functionUpdate={updateFirstPassword}
            />
          </section>
          <section>
            <FormItemPassword
              name='secondPassword'
              placeholder='Second password'
              message='Password is required'
              functionUpdate={updateSecondPassword}
            />
          </section>

          <StyledButtonContainer>
            <Button type='primary' loading={false} htmlType='submit'>
              Continue
            </Button>
          </StyledButtonContainer>
        </Form>

        <StyledHelpTextContainer>
          <h5>In order to protect your account, make sure your password:</h5>
          <CustomList size='small' list={list} />
        </StyledHelpTextContainer>
      </ImageCard>
    </StyledContainer>
  );
};

export default EnterPassword;

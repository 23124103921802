import React, {useEffect, useState} from 'react';

import NW2Button from 'view/components/NW2Button';
import Icon from 'view/components/Icon';
import CustomModal from 'view/components/CustomModal';

import {
  DeclineBody,
  DeclineBodyText,
  DeclineFooterContainer,
  DeclineHeaderTitle,
} from '../BookingChangeOverviewSidebar.styles';
import {modalSmallWidth} from 'constants/styleVars';
import {EBookingChangesStatus, EBookingOrderStatus} from 'types/bookingOrders';
import {EBookingStatus} from 'types/booking';
import DateUtils, {DateFormats} from 'utils/dateUtils';

type TStatusMessage = Partial<
  Record<
    EBookingOrderStatus,
    {
      headerText: string;
      bodyText: string | JSX.Element;
    }
  >
>;

type TProps = {
  isShowModal: boolean;
  handleChangesStatusModal: (status: boolean) => void;
  bookingStatus?: EBookingStatus | EBookingOrderStatus;
  updatedAt: string;
  isRTCinSearchParam: boolean;
};

const BookingChangesStatusModal = ({
  isShowModal,
  handleChangesStatusModal,
  bookingStatus,
  updatedAt,
  isRTCinSearchParam,
}: TProps) => {
  const [currentStatus, setCurrentStatus] = useState(bookingStatus);

  useEffect(() => {
    setCurrentStatus(bookingStatus);
  }, [bookingStatus]);

  const isRTCPendingConfirmed = bookingStatus === EBookingOrderStatus.CONFIRMED;
  const isRTCPendingDeclined =
    bookingStatus === EBookingOrderStatus.RTC_DECLINED;
  const isRTCEditCanceledByConfirm =
    bookingStatus === EBookingOrderStatus.EDIT_CANCELED_BY_CONFIRM;

  const shouldReturnNull =
    !bookingStatus || isRTCPendingConfirmed || isRTCEditCanceledByConfirm;

  if (shouldReturnNull) return null;

  const editDate = DateUtils.getDateWithoutHours(
    updatedAt,
    DateFormats['1 Jan, 2023'],
  );

  const statusMessages: TStatusMessage = {
    [EBookingOrderStatus.RTC_DECLINED]: {
      headerText: EBookingChangesStatus.RTC_DECLINED,
      bodyText: isRTCinSearchParam ? (
        `You have already declined this request on ${editDate}.`
      ) : (
        <>
          {' '}
          We will send an email to you and the customer confirming that the
          requested changes have been declined.
          <br /> <br />
          You will revert to your old booking.
        </>
      ),
    },
    [EBookingOrderStatus.RTC_CANCELLED]: {
      headerText: EBookingChangesStatus.RTC_CANCELLED,
      bodyText: 'Unfortunately, the booker has already cancelled this request.',
    },
    [EBookingOrderStatus.RTC_EXPIRED]: {
      headerText: EBookingChangesStatus.RTC_EXPIRED,
      bodyText: `
      Unfortunately, this booking request has expired as we did not receive
      a response within the designated 24-hour timeframe.
    `,
    },
  };

  const messageData = statusMessages[currentStatus as EBookingOrderStatus];

  const headerText = messageData ? messageData.headerText : '';
  const bodyText = messageData ? messageData.bodyText : '';

  const header = <DeclineHeaderTitle>{headerText}</DeclineHeaderTitle>;
  const footer = (
    <DeclineFooterContainer>
      <NW2Button
        buttonType='secondary'
        onClick={() => handleChangesStatusModal(false)}
      >
        {isRTCPendingDeclined && !isRTCinSearchParam ? 'okay' : 'I understand'}
      </NW2Button>
    </DeclineFooterContainer>
  );

  const body = (
    <DeclineBody>
      <Icon
        icon={
          isRTCPendingDeclined && !isRTCinSearchParam
            ? 'CHECKED'
            : 'RED_CROSSED'
        }
        size={44}
      ></Icon>
      <DeclineBodyText>{bodyText}</DeclineBodyText>
    </DeclineBody>
  );

  return (
    <CustomModal
      isShowModal={isShowModal}
      width={modalSmallWidth}
      header={header}
      footer={footer}
    >
      {body}
    </CustomModal>
  );
};

export default BookingChangesStatusModal;

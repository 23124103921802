import React from 'react';
import {useAppSelector} from 'store/hooks';

import SupplierUserMenu from './SupplierUserMenu';

import {setOpenLoginPopup, setOpenRegisterPopup} from 'store/app/appSlice';
import {useDispatch} from 'react-redux';
import {TMenuListPropsWithSpaces} from '../types';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {IUser} from 'types/dto/IUser.types';
import {
  LoginRegisterBlockWrapper,
  LoginRegisterBlockButton,
  LoginRegisterBlockUserMenu,
} from 'view/common/Header/Header.styles';

function HeaderLoginRegisterBlock({role, ...rest}: TMenuListPropsWithSpaces) {
  const dispatch = useDispatch();

  const customer: IUser = useAppSelector(({app}) => app.user);
  const token = useAppSelector(({app}) => app.token);

  const isUserLoggedIn = !!customer.email && token;
  const supplierRoles =
    role === EUserRoleCognito.ROLE_SUPPLIER ||
    role === EUserRoleCognito.ROLE_PROPERTY_MANAGER ||
    role === EUserRoleCognito.ROLE_ADMIN;

  const handleLoginClick = () => {
    dispatch(setOpenLoginPopup({openLoginPopup: true}));
  };
  const handleRegisterClick = () => {
    dispatch(setOpenRegisterPopup({openRegisterPopup: true}));
  };

  return (
    <LoginRegisterBlockWrapper>
      {isUserLoggedIn ? (
        supplierRoles ? (
          <SupplierUserMenu role={role} {...rest} />
        ) : (
          <LoginRegisterBlockUserMenu role={customer.role} user={customer} />
        )
      ) : (
        <>
          <LoginRegisterBlockButton
            buttonType='link'
            onClick={handleLoginClick}
          >
            Login
          </LoginRegisterBlockButton>
          <LoginRegisterBlockButton
            inverted
            inline
            onClick={handleRegisterClick}
          >
            register
          </LoginRegisterBlockButton>
        </>
      )}
    </LoginRegisterBlockWrapper>
  );
}

export default HeaderLoginRegisterBlock;

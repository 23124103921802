import {useCallback, useEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'store/hooks';
import {setIsBTCApp} from 'store/app/appSlice';
import {loginByJwtToken} from 'store/app/apiActions';
import {useNavigate} from 'react-router-dom';
import {Routes} from 'constants/routes';

function useFlutterWebView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isBTCApp = useAppSelector(({app}) => app.isBTCApp);

  const setupForBTCApp = useCallback(() => {
    dispatch(setIsBTCApp(true));

    return {
      loginByJWT: async (jwtToken: string) => {
        await dispatch(loginByJwtToken(jwtToken));

        // Added "reloading" to attach corporateId to request for some cases
        if (location.pathname === Routes.venuesList) {
          navigate(window.location?.pathname + window.location?.search);
        }
      },
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    if (window.isBTC && !window.setupForBTCApp) {
      window.setupForBTCApp = setupForBTCApp;
      window.runBTCInitCommands && window.runBTCInitCommands();
    }

    // TODO: should be removed when most part of BTC users will update their application
    if (window.BTC_APP === 'BTC_APP') {
      setupForBTCApp();
    }
  }, [setupForBTCApp]);

  const postToFlutterApp = () => {
    console.log('returnToBTCHomeScreen');
    window.returnToBTCHomeScreen.postMessage('returnToBTCHomeScreen');
  };

  return {
    postToFlutterApp,
    isBTCApp,
  };
}

export default useFlutterWebView;

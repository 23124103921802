import React, {ReactNode} from 'react';
import {Card} from 'antd';

type PropsType = {
  title: string;
  extra?: ReactNode;
  children: ReactNode;
};

const cardStyles = {
  marginBottom: '24px',
};

const VenueInfoBlockCard = ({title, extra, children}: PropsType) => {
  return (
    <Card type='inner' title={title} extra={extra} style={cardStyles}>
      {children}
    </Card>
  );
};

export default VenueInfoBlockCard;

import React from 'react';
import {Form} from 'react-final-form';
import {css} from 'styled-components';

import useGoBackFromInfoPage from 'view/common/NW2MultiModal/hooks/useGoBackFromInfoPage';

import {NW2ModalTwoColumnsContent} from 'view/components/NW2ModalTwoColumns/NW2ModalTwoColumnsContent';
import {PreviewAnnouncementModal, AnnouncementForm} from './components';

import {addAnnouncement, editAnnouncement} from 'store/admin/apiActions';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {EAnnouncementsVisibilityAreas} from 'store/admin/types';
import {setAnnouncementIdToEdit} from 'store/admin/adminSlice';
import {TFormData, validateAnnouncementForm} from './helpers';

import {offsetDef, offsetXXXXLg, pagePadding, xlg} from 'styles/configs';

const customPadding = `${offsetXXXXLg} ${pagePadding} 0`;
const leftColumnStyles = css`
  padding: 0 !important;
`;
const rightColumnStyles = css`
  position: relative;
  overflow: initial !important;
  padding: ${offsetDef};
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50%;
    right: -50%;
    background: linear-gradient(137.11deg, #0088a6 2.89%, #003440 59.24%);
    
    @media (min-width: ${xlg}) {
      top: -50%;
      left: 0;
      right: -50%;
      bottom: -50%;
    }
  },
`;

const modalProps = {
  customPadding,
  leftColumnStyles,
  rightColumnStyles,
  gridColumns: '1.4fr 2fr',
};

function AnnouncementAddEditModal() {
  const goBack = useGoBackFromInfoPage();
  const dispatch = useAppDispatch();

  const announcementToEdit = useAppSelector(({admin}) =>
    admin.announcements.find(({id}) => id === admin.announcementIdToEdit),
  );

  const initialHtmlString = announcementToEdit
    ? decodeURIComponent(atob(announcementToEdit.body))
    : '';

  const initialValues = {
    title: announcementToEdit?.title || '',
    subtitle: announcementToEdit?.subtitle || '',
    htmlString: initialHtmlString,
    isActiveSwitch: announcementToEdit ? announcementToEdit.isActive : true,
    visibilityAreas: announcementToEdit?.visibilityAreas || [
      EAnnouncementsVisibilityAreas.PUBLIC,
    ],
  };

  const handleOnSubmit = async (data: TFormData) => {
    const announcementPayload = {
      title: data.title,
      subtitle: data.subtitle,
      isActive: data.isActiveSwitch,
      body: btoa(encodeURIComponent(data.htmlString)),
      visibilityAreas: data.visibilityAreas,
    };

    if (announcementToEdit) {
      const editPayload = {
        id: announcementToEdit.id,
        ...announcementPayload,
      };
      await dispatch(editAnnouncement(editPayload, goBack));
    } else {
      await dispatch(addAnnouncement(announcementPayload, goBack));
    }
  };

  const handleOnCancel = () => {
    dispatch(setAnnouncementIdToEdit(0));
    goBack();
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validate={validateAnnouncementForm}
    >
      {({handleSubmit, errors, touched}) => {
        return (
          <NW2ModalTwoColumnsContent
            {...modalProps}
            title={`${announcementToEdit ? 'Edit' : 'Create'} announcement`}
            onToggleShow={handleOnCancel}
            colLeft={
              <AnnouncementForm
                handleSubmit={handleSubmit}
                initialHtmlString={initialHtmlString}
                isEditMode={!!announcementToEdit}
                onCancel={handleOnCancel}
                errors={errors}
                touched={touched}
              />
            }
            colRight={<PreviewAnnouncementModal />}
          />
        );
      }}
    </Form>
  );
}

export default AnnouncementAddEditModal;

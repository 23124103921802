import {Routes} from 'constants/routes';
import {TNmmNavMenuList} from 'types/header';
import {
  getAdminLayout,
  getCenteredLayout,
  getLogoutLayout,
} from '../layoutConfigs';
import {TdropdownItem} from 'view/components/NW2MenuItem/types';
import {SupplierHeaderItems, TMenuReturnType} from '../types';

const getUserMenuDropdownItems = (): TdropdownItem[] => {
  return [
    {
      id: SupplierHeaderItems.LogOut,
      title: 'Log out',
      link: Routes.login,
      isExternal: false,
    },
  ];
};

const userMenuList = (): TNmmNavMenuList[] => [
  {
    id: SupplierHeaderItems.Profile,
    text: 'Profile',
    isVisible: true,
    dropDownItems: getUserMenuDropdownItems(),
    icon: 'USER',
  },
];

export const adminMenu = (): TMenuReturnType => {
  return {
    layout: {
      defaultLayoutType: getAdminLayout(),
      customRoutesLayout: {
        [Routes.login]: getCenteredLayout(),
        [Routes.userCompleteRegistration]: getCenteredLayout(),
        [Routes.mainLanding]: getLogoutLayout(),
      },
    },
    userMenuList: userMenuList(),
  };
};

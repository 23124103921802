import styled, {css} from 'styled-components';

import {
  borderRadiusDef,
  errorColor,
  fontSizeXLg,
  fontSizeXSm,
  fontWeightBold,
  NW2Gray200Color,
  offsetDef,
  offsetXLg,
  offsetXSm,
  xLgBp,
} from 'constants/styleVars';

const COL_WIDTH = '326px';

export const Wrapper = styled.div<{columns?: number}>(({columns}) => {
  const columnTemplate = `repeat(${columns}, minmax(auto, ${COL_WIDTH}))`;

  return css`
    display: grid;
    grid-template-columns: ${columns ? columnTemplate : `1fr 1fr`};
    grid-column-gap: ${offsetXLg};
    grid-row-gap: ${offsetXLg};
    justify-items: start;
    align-items: center;

    ${columns === 3 &&
    css`
      > div {
        &:last-child {
          grid-column-start: 2;
          grid-column-end: 3;
        }
      }
    `};

    ${columns
      ? css`
          @media (min-width: ${xLgBp}px) and (max-width: 1400px) {
            grid-template-columns: ${columns === 3
              ? columnTemplate
              : COL_WIDTH};
          }
          @media (max-width: 991px) {
            grid-template-columns: ${COL_WIDTH};
          }
        `
      : css`
          > div {
            width: 100%;
          }
        `}
  `;
});

export const Container = styled.div<{width?: string}>`
  display: flex;
  width: 100%;
  max-width: ${({width}) => width || COL_WIDTH};
  border: 1px solid ${NW2Gray200Color};
  border-radius: ${borderRadiusDef};
`;

export const InputBlock = styled.div`
  width: 100%;
  min-width: 193px;
  padding: ${offsetDef};
`;

export const StyledItemInputWrapper = styled.div`
  padding-top: ${offsetDef};
`;

export const SchemaImage = styled.img<{src: string}>`
  display: block;
  background: no-repeat ${({src}) => src && css`url(${src});`};
  background-size: auto 100%;
  height: 100%;
  border-radius: 0 ${borderRadiusDef} ${borderRadiusDef} 0;
`;

export const Error = styled.div<{columns: number}>`
  display: flex;
  justify-content: center;
  font-size: ${fontSizeXSm};
  line-height: ${fontSizeXLg};
  font-weight: ${fontWeightBold};
  color: ${errorColor};
  margin-top: ${offsetXSm};
  ${({columns}) =>
    columns === 2 &&
    css`
      width: 652px;
    `}
`;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import _get from 'lodash/get';

import NMMSpaceDetails from './common/NMMSpacesDetails/NMMSpacesDetails';
import SupplierLayout from 'view/components/SupplierLayout';
import useToggle from 'hooks/useToggle';
import useVenue from 'view/venue/hooks/useVenue';
import useGlobalVenue from 'view/venue/hooks/useGlobalVenue';
import useSpaces from 'view/venue/hooks/useSpaces';
import useWorkInProgress from 'view/venue/hooks/useWorkInProgress';
import useAnchorsSpacesSection from 'view/venue/hooks/useAnchorsSpacesSection';
import ProfileSpaceContent from './ProfileSpaceContent';

import {AddDesk} from '../AddSpace/AddDesk';
import {AddMeetingRoom} from '../AddSpace/AddMeetingRoom';

import {PATH_TO_REDUCER_VENUE_DATA} from 'constants/venue';
import {filterSpacesWithFloorsByRoomType} from '../Header/helpers/NMMSpaceHelper';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IAmenity} from 'types/dto/IAmenity';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {ESpacesHashes, ESpacesPages} from './types';
import {EVenueSupplierPagesWithHeader} from 'view/venue/NW2VenueProfile/types';
import {IAddSpaceProps} from '../AddSpace/types';
import {ESpaceSelectedTab} from 'types/venue';

interface IProps {
  accommodationType: EAccommodationType;
}

const NMMSpaces = ({accommodationType}: IProps) => {
  const [globalVenue] = useGlobalVenue();
  const [venue] = useVenue();
  const [spaces] = useSpaces();
  const [wip] = useWorkInProgress();

  const venueFloors: number[] = useSelector((state) =>
    _get(state, `${PATH_TO_REDUCER_VENUE_DATA}.location.floors`),
  );
  const roomAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.roomAmenities'),
  );
  const deskAmenities: IAmenity[] = useSelector((state) =>
    _get(state, 'venue.deskAmenities'),
  );

  const isExternalVenue = accommodationType === EAccommodationType.VENUE;

  // Sections and active section
  const navigate = useNavigate();
  const {hash} = useLocation();
  const pageType = hash.substring(1);
  const [anchorSections] = useAnchorsSpacesSection(spaces, wip, pageType);

  const [initialActiveSection, setInitialActiveSection] = useState(
    EVenueSupplierPagesWithHeader.MEETING_ROOM,
  );

  const sidebarSections = useMemo(
    () =>
      Object.keys(ESpacesPages).map((key) => ({
        title:
          EVenueSupplierPagesWithHeader[
            key as keyof typeof EVenueSupplierPagesWithHeader
          ],
        href: ESpacesHashes[key as keyof typeof ESpacesHashes],
      })),
    [],
  );

  const result = sidebarSections.find((item) => item.href === pageType);

  // correct selection of spaces on switching multiple venues
  useEffect(() => {
    if (!venue?.id) return;

    navigate({
      // Previously we had here: 'isExternalVenue ? `${Routes.publicVenueSpaces}` : `${Routes.corporateVenueSpaces}`',
      // but looks like it overhead.
      // Using location.pathname could be enough especially when now we have additional Agent role.
      pathname: location.pathname,
      search: `?id=${venue?.id}`,
      hash,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, venue?.id]);

  useEffect(() => {
    if (result) {
      setInitialActiveSection(result.title);
    }
  }, [result]);

  const handleSectionChange = useCallback(
    (sectionName: EVenueSupplierPagesWithHeader) => {
      setInitialActiveSection(sectionName);
      const indexOfS = Object.values(ESpacesPages).indexOf(
        sectionName as unknown as ESpacesPages,
      );
      const key = Object.keys(ESpacesPages)[indexOfS];
      const pagesHash = ESpacesHashes[key as keyof typeof ESpacesHashes];
      navigate({
        // Same as higher
        pathname: location.pathname,
        search: `?id=${venue?.id}`,
        hash: `#${pagesHash}`,
      });
    },
    [navigate, venue?.id],
  );

  // room floors
  const [spaceFloor, setSpaceFloor] = useState<number | undefined>();

  const [isCreateRoomShowed, onCreateRoom] = useToggle(false);

  const handleCreateRoom = useCallback(
    (floor?: number) => {
      // set room floor if creation goes throw floor list
      setSpaceFloor(typeof floor === 'number' ? floor : undefined);

      onCreateRoom();
    },
    [onCreateRoom],
  );

  const spaceAmenities = useMemo(() => {
    switch (pageType) {
      case ESpaceSelectedTab.DESK:
        return deskAmenities;
      case ESpaceSelectedTab.ROOM:
        return roomAmenities;
      default:
        return [];
    }
  }, [deskAmenities, pageType, roomAmenities]);

  if (!globalVenue || !spaces || !venueFloors || !venue) return null;

  const meetingRooms = filterSpacesWithFloorsByRoomType(
    spaces,
    ERoomType.MEETING_ROOM,
  );
  const workDesks = filterSpacesWithFloorsByRoomType(
    spaces,
    ERoomType.WORK_SPACE,
  );

  const addSpaceProps: IAddSpaceProps = {
    venueId: venue?.id,
    spaceAmenities,
    spaceFloor,
    isExternalVenue,
    venueFloors,
    isShowed: isCreateRoomShowed,
    onToggleShow: handleCreateRoom,
    commonAmenities: venue.commonAmenities,
  };

  const spaceContentProps = {
    venue,
    accommodationType,
    anchorSections,
    handleCreateRoom,
    wip,
  };

  return (
    <SupplierLayout
      globalVenue={globalVenue}
      showHeaderForSection={initialActiveSection}
      initialActiveSection={initialActiveSection}
      sections={sidebarSections}
      sideBarTitle='contents'
      handleCreateRoom={handleCreateRoom}
      handleSectionChange={handleSectionChange}
    >
      {pageType === ESpacesHashes.MEETING_ROOM && (
        <>
          <ProfileSpaceContent
            roomType={ERoomType.MEETING_ROOM}
            spaces={meetingRooms}
            {...spaceContentProps}
          />

          <AddMeetingRoom {...addSpaceProps} />
        </>
      )}

      {pageType === ESpacesHashes.WORK_SPACE && (
        <>
          <ProfileSpaceContent
            roomType={ERoomType.WORK_SPACE}
            spaces={workDesks}
            {...spaceContentProps}
          />

          <AddDesk {...addSpaceProps} />
        </>
      )}

      <NMMSpaceDetails
        spaces={spaces}
        wip={wip}
        floors={venue.location.floors}
        isExternalVenue={isExternalVenue}
      />
    </SupplierLayout>
  );
};

export default NMMSpaces;

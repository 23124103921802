import React from 'react';

import TextValue from 'view/components/TextValue';

import {fontSizeMd} from 'styles/configs/typography';

function AboutMeeting({meetingName}: {meetingName?: string}) {
  return (
    <>
      <TextValue bold NW2Gray600 marginTop='0'>
        Meeting name:
      </TextValue>
      <TextValue fontSize={fontSizeMd}>{meetingName}</TextValue>
    </>
  );
}

export default AboutMeeting;

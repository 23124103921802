import styled from 'styled-components';

import {SectionTitle} from '../LegalDetails/LegalDetails.styles';
import {
  fontWeightExtraBold,
  offsetXSm,
  offsetDef,
  offsetXLg,
  offsetXXLg,
  offsetXXXXXLg,
  fontSizeMd,
  lineHeightLg,
  NW2Gray200Color,
} from 'constants/styleVars';

const TITLE_LINE_HEIGHT = '32px';
const CONTAINER_WIDTH = '443px';
const SIDEBAR_WIDTH = '240px';

export const Container = styled.div`
  width: ${CONTAINER_WIDTH};
`;

export const Title = styled.h1`
  font-size: ${offsetXLg};
  line-height: ${TITLE_LINE_HEIGHT};
  font-weight: ${fontWeightExtraBold};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: ${offsetXXXXXLg};
`;

export const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0;
  height: inherit;
`;

export const SectionWrapper = styled.div`
  padding: ${offsetXXXXXLg} 0;
  border-top: 1px ${NW2Gray200Color} solid;
`;

export const SectionRow = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  display: flex;
  align-items: center;
  gap: ${offsetDef};
  margin-bottom: ${offsetXSm};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SectionText = styled.span``;

export const FormActions = styled.div`
  display: flex;
  padding-top: ${offsetXXLg};
  gap: ${offsetXLg};
`;

// Anchors
export const AnchorsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr ${SIDEBAR_WIDTH};
  padding: 0 ${offsetXLg};
  grid-column-gap: ${offsetXLg};
`;

export const AnchorSections = styled.div<{isNoSeparators?: boolean}>`
  padding-top: ${offsetXXLg};
  padding-bottom: ${offsetXLg};

  section {
    &:nth-child(2),
    &:nth-child(8) {
      ${({isNoSeparators}) =>
        isNoSeparators
          ? ''
          : 'border-bottom: 1px solid ${NW2Gray200Color}; padding-bottom: ${offsetXXXXXLg};'}
  }
`;

import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import {Uploader} from 'view/components/Uploader/Uploader';
import NMMSubmitSection from 'view/components/NMMSubmitSection/NMMSubmitSection';
import NW2Button from 'view/components/NW2Button';

import {
  clearCancellationPolicyFile,
  setCancellationPolicyFile,
  setCancellationPolicyFileBlobPreview,
} from 'store/offers/offersSlice';
import {
  EUploaderLabel,
  EUploadedFileType,
  TUploadFile,
} from 'view/components/Uploader/types';
import {offsetSm, offsetXLg} from 'constants/styleVars';
import {StyledConfirmButton} from 'view/components/Uploader/Uploader.styles';
import {MODAL_BODY, MODAL_HEADER_CANCELLATION} from 'constants/app';

type TProps = {
  isShowUploaderModal: boolean;
  isOpenCancelModal: boolean;
  toggleUploaderModal: () => void;
  toggleCancelModal: () => void;
  cancellationPolicyFile: TUploadFile | null;
  setPolicyError: (isError: boolean) => void;
};

export const OfferCancellationPolicyFileUploaderModal: FC<TProps> = ({
  isShowUploaderModal,
  isOpenCancelModal,
  toggleUploaderModal,
  toggleCancelModal,
  cancellationPolicyFile,
  setPolicyError,
}) => {
  const dispatch = useDispatch();

  const onFileUpload = useCallback(
    (file: TUploadFile) => {
      // TODO: Can be removed if we will find a way to resolve issue with basic blob preview
      dispatch(
        setCancellationPolicyFileBlobPreview(
          URL.createObjectURL(file.originFileObj as any),
        ),
      );
      dispatch(setCancellationPolicyFile(file));
      setPolicyError(false);
    },
    [dispatch, setPolicyError],
  );

  const onFileDelete = () => {
    dispatch(clearCancellationPolicyFile());
  };

  const handleUploaderCancel = () => {
    toggleUploaderModal();
    dispatch(clearCancellationPolicyFile());
  };

  const handleCancelModalClose = () => {
    toggleCancelModal();
  };

  const handleDeleteFile = () => {
    onFileDelete();
    toggleCancelModal();
  };
  const isFile = cancellationPolicyFile?.type === 'application/pdf'; //check type from BE

  const commonUploaderProps = {
    label: EUploaderLabel.PDF_FILE,
    maxFiles: 1,
  };

  const commonSubmitSectionProps = {
    gap: offsetSm,
    justifyContent: 'flex-end',
    margin: '0',
  };

  const submitSectionProps = isShowUploaderModal
    ? {
        ...commonSubmitSectionProps,
        handleCancel: handleUploaderCancel,
        customConfirmButton: isFile ? (
          <NW2Button
            type='button'
            buttonType='primary'
            size='small'
            onClick={toggleUploaderModal}
          >
            Save
          </NW2Button>
        ) : undefined,
      }
    : {
        ...commonSubmitSectionProps,
        cancelLabel: 'close',
        handleCancel: handleCancelModalClose,
        customConfirmButton: (
          <StyledConfirmButton
            type='button'
            buttonType='primary'
            size='small'
            onClick={handleDeleteFile}
          >
            Delete
          </StyledConfirmButton>
        ),
      };

  let modalHeader = '';
  let modalBody = null;
  let modalWidth = 440;

  switch (true) {
    case isShowUploaderModal:
      modalHeader = 'Upload cancellation policy';
      modalBody = (
        <Uploader
          uploadedFile={cancellationPolicyFile}
          uploadedFileType={EUploadedFileType.PDF_FILE}
          onFileUpload={onFileUpload}
          onFileDelete={onFileDelete}
          modalBody={MODAL_BODY}
          modalHeader={MODAL_HEADER_CANCELLATION}
          {...commonUploaderProps}
        />
      );
      break;

    case isOpenCancelModal:
      modalHeader = MODAL_HEADER_CANCELLATION;
      modalBody = MODAL_BODY;
      modalWidth = 440;
      break;

    default:
      break;
  }

  return (
    <NW2ModalDrawer
      isShowed={isShowUploaderModal || isOpenCancelModal}
      header={modalHeader}
      isMobile={false}
      onClose={isShowUploaderModal ? toggleUploaderModal : toggleCancelModal}
      body={modalBody}
      modalWidth={modalWidth}
      drawerHeight='auto'
      headerPadding={`${offsetXLg} ${offsetXLg} 0`}
      footerPadding={`0 ${offsetXLg} ${offsetXLg}`}
      footer={<NMMSubmitSection {...submitSectionProps} />}
    />
  );
};

import React, {SyntheticEvent} from 'react';
import styled from 'styled-components';

import {ESectionIds, offerNavigationHeight} from '../helpers';
import {fontWeightExtraBold, offsetDef, whiteColor} from 'constants/styleVars';

const Link = styled.a<{isActive?: boolean}>`
  display: inline-block;
  padding: ${offsetDef} 0;
  position: relative;

  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
  color: ${({isActive, theme}) => (isActive ? theme.primaryColor : whiteColor)};

  &:hover {
    color: ${({theme}) => theme.primaryColor};
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 4px;
    background: ${({theme}) => theme.primaryColor};
    right: ${({isActive}) => (isActive ? '0' : '100%')};
    opacity: ${({isActive}) => (isActive ? '1' : '0')};
    transition: 0.25ms;
  }
`;

type TProps = {
  contentTitle: string;
  activeTitle: string;
  sectionId: ESectionIds;
  onClick: (arg0: ESectionIds) => void;
};

export function AnchorLink({
  contentTitle,
  activeTitle,
  sectionId,
  onClick,
}: TProps) {
  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    const element = document.getElementById(`${sectionId}`);

    if (!element) return;
    // 1 - need to compensate border between sections
    const topEl =
      element.getBoundingClientRect().top +
      window.scrollY -
      offerNavigationHeight +
      1;
    window.scrollTo({
      top: topEl,
      behavior: 'smooth',
    });
    onClick(sectionId);
  };

  return (
    <Link isActive={sectionId === activeTitle} onClick={handleClick}>
      {contentTitle}
    </Link>
  );
}

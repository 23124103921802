import React, {PureComponent} from 'react';
import {GoogleMap, Marker} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import {useGeolocated} from 'react-geolocated';
import _debounce from 'lodash/debounce';
import styled from 'styled-components';

import GoogleMapSearchInput from 'view/components/GoogleMapSearchInput';
import Icon from 'view/components/Icon';
import TextValue from 'view/components/TextValue';

import {getAddressStringFromLocation} from 'utils/stringUtils';
import {processGoogleMapAddressData} from 'utils/googleMapUtils';
import {DEFAULT_GOOGLE_MAP_ZOOM, EMPTY_OBJECT} from 'constants/app';
import {offsetLg, offsetXSm} from 'constants/styleVars';

const MAP_HEIGHT = '400px';

const Container = styled.div`
  margin-bottom: ${offsetLg};
`;

const GoogleMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${MAP_HEIGHT};
`;

const SearchContainer = styled.div`
  margin-bottom: ${offsetLg};
`;

const SearchHintText = styled(TextValue)`
  margin-top: ${offsetXSm};
`;

const GoogleMapHintContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${offsetXSm};
`;

const GoogleMapHintText = styled(TextValue)`
  margin-left: ${offsetXSm};
`;

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY || '');

type Props = {
  location: any;
  onLocationChange: (locationData: any) => void;
  coords: any;
  showMapHint?: boolean;
  searchHintText?: string;
  searchPlaceholder?: string;
};

const containerStyle = {
  width: '100%',
  height: MAP_HEIGHT,
};

export class MapContainer extends PureComponent<Props, any> {
  getGeocodeFromAddressDebounced: any = null;
  onPlacesChangedDebounced: any = null;

  constructor(props: any) {
    super(props);

    this.onMapClicked = this.onMapClicked.bind(this);
    this.getGeocodeFromAddressDebounced = _debounce(
      this.getGeocodeFromAddress,
      600,
    );
    this.onPlacesChangedDebounced = _debounce(this.onPlacesChanged, 300);
    this.state = {locationInputValue: ''};
  }

  setLocationInputValue = (value: string): void => {
    this.setState({
      locationInputValue: value,
    });
  };

  componentDidMount() {
    // set initial user location
    if (!this.props.location.latitude) {
      this.setCoords();
    }
  }

  componentDidUpdate(prevProps: Readonly<any>) {
    const {location} = this.props;

    // set initial user location
    if (!location.latitude && !prevProps.coords && this.props.coords) {
      this.setCoords();
    }

    const currentAddress = getAddressStringFromLocation(location);
    const prevAddress = getAddressStringFromLocation(prevProps.location);
    const isAddressChanged = currentAddress !== prevAddress;
    const isLatLongSame =
      location.latitude === prevProps.location.latitude &&
      location.longitude === prevProps.location.longitude;

    // update latitude and longitude when user changes address by typing in form input fields
    if (
      (isAddressChanged && isLatLongSame) ||
      (isAddressChanged && !location.latitude)
    ) {
      this.getGeocodeFromAddressDebounced();
    }
  }

  static defaultProps = {
    location: EMPTY_OBJECT,
  };

  setCoords = () => {
    const {coords, location} = this.props;
    if (coords && !location.longitude) {
      this.setUserLocation({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    }
  };

  setUserLocation = async (data: {lat: number; lng: number}) => {
    try {
      const {lat, lng} = data;

      const addressData = await this.getAddressDataFromLocation({lat, lng});
      const address = processGoogleMapAddressData(addressData);

      this.props.onLocationChange({
        latitude: lat,
        longitude: lng,
        ...address,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getAddressDataFromLocation = async ({lat, lng}: any) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);
      return response.results[0];
    } catch (error) {
      console.error(error);
    }
  };

  // update latitude and longitude when user updates form input data by typing
  getGeocodeFromAddress = () => {
    const {location} = this.props;
    const address = getAddressStringFromLocation(location);

    Geocode.fromAddress(address).then(
      (response) => {
        const {lat, lng} = response.results[0].geometry.location;
        const placeId = response.results[0].place_id;

        if (lat && lng) {
          this.props.onLocationChange({
            ...location,
            placeId,
            latitude: lat,
            longitude: lng,
          });
        }
      },
      (error) => {
        console.error(error);
      },
    );
  };

  onPlacesChanged = (data: any) => {
    try {
      const value = data[0];
      const lat = value.geometry && value.geometry.location.lat();
      const lng = value.geometry && value.geometry.location.lng();

      if (lat && lng) {
        this.setUserLocation({lat, lng});
      }
    } catch (error) {
      console.error(error);
    }
  };

  onMapClicked = (event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.setUserLocation({lat, lng});
  };

  render() {
    const {
      location: {latitude = 0, longitude = 0},
      showMapHint,
      searchHintText,
      searchPlaceholder = 'Enter a query',
    } = this.props;
    return (
      <Container>
        <SearchContainer>
          <GoogleMapSearchInput
            value={this.state.locationInputValue}
            onChangeHandler={this.setLocationInputValue}
            onPlacesChangedHandler={this.onPlacesChangedDebounced}
            google={(window as any).google}
            placeholder={searchPlaceholder}
          />

          {searchHintText ? (
            <SearchHintText>{searchHintText}</SearchHintText>
          ) : null}
        </SearchContainer>

        <GoogleMapContainer>
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={DEFAULT_GOOGLE_MAP_ZOOM}
            center={{
              lat: latitude,
              lng: longitude,
            }}
            onClick={this.onMapClicked}
          >
            <Marker
              title='Your position'
              onDragEnd={this.onMapClicked}
              position={{
                lat: latitude,
                lng: longitude,
              }}
              draggable
            />
          </GoogleMap>
        </GoogleMapContainer>

        {showMapHint ? (
          <GoogleMapHintContainer>
            <Icon icon='MAP_PIN' />
            <GoogleMapHintText secondary>
              Check if the pin is in the correct location. If not, drag the map
              until it is in the right spot.
            </GoogleMapHintText>
          </GoogleMapHintContainer>
        ) : null}
      </Container>
    );
  }
}

function GeolocatedComponent(props: any) {
  const {coords} = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
      maximumAge: 0,
      timeout: Infinity,
    },
    watchPosition: false,
    userDecisionTimeout: 5000,
    suppressLocationOnMount: false,
    geolocationProvider: navigator.geolocation,
    isOptimisticGeolocationEnabled: true,
  });

  return (
    <MapContainer
      {...props}
      isGeolocationEnabled={coords !== null}
      coords={coords}
    />
  );
}

export default GeolocatedComponent;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EPackageType, IPackageResponse} from 'types/dto/IPackages.type';

interface IPackagesReducer {
  packages: IPackageResponse[];
  packagesLoading: boolean;
  packageLoadingType: EPackageType | '';
}

const initialState: IPackagesReducer = {
  packages: [],
  packagesLoading: false,
  packageLoadingType: '',
};

export const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    getPackagesRequest(state) {
      state.packagesLoading = true;
    },

    packagesRequest(state, action: PayloadAction<EPackageType>) {
      state.packageLoadingType = action.payload;
    },

    getPackagesSuccess(state, action: PayloadAction<IPackageResponse[]>) {
      state.packages = action.payload;
      state.packagesLoading = false;
    },

    updatePackagesSuccess(state, action: PayloadAction<IPackageResponse>) {
      state.packages = state.packages.map((item: IPackageResponse) => {
        const isUpdatedPackage = item.type === action.payload.type;
        return isUpdatedPackage ? action.payload : item;
      });
      state.packageLoadingType = '';
    },

    packagesFailure(state) {
      state.packagesLoading = false;
      state.packageLoadingType = '';
    },

    clearPackages(state) {
      state.packages = [];
    },
  },
});

export const {
  getPackagesRequest,
  packagesRequest,
  getPackagesSuccess,
  updatePackagesSuccess,
  packagesFailure,
  clearPackages,
} = packagesSlice.actions;

export default packagesSlice.reducer;

import styled from 'styled-components';

import {Container} from 'view/components/SupplierLayout/SupplierLayout.styles';

import {
  offsetNone,
  offsetXSm,
  offsetSm,
  offsetXXSm,
  offsetXLg,
  offsetXXLg,
  offsetXXXXXLg,
  fontSizeSm,
  fontSizeMd,
  fontSizeLg,
  NW2FontSizeH4Lg,
  fontWeightSemiThin,
  fontWeightNormal,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightMd,
  lineHeightLg,
  lineHeightXL,
} from 'constants/styleVars';
import {SectionTitle} from 'view/components/NMMSection/NMMSection.styles';

const FORMBOX_WIDTH = '326px';

export const StyledContainer = styled(Container)`
  padding: ${offsetNone};
`;

// ShowForm styles

export const ContentName = styled.p`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeLg};
  line-height: ${lineHeightXL};
  margin-bottom: ${offsetXXSm};
`;

export const Text = styled.p`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin: ${offsetNone};
`;

export const ContentSubtitle = styled.h6<{marginBottom?: string}>`
  font-weight: ${fontWeightExtraBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMd};
  margin-bottom: ${({marginBottom}) => marginBottom || `${offsetXLg}`};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const ContentWrapper = styled.div<{numberOfColumns?: number}>`
  display: grid;
  grid-template-columns: ${({numberOfColumns}) =>
    numberOfColumns ? `repeat(${numberOfColumns}, 1fr)` : 'repeat(3, 1fr)'};
  grid-gap: ${offsetXLg};
  margin-bottom: ${offsetXLg};
`;

export const ListsWrapper = styled.div`
  display: flex;
  gap: ${offsetXXXXXLg};
`;

export const List = styled.ul`
  padding-left: ${offsetXLg};
`;

export const ListItem = styled.li`
  line-height: ${lineHeightLg};
  font-size: ${fontSizeMd};
  margin-bottom: ${offsetXSm};
`;

export const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXSm};
`;

// EditForm styles

export const FormBox = styled.div`
  width: ${FORMBOX_WIDTH};
`;

export const FormGroup = styled.div<{
  columnNumber: number;
  gap: number;
  marginBottom?: string;
}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => gap}px;
  margin-bottom: ${({marginBottom}) => marginBottom || '0'};

  > div {
    margin: 0;
  }
`;

export const FlexGroup = styled.div`
  display: flex;
  gap: ${offsetSm};
  margin-bottom: ${offsetXXLg};
`;

export const InputSeparator = styled.span`
  margin-top: ${offsetSm};
  font-size: ${NW2FontSizeH4Lg};
  font-weight: ${fontWeightSemiThin};
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: ${offsetXLg};
`;

export const InfoCardWrapper = styled.div`
  display: flex;
  margin-bottom: ${offsetXLg};
`;

export const RegisterLink = styled.span`
  font-weight: ${fontWeightBold};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const InfoCardText = styled.div`
  margin-bottom: ${offsetXSm};
`;

export const ModalActions = styled.div`
  display: flex;
  gap: ${offsetSm};
  justify-content: flex-end;
`;

export const StyledSectionTitle = styled(SectionTitle)`
  gap: 8px;
`;

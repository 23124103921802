import styled, {css} from 'styled-components';

import Icon from 'view/components/Icon';

import {
  borderRadiusDef,
  fontSizeLg,
  fontSizeMd,
  fontSizeXSm,
  fontSizeXXSm,
  fontWeightExtraBold,
  offsetSm,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';
import {EBookingTagStatus, tagSchema} from './types';
import {TSize} from 'types/app';

export const Tag = styled.span<{
  status: keyof typeof EBookingTagStatus;
  size?: TSize;
}>(({status, size}) => {
  return css`
    display: inline-flex;
    align-items: center;
    gap: ${offsetXSm};
    font-size: ${size === 'small'
      ? fontSizeXXSm
      : size === 'large'
      ? fontSizeMd
      : fontSizeXSm};
    line-height: ${fontSizeLg};
    font-weight: ${fontWeightExtraBold};
    letter-spacing: 0.02em;
    text-transform: uppercase;
    padding: ${size === 'small'
      ? `0 ${offsetXSm}`
      : size === 'large'
      ? `${offsetXSm} ${offsetSm}`
      : `${offsetXXSm} ${offsetXSm}`};
    border-width: 1px;
    border-style: solid;
    border-radius: ${borderRadiusDef};
    user-select: none;

    background-color: ${tagSchema[status]?.bgColor};
    border-color: ${tagSchema[status]?.bdColor};
    color: ${tagSchema[status]?.color};
  `;
});

export const StyledIcon = styled(Icon)`
  max-width: 16px;

  svg {
    height: auto;
  }
`;

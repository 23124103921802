import styled, {css} from 'styled-components';

import {
  borderRadiusDef,
  NW2Warn500Color,
  NW2Warn50Color,
  offsetDef,
  offsetXLg,
  offsetXXXXXLg,
} from 'constants/styleVars';

export const Section = styled.section<{isInvalid?: boolean}>`
  padding: ${offsetXLg} 0 0;
  margin: 0 0 ${offsetXXXXXLg};

  ${({isInvalid}) =>
    isInvalid &&
    css`
      position: relative;
      background-color: ${NW2Warn50Color};
      padding: ${offsetDef} 28px !important;
      border-radius: ${borderRadiusDef};

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: ${NW2Warn500Color};
        border-radius: ${borderRadiusDef} 0 0 ${borderRadiusDef};
      }
    `}
`;

import {useEffect} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getRoot, $insertNodes} from 'lexical';
import {$generateNodesFromDOM} from '@lexical/html';

type TProps = {
  initialHtmlString?: string;
};

export function PrePopulateTextPlugin({initialHtmlString}: TProps) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (!initialHtmlString) return;
    // Populate text in editor on mount
    editor.update(() => {
      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtmlString, 'text/html');

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom);

      // Select the root
      $getRoot().select();

      // Insert them at a selection.
      $insertNodes(nodes);
    });
  }, [editor, initialHtmlString]);

  return null;
}

import React from 'react';

import NW2InfoCard from 'view/common/NW2InfoCard';
import useDynamicCancellationPolicyData from 'hooks/useDynamicCancellationPolicyData';

import {ConditionsListBlock} from './ConditionsListBlock';
import {PolicyMessage} from './PolicyMessage';
import {useAppSelector} from 'store/hooks';
import NW2Loader from 'view/components/NW2Loader/NW2Loader';
import {useIsVenueFromUSA} from 'view/venue/hooks/useIsUSA';

import {
  Container,
  FootNote,
  StaticText,
  Wrapper,
} from './CancellationPolicyBlock.styles';
import {IDynamicCancellationPolicyPayload} from 'types/dto/ICancellationPolicy.type';
import {EInfoCardType} from 'view/common/NW2InfoCard/NW2InfoCard';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {COUNTRY_CANADA, COUNTRY_USA} from 'constants/venue';

export const CANCELLATION_POLICY_BLOCK_ID = 'CancellationPolicyBlock';

interface IProps extends IDynamicCancellationPolicyPayload {
  isCanceledStatus?: boolean;
  isRTBText?: boolean;
  isSimpleView?: boolean;
  isCancelModal?: boolean;
}

export const CancellationPolicyBlock = ({
  eventType,
  participants,
  hasCatering,
  checkIn,
  venueId,
  orderNumber,
  requestNumber,
  offerNumber,
  isCanceledStatus = false,
  isRTBText,
  isSimpleView,
  isCancelModal,
}: IProps) => {
  const userRole = useAppSelector(({app}) => app.user.role);
  const location = useAppSelector(
    ({venuesReducer}) => venuesReducer.venueDetails.location,
  );
  const isUSAFromLocation = [COUNTRY_USA, COUNTRY_CANADA].includes(
    location?.country,
  );
  const [isUSAFromHook] = useIsVenueFromUSA();

  const isUSA = isUSAFromLocation || isUSAFromHook;
  const {statusData, conditionsList, cancellationType, isLoading} =
    useDynamicCancellationPolicyData({
      eventType,
      participants,
      hasCatering,
      checkIn,
      venueId,
      orderNumber,
      requestNumber,
      offerNumber,
      isCanceledStatus,
    });

  const isSuccessStatus = cancellationType === EInfoCardType.success;
  const isErrorStatus = cancellationType === EInfoCardType.error;

  if (isLoading) {
    return <NW2Loader />;
  }

  return (
    <Wrapper id={CANCELLATION_POLICY_BLOCK_ID}>
      <NW2InfoCard
        icon={isSuccessStatus ? '' : 'TRIANGLE_WARN'}
        type={cancellationType}
        isColoredBackground
      >
        <Container isMarginLeft={isErrorStatus}>
          {!isErrorStatus && <b>{statusData?.freeOfCharge}%</b>}
          <span>
            <b>
              <PolicyMessage
                data={
                  isCancelModal && statusData?.freeOfCharge !== 0
                    ? 'free of charge '
                    : statusData?.message
                }
                showStar={false}
              />{' '}
            </b>
            {isCanceledStatus && !isErrorStatus ? (
              <>({isUSA ? 'excluding' : 'including'} taxes and fees)</>
            ) : (
              <PolicyMessage
                data={statusData?.date}
                showStar={isSuccessStatus}
              />
            )}
          </span>
        </Container>
      </NW2InfoCard>

      {!isCanceledStatus && !isSimpleView && (
        <>
          {!isErrorStatus && (
            <StaticText>
              {userRole === EUserRoleCognito.ROLE_SUPPLIER ? (
                <>
                  <b>Important note:</b> The percentages show you how much of
                  the original booking volume (incl. taxes and fees) the booker
                  can reduce by reservation changes or cancellations without a
                  fee.
                </>
              ) : (
                <>
                  <b>Important note: </b>
                  {isRTBText && (
                    <>
                      Policy becomes binding{' '}
                      <b>only once the request is accepted by the venue. </b>
                    </>
                  )}
                  If you miss the above cancellation deadline, fees may apply.
                  The percentages show you how much of your original booking
                  volume (incl. taxes and fees) you can reduce by reservation
                  changes without a fee.
                </>
              )}
            </StaticText>
          )}

          {conditionsList && !isErrorStatus && (
            <ConditionsListBlock conditionsList={conditionsList} />
          )}

          {!isErrorStatus && (
            <FootNote>
              * The deadline is based on the timezone of the venue
            </FootNote>
          )}
        </>
      )}
    </Wrapper>
  );
};

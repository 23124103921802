import styled from 'styled-components';

import {
  fontSizeMd,
  fontSizeSm,
  fontWeightBold,
  lineHeightLg,
  md,
  NW2Gray500Color,
  offsetDef,
  offsetSm,
} from 'constants/styleVars';
import NW2AccordionItem from 'view/components/NW2Accordion/components/NW2AccordionItem';

const SIDE_SIZE = '40px';

export const StyledAccordionItem = styled(NW2AccordionItem)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${offsetDef};

  & > div {
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  font-size: ${fontSizeSm};
  gap: ${offsetSm};

  @media (min-width: ${md}) {
    font-size: ${fontSizeMd};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightLg};

  @media (min-width: ${md}) {
    font-size: ${fontSizeMd};
  }
`;

export const ImageCircle = styled.img`
  width: ${SIDE_SIZE};
  height: ${SIDE_SIZE};
  border: 1px solid ${NW2Gray500Color};
  border-radius: 50%;

  &:not(:first-child) {
    margin-left: -15px;
  }
`;

export const MobileHeaderTitle = styled.div`
  padding: ${offsetSm} ${offsetDef};
`;

export const VenuesImage = styled.div`
  min-width: fit-content;
`;

import React, {FC} from 'react';
import styled from 'styled-components';
import {Form, Input, FormInstance, InputNumber} from 'antd';

import {
  COMPANY_USER_FIRST_NAME,
  COMPANY_USER_LAST_NAME,
  COMPANY_USER_PHONE,
} from 'constants/admin';
import {EMPTY_OBJECT} from 'constants/app';
import {ICorporateAccountCompanyUser} from 'store/admin/types';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 24},
};

type Props = {
  form: FormInstance;
  initialValues: ICorporateAccountCompanyUser;
};

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const AdminEditCompanyUser: FC<Props> = ({
  form,
  initialValues = EMPTY_OBJECT,
}) => {
  return (
    <Form
      autoComplete='off'
      form={form}
      preserve={false}
      {...layout}
      initialValues={initialValues}
    >
      <Form.Item
        label='First name'
        name={COMPANY_USER_FIRST_NAME}
        rules={[{required: true, message: "Please input user's first name!"}]}
      >
        <Input placeholder='First name' allowClear />
      </Form.Item>

      <Form.Item
        label='Last name'
        name={COMPANY_USER_LAST_NAME}
        rules={[{required: true, message: "Please input user's last name!"}]}
      >
        <Input placeholder='Last name' allowClear />
      </Form.Item>

      <Form.Item label='Phone number' name={COMPANY_USER_PHONE}>
        <FullWidthInputNumber placeholder='Phone number' />
      </Form.Item>
    </Form>
  );
};

export default React.memo(AdminEditCompanyUser);

import React, {useMemo} from 'react';
import {Column, Row, TableOptions} from 'react-table';

import DateRangeString from 'view/components/DateRangeString';
import Icon from 'view/components/Icon';
import StatusLabel from 'view/common/StatusLabel';
import TableLayout from 'view/common/TableLayout';
import TruncateText from 'view/components/TruncateText';
import NW2BookingStatusTag from 'view/common/NW2BookingStatusTag';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';

import {
  EmptyData,
  FlexContainer,
  NoWrap,
  TableContainer,
  TextCursive,
  OrderNumberCell,
  ExtrasCellBox,
} from './BookingsListRooms.styles';
import {
  EBookingOrderStatus,
  EOrderShowStatus,
  ISearchOrder,
} from 'types/bookingOrders';
import {EOfferStatus, ERequestStatus} from 'types/offer';
import {NOT_PROVIDED_BY_BOOKER} from 'constants/app';
import {ICON_ARROW_MARGIN_IN_TABLES} from 'view/venue/Offer/constants';

interface IProps {
  currency: string;
  data: ISearchOrder[] | unknown[];
  isLoading: boolean;
  onShowOrderDetails: (row: Row<Record<string, never>>) => () => void;
}

const EMPTY_SYMBOL = '-';

const ExtrasCell = ({value, width}: {value: boolean; width: number}) => (
  <ExtrasCellBox width={width}>
    {value ? <Icon icon='NW2_TICK' /> : <span>{EMPTY_SYMBOL}</span>}
  </ExtrasCellBox>
);

export function BookingsListRoomsTable({
  currency,
  data,
  isLoading,
  onShowOrderDetails,
}: IProps) {
  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: 'Booking ID',
        accessor: 'orderNumber',
        Cell: ({value, row}) => (
          <OrderNumberCell>
            {row.isSelected && (
              <NW2BookingStatusTag status='NEW' size='small' />
            )}
            <TruncateText text={value} hideToggler />
          </OrderNumberCell>
        ),
      },
      {
        Header: 'Date',
        accessor: 'checkInDate',
        Cell: ({row}: {row: {original: ISearchOrder}}) => {
          const {checkInDate, checkOutDate} = row.original;

          return (
            <DateRangeString
              checkIn={checkInDate}
              checkOut={checkOutDate}
              iconMargin={ICON_ARROW_MARGIN_IN_TABLES}
            />
          );
        },
      },
      {
        Header: 'Company',
        accessor: 'company',
        Cell: ({value}) =>
          value || <TextCursive>{NOT_PROVIDED_BY_BOOKER}</TextCursive>,
      },
      {
        Header: 'Booker',
        accessor: 'firstName',
        Cell: ({row}) => {
          const {firstName, lastName, status} = row.original;

          // hide booker name for statuses below
          if (
            [
              EBookingOrderStatus.RFP_EXPIRED,
              EBookingOrderStatus.RFP_CANCELLED,
              EBookingOrderStatus.RFP_DECLINED,
            ].includes(status as EBookingOrderStatus)
          ) {
            return <span>{EMPTY_SYMBOL}</span>;
          }

          // for these statuses of Offers and Reqests show Not available
          if (
            [
              ERequestStatus.REQUEST_DECLINED,
              ERequestStatus.REQUEST_CANCELED,
              ERequestStatus.REQUEST_EXPIRED,
            ].includes(status as ERequestStatus) ||
            [
              EOfferStatus.OFFER_DECLINED,
              EOfferStatus.OFFER_ACCEPTING_EXPIRED,
            ].includes(status as EOfferStatus)
          ) {
            return <TextCursive>Not available</TextCursive>;
          }

          // for these statuses of Offers show Available when confirmed
          if (status === EOfferStatus.OFFER_PENDING) {
            return <TextCursive>Available when confirmed</TextCursive>;
          }

          return firstName && lastName ? (
            <span>
              {firstName} {lastName}
            </span>
          ) : (
            <span>{EMPTY_SYMBOL}</span>
          );
        },
      },
      {
        Header: 'Participants',
        accessor: 'participants',
      },
      {
        Header: 'Meeting Room',
        accessor: 'hasMeetingRoom',
        Cell: ({value}) => <ExtrasCell value={value} width={90} />,
        width: 90,
        textAlign: 'center',
      },
      {
        Header: 'Equipment',
        accessor: 'hasEquipment',
        Cell: ({value}) => <ExtrasCell value={value} width={80} />,
        width: 80,
        textAlign: 'center',
      },
      {
        Header: 'Catering',
        accessor: 'hasCatering',
        Cell: ({value}) => <ExtrasCell value={value} width={74} />,
        width: 74,
        textAlign: 'center',
      },
      {
        Header: 'Accom.',
        accessor: 'hasAccommodation',
        Cell: ({value}) => <ExtrasCell value={value} width={60} />,
        width: 60,
        textAlign: 'center',
      },
      {
        Header: 'Price',
        accessor: 'totalPrice',
        textAlign: 'right',
        Cell: ({row}: {row: {original: ISearchOrder}}) => {
          const {totalPrice, status, hasAccommodation, isRequest} =
            row.original;
          const isRequestWithBedroom = isRequest && hasAccommodation;

          return (
            <NoWrap
              textAlign='right'
              lineThrough={[
                ERequestStatus.REQUEST_DECLINED,
                ERequestStatus.REQUEST_CANCELED,
                ERequestStatus.REQUEST_EXPIRED,
                EOfferStatus.OFFER_DECLINED,
                EOfferStatus.OFFER_ACCEPTING_EXPIRED,
              ].includes(status as ERequestStatus)}
            >
              {isRequestWithBedroom ? (
                '-'
              ) : (
                <PriceDisplay price={totalPrice} currency={currency} />
              )}
            </NoWrap>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({value, row}) => (
          <FlexContainer>
            <StatusLabel status={value} showStatus={row.original.showStatus} />
          </FlexContainer>
        ),
      },
    ],
    [currency],
  );

  const preSelectedUnreadOrders = (data as ISearchOrder[]).reduce(
    (prev, curr, index) => ({
      ...prev,
      ...(curr.showStatus === EOrderShowStatus.UNREAD ? {[index]: true} : {}),
    }),
    {},
  );

  const tableOptions = useMemo<TableOptions<any>>(
    () => ({
      columns,
      data,
      initialState: {
        selectedRowIds: preSelectedUnreadOrders,
      },
    }),
    [columns, data, preSelectedUnreadOrders],
  );

  return (
    <TableContainer>
      <TableLayout
        emptyText={
          <EmptyData>
            <Icon icon='NW2_TASK_LIST' NW2Gray600Color /> You have no bookings
            history
          </EmptyData>
        }
        options={tableOptions}
        isLoading={isLoading}
        onRowClick={onShowOrderDetails}
      />
    </TableContainer>
  );
}

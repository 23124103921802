import styled from 'styled-components';

import {
  mdBp,
  offsetXXLg,
  fontWeightExtraBold,
  lgBp,
  offsetXXXLg,
  offsetXLg,
  whiteColor,
  NW2BackIconSize,
  NW2SummaryBlockMdWidth,
  NW2SummaryBlockLgWidth,
  infoColor,
  offsetDef,
} from 'constants/styleVars';

export const StyledWrapper = styled.div`
  width: 100%;
  background-color: ${whiteColor};
  padding-top: ${offsetXXLg};
  padding-bottom: ${offsetXXXLg};
`;

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: ${NW2BackIconSize} 1fr;
  grid-column-gap: ${offsetXXLg};
  align-items: start;
  margin-bottom: ${offsetXXXLg};
`;

export const StyledTitle = styled.div`
  font-size: 26px;
  line-height: 34px;
  font-weight: ${fontWeightExtraBold};
  text-transform: uppercase;
  letter-spacing: 0.02em;

  @media (min-width: ${mdBp}px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const StyledMain = styled.div`
  @media (min-width: ${mdBp}px) {
    display: flex;
    gap: ${offsetXLg};
    align-items: start;

    > div {
      &:first-child {
        width: calc(100% - ${NW2SummaryBlockMdWidth} - ${offsetXLg});
      }
      &:last-child {
        width: ${NW2SummaryBlockMdWidth};
      }
    }
  }

  @media (min-width: ${lgBp}px) {
    > div {
      &:first-child {
        width: calc(100% - ${NW2SummaryBlockLgWidth} - ${offsetXLg});
      }
      &:last-child {
        width: ${NW2SummaryBlockLgWidth};
      }
    }
  }
`;

// sign in card
export const CardTitle = styled.div`
  font-weight: ${fontWeightExtraBold};
  color: ${infoColor};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;
export const CardText = styled.div`
  margin-right: ${offsetDef};
  flex: 1;
`;
export const FormGroup = styled.div<{columnNumber: number; gap: number}>`
  display: grid;
  grid-template-columns: repeat(${({columnNumber}) => columnNumber}, 1fr);
  grid-gap: ${({gap}) => gap}px;

  > div {
    margin: 0;
  }
`;

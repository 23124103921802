import styled from 'styled-components';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button';
import {NW2DatePicker} from 'view/components/NW2Datepicker/NW2DatePicker';

import {
  borderRadiusDef,
  borderRadiusLg,
  borderRadiusXLg,
  formItemDropdownContainerZIndex,
  sectionBoxShadow,
} from 'styles/configs/variables';
import {dayItemSize} from 'view/components/NW2Datepicker/NW2DatePicker/NW2DatePicker.styles';
import {sm} from 'styles/configs/breakpoints';
import {offsetSm, offsetXSm, offsetXXSm} from 'styles/configs/offset';
import {
  NW2Blue50Color,
  NW2Gray500Color,
  NW2Gray900Color,
  NW2Primary,
  whiteColor,
} from 'styles/configs/colors';
import {
  fontSizeSm,
  fontSizeXSm,
  fontWeightBold,
} from 'styles/configs/typography';

export const MONTH_BLOCK_HEIGHT = '250px';
export const DAY_NAME_BLOCK_HEIGHT = '25px';

const StyledIcon = styled(Icon)`
  transform: rotate(180deg);
`;

const StyledNW2DatePicker = styled(NW2DatePicker)`
  &:after {
    content: none;
  }

  .react-datepicker {
    padding: 0;
    border-radius: ${borderRadiusLg};
    box-shadow: ${sectionBoxShadow};
    overflow: hidden;
    background: ${whiteColor};

    &__triangle {
      display: none;
    }
    &__header--custom {
      padding: 0;
      border: 0;
    }
    &__month {
      min-height: ${MONTH_BLOCK_HEIGHT};
    }
    &__day,
    &__day-name {
      width: ${dayItemSize};
      margin: 2px;
      font-size: ${fontSizeXSm};
    }
    &__day {
      line-height: ${dayItemSize};
    }
    &-popper {
      z-index: ${formItemDropdownContainerZIndex};
      max-width: 100%;
    }

    min-width: 314px;
  }
`;

const StyledNW2Button = styled(NW2Button)`
  &:disabled {
    background: transparent;
    border-color: transparent;

    div {
      color: transparent;
    }
  }
`;

const CurrentYearMonth = styled.div<{isActive: boolean; isDisabled: boolean}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${offsetXXSm};

  font-weight: ${fontWeightBold};
  letter-spacing: 0.02em;
  font-size: ${fontSizeXSm};
  text-transform: uppercase;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 5px solid
      ${({isDisabled}) => (isDisabled ? NW2Gray500Color : NW2Gray900Color)};
    transition: 250ms;

    border-radius: ${borderRadiusDef};
  }

  ${({isActive}) =>
    isActive &&
    `&:after {
    transform: rotate(180deg);
    }
  `}
  ${({isDisabled}) => isDisabled && `color: ${NW2Gray500Color}`}
`;

const Option = styled.option<{isActive: boolean}>`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: ${offsetXSm} ${offsetSm};

  border-radius: ${borderRadiusXLg};
  cursor: pointer;

  &:hover {
    background-color: ${NW2Blue50Color};
  }

  ${({isActive}) =>
    isActive &&
    `font-weight: ${fontWeightBold}; 
    background-color: ${NW2Primary};
    `}
`;

const MonthYearPickerOptions = styled.div<{
  isActive: boolean;
  columns?: number;
  gap?: string;
  alignItems?: string;
}>`
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  right: 0;
  height: calc(${MONTH_BLOCK_HEIGHT} + ${DAY_NAME_BLOCK_HEIGHT});
  background: ${whiteColor};
  overflow: auto;

  display: flex;
  align-items: ${({alignItems}) => alignItems || 'flex-start'};
  justify-content: center;

  visibility: hidden;
  opacity: 0;

  ${({isActive}) =>
    isActive &&
    `
      visibility: visible;
      opacity: 1;
    `}

  > div {
    display: grid;
    grid-template-columns: repeat(3, minmax(30px, 65px));
    grid-column-gap: ${offsetXSm};
    grid-row-gap: ${offsetXSm};
    justify-content: center;

    width: 100%;
    max-width: 270px;

    font-size: ${fontSizeSm};
    text-transform: capitalize;

    @media (min-width: ${sm}) {
      grid-template-columns: repeat(
        ${({columns}) => columns || 3},
        minmax(30px, 65px)
      );
      grid-column-gap: ${({gap}) => gap || 0};
    }
  }
`;

export {
  StyledNW2DatePicker,
  StyledNW2Button,
  StyledIcon,
  Option,
  CurrentYearMonth,
  MonthYearPickerOptions,
};

import React from 'react';

import NW2Button from 'view/components/NW2Button';
import NW2ModalDrawer from 'view/components/NW2ModalDrawer';

import {floorLevels, getKeyByValue} from 'constants/floorStorey';
import {
  bodyStyle,
  BodyWrapper,
  ButtonContainer,
  Container,
} from './DeleteModal.styles';

interface IProps {
  onCloseDeleteFloorModal: () => void;
  showDeleteModal: boolean;
  isSending: boolean;
  deletedFloors: number[];
}

export const DeleteModal = ({
  onCloseDeleteFloorModal,
  showDeleteModal,
  deletedFloors,
  isSending,
}: IProps) => {
  const modalBody = (
    <BodyWrapper>
      <div>You are about to delete the following floors.</div>
      <div>
        <b>Please note this action is irreversible.</b>
      </div>
      <Container>
        {deletedFloors.map((floorNumber) => {
          const floorName = getKeyByValue(floorLevels, floorNumber);

          return <div key={floorNumber}>{floorName}</div>;
        })}
      </Container>
    </BodyWrapper>
  );

  const modalFooter = (
    <ButtonContainer>
      <NW2Button
        form='floor-edit-form'
        buttonType='secondary'
        data-testid='floor delete cancel'
        onClick={onCloseDeleteFloorModal}
      >
        Cancel
      </NW2Button>
      <NW2Button
        type='submit'
        form='floor-edit-form'
        buttonType='primary'
        loading={isSending}
        data-testid='floor delete submit'
      >
        Delete
      </NW2Button>
    </ButtonContainer>
  );

  return (
    <NW2ModalDrawer
      header='Delete floor'
      body={modalBody}
      footer={modalFooter}
      isShowed={showDeleteModal}
      isMobile={false}
      modalBodyStyle={bodyStyle}
      onClose={onCloseDeleteFloorModal}
      modalWidth={400}
      drawerHeight='auto'
    />
  );
};

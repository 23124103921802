import {useMemo} from 'react';

import {EAmenitiesCategories, TFilteredAmenities} from 'types/venue';
import {EAmenityType} from 'types/amenities';
import {useVenueDetailsData} from 'view/venue/hooks/useVenueDetailsData';
import {IAmenity} from 'types/dto/IAmenity';

type TSortedAmenities = {
  [key in EAmenitiesCategories]: {
    label: string;
    value: number;
  }[];
};

export const useSortedAmenities = (): TSortedAmenities => {
  const {venueDetails} = useVenueDetailsData();

  return useMemo(() => {
    const getFilteredAmenities = (
      amenityType: EAmenityType,
    ): TFilteredAmenities[] =>
      (venueDetails.amenities as IAmenity[])
        .filter(({type}) => type === amenityType)
        .map(({name, id}) => ({
          label: name,
          value: id,
        }));

    return {
      [EAmenitiesCategories.GENERAL]: getFilteredAmenities(EAmenityType.VENUE),
      [EAmenitiesCategories.SERVICES]: getFilteredAmenities(
        EAmenityType.VENUE_SERVICE,
      ),
      [EAmenitiesCategories.ENTERTAINMENT]: getFilteredAmenities(
        EAmenityType.VENUE_ENTERTAINMENT,
      ),
      [EAmenitiesCategories.ACCESSIBILITY]: getFilteredAmenities(
        EAmenityType.VENUE_ACCESSIBILITY,
      ),
      [EAmenitiesCategories.TRANSPORTATION]: getFilteredAmenities(
        EAmenityType.VENUE_TRANSPORTATION,
      ),
      [EAmenitiesCategories.WELLNESS_FITNESS]: getFilteredAmenities(
        EAmenityType.VENUE_WELLNESS_AND_FITNESS,
      ),
    };
  }, [venueDetails.amenities]);
};

import React, {ChangeEvent, forwardRef, useEffect} from 'react';
import {useField} from 'react-final-form';
import {DateTime} from 'luxon';
import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {IInputFieldProps} from 'view/components/NW2FormItem/types';
import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';
import {datePickerRules} from 'utils/finalFormFieldRules';
import {maskDatePickerValue} from '../helpers';
import {usePrevious} from 'hooks/usePrevious';

import {offsetSm} from 'styles/configs/offset';

const CustomInputWrapper = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: ${offsetSm};
  right: ${offsetSm};
`;

type TProps = Partial<IInputFieldProps> & {
  inputName: string;
  label: string;
  dateFormat: string;
  inputDisabled?: boolean;
  isRequired?: boolean;
};

export const DatePickerCustomInput = forwardRef<HTMLDivElement, TProps>(
  (
    {
      value,
      onClick,
      inputName,
      label,
      type = 'text',
      onChange,
      dateFormat,
      inputDisabled,
      isRequired,
    },
    ref,
  ) => {
    const prevValue = usePrevious(value);
    const field = useField(inputName);
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = maskDatePickerValue(event.target.value);

      field.input.onChange(value);

      if (
        event.target.value &&
        DateTime.fromFormat(event.target.value, dateFormat).isValid &&
        onChange
      ) {
        onChange(event);
      }
    };

    useEffect(() => {
      if (prevValue !== value) field.input.onChange(value);
    }, [value, prevValue, field.input]);

    return (
      <CustomInputWrapper ref={ref}>
        <NW2FormItemInput
          type={type}
          name={inputName}
          placeholder={label}
          label={label}
          onClick={onClick}
          onChange={handleOnChange}
          rules={datePickerRules(dateFormat, isRequired)}
          hasValidation={isRequired}
          disabled={inputDisabled}
          showAllValidationErrors
        />
        <StyledIcon size={18} icon='NW2_CALENDAR' NW2Gray400Color />
      </CustomInputWrapper>
    );
  },
);

import styled from 'styled-components';

import CustomList from 'view/components/CustomList';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  textColor,
  preXLgBd,
  fontSizeMd,
  offsetSm,
  preLgBd,
} from 'constants/styleVars';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${offsetSm} 0;

  @media (max-width: ${preXLgBd}px) {
    justify-content: center;
  }
`;

export const RTCContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${offsetSm} 0;
  align-items: flex-end;
  gap: ${offsetSm};

  @media (max-width: ${preLgBd}px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const StyledNW2Button = styled(NW2Button)`
  margin-right: ${offsetSm};
`;

export const StyledCustomList = styled(CustomList)`
  li {
    font-size: ${fontSizeMd};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: ${textColor};
  }
`;

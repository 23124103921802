import React, {FC, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Popover} from 'antd';
import {isMobile} from 'react-device-detect';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';
import {DateTime} from 'luxon';

import NWTag from 'view/components/NWTag';
import PopoverTitle from '../PopoverTitle';
import PopoverContent from '../PopoverContent';

import {
  blockColor,
  borderColor,
  cellHoverColor,
  disabledCellColor,
  fontWeightBold,
  offsetXSm,
  offsetXXSm,
  primaryColor,
  selectedCellColor,
  whiteColor,
} from 'constants/styleVars';
import {EUnitBookingStatus} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';
import {RootState} from '../../../../../store/types';
import {EUserRoleCognito} from '../../../../../types/dto/EUserRoleCognito';

type Props = {
  date: number;
  value: string;
  isSelected: boolean;
  isCurrent: boolean;
};

const Container = styled.div<{
  isCurrent?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}>`
  min-height: 80px;
  min-width: 24px;
  background-color: ${({isCurrent, isSelected, isDisabled}) =>
    isCurrent
      ? blockColor
      : isDisabled
      ? disabledCellColor
      : isSelected
      ? selectedCellColor
      : whiteColor};
  margin: 0 4px;
  padding: ${offsetXXSm} ${offsetXSm};
  border: 0;
  border-top: 2px solid ${borderColor};
  box-sizing: border-box;

  &:hover {
    background: ${cellHoverColor};
    cursor: pointer;
  }
`;

const DateValueContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const DateValue = styled.span<{isCurrent: boolean; isSelected: boolean}>`
  color: ${({isCurrent}) => (isCurrent ? primaryColor : 'inherit')};
  font-weight: ${({isSelected, isCurrent}) =>
    isSelected || isCurrent ? fontWeightBold : 'normal'};
`;

const STATUS_MAP: {[key: string]: string} = {
  [EUnitBookingStatus.OPEN]: 'Open',
  [EUnitBookingStatus.CLOSED]: 'Closed',
  [EUnitBookingStatus.FULLY_BOOKED]: 'Fully booked',
};

const DateCell: FC<Props> = ({date, value, isSelected, isCurrent}) => {
  const [isVisible, setVisible] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(EUnitBookingStatus.NONE);

  const id = DateTime.fromISO(value).toFormat('y-LL-dd');

  const status: EUnitBookingStatus = useSelector((state) =>
    _get(state, `venuesReducer.venueUnitBookingStatistics.${id}.workedStatus`),
  );

  const isDateInPast: boolean = useSelector((state) =>
    _get(state, `venuesReducer.venueUnitBookingStatistics.${id}.pastDate`),
  );

  useEffect(() => {
    if (status) {
      setUpdatedStatus(status);
    }
  }, [status]);

  const isPrimary = isSelected; // user has clicked on the cell
  const isActive =
    !isSelected && (isCurrent || status === EUnitBookingStatus.OPEN);
  const isDisabled =
    !isSelected && !isCurrent && status === EUnitBookingStatus.CLOSED;
  const isSuccess = status === EUnitBookingStatus.FULLY_BOOKED;

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  const handleVisibleChange = (visible: boolean) => {
    if (isDateInPast || isAgent) return;
    setVisible(visible);
  };

  return (
    <Popover
      title={
        <PopoverTitle status={updatedStatus} setStatus={setUpdatedStatus} />
      }
      content={
        <PopoverContent
          status={updatedStatus}
          dateValue={value}
          setVisible={setVisible}
          disabled={updatedStatus === status}
        />
      }
      trigger='click'
      open={!isDateInPast && isVisible && isSelected}
      onOpenChange={handleVisibleChange}
      placement={isMobile ? 'topLeft' : 'rightTop'}
      key={id}
    >
      <Container
        isCurrent={isCurrent}
        isSelected={isSelected}
        isDisabled={status === EUnitBookingStatus.CLOSED}
      >
        <DateValueContainer>
          {status ? (
            <NWTag
              primary={isPrimary}
              active={isActive}
              success={isSuccess}
              disabled={isDisabled}
            >
              {STATUS_MAP[status]}
            </NWTag>
          ) : (
            <span />
          )}
          <DateValue isCurrent={isCurrent} isSelected={isSelected}>
            {date}
          </DateValue>
        </DateValueContainer>
      </Container>
    </Popover>
  );
};

export default React.memo(DateCell);

import styled, {css} from 'styled-components';

import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import NW2Button from 'view/components/NW2Button/NW2Button';
import {
  borderRadiusDef,
  datepickerZIndex,
  fontSizeXXSm,
  fontWeightExtraBold,
  mdBp,
  NW2Gray200Color,
  NW2Gray50Color,
  NW2Gray600Color,
  NW2Gray800Color,
  NW2Gray900Color,
  offsetDef,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  pagePadding,
  whiteColor,
} from 'constants/styleVars';
import {calendarWidth} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker/NW2SearchDatePicker.styles';

export const StyledWrapper = styled.div<{
  inverted: boolean;
}>(
  ({inverted}) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, auto));
    grid-gap: ${pagePadding};
    align-items: center;
    min-width: 550px;
    padding: ${offsetDef} ${offsetXLg};
    height: 76px;
    background-color: ${inverted ? whiteColor : NW2Gray800Color};
    border-radius: ${borderRadiusDef};
    color: ${inverted ? NW2Gray600Color : whiteColor};
    transition: background-color 0.15s, color 0.15s;

    ${inverted &&
    `
      ${StyledTimeRange}, ${StyledParticipantsWrapper} {
        border-color: ${NW2Gray200Color};
      }
    `}
  `,
);

export const StyledDropdown = styled.div`
  display: flex;
  width: ${calendarWidth};
  height: 350px;
  background-color: ${NW2Gray50Color};
  border-radius: ${borderRadiusDef};
  position: absolute;
  top: calc(100% + ${offsetXSm});
  left: 0;
  transition: width 0.15s ease;
  z-index: ${datepickerZIndex};
  overflow: hidden;
`;

export const StyledTimeRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${offsetXLg};

  @media (min-width: ${mdBp}px) {
    padding: 0 ${offsetDef};
    border-left: solid ${NW2Gray600Color};
    gap: 28px;
  }
`;

export const StyledIconBox = styled.div`
  display: flex;
  height: 20px;
`;

// buttons
export const StyledButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledButtonEdit = styled(NW2Button)`
  margin-left: ${offsetSm};

  &:before {
    display: none;
  }

  > span {
    font-size: ${fontSizeXXSm};
    margin-left: ${offsetXXSm};
  }
`;

export const StyledCustomButton = styled(NW2Button)`
  min-width: 100%;

  @media (min-width: ${mdBp}px) {
    min-width: 90px;
  }
`;

export const StyledCancelButton = styled(StyledCustomButton)<{
  isMobile: boolean;
}>(
  ({isMobile}) =>
    isMobile &&
    css`
      color: ${whiteColor};
      border-color: ${whiteColor};
    `,
);

// Participants
export const StyledParticipantsWrapper = styled.div`
  position: relative;

  @media (min-width: ${mdBp}px) {
    border-left: solid ${NW2Gray600Color};
    padding-left: ${offsetXLg};
    max-width: 65px;
  }

  input {
    cursor: pointer;
    letter-spacing: -1px;
  }
`;

// MOBILE
export const StyledDrawer = styled(StyledAntdDrawer)`
  .ant-drawer {
    &-content,
    &-body {
      overflow: visible;
    }

    &-body {
      background-color: ${NW2Gray900Color};
      padding: 0;
    }
  }
`;

export const StyledWrapperMobile = styled.div`
  width: 100%;
  height: 340px;
  padding: ${offsetXXLg} ${offsetDef} ${offsetDef};
  background-color: ${NW2Gray900Color};
`;

export const StyledWrapperMobileHeader = styled.div`
  font-size: 68px;
  line-height: 54px;
  font-weight: ${fontWeightExtraBold};
  color: ${whiteColor};
  margin-bottom: ${offsetDef};
`;

export const StyledWrapperMobileHeaderDot = styled.span`
  display: inline-block;
  vertical-align: baseline;
  width: 14px;
  height: 14px;
  background-color: ${whiteColor};
  border-radius: 2px;
  pointer-events: none;
`;

export const StyledWrapperMobileMain = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${whiteColor};
  padding: ${offsetDef} ${offsetXLg};
  margin-bottom: ${offsetDef};
  border-radius: 10px;

  > div {
    &:first-child {
      box-shadow: 0 1px 0 0 ${NW2Gray200Color};
    }

    &:last-child {
      padding-top: ${offsetDef};
    }
  }
`;

export const StyledWrapperMobileMainTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${offsetXLg};
`;

export const StyledWrapperMobileFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MultiRoomRequirementsWrapper = styled.div`
  @media (min-width: ${mdBp}px) {
    border-left: solid ${NW2Gray600Color};
    padding-left: ${offsetXLg};
  }
`;

import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {notification} from 'antd';

import Icon from 'view/components/Icon';

import {
  IMAGE_MAX_SIZE,
  IMAGE_MIN_SIZE,
  MAX_FILES_LIMIT,
  maximumImageFileSize,
  minimumImageFileSize,
} from 'constants/app';
import {TImageFile} from 'view/components/ImageUploaderComponent';
import {TFileWithPreview, THandleUpdateImages} from '../../types';
import {
  Container,
  InputLayout,
  InputLayoutSubTitle,
  InputLayoutTitle,
  StyledAnimatedIcon,
} from './NW2Upload.styles';

interface IProps {
  isMultiple?: boolean;
  maxFiles?: number;
  label?: string;
  onImageUpload: (file: TImageFile) => void;
  onUpdateVenueImages: THandleUpdateImages;
}

export function useNW2Upload({
  isMultiple = false,
  label = 'Upload image',
  maxFiles,
  onImageUpload,
  onUpdateVenueImages,
}: IProps) {
  const [files, setFiles] = useState<TFileWithPreview[]>([]);
  const [isFileSent, setFileSent] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [isUploading, setUploading] = useState(false);

  const {getRootProps, getInputProps, isFocused, isDragAccept, isDragReject} =
    useDropzone({
      maxFiles: maxFiles || MAX_FILES_LIMIT,
      maxSize: IMAGE_MAX_SIZE,
      minSize: IMAGE_MIN_SIZE,
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      },
      multiple: isMultiple,
      onError: (err) => {
        notification.error({
          message: err?.message,
          duration: 4,
        });
      },
      onDrop: (acceptedFiles, fileRejections) => {
        if (fileRejections.length) {
          const err = fileRejections[0].errors[0];
          const file = fileRejections[0].file;

          let message = '';
          if (err.code === 'file-too-large') {
            message = `${file.name} must be smaller than ${maximumImageFileSize}`;
          }
          if (err.code === 'file-too-small') {
            message = `${file.name} must be bigger than ${minimumImageFileSize}`;
          }
          if (err.code === 'file-invalid-type') {
            message = `${file.name} has invalid format`;
          }

          notification.error({
            message: message || err.message,
            duration: 4,
          });

          return;
        }

        setFiles([]);
        setFileSent(false);

        setUploading(true);

        setFiles(
          acceptedFiles.map((file: any) => {
            onImageUpload({
              uid: file.uid,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
              name: file.name,
              size: file.size,
              type: file.type,
              originFileObj: file,
            });

            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
          }),
        );
      },
    });

  const handleProgress = useCallback((value: number) => {
    setProgress(value);
  }, []);

  useEffect(() => {
    // auto upload
    if (files.length > 0 && !isFileSent) {
      setFileSent(true);
      onUpdateVenueImages({handleProgress});
    }
  }, [files.length, handleProgress, onUpdateVenueImages, isFileSent]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      files.forEach((file: TFileWithPreview) =>
        URL.revokeObjectURL(file.preview),
      );
    };
  }, [files]);

  const handleReset = useCallback(() => {
    setFiles([]);
    setFileSent(false);
    setUploading(false);
    setProgress(0);
  }, []);

  const handleUploading = useCallback((val: boolean) => {
    setUploading(val);
  }, []);

  return {
    isUploading,
    handleUploading,
    files,
    progress,
    handleReset,
    uploadComponent: (
      <Container
        isFocused={isFocused}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        data-testid='image upload input'
      >
        {isUploading ? (
          <InputLayout>
            <StyledAnimatedIcon icon='PROCESSING' NW2Gray400Color />
            <InputLayoutTitle>Uploading...</InputLayoutTitle>
          </InputLayout>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <InputLayout>
              <Icon icon='NW2_UPLOAD' NW2Gray400Color />
              <InputLayoutTitle>{label}</InputLayoutTitle>
              <InputLayoutSubTitle>
                (click or drag in image to this area)
              </InputLayoutSubTitle>
            </InputLayout>
          </div>
        )}
      </Container>
    ),
  };
}

import React from 'react';
import styled from 'styled-components';

import Icon from '../Icon';

import {offsetXXSm, primaryColor, coverImageZIndex} from 'constants/styleVars';

const CoverImage = styled.div`
  position: absolute;
  z-index: ${coverImageZIndex};
  left: 0;
  top: 16px;
  display: flex;
  align-items: center;
  width: auto;
  height: 22px;
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  background-color: ${primaryColor};
  color: #ffffff;
  border-radius: 0 4px 4px 0;
  padding: 0 ${offsetXXSm};
  cursor: pointer;

  & svg {
    width: 14px;
    margin: 0 8px 0 0;
  }
`;

type PropsType = {
  showIcon?: boolean;
  onClick?: () => void;
};

const CoverImageLabel = ({showIcon = true, onClick}: PropsType) => {
  return (
    <CoverImage onClick={onClick}>
      {showIcon ? <Icon icon='COVER_LABEL' white /> : null}
      <span>Cover image</span>
    </CoverImage>
  );
};

export default CoverImageLabel;

import React from 'react';
import styled from 'styled-components';

import CustomList from 'view/components/CustomList';

import {useSortedAmenities} from './useSortedAmenities';

import {EAmenitiesCategories} from 'types/venue';
import {amenitiesCategoriesMap} from './types';

import {
  fontWeightExtraBold,
  mdBp,
  offsetSm,
  offsetXLg,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  offsetXXXLg,
} from 'constants/styleVars';

const Group = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  gap: ${offsetXXLg} ${offsetXXSm};

  @media (min-width: ${mdBp}px) {
    gap: ${offsetXXXLg} ${offsetXXSm};
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeightExtraBold};
  margin-bottom: ${offsetXLg};
`;

const StyledCustomList = styled(CustomList)`
  margin-left: ${offsetXSm};
`;

export const VenueAmenitiesBlock = () => {
  const sortedAmenities = useSortedAmenities();

  const filteredAmenities = Object.entries(sortedAmenities).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, val]) => val.length,
  );

  return (
    <Group>
      {filteredAmenities.map(([key, val]) => {
        const list = val.map((item) => {
          return {value: item.label};
        });
        return (
          <div key={key}>
            <Title>{amenitiesCategoriesMap[key as EAmenitiesCategories]}</Title>
            <StyledCustomList size='large' list={list} offset={offsetSm} />
          </div>
        );
      })}
    </Group>
  );
};

export default VenueAmenitiesBlock;

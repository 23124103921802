import {useContext, useMemo} from 'react';
import {useAppSelector} from 'store/hooks';

import {FeatureToggleContext} from 'context/FeatureToggleContext';
import {EFeatureToggles} from 'constants/featureToggles';

export const useFeatureToggle = (featureName: EFeatureToggles) => {
  const environment = useAppSelector(({app}) => app.environment);

  /**
   * we need to read values defined in the FeatureToggleContext.
   * In this case, we'll take only the array of enabled features.
   */
  const {enabledFeatures} = useContext(FeatureToggleContext);

  const isLoaded = !!enabledFeatures;

  const isEnabled = useMemo((): boolean => {
    if (!enabledFeatures || !environment) {
      return false;
    }

    return enabledFeatures.includes(featureName);
  }, [enabledFeatures, environment, featureName]);
  /**
   * For consistency, we return an array of helpers,
   * so we follow the pattern defined by the useState hook.
   */
  return [isEnabled, isLoaded];
};

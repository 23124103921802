import React, {FC, useState} from 'react';
import styled from 'styled-components';

import AddUserButton from '../AddUserButton';
import UsersTable from '../UsersTable';
import Pagination from '../Pagination';
import Tabs from '../Tabs/Tabs';
import NW2Loader from 'view/components/NW2Loader';
import Icon from 'view/components/Icon';
import {TermsAndConditionsUploader} from '../TermsAndConditionsUploader/TermsAndConditionsUploader';

import {offsetXLg} from 'constants/styleVars';
import {EFeatureToggles} from 'constants/featureToggles';
import {useFeatureToggle} from 'hooks/useFeatureToggle';
import {ECompanyTab} from '../Tabs/types';
import {
  NoItemsBlock,
  NoItemsContent,
  NoItemsText,
} from 'view/admin/common.styles';

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${offsetXLg};
`;

const CompanyUsers: FC<{
  companyId: number;
  loading: boolean;
  totalUsers: number;
}> = ({companyId, loading, totalUsers}) => {
  const [tabValue, setTabValue] = useState(ECompanyTab.ASSOCIATED_EMPLOYEES);

  const isUsersTable = tabValue === ECompanyTab.ASSOCIATED_EMPLOYEES;
  const isTermsAndConditions = tabValue === ECompanyTab.TERMS_AND_CONDITIONS;

  const onTabChange = (value: ECompanyTab): void => {
    setTabValue(value);
  };

  const [isTermsAndConditionsEnabled] = useFeatureToggle(
    EFeatureToggles.T_AND_C,
  );

  const tabsData = [
    {
      label: 'Associated Employees',
      value: ECompanyTab.ASSOCIATED_EMPLOYEES,
    },
    ...(isTermsAndConditionsEnabled
      ? [
          {
            label: 'Terms and conditions',
            value: ECompanyTab.TERMS_AND_CONDITIONS,
          },
        ]
      : []),
  ];

  const renderContent = () => {
    if (loading) {
      return <NW2Loader height='100%' />;
    }

    if (isUsersTable) {
      if (totalUsers > 0) {
        return <UsersTable />;
      }
      return (
        <NoItemsBlock>
          <NoItemsContent>
            <Icon icon='DOCUMENT' secondary />
            <NoItemsText>No users added yet</NoItemsText>
          </NoItemsContent>
        </NoItemsBlock>
      );
    }

    if (isTermsAndConditions) {
      return <TermsAndConditionsUploader />;
    }

    return null;
  };

  return (
    <>
      {isUsersTable && (
        <ButtonBlock>
          <AddUserButton companyId={companyId} />
        </ButtonBlock>
      )}

      <Tabs onTabChange={onTabChange} tabValue={tabValue} tabsData={tabsData} />
      {renderContent()}

      {isUsersTable && <Pagination />}
    </>
  );
};

export default CompanyUsers;

import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Card} from 'antd';
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'store/hooks';
import styled from 'styled-components';
import _get from 'lodash/get';

import {Routes} from 'constants/routes';
import LoginContent from '../components/LoginContent';
import useLoginInSystem from 'hooks/useLoginInSystem';
import {setVenueAlreadyExists} from 'store/venue/actions';
import {formMaxWidth, offsetLg} from 'constants/styleVars';
import {useRedirectOfferLinkAfterLogin} from 'view/venue/Offer/hooks/useRedirectOfferLinkAfterLogin';

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: ${formMaxWidth};
  margin: ${offsetLg} auto 0;
`;
const LoginSupplier = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isVenueAlreadyExists = useSelector((state) =>
    _get(state, 'venue.isVenueAlreadyExists'),
  );
  const openForgotPassword = () => {
    navigate(Routes.forgotPassword);
  };

  const [redirectLinkAfterLogin] = useRedirectOfferLinkAfterLogin();

  const {isLoading, isSubmitFailed, loginInSystem} = useLoginInSystem({
    redirectLinkAfterLogin,
  });

  useEffect(() => {
    return () => {
      dispatch(setVenueAlreadyExists(false));
    };
  }, [dispatch]);

  const title = isVenueAlreadyExists ? 'Venue already registered' : 'Login';
  const description = isVenueAlreadyExists
    ? 'Your venue has already been set up. Please log in to access your account.'
    : '';

  return (
    <StyledCard>
      <LoginContent
        loginTitle={title}
        loginDescription={description}
        isLoading={isLoading}
        isSubmitFailed={isSubmitFailed}
        loginInSystem={loginInSystem}
        openForgotPassword={openForgotPassword}
      />
    </StyledCard>
  );
};

export default LoginSupplier;

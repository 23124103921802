import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  IAnnouncementRespond,
  ICorporateAccountCompany,
  ICorporateAccountCompanyUser,
} from './types';
import {
  INITIAL_CORPORATE_ACCOUNT_COMPANIES_PER_PAGE,
  INITIAL_CORPORATE_ACCOUNT_COMPANY_USERS_PER_PAGE,
} from 'constants/admin';
import {EMPTY_ARRAY} from 'constants/app';
import {TUploadFile} from 'view/components/Uploader/types';

interface IAdminReducer {
  corporateAccountCompanies: ICorporateAccountCompany[];
  corporateAccountCompaniesPage: number;
  corporateAccountCompaniesSize: number;
  importedCompany: ICorporateAccountCompany | null;
  totalCorporateAccountCompanies: number;
  corporateAccountCompaniesLoading: boolean;
  corporateAccountCompanyUsers: ICorporateAccountCompanyUser[];
  corporateAccountCompanyUsersPage: number;
  corporateAccountCompanyUsersSize: number;
  totalCorporateAccountCompanyUsers: number;
  corporateAccountCompanyUsersLoading: boolean;
  unassignedUsers: ICorporateAccountCompanyUser[];
  unassignedUsersPage: number;
  unassignedUsersSize: number;
  totalUnassignedUsers: number;
  unassignedUsersLoading: boolean;
  announcementUpdateLoadingId: number;
  announcements: IAnnouncementRespond[];
  announcementsLoading: boolean;
  announcementIdToEdit: number | null;
  isAnnouncementActionPopupActive: boolean;
  corporateAccountCompany: ICorporateAccountCompany | null;
  isLoading: boolean;
  cancellationPoliciesFile: TUploadFile | null;
  termsAndConditionsFile: TUploadFile | null;
}

const initialState: IAdminReducer = {
  /* CompaniesOverview page state */
  corporateAccountCompanies: [],
  corporateAccountCompaniesPage: 0,
  corporateAccountCompaniesSize: INITIAL_CORPORATE_ACCOUNT_COMPANIES_PER_PAGE, // number of companies displayed on one page
  totalCorporateAccountCompanies: 0,
  corporateAccountCompaniesLoading: false,

  /* Imported company */
  importedCompany: null,

  /* AdminCompanyAccount page state */
  corporateAccountCompanyUsers: [],
  corporateAccountCompanyUsersPage: 0,
  corporateAccountCompanyUsersSize:
    INITIAL_CORPORATE_ACCOUNT_COMPANY_USERS_PER_PAGE, // number of users displayed on one page
  totalCorporateAccountCompanyUsers: 0,
  corporateAccountCompanyUsersLoading: false,

  /* UnassignedUsers page state */
  unassignedUsers: [],
  unassignedUsersPage: 0,
  unassignedUsersSize: INITIAL_CORPORATE_ACCOUNT_COMPANY_USERS_PER_PAGE, // number of users displayed on one page
  totalUnassignedUsers: 0,
  unassignedUsersLoading: false,

  /* Announcements */
  announcements: [],
  announcementsLoading: false,
  announcementUpdateLoadingId: 0,
  announcementIdToEdit: null,
  isAnnouncementActionPopupActive: false,

  /* Current company */
  corporateAccountCompany: null,

  /* Other */
  isLoading: false,
  cancellationPoliciesFile: null,
  termsAndConditionsFile: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAnnouncements(state, action: PayloadAction<IAnnouncementRespond[]>) {
      state.announcements = action.payload;
    },
    updateAnnouncements(state, action: PayloadAction<IAnnouncementRespond>) {
      const announcementToUpdateIndex = state.announcements.findIndex(
        (e) => e.id === action.payload.id,
      );
      state.announcements[announcementToUpdateIndex] = action.payload;
    },
    setAnnouncementUpdateLoadingId(state, action: PayloadAction<number>) {
      state.announcementUpdateLoadingId = action.payload;
    },
    setAnnouncementsLoading(state, action: PayloadAction<boolean>) {
      state.announcementsLoading = action.payload;
    },
    setAnnouncementIdToEdit(state, action: PayloadAction<number | null>) {
      state.announcementIdToEdit = action.payload;
    },
    setCorporateAccountCompanies(
      state,
      action: PayloadAction<ICorporateAccountCompany[]>,
    ) {
      state.corporateAccountCompanies = action.payload || EMPTY_ARRAY;
      state.corporateAccountCompaniesLoading = false;
    },
    setCorporateAccountCompaniesPage(state, action: PayloadAction<number>) {
      state.corporateAccountCompaniesPage = action.payload;
    },
    setCorporateAccountCompaniesSize(state, action: PayloadAction<number>) {
      state.corporateAccountCompaniesSize = action.payload;
    },
    setTotalCorporateAccountCompanies(state, action: PayloadAction<number>) {
      state.totalCorporateAccountCompanies = action.payload;
    },
    setCorporateAccountCompaniesLoading(state, action: PayloadAction<boolean>) {
      state.corporateAccountCompaniesLoading = action.payload;
    },
    setCorporateAccountCompanyUsersLoading(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.corporateAccountCompanyUsersLoading = action.payload;
    },
    setCorporateAccountCompanyUsers(
      state,
      action: PayloadAction<ICorporateAccountCompanyUser[]>,
    ) {
      state.corporateAccountCompanyUsers = action.payload;
      state.corporateAccountCompanyUsersLoading = false;
    },
    addCorporateAccountCompanyUser(
      state,
      action: PayloadAction<ICorporateAccountCompanyUser>,
    ) {
      const {totalCorporateAccountCompanyUsers, corporateAccountCompanyUsers} =
        state;

      state.corporateAccountCompanyUsers = [
        action.payload,
        ...corporateAccountCompanyUsers.map((user) => ({
          ...user,
          isHighlighted: false,
        })),
      ];
      state.corporateAccountCompanyUsersLoading = false;
      state.totalCorporateAccountCompanyUsers =
        totalCorporateAccountCompanyUsers + 1;
    },
    setCorporateAccountCompanyUsersPage(state, action: PayloadAction<number>) {
      state.corporateAccountCompanyUsersPage = action.payload;
    },
    setCorporateAccountCompanyUsersSize(state, action: PayloadAction<number>) {
      state.corporateAccountCompanyUsersSize = action.payload;
    },
    setTotalCorporateAccountCompanyUsers(state, action: PayloadAction<number>) {
      state.totalCorporateAccountCompanyUsers = action.payload;
    },
    setUnassignedUsersLoading(state, action: PayloadAction<boolean>) {
      state.unassignedUsersLoading = action.payload;
    },
    setUnassignedUsers(
      state,
      action: PayloadAction<ICorporateAccountCompanyUser[]>,
    ) {
      state.unassignedUsers = action.payload || EMPTY_ARRAY;
      state.unassignedUsersLoading = false;
    },
    setTotalUnassignedUsers(state, action: PayloadAction<number>) {
      state.totalUnassignedUsers = action.payload;
    },
    setUnassignedUsersPage(state, action: PayloadAction<number>) {
      state.unassignedUsersPage = action.payload;
    },
    setUnassignedUsersSize(state, action: PayloadAction<number>) {
      state.unassignedUsersSize = action.payload;
    },
    setImportedCompany(
      state,
      action: PayloadAction<ICorporateAccountCompany | null>,
    ) {
      state.importedCompany = action.payload;
      state.isLoading = false;
    },
    clearImportedCompany(state) {
      state.importedCompany = null;
    },
    setCorporateAccountCompany(
      state,
      action: PayloadAction<ICorporateAccountCompany>,
    ) {
      state.corporateAccountCompany = action.payload;
      state.isLoading = false;
    },
    clearCorporateAccountCompany(state) {
      state.corporateAccountCompany = null;
    },

    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setCancellationPoliciesFile(
      state,
      action: PayloadAction<TUploadFile | null>,
    ) {
      state.cancellationPoliciesFile = action.payload;
    },
    clearCancellationPoliciesFile(state) {
      state.cancellationPoliciesFile = null;
    },
    setTermsAndConditionsFile(
      state,
      action: PayloadAction<TUploadFile | null>,
    ) {
      state.termsAndConditionsFile = action.payload;
    },
    clearTermsAndConditionsFile(state) {
      state.termsAndConditionsFile = null;
    },
  },
});

export const {
  setAnnouncements,
  setAnnouncementsLoading,
  setAnnouncementUpdateLoadingId,
  updateAnnouncements,
  setAnnouncementIdToEdit,
  setCorporateAccountCompanies,
  addCorporateAccountCompanyUser,
  setCorporateAccountCompaniesLoading,
  setCorporateAccountCompaniesPage,
  setCorporateAccountCompaniesSize,
  setCorporateAccountCompanyUsers,
  setCorporateAccountCompanyUsersLoading,
  setCorporateAccountCompanyUsersPage,
  setCorporateAccountCompanyUsersSize,
  setTotalCorporateAccountCompanies,
  setUnassignedUsersPage,
  setTotalUnassignedUsers,
  setTotalCorporateAccountCompanyUsers,
  setUnassignedUsers,
  setUnassignedUsersLoading,
  setUnassignedUsersSize,
  setImportedCompany,
  clearImportedCompany,
  setCorporateAccountCompany,
  clearCorporateAccountCompany,
  setIsLoading,
  setCancellationPoliciesFile,
  clearCancellationPoliciesFile,
  setTermsAndConditionsFile,
  clearTermsAndConditionsFile,
} = adminSlice.actions;

export default adminSlice.reducer;

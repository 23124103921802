import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import {PATH_TO_REDUCER_GLOBAL_VENUE_DATA} from 'constants/venue';
import {RootState} from 'store/types';
import {IGlobalVenue} from 'types/venue';

// ToDo change name as per current structure (use for Supplier), remove hook
const useGlobalVenue = () => {
  const globalVenue: IGlobalVenue = useSelector((state: RootState) =>
    _get(state, PATH_TO_REDUCER_GLOBAL_VENUE_DATA),
  );

  return [globalVenue];
};

export default useGlobalVenue;

import React from 'react';
import Icon from 'view/components/Icon';

import {Container, StyledImage, Name, Location} from './styles';

interface IProps {
  image: string;
  venueName: string;
  location: string;
}

const MultiVenueMenuButton = ({image, venueName, location}: IProps) => {
  return (
    <Container>
      <StyledImage src={image} />
      <div>
        <Name>{venueName}</Name>
        <Location>
          <Icon icon='NW2_LOCATION_OUTLINE' /> {location}
        </Location>
      </div>
    </Container>
  );
};

export default MultiVenueMenuButton;

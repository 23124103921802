import React from 'react';
import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {IBooking} from 'types/dto/IBooking.types';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export function BookingExtrasColumn(
  extras: number,
  row: IBooking,
  index: number,
) {
  return (
    <Container key={index}>
      {row.bookedExtras.length ? <Icon icon='TASK_LIST_PEN' /> : '-'}
    </Container>
  );
}

import styled from 'styled-components';

import {
  borderRadiusLg,
  fontWeightBold,
  lineHeightMd,
  NW2Blue100Color,
  NW2Blue500Color,
  NW2Gray100Color,
  NW2Surface800Color,
  offsetSm,
  offsetXSm,
  sectionBoxShadow,
  whiteColor,
} from 'constants/styleVars';

const TRIGGER_ITEM_SIZE = '26px';

export const ListWrapper = styled.ul<{maxHeight?: string}>`
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: ${whiteColor};
  box-shadow: ${sectionBoxShadow};
  border-radius: ${borderRadiusLg};
  overflow: auto;
  max-height: ${({maxHeight}) => maxHeight || 'auto'};
`;

export const ListItem = styled.li`
  background-color: ${whiteColor};
  box-shadow: inset 0 -1px 0 #e1e1e1;
  transition: background-color 0.15s;
  user-select: none;
  cursor: pointer;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: ${borderRadiusLg};
    border-top-right-radius: ${borderRadiusLg};
  }

  &:last-child {
    border-bottom-left-radius: ${borderRadiusLg};
    border-bottom-right-radius: ${borderRadiusLg};
    box-shadow: none;
  }

  &:hover {
    background-color: ${NW2Blue100Color};
  }

  &:active {
    background-color: ${NW2Blue500Color};
  }
`;

export const ListTitle = styled.li`
  box-shadow: inset 0 -1px 0 #e1e1e1;
  transition: background-color 0.15s;
  color: ${NW2Surface800Color};
  line-height: ${lineHeightMd};
  font-weight: ${fontWeightBold};
  padding: ${offsetXSm} ${offsetSm};
`;

export const TriggerItemWrapper = styled.div`
  display: inline-flex;
  user-select: none;
  cursor: pointer;
`;

export const TriggerItem = styled.div<{triggerItemSize?: string}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${NW2Gray100Color};
  width: ${({triggerItemSize}) => triggerItemSize || TRIGGER_ITEM_SIZE};
  height: ${({triggerItemSize}) => triggerItemSize || TRIGGER_ITEM_SIZE};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {Upload, Modal} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';
import {RcFile, UploadChangeParam} from 'antd/lib/upload/interface';
import {UploadRequestOption as RcCustomRequestOptions} from 'rc-upload/lib/interface';

import UploadButton from './components/UploadButton';
import Icon from '../Icon/Icon';
import CoverImageLabel from '../CoverImageLabel';
import {isJpegOrJpgOrPng, isImageValid, maxSize, minSize} from 'utils/helpers';
import {
  getBase64,
  maxAmountOfImages,
  sliceImageFiles,
  uploadAlignment,
  resizeFile,
} from './imageUploaderHelper';
import {TAlignment, TImageFile, TOnError} from './types';
import {TStepMode} from 'types/app';
import {maximumImageFileSize, minimumImageFileSize} from 'constants/app';
import {
  StyledButton,
  ButtonContainer,
  UploadContainer,
  ItemRenderContainer,
  IconContainer,
} from './ImageUploader.styles';

type TProps = {
  imageFileList: TImageFile[];
  coverImage: string;
  onError?: ({uid, message}: TOnError) => void;
  onImageUpload?: (file: UploadFile) => void;
  setCoverImage?: (file: TImageFile) => void;
  onRemove?: (file: TImageFile) => void;
  disableUploadBtn?: boolean;
  align?: 'start' | 'center' | 'end';
  onChange?: (e: UploadFile[]) => void;
  value?: UploadFile[];
  valuePriority?: boolean;
  mode?: TStepMode | 'edit';
};

const ImageUploader = ({
  value,
  valuePriority = true,
  onChange,
  imageFileList,
  coverImage,
  onError,
  onImageUpload,
  onRemove,
  setCoverImage,
  disableUploadBtn = false,
  align = 'start',
  mode = 'view',
}: TProps) => {
  const [previewVisibleState, setPreviewVisibleState] = useState({
    previewVisible: false,
    previewImage: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorFile, setErrorFile] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[] | null>(null);
  const [isShowMore, setIsShowMore] = useState(false);

  const isShowAllButtonBlockVisible =
    imageFileList.length > maxAmountOfImages && mode === 'view';

  const findCoverImage = (fileList: TImageFile[]) =>
    fileList.find((file) => file.isCover);

  const findCoverImageName = (fileList: TImageFile[], coverImage: string) =>
    fileList.find((file) => file.uid === coverImage);

  const handleCoverImage = useCallback(
    (file: TImageFile): void => {
      if (setCoverImage) setCoverImage(file);
    },
    [setCoverImage],
  );

  useEffect(() => {
    if (
      imageFileList.length &&
      ((!coverImage && !findCoverImage(imageFileList)) ||
        (coverImage && !findCoverImageName(imageFileList, coverImage)))
    ) {
      handleCoverImage(imageFileList[0]);
    }
  }, [imageFileList, coverImage, handleCoverImage]);

  useEffect(() => {
    if (isShowAllButtonBlockVisible) {
      setFileList(sliceImageFiles(imageFileList as UploadFile[]));
      setIsShowMore(true);
    } else {
      setFileList(null);
    }
  }, [isShowAllButtonBlockVisible, imageFileList]);

  const handleCancel = () => {
    setPreviewVisibleState((prevState) => {
      return {...prevState, previewVisible: false};
    });
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewVisibleState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleChange = async ({
    fileList: modFileList,
    file,
  }: UploadChangeParam) => {
    switch (file.status) {
      case 'done': {
        const isCoverImage = modFileList.length === 1 || !coverImage;

        if (onImageUpload) onImageUpload(file);
        if (isCoverImage && setCoverImage) setCoverImage(file);

        setIsLoading(false);
        break;
      }
      default:
        break;
    }
    setFileList([...modFileList]);
    if (onChange) onChange(modFileList);
  };

  const beforeUpload = async (file: RcFile): Promise<boolean | Blob> => {
    setIsLoading(true);
    if (!isJpegOrJpgOrPng(file) && onError) {
      onError({
        uid: file.uid,
        message: `${file.name} has invalid format`,
      });
    }

    if (maxSize(file) && onError) {
      onError({
        uid: file.uid,
        message: `${file.name} must be smaller than ${maximumImageFileSize}`,
      });
    }

    if (!minSize(file) && onError) {
      onError({
        uid: file.uid,
        message: `${file.name} must be bigger than ${minimumImageFileSize}`,
      });
    }

    const isFileValid = isImageValid(file);

    if (isFileValid) return resizeFile(file);
    setErrorFile((prevState) => [...prevState, file.uid]);
    return isFileValid;
  };

  const handleRemove = (file: TImageFile) => {
    if (onRemove) onRemove(file);
  };

  const customRequest = async (
    options: RcCustomRequestOptions,
  ): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 0));
    if (options.onSuccess) options.onSuccess({}, new XMLHttpRequest());
  };

  const showUploadList = {
    showDownloadIcon: !disableUploadBtn,
    showPreviewIcon: true,
    showRemoveIcon: true,
    downloadIcon: (
      <IconContainer title='Set Cover Image'>
        <Icon icon='COVER_LABEL' white />
      </IconContainer>
    ),
    removeIcon: (
      <IconContainer>
        <Icon icon='DELETE' white />
      </IconContainer>
    ),
  };

  const itemRender = (originNode: ReactElement, file: UploadFile) => {
    const modFile: TImageFile =
      'percent' in file ? file : {...file, percent: 100};
    const isCoverImage =
      (coverImage && coverImage === file.uid) ||
      (modFile.isCover && !coverImage);

    const isError =
      (!modFile.percent && modFile.status !== 'done') ||
      errorFile.includes(modFile.uid as string);

    const isLastImageInUploader = mode === 'edit' && fileListValues.length <= 1;

    return (
      <ItemRenderContainer
        isLastImageInUploader={isLastImageInUploader}
        error={isError}
        isCoverImage={!!isCoverImage}
        data-test-class='imageUploaderImageItem'
      >
        {originNode}
        {isCoverImage && <CoverImageLabel />}
      </ItemRenderContainer>
    );
  };

  const handleShowAllImages = () => {
    setIsShowMore((prevState) => {
      if (prevState) {
        setFileList(imageFileList as UploadFile[]);
        return false;
      }
      setFileList(sliceImageFiles(imageFileList as UploadFile[]));
      return true;
    });
  };

  const {previewVisible, previewImage} = previewVisibleState;

  if (disableUploadBtn && !imageFileList.length) return null;

  const fileListValues = valuePriority
    ? value || fileList || imageFileList
    : fileList || value || imageFileList;

  return (
    <UploadContainer
      alignment={uploadAlignment[align] as TAlignment}
      isImgUploaded={!!value?.length || !!imageFileList.length}
      id='imageUploaderUploadContainer'
    >
      <Upload
        listType='picture-card'
        multiple={true}
        fileList={fileListValues as UploadFile[]}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        onDownload={handleCoverImage}
        onRemove={handleRemove}
        showUploadList={showUploadList}
        disabled={disableUploadBtn}
        itemRender={itemRender}
        accept='image/jpg,image/jpeg,image/png'
      >
        {!disableUploadBtn && (
          <UploadButton fileList={fileListValues} isLoading={isLoading} />
        )}
      </Upload>
      {isShowAllButtonBlockVisible && (
        <ButtonContainer>
          <StyledButton
            type='link'
            onClick={handleShowAllImages}
            id='imageUploaderMoreLessButton'
          >
            {isShowMore
              ? `Show all ${imageFileList.length} images`
              : 'Show less'}
          </StyledButton>
        </ButtonContainer>
      )}
      <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt='preview image' style={{width: '100%'}} src={previewImage} />
      </Modal>
    </UploadContainer>
  );
};

export default ImageUploader;

import React from 'react';

import Icon from 'view/components/Icon';

import {
  StyledButtonBox,
  StyledButtonEdit,
  StyledCancelButton,
  StyledCustomButton,
} from '../NW2DatepickerDetails.styles';

type TProps = {
  isMobile: boolean;
  isChangeActivated: boolean;
  isPanelTouched: boolean;
  handleUpdate: () => void;
  handleCancel: () => void;
  onChangeModeToggle: () => void;
};

const ButtonsSection = ({
  isMobile,
  isChangeActivated,
  isPanelTouched,
  handleUpdate,
  handleCancel,
  onChangeModeToggle,
}: TProps) => {
  const getButton = () => {
    const buttonSize = isMobile ? 'large' : 'small';
    switch (true) {
      case isPanelTouched:
        return (
          <StyledCustomButton
            onClick={handleUpdate}
            buttonType='primary'
            size={buttonSize}
          >
            update
          </StyledCustomButton>
        );

      case isChangeActivated || isMobile:
        return (
          <StyledCancelButton
            onClick={handleCancel}
            buttonType='secondary'
            size={buttonSize}
            isMobile={isMobile}
          >
            cancel
          </StyledCancelButton>
        );

      default:
        return (
          <StyledButtonEdit
            onClick={onChangeModeToggle}
            buttonType='link'
            size='small'
            icon={<Icon icon='NW2_EDIT' size={20} transparent />}
          >
            change
          </StyledButtonEdit>
        );
    }
  };

  return <StyledButtonBox>{getButton()}</StyledButtonBox>;
};

export default ButtonsSection;

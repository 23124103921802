import React, {useMemo} from 'react';

import Icon from 'view/components/Icon';
import Image from 'view/components/Image';
import NW2ModalTwoColumns from 'view/components/NW2ModalTwoColumns';

import {borderRadiusDef} from 'constants/styleVars';
import {TImage} from 'types/app';
import {ELink} from '../../types';

import {
  StyledButtonLink,
  StyledImageItem,
  StyledSwitchers,
  StyledWrapper,
  MorePhotosBtn,
} from './NW2MorePhotos.styles';

type TProps = {
  isMorePhotosShowed: boolean;
  setMorePhotosShowed: (state: boolean) => void;
  activeLink: ELink;
  setActiveLink: (link: ELink) => void;
  showGallery: (uid: string) => void;
  images: TImage[];
  meetingRoomsImagesNumber: number;
  workSpacesImagesNumber: number;
};

export function NW2MorePhotos({
  isMorePhotosShowed,
  setMorePhotosShowed,
  showGallery,
  activeLink,
  setActiveLink,
  images,
  meetingRoomsImagesNumber = 0,
  workSpacesImagesNumber = 0,
}: TProps) {
  const onToggleShowMore = () => {
    setMorePhotosShowed(!isMorePhotosShowed);
  };

  const onShowGallery = (uid: string) => () => {
    showGallery(uid);
  };

  const switchers = useMemo(() => {
    const onLinkClick = (val: ELink) => () => {
      setActiveLink(val);
    };

    return (
      <StyledSwitchers>
        <StyledButtonLink
          buttonType='link'
          onClick={onLinkClick(ELink.VENUE)}
          isActive={activeLink === ELink.VENUE}
        >
          Venue
        </StyledButtonLink>

        {meetingRoomsImagesNumber > 0 && (
          <StyledButtonLink
            buttonType='link'
            onClick={onLinkClick(ELink.MEETINGS)}
            isActive={activeLink === ELink.MEETINGS}
          >
            Meetings
          </StyledButtonLink>
        )}

        {workSpacesImagesNumber > 0 && (
          <StyledButtonLink
            buttonType='link'
            onClick={onLinkClick(ELink.DESKS)}
            isActive={activeLink === ELink.DESKS}
          >
            Desks
          </StyledButtonLink>
        )}
      </StyledSwitchers>
    );
  }, [
    activeLink,
    setActiveLink,
    meetingRoomsImagesNumber,
    workSpacesImagesNumber,
  ]);

  return (
    <>
      <MorePhotosBtn
        size='small'
        buttonType='primary'
        inline
        white
        icon={<Icon transparent icon='NW2_FOLDER_IMAGE' />}
        onClick={onToggleShowMore}
      >
        More Photos
      </MorePhotosBtn>

      <NW2ModalTwoColumns
        title='More Photos'
        isShowed={isMorePhotosShowed}
        onToggleShow={onToggleShowMore}
        colLeft={switchers}
        colRight={
          <StyledWrapper>
            {images.map(({uid, name, url}: TImage, idx) => (
              <StyledImageItem
                key={`details-more-photos-${uid}-${idx}`}
                onClick={onShowGallery(uid)}
              >
                <Image src={url} borderRadius={borderRadiusDef} alt={name} />
              </StyledImageItem>
            ))}
          </StyledWrapper>
        }
        isLeftColumnFixed
      />
    </>
  );
}

import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {ISpacesData} from 'types/venue';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {fontSizeMd, lgBp, lineHeightLg, offsetXLg} from 'constants/styleVars';
import {EVenueSupplierPagesWithHeader} from 'view/venue/NW2VenueProfile/types';

const GRID_WIDTH = '676px';

const MainText = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};

  @media (min-width: ${lgBp}px) {
    width: ${GRID_WIDTH};
  }
`;

type TReturnProps = {
  title: string | undefined;
  subtitle?: ReactNode | string;
  items: string | number;
  content: ReactNode;
  gridWidth?: string;
  gap?: string;
};

interface IProps {
  spaces: ISpacesData[];
  accommodationType: EAccommodationType;
  type: EVenueSupplierPagesWithHeader;
  handleCreateRoom?: () => void;
}

const usePackagesHeader = ({type}: IProps): TReturnProps => {
  return {
    title: type,
    items: 1,
    gridWidth: GRID_WIDTH,
    gap: offsetXLg,
    content: (
      <>
        <div data-testid='space-total-count'>
          <MainText>
            All selected packages will be available for customers to book with
            their meeting space of choice. The package price is quoted on a
            per-person basis.
          </MainText>
        </div>
      </>
    ),
  };
};

export default usePackagesHeader;

import {TContainer} from 'app';
import {message, notification} from 'antd';
import _get from 'lodash/get';
import ApiInventoryService from 'infra/common/apiInventoryService';
import tokenService from 'infra/common/token.service';
import {
  onSignOutUser,
  setCognitoUserRole,
  setCorporateAccountId,
  setCustomerBillingAddresses,
  setCustomerDefaultBillingAddress,
  setExpiresAt,
  setIsAuthConfigured,
  setToken,
  setUserCustomer,
} from './appSlice';
import {
  clearSupplierVenueId,
  setSupplierVenueId,
} from 'store/venueDetails/venueDetailsSlice';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {
  ICreateCustomerBillingAddressPayload,
  ICustomerBillingAddress,
  IUser,
} from 'types/dto/IUser.types';
import {AppDispatch, RootState} from '../types';
import {TFeedbackFormPayload, TRequestToHRSSupportPayload} from 'types/app';
import {ERoomType} from 'types/dto/ERoomType.type';
import store from 'store/config';
import {clearShortList} from 'store/offers/offersSlice';
import {clearOfficesPayload} from 'store/corporateOffice/corporateOfficeSlice';
import {clearVenueData} from '../venue/actions';
import {clearPackages} from 'store/packages/packagesSlice';

export const getUserDataByEmail =
  (email: string, cognitoUserRole: EUserRoleCognito) =>
  async (
    dispatch: AppDispatch,
    _: () => RootState,
    {appContainer}: TContainer,
  ) => {
    await appContainer.getUserDataByEmail({
      payload: email,
      onSuccess: (data: {
        userId: string;
        venueIds: string[];
        corporateAccountId: number;
      }) => {
        const isSupplier = cognitoUserRole === EUserRoleCognito.ROLE_SUPPLIER;
        const {venueIds, userId, corporateAccountId} = data;
        const isAuthConfigured = store.getState().app.isAuthConfigured;
        if (isSupplier) {
          dispatch(setSupplierVenueId(Number(venueIds[0])));
        }
        dispatch(setCorporateAccountId(corporateAccountId));
        if (cognitoUserRole === EUserRoleCognito.ROLE_CUSTOMER) {
          dispatch(getUserCustomer(userId, cognitoUserRole));
        }
        if (!isAuthConfigured) dispatch(setIsAuthConfigured(true));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

//
export const getUserCustomer =
  (id: number | string, role: EUserRoleCognito) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    const customerBillingAddresses =
      await ApiInventoryService.getCustomerBillingAddresses(Number(id));

    await appContainer.getCustomer({
      payload: Number(id),
      onSuccess: (data: IUser) => {
        const defaultBillingAddress = customerBillingAddresses.find(
          (item: ICustomerBillingAddress) => item.isDefault,
        );
        dispatch(
          setCustomerDefaultBillingAddress(defaultBillingAddress || null),
        );
        dispatch(
          setUserCustomer({
            ...data,
            role,
            billingAddress: customerBillingAddresses || [],
            isLoggedIntoSystem: true,
          }),
        );
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };
//
export const getCustomerBillingAddresses =
  (customerId: number) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    await appContainer.getCustomerBillingAddresses({
      payload: customerId,
      onSuccess: (data: ICustomerBillingAddress[]) => {
        dispatch(setCustomerBillingAddresses(data));

        const defaultBillingAddress = data.find((item) => item.isDefault);
        dispatch(setCustomerDefaultBillingAddress(defaultBillingAddress));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const createCustomerBillingAddress =
  (data: ICreateCustomerBillingAddressPayload) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    const customerIdFromStore = getState().app.user.id || '';
    await appContainer.createCustomerBillingAddress({
      payload: data,
      onSuccess: () => {
        dispatch(getCustomerBillingAddresses(+customerIdFromStore));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };
export const updateDefaultCustomerBillingAddress =
  (payload: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    const customerIdFromStore = getState().app.user.id || '';
    await appContainer.updateDefaultCustomerBillingAddress({
      payload,
      onSuccess: () => {
        dispatch(getCustomerBillingAddresses(+customerIdFromStore));
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const deleteCustomerBillingAddress =
  (payload: string, callback?: () => void) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    const customerIdFromStore = getState().app.user.id || '';
    await appContainer.deleteCustomerBillingAddress({
      payload,
      onSuccess: () => {
        dispatch(getCustomerBillingAddresses(+customerIdFromStore));
        if (callback) callback();
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

export const postRequestToHRSSupport =
  (payload: TRequestToHRSSupportPayload, callback?: () => void) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    await appContainer.postRequestToHRSSupport({
      payload,
      onSuccess: () => {
        const isMeetingRoom = payload.roomType === ERoomType.MEETING_ROOM;
        const message = `Thank you for submitting your request. We will get in contact with you ${
          isMeetingRoom
            ? 'latest on the next working day'
            : 'once we on-boarded your favorite venue'
        }`;

        notification.success({
          message,
        });
        if (callback) callback();
      },
      onError: () => {
        message.error({
          content: 'Oops! Something has gone wrong!',
          key: 'errorMessage',
        });
      },
    });
  };

//
export const loginByJwtToken =
  (jwtToken: string) => async (dispatch: AppDispatch) => {
    const userDataFromToken = tokenService.getUserData(jwtToken);
    // @ts-ignore
    const {cognitoUserRole, email, expiresAt} = userDataFromToken;

    dispatch(setToken(jwtToken));
    dispatch(setCognitoUserRole(cognitoUserRole));
    await dispatch(getUserDataByEmail(email, cognitoUserRole));
    dispatch(setExpiresAt(expiresAt));

    return userDataFromToken;
  };

export const signOutUser =
  ({onSuccess}: {onSuccess?: () => void} = {}) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    const {role} = _get(getState(), 'app.user');
    await appContainer.signOutUser({
      onSuccess: () => {
        dispatch(onSignOutUser());
        dispatch(clearOfficesPayload());
        dispatch(clearShortList());
        dispatch(clearVenueData());
        // TODO: Refactor when supplier packages will be separate in store
        if (role === EUserRoleCognito.ROLE_SUPPLIER) {
          dispatch(clearPackages());
          dispatch(clearSupplierVenueId());
        }

        onSuccess?.();
      },
      onError: () => {
        message.error({
          type: 'error',
          content: 'Oops! Something has gone wrong with sing out!',
          key: 'errorMessage',
        });
      },
    });
  };

export const sendFeedback =
  (formData: TFeedbackFormPayload) =>
  async (
    _: AppDispatch,
    getState: () => RootState,
    {appContainer}: TContainer,
  ) => {
    const {firstName, lastName, email, phone, id} = _get(
      getState(),
      'app.user',
    );

    const payload = {
      firstName,
      lastName,
      email,
      phone,
      userId: id,
      fullPath: window.location.href,
      ...formData,
    };

    await appContainer.postSendFeedback({
      payload,
      onSuccess: () => {
        notification.success({
          message:
            'Your feedback was successfully submitted. If you shared your indicated to be contacted we will get in touch soon.',
        });
      },
      onError: () => {
        notification.error({
          message: 'Oops! Something has gone wrong sending feedback!',
          key: 'errorMessage',
        });
      },
    });
  };

import {EIgnoreInterceptorCodes} from './types';

export const payErrors = [
  EIgnoreInterceptorCodes.HRS_PAY_REQUEST_IS_NOT_EXISTS,
  EIgnoreInterceptorCodes.HRS_PAY_VALIDATION_FAILED,
  EIgnoreInterceptorCodes.HRS_PAY_DTO_VALIDATION_FAILED,
  EIgnoreInterceptorCodes.HRS_PAY_DTO_RESERVATION_IS_NULL,
];

export const ignoreInterceptorCodes = [
  EIgnoreInterceptorCodes.UNAUTHORIZED_FETCH_VENUE_DETAILS_DATA_ERROR,
  EIgnoreInterceptorCodes.FORBIDDEN_ACCESS_TO_VENUE_DETAILS_ERROR,
  EIgnoreInterceptorCodes.AUTHORIZATION_FAILED,
  EIgnoreInterceptorCodes.PASSWORD_INVALID,
  ...payErrors,
];

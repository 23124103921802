import React from 'react';
import styled from 'styled-components';
import {LoadingOutlined} from '@ant-design/icons';

import Icon from 'view/components/Icon';
import {BlockTitle} from 'view/components/Typography';
import {TextValue} from 'view/components/TextValue';

import {TImageFile} from '../types';
import {
  fontWeightNormal,
  offsetDef,
  offsetXSm,
  offsetXXSm,
} from 'constants/styleVars';
import {
  maximumImageFileSize,
  minimumImageFileSize,
  validImageFormats,
} from 'constants/app';

const StyledUploadText = styled.div`
  margin-top: ${offsetXSm};
`;

type TProps = {
  fileList: TImageFile[];
  isLoading: boolean;
};

const UploadButtonContainer = styled.div`
  user-select: none;
  pointer-events: none;
`;

const StyledIcon = styled(Icon)<{size?: number; isImageUploaded: boolean}>`
  ${({size}) =>
    size
      ? `
  & > svg {
    width: ${size}px;
    height: ${size}px;
  }
  `
      : ''};

  ${({isImageUploaded}) =>
    !isImageUploaded ? `margin-bottom: ${offsetDef};` : ''}
`;

const StyledBlockTitle = styled(BlockTitle)`
  font-weight: ${fontWeightNormal};
  margin-bottom: ${offsetXXSm};
`;

const UploadButtonHolder = styled.div`
  pointer-events: none;
`;

const helperTextList = [
  {value: `Minimum file size: ${minimumImageFileSize}`},
  {value: `Maximum file size: ${maximumImageFileSize}`},
  {value: `File formats: ${validImageFormats}`},
];

const UploadButton = ({fileList, isLoading}: TProps) => (
  <UploadButtonHolder id='imageUploadButtonContainer'>
    {/* @ts-ignore */}
    {isLoading && <LoadingOutlined />}
    <UploadButtonContainer>
      {!fileList.length ? (
        <>
          {!isLoading && (
            <StyledIcon
              size={28}
              isImageUploaded={!!fileList.length}
              icon='UPLOAD'
              primary
            />
          )}
          <StyledBlockTitle>
            Click or drag images here to upload
          </StyledBlockTitle>
          {helperTextList.map(({value}) => (
            <TextValue key={value} secondary>
              {value}
            </TextValue>
          ))}
        </>
      ) : (
        <>
          {!isLoading && (
            <StyledIcon isImageUploaded={!!fileList.length} icon='UPLOAD' />
          )}
          <StyledUploadText>Upload more</StyledUploadText>
        </>
      )}
    </UploadButtonContainer>
  </UploadButtonHolder>
);

export default UploadButton;

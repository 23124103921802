import React from 'react';
import styled from 'styled-components';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {Link} from 'view/components/Typography';
import {StepHint} from './StepHint';
import {useOpenTermsAndConditionsModal} from 'view/common/NW2MultiModal/hooks/useOpenInfoPageModal';

import {PageContent} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {PageWrapper} from 'view/components/NW2Forms/FormWizard/FormWizard.styles';
import {infoColor, offset14} from 'constants/styleVars';
import {StepTitle, StepActions} from '../OfferCreate.styles';

const CardList = styled.ul`
  padding-left: ${offset14};
`;

interface IProps {
  handleNext: () => void;
  isAlternative?: boolean;
  isSomePricesMissed?: boolean;
}

export function StartPage({
  handleNext,
  isAlternative,
  isSomePricesMissed,
}: IProps) {
  const openTermsAndConditionsModal = useOpenTermsAndConditionsModal();

  return (
    <PageWrapper>
      <StepTitle>
        {isAlternative ? (
          'Before we start...'
        ) : isSomePricesMissed ? (
          <>A few more details and you’re set.</>
        ) : (
          <>
            <span>All information provided!</span>
            <span>Review & confirm</span>
          </>
        )}
      </StepTitle>

      <PageContent width={324}>
        {isAlternative ? (
          <>
            <StepHint
              icon='OFFER_DB'
              iconColor={infoColor}
              title='Everything is saved'
              text={`All of the information you provided, excluding prices for accommodation if requested,
           will be saved for future requests. This means you only need to enter it once!`}
            />

            <StepHint
              icon='OFFER_COMMISSION'
              iconColor={infoColor}
              title='Commission included'
              text={
                <>
                  HRS Connect applies commission on meeting and accommodation
                  bookings. For full information refer to our{' '}
                  <Link onClick={openTermsAndConditionsModal} black>
                    Terms & Conditions.
                  </Link>
                </>
              }
            />

            <StepHint
              icon='OFFER_BRIEFCASE'
              iconColor={infoColor}
              title='What you’ll need'
              text={
                <CardList>
                  <li>Room images and description</li>
                  <li>Space dimensions in square metres or feet</li>
                  <li>Meeting and guest room (if requested) prices</li>
                  <li>If requested, prices for equipment, food and beverage</li>
                </CardList>
              }
            />
          </>
        ) : (
          <>
            {isSomePricesMissed ? (
              <StepHint
                icon='NW2_TICK'
                iconColor={infoColor}
                title='Confirm availability'
                text={`The majority information, including pricing for meeting rooms as well as food & beverages,
                  have already been reviewed by the booker. There are just a few blank spots, where we need you
                  to provide the missing prices, review the rest & confirm.`}
              />
            ) : (
              <StepHint
                icon='NW2_TICK'
                iconColor={infoColor}
                title='Confirm availability'
                text={`All information, including pricing, has already been reviewed by the booker.
                  The only thing left for you to do is review & confirm.`}
              />
            )}

            <StepHint
              icon='OFFER_COMMISSION'
              iconColor={infoColor}
              title='Commission included'
              text={
                <>
                  HRS Connect applies commission on meeting and accommodation
                  bookings. For full information refer to our{' '}
                  <Link onClick={openTermsAndConditionsModal} black>
                    Terms & Conditions.
                  </Link>
                </>
              }
            />
          </>
        )}

        <StepActions>
          <NW2Button onClick={handleNext} buttonType='primary' size='small'>
            Next
          </NW2Button>
        </StepActions>
      </PageContent>
    </PageWrapper>
  );
}

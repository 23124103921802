import React, {useEffect} from 'react';
import {useForm} from 'react-final-form';
import styled from 'styled-components';

import NW2FormItemRadio from 'view/components/NW2FormItem/components/NW2FormItemRadio';
import {floorLevels, getKeyByValue} from 'constants/floorStorey';
import {offsetDef} from 'constants/styleVars';
import {ERadioSizes} from 'view/components/NW2FormItem/components/NW2FormItemRadio/types';
import {EAddMeetingRoomFormFields} from '../types';

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${offsetDef};
`;

interface IProps {
  fieldName: string;
  venueFloors: number[] | undefined;
  spaceFloor: number | string;
}

export const AddFloor = ({fieldName, venueFloors, spaceFloor}: IProps) => {
  const {mutators, getState} = useForm();
  const sameFloorAsSet = Number(getState().initialValues.floor) === spaceFloor;

  useEffect(() => {
    if (typeof spaceFloor !== undefined && !sameFloorAsSet) {
      mutators.setValue(fieldName, String(spaceFloor));
    }
  }, [sameFloorAsSet, mutators, spaceFloor, fieldName]);

  return (
    <RadioWrapper>
      {venueFloors?.map((floorNumber) => {
        const floorName = getKeyByValue(floorLevels, floorNumber);
        if (!floorName) return null;

        return (
          <NW2FormItemRadio
            value={String(floorNumber)}
            key={floorNumber}
            id={String(floorNumber)}
            label={floorName}
            name={EAddMeetingRoomFormFields.FLOOR}
            size={ERadioSizes.MEDIUM}
          />
        );
      })}
    </RadioWrapper>
  );
};

import styled from 'styled-components';

import CustomList from 'view/components/CustomList';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  fontSizeMd,
  offsetSm,
  fontWeightNormal,
  lineHeightLg,
} from 'constants/styleVars';

export const StyledCustomList = styled(CustomList)`
  li {
    font-size: ${fontSizeMd};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${offsetSm};
`;

export const StyledNW2Button = styled(NW2Button)`
  margin-right: ${offsetSm};
`;

export const StyledText = styled.p<{margin?: string}>`
  font-weight: ${fontWeightNormal};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  margin: ${({margin}) => margin || `0 0 ${fontSizeMd}`};
`;

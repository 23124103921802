import React from 'react';
import {useLocation} from 'react-router-dom';

import {Nw2EnterPassword} from 'view/components/EnterPassword';
import useOpenSetPasswordModal from 'view/auth/utils/useOpenSetPasswordModal';

import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {Container} from 'view/components/EnterPassword/helper';
import {EOpenPasswordModalTypes} from 'types/app';
import useLoginInSystem from 'hooks/useLoginInSystem';
import {useRedirectOfferLinkAfterLogin} from 'view/venue/Offer/hooks/useRedirectOfferLinkAfterLogin';

const modalProps = {
  btnOkText: 'finish activation',
  pageName: 'Set a password',
  type: EOpenPasswordModalTypes.REGISTER,
  notificationMessage: 'Welcome to NewWork! Your account is ready to be used.',
};

const EndRegistrationSetPassword = () => {
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const role: EUserRoleCognito = query.get('role');

  const {isRedirected} = useOpenSetPasswordModal({
    role,
    ...modalProps,
  });

  const [redirectLinkAfterLogin] = useRedirectOfferLinkAfterLogin();

  const {completeNewPassword} = useLoginInSystem({
    redirectLinkAfterLogin,
  });

  if (isRedirected) return null;

  return (
    <Container>
      <Nw2EnterPassword
        request={completeNewPassword}
        isMobile
        {...modalProps}
      />
    </Container>
  );
  // Old flow for registration
  // <EnterPassword
  //   moveToSuccessPagePath={redirectUrl}
  //   pageName='Set a password'
  //   request={ApiInventoryService.confirmAccountPassword}
  // />
};

export default EndRegistrationSetPassword;

import React from 'react';

import SpaceDetailsSection from './SpaceDetailsSection';
import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';

import {
  AnchorSections,
  AnchorsWrapper,
} from 'view/components/SupplierLayout/SupplierLayout.styles';
import {StyledContainer} from '../NMMSpacesDetails.styles';
import {ISpacesDataItem, TVenueFloors} from 'types/venue';
import {MODAL_TWO_COLS_RIGHT_COL_ID} from 'constants/app';
import {useSpaceSections} from '../hooks/useSpaceSections';

type TProps = {
  space: ISpacesDataItem;
  isExternalVenue: boolean;
  floors: TVenueFloors;
};

const SpaceDetailsContent = ({space, floors, isExternalVenue}: TProps) => {
  const {roomType} = space;

  const spaceSections = useSpaceSections({isExternalVenue, roomType});

  return (
    <StyledContainer>
      <AnchorsWrapper>
        <AnchorSections>
          {spaceSections.map((section) => (
            <SpaceDetailsSection
              key={section}
              space={space}
              floors={floors}
              sectionName={section}
              roomType={roomType}
            />
          ))}
        </AnchorSections>

        <AnchorMenu
          rootId={MODAL_TWO_COLS_RIGHT_COL_ID}
          sections={spaceSections}
        />
      </AnchorsWrapper>
    </StyledContainer>
  );
};

export default SpaceDetailsContent;

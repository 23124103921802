import React from 'react';

import {Text, TextTitle} from '../../Offer.styles';

interface IProps {
  isGroupRequest?: boolean;
}
export function WhatIsNext({isGroupRequest}: IProps) {
  return (
    <>
      {isGroupRequest ? (
        <>
          <TextTitle>What happens if I receive multiple offers?</TextTitle>
          <Text>
            If you receive multiple offers, you'll have until your option date
            to evaluate each venue's offer and determine the best option for
            your event. There's no need for you to individually decline any
            offers. Simply choose and confirm your preferred offer, and we will
            automatically decline all the other offers on your behalf.
          </Text>
        </>
      ) : (
        <>
          <TextTitle>
            What happens if the venue is unable to accommodate my request?
          </TextTitle>
          <Text>
            The venue will provide the reason as to why they’re unable to
            accommodate your request. We will also help by providing alternative
            venues for you to consider.
          </Text>
        </>
      )}

      <TextTitle>
        What happens if I am not happy with the offer provided by the venue?
      </TextTitle>
      {isGroupRequest ? (
        <>
          <Text>
            If none of the offers meet your expectations, you have the
            flexibility to cancel the entire request at any time without
            incurring any charges, as long as you haven't accepted any of the
            offers.
          </Text>

          <TextTitle>
            What happens if a venue does not respond in time?
          </TextTitle>
          <Text>
            In the event that a venue doesn't respond within the given deadline,
            we take proactive action on your behalf. We will automatically
            decline your request with that particular venue. We understand that
            venues may occasionally experience high demand or other challenges,
            and we will follow up to investigate the situation. If necessary, we
            will blacklist the venue to prevent future inconveniences.
          </Text>
        </>
      ) : (
        <Text>
          You can always decline an offer. The offer is not binding until it is
          confirmed by you.
        </Text>
      )}
    </>
  );
}

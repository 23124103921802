import React, {ReactNode, useEffect, useState} from 'react';

import Icon from 'view/components/Icon';

import {
  StyledCloseButton,
  StyledModalBody,
  StyledModalContent,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalMask,
  StyledModalTitle,
  StyledModalWrapper,
} from './CustomModal.styles';

interface CustomModalProps {
  children?: ReactNode;
  width?: string;
  modalHeight?: string;
  header?: ReactNode;
  isShowModal?: boolean;
  onClose?: () => void;
  footer?: ReactNode;
  modalBodyStyle?: React.CSSProperties;
  modalHeaderStyle?: React.CSSProperties;
  modalFooterStyle?: React.CSSProperties;
  isMask?: boolean;
  position?: 'center' | 'topRight';
  isCloseOnMaskClick?: boolean;
}

const CustomModal = ({
  children,
  width,
  modalHeight,
  header,
  isShowModal,
  onClose,
  footer,
  modalBodyStyle,
  modalHeaderStyle,
  modalFooterStyle,
  isMask = true,
  position = 'center',
  isCloseOnMaskClick = false,
}: CustomModalProps) => {
  const [isShowed, setIsShowed] = useState(isShowModal);

  useEffect(() => {
    setIsShowed(isShowModal);
  }, [isShowModal]);
  const closeModal = () => {
    setIsShowed(false);
    if (onClose) {
      onClose();
    }
  };
  const handleMaskClick = () => {
    if (isCloseOnMaskClick) {
      closeModal();
    }
  };

  return isShowed ? (
    <StyledModalWrapper position={position}>
      {isMask && <StyledModalMask onClick={handleMaskClick} isMask={isMask} />}
      <StyledModalContent width={width} modalHeight={modalHeight}>
        <StyledModalHeader style={modalHeaderStyle} isHeaderHidden={!header}>
          {header && (
            <StyledModalTitle>
              {header}
              <StyledCloseButton onClick={closeModal}>
                <Icon icon='NW2_CLOSE' clickable />
              </StyledCloseButton>
            </StyledModalTitle>
          )}
        </StyledModalHeader>
        <StyledModalBody style={modalBodyStyle}>{children}</StyledModalBody>
        {footer && (
          <StyledModalFooter style={modalFooterStyle}>
            {footer}
          </StyledModalFooter>
        )}
      </StyledModalContent>
    </StyledModalWrapper>
  ) : null;
};

export default CustomModal;

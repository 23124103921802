import styled from 'styled-components';

import {
  contentMaxWidthLg,
  lgBp,
  mdBp,
  NW2Gray900Color,
  offsetDef,
  offsetXXLg,
  offsetXXXLg,
  xLgBp,
} from 'constants/styleVars';

export const Wrapper = styled.div<{isFixed: boolean}>`
  background-color: ${NW2Gray900Color};
  min-height: 290px;
  ${({isFixed}) => isFixed && `min-height: 25px`};
`;

export const Content = styled.div<{minimized: boolean}>`
  margin: 0 auto;
  position: relative;
  background-color: ${NW2Gray900Color};
  padding: ${offsetDef} 0;

  @media (min-width: ${mdBp}px) {
    display: flex;
    padding: ${({minimized}) => (minimized ? offsetDef : offsetXXXLg)} 0;
  }

  @media (min-width: ${lgBp}px) {
    max-width: ${contentMaxWidthLg};
  }

  @media (min-width: ${xLgBp}px) {
    padding: ${({minimized}) => (minimized ? offsetDef : offsetXXXLg)} 0;
  }
`;

export const Section = styled.div<{isFixed: boolean}>`
  position: relative;
  z-index: 99;
  background-color: ${NW2Gray900Color};
  padding: 0 ${offsetDef};

  @media (min-width: ${mdBp}px) {
    padding: 0 ${offsetXXLg};
  }

  ${({isFixed}) =>
    isFixed &&
    `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    `};
`;

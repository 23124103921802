import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import TruncateMarkup, {TruncateProps} from 'react-truncate-markup';
import styled from 'styled-components';

import TextValue from '../TextValue';
import FullContentLink from './FullContentLink';

import {useGoogleTranslationStatus} from 'hooks/useGoogleTranslationStatus';

export type TAlignToggler = 'left' | 'center' | 'right';

type TTag =
  | 'span'
  | 'pre'
  | 'div'
  | 'p'
  | 'a'
  | 'b'
  | 'strong'
  | 'i'
  | 'main'
  | 'section'
  | 'article';

interface TProps extends TruncateProps {
  text: string;
  tag?: TTag;
  numberOfLines?: number;
  moreText?: string;
  lessText?: string;
  alignChildren?: TAlignToggler;
  hideToggler?: boolean;
  alignToggler?: TAlignToggler;
  expanded?: boolean;
  isTextAligned?: boolean;
  showFullModal?: () => void;
  fullContentLinkTitle?: string;
  handleIsTruncated?: (isTruncated: boolean) => void;
}

const Container = styled.span<{isTextAligned?: boolean}>`
  display: flex;
  flex-direction: column;
  text-align: ${({isTextAligned}) => (isTextAligned ? 'justify' : '')};

  pre {
    font-family: inherit;
    white-space: break-spaces;
  }
`;

const StyledTextValue = styled(TextValue)<{alignToggler: TAlignToggler}>`
  text-align: ${({alignToggler}) => alignToggler};
`;

const TextLine = styled.span`
  word-break: break-word;
  display: inline !important;
`;

const TruncateMarkupChildren = styled.span<{alignChildren?: TAlignToggler}>`
  text-align: ${({alignChildren}) => alignChildren};
`;

const TruncateText = ({
  text = '',
  numberOfLines = 1,
  tag = 'span',
  moreText = 'Read more',
  lessText = 'Read less',
  alignChildren = 'left',
  hideToggler = false,
  alignToggler = 'left',
  expanded: isInitiallyExpanded = false,
  isTextAligned = true,
  showFullModal,
  fullContentLinkTitle,
  handleIsTruncated,
  ...rest
}: TProps) => {
  const isTranslated = useGoogleTranslationStatus();
  const [toggle, setToggle] = useState(false);
  const [expanded, setExpanded] = useState(isInitiallyExpanded);
  const togglerText = expanded ? lessText : moreText;

  const toggleExpand = useCallback(() => setExpanded(!expanded), [expanded]);

  const Wrapper = tag;

  const getToggler = useMemo(() => {
    if (hideToggler || toggle) return null;
    if (showFullModal)
      return (
        <FullContentLink
          text={fullContentLinkTitle}
          onClick={showFullModal}
          alignToggler={alignToggler}
          data-testid='truncate-btn'
        />
      );
    return (
      <StyledTextValue
        primary
        onClick={toggleExpand}
        alignToggler={alignToggler}
        data-testid='truncate-btn'
      >
        {togglerText}
      </StyledTextValue>
    );
  }, [
    alignToggler,
    fullContentLinkTitle,
    hideToggler,
    showFullModal,
    toggle,
    toggleExpand,
    togglerText,
  ]);

  const formatTextWithNewLinesToJSX = useMemo(
    () =>
      text
        ?.split('\n')
        .map((line, index, arr): ReactElement | ReactElement[] => {
          const lineTag = <TextLine key={line}>{line}</TextLine>;

          if (index === arr.length - 1) {
            return lineTag;
          } else {
            return [lineTag, <br key={index + 'br'} />];
          }
        }),
    [text],
  );

  const onTruncate = (isTruncated: boolean) => {
    setToggle(!isTruncated);

    if (typeof handleIsTruncated === 'function') {
      handleIsTruncated(isTruncated);
    }
  };

  return (
    <Container isTextAligned={isTextAligned}>
      {expanded || isTranslated ? (
        <Wrapper>{formatTextWithNewLinesToJSX}</Wrapper>
      ) : (
        <TruncateMarkup lines={numberOfLines} onTruncate={onTruncate} {...rest}>
          <TruncateMarkupChildren alignChildren={alignChildren}>
            {formatTextWithNewLinesToJSX}
          </TruncateMarkupChildren>
        </TruncateMarkup>
      )}

      {getToggler}
    </Container>
  );
};

export default TruncateText;

import styled from 'styled-components';
import NW2Button from 'view/components/NW2Button';

import {
  fontSizeMd,
  fontSizeXLg,
  fontSizeXSm,
  fontSizeXXXLg,
  fontWeightBold,
  lineHeightLg,
  lineHeightSm,
  lineHeightXXLg,
} from 'constants/styleVars';

export const StyledTotalLink = styled(NW2Button)`
  font-size: ${fontSizeXSm};
  line-height: ${lineHeightSm};
  padding: 0;
  text-transform: none;
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  > span {
    &:before {
      display: none;
    }
  }
`;

export const TotalFooterLeft = styled.div`
  text-align: left;
`;

export const TotalFooterTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const TotalFooterRight = styled.div`
  text-align: right;
`;

export const TotalPriceTitle = styled.div`
  font-size: ${fontSizeMd};
  line-height: ${lineHeightLg};
  font-weight: ${fontWeightBold};
  margin-bottom: 6px;
`;

export const TotalPriceSubTitle = styled.div`
  font-size: ${fontSizeXSm};
  line-height: ${fontSizeXLg};
`;

export const TotalPrice = styled.div`
  font-size: ${fontSizeXXXLg};
  line-height: ${lineHeightXXLg};
  font-weight: ${fontWeightBold};
`;

import React from 'react';

import {TNmmNavMenuList} from 'types/header';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';
import {Divider} from 'view/common/Header/Header.styles';
import {propertyManagerMenu, supplierMenu} from '../menuConfigs';
import {NW2MenuItem} from 'view/components/NW2MenuItem/NW2MenuItem';
import {SupplierHeaderItems, TMenuListPropsWithSpaces} from '../types';

const setNavMenuList = (
  role: string,
  venueId: string,
  isSpaces: boolean,
): TNmmNavMenuList[] | undefined => {
  switch (role) {
    case EUserRoleCognito.ROLE_SUPPLIER:
      return supplierMenu({venueId, isSpaces}).restNavList?.filter(
        ({isVisible}) => isVisible,
      );
    case EUserRoleCognito.ROLE_PROPERTY_MANAGER:
      return propertyManagerMenu({venueId, isSpaces}).restNavList?.filter(
        ({isVisible}) => isVisible,
      );
    // case EUserRoleCognito.ROLE_ADMIN:
    //   return adminMenu.navList;
    default:
      return undefined;
  }
};

function RestNavMenuList({
  role,
  activeItem,
  selectMenuItem,
  activeDropdownItem,
  selectActiveDropdownItem,
  venueId,
  spaces,
}: TMenuListPropsWithSpaces) {
  const navMenuList = setNavMenuList(
    role,
    venueId,
    !!(spaces && spaces.length),
  );

  const handleMenuClick = (id: SupplierHeaderItems) => () => {
    selectMenuItem(id);
  };

  if (!navMenuList) return null;

  return (
    <>
      {navMenuList.map(({url, text, dropDownItems, icon, id}) => {
        return (
          <NW2MenuItem
            id={id}
            key={id}
            isActive={activeItem === id}
            title={text}
            url={url}
            activeDropdownItem={activeDropdownItem}
            onDropdownClick={selectActiveDropdownItem}
            color='NW2Primary'
            onClick={handleMenuClick(id)}
            dropdownItems={dropDownItems}
            icon={icon}
          />
        );
      })}
      <Divider />
    </>
  );
}

export default RestNavMenuList;

import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useAppDispatch} from 'store/hooks';
import AnchorMenu from 'view/components/SupplierLayout/components/AnchorMenu';
import NMMSection from 'view/components/NMMSection';
import useUpdateVenue from 'view/venue/hooks/useUpdateVenue';
import useRoomAmenities from './common/hooks/useRoomAmenities';
import NoRooms from './common/NoRooms/NoRooms';
import NMMSpacesList from './common/NMMSpacesList/NMMSpacesList';
import CommonAmenitiesView from './common/CommonAmenitiesInfoForm/CommonAmenitiesView';
import CommonAmenitiesForm from './common/CommonAmenitiesInfoForm/CommonAmenitiesForm';

import {ERoomType} from 'types/dto/ERoomType.type';
import {updateCommonAmenities} from './common/utils/amenities';
import {filterSpacesByRoomType} from '../Header/helpers/NMMSpaceHelper';
import {ALL_SPACES_INCLUDE} from 'view/venue/hooks/useAnchorsSpacesSection';

import {EAccommodationType} from 'types/dto/IPublicVenue';
import {
  AnchorSections,
  AnchorsWrapper,
  Container,
  StyledDiv,
} from 'view/components/SupplierLayout/SupplierLayout.styles';
import {offset80, offsetXLg} from 'constants/styleVars';
import {ISpacesData, ISpacesDataItem, IVenue} from 'types/venue';
import {getSpaces, getWorkInProgress} from 'store/venue/actions';
import {useSelector} from 'react-redux';
import {RootState} from 'store/types';
import _get from 'lodash/get';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

const AMENITIES_SECTION_PADDING = `${offset80} 0 0`;
const AMENITIES_SECTION_MARGIN = `0 0 ${offset80}`;

interface IProps {
  roomType: ERoomType;
  venue: IVenue;
  spaces: ISpacesData[];
  accommodationType: EAccommodationType;
  anchorSections: string[];
  handleCreateRoom: (roomFloor?: number) => void;
  wip: ISpacesDataItem[];
}

const ProfileSpaceContent = ({
  roomType,
  venue,
  spaces,
  accommodationType,
  anchorSections,
  handleCreateRoom,
  wip,
}: IProps) => {
  const dispatch = useAppDispatch();

  const isExternalVenue = accommodationType === EAccommodationType.VENUE;
  const filteredSpaces = filterSpacesByRoomType(spaces, roomType);
  const numberOfSpaces = filteredSpaces.length;

  // Amenities
  const {roomAmenities} = useRoomAmenities(roomType);

  const commonAmenities = venue.commonAmenities;

  const filteredAmenities = useMemo(() => {
    const activeAmenities = commonAmenities || [];

    return (
      activeAmenities.find((room) => room.type === roomType)
        ?.allUnitsIncluded || []
    );
  }, [roomType, commonAmenities]);

  // TODO: Find better solution
  const isAgent =
    useSelector((state: RootState) => _get(state, 'app.user.role')) ===
    EUserRoleCognito.ROLE_AGENT;

  const [inEditMode, setInEditMode] = useState(filteredAmenities.length === 0);

  useEffect(() => {
    setInEditMode(filteredAmenities.length === 0);
  }, [filteredAmenities.length]);

  const openEditMode = useCallback(() => setInEditMode(true), []);

  const closeEditMode = useCallback(() => {
    setInEditMode(filteredAmenities.length === 0);
  }, [filteredAmenities.length]);

  const venueId = venue.id;
  const {updateVenue, isSending} = useUpdateVenue({
    venue,
    isExternalVenue,
    endHandler: closeEditMode,
    onSuccessCallback: () => {
      // getting spaces after ALL SPACES INCLUDE update
      dispatch(getSpaces(venueId));
      dispatch(getWorkInProgress(venueId));
    },
  });

  const updateAmenities = useMemo(
    () =>
      updateCommonAmenities(
        commonAmenities,
        roomAmenities,
        roomType,
        updateVenue,
      ),
    [commonAmenities, roomAmenities, roomType, updateVenue],
  );

  const isNoRooms = useMemo(
    () =>
      numberOfSpaces === 0 && !wip.some((space) => space.roomType === roomType),
    [numberOfSpaces, roomType, wip],
  );

  const activeAmenities = useMemo(
    () => filteredAmenities.map((amenity) => amenity.id),
    [filteredAmenities],
  );

  return (
    <Container>
      <AnchorsWrapper>
        <AnchorSections>
          {isNoRooms ? (
            <NoRooms
              handleCreateRoom={handleCreateRoom}
              isWorkdesk={roomType === ERoomType.WORK_SPACE}
            />
          ) : (
            <StyledDiv>
              <NMMSpacesList
                spaces={spaces}
                wip={wip}
                roomType={roomType}
                isExternalVenue={isExternalVenue}
                handleCreateRoom={handleCreateRoom}
              />
            </StyledDiv>
          )}

          <NMMSection
            id={ALL_SPACES_INCLUDE}
            title={ALL_SPACES_INCLUDE}
            isEditOn={inEditMode}
            onEdit={openEditMode}
            titleFontSize={offsetXLg}
            sectionPadding={AMENITIES_SECTION_PADDING}
            sectionMargin={AMENITIES_SECTION_MARGIN}
          >
            {inEditMode && !isAgent ? (
              <CommonAmenitiesForm
                roomType={roomType}
                roomAmenities={roomAmenities}
                activeAmenities={activeAmenities}
                isSending={isSending}
                handleEnd={closeEditMode}
                updateVenue={updateAmenities}
              />
            ) : (
              <CommonAmenitiesView
                roomType={roomType}
                activeAmenities={filteredAmenities}
              />
            )}
          </NMMSection>
        </AnchorSections>

        <AnchorMenu sections={anchorSections} />
      </AnchorsWrapper>
    </Container>
  );
};

export default ProfileSpaceContent;

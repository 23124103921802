import {ApiType, IApi} from 'infra/common/http.service';
import {IRecordDTO, ISupplierMasterDataDTO} from 'types/agent';
import {
  TRecordFormFields,
  TSupplierMasterDataFormFields,
} from 'view/agent/AgentView/types';

const agentRepository = (api: IApi) => ({
  getUserRoleInfoByEmail: async (email: string): Promise<any> =>
    await api.get(ApiType.Inventory, `users/data?email=${email}`),
  getOfferRequestByBookingNumber: async (bookingNumber: string): Promise<any> =>
    await api.get(ApiType.Offers, `requests/v2?number=${bookingNumber}`),
  getOfferByBookingNumber: async (bookingNumber: string): Promise<any> =>
    await api.get(ApiType.Offers, `offers/v2?number=${bookingNumber}`),
  getOfferGroupsByBookingNumber: async (bookingNumber: string): Promise<any> =>
    await api.get(ApiType.Offers, `groups/number/${bookingNumber}`),

  getRecordData: async (payload: TRecordFormFields): Promise<IRecordDTO[]> => {
    const params = new URLSearchParams(payload).toString();

    return await api.get(
      ApiType.Common,
      `agent-bff/records${params ? '?' + params : ''}`,
    );
  },

  getSupplierMasterData: async (
    payload: TSupplierMasterDataFormFields,
  ): Promise<ISupplierMasterDataDTO[]> => {
    const params = new URLSearchParams(payload).toString();

    // TODO: Provide pagination with future updates of Agent View
    return await api.get(
      ApiType.Common,
      `agent-bff/venues?page=0&size=500${params ? '&' + params : ''}`,
    );
  },
});

export default agentRepository;

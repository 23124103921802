import styled from 'styled-components';

import Icon from 'view/components/Icon';

import {
  NW2Gray600Color,
  offsetXXSm,
  offsetDef,
  fontWeightBold,
} from 'constants/styleVars';

export const StyledIcon = styled(Icon)`
  margin-right: ${offsetXXSm};
  color: ${NW2Gray600Color};
`;

export const AddressText = styled.span`
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
  color: ${NW2Gray600Color};
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TimeDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${offsetDef};
`;

export const TimeText = styled.span`
  margin-bottom: 0;
  margin-left: ${offsetXXSm};
  font-weight: ${fontWeightBold};
  color: ${NW2Gray600Color};
  white-space: nowrap;
`;

import React, {ChangeEvent, FocusEvent, memo} from 'react';

import {NW2FormItemInput} from 'view/components/NW2FormItem/NW2FormItem';

import {offsetXLg, offsetXXLg} from 'styles/configs/offset';
import {NW2Gray600Color} from 'styles/configs/colors';
import {EPricesFormFields, TPricesFormFieldsValues} from './PricesForm.types';
import {
  requiredFieldRules,
  requiredPriceRules,
} from 'utils/finalFormFieldRules';
import {ContentSubtitle, FormGroup} from '../../NMMSpacesDetails.styles';
import {usePaidDurationOptions} from 'view/venue/NW2VenueProfile/components/AddSpace/hooks/usePaidDurationOptions';
import {StyledSelect} from 'view/venue/NW2VenueProfile/components/AddSpace/styles';
import {ONLY_DIGITS_WITH_ONE_DOT_EXP} from 'utils/helpers';

interface IProps {
  currencySymbol?: string;
  hasPriceHalfDay?: boolean;
  onFormChange: (name: any, value?: any) => void;
}

const SELECT_MAX_HEIGHT = '330px';
const SELECT_MIN_WIDTH = '209px';

export const PricesFormItems = memo(
  ({currencySymbol, hasPriceHalfDay = true, onFormChange}: IProps) => {
    const paidDurationOptions = usePaidDurationOptions();

    const onChangePrice =
      (type: keyof TPricesFormFieldsValues) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const onChange = (value: string) => {
          onFormChange(type, value);
        };

        // ToDo should be replaced by inputTextToNumber helper, when number format provided on edit
        if (ONLY_DIGITS_WITH_ONE_DOT_EXP.test(e.target.value)) {
          onChange(e.target.value);
        }
      };

    const onBlurPrice =
      (type: keyof TPricesFormFieldsValues) =>
      (e: FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (value) {
          onFormChange(type, Number(value).toFixed(2));
        }
      };

    return (
      <>
        <ContentSubtitle>price range</ContentSubtitle>
        <FormGroup
          columnNumber={3}
          gap={parseInt(offsetXLg)}
          marginBottom={offsetXXLg}
        >
          <NW2FormItemInput
            type='text'
            label={`Per hour in ${currencySymbol}`}
            placeholder={`Per hour in ${currencySymbol}`}
            name={EPricesFormFields.PRICE_HOUR}
            labelColor={NW2Gray600Color}
            rules={requiredPriceRules(
              EPricesFormFields.PRICE_HOUR,
              'Please provide per hour price',
            )}
            onChange={onChangePrice(EPricesFormFields.PRICE_HOUR)}
            onBlur={onBlurPrice(EPricesFormFields.PRICE_HOUR)}
            data-testid={`input-space-prices-${EPricesFormFields.PRICE_HOUR}`}
          />

          {hasPriceHalfDay && (
            <NW2FormItemInput
              type='text'
              label={`Per half-day in ${currencySymbol}`}
              placeholder={`Per half-day in ${currencySymbol}`}
              name={EPricesFormFields.PRICE_HALF_DAY}
              labelColor={NW2Gray600Color}
              rules={requiredPriceRules(
                EPricesFormFields.PRICE_HALF_DAY,
                'Please provide per half-day price',
              )}
              onChange={onChangePrice(EPricesFormFields.PRICE_HALF_DAY)}
              onBlur={onBlurPrice(EPricesFormFields.PRICE_HALF_DAY)}
              data-testid={`input-space-prices-${EPricesFormFields.PRICE_HALF_DAY}`}
            />
          )}

          <NW2FormItemInput
            type='text'
            label={`Per day in ${currencySymbol}`}
            placeholder={`Per day in ${currencySymbol}`}
            name={EPricesFormFields.PRICE_DAY}
            labelColor={NW2Gray600Color}
            rules={requiredPriceRules(
              EPricesFormFields.PRICE_DAY,
              'Please provide per day price',
            )}
            onChange={onChangePrice(EPricesFormFields.PRICE_DAY)}
            onBlur={onBlurPrice(EPricesFormFields.PRICE_DAY)}
            data-testid={`input-space-prices-${EPricesFormFields.PRICE_DAY}`}
          />
        </FormGroup>

        {hasPriceHalfDay && (
          <>
            <ContentSubtitle>minimum spend</ContentSubtitle>
            <FormGroup columnNumber={3} gap={parseInt(offsetXLg)}>
              <StyledSelect
                name={EPricesFormFields.PAID_DURATION}
                label='Minimum duration to be paid'
                placeholder='Minimum duration'
                options={paidDurationOptions}
                labelColor={NW2Gray600Color}
                rules={requiredFieldRules(EPricesFormFields.PAID_DURATION)}
                maxHeight={SELECT_MAX_HEIGHT}
                data-testid={`input-space-prices-${EPricesFormFields.PAID_DURATION}`}
                minWidth={SELECT_MIN_WIDTH}
                readonly
              />
            </FormGroup>
          </>
        )}
      </>
    );
  },
);

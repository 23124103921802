import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import LocalStorageService from 'infra/common/localStorage.service';

import {IVenue} from 'types/venue';

interface ICorporateOfficeInitialState {
  isLoading: boolean;
  offices: IVenue[];
  selectedOfficeId: number | null;
  error: unknown;
}

const initialState: ICorporateOfficeInitialState = {
  isLoading: false,
  offices: [],
  selectedOfficeId: null,
  error: null,
};

const corporateOfficeSlice = createSlice({
  name: 'corporate',
  initialState,
  reducers: {
    setOfficesLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setOfficesError(state, action: PayloadAction<unknown>) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setOfficesPayload(state, action: PayloadAction<IVenue[]>) {
      state.offices = action.payload;
    },
    setSelectedOfficeId(state, action: PayloadAction<number>) {
      LocalStorageService.setByKey('selectedOfficeId', String(action.payload));
      state.selectedOfficeId = action.payload;
    },
    clearOfficesPayload(state) {
      state.offices = [];
    },
  },
});

export const {
  setOfficesLoading,
  setOfficesError,
  setOfficesPayload,
  clearOfficesPayload,
  setSelectedOfficeId,
} = corporateOfficeSlice.actions;

export default corporateOfficeSlice.reducer;

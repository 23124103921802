import {floorLevels, getKeyByValue} from 'constants/floorStorey';
import {ERoomType} from 'types/dto/ERoomType.type';
import {IFloorInfo} from 'types/venue';

interface IProps {
  floorNumber: number;
  units: IFloorInfo;
}

export const useFloorUnits = ({
  floorNumber,
  units: {roomDetailDto},
}: IProps) => {
  const floorName = getKeyByValue(floorLevels, floorNumber);
  const meetingSpaces = roomDetailDto?.[ERoomType.MEETING_ROOM] || [];
  const workDesksSpaces = roomDetailDto?.[ERoomType.WORK_SPACE] || [];
  const meetingRoomsCount = meetingSpaces.length;
  const workDesksCount = workDesksSpaces.length;

  const hasSpaces = !!(workDesksCount || meetingRoomsCount);

  return {
    floorName,
    meetingSpaces,
    workDesksSpaces,
    meetingRoomsCount,
    workDesksCount,
    hasSpaces,
  };
};

import {EEventType} from 'types/venue';

export type TDateType = Date | null;

export interface ITimeData {
  timeStart: string;
  timeEnd: string;
  eventType?: EEventType;
}

export const DEFAULT_START_TIME = 9;
export const DEFAULT_END_TIME = 18;

export const SEARCH_DEFAULT_START_TIME = {
  hour: DEFAULT_START_TIME,
  minute: 0,
  second: 0,
  millisecond: 0,
};
export const SEARCH_DEFAULT_END_TIME = {
  hour: DEFAULT_END_TIME,
  minute: 0,
  second: 0,
  millisecond: 0,
};

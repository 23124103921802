import styled from 'styled-components';
import {InputNumber} from 'antd';

import {
  borderRadiusDef,
  fontWeightBold,
  NW2Gray200Color,
  NW2Gray500Color,
  NW2Primary,
  offsetDef,
  offsetNone,
  offsetXLg,
  offsetXXLg,
} from 'constants/styleVars';

const BORDER_RADIUS = '99px';
const INPUT_HEIGHT = '44px';

export const ButtonContainer = styled.div`
  display: flex;

  & > * {
    margin-right: ${offsetXLg};
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${offsetDef} ${offsetNone};
  background: ${NW2Gray200Color};
  border-radius: ${BORDER_RADIUS};
`;

export const GroupDistance = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInputNumber = styled(InputNumber)`
  border-radius: ${borderRadiusDef};
  border-color: ${NW2Gray500Color};

  &.ant-input-number-affix-wrapper input.ant-input-number-input {
    height: ${INPUT_HEIGHT};
  }

  & > .ant-input-number-prefix {
    font-weight: ${fontWeightBold};
  }

  &.ant-input-number-affix-wrapper:focus,
  &.ant-input-number-affix-wrapper-focused,
  &.ant-input-number-affix-wrapper:active {
    border-color: ${NW2Primary};
    box-shadow: none;
  }

  &.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
    border-color: ${NW2Primary};
  }
`;

export const Wrapper = styled.div`
  margin-bottom: ${offsetXXLg};
`;

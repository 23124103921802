import React, {ReactNode, useCallback, useEffect} from 'react';
import {Form, FormSpy} from 'react-final-form';
import {ValidationErrors} from 'final-form';

import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  PageActions,
  PageCount,
  PageHeader,
  PageHeaderWrapper,
  PageSubTitle,
  PageTitle,
  ProgressBar,
  SkipLink,
  SkipLinkWrapper,
} from './FormWizard.styles';

type TValues = any;

interface IProps {
  page: number;
  handleSetPage: (page: number) => void;
  children: ReactNode;
  initialValues: any;
  pagesToSkip?: number[];
  isSubmitting?: boolean;
  btnSubmitText?: string;
  validationRules?: (
    page: number,
  ) => (
    values: TValues,
  ) => ValidationErrors | Promise<ValidationErrors> | undefined;
  handleSetSkippedPages?: (step: string) => void;
  onSubmit: (values: TValues) => void;
  handleSetFormState?: (values: TValues) => void;
  handleActiveStep?: (stepTitle: string) => void;
}

export function FormWizard({
  page,
  handleSetPage,
  children,
  initialValues,
  isSubmitting,
  validationRules,
  btnSubmitText = 'next',
  pagesToSkip = [],
  handleSetSkippedPages,
  onSubmit,
  handleSetFormState,
  handleActiveStep,
}: IProps) {
  const pages = React.Children.toArray(children).filter(
    // @ts-ignore
    (p) => !p.props?.isHidden,
  );
  const activePage = pages[page];
  const totalPagesCount = pages.length;
  const isLastPage = page === totalPagesCount - 1;

  const {
    fieldName,
    title,
    subTitle,
    titleWidth,
    titlePaddingTop,
    isTitleCentered,
    isButtonNextHidden,
    stepTotalCount, // uses for dynamic counter
    btnNextText,
    buttonsSize,
    onNextClick, // optional additional action
    onBackClick, // optional additional action
  } =
    // @ts-ignore
    activePage?.props || {};

  // next page
  const handleNext = useCallback(() => {
    if (typeof onNextClick === 'function') {
      onNextClick();
    }

    handleSetPage(Math.min(page + 1, totalPagesCount));
  }, [handleSetPage, onNextClick, page, totalPagesCount]);

  // prev page
  const handleBack = useCallback(() => {
    if (typeof onBackClick === 'function') {
      onBackClick();
    }

    handleSetPage(Math.max(page - 1, 0));
  }, [handleSetPage, onBackClick, page]);

  useEffect(() => {
    if (!handleActiveStep || !title) return;

    handleActiveStep(title);
  }, [handleActiveStep, title]);

  const handleSubmit = useCallback(
    (values: TValues) => {
      if (isLastPage) {
        onSubmit(values);
      } else {
        handleNext();
      }
    },
    [handleNext, isLastPage, onSubmit],
  );

  const currentPage = page + 1;
  const isSkipLinkShowed = pagesToSkip.includes(currentPage);

  const onSkipStep = (step: string) => () => {
    handleSetSkippedPages?.(step);
  };

  const totalCount = stepTotalCount || totalPagesCount;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validationRules?.(page)}
      mutators={{
        setValue: ([field, value], state, {changeValue}) => {
          changeValue(state, field, () => value);
        },
      }}
    >
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <ProgressBar width={(100 * currentPage) / totalCount} />

          <PageHeaderWrapper maxWidth={titleWidth} paddingTop={titlePaddingTop}>
            <PageHeader>
              <PageCount>
                {currentPage} / {totalCount} Step
              </PageCount>
              {title && (
                <PageTitle isTitleCentered={isTitleCentered}>{title}</PageTitle>
              )}
              {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
            </PageHeader>
          </PageHeaderWrapper>

          {activePage}

          <PageActions>
            <NW2Button
              onClick={handleBack}
              size={buttonsSize || 'small'}
              data-testid={`form-wizard-prev-${page}`}
            >
              back
            </NW2Button>
            {!isButtonNextHidden &&
              (isLastPage ? (
                <NW2Button
                  type='submit'
                  buttonType='primary'
                  size={buttonsSize || 'small'}
                  disabled={isSubmitting}
                  data-testid='form-wizard-submit'
                >
                  {btnSubmitText}
                </NW2Button>
              ) : (
                <NW2Button
                  type='submit'
                  buttonType='primary'
                  size={buttonsSize || 'small'}
                  data-testid={`form-wizard-next-${page}`}
                >
                  {btnNextText || 'next'}
                </NW2Button>
              ))}
          </PageActions>

          {isSkipLinkShowed && (
            <SkipLinkWrapper>
              <SkipLink
                type='submit'
                role='button'
                tabIndex={0}
                onClick={onSkipStep(fieldName)}
              >
                Skip for now
              </SkipLink>
            </SkipLinkWrapper>
          )}

          {!!handleSetFormState && (
            <FormSpy
              subscription={{values: true}}
              onChange={handleSetFormState}
            />
          )}
        </form>
      )}
    </Form>
  );
}

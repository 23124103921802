import React from 'react';
import styled from 'styled-components';

import NW2ModalDrawer from 'view/components/NW2ModalDrawer';
import NW2Button from 'view/components/NW2Button';

import {pluralize} from 'utils/helpers';
import {offsetSm, offsetXXSm, preXLgBd} from 'constants/styleVars';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 ${offsetSm} 0;

  @media (max-width: ${preXLgBd}px) {
    justify-content: center;
  }
`;
export const StyledNW2Button = styled(NW2Button)`
  margin-right: ${offsetSm};
`;
export const BodyContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
`;
export const StyledLi = styled.li`
  margin-bottom: ${offsetXXSm};
`;

interface IProps {
  isConfirmModalShowed: boolean;
  onConfirm: () => void;
  onModalClose: () => void;
  offersToDecline: number;
  requestsToCancel: number;
  isMobile: boolean;
  isLoading: boolean;
}

export const ConfirmOfferModal = ({
  offersToDecline,
  requestsToCancel,
  isMobile,
  onModalClose,
  isConfirmModalShowed,
  onConfirm,
  isLoading,
}: IProps) => {
  const modalFooter = (
    <Container>
      <StyledNW2Button
        buttonType='secondary'
        onClick={onModalClose}
        size={isMobile ? 'large' : 'small'}
        inline
      >
        Cancel
      </StyledNW2Button>
      <NW2Button
        buttonType='primary'
        onClick={onConfirm}
        size={isMobile ? 'large' : 'small'}
        disabled={isLoading}
        inline
      >
        Confirm
      </NW2Button>
    </Container>
  );

  return (
    <NW2ModalDrawer
      header='Confirm'
      body={
        <BodyContainer>
          <p>
            You are about to confirm this booking. Please note, that you
            currently have:
          </p>
          <ul style={{paddingLeft: '24px'}}>
            {offersToDecline > 0 && (
              <StyledLi>{`${pluralize(
                'x alternative offer',
                offersToDecline,
              )}`}</StyledLi>
            )}
            {requestsToCancel > 0 && (
              <li>
                {` ${pluralize(
                  'x request',
                  requestsToCancel,
                )} pending for venue response
                  `}
              </li>
            )}
          </ul>
          <p>
            Confirming this offer will automatically decline/cancel pending
            offers/requests.
          </p>
          <div>
            <b>This action is irreversible.</b>
          </div>
        </BodyContainer>
      }
      isShowed={isConfirmModalShowed}
      isMobile={isMobile}
      footer={modalFooter}
      onClose={onModalClose}
      modalWidth={400}
      drawerHeight='auto'
    />
  );
};

import React, {memo} from 'react';
import styled from 'styled-components';

import {
  fontWeightSemiBold,
  NW2GrayTextColor,
  offsetSm,
  offsetXXSm,
} from 'constants/styleVars';
import {
  EPointsOfInterest,
  IPointOfInterest,
  pointsOfInterestMap,
} from 'types/venue';
import {getDecimalWithCount} from 'view/venue/NW2VenueProfile/helpers';
import {distanceConverter} from 'utils/venueUtils';

const Container = styled.div`
  margin-bottom: ${offsetSm};
`;
const Title = styled.div`
  font-weight: ${fontWeightSemiBold};
  margin-bottom: ${offsetXXSm};
`;
const PointBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  color: ${NW2GrayTextColor};
`;

interface IProps {
  poiList: IPointOfInterest[] | [];
}

export const NW2PointsOfInterestView = memo(({poiList}: IProps) => {
  if (!poiList?.length) return null;

  return (
    <>
      {Object.values(EPointsOfInterest).map((point) => {
        const data = poiList.filter((item) => item.type === point)[0];
        if (!data) return null;

        const distanceMi = distanceConverter('mi', data.distance);

        return (
          <Container key={point}>
            <Title>{pointsOfInterestMap[point]}</Title>
            <PointBlock>
              <div data-testid={`poi-view-name-${data.type}`}>{data.name}</div>
              <div data-testid={`poi-view-distance`}>{`${getDecimalWithCount(
                data.distance,
              )}km / ${getDecimalWithCount(distanceMi)}mi`}</div>
            </PointBlock>
          </Container>
        );
      })}
    </>
  );
});

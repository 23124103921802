import React, {ReactNode} from 'react';

import {PageWrapper} from './FormWizard.styles';

interface IProps {
  title: string;
  fieldName: string;
  titleWidth?: number;
  isTitleCentered?: boolean;
  children: ReactNode;
}

export const FormWizardPage = ({children}: IProps) => (
  <PageWrapper>{children}</PageWrapper>
);

import React from 'react';

import useNavigateFromInfoPage from '../../../NW2MultiModal/hooks/useNavigateFromInfoPage';

import {
  Content,
  Section,
  Title,
  Paragraph,
  StyledExternalLink,
} from 'view/common/NW2MultiModal/components/InfoPageTypography';
import {Routes} from 'constants/routes';
import {NEWWORK_SUPPORT_EMAIL} from 'constants/app';
import ContactUsBlock from '../../components/ContactUsBlock';

const TextContent = () => {
  const handleContactUsClick = () => {
    window.open(
      `mailto:${NEWWORK_SUPPORT_EMAIL}`,
      '_blank',
      'noreferrer noopener',
    );
  };
  return (
    <Content>
      <Section>
        <Title>What do we offer on this platform?</Title>
        <Paragraph>
          The platform offers a search and booking service for meeting rooms,
          work desks, and accommodations in hotels, event locations, and
          co-working spaces.
        </Paragraph>
      </Section>

      <Section>
        <Title>How to register my venue if I am new to HRS? </Title>
        <Paragraph>
          To register your venue please follow the registration &nbsp;
          <StyledExternalLink
            onClick={useNavigateFromInfoPage(Routes.createPublicVenue)}
          >
            funnel
          </StyledExternalLink>
          . In six steps, you provide your venue's contact details, address,
          description, images, and operational hours. To finalize the
          registration process, you must set up your meeting rooms and/or work
          desks as well as catering and equipment you want to offer your
          customers.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          Can I add additional email addresses to receive notifications?{' '}
        </Title>
        <Paragraph>
          Yes, you can add additional email addresses to receive notifications.
          In the 'Contacts' section, use the 'Booking Confirmation' and
          'Reception' fields to enter extra email addresses that should receive
          copies of all messages sent to your venue. For hotel or venue chains,
          you can also add a centralized booking or sales office email here.
          Please ensure the main email address is a general hotel email, not an
          individual's email.
        </Paragraph>
      </Section>

      <Section>
        <Title>How to register my venue being an existing HRS partner? </Title>
        <Paragraph>
          If you are already an existing hotel partner of HRS, please contact
          HRS &nbsp;
          <StyledExternalLink
            onClick={handleContactUsClick}
            target='_blank'
            rel='noreferrer noopener'
          >
            support
          </StyledExternalLink>
          . We will make sure that information you have already provided in the
          past is prefilled within the platform to reduce your effort at best.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I update my venues profile?</Title>
        <Paragraph>
          To update your venue's profile, you have to log in with the venue
          account that you created during the registration process. To access
          your venue details, visit&nbsp;
          <StyledExternalLink
            onClick={useNavigateFromInfoPage(Routes.publicVenue)}
          >
            here
          </StyledExternalLink>
          . Within each section, it is possible to update the profile.
          Exceptions to this are the name and address. To update name or
          address, please contact HRS&nbsp;
          <StyledExternalLink
            onClick={handleContactUsClick}
            target='_blank'
            rel='noreferrer noopener'
          >
            support
          </StyledExternalLink>
          .
        </Paragraph>
      </Section>

      <Section>
        <Title>How can customers find my venue in a search? </Title>
        <Paragraph>
          To ensure that your venue appears in search results, it is essential
          to add at least one meeting room or work desk to your listing. After
          adding your meeting rooms or work desks, please verify that they are
          set to active status, as only active listings will be visible to
          potential customers. When defining your meeting rooms, be sure to
          include all possible seating styles and the maximum number of
          participants, as these are key criteria used in the search filters. By
          providing comprehensive and accurate information about your meeting
          spaces, you can improve your venue's discoverability and attract more
          bookings from customers seeking the perfect space for their needs.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          How can I make my venue more discoverable in search results?{' '}
        </Title>
        <Paragraph>
          To make your venue more discoverable in search results, there are
          several steps you can take. First and foremost, ensure that your venue
          description is comprehensive, accurate, and up-to-date. Additionally,
          provide detailed information about your amenities, such as food and
          beverages, equipment, and meeting packages that set your venue apart.
          It's also crucial to create listings for all of your existing meeting
          rooms and work desks, not just a select few. By showcasing the full
          range of your available spaces, you increase the likelihood of
          appearing in relevant search results and attracting potential
          customers. Be sure to include key details for each meeting room, such
          as the maximum capacity, available seating arrangements, and any
          specific amenities or features. The more complete and informative your
          venue profile is, the higher your chances of ranking well in search
          results and capturing the attention of event planners and meeting
          organizers.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I offer meeting rooms or workdesks? </Title>
        <Paragraph>
          Go to the “Spaces” Tab and select “Meeting rooms” or “Workdesks”. When
          selecting "Create new” an intuitive funnel will guide you through all
          required details. Please provide at least one image, a brief
          description, the capacity, room setup styles (for meeting rooms only,
          amenities, and the price per hour, per half-day and per day. The
          platform will calculate the best price based on the duration of usage
          the customers are searching for. For meeting rooms, please define how
          each of them can get booked: instantly or on request. Instant
          bookability means that you enable your customers to book the meeting
          room directly and with no further confirmation from your side.
          On-request means that the booking will only become binding once you
          reconfirm it. Workdesks are always instantly bookable.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I offer accommodation? </Title>
        <Paragraph>
          When you receive a request for a meeting that includes accommodation
          needs, you have the ability to promptly add pricing for the requested
          bedrooms and respond with a comprehensive offer to the Request for
          Proposal (RFP). You can send a complete offer, covering both meeting
          spaces and accommodation, directly to the customer through this
          platform. When you receive a booking request that includes
          accommodations, you will be provided with the following room details:
          number of rooms requested, room type (single or double occupancy),
          breakfast preference (included or not included), check-in and
          check-out dates, and number of guests per room. With this information,
          you can accurately price and allocate the requested rooms, ensuring a
          seamless booking experience for the customer. Customers can then
          review the details of your offer, including pricing for both the
          meeting room and accommodations, and easily accept or reject offers
          that align with their needs
        </Paragraph>
      </Section>

      <Section>
        <Title>
          How can I update my pricing for meeting rooms or work desks?{' '}
        </Title>
        <Paragraph>
          Go to the spaces section and use the edit mode to adjust rates and
          capacities or number of work desks for future requests and bookings.
          Changes made here do not have an impact on existing or already
          departed bookings.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I offer equipment and catering services?</Title>
        <Paragraph>
          Go to the "Services" tab in your account. To add equipment or catering
          services, select the relevant tab as "equipment" or "catering”. From
          the list there, you can activate the service you want to add and set a
          price by entering edit mode, or you can offer this service for free.
          You can also specify each service to be offered to customers who make
          meeting room or work desk reservations (as both or either one). Later,
          you can deactivate the service you have activated or update its prices
          by putting it into edit mode from the same place. If there is a
          service in your RFP request that is not defined in your venue, you can
          still provide this service while responding to the request and
          determining a price for the service.
        </Paragraph>
      </Section>

      <Section>
        <Title>What is the meeting package? </Title>
        <Paragraph>
          Meeting packages are standardized offerings introduced by our platform
          to simplify the booking process for corporate meetings. You can offer
          three types of packages: Half-Day Package (for meetings less than 6
          hours), Full-Day Package (for meetings 6 hours or longer), and
          Full-Day Package + Dinner. Each package includes meeting room rental,
          equipment, catering, and beverages. By offering these packages, you
          can provide transparent, upfront pricing and streamline the booking
          process for customers.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I offer the meeting packages? </Title>
        <Paragraph>
          Go to the "Services" tab in your account and select "Meeting
          Packages." Activate the packages you want to offer (Half-Day,
          Full-Day, and/or Full-Day with Dinner). For each activated package,
          input your pricing per participant for groups of 10 or more. Review
          the standardized package details provided by this platform, which
          outlines the inclusions for each package type. Ensure that your venue
          can provide the necessary meeting rooms, equipment, and catering
          services as described in the package specifications.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I update my pricing for meeting packages? </Title>
        <Paragraph>
          Go to the "Services" tab in your account and select "Meeting
          Packages." use the edit mode to adjust price for future requests.
          Changes made here do not have an impact on existing or already
          departed bookings.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What is the difference between "instant booking" and
          "request-to-book"?
        </Title>
        <Paragraph>
          For meeting room bookings, you can decide if customers can book
          instantly, which means the binding booking is created when the
          customer finalizes the booking. We also provide the flexibility if you
          want to reconfirm the availability for a chosen date and room. In
          these cases, the customer sends you a request-to-book a meeting room
          which indicates the customer's wish to hold the meeting with you. This
          gives you full transparency on the expected income you will generate
          with that booking. The booking will be binding once you reconfirm the
          request. Work desks are always instantly bookable.
        </Paragraph>
      </Section>

      <Section>
        <Title>What is a "request for proposal"?</Title>
        <Paragraph>
          Customers interested in having their meeting at your venue can send
          you a request for proposal, also called request for offer. This
          usually happens if not all prices for those services a customer is
          looking for were provided by you in the past. In this case, you are
          asked to create an offer which is valid until the option date provided
          by the customer. While adding those missing prices to the offer, they
          will automatically enrich your venue profile pricelist. This way, the
          platform will calculate for every future customer the total price for
          the planned meeting upfront. As a result, we will optimize your
          incoming requests as you will only receive those which are within the
          customer's budget.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          How do I receive information about new requests, bookings, updated
          bookings, and cancellations?
        </Title>
        <Paragraph>
          You receive all updates within the booking overview section of the
          supplier extranet. The status of each reservation is updated whenever
          a customer is requesting, booking, editing, or cancelling a booking.
          Beyond that, you will receive all notifications via email to the
          address used to create your venue profile user account. Copies of
          those notifications are sent to “reception” and “booking confirmation”
          addresses you can maintain within the “Contacts” section within the
          venue profile page in the supplier extranet
        </Paragraph>
      </Section>

      <Section>
        <Title>What is the option date?</Title>
        <Paragraph>
          Option date provides customers with a designated time frame to make
          decisions on offers received from suppliers. As a supplier, when you
          set an option date, you agree to hold the meeting space and
          accommodation for the customer during this period, pending their
          decision. This feature ensures that customers have a clear and defined
          period to make decisions, providing peace of mind that the space will
          be held for them while they consider their options. If the customer
          decides to book after the option date has passed, you will be required
          to reconfirm availability before the booking can be finalized.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I access my bookings overview? </Title>
        <Paragraph>
          To access the bookings overview, log in with your venue account and
          select the “Meetings” or “Workdesks” tab.
        </Paragraph>
      </Section>

      <Section>
        <Title>How will I get paid by my customers? </Title>
        <Paragraph>
          Customers are obliged to pay you directly. You are asked to provide
          the customers with an invoice for the booked services and those the
          customers do order on-site.
        </Paragraph>
      </Section>

      <Section>
        <Title>How do I handle No-shows or late cancellations? </Title>
        <Paragraph>
          In case of No-shows as well as late cancellations, please create an
          appropriate invoice and send it to the customer. The customers'
          contact details as well as billing addresses are shared within the
          booking confirmation.
        </Paragraph>
      </Section>

      <Section>
        <Title>Do I have to create invoices for the customer? </Title>
        <Paragraph>
          Yes. It is your responsibility to provide an invoice to the customer.
          This is mandatory for all services booked via this platform and those
          which customers order on-site.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I find the guest's contact details? </Title>
        <Paragraph>
          The guest's contact details are part of the booking confirmation. You
          will find them in the booking details accessible from the booking
          overview and in the email notifications.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          What is a transient lounge space in the context of workdesks?{' '}
        </Title>
        <Paragraph>
          A transient lounge space is a work area, which is not separated from
          other areas within the venue. It could be a lounge area or a
          conference foyer. They are areas which are convenient for multi-tasker
          collaborators as they like to talk with other people, people watch,
          and do not get distracted easily.
        </Paragraph>
      </Section>

      <Section>
        <Title>What is an open shared space in the context of workdesks?</Title>
        <Paragraph>
          An open shared space is a work area, which is separated from other
          areas within the venue. This area is shared with other co-workers. It
          could be a shared office, a business center, or a converted meeting
          room. These are areas which are convenient for collaborators, as they
          like to talk to other people and like to get input and ideas from
          other people. Depending on the venue, those areas could be quiet
          workplaces as well.
        </Paragraph>
      </Section>

      <Section>
        <Title>What standard equipment must I provide for a work desk?</Title>
        <Paragraph>
          Based on our customers' feedback, we recommend each work desk is
          equipped with an office desk, an ergonomic chair, wireless internet
          connection and power plugs. Additional amenities can vary per venue
          and desk type.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I update the availability? </Title>
        <Paragraph>
          Please go to the "Availability" tab. Within this area you can open or
          block days e.g., in case of a bank holiday. Of course, we observe the
          availability as well, which means, if a meeting room or work desk are
          sold via our platform, you will not receive another request or booking
          for the same unit at the same time. In case you want to reduce the
          availability or deactivate the bookability of work desks, you can
          reduce the number of desks within the capacity of each category.
          Beyond that, you can make use of the “operational hours” within your
          venue profile to define your venues general availability.
        </Paragraph>
      </Section>

      <Section>
        <Title>Will I pay any commission to HRS and how? </Title>
        <Paragraph>
          HRS will charge a commission for providing the platform and access to
          our large customer base including global F500 companies. We will send
          you a commission invoice at the end of each month.
        </Paragraph>
      </Section>

      <Section>
        <Title>
          Do the dates and times relate to the customer's time zone or to the
          local supplier's time?{' '}
        </Title>
        <Paragraph>
          Dates and times always refer to the local supplier time at the travel
          destination.
        </Paragraph>
      </Section>

      <Section>
        <Title>What do the operational hours stand for?</Title>
        <Paragraph>
          Your operational hours should indicate the availability of their
          staff, e.g., at the front desk. It is possible for customers to book
          workdesks as well as instantly bookable meeting rooms during those
          operational hours. All other requests require either your offer or
          reconfirmation and are therefore a matter of availability and usually
          independent of the operational hours.
        </Paragraph>
      </Section>

      <Section>
        <Title>How can I reset my password? </Title>
        <Paragraph>
          Go to&nbsp;
          <StyledExternalLink onClick={useNavigateFromInfoPage(Routes.login)}>
            login page
          </StyledExternalLink>
          &nbsp;, then select "Forgot Password" to set up a new password. Only
          your contact email address is required.
        </Paragraph>
      </Section>

      <ContactUsBlock />
    </Content>
  );
};

export default TextContent;

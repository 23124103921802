import React, {ChangeEvent, useCallback, useState} from 'react';

import Icon from 'view/components/Icon';
import NW2Loader from 'view/components/NW2Loader';

import {ISearchDropdownListOption} from 'view/components/NW2FormItem/types';
import {
  Dropdown,
  Option,
  Options,
  OptionSubText,
  OptionText,
  ResetIcon,
  SearchBox,
  StyledInput,
} from './SearchableDropdown.styles';
import {isString} from 'lodash';

interface IProps {
  options: ISearchDropdownListOption[];
  selectedValue?: string | ISearchDropdownListOption;
  isLoading?: boolean;
  onChange: (value: string | ISearchDropdownListOption) => void;
  onClose: () => void;
}
const SearchableDropdown = ({
  options,
  selectedValue,
  onChange,
  onClose,
  isLoading,
}: IProps) => {
  const [query, setQuery] = useState('');

  const filter = useCallback(
    (options: ISearchDropdownListOption[]) =>
      options.filter(
        (option) =>
          option.text?.length &&
          option.text.toLowerCase().indexOf(query.toLowerCase()) > -1,
      ),
    [query],
  );

  const selectOption = (name: string | ISearchDropdownListOption) => () => {
    onChange(name);
    onClose();
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onReset = () => {
    setQuery('');
  };

  const filteredOptions = filter(options);

  const filtered = filteredOptions.map((option, index) => {
    const isSelected =
      option.text ===
      (isString(selectedValue) ? selectedValue : selectedValue?.value);

    return (
      <Option
        key={`${option.text}-${index}`}
        isSelected={isSelected}
        onClick={isSelected ? () => {} : selectOption(option)}
      >
        {isSelected && <Icon icon='CHECK' />}
        <span>
          <OptionText>{option.text}</OptionText>
          {option.subText && <OptionSubText>{option.subText}</OptionSubText>}
        </span>
      </Option>
    );
  });

  return (
    <Dropdown>
      {isLoading ? (
        <NW2Loader height='40px' />
      ) : (
        <>
          <SearchBox>
            <StyledInput
              type='text'
              name='searchTerm'
              placeholderIcon='NW2_SEARCH_THIN'
              placeholder='Search'
              value={query}
              onChange={onInputChange}
            />

            {query.length > 0 && <ResetIcon icon='CLOSE' onClick={onReset} />}
          </SearchBox>

          <Options>{filtered}</Options>
        </>
      )}
    </Dropdown>
  );
};

export default SearchableDropdown;
